import {createDate, getPastTimeFrom} from 'utils/dateTime';
import React, {useEffect, useRef, useState} from 'react';

const PassedTime: React.FC<{
    from: string;
}> = (props) => {
    const {from} = props;

    const [stateValue, setStateValue] = useState<{
        duration: {days: number; hours: number; minutes: number};
        isTimeInFuture: boolean;
    }>();
    const ref = useRef<{timerID: number | null}>({timerID: null});

    const calculateDuration = (): void => {
        const {fullOriginalDateTime: fromDateTime} = createDate(from, {toTimeZone: 'utc'});
        const {duration, milliseconds} = getPastTimeFrom(fromDateTime);
        const isTimeInFuture = Number(milliseconds) < 0;

        const result = {
            days: isTimeInFuture ? Math.abs(duration.days) : duration.days,
            hours: isTimeInFuture ? Math.abs(duration.hours) : duration.hours,
            minutes: isTimeInFuture ? Math.abs(duration.minutes) : duration.minutes,
        };
        setStateValue({duration: result, isTimeInFuture});
    };

    useEffect(() => {
        if (!ref.current.timerID) {
            calculateDuration();
        }

        const ONE_MINUTE_TIMEOUT = 60000;
        ref.current.timerID = window.setInterval(() => {
            calculateDuration();
        }, ONE_MINUTE_TIMEOUT);

        return function cleanUp() {
            if (ref.current.timerID) {
                window.clearInterval(ref.current.timerID);
            }
        };
    }, []);

    if (!stateValue) {
        return null;
    }

    const {
        duration: {days, hours, minutes},
        isTimeInFuture,
    } = stateValue;

    return (
        <>
            {isTimeInFuture && <span>in </span>}
            <strong>{days}</strong>d &nbsp;
            <strong>{hours}</strong>h &nbsp;
            <strong>{minutes}</strong>m{!isTimeInFuture && <span> ago</span>}
        </>
    );
};

export default PassedTime;
