import React from 'react';
import {Modal} from 'react-bootstrap';

import Batch from 'core/entities/Invoice/types/Batch';

import Form from './Form';
import {getCompaniesForSetupBatch} from './utils';

interface OwnProps {
    data: {factoredCompany: any; directCompanies: any; isEdit: boolean; batch: Batch};
    onClose();
    handlers: {onSubmitBatch(params: any)};
}

const SetupBatch = (props: OwnProps): JSX.Element => {
    const {
        onClose,
        data: {factoredCompany, directCompanies, isEdit, batch},
        handlers: {onSubmitBatch},
    } = props;
    const batchSetupData = getCompaniesForSetupBatch(factoredCompany, directCompanies);
    const onSubmit = (fields): void => {
        onSubmitBatch(fields);
    };
    const modalTitle = isEdit ? `Adding Invoices to the Batch #${batch.number}` : `Setup Batch`;
    return (
        <Modal show={true} onHide={onClose} dialogClassName="standard-form-modal" size="sm" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form
                        companies={batchSetupData}
                        batch={batch}
                        isEdit={isEdit}
                        onSubmit={onSubmit}
                        onClose={onClose}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SetupBatch;
