import {getEntityFieldName} from 'core/entities/Driver/types';

export const FIELDS = {
    general: {
        id: getEntityFieldName('id'),
        full_name: getEntityFieldName('full_name'),
        is_owner: getEntityFieldName('is_owner'),
        date_of_birthday: getEntityFieldName('date_of_birthday'),
        country_of_birth: getEntityFieldName('country_of_birth'),
        canada: getEntityFieldName('canada'),
        mexico: getEntityFieldName('mexico'),
        great_britain: getEntityFieldName('great_britain'),
        switzerland: getEntityFieldName('switzerland'),
        language: getEntityFieldName('language'),
        avatar: 'avatar',
        hired_by: getEntityFieldName('hired_by'),
        hired_date: getEntityFieldName('hired_date'),
        ssn: 'ssn',
        ein: 'ein',

        form_1099: 'form_1099',
        llc_name: 'llc_name',
        llc_ein: 'llc_ein',
        note: getEntityFieldName('note'),

        carrier: 'carrier',
        status: getEntityFieldName('status'),
        citizenship: getEntityFieldName('citizenship'),
        work_permit_expiry_date: getEntityFieldName('work_permit_expiry_date'),
        payroll: 'payroll',
        documents: 'documents',
        company_name: 'company_name',
        licence_number: getEntityFieldName('licence_number'),
        licence_type: getEntityFieldName('licence_type'),
        licence_state: getEntityFieldName('licence_state'),
        licence_country: getEntityFieldName('licence_country'),

        adr_certificate: getEntityFieldName('adr_certificate'),
        adr_certificate_number: getEntityFieldName('adr_certificate_number'),
        adr_class: getEntityFieldName('adr_class'),
        adr_country: getEntityFieldName('adr_country'),
        adr_expire: getEntityFieldName('adr_expire'),

        loi_macron_certificate: getEntityFieldName('loi_macron_certificate'),
        loi_macron_expire: getEntityFieldName('loi_macron_expire'),
        loi_macron_issue: getEntityFieldName('loi_macron_issue'),

        mi_log_certificate: getEntityFieldName('mi_log_certificate'),
        mi_log_expire: getEntityFieldName('mi_log_expire'),
        mi_log_issue: getEntityFieldName('mi_log_issue'),

        class: getEntityFieldName('class'),
        expiration: getEntityFieldName('expiration'),

        hazmat_certificate: getEntityFieldName('hazmat_certificate'),
        hazmat_since: getEntityFieldName('hazmat_since'),
        hazmat_exp: getEntityFieldName('hazmat_exp'),

        tsa_certificate: getEntityFieldName('tsa_certificate'),
        tsa_since: getEntityFieldName('tsa_since'),
        tsa_exp: getEntityFieldName('tsa_exp'),

        twic_certificate: getEntityFieldName('twic_certificate'),
        twic_since: getEntityFieldName('twic_since'),
        twic_exp: getEntityFieldName('twic_exp'),

        tanker_endorsement: getEntityFieldName('tanker_endorsement'),
        owner: getEntityFieldName('owner'),
        not_working_with: getEntityFieldName('not_working_with'),
        is_deleted: getEntityFieldName('is_deleted'),
        is_main: getEntityFieldName('is_main'),
        driver_note: getEntityFieldName('driver_note'),

        drivers_documents: getEntityFieldName('drivers_documents'),
        networks: getEntityFieldName('networks'),
    },
    contact: {
        city: getEntityFieldName('city'),
        zip: getEntityFieldName('zip'),
        additional_address_line: getEntityFieldName('additional_address_line'),
        address_line: getEntityFieldName('address_line'),
        home_phone: getEntityFieldName('home_phone'),

        mobile_phone: getEntityFieldName('mobile_phone'),

        additional_mobile_phone: getEntityFieldName('additional_mobile_phone'),

        fax: getEntityFieldName('fax'),
        email: getEntityFieldName('email'),
        relationship: getEntityFieldName('relationship'),
        ec_name: getEntityFieldName('ec_name'),
        ec_phone: getEntityFieldName('ec_phone'),
        ec_phone2: getEntityFieldName('ec_phone2'),

        home_lat: getEntityFieldName('home_lat'),
        home_lng: getEntityFieldName('home_lng'),
        state: getEntityFieldName('state'),
        country: getEntityFieldName('country'),

        address1: 'address1',
        address2: 'address2',

        mobile_app: getEntityFieldName('mobile_app'),
        device_id: getEntityFieldName('device_id'),
        device_info: getEntityFieldName('device_info'),
        device_type: getEntityFieldName('device_type'),
        device_app_version: getEntityFieldName('device_app_version'),
        password: 'password',
        confirm_password: 'confirm_password',
    },
    custom: {
        driverHomeLocation: 'driverHomeLocation',
    },
};
