import React from 'react';

const ButtonGroupSwitch = ({buttons, activeKey}) => (
    <div className="btn-group__filter">
        <div className="btn-group" role="group">
            {buttons.map(({label, key, onSelect, isDisabled}) => {
                const isButtonDisabled = key !== activeKey && isDisabled;

                return (
                    <button
                        key={key}
                        type="button"
                        className={`btn btn-default ${key === activeKey ? 'active' : ''} ${
                            isButtonDisabled ? 'disabled' : ''
                        }`}
                        onClick={onSelect}
                        disabled={isButtonDisabled}
                    >
                        {label}
                    </button>
                );
            })}
        </div>
    </div>
);

export default React.memo(ButtonGroupSwitch);
