import React from 'react';

import ClipboardWrapper from 'components/ui/ClipboardWrapper';
import TimeOvering from 'components/ui/TimeOvering';
import Button from 'components/ui/Buttons/Button';

import {createDate} from 'utils/dateTime';

import styles from './styles.module.scss';

interface OwnProps {
    onArrowClick: () => void;
    dateTime?: string; // only UTC-0
    clipBoardText: string;
    tooltipText: string;
    location?: string;
    title?: string;
}

const LocationInfo: React.FC<OwnProps> = (props) => {
    const {tooltipText, onArrowClick, location, dateTime, title, clipBoardText} = props;

    const transformedDate = dateTime ? createDate(dateTime).fullDate : '';

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.info}>
                <div className={styles.location_info}>
                    <Button
                        buttonSize="icon"
                        onClick={onArrowClick}
                        colorTheme="grey-blue"
                        buttonIcon="arrow-down"
                        className="custom-design"
                    />
                    <div className={styles.location}>{location}</div>
                    <div className={styles.clipboard}>
                        <ClipboardWrapper tooltip={tooltipText} clipBoardText={clipBoardText} position="relative" />
                    </div>
                </div>

                <div className={styles.date_info}>
                    <div className={styles.date}>{transformedDate}</div>
                    <div className={styles.time_overing}>
                        <TimeOvering startingDate={dateTime} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationInfo;
