import type {CreateTravelOrderWithCarrierParams, CreateTravelOrderWithCarrierResult, LoadApiGateway} from '../types';

const createTravelOrderWithTruck = async function (
    data: CreateTravelOrderWithCarrierParams,
    deps: {
        loadApiGateway: LoadApiGateway;
    },
): Promise<CreateTravelOrderWithCarrierResult> {
    const {loadNumber, carrierId} = data;
    const {loadApiGateway} = deps;

    const res = await loadApiGateway.createTravelOrderWithCarrier({
        loadNumber,
        carrierId,
    });

    return {
        createdTravelOrderNumber: res.toNumber,
        loadNumber: res.loadNumber,
    };
};

export default createTravelOrderWithTruck;
