import React from 'react';

import {SettlementOwner} from 'core/entities/Settlement/types';

import {CurrencyValue} from 'utils/data/currency';

import CurrencyAmount from 'components/ui/CurrencyAmount';

import {transformDistance} from 'utils/distance';

import SettlementsList from '../SettlementsList';

import styles from './addToNewPayRecord.module.scss';

interface Props {
    settlement?: SettlementOwner;
    isExpanded: boolean;
}

const TotalAmount = ({icon, amount, currency}: {icon: '+' | '-'; amount: number; currency: CurrencyValue}): any => {
    return (
        <div className={styles.total_amount}>
            <CurrencyAmount amount={amount} currency={currency} withStrong={false} /> <span>{icon}</span>
        </div>
    );
};

const AddToNewPayRecord = ({settlement, isExpanded}: Props): JSX.Element | null => {
    if (!settlement) {
        return null;
    }

    const convertedSettlementEmptyMiles = transformDistance(settlement.empty_miles);
    const convertedSettlementLoadedMiles = transformDistance(settlement.loaded_miles);

    return (
        <div className={styles.wrap}>
            {isExpanded && (
                <>
                    <SettlementsList settlements={[settlement]} />
                    <div className={styles.total}>
                        <div className={styles.total__miles}>
                            <div className={styles.total__miles_titles}>
                                total empty {convertedSettlementEmptyMiles.longUnit}
                                <br />
                                total loaded {convertedSettlementLoadedMiles.longUnit}
                            </div>
                            <div className={styles.total__miles_data}>
                                {convertedSettlementEmptyMiles.fromMiles} <br />
                                <strong>{convertedSettlementLoadedMiles.fromMiles}</strong>
                            </div>
                        </div>
                        <div className={styles.total__payment}>
                            <div className={styles.block__titles}>
                                gross pay
                                <br />
                                reimbursement
                                <br />
                                deductions
                            </div>
                            <div className={styles.block__data}>
                                <div className={styles.block__data_payment}>
                                    <CurrencyAmount
                                        amount={settlement?.total_pay as number}
                                        currency={settlement?.currency as CurrencyValue}
                                        withStrong={false}
                                    />
                                </div>
                                <TotalAmount key="reimbursements" icon="+" amount={0} currency={settlement?.currency} />
                                <TotalAmount key="deductions" icon="-" amount={0} currency={settlement?.currency} />
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className={styles.footer}>
                <div />
                <div className={styles.footer__total}>
                    <span className="sep mr-3">
                        <span className="dot" />
                        <span className="dot" />
                        <span className="dot" />
                    </span>
                    <div className={styles.footer__total_title}>Total Pay</div>
                    <div className={styles.footer__total_data}>
                        <CurrencyAmount
                            amount={settlement?.total_pay as number}
                            currency={settlement?.currency as CurrencyValue}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddToNewPayRecord;
