import React from 'react';
import {ToggleButtonGroup, ToggleButton} from 'react-bootstrap';

import styles from './styles.module.scss';

interface OwnProps {
    unitOptions: {value: string; name: string}[];
    unit?: string;
    setUnit: (unit) => void;
    clearFields: () => void;
    disabled?: boolean;
}

const UnitOfMeasurementSkipField: React.FC<OwnProps> = (props) => {
    const {unitOptions, unit, setUnit, disabled, clearFields} = props;

    const onChange = (value) => {
        setUnit(value);
        clearFields();
    };

    return (
        <div className={styles.group}>
            <ToggleButtonGroup type="radio" name="radio" value={unit || unitOptions[0].value} onChange={onChange}>
                {unitOptions.map((radio, idx) => (
                    <ToggleButton key={idx} variant="outline-dark" value={radio.value} disabled={disabled}>
                        {radio.name}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </div>
    );
};

export default UnitOfMeasurementSkipField;
