import {SettlementCarrier, SettlementOwner} from 'core/entities/Settlement/types';
import {isSettlementOwner, getSettlementMiles} from 'core/entities/Settlement/settlement';

type SettlementData = SettlementOwner | SettlementCarrier;

type Miles = {empty: number; loaded: number};

const getMilesFromSettlementOwner = (settlement: SettlementOwner): Miles => {
    return {empty: settlement.empty_miles, loaded: settlement.loaded_miles};
};

const getMilesFromSettlementCarrier = (settlement: SettlementCarrier): Miles => {
    const settlementMiles = getSettlementMiles(settlement);
    return {empty: settlementMiles.empty, loaded: settlementMiles.loaded};
};

export const getMilesFromSettlement = (settlement: SettlementData): Miles => {
    return isSettlementOwner(settlement)
        ? getMilesFromSettlementOwner(settlement)
        : getMilesFromSettlementCarrier(settlement);
};
