// min two characters
// not allow: "only spaces", "spaces at begin and end"
export const MIN_VALID_STRING = /^[^\s][\s\S]{0,255}[^\s]$/;

/* Use for first/last name */
export const NICKNAME = /^[a-zA-Z][a-zA-Z0-9-_.]{2,45}$/;

/* Use for first/last name */
export const NAME = /^[a-zA-Z][a-zA-Z-\s.]{1,99}$/;

export const COMPANY_NAME = /^(['"]|[A-Za-z]){1}([a-zA-Z0-9]|[- @.#&!"'()*+={}[],]){2,160}$/;

export const DIGITS = /^\d{1,100}$/;
export const FLOATING_POINT_NUMBERS = /^-?\d*(\.\d+)?$/;
export const CURRENCY = /^\d+(\.\d{1,2})?$/;

export const ONLY_LETTERS_AND_SPACE = /^[a-zA-Z\s]+$/;
export const ONLY_LETTERS = /[a-zA-Z]/;
export const LETTERS = /^[a-zA-Z]{1,100}$/;
export const LETTERS_AND_SPACE = /^[a-zA-Z ]{1,100}$/;

export const LETTERS_AND_DIGITS_OR_DIGITS = /^[\w\s]+.*[0-9].*$/;
export const LETTERS_AND_DIGITS = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{2,}$/;
export const LETTERS_OR_DIGITS = /^[0-9a-zA-Z]+$/;
export const LETTERS_OR_DIGITS_AND_HYPHEN = /^[a-zA-Z0-9._#-]+$/;
export const LETTERS_OR_DIGITS_AND_SPECIFIC_SYMBOLS = /^[0-9a-zA-Z(),.:?!=/\\#№+-]+$/;
export const LETTERS_AND_SYMBOLS = /^\D+$/;
export const DIGITS_OR_SYMBOLS = /^[\d\-._~:/?#[\]@!$&'"()*+,;=][^a-zA-Z]*$/;
export const ONLY_DIGITS_AND_SPACE = /^\d+(?:\s+\d+)*$/;
export const FACILITY_NAME = /^[^\u0400-\u04FF\u0080-\uFFFF]+$/;

export const COMMODITY = /^[a-zA-Z'-]+([a-zA-Z\s'-]*[a-zA-Z'-]+|)$/;

export const PHONE_EXT = /^\d{2,6}$/;

export const EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const DOMAIN = /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/i;

export const POLICY_NUMBER = /^[a-zA-Z0-9]{1,100}$/;

// New York, NY, 12345, US
export const CITY_LINE = /^[À-źÀ-žА-яa-zA-Z0-9'/.\s-]{1,100},\s?[a-zA-Z.\s-]{2,},\s?[\w\s]{2,}(?:[-\s]\d{4})?,\s?[\w\s]{2,}$/;

// Campo Alonso Borquez (Bloque 1005), SO, 85206, MX
export const CITY_LINE_WITH_BRACKETS = /^[À-źÀ-žА-яa-zA-Z0-9'/.)(\s-]{1,100},\s?[a-zA-Z.\s-]{2,},\s?[\w\s-]{2,}(?:[-\s]\d{4})?,\s?[\w\s]{2,}$/;

export const CITY_WITH_DIGIT = /^[À-źÀ-žА-яa-zA-Z0-9'/.]+(?:[\s-][À-źÀ-žА-яa-zA-Z0-9'/.]+)*$/;

export const CITY = /^[À-źÀ-žА-яa-zA-Z'/.]+(?:[\s-][À-źÀ-žА-яa-zA-Z'/.]+)*$/;
/* RegExp for phone and fax with/without extension
    like:  "(704)869-2830"
*/
export const PHONE = /^\d\(\d{3}\)\d{3}-\d{4}(?:\sext\s\d{3,5})?$/;

// 1 7048692830
export const PHONE_WITH_EXT = /^\d+\s\d{10}$/;
// 7048692830
export const PHONE_WITHOUT_EXT = /^\d{10}$/;

// min two characters
// not allow: "only spaces", "spaces at begin and end"
export const ADDRESS = /^[^\s][\s\S]{0,255}[^\s]$/;

/* RegExp for MC and USDOT numbers
 format: "DLDDLLDL"
*  like: "9Q34RT8Z"
* */
export const MC_NUMBER = /^\d[A-Za-z]\d\d[A-Za-z]{2}\d[A-Za-z]$/;

export const US_DOT_NUMBER = /^\d[A-Za-z]\d\d[A-Za-z]{2}\d[A-Za-z]$/;
// eslint-disable-next-line
export const DATE = /((0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d)|(((19|20)\d\d)[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01]))/;

export const TIME = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\sUTC(?:[+-]\d{1,2})?(?::30)?$/;

export const YEAR = /^19\d{2}$|^20\d{2}$/;

export const DRIVER_LICENSE = /^[A-Za-z0-9-]*$/;

export const ZIP = /^[\w\s]*[A-Za-z0-9]{1,45}$/;

export const URL = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;

export const DIGITS_OR_LETTERS = new RegExp(/[A-Za-z]|[0-9]/g);

export const ONLY_NUMBERS = /^\d+$/;

export const TWO_DECIMAL_DIGITS_AFTER_COMMA = /^(?!0+\d)\d+((?:[.]\d{1,2})?|(?:[.])?)$/gm;
export const THREE_DECIMAL_DIGITS_AFTER_COMMA = /^(?!0+\d)\d+((?:[.]\d{1,3})?|(?:[.])?)$/gm;

export const INTEGER_WITH_HYPHEN = /(-?\d+(?:[.,]\d+)*)/;
