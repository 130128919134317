import Pagination from 'types/Pagination';

import getNextSorting from 'utils/getNextSorting';
import {SearchFormParams, Sorting, ListType} from '../types';
import * as types from '../actionTypes/index';

export type ListState = {
    searchParams: SearchFormParams;
    sorting: Sorting;
    pagination: Pick<Pagination, 'currentPage' | 'perPage'>;
    listType: ListType;
};

const defaultState: ListState = {
    searchParams: {} as SearchFormParams,
    sorting: undefined,
    pagination: {
        currentPage: 1,
        perPage: 50,
    },
    listType: 'invoices',
};

const listInvoicesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case types.LIST_INVOICES_RECEIVED: {
            const {pagination} = action.payload;
            return {
                ...state,
                pagination,
            };
        }

        case types.LIST_BATCHES_RECEIVED: {
            const {pagination} = action.payload;
            return {
                ...state,
                pagination,
            };
        }

        case types.LIST_SORTING_CHANGED: {
            const currentSorting = state.sorting;
            const nextSorting = getNextSorting(currentSorting);
            return {
                ...state,
                sorting: nextSorting,
            };
        }

        case types.LIST_PAGINATION_CHANGED: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload,
                },
            };
        }

        case types.LIST_SEARCH_PARAMS_CHANGED: {
            return {
                ...state,
                searchParams: action.payload,
                pagination: {...state.pagination, currentPage: 1},
            };
        }

        case types.LIST_TYPE_CHANGED: {
            return {
                ...state,
                listType: action.payload,
                searchParams: defaultState.searchParams,
                sorting: defaultState.sorting,
                pagination: defaultState.pagination,
            };
        }

        default:
            return state;
    }
};

export default listInvoicesReducer;
