import React from 'react';

import Rate from './components/Rate';
import Quote from './components/Quote';
import Wrapper from '../common/Wrapper';

const RateInfo: React.FC = () => {
    return (
        <Wrapper>
            <div className="container px-0 my-3">
                <div className="row">
                    <div className="col">
                        <Rate />
                    </div>
                    <div className="col">
                        <Quote />
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default React.memo(RateInfo);
