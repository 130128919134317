import React from 'react';

import Truck from 'core/entities/Truck/types';

import TruckDistances from 'pages/LoadBoard/components/common/TruckDistances';

import styles from './styles.module.scss';

const OriginAway: React.FC<{truck: Truck}> = (props) => {
    const {truck} = props;
    return (
        <td className={styles.wrapper}>
            <TruckDistances truck={truck} />
        </td>
    );
};

export default OriginAway;
