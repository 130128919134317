import React from 'react';
import {Modal} from 'react-bootstrap';
import Button from 'components/ui/Buttons/Button';

type OwnProps = {
    data: {
        quoteNumber: number;
    };
    handlers: {
        goToGeneralList(): void;
        goToArchivedList(): void;
    };
};
const SuccessfullyArchiveQuote: React.FC<OwnProps> = (props) => {
    const {data, handlers} = props;
    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Successfully moved to Archive</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row align-items-center">
                    <div className="col-1">
                        <i className="fa fa-check feedback-icon-success" />
                    </div>
                    <div className="col-11">
                        <p>
                            The Quote <strong>#{data.quoteNumber}</strong> has been moved to archive successfully
                        </p>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <div />
                <div className="d-flex">
                    <Button
                        onClick={handlers.goToArchivedList}
                        className="mr10"
                        colorTheme="grey"
                        buttonSize="small"
                        defaultFocus={true}
                    >
                        ARCHIVED LIST
                    </Button>

                    <Button
                        onClick={handlers.goToGeneralList}
                        className="mr10"
                        colorTheme="green"
                        buttonSize="small"
                        defaultFocus={true}
                    >
                        AUCTION LIST
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default SuccessfullyArchiveQuote;
