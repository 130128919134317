import React from 'react';

import {DispatcherLink} from 'components/ui/Links';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';

import {createDate} from 'utils/dateTime';

import styles from './infoBlock.module.scss';

const CloseReopenInfo = ({closedBy, closedWhen, isCanBeReopened, reopenTitle, onReopen}) => {
    const {date: convertedDateOfClose} = createDate(closedWhen);

    return (
        <div className={styles.item}>
            <div>
                {closedBy && (
                    <span>
                        Closed by{' '}
                        <strong>
                            <DispatcherLink
                                dispatcher={closedBy}
                                content={getDispatcherName(closedBy)}
                                className="second-link"
                            />
                        </strong>{' '}
                        on {convertedDateOfClose}
                    </span>
                )}
            </div>
            {isCanBeReopened && (
                <div className="open-period" onClick={onReopen}>
                    <span className="second-link">{reopenTitle}</span> <i className="fa fa-unlock-alt" />
                </div>
            )}
        </div>
    );
};

export default CloseReopenInfo;
