import React from 'react';

import Truck from 'core/entities/Truck/types';

import ArchivingWarnWrapper from 'components/ui/modals/ArchivingWarnWrapper';

type OwnProps = {
    data: {truck: Truck};
    handlers: {onCancel(); onConfirm()};
    onClose();
};

const ArchivingWarn = (props: OwnProps): JSX.Element => {
    const {
        data: {truck},
        handlers: {onCancel, onConfirm},
        onClose,
    } = props;
    const truckNumber = `truck #${truck.number}`;
    return (
        <ArchivingWarnWrapper title={truckNumber} onClose={onClose} onCancel={onCancel} onConfirm={onConfirm}>
            <p>
                <strong>DO YOU WANT TO ARCHIVE THE TRUCK?</strong>
            </p>
            <p>
                {' '}
                The archived truck profile <strong>#{truck.number}</strong> will disable any actions with it.
            </p>
            <p>If needed, you can still the ability to restore the profile.</p>
        </ArchivingWarnWrapper>
    );
};

export default ArchivingWarn;
