import type {GalleryActionsTypes} from './actionCreators';
import * as types from './actionTypes';

export type GalleryState = {
    isOpen: boolean;
    files: Array<{
        src: string;
        title: string;
    }>;
    currentItemIndex: number;
};

const defaultState: GalleryState = {
    isOpen: false,
    files: [],
    currentItemIndex: 0,
};

const gallery = (state = defaultState, action: GalleryActionsTypes): GalleryState => {
    switch (action.type) {
        case types.OPEN: {
            return {
                ...state,
                isOpen: true,
                files: action.payload.files.map((item) => ({
                    src: item.url,
                    title: item.name,
                })),
                currentItemIndex: action.payload.currentItemIndex || 0,
            };
        }

        case types.CLOSE: {
            return defaultState;
        }

        // no default
    }

    const _exhaustiveCheck: never = action;

    return state;
};

export default gallery;
