import isEmpty from 'lodash/isEmpty';

import {BrokerUpdate, LocationEventData} from 'core/entities/LocationEvents/types';

export const getEventBrokerUpdate = (event: LocationEventData): BrokerUpdate | undefined => event?.brokerUpdate;

export const getEventIDBySendBrokerUpdate = (event: LocationEventData): string => {
    if (isEmpty(event?.groupedEventsByLoad)) {
        return event.eventId;
    }

    const [firstEventID] = event.groupedEventsByLoad.map((item) => item.id);

    return firstEventID;
};
