import React from 'react';
import {Field} from 'redux-form';
import RequiredLabel from 'components/ui/Form/RequiredLabel';
import LocationFields from 'components/ui/Form/LocationFields';

interface OwnProps {
    cityLineFieldName: string;
    cityFieldName: string;
    stateFieldName: string;
    zipFieldName: string;
    countryFieldName: string;
    latitudeFieldName: string;
    longitudeFieldName: string;
    label: string;
    isDisabled?: boolean;
}

const Location: React.FC<OwnProps> = (props) => {
    const {
        cityLineFieldName,
        cityFieldName,
        countryFieldName,
        latitudeFieldName,
        longitudeFieldName,
        zipFieldName,
        stateFieldName,
        label,
        isDisabled,
    } = props;

    return (
        <Field
            isDisabled={isDisabled}
            name={cityLineFieldName}
            component={LocationFields}
            cityFieldName={cityFieldName}
            stateFieldName={stateFieldName}
            zipFieldName={zipFieldName}
            countryFieldName={countryFieldName}
            latitudeFieldName={latitudeFieldName}
            longitudeFieldName={longitudeFieldName}
            label={RequiredLabel(label)}
            placeholder="Enter Location"
            required
        />
    );
};

export default Location;
