import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {FormLabel, FormControl, FormGroup} from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import {WrappedFieldProps} from 'redux-form';

import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';

import {createTime, changeTime, transformTime, DEFAULT_TIME_FORMAT} from 'utils/dateTime';
import {generatePlaceholder} from 'utils/timeInputPlaceholder';
import {generateTimeMask} from 'utils/timeInputMask';

import './style.scss';

type OwnProps = {
    label: string | JSX.Element;
    redesign: boolean;
    format: string;
    value: any;
    onChange: any;
    disabled: boolean;
    type: string;
    placeholder: string;
};

const checkValidation = (meta: WrappedFieldProps['meta']): 'error' | 'success' | null => {
    if (meta.submitFailed && meta.error) {
        return 'error';
    }
    if (meta.dirty && meta.visited && meta.error) {
        return 'error';
    }
    if (!meta.active || meta.pristine) {
        return null;
    }
    return 'success';
};

const TimeInput: React.FC<WrappedFieldProps & Partial<OwnProps>> = (props) => {
    const {input, meta, label, redesign, placeholder, disabled} = props;
    const {value, onChange} = input;

    const transformTimeFormat = transformTime(value || '00:00', DEFAULT_TIME_FORMAT);
    const timeData = createTime(transformTimeFormat);
    const {timeFormat} = timeData;
    const mask = generateTimeMask(timeData.timeFormat);
    const [timeValue, setTimeValue] = useState(timeData.formattedTime);
    const changeMinutesValue = 15;

    const decrease = () => {
        if (!disabled) {
            const decreasedTime = changeTime(transformTimeFormat, {
                operation: 'decrease',
                value: changeMinutesValue,
                valueType: 'minutes',
            });
            onChange(decreasedTime.defaultTime);
        }
    };

    const increase = () => {
        if (!disabled) {
            const increasedTime = changeTime(transformTimeFormat, {
                operation: 'increase',
                value: changeMinutesValue,
                valueType: 'minutes',
            });
            onChange(increasedTime.defaultTime);
        }
    };

    const setValue = () => {
        if (!timeValue) {
            const timeInDefaultFormat = createTime('00:00');
            onChange(timeInDefaultFormat.defaultTime);
            setTimeValue(timeInDefaultFormat.formattedTime);
            return;
        }

        /**
         * Variable <timeWithSkippedUnderline> with <includes> and <replace> methods is necessary for handling some case.
         * We can enter following time's cases, for example:
         * 1. __:__
         * 2. 1_:__
         * 3. 11:__
         * 4. 11:1_
         * Using <fromFormat> method from luxon, we must set correct, strict time's format and time,
         * as a result we replacing all underline by '0'.
         * Reproduce:
         * 1. .fromFormat(1_:__, HH:mm) -> failure;
         * 2. replacing all underline;
         * 3. .fromFormat(10:00, HH:mm) -> successful;
         * */
        const timeWithSkippedUnderline = timeValue?.includes('_') ? timeValue.replace(/_/g, '0') : timeValue;
        const timeInCurrentFormat = createTime(timeWithSkippedUnderline, {fromCurrentFormat: true});
        onChange(timeInCurrentFormat.defaultTime);
        setTimeValue(timeInCurrentFormat.formattedTime);
    };

    const onBlur = () => {
        setValue();
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            setValue();
        }
    };

    const validation = checkValidation(meta);

    useEffect(() => {
        setTimeValue(timeData.formattedTime);
    }, [timeData.formattedTime]);

    return (
        <div className={classNames('input-time-component', {'custom-time-input': redesign})}>
            <FormGroup className={validation === 'error' ? 'has-feedback has-error' : ''}>
                {label && <FormLabel>{label}</FormLabel>}
                <OverlayTriggerCustom inputMeta={meta} placement="bottom">
                    <div className="iw-time_input">
                        <div onClick={decrease} className={classNames('iw-arrow', 'iw-left_minus', {disabled})} />

                        <div onClick={increase} className={classNames('iw-arrow', 'iw-right_plus', {disabled})} />

                        <MaskedInput
                            type={props.type || 'text'}
                            disabled={disabled}
                            className="form-control"
                            mask={mask}
                            value={timeValue}
                            onChange={(e) => setTimeValue(e.target.value)}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            placeholder={placeholder || generatePlaceholder(timeFormat)}
                        />
                    </div>
                </OverlayTriggerCustom>
                <FormControl.Feedback
                    className={validation === 'error' ? 'custom-feedback glyphicon-remove' : 'custom-feedback'}
                />
            </FormGroup>
        </div>
    );
};

export default TimeInput;
