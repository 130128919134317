import pick from 'lodash/pick';

import {PlaceItem} from 'services/mapsApi/expediteApi/types';

import countries from 'utils/data/countries';

import {ExtendedPlaceItem} from './types';

type AddressFields = Array<keyof Pick<PlaceItem['address'], 'city' | 'state' | 'zip' | 'country'>>;

const mergeObjectValues = (object: any, options?: {filterEmptyFields: boolean}): string => {
    const objectValues = Object.values(object);
    const separator = ', ';
    return options?.filterEmptyFields ? objectValues.filter(Boolean).join(separator) : objectValues.join(separator);
};

const getFullAddress = (placeItem: PlaceItem): string => {
    const addressFields: AddressFields = ['city', 'state', 'zip', 'country'];
    const addressData = pick(placeItem.address, addressFields);
    return mergeObjectValues(addressData, {filterEmptyFields: true});
};

const getFullAddressWithUnknownValues = (placeItem: PlaceItem): string => {
    const addressFields: AddressFields = ['city', 'state', 'zip', 'country'];
    const addressData = pick(placeItem.address, addressFields);
    const addressWithUnknownValues = {
        city: addressData.city ? addressData.city : 'unknown city',
        state: addressData.state ? addressData.state : 'unknown state',
        zip: addressData.zip ? addressData.zip : 'unknown zip code',
        country: addressData.country ? addressData.country : 'unknown country',
    };
    return mergeObjectValues(addressWithUnknownValues);
};

const removePlaceAddressUmlauts = (placeItem: PlaceItem): PlaceItem => {
    return {...placeItem, address: {...placeItem.address, city: placeItem.address.city}};
};

export const extendPlacesItemsData = (placeItems: PlaceItem[]): ExtendedPlaceItem[] | undefined => {
    return placeItems.map((place) => {
        const placeWithAddressWithoutUmlauts = removePlaceAddressUmlauts(place);
        return {
            ...placeWithAddressWithoutUmlauts,
            fullAddress: getFullAddress(placeWithAddressWithoutUmlauts),
            fullAddressWithUnknownValues: getFullAddressWithUnknownValues(placeWithAddressWithoutUmlauts),
        };
    });
};

export const liftUpOnlyUSACountry = (places) => {
    const {name, code} = countries.US;
    const onlyUSA = places.filter(({address}) => address.country === name || address.country === code);
    const skipUSA = places.filter(({address}) => address.country !== name && address.country !== code);
    return [...onlyUSA, ...skipUSA];
};
