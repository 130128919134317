import FIELDS from 'core/entities/Facility/constants/fields';
import {getCityLineFromObject} from 'utils/getCityLine';

/**
 *
 * @param {Object} facility
 * @return {string}
 */

export default function composeFacilityCityLine(facility): string {
    if (!facility) {
        return '';
    }
    const {[FIELDS.city]: city, [FIELDS.state]: state, [FIELDS.zip]: zip, [FIELDS.country]: country} = facility;
    return getCityLineFromObject({city, state, zip, country});
}
