import {useEffect} from 'react';
import isNumber from 'lodash/isNumber';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import {convertMmToMiles} from 'pages/LoadBoard/utils/distance';

import getFormFieldNames from '../utils/getFormFieldNames';
import useFormActions from './useFormActions';
import {getRPM} from '../utils/getRMP';

const useRecalculateRpm = (formName: string): void => {
    const {getDriverRateFieldName, getRouteDistanceFieldName} = getFormFieldNames();
    const {setDriverRPM} = useFormActions();
    const routeDistance = useGetReduxFormValue(formName, getRouteDistanceFieldName());
    const rate = useGetReduxFormValue(formName, getDriverRateFieldName());

    const getUpdatedRPM = (): number | undefined => {
        if (!routeDistance || !rate) {
            return 0;
        }

        const distance = convertMmToMiles(routeDistance);
        const rpm = getRPM({distance, rate});

        return Number(rpm);
    };

    useEffect(() => {
        const updatedRpm = getUpdatedRPM();
        if (isNumber(updatedRpm)) {
            setDriverRPM(updatedRpm);
        }
    }, [routeDistance, rate]);
};

export default useRecalculateRpm;
