import React from 'react';
import classNames from 'classnames';

import {AutoCheckCallErrors, AutoCheckCallEvent} from 'core/entities/TravelOrder/types/AutoCheckCallSettings';

import {transformDistance} from 'utils/distance';

import TooltipBootstrap from 'components/ui/TooltipBootstrap';

import styles from '../../styles.module.scss';

type OwnProps = {
    distanceDiffer: AutoCheckCallEvent['milesDiffer'];
    distance: number;
    error: AutoCheckCallErrors['distance'];
};

const DistanceDone: React.FC<OwnProps> = ({distanceDiffer, distance, error}) => {
    const done = transformDistance(distance);
    const differ = transformDistance(distanceDiffer);

    const distanceText = `${done.fromMiles} ${done.shortUnit}`;
    const distanceDifferText = `${Math.abs(differ.fromMiles)} ${differ.shortUnit}`;

    if (differ.fromMiles === 0) {
        return <span>{distanceText}</span>;
    }

    const mark = differ.fromMiles > 0 ? '-' : '+';

    const className = classNames({[styles.bgPink]: error});

    return (
        <span>
            <span>{distanceText}</span>
            <span> | </span>
            <TooltipBootstrap tooltipContent={`${differ.longUnit} differ`}>
                <strong className={className}>
                    {mark} {distanceDifferText}
                </strong>
            </TooltipBootstrap>
        </span>
    );
};

export default DistanceDone;
