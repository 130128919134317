import React from 'react';
import {Field} from 'redux-form';

import Select from 'components/ui/Form/Select';

import {getPreferredLoadsOptions} from 'components/common/Truck/TruckPreferredLoads/utils';

interface OwnProps {
    name: string;
}

const TruckPreferredLoadsSelect: React.FC<OwnProps> = (props) => {
    const {name} = props;

    const truckPreferredLoadItems = getPreferredLoadsOptions();

    return (
        <Field
            name={name}
            label="Preferred Load"
            component={Select}
            data={truckPreferredLoadItems}
            placeholder="choose truck preferred Load"
            isClearable={false}
        />
    );
};

export default TruckPreferredLoadsSelect;
