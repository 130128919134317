import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';

import {AppState} from 'store';
import {getCurrentTimeZone} from 'store/reducers/appSettings/selectors';

import {getCurrentDate} from 'utils/dateTime';
import {getTypeFieldNameFactory} from 'utils/typeScript';

import TimeDataPicker from 'components/common/TravelOrder/TimeDataPicker';
import Button from 'components/ui/Buttons/Button';
import LocationFields from 'components/ui/Form/LocationFields';
import RequiredLabel from 'components/ui/Form/RequiredLabel';

import {AddTruckLocationFormValues} from '../types';
import validate from '../validation';

import styles from '../addTruckLocation.module.scss';

type OwnProps = {
    onClose();
};

type PublicProps = {
    onSubmit(fields: any): void;
};

type StateProps = {
    initialValues: AddTruckLocationFormValues;
};

const ADD_TRUCK_LOCATION_FORM_NAME = 'add-truck-location-form';
const getName = getTypeFieldNameFactory<AddTruckLocationFormValues>();

const AddTruckLocationForm: React.FC<InjectedFormProps<{}, OwnProps> & OwnProps> = (props) => {
    const {form, handleSubmit, pristine, submitting} = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.warning}>
                <i className="fa fa-exclamation-triangle" />
                Truck&apos;s location to record to the &quot;vehicle attached&quot; event was not found.
                <br />
                Please type it below.
            </div>

            <div className={styles.main}>
                <Field
                    longitudeFieldName={`${getName('location')}.longitude`}
                    latitudeFieldName={`${getName('location')}.latitude`}
                    countryFieldName={`${getName('location')}.country`}
                    stateFieldName={`${getName('location')}.state`}
                    cityFieldName={`${getName('location')}.city`}
                    zipFieldName={`${getName('location')}.zip`}
                    name={`${getName('location')}.cityLine`}
                    placeholder="Enter truck location city line"
                    label={RequiredLabel('Truck Location')}
                    component={LocationFields}
                />

                <TimeDataPicker form={form} showReset={true} timeZoneDisabled={true} />
            </div>

            <div className={styles.bottom}>
                <Button onClick={props.onClose} buttonSize="small">
                    Cancel
                </Button>

                <Button type="submit" colorTheme="red" buttonSize="small" disabled={pristine || submitting}>
                    Save
                </Button>
            </div>
        </form>
    );
};

function mapStateToProps(state: AppState): StateProps {
    const currentDateTime = getCurrentDate();
    const timeZone = getCurrentTimeZone(state);

    return {
        initialValues: {
            date: currentDateTime.originalDate,
            time: currentDateTime.originalTime,
            timeZone,
        },
    };
}

export default compose<React.ComponentType<OwnProps & PublicProps>>(
    connect(mapStateToProps),
    reduxForm<AddTruckLocationFormValues, OwnProps & StateProps>({form: ADD_TRUCK_LOCATION_FORM_NAME, validate}),
)(AddTruckLocationForm);
