import React from 'react';

import {Quote} from 'core/entities/Quote/types';
import {getQuoteDestinationStop, getQuoteOriginStop} from 'core/entities/Quote/modules/quoteStop';
import {getQuoteDistance} from 'core/entities/Quote/modules/quoteLoad';
import {getCurrency, getRate} from 'core/entities/Quote/modules/quoteDriverPayment';

import {getStopCityLine, getStopArrivalDate} from 'pages/LoadBoard/utils';

import LoadIcon from './components/LoadIcon';
import LoadStop from './components/LoadStop';
import LoadDistance from './components/LoadDistance';
import LoadRate from './components/LoadRate';
import styles from './styles.module.scss';

type OwnProps = {
    quote: Quote;
};

const OriginDestination: React.FC<OwnProps> = (props) => {
    const {quote} = props;

    const originStop = getQuoteOriginStop(quote);
    const destinationStop = getQuoteDestinationStop(quote);

    const originStopCityLine = getStopCityLine(originStop);
    const originStopArrivalDate = getStopArrivalDate(originStop);

    const destinationStopCityLine = getStopCityLine(destinationStop);
    const destinationStopArrivalDate = getStopArrivalDate(destinationStop);

    const quoteDistance = getQuoteDistance(quote);
    const rate = getRate(quote);
    const currency = getCurrency(quote);

    return (
        <div className="row">
            <div className="col-1">
                <LoadIcon />
            </div>

            <div className="col-4">
                <LoadStop cityLine={originStopCityLine} arrivalDate={originStopArrivalDate}>
                    <span className={styles.arrowDown}>
                        <i className="fa fa-level-down" />
                    </span>
                </LoadStop>
            </div>

            <div className="col-2">
                <LoadDistance distance={quoteDistance} />
            </div>

            <div className="col-3">
                <LoadStop cityLine={destinationStopCityLine} arrivalDate={destinationStopArrivalDate}>
                    <span className={styles.arrowUp}>
                        <i className="fa fa-level-up" />
                    </span>
                </LoadStop>
            </div>

            <div className="col-2">
                <LoadRate rate={rate} currency={currency} />
            </div>
        </div>
    );
};

export default OriginDestination;
