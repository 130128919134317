import React from 'react';
import classNames from 'classnames';

import * as carrierModules from 'core/entities/Carrier/modules';
import Carrier from 'core/entities/Carrier/types';

import useCurrentRegion from 'hooks/useCurrentRegion';

import Button from 'components/ui/Buttons/Button';
import {CarrierLink} from 'components/ui/Links';

import {phoneNumberWithBraces} from 'utils';

import useAttachState from 'pages/Loads/components/common/modals/AttachModal/hooks/useAttachState';
import useModalActions from 'pages/Loads/hooks/useModalActions';

import CarrierItemNotApprovedBadge from './components/CarrierItemNotApprovedBadge';
import CarrierItemPhysicalAddress from './components/CarrierItemPhysicalAddress';
import CarrierItemGlobalBadge from './components/CarrierItemGlobalBadge';
import CarrierItemRegNumber from './components/CarrierItemRegNumber';

import styles from './styles.module.scss';

type OwnProps = {
    carrier: Carrier;
};

const CarrierItem: React.FC<OwnProps> = (props) => {
    const {carrier} = props;

    const {createTravelOrder} = useModalActions();
    const {isNorthAmerica} = useCurrentRegion();
    const {loadToAttach} = useAttachState();

    if (!loadToAttach) {
        return null;
    }

    const carrierMotorNumber = carrierModules.getCarrierMotorNumber(carrier);
    const carrierPhoneNumber = carrierModules.getCarrierPhoneNumber(carrier);
    const isCarrierGlobal = carrierModules.checkIsCarrierGlobal(carrier);
    const carrierEmail = carrierModules.getCarrierEmail(carrier);
    const carrierName = carrierModules.getCarrierName(carrier);

    const handleAttachLoadToCarrier = () => {
        createTravelOrder({load: loadToAttach, carrier});
    };

    return (
        <div className={classNames(styles.wrap, {[styles.global]: isCarrierGlobal})}>
            <div className={styles.block}>
                <CarrierItemGlobalBadge carrier={carrier} />

                <CarrierLink carrier={carrier} content={carrierName} className="second-link" />

                <CarrierItemNotApprovedBadge carrier={carrier} />

                <div className="mt5" />

                <div>{phoneNumberWithBraces(carrierPhoneNumber)}</div>

                <div className="text-break">{carrierEmail}</div>

                {isNorthAmerica ? <div>MC#{carrierMotorNumber}</div> : <CarrierItemRegNumber carrier={carrier} />}
            </div>

            <CarrierItemPhysicalAddress carrier={carrier} />

            <Button className={styles.button} onClick={handleAttachLoadToCarrier}>
                Attach carrier
            </Button>
        </div>
    );
};

export default CarrierItem;
