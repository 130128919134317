const certificates = {
    hazmat: {
        title: 'HAZMAT',
        text: 'Hazmat',
        value: 'hazmat_certificate',
    },
    tsa: {
        title: 'TSA',
        value: 'tsa_certificate',
    },
    twic: {
        title: 'TWIC',
        value: 'twic_certificate',
    },
    tanker_endorsement: {
        title: 'Tanker Endorsement',
        value: 'tanker_endorsement',
    },
    adr: {
        title: 'ADR',
        value: 'adr_certificate',
    },
    mi_log: {
        title: 'Mi LoG',
        value: 'mi_log_certificate',
    },
    loi_macron: {
        title: 'Loi Macron',
        value: 'loi_macron_certificate',
    },
};

export default certificates;
