import {Weight} from 'core/entities/AppSettings/types';

const WEIGHT_UNITS = {
    LBS: 'lbs',
    KG: 'kg',
    TON: 'ton',
} as const;

export type WeightUnits = Record<keyof typeof WEIGHT_UNITS, Weight>;

export default WEIGHT_UNITS;
