import head from 'lodash/head';
import last from 'lodash/last';

import {Quote, LoadStop, Address} from '../../types';

export const getOriginAddress = (quote: Quote): Address => quote.load.origin.address;

export const getDestinationAddress = (quote: Quote): Address => quote.load.destination.address;

export const getOriginStop = (quote: Quote): LoadStop => quote.load.origin;

export const getDestantionStop = (quote: Quote): LoadStop => quote.load.destination;

export const getQuoteOriginStop = (quote: Quote): LoadStop => {
    const {stops} = quote.load;
    const originStop = head(stops) as LoadStop;

    return originStop;
};

export const getQuoteDestinationStop = (quote: Quote): LoadStop => {
    const {stops} = quote.load;
    const destinationStop = last(stops) as LoadStop;

    return destinationStop;
};

export const getPickupsCount = (stops: LoadStop[]): number => {
    const pickups = stops.filter((s) => s.type === 'PICKUP');
    return pickups.length;
};

export const getDeliveriesCount = (stops: LoadStop[]): number => {
    const deliveries = stops.filter((s) => s.type === 'DELIVERY');
    return deliveries.length;
};

export const isPickupStop = (stop: LoadStop): boolean => {
    return stop.type === 'PICKUP';
};

export const isDeliveryStop = (stop: LoadStop): boolean => {
    return stop.type === 'DELIVERY';
};
