import {Quote, TruckDimensions} from '../../types';

export const getTruckTypes = (quote: Quote): string[] => quote.truck.types;
export const getTruckEquipment = (quote: Quote): string[] => quote.truck.equipment;
export const getTruckCertificates = (quote: Quote): string[] => quote.truck.driverCertificates;
export const getTruckStatus = (quote: Quote): string[] => quote.truck.statuses;
export const getTruckCitizenships = (quote: Quote): string[] => quote.truck.driverCitizenship;
export const getTruckPayloadValue = (quote: Quote): number | undefined => quote.truck?.payload?.value;
export const getTruckPayloadUnit = (quote: Quote): string | undefined => quote.truck?.payload?.unit;
export const getTruckDimensions = (quote: Quote): TruckDimensions | undefined => quote.truck.dimensions;
export const getTruckDimensionsUnit = (quote: Quote): string | undefined => quote.truck.dimensions?.unit;
export const getTruckDimensionsWidth = (quote: Quote): number | undefined => quote.truck.dimensions?.width;
export const getTruckDimensionsHeight = (quote: Quote): number | undefined => quote.truck.dimensions?.height;
export const getTruckDimensionsLength = (quote: Quote): number | undefined => quote.truck.dimensions?.length;
export const getTruckCrossBorder = (quote: Quote): string[] => quote.truck.driverCrossBorder;
export const getTruckSigns = (quote: Quote): boolean => quote.truck.withSigns;
export const getTruckTeam = (quote: Quote): boolean => quote.truck.withDriversTeam;
