import React from 'react';
import classNames from 'classnames';

import Tooltip from 'components/ui/Tooltip';

import useChatToggle from './hooks/useChatToggle';

import styles from './styles.dispatchland.module.scss';

const TOOLTIP_CONTENT = 'Chat is disconnected';

const ChatToggle: React.FC = () => {
    const {toggleChat, unreadMessagesCount, isChatOpened, chatClientSocketId} = useChatToggle();

    const buttonClassName = classNames(styles.btn, {
        [styles.btn__disconnected]: !chatClientSocketId,
        [styles.btn__connected]: chatClientSocketId,
        [styles.opened]: isChatOpened,
    });

    const Wrapper = ({children}) => {
        if (!chatClientSocketId) {
            return (
                <Tooltip tooltipContent={TOOLTIP_CONTENT} className={styles.tooltip}>
                    {children}
                </Tooltip>
            );
        }

        return <>{children}</>;
    };

    const connectedEntry = (
        <>
            {unreadMessagesCount > 0 ? <span className={styles.counter}>{unreadMessagesCount}</span> : null}
            <i className="fa fa-comment" />
        </>
    );

    const disconnectedEntry = (
        <>
            <i className={classNames('fa fa-times-circle', styles.disconnected_icon)} />
            <i className="fa fa-comment" aria-hidden="true" />
        </>
    );

    return (
        <Wrapper>
            <button onClick={toggleChat} className={buttonClassName}>
                {!chatClientSocketId ? disconnectedEntry : connectedEntry}
            </button>
        </Wrapper>
    );
};

export default ChatToggle;
