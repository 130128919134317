import React from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field, formValueSelector, InjectedFormProps} from 'redux-form';

import {Batch} from 'core/entities/Settlement/types';

import Button from 'components/ui/Buttons/Button/index';

import CloseReopenInfo from 'pages/Settlements/components/common/CloseReopenInfo';
import SelectEmailSender from 'pages/Settlements/components/common/SelectEmailSender';
import EmailNote from 'pages/Settlements/components/common/EmailNote';
import SendEmailButton from 'pages/Settlements/components/common/SendEmailButton';

import WithoutSettlementsMessage from '../WithoutSettlementsMessage';
import SettlementsList from '../SettlementsList';

import FIELDS from './fields';
import * as selectors from './selectors';
import styles from './sendBatchEmail.module.scss';

const FORM_NAME = 'edit-batch-form';

interface OwnProps {
    batch: Batch;
    isBatchCanBeReopened: boolean;
    onReopen(p: Batch);
    onClose();
}

interface StateProps {
    isEmailNoteChanged: boolean;
    isNeedSendEmail: boolean;
    // eslint-disable-next-line react/no-unused-prop-types
    initialValues: any;
}

type AllFormProps = OwnProps & StateProps;

const SendBatchEmail = (props: AllFormProps & InjectedFormProps<{}, AllFormProps>): JSX.Element => {
    const {handleSubmit, batch, onClose, onReopen, isEmailNoteChanged, isNeedSendEmail, isBatchCanBeReopened} = props;

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className="custom-scroll">
                <CloseReopenInfo
                    closedBy={batch.closed_by}
                    closedWhen={batch.closed_at}
                    isCanBeReopened={isBatchCanBeReopened}
                    reopenTitle="open batch"
                    onReopen={onReopen}
                />

                {batch.settlements_count === 0 && <WithoutSettlementsMessage />}

                <div className="modal-body__main">
                    <div className="check-send-email">
                        <div>Check Pay Record below in current batch:</div>

                        <Field name={FIELDS.emailSender} component={SelectEmailSender} />
                    </div>

                    <SettlementsList
                        batch={batch}
                        emailSentDate={batch.email_sent_at}
                        isEmailSent={batch.is_email_sent}
                        isClosing={false}
                    />
                </div>

                <Field
                    name={FIELDS.emailNote}
                    title="Batch Note"
                    component={EmailNote}
                    isEmailSent={batch.is_email_sent}
                />
            </div>
            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <SendEmailButton isEmailNoteChanged={isEmailNoteChanged} isNeedSendEmail={isNeedSendEmail} />
                </div>
            </div>
        </form>
    );
};

const ReduxForm = reduxForm<{}, AllFormProps>({
    form: FORM_NAME,
})(SendBatchEmail);

const formSelector = formValueSelector(FORM_NAME);

export default connect(
    (state, ownProps: OwnProps): StateProps => {
        const batchCurrentEmailNote = ownProps.batch && ownProps.batch.note_to_email;
        const editedNote = formSelector(state, FIELDS.emailNote);
        return {
            isEmailNoteChanged: Boolean(editedNote && batchCurrentEmailNote !== editedNote),
            isNeedSendEmail: Boolean(formSelector(state, FIELDS.sendEmail)),
            initialValues: selectors.getSendBatchEmailInitialData(state, ownProps),
        };
    },
)(ReduxForm);
