import {FormErrors} from 'redux-form';

import FormData from './types/FormData';

function validate(fields: FormData): FormErrors<FormData> {
    const errors: FormErrors<FormData> = {};

    if (!fields.shippers) {
        errors.shippers = 'Please select facility.';
    }
    if (fields.is_pick_up && !fields.pieces) {
        errors.pieces = 'Please type pieces.';
    }
    if (fields.is_pick_up && !fields.weight) {
        errors.weight = 'Please type weight.';
    }
    if (!fields.is_pick_up && !fields.pod_signed_by) {
        errors.pod_signed_by = 'Please type signed by.';
    }

    return errors;
}

export default validate;
