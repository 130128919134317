import {getRemainingTimeTo} from 'utils/dateTime';

type CheckDriverLicenseExpirationReturn = {
    severity: 'error' | 'warning' | null;
    isInWarningThreshold: boolean;
    isInErrorThreshold: boolean;
    daysToExpiration: number;
    isExpired: boolean;
};

export const checkDriverLicenseExpiration = (expirationDate?: string): CheckDriverLicenseExpirationReturn => {
    const defaultResult: CheckDriverLicenseExpirationReturn = {
        isInWarningThreshold: true,
        isInErrorThreshold: true,
        daysToExpiration: 0,
        severity: 'error',
        isExpired: true,
    };

    if (!expirationDate) {
        return defaultResult;
    }

    const {isExpired = false, days} = getRemainingTimeTo(expirationDate);

    const expirationErrorStartThreshold = 0;
    const expirationWarningThreshold = 5;
    const expirationErrorThreshold = 1;

    const isInErrorThreshold = days > expirationErrorStartThreshold && days < expirationErrorThreshold;
    const isInWarningThreshold = days > expirationErrorThreshold && days < expirationWarningThreshold;

    const severity = ((isExpired || isInErrorThreshold) && 'error') || (isInWarningThreshold && 'warning') || null;

    return {
        daysToExpiration: Math.ceil(days || 0),
        isInWarningThreshold,
        isInErrorThreshold,
        isExpired,
        severity,
    };
};
