import * as types from '../actionTypes';
import * as tabs from '../constants/tabs';

const defaultState = {
    current: null,
    activeTab: tabs.GENERAL.section,
    showResetFormModal: false,
};

export default function currentReducer(state = defaultState, action) {
    switch (action.type) {
        case types.DISPATCHER_RECEIVED: {
            return {
                ...state,
                current: action.payload,
            };
        }

        case types.DISPATCHER_ARCHIVED: {
            const {dispatcher} = action.payload;
            if (!state.current) {
                return state;
            }
            return {
                ...state,
                current: {...state.current, is_deleted: dispatcher.is_deleted},
            };
        }

        case types.FORM_TAB_CHANGED: {
            return {
                ...state,
                activeTab: action.payload.activeTab,
            };
        }

        case types.TOGGLE_RESET_FORM_MODAL: {
            return {
                ...state,
                showResetFormModal: !state.showResetFormModal,
            };
        }

        case types.CLEAR_DISPATCHER_STATE:
            return defaultState;

        default:
            return state;
    }
}
