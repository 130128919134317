import React from 'react';
import {Field} from 'redux-form';

import {MergeCustomersFormValues} from 'pages/Customers/types/formTypes';

import RequiredLabel from 'components/ui/Form/RequiredLabel';
import TextInput from 'components/ui/Form/TextInput';

import {getTypeFieldNameFactory} from 'utils/typeScript';
import {removeNonDigitChars} from 'utils';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<MergeCustomersFormValues>();

type OwnProps = {
    isDisabled: boolean;
};

const SetCustomerFields: React.FC<OwnProps> = (props) => {
    const {isDisabled} = props;

    return (
        <>
            <div className={styles.wrap}>
                <p>Set ID of the Customer that is being attached:</p>

                <div className="ml20" />

                <Field
                    name={getName('sourceID')}
                    type="text"
                    maxLength={5}
                    component={TextInput}
                    normalize={removeNonDigitChars}
                    label={RequiredLabel('Customer ID')}
                    placeholder="enter customer ID"
                    disabled={isDisabled}
                />
            </div>

            <div className="mt5" />

            <div className={styles.wrap}>
                <p>Set ID of the main Customer to attach to it:</p>

                <div className="ml20" />

                <Field
                    name={getName('destinationID')}
                    type="text"
                    maxLength={5}
                    component={TextInput}
                    normalize={removeNonDigitChars}
                    label={RequiredLabel('Customer ID')}
                    placeholder="enter customer ID"
                    disabled={isDisabled}
                />
            </div>
        </>
    );
};

export default SetCustomerFields;
