import {Coordinates} from 'services/mapsApi/types';
import {LoadStop} from 'pages/LoadBoard/types';

export const getCoordinatesFromStops = (stops: LoadStop[]): Coordinates[] => {
    const stopCoordinates = stops.reduce((coords: Coordinates[], {coordinates}) => {
        if (coordinates) {
            const renamedCoordinate: Coordinates = {
                lat: Number(coordinates.latitude),
                lng: Number(coordinates.longitude),
            };
            return [...coords, renamedCoordinate];
        }
        return coords;
    }, []);

    return stopCoordinates;
};
