import {
    TRUCK_STATUS_AVAILABLE,
    TRUCK_STATUS_NOT_AVAILABLE,
    TRUCK_STATUS_ON_HOLD,
    TRUCK_STATUS_ON_ROUTE,
    TRUCK_STATUS_ON_VACATION,
    TRUCK_STATUS_RETIRED,
    TRUCK_STATUS_WAITING_FOR_APPROVAL,
    TRUCK_STATUS_WILL_BE_AVAILABLE_AT,
} from 'core/entities/Truck/constants/truckStatuses';

import certificates from 'utils/data/certificates';
import countries from 'utils/data/countries';

export const TRUCK_LIST_FILTER_FORM_NAME = 'truck_filter_form';

export const APP_UPDATE = {label: 'mobile store', value: 'app'};
export const EMAIL_UPDATE = {label: 'email', value: 'email'};
export const TEXT_MESSAGE_UPDATE = {label: 'text messages', value: 'sms'};

export const ATTACHMENT_TYPES = {
    photos: 'photos',
    insuranceFile: 'insurance_file',
    generalFiles: 'general_files',
    otherFiles: 'other_files',
};

export const weightInputMask = [
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
];

export const GROUPED_TRUCK_STATUSES = [
    {
        label: 'Active',
        items: [
            {label: TRUCK_STATUS_AVAILABLE, value: TRUCK_STATUS_AVAILABLE},
            {label: TRUCK_STATUS_NOT_AVAILABLE, value: TRUCK_STATUS_NOT_AVAILABLE},
            {label: TRUCK_STATUS_WILL_BE_AVAILABLE_AT, value: TRUCK_STATUS_WILL_BE_AVAILABLE_AT},
            {label: TRUCK_STATUS_ON_ROUTE, value: TRUCK_STATUS_ON_ROUTE},
        ],
    },
    {
        label: 'Not active',
        items: [
            {label: TRUCK_STATUS_ON_HOLD, value: TRUCK_STATUS_ON_HOLD},
            {label: TRUCK_STATUS_ON_VACATION, value: TRUCK_STATUS_ON_VACATION},
            {label: TRUCK_STATUS_WAITING_FOR_APPROVAL, value: TRUCK_STATUS_WAITING_FOR_APPROVAL},
            {label: TRUCK_STATUS_RETIRED, value: TRUCK_STATUS_RETIRED},
        ],
    },
];

export const certificatesItemsNA = [
    {label: certificates.hazmat.text, value: certificates.hazmat.value},
    {label: certificates.tsa.title, value: certificates.tsa.value},
    {label: certificates.twic.title, value: certificates.twic.value},
    {label: certificates.tanker_endorsement.title, value: certificates.tanker_endorsement.value},
];

export const certificatesItemsEU = [
    {label: certificates.adr.title, value: certificates.adr.value},
    {label: certificates.loi_macron.title, value: certificates.loi_macron.value},
    {label: certificates.mi_log.title, value: certificates.mi_log.value},
];

export const crossBorderNA = [
    {label: countries.CA.name, value: countries.CA.code},
    {label: countries.MX.name, value: countries.MX.code},
];

export const crossBorderEU = [
    {label: countries.GB.name, value: countries.GB.code},
    {label: countries.CH.name, value: countries.CH.code},
];
