import React from 'react';
import {Modal} from 'react-bootstrap';

import PayCorrection from 'core/entities/Settlement/types/PayCorrection';
import {checkIsDeduction} from 'core/entities/Settlement/payCorrections';
import Button from 'components/ui/Buttons/Button/index';
import CurrencyAmount from 'components/ui/CurrencyAmount';

interface OwnProps {
    data: {payCorrection: PayCorrection};
    handlers: {onDelete(): void};
    onClose(): void;
}

const DeletePayCorrectionModal = (props: OwnProps): JSX.Element => {
    const {
        onClose,
        data: {payCorrection},
        handlers: {onDelete},
    } = props;
    const isDeduction = checkIsDeduction(payCorrection);

    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Delete {payCorrection.type}</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-error" />
                    </div>
                    <div className="col-11">
                        <p>
                            Deleting the{' '}
                            <strong>
                                {isDeduction ? '-' : ''}
                                <CurrencyAmount amount={payCorrection.amount} currency={payCorrection.currency} />
                            </strong>{' '}
                            {payCorrection.type}
                            {payCorrection.comment && (
                                <>
                                    : <em>{payCorrection.comment}</em>,
                                </>
                            )}{' '}
                            will lead to re-count total payment.
                        </p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} buttonSize="small" colorTheme="grey">
                    Cancel
                </Button>
                <Button onClick={onDelete} buttonSize="small" colorTheme="red">
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeletePayCorrectionModal;
