import React from 'react';

import EventName from 'widgets/LocationEvents/components/common/EventName';

import {LastEventForCurrentSummary} from 'core/entities/LocationEvents/types';
import {getConvertedFormattedTime} from 'core/entities/LocationEvents/modules/eventDateTime';

import {getCityLineFromObject} from 'utils/getCityLine';

import styles from './styles.module.scss';

type OwnProps = {
    lastEvent: LastEventForCurrentSummary;
};

const LoadSummaryLastEvent: React.FC<OwnProps> = (props) => {
    const {eventType, cityLine, expectedDate, isSentToBroker} = props.lastEvent;

    const convertCityLine = getCityLineFromObject(cityLine);
    const convertDateTime = getConvertedFormattedTime(expectedDate);
    const sendText = isSentToBroker ? (
        <span className={styles.send}>sent to broker</span>
    ) : (
        <span className={styles.noSend}>not sent to broker</span>
    );

    return (
        <div className={styles.wrap}>
            <div className={styles.info}>
                <div className={styles.title}>Last Event:</div>
                <div className={styles.eventName}>
                    <EventName name={eventType} /> | {sendText}
                </div>
                <div className={styles.cityLine}>{convertCityLine}</div>
            </div>
            <div className={styles.dateTime}>{convertDateTime}</div>
        </div>
    );
};

export default LoadSummaryLastEvent;
