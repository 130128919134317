import React from 'react';
import classNames from 'classnames';

import {
    TRUCK_STATUS_AVAILABLE,
    TRUCK_STATUS_WILL_BE_AVAILABLE_AT,
    TRUCK_STATUS_ON_HOLD,
    TRUCK_STATUS_ON_ROUTE,
    TRUCK_STATUS_ON_VACATION,
    TRUCK_STATUS_RETIRED,
    TRUCK_STATUS_WAITING_FOR_APPROVAL,
    TRUCK_STATUS_NOT_AVAILABLE,
} from 'core/entities/Truck/constants/truckStatuses';

import Tooltip from 'components/ui/Tooltip';

import './style.scss';

type OwnProps = {
    status: string;
    onClick?: () => void;
    disabled?: boolean;
};

const statusIcons = {
    [TRUCK_STATUS_AVAILABLE]: ['fa-check', 'icon-green', '16px'],
    [TRUCK_STATUS_WILL_BE_AVAILABLE_AT]: ['fa-clock-o', 'icon-blue', '16px'],
    [TRUCK_STATUS_NOT_AVAILABLE]: ['fa-lock', 'icon-gray', '16px'],
    [TRUCK_STATUS_ON_ROUTE]: ['fa-road', 'icon-yellow', '16px'],
    [TRUCK_STATUS_ON_VACATION]: ['fa-sun-o', 'icon-gray', '16px'],
    [TRUCK_STATUS_WAITING_FOR_APPROVAL]: ['fa-hourglass', 'icon-gray', '14px'],
    [TRUCK_STATUS_ON_HOLD]: ['fa-pause', 'icon-gray', '14px'],
    [TRUCK_STATUS_RETIRED]: ['fa-ban', 'icon-gray', '16px'],
};

const TruckStatus: React.FC<OwnProps> = ({status, onClick, disabled = false}) => {
    const [icon, iconColor, iconSize] = statusIcons[status] || ['fa-lock', 'icon-gray', '16px'];

    const isButton =
        status === TRUCK_STATUS_AVAILABLE ||
        status === TRUCK_STATUS_WILL_BE_AVAILABLE_AT ||
        status === TRUCK_STATUS_NOT_AVAILABLE;

    const iconJSX = <i className={classNames('fa', icon, iconColor)} style={{fontSize: iconSize}} aria-hidden="true" />;

    return (
        <Tooltip tooltipContent={status} position="bottom">
            {isButton ? (
                <button
                    className={classNames('btn-table btn-table__light', {disabled})}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {iconJSX}
                </button>
            ) : (
                <div className="text-center">{iconJSX}</div>
            )}
        </Tooltip>
    );
};

export default TruckStatus;
