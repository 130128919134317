import React from 'react';

import styles from './styles.module.scss';

const WarningInfo: React.FC = () => {
    return (
        <div className={styles.warning}>
            <i className="fa fa-exclamation-circle" aria-hidden="true" />

            <div className="ml10">
                <div className={styles.title}>WARNING!</div>
                <p>
                    Please ensure that the Customer ID being attached is accurate and that it is being attached to the
                    correct Customer ID.
                </p>
                <p>All loads and invoices will be merged together.</p>
                <p>
                    Once the merge is completed, <strong>it will not be possible to undo the changes.</strong>
                </p>
            </div>
        </div>
    );
};

export default WarningInfo;
