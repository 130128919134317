import isEmpty from 'lodash/isEmpty';

import {REQUIRED} from 'utils/validation/errorMessages';

import {SettlementReimbursementFormValues} from '../types/formTypes';

const getPayCorrectionField = (field: keyof SettlementReimbursementFormValues): string => field;

export default (values: any): any => {
    const allErrors = {};

    if (isEmpty(values)) {
        return {};
    }

    if (!values[getPayCorrectionField('amount')]) {
        allErrors[getPayCorrectionField('amount')] = REQUIRED;
    }

    if (!values[getPayCorrectionField('currency')]) {
        allErrors[getPayCorrectionField('currency')] = REQUIRED;
    }

    return allErrors;
};
