import React from 'react';

import Truck from 'core/entities/Truck/types';
import QuoteReceiversTable from './components/QuoteReceiversTable';
import useFetchTrucks from './hooks/useFetchTrucks';
import useCheckEmptyReceivers from '../../hooks/useCheckEmptyReceivers';

const QuoteReceivers: React.FC<{
    formName: string;
    fetchAllTrucks: () => Promise<Truck[]>;
}> = (props) => {
    const {formName, fetchAllTrucks} = props;

    useFetchTrucks({formName, fetchAllTrucks});

    const isReceiversEmpty = useCheckEmptyReceivers();

    return isReceiversEmpty ? null : <QuoteReceiversTable formName={formName} />;
};

export default React.memo(QuoteReceivers);
