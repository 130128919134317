import isArray from 'lodash/isArray';

import Truck from 'core/entities/Truck/types';

export const getTrucksList = (trucks: Truck[], valueAsObject: boolean) => {
    if (!trucks || !isArray(trucks)) {
        return [];
    }

    return trucks.map((truck: Truck) => {
        return {
            value: valueAsObject ? truck : truck.id,
            label: truck.number,
        };
    });
};
