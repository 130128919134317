import React from 'react';
import {Field} from 'redux-form';
import {normalizeMultipleSelect} from 'utils';
import Select from 'components/ui/Form/Select';
import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import certificatesData from 'utils/data/certificates';

export const certificates = [
    {label: certificatesData.hazmat.text, value: certificatesData.hazmat.value},
    {label: certificatesData.tsa.title, value: certificatesData.tsa.value},
    {label: certificatesData.twic.title, value: certificatesData.twic.value},
    {label: certificatesData.tanker_endorsement.title, value: certificatesData.tanker_endorsement.value},
];
const Certificate: React.FC = (): JSX.Element => {
    const {getTruckDriverCertificateFieldName} = getFormFieldNames();
    return (
        <Field
            label="Certificate"
            name={getTruckDriverCertificateFieldName()}
            type="text"
            data={certificates}
            component={Select}
            placeholder="choose or type the certificate"
            normalize={normalizeMultipleSelect}
            closeMenuOnSelect={false}
            isMulti
            isCreatable
        />
    );
};

export default Certificate;
