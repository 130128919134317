import {getCityLineFromObject} from 'utils/getCityLine';
import {Facility} from './types';

export default function getFacilityCityLine(facility?: Facility): string {
    if (!facility) {
        return '';
    }

    const {city, state, zip, country} = facility;

    return getCityLineFromObject({city, state, zip, country});
}
