import {useState} from 'react';
import omit from 'lodash/omit';

import Invoice from 'core/entities/Invoice/types';

type SelectedInvoicesMap = Record<number, Invoice>;

type SelectInvoices = {
    selectedInvoices: SelectedInvoicesMap;
    onSelectAllInvoices();
    onSelectInvoice(invoice: Invoice);
    clearSelectedInvoices();
};
export const useSelectInvoices = (allInvoices: Invoice[]): SelectInvoices => {
    const [selectedInvoices, setSelectedInvoices] = useState<SelectedInvoicesMap>({});
    return {
        selectedInvoices,
        onSelectInvoice: (invoice: Invoice) => {
            setSelectedInvoices((currentState) => {
                const isSelectNewInvoice = !(invoice.id in currentState);
                return isSelectNewInvoice ? {...currentState, [invoice.id]: invoice} : omit(currentState, [invoice.id]);
            });
        },
        onSelectAllInvoices: () => {
            const countSelectedInvoices = Object.keys(selectedInvoices).length;
            if (countSelectedInvoices < allInvoices.length) {
                const allSelectedInvoicesMap = allInvoices.reduce((result, invoice) => {
                    result[invoice.id] = invoice;
                    return result;
                }, {});
                setSelectedInvoices(allSelectedInvoicesMap);
            } else {
                setSelectedInvoices({});
            }
        },
        clearSelectedInvoices: () => {
            setSelectedInvoices({});
        },
    };
};
