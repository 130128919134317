import {Field} from 'redux-form';
import React from 'react';
import LengthMeasureInput from 'components/ui/Form/LengthMeasureInput';
import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';

type OwnProps = {
    units: string;
    index: number;
};

const DimensionItem: React.FC<OwnProps> = (props) => {
    const {units, index} = props;
    const {
        getLoadFreightDimensionLengthFieldName,
        getLoadFreightDimensionWidthFieldName,
        getLoadFreightDimensionHeightFieldName,
    } = getFormFieldNames();
    const lengthFieldName = getLoadFreightDimensionLengthFieldName(index);
    const widthFieldName = getLoadFreightDimensionWidthFieldName(index);
    const heightFieldName = getLoadFreightDimensionHeightFieldName(index);
    return (
        <>
            <div className="d-flex align-items-end">
                <Field name={lengthFieldName} component={LengthMeasureInput} label="length" units={units} />
                <span className="m-2">x</span>
                <Field name={widthFieldName} component={LengthMeasureInput} label="width" units={units} />
                <span className="m-2">x</span>
                <Field name={heightFieldName} component={LengthMeasureInput} label="height" units={units} />
            </div>
        </>
    );
};
export default DimensionItem;
