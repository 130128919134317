import {AppState} from 'store';

import {CurrentUserState} from '.';

export const getCurrentDispatcher = (state: AppState): CurrentUserState => state.userData;

export const getCurrentUser = (state: AppState): CurrentUserState['user'] => state.userData.user;

export const getCurrentUserID = (state: AppState): CurrentUserState['id'] => state.userData.id;

export const getCurrentUserEmail = (state: AppState): CurrentUserState['email'] => state.userData.email;

export const getCurrentUserPreferences = (state: AppState): CurrentUserState['preferences'] =>
    state.userData.preferences;

export const getCurrentUserTripMonitorColorSettings = (state: AppState) => {
    return state.userData.preferences?.colorSettings?.tripMonitor;
};
