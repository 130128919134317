import {
    WEB_SOCKET_BILLING_INVOICE_RESET,
    WEB_SOCKET_INVOICES_INVOICE_ADDED_TO_BATCH,
    WEB_SOCKET_INVOICES_INVOICE_RESET_FROM_BATCH,
    WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_READY_TO_GENERATE,
    WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_GENERATED,
    WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_READY_TO_POST,
    WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_POSTED,
} from 'store/middlewares/mainAPISocketsIO/entities/invoices/actionTypes';

import {updateItem} from 'utils/normalizer';

import {getBatchByID, getInvoiceByID, DataState} from './invoicesData';

const websockets = (state: DataState, action: any): DataState => {
    switch (action.type) {
        case WEB_SOCKET_INVOICES_INVOICE_ADDED_TO_BATCH: {
            const {batch: batchWithAddedInvoices} = action.payload;
            const batchForUpdateFromCurrentState = getBatchByID(state, batchWithAddedInvoices.id);
            if (!batchWithAddedInvoices || !batchForUpdateFromCurrentState) {
                return state;
            }
            const updatedBatch = {...batchForUpdateFromCurrentState, ...batchWithAddedInvoices};
            return {
                ...state,
                batches: updateItem(state.batches, updatedBatch),
            };
        }

        case WEB_SOCKET_INVOICES_INVOICE_RESET_FROM_BATCH: {
            const {batch: batchWithResetInvoice} = action.payload;
            const batchForUpdateFromCurrentState = getBatchByID(state, batchWithResetInvoice?.id);

            if (!batchForUpdateFromCurrentState) {
                return state;
            }

            const updatedBatch = {...batchForUpdateFromCurrentState, ...batchWithResetInvoice};
            return {
                ...state,
                batches: updateItem(state.batches, updatedBatch),
            };
        }

        case WEB_SOCKET_BILLING_INVOICE_RESET:
        case WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_READY_TO_GENERATE:
        case WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_GENERATED:
        case WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_READY_TO_POST:
        case WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_POSTED: {
            const invoiceFromWS = action.payload;
            const invoiceForUpdateFromCurrentState = getInvoiceByID(state, invoiceFromWS?.id);

            if (!invoiceForUpdateFromCurrentState) {
                return state;
            }

            const updatedInvoice = {...invoiceForUpdateFromCurrentState, ...invoiceFromWS};
            return {
                ...state,
                invoices: updateItem(state.invoices, updatedInvoice),
            };
        }

        default:
            return state;
    }
};

export default websockets;
