import isArray from 'lodash/isArray';

import Invoice from 'core/entities/Invoice/types';
import {fetchGeneralBatchInvoices, fetchResetBatchInvoices} from 'core/entities/Invoice/requests';
import parsePaginationHeaders from 'utils/parsePaginationHeaders';

interface FetchParams {
    batchNumber: number;
    searchParams: any;
    pagination: {page: number; perPage: number};
}

export const fetchGeneralInvoices = (params: FetchParams): Promise<{invoices: Invoice[]; pagination: any}> => {
    const {batchNumber, searchParams = {}, pagination} = params;
    return fetchGeneralBatchInvoices(batchNumber, searchParams, pagination)
        .then((response) => {
            const receivedPagination = parsePaginationHeaders(response.headers);
            if (!isArray(response.data)) {
                console.warn('Incorrect response on fetch batch invoices expected array received: ', response.data);
                return {invoices: [], pagination: receivedPagination};
            }
            return {invoices: response.data, pagination: receivedPagination};
        })
        .catch((error) => {
            console.error('Error on fetch general batch invoices: ', error);
            return {invoices: [], pagination: parsePaginationHeaders(error.headers)};
        });
};

export const fetchResetInvoices = (params: FetchParams): Promise<{invoices: Invoice[]; pagination: any}> => {
    const {batchNumber, searchParams = {}, pagination} = params;
    return fetchResetBatchInvoices(batchNumber, searchParams, pagination)
        .then((response) => {
            return {invoices: response.data, pagination: parsePaginationHeaders(response.headers)};
        })
        .catch((error) => {
            console.error('Error on fetch reset batch invoices: ', error);
            return {invoices: [], pagination: parsePaginationHeaders(error.headers)};
        });
};
