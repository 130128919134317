import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {InjectedFormProps, Field, reduxForm, formValueSelector} from 'redux-form';

import TextArea from 'components/ui/Form/TextArea';
import Button from 'components/ui/Buttons/Button';

import trimAndReduceSpaces from 'utils/trimAndReduceSpaces';

const ADD_QUOTE_GENERAL_NOTE_FORM_NAME = 'add-quote-general-note-form';
const NOTE_FIELD_NAME = 'text';

interface OwnProps {
    isDisabledAddNotes: boolean;
}
const NotesForm = (props: InjectedFormProps<{text: string}, OwnProps> & OwnProps): JSX.Element => {
    const {handleSubmit, reset, isDisabledAddNotes} = props;

    useEffect(() => {
        reset();
    }, []);

    const submit = (data): void => {
        handleSubmit(data);
        reset();
    };

    return (
        <form onSubmit={submit}>
            <div>
                <Field
                    name={NOTE_FIELD_NAME}
                    maxLength="100"
                    component={TextArea}
                    rows="2"
                    placeholder="type a note here"
                    normalize={trimAndReduceSpaces}
                    disabled={isDisabledAddNotes}
                />
            </div>
            <div>
                <div />
                <div className="d-flex justify-content-end">
                    <Button
                        disabled={isDisabledAddNotes}
                        type="submit"
                        colorTheme="blue"
                        className="custom-design ml-1"
                    >
                        Add a Note
                    </Button>
                </div>
            </div>
        </form>
    );
};

const Form = reduxForm<{text: string}, OwnProps>({
    form: ADD_QUOTE_GENERAL_NOTE_FORM_NAME,
})(NotesForm);

export default Form;
