import {useSelector} from 'react-redux';

import {
    getCompanyFullTitle,
    getCompanyShortTitle,
    getDomainLogo,
    getSendQuote,
    getShowPartnersTab,
} from 'store/reducers/appSettings/selectors';

const useGetDomainSettings = (): {
    companyFullName: string;
    companyShortName: string;
    isSendQuote: boolean;
    isShowPartnersTab: boolean;
    urlLogo: string;
} => {
    const companyFullName = useSelector(getCompanyFullTitle);
    const companyShortName = useSelector(getCompanyShortTitle);
    const isSendQuote = useSelector(getSendQuote);
    const isShowPartnersTab = useSelector(getShowPartnersTab);
    const urlLogo = useSelector(getDomainLogo);

    return {
        companyFullName,
        companyShortName,
        isSendQuote,
        isShowPartnersTab,
        urlLogo,
    };
};

export default useGetDomainSettings;
