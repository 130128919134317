import sumBy from 'lodash/sumBy';
import head from 'lodash/head';

import {getToFixedNumberValue} from 'utils';

import {getInvoiceTotalRate} from 'core/entities/Invoice/invoice';

import {POSTED, READY_TO_POST} from './constants';

export const getBatchStatusInfo = (batch): any => {
    if (!batch || !batch.status_history) {
        return;
    }

    return head(batch.status_history);
};

export const getBatchStatusName = (batch) => {
    const batchStatusInfo = getBatchStatusInfo(batch);
    if (!batchStatusInfo) {
        return;
    }

    return batchStatusInfo.status && batchStatusInfo.status.status_name;
};

export const getBatchInvoices = (batch) => {
    if (!batch || !batch.batch_invoices || batch.batch_invoices.length === 0) {
        return [];
    }
    const getInvoiceDataFromBatch = (batchInvoiceData, field) =>
        batchInvoiceData && batchInvoiceData.invoice_file && batchInvoiceData.invoice_file[field];
    return batch.batch_invoices.reduce((result, batchInvoiceData) => {
        const invoiceDataFromBatch = getInvoiceDataFromBatch(batchInvoiceData, 'invoice');
        const invoiceFileFromBatch = getInvoiceDataFromBatch(batchInvoiceData, 'file');
        const invoiceStatusDataFromBatch = getInvoiceDataFromBatch(batchInvoiceData, 'status');
        const invoiceResponsibleDataFromBatch = getInvoiceDataFromBatch(batchInvoiceData, 'responsible');
        if (invoiceDataFromBatch) {
            // because API send invoice inside batch in different data structure than in usual case
            // we should fix manually data to correct format
            result.push({
                ...invoiceDataFromBatch,
                last_status_history: {
                    ...invoiceDataFromBatch.last_status_history,
                    status: invoiceStatusDataFromBatch,
                    responsible: invoiceResponsibleDataFromBatch,
                    file: invoiceFileFromBatch,
                },
            });
        }
        return result;
    }, []);
};

export const getBatchTotalCharges = (batch) => {
    const batchInvoices = getBatchInvoices(batch);
    if (batchInvoices.length === 0) {
        return 0;
    }
    const totalCharges = sumBy(batchInvoices, (invoice) => Number(getInvoiceTotalRate(invoice)));

    return getToFixedNumberValue(totalCharges);
};

export function isBatchStatus(status) {
    const batchStatuses = [READY_TO_POST, POSTED];
    return batchStatuses.includes(status);
}
