import {useSelector} from 'react-redux';

import {getChatClientSocketId, getMessageStatus} from 'widgets/Chat/redux/selectors';

const useChatState = () => {
    const chatClientSocketId = useSelector(getChatClientSocketId);
    const chatMessageStatus = useSelector(getMessageStatus);

    return {
        chatClientSocketId,
        chatMessageStatus,
    };
};

export default useChatState;
