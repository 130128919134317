import React from 'react';
import {connect} from 'react-redux';
import {FormLabel, Row, Col} from 'react-bootstrap';
import {Field, reduxForm, formValueSelector} from 'redux-form';

import DispatchersSelect from 'components/common/Dispatcher/DispatchersSelect';
import RadioInput from 'components/ui/Form/RadioInput';
import Button from 'components/ui/Buttons/Button';

import {getTruckReserveInfo} from 'core/entities/Truck/modules/truckReserve';

import getText from 'utils/getText';

import {reservingFields, reserveActions} from '../constants';
import MinutesInput from './MinutesInput';

const ReservingForm = (props) => {
    const {
        handleSubmit,
        submitting,
        transferDispatcher,
        reserveType,
        reserveAction,
        onCancel,
        onCancelReserve,
        truck,
        isCrossServerReserving,
    } = props;

    const {waitingRC} = getTruckReserveInfo(truck) || {};
    const rc = getText('rc');
    const isTransferDispatcherNotChosen = reserveAction === reserveActions.transfer && !transferDispatcher;

    const isSubmitDisabled = () =>
        submitting || isTransferDispatcherNotChosen || (waitingRC && reserveAction === reserveActions.holdForRC);

    const submitTitle = () => {
        let title = 'reserve';

        if (reserveType.reservedByCurrent && reserveAction === reserveActions.reserveForTime) {
            title = 'update';
        }
        if (reserveType.reservedByCurrent && reserveAction === reserveActions.transfer) {
            title = 'transfer';
        }
        if (reserveAction === reserveActions.holdForRC) {
            title = 'hold truck';
        }
        return title;
    };

    return (
        <div className="reserving_form">
            <form onSubmit={handleSubmit}>
                <div className="reserve_action">
                    <Field
                        name={reservingFields.reserveAction}
                        component={RadioInput}
                        items={[
                            {
                                label: 'extend the time',
                                value: reserveActions.reserveForTime,
                                disabled: waitingRC,
                            },
                            {
                                label: 'transfer',
                                value: reserveActions.transfer,
                                disabled: isCrossServerReserving || !reserveType.reservedByCurrent || waitingRC,
                            },
                            {label: `hold for ${rc}`, value: reserveActions.holdForRC},
                        ]}
                    />
                </div>

                {reserveAction === reserveActions.reserveForTime && (
                    <div className="reserve_select">
                        <FormLabel>
                            <strong>{reserveType.reservedByCurrent ? 'reserve for more:' : 'reserve for:'}</strong>
                        </FormLabel>
                        <Field name={reservingFields.reservationMinutes} component={MinutesInput} step={1} limit={60} />
                    </div>
                )}

                {reserveAction === reserveActions.transfer && reserveType.reservedByCurrent ? (
                    <div className="reserve_select">
                        <FormLabel>
                            <strong>transfer reservation:</strong>
                        </FormLabel>
                        <Row>
                            <Col md={5}>
                                <Field
                                    name={reservingFields.transferDispatcher}
                                    type="text"
                                    component={DispatchersSelect}
                                    placeholder="choose user from the list"
                                    withoutCurrent={true}
                                />
                            </Col>
                        </Row>
                    </div>
                ) : null}

                {reserveAction === reserveActions.holdForRC ? (
                    <div className="reserve_select">
                        <p className="mb15">
                            {waitingRC ? (
                                <>
                                    you are already holding <b>Truck#{truck.number}</b> for 2 {getText('rc')}
                                </>
                            ) : (
                                <>
                                    you are going to hold <b>Truck#{truck.number}</b> for 3 {getText('rc')}
                                </>
                            )}
                        </p>
                    </div>
                ) : null}

                <div className="modal-body__bottom">
                    <Button onClick={onCancel} buttonSize="small" colorTheme="grey">
                        Cancel
                    </Button>

                    <div className="reserve-btn-container">
                        {reserveType.reservedByCurrent ? (
                            <Button buttonSize="small" colorTheme="blue" onClick={onCancelReserve}>
                                release truck
                            </Button>
                        ) : null}

                        {reserveType.notReserved || reserveType.reservedByCurrent ? (
                            <Button type="submit" disabled={isSubmitDisabled()} buttonSize="small" colorTheme="blue">
                                {submitTitle()}
                            </Button>
                        ) : null}
                    </div>
                </div>
            </form>
        </div>
    );
};

const selector = (form, ...other) => formValueSelector(form)(...other);

export default connect(
    (state, ownProps) => ({
        reserveAction: selector(ownProps.form, state, reservingFields.reserveAction),
        transferDispatcher: selector(ownProps.form, state, reservingFields.transferDispatcher),
    }),
    () => ({}),
)(
    reduxForm({
        asyncBlurFields: [],
        forceUnregisterOnUnmount: true,
    })(ReservingForm),
);
