import React from 'react';

import Invoice from 'core/entities/Invoice/types';
import {getInvoiceLinks} from 'routing/linkBuilder';

import ButtonsBlock from 'components/ui/ButtonsBlock';
import ButtonLink from 'components/ui/Buttons/ButtonLink';
import Button from 'components/ui/Buttons/Button';

import styles from './styles.module.scss';

const ActionButtons: React.FC<{invoice: Invoice; displayMode: 'reset' | 'general'; onOpeInvoicePDF()}> = (
    props,
): JSX.Element => {
    const {invoice, displayMode, onOpeInvoicePDF} = props;
    const invoiceLinks = getInvoiceLinks(invoice);
    return (
        <div className={styles.actions}>
            <ButtonsBlock>
                {displayMode === 'reset' ? (
                    <ButtonLink target="_blank" href={invoiceLinks.view} colorTheme="white" buttonSize="full-width">
                        View Profile
                    </ButtonLink>
                ) : (
                    <Button onClick={onOpeInvoicePDF} colorTheme="white" buttonSize="full-width">
                        Open PDF
                    </Button>
                )}
            </ButtonsBlock>
        </div>
    );
};

export default ActionButtons;
