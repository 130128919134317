import last from 'lodash/last';
import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';
import head from 'lodash/head';

import Load, {DeliveryStop, LoadStopMeta, PickupStop, LoadStop} from 'core/entities/Load/types';

import {getTotalFreightsInfo} from 'core/entities/Load/modules/loadFreight';
import composeFacilityCityLine from 'core/entities/Facility/composeFacilityCityLine';

import {getStopsInfo, StopsInfo, ExtendedLoadStop} from './getStopsInfo';

export const isPickupStop = (stop: LoadStop): stop is PickupStop => stop.type === 'pick up';
export const isDeliveryStop = (stop: LoadStop): stop is DeliveryStop => stop.type === 'delivery';

export const getPickupStops = (stops: LoadStop[]): PickupStop[] => stops.filter<PickupStop>(isPickupStop);
export const getDeliveryStops = (stops: LoadStop[]): DeliveryStop[] => stops.filter<DeliveryStop>(isDeliveryStop);

export const getStopMeta = (stop: LoadStop): LoadStopMeta | undefined => stop?.meta;
export const getShortStopTitle = (meta: LoadStopMeta): string | undefined => meta?.shortName;

export const getAllLoadStops = (load: Load): LoadStop[] => {
    return load?.stops;
};

export const getOppositeStopType = (stop: LoadStop): LoadStop['type'] => (isPickupStop(stop) ? 'delivery' : 'pick up');

export const isStopDone = (stop: LoadStop): boolean => stop?.status === 'completed';
export const isStopPlanned = (stop: LoadStop): boolean => stop?.status === 'planned';
export const isStopInProgress = (stop: LoadStop): boolean => stop?.status === 'in progress';

export const getLoadFirstPickup = (load: Load | null | undefined): LoadStop | undefined => {
    return head(load?.stops);
};
export const getLoadLastDelivery = (load: Load | null | undefined): LoadStop | undefined => {
    return last(load?.stops);
};
export const getAllPickUps = (load?: Load | null): PickupStop[] => getPickupStops(load?.stops || []);

export const getAllDeliveries = (load: Load | null | undefined): DeliveryStop[] => getDeliveryStops(load?.stops || []);

export const getPickUpDeliveryNumberMap = (load: Load) => {
    const allPickUps = getAllPickUps(load);
    const allDeliveries = getAllDeliveries(load);
    const getMapWithNumbers = (listItems) => listItems.map((item, index) => ({id: item.id, number: index + 1}));
    return keyBy([...getMapWithNumbers(allPickUps), ...getMapWithNumbers(allDeliveries)], 'id');
};

export type PickupTotalFreightsInfo = {
    id: LoadStop['id'];
    loadNumber: number;
    tempMin: number | undefined;
    tempMax: number | undefined;
    isTempRange: boolean;
    freights: PickupStop['freights'];
} & Partial<ReturnType<typeof getTotalFreightsInfo>>;
export const getPickUpTotalFreightsInfo = (load: Load, excludedFields?: string[]): PickupTotalFreightsInfo[] => {
    const {number: loadNumber, truckTempMin, truckTempMax, stops} = load;
    const allPickUps = getPickupStops(stops);
    return allPickUps.map(
        (stop): PickupTotalFreightsInfo => {
            const pickupTotalFreightsInfo = getTotalFreightsInfo(stop?.freights);
            const totalFreightsInfo = excludedFields
                ? omit(pickupTotalFreightsInfo, excludedFields)
                : pickupTotalFreightsInfo;
            const isTempRange = Number.isInteger(truckTempMin) && Number.isInteger(truckTempMax);
            return {
                id: stop.id,
                loadNumber,
                tempMin: truckTempMin,
                tempMax: truckTempMax,
                isTempRange,
                freights: stop?.freights,
                ...totalFreightsInfo,
            };
        },
    );
};

type PickupDeliveryInfo = {
    id: number;
    allPickUps: PickupStop[];
    allDeliveries: DeliveryStop[];
    firstPickUp: PickupStop;
    lastDelivery: DeliveryStop;
    firstPickUpAddress: string;
    lastDeliveryAddress: string;
    allStopsAddresses: string[] | undefined;
    allStopsLength: number;
    numbersMap: ReturnType<typeof getPickUpDeliveryNumberMap>;
};
type PickupDeliveryOptions = {withAllAddresses: boolean};
export const getPickUpDeliveryInfo = (load: Load, options?: PickupDeliveryOptions): PickupDeliveryInfo => {
    const withAllAddresses = options?.withAllAddresses;
    const allPickUps = getAllPickUps(load);
    const allDeliveries = getAllDeliveries(load);
    const firstPickUp = head(allPickUps);
    const lastDelivery = last(allDeliveries);
    const getPickUpDeliveryAddress = (pickUpDelivery: PickupStop | DeliveryStop) =>
        pickUpDelivery && pickUpDelivery.facility ? composeFacilityCityLine(pickUpDelivery.facility) : '';
    const firstPickUpAddress = getPickUpDeliveryAddress(firstPickUp as PickupStop);
    const lastDeliveryAddress = getPickUpDeliveryAddress(lastDelivery as DeliveryStop);

    return {
        id: load.number,
        allPickUps,
        allDeliveries,
        firstPickUp: firstPickUp as PickupStop,
        lastDelivery: lastDelivery as DeliveryStop,
        firstPickUpAddress,
        lastDeliveryAddress,
        allStopsAddresses: withAllAddresses ? load.stops.map(getPickUpDeliveryAddress) : undefined,
        allStopsLength: load.stops?.length,
        numbersMap: getPickUpDeliveryNumberMap(load),
    };
};

export type {ExtendedLoadStop};
export type {StopsInfo};
export {getStopsInfo};
