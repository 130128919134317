import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector, change as reduxFormChange, InjectedFormProps} from 'redux-form';

import {AppState} from 'store';

import {PayPeriod} from 'core/entities/Settlement/types';

import Button from 'components/ui/Buttons/Button';

import {OpenedPayPeriod, PayPeriodResponsibleUser} from './types';
import PayPeriodInfo from './PayPeriodInfo';
import SelectedPayPeriodInfo from './SelectedPayPeriodInfo';
import * as selectors from './selectors';
import styles from './form.module.scss';

interface OwnProps {
    // eslint-disable-next-line react/no-unused-prop-types
    payPeriods: PayPeriod[];
    onClose();
}

interface StateProps {
    allPayPeriods: OpenedPayPeriod[];
    selectedPayPeriod: OpenedPayPeriod;
    // eslint-disable-next-line react/no-unused-prop-types
    initialValues: {
        period: OpenedPayPeriod;
    };
}

interface DispatchProps {
    onSelectPayPeriod(payPeriod: OpenedPayPeriod): void;
    onSelectResponsibleUser(user: PayPeriodResponsibleUser): void;
    onSelectAllPeriodUsers(users: PayPeriodResponsibleUser[]): void;
}

type AllFormProps = OwnProps & StateProps & DispatchProps;

const FORM_NAME = 'settlements-setup-pay-period-form';
const PAY_PERIOD_FIELD_NAME = 'period';
const initialValues: {period: OpenedPayPeriod} = {
    period: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id: undefined,
        title: '',
        users: [],
    },
};

const formSelector = formValueSelector(FORM_NAME);

const Form = (props: AllFormProps & InjectedFormProps<{}, AllFormProps>): JSX.Element => {
    const {
        handleSubmit,
        pristine,
        submitting,
        allPayPeriods,
        selectedPayPeriod = {} as OpenedPayPeriod,
        onSelectPayPeriod,
        onSelectResponsibleUser,
        onSelectAllPeriodUsers,
        onClose,
    } = props;
    const selectedUsers = selectedPayPeriod.users || [];
    const payPeriods = useMemo(() => {
        return allPayPeriods.map((period) => {
            const selectedUsersInPayPeriod = selectedPayPeriod?.id === period.id ? selectedUsers : [];
            return (
                <PayPeriodInfo
                    key={period.id}
                    payPeriod={period}
                    isOpened={selectedPayPeriod.id === period.id}
                    selectedUsers={selectedUsersInPayPeriod}
                    onClickHeader={() => onSelectPayPeriod(period)}
                    onSelectUser={onSelectResponsibleUser}
                    onSelectAllUsers={onSelectAllPeriodUsers}
                />
            );
        });
    }, [allPayPeriods, selectedPayPeriod.id, selectedPayPeriod.users]);

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className="custom-scroll">
                <div className="modal-body__main">
                    <p className="mb15">Choose needed pay period:</p>
                    <div className="invoices-group">{payPeriods}</div>
                </div>
            </div>

            <SelectedPayPeriodInfo selectedPayPeriod={selectedPayPeriod} />

            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <Button
                        disabled={pristine || submitting || selectedUsers.length === 0}
                        type="submit"
                        colorTheme="blue"
                    >
                        Post
                    </Button>
                </div>
            </div>
        </form>
    );
};

const ReduxForm = reduxForm<{}, AllFormProps>({form: FORM_NAME})(Form);

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
    const payPeriods = selectors.getPayPeriodsWithSettlements(state, ownProps);
    return {
        allPayPeriods: payPeriods,
        initialValues: {
            period: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                id: undefined,
                title: '',
                settlementsCount: 0,
                users: [],
            },
        },
        selectedPayPeriod: formSelector(state, PAY_PERIOD_FIELD_NAME),
    };
};

const dispatchToProps = (dispatch): DispatchProps => {
    return {
        onSelectPayPeriod: (payPeriod) => {
            return dispatch((_, getState) => {
                const currentPeriod = formSelector(getState(), PAY_PERIOD_FIELD_NAME);
                if (currentPeriod.id === payPeriod.id) {
                    dispatch(reduxFormChange(FORM_NAME, PAY_PERIOD_FIELD_NAME, initialValues.period));
                    return;
                }
                dispatch(reduxFormChange(FORM_NAME, PAY_PERIOD_FIELD_NAME, payPeriod));
            });
        },

        onSelectResponsibleUser: (selectedUser) => {
            return dispatch((_, getState) => {
                const currentPeriod = formSelector(getState(), PAY_PERIOD_FIELD_NAME);
                const isUserInPayPeriod = currentPeriod.users.some((user) => user.id === selectedUser.id);
                if (isUserInPayPeriod) {
                    const usersWithoutSelected = currentPeriod.users.filter((user) => user.id !== selectedUser.id);
                    const updatedPayPeriod = {
                        ...currentPeriod,
                        users: usersWithoutSelected,
                    };
                    dispatch(reduxFormChange(FORM_NAME, PAY_PERIOD_FIELD_NAME, updatedPayPeriod));
                } else {
                    const updatedPayPeriod = {
                        ...currentPeriod,
                        users: [...currentPeriod.users, selectedUser],
                    };
                    dispatch(reduxFormChange(FORM_NAME, PAY_PERIOD_FIELD_NAME, updatedPayPeriod));
                }
            });
        },

        onSelectAllPeriodUsers: (allUsers) => {
            return dispatch((_, getState) => {
                const currentPeriod = formSelector(getState(), PAY_PERIOD_FIELD_NAME);
                if (currentPeriod.users.length === 0) {
                    dispatch(reduxFormChange(FORM_NAME, PAY_PERIOD_FIELD_NAME, {...currentPeriod, users: allUsers}));
                } else {
                    dispatch(reduxFormChange(FORM_NAME, PAY_PERIOD_FIELD_NAME, {...currentPeriod, users: []}));
                }
            });
        },
    };
};

export default connect(mapStateToProps, dispatchToProps)(ReduxForm);
