import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';

const ButtonLink = (props) => {
    const {children, colorTheme = 'grey', buttonSize = 'medium', buttonIcon, className = '', disabled, ...rest} = props;

    const getLink = ({children: childrenContainer, href, ...restProps}) => {
        const isTargetBlank = props.target && props.target === '_blank';

        return isTargetBlank ? (
            <a {...restProps} href={href}>
                {childrenContainer}
            </a>
        ) : (
            <Link {...restProps} to={href}>
                {childrenContainer}
            </Link>
        );
    };

    if (buttonIcon && !disabled) {
        return getLink({
            ...rest,
            className: `button button-with-icon button-${colorTheme} button-${buttonSize} ${className}`,
            children: (
                <Fragment>
                    <i className={`fa fa-${buttonIcon}`} aria-hidden="true" />
                    {children}
                </Fragment>
            ),
        });
    }
    if (buttonIcon && disabled) {
        return (
            <button
                type="button"
                className={`button button-with-icon button-${colorTheme} button-${buttonSize} ${className}`}
                disabled
            >
                <i className={`fa fa-${buttonIcon}`} aria-hidden="true" />
                {children}
            </button>
        );
    }

    if (!buttonIcon && !disabled) {
        return getLink({
            ...rest,
            className: `button button-${colorTheme} button-${buttonSize} ${className}`,
            children,
        });
    }

    if (!buttonIcon && disabled) {
        return (
            <button type="button" className={`button button-${colorTheme} button-${buttonSize} ${className}`} disabled>
                {children}
            </button>
        );
    }

    return null;
};

ButtonLink.defaultProps = {
    buttonSize: 'medium',
    colorTheme: 'grey',
    buttonIcon: undefined,
};

export default ButtonLink;
