import React from 'react';

import StatusLabel from 'components/common/Truck/StatusLabel';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';

import styles from './styles.module.scss';

const TruckStatus: React.FC = () => {
    const {getTruckTypeStatus} = getFormFieldNames();
    const statuses = useGetReduxFormValue(FORM_NAME, getTruckTypeStatus());
    return (
        <div>
            <p className={styles.title}>Truck`s status: </p>
            <div className={styles.statuses}>
                {statuses.map((status, index) => (
                    <div className="mr-1" key={index}>
                        <StatusLabel statusText={status} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TruckStatus;
