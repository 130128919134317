import {AxiosResponse} from 'axios';

import restapi from 'services/restapi';

import Carrier from 'core/entities/Carrier/types';

import {ENTITY_NAME_CARRIERS} from 'utils/data/entityNames';

const apiUrl = `/${ENTITY_NAME_CARRIERS}`;

export const fetchCarrierList = (params): Promise<AxiosResponse> => {
    return restapi.post(`${apiUrl}/search`, params);
};

export const fetchCurrentCarrier = (): Promise<AxiosResponse> => {
    return restapi.get(`${apiUrl}/current`);
};

export const fetchCarrier = (carrierID: number): Promise<AxiosResponse> => {
    return restapi.get(`${apiUrl}/${carrierID}`);
};

export const updateCarrier = (carrier: Carrier): Promise<AxiosResponse> => {
    return restapi.put(`${apiUrl}/${carrier.id}`, carrier);
};

export const createCarrier = (carrier): Promise<AxiosResponse> => {
    return restapi.post(apiUrl, carrier);
};

export const archiveCarrier = (carrier: Carrier): Promise<Carrier> => {
    return restapi.put(`${apiUrl}/${carrier.id}/archive`).then(() => ({...carrier, is_deleted: true}));
};

export const restoreCarrier = (carrier): Promise<Carrier> => {
    return restapi.put(`${apiUrl}/${carrier.id}/restore`).then(() => ({...carrier, is_deleted: false}));
};

export const fetchCarriersOptionsByTitle = (data: {filterByTitle?: string}): Promise<AxiosResponse> => {
    return restapi.options(apiUrl, {data});
};
