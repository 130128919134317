import getFacilityCityLine from 'core/entities/Facility/getFacilityCityLine';
import {Facility} from 'core/entities/Facility/types';
import {getTravelOrderStopByID} from 'core/entities/TravelOrder/modules/travelOrderStops';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';
import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';

const getFacility = (stop?: TravelOrderStop): Facility | undefined => stop?.loadStop?.facility || undefined;
const isDelivery = (stop?: TravelOrderStop): boolean => Boolean(stop?.loadStop?.type === 'delivery');
const isPickup = (stop?: TravelOrderStop): boolean => Boolean(stop?.loadStop?.type === 'pick up');
const getOrderNumber = (stop?: TravelOrderStop): number | undefined => stop?.loadStop?.orderNumber;
const getShortTitle = (stop?: TravelOrderStop): string => stop?.meta?.shortName || '';
const getNote = (stop?: TravelOrderStop): string | undefined => stop?.loadStop?.note;
const getID = (stop?: TravelOrderStop): number | undefined => stop?.loadStop.id;
const getFacilityLocation = (stop?: TravelOrderStop): string =>
    getFacilityCityLine(stop?.loadStop?.facility || undefined);
const getTitle = (stop?: TravelOrderStop): string => {
    const pickUpTitle = isPickup(stop) ? 'Pick Up' : '';
    const deliveryTitle = isDelivery(stop) ? 'Delivery' : '';

    return pickUpTitle || deliveryTitle || '';
};
const getCommodity = (stop?: TravelOrderStop): string => stop?.loadStop?.commodity || '';

export type TOStopLoadStopInfo = {
    facilityLocation: ReturnType<typeof getFacilityLocation>;
    orderNumber: ReturnType<typeof getOrderNumber>;
    shortTitle: ReturnType<typeof getShortTitle>;
    commodity: ReturnType<typeof getCommodity>;
    isDelivery: ReturnType<typeof isDelivery>;
    facility: ReturnType<typeof getFacility>;
    isPickup: ReturnType<typeof isPickup>;
    title: ReturnType<typeof getTitle>;
    note: ReturnType<typeof getNote>;
    id: ReturnType<typeof getID>;
};

export const getTravelOrderStopLoadStopInfo = (stop?: TravelOrderStop): TOStopLoadStopInfo => ({
    facilityLocation: getFacilityLocation(stop),
    orderNumber: getOrderNumber(stop),
    shortTitle: getShortTitle(stop),
    commodity: getCommodity(stop),
    isDelivery: isDelivery(stop),
    facility: getFacility(stop),
    isPickup: isPickup(stop),
    title: getTitle(stop),
    note: getNote(stop),
    id: getID(stop),
});

export const getTravelOrderLoadStopCommodityById = (params: StopInfoParams): string | null =>
    getCommodity(getTravelOrderStopByID(params));

export const getTravelOrderStopLoadStopInfoByID = (params: StopInfoParams): TOStopLoadStopInfo =>
    getTravelOrderStopLoadStopInfo(getTravelOrderStopByID(params));
