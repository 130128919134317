import React from 'react';
import {phoneNumberWithBraces} from 'utils';
import Tooltip from 'components/ui/Tooltip';

import {Dispatcher} from 'core/entities/Dispatcher/types';

import styles from './dispatchersExtension.module.scss';

type OwnProps = {
    extension: Dispatcher['extension'];
    mainPhone?: Dispatcher['main_phone'];
    isTooltip?: boolean;
};

const DispatchersExtension: React.FC<OwnProps> = ({extension, mainPhone = null, isTooltip = false}) => {
    if (isTooltip && mainPhone) {
        return (
            <div className={styles.phone}>
                <Tooltip tooltipContent={phoneNumberWithBraces(mainPhone)}>
                    <em>
                        <i className="fa fa-phone-square" aria-hidden="true" />
                    </em>
                </Tooltip>
                <span>ext: {extension || '-'}</span>
            </div>
        );
    }
    return (
        <span className={styles.item}>
            <i className="fa fa-phone-square" aria-hidden="true" />
            <span>ext: {extension || '-'}</span>
        </span>
    );
};

export default DispatchersExtension;
