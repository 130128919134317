import React from 'react';

type OwnProps = {
    label: string;
};
const InfoItem: React.FC<OwnProps> = (props) => {
    const {label, children} = props;
    return (
        <div className="row mt-1">
            <div className="col-3">{label}:</div>
            <div className="col-9">{children}</div>
        </div>
    );
};

export default InfoItem;
