import curryRight from 'lodash/curryRight';

import {Dispatcher} from 'core/entities/Dispatcher/types';

import {
    ACCOUNTING,
    ADMIN,
    BOOKING_DISPATCHER,
    CHECKING_DISPATCHER,
    FULL_CIRCLE,
    HR,
    OPERATION_DISPATCHER,
    SAFETY,
    SALES,
    TEAMLEAD,
    UserRole,
} from 'utils/data/roles';

export const checkHasRole = (dispatcher: Partial<Dispatcher>, role: UserRole): boolean =>
    (dispatcher?.roles || []).some((dispatcherRole) => dispatcherRole.name === role);

const checkHasRoleCurried = curryRight(checkHasRole);

export const checkHasOperationDispatcherRole = checkHasRoleCurried(OPERATION_DISPATCHER);
export const checkHasBookingDispatcherRole = checkHasRoleCurried(BOOKING_DISPATCHER);
export const checkHasTeamleadRole = checkHasRoleCurried(TEAMLEAD);
export const checkHasAccountingRole = checkHasRoleCurried(ACCOUNTING);
export const checkHasHrRole = checkHasRoleCurried(HR);
export const checkHasSafetyRole = checkHasRoleCurried(SAFETY);
export const checkHasAdminRole = checkHasRoleCurried(ADMIN);
export const checkHasSalesRole = checkHasRoleCurried(SALES);
export const checkHasCheckingDispatcherRole = checkHasRoleCurried(CHECKING_DISPATCHER);
export const checkHasFullCircleDispatcherRole = checkHasRoleCurried(FULL_CIRCLE);
