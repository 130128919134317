import {PayPeriod, PayRecord, SettlementOwner} from 'core/entities/Settlement/types';
import {findPayPeriodPayRecordByOwner, getSettlementOwner} from 'core/entities/Settlement';
import {CurrencyValue} from 'utils/data/currency';

type Payment = {amount: number; currency: CurrencyValue};

type PayRecordData = Pick<PayRecord, 'id' | 'settlements' | 'total_payments'>;

export const extendPayRecordPayments = (payPeriod: PayRecord, newPayment: Payment): PayRecord => {
    const {total_payments: totalPayments = []} = payPeriod;
    const existPaymentWithSameCurrency = totalPayments.find((existsPayment) => {
        return existsPayment.currency === newPayment.currency;
    });
    if (existPaymentWithSameCurrency) {
        return {
            ...payPeriod,
            total_payments: totalPayments.map((existsPayment) => {
                if (existsPayment.currency === newPayment.currency) {
                    const sum = existsPayment.amount + newPayment.amount;
                    const updatedPayment = {
                        ...existsPayment,
                        // eslint-disable-next-line no-magic-numbers
                        amount: Number(sum.toFixed(2)),
                    };
                    return updatedPayment;
                }
                return existsPayment;
            }),
        };
    }
    return {...payPeriod, total_payments: [...totalPayments, newPayment]};
};

export const getPayRecordData = (
    settlement: SettlementOwner,
    period: PayPeriod,
): {owner: any; payRecord?: PayRecordData} | undefined => {
    const owner = getSettlementOwner(settlement);
    if (!owner) {
        return undefined;
    }
    const payRecord = findPayPeriodPayRecordByOwner(period, owner);
    if (payRecord) {
        return {
            owner,
            payRecord,
        };
    }
    return {owner};
};
