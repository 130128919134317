import React from 'react';
import {useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';

import {SettlementOwner, SettlementCarrier} from 'core/entities/Settlement/types';

import Button from 'components/ui/Buttons/Button/index';
import {SettlementLink} from 'components/ui/Links';

import {isListPage} from 'pages/Settlements/selectors';

import getText from 'utils/getText';
import {getLoadInfo} from './utils';

interface OwnProps {
    data: {settlement: SettlementOwner | SettlementCarrier};
    handlers: {onApproveSettlement()};
    onClose();
}

const MessageForList = ({settlement, onClose}): JSX.Element => {
    return (
        <p>
            If you are not sure, please{' '}
            <span onClick={onClose}>
                <SettlementLink settlement={settlement} content="check information here" className="main-link" />
            </span>
            .
        </p>
    );
};

const MessageForView = (): JSX.Element => {
    return <p>If you are not sure, please check information.</p>;
};

const ApproveSettlementModal = (props: OwnProps): JSX.Element => {
    const {
        onClose,
        data: {settlement},
        handlers: {onApproveSettlement},
    } = props;
    const isOpenedFromList = useSelector(isListPage) as boolean;
    const {loadNumber} = getLoadInfo({settlement, isNewFormat: isOpenedFromList});
    const rateConfirmation = getText('rateConfirmation');
    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Approving Information</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <p className="mb10">
                            By approving the information you agree that all data in the <strong>trip summary</strong> of{' '}
                            <br />
                            <strong>Travel Order #{settlement.settlement_number}</strong> corresponds to the all
                            information in:
                        </p>
                        <ul className="mb10">
                            <li>
                                - customer’s <strong>{rateConfirmation}</strong> for{' '}
                                <strong>Load(s) #{loadNumber}</strong>;
                            </li>
                            <li>
                                - <strong>signed PoD;</strong>
                            </li>
                            <li>- another attached documents.</li>
                        </ul>
                        {isOpenedFromList ? (
                            <MessageForList settlement={settlement} onClose={onClose} />
                        ) : (
                            <MessageForView />
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} buttonSize="small" colorTheme="grey">
                    Cancel
                </Button>
                <Button onClick={onApproveSettlement} buttonSize="small" colorTheme="blue">
                    APPROVE
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ApproveSettlementModal;
