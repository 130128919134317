import React from 'react';

import {Message} from 'widgets/Chat/types';

import splitTextByTitleAndBody from 'utils/splitTextByTitleAndBody';

import styles from './messageNotificationItem.module.scss';

interface OwnProps {
    message: Message;
}

const MessageNotificationItem: React.FC<OwnProps> = ({message}) => {
    const {text} = message;

    if (!text) {
        return null;
    }

    const [notificationTitle, notificationBody] = splitTextByTitleAndBody(text);

    return (
        <div className={styles.notification}>
            <div className="notification__title">
                <strong>{notificationTitle}</strong>
            </div>
            <div className="notification__desc">{notificationBody}</div>
        </div>
    );
};

export default MessageNotificationItem;
