import isNull from 'lodash/isNull';
import some from 'lodash/some';

import {TRAVEL_ORDER_ACTIONS} from 'core/entities/TravelOrder/constants/travelOrderActions';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';

type Actions = typeof TRAVEL_ORDER_ACTIONS[keyof typeof TRAVEL_ORDER_ACTIONS];
type ClearedAction = {action: Actions; locked: boolean};

const isExist = (params: {actions: ClearedAction[]; action: Actions; locked: boolean}): boolean =>
    some(params.actions, {action: params.action, locked: params.locked});
const isSomeActionLocked = (params: {actions: ClearedAction[]; action: Actions}): boolean =>
    isExist({...params, locked: true});
const isSomeActionUnLocked = (params: {actions: ClearedAction[]; action: Actions}): boolean =>
    isExist({...params, locked: false});
const isSomeActionExist = (params: {actions: ClearedAction[]; action: Actions}): boolean =>
    isSomeActionUnLocked({...params}) || isSomeActionLocked({...params});

const isAllowCheckOut = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.CHECK_OUT});
const isAllowCheckIn = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.CHECK_IN});
const isAllowSignPOD = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.SIGN_POD});
const isAllowAddBOL = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.ADD_BOL});

const isAllowCancelFinishConfirm = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL_FINISH_CONFIRM});
const isAllowFinishTravelOrder = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.FINISH});
const isAllowCancelCheckOut = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL_CHECK_OUT});
const isAllowCancelCheckIn = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL_CHECK_IN});
const isAllowCancelAddBOL = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL_BOL});
const isAllowCancelSignPOD = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL_POD});

const isAllowEdit = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.EDIT});
const isAllowCancel = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL});
const isAllowCancelDryRun = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL_DRY_RUN});
const isAllowDryRun = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.DRY_RUN});
const isAllowDispatch = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.DISPATCH});
const isAllowReDispatch = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.RE_DISPATCH});
const isAllowSetPay = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.SET_PAY});
const isAllowSendFiles = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.SEND_FILES});
const isAllowOpenRC = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionUnLocked({...params, action: TRAVEL_ORDER_ACTIONS.OPEN_RC});

const isEditExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.EDIT});
const isDryRunExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.DRY_RUN});
const isCancelExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL});
const isCancelDryRunExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL_DRY_RUN});
const isCancelAddBOLExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL_BOL});
const isCancelCheckOutExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL_CHECK_OUT});
const isCancelCheckInExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL_CHECK_IN});
const isCancelSignPODExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL_POD});
const isCancelFinishExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.CANCEL_FINISH_CONFIRM});
const isFinishExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.FINISH});
const isSendFilesExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.SEND_FILES});
const isOpenRCExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.OPEN_RC});
const isDispatchExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.DISPATCH});
const isReDispatchExist = (params: {actions: ClearedAction[]}): boolean =>
    isSomeActionExist({...params, action: TRAVEL_ORDER_ACTIONS.RE_DISPATCH});

type ActionsMeta = {
    // allow ↓
    allowCancelFinish: ReturnType<typeof isAllowCancelFinishConfirm>;
    allowCancelCheckOut: ReturnType<typeof isAllowCancelCheckOut>;
    allowCancelCheckIn: ReturnType<typeof isAllowCancelCheckIn>;
    allowCancelSignPOD: ReturnType<typeof isAllowCancelSignPOD>;
    allowCancelAddBOL: ReturnType<typeof isAllowCancelAddBOL>;
    allowCancelDryRun: ReturnType<typeof isAllowCancelDryRun>;

    allowFinish: ReturnType<typeof isAllowFinishTravelOrder>;

    allowCheckOut: ReturnType<typeof isAllowCheckOut>;
    allowCheckIn: ReturnType<typeof isAllowCheckIn>;
    allowSignPOD: ReturnType<typeof isAllowSignPOD>;
    allowAddBOL: ReturnType<typeof isAllowAddBOL>;

    allowReDispatch: ReturnType<typeof isAllowReDispatch>;
    allowSendFiles: ReturnType<typeof isAllowSendFiles>;
    allowDispatch: ReturnType<typeof isAllowDispatch>;
    allowSetPay: ReturnType<typeof isAllowSetPay>;
    allowOpenRC: ReturnType<typeof isAllowOpenRC>;
    allowDryRun: ReturnType<typeof isAllowDryRun>;
    allowCancel: ReturnType<typeof isAllowCancel>;
    allowEdit: ReturnType<typeof isAllowEdit>;

    // exist ↓
    isCancelCheckOutExist: ReturnType<typeof isCancelCheckOutExist>;
    isCancelCheckInExist: ReturnType<typeof isCancelCheckInExist>;
    isCancelSignPODExist: ReturnType<typeof isCancelSignPODExist>;
    isCancelFinishExist: ReturnType<typeof isCancelFinishExist>;
    isCancelAddBOLExist: ReturnType<typeof isCancelAddBOLExist>;
    isCancelDryRunExist: ReturnType<typeof isCancelDryRunExist>;
    isReDispatchExist: ReturnType<typeof isReDispatchExist>;
    isSendFilesExist: ReturnType<typeof isSendFilesExist>;
    isDispatchExist: ReturnType<typeof isDispatchExist>;
    isOpenRCExist: ReturnType<typeof isOpenRCExist>;
    isCancelExist: ReturnType<typeof isCancelExist>;
    isFinishExist: ReturnType<typeof isFinishExist>;
    isDryRunExist: ReturnType<typeof isDryRunExist>;
    isEditExist: ReturnType<typeof isEditExist>;
};

const getActionsMeta = (params: {actions: ClearedAction[]}): ActionsMeta => ({
    // allow ↓
    allowCancelFinish: isAllowCancelFinishConfirm(params),
    allowCancelCheckOut: isAllowCancelCheckOut(params),
    allowCancelSignPOD: isAllowCancelSignPOD(params),
    allowCancelCheckIn: isAllowCancelCheckIn(params),
    allowCancelAddBOL: isAllowCancelAddBOL(params),
    allowCancelDryRun: isAllowCancelDryRun(params),

    allowFinish: isAllowFinishTravelOrder(params),

    allowCheckOut: isAllowCheckOut(params),
    allowSignPOD: isAllowSignPOD(params),
    allowCheckIn: isAllowCheckIn(params),
    allowAddBOL: isAllowAddBOL(params),

    allowReDispatch: isAllowReDispatch(params),
    allowSendFiles: isAllowSendFiles(params),
    allowDispatch: isAllowDispatch(params),
    allowSetPay: isAllowSetPay(params),
    allowCancel: isAllowCancel(params),
    allowDryRun: isAllowDryRun(params),
    allowOpenRC: isAllowOpenRC(params),
    allowEdit: isAllowEdit(params),

    // exist ↓
    isCancelCheckOutExist: isCancelCheckOutExist(params),
    isCancelCheckInExist: isCancelCheckInExist(params),
    isCancelSignPODExist: isCancelSignPODExist(params),
    isCancelAddBOLExist: isCancelAddBOLExist(params),
    isCancelFinishExist: isCancelFinishExist(params),
    isCancelDryRunExist: isCancelDryRunExist(params),
    isReDispatchExist: isReDispatchExist(params),
    isSendFilesExist: isSendFilesExist(params),
    isDispatchExist: isDispatchExist(params),
    isOpenRCExist: isOpenRCExist(params),
    isCancelExist: isCancelExist(params),
    isFinishExist: isFinishExist(params),
    isDryRunExist: isDryRunExist(params),
    isEditExist: isEditExist(params),
});

export const getStopActions = (params: StopInfoParams): ActionsMeta => {
    const {stopID, travelOrder} = params;

    const filteredActions = (travelOrder?.actions || []).filter((item) => item.stopId === stopID);
    const pickedActions = filteredActions.map((item) => ({action: item.action, locked: item.locked}));

    return getActionsMeta({actions: pickedActions});
};

export const getDeadheadActions = (params: Pick<StopInfoParams, 'travelOrder'>): ActionsMeta => {
    const {travelOrder} = params;

    const filteredActions = (travelOrder?.actions || []).filter((item) => isNull(item.stopId));
    const pickedActions = filteredActions.map((item) => ({action: item.action, locked: item.locked}));

    return getActionsMeta({actions: pickedActions});
};

export const getCommonActions = (params: Pick<StopInfoParams, 'travelOrder'>): ActionsMeta => {
    const {travelOrder} = params;

    const pickedActions = (travelOrder?.actions || []).map((item) => ({action: item.action, locked: item.locked}));

    return getActionsMeta({actions: pickedActions});
};
