import {useSelector} from 'react-redux';
import has from 'lodash/has';

import {getPermissions} from 'store/reducers/permissions/selectors';

import {PermissionsValues} from 'core/entities/Permissions/types';

const useCheckIfUserHasPermission = (permission: PermissionsValues): boolean => {
    const permissions = useSelector(getPermissions);

    return has(permissions, permission);
};

export default useCheckIfUserHasPermission;
