export const RECEIVED_QUOTES = 'LOAD_BOARD_PAGE_RECEIVED_QUOTES';
export const RECEIVED_QUOTE_DRIVERS_RECEIVERS = 'LOAD_BOARD_PAGE_RECEIVED_QUOTE_DRIVERS_RECEIVERS';
export const STARTED_SEND_QUOTE = 'LOAD_BOARD_PAGE_STARTED_SEND_QUOTE';
export const COMPLETED_SEND_QUOTE = 'LOAD_BOARD_PAGE_COMPLETED_SEND_QUOTE';
export const TOGGLE_EXPAND_QUOTE_OFFERS = 'LOAD_BOARD_PAGE_TOGGLE_EXPAND_QUOTE_OFFERS';
export const RESEND_QUOTE = 'LOAD_BOARD_PAGE_RESEND_QUOTE';
export const ARCHIVE_QUOTE = 'LOAD_BOARD_PAGE_ARCHIVE_QUOTE';
export const ACTIVATED_QUOTE_WS = 'LOAD_BOARD_PAGE_ACTIVATED_QUOTE_WS';

export const SET_SEARCH_PARAMS = 'LOAD_BOARD_PAGE_SET_SEARCH_PARAMS';
export const SET_SEARCH_PARAMS_QUOTE_NUMBER = 'LOAD_BOARD_PAGE_SET_SEARCH_PARAMS_QUOTE_NUMBER';
export const CHANGED_QUOTES_PAGINATION_PAGE = 'LOAD_BOARD_PAGE_CHANGED_QUOTES_PAGINATION_PAGE';
export const CHANGED_QUOTES_PAGINATION_COUNT = 'LOAD_BOARD_PAGE_CHANGED_QUOTES_PAGINATION_COUNT';
export const CHANGED_QUOTES_LIST_TYPE = 'LOAD_BOARD_PAGE_CHANGED_QUOTES_LIST_TYPE';

export const RECEIVED_QUOTE_GENERAL_NOTES = 'LOAD_BOARD_PAGE_RECEIVED_QUOTE_GENERAL_NOTES';
export const CREATED_QUOTE_GENERAL_NOTE = 'LOAD_BOARD_PAGE_CREATED_QUOTE_GENERAL_NOTE';
export const CREATED_QUOTE_GENERAL_NOTE_WS = 'LOAD_BOARD_PAGE_CREATED_QUOTE_GENERAL_NOTE_WS';
export const DELETED_QUOTE_GENERAL_NOTE_WS = 'LOAD_BOARD_PAGE_DELETED_QUOTE_GENERAL_NOTE_WS';
export const DELETED_QUOTE_GENERAL_NOTE = 'LOAD_BOARD_PAGE_DELETED_QUOTE_GENERAL_NOTE_WS';

export const RECEIVED_DRIVER_OFFERS = 'LOAD_BOARD_RECEIVED_DRIVER_OFFERS';
export const ADDED_DRIVER_OFFER = 'LOAD_BOARD_PAGE_ADDED_DRIVER_OFFER';
export const ADDED_DRIVER_OFFER_WS = 'LOAD_BOARD_PAGE_ADDED_DRIVER_OFFER_WS';
export const UPDATED_DRIVER_OFFER = 'LOAD_BOARD_PAGE_UPDATED_DRIVER_OFFER';
export const READ_DRIVER_OFFER = 'LOAD_BOARD_PAGE_READ_DRIVER_OFFER';
export const UPDATED_DRIVER_OFFER_WS = 'LOAD_BOARD_PAGE_UPDATED_DRIVER_OFFER_WS';
export const EXPIRED_DRIVER_OFFER = 'LOAD_BOARD_PAGE_EXPIRED_DRIVER_OFFER';
export const CHANGED_DRIVER_OFFER_STATUS = 'LOAD_BOARD_PAGE_CHANGED_DRIVER_OFFER_STATUS';
export const CHANGED_DRIVER_OFFER_STATUS_WS = 'LOAD_BOARD_PAGE_CHANGED_DRIVER_OFFER_STATUS_WS';
export const DEACTIVATED_DRIVER_OFFER = 'LOAD_BOARD_PAGE_DEACTIVATED_DRIVER_OFFER';
export const DEACTIVATED_DRIVER_OFFER_WS = 'LOAD_BOARD_PAGE_DEACTIVATED_DRIVER_OFFER_WS';

export const CLEAR_STATE = 'LOAD_BOARD_PAGE_CLEAR_STATE';
