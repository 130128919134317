import TravelOrder from 'core/entities/TravelOrder/types';
import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';

import * as stopModules from 'core/entities/TravelOrder/modules/travelOrderStops';
import {getTravelOrderDistances} from 'core/entities/TravelOrder/modules/travelOrderDistances';

import {getDistanceSettings} from 'utils/distance';

type FirstLastStopInfo = {
    firstPickup: TravelOrderStop;
    lastDelivery: TravelOrderStop;
    firstPickupAddress: string;
    lastDeliveryAddress: string;
    firstPickupNumber?: number;
    lastDeliveryNumber?: number;
    pickupsTotalCount?: number;
    deliveriesTotalCount?: number;
    distance: string;
    isShowTotalPickups?: boolean;
};

export const getTravelOrderFirstLastStopsInfo = (travelOrder?: TravelOrder | null): FirstLastStopInfo | null => {
    const firstPickupStopID = stopModules.getTravelOrderFirstPickUpStopID(travelOrder);
    const lastDeliveryStopID = stopModules.getTravelOrderLastDeliveryStopID(travelOrder);

    const firstPickup = stopModules.getTravelOrderStopByID({stopID: firstPickupStopID, travelOrder});
    const lastDelivery = stopModules.getTravelOrderStopByID({stopID: lastDeliveryStopID, travelOrder});

    if (!firstPickup || !lastDelivery) {
        return null;
    }

    const {facilityLocation: firstPickupAddress} = stopModules.getTravelOrderStopLoadStopInfo(firstPickup);
    const {facilityLocation: lastDeliveryAddress} = stopModules.getTravelOrderStopLoadStopInfo(lastDelivery);

    const {orderNumberByType: firstPickupNumber} = stopModules.getTravelOrderStopInfo(firstPickup);
    const {orderNumberByType: lastDeliveryNumber} = stopModules.getTravelOrderStopInfo(lastDelivery);

    const {totalStopsByType: pickupsTotalCount} = stopModules.getTravelOrderStopInfo(firstPickup);
    const {totalStopsByType: deliveriesTotalCount} = stopModules.getTravelOrderStopInfo(lastDelivery);

    const isShowTotalPickups = pickupsTotalCount ? pickupsTotalCount > 1 : undefined;

    const {shortUnit} = getDistanceSettings();
    const {stopsMiles} = getTravelOrderDistances(travelOrder);

    const distance = stopsMiles?.distanceWithShortUnit || `0 ${shortUnit}`;

    return {
        firstPickup,
        lastDelivery,
        firstPickupAddress,
        lastDeliveryAddress,
        firstPickupNumber,
        lastDeliveryNumber,
        pickupsTotalCount,
        deliveriesTotalCount,
        distance,
        isShowTotalPickups,
    };
};
