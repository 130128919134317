import isEmpty from 'lodash/isEmpty';

import {TIME_FRAME_FCFS} from 'core/entities/Load/constants';

import {Address, QuoteInitialValues, LoadStop} from 'pages/LoadBoard/types';
import {isDestinationDateGreaterOriginDate} from 'pages/LoadBoard/utils';

const getStopAddress = (value: LoadStop): Address | undefined => value.address;
const getPieces = (values: QuoteInitialValues): number | undefined => values.load?.freight?.pieces;
const getWeight = (values: QuoteInitialValues): number | undefined => values.load?.freight?.weight;
const getTruckTypes = (values: QuoteInitialValues): string[] | undefined => values.truck?.types;
const isExistsReceiversAllowedToSend = (values: QuoteInitialValues): boolean => {
    if (!values?.receivers) {
        return true;
    }
    return values?.receivers.filter((r) => r.isReceiveQuoteAllowed).length > 0;
};

export default function (values: QuoteInitialValues): any {
    const errors = {
        receivers: '',
        load: {
            freight: {},
            stops: [],
        },
        truck: {
            payload: {},
        },
    } as Record<keyof QuoteInitialValues, any>;

    if (isEmpty(values)) {
        return {};
    }

    values.load.stops.forEach((stop, index) => {
        const stopError = {address: {cityLine: ''}, dateTo: ''};
        if (isEmpty(getStopAddress(stop))) {
            stopError.address.cityLine = 'Please enter location';
        }

        if (stop.timeFrame === TIME_FRAME_FCFS) {
            const originDate = isDestinationDateGreaterOriginDate(stop.dateFrom, stop.dateTo);
            if (originDate) {
                stopError.dateTo = 'Date From older than Date To ';
            }
        }

        if (!isEmpty(stopError)) {
            errors.load.stops[index] = stopError;
        }
    });

    if (isEmpty(getPieces(values))) {
        errors.load.freight.pieces = 'Please, enter the amount of current type of the freight.';
    }

    if (isEmpty(getWeight(values))) {
        errors.load.freight.weight = 'Please, enter weight of the freight.';
    }

    if (isEmpty(getTruckTypes(values))) {
        errors.truck.types = 'Required';
    }
    if (!isExistsReceiversAllowedToSend(values)) {
        errors.receivers = 'Please choose truck(s) to which you want to send quote';
    }

    return errors;
}
