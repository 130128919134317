import {Networks} from 'core/entities/Driver/types';
import {LOCATION_EVENTS_TYPES} from 'core/entities/LocationEvents/constants';
import TravelOrderDefaultPresets from 'core/entities/TravelOrder/types/TravelOrderDefaultPresets';

import {UserRoleOptions} from 'utils/data/roles';

import SelectOption from 'types/SelectOption';

import type {Registry, RegistryActionsTypes} from './actionCreators';
import * as types from './actionTypes';
import {transformNetworksBenefitsToSelectOptions} from './mappers';

export type RegistryState = {
    truckAdditionalEquipment: {label: string; value: string}[];
    truckBasicEquipment: {label: string; value: string}[];
    travelOrderDefaultPresets: TravelOrderDefaultPresets;
    loadSourcesOptions: {label: string; value: string}[];
    supportedFileTypes: Registry['supportedFileTypes'];
    networksOptions: SelectOption['options'];
    userFilterRoleOptions: UserRoleOptions;
    currency: Registry['currency'] | null;
    userRoleOptions: UserRoleOptions;
    networksBenefits: Networks[];
};

const defaultState: RegistryState = {
    truckAdditionalEquipment: [],
    userFilterRoleOptions: [],
    truckBasicEquipment: [],
    loadSourcesOptions: [],
    networksBenefits: [],
    userRoleOptions: [],
    networksOptions: [],
    supportedFileTypes: [],
    currency: null,
    travelOrderDefaultPresets: {
        distanceToStopOnDriverCheckInForAlertEnable: 0,
        toPickUpTimer: '00:00',
        toDeliveryUpTimer: '00:00',
        etaMarginForDelay: '00:00',
        averageSpeedFrom: 0,
        averageSpeedTo: 0,
        averageSpeedRangeFrom: 0,
        averageSpeedRangeTo: 0,
        differMiles: 0,
        brokerUpdateText: [],
        brokerUpdateType: {
            label: 'Location Update',
            value: LOCATION_EVENTS_TYPES.CHECK_CALL,
        },
    },
};

const registry = (state = defaultState, action: RegistryActionsTypes): RegistryState => {
    switch (action.type) {
        case types.RECEIVED_REGISTRY: {
            return {
                ...state,
                supportedFileTypes: action.payload.supportedFileTypes,
                loadSourcesOptions: action.payload.loadSources,
                currency: action.payload.currency,
            };
        }

        case types.RECEIVED_USER_ROLE_OPTIONS: {
            return {
                ...state,
                userFilterRoleOptions: action.payload.userFilterRoleDropDown,
                userRoleOptions: action.payload.userUpdateRoleDropDown,
            };
        }

        case types.RECEIVED_TRAVEL_ORDER_PRESETS: {
            return {
                ...state,
                travelOrderDefaultPresets: action.payload,
            };
        }

        case types.RECEIVED_NETWORKS_BENEFITS: {
            return {
                ...state,
                networksBenefits: action.payload.networks,
            };
        }

        case types.RECEIVED_NETWORKS_OPTIONS: {
            return transformNetworksBenefitsToSelectOptions({state, networks: action.payload.networks});
        }

        case types.TRUCK_BASIC_EQUIPMENT_RECEIVED: {
            return {
                ...state,
                truckBasicEquipment: action.payload.basicEquipment,
            };
        }

        case types.TRUCK_ADDITIONAL_EQUIPMENT_RECEIVED: {
            return {
                ...state,
                truckAdditionalEquipment: action.payload.additionalEquipment,
            };
        }

        // no default
    }

    const _exhaustiveCheck: never = action;

    return state;
};

export default registry;
