import {getRemainingTimeTo} from 'utils/dateTime';

type CheckTruckInsuranceExpirationReturn = {
    severity: 'error' | 'warning' | null;
    isInErrorOrWarningThreshold: boolean;
    daysToExpiration: number;
    isExpired: boolean;
};

export const checkTruckInsuranceExpiration = (expirationDate?: string | null): CheckTruckInsuranceExpirationReturn => {
    const defaultResult: CheckTruckInsuranceExpirationReturn = {
        isInErrorOrWarningThreshold: true,
        daysToExpiration: 0,
        isExpired: true,
        severity: 'error',
    };

    if (!expirationDate) {
        return defaultResult;
    }

    const {isExpired = false, days} = getRemainingTimeTo(expirationDate);

    const expirationErrorStartThreshold = 0;
    const expirationWarningThreshold = 5;
    const expirationErrorThreshold = 1;

    const isInErrorThreshold = days > expirationErrorStartThreshold && days < expirationErrorThreshold;
    const isInWarningThreshold = days > expirationErrorThreshold && days < expirationWarningThreshold;

    const severity = ((isExpired || isInErrorThreshold) && 'error') || (isInWarningThreshold && 'warning') || null;

    const isInErrorOrWarningThreshold = isInErrorThreshold || isInWarningThreshold;

    return {
        daysToExpiration: Math.ceil(days || 0),
        isInErrorOrWarningThreshold,
        isExpired,
        severity,
    };
};
