import TextareaAutosize from 'react-textarea-autosize';
import React, {useEffect, useRef} from 'react';
import {FormGroup} from 'react-bootstrap';

const MAX_ROWS = 10;

const ResizableTextarea = (props) => {
    const {input, onSubmitByEnter, autoFocus, ...rest} = props;
    const inputNode = useRef<any>(null);

    const addNewRowToExistingText = (text) => {
        props.input.onChange(`${text}\n`);
    };

    const handleKeyPress = (event, onSubmit) => {
        const ENTER_CODE = 13;
        const isOnlyEnter = !event.ctrlKey && !event.shiftKey && event.charCode === ENTER_CODE;
        const isCtrlPlusEnter = event.ctrlKey && event.charCode === ENTER_CODE;
        const text = event.target.value;

        if (isOnlyEnter) {
            event.preventDefault();
            onSubmit();
        } else if (isCtrlPlusEnter) {
            addNewRowToExistingText(text);
        } else {
            props.input.onChange(text);
        }
    };

    useEffect(function () {
        if (autoFocus) {
            inputNode?.current?.focus();
        }
    }, []);

    return (
        <FormGroup controlId="formControlsTextarea">
            <TextareaAutosize
                {...input}
                {...rest}
                value={input.value}
                ref={inputNode}
                placeholder="type your message here"
                maxRows={MAX_ROWS}
                onKeyPress={(event) => handleKeyPress(event, onSubmitByEnter)}
                className="form-control"
                autoFocus
            />
        </FormGroup>
    );
};

export default ResizableTextarea;
