import React from 'react';
import {Field} from 'redux-form';
import LengthMeasureInput from 'components/ui/Form/LengthMeasureInput';
import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';

type OwnProps = {
    units: string;
};

const DimensionWidth: React.FC<OwnProps> = (props) => {
    const {units} = props;
    const {getTruckDimensionWidthFieldName} = getFormFieldNames();
    const widthFieldName = getTruckDimensionWidthFieldName();
    return (
        <>
            <Field name={widthFieldName} component={LengthMeasureInput} label="width" units={units} />
        </>
    );
};
export default DimensionWidth;
