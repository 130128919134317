import React from 'react';
import {Quote} from 'core/entities/Quote/types';
import QuoteNumber from './components/QuoteNumber';
import QuoteStopsInfo from './components/QuoteStopsInfo';
import QuoteFreightInfo from './components/QuoteFreightInfo';
import QuoteTruckAndPaymentInfo from './components/QuoteTruckAndPaymentInfo';

import styles from './styles.module.scss';

const QuoteRow: React.FC<{quote: Quote}> = (props) => {
    const {quote} = props;
    return (
        <div className={`row ${styles.wrapper}`}>
            <QuoteNumber quote={quote} />
            <QuoteStopsInfo quote={quote} />
            <QuoteFreightInfo quote={quote} />
            <QuoteTruckAndPaymentInfo quote={quote} />
        </div>
    );
};

export default QuoteRow;
