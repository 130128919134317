import {
    WEB_SOCKET_TRUCKS_COORDINATES_RECEIVED,
    WEB_SOCKET_TRUCKS_RESERVE_CANCELED,
    WEB_SOCKET_TRUCKS_RESERVE_RECEIVED,
    WEB_SOCKET_TRUCKS_UPDATE_RECEIVED,
} from './actionTypes';

export function trucksUpdateReceived(message) {
    return {
        type: WEB_SOCKET_TRUCKS_UPDATE_RECEIVED,
        payload: message,
    };
}

export function trucksCoordinatesReceived(message) {
    return {
        type: WEB_SOCKET_TRUCKS_COORDINATES_RECEIVED,
        payload: message,
    };
}

export function trucksReserveChanged(message) {
    return {
        type: WEB_SOCKET_TRUCKS_RESERVE_RECEIVED,
        payload: message,
    };
}

export function trucksReserveCanceled(message) {
    return {
        type: WEB_SOCKET_TRUCKS_RESERVE_CANCELED,
        payload: message,
    };
}
