import React from 'react';
import TruckType from './components/TruckType';
import CrossBorder from './components/CrossBorder';
import Signs from './components/Signs';
import Team from './components/Team';
import Equipment from './components/Equipment';
import Certificate from './components/Certificate';
import Citizenship from './components/Citizenship';
import Wrapper from '../common/Wrapper';
import Payload from './components/Payload';
import Dimension from './components/Dimension';
import TruckStatus from './components/TruckStatus';

const TruckInfo: React.FC = () => {
    return (
        <Wrapper>
            <div className="row align-items-center pb-3 pt-2">
                <div className="col-12 mb-3 mt-3">
                    <h3>Truck Info</h3>
                </div>
                <div className="col-12 mb-auto">
                    <TruckStatus />
                </div>
                <div className="col-6 mb-auto">
                    <TruckType />
                </div>
                <div className="col-6 mb-auto">
                    <Certificate />
                </div>
                <div className="col-6 mb-auto">
                    <Equipment />
                </div>
                <div className="col-6 mb-auto">
                    <Citizenship />
                </div>
                <div className="col-6 mb-auto">
                    <Payload />
                </div>
                <div className="col-6 mb-auto">
                    <Dimension />
                </div>
                <div className="col-2">
                    <CrossBorder />
                </div>
                <div className="col-2">
                    <Signs />
                </div>
                <div className="col-2">
                    <Team />
                </div>
            </div>
        </Wrapper>
    );
};

export default React.memo(TruckInfo);
