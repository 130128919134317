import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';

import {QuoteReceiver} from 'pages/LoadBoard/types/formTypes';
import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';

import getFormFieldNames from '../utils/getFormFieldNames';

interface Result {
    setStopTimeZone: (zone: string, index: number) => void;
    setTrucksReceivers: (trucksReceivers: QuoteReceiver[]) => void;
    setDriverRPM: (rpm: number) => void;
    setRouteDistance: (distance: number) => void;
    setTruckPayloadUnit: (unit: string) => void;
    setTruckPayloadFieldName: (value: number | null) => void;
    setTruckDimensionUnit: (unit: string) => void;
    setTuckDimensionLength: (value: number | null) => void;
    setTuckDimensionWidth: (value: number | null) => void;
    setTuckDimensionHeight: (value: number | null) => void;
    setFreightWeightUnit: (unit: string) => void;
    setFreightWeightFieldName: (value: number | null) => void;
    setFreightDimensionUnit: (unit: string) => void;
    setFreightDimensionLengthFieldName: (value: string | null, index: number) => void;
    setFreightDimensionWidthFieldName: (value: string | null, index: number) => void;
    setFreightDimensionHeightFieldName: (value: string | null, index: number) => void;
}

export default function (): Result {
    const {
        getStopTimezoneFieldName,
        getReceiversListFiledName,
        getDriverRPMFieldName,
        getRouteDistanceFieldName,
        getLoadFreightLengthUnitFieldName,
        getLoadFreightWeightFieldName,
        getLoadFreightDimensionUnitFieldName,
        getLoadFreightDimensionLengthFieldName,
        getLoadFreightDimensionWidthFieldName,
        getLoadFreightDimensionHeightFieldName,
        getTruckPayloadUnitFieldName,
        getTruckPayloadValueFieldName,
        getTruckDimensionUnitFieldName,
        getTruckDimensionLengthFieldName,
        getTruckDimensionWidthFieldName,
        getTruckDimensionHeightFieldName,
    } = getFormFieldNames();
    const dispatch = useChangeReduxFormValue(FORM_NAME);

    return {
        setStopTimeZone: (timeZone, index) => dispatch(getStopTimezoneFieldName(index), timeZone),
        setTrucksReceivers: (trucksReceivers) => dispatch(getReceiversListFiledName(), trucksReceivers),
        setDriverRPM: (rpm) => dispatch(getDriverRPMFieldName(), rpm),
        setRouteDistance: (distance) => dispatch(getRouteDistanceFieldName(), distance),
        setFreightWeightUnit: (unit) => dispatch(getLoadFreightLengthUnitFieldName(), unit),
        setFreightWeightFieldName: (value) => dispatch(getLoadFreightWeightFieldName(), value),
        setFreightDimensionUnit: (unit) => dispatch(getLoadFreightDimensionUnitFieldName(), unit),
        setFreightDimensionLengthFieldName: (value, index) =>
            dispatch(getLoadFreightDimensionLengthFieldName(index), value),
        setFreightDimensionWidthFieldName: (value, index) =>
            dispatch(getLoadFreightDimensionWidthFieldName(index), value),
        setFreightDimensionHeightFieldName: (value, index) =>
            dispatch(getLoadFreightDimensionHeightFieldName(index), value),
        setTruckPayloadUnit: (unit) => dispatch(getTruckPayloadUnitFieldName(), unit),
        setTruckPayloadFieldName: (value) => dispatch(getTruckPayloadValueFieldName(), value),
        setTruckDimensionUnit: (unit) => dispatch(getTruckDimensionUnitFieldName(), unit),
        setTuckDimensionLength: (value) => dispatch(getTruckDimensionLengthFieldName(), value),
        setTuckDimensionWidth: (value) => dispatch(getTruckDimensionWidthFieldName(), value),
        setTuckDimensionHeight: (value) => dispatch(getTruckDimensionHeightFieldName(), value),
    };
}
