import React from 'react';
import {DispatcherLink} from 'components/ui/Links';
import {DriverOffer} from 'core/entities/Quote/types';
import {getQuoteDate} from 'pages/LoadBoard/utils';
import {isUndefined} from 'lodash';
import {Dispatcher} from 'core/entities/Dispatcher/types';
import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';
import Title from '../common/Title';

interface OwnProps {
    offer: DriverOffer;
    updatedByDispatcher: Dispatcher;
}

const UpdatedBy: React.FC<OwnProps> = (props) => {
    const {offer, updatedByDispatcher} = props;
    const {updatedAt} = offer;
    const date = getQuoteDate(updatedAt);
    if (isUndefined(date)) {
        return null;
    }
    return (
        <div className="mb-2">
            <Title>Updated By</Title>
            <DispatcherLink
                dispatcher={updatedByDispatcher}
                content={getDispatcherName(updatedByDispatcher)}
                className="second-link"
                action="view"
            />
            {` ${date.fullDate} ${date.timeZoneCode}`}
        </div>
    );
};
export default UpdatedBy;
