import React from 'react';

import {getDimensionView} from './utils';
import styles from './styles.module.scss';

interface OwnProps {
    length?: number;
    width?: number;
    height?: number;
    units: string | null | undefined;
}

const DimensionView: React.FC<OwnProps> = (props) => {
    const {length, width, height, units} = props;

    const lengthView = getDimensionView(units, length);
    const widthView = getDimensionView(units, width);
    const heightView = getDimensionView(units, height);

    return (
        <div className={styles.wrap}>
            <div>{`${lengthView} x ${widthView} x ${heightView}`}</div>
        </div>
    );
};

export default DimensionView;
