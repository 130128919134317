import React, {useMemo} from 'react';
import keyBy from 'lodash/keyBy';

import Truck from 'core/entities/Truck/types';

import Header from './components/Header';
import Row from './components/Row';
import styles from './styles.module.scss';

interface OwnProps {
    initialTrucks: Truck[];
    selectedTrucks: Truck[];
    onSelectTruck: (truck: Truck) => void;
}

const TrucksList: React.FC<OwnProps> = (props) => {
    const {initialTrucks, onSelectTruck, selectedTrucks} = props;
    const selectedTrucksMap = useMemo(() => {
        const truckID: keyof Truck = 'id';
        return keyBy(selectedTrucks, truckID);
    }, [selectedTrucks]);
    return (
        <div className={`standard-table-style ${styles.wrapper}`}>
            <table className="table">
                <Header />
                <tbody>
                    {initialTrucks.map((truck) => (
                        <Row
                            key={truck.id}
                            truck={truck}
                            isTruckSelected={truck.id in selectedTrucksMap}
                            onSelectTruck={onSelectTruck}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TrucksList;
