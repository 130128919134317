import React from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';

import {getCurrentTimeZone} from 'store/reducers/appSettings/selectors';

import {convertDate, createDate} from 'utils/dateTime';
import {transformDistance} from 'utils/distance';

import {getName} from 'components/common/LocationEvents/modals/CheckCallModal/utils';
import PassedTime from 'components/ui/PassedTime';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import styles from './styles.module.scss';

type OwnProps = {
    form: string;
    miles: number;
    speed: number;
};

const TruckInfo: React.FC<OwnProps> = ({form, miles, speed}) => {
    const truckLocation = useGetReduxFormValue(form, getName('cityLine'));
    const truckDateTime = useGetReduxFormValue(form, getName('dateTime'));
    const timezone = useSelector(getCurrentTimeZone);

    const transformedTruckDateTime = createDate(truckDateTime, {fromTimeZone: timezone});
    const convertTruckDateTimeToUTC = convertDate(truckDateTime, {timeZoneTo: 'utc', timeZoneFrom: timezone});

    const convertedTruckMiles = transformDistance(miles);
    const convertedTruckSpeed = transformDistance(speed);

    const maxAverageSpeed = 60;
    const minAverageSpeed = 30;
    const isAverageSpeedTooHigh = speed > maxAverageSpeed;
    const isAverageSpeedTooLow = speed < minAverageSpeed;

    const averageSpeedClassName = classNames(
        (isAverageSpeedTooHigh || speed === 0) && styles.more60,
        isAverageSpeedTooLow && styles.less30,
    );

    return (
        <div className={styles.wrap}>
            <div className={styles.title}>truck event</div>
            <div className={styles.location}>
                <strong>{truckLocation}</strong>
            </div>
            <div className={styles.dateTime}>
                {transformedTruckDateTime.fullDate} {transformedTruckDateTime.timeZoneCode}{' '}
                <PassedTime from={convertTruckDateTimeToUTC.defaultDateTime} />
            </div>
            <div className={styles.milesSpeed}>
                <div className={styles.miles}>
                    <strong>{convertedTruckMiles.fromMiles}</strong> {convertedTruckMiles.shortUnit}
                </div>
                <div className={styles.speed}>
                    <span className={averageSpeedClassName}>{convertedTruckSpeed.speedWithUnit}</span>
                </div>
            </div>
        </div>
    );
};

export default TruckInfo;
