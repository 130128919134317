import React from 'react';
import {InjectedFormProps, reduxForm} from 'redux-form';

import useGetReduxFormValues from 'hooks/reduxForm/useGetReduxFormValues';

import {MergeCustomersFormValues} from 'pages/Customers/types/formTypes';
import useMergeActions from 'pages/Customers/hooks/useMergeActions';
import useModalActions from 'pages/Customers/hooks/useModalActions';
import useMergeState from 'pages/Customers/hooks/useMergeState';

import Button from 'components/ui/Buttons/Button';

import CustomersMergeTable from './components/CustomersMergeTable';
import SetCustomerFields from './components/SetCustomerFields';
import WarningInfo from './components/WarningInfo';

const Form: React.FC<{} & InjectedFormProps<{}, {}>> = (props) => {
    const {handleSubmit, form, pristine, reset} = props;

    const {sourceID, destinationID} = useGetReduxFormValues<MergeCustomersFormValues>(form);
    const {clearCustomersToMerge} = useMergeActions();
    const {customersToMerge} = useMergeState();
    const {closeModal} = useModalActions();

    const isPresentCustomersToMerge = Boolean(customersToMerge);
    const isDisabledButton = !sourceID || !destinationID;

    const buttonText = isPresentCustomersToMerge ? 'Confirm' : 'Merge';

    const handleClearAll = () => {
        clearCustomersToMerge();
        reset();
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="modal-body__main">
                <WarningInfo />

                <div className="mt20" />

                <SetCustomerFields isDisabled={isPresentCustomersToMerge} />

                {isPresentCustomersToMerge ? <CustomersMergeTable formName={form} /> : null}
            </div>

            <div className="mt20" />

            <div className="modal-body__bottom">
                <Button onClick={closeModal}>Cancel</Button>

                <div>
                    <Button onClick={handleClearAll} type="button" disabled={pristine}>
                        Clear all
                    </Button>

                    <Button type="submit" colorTheme="blue" className="ml20" disabled={isDisabledButton}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({})(Form);
