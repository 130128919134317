import {useDispatch} from 'react-redux';

import Invoice from 'core/entities/Invoice/types';
import Customer from 'core/entities/Customer/types';

import * as invoiceActions from '../actions/invoiceActions';
import * as modalActions from '../actions/modalActions';

type InvoiceActions = {
    changeInvoiceLoadCustomer: (changedCustomer: Customer) => void;
    changeInvoiceLoadRefNumber: (refNumbersList: number[]) => void;
    openChatWithNoPodDriverWarning: (invoice: Invoice) => void;
    openResetInvoiceStatusModal: (invoice: Invoice) => void;
    openGenerateInvoicePDFModal: (invoice: Invoice) => void;
    changeInvoiceDueDate: (date: {dueDate: string}) => void;
    openGenerationFailedModal: (invoice: Invoice) => void;
    setApproveSettlementStatus: (status: string) => void;
    openApproveInvoiceModal: (invoice: Invoice) => void;
    changeInvoiceTravelOrderStops: (updatedInfo) => void;
    fetchCurrentInvoice: (invoiceNumber: string) => void;
    showAddVirtualStopModal: (data: unknown) => void;
    changeInvoiceTotalAmount: (totalAmount) => void;
    removeVirtualStop: (stopIndex: number) => void;
    changeInvoiceDriverPayment: (payment) => void;
    openInvoicePDF: (invoice: Invoice) => void;
    openCreateInvoiceCustomerModal: () => void;
    includeStopToPDF: (stopID: string) => void;
    addNoteToInvoice: (note?: string) => void;
};

export default (): InvoiceActions => {
    const dispatch = useDispatch();

    return {
        openChatWithNoPodDriverWarning: (invoice) => dispatch(invoiceActions.openChatWithNoPodDriverWarning(invoice)),
        changeInvoiceTotalAmount: (totalAmount) => dispatch(invoiceActions.changeInvoiceTotalAmount(totalAmount)),
        changeInvoiceDueDate: (date: {dueDate: string}) => dispatch(invoiceActions.changeInvoiceDueDate(date)),
        openResetInvoiceStatusModal: (invoice) => dispatch(modalActions.openResetInvoiceStatusModal(invoice)),
        openGenerateInvoicePDFModal: (invoice) => dispatch(modalActions.openGenerateInvoicePDFModal(invoice)),
        changeInvoiceDriverPayment: (payment) => dispatch(invoiceActions.changeInvoiceDriverPayment(payment)),
        fetchCurrentInvoice: (invoiceNumber) => dispatch(invoiceActions.fetchCurrentInvoice(invoiceNumber)),
        setApproveSettlementStatus: (status) => dispatch(invoiceActions.setApproveSettlementStatus(status)),
        openGenerationFailedModal: (invoice) => dispatch(modalActions.openGenerationFailedModal(invoice)),
        openApproveInvoiceModal: (invoice) => dispatch(modalActions.openApproveInvoiceModal(invoice)),
        openCreateInvoiceCustomerModal: () => dispatch(modalActions.openCreateInvoiceCustomerModal()),
        showAddVirtualStopModal: (data) => dispatch(modalActions.showAddVirtualStopModal(data)),
        removeVirtualStop: (stopIndex) => dispatch(invoiceActions.removeVirtualStop(stopIndex)),
        addNoteToInvoice: (note?: string) => dispatch(invoiceActions.addNoteToInvoice(note)),
        includeStopToPDF: (stopID) => dispatch(invoiceActions.includeStopToPDF(stopID)),
        openInvoicePDF: (invoice) => dispatch(invoiceActions.openInvoicePDF(invoice)),
        changeInvoiceLoadCustomer: (changedCustomer) =>
            dispatch(invoiceActions.changeInvoiceLoadCustomer(changedCustomer)),
        changeInvoiceLoadRefNumber: (refNumbersList) =>
            dispatch(invoiceActions.changeInvoiceLoadRefNumber(refNumbersList)),
        changeInvoiceTravelOrderStops: (updatedInfo) =>
            dispatch(invoiceActions.changeInvoiceTravelOrderStops(updatedInfo)),
    };
};
