export const ON_HOLD_REASONS = [
    {
        label: `Pending insurance Cancellation`,
        value: `Pending insurance Cancellation`,
    },
    {
        label: `Broker authority revoked`,
        value: `Broker authority revoked`,
    },
    {
        label: `Carrier-only authority`,
        value: `Carrier-only authority`,
    },
    {
        label: `Non-payment alerts`,
        value: `Non-payment alerts`,
    },
    {
        label: `Mixed pay`,
        value: `Mixed pay`,
    },
    {
        label: `No credit history`,
        value: `No credit history`,
    },
    {
        label: `Over credit limit`,
        value: `Over credit limit`,
    },
    {
        label: `Process payment we can't accept`,
        value: `Process payment we can't accept`,
    },
    {
        label: `Issue on previous loads`,
        value: `Issue on previous loads`,
    },
];
