import omit from 'lodash/omit';

import {Dispatcher} from 'core/entities/Dispatcher/types';

import getParsedJsonOrNull from 'utils/getParsedJsonOrNull';

import type {UserDataActionsTypes} from './actionCreators';
import * as types from './actionTypes';

export type CurrentUserState = Dispatcher;

const defaultState = {} as CurrentUserState;

const user = (state = defaultState, action: UserDataActionsTypes): CurrentUserState => {
    switch (action.type) {
        case types.SET_CURRENT_USER: {
            return {
                ...state,
                ...omit(action.payload, ['computedPermissions']),
                preferences: getParsedJsonOrNull(action.payload?.preferences),
            };
        }

        case types.SET_CURRENT_USER_PREFERENCES: {
            return {
                ...state,
                preferences: action.payload,
            };
        }

        case types.SET_CURRENT_USER_AVATAR: {
            return {
                ...state,
                avatar: action.payload,
            };
        }

        case types.UPDATE_CURRENT_USER: {
            return {
                ...state,
                ...action.payload,
            };
        }

        default:
            return state;
    }
};

export default user;
