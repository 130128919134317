import React, {useMemo, useState} from 'react';

import ButtonGroupSwitch from 'components/ui/ButtonGroupSwitch';

import {NoteType, NoteItem, Notes as NotesData} from './types';
import NotesList from './components/NotesList';
import AddGeneralNote from './components/AddGeneralNote';
import styles from './styles.module.scss';

interface OwnProps {
    notes: NotesData;
    onAddGeneralNote(note: {text: string}): void;
    onDeleteGeneralNote(note: {id: string}): void;
    showNotesAction: boolean;
}

const notesTypes: Record<NoteType, NoteType> = {
    all: 'all',
    general: 'general',
    forDrivers: 'forDrivers',
} as const;

const Notes: React.FC<OwnProps> = (props) => {
    const {notes, onAddGeneralNote, onDeleteGeneralNote, showNotesAction} = props;
    const [currentNotesType, setCurrentNotesType] = useState<NoteType>(notesTypes.all);
    const handleAddGeneralNote = (note: {text: string}) => {
        if (note?.text) {
            onAddGeneralNote(note);
        }
    };
    const handleDeleteGeneralNote = (note: NoteItem) => {
        if (note.id) {
            onDeleteGeneralNote(note as {id: string});
        }
    };
    const selectTabWithAllNotes = () => setCurrentNotesType(notesTypes.all);
    const selectTabWithGeneralNotes = () => setCurrentNotesType(notesTypes.general);
    const selectTabWithDriversNotes = () => setCurrentNotesType(notesTypes.forDrivers);
    const notesByCurrentType = useMemo(
        function () {
            if (currentNotesType === notesTypes.all) {
                return notes.allNotes;
            }
            if (currentNotesType === notesTypes.general) {
                return notes.generalNotes;
            }
            return notes.noteForDrivers ? [notes.noteForDrivers] : [];
        },
        [currentNotesType, notes],
    );
    const isOpenNoteDriversTab = currentNotesType === 'forDrivers';
    return (
        <div className={styles.main}>
            <div className={styles.list}>
                <div className={styles.group}>
                    <ButtonGroupSwitch
                        activeKey={currentNotesType}
                        buttons={[
                            {
                                label: 'all',
                                key: notesTypes.all,
                                onSelect: selectTabWithAllNotes,
                            },
                            {
                                label: 'general',
                                key: notesTypes.general,
                                onSelect: selectTabWithGeneralNotes,
                            },
                            {
                                label: 'note for drivers',
                                key: notesTypes.forDrivers,
                                onSelect: selectTabWithDriversNotes,
                            },
                        ]}
                    />
                </div>
                <NotesList
                    isShownDeleteButton={!showNotesAction}
                    notes={notesByCurrentType}
                    notesType={currentNotesType}
                    onDeleteNote={handleDeleteGeneralNote}
                />
            </div>
            <div className={styles.form}>
                {!isOpenNoteDriversTab && (
                    <AddGeneralNote isDisabledAddNotes={showNotesAction} onAdd={handleAddGeneralNote} />
                )}
            </div>
        </div>
    );
};

export default Notes;
