import React from 'react';
import {Field} from 'redux-form';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';

import RequiredLabel from 'components/ui/Form/RequiredLabel';
import CheckBoxInput from 'components/ui/Form/CheckBoxInput';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import MinutesInput from './components/MinutesInput';
import Radius from './components/Radius';
import classes from './styles.module.scss';

const Quote: React.FC = () => {
    const {
        getDurationInMinutesFieldName,
        getRadiusSearchDistanceKey,
        getIsAirportFieldName,
        getIsHotFieldName,
    } = getFormFieldNames();
    const radiusSearchDistance = useGetReduxFormValue(FORM_NAME, getRadiusSearchDistanceKey());

    return (
        <div className="d-flex">
            <div>
                <Field
                    name={getDurationInMinutesFieldName()}
                    label={RequiredLabel('TIME QUOTE')}
                    limit={60}
                    step={15}
                    component={MinutesInput}
                />
                <Radius value={radiusSearchDistance} />
            </div>

            <div className={classes.checkboxContainer}>
                <Field
                    name={getIsHotFieldName()}
                    component={CheckBoxInput}
                    label={<span className={classes.label}>🔥 Hot Load</span>}
                    position="right"
                    normalize={(value) => Boolean(value)}
                />

                <Field
                    name={getIsAirportFieldName()}
                    component={CheckBoxInput}
                    label={
                        <span className={classes.label}>
                            <i className={`fa fa-plane pr-1 ${classes.faPlaneIcon}`} /> Airport
                        </span>
                    }
                    position="right"
                    normalize={(value) => Boolean(value)}
                />
            </div>
        </div>
    );
};

export default Quote;
