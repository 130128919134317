import {useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {isDirty, reset} from 'redux-form';

const useGetIsReduxFormWasReset = (
    formName: string,
): {
    isNeedReset: boolean;
    resetForm: (form: string) => void;
} => {
    const dispatch = useDispatch();
    const ref = useRef({isFormChanged: false});
    const isFormDirty = useSelector((state) => isDirty(formName)(state));

    if (isFormDirty) {
        ref.current.isFormChanged = true;
    }

    return {
        isNeedReset: ref.current.isFormChanged && !isFormDirty,
        resetForm: (form) => dispatch(reset(form)),
    };
};

export default useGetIsReduxFormWasReset;
