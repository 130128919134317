import React from 'react';
import {Modal} from 'react-bootstrap';

import classNames from 'classnames';

import Driver from 'core/entities/Driver/types';
import {getDriverName} from 'core/entities/Driver/modules/driverName';

import Button from 'components/ui/Buttons/Button';

import styles from './style.module.scss';

type OwnProps = {
    data: {driver: Driver};
    handlers: {onConfirm: () => void};
    onClose: () => void;
};

const RestoreDriverAccountModal = (props: OwnProps): JSX.Element => {
    const {
        data: {driver},
        handlers: {onConfirm},
        onClose,
    } = props;

    const driverName = getDriverName(driver);

    return (
        <Modal show={true} onHide={onClose} className={classNames('info-modal', styles.modal)} backdrop="static">
            <Modal.Header>
                <Modal.Title>Restore Driver&apos;s Mobile Application Account</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={styles.login}>
                    <div className={styles.label}>login</div>
                    <div className={styles.alert}>{driver.email}</div>
                </div>
                <div className="row align-items-center">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-reset" />
                    </div>
                    <div className="col-11">
                        <div>
                            Driver <strong>{driverName}</strong> has deleted its profile on the mobile application.
                        </div>
                        <div>Are you sure you want to restore Driver&apos;s mobile account?</div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onClose} className="mr10" colorTheme="grey" buttonSize="small" defaultFocus={true}>
                    CANCEL
                </Button>
                <Button onClick={onConfirm} className="mr10" colorTheme="blue" buttonSize="small" defaultFocus={true}>
                    RESTORE
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RestoreDriverAccountModal;
