import round from 'lodash/round';

import WEIGHT_UNITS from 'utils/data/weightUnits';

const KG_IN_LBS = 0.45359237;
const THOUSAND = 1000;
export const FLOATING_POINT_TO_LBS = 4;
export const FLOATING_POINT_LBS_TO_KG = 2;
export const FLOATING_POINT_LBS_TO_TON = 3;

type MeasureType = number | undefined | null;
type UnitsType = string | undefined | null;

export const convertKgToLbs = (value: number): number => value / KG_IN_LBS;
export const convertTonsToLbs = (value: number): number => (value / KG_IN_LBS) * THOUSAND;

export const convertLbsToKg = (value: number): number => value * KG_IN_LBS;
export const convertLbsToTons = (value: number): number => (value * KG_IN_LBS) / THOUSAND;

export const transformWeightToNumber = (value: string | number | null | undefined): number => {
    if (!value) {
        return 0;
    }

    const weightAsString = typeof value === 'string' && value?.toString().replace(/,/g, '.');
    const weightAsNumber = typeof value === 'number' && value.toString();
    const weight = parseFloat(weightAsString || weightAsNumber || '0');
    return Number.isNaN(weight) ? 0 : weight;
};

export const transformWeightToString = (value: string | number | null | undefined): string => {
    if (!value) {
        return '';
    }
    const weight = transformWeightToNumber(value);
    return !weight ? '' : weight.toString().replace(/\./g, ',');
};

export const convertToLbs = (units: UnitsType, value: MeasureType): number | null => {
    if (!value) {
        return null;
    }

    const measure = transformWeightToNumber(value);

    if (units === WEIGHT_UNITS.KG) {
        return Number(convertKgToLbs(measure).toFixed(FLOATING_POINT_TO_LBS));
    }

    if (units === WEIGHT_UNITS.TON) {
        return Number(convertTonsToLbs(measure).toFixed(FLOATING_POINT_TO_LBS));
    }

    return Number(measure.toFixed(FLOATING_POINT_TO_LBS));
};

export const convertFromLbs = (units: UnitsType, value: MeasureType): string | null => {
    if (!value) {
        return '';
    }

    const measure = transformWeightToNumber(value);

    if (units === WEIGHT_UNITS.KG) {
        return transformWeightToString(convertLbsToKg(measure).toFixed(FLOATING_POINT_LBS_TO_KG));
    }

    if (units === WEIGHT_UNITS.TON) {
        return transformWeightToString(convertLbsToTons(measure).toFixed(FLOATING_POINT_LBS_TO_TON));
    }

    return transformWeightToString(measure.toFixed(FLOATING_POINT_LBS_TO_KG));
};

export const weightView = (units: UnitsType, value: MeasureType): string => {
    if (!value) {
        return '';
    }

    const measure = transformWeightToNumber(value);

    if (units === WEIGHT_UNITS.KG) {
        return `${transformWeightToString(convertLbsToKg(measure).toFixed(FLOATING_POINT_LBS_TO_KG))} kg`;
    }

    if (units === WEIGHT_UNITS.TON) {
        return `${transformWeightToString(convertLbsToTons(measure).toFixed(FLOATING_POINT_LBS_TO_TON))} t`;
    }

    return `${transformWeightToString(measure.toFixed(FLOATING_POINT_LBS_TO_KG))} lbs`;
};

export const getOnlyRoundedWeight = (unit: UnitsType, weight: MeasureType): string => {
    if (!weight) {
        return `0 ${unit || ''}`;
    }

    if (unit === WEIGHT_UNITS.TON) {
        return `${transformWeightToString(round(weight, FLOATING_POINT_LBS_TO_TON))} ${unit || ''}`;
    }
    return `${transformWeightToString(round(weight, FLOATING_POINT_LBS_TO_KG))} ${unit || ''}`;
};
