import React, {useCallback, useState} from 'react';
import {FieldArray, FieldArrayFieldsProps} from 'redux-form';

import {LoadStop} from 'pages/LoadBoard/types';
import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import {getNumberedStops} from 'pages/LoadBoard/utils';

import {TIME_FRAME_DIRECT, TIME_FRAME_ASAP} from 'core/entities/Load/constants';
import {TIME_ZONES} from 'utils/data/timeZones';

import Wrapper from '../common/Wrapper';
import Distance from './components/Distance';
import Stop from './components/Stop';

import styles from './styles.module.scss';

type CreateStop = {
    fields: FieldArrayFieldsProps<LoadStop>;
    type: LoadStop['type'];
    index: number;
};

const OriginDestinationInfo: React.FC = () => {
    const {getStopsFieldName} = getFormFieldNames();

    const [expandedStops, setExpandedStops] = useState<number[]>([]);

    const toggleStops = (index: number): void => {
        const isExpanded = expandedStops.includes(index);

        if (isExpanded) {
            setExpandedStops((prev) => prev.filter((num) => num !== index));
            return;
        }

        setExpandedStops((prev) => [...prev, index]);
    };

    const createEmptyStop = useCallback(({fields, type, index}: CreateStop) => {
        const nextIndex = index + 1;
        const newStop: LoadStop = {
            timeFrame: type === 'PICKUP' ? TIME_FRAME_ASAP : TIME_FRAME_DIRECT,
            timeZone: TIME_ZONES['America/New_York'].name,
            type,
        };

        fields.insert(nextIndex, newStop);
    }, []);

    const renderStops = React.useCallback(
        ({fields}): JSX.Element => {
            const numberedStops = getNumberedStops(fields.getAll());
            return (
                <div>
                    {numberedStops.map((stop, index) => {
                        const isLastItem = index === fields.length - 1;

                        return (
                            <Stop
                                key={index}
                                index={index}
                                stopNumber={stop.stopNumber}
                                fields={fields}
                                expandedStops={expandedStops}
                                isLastItem={isLastItem}
                                onCreatePickUp={() => createEmptyStop({fields, type: 'PICKUP', index})}
                                onCreateDelivery={() => createEmptyStop({fields, type: 'DELIVERY', index})}
                                toggleStops={toggleStops}
                            />
                        );
                    })}
                </div>
            );
        },
        [expandedStops],
    );

    return (
        <Wrapper>
            <div className={styles.wrapper}>
                <h3 className="ml-1 mb-3">Trip Info</h3>

                <FieldArray name={getStopsFieldName()} component={renderStops} />

                <div className={styles.distanceWrapper}>
                    <Distance />
                </div>
            </div>
        </Wrapper>
    );
};

export default React.memo(OriginDestinationInfo);
