import React from 'react';
import {connect} from 'react-redux';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import {createNumberMask} from 'redux-form-input-masks';

import Button from 'components/ui/Buttons/Button/index';
import Select from 'components/ui/Form/Select';
import TextArea from 'components/ui/Form/TextArea';
import TextInput from 'components/ui/Form/TextInput';

import * as errorMessages from 'utils/validation/errorMessages';

import styles from './deductionPayment.module.scss';

const FORM_NAME = 'settlement-deduction-payment-modal';
const SETTLEMENT_ID_FIELD = 'settlementID';
const AMOUNT_FIELD = 'amount';
const REASON_FIELD = 'reason';
const COMMENT_FIELD = 'comment';
const COMMENT_MAX_LENGTH = 200;
const currencyMask = createNumberMask({
    prefix: '- $ ',
});
const required = (value) => (value ? undefined : errorMessages.REQUIRED);

let Form = (props) => {
    const {handleSubmit, pristine, submitting, deduction: selectedDeduction, reasonComment, onDelete, onClose} = props;
    const labelText = <strong>Deduction</strong>;
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className="modal-body__main">
                <div className="row align-items-end">
                    <div className="col-md-4">
                        <Field
                            name={AMOUNT_FIELD}
                            type="text"
                            label={labelText}
                            component={TextInput}
                            {...currencyMask}
                            placeholder="_ _ _ _"
                            disabled={selectedDeduction}
                        />
                    </div>
                    {selectedDeduction && (
                        <Button onClick={onDelete} className="mb15">
                            delete
                        </Button>
                    )}
                </div>
                <Field
                    label="Please select the reason"
                    name={REASON_FIELD}
                    data={[
                        {label: 'reason1', value: 'reason1'},
                        {label: 'reason2', value: 'reason2'},
                        {label: 'reason3', value: 'reason3'},
                        {label: 'reason4', value: 'reason4'},
                        {label: 'reason5', value: 'reason5'},
                    ]}
                    validate={[required]}
                    component={Select}
                    placeholder="please choose one of the following options"
                    isDisabled={selectedDeduction}
                />
                <Field
                    name={COMMENT_FIELD}
                    type="text"
                    maxLength={COMMENT_MAX_LENGTH}
                    placeholder="brief comment"
                    component={TextArea}
                    disabled={selectedDeduction}
                />
                <div className="count-brief-comment">
                    {reasonComment.length} / {COMMENT_MAX_LENGTH}
                </div>
            </div>
            {!selectedDeduction && (
                <div className="modal-body__bottom">
                    <div>
                        <Button onClick={onClose}>Cancel</Button>
                    </div>
                    <div>
                        <Button disabled={pristine || submitting} type="submit" colorTheme="red">
                            Confirm
                        </Button>
                    </div>
                </div>
            )}
        </form>
    );
};

const selector = formValueSelector(FORM_NAME);

Form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
})(Form);

export default connect((state, {settlement, deduction}) => {
    return {
        reasonComment: selector(state, COMMENT_FIELD) || '',
        initialValues: {
            [SETTLEMENT_ID_FIELD]: settlement.id,
            [AMOUNT_FIELD]: (deduction && deduction.amount) || 0,
            [REASON_FIELD]: (deduction && deduction.reason) || '',
            [COMMENT_FIELD]: (deduction && deduction.comment) || '',
        },
    };
})(Form);
