import Customer from 'core/entities/Customer/types';

export const getCustomerBillingAddressLine = (customer?: Customer | null) => customer?.billing_address_line;

export const getCustomerBillingCountry = (customer?: Customer | null) => customer?.billing_country;

export const getCustomerBillingState = (customer?: Customer | null) => customer?.billing_state;

export const getCustomerBillingCity = (customer?: Customer | null) => customer?.billing_city;

export const getCustomerBillingZip = (customer?: Customer | null) => customer?.billing_zip;

export const getCustomerBillingAdditionalAddressLine = (customer?: Customer | null) =>
    customer?.billing_additional_address_line;

type CustomerBillingAddressAllInfo = {
    customerBillingAdditionalAddressLine: ReturnType<typeof getCustomerBillingAdditionalAddressLine>;
    customerBillingAddressLine: ReturnType<typeof getCustomerBillingAddressLine>;
    customerBillingCountry: ReturnType<typeof getCustomerBillingCountry>;
    customerBillingState: ReturnType<typeof getCustomerBillingState>;
    customerBillingCity: ReturnType<typeof getCustomerBillingCity>;
    customerBillingZip: ReturnType<typeof getCustomerBillingZip>;
};

export const getCustomerBillingAddressAllInfo = (customer?: Customer | null): CustomerBillingAddressAllInfo => {
    return {
        customerBillingAdditionalAddressLine: getCustomerBillingAdditionalAddressLine(customer),
        customerBillingAddressLine: getCustomerBillingAddressLine(customer),
        customerBillingCountry: getCustomerBillingCountry(customer),
        customerBillingState: getCustomerBillingState(customer),
        customerBillingCity: getCustomerBillingCity(customer),
        customerBillingZip: getCustomerBillingZip(customer),
    };
};
