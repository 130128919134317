import {CheckInFormValues} from 'pages/TravelOrders/types/formTypes';

import {createDate} from 'utils/dateTime';

export const transformCheckInDataToRequestBody = (
    checkInData: CheckInFormValues,
): {
    dateTime: string | null;
    timeZone: string | null;
} => {
    const {date, time, timeZone} = checkInData;

    const {fullOriginalDateTime: dateTime, isValid} = createDate(`${date} ${time}`, {
        fromTimeZone: timeZone,
        toTimeZone: 'utc',
    });

    return {
        timeZone: isValid && timeZone ? timeZone : null,
        dateTime: isValid ? dateTime : null,
    };
};
