import toNumber from 'lodash/toNumber';

const FLOATING_POINT_TO_MILES = 0;

const MM_IN_MI = 1609344;

export const convertMiToMm = (miles: number): number => miles * MM_IN_MI;

const convertMmToMi = (millimeters: number): number => millimeters / MM_IN_MI;

export const convertMmToMiles = (millimeters: number): number => {
    return toNumber(convertMmToMi(millimeters).toFixed(FLOATING_POINT_TO_MILES));
};
