import {useDispatch} from 'react-redux';

import * as mergeActions from 'pages/Customers/redux/actions/merge';
import * as formTypes from 'pages/Customers/types/formTypes';

type UseMergeActions = {
    submitMergeCustomers: (params: {formData: Partial<formTypes.MergeCustomersFormValues>; formName: string}) => void;
    clearCustomersToMerge: () => void;
};

const useMergeActions = (): UseMergeActions => {
    const dispatch = useDispatch();

    return {
        submitMergeCustomers: (params: {formData: Partial<formTypes.MergeCustomersFormValues>; formName: string}) =>
            dispatch(mergeActions.submitMergeCustomers(params)),
        clearCustomersToMerge: () => dispatch(mergeActions.clearCustomersToMerge()),
    };
};

export default useMergeActions;
