import React from 'react';

import Driver from 'core/entities/Driver/types';
import Truck from 'core/entities/Truck/types';
import {getDriverName} from 'core/entities/Driver/modules/driverName';
import ArchivingSuccessWrapper from 'components/ui/modals/ArchivingSuccessWrapper';
import {TruckLink} from 'components/ui/Links';

import ButtonsBlock from './ButtonsBlock';

import styles from './styles.module.scss';

type OwnProps = {
    data: {driver: Driver; isFirstDriver: boolean; isDriverMain: boolean; truck: Truck | null | undefined};
    handlers: {onViewArchivedList()};
    onClose();
};
const ArchivingSuccess: React.FC<OwnProps> = (props) => {
    const {
        data: {driver, isFirstDriver, isDriverMain, truck},
        handlers: {onViewArchivedList},
        onClose,
    } = props;
    const driverName = getDriverName(driver);
    const showTruckBlock = isFirstDriver;
    const showUpdateTruckButton = isFirstDriver;
    const firstDriverDesc = isDriverMain ? 'without a first driver (main contact)' : 'without a first driver';
    const buttons = (
        <ButtonsBlock
            driver={driver}
            truck={truck}
            withUpdateTruckButton={showUpdateTruckButton}
            onViewArchivedList={onViewArchivedList}
            onClose={onClose}
        />
    );
    return (
        <ArchivingSuccessWrapper buttons={buttons}>
            <>
                <p>
                    <strong>{driverName}</strong> profile has been moved to archive successfully.
                </p>
                {showTruckBlock && (
                    <div className={styles.info}>
                        <TruckLink truck={truck} content={`Truck #${truck?.number}`} className="second-link" />{' '}
                        {firstDriverDesc}. If you want to attach this truck for the load you need to add the driver
                    </div>
                )}
            </>
        </ArchivingSuccessWrapper>
    );
};

export default ArchivingSuccess;
