import React from 'react';
import currencyData, {CurrencyValue} from 'utils/data/currency';

type OwnProps = {
    currency: CurrencyValue;
    rate: number | undefined;
};

const Payment: React.FC<OwnProps> = (props) => {
    const {rate, currency} = props;
    const currencySettings = currencyData[currency];
    return (
        <>
            {currencySettings.mark} {rate}
        </>
    );
};
export default Payment;
