import React from 'react';
import {useSelector} from 'react-redux';

import {Facility} from 'core/entities/Facility/types';
import composeFacilityCityLine from 'core/entities/Facility/composeFacilityCityLine';

import {getFacilityDuplicateData, getSelectedEntityData} from '../../utils';
import {getIsFacilityCreate} from '../../selectors';
import DuplicatesCount from '../DuplicatesCount';
import DuplicatesEntitiesList, {Entity} from '../DuplicatesEntitiesList';
import SelectDuplicateButton from '../SelectDuplicateButton';

interface OwnProps {
    facility: Facility;
    facilityDuplicates: Facility[];
    onSelectFacilityDuplicate(f: Facility);
}

const getMappedFacilityDuplicates = (facilities: Facility[]): Entity[] => {
    return facilities.map(getFacilityDuplicateData);
};

const DuplicatesFacilities = ({facility, facilityDuplicates, onSelectFacilityDuplicate}: OwnProps): JSX.Element => {
    const facilityCityLine = composeFacilityCityLine(facility);
    const facilityWithDuplicate = getFacilityDuplicateData(facility);
    const entitiesWithDuplicates = getMappedFacilityDuplicates(facilityDuplicates);
    const isCalledFromFacilityCreateForm = useSelector(getIsFacilityCreate);
    const handleSelectDuplicatedCustomer = (entityDuplicate: Entity): void => {
        const selectedDuplicatedFacility = getSelectedEntityData<Facility>(entityDuplicate, facilityDuplicates);
        if (selectedDuplicatedFacility) {
            onSelectFacilityDuplicate(selectedDuplicatedFacility);
        }
    };
    return (
        <div className="custom-scroll">
            <div className="general-data">
                <DuplicatesCount count={facilityDuplicates.length} duplicateType="facility" />

                <div className="general-data__company-data">
                    <strong>{facility.name}</strong>
                    <br />
                    <span>{facility.address_line}</span> <br />
                    <span>{facilityCityLine}</span>
                </div>
                <div className="general-data__info-merge">
                    {isCalledFromFacilityCreateForm ? (
                        <>
                            <div>You can merge current facility with the one below</div>
                            <div className="warning-info">
                                <i className="fa fa-info-circle" aria-hidden="true" />
                                Entered data will not exchange the info
                            </div>
                        </>
                    ) : (
                        <div>You can insert booked with facility by selecting one below</div>
                    )}
                </div>
            </div>
            <div className="standard-table-style">
                <table className="table table-duplicates">
                    <thead>
                        <tr>
                            <th>ID#</th>
                            <th>FACILITY NAME</th>
                            <th>BILLING ADDRESS</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <DuplicatesEntitiesList
                        originalEntity={facilityWithDuplicate}
                        entityDuplicates={entitiesWithDuplicates}
                    >
                        {(duplicate) => {
                            return (
                                <SelectDuplicateButton
                                    title={isCalledFromFacilityCreateForm ? 'Merge' : 'Select'}
                                    onSelect={() => handleSelectDuplicatedCustomer(duplicate)}
                                />
                            );
                        }}
                    </DuplicatesEntitiesList>
                </table>
            </div>
        </div>
    );
};

export default DuplicatesFacilities;
