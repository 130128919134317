import React, {useState} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import {SubMenuItem} from 'core/entities/AppSettings/types';

type OwnProps = {
    currentPath: string;
    isSidebarExpanded: boolean;
    icon: string;
    label: string;
    subMenuItems: Array<SubMenuItem>;
};

const SidebarGroupItem: React.FC<OwnProps> = (props) => {
    const {subMenuItems, icon, label, isSidebarExpanded, currentPath} = props;
    const [expanded, toggleExpanded] = useState(true);

    const handleClick = () => toggleExpanded((prevState) => !prevState);

    const hasCurrentPath = subMenuItems.some((item) => item.path === currentPath);

    const containerClassName = classNames('sublist-item', {'sublist-item-active': expanded});
    const linkClassName = classNames('side-bar-menu-item', {'active-page': !isSidebarExpanded && hasCurrentPath});

    return (
        <li className={containerClassName} role="menuitem">
            <div className="sublist-header" onClick={handleClick}>
                <a className={linkClassName}>
                    <i className={icon} />
                    <span className="side-bar-menu-label">{label}</span>
                    <span className="pull-right-container" />
                </a>
            </div>
            <ul className="sublist">
                {subMenuItems.map((menuItem) => (
                    <li key={menuItem.name} className={classNames({active: menuItem.path === currentPath})}>
                        <Link className="side-bar-menu-item sub-item" to={menuItem.path}>
                            <span className="side-bar-menu-label">{menuItem.displayName}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </li>
    );
};

export default SidebarGroupItem;
