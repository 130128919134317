import React from 'react';
import {Form, FormGroup, FormLabel} from 'react-bootstrap';
import classNames from 'classnames';

import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';
import getValidationState from 'components/ui/Form/getValidationState';

const FormGroupWithValidation = (props) => {
    const {children, meta, label, className} = props;
    const validate = getValidationState(meta);
    return (
        <FormGroup className={classNames(className, {'has-feedback has-error': !validate})}>
            {label && <FormLabel>{label}</FormLabel>}
            <OverlayTriggerCustom inputMeta={meta} placement="bottom">
                {children}
            </OverlayTriggerCustom>
            <Form.Control.Feedback type="invalid" className={!validate ? 'error' : ''}>
                <i className="fa fa-exclamation-circle" />
            </Form.Control.Feedback>
        </FormGroup>
    );
};

export default FormGroupWithValidation;
