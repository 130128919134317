import classNames from 'classnames';
import React from 'react';

import * as filesValidation from 'widgets/Chat/components/MessageForm/utils';
import {NormalizedFile} from 'widgets/Chat/types';

import formatFileSize from 'utils/files/formatFileSize';

import Tooltip from 'components/ui/Tooltip';

import AttachPreviewImage from './AttachPreviewImage';

import styles from './styles.module.scss';

type OwnProps = {
    handleRemove?: () => void;
    file: NormalizedFile;
};

const AttachPreview: React.FC<OwnProps> = (props: OwnProps) => {
    const {file, handleRemove} = props;

    const {isSomeAttachmentTypeUnsupported} = filesValidation.checkAttachmentsTypeValidation({files: [{file}]});
    const {isSomeFileGte50MB} = filesValidation.calculateAttachmentsSizeValidation({files: [{file}]});

    const itemClassName = classNames(styles.item, {
        [styles.invalid]: isSomeFileGte50MB || isSomeAttachmentTypeUnsupported,
    });

    return (
        <div className={itemClassName}>
            <AttachPreviewImage file={file} handleRemove={handleRemove} />

            <div className="file-name-tooltip">
                <Tooltip className="btn-tooltip" tooltipContent={`${file.name}`}>
                    <div className="file-name">{file.name}</div>
                </Tooltip>
                {file.size && <div className="file-size">{formatFileSize(file.size)}</div>}
            </div>
        </div>
    );
};

export default AttachPreview;
