import React from 'react';

import Spinner from 'pages/Settlements/components/common/Spinner';

import style from './dataLoading.module.scss';

interface OwnProps {
    isLoading: boolean;
    isAllLoaded: boolean;
    loadMoreTitle: string;
    onLoadMore();
}

const DataLoading = ({isLoading, isAllLoaded, loadMoreTitle, onLoadMore}: OwnProps): JSX.Element | null => {
    const isShowLoadMoreButton = !isLoading && !isAllLoaded;
    return (
        <div className={style.container}>
            {isLoading && <Spinner />}

            {isShowLoadMoreButton && (
                <span className="second-link" onClick={onLoadMore}>
                    {loadMoreTitle}
                </span>
            )}
        </div>
    );
};

export default DataLoading;
