import React from 'react';

import {convertMmToMiles} from 'pages/LoadBoard/utils/distance';

interface OwnProps {
    distance: number | undefined;
    withUnit?: boolean;
}

const DistanceValue: React.FC<OwnProps> = ({distance, withUnit}): JSX.Element => {
    const convertedDistance = distance ? convertMmToMiles(distance) : '-';

    const distanceUnit = withUnit ? ' mi' : '';

    return <span>{`${convertedDistance}${distanceUnit}`}</span>;
};

export default DistanceValue;
