export const FIELDS = {
    // id: getEntityFieldName('id'),
    isCalculated: 'type',
    profit: 'profit',
    dryMiles: 'dry_miles',
    // emptyMiles: getEntityFieldName('empty_miles'),
    lastEventLocation: 'last_event_location',
    reason: 'reason',
    totalAmount: 'total_amount',
    totalCharges: 'total_charges',
    profitPercent: 'profit_percent',
    driverPayment: 'driver_payment',
    rpm: 'rpm',
    miles: 'miles',
    extra: 'extra',

    time: 'time',
    date: 'date',
    timeZone: 'timeZone',
    truckLocation: 'truckLocation',
    lat: 'latitude',
    lng: 'longitude',
    cityLine: 'city_line',
    podData: 'pods',
    bolNumber: 'number',
    bolID: 'bolID',
    bolSigned: 'signed_who',
    rcSigned: 'rc_signed',
    carrierDriverName: 'driver_name',
    carrierDriverPhone: 'driver_phone',
    carrierTruckNumber: 'truck_number',

    futureTruckStatus: 'truck_status',
    truckDatetime: 'truckDatetime',
    checkInAsCompanyName: 'check_in_as',
    dispatcherNote: 'dispatcher_note',
    // isConfirmed: getEntityFieldName('is_confirmed'),
    // isPlanned: getEntityFieldName('is_planned'),
    // finishedDatetime: getEntityFieldName('finished_datetime'),
    // finishedTimezone: getEntityFieldName('finished_timezone'),
    // status: getEntityFieldName('status'),
    // stepNumber: getEntityFieldName('step_number'),
    // carrier: getEntityFieldName('carrier'),
    // dispatch: getEntityFieldName('dispatch'),
    // dispatcher: getEntityFieldName('dispatcher'),
    // dryRun: getEntityFieldName('dry_run'),
    // finished_dispatcher: getEntityFieldName('finished_dispatcher'),
    // load: getEntityFieldName('load'),
    // rate: getEntityFieldName('rate'),
    // truck: getEntityFieldName('truck'),
    // isSendSurvey: 'is_send_survey',
};

export const PROGRESS_STEP_STATUSES = {
    noRate: 'no rate',
    dryRun: 'dry run',
    dryRunConfirmed: 'dry run. (Confirmed)',
    notDispatched: 'not dispatched',
    notStarted: 'not started',
    dispatchedWaitingConfirm: 'not confirmed',
    dispatchedPlanned: 'Dispatched. (Planned)',
    onRouteTo: 'on route to',
    checkedIn: 'checked in',
    bolAdded: 'bol added',
    podSigned: 'pod signed',
    finished: 'Finished',

    DRY_RUN_NOT_CONFIRMED: 'dry run. not confirmed',
    DISPATCHED_NOT_CONFIRMED: 'not confirmed',
    NOT_DISPATCHED: 'not dispatched',
    ON_ROUTE: 'on route',
    FINISHED: 'finished',
    DRY_RUN: 'dry run',
    NO_PAY: 'no pay',

    // custom status which using only for calculate "done" travel order steps
    DONE: 'done',
} as const;

export const FILE_TYPES = {
    bol: 'bol',
    pod: 'pod',
    rc: 'rc',
    rcSigned: 'rc_signed',
    rcGenerated: 'generated_rc',
};
