import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button/index';
import {createDate} from 'utils/dateTime';

const NoHistoryMovement = (props) => {
    const {
        data: {truck, travelOrder, dateStartInUTC, dateEndInUTC},
        onClose,
    } = props;

    const {fullDate: dateStartInAnotherTimeZone} = createDate(dateStartInUTC);
    const {fullDate: dateEndInAnotherTimeZone} = createDate(dateEndInUTC);

    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Unable to show movement history</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-error" />
                    </div>
                    <div className="col-11">
                        <p className="mb10">
                            There is no data about the route for the{' '}
                            <strong>Truck{truck && <span>#{truck.number}</span>}</strong>&nbsp;{' '}
                            {travelOrder && <strong>(T.O. #{travelOrder.id})</strong>} in the chosen time frames (
                            {dateStartInAnotherTimeZone} - {dateEndInAnotherTimeZone})
                        </p>
                        <p>Please change settings and try once again.</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="ml-auto">
                    <Button defaultFocus={true} onClick={onClose} buttonSize="small" colorTheme="red">
                        ok
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default NoHistoryMovement;
