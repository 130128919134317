import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';
import TravelOrder from 'core/entities/TravelOrder/types';

import {TABS} from 'components/ui/modals/AddTONotes/constants';
import {DispatcherLink} from 'components/ui/Links';

import {createDate} from 'utils/dateTime';

import {getNotes} from './utils';

import styles from './styles.module.scss';

type OwnProps = {
    activeTab: typeof TABS[keyof typeof TABS];
    travelOrder?: TravelOrder | null;
};

const NotesList: React.FC<OwnProps> = (props) => {
    const {travelOrder, activeTab} = props;

    const {notes} = getNotes({travelOrder, activeTab});

    const textTabsName = activeTab !== TABS.all ? <strong>{activeTab.toLowerCase()}</strong> : '';
    const emptyText = <>The current travel order doesn&apos;t have {textTabsName} notes.</>;

    if (isEmpty(notes)) {
        return <div className={styles.empty_notes}>{emptyText}</div>;
    }

    return (
        <div className={styles.list}>
            {notes.map((note, index) => {
                const dispatcherLink = note?.dispatcher ? (
                    <DispatcherLink
                        content={getDispatcherName(note.dispatcher)}
                        dispatcher={note.dispatcher}
                        className="second-link"
                    />
                ) : null;

                const {fullDate} = createDate(note.createdAt);

                const className = classNames(styles.list__item, {[styles.list__item_important]: note.isImportant});

                return (
                    <div key={index} className={className}>
                        <div className={styles.list__item_header}>
                            <div className={styles.list__item_header_link}>
                                <strong>{dispatcherLink}</strong>
                            </div>
                            <div className={styles.list__item_header_desc}>
                                <strong>{note.type} note</strong> <span>{fullDate}</span>
                            </div>
                        </div>
                        <div className={styles.list__item_content}>{note.text}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default NotesList;
