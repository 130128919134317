import React from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import {SelectCallback} from 'react-bootstrap/esm/helpers';
import isEmpty from 'lodash/isEmpty';

import useAttachActions from 'pages/Loads/components/common/modals/AttachModal/hooks/useAttachActions';
import useAttachState from 'pages/Loads/components/common/modals/AttachModal/hooks/useAttachState';
import {ATTACH_LOAD_TABS} from 'pages/Loads/constants/loadConstants';

import CarrierItem from './components/CarrierItem';
import TruckItem from './components/TruckItem';

import styles from './styles.module.scss';

const AttachTabs: React.FC = () => {
    const {activeTab, trucks, carriers, searchCarriers, carriersSearchParams} = useAttachState();
    const {changeTab} = useAttachActions();

    const handleChangeTab: SelectCallback = (tab) => {
        if (tab) {
            changeTab({tab});
        }
    };

    const carriersToRender = searchCarriers || carriers;

    const renderCarriers = carriersToRender.map((carrier) => {
        return (
            <div className={styles.item} key={carrier.id}>
                <CarrierItem carrier={carrier} />
            </div>
        );
    });

    const renderTrucks = trucks.map((truck) => {
        return (
            <div className={styles.item} key={truck.id}>
                <TruckItem truck={truck} />
            </div>
        );
    });

    const emptyItemsPlaceholder = (
        <div className={styles.empty}>
            <p>Please start a search to find a vehicle with drivers assigned</p>
        </div>
    );

    const renderEmptyTrucksPlaceholder = isEmpty(trucks) ? emptyItemsPlaceholder : null;

    const renderEmptySearchCarriersPlaceholder =
        carriersSearchParams.company_name && isEmpty(searchCarriers) ? emptyItemsPlaceholder : null;

    const renderEmptyCarriersPlaceholder = isEmpty(carriers) ? emptyItemsPlaceholder : null;

    return (
        <div className={styles.wrap}>
            <Tabs activeKey={activeTab} onSelect={handleChangeTab} id="attach-trucks-carriers">
                <Tab eventKey={ATTACH_LOAD_TABS.TRUCKS} title="Trucks">
                    <div className={styles.scroll}>
                        {renderEmptyTrucksPlaceholder}

                        {renderTrucks}
                    </div>
                </Tab>

                <Tab eventKey={ATTACH_LOAD_TABS.CARRIERS} title="Carriers">
                    <div className={styles.scroll}>
                        {renderEmptyCarriersPlaceholder}

                        {renderEmptySearchCarriersPlaceholder}

                        {renderCarriers}
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
};

export default AttachTabs;
