import React, {useState, useMemo} from 'react';
import classNames from 'classnames';

import Button from 'components/ui/Buttons/Button';

import {Batch} from 'core/entities/Settlement/types';

import CarrierBadge from 'pages/Settlements/components/common/CarrierBadge';
import BatchTotalPays from 'pages/Settlements/components/common/BatchTotalPays';
import DataLoading from 'pages/Settlements/components/common/DataLoading';
import useFetchBatchSettlements from 'pages/Settlements/hooks/useFetchBatchSettlements';

import {getDistanceSettings} from 'utils/distance';

import CarrierEmailCheckBox from '../CarrierEmailCheckBox';

import SettlementRow from './SettlementRow';
import SettlementsTotalInfo from './SettlementsTotalInfo';
import styles from './settlementsGroup.module.scss';

type OwnProps = {
    batch: Batch;
    emailSentDate?: any;
    isClosing?: boolean;
    isEmailSent?: boolean;
};

const SettlementsList: React.FC<OwnProps> = ({batch, emailSentDate = null, isClosing = true, isEmailSent = false}) => {
    const {settlements, isFetching, isAllFetched, fetchNextPage} = useFetchBatchSettlements({
        batchID: batch.id,
    });
    const [open, setOpen] = useState(false);
    const settlementsGroupClassName = classNames('main', {open});
    const settlementsRows = useMemo(() => {
        return settlements.map((s) => <SettlementRow key={s.id} settlement={s} />);
    }, [settlements]);
    const totalDriverPayment = batch.batch_total_charges;

    const distanceSettings = getDistanceSettings();

    return (
        <div className={styles.item}>
            <div className="header">
                <div>
                    <strong>{batch.carrier.company_name}</strong> <CarrierBadge carrier={batch.carrier} />
                </div>
                <div>
                    <strong>{batch.settlements_count}</strong> settlement(s)
                    <Button
                        buttonIcon={open ? 'angle-up' : 'angle-down'}
                        buttonSize="icon"
                        className="btn-link"
                        onClick={() => setOpen(!open)}
                    />
                </div>
            </div>
            <div className={settlementsGroupClassName}>
                <table className="standard-table-style settlements-group-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Origin - Destination</th>
                            <th>{distanceSettings.longUnit}</th>
                            <th>Check OUT info</th>
                            <th>Payment</th>
                        </tr>
                    </thead>
                    <tbody>{open && settlementsRows}</tbody>
                </table>
                <br />

                <DataLoading
                    isLoading={isFetching}
                    isAllLoaded={isAllFetched}
                    loadMoreTitle="load more settlements"
                    onLoadMore={fetchNextPage}
                />

                <SettlementsTotalInfo
                    totalEmptyMiles={batch.empty_miles}
                    totalLoadedMiles={batch.total_miles}
                    totalPayment={totalDriverPayment}
                />
            </div>
            <div className="footer">
                <div>
                    {isClosing && batch.carrier.email && <div>{batch.carrier.email}</div>}

                    {!isClosing && batch.carrier.email && (
                        <CarrierEmailCheckBox
                            emailSentDate={emailSentDate}
                            isEmailSent={isEmailSent}
                            email={batch.carrier.email}
                        />
                    )}
                </div>

                <div className="total">
                    <div className="title">Total Pay</div>
                    <div className="data">
                        <BatchTotalPays batch={batch} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(SettlementsList);
