import {InferActions} from 'store';

import Load from 'core/entities/Load/types';
import TravelOrder from 'core/entities/TravelOrder/types';

import * as actionTypes from '../actionTypes/loadActionTypes';
import {CopiedLoad} from '../actions/copyActions/utils';

export const actionCreators = {
    fetchLoad: (payload: {load?: Load}) => ({type: actionTypes.LOAD_INIT_DATA_FETCHED, payload} as const),
    fetchTravelOrder: (payload: {travelOrder: TravelOrder}) =>
        ({type: actionTypes.LOAD_INIT_DATA_TRAVEL_ORDER_FETCHED, payload} as const),
    createLoad: () => ({type: actionTypes.LOAD_CREATED} as const),
    changeCreateFormStep: (payload: string) => ({type: actionTypes.CHANGE_FORM_STEP, payload} as const),
    currentLoadStartLoading: () => ({type: actionTypes.CURRENT_LOAD_START_LOADING} as const),
    currentLoadStopLoading: () => ({type: actionTypes.CURRENT_LOAD_STOP_LOADING} as const),
    toggleExpandPickUpDeliveryItem: (payload: {itemIndex: number; params?: {expand: boolean}}) =>
        ({type: actionTypes.TOGGLE_EXPAND_PICK_UP_DELIVERY_ITEM, payload} as const),
    expandPickUpDeliveryItems: (payload: {indexes: Array<number>}) =>
        ({type: actionTypes.EXPAND_PICK_UP_DELIVERY_ITEMS, payload} as const),
    toggleExpandPickUpDeliveryItemsAll: (payload: number) =>
        ({type: actionTypes.TOGGLE_EXPAND_PICK_UP_DELIVERY_ITEMS_ALL, payload} as const),
    setCopiedLoadData: (payload: CopiedLoad) => ({type: actionTypes.CREATE_LOAD_COPY, payload} as const),
    clearLoadFormFields: () => ({type: actionTypes.CLEAR_LOAD_COPY} as const),
    clearState: () => ({type: actionTypes.LOAD_STATE_CLEAR} as const),
};

export type ActionsTypes = InferActions<typeof actionCreators>;
