import {useDispatch} from 'react-redux';

import {sendSupportMessage} from '../actions';

import {SendDataType, useSupportModalHookType} from '../types';

export const useSupportModalHook = (onClose: () => void): useSupportModalHookType => {
    const dispatch = useDispatch();

    return {
        sendMessage: (data: SendDataType) => dispatch(sendSupportMessage(data, onClose)),
    };
};
