import memoize from 'lodash/memoize';
import toNumber from 'lodash/toNumber';

import {store} from 'store';
import {getCurrentDistanceFormat} from 'store/reducers/appSettings/selectors';

import userSession from 'utils/userSession';

type Units = {km: string; DEFAULT: string};
type DistanceValue = number | string | null | undefined;
type UnitsConverters = {km: (arg: number) => number; DEFAULT: (arg: number) => number};
type DistanceSettings = {shortUnit: string; longUnit: string; speedUnit: string};
export type Distance = DistanceSettings & {
    toMiles: number;
    fromMiles: number;
    distanceWithShortUnit: string;
    distanceWithLongUnit: string;
    speedWithUnit: string;
};

const KM_IN_MI = 1.609344;
const FLOATING_POINT_TO_MILES = 6;

const convertMiToKm = (miles: number): number => miles * KM_IN_MI;
const convertKmToMi = (miles: number): number => miles / KM_IN_MI;

const milesShortUnit = 'mi';
const milesLongUnit = 'miles';
const milesSpeedUnit = 'mph';

const kilometersShortUnit = 'km';
const kilometersLongUnit = 'kilometers';
const kilometersSpeedUnit = 'km/h';

const checkDistance = (distance: DistanceValue): number => (distance ? toNumber(distance) : 0);

const getDistanceFormat = memoize(
    () => ({distance_format: getCurrentDistanceFormat(store.getState())}),
    userSession.getID,
);

const fromServerConverters: UnitsConverters = {
    km: (miles: number): number => convertMiToKm(miles),
    DEFAULT: (miles: number): number => miles,
};

const toServerConverters: UnitsConverters = {
    km: (kilometers: number): number => convertKmToMi(kilometers),
    DEFAULT: (kilometers: number): number => kilometers,
};

const shortUnits: Units = {
    km: kilometersShortUnit,
    DEFAULT: milesShortUnit,
};

const longUnits: Units = {
    km: kilometersLongUnit,
    DEFAULT: milesLongUnit,
};

export const speedUnits: Units = {
    km: kilometersSpeedUnit,
    DEFAULT: milesSpeedUnit,
};

export const getDistanceSettings = (): DistanceSettings => {
    const {distance_format} = getDistanceFormat();

    const distanceUnit = shortUnits[distance_format] || shortUnits.DEFAULT;
    const speedUnit = speedUnits[distance_format] || speedUnits.DEFAULT;
    const fullNameOfUnit = longUnits[distance_format] || longUnits.DEFAULT;
    return {shortUnit: distanceUnit, longUnit: fullNameOfUnit, speedUnit};
};

export const transformDistance = (distance: DistanceValue): Distance => {
    const {shortUnit, speedUnit, longUnit} = getDistanceSettings();
    const convertToMiles = toServerConverters[shortUnit] || toServerConverters.DEFAULT;
    const convertFromMiles = fromServerConverters[shortUnit] || fromServerConverters.DEFAULT;
    const toMiles = toNumber(convertToMiles(checkDistance(distance)).toFixed(FLOATING_POINT_TO_MILES));
    const fromMiles = toNumber(convertFromMiles(checkDistance(distance)).toFixed());
    const distanceWithShortUnit = `${fromMiles} ${shortUnit}`;
    const distanceWithLongUnit = `${fromMiles} ${longUnit}`;
    const speedWithUnit = `${fromMiles} ${speedUnit}`;

    return {
        toMiles,
        fromMiles,
        shortUnit,
        speedUnit,
        longUnit,
        distanceWithShortUnit,
        distanceWithLongUnit,
        speedWithUnit,
    };
};

export const getSpeedUnit = () => {
    const {distance_format} = getDistanceFormat();

    return speedUnits[distance_format] || speedUnits.DEFAULT;
};
