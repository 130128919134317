import React from 'react';
import {Field} from 'redux-form';

import {createDate} from 'utils/dateTime';

import CheckBoxInput from 'components/ui/Form/CheckBoxInput';

import FIELDS from '../fields';

import styles from './carrierEmailCheckBox.module.scss';

const EmailSent = ({emailSentDate}) => {
    if (!emailSentDate) {
        return null;
    }

    const date = createDate(emailSentDate).fullDate;

    return (
        <p>
            sent <strong>{date}</strong>
        </p>
    );
};

const EmailNotSent = () => (
    <div className="not-sent">
        <em>pay record was not sent</em>
    </div>
);

const CarrierEmailCheckBox = ({email, emailSentDate = null, isEmailSent = null}) => {
    const labelText = (
        <div className="label-text ellips-text">
            send pay record to: <strong>{email}</strong>
        </div>
    );
    return (
        <div className={styles.item}>
            <Field name={FIELDS.sendEmail} component={CheckBoxInput} position="right" label={labelText} />
            <div className="sent-status">
                <div className="sep">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                </div>

                {isEmailSent ? <EmailSent emailSentDate={emailSentDate} /> : <EmailNotSent />}

                <div className="sep">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                </div>
            </div>
        </div>
    );
};

export default CarrierEmailCheckBox;
