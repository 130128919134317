import React from 'react';

import {PayRecord} from 'core/entities/Settlement/types';
import {getPayRecordCurrencyPayments} from 'core/entities/Settlement/settlementPayRecord';
import CurrencyAmount from 'components/ui/CurrencyAmount';

import {CurrencyValue} from 'utils/data/currency';
import styles from './totalPayment.module.scss';

interface OwnProps {
    payRecord: PayRecord;
}

const TotalAmount = ({icon, amount, currency}: {icon: '+' | '-'; amount: number; currency: CurrencyValue}): any => {
    return (
        <div className={styles.total_amount}>
            <CurrencyAmount amount={amount} currency={currency} withStrong={false} /> <span>{icon}</span>
        </div>
    );
};

const TotalPayment = ({payRecord}: OwnProps): JSX.Element | null => {
    const payRecordPayments = getPayRecordCurrencyPayments(payRecord);
    return (
        <div className={styles.wrap}>
            {payRecordPayments.map((payment) => {
                return (
                    <div key={payment.currency} className={styles.block}>
                        <div className={styles.block__titles}>
                            gross pay
                            <br />
                            reimbursement
                            <br />
                            deductions
                        </div>
                        <div className={styles.block__data}>
                            <div className={styles.block__data_payment}>
                                <CurrencyAmount
                                    amount={payment.amount}
                                    currency={payment.currency}
                                    withStrong={false}
                                />
                            </div>
                            <TotalAmount
                                key="reimbursements"
                                icon="+"
                                amount={payment.reimbursements.totalAmount}
                                currency={payment.currency}
                            />
                            <TotalAmount
                                key="deductions"
                                icon="-"
                                amount={payment.deductions.totalAmount}
                                currency={payment.currency}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default TotalPayment;
