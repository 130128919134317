import React from 'react';
import {Modal} from 'react-bootstrap';

import SupportForm from './components/Form';

import styles from './support.module.scss';

import {useSupportModalHook} from './hooks';

const SupportModal: React.FC = (props) => {
    // @ts-ignore
    const {onClose} = props;

    const {sendMessage} = useSupportModalHook(onClose);

    const handleClose = (): void => {
        onClose();
    };

    const onSendMessage = (value): void => {
        sendMessage(value);
    };

    return (
        <Modal show={true} onHide={handleClose} backdrop="static" dialogClassName={styles.modal}>
            <Modal.Header>
                <div className="support-modal__header">
                    <Modal.Title>Give Feedback </Modal.Title>
                    <button onClick={handleClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="support-modal__body">
                    <SupportForm onSubmit={onSendMessage} onClose={handleClose} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SupportModal;
