import {InferActions} from 'store';

import {archiveCustomerActionCreators} from 'pages/Customers/redux/actions/archiving';
import * as actionArchivingTypes from 'pages/Customers/redux/actionTypes/archiving';
import * as actionCustomerTypes from 'pages/Customers/redux/actionTypes/customer';
import {customerActionCreators} from 'pages/Customers/redux/actions/customer';

type ActionTypes = InferActions<typeof archiveCustomerActionCreators | typeof customerActionCreators>;

type CustomerState = {
    current: any;
};

const defaultState: CustomerState = {
    current: null,
};

const customer = (state = defaultState, action: ActionTypes): CustomerState => {
    switch (action.type) {
        case actionCustomerTypes.RECEIVED_CUSTOMER_DATA: {
            return {
                ...state,
                current: action.payload,
            };
        }

        case actionArchivingTypes.SET_ARCHIVED_CUSTOMER: {
            const {is_deleted} = action.payload.customer;

            if (!state.current) {
                return state;
            }

            return {
                ...state,
                current: {...state.current, is_deleted},
            };
        }

        case actionCustomerTypes.RECEIVED_CUSTOMER_GENERAL_NOTE: {
            return {
                ...state,
                current: {
                    ...state.current,
                    generalNotes: [action.payload, ...state.current.generalNotes],
                },
            };
        }

        case actionCustomerTypes.CLEAR_CUSTOMER_STATE:
            return defaultState;

        // no default
    }

    const _exhaustiveCheck = action;

    return state;
};

export default customer;
