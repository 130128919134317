import React, {Fragment, useState} from 'react';
import {FormControl} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button';

interface OwnProps {
    disabled?: boolean;
    handleChange(event: React.FormEvent<any>): void;
}

const SearchInput = (props: OwnProps): JSX.Element => {
    const {handleChange, disabled = false} = props;
    const [isExpanded, setIsExpanded] = useState<boolean>();
    return (
        <Fragment>
            <FormControl
                type="text"
                className={isExpanded ? 'file-search' : 'file-search hide_field'}
                onChange={handleChange}
                disabled={disabled}
            />
            <Button
                buttonIcon="search"
                buttonSize="icon"
                onClick={() => setIsExpanded(!isExpanded)}
                disabled={disabled}
            />
        </Fragment>
    );
};

export default SearchInput;
