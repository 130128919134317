import React from 'react';
import classNames from 'classnames';

import Button from 'components/ui/Buttons/Button';

import styles from './styles.module.scss';

type OwnProps = {
    onClick: () => void;
    disabled?: boolean;
    className?: string;
};

const ResetButton: React.FC<OwnProps> = ({onClick, disabled = false, className = ''}) => (
    <Button
        className={classNames(styles.btn, className)}
        buttonIcon="undo fa-flip-horizontal"
        onClick={onClick}
        disabled={disabled}
    />
);

export default ResetButton;
