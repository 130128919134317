import React from 'react';

import './style.scss';

const TooltipCustom = (props) => {
    const {placement, children} = props;

    let cls = 'tooltip-custom-top';

    switch (placement) {
        case 'top':
            cls = 'tooltip-custom-top';
            break;
        case 'top-checkbox':
            cls = 'tooltip-checkbox-top';
            break;
        case 'bottom':
            cls = 'tooltip-custom-bottom';
            break;
        case 'top-select':
            cls = 'tooltip-select-top';
            break;
        case 'bottom-select':
            cls = 'tooltip-select-bottom';
            break;
        case 'top-chat':
            cls = 'tooltip-chat-top';
            break;
        default:
            cls = 'tooltip-custom-top';
    }

    return <div className={cls}>{children}</div>;
};
export default TooltipCustom;
