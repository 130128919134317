import React from 'react';
import {useDispatch} from 'react-redux';
import {WrappedFieldInputProps, change, WrappedFieldMetaProps} from 'redux-form';
import toNumber from 'lodash/toNumber';
import {FormControl, FormLabel} from 'react-bootstrap';

import {speedUnits} from 'utils/distance';

import styles from './styles.module.scss';

type OwnProps = {
    label: string;
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    minValue: number;
    maxValue: number;
    unit: string;
};

const getValueFromDiapason = (value, diapason): number => {
    const [min, max] = diapason;

    if (value < min) {
        return min;
    }

    if (value > max) {
        return max;
    }

    return value;
};

const SpeedInput: React.FC<OwnProps> = (props) => {
    const {
        unit = speedUnits.DEFAULT,
        minValue,
        maxValue,
        label,
        meta: {form},
        input: {onChange, value, name},
    } = props;

    const dispatch = useDispatch();

    const onBlur = (e) => {
        const val = getValueFromDiapason(toNumber(e.target.value), [minValue, maxValue]);

        dispatch(change(form, name, val));
    };

    return (
        <div className={styles.container}>
            <FormLabel>{label}</FormLabel>
            <div className={styles.inputContainer}>
                <FormControl type="text" onChange={onChange} value={value} onBlur={onBlur} />
                <div className={styles.units}>
                    <span>{unit}</span>
                </div>
            </div>
        </div>
    );
};

export default SpeedInput;
