import React from 'react';
import {change, Field} from 'redux-form';
import {useDispatch, useSelector} from 'react-redux';

import {selectTravelOrderDefaultPresets} from 'store/reducers/registry/selectors';

import {removeNonDigitChars} from 'utils';
import {getSpeedUnit, transformDistance} from 'utils/distance';

import {useGetIsReduxFormHasInitialStateFields} from 'pages/TravelOrders/components/common/modals/TimerSettingsModal/components/Form/hooks';
import {getName} from 'pages/TravelOrders/components/common/modals/TimerSettingsModal/components/Form/utils';

import SpeedInput from './components/SpeedInput';

import ResetButton from '../ResetButton';

import styles from './styles.module.scss';

type OwnProps = {
    form: string;
};

const SpeedDiapason: React.FC<OwnProps> = ({form}) => {
    const dispatch = useDispatch();

    const {averageSpeedRangeFrom, averageSpeedRangeTo, averageSpeedFrom, averageSpeedTo} = useSelector(
        selectTravelOrderDefaultPresets,
    );

    const isAverageSpeedFromValuePristine = useGetIsReduxFormHasInitialStateFields(form, getName('averageSpeedFrom'));
    const isAverageSpeedToValuePristine = useGetIsReduxFormHasInitialStateFields(form, getName('averageSpeedTo'));

    const resetBtnDisabled = isAverageSpeedFromValuePristine && isAverageSpeedToValuePristine;

    const speedUnit = getSpeedUnit();

    const handleResetToDefaults = () => {
        dispatch(change(form, getName('averageSpeedFrom'), transformDistance(averageSpeedFrom).fromMiles));
        dispatch(change(form, getName('averageSpeedTo'), transformDistance(averageSpeedTo).fromMiles));
    };

    return (
        <div className={styles.container}>
            <Field
                name={getName('averageSpeedFrom')}
                type="text"
                component={SpeedInput}
                label="FROM"
                normalize={removeNonDigitChars}
                minValue={transformDistance(averageSpeedRangeFrom).fromMiles}
                maxValue={transformDistance(averageSpeedRangeTo).fromMiles}
                unit={speedUnit}
            />
            <Field
                name={getName('averageSpeedTo')}
                type="text"
                component={SpeedInput}
                label="TO"
                normalize={removeNonDigitChars}
                minValue={transformDistance(averageSpeedRangeFrom).fromMiles}
                maxValue={transformDistance(averageSpeedRangeTo).fromMiles}
                unit={speedUnit}
            />
            <ResetButton onClick={handleResetToDefaults} disabled={resetBtnDisabled} className="align-self-end" />
        </div>
    );
};

export default SpeedDiapason;
