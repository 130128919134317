import React from 'react';

import Button from 'components/ui/Buttons/Button';
import ButtonsBlock from 'components/ui/ButtonsBlock';
import {ifFileHasPdfExtension, isImage} from 'components/ui/Files/FileUpload/utils';
import {NormalizedFile, NormalizedFileWithoutExtraData} from 'components/ui/Files/FileUpload//types';

import styles from './styles.module.scss';

interface OwnProps {
    file: NormalizedFile | NormalizedFileWithoutExtraData;
    gridView?: boolean;
    linkInNewTab?: boolean;
    handleRemove?(): void;
    viewInGallery?(): void;
    isFileRemovable: boolean;
}

const FilePreviewImage = (props: OwnProps): JSX.Element => {
    const {file, viewInGallery, linkInNewTab, gridView, handleRemove, isFileRemovable} = props;
    const {url: fileUrl, name: fileName} = file;
    const isPdfFile = ifFileHasPdfExtension(file);
    const openFileElement = (): JSX.Element => {
        if (isPdfFile) {
            // eslint-disable-next-line react/jsx-no-target-blank,jsx-a11y/anchor-has-content
            return <a href={file.url} aria-label="Open Pdf" target="_blank" className="file-preview__hover" />;
        }

        return <div onClick={viewInGallery} className="file-preview__hover" />;
    };
    const checkFileType = (): any => {
        if (viewInGallery) {
            return isPdfFile ? window.open(file.url) : viewInGallery();
        }
    };

    if (gridView) {
        return (
            <div className={styles.grid}>
                <div className="file-preview">
                    <ButtonsBlock className="action-buttons">
                        {viewInGallery && <Button onClick={checkFileType}>View</Button>}
                        {!file.isBlob && (
                            <a className="button button-grey" href={fileUrl} download={fileName}>
                                Download
                            </a>
                        )}
                        {isFileRemovable && <Button onClick={handleRemove}>Delete</Button>}
                    </ButtonsBlock>
                    {openFileElement()}
                    {linkInNewTab && (
                        <a
                            href={fileUrl}
                            className="file-preview__link-in-new-tab"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                        </a>
                    )}
                    {isImage(file) ? <img src={file.thumbUrl || file.url} alt="" /> : file.extension}
                </div>
            </div>
        );
    }
    const fileIcon = isImage(file) ? <img src={file.thumbUrl || file.url} alt="" /> : file.extension;
    return isPdfFile ? (
        // eslint-disable-next-line react/jsx-no-target-blank,jsx-a11y/anchor-has-content
        <a style={{color: 'gray', textDecoration: 'none'}} className={styles.list} href={file.url} target="_blank">
            {fileIcon}
        </a>
    ) : (
        <div className={styles.list}>{fileIcon}</div>
    );
};

export default FilePreviewImage;
