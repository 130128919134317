import React from 'react';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import WeightsUnitsOfMeasurement from 'components/ui/Form/WeightsUnitsOfMeasurement';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import useFormActions from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/hooks/useFormActions';
import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';

const Unit: React.FC = () => {
    const {getTruckPayloadUnitFieldName} = getFormFieldNames();
    const {setTruckPayloadFieldName, setTruckPayloadUnit} = useFormActions();
    const payloadUnit = useGetReduxFormValue(FORM_NAME, getTruckPayloadUnitFieldName());

    const setPayloadUnit = (unit): void => {
        setTruckPayloadUnit(unit);
    };

    const clearPayload = (): void => {
        setTruckPayloadFieldName(null);
    };

    return (
        <WeightsUnitsOfMeasurement
            unit={payloadUnit}
            setUnit={setPayloadUnit}
            clearFields={clearPayload}
            disabled={false}
        />
    );
};

export default Unit;
