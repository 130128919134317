import React from 'react';
import isEmpty from 'lodash/isEmpty';

import {NoteType, NoteItem as NoteItemData} from '../../types';
import NoteItem from './components/NoteItem';
import DeleteNoteItem from './components/DeleteNoteItem';
import styles from './notesList.module.scss';

interface OwnProps {
    notes: NoteItemData[];
    notesType: NoteType;
    onDeleteNote(note: NoteItemData);
    isShownDeleteButton: boolean;
}

const notesTypesDescriptions: Record<NoteType, string> = {
    all: 'notes',
    general: 'General note',
    forDrivers: 'Notes for drivers',
};

const NotesList: React.FC<OwnProps> = (props) => {
    const {notes, notesType, onDeleteNote, isShownDeleteButton} = props;
    if (isEmpty(notes)) {
        const textTabsName = notesTypesDescriptions[notesType];
        return (
            <div className={styles.empty_notes}>
                The current quote doesn&#8217;t have <strong>{textTabsName}</strong>.
            </div>
        );
    }
    if (!notes) {
        return null;
    }
    return (
        <div className={styles.list}>
            {notes.map((note, index) => (
                <NoteItem note={note} key={index}>
                    {note.type === 'general' && (
                        <DeleteNoteItem isShownDeleteButton={isShownDeleteButton} onDelete={() => onDeleteNote(note)} />
                    )}
                </NoteItem>
            ))}
        </div>
    );
};

export default NotesList;
