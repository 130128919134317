import Load from 'deprecated/core/entities/Load/types';

import {SettlementOwner, SettlementCarrier} from 'core/entities/Settlement/types';
import {getSettlementLoad, isSettlementOwner} from 'core/entities/Settlement/settlement';

type LoadInfo = {
    loadNumber: number | undefined;
};

const getLoadNumberFromSettlementWIthOldFormat = (settlement: SettlementOwner | SettlementCarrier): number => {
    const load = getSettlementLoad(settlement) || ({} as Load);
    return load.load_id;
};

export const getLoadInfo = ({
    settlement,
    isNewFormat,
}: {
    settlement: SettlementOwner | SettlementCarrier;
    isNewFormat: boolean;
}): LoadInfo => {
    if (!isSettlementOwner(settlement)) {
        return {loadNumber: getLoadNumberFromSettlementWIthOldFormat(settlement)};
    }
    if (isNewFormat) {
        return {
            loadNumber: settlement.load_id,
        };
    }
    return {loadNumber: getLoadNumberFromSettlementWIthOldFormat(settlement)};
};
