import React from 'react';
import classNames from 'classnames';
import toLower from 'lodash/toLower';

import styles from './styles.module.scss';

type OwnProps = {
    text?: string | null;
    highlightedWords: string[];
    highlightedClassName?: string;
    caseSensitive?: boolean;
};

const TextHighlighter: React.FC<OwnProps> = (props) => {
    const {text = '', highlightedWords, caseSensitive = false, highlightedClassName = ''} = props;

    if (!text) {
        return null;
    }

    const wordsLowered = highlightedWords.map(toLower);
    const regex = wordsLowered.join('|');
    const flags = caseSensitive ? 'g' : 'gi';

    const chunks = text.split(new RegExp(`(${regex})`, flags));

    return (
        <>
            {chunks.map((chunk, index) => (
                <React.Fragment key={index}>
                    {wordsLowered.includes(toLower(chunk)) ? (
                        <span className={classNames(styles.coincidence, highlightedClassName)}>{chunk}</span>
                    ) : (
                        chunk
                    )}
                </React.Fragment>
            ))}
        </>
    );
};

export default TextHighlighter;
