import {InferActions} from 'store';

import * as types from './actionTypes';

type UserLogInPayload = {
    access_token: string | null;
    expires_in: number | null;
    refresh_token: string | null;
    scope: string | null;
    token_type: string | null;
};

export const userLogIn = (payload: UserLogInPayload) => ({type: types.USER_LOGIN, payload} as const);

export const userLogOut = () => ({type: types.USER_LOGOUT} as const);

const actionCreators = {
    userLogIn,
    userLogOut,
};

export type AuthActionsTypes = InferActions<typeof actionCreators>;

export default actionCreators;
