import {store} from 'store';
import {getCurrentRegion} from 'store/reducers/appSettings/selectors';

type Data = {
    default?: string;
    dispatchland?: {
        EU?: string;
        default?: string;
    };
};

type Text = {
    serverName: Data;
    globalCompanyShortName: Data;
    globalCompanyName: Data;
    trackingUrl: Data;
    rateConfirmation: Data;
    noRcFile: Data;
    rc: Data;
    billOfLading: Data;
    bol: Data;
    mc: Data;
};

const text: Text = {
    serverName: {
        dispatchland: {
            default: 'Dispatchland',
        },
    },
    globalCompanyShortName: {
        dispatchland: {
            default: 'd',
        },
    },
    globalCompanyName: {
        dispatchland: {
            default: 'dispatchland',
        },
    },
    trackingUrl: {
        dispatchland: {
            default: 'https://tracking.dispatchland.com',
        },
    },
    // Localization
    // Service
    rateConfirmation: {
        default: 'Rate Confirmation',
        dispatchland: {
            EU: 'Order',
        },
    },
    rc: {
        default: 'RC',
        dispatchland: {
            EU: 'Order',
        },
    },
    noRcFile: {
        default: 'No RC file',
        dispatchland: {
            default: 'No RC file',
        },
    },
    // Convention
    billOfLading: {
        default: 'Bill of Lading',
        dispatchland: {
            EU: 'CMR',
        },
    },
    bol: {
        default: 'BoL',
        dispatchland: {
            EU: 'CMR',
        },
    },
    mc: {
        default: 'MC',
        dispatchland: {
            EU: 'REG',
        },
    },
};

export default (key: keyof Text): string => {
    const currentRegion: string = getCurrentRegion(store.getState()).toUpperCase();
    const cluster = 'dispatchland' as string;

    if (text[key]?.[cluster]?.[currentRegion]) {
        return text[key]?.[cluster]?.[currentRegion];
    }

    if (text[key]?.[cluster]?.default) {
        return text[key]?.[cluster]?.default;
    }

    if (text[key]?.default) {
        return text[key]?.default || '';
    }

    return '';
};
