import React from 'react';

import ButtonGroupSwitch from 'components/ui/ButtonGroupSwitch';
import {TABS} from 'components/ui/modals/AddTONotes/constants';

import styles from './styles.module.scss';

type OwnProps = {
    setActiveTab: React.Dispatch<React.SetStateAction<typeof TABS[keyof typeof TABS]>>;
    activeTab: string;
};

const Tabs: React.FC<OwnProps> = (props) => {
    const {activeTab, setActiveTab} = props;

    const generalBtn = {label: 'general', key: TABS.general, onSelect: () => setActiveTab(TABS.general)};
    const eventBtn = {label: 'event', key: TABS.event, onSelect: () => setActiveTab(TABS.event)};
    const allBtn = {label: 'all', key: TABS.all, onSelect: () => setActiveTab(TABS.all)};

    return (
        <div className={styles.group}>
            <ButtonGroupSwitch activeKey={activeTab} buttons={[allBtn, generalBtn, eventBtn]} />
        </div>
    );
};

export default Tabs;
