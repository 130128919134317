import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button/index';

import {statuses} from 'core/entities/Settlement/constants';

const ResetStatusModal = (props) => {
    const {
        onClose,
        handlers: {onReset},
        data: {settlement},
    } = props;
    return (
        <Modal show={true} dialogClassName="info-modal info-modal-no-break" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Reset Status</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-error" />
                    </div>
                    <div className="col-11">
                        <p>
                            Resetting the status of the <strong>#{settlement.settlement_number} settlement</strong> will
                            change it back to <strong>{statuses.RATED}</strong>.
                        </p>
                        <p>After that, you will have to go through all the steps again.</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} buttonSize="small" colorTheme="grey">
                    Cancel
                </Button>
                <Button onClick={onReset} buttonSize="small" colorTheme="red">
                    Reset
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ResetStatusModal;
