import {Dispatcher} from 'core/entities/Dispatcher/types';
import User from 'core/entities/Dispatcher/types/User';
import Driver from 'core/entities/Driver/types';

import File from 'types/File';

import {RawDriverChannelFromApi, RawMessageFromApi, DriverTruckData, MessageStatus} from 'widgets/Chat/types';
import * as wsTypes from 'widgets/Chat/redux/actionTypes/wsTypes';

export const FETCH_TRUCK_DATA_FOR_DRIVER_CHANNEL = 'CHAT_FETCH_TRUCK_DATA_FOR_DRIVER_CHANNEL' as const;
export const SET_DRIVER_SEARCH_PARAMS = 'CHAT_SET_DRIVER_SEARCH_PARAMS' as const;
export const ENTER_TO_DRIVER_CHANNEL = 'CHAT_ENTER_TO_DRIVER_CHANNEL' as const;
export const LEAVE_DRIVER_CHANNEL = 'CHAT_LEAVE_DRIVER_CHANNEL' as const;
export const DATA_RECEIVED = 'CHAT_DATA_RECEIVED' as const;
export const TOGGLE_CHAT = 'CHAT_TOGGLE_WIDGET' as const;
export const SENDING_MESSAGE = 'CHAT_SENDING_MESSAGE' as const;

interface ReceivedChatData {
    type: typeof DATA_RECEIVED;
    payload: {
        drivers: Driver[];
        driversAvatars: File[];
        dispatchers: Dispatcher[];
        channels: RawDriverChannelFromApi[];
        currentUser: User;
    };
}

interface ReceivedMoreChannelMessages {
    type: typeof wsTypes.WEB_SOCKET_CHAT_MORE_CHANNEL_MESSAGES_RECEIVED;
    payload: {
        driverID: number;
        messages: RawMessageFromApi[];
        currentUser: User;
    };
}

interface EnterDriverChannel {
    type: typeof ENTER_TO_DRIVER_CHANNEL;
    payload: {
        driverID: number;
    };
}

interface FetchTruckDataFroChannel {
    type: typeof FETCH_TRUCK_DATA_FOR_DRIVER_CHANNEL;
    payload: {
        driverTruckData: DriverTruckData;
    };
}

interface LeaveDriverChannel {
    type: typeof LEAVE_DRIVER_CHANNEL;
}

interface DriverSearch {
    type: typeof SET_DRIVER_SEARCH_PARAMS;
    payload: {truckNumber: string};
}

interface ReceivedChatMessage {
    type: typeof wsTypes.WEB_SOCKET_CHAT_RECEIVED_CHAT_MESSAGE;
    payload: {message: RawDriverChannelFromApi; currentUser: User};
}

interface MarkLocalMessagesAsRead {
    type: typeof wsTypes.WEB_SOCKET_CHAT_MARK_LOCAL_MESSAGES_AS_READ;
    payload: {driverID: number};
}

interface ToggleChat {
    type: typeof TOGGLE_CHAT;
    payload: {opened: boolean};
}

interface SetWebSocketID {
    type: typeof wsTypes.WEB_SOCKET_CHAT_SET_SOCKET_ID;
    payload: {socketId: string | null};
}

interface ClearSocketID {
    type: typeof wsTypes.WEB_SOCKET_CHAT_CLEAR_SOCKET_ID;
}

interface SendingMessage {
    type: typeof SENDING_MESSAGE;
    payload: {messageStatus: MessageStatus};
}

interface SendMessageError {
    type: typeof wsTypes.WEB_SOCKET_CHAT_SEND_CHAT_MESSAGE_ERROR;
}

interface OpenDriverChannel {
    type: typeof wsTypes.WEB_SOCKET_CHAT_MARK_AS_READ_BY_CURRENT_DISPATCHER;
}

interface WebSocketDisconnect {
    type: typeof wsTypes.WEB_SOCKET_CHAT_DISCONNECT;
}

export type ChatActionTypes =
    | ReceivedMoreChannelMessages
    | ReceivedChatData
    | EnterDriverChannel
    | FetchTruckDataFroChannel
    | LeaveDriverChannel
    | DriverSearch
    | ReceivedChatMessage
    | MarkLocalMessagesAsRead
    | ToggleChat
    | SetWebSocketID
    | ClearSocketID
    | SendMessageError
    | OpenDriverChannel
    | WebSocketDisconnect
    | SendingMessage;
