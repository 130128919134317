import React from 'react';
import {useLocation} from 'react-router';

import {Dispatcher} from 'core/entities/Dispatcher/types';
import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';

import ArchivingSuccessWrapper from 'components/ui/modals/ArchivingSuccessWrapper';
import ButtonLink from 'components/ui/Buttons/ButtonLink';
import Button from 'components/ui/Buttons/Button';
import {getDispatcherLinks} from 'routing/linkBuilder';

type OwnProps = {
    data: {dispatcher: Dispatcher};
    handlers: {onViewArchivedList()};
    onClose();
};
const ArchivingSuccess: React.FC<OwnProps> = (props) => {
    const {
        data: {dispatcher},
        handlers: {onViewArchivedList},
        onClose,
    } = props;
    const dispatcherName = getDispatcherName(dispatcher);
    const {view: viewLink, list: listDispatchersLink} = getDispatcherLinks(dispatcher);
    const location = useLocation();
    const isListPage = location?.pathname === listDispatchersLink;
    const buttons = (
        <>
            <div />
            <div className="d-flex justify-content-end">
                <Button onClick={onViewArchivedList} buttonSize="medium" colorTheme="grey" className="mr10">
                    View Archive
                </Button>

                {isListPage ? (
                    <ButtonLink href={viewLink} buttonSize="medium" colorTheme="green" className="mr10">
                        View Profile
                    </ButtonLink>
                ) : (
                    <Button onClick={onClose} buttonSize="medium" colorTheme="green" className="mr10">
                        View Profile
                    </Button>
                )}
            </div>
        </>
    );
    return (
        <ArchivingSuccessWrapper buttons={buttons}>
            <p>
                <strong>{dispatcherName}</strong> profile has been moved to archive successfully.
            </p>
        </ArchivingSuccessWrapper>
    );
};

export default ArchivingSuccess;
