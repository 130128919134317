import React from 'react';

import {TravelOrderLink} from 'components/ui/Links';

import {DriverTruckData} from '../../types';

type OwnProps = {
    truckData: DriverTruckData;
};

const CurrentTravelOrder: React.FC<OwnProps> = (props) => {
    const {truckData} = props;

    const currentTravelOrderNumber = truckData.current_travel_order_number;

    return currentTravelOrderNumber ? (
        <>
            ; T.O.{' '}
            <TravelOrderLink
                travelOrder={{number: currentTravelOrderNumber}}
                content={`#${currentTravelOrderNumber}`}
                className="second-link"
            />
        </>
    ) : null;
};

export default CurrentTravelOrder;
