import React from 'react';

import {createDate} from 'utils/dateTime';

import CurrencyAmount from 'components/ui/CurrencyAmount';

import {UserSettlement} from 'pages/Settlements/components/common/modals/SetupSettlementsBatchModal/types';

type OwnProps = {
    settlement: UserSettlement;
};

const getSettlementLastDeliveryCheckoutDate = (settlement: UserSettlement | undefined): string | undefined => {
    if (!settlement || !settlement.settlement_date) {
        return undefined;
    }
    const {fullDate} = createDate(settlement.settlement_date);
    return fullDate;
};

const getSettlementDryRunDate = (settlement: UserSettlement | undefined): string | undefined => {
    if (!settlement || !settlement.dry_run_date) {
        return undefined;
    }
    const {fullDate} = createDate(settlement.dry_run_date);
    return fullDate;
};

const UserSettlementInfo: React.FC<OwnProps> = (props) => {
    const {settlement} = props;
    const totalDriverPayment = settlement.total_pay;
    const dryRunDate = getSettlementDryRunDate(settlement);
    const lastDeliveryCheckoutDate = getSettlementLastDeliveryCheckoutDate(settlement);
    return (
        <div className="settlement-item-list__row">
            <div className="settlement-item-list__col">
                <div>sett #{settlement.settlement_number}</div>
            </div>
            <div className="settlement-item-list__col">
                {dryRunDate ? <span>dry run: {dryRunDate}</span> : <span>out: {lastDeliveryCheckoutDate}</span>}
            </div>
            <div className="settlement-item-list__col">
                <div className="text-right">
                    <CurrencyAmount amount={totalDriverPayment} currency={settlement.currency} />
                </div>
            </div>
        </div>
    );
};

export default React.memo(UserSettlementInfo);
