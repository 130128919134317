import * as requests from 'core/gateways/CustomerApiGateway/requests';

import * as appActions from 'store/actions';

import {openModal} from 'components/ui/ModalProvider/actions';

import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import Pagination from 'types/Pagination';

import {Agent} from 'core/entities/Agent';

import {modalNames} from 'pages/Customers/components/common/modals/modalMap';
import agentsActionCreators from 'pages/Customers/redux/actionCreators/agents';
import {getCustomerAgentsPagination, getCustomerAgentsShowArchived} from 'pages/Customers/redux/selectors/agents';
import {
    SuccessAgentModalButtonsActions,
    SuccessAgentModalData,
} from 'pages/Customers/components/common/modals/SuccessAgentModal';
import {WarningAgentModalData} from 'pages/Customers/components/common/modals/WarningAgentModal';

type CreateAgentOnSubmitResponse = Promise<{
    isSubmitted: boolean;
    agent: Agent;
}>;

type UpdateAgentOnSubmitResponse = Promise<{
    isSubmitted: boolean;
    agent: Pick<Agent, 'agentName'>;
}>;

export const getCustomerAgents = (customerId: string) => async (dispatch, getState) => {
    const state = getState();
    const showArchived = getCustomerAgentsShowArchived(state);
    const pagination = getCustomerAgentsPagination(state);

    try {
        const response = showArchived
            ? await requests.fetchCustomerAgentsArchived(customerId, pagination)
            : await requests.fetchCustomerAgents(customerId, pagination);

        const payload = {agents: response.data, pagination: parsePaginationHeaders(response.headers)};

        dispatch(agentsActionCreators.setAgents(payload));
    } catch (e) {
        dispatch(appActions.handleError(e));
    }
};

export const getCustomerAgentsWithLoader = (customerId: string) => async (dispatch) => {
    dispatch(appActions.showLoader());

    await dispatch(getCustomerAgents(customerId));

    dispatch(appActions.hideLoader());
};

export const createCustomerAgent = ({customerId, fields}) => async (dispatch) => {
    try {
        dispatch(appActions.showLoader());

        const {data} = await requests.createCustomerAgentRequest({customerId, body: fields});

        dispatch(getCustomerAgents(customerId));

        return data;
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const updateCustomerAgent = ({customerId, agentId, fields}) => async (dispatch) => {
    try {
        dispatch(appActions.showLoader());

        const {data} = await requests.updateCustomerAgentRequest({customerId, agentId, body: fields});

        await dispatch(getCustomerAgents(customerId));

        return data;
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const archiveCustomerAgent = ({customerId, agentId}) => async (dispatch) => {
    try {
        dispatch(appActions.showLoader());

        const {data} = await requests.archiveCustomerAgentRequest({customerId, agentId});

        dispatch(getCustomerAgents(customerId));

        return data;
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const restoreCustomerAgent = ({customerId, agentId}) => async (dispatch) => {
    try {
        dispatch(appActions.showLoader());

        const {data} = await requests.restoreCustomerAgentRequest({customerId, agentId});

        dispatch(getCustomerAgents(customerId));

        return data;
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const clearCustomerAgents = () => (dispatch) => {
    dispatch(agentsActionCreators.clearCustomerAgents());
};

export const switchArchived = (isArchived: boolean) => (dispatch) => {
    dispatch(agentsActionCreators.switchShowArchived(isArchived));
};

export const setPaginationParams = (params: Partial<Pagination>) => (dispatch) => {
    dispatch(agentsActionCreators.setPaginationParams(params));
};

export const showCreateAgentModal = ({customerName, customerId}) => (dispatch): CreateAgentOnSubmitResponse => {
    return new Promise((resolve) => {
        dispatch(
            openModal({
                modalName: modalNames.manageAgentModal,
                data: {
                    customerName,
                    customerId,
                    modalType: 'create',
                },
                handlers: {
                    onSubmit: (agent: Agent) => resolve({isSubmitted: true, agent}),
                },
            }),
        );
    });
};

export const showUpdateAgentModal = ({customerName, customerId, agent}) => (dispatch): UpdateAgentOnSubmitResponse => {
    return new Promise((resolve) => {
        dispatch(
            openModal({
                modalName: modalNames.manageAgentModal,
                data: {
                    customerName,
                    customerId,
                    agent,
                    modalType: 'update',
                },
                handlers: {
                    onSubmit: ({agentName}: {agentName: string}) => resolve({isSubmitted: true, agent: {agentName}}),
                },
            }),
        );
    });
};

export const showAgentSuccessActionModal = ({agentName, action}: SuccessAgentModalData) => (
    dispatch,
): Promise<{action: SuccessAgentModalButtonsActions; onClose: () => void}> => {
    return new Promise((resolve) => {
        dispatch(
            openModal({
                modalName: modalNames.successAgentModal,
                data: {agentName, action},
                handlers: {onViewList: resolve},
            }),
        );
    });
};

export const showAgentWarningModal = ({agentName, action}: WarningAgentModalData) => (dispatch): Promise<boolean> => {
    return new Promise((resolve) => {
        dispatch(
            openModal({
                modalName: modalNames.warningAgentModal,
                data: {agentName, action},
                handlers: {
                    onCancel: () => resolve(false),
                    onConfirm: () => resolve(true),
                },
            }),
        );
    });
};
