import React, {PureComponent} from 'react';
import {FormLabel, FormControl, FormGroup, Form} from 'react-bootstrap';
import OverlayTriggerCustom from '../OverlayTriggerCustom';

import getValidationState from '../getValidationState';

import styles from './passwordInput.module.scss';

class PasswordInput extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {showPassword: false};
    }

    showInputValue = () => this.setState((prevState) => ({showPassword: !prevState.showPassword}));

    render() {
        const {label, name, onChange, showValidation = false, placeholder, bsPrefix, maxLength} = this.props;
        let onChangePassword = onChange && onChange.bind(this, name);

        if (showValidation) {
            const {meta, errorTooltipPlacement} = this.props;
            const {onBlur, onKeyDown, disabled, inputRef, value} = this.props.input;
            onChangePassword = this.props.input.onChange;

            const validate = getValidationState(meta);

            return (
                <FormGroup
                    className={!validate ? `has-feedback has-error ${styles.input_item}` : `${styles.input_item}`}
                >
                    <FormLabel>{label}</FormLabel>
                    <div className={styles.input_wrap}>
                        <OverlayTriggerCustom inputMeta={meta} placement={errorTooltipPlacement}>
                            <FormControl
                                {...this.props.input}
                                onChange={onChangePassword}
                                type={this.state.showPassword ? 'text' : 'password'}
                                placeholder={placeholder}
                                bsPrefix={bsPrefix}
                                autoComplete="new-password"
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                inputref={inputRef}
                                disabled={disabled}
                                value={value}
                                maxLength={maxLength}
                            />
                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <button
                                type="button"
                                onClick={this.showInputValue}
                                className={`button button-grey input-group-button-after action-icon
                                    ${this.state.showPassword ? 'action-icon-view-slashed' : 'action-icon-view'}`}
                            />
                        </OverlayTriggerCustom>
                    </div>
                    <div>
                        <Form.Control.Feedback type="invalid" className={!validate ? 'error' : ''}>
                            <i className="fa fa-exclamation-circle" />
                        </Form.Control.Feedback>
                    </div>
                </FormGroup>
            );
        }
        return (
            <FormGroup className={styles.input_item}>
                <FormLabel>{label}</FormLabel>
                <div className={styles.input_wrap}>
                    <FormControl
                        {...this.props.input}
                        onChange={onChangePassword}
                        type={this.state.showPassword ? 'text' : 'password'}
                        placeholder={placeholder}
                        maxLength={maxLength}
                    />

                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        onClick={this.showInputValue}
                        className={`button button-grey input-group-button-after action-icon
                                ${this.state.showPassword ? 'action-icon-view-slashed' : 'action-icon-view'}`}
                    />
                </div>
            </FormGroup>
        );
    }
}

export default PasswordInput;
