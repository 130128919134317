import React from 'react';
import {Field} from 'redux-form';

import TextArea from 'components/ui/Form/TextArea';

const Notes: React.FC<{fieldName: string}> = (props) => {
    const {fieldName} = props;
    return (
        <>
            <Field
                label="Notes"
                name={fieldName}
                maxLength={100}
                component={TextArea}
                rows={1}
                placeholder="enter notes"
            />
        </>
    );
};

export default Notes;
