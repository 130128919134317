import isEmpty from 'lodash/isEmpty';

import {TripMonitorState} from 'pages/TravelOrders/redux/reducers/list';

import {getLoadFromTravelOrder} from 'core/entities/TravelOrder/modules/travelOrderLoad';
import TravelOrder from 'core/entities/TravelOrder/types';
import Load from 'core/entities/Load/types';

export const handleReducerByReceivedGeneralNote = (params: {state: TripMonitorState; travelOrder: TravelOrder}) => {
    const {travelOrder, state} = params;

    if (isEmpty(state.items) || isEmpty(travelOrder)) {
        return state;
    }

    const updatedItems = state.items.map((item) => (item.number === travelOrder.number ? {...travelOrder} : item));

    return {...state, items: updatedItems};
};

export const handleReducerByReceivedLoadNote = (params: {state: TripMonitorState; load: Load}) => {
    const {load, state} = params;

    if (isEmpty(state.items) || isEmpty(load)) {
        return state;
    }

    const updatedItems = state.items.map((item) => {
        const loadFromItem = getLoadFromTravelOrder(item);

        return loadFromItem?.number === load.number ? {...item, loads: [load]} : item;
    });

    return {...state, items: updatedItems};
};
