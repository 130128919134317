import {handleError} from 'store/actions/common';

import {fetchDispatcherRolesOptions} from 'core/gateways/DispatcherApiGateway/requests';
import {fetchNetworksBenefitsList} from 'core/gateways/DriverApiGateway/requests';
import {fetchEquipment} from 'core/gateways/TruckApiGateway/requests';

import restapi from 'services/restapi';

import actionsCreators from './actionCreators';

export const fetchRegistry = () => async (dispatch): Promise<void> => {
    try {
        const {data} = await restapi.get('/registry');

        dispatch(actionsCreators.receivedRegistry(data));
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const fetchDefaultTravelOrderPresets = (): any => async (dispatch): Promise<void> => {
    try {
        const {data} = await restapi.get('/app-settings/default-travel-order-presets');

        dispatch(actionsCreators.receivedTravelOrderPresets(data));
    } catch (e) {
        dispatch(handleError(e));
    }
};

export const fetchUserRoleOptions = () => async (dispatch): Promise<void> => {
    try {
        const {data} = await fetchDispatcherRolesOptions();

        dispatch(actionsCreators.receivedUserRoleOptions(data));
    } catch (e) {
        dispatch(handleError(e));
    }
};

export const fetchNetworksBenefits = () => async (dispatch): Promise<void> => {
    try {
        const {data} = await fetchNetworksBenefitsList();

        dispatch(actionsCreators.receivedNetworksBenefits({networks: data}));

        dispatch(actionsCreators.receivedNetworksOptions({networks: data}));
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const fetchTruckEquipment = () => async (dispatch): Promise<void> => {
    try {
        const {data} = await fetchEquipment();
        const {additionalEquipment, generalEquipment: basicEquipment} = data || {};

        dispatch(actionsCreators.truckBasicEquipmentOptionsReceived({basicEquipment}));
        dispatch(actionsCreators.truckAdditionalEquipmentOptionsReceived({additionalEquipment}));
    } catch (error) {
        dispatch(handleError(error));
    }
};
