import {travelOrdersEvents} from '../../events';
import * as travelOrdersActions from './actions';

export const tripMonitorChannelMessageHandler = (store) => (uri, payload) => {
    const messageData = payload.msg;

    if (!messageData.event || !messageData.data) {
        return;
    }

    const {event, data} = messageData;

    switch (event) {
        case travelOrdersEvents.CHANGE_LOCATION_SAME_FROM:
            store.dispatch(travelOrdersActions.truckLocationSameFromReceived(data));
            break;
        case travelOrdersEvents.TRAVEL_ORDER_UPDATE:
        case travelOrdersEvents.DRY_RUN_CREATED:
        case travelOrdersEvents.DRY_RUN_CONFIRMED:
        case travelOrdersEvents.DRY_RUN_UPDATED:
        case travelOrdersEvents.DRY_RUN_CANCELED:
            store.dispatch(travelOrdersActions.travelOrderUpdate(data, event));
            break;
        default:
            break;
    }
};
