import {Facility} from 'core/entities/Facility/types';

import {FacilitySearchParams} from 'pages/Facilities/components/Filter';
import {FACILITIES_TABS} from 'pages/Facilities/constants';
import * as types from 'pages/Facilities/redux/actionTypes';

import Pagination from 'types/Pagination';

export type ListState = {
    searchParams: FacilitySearchParams;
    facilities: Facility[];
    pagination: Pagination;
    tab: string;
};

export const defaultState: ListState = {
    pagination: {currentPage: 1, perPage: 50, totalItemsCount: 0, pagesCount: 0},
    tab: FACILITIES_TABS.GENERAL,
    searchParams: {},
    facilities: [],
};

const listReducer = (state = defaultState, action): ListState => {
    switch (action.type) {
        case types.RECEIVED_FACILITIES: {
            return {
                ...state,
                facilities: action.payload.facilities,
                pagination: action.payload.pagination,
            };
        }

        case types.FACILITY_ARCHIVED: {
            const {facility: archivedFacility} = action.payload;
            return {
                ...state,
                facilities: state.facilities.filter((facility) => facility.id !== archivedFacility.id),
            };
        }

        case types.SET_CURRENT_PAGE: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: action.payload,
                },
            };
        }

        case types.SET_PER_PAGE: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                },
            };
        }

        case types.SET_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: action.payload,
            };
        }

        case types.LIST_TAB_RECEIVED: {
            return {
                ...state,
                tab: action.payload.tab,
            };
        }

        case types.FACILITY_CLEAR_STATE: {
            return {...defaultState};
        }

        // no default
    }

    const _exhaustiveCheck = action;

    return state;
};

export default listReducer;
