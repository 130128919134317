import React from 'react';

import Select from 'components/ui/Form/Select';

import allCurrencies, {CurrencyData, CurrencyValue} from 'utils/data/currency';

import CurrencySelectOptions from './components/CurrencySelectOptions';
import CurrencyOptionsList from './components/CurrencyOptionsList';

type OwnProps = {
    meta: any;
    input: any;
    label?: string | JSX.Element;
    formGroup: boolean;
    isDisabled: boolean;
    isCreatable?: boolean;
    isMulti: boolean;
    isLoading: boolean;
    placeholder: string;
    valueAsObject: boolean;
    redesign?: boolean;
    isLargeSize?: boolean;
    currencyMap: Record<CurrencyValue, CurrencyData>;
};

const CurrencySelect: React.FC<OwnProps> = (props) => {
    // For Settlements
    if (props.currencyMap) {
        const optionsData = CurrencyOptionsList(props.currencyMap || allCurrencies);

        return <Select {...props} data={optionsData} />;
    }

    const options = CurrencySelectOptions();

    return <Select {...props} data={options} />;
};

export default CurrencySelect;
