import isNaN from 'lodash/isNaN';
import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';
import mapKeys from 'lodash/mapKeys';

import {getNumberValue, getToFixedNumberValue} from 'utils';
import {CurrencyValue} from 'utils/data/currency';

import {getSettlementMiles, getSettlementTravelOrder, isSettlementOwner} from 'core/entities/Settlement/settlement';

import {Settlement, SettlementMiles} from './types';
import {TotalPayment, Payment} from './types/SettlementCurrency';

export const getSettlementsTotalMiles = (settlements: Settlement[]): SettlementMiles => {
    return settlements.reduce(
        (result, settlement) => {
            const {empty, loaded} = getSettlementMiles(settlement);
            result.empty += getNumberValue(empty);
            result.loaded += getNumberValue(loaded);
            return result;
        },
        {empty: 0, loaded: 0},
    );
};

export const getValueConvertedToNumber = (value: any): number => {
    const convertedNumber = parseFloat(value);

    return !isNaN(convertedNumber) ? convertedNumber : 0;
};

export const getSettlementTotalDriverPayment = (settlement: Settlement): number => {
    if (isSettlementOwner(settlement)) {
        return getValueConvertedToNumber(settlement.total_pay);
    }
    // eslint-disable-next-line
    // const {deductions, reimbursements} = settlement;
    const travelOrder = getSettlementTravelOrder(settlement);
    const travelOrderDriverPayment = (travelOrder && travelOrder.rate && travelOrder.rate.driver_payment) || 0;

    return getValueConvertedToNumber(travelOrderDriverPayment);
    // on moment of 14 sprint deductions and reimbursements not exists in settlement but these data will be added in next sprint
    // so we keep logic of calculate total amount
    // eslint-disable-next-line
    /*    const sumOfDeductions = sumBy(deductions, item => getConvertedNumber(item.amount));
        const sumOfReimbursements = sumBy(reimbursements, item => getConvertedNumber(item.amount));
        return convertedDriverPayment - sumOfDeductions + sumOfReimbursements;*/
};

export const getSettlementsTotalPayment = (listSettlements: Settlement[]): number => {
    const allSettlementsSummary = sumBy(listSettlements, (settlement) => getSettlementTotalDriverPayment(settlement));
    return getToFixedNumberValue(allSettlementsSummary);
};

export const getSettlementCurrency = (settlement: Settlement): CurrencyValue => {
    if (isSettlementOwner(settlement)) {
        const {currency} = settlement;
        return currency;
    }
    const travelOrder = getSettlementTravelOrder(settlement);
    const travelOrderCurrency = (travelOrder && travelOrder.rate && travelOrder.rate.currency) || 'USD';

    return travelOrderCurrency;
};

export const getSettlementsTotalPaymentForCarrier = (listSettlements: Settlement[]): TotalPayment[] => {
    const settlementsCurrencyList: Payment[] = listSettlements.reduce((result: Payment[], item) => {
        result.push({
            currency: getSettlementCurrency(item),
            amount: getSettlementTotalDriverPayment(item),
        });
        return result;
    }, []);

    const getTotalPayment = (paymentsList: Payment[]): TotalPayment[] | [] => {
        const result: TotalPayment[] = [];
        const groupToCurrency = groupBy(paymentsList, 'currency');

        mapKeys(groupToCurrency, (settlementsSummary, key: any) => {
            const allSettlementsSummary = sumBy(settlementsSummary, (item) => getValueConvertedToNumber(item.amount));
            result.push({
                totalAmount: getToFixedNumberValue(allSettlementsSummary),
                currency: key,
            });
        });

        return result;
    };

    return getTotalPayment(settlementsCurrencyList);
};
