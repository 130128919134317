type CurrentSearchParams = {
    billing_city?: string;
    billing_country?: string;
    billing_method?: string;
    billing_state?: string;
    billing_zip?: string;
    id?: string;
    name?: string;
    customerType?: string;
    main_phone?: string;
};

type CurrentSearchParamsToRequest = Omit<CurrentSearchParams, 'customerType'> & {customerType?: string[]};

export const transformSearchDataToRequest = (searchData: CurrentSearchParams): CurrentSearchParamsToRequest => {
    return {
        ...searchData,
        customerType: searchData?.customerType ? searchData.customerType.split(',') : undefined,
    };
};
