import React from 'react';
import Owner from 'core/entities/Owner/types';
import {OwnerLink} from 'components/ui/Links';
import {getOwnerName} from 'core/entities/Owner/modules/ownerName';
import {phoneNumberWithBraces} from 'utils';

interface OwnProps {
    owner: Owner;
}

const OwnerDuplicateError = ({owner}: OwnProps) => {
    return (
        <div>
            <p>You cannot create owner because a user with typed data already exists:</p>
            <OwnerLink owner={owner} className="second-link" content={getOwnerName(owner)} />{' '}
            {phoneNumberWithBraces(owner.mobile_phone)}
        </div>
    );
};

export default OwnerDuplicateError;
