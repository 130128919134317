import NoHistoryMovement from './NoHistoryMovement';

export const modalNames = {
    noHistoryMovement: 'TRUCKS_MAP_NO_HISTORY_MOVEMENT',
};

const modalMap = {
    [modalNames.noHistoryMovement]: NoHistoryMovement,
};

export default modalMap;
