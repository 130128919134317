import {AppState} from 'store';
import {getRouterCurrentLocation} from 'store/selectors';

export const getIsCustomerCreate = (state: AppState): boolean => {
    const currentLocation = getRouterCurrentLocation(state);
    return currentLocation === '/customers/create';
};

export const getIsFacilityCreate = (state: AppState): boolean => {
    const currentLocation = getRouterCurrentLocation(state);
    return currentLocation === '/facilities/create';
};
