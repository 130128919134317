import React from 'react';
import {InjectedFormProps, reduxForm} from 'redux-form';

import {SUMMARY_ITEM_FORM_SECTION} from 'components/common/LocationEvents/modals/CheckCallModal/constants';
import Button from 'components/ui/Buttons/Button';

import withNavigationConfirm from 'hocs/withNavigationConfirm';

import useConfirmFormData from '../../hooks/useConfirmFormData';
import SummaryInfo from './components/SummaryInfo';
import TruckInfo from './components/TruckInfo';

type OwnProps = {
    travelOrderNumber: number;
    handlePrev: () => void;
    form: string;
};

const ConfirmForm: React.FC<OwnProps & InjectedFormProps<{}, OwnProps>> = (props) => {
    const {handleSubmit, handlePrev, travelOrderNumber, form} = props;

    const {miles, speed, summaryDistanceList} = useConfirmFormData({travelOrderNumber, form});

    const summaryItemsList = (summaryDistanceList || []).map((item, i) => {
        return <SummaryInfo form={form} formSection={SUMMARY_ITEM_FORM_SECTION} summaryData={item} key={i} />;
    });

    return (
        <form onSubmit={handleSubmit}>
            <TruckInfo form={form} miles={miles} speed={speed} />

            {summaryItemsList}

            <div className="modal-body__bottom">
                <Button onClick={handlePrev}>Previous step</Button>
                <Button type="submit" colorTheme="blue">
                    Add Event(s)
                </Button>
            </div>
        </form>
    );
};

const WrappedForm = withNavigationConfirm(ConfirmForm);

const ReduxFrom = reduxForm<{}, OwnProps>({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(WrappedForm);

export default ReduxFrom;
