/**
 * Parses file into object with fields fileName and extraData
 * @param {string} file
 */
export default function formatExtraData({file, ...rest}) {
    return {
        fileName: file.name,
        extraData: rest || null,
    };
}
