import React from 'react';

const Label: React.FC<{subtitle: string; title: string}> = ({subtitle, title}) => {
    return (
        <div className="w-100">
            <h6 className="font-weight-light">
                {title}: <span className="font-weight-bold">{subtitle}</span>
            </h6>
        </div>
    );
};

export default Label;
