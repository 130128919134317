import React from 'react';
import {Field, reduxForm} from 'redux-form';

import Button from 'components/ui/Buttons/Button';

import BatchRadioInput from './BatchRadioInput';

const FORM_NAME = 'post-settlement-form';
const BATCH_FIELD_NAME = 'batch';

const Form = (props) => {
    const {handleSubmit, openedBatch, closedBatches, onClose} = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="custom-scroll">
                <div className="modal-body__main">
                    <p>Choose needed batch:</p>
                    <Field
                        name={BATCH_FIELD_NAME}
                        component={BatchRadioInput}
                        openedBatch={openedBatch}
                        closedBatches={closedBatches}
                    />
                </div>
            </div>

            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <Button type="submit" colorTheme="blue">
                        Post
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({form: FORM_NAME})(Form);
