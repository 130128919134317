import React from 'react';
import {Modal} from 'react-bootstrap';

import {PayPeriod} from 'core/entities/Settlement/types';
import Button from 'components/ui/Buttons/Button/index';

interface OwnProps {
    data: {payPeriod: PayPeriod};
    handlers: {onReopen()};
    onClose();
}

const ReOpenPayPeriodModal = (props: OwnProps): JSX.Element => {
    const {
        data: {payPeriod},
        handlers: {onReopen},
        onClose,
    } = props;
    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Open Period</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row align-items-center">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-error" />
                    </div>
                    <div className="col-11">
                        <p>
                            Please confirm opening of the pay period <strong>#{payPeriod.number}</strong> with{' '}
                            {payPeriod.settlements_total} settlement(s).
                        </p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} buttonSize="small" colorTheme="grey">
                    Cancel
                </Button>
                <Button onClick={onReopen} buttonSize="small" colorTheme="red">
                    Open Period
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReOpenPayPeriodModal;
