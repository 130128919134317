import Pagination from 'types/Pagination';

const transformApiPaginationToClient = (pagination: {
    totalPages: number;
    totalCount: number;
    perPage: number;
    page: number;
}): Pagination => ({
    totalItemsCount: pagination.totalCount,
    pagesCount: pagination.totalPages,
    currentPage: pagination.page,
    perPage: pagination.perPage,
});

export default transformApiPaginationToClient;
