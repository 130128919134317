import head from 'lodash/head';

import TravelOrder from 'core/entities/TravelOrder/types';

export const getTravelOrderLocationEvents = (travelOrder?: TravelOrder | null): TravelOrder['locationEvents'] =>
    travelOrder?.locationEvents || [];

export const getTravelOrderLastLocationEvent = (
    travelOrder?: TravelOrder | null,
): TravelOrder['locationEvents'][number] | undefined => head(travelOrder?.locationEvents || []);
