import React, {ReactNode, useEffect, useRef} from 'react';
import classNames from 'classnames';
import {useToggle} from 'react-use';

import Button from 'components/ui/Buttons/Button';
import Tooltip from 'components/ui/Tooltip';

import styles from './styles.module.scss';

type OwnProps = {
    children: ReactNode;
};

const ButtonsBlock: React.FC<OwnProps> = ({children}) => {
    const [expanded, toggle] = useToggle(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (expanded && containerRef.current && !containerRef.current.contains(e.target)) {
                toggle(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef, expanded, toggle]);

    const buttonListClassName = classNames(styles.buttonList, {[styles.buttonListActice]: expanded});

    return (
        <div ref={containerRef} className={styles.buttonBlock}>
            <Tooltip tooltipContent="view more" typeContent="button" position="right">
                <Button onClick={toggle} buttonIcon="plus" buttonSize="icon" className="btn-link" />
                <div className={buttonListClassName}>{children}</div>
            </Tooltip>
        </div>
    );
};

export default ButtonsBlock;
