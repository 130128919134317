type RPM = {
    rate: number;
    distance: number;
};

const convertToNumberWithTwoDecimalPlaces = (number: number): string => {
    const roundedNumber = 100;
    const symbolsAfterComma = 2;
    return (Math.round(number * roundedNumber) / roundedNumber).toFixed(symbolsAfterComma);
};

export const getRPM = ({distance, rate}: RPM): string => {
    const rpmResult = distance ? rate / distance : 0;

    return convertToNumberWithTwoDecimalPlaces(rpmResult);
};
