import React from 'react';

import Button from 'components/ui/Buttons/Button';
import {isImage} from 'components/ui/Files/FileUpload/utils';

import {NormalizedFile} from 'widgets/Chat/types';

import styles from './attachPreviewImage.module.scss';

interface OwnProps {
    file: NormalizedFile;
    handleRemove?(): void;
}

const AttachPreviewImage = (props: OwnProps): JSX.Element => {
    const {file, handleRemove} = props;

    return (
        <div className={styles.grid}>
            <Button onClick={handleRemove} buttonSize="icon" buttonIcon="times" className="close-btn" />
            {isImage(file) ? <img src={file.url} alt="" /> : file.extension}
        </div>
    );
};

export default AttachPreviewImage;
