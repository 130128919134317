import React from 'react';
import {Form} from 'react-bootstrap';

import useLoadBoardNotificationActions from 'widgets/Notifications/hooks/useLoadBoardNotificationActions';
import {useSwitchPlaySoundOnGetNewNotification} from 'widgets/Notifications/hooks/useSoundNewNotification';
import styles from './notificationHeader.module.scss';

const NotificationsHeader: React.FC = () => {
    const {removeAllNotifications} = useLoadBoardNotificationActions();
    const {switchEnabled, sound} = useSwitchPlaySoundOnGetNewNotification();
    return (
        <div className={styles.header_menu}>
            <p className={styles.title_menu}>Notifications</p>
            <span className="main-link ml-5" onClick={removeAllNotifications}>
                Clear all
            </span>
            <Form className={styles.notification_switch}>
                <p className="mr-2">Sounds</p>
                <Form.Switch onChange={switchEnabled} id="custom-switch" checked={sound} />
            </Form>
        </div>
    );
};
export default NotificationsHeader;
