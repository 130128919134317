import toNumber from 'lodash/toNumber';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';

import {MergeCustomersFormValues} from 'pages/Customers/types/formTypes';

export const transformFormDataToRequestBody = (params: {formData: Partial<MergeCustomersFormValues>}) => {
    const {formData} = params;

    const searchParams = {
        destinationID: formData?.destinationID ? toNumber(formData.destinationID) : null,
        sourceID: formData?.sourceID ? toNumber(formData.sourceID) : null,
        billingMethod: formData?.billingMethod || null,
    };

    return omitBy(searchParams, (value) => isNull(value));
};
