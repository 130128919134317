import SendQuote from './SendQuote';

export const modalNames = {
    sendQuote: 'TRUCKS_NEARBY_SEND_QUOTE',
};

const modalMap = {
    [modalNames.sendQuote]: SendQuote,
};

export default modalMap;
