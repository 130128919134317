import React from 'react';
import {useDispatch} from 'react-redux';

import Truck from 'core/entities/Truck/types';

import {openModal} from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';
import Tooltip from 'components/ui/Tooltip';

type OwnProps = {
    truck: Truck;
    isClickable?: boolean;
};

const PlannedTravelOrdersBadge: React.FC<OwnProps> = ({truck, isClickable = true}) => {
    const dispatch = useDispatch();

    if (truck.planned_to_count === 0) {
        return null;
    }

    const handleClick = (): void => {
        dispatch(openModal({modalName: commonModalNames.plannedTravelOrders, data: {truck}}));
    };

    const tooltipContent = (
        <>
            <div>driver has {truck.planned_to_count} planned loads</div>
            {isClickable ? (
                <div>
                    <em>click to view more</em>
                </div>
            ) : null}
        </>
    );

    const handlers = isClickable ? {onClick: handleClick} : {};

    return (
        <div className="table-pic__block">
            <Tooltip {...handlers} tooltipContent={tooltipContent}>
                <span className="table-pic">{truck.planned_to_count}</span>
            </Tooltip>
        </div>
    );
};

export default PlannedTravelOrdersBadge;
