import isEmpty from 'lodash/isEmpty';

import {FileMetaData} from 'core/entities/TravelOrder/types/Files';

import * as formTypes from 'pages/TravelOrders/types/formTypes';

import {getAttachmentsMetaData, getCorrespondingAttachments} from '../index';

export const transformOtherAttachmentsToRequestBody = (params: {
    data: Partial<formTypes.AddOtherFilesTOFormValues>;
    filesIds: Record<number, string>;
}): {uploadedFormData: FormData | null} => {
    const {data, filesIds} = params;

    if (isEmpty(data)) {
        return {uploadedFormData: null};
    }

    const {attachments} = data;

    const correspondingAttachments = getCorrespondingAttachments({
        formAttachments: attachments || [],
        filesIds,
    });

    return correspondingAttachments;
};

export const transformOtherAttachmentsToMetaRequestBody = (params: {
    data: Partial<formTypes.AddOtherFilesTOFormValues>;
}): {files?: FileMetaData[]} | null => {
    const {data} = params;

    if (isEmpty(data)) {
        return null;
    }

    const {attachments} = data;

    const convertedAttachments = getAttachmentsMetaData(attachments);

    return {
        files: convertedAttachments,
    };
};
