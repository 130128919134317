import React from 'react';

import Owner from 'core/entities/Owner/types';
import {getOwnerName} from 'core/entities/Owner/modules/ownerName';

import ArchivingWarnWrapper from 'components/ui/modals/ArchivingWarnWrapper';

type OwnProps = {
    data: {owner: Owner};
    handlers: {onCancel(); onConfirm()};
    onClose();
};

const ArchivingWarn = (props: OwnProps): JSX.Element => {
    const {
        data: {owner},
        handlers: {onCancel, onConfirm},
        onClose,
    } = props;
    const ownerName = getOwnerName(owner);
    return (
        <ArchivingWarnWrapper title={ownerName} onClose={onClose} onCancel={onCancel} onConfirm={onConfirm}>
            <p className="text-uppercase">
                <strong>DO YOU WANT TO ARCHIVE {ownerName}?</strong>
            </p>
            <div className="mt10">
                <p>
                    Archiving <strong>{ownerName}</strong> owner`s profile will deactivate any actions with it.&nbsp;
                    And will be archiving all owner`s trucks and drivers.
                </p>
                <p>If needed, you will still have the ability to restore the profile.</p>
            </div>
        </ArchivingWarnWrapper>
    );
};

export default ArchivingWarn;
