import React from 'react';
import {Field} from 'redux-form';
import CheckBoxInput from 'components/ui/Form/CheckBoxInput';

import SelectEmailSender from 'pages/Settlements/components/common/SelectEmailSender';

import FIELDS from '../fields';

import styles from './emailsSettings.module.scss';

interface OwnProps {
    isAllVisiblePayRecordsSelected: boolean;
    isAllExistsPayRecordsSelected: boolean;
    onSelectAllVisible();
}

const EmailsSettings = ({
    isAllVisiblePayRecordsSelected,
    isAllExistsPayRecordsSelected,
    onSelectAllVisible,
}: OwnProps): JSX.Element => {
    const input = {
        value: isAllVisiblePayRecordsSelected,
        onChange: onSelectAllVisible,
    };
    return (
        <>
            <div className={styles.settings}>
                <CheckBoxInput position="right" label="Select all" input={input} />
                <div className="d-flex align-items-center">
                    <Field name={FIELDS.emailSender} component={SelectEmailSender} />
                </div>
            </div>
            <div>
                {isAllVisiblePayRecordsSelected && !isAllExistsPayRecordsSelected && (
                    <div className={styles.settings__selected__all_visible}>
                        {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                        <div>// all loaded pay records on this modal are selected</div>
                        {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                        <div>// load more below to re-select next loaded</div>
                    </div>
                )}
                {isAllVisiblePayRecordsSelected && isAllExistsPayRecordsSelected && (
                    <div className={styles.settings__selected__all_exists}>
                        {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                        <div>// all pay records in the pay period are selected</div>
                    </div>
                )}
            </div>
        </>
    );
};

export default EmailsSettings;
