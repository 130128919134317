import Load from 'core/entities/Load/types';
import {InferActions} from 'store';

import * as types from 'pages/Loads/redux/actionTypes/loadActionTypes';
import {FORM_BOOKING_INFO_STEP} from 'pages/Loads/constants/loadConstants';
import {actionCreators} from '../../actionCreators';
import {CopiedLoad} from '../../actions/copyActions/utils';

type ActionTypes = InferActions<typeof actionCreators>;

export type LoadState = {
    load: Load | undefined;
    loadCopyFields: CopiedLoad | null;
    formStep: string;
    isLoading: boolean;
    expandedPickUpDeliveryItems: Array<number>;
};

const defaultState: LoadState = {
    load: undefined,
    loadCopyFields: null,
    formStep: FORM_BOOKING_INFO_STEP,
    isLoading: false,
    expandedPickUpDeliveryItems: [0, 1],
};

export default function reducer(state = defaultState, action: ActionTypes): LoadState {
    switch (action.type) {
        case types.LOAD_INIT_DATA_FETCHED: {
            const {load} = action.payload;

            return {
                ...state,
                load,
            };
        }

        case types.LOAD_INIT_DATA_TRAVEL_ORDER_FETCHED: {
            const {travelOrder} = action.payload;

            return {
                ...state,
                load: {
                    ...state.load,
                    travelOrders: [travelOrder],
                } as Load,
            };
        }

        case types.CHANGE_FORM_STEP: {
            return {
                ...state,
                formStep: action.payload,
            };
        }

        case types.CREATE_LOAD_COPY: {
            return {
                ...state,
                load: undefined,
                loadCopyFields: action.payload,
            };
        }

        case types.CURRENT_LOAD_START_LOADING: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case types.CURRENT_LOAD_STOP_LOADING: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case types.CLEAR_LOAD_COPY: {
            return {
                ...state,
                load: undefined,
                loadCopyFields: null,
            };
        }

        case types.TOGGLE_EXPAND_PICK_UP_DELIVERY_ITEM: {
            const {itemIndex, params} = action.payload;
            const isItemExpanded = state.expandedPickUpDeliveryItems.includes(itemIndex);
            if (params && params.expand) {
                return {
                    ...state,
                    expandedPickUpDeliveryItems: isItemExpanded
                        ? state.expandedPickUpDeliveryItems
                        : [...state.expandedPickUpDeliveryItems, itemIndex],
                };
            }

            return {
                ...state,
                expandedPickUpDeliveryItems: isItemExpanded
                    ? state.expandedPickUpDeliveryItems.filter((item) => item !== itemIndex)
                    : [...state.expandedPickUpDeliveryItems, itemIndex],
            };
        }

        case types.EXPAND_PICK_UP_DELIVERY_ITEMS: {
            const {indexes} = action.payload;

            return {
                ...state,
                expandedPickUpDeliveryItems: [...state.expandedPickUpDeliveryItems, ...indexes],
            };
        }

        case types.TOGGLE_EXPAND_PICK_UP_DELIVERY_ITEMS_ALL: {
            const fieldsLength = action.payload;
            const isAllItemsCollapsed = state.expandedPickUpDeliveryItems.length === 0;
            return {
                ...state,
                expandedPickUpDeliveryItems: isAllItemsCollapsed ? [...Array(fieldsLength).keys()] : [],
            };
        }

        case types.LOAD_CREATED: {
            return {...state, loadCopyFields: null};
        }

        case types.LOAD_STATE_CLEAR: {
            return {...defaultState, loadCopyFields: state.loadCopyFields};
        }

        // no default
    }
    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
