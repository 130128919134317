export const SETTLEMENT_RECEIVED = 'SETTLEMENTS_COMPONENT_SETTLEMENT_RECEIVED';
export const SETTLEMENT_UPDATED = 'SETTLEMENTS_COMPONENT_SETTLEMENT_UPDATED';
export const SETTLEMENT_LOAD_UPDATED = 'SETTLEMENTS_COMPONENT_SETTLEMENT_LOAD_UPDATED';
export const SETTLEMENT_TRAVEL_ORDER_UPDATED = 'SETTLEMENTS_COMPONENT_SETTLEMENT_TRAVEL_ORDER_UPDATED';
export const SETTLEMENT_TRAVEL_ORDER_UPDATED_FILES = 'SETTLEMENT_TRAVEL_ORDER_UPDATED_FILES';
export const SETTLEMENT_STATUS_CHANGED = 'SETTLEMENTS_COMPONENT_SETTLEMENT_STATUS_CHANGED';
export const SETTLEMENT_PAY_PERIOD_CHANGED = 'SETTLEMENTS_COMPONENT_PAY_PERIOD_CHANGED';
export const SETTLEMENT_DEDUCTION_ADDED = 'SETTLEMENTS_COMPONENT_SETTLEMENT_DEDUCTION_ADDED';
export const SETTLEMENT_DEDUCTION_DELETED = 'SETTLEMENTS_COMPONENT_SETTLEMENT_DEDUCTION_DELETED';
export const SETTLEMENT_REIMBURSEMENT_ADDED = 'SETTLEMENTS_COMPONENT_SETTLEMENT_REIMBURSEMENT_ADDED';
export const SETTLEMENT_REIMBURSEMENT_DELETED = 'SETTLEMENTS_COMPONENT_SETTLEMENT_REIMBURSEMENT_DELETED';
export const SETTLEMENT_PDF_GENERATED = 'SETTLEMENTS_COMPONENT_SETTLEMENT_PDF_GENERATED';
export const SETTLEMENT_DRIVER_PAYMENT_UPDATED = 'SETTLEMENTS_COMPONENT_SETTLEMENT_DRIVER_PAYMENT_UPDATED';
export const SETTLEMENT_STATE_CLEARED = 'SETTLEMENT_COMPONENT_CURRENT_SETTLEMENT_STATE_CLEARED';
export const LIST_PAY_RECORD_SETTLEMENTS_RECEIVED = 'SETTLEMENT_COMPONENT_LIST_PAY_RECORD_SETTLEMENTS_RECEIVED';
export const LIST_SETTLEMENTS_RECEIVED = 'SETTLEMENT_COMPONENT_LIST_SETTLEMENTS_RECEIVED';
export const LIST_PAY_RECORDS_RECEIVED = 'SETTLEMENT_COMPONENT_LIST_RECORDS_RECEIVED';
export const LIST_SEARCHED_PAY_RECORDS_RECEIVED = 'SETTLEMENT_COMPONENT_LIST_SEARCHED_PAY_RECORDS_RECEIVED';
export const LIST_SETTLEMENTS_POSTED = 'SETTLEMENT_COMPONENT_LIST_SETTLEMENTS_POSTED';

export const ALL_BATCHES_RECEIVED = 'SETTLEMENT_COMPONENT_ALL_BATCHES_RECEIVED';
export const LIST_BATCHES_RECEIVED = 'SETTLEMENT_COMPONENT_LIST_BATCHES_RECEIVED';
export const LIST_BATCHES_SETTLEMENTS_RECEIVED = 'SETTLEMENT_COMPONENT_LIST_BATCHES_SETTLEMENTS_RECEIVED';
export const BATCHES_DATA_CLEARED = 'SETTLEMENT_COMPONENT_BATCHES_DATA_CLEARED';
export const BATCH_CREATED = 'SETTLEMENT_COMPONENT_BATCH_CREATED';
export const BATCH_UPDATED = 'SETTLEMENT_COMPONENT_BATCH_UPDATED';
export const BATCH_POSTED = 'SETTLEMENT_COMPONENT_BATCH_POSTED';

export const ALL_PAY_PERIODS_RECEIVED = 'SETTLEMENTS_COMPONENT_ALL_PAY_PERIODS_RECEIVED';
export const LIST_PAY_PERIODS_RECEIVED = 'SETTLEMENT_COMPONENT_LIST_PAY_PERIODS_RECEIVED';
export const PERIODS_DATA_CLEARED = 'SETTLEMENT_COMPONENT_PERIODS_DATA_CLEARED';
export const PAY_PERIOD_CREATED = 'SETTLEMENT_COMPONENT_PAY_PERIOD_CREATED';
export const PAY_PERIOD_UPDATED = 'SETTLEMENT_COMPONENT_PAY_PERIOD_UPDATED';
export const PAY_PERIOD_POSTED = 'SETTLEMENT_COMPONENT_PAY_PERIOD_POSTED';
export const PAY_PERIOD_PAY_RECORDS_CLEARED = 'SETTLEMENT_COMPONENT_PAY_PERIOD_PAY_RECORDS_CLEARED';

export const PAY_CORRECTION_ADDED = 'SETTLEMENT_COMPONENT_PAY_CORRECTION_ADDED';
export const PAY_CORRECTION_REMOVED = 'SETTLEMENT_COMPONENT_PAY_CORRECTION_REMOVED';

export const PAY_RECORD_UPDATED = 'SETTLEMENT_COMPONENT_PAY_RECORD_UPDATED';

export const LIST_SEARCH_PARAMS_CHANGED = 'SETTLEMENT_COMPONENT_LIST_CHANGED_SEARCH_PARAMS';
export const LIST_SEARCH_VIEW_ALL_SEARCHED_ITEMS = 'SETTLEMENT_COMPONENT_LIST_VIEW_ALL_SEARCHED_ITEMS';
export const LIST_SET_EXPANDED_ROW = 'SETTLEMENT_COMPONENT_LIST_SET_EXPANDED_ROW';
export const LIST_PAGINATION_CURRENT_PAGE_CHANGED = 'SETTLEMENT_COMPONENT_LIST_PAGINATION_CURRENT_PAGE_CHANGED';
export const LIST_PAGINATION_PER_PAGE_CHANGED = 'SETTLEMENT_COMPONENT_LIST_PAGINATION_PER_PAGE_CHANGED';
export const LIST_SETTLEMENTS_STATE_CLEARED = 'SETTLEMENT_COMPONENT_LIST_STATE_CLEARED';
export const FILTER_STATUS_CHANGED = 'SETTLEMENT_COMPONENT_LIST_FILTER_STATUS_CHANGED';
export const LIST_CLEAR_LAST_COMMAND = 'LIST_CLEAR_LAST_COMMAND';

export const REMOVE_SETTLEMENT_FROM_THE_LIST = 'SETTLEMENT_COMPONENT_LIST_REMOVE_SETTLEMENT';
