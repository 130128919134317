import convertStringToNumber from 'utils/convertStringToNumber';
import LENGTH_UNITS from 'utils/data/lengthUnits';

export const INCHES_IN_METER = 39.37;
export const INCHES_IN_CM = 0.3937;
export const INCHES_IN_FOOT = 12;
export const CENTIMETER_IN_METER = 100;
export const FLOATING_POINT_TO_INCHES = 4;

type MeasureType = number | string | undefined | null;
type UnitsType = string | undefined | null;

const convertFromFootToInches = (ft: number): number => ft * INCHES_IN_FOOT;
const convertFromMeterToInches = (m: number): number => m * INCHES_IN_METER;
const convertFromCentimeterToInches = (cm: number): number => cm * INCHES_IN_CM;

const convertFromInchesToCentimeter = (inch: number) => inch / INCHES_IN_CM;

const convertFromInchesToFoot = (num: number) => [
    Math.trunc(num / INCHES_IN_FOOT),
    Number((((num % INCHES_IN_FOOT) / INCHES_IN_FOOT) * INCHES_IN_FOOT).toFixed()),
];
const convertFromInchesToMeter = (num: number) => [
    Math.trunc(num / INCHES_IN_METER),
    (num / INCHES_IN_METER) % 1 === 0
        ? 0
        : Number((((num % INCHES_IN_METER) / INCHES_IN_METER) * CENTIMETER_IN_METER).toFixed()),
];

export const convertMeasurementsToTheInches = (units, measurements) => {
    if (units === LENGTH_UNITS.METER || units === LENGTH_UNITS.CENTIMETER) {
        const mt = convertFromMeterToInches(measurements[0]);
        const cm = convertFromCentimeterToInches(measurements[1]);
        return Number((mt + cm).toFixed(FLOATING_POINT_TO_INCHES));
    }
    return Number(convertFromFootToInches(measurements[0]) + measurements[1]);
};

export const convertFromInches = (units: UnitsType, value: MeasureType): number[] => {
    if (!value) {
        return [0, 0];
    }

    const measure = convertStringToNumber(value);

    if (units === LENGTH_UNITS.FOOT) {
        const foot = convertFromInchesToFoot(measure);
        return foot[1] >= INCHES_IN_FOOT ? [foot[0] + 1, 0] : foot;
    }

    if (units === LENGTH_UNITS.METER) {
        return convertFromInchesToMeter(measure);
    }

    if (units === LENGTH_UNITS.CENTIMETER) {
        return [0, Number(convertFromInchesToCentimeter(measure).toFixed())];
    }

    return [0, measure % 1 === 0 ? measure : Number(measure.toFixed())];
};

export const convertToInches = (units: UnitsType, value: MeasureType): number | undefined => {
    if (!value) {
        return undefined;
    }

    if (units === LENGTH_UNITS.FOOT) {
        return convertFromFootToInches(Number(value));
    }

    if (units === LENGTH_UNITS.METER) {
        return convertFromMeterToInches(Number(value));
    }

    if (units === LENGTH_UNITS.CENTIMETER) {
        return convertFromCentimeterToInches(Number(value));
    }

    return Number(value);
};

export const lengthView = (units: UnitsType, value: MeasureType): string => {
    if (!value) {
        return '000';
    }

    const length = convertFromInches(units, value);

    if (units === LENGTH_UNITS.FOOT) {
        return `${length[0]}’${length[1]}”`;
    }

    if (units === LENGTH_UNITS.METER) {
        return `${length[0]}m ${length[1]}cm`;
    }

    if (units === LENGTH_UNITS.CENTIMETER) {
        return `${length[1]}cm`;
    }

    return `${length[1]}”`;
};

export const lengthViewWithoutConvert = (units: UnitsType, value: MeasureType): string => {
    if (!value) {
        return '000';
    }

    if (units === LENGTH_UNITS.FOOT) {
        return `${value}’${0}”`;
    }

    if (units === LENGTH_UNITS.METER) {
        return `${value}m ${0}cm`;
    }

    if (units === LENGTH_UNITS.CENTIMETER) {
        return `${value}cm`;
    }

    return `${value}”`;
};

export const concatLength = (args: {
    height: MeasureType;
    length: MeasureType;
    width: MeasureType;
    units: UnitsType;
}): string => {
    const {length, width, height, units} = args;

    return [length, width, height].map((item) => lengthViewWithoutConvert(units, item)).join(' x ');
};
