import {
    APP_BAD_REQUEST_ERROR_STATUS,
    APP_DOMAIN_LOGIC_ERROR_STATUS,
    APP_INTERNAL_SERVER_ERROR,
    APP_NOT_FOUND_ERROR_STATUS,
    APP_SERVICE_UNAVAILABLE,
} from 'services/restapi/constants';

type ApiError = {
    status:
        | typeof APP_BAD_REQUEST_ERROR_STATUS
        | typeof APP_NOT_FOUND_ERROR_STATUS
        | typeof APP_INTERNAL_SERVER_ERROR
        | typeof APP_DOMAIN_LOGIC_ERROR_STATUS
        | typeof APP_SERVICE_UNAVAILABLE;
    data?: string | null;
};

export const getErrorHandlers = (
    error: ApiError,
    badRequestHandler: () => void,
    notFoundHandler: () => void,
    unavailableService: () => void,
) => {
    if (error.status === APP_SERVICE_UNAVAILABLE) {
        return unavailableService();
    }

    if (error.status === APP_INTERNAL_SERVER_ERROR) {
        return badRequestHandler();
    }

    if (error.status === APP_BAD_REQUEST_ERROR_STATUS || error.status === APP_DOMAIN_LOGIC_ERROR_STATUS) {
        return badRequestHandler();
    }

    if (error.status === APP_NOT_FOUND_ERROR_STATUS) {
        return notFoundHandler();
    }
};
