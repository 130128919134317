/* eslint-disable no-magic-numbers */
import React, {ReactNode, useEffect} from 'react';
import classNames from 'classnames';
import {ClearFieldsAction, WrappedFieldProps} from 'redux-form';

import ResetButton from '../ResetButton';

import Controls, {ControlsAction} from './components/Controls';

import styles from './styles.module.scss';

type OwnProps = {
    label: ReactNode;
    containerClassName?: string;
    onReset: ClearFieldsAction;
    resetBtnDisabled?: boolean;
    maxHours?: number;
    maxMinutes?: number;
    hoursStep?: number;
    minutesStep?: number;
    canUseZeroTimeValues?: boolean;
};

const MAX_HOURS = 24;
const MAX_MINUTES = 45;
const HOURS_STEP = 1;
const MINUTES_STEP = 1;
const TIME_DIVIDER = ':';

const splitTime = (timeStr: string) => timeStr.split(TIME_DIVIDER);
const joinTime = (timeArr: number[]) => timeArr.join(TIME_DIVIDER);

const increase = (val, step) => val + step;
const decrease = (val, step) => val - step;

const formatToMinIntegerDigits = (num, digitsCount): string =>
    num.toLocaleString('en-US', {minimumIntegerDigits: digitsCount, useGrouping: false});

const TimeSelector: React.FC<OwnProps & WrappedFieldProps> = (props) => {
    const {
        input,
        label,
        containerClassName = '',
        resetBtnDisabled = false,
        maxHours = MAX_HOURS,
        maxMinutes = MAX_MINUTES,
        hoursStep = HOURS_STEP,
        minutesStep = MINUTES_STEP,
        canUseZeroTimeValues = false,
    } = props;

    const [hours, minutes] = splitTime(input.value).map(Number);

    const changeHours = (h: number) => input.onChange(joinTime([h, minutes]));
    const changeMinutes = (m: number) => input.onChange(joinTime([hours, m]));

    const handleChangeHours = (action: ControlsAction) => {
        if (action === 'inc' && hours === maxHours) {
            changeHours(0);
            return;
        }

        if (action === 'dec' && hours === 0) {
            changeHours(maxHours);
            return;
        }

        const newValue = action === 'inc' ? increase(hours, hoursStep) : decrease(hours, hoursStep);

        changeHours(newValue);
    };

    const handleChangeMinutes = (action: ControlsAction) => {
        if (action === 'inc' && minutes === maxMinutes) {
            changeMinutes(0);
            return;
        }

        if (action === 'dec' && minutes === 0) {
            changeMinutes(maxMinutes);
            return;
        }

        const newValue = action === 'inc' ? increase(minutes, minutesStep) : decrease(minutes, minutesStep);

        changeMinutes(newValue);
    };

    const handleUndoChanges = () => {
        props.onReset(false, false, input.name);
    };

    useEffect(() => {
        if (!canUseZeroTimeValues && hours === 0 && minutes === 0) {
            changeMinutes(minutesStep);
        } else if (hours === maxHours && minutes !== 0) {
            changeMinutes(0);
        }
    }, [hours]);

    useEffect(() => {
        if (canUseZeroTimeValues) {
            return;
        }

        if (minutes === 0 && hours === 0) {
            changeHours(hoursStep);
        }
    }, [minutes]);

    const hoursFormatted = formatToMinIntegerDigits(hours, 2);
    const minutesFormatted = formatToMinIntegerDigits(minutes, 2);
    const minutesDisabled = hours === maxHours;

    return (
        <div className={classNames(styles.container, containerClassName)}>
            <div className={styles.inputContainer}>
                <span className={styles.label}>{label}</span>

                <div className={styles.input}>
                    <div className={styles.timeSelectWrapper}>
                        <div className={styles.timeSelect}>
                            <span>{hoursFormatted} h</span>
                            <Controls onClick={handleChangeHours} />
                        </div>

                        <div className={styles.timeSelect}>
                            <span>{minutesFormatted} min</span>
                            <Controls onClick={handleChangeMinutes} disabled={minutesDisabled} />
                        </div>
                    </div>

                    <i className="fa fa-clock-o" aria-hidden="true" />
                </div>
            </div>

            <ResetButton onClick={handleUndoChanges} disabled={resetBtnDisabled} className="align-self-end" />
        </div>
    );
};

export default TimeSelector;
