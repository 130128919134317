import Pagination from 'types/Pagination';

const parsePaginationHeaders = (headers: object): Pagination => ({
    pagesCount: parseInt(headers['x-pagination-page-count'], 10),
    currentPage: parseInt(headers['x-pagination-current-page'], 10),
    perPage: parseInt(headers['x-pagination-per-page'], 10),
    totalItemsCount: parseInt(headers['x-pagination-total-count'], 10),
});

export default parsePaginationHeaders;
