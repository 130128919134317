import React from 'react';
import Truck from 'core/entities/Truck/types';
import {getMainContact} from './utils';

import styles from './styles.module.scss';

const MainContact: React.FC<{truck: Truck}> = (props) => {
    const {truck} = props;
    return <td className={styles.wrapper}>{getMainContact(truck)}</td>;
};

export default MainContact;
