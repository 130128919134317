import React from 'react';

import {isTruckStatusOnRoute} from 'core/entities/Truck/statuses';
import Truck from 'core/entities/Truck/types';

import {TravelOrderLink} from 'components/ui/Links';

type OwnProps = {
    linkClass?: string;
    prefix?: string;
    truck: Truck;
};

const TruckCurrentTravelOrder: React.FC<OwnProps> = (props) => {
    const {truck, prefix = '', linkClass = 'second-link'} = props;

    const {current_travel_order_number: currentTravelOrderNumber} = truck;

    const isTruckOnRoute = isTruckStatusOnRoute(truck.status);
    const displayTONumber = currentTravelOrderNumber && isTruckOnRoute;
    const content = `${prefix}${currentTravelOrderNumber}`;

    const currentTravelOrder =
        (truck.travelOrder || []).find((travelOrder) => travelOrder.number === currentTravelOrderNumber) || {};

    if (!displayTONumber) {
        return null;
    }

    return (
        <div className="text-center">
            <TravelOrderLink
                isBold={true}
                travelOrder={{...currentTravelOrder, number: currentTravelOrderNumber}}
                className={linkClass}
                content={content}
            />
        </div>
    );
};

export default TruckCurrentTravelOrder;
