import EditDispatcherNote from 'components/common/Truck/modals/DispatcherNoteModal';
import TruckNotesModal from 'components/common/Truck/modals/TruckNotesModal';

import TruckReserving from './TruckReserving';

export const modalNames = {
    truckReserving: 'ENTITIES_TRUCKS_RESERVING_MODAL',
    editDispatcherNote: 'TRUCKS_EDIT_DISPATCHER_NOTE',
    truckNotesModal: 'TRUCK_NOTES_MODAL',
};

const modalMap = {
    [modalNames.editDispatcherNote]: EditDispatcherNote,
    [modalNames.truckNotesModal]: TruckNotesModal,
    [modalNames.truckReserving]: TruckReserving,
};

export default modalMap;
