import head from 'lodash/head';
import trim from 'lodash/trim';
import {change, reset} from 'redux-form';

import {getIsUserLoggedIn} from 'store/reducers/auth/selectors';
import {getCurrentDispatcher, getCurrentUser} from 'store/reducers/userData/selectors';

import {fetchDriversAvatars} from 'core/gateways/DriverApiGateway/requests';
import {searchTrucks} from 'core/gateways/TruckApiGateway/requests';

import chatApi from 'services/chatApi/http';
import restApi, {fetchDispatchers} from 'services/restapi';

import * as constants from 'widgets/Chat/constants';
import * as wsActions from 'widgets/Chat/redux/actions/wsActions';
import * as types from 'widgets/Chat/redux/actionTypes';
import {getMessageStatus} from 'widgets/Chat/redux/selectors';
import * as selectors from 'widgets/Chat/redux/selectors';

const fetchDriversForChat = () => restApi('/drivers/chat?per-page=0&page=1');

const getFilesFromAttachments = (attachments) => {
    if (!attachments || attachments.length === 0) {
        return undefined;
    }

    return attachments
        .map((attachment) => {
            return attachment && attachment.file
                ? {buffer: attachment.file, name: attachment.file.name, type: attachment.file.type}
                : undefined;
        })
        .filter(Boolean);
};

const getMoreChannelMessagesAction = (
    channel,
    {getAllMessages}: {getAllMessages: boolean} = {getAllMessages: false},
) => {
    const lastMessage: any = head(channel.messages);

    if (!lastMessage) {
        return;
    }

    const MESSAGES_LIMIT = 10;

    const data = {
        driverID: channel.driver.id,
        channelID: channel.channelID,
        lastMessageID: lastMessage.id,
        limit: getAllMessages ? undefined : MESSAGES_LIMIT,
    };

    return wsActions.getMoreChannelMessages(data);
};

export const driverSearch = (searchParams) => ({type: types.SET_DRIVER_SEARCH_PARAMS, payload: searchParams});
export const toggleChat = (payload) => ({type: types.TOGGLE_CHAT, payload});

export const fetchData = () => async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());

    const driversRequest = fetchDriversForChat()
        .then((res) => res.data)
        .catch((error) => {
            console.warn('Error on fetch drivers for chat: ', error);
        });

    const driversAvatarsRequest = await fetchDriversAvatars()
        .then((res) => res.data)
        .catch((error) => {
            console.warn('Error on fetch drivers avatars for chat: ', error);
        });

    const dispatchersRequest = await fetchDispatchers({'per-page': 0, page: 1})
        .then((res) => {
            if ('data' in res) {
                return res.data;
            }
        })
        .catch((error) => {
            console.warn('Error on fetch dispatchers for chat: ', error);
        });

    const channelsRequest = chatApi
        .get(`/channels`)
        .then((res) => res.data)
        .catch((error) => {
            console.warn('Error on fetch channels for chat: ', error);
        });

    const [drivers = [], driversAvatars = [], dispatchers = [], channels = []] = await Promise.all([
        driversRequest,
        driversAvatarsRequest,
        dispatchersRequest,
        channelsRequest,
    ]);

    dispatch({type: types.DATA_RECEIVED, payload: {drivers, driversAvatars, dispatchers, channels, currentUser}});
};

export const fetchChannelMoreMessages = (channel) => (dispatch) => {
    const moreMessagesAction = getMoreChannelMessagesAction(channel);

    if (moreMessagesAction) {
        dispatch(moreMessagesAction);
    }
};

export const fetchChannelAllMessages = (channel) => (dispatch) => {
    const moreMessagesAction = getMoreChannelMessagesAction(channel, {getAllMessages: true});

    if (moreMessagesAction) {
        dispatch(moreMessagesAction);
    }
};

export const enterToDriverChannel = (selectedDriverID) => (dispatch, getState) => {
    const allChannels = selectors.getAllChannels(getState());
    const selectedChannel = selectors.getChannelByDriverID(allChannels, selectedDriverID);

    dispatch({type: types.ENTER_TO_DRIVER_CHANNEL, payload: {driverID: selectedDriverID}});

    const moreMessagesAction = getMoreChannelMessagesAction(selectedChannel);

    if (moreMessagesAction) {
        dispatch(moreMessagesAction);
    }

    dispatch(wsActions.markAsReadByCurrentDispatcher(selectedDriverID));
};

export const fetchDataForDriverChannel = (driverID) => (dispatch) => {
    searchTrucks({driver: driverID})
        .then((response) => {
            dispatch({
                type: types.FETCH_TRUCK_DATA_FOR_DRIVER_CHANNEL,
                payload: {driverTruckData: head(response.data)},
            });
        })
        .catch((error) => {
            console.warn('Error on search driver truck data: ', error);
        });
};

export const leaveDriverChannel = () => (dispatch, getState) => {
    const selectedChannelDriverID = selectors.getSelectedChannelDriverID(getState());

    dispatch(reset(constants.DRIVER_SEARCH_FORM));
    dispatch(wsActions.markLocalMessagesAsRead(selectedChannelDriverID));
    dispatch({type: types.LEAVE_DRIVER_CHANNEL});
};

export const sendMessageToChannel = (message) => (dispatch, getState) => {
    const state = getState();
    const trimmedText = trim(message.text);
    const channel = selectors.getSelectedChannel(state);
    const driverID = channel.driver.id;
    const currentDispatcher = getCurrentDispatcher(state);
    const senderID = currentDispatcher.id;
    const senderName = currentDispatcher.fake_full_name;
    const senderExtension = currentDispatcher.extension;
    const attachments = getFilesFromAttachments(message.attachments);
    const chatMessageStatus = getMessageStatus(state);
    const isSendingStatus = chatMessageStatus === 'sending';

    if (!trimmedText && !attachments) {
        return;
    }

    if (isSendingStatus) {
        return;
    }

    const newMessage = {
        text: message.text,
        attachments,
        senderID,
        senderName,
        senderExtension,
        driverID,
        senderType: constants.SENDER_TYPE,
    };

    dispatch(wsActions.sendChatMessage(newMessage));
    dispatch({type: types.SENDING_MESSAGE, payload: {messageStatus: 'sending'}});
};

export const openChatChannelWithFilledMessages = (driverId, message) => (dispatch) => {
    dispatch(toggleChat({opened: true}));
    dispatch(enterToDriverChannel(driverId));
    dispatch(dispatch(change(constants.SEND_MESSAGE_FORM, constants.TEXT_FIELD, message)));
};

export const init = () => async (dispatch, getState) => {
    const state = getState();

    const isUserLoggedIn = getIsUserLoggedIn(state);

    if (!isUserLoggedIn) {
        return;
    }

    const isAuthenticated = await dispatch(wsActions.chatAuthenticate());

    if (!isAuthenticated) {
        return;
    }

    const webSocket = await dispatch(wsActions.chatWSConnect());

    if (!webSocket) {
        return;
    }

    dispatch(wsActions.websocketConnectionReceived({webSocket}));

    await dispatch(fetchData());
};
