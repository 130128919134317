import head from 'lodash/head';
import omit from 'lodash/omit';

const getInvoiceSnapshotData = (invoice) => {
    const {current_snapshot, load} = invoice;
    if (!invoice || !invoice.current_snapshot) {
        return undefined;
    }

    const lastSnapshotData = {
        ...current_snapshot,
        with_temp_control: load?.with_temp_control,
        customer: {...load?.customer},
    };

    return lastSnapshotData;
};

export const getInvoiceTravelOrderFromSnapshot = (invoice) => {
    const invoiceSnapshotData = getInvoiceSnapshotData(invoice) || {};
    return head(invoiceSnapshotData.travel_order);
};

export const getInvoiceLoadFromSnapshot = (invoice) => {
    return omit(getInvoiceSnapshotData(invoice), ['travel_order']);
};

export const getInvoiceCustomerFromSnapshot = (invoice) => {
    if (!invoice) {
        return undefined;
    }
    const loadFromSnapshot = getInvoiceLoadFromSnapshot(invoice);
    return loadFromSnapshot && loadFromSnapshot.customer;
};
