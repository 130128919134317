import TimerSettingsModal from './TimerSettingsModal';
import ColorSettingsModal from './ColorSettingsModal';
import ErrorCancelFinishModal from './ErrorCancelFinishModal';

export const travelOrderModalNames = {
    timerSettingsModal: 'TRAVEL_ORDER_TIMER_SETTINGS_MODAL',
    colorSettingsModal: 'TRAVEL_ORDER_COLOR_SETTINGS_MODAL',
    errorCancelFinishModal: 'TRAVEL_ORDER_ERROR_CANCEL_FINISH_MODAL',
};

const modalMap = {
    [travelOrderModalNames.timerSettingsModal]: TimerSettingsModal,
    [travelOrderModalNames.colorSettingsModal]: ColorSettingsModal,
    [travelOrderModalNames.errorCancelFinishModal]: ErrorCancelFinishModal,
};

export default modalMap;
