import {Dimensions} from 'core/entities/AppSettings/types';

const LENGTH_UNITS = {
    CENTIMETER: 'cm',
    INCH: 'in',
    FOOT: 'ft',
    METER: 'm',
} as const;

export type LengthUnits = Record<keyof typeof LENGTH_UNITS, Dimensions>;

export default LENGTH_UNITS;
