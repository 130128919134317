export const statuses = {
    RATED: 'Rated',
    READY_TO_POST: 'Ready to Post',
    POSTED: 'Posted',
};

// eslint-disable-next-line no-shadow
export enum periodTypes {
    FPP = 'FPP',
    PAST_DUE = 'past due',
}

export const SETTLEMENTS_CARRIERS_TYPE = 'carriers';
export const SETTLEMENTS_OWNERS_TYPE = 'owners';
export const SEARCH_FORM_NAME = 'settlements_filter_form';
