import isEmpty from 'lodash/isEmpty';
import gt from 'lodash/gt';

import TravelOrder from 'core/entities/TravelOrder/types';
import Truck from 'core/entities/Truck/types';

export const getTruckFromTheTravelOrder = (travelOrder?: TravelOrder | null): Truck | undefined => travelOrder?.truck;

export const isTravelOrderCreatedByAttachTruck = (travelOrder?: TravelOrder | null): boolean =>
    !isEmpty(travelOrder?.truck);

export const isTravelOrderHasTruck = (travelOrder?: TravelOrder | null): boolean => !isEmpty(travelOrder?.truck);

export const isTravelOrderHasNextPlannedTO = (travelOrder: TravelOrder | null): boolean =>
    gt(travelOrder?.truck?.planned_to_count, 0);

export const isTravelOrderHasNextPlannedTOAfterFinish = (travelOrder: TravelOrder | null): boolean =>
    Boolean(travelOrder?.truck?.current_travel_order_number);

export const getTruckOwnerFromTheTravelOrder = (travelOrder?: TravelOrder | null): TravelOrder['truckOwner'] =>
    travelOrder?.truckOwner || null;
