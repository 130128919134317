import React, {ReactNode, ReactNodeArray, useCallback, useMemo} from 'react';
import {WrappedFieldProps} from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import {connect} from 'react-redux';
import head from 'lodash/head';
import last from 'lodash/last';

import {mapExistingFilesFromFormState} from 'components/ui/Files/FileUpload/utils/mapExistingFilesFromFormState';
import withFormActionCreators from 'components/ui/Files/FileUpload/hocs/withFormActionCreators';
import ButtonFileInput from 'components/ui/Files/FileUpload/components/ButtonFileInput';
import {normalizeFile} from 'components/ui/Files/FileUpload/utils';

import {NormalizedFile} from 'widgets/Chat/types';

import StoredFile from 'types/File';

import {omitRemoved, composeBadge, groupByPrimaryIndex, sortFiles} from './utils';
import AttachPreview from './components/AttachPreview';

import styles from './attachments.module.scss';

interface OwnProps {
    disabled: boolean;
    fileIDsToBeRemoved: number[];
    change(inputName: string, value: any): void;
    pushFileToDeleteList(value: StoredFile): void;
    arrayRemove(inputName: string, index: number): void;
}

const AttachmentsUpload = (props: OwnProps & WrappedFieldProps): JSX.Element => {
    const {
        input: {name: inputName, value: newFiles = []},
        fileIDsToBeRemoved = [],
        pushFileToDeleteList,
        arrayRemove,
        disabled,
        change,
    } = props;

    const existingFiles = [];

    const normalizedStoredFiles = useMemo(
        function (): NormalizedFile[] {
            const filteredFiles = omitRemoved(existingFiles, fileIDsToBeRemoved);
            return filteredFiles.map(
                (file: StoredFile): NormalizedFile =>
                    normalizeFile(file, {remove: () => pushFileToDeleteList(file)}) as NormalizedFile,
            );
        },
        [existingFiles, fileIDsToBeRemoved],
    );

    const normalizedNewFiles = useMemo(
        function (): NormalizedFile[] {
            return newFiles.map(
                (file: {file: File}, index: number): NormalizedFile =>
                    normalizeFile(file, {remove: () => arrayRemove(inputName, index)}) as NormalizedFile,
            );
        },
        [newFiles],
    );

    const mergedFiles = useMemo(
        function (): NormalizedFile[] {
            return sortFiles(normalizedStoredFiles.concat(normalizedNewFiles));
        },
        [normalizedStoredFiles, normalizedNewFiles],
    );

    const groupedNormalizedFiles = useMemo(
        function (): NormalizedFile[][] {
            return groupByPrimaryIndex(mergedFiles);
        },
        [mergedFiles],
    );

    const handleChange = useCallback(function (event: React.FormEvent<HTMLInputElement>, extraData): void {
        const {files} = event.currentTarget;
        if (!files || files.length === 0) {
            return;
        }
        const newFile = {file: head(files), ...extraData};
        // temp fix for disable possibility upload many files
        change(inputName, [newFile]);
    }, []);

    const handleUpload = useCallback(
        function (event: React.FormEvent<HTMLInputElement>): void {
            const lastFile = last(mergedFiles);
            const newPrimaryIndex = lastFile ? lastFile.extraData.primary_index + 1 : 1;
            const extraData = {
                badge: composeBadge(newPrimaryIndex, 1),
                primary_index: newPrimaryIndex,
                secondary_index: 1,
            };
            handleChange(event, extraData);
        },
        [mergedFiles],
    );

    const renderPreviews = (): ReactNodeArray => {
        return groupedNormalizedFiles.map(
            (fileGroup, groupIndex): ReactNode => (
                <div key={groupIndex} className="attachment-group">
                    {fileGroup.map((file: NormalizedFile, fileIndex) => {
                        return <AttachPreview key={fileIndex} file={file} handleRemove={file.handleRemove} />;
                    })}
                </div>
            ),
        );
    };

    const previews = useMemo(renderPreviews, [groupedNormalizedFiles]);

    return (
        <div className={styles.upload}>
            {!isEmpty(previews) ? (
                <>
                    <div className="attachments-list">{previews}</div>
                    <div className="count-preview">1 of 1 file selected</div>
                </>
            ) : null}

            <div className="button-block">
                <ButtonFileInput
                    className="button-attach"
                    onChange={handleUpload}
                    buttonIcon="paperclip"
                    inputName={inputName}
                    disabled={disabled}
                    colorTheme="white"
                    multiple
                />
            </div>
        </div>
    );
};

export default connect(mapExistingFilesFromFormState)(withFormActionCreators(AttachmentsUpload));
