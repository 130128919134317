import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import StoredFile from 'types/File';

function sort(files: StoredFile[]): StoredFile[] {
    return sortBy(files, ['extra_data.primary_index', 'extra_data.secondary_index']);
}

function groupByPrimaryIndex(files: StoredFile[]): StoredFile[][] | {} {
    return groupBy(files, 'extra_data.primary_index');
}

export default function formatRCFiles(files: StoredFile[]): StoredFile[][] {
    const sortedFiles = sort(files);
    const groupedFiles = groupByPrimaryIndex(sortedFiles);
    return Object.values(groupedFiles);
}
