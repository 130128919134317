import {getPickUpDeliveryInfo} from 'deprecated/core/entities/Load';

import {SettlementCarrier, SettlementOwner} from 'core/entities/Settlement/types';
import {getSettlementLoad, isSettlementOwner} from 'core/entities/Settlement';

type SettlementData = SettlementOwner | SettlementCarrier;
type OriginDestination = {origin: string; destination: string};

const getOriginDestinationFromSettlementOwner = (settlement: SettlementOwner): OriginDestination => {
    return {origin: settlement.origin_location, destination: settlement.destination_location};
};

const getOriginDestinationFromSettlementCarrier = (settlement: SettlementCarrier): OriginDestination => {
    const load = getSettlementLoad(settlement);
    const {firstPickUpAddress, lastDeliveryAddress} = getPickUpDeliveryInfo(load);
    return {origin: firstPickUpAddress, destination: lastDeliveryAddress};
};

export const getOriginDestination = (settlement: SettlementData): {origin: string; destination: string} => {
    return isSettlementOwner(settlement)
        ? getOriginDestinationFromSettlementOwner(settlement)
        : getOriginDestinationFromSettlementCarrier(settlement);
};
