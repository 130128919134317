import {change} from 'redux-form';
import head from 'lodash/head';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import isObjectHasKay from 'lodash/has';

import {getSettlementStatusName} from 'core/entities/Settlement';
import {SEARCH_FORM_NAME, SETTLEMENTS_CARRIERS_TYPE, statuses} from 'core/entities/Settlement/constants';

import carriersFilterFields from 'pages/Settlements/components/List/FilterSettlementsCarriers/filterFields';
import ownersFilterFields from 'pages/Settlements/components/List/FilterSettlementsOwners/filterFields';
import getNewSearchParamsByMainFields from 'pages/Settlements/utils/settlementSearchParams';

import * as types from '../actionTypes';
import * as selectors from '../selectors';

export function setExpandedRow(id) {
    return function (dispatch) {
        dispatch({
            type: types.LIST_SET_EXPANDED_ROW,
            payload: id,
        });
    };
}

export function setSearchParams(payload = {}) {
    return {
        type: types.LIST_SEARCH_PARAMS_CHANGED,
        payload,
    };
}

export function setCurrentPage(currentPage) {
    return {
        type: types.LIST_PAGINATION_CURRENT_PAGE_CHANGED,
        payload: currentPage,
    };
}

export function setPerPage(perPage) {
    return {
        type: types.LIST_PAGINATION_PER_PAGE_CHANGED,
        payload: perPage,
    };
}

export function clearListState() {
    return {
        type: types.LIST_SETTLEMENTS_STATE_CLEARED,
    };
}

export function handleSearch(formValuesSearchParams, settlementsType) {
    return function (dispatch, getState) {
        // If we search by main params - remove other fields from search form
        const normalizedSearchParams = getNewSearchParamsByMainFields(formValuesSearchParams);
        const {
            settlements: {
                list: {searchParams},
            },
        } = getState();

        if (normalizedSearchParams) {
            if (settlementsType === SETTLEMENTS_CARRIERS_TYPE) {
                Object.values(carriersFilterFields).forEach((fieldName) => {
                    dispatch(change(SEARCH_FORM_NAME, fieldName, normalizedSearchParams[fieldName]));
                });
            } else {
                Object.values(ownersFilterFields).forEach((fieldName) => {
                    dispatch(change(SEARCH_FORM_NAME, fieldName, normalizedSearchParams[fieldName]));
                });
            }
        }
        const isParametersChanged = !isEqual(searchParams, normalizedSearchParams);

        if (isParametersChanged) {
            dispatch(setCurrentPage(1));
            dispatch(setSearchParams(normalizedSearchParams));
        }
    };
}

export function resetFilterByFieldName(newStatus) {
    return function (dispatch, getState) {
        const searchParams = selectors.getSearchParams(getState());
        if (
            isObjectHasKay(searchParams, ownersFilterFields.hide_tonu_processing) ||
            isObjectHasKay(searchParams, ownersFilterFields.with_pay_corrections)
        ) {
            const fieldName =
                newStatus === statuses.POSTED
                    ? ownersFilterFields.hide_tonu_processing
                    : ownersFilterFields.with_pay_corrections;
            dispatch(handleSearch(omit(searchParams, [fieldName])));
        }
    };
}

export function changeSettlementsGroupStatus(newStatus) {
    return function (dispatch, getState) {
        const groupStatus = selectors.getGroupStatus(getState());
        if (groupStatus !== newStatus) {
            dispatch(resetFilterByFieldName(newStatus));
            dispatch({
                type: types.FILTER_STATUS_CHANGED,
                payload: {groupStatus: newStatus},
            });
        }
    };
}

export function setGroupStatusBySettlements(settlements, isSearchByMainParams) {
    return function (dispatch) {
        // If we search by main params - needs to get status from searched settlement and switch status filter
        if (isSearchByMainParams) {
            const settlement = head(settlements);

            if (settlement) {
                const settlementStatus = getSettlementStatusName(settlement);

                dispatch(changeSettlementsGroupStatus(settlementStatus));
            }
        }
    };
}

export function expandAndHighlightRow(postedEntities) {
    return function (dispatch, getState) {
        const isSearchByMainParams = selectors.isSearchingByMainParams(getState());

        if (isSearchByMainParams) {
            const postedEntity = head(postedEntities);

            if (postedEntity) {
                dispatch(setExpandedRow(postedEntity.id));
            }
        }
    };
}

export function viewAllSearchedItems() {
    return {
        type: types.LIST_SEARCH_VIEW_ALL_SEARCHED_ITEMS,
    };
}

export function clearLastCommand() {
    return {
        type: types.LIST_CLEAR_LAST_COMMAND,
    };
}
