import React, {Fragment, useCallback, ReactNode} from 'react';
import isEmpty from 'lodash/isEmpty';

import {ACCEPTED_FILE_TYPES} from 'components/ui/Files/FileUpload/constants';

interface OwnProps {
    inputName: string;
    children?: ReactNode;
    fileTypes?: string;
    buttonSize?: string;
    colorTheme?: string;
    multiple?: boolean;
    buttonIcon?: string;
    inputRef?: string;
    className?: string;
    disabled?: boolean;
    onChange(event: React.FormEvent<HTMLInputElement>): void;
}

const defaultAcceptedTypes = ACCEPTED_FILE_TYPES.join(', ');

const ButtonFileInput = (props: OwnProps): JSX.Element => {
    const {
        inputName,
        children,
        fileTypes = defaultAcceptedTypes,
        buttonSize = 'medium',
        colorTheme = 'grey',
        disabled = false,
        multiple = false,
        buttonIcon,
        className,
        inputRef,
        onChange,
    } = props;

    const inputID = `file_input_${inputName}`;
    const inputNode = document.getElementById(inputID) as HTMLInputElement;
    const onClickHandler = useCallback(
        function (): void {
            if (!inputNode) {
                return;
            }
            inputNode.value = '';
        },
        [inputNode],
    );
    const onKeyDownHandler = useCallback(
        function (event: React.KeyboardEvent<HTMLLabelElement>): void {
            const ENTER_KEY_CODE = 13;
            if (event.keyCode === ENTER_KEY_CODE && inputNode) {
                inputNode.click();
            }
        },
        [inputNode],
    );
    const customClassName = isEmpty(className) ? '' : className;
    return (
        <Fragment>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
                htmlFor={inputID}
                className={`button button-${buttonSize} button-file-input button-${colorTheme} ${
                    buttonIcon ? 'button-with-icon' : ''
                } ${customClassName}`}
                style={{paddingTop: 6, textAlign: 'center', cursor: 'pointer', margin: '0 5px'}}
                onClick={onClickHandler}
                onKeyDown={onKeyDownHandler}
                // eslint-disable-next-line
                tabIndex={0}
            >
                {buttonIcon && <i className={`fa fa-${buttonIcon}`} aria-hidden="true" />}
                {children}
            </label>
            <input
                id={inputID}
                type="file"
                accept={fileTypes}
                multiple={multiple}
                onChange={onChange}
                style={{display: 'none'}}
                disabled={disabled}
                ref={inputRef}
            />
        </Fragment>
    );
};

export default ButtonFileInput;
