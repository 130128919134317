import React from 'react';
import {TruckDimensions} from 'core/entities/Quote/types';
import LengthView from 'components/ui/LengthView';

const QuoteTruckDimensions: React.FC<{dimensions: TruckDimensions}> = (props) => {
    const {dimensions} = props;
    const {length, width, unit, height} = dimensions;
    return <LengthView units={unit} length={length} width={width} height={height} />;
};

export default QuoteTruckDimensions;
