import React from 'react';
import {FieldArray, WrappedFieldArrayProps} from 'redux-form';
import {FormGroup} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';
import DimensionItem from './components/DimensionItem';
import styles from './styles.module.css';

interface OwnProps extends WrappedFieldArrayProps {
    units: string;
}

const RenderField: React.FC<OwnProps> = (props): JSX.Element => {
    const {fields, units} = props;
    return (
        <>
            {fields.map((field, index) => (
                <FormGroup key={index}>
                    <DimensionItem index={index} units={units} />
                </FormGroup>
            ))}
            <Button
                buttonIcon="plus"
                onClick={() => fields.push({length: 0, width: 0, height: 0})}
                buttonSize="icon"
                className="btn-link d-flex mt-2"
            >
                <div className={styles.add_dimensions}>add dimensions</div>
            </Button>
        </>
    );
};

const DimensionList: React.FC = () => {
    const {getLoadFreightDimensionUnitFieldName, getLoadFreightDimensionItems} = getFormFieldNames();
    const dimensionUnit = useGetReduxFormValue(FORM_NAME, getLoadFreightDimensionUnitFieldName());
    return <FieldArray units={dimensionUnit} name={getLoadFreightDimensionItems()} component={RenderField} />;
};

export default DimensionList;
