import last from 'lodash/last';
import sumBy from 'lodash/sumBy';

import {LoadStop, EmptyFreight, LoadStopFreight} from 'core/entities/Load/types';
import {isPickupStop} from 'core/entities/Load/modules/loadStop';

import WEIGHT_UNITS from 'utils/data/weightUnits';
import {transformWeightToNumber} from 'utils/weightConverter';

import {store} from 'store';
import {getCurrentDimensionsFormat, getCurrentWeightFormat} from 'store/reducers/appSettings/selectors';

const getCommonFreightUnits = (freights) => {
    if (freights.every((freight) => freight.weight.unit === WEIGHT_UNITS.KG)) {
        return WEIGHT_UNITS.KG;
    }
    if (freights.every((freight) => freight.weight.unit === WEIGHT_UNITS.LBS)) {
        return WEIGHT_UNITS.LBS;
    }

    if (freights.every((freight) => freight.weight.unit === WEIGHT_UNITS.TON)) {
        return WEIGHT_UNITS.TON;
    }
    return undefined;
};

const getPiecesSum = (items): number => sumBy(items, (item: {pieces: string}) => parseInt(item.pieces, 10) || 0);

const getWeightSum = (items: LoadStopFreight[]): number => {
    return sumBy(items, (item) => (item.weight ? transformWeightToNumber(item.weight?.amount) : 0));
};

const getUnit = (items: LoadStopFreight[]): string => {
    const defaultWeight = getCurrentWeightFormat(store.getState());

    const lastItem = last(items);
    return lastItem?.weight?.unit || defaultWeight;
};

const dimsUnit = (items: LoadStopFreight[]): string => {
    const defaultDimensions = getCurrentDimensionsFormat(store.getState());
    const lastItem = last(items);

    return lastItem?.dims?.unit || defaultDimensions;
};

export type TotalFreightInfo = {
    pieces: number;
    weight: number;
    weightUnit: string;
    dimsUnit: string;
    commonUnits: string | undefined;
    isHazmat: boolean;
    isStackable: boolean;
};

export const getTotalFreightsInfo = (freightInfo: LoadStopFreight[]): TotalFreightInfo => ({
    pieces: getPiecesSum(freightInfo),
    weight: getWeightSum(freightInfo),
    weightUnit: getUnit(freightInfo),
    dimsUnit: dimsUnit(freightInfo),
    commonUnits: getCommonFreightUnits(freightInfo),
    isHazmat: freightInfo.some((freight) => freight?.isHazmat),
    isStackable: freightInfo.some((freight) => freight?.isStackable),
});

export const getEmptyFreight = (): EmptyFreight => {
    const defaultWeight = getCurrentWeightFormat(store.getState());
    const defaultDimensions = getCurrentDimensionsFormat(store.getState());

    return {
        weight: {
            amount: null,
            unit: defaultWeight,
        },
        dims: {
            unit: defaultDimensions,
        },
    };
};

export const getOppositeStopFreight = (stop: LoadStop): EmptyFreight[] | undefined => {
    return isPickupStop(stop) ? undefined : [getEmptyFreight()];
};
