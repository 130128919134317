import React from 'react';

import {DriverReceiverWithTruck} from 'core/entities/Quote/types';
import {getTruckLocationCityLine} from 'core/entities/Quote/modules/quoteDriverReceiver';
import {getTruckAndTrailerTypes} from 'core/entities/Truck/getTruckAndTrailerTypes';

import {TruckNumberAsLink} from 'components/common/Truck/TruckNumber';
import TruckDimsAndPayload from 'components/common/Truck/TruckDimsAndPayload';
import TruckStatus from 'components/common/Truck/TruckStatus';

import DistanceValue from 'pages/LoadBoard/components/common/DistanceValue';
import TruckReserveButton from 'pages/LoadBoard/components/common/TruckReserveButton';

import TruckMainContact from './components/TruckMainContact';
import ReceiveStatus from './components/ReceiveStatus';

const ReceiverItem: React.FC<{receiver: DriverReceiverWithTruck}> = (props) => {
    const {receiver} = props;
    if (!receiver.truck) {
        return (
            <tr>
                <td colSpan={4}>
                    <div>No truck data</div>
                </td>
            </tr>
        );
    }
    const truckLocation = getTruckLocationCityLine(receiver);
    const truckTypes = getTruckAndTrailerTypes(receiver.truck);
    return (
        <tr>
            <td>
                <div>
                    <TruckNumberAsLink truck={receiver.truck} /> <TruckStatus status={receiver.truck.status} />
                </div>
                <div>
                    <strong>{truckTypes}</strong>
                </div>
                <div>
                    <TruckDimsAndPayload truck={receiver.truck} withBoldDimensions={true} />
                </div>
            </td>
            <td>
                <div>
                    <TruckMainContact truck={receiver.truck} />
                </div>
                <div>
                    <ReceiveStatus receiver={receiver} />
                </div>
            </td>
            <td>
                <div>
                    <span className="second-link">{truckLocation}</span>
                </div>
                <br />
                <div>
                    Origin Away:{' '}
                    <strong>
                        <DistanceValue distance={receiver.distanceToLoad} withUnit />
                    </strong>
                </div>
            </td>
            <td>
                <div>
                    {' '}
                    <TruckReserveButton
                        truck={receiver.truck}
                        switchToLinkOnReserve={false}
                        isDisabled={receiver.truck?.is_deleted}
                    />
                </div>
            </td>
        </tr>
    );
};

export default ReceiverItem;
