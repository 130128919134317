export function removePrefix(value: string, prefix: string): number | undefined {
    if (!value) {
        return;
    }
    const formattedValue = value.replace(prefix, '');

    return parseFloat(formattedValue);
}

export function validateNumber(value?: number): boolean {
    if (!value && value !== 0) {
        return false;
    }

    return !Number.isNaN(value);
}
