import {createSelector} from 'reselect';

import {PayPeriod} from 'core/entities/Settlement/types';
import {getPayPeriodDateEnd} from 'core/entities/Settlement';

export const getPayPeriodsWithSettlements = createSelector([(state, props) => props.payPeriods], (payPeriods) => {
    return payPeriods.map((payPeriod: PayPeriod) => {
        return {
            id: payPeriod.id,
            title: `#${payPeriod.number} - ${getPayPeriodDateEnd(payPeriod)}`,
            settlementsCount: payPeriod.suitable_settlements_count,
            users: [],
        };
    });
});
