import {AxiosError} from 'axios';

import Pagination from 'types/Pagination';
import * as appActions from 'store/actions';
import {getCurrentUserID} from 'store/reducers/userData/selectors';
import {Quote} from 'core/entities/Quote/types';
import {
    searchQuotes,
    fetchQuoteDriversReceivers as fetchQuoteDriversReceiversRequest,
    postQuote,
    resendQuote as resendQuoteRequest,
    searchDuplicatesQuote as searchDuplicatesQuoteRequest,
    archiveQuote as archiveQuoteRequest,
} from 'core/gateways/LoadBoardApiGateway/requests';

import {QuoteSearchParams, QuoteInitialValues, ListType} from 'pages/LoadBoard/types';
import * as actionCreators from '../actionCreators';
import transformSearchParamsToRequest from './mappers/transformSearchParamsToRequest';
import transformQuoteToPostRequest from './mappers/transformQuoteToPostRequest';
import {openDuplicatesQuoteModal, openSuccessModal, openSuccessArchivedQuoteModal} from './modalActions';
import transformQuoteFromToSearchDuplicatesRequest from './mappers/transformQuoteToSearchDuplicatesRequest';
import {quoteModalNames} from '../../components/modals/modalMap';

export const fetchQuotes = (params: {
    listType: ListType;
    searchParams: QuoteSearchParams;
    pagination: Pagination;
    // sortBy: SortBy;
    // sortFrom: SortFrom;
}): any => async (dispatch) => {
    dispatch(appActions.showLoader());

    const {listType, searchParams, pagination} = params;
    const transformedParams = transformSearchParamsToRequest(searchParams);
    // const sortingParams = {sortBy: sortBy ? {[sortBy]: sortFrom} : {}};
    const sortingParams = {};
    const searchData = {
        ...transformedParams,
        ...sortingParams,
    };
    const paginationData = {page: pagination.currentPage, perPage: pagination.perPage};
    try {
        const response = await searchQuotes({
            searchParams: searchData,
            paginationParams: paginationData,
            isArchived: listType === 'archived',
        });
        dispatch(actionCreators.receivedQuotes(response.data, response.pagination));
    } catch (error) {
        console.error('Error on search quotes: ', error);
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

// eslint-disable-next-line require-await
export const fetchQuoteDriversReceivers = (quote: Quote): any => async (dispatch) => {
    dispatch(appActions.showLoader());

    const delay = (params: {timeOut: number}) => {
        return new Promise((resolve) => {
            window.setTimeout(resolve, params.timeOut);
        });
    };

    try {
        await delay({timeOut: 300});

        const driversReceivers = await fetchQuoteDriversReceiversRequest(quote);

        dispatch(actionCreators.receivedQuoteDriversReceivers(quote.id, driversReceivers));
    } catch (error) {
        console.error('Error on fetch quote drivers receivers: ', error);
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const searchDuplicatesQuote = (quoteData: QuoteInitialValues): any => async (dispatch) => {
    dispatch(appActions.showLoader());
    const searchParams = transformQuoteFromToSearchDuplicatesRequest(quoteData);

    try {
        const quotes = await searchDuplicatesQuoteRequest(searchParams);
        if (quotes?.length) {
            dispatch(appActions.hideLoader());
            const isCreationConfirm = await dispatch(openDuplicatesQuoteModal(quoteData, quotes));
            return isCreationConfirm;
        }

        return true;
    } catch (error) {
        console.error('Error on search duplicates quote: ', error);
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const createQuote = (quoteData: QuoteInitialValues): any => async (dispatch, getState) => {
    const isCreationConfirm = await dispatch(searchDuplicatesQuote(quoteData));

    if (!isCreationConfirm) {
        return;
    }

    dispatch(appActions.showLoader());

    const state = getState();
    const userID = getCurrentUserID(state);
    const quote = transformQuoteToPostRequest(quoteData, userID);

    return postQuote(quote)
        .then((quoteResponse) => {
            dispatch(openSuccessModal(quoteResponse.number));
            return quoteResponse;
        })
        .catch((error: AxiosError) => {
            console.error('Error on create quote: ', error);
            dispatch(appActions.handleError(error));
        })
        .finally(() => {
            dispatch(appActions.hideLoader());
        });
};

export const resendQuote = (quote: Quote): any => (dispatch) => {
    dispatch(actionCreators.startedSendQuote(quote.id));

    resendQuoteRequest(quote)
        .then((updatedQuote) => {
            if (updatedQuote) {
                dispatch(actionCreators.resendQuote(updatedQuote));
            }
        })
        .catch((error: AxiosError) => {
            console.error('Error on resend quote: ', error);
        })
        .finally(() => {
            dispatch(actionCreators.completedSendQuote(quote.id));
        });
};

export const archiveQuote = (quote: Quote): any => (dispatch) => {
    dispatch(appActions.showLoader());

    return archiveQuoteRequest(quote)
        .then((quoteResponse) => {
            dispatch(actionCreators.archiveQuote(quote));
            dispatch(openSuccessArchivedQuoteModal(quote.number));
            return quoteResponse;
        })
        .catch((error: AxiosError) => {
            console.error('Error on archive quote: ', error);
            dispatch(appActions.handleError(error));
        })
        .finally(() => {
            dispatch(appActions.hideLoader());
        });
};
