import {v4} from 'uuid';

import {getCityLineFromObject} from 'utils/getCityLine';
import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';

import {FactoredCompanyRawData, DirectCompanyRawData, Company, CustomerUserRawData, User} from '../types';

export const getCompaniesForSetupBatch = (
    factoredCompanyRawData: FactoredCompanyRawData,
    directCompaniesRawDta: DirectCompanyRawData[],
): Company[] => {
    const factoringCompany: Company = {
        keyID: v4(),
        entityID: null,
        name: 'Factoring Company',
        billingAddressLine: '',
        billingCityLine: '',
        invoicesCount: factoredCompanyRawData?.invoices_count || 0,
        users: [],
    };
    const directCompanies: Company[] = directCompaniesRawDta.map((company: DirectCompanyRawData) => {
        return {
            entityID: company.id,
            keyID: company.id,
            name: company.name,
            billingAddressLine: company.address_line,
            billingCityLine: getCityLineFromObject({
                city: company.city,
                state: company.state,
                zip: company.zip,
                country: company.country,
            }),
            invoicesCount: company.invoice_count,
            users: [],
        };
    });
    if (factoringCompany.invoicesCount > 0) {
        return [factoringCompany, ...directCompanies];
    }
    return directCompanies;
};

export const getCustomerUsers = (data: CustomerUserRawData[]): User[] => {
    return data.map((userRawData) => {
        return {
            id: userRawData?.user?.id,
            name: getDispatcherName(userRawData.user),
            invoicesCount: Number(userRawData.invoice_cnt),
            invoicesIds: userRawData.invoice_ids.split(','),
        };
    });
};
