import {useSelector} from 'react-redux';

import {getShowPartnersTab} from 'store/reducers/appSettings/selectors';
import * as commonSelectors from 'store/reducers/commonData/selectors';

import * as selectors from 'pages/TrucksNearby/redux/selectors';

type UseTrucksNearbyState = {
    externalSearchParams: ReturnType<typeof selectors.getExternalSearchParams>;
    isShowPartnersTab: ReturnType<typeof getShowPartnersTab>;
    openedInfoWindows: ReturnType<typeof selectors.getOpenedInfoWindows>;
    permissionList: ReturnType<typeof selectors.getPermissionList>;
    allTrucksIDs: ReturnType<typeof selectors.getAllTrucksIDs>;
    allTrucks: ReturnType<typeof selectors.getAllTrucks>;
    trucksIDs: ReturnType<typeof selectors.getTrucksIDs>;

    // new
    destinationPoint: ReturnType<typeof selectors.getDestinationPoint>;
    quoteTemplate: ReturnType<typeof selectors.generateQuoteTemplate>;
    allCarriers: ReturnType<typeof commonSelectors.getAllCarriers>;
    searchParams: ReturnType<typeof selectors.getSearchParams>;
    originPoint: ReturnType<typeof selectors.getOriginPoint>;
    expandedIDs: ReturnType<typeof selectors.getExpandedIDs>;
    pagination: ReturnType<typeof selectors.getPagination>;
    listTab: ReturnType<typeof selectors.getListTab>;
    sorting: ReturnType<typeof selectors.getSorting>;
    trucks: ReturnType<typeof selectors.getTrucks>;
};

const useTrucksNearbyState = (): UseTrucksNearbyState => ({
    externalSearchParams: useSelector(selectors.getExternalSearchParams),
    isShowPartnersTab: useSelector(getShowPartnersTab),
    openedInfoWindows: useSelector(selectors.getOpenedInfoWindows),
    permissionList: useSelector(selectors.getPermissionList),
    allTrucksIDs: useSelector(selectors.getAllTrucksIDs),
    allTrucks: useSelector(selectors.getAllTrucks),
    trucksIDs: useSelector(selectors.getTrucksIDs),

    // new
    destinationPoint: useSelector(selectors.getDestinationPoint),
    quoteTemplate: useSelector(selectors.generateQuoteTemplate),
    allCarriers: useSelector(commonSelectors.getAllCarriers),
    searchParams: useSelector(selectors.getSearchParams),
    originPoint: useSelector(selectors.getOriginPoint),
    expandedIDs: useSelector(selectors.getExpandedIDs),
    pagination: useSelector(selectors.getPagination),
    sorting: useSelector(selectors.getSorting),
    listTab: useSelector(selectors.getListTab),
    trucks: useSelector(selectors.getTrucks),
});

export default useTrucksNearbyState;
