import React from 'react';
import {useSelector} from 'react-redux';

import {AppState} from 'store';

import Spinner from './Spinner';

const AppLoader: React.FC = () => {
    const isShown = useSelector((state: AppState) => state.app.showLoader);

    return isShown ? <Spinner /> : null;
};

export default AppLoader;
