import {useEffect} from 'react';
import isNumber from 'lodash/isNumber';

import {LoadStop} from 'pages/LoadBoard/types';
import {getDistanceOfCoordinates} from 'core/gateways/LoadBoardApiGateway/requests';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import {getCoordinatesFromStops} from '../utils/getCoordinatesFromStops';
import getFormFieldNames from '../utils/getFormFieldNames';
import useFormActions from './useFormActions';

const useRecalculateDistance = (formName: string): void => {
    const {getStopsFieldName} = getFormFieldNames();
    const stops: LoadStop[] = useGetReduxFormValue(formName, getStopsFieldName());
    const {setRouteDistance} = useFormActions();

    const stopCoordinates = getCoordinatesFromStops(stops);

    useEffect(() => {
        let isRendered = true;
        const minCountOfStops = 2;

        if (stopCoordinates.length >= minCountOfStops) {
            getDistanceOfCoordinates({coordinates: stopCoordinates}).then((response) => {
                if (!isRendered) {
                    return;
                }

                if (isNumber(response)) {
                    setRouteDistance(response);
                    return;
                }

                setRouteDistance(0);
            });
        }

        return () => {
            isRendered = false;
        };
    }, [JSON.stringify(stopCoordinates)]);
};

export default useRecalculateDistance;
