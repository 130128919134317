import React from 'react';
import classNames from 'classnames';

import {LoadStop} from 'core/entities/Load/types';

import TooltipBootstrap from 'components/ui/TooltipBootstrap';
import DelayHighlighting from 'components/ui/DelayHighlighting';

import {getTimeFrameOptions} from './utils';

import styles from './styles.module.scss';

type OwnProps = {
    isHighlightDelay?: boolean;
    loadStop: LoadStop | null;
    withTimeZone?: boolean;
    inLine?: boolean;
};

const TimeFrameView: React.FC<OwnProps> = (props) => {
    const {loadStop, inLine = true, withTimeZone = true, isHighlightDelay = false} = props;

    if (!loadStop) {
        return null;
    }

    const optionsInTheInitialTimeZone = getTimeFrameOptions({inTheInitialTimeZone: true, withTimeZone: true, loadStop});
    const optionsInTheClientTimeZone = getTimeFrameOptions({inTheInitialTimeZone: false, withTimeZone, loadStop});

    const wrapOption = ({isBold, value}, key) => (
        <span key={key}>{isBold ? <strong>{value}</strong> : <>{value}</>}</span>
    );

    const wrapOptions = (array) => array.map((item, i) => wrapOption(item, i));

    const wrappedBottomOptions = wrapOptions(optionsInTheClientTimeZone.bottomOptions);
    const wrappedTooltipOptions = wrapOptions(optionsInTheInitialTimeZone.allOptions);
    const wrappedTopOptions = wrapOptions(optionsInTheClientTimeZone.topOptions);

    const tooltipContent = <div className={classNames(styles.skip_last_space)}>{wrappedTooltipOptions}</div>;

    const containerClassNames = classNames(
        styles.container,
        {[styles.column_direction]: !inLine},
        {[styles.zero_padding]: !isHighlightDelay},
    );

    return (
        <TooltipBootstrap tooltipContent={tooltipContent}>
            <DelayHighlighting endDate={loadStop.dateEnd} isHighlightDelay={isHighlightDelay}>
                <div className={containerClassNames}>
                    <div className={classNames(styles.row, styles.with_last_space)}>{wrappedTopOptions}</div>
                    <div className={classNames(styles.row, styles.skip_last_space)}>{wrappedBottomOptions}</div>
                </div>
            </DelayHighlighting>
        </TooltipBootstrap>
    );
};

export default TimeFrameView;
