import {PayPeriod} from 'core/entities/Settlement/types';

export const isAllFetched = (
    pagination: {pagesCount: number; currentPage: number},
    responseItems: any[],
    perPage: number,
): boolean => {
    const isNoMoreData = responseItems.length < perPage;
    const isLastPage = pagination.pagesCount === pagination.currentPage;
    return isNoMoreData || isLastPage;
};

export const calculateCountPayRecordsForFetch = (payPeriod: PayPeriod): number => {
    const MIN_PER_PAGE_COUNT = 10;
    const MAX_PER_PAGE_COUNT = 200;
    const PAY_PERIODS_MAX_PAGES = 10;
    const allPayRecordsInPayPeriod = payPeriod.owners_total;
    const perPage = Math.ceil(allPayRecordsInPayPeriod / PAY_PERIODS_MAX_PAGES);
    if (perPage < MIN_PER_PAGE_COUNT) {
        return MIN_PER_PAGE_COUNT;
    }
    if (perPage > MAX_PER_PAGE_COUNT) {
        return MAX_PER_PAGE_COUNT;
    }
    return perPage;
};
