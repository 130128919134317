import {DriverOffer} from 'core/entities/Quote/types';
import {Dispatcher} from 'core/entities/Dispatcher/types';

type UpdateData = DriverOffer & {dispatcherID: number};

export default function (offer: DriverOffer, currentDispatcher: Dispatcher): UpdateData {
    return {
        ...offer,
        dispatcherID: currentDispatcher?.id,
    };
}
