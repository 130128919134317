import React, {useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';

import {Quote} from 'core/entities/Quote/types';

import Button from 'components/ui/Buttons/Button';

import {QuoteInitialValues} from 'pages/LoadBoard/types';
import useQuoteActions from 'pages/LoadBoard/hooks/useQuoteActions';
import {getQuoteDriversReceivers} from 'pages/LoadBoard/redux/selectors';

import LoadInfo from './components/LoadInfo';
import ListReceivers from './components/ListReceivers';
import styles from './styles.module.scss';

type OwnProps = {
    data: {quote: Quote};
    handlers: {
        onReserve(data: QuoteInitialValues): void;
    };
    onClose(): void;
};

const QuoteReceiversModal: React.FC<OwnProps> = (props) => {
    const {data, onClose} = props;
    const {quote} = data;
    const driversReceiversMap = useSelector(getQuoteDriversReceivers);
    const driversReceiversList = useMemo(() => Object.values(driversReceiversMap.byId), [driversReceiversMap.byId]);

    const {fetchDriversReceivers} = useQuoteActions();

    useEffect(() => {
        fetchDriversReceivers(quote);
    }, [quote]);

    return (
        <Modal
            show={true}
            dialogClassName="standard-form-modal"
            onHide={onClose}
            className="info-modal"
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title className={styles.header}>
                    <div className="modal-header__top">
                        <div>
                            <strong>List of Receivers / Quote #{quote.number}</strong>
                        </div>
                        <div>
                            <button className="close" onClick={onClose} type="button">
                                <i className="fa fa-times" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className={styles.body}>
                <div className={styles.wrapper}>
                    <LoadInfo quote={quote} />
                </div>

                <div className={styles.borderTop}>
                    <ListReceivers driversReceivers={driversReceiversList} />
                </div>
            </Modal.Body>

            <Modal.Footer className={styles.footer}>
                <Button
                    type="submit"
                    className="mr10 ml-auto"
                    colorTheme="blue"
                    buttonSize="small"
                    defaultFocus={true}
                    onClick={onClose}
                >
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default QuoteReceiversModal;
