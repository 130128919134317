import React, {useMemo} from 'react';
import classNames from 'classnames';

import {isSettlementBatchOpened} from 'core/entities/Settlement';
import {formatDate} from 'utils/dateTime';

import styles from './batchRadioInput.module.scss';

const getBatchLabel = (batch) => {
    const batchOpened = isSettlementBatchOpened(batch);
    const statusTitle = batchOpened ? 'Open Batch' : 'Closed';
    const batchNumber = `#${batch.number}`;
    const closedAtDate = formatDate(batch.closed_at);
    return batchOpened ? `${batchNumber} - ${statusTitle}` : `${batchNumber} - ${statusTitle} ${closedAtDate}`;
};

const getBatchRadioItems = (batches) => {
    return batches.map((batch) => {
        return {
            value: batch,
            label: getBatchLabel(batch),
        };
    });
};

const RadioElement = ({title, isChecked}) => {
    const checkedClassName = classNames({'iw_rb_shell iw_rb_checked': isChecked});
    return (
        <div className="iw_rb_shell iw_rb_horisontal">
            <div className={checkedClassName}>
                <span className="rb_square" />
                <label>{title}</label>
            </div>
        </div>
    );
};

const OpenedBatchItem = ({openedBatch, value, onChange}) => {
    const isItemChecked = openedBatch.id === value.id;
    const openedBatchTitle = getBatchLabel(openedBatch);
    return (
        <div onClick={() => onChange(openedBatch)} className={classNames('item', {checked: isItemChecked})}>
            <RadioElement title={openedBatchTitle} isChecked={isItemChecked} />
        </div>
    );
};
const NewBatchItem = ({value, onChange}) => (
    <div onClick={() => onChange(null)} className={classNames('item', {checked: !value})}>
        <RadioElement title="Create new Batch" isChecked={!value} />
    </div>
);

const BatchRadioInput = (props) => {
    const {
        openedBatch,
        closedBatches,
        input: {value, onChange},
    } = props;

    const closedBatchItems = useMemo(() => {
        const items = getBatchRadioItems(closedBatches);
        return items.map((item, index) => {
            const isChecked = item.value.id === value.id;
            const checkedClassName = classNames('item', {checked: isChecked});
            return (
                <div key={index} onClick={() => onChange(item.value)} className={checkedClassName}>
                    <RadioElement title={item.label} isChecked={isChecked} />
                </div>
            );
        });
    }, [closedBatches, value]);

    return (
        <div className={styles.modal}>
            {openedBatch ? (
                <OpenedBatchItem value={value} openedBatch={openedBatch} onChange={onChange} />
            ) : (
                <NewBatchItem value={value} onChange={onChange} />
            )}
            {closedBatches.length > 0 && (
                <>
                    <hr />
                    {closedBatchItems}
                </>
            )}
        </div>
    );
};

export default React.memo(BatchRadioInput);
