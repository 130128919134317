import React from 'react';

import {getCarrierRegNumber} from 'core/entities/Carrier/modules';
import Carrier from 'core/entities/Carrier/types';

import TooltipBootstrap from 'components/ui/TooltipBootstrap';

type OwnProps = {
    carrier: Carrier;
};

const CarrierItemRegNumber: React.FC<OwnProps> = (props) => {
    const {carrier} = props;

    const carrierRegNumber = getCarrierRegNumber(carrier);

    if (carrierRegNumber) {
        return <TooltipBootstrap tooltipContent={carrierRegNumber}>REG#{carrierRegNumber}</TooltipBootstrap>;
    }

    return <div>REG#</div>;
};

export default CarrierItemRegNumber;
