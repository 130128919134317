import {toast} from 'react-toastify';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import {ThunkAction} from 'redux-thunk';

import {AppState} from 'store';
import * as appActions from 'store/actions';
import {getAllSenders} from 'store/reducers/commonData/selectors';

import {getSenderByID} from 'core/entities/Sender/modules/senderDefault';
import {getTravelOrderNumber} from 'core/entities/TravelOrder/modules/common/getTravelOrderNumber';
import {isTravelOrderHasCarrier} from 'core/entities/TravelOrder/modules/travelOrderCarrier';
import * as dispatchModules from 'core/entities/TravelOrder/modules/travelOrderDispatch';
import {getLoadFromTravelOrder} from 'core/entities/TravelOrder/modules/travelOrderLoad';
import {isTravelOrderNoPay} from 'core/entities/TravelOrder/modules/travelOrderStatus';
import * as stopsModules from 'core/entities/TravelOrder/modules/travelOrderStops';
import TravelOrder from 'core/entities/TravelOrder/types';
import * as toRequests from 'core/gateways/TravelOrderApiGateway/requests/indexNew';

import restApi from 'services/restapi';

import notifications from 'components/ui/notifications';

import * as modalActions from 'pages/TravelOrders/redux/actions/modals';
import * as types from 'pages/TravelOrders/redux/actionTypes/travelOrder';
import * as addBOLMappers from 'pages/TravelOrders/redux/mappers/addBOL';
import * as addPODMappers from 'pages/TravelOrders/redux/mappers/addPOD';
import * as otherAttachments from 'pages/TravelOrders/redux/mappers/attachments/otherAttachments';
import {transformSignedAttachmentsToRequestBody} from 'pages/TravelOrders/redux/mappers/attachments/signedAttachments';
import {transformCheckInDataToRequestBody} from 'pages/TravelOrders/redux/mappers/checkIn';
import * as checkOutMappers from 'pages/TravelOrders/redux/mappers/checkOut';
import {transformConfirmDataToRequestBody} from 'pages/TravelOrders/redux/mappers/confirm';
import {transformDispatchDataToRequestBody} from 'pages/TravelOrders/redux/mappers/dispatch';
import * as editBOLMappers from 'pages/TravelOrders/redux/mappers/editBOL';
import {transformEditCheckInDataToRequestBody} from 'pages/TravelOrders/redux/mappers/editCheckIn';
import {transformEditCheckOutDataToRequestBody} from 'pages/TravelOrders/redux/mappers/editCheckOut';
import * as mappersEditDeadhead from 'pages/TravelOrders/redux/mappers/editDeadhead';
import * as editPODMappers from 'pages/TravelOrders/redux/mappers/editPOD';
import {transformFinishDataToRequestBody} from 'pages/TravelOrders/redux/mappers/finish';
import {transformPayDataToRequestBody} from 'pages/TravelOrders/redux/mappers/pay';
import {getCurrentTravelOrder, getTripMonitorModalData} from 'pages/TravelOrders/redux/selectors';
import * as formTypes from 'pages/TravelOrders/types/formTypes';

import {showNotSignedPODModal, showSuccessfulFinishTravelOrderModal} from '../modals';

import {generateRateConfirmation} from './rateConfirmation';

type ThunkActionTypes = ThunkAction<void, AppState, unknown, any>;

export const travelOrderActionCreators = {
    travelOrderReceived: (payload: TravelOrder) => ({type: types.FETCHING_TRAVEL_ORDER_RECEIVED, payload} as const),
    clearTravelOrderState: () => ({type: types.TRAVEL_ORDER_STATE_CLEARED}),
};

export const fetchTO = (toNumber?: string | number): ThunkActionTypes => async (dispatch) => {
    if (!toNumber) {
        return;
    }

    try {
        dispatch(appActions.showLoader());

        const {data: travelOrder} = await toRequests.fetchTravelOrder(toNumber);

        dispatch(travelOrderActionCreators.travelOrderReceived(travelOrder));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const setTravelOrderPay = (
    params: formTypes.PayModalFormValues,
    {withDispatchModal = false} = {},
): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);
    const isTONoPay = isTravelOrderNoPay(travelOrder);
    const load = getLoadFromTravelOrder(travelOrder);

    const transformedPayDataToRequestBody = transformPayDataToRequestBody(params);
    const requestParams = {toNumber, loadNumber: load?.number, requestBody: transformedPayDataToRequestBody};

    const payRequest = isTONoPay ? toRequests.setTravelOrderPayRequest : toRequests.editTravelOrderPayRequest;

    try {
        dispatch(appActions.showLoader());

        await payRequest(requestParams);

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));

        if (withDispatchModal) {
            dispatch(modalActions.showDispatchReDispatchModal());
        }
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const dispatchTravelOrder = (
    dispatchReDispatchData: formTypes.DispatchReDispatchModalFormValues,
): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);

    const transformedDispatchDataToRequestBody = transformDispatchDataToRequestBody(dispatchReDispatchData);
    const requestParams = {toNumber, requestBody: transformedDispatchDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        await toRequests.dispatchTravelOrderRequest(requestParams);

        await dispatch(generateRateConfirmation());

        dispatch(modalActions.closeModal());

        dispatch(fetchTO(toNumber));
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const reDispatchTravelOrder = (
    dispatchReDispatchData: formTypes.DispatchReDispatchModalFormValues,
): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);
    const load = getLoadFromTravelOrder(travelOrder);

    const transformedDispatchDataToRequestBody = transformDispatchDataToRequestBody(dispatchReDispatchData);
    const requestParams = {toNumber, loadNumber: load?.number, requestBody: transformedDispatchDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        await toRequests.reDispatchTravelOrderRequest(requestParams);

        await dispatch(generateRateConfirmation());

        dispatch(modalActions.closeModal());

        dispatch(fetchTO(toNumber));
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const directReDispatchTravelOrder = (
    dispatchReDispatchData: formTypes.DispatchReDispatchModalFormValues,
): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);
    const load = getLoadFromTravelOrder(travelOrder);

    const transformedDispatchDataToRequestBody = transformDispatchDataToRequestBody(dispatchReDispatchData);
    const requestParams = {toNumber, loadNumber: load?.number, requestBody: transformedDispatchDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        await toRequests.directReDispatchTravelOrderRequest(requestParams);

        await dispatch(generateRateConfirmation());

        dispatch(modalActions.closeModal());

        dispatch(fetchTO(toNumber));
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const dispatchReDispatchTravelOrder = (dispatchReDispatchData: formTypes.DispatchReDispatchModalFormValues) => (
    dispatch,
    getState,
) => {
    const travelOrder = getCurrentTravelOrder(getState());

    const isTONeedReDispatch = dispatchModules.isTravelOrderNeedReDispatch(travelOrder);
    const isTODispatched = dispatchModules.isTravelOrderDispatched(travelOrder);

    if (!isTODispatched && !isTONeedReDispatch) {
        dispatch(dispatchTravelOrder(dispatchReDispatchData));
    }

    if (isTODispatched && isTONeedReDispatch) {
        dispatch(reDispatchTravelOrder(dispatchReDispatchData));
    }

    if (isTODispatched && !isTONeedReDispatch) {
        dispatch(directReDispatchTravelOrder(dispatchReDispatchData));
    }
};

const confirmDispatchReDispatchFiles = (
    confirmData: formTypes.ConfirmModalFormValues,
    filesIds: Record<number, string>,
): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);
    const isTOHasCarrier = isTravelOrderHasCarrier(travelOrder);

    if (!isTOHasCarrier || !confirmData.attachments?.length) {
        return;
    }

    const transformedConfirmFiles = transformSignedAttachmentsToRequestBody({
        data: confirmData,
        filesIds,
    });

    const {uploadedFormData} = transformedConfirmFiles;

    if (uploadedFormData) {
        const uploadingRequestParams = {toNumber, requestBody: uploadedFormData};
        await toRequests.addTravelOrderAttachmentsRequest(uploadingRequestParams).catch((e) => console.log(e));
    }
};

export const confirmReDispatch = (confirmData: formTypes.ConfirmModalFormValues): ThunkActionTypes => async (
    dispatch,
    getState,
) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);

    const transformedConfirmDataToRequestBody = transformConfirmDataToRequestBody({confirmData, travelOrder});
    const requestParams = {toNumber, requestBody: transformedConfirmDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        const {
            data: {files},
        } = await toRequests.confirmReDispatchTravelOrderRequest(requestParams);

        await dispatch(confirmDispatchReDispatchFiles(confirmData, files));

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const confirmDispatch = (confirmData: formTypes.ConfirmModalFormValues): ThunkActionTypes => async (
    dispatch,
    getState,
) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);

    const transformedConfirmDataToRequestBody = transformConfirmDataToRequestBody({confirmData, travelOrder});
    const requestParams = {toNumber, requestBody: transformedConfirmDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        const {
            data: {files},
        } = await toRequests.confirmDispatchTravelOrderRequest(requestParams);

        await dispatch(confirmDispatchReDispatchFiles(confirmData, files));

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const confirmDispatchReDispatch = (confirmData: formTypes.ConfirmModalFormValues): ThunkActionTypes => (
    dispatch,
    getState,
) => {
    const travelOrder = getCurrentTravelOrder(getState());

    const isTONeedConfirmReDispatch = dispatchModules.isTravelOrderNeedConfirmReDispatch(travelOrder);

    if (isTONeedConfirmReDispatch) {
        dispatch(confirmReDispatch(confirmData));
        return;
    }

    if (!isTONeedConfirmReDispatch) {
        dispatch(confirmDispatch(confirmData));
    }
};

export const stopCheckIn = (checkInData: formTypes.CheckInFormValues): ThunkActionTypes => async (
    dispatch,
    getState,
) => {
    const travelOrder = getCurrentTravelOrder(getState());

    const transformedCheckInDataToRequestBody = transformCheckInDataToRequestBody(checkInData);
    const currentStopID = stopsModules.getTravelOrderCurrentStopID(travelOrder);
    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, stopNumber: currentStopID, requestBody: transformedCheckInDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        await toRequests.checkInTravelOrderRequest(requestParams);

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const addBOLFiles = (
    bolInfoData: formTypes.AddBillOfLadingModalFormValues,
    filesIds: Record<number, string>,
): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());

    if (!bolInfoData?.attachments?.length) {
        return;
    }

    const transformedAddBOLFilesToRequestBody = addBOLMappers.transformAddBOLFilesToRequestBody(bolInfoData, filesIds);
    const currentStopID = stopsModules.getTravelOrderCurrentStopID(travelOrder);
    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, stopID: currentStopID, requestBody: transformedAddBOLFilesToRequestBody};

    try {
        dispatch(appActions.showLoader());

        await toRequests.addTravelOrderBOLFilesRequest(requestParams);

        toast.success(notifications.filesSuccessfulAdded, {data: {}});
    } catch (error) {
        toast.error(notifications.filesUnsuccessfulAdded, {data: {}});

        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const addBillOfLading = (bolInfoData: formTypes.AddBillOfLadingModalFormValues): ThunkActionTypes => async (
    dispatch,
    getState,
) => {
    const travelOrder = getCurrentTravelOrder(getState());

    const transformedAddBOLDataToRequestBody = addBOLMappers.transformAddBOLDataToRequestBody(bolInfoData);
    const currentStopID = stopsModules.getTravelOrderCurrentStopID(travelOrder);
    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, stopNumber: currentStopID, requestBody: transformedAddBOLDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        const {
            data: {files},
        } = await toRequests.addTravelOrderBOLRequest(requestParams);

        await dispatch(addBOLFiles(bolInfoData, files));

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const pickUpStopCheckOut = (checkOutData: formTypes.PickUpCheckOutModalFormValues): ThunkActionTypes => async (
    dispatch,
    getState,
) => {
    const travelOrder = getCurrentTravelOrder(getState());

    const transformedPickUpCheckOutDataToRequestBody = checkOutMappers.transformPickUpCheckOutDataToRequestBody(
        checkOutData,
    );
    const currentStopID = stopsModules.getTravelOrderCurrentStopID(travelOrder);
    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {
        requestBody: transformedPickUpCheckOutDataToRequestBody,
        stopNumber: currentStopID,
        toNumber,
    };

    try {
        dispatch(appActions.showLoader());

        await toRequests.checkOutTravelOrderRequest(requestParams);

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const deliveryStopCheckOut = (
    checkOutData: formTypes.DeliveryCheckOutModalFormValues,
): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());

    const transformedDeliveryCheckOutDataToRequestBody = checkOutMappers.transformDeliveryCheckOutDataToRequestBody(
        checkOutData,
    );
    const currentStopID = stopsModules.getTravelOrderCurrentStopID(travelOrder);
    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {
        requestBody: transformedDeliveryCheckOutDataToRequestBody,
        stopNumber: currentStopID,
        toNumber,
    };

    try {
        dispatch(appActions.showLoader());

        await toRequests.checkOutTravelOrderRequest(requestParams);

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const addPODFiles = (params: {
    podData: formTypes.ProofOfDeliveryModalFormValues;
    data: {[key: string]: {fileId: number; podId: number}}[];
}): ThunkActionTypes => async (dispatch, getState) => {
    const {podData, data} = params;

    if (isEmpty(data)) {
        return;
    }

    const travelOrder = getCurrentTravelOrder(getState());

    const transformedAddPODFilesToRequestBody = addPODMappers.transformAddPODFilesToRequestBody({
        pods: podData.pods,
        data,
    });
    const currentStopID = stopsModules.getTravelOrderCurrentStopID(travelOrder);
    const toNumber = getTravelOrderNumber(travelOrder);

    if (isEmpty(transformedAddPODFilesToRequestBody)) {
        return;
    }

    await Promise.allSettled(
        transformedAddPODFilesToRequestBody.map(async (item) => {
            const requestParams = {
                toNumber,
                stopNumber: currentStopID,
                podID: item.podID,
                requestBody: item.uploadedFormData,
            };
            await toRequests.addTravelOrderPODFilesRequest(requestParams);
        }),
    );
};

export const addProofOfDelivery = (podData: formTypes.ProofOfDeliveryModalFormValues): ThunkActionTypes => async (
    dispatch,
    getState,
) => {
    const travelOrder = getCurrentTravelOrder(getState());

    const transformedPODsDataToRequestBody = addPODMappers.transformPODsDataToRequestBody(podData);
    const currentStopID = stopsModules.getTravelOrderCurrentStopID(travelOrder);
    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, stopNumber: currentStopID, requestBody: transformedPODsDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        const {data} = await toRequests.addTravelOrderPODRequest(requestParams);

        await dispatch(addPODFiles({podData, data: data?.files || {}}));

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const addPODsForTheAllDeliveriesFiles = (params: {
    podData: formTypes.NotSignedPODFormValues;
    data: {[key: string]: {fileId: number; podId: number; stopId?: string}}[];
}): ThunkActionTypes => async (dispatch, getState) => {
    const {podData, data} = params;

    if (isEmpty(data)) {
        return;
    }

    const travelOrder = getCurrentTravelOrder(getState());
    const pods = flatten(podData?.deliveries.map((item) => item.podData));

    const transformedAddPODFilesToRequestBody = addPODMappers.transformAddPODFilesToRequestBody({
        pods,
        data,
    });

    const toNumber = getTravelOrderNumber(travelOrder);

    if (isEmpty(transformedAddPODFilesToRequestBody)) {
        return;
    }

    await Promise.allSettled(
        transformedAddPODFilesToRequestBody.map(async (item) => {
            const requestParams = {
                toNumber,
                stopNumber: item?.stopID,
                podID: item.podID,
                requestBody: item.uploadedFormData,
            };
            await toRequests.addTravelOrderPODFilesRequest(requestParams);
        }),
    );
};

export const addPODsForTheAllDeliveries = (podData: formTypes.NotSignedPODFormValues): ThunkActionTypes => async (
    dispatch,
    getState,
) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);

    const transformedPODsToRequestBody = addPODMappers.transformPODsDataOfTheAllDeliveriesToRequestBody(podData);
    const requestParams = {requestBody: transformedPODsToRequestBody, toNumber};

    try {
        dispatch(appActions.showLoader());

        const {data} = await toRequests.addAllTravelOrderPODsRequest(requestParams);

        await dispatch(addPODsForTheAllDeliveriesFiles({podData, data: data?.files || {}}));

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const finishTOWithSignedBOLs = (finishData: formTypes.FinishModalFormValues): ThunkActionTypes => async (
    dispatch,
    getState,
) => {
    const travelOrder = getCurrentTravelOrder(getState());

    const transformedFinishDataToRequestBody = transformFinishDataToRequestBody(finishData);
    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, requestBody: transformedFinishDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        await toRequests.finishTravelOrderRequest(requestParams);

        await dispatch(fetchTO(toNumber));

        dispatch(showSuccessfulFinishTravelOrderModal());
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const finishTravelOrder = (finishData: formTypes.FinishModalFormValues): ThunkActionTypes => (
    dispatch,
    getState,
) => {
    const travelOrder = getCurrentTravelOrder(getState());

    if (travelOrder?.countNotSignedBol) {
        dispatch(showNotSignedPODModal());

        return;
    }

    if (!travelOrder?.countNotSignedBol) {
        dispatch(finishTOWithSignedBOLs(finishData));
    }
};

export const editTravelOrderDeadheadFiles = (
    deadheadData: formTypes.EditDeadheadFilesFormValues,
): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);
    const isTOHasCarrier = isTravelOrderHasCarrier(travelOrder);
    const transformedConfirmFiles = mappersEditDeadhead.transformEditDeadheadDataFilesToRequestBody({
        data: deadheadData,
        travelOrder,
    });

    if (!isTOHasCarrier || !transformedConfirmFiles) {
        return;
    }

    try {
        dispatch(appActions.showLoader());

        const {data: files} = await toRequests.addTravelOrderAttachmentsMetaRequest({
            toNumber,
            requestBody: transformedConfirmFiles,
        });
        const uploadedFormDataConfirmFiles = transformSignedAttachmentsToRequestBody({
            data: deadheadData,
            filesIds: files,
        });

        const {uploadedFormData} = uploadedFormDataConfirmFiles;

        if (uploadedFormData) {
            const uploadingRequestParams = {toNumber, requestBody: uploadedFormData};
            await toRequests.addTravelOrderAttachmentsRequest(uploadingRequestParams).catch((e) => console.log(e));
        }

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const editTravelOrderDeadheadDetails = (
    deadheadData: formTypes.EditDeadheadDetailsFormValues,
): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());

    const transformedDataToRequestBody = mappersEditDeadhead.transformEditDeadheadDataToRequestBody({
        deadheadData,
        travelOrder,
    });
    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, requestBody: transformedDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        await toRequests.editTravelOrderDeadheadRequest(requestParams);

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const editCheckIn = (checkInData: formTypes.EditCheckInFormValues): ThunkActionTypes => async (
    dispatch,
    getState,
) => {
    const modalData = getTripMonitorModalData(getState());
    const travelOrder = getCurrentTravelOrder(getState());

    const transformedEditCheckInDataToRequestBody = transformEditCheckInDataToRequestBody(checkInData);
    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, stopID: modalData?.stopID, requestBody: transformedEditCheckInDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        await toRequests.editTravelOrderCheckInRequest(requestParams);

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const editCheckOut = (checkOutData: formTypes.EditCheckOutFormValues): ThunkActionTypes => async (
    dispatch,
    getState,
) => {
    const modalData = getTripMonitorModalData(getState());
    const travelOrder = getCurrentTravelOrder(getState());

    const transformedEditCheckOutDataToRequestBody = transformEditCheckOutDataToRequestBody(checkOutData);
    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, stopID: modalData?.stopID, requestBody: transformedEditCheckOutDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        await toRequests.editTravelOrderCheckOutRequest(requestParams);

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const editBOLFiles = (
    bolData: formTypes.EditBOLFormValues,
    filesIds: Record<number, string>,
): ThunkActionTypes => async (dispatch, getState) => {
    const modalData = getTripMonitorModalData(getState());
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);

    const stopID = modalData?.stopID;

    const transformedEditBOLFilesToRequestBody = editBOLMappers.transformEditBOLFilesToRequestBody({
        bolData,
        travelOrder,
        stopID,
        filesIds,
    });
    const {uploadedFormData} = transformedEditBOLFilesToRequestBody;

    if (uploadedFormData) {
        const uploadingRequestParams = {toNumber, stopID, requestBody: uploadedFormData};

        try {
            await toRequests.addTravelOrderBOLFilesRequest(uploadingRequestParams);

            toast.success(notifications.filesSuccessfulAdded, {data: {}});
        } catch (e) {
            toast.error(notifications.filesUnsuccessfulAdded, {data: {}});
        }
    }
};

export const editBOL = (bolData: formTypes.EditBOLFormValues): ThunkActionTypes => async (dispatch, getState) => {
    const modalData = getTripMonitorModalData(getState());
    const travelOrder = getCurrentTravelOrder(getState());

    const transformedEditBOLDataToRequestBody = editBOLMappers.transformEditBOLDataToRequestBody(
        bolData,
        modalData?.stopID,
        travelOrder,
    );
    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, stopID: modalData?.stopID, requestBody: transformedEditBOLDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        const {
            data: {files},
        } = await toRequests.editTravelOrderBOLRequest(requestParams);

        await dispatch(editBOLFiles(bolData, files));

        dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const editPODsFiles = (params: {
    podData: formTypes.EditPODFormValues;
    newFilesData: {[key: string]: {fileId: number; podId: number}}[];
}): ThunkActionTypes => async (dispatch, getState) => {
    const {podData, newFilesData} = params;

    const travelOrder = getCurrentTravelOrder(getState());
    const currentStopID = stopsModules.getTravelOrderCurrentStopID(travelOrder);
    const toNumber = getTravelOrderNumber(travelOrder);

    const transformedEditPODsFilesToRequestBody = editPODMappers.transformEditPODsFilesToRequestBody({
        podData,
        newFilesData,
    });

    if (transformedEditPODsFilesToRequestBody.length) {
        await Promise.allSettled(
            transformedEditPODsFilesToRequestBody.map(async (item) => {
                const requestParams = {
                    stopNumber: currentStopID,
                    requestBody: item.uploadedFormData,
                    podID: item.podID,
                    toNumber,
                };
                try {
                    await toRequests.addTravelOrderPODFilesRequest(requestParams);

                    toast.success(notifications.filesSuccessfulAdded, {data: {}});
                } catch (e) {
                    toast.error(notifications.filesUnsuccessfulAdded, {data: {}});
                }
            }),
        );
    }
};

export const editPODs = (podData: formTypes.EditPODFormValues): ThunkActionTypes => async (dispatch, getState) => {
    const modalData = getTripMonitorModalData(getState());
    const travelOrder = getCurrentTravelOrder(getState());
    const stopID = modalData?.stopID;

    const transformedEditPODsDataToRequestBody = editPODMappers.transformEditPODsDataToRequestBody({
        podData,
        travelOrder,
        stopID,
    });
    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, stopID, requestBody: transformedEditPODsDataToRequestBody};

    try {
        dispatch(appActions.showLoader());

        const {data} = await toRequests.editTravelOrderPODsRequest(requestParams);

        await dispatch(editPODsFiles({podData, newFilesData: data?.files}));

        dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const cancelAddBOL = (): ThunkActionTypes => async (dispatch, getState) => {
    const modalData = getTripMonitorModalData(getState());
    const travelOrder = getCurrentTravelOrder(getState());

    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, stopID: modalData?.stopID};

    try {
        dispatch(appActions.showLoader());

        await toRequests.cancelAddBOLRequest(requestParams);

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const cancelCheckIn = (): ThunkActionTypes => async (dispatch, getState) => {
    const modalData = getTripMonitorModalData(getState());
    const travelOrder = getCurrentTravelOrder(getState());

    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, stopID: modalData?.stopID};

    try {
        dispatch(appActions.showLoader());

        await toRequests.cancelCheckInRequest(requestParams);

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const cancelCheckOut = (): ThunkActionTypes => async (dispatch, getState) => {
    const modalData = getTripMonitorModalData(getState());
    const travelOrder = getCurrentTravelOrder(getState());

    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, stopID: modalData?.stopID};

    try {
        dispatch(appActions.showLoader());

        await toRequests.cancelCheckOutRequest(requestParams);

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const cancelSignPOD = (): ThunkActionTypes => async (dispatch, getState) => {
    const modalData = getTripMonitorModalData(getState());
    const travelOrder = getCurrentTravelOrder(getState());

    const toNumber = getTravelOrderNumber(travelOrder);

    const requestParams = {toNumber, stopID: modalData?.stopID};

    try {
        dispatch(appActions.showLoader());

        await toRequests.cancelSignPODRequest(requestParams);

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const cancelFinish = (): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());

    const toNumber = getTravelOrderNumber(travelOrder);

    try {
        dispatch(appActions.showLoader());

        await toRequests.cancelFinishTravelOrderRequest({toNumber});

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export function runTruckManual(travelOrder): any {
    return async function (dispatch) {
        const {truck} = travelOrder;
        dispatch(appActions.showLoader());

        try {
            const putData = {location_same_from: null};
            await restApi.put(`/trucks/${truck.id}/location-same-from`, putData).then(({data}) => data);
            dispatch({
                type: types.TRAVEL_ORDER_TRUCK_RUN_TRUCK_MANUAL,
                payload: {travelOrderID: travelOrder.id},
            });
        } catch (e) {
            dispatch(appActions.handleError(e));
        } finally {
            dispatch(appActions.hideLoader());
        }
    };
}

export const sendTravelOrderEmailAttachments = (attachmentData: formTypes.EmailAttachmentFormValues) => async (
    dispatch,
    getState,
) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);
    const senders = getAllSenders(getState());

    const {from: senderID} = attachmentData || {};

    if (!senderID) {
        return;
    }

    const sender = getSenderByID({senders, currentSenderID: senderID});

    const requestBody = {...attachmentData, from: sender?.email, senderId: senderID};

    try {
        dispatch(appActions.showLoader());

        await toRequests.emailAttachmentsRequest({travelOrderNumber: toNumber, requestBody});

        await dispatch(fetchTO(toNumber));

        dispatch(modalActions.closeModal());

        dispatch(modalActions.showSuccessfullySentEmailAttachmentsModal());
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const addTravelOrderOtherFiles = (
    data: Partial<formTypes.AddOtherFilesTOFormValues>,
): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);

    const filesToMetaRequestBody = otherAttachments.transformOtherAttachmentsToMetaRequestBody({data});

    if (!filesToMetaRequestBody) {
        return;
    }

    try {
        dispatch(appActions.showLoader());

        const {data: files} = await toRequests.addTravelOrderOtherAttachmentsMetaRequest({
            toNumber,
            requestBody: filesToMetaRequestBody,
        });

        const {uploadedFormData} = otherAttachments.transformOtherAttachmentsToRequestBody({
            data,
            filesIds: files,
        });

        if (uploadedFormData) {
            const uploadingRequestParams = {toNumber, requestBody: uploadedFormData};

            try {
                await toRequests.addTravelOrderOtherAttachmentsRequest(uploadingRequestParams);

                toast.success(notifications.filesSuccessfulAdded, {data: {}});
            } catch (e) {
                toast.error(notifications.filesUnsuccessfulAdded, {data: {}});
            }
        }

        dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const removeTravelOrderOtherFileByID = (fileID: number): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);

    try {
        dispatch(appActions.showLoader());

        await toRequests.addTravelOrderOtherAttachmentsMetaRequest({
            toNumber,
            requestBody: {deleteFileIds: [fileID]},
        });

        dispatch(fetchTO(toNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};
