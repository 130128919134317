import {QuoteInitialValues} from 'pages/LoadBoard/types';
import {Address} from 'core/entities/Quote/types';

export const getOriginAddress = (formValues: QuoteInitialValues): Address => {
    return formValues.load.stops[0].address as Address;
};
export const getDestinationAddress = (formValues: QuoteInitialValues): Address => {
    const lastIndex = formValues.load.stops.length - 1;

    return formValues.load.stops[lastIndex].address as Address;
};
