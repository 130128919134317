import React from 'react';

import CreateCustomer from 'components/common/Customer/modals/CreateCustomer';

// wrapper for combine old modal like CreateCustomer with new api for display common modals using Modal Provider
const CreateInvoiceCustomerModal = ({handlers: {onReset, onCancel, onCreate}, onClose}) => {
    return <CreateCustomer onSubmit={onCreate} onReset={onReset} onCancel={onCancel} onClose={onClose} />;
};

export default React.memo(CreateInvoiceCustomerModal);
