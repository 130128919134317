import flatten from 'lodash/flatten';

import SelectOption from 'types/SelectOption';

export function flattenGroupedOptions(options: SelectOption[]): (SelectOption | undefined)[] {
    // for case when we have groups with inner opts we should get inner options
    // ex. options = [{label: 'Some group': options: [{label: 'test1', value: 'test'}]}]
    const hasGroupedOptions = options && options.some((item) => item.options);
    if (hasGroupedOptions) {
        return flatten(options.map((item) => item.options));
    }

    return options;
}
