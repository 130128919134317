import {useState, useRef, useEffect} from 'react';
import isNumber from 'lodash/isNumber';

interface LoadParams {
    formInput: {value: any};
    getInitialOption?: () => Promise<any>;
    getNextOptions(params: {searchValue: string; page: number}): Promise<any>;
    formatNextOptions(nextOptions: any): any;
    isDisplayLabelOnlyName: boolean;
}

interface LoadSettings {
    currentOption: any;
    setCurrentOption: any;
    loadSettings: any;
}

const isEqualCurrentInputValueAndSelectedOption = (
    currentInputValue: any,
    selectedOption: {value: any} | undefined,
): boolean => {
    if (!currentInputValue) {
        return false;
    }
    const currentInputValueID = isNumber(currentInputValue) ? currentInputValue : currentInputValue?.id;
    const selectedOptionValueID = selectedOption?.value?.id;
    return currentInputValueID === selectedOptionValueID;
};

export const useLoadOptionsSettings = ({
    formInput,
    getInitialOption,
    getNextOptions,
    formatNextOptions,
    isDisplayLabelOnlyName,
}: LoadParams): LoadSettings => {
    const formInputCurrentValue = formInput?.value;
    const ref = useRef({inputValueSet: false});
    const [currentSelectedOption, setCurrentSelectedOption] = useState();
    const loadSettings = async (searchValue, loadedOptions, {page}) => {
        const nextOptions = await getNextOptions({searchValue, page});
        const formattedOptions = formatNextOptions(nextOptions);
        return {
            options: formattedOptions,
            hasMore: nextOptions.length > 0,
            additional: {
                page: page + 1,
            },
        };
    };
    const handleGetInitialOption = (): void => {
        if (getInitialOption) {
            getInitialOption().then((initialOption) => {
                if (initialOption) {
                    const optionToInitial = isDisplayLabelOnlyName
                        ? {...initialOption, label: initialOption.value.name}
                        : initialOption;

                    setCurrentSelectedOption(optionToInitial);
                }
            });
        }
    };

    const handleSetCurrentOption = (currentOption): void => {
        setCurrentSelectedOption(currentOption);
    };

    useEffect(() => {
        if (formInputCurrentValue && !ref.current.inputValueSet) {
            ref.current.inputValueSet = true;
        }
        if (!formInputCurrentValue && ref.current.inputValueSet) {
            ref.current.inputValueSet = false;
            setCurrentSelectedOption(undefined);
        }
    }, [formInputCurrentValue, ref.current.inputValueSet]);

    useEffect(() => {
        handleGetInitialOption();
    }, []);

    useEffect(() => {
        // cause async data flow and complexity with redux-form form we should wait until input value will be set
        if (!ref.current.inputValueSet) {
            return;
        }

        if (!formInputCurrentValue && !currentSelectedOption) {
            return;
        }

        const isEqualFormInputAndCurrentSelectedValue = isEqualCurrentInputValueAndSelectedOption(
            formInputCurrentValue,
            currentSelectedOption,
        );

        // set component initial option for case when form after initial value, and then by some reasons(like dispatch redux action) form values will change
        if (
            (formInputCurrentValue && !currentSelectedOption) ||
            (formInputCurrentValue && !isEqualFormInputAndCurrentSelectedValue)
        ) {
            handleGetInitialOption();
        }
    }, [ref.current.inputValueSet, formInputCurrentValue, currentSelectedOption]);
    return {
        currentOption: currentSelectedOption,
        setCurrentOption: handleSetCurrentOption,
        loadSettings,
    };
};
