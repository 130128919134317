// see https://confluence.rhinocodes.com/display/RH/PR.+RBAC+Permissions+Matrix
export const CHECKING_DISPATCHER = 'ROLE_CHECKING';
export const OPERATION_DISPATCHER = 'ROLE_OPERATIONS';
export const BOOKING_DISPATCHER = 'ROLE_BOOKING';
export const USER_MANAGER = 'ROLE_USER_MANAGER';
export const FULL_CIRCLE = 'ROLE_FULL_CIRCLE';
export const ACCOUNTING = 'ROLE_BILLING';
export const TEAMLEAD = 'ROLE_TEAMLEAD';
export const SAFETY = 'ROLE_SAFETY';
export const SALES = 'ROLE_SALES_REP';
export const ADMIN = 'ROLE_ADMIN';
export const HR = 'ROLE_HR';

export type UserRole =
    | typeof OPERATION_DISPATCHER
    | typeof CHECKING_DISPATCHER
    | typeof BOOKING_DISPATCHER
    | typeof TEAMLEAD
    | typeof ACCOUNTING
    | typeof HR
    | typeof SAFETY
    | typeof ADMIN
    | typeof SALES
    | typeof FULL_CIRCLE
    | typeof USER_MANAGER;

export type UserRoleOption = {
    label: string;
    value: UserRole;
};

export type UserRoleOptions = Array<UserRoleOption>;
