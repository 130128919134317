import {InferActions} from 'store';

import Customer from 'core/entities/Customer/types';

import * as actionMergeTypes from 'pages/Customers/redux/actionTypes/merge';
import {mergeActionCreators} from 'pages/Customers/redux/actions/merge';

type ActionTypes = InferActions<typeof mergeActionCreators>;

export type MergeState = {
    customersToMerge: Customer[] | null;
};

const defaultState: MergeState = {
    customersToMerge: null,
};

const merge = (state = defaultState, action: ActionTypes): MergeState => {
    switch (action.type) {
        case actionMergeTypes.RECEIVED_CUSTOMERS_TO_MERGE: {
            return {
                ...state,
                customersToMerge: action.payload,
            };
        }

        case actionMergeTypes.CLEARED_CUSTOMERS_TO_MERGE_STATE:
            return defaultState;

        // no default
    }

    const _exhaustiveCheck = action;

    return state;
};

export default merge;
