import React from 'react';

import {getCityLineFromString} from 'utils/getCityLine';

import EventName from 'widgets/LocationEvents/components/common/EventName';

import {LocationEventsTypes} from 'core/entities/LocationEvents/constants';
import {getConvertedFormattedTime} from 'core/entities/LocationEvents/modules/eventDateTime';

import styles from './styles.module.scss';

interface OwnProps {
    previouslyUpdateType: LocationEventsTypes;
    previouslyCityLine: string;
    previouslyUpdatedAt: string;
}

const PreviewEventForBroker: React.FC<OwnProps> = ({previouslyUpdateType, previouslyCityLine, previouslyUpdatedAt}) => {
    const location = getCityLineFromString(previouslyCityLine);
    const dateTime = previouslyUpdatedAt ? getConvertedFormattedTime(previouslyUpdatedAt) : '';

    return (
        <div className={styles.info}>
            <div className={styles.icon}>
                <i className="fa fa-info-circle" />
            </div>
            <div className={styles.content}>
                <p>
                    Event <EventName name={previouslyUpdateType} />
                </p>
                <p>
                    <strong>{location}</strong>
                </p>
                <p>sent to broker {dateTime}</p>
            </div>
        </div>
    );
};

export default PreviewEventForBroker;
