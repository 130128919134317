import React from 'react';
import {Modal} from 'react-bootstrap';
import Button from 'components/ui/Buttons/Button';
import ButtonLink from 'components/ui/Buttons/ButtonLink/index';
import {getLoadBoardLinks} from 'routing/linkBuilder';

type OwnProps = {
    data: {
        quoteNumber: number;
    };
    onClose(): void;
};

const SuccessfullyCreateQuote: React.FC<OwnProps> = (props) => {
    const {data, onClose} = props;
    const {list: listLink} = getLoadBoardLinks();
    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Successfully Sent</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row align-items-center">
                    <div className="col-1">
                        <i className="fa fa-check feedback-icon-success" />
                    </div>
                    <div className="col-11">
                        <p>
                            The Quote <strong>#{data.quoteNumber}</strong> profile has been sent successfully..
                        </p>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <div />
                <div className="d-flex">
                    <Button onClick={onClose} className="mr10" colorTheme="grey" buttonSize="small" defaultFocus={true}>
                        CLOSE
                    </Button>

                    <ButtonLink
                        href={listLink}
                        onClick={onClose}
                        buttonSize="small"
                        colorTheme="green"
                        className="mr10"
                    >
                        AUCTION LIST
                    </ButtonLink>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default SuccessfullyCreateQuote;
