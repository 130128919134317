import * as types from './actionTypes';

const initialState = {
    items: [],
};

function modalReducer(state = initialState, action) {
    switch (action.type) {
        case types.OPEN_MODAL:
            return {
                ...state,
                items: state.items.concat(action.payload),
            };
        case types.CLOSE_MODAL:
            return {
                ...state,
                items: state.items.filter((item) => item.id !== action.payload.id),
            };
        case types.CLOSE_ALL_MODALS:
            return {
                ...state,
                items: [],
            };

        default:
            return state;
    }
}

export default modalReducer;
