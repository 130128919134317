import React, {useState} from 'react';
import classNames from 'classnames';

import {PayPeriod} from 'core/entities/Settlement/types';
import {getPayPeriodCurrencyPayments} from 'core/entities/Settlement/settlementPayPeriod';
import {CurrencyValue} from 'utils/data/currency';

import Button from 'components/ui/Buttons/Button';
import Tooltip from 'components/ui/Tooltip';
import CurrencyAmount from 'components/ui/CurrencyAmount';

import PayPeriodTotalPays from 'pages/Settlements/components/common/PayPeriodTotalPays';

import {transformDistance} from 'utils/distance';

import styles from './payPeriodTotal.module.scss';

interface OwnProps {
    payPeriod: PayPeriod;
}

const PayCorrection = ({
    icon,
    amount,
    currency,
}: {
    icon: '+' | '-';
    amount: number;
    currency: CurrencyValue;
}): JSX.Element => {
    return (
        <div>
            <CurrencyAmount amount={amount} currency={currency} withStrong={false} /> <span>{icon}</span>
        </div>
    );
};

const PayPeriodTotal = ({payPeriod}: OwnProps): JSX.Element | null => {
    const [open, setOpen] = useState(false);
    const currencyPayments = getPayPeriodCurrencyPayments(payPeriod);
    const summaryClassName = classNames('summary-group', {open});

    const convertedTotalEmptyMiles = transformDistance(payPeriod.total_empty_miles);
    const convertedTotalMiles = transformDistance(payPeriod.total_miles);

    return (
        <div className={styles.item}>
            <div className={summaryClassName}>
                <div className="total-miles">
                    <div className="title">
                        total empty {convertedTotalEmptyMiles.longUnit}
                        <br />
                        total loaded {convertedTotalMiles.longUnit}
                    </div>
                    <div className="data">
                        {convertedTotalEmptyMiles.fromMiles} <br />
                        <strong>{convertedTotalMiles.fromMiles}</strong>
                    </div>
                </div>
                <div>
                    {currencyPayments.map((payment) => {
                        return (
                            <div key={payment.currency} className="total-payment">
                                <div className="title">
                                    gross pay
                                    <br />
                                    reimbursement
                                    <br />
                                    deductions
                                </div>
                                <div className="data">
                                    <div>
                                        <CurrencyAmount
                                            amount={payment.amount}
                                            currency={payment.currency}
                                            withStrong={false}
                                        />
                                    </div>
                                    <PayCorrection
                                        icon="+"
                                        amount={payment.reimbursements.totalAmount}
                                        currency={payment.currency}
                                    />
                                    <PayCorrection
                                        icon="-"
                                        amount={payment.deductions.totalAmount}
                                        currency={payment.currency}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="summary-total">
                <div className="summary-total__title">
                    <strong>Summary</strong>
                </div>
                <div className="summary-total__data">
                    <div className="title">Total Period Pay</div>
                    <div className="data">
                        <PayPeriodTotalPays payPeriod={payPeriod} />
                    </div>
                </div>
            </div>
            <div className="tooltip-expand">
                <Tooltip tooltipContent={open ? 'hide details' : 'view details'} typeContent="button" position="right">
                    <Button
                        buttonIcon={open ? 'angle-up' : 'angle-down'}
                        buttonSize="icon"
                        className="btn-link"
                        onClick={() => setOpen(!open)}
                    />
                </Tooltip>
            </div>
        </div>
    );
};

export default PayPeriodTotal;
