import React, {useMemo, useCallback} from 'react';
import {connect} from 'react-redux';
import {WrappedFieldProps} from 'redux-form';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';

import StoredFile from 'types/File';
import getFilePath from 'utils/files/getFilePath';
import Button from 'components/ui/Buttons/Button';
import {getUploadedFileUrl} from 'components/ui/Files/FileUpload/utils';
import withFormActionCreators from 'components/ui/Files/FileUpload/hocs/withFormActionCreators';
import {ACCEPTED_IMAGE_TYPES} from 'components/ui/Files/FileUpload/constants';
import {FormActionCreators} from 'components/ui/Files/FileUpload/types';
import {
    mapExistingFilesFromFormState,
    FormFileProps,
} from 'components/ui/Files/FileUpload/utils/mapExistingFilesFromFormState';

import emptyImagePlaceholder from 'components/ui/Files/FileUpload/components/ProfilePhotoInput/default_photo.png';
import styles from 'components/ui/Files/FileUpload/components/ProfilePhotoInput/profilePhoto.module.scss';

function getExistingProfileImage(
    existingFiles?: StoredFile[],
    fileIDsToBeRemoved: number[] = [],
): StoredFile | undefined {
    if (!existingFiles || isEmpty(existingFiles)) {
        return;
    }

    const existingImage: any = head(existingFiles);
    if (fileIDsToBeRemoved.includes(existingImage?.id)) {
        return;
    }
    return existingImage;
}

interface OwnProps {
    defaultBadge?: string;
}

const ProfilePhotoInput = (props: OwnProps & WrappedFieldProps & FormFileProps & FormActionCreators): JSX.Element => {
    const {
        input: {value: inputValue, onChange},
        existingFiles,
        fileIDsToBeRemoved,
        pushFileToDeleteList,
        defaultBadge,
    } = props;
    const newImage: any = inputValue && head(inputValue);
    const existingImage = useMemo(
        function (): StoredFile | undefined {
            return getExistingProfileImage(existingFiles, fileIDsToBeRemoved);
        },
        [fileIDsToBeRemoved],
    );
    function getImageSrc(): string {
        if (newImage) {
            return getUploadedFileUrl(newImage?.file);
        }
        if (existingImage) {
            return getFilePath(existingImage);
        }

        return emptyImagePlaceholder;
    }
    const imageSrc = useMemo(getImageSrc, [newImage, existingImage]);
    const handleChange = useCallback(
        function (event: React.FormEvent<HTMLInputElement>): void {
            if (isEmpty(event.currentTarget.files)) {
                return;
            }
            if (existingImage) {
                pushFileToDeleteList(existingImage);
            }
            const file = head(event.currentTarget.files);
            onChange([{file, badge: defaultBadge}]);
        },
        [existingImage],
    );
    const handleDelete = useCallback(
        function (): void {
            if (existingImage) {
                pushFileToDeleteList(existingImage);
            }
            onChange('');
        },
        [existingImage],
    );
    return (
        <div className={styles.block}>
            <div className="image-block-inner">
                <img className="img-circle" alt="profile photo" src={imageSrc} />
                <div>
                    <Button colorTheme="white" buttonSize="small" buttonIcon="refresh" className="button-file-input">
                        {newImage || existingImage ? 'CHANGE' : 'UPLOAD'}
                        <input type="file" accept={ACCEPTED_IMAGE_TYPES.join(', ')} onChange={handleChange} />
                    </Button>
                    {(newImage || existingImage) && (
                        <Button
                            colorTheme="white"
                            buttonSize="small"
                            buttonIcon="trash"
                            className="button-img-upload button-file-input"
                            onClick={handleDelete}
                        >
                            &nbsp;DELETE
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default connect(mapExistingFilesFromFormState)(withFormActionCreators(ProfilePhotoInput));
