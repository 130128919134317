import orderBy from 'lodash/orderBy';

import GeneralNote from 'types/GeneralNote';
import {NewNote} from 'types/NewNote';

const transferNewNotesToOldInterface = (notes: NewNote[] | undefined): GeneralNote[] => {
    if (!notes) {
        return [];
    }

    const sortByDateTime = (generalNotes: GeneralNote[]): GeneralNote[] =>
        orderBy(generalNotes, (noteA) => noteA.post_date, ['desc']);

    const transferredNotes = notes.map(
        (note): GeneralNote => ({
            dispatcher: note?.createdBy || {},
            is_important: note.isImportant,
            post_date: note.createdAt,
            entity_id: note.id,
            text: note.note,
            entity_name: '',
            id: note.id,
        }),
    );

    return sortByDateTime(transferredNotes);
};

export default transferNewNotesToOldInterface;
