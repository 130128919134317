export const TRAVEL_ORDER_TRUCK_STATES = {
    DISPATCHED_NOT_CONFIRMED: 'dispatched-not-confirmed',
    DELIVERY_WAITING: 'delivery-waiting',
    PICK_UP_WAITING: 'pick-up-waiting',
    RE_DISPATCHED: 're-dispatched',
    COMPLETED: 'completed',
    DELIVERY: 'delivery',
    ON_ROUTE: 'on-route',
    PLANNED: 'planned',
    PICK_UP: 'pick-up',
    DRY_RUN: 'dryrun',
    HOLD: 'hold',
} as const;
