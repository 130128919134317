import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button';

import Form from './Form';
import styles from './addTruckLocation.module.scss';

interface OwnProps {
    onClose();
    handlers: {
        onSubmit();
    };
}

const AddTruckLocationModal = (props: OwnProps): JSX.Element => {
    const {onSubmit} = props.handlers;
    return (
        <div className="standard-form-modal">
            <Modal show={true} dialogClassName={styles.modal} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Error</Modal.Title>
                    <Button onClick={props.onClose} buttonSize="icon" className="custom-design close-button">
                        <i className="fa fa-times" aria-hidden="true" />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form onClose={props.onClose} onSubmit={onSubmit} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddTruckLocationModal;
