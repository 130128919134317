import React from 'react';

import type Invoice from 'core/entities/Invoice/types';

import useInvoiceActions from 'pages/Invoices/hooks/useInvoiceActions';

import SortingIcon from 'components/ui/SortingIcon';

import {DisplayInvoicesMode} from '../../types';

import SelectedItemsInfo from './components/SelectedItemsInfo';
import InvoiceRow from './components/InvoiceRow';

import styles from './styles.module.scss';

type InvoicesListProps = {
    invoices: Invoice[];
    displayMode: DisplayInvoicesMode;
    allInvoicesCount: number;
    selectedInvoices: Record<number, Invoice>;
    onSelectInvoice(invoice: Invoice);
    onSelectAllInvoices();
    onResetInvoices(invoices: Invoice[]);
};

const InvoicesList = (props: InvoicesListProps): JSX.Element => {
    const {
        invoices,
        displayMode,
        allInvoicesCount,
        selectedInvoices,
        onSelectInvoice,
        onSelectAllInvoices,
        onResetInvoices,
    } = props;
    const {openInvoicePDF} = useInvoiceActions();
    const selectedInvoicesArray = Object.values(selectedInvoices);
    const selectedInvoicesCount = Object.keys(selectedInvoices).length;
    const handleResetSelectedInvoices = () => {
        onResetInvoices(selectedInvoicesArray);
    };
    const showSelectingInvoices = displayMode === 'general';
    return (
        <div className={`standard-table-style ${styles.scroll}`}>
            {showSelectingInvoices && (
                <SelectedItemsInfo
                    allItemsCount={allInvoicesCount}
                    currentItemsCount={invoices.length}
                    selectedItemsCount={selectedInvoicesCount}
                    onSelectAll={onSelectAllInvoices}
                    onResetAll={handleResetSelectedInvoices}
                />
            )}

            <table className={`table ${styles.table}`}>
                <thead>
                    <tr>
                        <th>
                            <SortingIcon
                                sortBy="invoice_number"
                                sortType="numeric"
                                title="#"
                                sortListName="invoicesBatchesModal"
                            />
                        </th>
                        <th>DATE AND TIME</th>
                        <th>REF#</th>
                        <th>CHARGES</th>
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {invoices.map((i) => (
                        <InvoiceRow
                            key={i.id}
                            invoice={i}
                            displayMode={displayMode}
                            isSelected={i.id in selectedInvoices}
                            onOpenInvoicePDF={() => openInvoicePDF(i)}
                            onSelect={() => onSelectInvoice(i)}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default InvoicesList;
