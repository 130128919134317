import {fetchPayPeriodResponsibleUsers} from 'core/entities/Settlement/requests/settlementOwnerRequests';

import useFetchWithPaginate from 'hooks/useFetchWithPaginate';
import {PayPeriodResponsibleUser} from '../types';

interface FetchData {
    users: PayPeriodResponsibleUser[];
    error: string | undefined;
    currentPage: number | undefined;
    isFetching: boolean;
    isAllFetched: boolean;
    fetchNextPage();
}

const PER_PAGE = 10;

const fetchPeriodResponsibleUsers = (
    payPeriodID: number,
    {page, perPage}: {page: string; perPage: number},
): Promise<PayPeriodResponsibleUser[]> => {
    const nextPage = parseInt(page, 10) + 1;
    return fetchPayPeriodResponsibleUsers(payPeriodID, {page: nextPage, perPage})
        .then((response) => response.data)
        .catch((err) => {
            console.error('Error on fetch pay period responsible users: ', err);
            return [];
        });
};

const cacheCreator = ({payPeriodID}: {payPeriodID: number}) => {
    return (pageIndex: any, previousPageData: any) => {
        if (previousPageData && previousPageData.length === 0) {
            return null;
        }
        const pageNumber = String(pageIndex);
        const cacheKey = `pay-period-${payPeriodID}-responsible-users-${pageNumber}`;
        return [cacheKey, pageNumber];
    };
};

const fetcherCreator = ({payPeriodID}: {payPeriodID: number}) => {
    return (...args): any => {
        const [_, pageNumber] = args;
        return fetchPeriodResponsibleUsers(payPeriodID, {page: pageNumber, perPage: PER_PAGE});
    };
};

export default ({payPeriodID}: {payPeriodID: number}): FetchData => {
    const getCacheKey = cacheCreator({payPeriodID});
    const fetcher = fetcherCreator({payPeriodID});
    const {data: users, error, currentPage, isFetching, isAllFetched, fetchNextPage} = useFetchWithPaginate({
        perPage: PER_PAGE,
        getKey: getCacheKey,
        fetchData: fetcher,
    });
    return {
        users,
        error,
        currentPage,
        isFetching,
        isAllFetched,
        fetchNextPage,
    };
};
