import restApi from 'services/restapi';

const settlementsUrl = '/settlements';
const settlementsCarriersUrl = `${settlementsUrl}-carriers`;
/**
 * @param {number} settlementNumber
 * @return {Promise}
 */
export const fetchSettlementCarrier = (settlementNumber) => {
    return restApi.get(`${settlementsCarriersUrl}/${settlementNumber}`);
};
export const updateSettlementCarrierData = (settlementID, data) => {
    return restApi.put(`${settlementsCarriersUrl}/${settlementID}/update-data`, data);
};
/**
 * @param {Object} params
 * @return {Promise}
 */
export const fetchSettlementsCarriersList = (params) => {
    const searchParams = {...params, carrier: params?.carrier?.value};
    return restApi.post(`${settlementsCarriersUrl}/search`, searchParams);
};
/**
 * @param {Object} settlement
 * @param {string} nextStatus
 * @return {Promise}
 */
export const setReadyToPostSettlementCarrierStatus = (settlement) => {
    return restApi.put(`${settlementsCarriersUrl}/${settlement.id}/ready-to-post`);
};
/**
 * @param {Object} settlement
 * @return {Promise}
 */
export const resetSettlementCarrierFromView = (settlement) => {
    return restApi.put(`${settlementsCarriersUrl}/${settlement.id}/reset-from-view`);
};
/**
 * @param {Object} settlement
 * @return {Promise}
 */
export const resetSettlementCarrierFromList = (settlement) => {
    return restApi.put(`${settlementsCarriersUrl}/${settlement.id}/reset-from-list`);
};
/**
 * @param {Object} params
 * @return {Promise}
 */
export const createSettlementBatch = (params = {}) => {
    return restApi.post(`${settlementsCarriersUrl}/batches`, params);
};
/**
 * @param {Number} batchID
 * @param {Object} params
 * @return {Promise}
 */
export const updateSettlementBatch = (batchID, params = {}) => {
    return restApi.put(`${settlementsCarriersUrl}/batches/${batchID}`, params);
};
/**
 * @param {Number} batchID
 * @return {Promise}
 */
export const closeSettlementBatch = (batchID) => {
    return restApi.put(`${settlementsCarriersUrl}/batches/${batchID}/close`);
};
/**
 * @param {Number} batchID
 * @return {Promise}
 */
export const reopenSettlementBatch = (batchID) => {
    return restApi.put(`${settlementsCarriersUrl}/batches/${batchID}/reopen`);
};
/**
 * @param {Number} batchID
 * @param {String} emailNote
 * @return {Promise}
 */
export const addSettlementBatchEmailNote = (batchID, emailNote) => {
    return restApi.put(`${settlementsCarriersUrl}/batches/${batchID}/add-note`, {note_to_email: emailNote});
};
/**
 * @param {Number} batchID
 * @param {Object} params
 * @return {Promise}
 */
export const sendSettlementBatchEmail = (batchID, params) => {
    return restApi.post(`${settlementsCarriersUrl}/batches/${batchID}/send-email`, params);
};
/**
 * @param {Object} params
 * @return {Promise}
 */
export const fetchAllBatches = (params = {}) => {
    const searchParams = {...params, carrier: params?.carrier?.value};
    return restApi.post(`${settlementsCarriersUrl}/batches/totals`, searchParams);
};
/**
 * @param {Number} carrierID
 * @return {Promise}
 */

export const fetchBatchNotPostedSettlements = (carrierID, pagination) => {
    const {page, perPage} = pagination;
    return restApi.get(`${settlementsCarriersUrl}/carrier/${carrierID}/not-posted`, {
        params: {page, 'per-page': perPage},
    });
};

/**
 * @param {Number} carrierID
 * @return {Promise}
 */
export const fetchCarrierBatches = (carrierID) => {
    return restApi.get(`${settlementsCarriersUrl}/carrier/${carrierID}/batches`);
};

export const fetchBatchSettlementsCarrier = (batchId, pagination, searchParams = {}) => {
    const {page, perPage} = pagination;
    const params = {...searchParams, carrier: searchParams?.carrier?.value};
    return restApi.get(`${settlementsCarriersUrl}/batches/${batchId}/posted`, {
        params: {page, 'per-page': perPage, ...params},
    });
};

export const fetchBatchesForSetupForm = () => {
    return restApi.get(`${settlementsCarriersUrl}/batches/form`);
};

export const fetchBatchResponsibleUsersRequest = (carrierID, pagination) => {
    const {page, perPage} = pagination;
    return restApi.get(`${settlementsCarriersUrl}/carriers/${carrierID}/responsible/form`, {
        params: {page, 'per-page': perPage},
    });
};

export const fetchResponsibleUserSettlements = (carrierID, userID, pagination) => {
    const {page, perPage} = pagination;
    return restApi.get(`${settlementsCarriersUrl}/carriers/${carrierID}/responsible/${userID}/form`, {
        params: {page, 'per-page': perPage},
    });
};

export const deleteSettlementCarrier = (settlement) => restApi.delete(`${settlementsCarriersUrl}/${settlement.id}`);
