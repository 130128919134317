import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type OwnProps = {
    colorBadge?: 'green' | 'light-green' | 'yellow' | 'blue' | 'red' | 'gray';
    isUpperCase?: boolean;
    isLargeSize?: boolean;
    hasBorder?: boolean;
    text: string;
};

const InfoBadge: React.FC<OwnProps> = (props) => {
    const {text, colorBadge = 'gray', hasBorder = false, isLargeSize = false, isUpperCase = false} = props;

    const infoBadgeClassNames = classNames(styles.wrap, styles[colorBadge], {
        [styles.border]: hasBorder,
        [styles.large]: isLargeSize,
        'text-uppercase': isUpperCase,
    });

    return <div className={infoBadgeClassNames}>{text}</div>;
};

export default InfoBadge;
