import React, {useEffect} from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';

import {transferTOFileToNewFileInterface} from 'core/entities/TravelOrder/modules/travelOrderAttachments';
import {GetBrokerUpdateDataFromRequest} from 'core/entities/LocationEvents/types';
import {LocationEventsTypes} from 'core/entities/LocationEvents/constants';
import {SENDER_TYPE_UPDATE} from 'core/entities/Sender/constants';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import SendersSelectByType from 'components/common/Sender/SendersSelectByType';
import TextInputBlockable from 'components/ui/Form/TextInputBlockable';
import EmailsSelect from 'components/ui/Form/EmailsSelect';
import FilePicker from 'components/ui/Files/FilePicker';
import TextArea from 'components/ui/Form/TextArea';
import Button from 'components/ui/Buttons/Button';
import Select from 'components/ui/Form/Select';

import {GroupedFilesTravelOrder} from 'types/File';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import {BrokerUpdateSendFormValues} from '../../types';
import {getListEmails} from '../../utils';
import validation from './validation';

import styles from './styles.module.scss';

type OwnProps = {
    updateTypeOptions: GetBrokerUpdateDataFromRequest['updateTypeOptions'];
    updateTypeMessages: Record<LocationEventsTypes, string[]>;
    files: GroupedFilesTravelOrder;
    brokerEmailsData: string[];
    emailFrom?: string | null;
    onClose: () => void;
};

const BROKER_UPDATE_SEND_FORM_NAME = 'broker-update-send';
const getName = getTypeFieldNameFactory<BrokerUpdateSendFormValues>();

const BrokerUpdateSendForm: React.FC<InjectedFormProps<{}, OwnProps> & OwnProps> = (props) => {
    const {
        updateTypeMessages,
        updateTypeOptions,
        brokerEmailsData,
        handleSubmit,
        emailFrom,
        onClose,
        files,
        form,
    } = props;

    const emailsTextForCopy = useGetReduxFormValue(form, getName('emailsTo'));
    const messageTextForCopy = useGetReduxFormValue(form, getName('message'));
    const updateType = useGetReduxFormValue(form, getName('updateType'));
    const changeFormValue = useChangeReduxFormValue(form);

    const transferredAttachments = mapValues(files, (items) =>
        items.map((file) => transferTOFileToNewFileInterface({file})),
    );

    const brokerEmailsOptions = getListEmails(brokerEmailsData);

    useEffect(() => {
        changeFormValue(getName('message'), updateTypeMessages[updateType]?.join('\n'));
    }, [updateType]);

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.content}>
                <div className={styles.from}>
                    <Row>
                        <Col md={7}>
                            <Field
                                name={getName('emailSenderId')}
                                placeholder="type one or more emails"
                                component={SendersSelectByType}
                                senderType={SENDER_TYPE_UPDATE}
                                emailFrom={emailFrom}
                                label="From"
                            />
                        </Col>
                    </Row>
                </div>
                <div className={styles.to}>
                    <Field
                        name={getName('emailsTo')}
                        component={EmailsSelect}
                        placeholder="type one or more emails"
                        label="To"
                        data={brokerEmailsOptions}
                        withCustomMultiValueContainer={false}
                    />
                    <CopyToClipboard text={emailsTextForCopy}>
                        <Button colorTheme="grey" buttonSize="icon" buttonIcon="files-o" />
                    </CopyToClipboard>
                </div>
                <div className={styles.subject}>
                    <Field
                        name={getName('subject')}
                        type="text"
                        component={TextInputBlockable}
                        label="Subject"
                        disabled
                    />
                </div>
                <div className={styles.typeUpdate}>
                    <Row>
                        <Col md={7}>
                            <Field
                                label="update Type"
                                name={getName('updateType')}
                                data={updateTypeOptions}
                                component={Select}
                                isClearable={false}
                            />
                        </Col>
                    </Row>
                </div>
                <div className={styles.message}>
                    <Field
                        name={getName('message')}
                        type="text"
                        maxLength="750"
                        component={TextArea}
                        rows="9"
                        label="Message"
                        className={styles.scroll}
                    />
                    <CopyToClipboard text={messageTextForCopy}>
                        <Button colorTheme="grey" buttonIcon="files-o" buttonSize="icon" />
                    </CopyToClipboard>
                </div>
                {!isEmpty(files) ? (
                    <div className={styles.attachments}>
                        <Field name={getName('attachments')} component={FilePicker} files={transferredAttachments} />
                    </div>
                ) : null}
            </div>

            <div className="modal-body__bottom">
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" colorTheme="blue">
                    Send to Broker
                </Button>
            </div>
        </form>
    );
};

export default reduxForm<{}, OwnProps>({
    form: BROKER_UPDATE_SEND_FORM_NAME,
    validate: validation,
})(BrokerUpdateSendForm);
