import isString from 'lodash/isString';
import isArray from 'lodash/isArray';

import {APP_BAD_REQUEST_ERROR_STATUS} from 'services/restapi/constants';

import {openChangeStatusFailedModal, openResetInvoiceFailedModal, openGenerationFailedModal} from './modalActions';

const getErrorWithTextMessage = (error, message) => {
    const errorMessage = isString(error.data) ? error.data : error.data?.message;
    return errorMessage && errorMessage.includes(message);
};

const getErrorTextMessage = (error) => {
    return isString(error.data) ? error.data : error.data?.message;
};

export const handleInvoiceGenerateError = (error, dispatch) => {
    const errorStatus = error.status;
    const errorFiles = error.data?.files;
    const isIncorrectFilesOnGenerateInvoice = errorStatus === APP_BAD_REQUEST_ERROR_STATUS && isArray(errorFiles);
    const isInvoiceGeneratedError = getErrorWithTextMessage(error, 'Invoice already generated');
    if (isIncorrectFilesOnGenerateInvoice) {
        dispatch(openGenerationFailedModal(errorFiles));
    }
    if (isInvoiceGeneratedError) {
        dispatch(openChangeStatusFailedModal());
    }
    return Promise.reject(error);
};

export const handleChangeInvoiceStatusError = (error, dispatch) => {
    const isInvoicePaymentReceived = getErrorWithTextMessage(error, 'Can not reset invoice, payment was been received');
    const isInvoiceAlreadyInStatus = getErrorWithTextMessage(error, 'Invoice already in status:');
    const isInvoiceInStatus = getErrorWithTextMessage(error, 'Invoice in status:');
    const isInvoiceStatusCanNotBeGenerated = getErrorWithTextMessage(error, "Can't generate Invoice in status");
    const isInvoiceStatusCanNotBeChanged = getErrorWithTextMessage(error, "Can't change the invoice status.");
    const isInvoiceNotFoundInBatch = getErrorWithTextMessage(error, 'Invoice not found in batch');
    const isBatchNotFoundForInvoice = getErrorWithTextMessage(error, 'Batch for invoice with');
    const isInvoiceAlreadyResetFromBatch =
        getErrorWithTextMessage(error, 'Invoice is already reset') ||
        getErrorWithTextMessage(error, 'There is no invoice with ID');
    const isInvoicePdfNotExists = getErrorWithTextMessage(error, 'pdf does not exist on server');
    const isInvoiceInappropriateStatus = getErrorWithTextMessage(error, ' only. Current status is ');

    if (isInvoicePaymentReceived) {
        dispatch(openResetInvoiceFailedModal());
    } else if (isInvoiceAlreadyResetFromBatch) {
        const message =
            'The action can not be completed because some invoices were reset by another user. Please reload page.';
        dispatch(openChangeStatusFailedModal(message));
    } else if (
        isInvoiceAlreadyInStatus ||
        isInvoiceInStatus ||
        isInvoiceStatusCanNotBeGenerated ||
        isInvoiceStatusCanNotBeChanged ||
        isInvoiceNotFoundInBatch ||
        isBatchNotFoundForInvoice ||
        isInvoiceInappropriateStatus ||
        isInvoicePdfNotExists
    ) {
        const message = getErrorTextMessage(error);
        dispatch(openChangeStatusFailedModal(message));
    }
    return Promise.reject(error);
};

export const handleChangeBatchStatusError = (error, dispatch) => {
    const isStatusChangeError = getErrorWithTextMessage(error, 'Batch already in status:');
    const isInvoiceInappropriateStatus = getErrorWithTextMessage(error, ' only. Current status is ');

    if (isStatusChangeError) {
        dispatch(openChangeStatusFailedModal());
    } else if (isInvoiceInappropriateStatus) {
        const message = getErrorTextMessage(error);
        dispatch(openChangeStatusFailedModal(message));
    }

    return Promise.reject(error);
};

export const handleCreteBatchError = (error, dispatch) => {
    const isIncorrectInvoice = getErrorWithTextMessage(error, 'Batch create error! Invoice');
    const isInvoiceInappropriateStatus = getErrorWithTextMessage(error, ' only. Current status is ');

    if (isIncorrectInvoice) {
        dispatch(openChangeStatusFailedModal());
    } else if (isInvoiceInappropriateStatus) {
        const message = getErrorTextMessage(error);
        dispatch(openChangeStatusFailedModal(message));
    }

    return Promise.reject(error);
};

export const handleUpdateBatchError = (error, dispatch) => {
    const isIncorrectInvoice = getErrorWithTextMessage(error, 'Batch update error! Invoice');
    const isBatchAlreadyInPostedStatus = getErrorWithTextMessage(
        error,
        'Expected a value equal to "Ready to Post". Got: "Posted"',
    );
    const isInvoiceInappropriateStatus = getErrorWithTextMessage(error, ' only. Current status is ');

    if (isIncorrectInvoice) {
        dispatch(openChangeStatusFailedModal());
    } else if (isBatchAlreadyInPostedStatus) {
        dispatch(openChangeStatusFailedModal('Batch has Posted status. Please reload page'));
    } else if (isInvoiceInappropriateStatus) {
        const message = getErrorTextMessage(error);
        dispatch(openChangeStatusFailedModal(message));
    }
    return Promise.reject(error);
};
