import React from 'react';

import Truck from 'core/entities/Truck/types';

import ArchivingErrorWrapper from 'components/ui/modals/ArchivingErrorWrapper';

type OwnProps = {
    data: {truck: Truck};
    onClose();
};

const ArchivingError = (props: OwnProps): JSX.Element => {
    const {
        data: {truck},
        onClose,
    } = props;
    const truckNumber = `truck #${truck.number}`;
    return (
        <ArchivingErrorWrapper title={truckNumber} onClose={onClose}>
            <div>
                <p>
                    The <strong>{truckNumber}</strong> is <strong>on route</strong> now. Moving the truck to the archive
                    is not possible. Wait for the truck to complete the travel order.
                </p>
            </div>
        </ArchivingErrorWrapper>
    );
};

export default ArchivingError;
