import {WEB_SOCKET_OWNERS_TRUCKS_COORDINATES_RECEIVED, WEB_SOCKET_OWNERS_TRUCKS_UPDATE_RECEIVED} from './actionTypes';

export function ownersTrucksUpdateReceived(message) {
    return {
        type: WEB_SOCKET_OWNERS_TRUCKS_UPDATE_RECEIVED,
        payload: message,
    };
}

export function ownersTrucksCoordinatesReceived(message) {
    return {
        type: WEB_SOCKET_OWNERS_TRUCKS_COORDINATES_RECEIVED,
        payload: message,
    };
}
