import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

import {ListState} from 'pages/FullAlliance/redux/reducers/list';
import {FullAllianceSearchFormValues} from 'pages/FullAlliance/types/formTypes';

import countries from 'utils/data/countries';
import {getTypeFieldNameFactory} from 'utils/typeScript';

import {PlaceAddress} from 'types/Address';

const getName = getTypeFieldNameFactory<FullAllianceSearchFormValues>();

export const buildCityLineFromAddress = (address?: PlaceAddress['address'] | null) => {
    if (!address) {
        return null;
    }

    const countriesMap = {
        [countries.US.name.toLowerCase()]: 'US',
        [countries.CA.name.toLowerCase()]: 'CA',
        [countries.MX.name.toLowerCase()]: 'MX',
    };

    const {city, state, zip, country} = address;

    const formattedCountry = countriesMap[country] || country;
    const formattedZip = zip || 'unknown zip code';

    return `${city}, ${state}, ${formattedZip}, ${formattedCountry}`;
};

export const handleReducerBySetSearchParams = (params: {
    state: ListState;
    formData: Partial<FullAllianceSearchFormValues>;
}) => {
    const {state, formData} = params;

    const destinationPoint = buildCityLineFromAddress(state?.locationPoints?.destination?.address);
    const originPoint = buildCityLineFromAddress(state?.locationPoints?.origin?.address);

    const searchParams = {
        ...formData,

        destinationPoint,
        originPoint,
    };

    // We create new object to make a certain order to display search result
    const orderedSearchParams = pick(
        searchParams,
        getName('originPoint'),
        getName('radius'),
        getName('destinationPoint'),
        getName('isReefer'),
        getName('type'),
        getName('trailerType'),
        getName('isTeam'),
        getName('statusDateFrom'),
        getName('statusDateTo'),
        getName('status'),
        getName('isReserved'),
        getName('reservedBy'),
        getName('dimsUnit'),
        getName('dimLength'),
        getName('dimWidth'),
        getName('dimHeight'),
        getName('weightsUnit'),
        getName('payload'),
        getName('gross'),
        getName('yearFrom'),
        getName('equipment'),
        getName('company'),
        getName('owner'),
        getName('driver'),
        getName('citizenship'),
        getName('temperatureRangeMin'),
        getName('temperatureRangeMax'),
        getName('temperatureDegreesUnit'),
        getName('doorType'),
        getName('crossBorder'),
        getName('certificates'),
        getName('withCompanySigns'),
        getName('preferredLoads'),
    );

    return {
        ...state,
        searchParams: omitBy(orderedSearchParams, (value) => isNull(value) || isUndefined(value)),
    };
};
