import Carrier from 'core/entities/Carrier/types';

export const getCarrierPaymentCountry = (carrier?: Carrier | null) => carrier?.paymentSettings?.location?.country;

export const getCarrierPaymentState = (carrier?: Carrier | null) => carrier?.paymentSettings?.location?.state;

export const getCarrierPaymentCity = (carrier?: Carrier | null) => carrier?.paymentSettings?.location?.city;

export const getCarrierPaymentZip = (carrier?: Carrier | null) => carrier?.paymentSettings?.location?.zip;

export const getCarrierPaymentAddressLine = (carrier?: Carrier | null) => carrier?.paymentSettings?.addressLine1;

export const getCarrierPaymentAdditionalAddressLine = (carrier?: Carrier | null) =>
    carrier?.paymentSettings?.addressLine2;

type CarrierPaymentAddressAllInfo = {
    carrierPaymentAdditionalAddressLine: ReturnType<typeof getCarrierPaymentAdditionalAddressLine>;
    carrierPaymentAddressLine: ReturnType<typeof getCarrierPaymentAddressLine>;
    carrierPaymentCountry: ReturnType<typeof getCarrierPaymentCountry>;
    carrierPaymentState: ReturnType<typeof getCarrierPaymentState>;
    carrierPaymentCity: ReturnType<typeof getCarrierPaymentCity>;
    carrierPaymentZip: ReturnType<typeof getCarrierPaymentZip>;
};

export const getCarrierPaymentAddressAllInfo = (carrier?: Carrier | null): CarrierPaymentAddressAllInfo => {
    return {
        carrierPaymentAdditionalAddressLine: getCarrierPaymentAdditionalAddressLine(carrier),
        carrierPaymentAddressLine: getCarrierPaymentAddressLine(carrier),
        carrierPaymentCountry: getCarrierPaymentCountry(carrier),
        carrierPaymentState: getCarrierPaymentState(carrier),
        carrierPaymentCity: getCarrierPaymentCity(carrier),
        carrierPaymentZip: getCarrierPaymentZip(carrier),
    };
};
