export const STOP_STATUSES = {
    IN_PROGRESS: 'in progress',
    COMPLETED: 'completed',
    PLANNED: 'planned',
} as const;

export const STOP_META_STATUSES = {
    ON_ROUTE_TO: 'on route to',
    NOT_STARTED: 'not started',
    COMPLETED: 'completed',
    ARRIVED: 'arrived',
} as const;
