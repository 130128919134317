import gte from 'lodash/gte';

import {convertSizeInBytesTo} from 'utils/files/formatFileSize';

import {NormalizedFile} from 'widgets/Chat/types';

const LIMIT_50_MB = 50;

export const calculateAttachmentsSizeValidation = (params: {files: ({file: File | NormalizedFile} | undefined)[]}) => {
    const {files} = params;

    const sizes = (files || []).map((file) => file?.file?.size || 0).filter(Boolean);
    const isSomeFileGte50MB = sizes.some((size) => gte(convertSizeInBytesTo(size, 'MB'), LIMIT_50_MB));

    return {isSomeFileGte50MB};
};

export const checkAttachmentsTypeValidation = (params: {files: ({file: File | NormalizedFile} | undefined)[]}) => {
    const {files} = params;

    const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'];
    const ACCEPTED_FILE_TYPES = ['application/pdf', 'text/plain'];

    const isSomeAttachmentTypeUnsupported = (files || []).filter(Boolean).some((file) => {
        return ![...ACCEPTED_IMAGE_TYPES, ...ACCEPTED_FILE_TYPES].some((type) => type === file?.file?.type);
    });

    return {isSomeAttachmentTypeUnsupported};
};
