import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';

export type NormalizedData<Entity extends {id: number | string}> = {
    byId: Record<number | string, Entity>;
    allIds: Entity['id'][];
};

/*
*
* export const addItemToTable = (table, newItem, {toBegin = false} = {}) => {
    const allIds = toBegin ? [newItem.id, ...table.allIds] : [...table.allIds, newItem.id];
    return {
        byId: {...table.byId, [newItem.id]: newItem},
        allIds,
    };
};

export const addItemsToTable = (table, newItems) => {
    const newItemsMap = keyBy(newItems, 'id');
    const newItemsIds = newItems.map((i) => i.id);
    return {
        byId: {...table.byId, ...newItemsMap},
        allIds: [...table.allIds, ...newItemsIds],
    };
};

export const updateItemInTable = (table, newItem) => {
    return {
        byId: {...table.byId, [newItem.id]: newItem},
        allIds: table.allIds,
    };
};

export const updateItemsInTable = (table, items) => {
    const map = keyBy(items, 'id');
    return {
        byId: {...table.byId, ...map},
        allIds: table.allIds,
    };
};

export const removeItemsFromTable = (table, items) => {
    const idsForRemove = items.map((i) => i.id);
    return {
        byId: omit(table.byId, idsForRemove),
        allIds: table.allIds.filter((currentID) => !idsForRemove.includes(currentID)),
    };
};

export const removeItemInTable = (table, tableItem) => {
    return {
        byId: omit(table.byId, [tableItem.id]),
        allIds: table.allIds.filter((id) => id !== tableItem.id),
    };
};
*
*
*
*
* */

export const getData = <Entity extends {id: number | string}>(items: Entity[]): NormalizedData<Entity> => {
    const idField: keyof Entity = 'id';
    const itemsMap = keyBy(items, idField);
    const itemsIds = items.map((i) => i.id);
    return {
        byId: itemsMap,
        allIds: itemsIds,
    };
};

type AddOptions = {
    insertToBegin: boolean;
};
export const addItem = <Entity extends {id: number | string}>(
    items: NormalizedData<Entity>,
    newItem: Entity,
    options?: AddOptions,
): NormalizedData<Entity> => {
    const allIds = options?.insertToBegin ? [newItem.id, ...items.allIds] : [...items.allIds, newItem.id];
    return {
        byId: {...items.byId, [newItem.id]: newItem},
        allIds,
    };
};

export const addListItems = <Entity extends {id: number | string}>(
    table: NormalizedData<Entity>,
    newItems: Entity[],
): NormalizedData<Entity> => {
    const newItemsMap = keyBy(newItems, 'id');
    const newItemsIds = newItems.map((i) => i.id);
    const uniqIds = uniq([...table.allIds, ...newItemsIds]);
    return {
        byId: {...table.byId, ...newItemsMap},
        allIds: uniqIds,
    };
};

export const updateItem = <Entity extends {id: number | string}>(
    items: NormalizedData<Entity>,
    item: Entity,
): NormalizedData<Entity> => {
    return {
        byId: {...items.byId, [item.id]: item},
        allIds: items.allIds,
    };
};

export const removeItem = <Entity extends {id: number | string}>(
    items: NormalizedData<Entity>,
    item: Entity,
): NormalizedData<Entity> => {
    return {
        byId: omit(items.byId, [item.id]),
        allIds: items.allIds.filter((id) => id !== item.id),
    };
};

export const getItemValueById = <Entity extends {id: number | string}>(
    items: NormalizedData<Entity>,
    id: string | number,
): Entity | undefined => {
    return items.byId[id];
};

export const updateListItems = () => {};

export const removeListItems = () => {};
