import React, {useState, useMemo, useCallback, ReactNodeArray} from 'react';
import {WrappedFieldProps} from 'redux-form';
import {useDispatch} from 'react-redux';
import union from 'lodash/union';

import imageGalleryActions from 'store/reducers/gallery/actionCreators';

import {FileNew as StoredFile, GroupedFiles} from 'types/File';

import Button from 'components/ui/Buttons/Button';
import {NormalizedFile} from 'components/ui/Files/FileUpload/types';
import SearchInput from 'components/ui/Files/FileUpload/components/FilesList/SearchInput';
import {normalizeFile} from 'components/ui/Files/FileUpload/utils';
import {getNextGroupStartingIndex} from 'components/ui/Files/FileUpload/components/FilesList/utils';

import flattenGroupedArrays from 'utils/flattenGroupedArrays';

import {
    checkIfFilesAreSelected,
    checkIfFileNameMatchesFilter,
    isPdfFile,
    openPdfFileInNewTab,
    getGroupedFilesCount,
} from './utils';
import FileSection from './components/FileSection';
import FilePreview from './components/FilePreview';

import styles from './styles.module.scss';

interface OwnProps {
    files: GroupedFiles;
}

const FilePicker = (props: WrappedFieldProps & OwnProps): JSX.Element => {
    const [filter, setFilter] = useState<any>();
    const [gridView, setGridView] = useState(true);
    const dispatch = useDispatch();
    const {input, files} = props;
    const {value, onChange}: {value: '' | number[]; onChange: (inputValue: number[]) => void} = input;

    const selectedFileIds = value || [];

    const galleryFiles = useMemo(
        function (): NormalizedFile[] {
            const flattenedFiles = flattenGroupedArrays<StoredFile>(files);
            return flattenedFiles.map((file: StoredFile): NormalizedFile => normalizeFile(file) as NormalizedFile);
        },
        [files],
    );

    const fileCount = useMemo(() => getGroupedFilesCount(files), [files]);

    const handlePreviewImage = useCallback((file, index) => {
        if (isPdfFile(file)) {
            openPdfFileInNewTab(file);
        } else {
            dispatch(imageGalleryActions.openGallery({files: galleryFiles, currentItemIndex: index}));
        }
    }, []);

    const handleSelect = useCallback(
        function handleSelect(fileId: number): void {
            if (!selectedFileIds) {
                return;
            }
            if (checkIfFilesAreSelected(selectedFileIds, fileId)) {
                onChange(selectedFileIds.filter((selectedId: number) => selectedId !== fileId));
                return;
            }
            onChange([...selectedFileIds, fileId]);
        },
        [selectedFileIds],
    );
    const handleSearch = useCallback(function (event: React.FormEvent<HTMLInputElement>): void {
        setFilter(event.currentTarget.value);
    }, []);
    function handleSelectAllInSection(fileIds: number[]): void {
        if (checkIfFilesAreSelected(selectedFileIds, ...fileIds)) {
            onChange(selectedFileIds.filter((id: number): boolean => !fileIds.includes(id)));
            return;
        }
        onChange(union(selectedFileIds, fileIds));
    }
    function mapFilesPreviews(groupFiles: StoredFile[], startingIndex: number): ReactNodeArray {
        return groupFiles.map((file: StoredFile, fileIndex) => {
            if (!checkIfFileNameMatchesFilter(file.displayName, filter)) {
                return;
            }
            const {id, dispatcher} = file;
            const isFileSelected = checkIfFilesAreSelected(selectedFileIds, id);
            const galleryIndex: number = startingIndex + fileIndex;
            return (
                <FilePreview
                    key={id}
                    file={file}
                    gridView={gridView}
                    isSelected={isFileSelected}
                    postedBy={dispatcher}
                    onSelect={() => handleSelect(id)}
                    onView={() => handlePreviewImage(file, galleryIndex)}
                />
            );
        });
    }
    function mapSections(): ReactNodeArray {
        if (!files) {
            return [];
        }
        return Object.entries(files).map(
            ([groupName, groupFiles]: [string, StoredFile[]], groupIndex: number, groupedFiles): JSX.Element => {
                const nextGroupStartingIndex = getNextGroupStartingIndex<StoredFile>(groupedFiles, groupIndex);
                const filesPreviewNodes = mapFilesPreviews(groupFiles, nextGroupStartingIndex);
                const groupFileIds = groupFiles.map((file: StoredFile): number => file.id);
                const allSelected = checkIfFilesAreSelected(selectedFileIds, ...groupFileIds);
                return (
                    <FileSection
                        key={groupName}
                        title={groupName}
                        allSelected={allSelected}
                        gridView={gridView}
                        fileCount={groupFiles.length}
                        onSelectAll={() => handleSelectAllInSection(groupFileIds)}
                    >
                        {filesPreviewNodes}
                    </FileSection>
                );
            },
        );
    }

    const fileSectionNodes: ReactNodeArray = useMemo(mapSections, [selectedFileIds, filter, gridView]);

    return (
        <div className={styles.block}>
            <div className="attach-file">
                <div className="attach-file__header">
                    <p>
                        <strong>
                            Attached {selectedFileIds.length} / {fileCount} file(s)
                        </strong>
                    </p>
                    <div className="control-outer">
                        <SearchInput handleChange={handleSearch} />
                        <Button
                            onClick={() => setGridView(!gridView)}
                            buttonIcon={gridView ? 'th-large' : 'th-list'}
                            buttonSize="icon"
                            className="mr10"
                        />
                    </div>
                </div>
                <div className="attach-file__stops">{fileSectionNodes}</div>
            </div>
        </div>
    );
};

export default FilePicker;
