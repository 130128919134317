import React from 'react';
import {Field} from 'redux-form';
import RadioInput from 'components/ui/Form/RadioInput';

interface OwnProps {
    name: string;
    timeFrames: {label: string; value: string}[];
    isDisabled?: boolean;
}

const TimeFrames: React.FC<OwnProps> = ({name, timeFrames, isDisabled = false}) => {
    return <Field name={name} component={RadioInput} label="Time Frames" items={timeFrames} disabled={isDisabled} />;
};

export default TimeFrames;
