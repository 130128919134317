import {
    TRUCK_PREFERRED_LOADS_ANY,
    TRUCK_PREFERRED_LOADS_LONG_TRIP,
    TRUCK_PREFERRED_LOADS_LOCAL_TRIP,
} from 'core/entities/Truck/constants/truckPreferredLoads';

export const getPreferredLoadsOptions = () => {
    return [
        {label: 'Local Trip', value: TRUCK_PREFERRED_LOADS_LOCAL_TRIP},
        {label: 'Long Trip', value: TRUCK_PREFERRED_LOADS_LONG_TRIP},
        {label: 'Any', value: TRUCK_PREFERRED_LOADS_ANY},
    ];
};
