import differenceBy from 'lodash/differenceBy';
import toNumber from 'lodash/toNumber';

import {getCorrespondingAttachments, getAttachmentsMetaData} from 'pages/TravelOrders/redux/mappers/attachments';
import {EditBOLFormValues, FormFreights} from 'pages/TravelOrders/types/formTypes';

import * as toStopModules from 'core/entities/TravelOrder/modules/travelOrderStops';
import {FileMetaData} from 'core/entities/TravelOrder/types/Files';
import TravelOrder from 'core/entities/TravelOrder/types';

import {convertToLbs, transformWeightToNumber} from 'utils/weightConverter';
import weightUnits from 'utils/data/weightUnits';
import {createDate} from 'utils/dateTime';

export const transformEditBOLDataToRequestBody = (
    bolData: EditBOLFormValues,
    stopID?: string,
    travelOrder?: TravelOrder | null,
): {
    bols: Partial<
        Omit<FormFreights, 'weight' | 'pieces'> & {
            weight: {amount: number | null; unit?: typeof weightUnits[keyof typeof weightUnits]};
            pieces: number;
        }
    >[];
    documentActionDateTime: string | null;
    documentActionTimeZone: string | null;
    files?: FileMetaData[];
    deleteFileIds?: number[];
} => {
    const {date, time, timeZone, freights, attachments} = bolData;

    const {fullOriginalDateTime: dateTime, isValid} = createDate(`${date} ${time}`, {
        fromTimeZone: timeZone,
        toTimeZone: 'utc',
    });

    const convertedFreights = freights.map((freight) => ({
        ...freight,
        weight: {
            ...freight.weight,
            amount: convertToLbs(freight.weight?.unit, transformWeightToNumber(freight.weight?.amount)),
        },
        pieces: toNumber(freight.pieces || 0),
    }));

    const storedFormFiles = (attachments || []).filter((attachment) => 'id' in attachment);
    const stopFiles = toStopModules.getTravelOrderStopFilesByID({travelOrder, stopID});

    const deletedFiles = differenceBy(stopFiles, storedFormFiles, 'id');
    const deletedFileIDs = deletedFiles.map((deletedFile) => deletedFile.id);

    const convertedAttachments = getAttachmentsMetaData(attachments);

    return {
        documentActionTimeZone: isValid && timeZone ? timeZone : null,
        documentActionDateTime: isValid ? dateTime : null,

        bols: convertedFreights,

        files: convertedAttachments,
        deleteFileIds: deletedFileIDs.length ? deletedFileIDs : undefined,
    };
};

export const transformEditBOLFilesToRequestBody = (params: {
    travelOrder?: TravelOrder | null;
    bolData: EditBOLFormValues;
    filesIds: Record<number, string>;
    stopID?: string;
}): {uploadedFormData: FormData | null} => {
    const {bolData, travelOrder, stopID, filesIds} = params;

    if (!stopID || !travelOrder || !bolData) {
        return {uploadedFormData: null};
    }

    const {attachments} = bolData;

    const correspondingAttachments = getCorrespondingAttachments({
        formAttachments: attachments || [],
        filesIds,
    });

    return correspondingAttachments;
};
