import React from 'react';

import CurrencyAmount from 'components/ui/CurrencyAmount';

import {transformDistance} from 'utils/distance';

const SettlementsTotalInfo = ({totalEmptyMiles, totalLoadedMiles, totalPayment}) => {
    const convertedEmptyTotalMiles = transformDistance(totalEmptyMiles);
    const convertedLoadedTotalMiles = transformDistance(totalLoadedMiles);

    return (
        <div className="settlements-group-table__total">
            <div className="total-miles">
                <div className="title">
                    total empty {convertedEmptyTotalMiles.longUnit}
                    <br />
                    total loaded {convertedEmptyTotalMiles.longUnit}
                </div>
                <div className="data">
                    {convertedEmptyTotalMiles.fromMiles} <br />
                    <strong>{convertedLoadedTotalMiles.fromMiles}</strong>
                </div>
            </div>
            <div>
                {totalPayment.map((payment) => {
                    return (
                        <div className="total-payment" key={payment.id}>
                            <div className="title">gross pay</div>
                            <div className="data">
                                <div>
                                    <CurrencyAmount
                                        currency={payment.currency}
                                        amount={payment.total_charges}
                                        withStrong={false}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default React.memo(SettlementsTotalInfo);
