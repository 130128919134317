import differenceBy from 'lodash/differenceBy';
import isNumber from 'lodash/isNumber';
import compact from 'lodash/compact';

import {transferTravelOrderSignedAttachments} from 'core/entities/TravelOrder/modules/travelOrderAttachments/signedAttachments';
import {isTravelOrderHasCarrier} from 'core/entities/TravelOrder/modules/travelOrderCarrier';
import {FileMetaData} from 'core/entities/TravelOrder/types/Files';
import TravelOrder from 'core/entities/TravelOrder/types';

import {EditDeadheadDetailsFormValues, EditDeadheadFilesFormValues} from 'pages/TravelOrders/types/formTypes';

import {createDate} from 'utils/dateTime';

import {getAttachmentsMetaData} from '../attachments';

export const transformEditDeadheadDataToRequestBody = (params: {
    deadheadData: EditDeadheadDetailsFormValues;
    travelOrder?: TravelOrder | null;
}): {
    locationDateTime: string | null;
    longitude: number | null;
    latitude: number | null;
    cityLine: string | null;

    carrierDriverPhone?: string | null;
    carrierTruckNumber?: string | null;
    carrierDriverName?: string | null;
} => {
    const {deadheadData, travelOrder} = params;

    const isTOHasCarrier = isTravelOrderHasCarrier(travelOrder);

    const {longitude, latitude, cityLine, timeZone, date, time} = deadheadData;

    const createdDateTime = createDate(`${date} ${time}`, {fromTimeZone: timeZone, toTimeZone: 'utc'});
    const {fullOriginalDateTime: dateTime, isValid} = createdDateTime;

    const truckValues = {
        longitude: isNumber(longitude) ? longitude : null,
        latitude: isNumber(latitude) ? latitude : null,
        locationDateTime: isValid ? dateTime : null,
        cityLine: cityLine || null,
    };

    const additionalCarrierValues = {
        carrierDriverPhone: deadheadData?.carrierDriverPhone || null,
        carrierTruckNumber: deadheadData?.carrierTruckNumber || null,
        carrierDriverName: deadheadData?.carrierDriverName || null,
    };

    if (isTOHasCarrier) {
        return {...truckValues, ...additionalCarrierValues};
    }

    return truckValues;
};

export const transformEditDeadheadDataFilesToRequestBody = (params: {
    data: EditDeadheadFilesFormValues;
    travelOrder?: TravelOrder | null;
}): {
    files?: FileMetaData[];
    deleteFileIds?: number[];
} => {
    const {data, travelOrder} = params;

    const {attachments} = data;

    const storedFormFiles = (attachments || []).filter((attachment) => 'id' in attachment);
    const stopFiles = transferTravelOrderSignedAttachments(travelOrder);

    const deletedFiles = differenceBy(stopFiles, storedFormFiles, 'id');
    const deletedFileIDs = compact(deletedFiles.map((deletedFile) => deletedFile.id));

    const convertedAttachments = getAttachmentsMetaData(attachments);

    return {
        files: convertedAttachments,
        deleteFileIds: deletedFileIDs.length ? deletedFileIDs : undefined,
    };
};
