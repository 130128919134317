import React from 'react';

import {reduxForm, Field} from 'redux-form';

import {isValidLength} from 'utils/validation';
import * as regexps from 'utils/validation/regexps';
import * as errorMessages from 'utils/validation/errorMessages';

import Button from 'components/ui/Buttons/Button/index';
import TextInput from 'components/ui/Form/TextInput';
import PasswordInput from 'components/ui/Form/PasswordInput/index';

import {FIELDS} from '../../../constants/fields';

const contactFields = FIELDS.contact;

const validate = (values) => {
    const errors = {};

    if (!values.password) {
        errors.password = "Please enter password for driver's mobile store.";
    } else if (!regexps.LETTERS_AND_DIGITS.test(values.password)) {
        errors.password = 'Please enter the password with letters and digits.';
        // eslint-disable-next-line no-magic-numbers
    } else if (values.password && !isValidLength(6, 16, values.password)) {
        // eslint-disable-next-line no-magic-numbers
        errors.password = errorMessages.INVALID_LENGTH(6, 16);
    }

    if (!values.confirm_password) {
        errors.confirm_password = 'Current input must be the same as password.';
    } else if (!regexps.LETTERS_AND_DIGITS.test(values.confirm_password)) {
        errors.confirm_password = 'Please enter the password with letters and digits.';
    } else if (values.password !== values.confirm_password) {
        errors.confirm_password = 'Current input must be the same as password.';
        // eslint-disable-next-line no-magic-numbers
    } else if (values.confirm_password && !isValidLength(6, 16, values.confirm_password)) {
        // eslint-disable-next-line no-magic-numbers
        errors.confirm_password = errorMessages.INVALID_LENGTH(6, 16);
    }

    return errors;
};

const Form = (props) => {
    const {handleSubmit, onClose} = props;

    return (
        <form onSubmit={handleSubmit} className="tm-confirm tm-check-in">
            <div className="modal-body__main">
                <div className="modal-body__main_item ">
                    <Field name={contactFields.email} type="text" component={TextInput} disabled={true} label="Login" />

                    <div>
                        <Field
                            name={contactFields.password}
                            type="password"
                            showValidation={true}
                            errorTooltipPlacement="top"
                            placeholder="type password with letters and numbers"
                            component={PasswordInput}
                            label="Password"
                        />

                        <Field
                            name={contactFields.confirm_password}
                            type="password"
                            errorTooltipPlacement="top"
                            showValidation={true}
                            placeholder="confirm password"
                            component={PasswordInput}
                            label="Confirm Password"
                        />
                    </div>
                </div>
            </div>
            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <Button type="submit" colorTheme="blue">
                        Change
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    validate,
})(Form);
