import trim from 'lodash/trim';
import isNumber from 'lodash/isNumber';

import {getDistanceFromOriginsToDestinations} from 'services/mapsApi';
import {getTimeZoneByCoordinates} from 'services/geocodingApi';

import {getTruckTeam as getTruckTeamData} from 'core/entities/Truck/modules/truckTeam';
import {TIME_FRAME_DIRECT, TIME_FRAME_ASAP} from 'core/entities/Load/constants';
import Truck from 'core/entities/Truck/types';

import {QuoteInitialValues, LoadStop, LengthUnits, QuoteReceiver} from 'pages/LoadBoard/types';
import {TrucksNearbySearchFormValues} from 'pages/TrucksNearby/types/formTypes';

import {getCurrentDate, getCurrentDateSettings} from 'utils/dateTime';
import {getCityLineFromObject} from 'utils/getCityLine';
import weightUnits from 'utils/data/weightUnits';
import lengthUnits from 'utils/data/lengthUnits';

import {PlaceAddress} from 'types/Address';

import {convertMiToMm} from '../utils/distance';

type TruckTeam = ReturnType<typeof getTruckTeamData>;

const getTruckWithConvertedDistance = (truck: Truck): Truck => {
    const truckWithConvertedDistance = {...truck};
    const {distance, homeDistance} = truckWithConvertedDistance;

    if (distance) {
        truckWithConvertedDistance.distance = convertMiToMm(distance);
    }

    if (homeDistance) {
        truckWithConvertedDistance.homeDistance = convertMiToMm(homeDistance);
    }

    return truckWithConvertedDistance;
};

const getTruckTypes = (externalParams: Partial<TrucksNearbySearchFormValues>): string[] => {
    const {type: truckTypes} = externalParams;

    if (!truckTypes) {
        return [];
    }

    return truckTypes.split(',').map((type) => trim(type));
};

const getTruckDistance = async (
    originPoint: PlaceAddress | null,
    destinationPoint: PlaceAddress | null,
): Promise<number> => {
    const originCoordinates = originPoint?.coords;
    const destinationCoordinates = destinationPoint?.coords;

    if (originCoordinates && destinationCoordinates) {
        const response = await getDistanceFromOriginsToDestinations({
            origin: [originCoordinates],
            destination: [destinationCoordinates],
        });

        const distance = response.data[0]?.distance;
        const isDistanceReceived = isNumber(distance);

        if (isDistanceReceived) {
            return Math.floor(distance);
        }
    }

    return 0;
};

const getTruckEquipment = (externalParams: Partial<TrucksNearbySearchFormValues>): string => {
    const {equipment} = externalParams;

    if (!equipment) {
        return '';
    }

    return equipment;
};

const getTruckSigns = (externalParams: Partial<TrucksNearbySearchFormValues>): boolean | undefined => {
    const {withCompanySigns} = externalParams;

    return withCompanySigns;
};

const getTruckTeam = (externalParams: Partial<TrucksNearbySearchFormValues>): boolean => {
    const {isTeam} = externalParams;

    return Boolean(isTeam);
};

const getDriverCertificates = (externalParams: Partial<TrucksNearbySearchFormValues>): string => {
    const {certificates} = externalParams;

    return certificates || '';
};

const getDriverCitizenship = (externalParams: Partial<TrucksNearbySearchFormValues>): string => {
    const {citizenship} = externalParams;

    if (!citizenship) {
        return '';
    }

    return citizenship;
};

const getDriverCrossBorder = (externalParams: Partial<TrucksNearbySearchFormValues>): string => {
    const {crossBorder} = externalParams;

    if (!crossBorder) {
        return '';
    }

    return crossBorder;
};

const getTruckStatuses = (externalParams: Partial<TrucksNearbySearchFormValues>): string[] => {
    const {status: statuses} = externalParams;

    if (!statuses) {
        return [];
    }

    return statuses.split(',').map((status) => trim(status));
};

const getStopData = (
    stopLocation?: PlaceAddress | null,
): Omit<LoadStop, 'timeFrame' | 'dateTo' | 'dateFrom' | 'type'> => {
    const {currentTimeZone} = getCurrentDateSettings();

    const stop = {
        timeZone: currentTimeZone,
    };

    if (!stopLocation) {
        return stop;
    }

    return {
        ...stop,
        address: {
            cityLine: getCityLineFromObject(stopLocation.address),
            city: stopLocation.address.city,
            state: stopLocation.address.state,
            zip: stopLocation.address.zip,
            country: stopLocation.address.country,
        },
        coordinates: {
            latitude: stopLocation.coords.lat,
            longitude: stopLocation.coords.lng,
        },
    };
};

const getStopTimeZone = (stopLocation?: PlaceAddress | null) => {
    const {currentTimeZone} = getCurrentDateSettings();

    if (!stopLocation || !stopLocation?.coords?.lat || !stopLocation?.coords?.lng) {
        return currentTimeZone;
    }

    return getTimeZoneByCoordinates({lat: stopLocation?.coords?.lat, lng: stopLocation?.coords?.lng})
        .then((data) => data.timeZone)
        .catch(() => currentTimeZone);
};

const getOriginStop = async (origin?: PlaceAddress | null): Promise<LoadStop> => {
    const {originalDate} = getCurrentDate();
    const stopData = getStopData(origin);

    const timeZone = await getStopTimeZone(origin);

    return {
        ...stopData,
        timeZone,
        timeFrame: TIME_FRAME_ASAP,
        dateFrom: `${originalDate} 08:00`,
        dateTo: `${originalDate} 15:00`,
        type: 'PICKUP',
    };
};

const getDestinationStop = async (originPoint?: PlaceAddress | null): Promise<LoadStop> => {
    const stopData = getStopData(originPoint);

    const timeZone = await getStopTimeZone(originPoint);

    return {
        ...stopData,
        timeZone,
        timeFrame: TIME_FRAME_DIRECT,
        type: 'DELIVERY',
    };
};

const isTruckWithDriverAsMainContact = (truckTeam: TruckTeam): boolean => {
    const {isOwnerResponsible, isDriverResponsible, ownerDriver} = truckTeam;

    if (isOwnerResponsible && ownerDriver) {
        return true;
    }

    if (isDriverResponsible) {
        return true;
    }

    return false;
};

export const getQuoteReceivers = (trucks?: Truck[]): QuoteReceiver[] => {
    if (!trucks) {
        return [];
    }

    return trucks.reduce<QuoteReceiver[]>((result, t) => {
        const truckTeam = getTruckTeamData(t);
        const truckWithConvertedDistance = getTruckWithConvertedDistance(t);

        if (!truckTeam.firstDriver) {
            return result;
        }

        const receiver: QuoteReceiver = {
            truck: truckWithConvertedDistance,
            isReceiveQuoteAllowed: isTruckWithDriverAsMainContact(truckTeam),
        };

        result.push(receiver);

        return result;
    }, []);
};

const getTruckDimensionUnit = (externalParams: Partial<TrucksNearbySearchFormValues>): LengthUnits => {
    const {dimsUnit} = externalParams;

    if (!dimsUnit) {
        return lengthUnits.INCH;
    }

    return dimsUnit as LengthUnits;
};

const getTruckDimensionLength = (externalParams: Partial<TrucksNearbySearchFormValues>): number => {
    const {dimLength} = externalParams;

    if (!dimLength) {
        return 0;
    }

    return Number(dimLength);
};

const getTruckDimensionWidth = (externalParams: Partial<TrucksNearbySearchFormValues>): number => {
    const {dimWidth} = externalParams;

    if (!dimWidth) {
        return 0;
    }

    return Number(dimWidth);
};

const getTruckDimensionHeight = (externalParams: Partial<TrucksNearbySearchFormValues>): number => {
    const {dimHeight} = externalParams;

    if (!dimHeight) {
        return 0;
    }

    return Number(dimHeight);
};

const getTruckPayloadValue = (externalParams: Partial<TrucksNearbySearchFormValues>): number | undefined => {
    const {payload} = externalParams;

    if (!payload) {
        return undefined;
    }

    return Number(payload);
};

const getRadiusSearchDistanceInMm = (externalParams: Partial<TrucksNearbySearchFormValues>): number => {
    const {radius} = externalParams;

    if (!radius) {
        return 0;
    }

    return convertMiToMm(radius);
};

export const mapExternalParamsToQuoteInitial = async (params: {
    externalParams: Partial<TrucksNearbySearchFormValues>;
    destinationPoint: PlaceAddress | null;
    originPoint: PlaceAddress | null;
    trucks: Truck[];
}): Promise<QuoteInitialValues> => {
    const {externalParams, trucks, originPoint, destinationPoint} = params;

    const routeDistance = await getTruckDistance(originPoint, destinationPoint);
    const destinationStop = await getDestinationStop(destinationPoint);
    const originStop = await getOriginStop(originPoint);

    return {
        load: {
            stops: [originStop, destinationStop],
            freight: {
                weightUnit: weightUnits.LBS,
                dimensions: {
                    items: [{length: 0, width: 0, height: 0}],
                    unit: lengthUnits.INCH,
                },
                isStackable: false,
            },
            isHot: false,
        },
        truck: {
            types: getTruckTypes(externalParams),
            equipment: getTruckEquipment(externalParams),
            withSigns: getTruckSigns(externalParams),
            withDriversTeam: getTruckTeam(externalParams),
            driverCertificates: getDriverCertificates(externalParams),
            driverCitizenship: getDriverCitizenship(externalParams),
            driverCrossBorder: getDriverCrossBorder(externalParams),
            statuses: getTruckStatuses(externalParams),
            payload: {
                unit: weightUnits.LBS,
                value: getTruckPayloadValue(externalParams),
            },
            dimensions: {
                unit: getTruckDimensionUnit(externalParams),
                length: getTruckDimensionLength(externalParams),
                width: getTruckDimensionWidth(externalParams),
                height: getTruckDimensionHeight(externalParams),
            },
        },
        route: {
            distance: routeDistance,
            isAirport: false,
        },
        notes: {
            aboutQuote: '',
            forDispatchers: '',
            forDrivers: '',
        },
        driverPayment: {
            currency: 'USD',
        },
        durationInMinutes: 30,
        receivers: getQuoteReceivers(trucks),
        radiusSearchDistance: getRadiusSearchDistanceInMm(externalParams),
    };
};
