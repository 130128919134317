import React from 'react';
import classNames from 'classnames';

import Truck from 'core/entities/Truck/types';
import {getMainPersonPhone} from 'core/entities/Truck/modules/truckMainPerson';
import {getTruckTeam} from 'core/entities/Truck/modules/truckTeam';
import {getOwnerName} from 'core/entities/Owner/modules/ownerName';
import {getOwnerCoordinatorName} from 'core/entities/Owner/modules/ownerCoordinatorName';
import {getDriverName} from 'core/entities/Driver/modules/driverName';

import {DriverLink, OwnerLink} from 'components/ui/Links';
import CitizenshipBadge from 'components/ui/Badges/Citizenship';
import LanguageBadge from 'components/ui/Badges/LanguageBadge';
import CertificatesBadge from 'components/ui/Badges/CertificatesBadge';
import DriverCrossBorderBadges from 'components/ui/Badges/DriverCrossBorderBadges';

import {phoneNumberWithBraces} from 'utils';

import styles from './truckMainPerson.module.scss';

type TruckTeam = ReturnType<typeof getTruckTeam>;

const getMainPersonLink = (props: {truck: Truck; truckTeam: TruckTeam}): JSX.Element | undefined => {
    const {truck, truckTeam} = props;
    const ownerLinkClassName = classNames('second-link', {'archived-link': truckTeam?.owner?.is_deleted});
    const driverLinkClassName = classNames('second-link', {'archived-link': truck.is_deleted});

    if (truckTeam.isCoordinatorResponsible && truckTeam.owner) {
        return (
            <OwnerLink
                content={getOwnerCoordinatorName(truckTeam.owner)}
                className={ownerLinkClassName}
                owner={truckTeam.owner}
            />
        );
    }

    if (truckTeam.isOwnerResponsible && truckTeam.owner) {
        return (
            <OwnerLink content={getOwnerName(truckTeam.owner)} owner={truckTeam.owner} className={ownerLinkClassName} />
        );
    }

    if (truckTeam.firstDriver) {
        return (
            <DriverLink
                content={getDriverName(truckTeam.firstDriver)}
                className={driverLinkClassName}
                driver={truckTeam.firstDriver}
            />
        );
    }
};

const TruckMainPersonInfo: React.FC<{truck: Truck}> = (props) => {
    const {truck} = props;

    if (!truck) {
        return null;
    }

    const truckTeam = getTruckTeam(truck);
    const {
        owner,
        firstDriver,
        ownerCoordinator,
        isOwnerResponsible,
        isDriverResponsible,
        mainContactPersonTitle,
        isCoordinatorResponsible,
    } = truckTeam;
    const personLink = getMainPersonLink({truck, truckTeam});
    const mainContactPersonPhone = getMainPersonPhone(truck);
    const mainPersonPhoneClassName = classNames('is-main', {archived: truck.is_deleted});
    const languageBadges = (
        <>
            {isOwnerResponsible ? <LanguageBadge language={owner?.language} /> : null}
            {isDriverResponsible ? <LanguageBadge language={firstDriver?.language} /> : null}
            {isCoordinatorResponsible ? <LanguageBadge language={ownerCoordinator?.coordinator_language} /> : null}
        </>
    );
    const citizenshipBadges = (
        <>
            {isOwnerResponsible ? <CitizenshipBadge citizenship={owner?.citizenship} /> : null}
            {isDriverResponsible ? <CitizenshipBadge citizenship={firstDriver?.citizenship} /> : null}
        </>
    );
    return (
        <div>
            <div>
                <div>
                    {mainContactPersonTitle} {personLink}
                </div>
                <div className={styles.phoneContainer}>
                    <div>
                        <span className={mainPersonPhoneClassName}>
                            {phoneNumberWithBraces(mainContactPersonPhone)}
                        </span>
                    </div>{' '}
                    <div className={styles.badgesContainer}>
                        {languageBadges}
                        {firstDriver && <CertificatesBadge driver={firstDriver} />}
                        {citizenshipBadges}
                        {firstDriver && <DriverCrossBorderBadges driver={firstDriver} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TruckMainPersonInfo;
