import ChangePasswordModal from './ChangePasswordModal';
import ArchivingError from './ArchivingError';
import ArchivingWarn from './ArchivingWarn';
import ArchivingSuccess from './ArchivingSuccess';

export const modalNames = {
    changePasswordModal: 'DISPATCHERS_PAGE_CHANGE_PASSWORD_MODAL',
    archivingError: 'DISPATCHERS_PAGE_ARCHIVING_ERROR_MODAL',
    archivingWarn: 'DISPATCHERS_PAGE_ARCHIVING_WARN_MODAL',
    archivingSuccess: 'DISPATCHERS_PAGE_ARCHIVING_SUCCESS_MODAL',
};

const modalMap = {
    [modalNames.changePasswordModal]: ChangePasswordModal,
    [modalNames.archivingError]: ArchivingError,
    [modalNames.archivingWarn]: ArchivingWarn,
    [modalNames.archivingSuccess]: ArchivingSuccess,
};

export default modalMap;
