import React, {useState} from 'react';

import useSelectedTrucks from '../../hooks/useSelectedTrucks';

import TableLabel from '../TableLabel';
import CheckAllTrucks from '../CheckAllTrucks';
import TrucksList from '../TrucksList';

const QuoteReceiversTable: React.FC<{
    formName: string;
}> = (props) => {
    const {formName} = props;

    const [isOpenTable, setIsOpenTable] = useState<boolean>(true);

    const {
        allTrucks,
        selectedTrucks,
        selectedTrucksLength,
        allTrucksLength,
        isAllSelected,
        onSelectAllTrucks,
        onSelectTruck,
    } = useSelectedTrucks(formName);

    const handleToggle = (): void => {
        setIsOpenTable((prev: boolean) => !prev);
    };

    return (
        <div>
            <TableLabel onToggle={handleToggle} open={isOpenTable} trucksLength={allTrucksLength} />
            {isOpenTable && (
                <>
                    <CheckAllTrucks
                        selectedTrucksLength={selectedTrucksLength}
                        isAllSelected={isAllSelected}
                        allTrucksLength={allTrucksLength}
                        onSelectAllTrucks={onSelectAllTrucks}
                    />
                    <TrucksList
                        initialTrucks={allTrucks}
                        selectedTrucks={selectedTrucks}
                        onSelectTruck={onSelectTruck}
                    />
                </>
            )}
        </div>
    );
};

export default QuoteReceiversTable;
