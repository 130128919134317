import {getCurrentUser} from 'store/reducers/userData/selectors';

import * as wsTypes from 'widgets/Chat/redux/actionTypes/wsTypes';
import * as requests from 'widgets/Chat/requests';

import * as wsRequests from 'services/chatApi/websocket';

export const sendChatMessage = (message) => ({type: wsTypes.WEB_SOCKET_CHAT_SEND_CHAT_MESSAGE, payload: message});
export const clearSocketID = () => ({type: wsTypes.WEB_SOCKET_CHAT_CLEAR_SOCKET_ID});
export const chatDisconnect = () => ({type: wsTypes.WEB_SOCKET_CHAT_DISCONNECT});

export const chatMessageSendError = (error) => ({
    type: wsTypes.WEB_SOCKET_CHAT_SEND_CHAT_MESSAGE_ERROR,
    payload: error,
});

export const setWebSocketID = ({webSocket}) => ({
    type: wsTypes.WEB_SOCKET_CHAT_SET_SOCKET_ID,
    payload: {socketId: webSocket.id},
});

export const websocketConnectionReceived = ({webSocket}) => ({
    type: wsTypes.WEB_SOCKET_CHAT_CONNECTION_RECEIVED,
    payload: {webSocket},
});

export const getMoreChannelMessages = (channelData) => ({
    type: wsTypes.WEB_SOCKET_CHAT_GET_MORE_CHANNEL_MESSAGES,
    payload: channelData,
});

export const chatMessageReceived = (message) => (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    dispatch({type: wsTypes.WEB_SOCKET_CHAT_RECEIVED_CHAT_MESSAGE, payload: {message, currentUser}});
};

export const moreMessagesForChannelReceived = (driverID, messages) => (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    dispatch({
        type: wsTypes.WEB_SOCKET_CHAT_MORE_CHANNEL_MESSAGES_RECEIVED,
        payload: {driverID, messages, currentUser},
    });
};

export const markAsReadByCurrentDispatcher = (driverID) => ({
    type: wsTypes.WEB_SOCKET_CHAT_MARK_AS_READ_BY_CURRENT_DISPATCHER,
    payload: {driverID},
});

export const markAsReadByEveryDispatcher = (driverID) => ({
    type: wsTypes.WEB_SOCKET_CHAT_MARK_AS_READ_BY_EVERY_DISPATCHER,
    payload: {driverID},
});

export const markLocalMessagesAsRead = (driverID) => ({
    type: wsTypes.WEB_SOCKET_CHAT_MARK_LOCAL_MESSAGES_AS_READ,
    payload: {driverID},
});

export const chatAuthenticate = () => async () => {
    try {
        await requests.chatAuthenticateRequest();

        return true;
    } catch (error) {
        console.log('Chat authentication error');

        return false;
    }
};

export const chatWSConnect = () => async () => {
    try {
        const webSocket = await wsRequests.chatWSConnectRequest();

        return webSocket;
    } catch (error) {
        return null;
    }
};
