import React from 'react';

import Truck from 'core/entities/Truck/types';

import LengthView from 'components/ui/LengthView';

import {weightView} from 'utils/weightConverter';

type OwnProps = {
    withBoldDimensions?: boolean;
    className?: string;
    truck: Truck;
};

const TruckDimsAndPayload: React.FC<OwnProps> = (props) => {
    const {truck, className, withBoldDimensions = false} = props;

    const dimensionsClassName = withBoldDimensions ? 'font-weight-bold' : '';

    return (
        <div>
            <div className={dimensionsClassName}>
                <LengthView
                    length={truck.valid_dims_length}
                    height={truck.valid_dims_height}
                    width={truck.valid_dims_width}
                    units={truck.dims_units}
                    className={className}
                />
            </div>

            <div>{weightView(truck.payload_units, truck.payload)}</div>
        </div>
    );
};

export default TruckDimsAndPayload;
