import {useDispatch} from 'react-redux';

import {PlaceAddress} from 'types/Address';

import {changeTimeZoneByCoordinates} from '../actions/modal';

type UseModalActions = {
    changeTimeZoneByCoordinates: (params: {formName: string; coordinates: PlaceAddress['coords']}) => void;
};

const useModalActions = (): UseModalActions => {
    const dispatch = useDispatch();

    return {
        changeTimeZoneByCoordinates: (params: {formName: string; coordinates: PlaceAddress['coords']}) =>
            dispatch(changeTimeZoneByCoordinates(params)),
    };
};

export default useModalActions;
