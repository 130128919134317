import React from 'react';

import {SettlementOwner} from 'core/entities/Settlement/types';
import CurrencyAmount from 'components/ui/CurrencyAmount';

import SettlementLastDeliveryCheckoutTime from 'pages/Settlements/components/common/SettlementLastDeliveryCheckoutTime';

type OwnProps = {
    settlement: SettlementOwner;
};

const SettlementInfo: React.FC<OwnProps> = ({settlement}) => {
    const totalDriverPayment = settlement.total_pay;
    const truckNumber = settlement.truck_number;
    return (
        <div className="settlement-item-list__row">
            <div className="settlement-item-list__col">
                <div>{settlement.owner_name}</div>
                <div>sett #{settlement.settlement_number}</div>
            </div>
            <div className="settlement-item-list__col">
                {truckNumber && <div>truck: #{truckNumber}</div>}
                <SettlementLastDeliveryCheckoutTime settlement={settlement} withPrefix={true} />
            </div>
            <div className="settlement-item-list__col">
                <div className="text-right">
                    <CurrencyAmount amount={totalDriverPayment} currency={settlement.currency} />
                </div>
            </div>
        </div>
    );
};

export default React.memo(SettlementInfo);
