import * as types from '../actionTypes';

// EXPORTED ONLY FOR UNIT TESTS
export const defaultState = {
    list: [],
    pagination: {
        currentPage: 1,
        perPage: 50,
    },
    searchIsOpen: false,
    searchParams: null,
};

export default function dispatchers(state = defaultState, action) {
    switch (action.type) {
        case types.RECEIVED_DISPATCHERS: {
            return {
                ...state,
                list: action.payload.data,
                pagination: action.payload.pagination,
            };
        }

        case types.DISPATCHER_ARCHIVED: {
            const {dispatcher: archivedDispatcher} = action.payload;
            return {
                ...state,
                list: state.list.filter((dispatcher) => dispatcher.id !== archivedDispatcher.id),
            };
        }

        case types.LIST_USERS_TOGGLE_SEARCH_FILTER: {
            return {...state, searchIsOpen: !state.searchIsOpen};
        }

        case types.SET_PAGINATION: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload,
                },
            };
        }

        case types.SET_SEARCH_DATA: {
            return {
                ...state,
                searchParams: action.payload,
            };
        }

        case types.CLEAR_STATE: {
            return defaultState;
        }

        default:
            return state;
    }
}
