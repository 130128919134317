import {statuses} from 'core/entities/Invoice/constants';

export const getInvoiceStatusInfo = (invoice) => {
    return invoice && invoice.last_status_history;
};

export const getInvoiceStatusName = (invoice) => {
    const invoiceStatusInfo = getInvoiceStatusInfo(invoice);
    if (!invoiceStatusInfo) {
        return;
    }

    return invoiceStatusInfo.status && invoiceStatusInfo.status.status_name;
};

export const getInvoiceLastStatusCratedTime = (invoice) => {
    const invoiceStatusInfo = getInvoiceStatusInfo(invoice);
    return invoiceStatusInfo && invoiceStatusInfo.created_at;
};

export const checkCurrentInvoiceStatusIsDifferentFromRated = (invoice) => {
    const status = getInvoiceStatusName(invoice);

    if (status) {
        return status !== statuses.RATED;
    }

    return false;
};

export const isInvoiceStatusRated = (invoice) => {
    const status = getInvoiceStatusName(invoice);
    return status === statuses.RATED;
};

export const isInvoiceStatusReadyToGenerate = (invoice) => {
    const status = getInvoiceStatusName(invoice);
    return status === statuses.READY_TO_GENERATE;
};

export const isInvoiceStatusGenerated = (invoice) => {
    const status = getInvoiceStatusName(invoice);
    return status === statuses.GENERATED;
};

export const isInvoiceStatusReadyToPost = (invoice) => {
    const status = getInvoiceStatusName(invoice);
    return status === statuses.READY_TO_POST;
};

export const isInvoiceStatusPosted = (invoice) => {
    const status = getInvoiceStatusName(invoice);
    return status === statuses.POSTED;
};

export const isInvoiceWithGeneratedPDF = (invoice) => {
    const status = getInvoiceStatusName(invoice);
    return status === statuses.GENERATED || status === statuses.READY_TO_POST || status === statuses.POSTED;
};
