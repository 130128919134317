import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';

import {
    isInvoiceStatusRated,
    getInvoiceLoad,
    getInvoiceFilesHistory,
    getBatchFile,
    getBatchInvoices,
} from 'core/entities/Invoice';

const getLoadStopIds = (load) => {
    if (!load) {
        return [];
    }
    const allStops = load.pick_up_info;
    if (!allStops) {
        return [];
    }
    return allStops.map((e) => e.id);
};

export const getStopsIncludedToPDF = (currentInvoice) => {
    if (isInvoiceStatusRated(currentInvoice)) {
        const invoiceLoad = getInvoiceLoad(currentInvoice);
        return getLoadStopIds(invoiceLoad);
    }
    const currentApprovedStops = currentInvoice.approved_stops;
    return currentApprovedStops || [];
};

export const getInvoiceFiles = (invoice, invoiceBatches) => {
    const invoiceFiles = getInvoiceFilesHistory(invoice);
    // avoid incorrect response from API
    const listInvoiceBatches = isPlainObject(invoiceBatches) ? [invoiceBatches] : invoiceBatches;
    const batchFiles = listInvoiceBatches.map(getBatchFile).filter(Boolean);
    return {invoice: invoiceFiles, batch: batchFiles};
};

export const updateInvoiceLoadData = (invoice, {fieldName, fieldValue}) => {
    return {
        ...invoice,
        load: {
            ...invoice.load,
            [fieldName]: fieldValue,
        },
    };
};

export const updateInvoiceData = (invoice, {fieldName, fieldValue}) => {
    return {
        ...invoice,
        [fieldName]: fieldValue,
    };
};

export const getInvoiceLastHistoryItemFromInvoiceFile = (invoice, batch) => {
    const batchInvoices = batch && batch.batch_invoices;
    if (!batchInvoices || !isArray(batchInvoices)) {
        return;
    }
    const batchInvoiceFileData = batchInvoices.find((batchInvoiceData) => {
        const innerInvoice = batchInvoiceData && batchInvoiceData.invoice_file && batchInvoiceData.invoice_file.invoice;
        return innerInvoice && innerInvoice.id === invoice.id;
    });

    const invoiceFileStatus =
        batchInvoiceFileData && batchInvoiceFileData.invoice_file && batchInvoiceFileData.invoice_file.status;
    const invoiceFileResponsible =
        batchInvoiceFileData && batchInvoiceFileData.invoice_file && batchInvoiceFileData.invoice_file.responsible;
    const invoiceFileCreatedAt =
        batchInvoiceFileData && batchInvoiceFileData.invoice_file && batchInvoiceFileData.invoice_file.created_at;

    if (!invoiceFileStatus || !isObject(invoiceFileStatus)) {
        return;
    }
    // add custom combined id for avoid bug in react when some elements have equal id
    return {
        status: invoiceFileStatus,
        id: `${invoice.id}${batch.id}`,
        responsible: invoiceFileResponsible,
        created_at: invoiceFileCreatedAt,
    };
};

export const getInvoiceDataFromCreatedBatch = (currentInvoice, createdBatch) => {
    const createdBatchInvoices = getBatchInvoices(createdBatch);
    const currentInvoiceFromCreatedBatch = createdBatchInvoices.find((invoice) => invoice.id === currentInvoice.id);

    if (!currentInvoiceFromCreatedBatch) {
        return undefined;
    }
    // for avoid bug with incorrect invoice last status history item form API response after create batch
    // we should use this hack for get custom history data and put it as last history item
    // https://jira.rhinocodes.com/browse/REX-2200
    const customLastStatusHistoryItem = getInvoiceLastHistoryItemFromInvoiceFile(
        currentInvoiceFromCreatedBatch,
        createdBatch,
    );
    const statusHistory = customLastStatusHistoryItem
        ? [customLastStatusHistoryItem, ...currentInvoiceFromCreatedBatch.status_history]
        : currentInvoiceFromCreatedBatch.status_history;
    return {
        ...currentInvoiceFromCreatedBatch,
        status_history: statusHistory,
    };
};

export const isWebsocketAction = (action) => {
    return action.type.startsWith('WEB_SOCKET');
};
