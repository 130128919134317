import React from 'react';
import classNames from 'classnames';

import {AutoCheckCallErrors} from 'core/entities/TravelOrder/types/AutoCheckCallSettings';

import {transformDistance} from 'utils/distance';

import styles from '../../styles.module.scss';

type OwnProps = {
    speed: number;
    error?: AutoCheckCallErrors['averageSpeed'];
};

const AverageSpeed: React.FC<OwnProps> = ({error, speed}) => {
    const {fromMiles, speedUnit} = transformDistance(speed);

    return (
        <span className={classNames({[styles.errorBgWithBorder]: error})}>
            avg <i className="fa fa-tachometer" aria-hidden="true" /> {fromMiles} {speedUnit}
        </span>
    );
};

export default AverageSpeed;
