import React from 'react';
import isEmpty from 'lodash/isEmpty';

import {PayRecord} from 'core/entities/Settlement/types';

import PayRecordTotalPays from 'pages/Settlements/components/common/PayRecordTotalPays';

import {transformDistance} from 'utils/distance';

import TotalPayment from './components/TotalPayment';

import styles from './payRecordTotalInfo.module.scss';

type OwnProps = {
    payRecord: PayRecord;
    isExpanded?: boolean;
    children?: React.ReactNode;
};

const PayRecordTotalInfo: React.FC<OwnProps> = ({payRecord, isExpanded = true, children}) => {
    const convertedEmptyMiles = transformDistance(payRecord.empty_miles);
    const convertedLoadedMiles = transformDistance(payRecord.loaded_miles);

    return (
        <>
            {isExpanded && (
                <>
                    <div className={styles.total}>
                        <div className={styles.total__miles}>
                            <div className={styles.total__miles_titles}>
                                total empty {convertedEmptyMiles.longUnit}
                                <br />
                                total loaded {convertedLoadedMiles.longUnit}
                            </div>
                            <div className={styles.total__miles_data}>
                                {convertedEmptyMiles.fromMiles} <br />
                                <strong>{convertedLoadedMiles.fromMiles}</strong>
                            </div>
                        </div>
                        <div className={styles.total__payment}>
                            <TotalPayment payRecord={payRecord} />
                        </div>
                    </div>
                </>
            )}
            <div className={styles.footer}>
                {children}
                <div className={styles.footer__total}>
                    {!isEmpty(children) && (
                        <span className="sep mr-3">
                            <span className="dot" />
                            <span className="dot" />
                            <span className="dot" />
                        </span>
                    )}
                    <div className={styles.footer__total_title}>Total Pay</div>
                    <div className={styles.footer__total_data}>
                        <PayRecordTotalPays payRecord={payRecord} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PayRecordTotalInfo;
