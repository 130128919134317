export const REGION_NAME = {
    NORTH_AMERICA: 'na',
    EUROPE: 'eu',
};

export type RegionValue = 'na' | 'eu';

export const isNorthAmericaRegion = (currentRegion: RegionValue): boolean => {
    return Boolean(currentRegion === REGION_NAME.NORTH_AMERICA);
};

export const isEuropeRegion = (currentRegion: RegionValue): boolean => {
    return Boolean(currentRegion === REGION_NAME.EUROPE);
};
