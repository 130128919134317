import isEmpty from 'lodash/isEmpty';

import {store} from 'store';
import {getCurrentRegion} from 'store/reducers/appSettings/selectors';

import {FIELDS} from 'core/entities/Customer/constants/fields';
import * as customersErrorMessages from 'core/entities/Customer/validation/errorMessages';
import {searchIdentifierNumberDuplicates} from 'core/gateways/CustomerApiGateway/requests';

import {isEuropeRegion, RegionValue} from 'utils/data/region';
import {checkIsValidZip, hasValueChanged} from 'utils/validation';

const validateBlurredField = async (blurredField, values, initialValues) => {
    if (
        (blurredField === FIELDS.identifier_number &&
            hasValueChanged(values, initialValues, FIELDS.identifier_number)) ||
        (!blurredField && hasValueChanged(values, initialValues, FIELDS.identifier_number))
    ) {
        const identifier = {
            type: values[FIELDS.identifier_type],
            number: values[FIELDS.identifier_number],
        };
        const duplicates = await searchIdentifierNumberDuplicates(identifier);
        if (Object.keys(duplicates).length) {
            const currentRegion: RegionValue = getCurrentRegion(store.getState());
            const isEurope = isEuropeRegion(currentRegion);
            const errorType = isEurope
                ? customersErrorMessages.IDENTIFIER_NUMBER_UNIQUENESS_CHECK_ERROR_EU
                : customersErrorMessages.IDENTIFIER_NUMBER_UNIQUENESS_CHECK_ERROR_NA;
            return {identifier_number: errorType};
        }
        return {};
    }
    if (
        (blurredField === FIELDS.billing_zip && hasValueChanged(values, initialValues, FIELDS.billing_zip)) ||
        (!blurredField && hasValueChanged(values, initialValues, FIELDS.billing_zip))
    ) {
        return checkIsValidZip({
            countryValue: values[FIELDS.billing_country],
            zipValue: values[FIELDS.billing_zip],
            zipFieldName: FIELDS.billing_zip,
        });
    }
    return null;
};

export default async function asyncValidate(values, dispatch, props, blurredField) {
    const {asyncErrors, initialValues} = props;
    const blurredFieldError = await validateBlurredField(blurredField, values, initialValues);
    return new Promise((resolve, reject) => {
        const mergedErrors = {...asyncErrors, ...blurredFieldError};
        if (!isEmpty(mergedErrors)) {
            reject(mergedErrors);
            return null;
        }
        resolve(null);
    });
}
