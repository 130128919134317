import axios from 'axios';

import {store} from 'store';
import {getUserAccessToken} from 'store/reducers/auth/selectors';

const auctionApi = axios.create({
    baseURL: '/api/auction',
});

// add user auth token to each ajax request
auctionApi.interceptors.request.use(
    (config) => {
        const state = store.getState();
        const userAuthKey = getUserAccessToken(state);

        config.headers.Authorization = `Bearer ${userAuthKey}`;
        config.headers['Accept-Language'] = 'en';

        return config;
    },
    (error) => Promise.reject(error),
);

auctionApi.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error.response);
    },
);

export default auctionApi;
