import PERMISSIONS from 'utils/data/permissions';
import * as pages from 'utils/data/pages';

export default {
    pages: {
        [pages.TRUCKS]: {
            [PERMISSIONS.list]: true,
            [PERMISSIONS.view]: true,
            [PERMISSIONS.create]: true,
            [PERMISSIONS.update]: true,
            [PERMISSIONS.delete]: true,
        },
        [pages.OWNERS]: {
            [PERMISSIONS.list]: true,
            [PERMISSIONS.view]: true,
            [PERMISSIONS.create]: true,
            [PERMISSIONS.update]: true,
            [PERMISSIONS.delete]: true,
        },
        [pages.DRIVERS]: {
            [PERMISSIONS.list]: true,
            [PERMISSIONS.view]: true,
            [PERMISSIONS.create]: true,
            [PERMISSIONS.update]: true,
            [PERMISSIONS.delete]: true,
        },
        [pages.LOADS]: {
            [PERMISSIONS.list]: true,
            [PERMISSIONS.view]: true,
            [PERMISSIONS.create]: true,
            [PERMISSIONS.update]: true,
            [PERMISSIONS.delete]: true,
        },
        [pages.REPORTS]: {
            [PERMISSIONS.list]: true,
        },
        [pages.DISPATCHERS]: {
            [PERMISSIONS.list]: true,
            [PERMISSIONS.view]: true,
            [PERMISSIONS.create]: true,
            [PERMISSIONS.update]: true,
            [PERMISSIONS.delete]: true,
        },
        [pages.CARRIERS]: {
            [PERMISSIONS.list]: true,
            [PERMISSIONS.view]: true,
            [PERMISSIONS.create]: false,
            [PERMISSIONS.update]: false,
            [PERMISSIONS.delete]: false,
        },
        [pages.CUSTOMERS]: {
            [PERMISSIONS.list]: true,
            [PERMISSIONS.view]: true,
            [PERMISSIONS.create]: false,
            [PERMISSIONS.update]: false,
            [PERMISSIONS.delete]: false,
        },
        [pages.FACILITIES]: {
            [PERMISSIONS.list]: true,
            [PERMISSIONS.view]: true,
            [PERMISSIONS.create]: true,
            [PERMISSIONS.update]: true,
            [PERMISSIONS.delete]: true,
        },
        [pages.FACTORING_COMPANIES]: {
            [PERMISSIONS.list]: true,
            [PERMISSIONS.view]: true,
            [PERMISSIONS.create]: true,
            [PERMISSIONS.update]: true,
            [PERMISSIONS.delete]: true,
        },
        [pages.TRIP_MONITOR]: {
            [PERMISSIONS.list]: true,
        },
        [pages.INVOICES]: {
            [PERMISSIONS.list]: false,
        },
        [pages.SETTLEMENTS]: {
            [PERMISSIONS.list]: false,
            [PERMISSIONS.delete]: false,
        },
        [pages.LOGS]: {
            [PERMISSIONS.list]: true,
        },
        [pages.LOAD_BOARD]: {
            [PERMISSIONS.list]: true,
        },
    },

    menu: {
        notifications: true,
        chat: true,
        leftNav: [
            pages.TRUCKS,
            pages.TRUCKS_MAP,
            pages.TRUCKS_NEAR_BY,

            pages.LOAD_BOARD,
            pages.LOADS,
            pages.REPORTS,
            pages.TRIP_MONITOR,

            pages.DRIVERS,
            pages.DISPATCHERS,
            pages.OWNERS,

            pages.CARRIERS,
            pages.BROKERS,
            pages.CUSTOMERS,
            pages.FACILITIES,
            pages.FACTORING_COMPANIES,

            pages.LOGS,

            pages.RELEASE_NOTES,
        ],
    },

    actions: {
        [PERMISSIONS.deleteNotes]: true,
    },
};
