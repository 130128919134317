import invert from 'lodash/invert';

export type TemperatureUnits = 'c' | 'f';

export const CELCIUS: Extract<TemperatureUnits, 'c'> = 'c';
export const FAHRENHEIT: Extract<TemperatureUnits, 'f'> = 'f';

export const temperatureUnitLabel: Record<TemperatureUnits, string> = {
    c: '°C',
    f: '°F',
};

export const getTemperatureUnitByLabel = (label = '') => invert(temperatureUnitLabel)[label];
