import React from 'react';

const ClearAllFieldsForm = () => {
    return (
        <div className="row align-items-center">
            <div className="col-1">
                <i className="fa fa-exclamation feedback-icon-reset" />
            </div>
            <div className="col-11">
                <p>
                    The entered information will be lost,&nbsp; and you will be transferred to the first step in the
                    current form.
                </p>
            </div>
        </div>
    );
};

export default ClearAllFieldsForm;
