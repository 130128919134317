import {createDate, getRemainingTimeTo} from 'utils/dateTime';

import {Quote} from '../../types';

export const isQuoteExpired = (quote: Quote): boolean => {
    const {status, expireAt} = quote;

    if (status === 'EXPIRED') {
        return true;
    }

    const {originalDate, originalTime, timeZone} = createDate(expireAt, {fromISO: true});
    const {minutes} = getRemainingTimeTo(`${originalDate} ${originalTime}`, {timeZone});

    return minutes < 0;
};

export const isQuoteProcessing = (quote: Quote): boolean => {
    return quote.status === 'PROCESSING';
};

export const isQuoteSent = (quote: Quote): boolean => {
    return quote.notifiedDriversCount > 0;
};

export const isQuoteArchived = (quote: Quote): boolean => {
    return quote.status === 'ARCHIVED';
};
