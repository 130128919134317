import {customerTypesOptions, CustomerTypes} from '../constants';

type CustomerTypeOptions = {
    label: string;
    value: CustomerTypes;
};

export default (): CustomerTypeOptions[] => {
    return customerTypesOptions;
};
