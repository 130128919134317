import {tripMonitorActionCreators} from 'pages/TravelOrders/redux/actions/list';
import {setSSEData} from 'pages/TrucksNearby/redux/actions/list';

export const tripMonitorSSEEventHandler = ({dispatch, data}) => {
    dispatch(tripMonitorActionCreators.setSSEData({data}));
};

export const trucksNearbySSEEventHandler = ({dispatch, data}) => {
    dispatch(setSSEData({data}));
};
