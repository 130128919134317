import React from 'react';
import {Modal} from 'react-bootstrap';

import {getDriverName} from 'core/entities/Driver/modules/driverName';

import Button from 'components/ui/Buttons/Button/index';

const DeleteDriverModal = (props) => {
    const {
        data: {driver},
        handlers,
        onClose,
    } = props;

    const onDelete = () => {
        handlers.onDelete();
        onClose();
    };

    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Delete Driver</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-error" />
                    </div>
                    <div className="col-11">
                        <p className="mb10">
                            Please confirm that you want to delete&nbsp;
                            <strong>
                                <span onClick={onClose} className="second-link">
                                    {getDriverName(driver)}
                                </span>
                            </strong>
                            . <br />
                            After the confirmation, the profile will be achieved with the ability to restore.
                        </p>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <p>If you don't want to delete profile please press Cancel.</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} buttonSize="small" colorTheme="grey">
                    Cancel
                </Button>
                <Button onClick={onDelete} buttonSize="small" colorTheme="red">
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteDriverModal;
