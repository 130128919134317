import React, {Fragment, useCallback, useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';

import {createDate} from 'utils/dateTime';
import getFilePath from 'utils/getFilePath';

import Button from 'components/ui/Buttons/Button';
import ButtonLink from 'components/ui/Buttons/ButtonLink';
import CheckBoxInput from 'components/ui/Form/CheckBoxInput';
import {DispatcherLink} from 'components/ui/Links';

import styles from './fileItem.module.scss';

const PDF_FILE = 'application/pdf';

const getFileBadge = (file) => {
    if (isPlainObject(file.extra_data) && file.extra_data.badge) {
        return <span className="file-badge">{file.extra_data.badge}</span>;
    }
    let badge;
    try {
        const extraData = JSON.parse(file.extra_data);
        badge = extraData && extraData.badge;
    } catch (e) {
        badge = null;
    }
    if (badge) {
        return <span className="file-badge">{badge}</span>;
    }
    return null;
};

const FileItem = ({file, onChange, onView, showCheckBox, isChecked}) => {
    const handleChange = useCallback(() => onChange(file, isChecked), [file, isChecked]);
    const {real_name: fileRealName} = file;
    const pathToFile = getFilePath(file);
    const {fullDate: dateOfCreation} = createDate(file.created_at);
    const viewButton = useMemo(() => {
        const isPdfFile = file.type === PDF_FILE;

        if (isPdfFile) {
            return (
                <ButtonLink
                    href={getFilePath(file)}
                    target="_blank"
                    className="mr10"
                    buttonIcon="eye"
                    buttonSize="icon"
                />
            );
        }

        return <Button onClick={onView} className="mr10" buttonIcon="eye" buttonSize="icon" />;
    }, [file]);

    return (
        <div className={styles.list}>
            <div className="item-title">
                {showCheckBox ? (
                    <>
                        <CheckBoxInput
                            label={fileRealName}
                            input={{
                                value: isChecked,
                                onChange: handleChange,
                            }}
                            position="right"
                        />
                        {getFileBadge(file)}
                    </>
                ) : (
                    <>
                        <div className="ellips-text">{fileRealName}</div>
                        {getFileBadge(file)}
                    </>
                )}
            </div>
            <div className="item-post-data">
                <div className="author">
                    {isEmpty(file.dispatcher) ? (
                        <span>mobile app</span>
                    ) : (
                        <DispatcherLink
                            content={getDispatcherName(file.dispatcher)}
                            dispatcher={file.dispatcher}
                            className="second-link"
                        />
                    )}
                </div>
                <div className="post-date">{dateOfCreation}</div>
            </div>

            <div className="item-buttons">
                <Fragment>
                    {viewButton}
                    <a
                        href={pathToFile}
                        className="button button-with-icon button-grey button-icon"
                        download={fileRealName}
                    >
                        <i className="fa fa-download" aria-hidden="true" />
                    </a>
                </Fragment>
            </div>
        </div>
    );
};

export default FileItem;
