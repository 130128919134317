import {useDispatch} from 'react-redux';
import {useEffect} from 'react';

import * as actions from '../redux/actions';

const useFetchNotifications = (): void => {
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchQuotesAction = actions.fetchLoadBoardNotifications();
        dispatch(fetchQuotesAction);
    }, []);
};

export default useFetchNotifications;
