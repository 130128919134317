import React, {useEffect} from 'react';
import {reduxForm, Field, InjectedFormProps} from 'redux-form';

import useAttachActions from 'pages/Loads/components/common/modals/AttachModal/hooks/useAttachActions';
import useAttachState from 'pages/Loads/components/common/modals/AttachModal/hooks/useAttachState';
import {CarriersSearchFormValues} from 'pages/Loads/types/formTypes';

import useChangeReduxFormValues from 'hooks/reduxForm/useChangeReduxFormValues';
import useResetReduxForm from 'hooks/reduxForm/useResetReduxForm';

import TooltipBootstrap from 'components/ui/TooltipBootstrap';
import TextInput from 'components/ui/Form/TextInput';
import Button from 'components/ui/Buttons/Button';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<CarriersSearchFormValues>();

const CarriersSearchForm: React.FC<{} & InjectedFormProps<{}, {}>> = (props) => {
    const {handleSubmit, pristine, submitting, form} = props;

    const initCarriersSearchFormValues = useChangeReduxFormValues(form);
    const {resetSearchCarriersParams} = useAttachActions();
    const {carriersSearchParams} = useAttachState();
    const resetForm = useResetReduxForm(form);

    useEffect(() => {
        initCarriersSearchFormValues(carriersSearchParams);
    }, []);

    const handleResetFields = () => {
        resetSearchCarriersParams();
        resetForm();
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field placeholder="Company Name" name={getName('company_name')} component={TextInput} />

            <div className="mr10" />

            <TooltipBootstrap tooltipContent="clear fields">
                <Button
                    onClick={handleResetFields}
                    disabled={pristine}
                    buttonSize="icon"
                    colorTheme="grey"
                    buttonIcon="undo"
                />
            </TooltipBootstrap>

            <div className="mr5" />

            <Button disabled={pristine || submitting} type="submit" buttonSize="small">
                Apply
            </Button>
        </form>
    );
};

export default reduxForm({})(CarriersSearchForm);
