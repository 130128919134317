import React, {useEffect, useState} from 'react';
import {Field} from 'redux-form';

import {FIELDS} from 'core/entities/Customer/constants/fields';
import {CUSTOMER_TYPES} from 'core/entities/Customer/constants/customerTypes';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';
import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';

import RequiredLabel from 'components/ui/Form/RequiredLabel';
import Select from 'components/ui/Form/Select';
import TextInput from 'components/ui/Form/TextInput';

import useGetCustomerTypesOptions from '../../hooks/useGetCustomerTypesOptions';

interface OwnProps {
    form: string;
    disabled?: boolean;
}

const CustomerTypes: React.FC<OwnProps> = ({form, disabled}) => {
    const selectedCustomerType = useGetReduxFormValue(form, FIELDS.customerType);
    const changeFormValue = useChangeReduxFormValue(form);
    const customerTypesOptions = useGetCustomerTypesOptions();
    const [viewCustomerTypesNote, setViewCustomerTypesNote] = useState<boolean>(false);

    useEffect(() => {
        if (selectedCustomerType === CUSTOMER_TYPES.OTHER) {
            setViewCustomerTypesNote(true);
        } else {
            setViewCustomerTypesNote(false);
            changeFormValue(FIELDS.customerTypeNote, null);
        }
    }, [selectedCustomerType]);

    return (
        <div className="row">
            <div className="col-md-6 col-lg-3">
                <Field
                    name={FIELDS.customerType}
                    type="text"
                    component={Select}
                    label={RequiredLabel('Customer type')}
                    placeholder="choose customer type"
                    data={customerTypesOptions}
                    isDisabled={disabled}
                />
            </div>
            {viewCustomerTypesNote ? (
                <div className="col-md-6 col-lg-3">
                    <Field
                        name={FIELDS.customerTypeNote}
                        type="text"
                        component={TextInput}
                        label={RequiredLabel('Other Customer type')}
                        placeholder="enter customer type"
                        maxLength={30}
                        disabled={disabled}
                    />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default CustomerTypes;
