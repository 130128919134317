export const reserveActions = {
    reserveForTime: 'reserveForTime',
    holdForRC: 'holdForRC',
    transfer: 'transfer',
};

export const reservingFields = {
    reservationMinutes: 'reservationMinutes',
    transferDispatcher: 'transferDispatcher',
    reserveAction: 'reserveAction',
};

export const truckFields = {
    dispatcher_note_updated_at: 'dispatcher_note_updated_at',
    dispatcher_note_creator: 'dispatcher_note_creator',
    dispatcher_note: 'dispatcher_note',
};

// 15 min
export const RESERVATION_DEFAULT_VALUE = 15;
