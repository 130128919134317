import {SettlementOwner} from 'core/entities/Settlement/types';
import {fetchResponsibleUserSettlements} from 'core/entities/Settlement/requests/settlementOwnerRequests';

import useFetchWithPaginate from 'hooks/useFetchWithPaginate';

interface FetchData {
    settlements: SettlementOwner[];
    error: string | undefined;
    currentPage: number | undefined;
    isFetching: boolean;
    isAllFetched: boolean;
    fetchNextPage();
}

const PER_PAGE = 10;

const fetchUserSettlements = (
    payPeriodID: number,
    userID: number,
    {page, perPage}: {page: string; perPage: number},
): Promise<SettlementOwner[]> => {
    const nextPage = parseInt(page, 10) + 1;
    return fetchResponsibleUserSettlements(payPeriodID, userID, {page: nextPage, perPage})
        .then((response) => response.data)
        .catch((err) => {
            console.error('Error on fetch pay period responsible user settlements: ', err);
            return [];
        });
};

const cacheCreator = ({userID}: {userID: number}) => {
    return (pageIndex: any, previousPageData: any) => {
        if (previousPageData && previousPageData.length === 0) {
            return null;
        }
        const pageNumber = String(pageIndex);
        const cacheKey = `responsible-user-${userID}-settlements-${pageNumber}`;
        return [cacheKey, pageNumber];
    };
};

const fetchCreator = ({payPeriodID, userID}: {payPeriodID: number; userID: number}) => {
    return (_, pageNumber) => {
        return fetchUserSettlements(payPeriodID, userID, {page: pageNumber, perPage: PER_PAGE});
    };
};

export default ({payPeriodID, responsibleUserID}: {payPeriodID: number; responsibleUserID: number}): FetchData => {
    const getCacheKey = cacheCreator({userID: responsibleUserID});
    const fetcher = fetchCreator({payPeriodID, userID: responsibleUserID});
    const {data: settlements, error, currentPage, isFetching, isAllFetched, fetchNextPage} = useFetchWithPaginate({
        perPage: PER_PAGE,
        getKey: getCacheKey,
        fetchData: fetcher,
    });
    return {
        settlements,
        error,
        currentPage,
        isFetching,
        isAllFetched,
        fetchNextPage,
    };
};
