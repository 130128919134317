import React from 'react';

import {Dispatcher} from 'core/entities/Dispatcher/types';

import ArchivingErrorWrapper from 'components/ui/modals/ArchivingErrorWrapper';

type OwnProps = {
    data: {dispatcher: Dispatcher};
    onClose();
};

const ArchivingError = (props: OwnProps): JSX.Element => {
    const {
        data: {dispatcher},
        onClose,
    } = props;
    return (
        <ArchivingErrorWrapper onClose={onClose}>
            <div>
                <p>
                    The User in the <strong>uncompleted load</strong>. Moving the User to the archive is not possible.
                    Please, waiting for finishing the travel order or change User in load.
                </p>
            </div>
        </ArchivingErrorWrapper>
    );
};

export default ArchivingError;
