import TruckHasExpiredDocumentsError from './TruckHasExpiredDocumentsError';
import UnsuccessfulMergedCustomers from './UnsuccessfulMergedCustomers';
import SuccessfulMergedCustomers from './SuccessfulMergedCustomers';
import UnsuccessfulAddedFiles from './UnsuccessfulAddedFiles';
import SuccessfulAddedFiles from './SuccessfulAddedFiles';

const notifications = {
    truckHasExpiredDocumentsError: TruckHasExpiredDocumentsError,
    customersUnsuccessfulMerged: UnsuccessfulMergedCustomers,
    customersSuccessfulMerged: SuccessfulMergedCustomers,
    filesUnsuccessfulAdded: UnsuccessfulAddedFiles,
    filesSuccessfulAdded: SuccessfulAddedFiles,
};

export default notifications;
