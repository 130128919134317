import {Facility} from 'core/entities/Facility/types';

import restapi, {fetchFiles} from 'services/restapi';
import {getGeneralNotes, postGeneralNote} from 'services/restapi/generalNotes';

import {ENTITY_NAME_FACILITIES_API_SIDE} from 'utils/data/entityNames';

const URL = `/${ENTITY_NAME_FACILITIES_API_SIDE}`;

/**
 *
 * @param {number} facilityId
 * @return {Promise}
 */
export function getFacilityById(facilityId) {
    return restapi(`${URL}/${facilityId}`);
}

/**
 *
 * @param {Object} [params]
 * @return {Promise}
 */
export function getFacilities(params) {
    const {isMinRequest, ...restParams} = params;

    if (isMinRequest) {
        restParams._min = 1;
    }

    return restapi(URL, {params: {...restParams}});
}

/**
 *
 * @param {Object} params
 * @return {Promise}
 */
export function searchFacilities(params) {
    return restapi.post(`${URL}/search`, params);
}

/**
 *
 * @param {Object} params
 * @return {Promise}
 */
export function searchFacilitiesByTitle(params) {
    return restapi.post(`${URL}/title/search`, params);
}

export function searchFacilityDuplicates(facility: Facility): Promise<Facility[]> {
    const searchParams = {
        title: facility.name?.trim(),
        address_line: facility.address_line?.trim(),
        city: facility.city?.trim(),
        state: facility.state?.trim(),
    };
    return restapi
        .post(`${URL}/merge/search`, searchParams)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.error('Error on search facilities duplicates: ', err);
            return [];
        });
}

/**
 *
 * @param {Object} data
 * @return {Promise}
 */
export function postFacility(data) {
    return restapi.post(URL, data);
}

/**
 *
 * @param {number} facilityId
 * @param {Object} data
 * @return {Promise}
 */
export function putFacility(facilityId, data) {
    return restapi.put(`${URL}/${facilityId}`, data);
}

/**
 *
 * @param {number} facilityId
 * @return {Promise}
 */
export function toggleFacilityArchivedStatus(facilityId) {
    return restapi.delete(`${URL}/${facilityId}`);
}

type ValidateResult = {
    result: 'error' | 'success';
    errors?: {isFacilityAttachedToUncompletedLoad?: boolean; isFacilityAttachedToUncompletedInvoice?: boolean};
};
export const validateArchiveFacility = (facility: Facility): Promise<ValidateResult> => {
    return restapi
        .get(`${URL}/${facility.id}/validate-archive`)
        .then<ValidateResult>(() => {
            return {result: 'success'};
        })
        .catch<ValidateResult>((error) => {
            const APP_DOMAIN_LOGIC_ERROR_CODE = 422;
            const APP_DOMAIN_LOGIC_ERROR_FACILITY_ATTACHED_TO_UNCOMPLETED_LOAD = 'UNCOMPLETED_LOAD';
            const APP_DOMAIN_LOGIC_ERROR_FACILITY_ATTACHED_TO_UNCOMPLETED_INVOICE = 'UNCOMPLETED_INVOICE';

            if (error.status !== APP_DOMAIN_LOGIC_ERROR_CODE) {
                throw error;
            }

            const errorType = error.data?.type;

            if (errorType === APP_DOMAIN_LOGIC_ERROR_FACILITY_ATTACHED_TO_UNCOMPLETED_LOAD) {
                return {result: 'error', errors: {isFacilityAttachedToUncompletedLoad: true}};
            }

            if (errorType === APP_DOMAIN_LOGIC_ERROR_FACILITY_ATTACHED_TO_UNCOMPLETED_INVOICE) {
                return {result: 'error', errors: {isFacilityAttachedToUncompletedInvoice: true}};
            }

            console.error('Unknown domain logic error: ', error);
            throw error;
        });
};

export function archiveFacility(facility: Facility): Promise<Facility> {
    return restapi.put(`${URL}/${facility.id}/archive`).then(() => ({...facility, is_deleted: true}));
}

export function restoreFacility(facility: Facility): Promise<Facility> {
    return restapi.put(`${URL}/${facility.id}/restore`).then(() => ({...facility, is_deleted: false}));
}

/**
 *
 * @param {number} [facilityId]
 * @return {Promise}
 */
export function getFacilityNotes(facilityId?) {
    const params = {entity_name: ENTITY_NAME_FACILITIES_API_SIDE, entity_id: facilityId};
    return getGeneralNotes(params).then(({data}) => data);
}

/**
 *
 * @param {number} facilityId
 * @param {string} text
 * @return {Promise}
 */
export function postFacilityNote(facilityId, text) {
    const params = {
        entity_name: ENTITY_NAME_FACILITIES_API_SIDE,
        entity_id: facilityId,
        text,
    };
    return postGeneralNote(params).then(({data}) => data);
}

/**
 *
 * @param {number} facilityId
 * @return {Promise}
 */
export function getFacilityFiles(facilityId) {
    const params = {
        id: facilityId,
        entity: ENTITY_NAME_FACILITIES_API_SIDE,
    };
    return fetchFiles(params).then((res) => {
        if ('data' in res) {
            return res.data;
        }
    });
}

export default {
    getFacilityById,
    getFacilities,
    searchFacilities,
    searchFacilitiesByTitle,
    postFacility,
    putFacility,
    toggleFacilityArchivedStatus,
    getFacilityNotes,
    postFacilityNote,
    getFacilityFiles,
};
