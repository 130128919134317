import React from 'react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import {InvoiceLink} from 'components/ui/Links';

import Invoice from 'core/entities/Invoice/types';
import {getInvoiceStatusName} from 'core/entities/Invoice';

import ResetBadge from './components/ResetBadge';
import NoPodBadge from './components/NoPodBadge';

interface OwnProps {
    invoice: Invoice;
    isDisabled?: boolean;
    children?: JSX.Element;
}

const InvoiceNumberInfo = (props: OwnProps): JSX.Element => {
    const {invoice, isDisabled = false, children} = props;
    const invoiceStatus = getInvoiceStatusName(invoice);
    const isCurrentInvoiceResetFromAnyBatch = !isEmpty(invoice.reseted_from_batches);
    const mainClass = classNames('main-link', {disabled: isDisabled});
    return (
        <>
            <div>
                <div>
                    <ResetBadge invoiceStatus={invoiceStatus} isInvoiceReset={isCurrentInvoiceResetFromAnyBatch} />
                </div>
                <div>
                    <strong>
                        <InvoiceLink invoice={invoice} content={invoice.invoice_number} className={mainClass} />
                    </strong>{' '}
                    {children}
                </div>
            </div>

            <NoPodBadge invoice={invoice} />
        </>
    );
};

export default React.memo(InvoiceNumberInfo);
