import React from 'react';
import {Modal} from 'react-bootstrap';
import isPlainObject from 'lodash/isPlainObject';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

import {LocationEventData} from 'core/entities/LocationEvents/types';
import {getConvertedFormattedTime} from 'core/entities/LocationEvents/modules/eventDateTime';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';

import Button from 'components/ui/Buttons/Button';

import {getCityLineFromObject} from 'utils/getCityLine';

type OwnProps = {
    data: {
        event: LocationEventData;
    };
    handlers: {
        onBan: () => void;
    };
    onClose: () => void;
};

const BanEventModal: React.FC<OwnProps> = ({
    data: {
        event: {
            eventCreatedAt,
            whoCreatedEvent,
            location: {cityLine, timeFrom},
            locationForBroker: {cityLineForBroker, timeFromForBroker},
        },
    },
    handlers: {onBan},
    onClose,
}) => {
    const isCityLineForBroker = !isNull(cityLineForBroker) && !isEmpty(cityLineForBroker);
    const isDataForBroker = isCityLineForBroker && timeFromForBroker;

    const convertedTimeFrom = getConvertedFormattedTime(timeFrom);
    const convertedTimeCreatedAt = getConvertedFormattedTime(eventCreatedAt);
    const convertedTimeForBroker = timeFromForBroker ? getConvertedFormattedTime(timeFromForBroker) : null;

    const convertCityLine = getCityLineFromObject(cityLine);
    const convertCityLineForBroker = cityLineForBroker ? getCityLineFromObject(cityLineForBroker) : null;

    const createdEventPerson = isPlainObject(whoCreatedEvent) ? getDispatcherName(whoCreatedEvent) : 'mobile store';

    return (
        <Modal show={true} onHide={onClose} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <Modal.Title>Delete Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-error" />
                    </div>
                    <div className="col-11">
                        <div className="modal-body__content">
                            <p className="mb-2">Are you sure you want to delete this event?</p>
                            <p>
                                <strong>Check Call</strong> with location:
                                <br />
                                {convertCityLine} {convertedTimeFrom}
                            </p>
                            {isDataForBroker ? (
                                <p>
                                    <strong>Location for broker</strong>:
                                    <br />
                                    {convertCityLineForBroker} {convertedTimeForBroker}
                                </p>
                            ) : null}
                            <p>
                                Event added {convertedTimeCreatedAt} by <strong>{createdEventPerson}</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onBan} type="submit" colorTheme="red">
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BanEventModal;
