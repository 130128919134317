import * as truckStatuses from './constants/truckStatuses';

export const isTruckStatusWillBeAvailable = (status: string): boolean =>
    status === truckStatuses.TRUCK_STATUS_WILL_BE_AVAILABLE_AT;
export const isTruckStatusAvailable = (status: string): boolean => status === truckStatuses.TRUCK_STATUS_AVAILABLE;
export const isTruckStatusOnRoute = (status: string): boolean => status === truckStatuses.TRUCK_STATUS_ON_ROUTE;

export function checkCanTruckStatusBeUpdated(status: string): boolean {
    const truckStatusesForStatusModal = [
        truckStatuses.TRUCK_STATUS_AVAILABLE,
        truckStatuses.TRUCK_STATUS_NOT_AVAILABLE,
        truckStatuses.TRUCK_STATUS_WILL_BE_AVAILABLE_AT,
    ];

    return truckStatusesForStatusModal.includes(status);
}
