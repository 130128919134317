import React from 'react';

import styles from '../../styles.module.scss';

const InfoMessage: React.FC = () => (
    <div className={styles.info}>
        <i className="fa fa-exclamation-circle" />
        <p>
            <strong>ON Automation update check call</strong>
            <br />
            <span>
                In automatic mode, events are generated according to specified parameters and automatically sent to the
                broker. If any problems arise that prevent events from being generated, the system will notify you.
            </span>
        </p>
    </div>
);

export default InfoMessage;
