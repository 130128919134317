import groupBy from 'lodash/groupBy';
import difference from 'lodash/difference';

import Files, {GroupedFilesBilling} from 'types/File';

import {deleteFile, postFiles} from 'services/restapi';

import {FILE_TYPES} from 'deprecated/core/entities/TravelOrder/constants/travel-order';
import {fetchTravelOrderFiles} from 'deprecated/core/gateways/TravelOrderApiGateway/requests';

/**
 * @typedef {Object} GroupedTravelOrderFiles
 * @property {Array.<Object>} rc array of travel order rc files or empty array
 * @property {Array.<Object>} bol array of travel order bol files or empty array
 * @property {Array.<Object>} pod array of travel order pod files or empty array
 * @property {Array.<Object>} rc_signed array of travel order signed files or empty array
 * @property {Array.<Object>} generated_rc array of travel order generated files or empty array
 * */
/**
 * Converts all files of travel order entity to map with attach type as key.
 * @param {Array.<Object>} allTravelOrderFiles
 * @returns {GroupedTravelOrderFiles}
 */
export const getTravelOrderFilesMap = (allTravelOrderFiles: Files[]): GroupedFilesBilling => {
    const groupedLoadFiles = groupBy(allTravelOrderFiles, 'attach_type');
    return {
        rc: groupedLoadFiles[FILE_TYPES.rc] || [],
        bol: groupedLoadFiles[FILE_TYPES.bol] || [],
        pod: groupedLoadFiles[FILE_TYPES.pod] || [],
        rc_signed: groupedLoadFiles[FILE_TYPES.rcSigned] || [],
        generated_rc: groupedLoadFiles[FILE_TYPES.rcGenerated] || [],
    };
};

const postTravelOrderFiles = (travelOrderID, files, type) =>
    postFiles(
        files.map((item) => item.file),
        {
            id: travelOrderID,
            entity: 'travel-order',
            type,
            aliases: files
                .map((item) => {
                    const {
                        alias,
                        file: {name},
                    } = item;
                    if (alias && name) {
                        return {alias, fileName: name};
                    }
                    return null;
                })
                .filter(Boolean),
            extraData: files
                .map((item) => {
                    const {
                        extra_data,
                        file: {name},
                    } = item;
                    if (extra_data && name) {
                        return {extraData: extra_data, fileName: name};
                    }
                    return null;
                })
                .filter(Boolean),
        },
    );

export const postRCSignedFiles = (signedFiles, toID) => {
    if (signedFiles.length === 0) {
        return {
            [FILE_TYPES.rcSigned]: [],
        };
    }

    return postTravelOrderFiles(toID, signedFiles, FILE_TYPES.rcSigned);
};

export const postBolFiles = (bolFiles, toID) => {
    if (bolFiles.length === 0) {
        return {
            [FILE_TYPES.bol]: [],
        };
    }

    return postTravelOrderFiles(toID, bolFiles, FILE_TYPES.bol);
};

export const postPodFiles = (podFiles, toID) => {
    if (podFiles.length === 0) {
        return {
            [FILE_TYPES.pod]: [],
        };
    }

    return postTravelOrderFiles(toID, podFiles, FILE_TYPES.pod);
};

const postTOFile = (travelOrderID, fileType, newFiles) => {
    if (fileType === FILE_TYPES.bol) {
        return postBolFiles(newFiles, travelOrderID);
    }
    if (fileType === FILE_TYPES.pod) {
        return postPodFiles(newFiles, travelOrderID);
    }

    return postRCSignedFiles(newFiles, travelOrderID);
};

export const updateTravelOrderFiles = async (
    travelOrderID,
    currentFiles,
    updatedFiles,
    fileType,
    options = {refetchAfterUpdate: true},
) => {
    const getFilesIds = (files) => files.map((file) => file.id).filter(Boolean);
    const getDeletedFilesID = (currentFilesList, updatedFilesList) =>
        difference(getFilesIds(currentFilesList), getFilesIds(updatedFilesList));
    const getNewFiles = (files) => files.filter((obj) => obj.file instanceof File);
    const deletedFilesID = getDeletedFilesID(currentFiles, updatedFiles);

    const newFiles = getNewFiles(updatedFiles);
    const requests: unknown[] = [];

    if (newFiles.length > 0) {
        requests.push(postTOFile(travelOrderID, fileType, newFiles));
    }

    // if (deletedFilesID.length > 0) {
    //     deletedFilesID.forEach((id) => requests.push(deleteFile(id)));
    // }

    await Promise.all(requests);

    if (options.refetchAfterUpdate) {
        return fetchTravelOrderFiles(travelOrderID, fileType);
    }
};
