import {useEffect, useRef, useState} from 'react';

import File from 'types/File';
import {fetchBatchFiles} from 'core/entities/Invoice/requests';
import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import {isAllFetched} from './utils';

interface FetchResult {
    files: File[];
    isFetching: boolean;
    isAllFetched: boolean;
    fetchNextPage();
}

interface FetchParams {
    batchNumber: number;
    pagination: {page: number; perPage: number};
}

const PER_PAGE = 10;

const fetchFiles = (params: FetchParams): Promise<{files: File[]; pagination: any}> => {
    const {batchNumber, pagination} = params;
    return fetchBatchFiles(batchNumber, pagination)
        .then((response) => {
            return {files: response.data, pagination: parsePaginationHeaders(response.headers)};
        })
        .catch((error) => {
            console.error('Error on fetch batch files: ', error);
            return {files: [], pagination: parsePaginationHeaders(error.headers)};
        });
};

export default ({batchNumber}: {batchNumber: number}): FetchResult => {
    const ref = useRef({isAllFetched: false, currentPage: 1});
    const [files, setFiles] = useState<File[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const fetchData = (): void => {
        setIsFetching(true);
        fetchFiles({
            batchNumber,
            pagination: {page: ref.current.currentPage, perPage: PER_PAGE},
        })
            .then((response) => {
                ref.current.isAllFetched = isAllFetched(response.pagination, response.files, PER_PAGE);
                setFiles([...files, ...response.files]);
            })
            .finally(() => {
                setIsFetching(false);
            });
    };
    const fetchNextPage = (): void => {
        ref.current.currentPage += 1;
        fetchData();
    };
    useEffect(() => {
        fetchData();
    }, []);

    return {
        files,
        isFetching,
        isAllFetched: ref.current.isAllFetched,
        fetchNextPage,
    };
};
