import React from 'react';

import {Facility} from 'core/entities/Facility/types';

import ArchivingErrorWrapper from 'components/ui/modals/ArchivingErrorWrapper';

type OwnProps = {
    data: {facility: Facility};
    onClose();
};

const ArchivingError = (props: OwnProps): JSX.Element => {
    const {
        data: {facility},
        onClose,
    } = props;
    return (
        <ArchivingErrorWrapper onClose={onClose}>
            <div>
                <p>
                    The facility is in the <strong>incompleted load.</strong> Moving the facility to the archive is not
                    possible. Please wait for completion of the Travel Order.
                </p>
            </div>
        </ArchivingErrorWrapper>
    );
};

export default ArchivingError;
