import React from 'react';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';
import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';
import {LoadStop} from 'pages/LoadBoard/types';

import Title from '../common/Title';
import Location from '../common/Location';
import TimeFrames from '../common/TimeFrames';
import Timezone from '../common/Timezone';
import DateTime from '../common/DateTime';
import StopActions from '../common/StopActions';

import useRecalculateTimeZones from './hooks/useRecalculateTimeZones';
import {pickupTimeFrames, deliveryTimeFrames} from './utils';
import styles from './styles.module.scss';

type OwnProps = {
    index: number;
    stopNumber: number;
    fields: any;
    isLastItem: boolean;
    expandedStops: number[];
    toggleStops: (index: number) => void;
    onCreatePickUp: () => void;
    onCreateDelivery: () => void;
};

const Stop: React.FC<OwnProps> = ({
    index,
    stopNumber,
    fields,
    expandedStops,
    toggleStops,
    isLastItem,
    onCreatePickUp,
    onCreateDelivery,
}) => {
    const {
        getStopDateFromFieldName,
        getStopDateToFieldName,
        getStopTimeFrameFieldName,
        getStopTimezoneFieldName,
        getStopCityLineFieldName,
        getStopAddressCityFieldName,
        getStopAddressStateFieldName,
        getStopAddressZipFieldName,
        getStopAddressCountryFieldName,
        getStopCoordinatesLatFieldName,
        getStopCoordinatesLngFieldName,
    } = getFormFieldNames();

    const timeFrame = useGetReduxFormValue(FORM_NAME, getStopTimeFrameFieldName(index));
    const isExpanded = expandedStops.includes(index);
    const stop: LoadStop = fields.get(index);
    const isPickupStop = stop.type === 'PICKUP';
    const isFirstStop = index === 0;
    const onToggleExpand = (): void => toggleStops(index);

    const orderNumber = index + 1;

    const timeFrames = isPickupStop ? pickupTimeFrames : deliveryTimeFrames;
    const title = isPickupStop ? `Pick Up #${stopNumber}` : `Delivery #${stopNumber}`;
    const titleStyle = isPickupStop ? styles.tripPickUp : styles.tripDelivery;
    const stopBlockToggleClass = isExpanded ? '' : styles.stopInfoToggle;

    useRecalculateTimeZones(FORM_NAME, index);

    return (
        <div className="container pl-0 pr-0">
            <Title label={title} stopNumber={orderNumber} className={titleStyle}>
                <StopActions
                    currentItemIndex={index}
                    fields={fields}
                    isExpanded={isExpanded}
                    isLast={isLastItem}
                    onToggleExpand={onToggleExpand}
                    onCreatePickUp={onCreatePickUp}
                    onCreateDelivery={onCreateDelivery}
                />
            </Title>

            <div className={`row ${styles.stopInfo} ${stopBlockToggleClass}`}>
                <div className="col-sm">
                    <Location
                        cityLineFieldName={getStopCityLineFieldName(index)}
                        cityFieldName={getStopAddressCityFieldName(index)}
                        stateFieldName={getStopAddressStateFieldName(index)}
                        zipFieldName={getStopAddressZipFieldName(index)}
                        countryFieldName={getStopAddressCountryFieldName(index)}
                        latitudeFieldName={getStopCoordinatesLatFieldName(index)}
                        longitudeFieldName={getStopCoordinatesLngFieldName(index)}
                        label="Location"
                        isDisabled={isFirstStop}
                    />
                    <Timezone name={getStopTimezoneFieldName(index)} isDisabled={isFirstStop} />
                </div>

                <div className="col-sm">
                    <TimeFrames timeFrames={timeFrames} name={getStopTimeFrameFieldName(index)} />

                    <DateTime
                        timeFrame={timeFrame}
                        dateFromFiledName={getStopDateFromFieldName(index)}
                        dateToFiledName={getStopDateToFieldName(index)}
                    />
                </div>
            </div>
        </div>
    );
};

export default Stop;
