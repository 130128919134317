import map from 'lodash/map';
import head from 'lodash/head';
import last from 'lodash/last';
import omit from 'lodash/omit';
import values from 'lodash/values';

import {isTravelOrderFinished} from 'core/entities/TravelOrder/modules/travelOrderStatus';
import * as stopModules from 'core/entities/TravelOrder/modules/travelOrderStops';
import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';
import {HistoryPoint, TruckOnMap} from 'core/entities/Truck/types';
import {SearchTrucksOnMapDTO} from 'core/useCases/Truck/types';
import TravelOrder from 'core/entities/TravelOrder/types';

import {TRUCK_WITH_REEFER, TRUCK_WITHOUT_REEFER} from 'pages/TrucksMap/constants';
import {LoadStopInfo, SearchFormValues} from 'pages/TrucksMap/types';

type TruckWithCurrentStatus = ({isCurrent?: boolean} & TruckOnMap)[] | [];

export const transformCoordinates = ({
    lat,
    lng,
}: Partial<{lat?: number | string; lng?: number | string}>): {
    lat: number;
    lng: number;
} | null => {
    if (!lat || !lng) {
        return null;
    }

    return {lat: Number(lat), lng: Number(lng)};
};

export const getUniqPoints = (points: HistoryPoint[]): HistoryPoint[] => {
    const getMilliseconds = (time) => new Date(time).getTime();
    const liftUpNewestPoint = (point1, point2) => getMilliseconds(point2.time) - getMilliseconds(point1.time);
    const pointsSortedByDesc = points.sort(liftUpNewestPoint);

    const equalPointCondition = (point) => (item) => item.lat === point.lat && item.lng === point.lng;
    const getIndexOfEqualPoint = (point) => pointsSortedByDesc.findIndex(equalPointCondition(point));
    const uniqPoints = pointsSortedByDesc.filter((point, i) => getIndexOfEqualPoint(point) === i);

    return uniqPoints.reverse();
};

export const sliceFirstAndLastPoints = (historyPoints: HistoryPoint[]) => {
    const firstPoint = head(historyPoints);
    const lastPoint = last(historyPoints);
    const historyPointsWithoutFirstAndLast = historyPoints ? historyPoints.slice(1, -1) : [];

    const firstPointPosition = transformCoordinates({lat: firstPoint?.lat, lng: firstPoint?.lng});
    const lastPointPosition = transformCoordinates({lat: lastPoint?.lat, lng: lastPoint?.lng});

    return {
        firstPoint,
        lastPoint,
        firstPointPosition,
        lastPointPosition,
        historyPointsWithoutFirstAndLast,
    };
};

export const isEmptyObjectValues = (obj): boolean => !values(obj).filter(Boolean).length;

export const checkSearchedTrucks = (newTrucks: TruckOnMap[] | [], oldTrucks: TruckWithCurrentStatus): TruckOnMap[] => {
    // find truck with current status in the all trucks witch displaying on the map in the current moment
    const truckWitCurrentStatus = oldTrucks.find((truck) => truck.isCurrent);

    if (!truckWitCurrentStatus) {
        return newTrucks;
    }

    // find intersection between new trucks and truck with current status by key - [ID]
    const intersectionTruck = newTrucks.find((truck) => truck.id === truckWitCurrentStatus.id);

    if (!intersectionTruck) {
        return [...newTrucks, truckWitCurrentStatus];
    }

    // if intersection matched - exclude truck with current status from new trucks
    const skippedIntersectionTruck = newTrucks.filter((truck) => truck.id !== intersectionTruck?.id);
    return [...skippedIntersectionTruck, truckWitCurrentStatus];
};

export const checkSearchParams = (params: SearchFormValues): boolean => {
    return (
        !params.truckStatus.length &&
        !params.truckType.length &&
        !params.truckTrailerType.length &&
        !params.truckReeferType.length
    );
};

export const transformSearchParamsBeforeRequest = (searchParams: SearchFormValues): SearchTrucksOnMapDTO => {
    const searchParamsWithOutReeferType = omit(searchParams, 'truckReeferType');

    const withReefer = searchParams?.truckReeferType?.includes(TRUCK_WITH_REEFER);
    const withOutReefer = searchParams?.truckReeferType?.includes(TRUCK_WITHOUT_REEFER);

    if ((withReefer && withOutReefer) || (!withReefer && !withOutReefer)) {
        return searchParamsWithOutReeferType;
    }

    if (withReefer) {
        return {...searchParamsWithOutReeferType, isTruckReefer: true};
    }

    return {...searchParamsWithOutReeferType, isTruckReefer: false};
};

export const isIntersectionReeferType = ({
    searchParams,
    isReefer,
}: {
    searchParams: SearchFormValues;
    isReefer: boolean | null;
}) => {
    const withReefer = searchParams?.truckReeferType?.includes(TRUCK_WITH_REEFER);
    const withOutReefer = searchParams?.truckReeferType?.includes(TRUCK_WITHOUT_REEFER);

    if (withReefer && withOutReefer) {
        return true;
    }

    if (withReefer && isReefer) {
        return true;
    }

    if (withOutReefer && !isReefer) {
        return true;
    }

    return false;
};

export const getTrucksWithClearedCurrentStatus = (
    trucks: TruckWithCurrentStatus,
    searchParams: SearchFormValues,
): TruckOnMap[] | [] => {
    const truckWithCurrentStatus = trucks.find((truck) => truck.isCurrent);

    if (!truckWithCurrentStatus) {
        return trucks;
    }

    const {truckStatus, truckType, truckTrailerType} = searchParams;
    const {status, type, trailer_type, is_reefer} = truckWithCurrentStatus;

    const isIntersectionStatus = truckStatus.includes(status);
    const isIntersectionType = type && truckType.includes(type);
    const isIntersectionTrailerType = trailer_type && truckTrailerType.includes(trailer_type);

    const isIntersection =
        isIntersectionStatus ||
        isIntersectionType ||
        isIntersectionTrailerType ||
        isIntersectionReeferType({searchParams, isReefer: is_reefer});

    if (isIntersection) {
        return map(trucks, (truck) => omit(truck, ['isCurrent']));
    }

    return trucks.filter((truck) => truck.id !== truckWithCurrentStatus.id);
};

export const checkTrucksBeforeRemove = (trucks: TruckWithCurrentStatus): TruckOnMap[] => {
    const truckWitCurrentStatus = trucks.find((truck) => truck.isCurrent);

    if (!truckWitCurrentStatus) {
        return [];
    }

    return [truckWitCurrentStatus];
};

export const getLoadStopInfo = (stop: TravelOrderStop, travelOrder: TravelOrder): LoadStopInfo => {
    const stopTime = stopModules.getTravelOrderStopLoadStopDateTimeByID({stopID: stop.id, travelOrder});
    const {isStopCompleted} = stopModules.getTravelOrderStopStatusesByID({travelOrder, stopID: stop.id});
    const {isPickup, facility, facilityLocation} = stopModules.getTravelOrderStopLoadStopInfo(stop);
    const {orderNumberByStops} = stopModules.getTravelOrderStopInfo(stop);

    const coordinates = {lat: facility?.latitude, lng: facility?.longitude};
    const location = facilityLocation;
    const stopType = stop.type;

    const dateAndTime = `${stopTime.dateEndInTheClientFormat} ${stopTime.timeEndInTheClientFormat} ${stopTime.timeZoneCode}`;

    return {
        stopNumberOfFullPath: orderNumberByStops,
        isCompletedStop: isStopCompleted,
        travelOrder,
        dateAndTime,
        coordinates,
        isPickup,
        stopType,
        location,
    };
};

export const getTruckForTravelOrderRoute = (travelOrder: TravelOrder) =>
    isTravelOrderFinished(travelOrder) ? [] : [{...travelOrder.truck, isCurrent: true}];

export const isEqualTrucksCoordinates = (oldTruck, newTruck): boolean =>
    oldTruck?.last_latitude === newTruck?.latitude && oldTruck?.last_longitude === newTruck?.longitude;
