import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button/index';

const ClearAllFields = (props) => {
    const {handlers, onClose} = props;

    const onConfirm = () => {
        handlers.onConfirm();
        onClose();
    };

    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Clear All Fields</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-reset" />
                    </div>
                    <div className="col-11">
                        <p className="mb10">
                            Please confirm that you want to clear all fields. All info will be lost.
                            <br />
                            After the confirmation you will be relocated to the first step in this form.
                        </p>
                        <p className="mb10">If you like to keep the process please press Cancel.</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} buttonSize="small" colorTheme="grey">
                    Cancel
                </Button>
                <Button onClick={onConfirm} buttonSize="small" colorTheme="blue">
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ClearAllFields;
