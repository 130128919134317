import toNumber from 'lodash/toNumber';
import sumBy from 'lodash/sumBy';
import some from 'lodash/some';

import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';
import {getTravelOrderStopByID} from 'core/entities/TravelOrder/modules/travelOrderStops';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';
import {Freight} from 'core/entities/Load/types/Freight';

const checkIsHazmat = (stop?: TravelOrderStop): boolean => some(stop?.freights || [], {isHazmat: true});
const checkIsStackable = (stop?: TravelOrderStop): boolean => some(stop?.freights || [], {isStackable: true});
const getTotalNumberOfPieces = (stop?: TravelOrderStop): number =>
    sumBy(stop?.freights, (freight) => toNumber(freight?.pieces || 0));
const getTotalNumberOfWeight = (stop?: TravelOrderStop): number =>
    sumBy(stop?.freights, (freight) => toNumber(freight?.weight?.amount || 0));
const getWeightUnit = (stop?: TravelOrderStop): Freight['weight']['unit'] | undefined => {
    const [firstFreight] = stop?.freights || [];
    return firstFreight?.weight.unit;
};

type TOStopFreightsInfo = {
    totalNumberOfPieces: ReturnType<typeof getTotalNumberOfPieces>;
    totalNumberOfWeight: ReturnType<typeof getTotalNumberOfWeight>;
    weightUnit: ReturnType<typeof getWeightUnit>;

    isStackable: ReturnType<typeof checkIsStackable>;
    isHazmat: ReturnType<typeof checkIsHazmat>;
};

export const getTravelOrderStopFreightsInfo = (stop?: TravelOrderStop): TOStopFreightsInfo => ({
    totalNumberOfPieces: getTotalNumberOfPieces(stop),
    totalNumberOfWeight: getTotalNumberOfWeight(stop),
    weightUnit: getWeightUnit(stop),

    isStackable: checkIsStackable(stop),
    isHazmat: checkIsHazmat(stop),
});

export const getTravelOrderStopFreightsInfoByID = (params: StopInfoParams): TOStopFreightsInfo =>
    getTravelOrderStopFreightsInfo(getTravelOrderStopByID(params));
