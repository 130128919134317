import React, {useState, useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

import Pagination from 'types/Pagination';
import useToggleLoader from 'hooks/useToggleLoader';

import type Batch from 'core/entities/Invoice/types/Batch';
import type Invoice from 'core/entities/Invoice/types';

import useBatchActions from 'pages/Invoices/hooks/useBatchActions';

import {useSelector} from 'react-redux';
import {AppState} from 'store';
import {getCurrentSortBy, getCurrentSortFrom} from 'store/reducers/sort/selectors';
import {BatchInvoicesSearchParams} from './types';
import BatchInfoHeader from './components/BatchInfoHeader';
import InvoicesSearch from './components/InvoicesSearch';
import InvoicesList from './components/InvoicesList';
import InvoicesPagination from './components/InvoicesPagination';
import {useSelectInvoices} from './hooks';
import {fetchGeneralInvoices, fetchResetInvoices} from './requests';

type OwnProps = {
    data: {batch: Batch; displayOnlyResetInvoices?: boolean};
    onClose();
};

const BatchInvoicesModal = (props: OwnProps): JSX.Element => {
    const {
        onClose,
        data: {batch, displayOnlyResetInvoices = false},
    } = props;
    const [isFilterExpanded, setIsFilterExpanded] = useState(true);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [searchParams, setSearchParams] = useState<BatchInvoicesSearchParams>({});
    const [pagination, setPagination] = useState<Pagination>({
        currentPage: 1,
        pagesCount: 0,
        perPage: 100,
        totalItemsCount: 0,
    });
    const toggleLoader = useToggleLoader();
    const batchActions = useBatchActions();
    const {selectedInvoices, onSelectInvoice, onSelectAllInvoices, clearSelectedInvoices} = useSelectInvoices(invoices);
    const removeResetedInvoices = (resetedInvoices: Invoice[]) => {
        const map = keyBy(resetedInvoices, 'id');
        const filteredInvoices = invoices.filter((i) => !map[i.id]);
        setInvoices(filteredInvoices);
    };
    const handleResetBatchInvoices = (invoicesForReset: Invoice[]) => {
        batchActions.resetBatchInvoices(batch, invoicesForReset)?.then((success) => {
            if (success) {
                removeResetedInvoices(invoicesForReset);
                clearSelectedInvoices();
            }
        });
    };
    const toggleFilterExpand = () => {
        setIsFilterExpanded(!isFilterExpanded);
    };
    const handleChangeSearchParams = (newSearchParams) => {
        setSearchParams(newSearchParams);
        clearSelectedInvoices();
    };
    const resetSearchParams = () => {
        setSearchParams({});
    };
    const handleChangePaginationPage = (newPage) => {
        const updatedPagination = {...pagination, currentPage: newPage};
        setPagination(updatedPagination);
    };

    const displayInvoicesMode = displayOnlyResetInvoices ? 'reset' : 'general';

    const currentSortFrom = useSelector((state: AppState) => getCurrentSortFrom(state, 'invoicesBatchesModal'));
    const currentSortBy = useSelector((state: AppState) => getCurrentSortBy(state, 'invoicesBatchesModal'));

    useEffect(() => {
        toggleLoader.showLoader();

        const allFetchParams = {
            batchNumber: batch.number,
            searchParams: {
                ...omit(searchParams, 'status'),
                sortBy: currentSortBy ? {[currentSortBy]: currentSortFrom} : {},
            },
            pagination: {page: pagination.currentPage, perPage: pagination.perPage},
        };

        const request = displayOnlyResetInvoices ? fetchResetInvoices : fetchGeneralInvoices;

        request(allFetchParams)
            .then((response) => {
                setInvoices(response.invoices);
                setPagination(response.pagination);
            })
            .finally(() => toggleLoader.hideLoader());
    }, [
        pagination.currentPage,
        pagination.perPage,
        searchParams,
        displayOnlyResetInvoices,
        currentSortFrom,
        currentSortBy,
    ]);

    return (
        <Modal show={true} dialogClassName="standard-form-modal " backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>All Invoices in the Batch #{batch.number}</Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <BatchInfoHeader
                            batch={batch}
                            isExpanded={isFilterExpanded}
                            onToggleExpand={toggleFilterExpand}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {isFilterExpanded && (
                            <InvoicesSearch resetSearchParams={resetSearchParams} onSubmit={handleChangeSearchParams} />
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <InvoicesList
                            invoices={invoices}
                            displayMode={displayInvoicesMode}
                            allInvoicesCount={pagination.totalItemsCount}
                            selectedInvoices={selectedInvoices}
                            onSelectInvoice={onSelectInvoice}
                            onSelectAllInvoices={onSelectAllInvoices}
                            onResetInvoices={handleResetBatchInvoices}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <InvoicesPagination
                            currentPage={pagination.currentPage}
                            perPageCount={pagination.perPage}
                            allItemsCount={pagination.totalItemsCount}
                            allPagesCount={pagination.pagesCount}
                            onChangePage={handleChangePaginationPage}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default BatchInvoicesModal;
