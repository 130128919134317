import React from 'react';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import DimensionsUnitsOfMeasurement from 'components/ui/Form/DimensionsUnitsOfMeasurement';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';
import useFormActions from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/hooks/useFormActions';

const DimensionUnits: React.FC = () => {
    const {getLoadFreightDimensionUnitFieldName, getLoadFreightDimensionItems} = getFormFieldNames();
    const {
        setFreightDimensionUnit,
        setFreightDimensionLengthFieldName,
        setFreightDimensionWidthFieldName,
        setFreightDimensionHeightFieldName,
    } = useFormActions();
    const dimensionUnit = useGetReduxFormValue(FORM_NAME, getLoadFreightDimensionUnitFieldName());
    const lengthArrayUnit = useGetReduxFormValue(FORM_NAME, getLoadFreightDimensionItems())?.length;

    const setPayloadUnit = (unit): void => {
        setFreightDimensionUnit(unit);
    };

    const clearPayload = (): void => {
        for (let i = 0; i < lengthArrayUnit; i++) {
            setFreightDimensionLengthFieldName(null, i);
            setFreightDimensionWidthFieldName(null, i);
            setFreightDimensionHeightFieldName(null, i);
        }
    };
    return (
        <DimensionsUnitsOfMeasurement
            unit={dimensionUnit}
            setUnit={setPayloadUnit}
            clearFields={clearPayload}
            disabled={false}
        />
    );
};

export default DimensionUnits;
