import * as actionCreators from '../actionCreators';

export function changePaginationPage(page) {
    return function (dispatch) {
        dispatch(actionCreators.changedPaginationPage(page));
    };
}

export function changePaginationCount(count) {
    return function (dispatch) {
        dispatch(actionCreators.changedPaginationCount(count));
    };
}
