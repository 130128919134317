import React from 'react';
import {Modal} from 'react-bootstrap';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';
import trim from 'lodash/trim';

import RequiredLabel from 'components/ui/Form/RequiredLabel';
import TextInput from 'components/ui/Form/TextInput';
import Button from 'components/ui/Buttons/Button';
import PhoneWithLocalization from 'components/ui/Form/PhoneWithLocalization';

import useManageAgent from './hooks/useManageAgent';
import validate from './validate';
import {ModalOwnProps, ManageAgentFormFields} from './types';

import styles from './styles.module.scss';

const MANAGE_AGENT_FORM = 'MANAGE_AGENT_FORM';

const ManageAgentModal: React.FC<InjectedFormProps<ManageAgentFormFields, ModalOwnProps> & ModalOwnProps> = (props) => {
    const {
        customerName,
        isClearFieldsBtnDisabled,
        isSubmitBtnDisabled,
        onClearFields,
        onSubmit,
        onClose,
        submitBtnTitle,
    } = useManageAgent(props);

    return (
        <Modal show={true} onHide={onClose} dialogClassName="standard-form-modal" backdrop="static">
            <form onSubmit={onSubmit}>
                <Modal.Header>
                    <div className="modal-header__top">
                        <Modal.Title>{`Create agent for ${customerName}`}</Modal.Title>
                        <button onClick={onClose} type="button" className="close">
                            <i className="fa fa-times" aria-hidden="true" />
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.formContainer}>
                        <div className={styles.row}>
                            <div className={styles.halfWidth}>
                                <h3 className={styles.blockHeading}>General Information:</h3>
                                <Field
                                    name="agentName"
                                    component={TextInput}
                                    type="text"
                                    label={RequiredLabel('Agent name')}
                                    placeholder="enter agent name"
                                    maxLength={40}
                                />
                            </div>
                        </div>
                        <div className={styles.row}>
                            <h3 className={styles.blockHeading}>Contact Information:</h3>
                            <div className="d-flex">
                                <div className="mr20 w-50">
                                    <Field
                                        name="phone"
                                        component={PhoneWithLocalization}
                                        type="text"
                                        label="Phone#"
                                        placeholder="enter agent name"
                                    />
                                </div>
                                <div className="w-50">
                                    <Field
                                        name="email"
                                        component={TextInput}
                                        type="text"
                                        label="Email"
                                        placeholder="enter email address"
                                        normalize={trim}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.halfWidth}>
                                <h3 className={styles.blockHeading}>Office Address:</h3>
                                <Field
                                    name="office"
                                    component={TextInput}
                                    type="text"
                                    label="Office address"
                                    placeholder="enter agent's office address"
                                    maxLength={30}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="p20">
                    <div className="d-flex flex-fill">
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onClearFields} className="ml10" disabled={isClearFieldsBtnDisabled}>
                            Clear all fields
                        </Button>
                    </div>

                    <Button type="submit" colorTheme="green" disabled={isSubmitBtnDisabled}>
                        {submitBtnTitle}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default reduxForm<ManageAgentFormFields, ModalOwnProps>({form: MANAGE_AGENT_FORM, validate})(ManageAgentModal);
