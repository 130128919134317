import React, {ReactNode} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';

import {AppState} from 'store';
import {setSortBy} from 'store/actionCreators';
import {getCurrentSortBy, getCurrentSortFrom} from 'store/reducers/sort/selectors';
import {SortProps} from 'store/reducers/sort/types';

import styles from './styles.module.scss';

interface OwnProps {
    sortType: 'numeric' | 'alpha';
    title: ReactNode;
}

const SortingIcon: React.FC<OwnProps & SortProps> = (props) => {
    const {sortBy, sortType, title, sortListName} = props;
    const dispatch = useDispatch();
    const currentSortFrom = useSelector((state: AppState) => getCurrentSortFrom(state, sortListName));
    const currentSortBy = useSelector((state: AppState) => getCurrentSortBy(state, sortListName));
    const sortFrom = sortBy === currentSortBy ? currentSortFrom : undefined;
    const containerClassName = classNames(styles.icon, {
        [styles.applied]: sortFrom,
    });
    const iconClassName = classNames('fa', {
        [`fa-sort-${sortType}-asc`]: !sortFrom || sortFrom === 'asc',
        [`fa-sort-${sortType}-desc`]: sortFrom === 'desc',
    });

    const sortByParams = {
        sortBy,
        sortListName,
    };

    const onChangeSort = (): void => {
        dispatch(setSortBy(sortByParams));
    };

    return (
        <>
            <span onClick={onChangeSort} className={containerClassName}>
                {title} <i className={iconClassName} />
            </span>
        </>
    );
};

export default SortingIcon;
