import React from 'react';

type OwnProps = {
    message?: string;
};

const ResetFieldsForm: React.FC<OwnProps> = ({message}) => (
    <div className="row align-items-center">
        <div className="col-1">
            <i className="fa fa-exclamation feedback-icon-reset" />
        </div>
        <div className="col-11">
            <p>{message}</p>
        </div>
    </div>
);

export default ResetFieldsForm;
