import makeChunks from 'lodash/chunk';
import head from 'lodash/head';

/**
 * last element of chunk is the same as first element of the next chunk
 * */
export default function makeChainedChunks(array: any[], chunkSize: number) {
    const chunks = makeChunks(array, chunkSize - 1);
    const chainedChunks: any[] = [];

    chunks.forEach((chunk, index) => {
        const nextChunk = chunks[index + 1];
        if (nextChunk) {
            chainedChunks.push([...chunk, head(nextChunk)]);
        } else if (chunk.length > 1) {
            chainedChunks.push(chunk);
        }
    });
    return chainedChunks;
}
