import React from 'react';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';
import {Dispatcher} from 'core/entities/Dispatcher/types';

import ArchivingWarnWrapper from 'components/ui/modals/ArchivingWarnWrapper';

type OwnProps = {
    data: {dispatcher: Dispatcher};
    handlers: {onCancel(); onConfirm()};
    onClose();
};

const ArchivingWarn = (props: OwnProps): JSX.Element => {
    const {
        data: {dispatcher},
        handlers: {onCancel, onConfirm},
        onClose,
    } = props;
    const dispatcherName = getDispatcherName(dispatcher);
    return (
        <ArchivingWarnWrapper title={dispatcherName} onClose={onClose} onCancel={onCancel} onConfirm={onConfirm}>
            <p>
                <strong>DO YOU WANT TO ARCHIVE THE USER?</strong>
            </p>
            <p>
                {' '}
                The archived user`s profile <strong>{dispatcherName}</strong> will disable any actions with it.&nbsp;If
                needed, you can still the ability to restore the profile.
            </p>
        </ArchivingWarnWrapper>
    );
};

export default ArchivingWarn;
