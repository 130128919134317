import {useSelector} from 'react-redux';

import * as selectors from 'pages/Customers/redux/selectors';

type UseMergeState = {
    customersToMerge: ReturnType<typeof selectors.getCustomersToMerge>;
};

const useMergeState = (): UseMergeState => ({
    customersToMerge: useSelector(selectors.getCustomersToMerge),
});

export default useMergeState;
