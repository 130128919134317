import React from 'react';
import {ClearFieldsAction, Field} from 'redux-form';
import classNames from 'classnames';

import TooltipBootstrap from 'components/ui/TooltipBootstrap';

import {useGetIsReduxFormHasInitialStateFields} from 'pages/TravelOrders/components/common/modals/TimerSettingsModal/components/Form/hooks';

import {getName} from 'pages/TravelOrders/components/common/modals/TimerSettingsModal/components/Form/utils';

import {MARGIN_FOR_DELAY_CONFIG} from 'pages/TravelOrders/components/common/modals/TimerSettingsModal/constants';

import TimeSelector from '../TimeSelector';
import SpeedDiapason from '../SpeedDiapason';

import styles from '../../styles.module.scss';

type OwnProps = {
    form: string;
    distanceDiffer: number;
    clearFields: ClearFieldsAction;
};

const ETATooltipContent = (
    <span className="check-call-settings-modal_tooltip">
        Estimated Time of Arrival to the Next stop should align with the planned arrival time for the load. You can
        incorporate a margin for delay if you want to introduce more flexibility.
    </span>
);

const AverageSpeedTooltipContent = (
    <span className="check-call-settings-modal_tooltip">
        You can changing the &quot;Speed diapason&quot; to set an optimal speed depending on road conditions, weather,
        and other factors.
    </span>
);

const CheckCallTruckInfoSection: React.FC<OwnProps> = (props) => {
    const {form, distanceDiffer, clearFields} = props;

    const isMarginForDelayResetBtnDisabled = useGetIsReduxFormHasInitialStateFields(form, getName('marginForDelay'));

    // @ts-ignore
    const handleResetMarginForDelay = (...args: any[]) => clearFields(...args);

    return (
        <div className={classNames(styles.section, styles.borderTop)}>
            <div>
                <i className="fa fa-truck fa-flip-horizontal" aria-hidden="true" />
            </div>
            <div>
                <h4 className={styles.sectionTitle}>CHECK CALL TRUCK INFO:</h4>
                <p className={styles.subTitle}>
                    Location event check call creating and sent broker if next information will be correct. Some
                    parameters you can change.
                </p>
                <div className="mt20">
                    <div className={styles.dataRow}>
                        <span className={styles.rowLeft}>Location:</span>
                        <div className={styles.rowRight}>
                            <span>Truck current location</span>
                        </div>
                    </div>
                    <div className={styles.dataRow}>
                        <span className={styles.rowLeft}>Date and time:</span>
                        <div className={styles.rowRight}>
                            <span>Current date and time</span>
                        </div>
                    </div>
                    <div className={styles.dataRow}>
                        <span className={styles.rowLeft}>ETA:</span>
                        <div className={styles.rowRight}>
                            <span>
                                <span className="mr10">Estimated Time of Arrival to the Next stop</span>
                                <TooltipBootstrap tooltipContent={ETATooltipContent} tooltipPlacement="bottom">
                                    <i className={classNames(styles.infoIcon, 'fa fa-exclamation-circle')} />
                                </TooltipBootstrap>
                            </span>
                            <Field
                                name={getName('marginForDelay')}
                                component={TimeSelector}
                                label="MARGIN FOR DELAY"
                                onReset={handleResetMarginForDelay}
                                resetBtnDisabled={isMarginForDelayResetBtnDisabled}
                                maxHours={MARGIN_FOR_DELAY_CONFIG.maxHours}
                                minutesStep={MARGIN_FOR_DELAY_CONFIG.minutesStep}
                                maxMinutes={55}
                                canUseZeroTimeValues={true}
                            />
                        </div>
                    </div>
                    <div className={styles.dataRow}>
                        <span className={styles.rowLeft}>Distance done:</span>
                        <div className={styles.rowRight}>
                            <span>{`Compare 'Distance done' with 'Distance differ' (Constant ≈ ${distanceDiffer} mi), which is considered as the acceptable deviation from the route`}</span>
                        </div>
                    </div>
                    <div className={styles.dataRow}>
                        <span className={styles.rowLeft}>Average speed:</span>
                        <div className={styles.rowRight}>
                            <span>
                                <span className="mr10">Speed diapason</span>
                                <TooltipBootstrap tooltipContent={AverageSpeedTooltipContent} tooltipPlacement="bottom">
                                    <i className={classNames(styles.infoIcon, 'fa fa-exclamation-circle')} />
                                </TooltipBootstrap>
                            </span>

                            <SpeedDiapason form={form} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckCallTruckInfoSection;
