export const ENTITY_NAME_DRIVER = 'driver';
export const ENTITY_NAME_DRIVERS = 'drivers';
export const ENTITY_NAME_TRAVEL_ORDERS = 'travelOrders';
export const ENTITY_NAME_TRAVEL_ORDER = 'travelOrder';
export const ENTITY_NAME_TRIP_TRAVEL_ORDER = 'tripTravelOrder';
export const ENTITY_NAME_TRIP_UPDATE = 'tripUpdate';
export const ENTITY_NAME_BROKER = 'broker';
export const ENTITY_NAME_BROKERS = 'brokers';
export const ENTITY_NAME_CARRIER = 'carrier';
export const ENTITY_NAME_CURRENT_CARRIER = 'current_carrier';
export const ENTITY_NAME_CARRIERS = 'carriers';
export const ENTITY_NAME_CUSTOMER = 'customer';
export const ENTITY_NAME_CUSTOMERS = 'customers';
export const ENTITY_NAME_FACILITY = 'facility';
export const ENTITY_NAME_FACILITIES = 'facilities';
export const ENTITY_NAME_FACILITIES_API_SIDE = 'shippers'; // Facilities on api store as shippers.
export const ENTITY_NAME_FACTORING_COMPANY = 'factoringCompany';
export const ENTITY_NAME_FACTORING_COMPANIES = 'factoringCompanies';
export const ENTITY_NAME_TRUCK = 'truck';
export const ENTITY_NAME_TRUCKS = 'trucks';
export const ENTITY_NAME_LOAD = 'load';
export const ENTITY_NAME_INVOICE = 'invoice';
export const ENTITY_NAME_SETTLEMENT = 'settlement';
export const ENTITY_NAME_SETTLEMENT_OWNER = 'settlementOwner';
export const ENTITY_NAME_SETTLEMENT_CARRIER = 'settlementCarrier';
export const ENTITY_NAME_CARRIER_SETTLEMENT = 'carrierSettlement';
export const ENTITY_NAME_LOADS = 'loads';
export const ENTITY_NAME_DISPATCHER = 'dispatcher';
export const ENTITY_NAME_DISPATCHERS = 'dispatchers';
export const ENTITY_NAME_COMPANY = 'company';
export const ENTITY_NAME_OWNERS = 'owners';
export const ENTITY_NAME_OWNER = 'owner';
export const ENTITY_NAME_CLIENTS = 'clients';
export const ENTITY_NAME_EVENT_NAMES = 'eventNames';
export const ENTITY_NAME_SENDERS = 'senders';
export const ENTITY_NAME_LOGS = 'logs';
export const ENTITY_NAME_EVENT_NOTE = 'eventNote';
export const ENTITY_NAME_REPORT = 'report';
export const ENTITY_NAME_AGENT = 'agent';
