import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';
import {getDriverName} from 'core/entities/Driver/modules/driverName';
import {Note} from 'core/entities/Truck/types/Note';

import {DispatcherLink, DriverLink} from 'components/ui/Links';

import {createDate} from 'utils/dateTime';
import decodeText from 'utils/decodeText';

import {NoteType, Tab} from '../../types';

import styles from './notesList.module.scss';

interface OwnProps {
    tabsName: Tab;
    notes: Note[];
    isCompactView: boolean;
    children: JSX.Element;
    isDisableDispatcherLinks?: boolean;
    isDisableDriverLinks?: boolean;
}

const listDescribe: {[key in NoteType]: string} = {
    firstDriver: "First driver's note",
    dispatcher: "Dispatcher's note",
    general: 'General note',
};

const listDescribeForEmptyTabs: {[key in Tab]: string} = {
    all: 'notes',
    firstDriver: "First driver's note",
    dispatcher: "Dispatcher's note",
    general: 'General note',
};

const getTypeNoteDescribe = (type: NoteType, describe: {[key in NoteType]: string}): string => {
    return describe[type] || 'General note';
};

const getDescribeEmptyNoteInTab = (tab: Tab, describe: {[key in Tab]: string}): string => {
    return describe[tab];
};

const NoteItem = ({note, isCompactView, children, isDisableDispatcherLinks, isDisableDriverLinks}): JSX.Element => {
    const dispatcherLink = !isEmpty(note.dispatcher) ? (
        <DispatcherLink
            content={getDispatcherName(note.dispatcher)}
            disabled={isDisableDispatcherLinks}
            dispatcher={note.dispatcher}
            className="second-link"
        />
    ) : null;

    const driverLink = !isEmpty(note?.firstDriver) ? (
        <DriverLink
            content={getDriverName(note.firstDriver)}
            disabled={isDisableDriverLinks}
            driver={note.firstDriver}
            className="second-link"
        />
    ) : null;

    const timeOfCreate = (note.post_date && createDate(note.post_date).fullDate) || null;
    const noteDescribeType = getTypeNoteDescribe(note.type, listDescribe);
    const noteSplitInLines = note.text && decodeText(note.text).split('\n');

    return (
        <div className={classNames(styles.list__item)}>
            {isCompactView ? (
                <div className={styles.list__item_header}>
                    <div className={styles.list__item_header_type}>
                        <strong>{noteDescribeType}: </strong>
                    </div>
                    <div className={styles.list__item_header_link}>
                        <strong>{dispatcherLink || driverLink}</strong>
                    </div>
                    <div className={styles.list__item_header_desc}>
                        <span>{timeOfCreate}</span>
                    </div>
                </div>
            ) : (
                <div className={styles.list__item_header}>
                    <div className={styles.list__item_header_link}>
                        <strong>{dispatcherLink || driverLink}</strong>
                    </div>
                    <div className={styles.list__item_header_desc}>
                        <strong>{noteDescribeType}</strong> <span>{timeOfCreate}</span>{' '}
                        {!note.type && React.cloneElement(children, {note})}
                    </div>
                </div>
            )}

            <div className={styles.list__item_content}>
                {noteSplitInLines.map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </div>
        </div>
    );
};

const NotesList = (props: OwnProps): JSX.Element => {
    const {notes, tabsName, isCompactView, children, isDisableDispatcherLinks, isDisableDriverLinks} = props;

    if (isEmpty(notes)) {
        const textTabsName = getDescribeEmptyNoteInTab(tabsName, listDescribeForEmptyTabs);

        return (
            <div className={styles.empty_notes}>
                The current truck doesn&#8217;t have <strong>{textTabsName}</strong>.
            </div>
        );
    }

    return (
        <div className={classNames(styles.list, isCompactView && styles.list_compact)}>
            {notes.map((note, index) => (
                <NoteItem
                    isDisableDispatcherLinks={isDisableDispatcherLinks}
                    isDisableDriverLinks={isDisableDriverLinks}
                    isCompactView={isCompactView}
                    note={note}
                    key={index}
                >
                    {children}
                </NoteItem>
            ))}
        </div>
    );
};

export default NotesList;
