import React from 'react';
import classNames from 'classnames';

import Tooltip from 'components/ui/Tooltip';

import styles from './styles.module.scss';

type OwnProps = {
    tooltipPosition?: 'left' | 'right' | 'bottom';
    text: string | number | JSX.Element;
    tooltip?: string | JSX.Element;
    withBorderRadius?: boolean;
    isTooltip?: boolean;
    className?: string;
};

const GeneralBadge: React.FC<OwnProps> = (props) => {
    const {text, tooltip, className, withBorderRadius = false, isTooltip = true, tooltipPosition = 'left'} = props;

    if (!text) {
        return null;
    }

    const classNameBadge = classNames(styles.badge, withBorderRadius && styles.radius, className);

    if (isTooltip) {
        return (
            <Tooltip className={classNameBadge} tooltipContent={tooltip} position={tooltipPosition}>
                <span>{text}</span>
            </Tooltip>
        );
    }

    return (
        <div className={classNameBadge}>
            <span>{text}</span>
        </div>
    );
};

export default GeneralBadge;
