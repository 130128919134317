import {settlementsOwnersEvents} from '../../events';
import * as actions from './actions';

export const settlementsOwnersChannelMessageHandler = (store) => (uri, payload) => {
    const messageData = payload.msg;
    if (!messageData.event || !messageData.data) {
        return;
    }
    const {event, data} = messageData;

    try {
        switch (event) {
            case settlementsOwnersEvents.SETTLEMENT_UPDATE:
                store.dispatch(actions.updateSettlement(data));
                break;
            case settlementsOwnersEvents.PAY_PERIOD_CREATE:
                store.dispatch(actions.createPayPeriod(data));
                break;
            case settlementsOwnersEvents.PAY_PERIOD_UPDATE:
                store.dispatch(actions.updatePayPeriod(data));
                break;
            case settlementsOwnersEvents.PAY_RECORD_UPDATE:
                store.dispatch(actions.updatePayRecord(data));
                break;
            default:
                break;
        }
    } catch (error) {
        console.error('Error on handle websocket message for settlements owners channel: ', {
            error,
            message: messageData,
        });
    }
};
