export default {
    settlementsCarriers: 'carriers_settlements/websocket/channel',
    truckLocationEvents: 'trucklocationevents/websocket/channel',
    settlementsOwners: 'owners_settlements/websocket/channel',
    notifications: 'notifications/websocket/channel',
    tripMonitor: 'travelorders/websocket/channel',
    messages: 'messages/websocket/channel',
    invoices: 'invoices/websocket/channel',
    trucks: 'trucks/websocket/channel',
    loads: 'loads/websocket/channel',
    files: 'files/websocket/channel',
};
