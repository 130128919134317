import {createSelector} from 'reselect/lib';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';

import formatRCFiles from 'deprecated/core/entities/Load/formatRCFiles';
import {getLoadFilesMap} from 'deprecated/core/entities/Load/loadFiles';
import {getTravelOrderFilesMap} from 'deprecated/core/entities/TravelOrder/travelOrderFiles';
import {getFilesFromProgressStep} from 'deprecated/core/entities/TravelOrder/travelOrder';
import {FILE_TYPES} from 'deprecated/core/entities/TravelOrder/constants/travel-order';

import getText from 'utils/getText';

const calculateTravelOrderFiles = (allTravelOrderFiles, travelOrder) => {
    if (!travelOrder) {
        return [];
    }

    const travelOrderFilesMap = getTravelOrderFilesMap(allTravelOrderFiles);
    const {dispatch} = travelOrder;

    if (!isPlainObject(dispatch) || isEmpty(travelOrderFilesMap)) {
        return [];
    }

    const {steps: progressSteps} = dispatch;

    if (!progressSteps) {
        return [];
    }

    if (!travelOrderFilesMap.bol && !travelOrderFilesMap.pod) {
        return [];
    }

    return progressSteps.slice(1).map((step) => {
        const bolFilesInStep = getFilesFromProgressStep(step.id, travelOrderFilesMap, FILE_TYPES.bol);
        const podFilesInStep = getFilesFromProgressStep(step.id, travelOrderFilesMap, FILE_TYPES.pod);

        return {
            title: step.name,
            files: [...bolFilesInStep, ...podFilesInStep],
        };
    });
};

const getLastRC = (allLoadRcFiles) => {
    const rcFiles = formatRCFiles(allLoadRcFiles);
    return last(rcFiles) || [];
};

const calculateLoadFiles = (loadFilesMap) => {
    const rateConfirmation = getText('rateConfirmation');
    const {rc: rcFiles, pod: podFiles, files: regularFiles, extra_files: extraFiles} = loadFilesMap;

    return [
        {title: rateConfirmation, files: getLastRC(rcFiles)},
        {title: 'Proof of Delivery', files: podFiles},
        {
            title: 'Other Files',
            files: [...regularFiles, ...extraFiles],
        },
    ];
};

export const getInitValues = createSelector(
    [
        (state, props) => props.invoice,
        (state, props) => props.loadFiles,
        (state, props) => props.fullTravelOrder,
        (state, props) => props.travelOrderFiles,
    ],
    (invoice, loadFiles, fullTravelOrder, travelOrderFiles) => {
        const loadFilesMap = getLoadFilesMap(loadFiles);
        const initFiles = getLastRC(loadFilesMap.rc);
        return {
            initialValues: {
                // set last rate confirmation / order as default value if it exists
                files: initFiles,
            },
            invoiceFiles: {
                load: calculateLoadFiles(loadFilesMap),
                travelOrder: calculateTravelOrderFiles(travelOrderFiles, fullTravelOrder),
            },
        };
    },
);
