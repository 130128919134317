import React from 'react';

const ResetFieldsForm = () => {
    return (
        <div className="row align-items-center">
            <div className="col-1">
                <i className="fa fa-exclamation feedback-icon-reset" />
            </div>
            <div className="col-11">
                <p>The entered information in the current tab will be lost.</p>
            </div>
        </div>
    );
};

export default ResetFieldsForm;
