import React from 'react';

import useRegistryState from 'hooks/useRegistryState';

import styles from './styles.module.scss';

const CurrencySelectOptions = () => {
    const {currency} = useRegistryState();

    const renderSelectOptions = (currency || []).map((option) => {
        const label = (
            <span className={styles.wrap}>
                <span className={styles.symbol}>{option.symbol}</span>
                <span className="mr5">{option.label}</span>
                <span className="text-uppercase">{option.value}</span>
            </span>
        );

        return {
            label,
            value: option.value,
        };
    });

    return renderSelectOptions;
};

export default CurrencySelectOptions;
