import React from 'react';

import Driver from 'core/entities/Driver/types';
import {getDriverName} from 'core/entities/Driver/modules/driverName';

import {DriverAvatarType} from 'widgets/Chat/types';

import defaultDriverAvatar from './defaultDriverAvatar.png';
import styles from './driverAvatar.module.scss';

type OwnProps = {
    driver: Driver;
    withLink?: boolean;
    avatar: DriverAvatarType;
};

const DriverAvatar: React.FC<OwnProps> = ({driver, avatar, withLink = true}) => {
    const imageSrc = avatar.thumb || defaultDriverAvatar;
    const imageSrcFullSize = avatar.fullSize;
    const imageAlt = getDriverName(driver);

    return (
        <div className={styles.wrap}>
            {imageSrcFullSize && withLink ? (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a href={imageSrcFullSize} target="_blank">
                    <img src={imageSrc} alt={imageAlt} title="click to open full size" />
                </a>
            ) : (
                <img src={imageSrc} alt={imageAlt} />
            )}
        </div>
    );
};

export default DriverAvatar;
