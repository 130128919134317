import {INVOICES} from 'utils/data/pages';
import {
    getPageNameFromChangeLocationAction,
    getRouteParamsFromChangeLocationAction,
    isChangeLocationAction,
} from 'routing/utils';

const defaultState = {
    currentPage: null,
};

export default function invoicesNavigationDataReducer(state = defaultState, action) {
    if (isChangeLocationAction(action)) {
        const pageName = getPageNameFromChangeLocationAction(action);
        const routeParams = getRouteParamsFromChangeLocationAction(action) || {};
        if (pageName === INVOICES) {
            return {...state, currentPage: routeParams.action === 'view' ? 'view' : 'list'};
        }
        return state;
    }

    return state;
}
