import isPlainObject from 'lodash/isPlainObject';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';

import {SET_CURRENT_USER} from 'store/actionTypes';

import * as USER_ROLES from 'utils/data/roles';

import accounting from './roles/accounting';
import admin from './roles/admin';
import bookingDispatcher from './roles/bookingDispatcher';
import checkingDispatcher from './roles/checkingDispatcher';
import fullCircle from './roles/fullCircle';
import humanResources from './roles/hr';
import operationsDispatcher from './roles/operationsDispatcher';
import sales from './roles/sales';
import satefy from './roles/satefy';
import teamleader from './roles/teamleader';
import userManager from './roles/userManager';

// Describe how to merge objects with permission
function permissionMergeCustomizer(objValue, srcValue) {
    if (Array.isArray(objValue) && Array.isArray(srcValue)) {
        return union(objValue, srcValue);
    }

    if (isPlainObject(objValue) && isPlainObject(srcValue)) {
        return mergeWith(objValue, srcValue, permissionMergeCustomizer);
    }

    return objValue || srcValue;
}

// see https://confluence.rhinocodes.com/display/RH/PR.+RBAC+Permissions+Matrix
export default function userPermissions(state = {}, action) {
    switch (action.type) {
        case SET_CURRENT_USER: {
            const permissions = action.payload.user.roles.map((currentUserRole) => {
                switch (currentUserRole) {
                    case USER_ROLES.OPERATION_DISPATCHER: {
                        return operationsDispatcher;
                    }

                    case USER_ROLES.CHECKING_DISPATCHER: {
                        return checkingDispatcher;
                    }

                    case USER_ROLES.BOOKING_DISPATCHER: {
                        return bookingDispatcher;
                    }

                    case USER_ROLES.TEAMLEAD: {
                        return teamleader;
                    }

                    case USER_ROLES.ACCOUNTING: {
                        return accounting;
                    }

                    case USER_ROLES.HR: {
                        return humanResources;
                    }

                    case USER_ROLES.SAFETY: {
                        return satefy;
                    }

                    case USER_ROLES.SALES: {
                        return sales;
                    }

                    case USER_ROLES.FULL_CIRCLE: {
                        return fullCircle;
                    }

                    case USER_ROLES.USER_MANAGER: {
                        return userManager;
                    }

                    default: {
                        return admin;
                    }
                }
            });

            // Return merged permission for all roles that apply for user.
            return mergeWith({}, ...permissions, permissionMergeCustomizer);
        }

        default: {
            return state;
        }
    }
}
