import React from 'react';

import ButtonGroupSwitch from 'components/ui/ButtonGroupSwitch';

import NotesForm from './components/NotesForm';

interface OwnProps {
    onAdd(params: {text: string}): void;
    isDisabledAddNotes: boolean;
}

const buttons = [
    {
        key: 'general',
        label: 'New general note',
        isDisabled: true,
        onSelect: () => {},
    },
];

const AddGeneralNote: React.FC<OwnProps> = (props) => {
    const {onAdd, isDisabledAddNotes} = props;
    const initialValues = {text: ''};
    return (
        <>
            <div>
                <ButtonGroupSwitch activeKey="general" buttons={buttons} />
            </div>
            <div>
                <NotesForm isDisabledAddNotes={isDisabledAddNotes} initialValues={initialValues} onSubmit={onAdd} />
            </div>
        </>
    );
};

export default AddGeneralNote;
