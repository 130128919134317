import * as appActions from 'store/actions';

import formatPaginationParams from 'utils/formatPaginationParams';
import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import {fetchSettlementData} from 'core/entities/Settlement/requests/common';
import {statuses} from 'core/entities/Settlement/constants';
import {
    fetchSettlementCarrier,
    fetchSettlementsCarriersList,
    resetSettlementCarrierFromView,
    setReadyToPostSettlementCarrierStatus,
    resetSettlementCarrierFromList,
} from 'core/entities/Settlement/requests/settlementCarrierRequests';

import * as types from '../actionTypes';
import {setGroupStatusBySettlements} from './listActions';
import {isSearchingByMainParams} from '../selectors';
import {handleChangeSettlementStatusError} from './errorHandlers';

export function approveSettlementCarrier(settlement) {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        setReadyToPostSettlementCarrierStatus(settlement)
            .then(({data: approvedSettlement}) => {
                dispatch({
                    type: types.SETTLEMENT_UPDATED,
                    payload: approvedSettlement,
                });
            })
            .catch((error) => handleChangeSettlementStatusError(error, dispatch))
            .catch((error) => console.warn('Error on approve settlement-carrier: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function resetSettlementFromView(data) {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        resetSettlementCarrierFromView(data)
            .then(({data: resetSettlement}) => {
                dispatch({
                    type: types.SETTLEMENT_STATUS_CHANGED,
                    payload: resetSettlement,
                });
            })
            .catch((error) => handleChangeSettlementStatusError(error, dispatch))
            .catch((error) => console.warn('Error on reset settlement-carrier status: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function resetSettlementFromList(settlement) {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        resetSettlementCarrierFromList(settlement)
            .then(({data: resetSettlement}) => {
                dispatch({
                    type: types.SETTLEMENT_STATUS_CHANGED,
                    payload: resetSettlement,
                });
            })
            .catch((error) => handleChangeSettlementStatusError(error, dispatch))
            .catch((error) => console.warn('Error on reset settlement-carrier status for list: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function fetchCurrentSettlement(settlementNumber) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        const settlement = await fetchSettlementCarrier(settlementNumber)
            .then(({data}) => data)
            .catch((error) => console.warn('Error on fetch current settlement carrier', error));

        if (!settlement) {
            dispatch(appActions.hideLoader());
            return;
        }

        fetchSettlementData(settlement)
            .then(({invoice, travelOrderFiles, travelOrderNotes, loadFiles, loadNotes}) => {
                dispatch({
                    type: types.SETTLEMENT_RECEIVED,
                    payload: {settlement, invoice, travelOrderFiles, travelOrderNotes, loadFiles, loadNotes},
                });
            })
            .catch((error) => console.warn('Error on fetch current settlement carrier data: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function searchCarrierSettlements({status: selectedStatus, searchParams, pagination, sortBy}) {
    return function (dispatch, getState) {
        const isSearchByMainParams = isSearchingByMainParams(getState());
        const fetchParams = {...searchParams, sortBy, ...formatPaginationParams(pagination)};

        if (!isSearchByMainParams) {
            fetchParams.status = selectedStatus;
        }

        dispatch(appActions.showLoader());

        fetchSettlementsCarriersList(fetchParams)
            .then(({data: settlements, headers}) => {
                dispatch(setGroupStatusBySettlements(settlements, isSearchByMainParams));
                dispatch({
                    type: types.LIST_SETTLEMENTS_RECEIVED,
                    payload: {settlements, pagination: parsePaginationHeaders(headers)},
                });
            })
            .catch((error) => console.warn('Error fetch list settlements carriers: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}
