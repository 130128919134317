import React from 'react';

import CheckBoxInput from 'components/ui/Form/CheckBoxInput';

import DataLoading from 'pages/Settlements/components/common/DataLoading';

import {OpenedCompany, BatchResponsibleUser} from '../../types';
import useFetchBatchResponsibleUsers from '../../hooks/useFetchBatchResponsibleUsers';

import ResponsibleUser from './ResponsibleUser';
import style from './listResponsibleUsers.module.scss';

interface OwnProps {
    company: OpenedCompany;
    selectedUsers: BatchResponsibleUser[];
    onSelectUser(user: BatchResponsibleUser);
    onSelectAllUsers(allUsers: BatchResponsibleUser[]);
}

const ListResponsibleUsers = ({company, selectedUsers, onSelectUser, onSelectAllUsers}: OwnProps): JSX.Element => {
    const {users, isFetching, isAllFetched, fetchNextPage} = useFetchBatchResponsibleUsers({
        carrierID: company.id,
    });
    const isAllChecked = users.length > 0 && users.length === selectedUsers.length;
    const isAllVisiblePayRecordsSelected = isAllChecked;
    const isAllExistsPayRecordsSelected = isAllChecked && isAllFetched;
    return (
        <>
            <div className="settlements-list__select-all">
                {users.length > 0 && (
                    <CheckBoxInput
                        label="Select All"
                        input={{
                            value: isAllChecked,
                            onChange: () => onSelectAllUsers(users),
                        }}
                        position="right"
                    />
                )}

                {isAllVisiblePayRecordsSelected && !isAllExistsPayRecordsSelected && (
                    <div className={style.select_info__all_visible}>
                        {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                        <div>// all loaded users on this modal are selected</div>
                        {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                        <div>// load more below to re-select next loaded</div>
                    </div>
                )}
                {isAllVisiblePayRecordsSelected && isAllExistsPayRecordsSelected && (
                    <div className={style.select_info__all_exists}>
                        {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                        <div>// all responsible users for the settlement are selected</div>
                    </div>
                )}
            </div>
            <>
                {users.map((user) => {
                    return (
                        <ResponsibleUser
                            key={user.id as number}
                            carrierID={company.id}
                            user={user}
                            selectedUsers={selectedUsers}
                            onSelectUser={() => onSelectUser(user)}
                        />
                    );
                })}
                <DataLoading
                    isLoading={isFetching}
                    isAllLoaded={isAllFetched}
                    onLoadMore={fetchNextPage}
                    loadMoreTitle="load more users"
                />
            </>
        </>
    );
};

export default React.memo(ListResponsibleUsers);
