import {useRef, useEffect} from 'react';

/**
 * Listens for clicks outside a node and sets isOpened state to false
 * @param {boolean} isOpened
 * @param {function} setIsOpened
 * @return {*}
 */
export default function useRefWithOutsideClickHandler(isOpened, setIsOpened) {
    const containerNode = useRef(null);
    function handleClickOutside(event) {
        if (isOpened && containerNode.current && !containerNode.current.contains(event.target)) {
            setIsOpened(false);
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });
    return containerNode;
}
