import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';

import Load from 'deprecated/core/entities/Load/types';
import TravelOrder from 'deprecated/core/entities/TravelOrder/types';
import CarrierTruck from 'deprecated/core/entities/TravelOrder/types/CarrierTruck';
import DryRun from 'deprecated/core/entities/TravelOrder/types/DryRun';
import {getTotalDistance as getLoadTotalDistance} from 'deprecated/core/entities/Load';
import {getPickUpDeliveryProgressSteps} from 'deprecated/core/entities/TravelOrder/travelOrder';
import {getTravelOrderCarrierTruckInfo} from 'deprecated/core/entities/TravelOrder/travelOrderCarrier';

import Truck from 'core/entities/Truck/types';
import Carrier from 'core/entities/Carrier/types';
import Customer from 'core/entities/Customer/types';
import Owner from 'core/entities/Owner/types';

import {Settlement, SettlementOwner, SettlementCarrier, SettlementMiles} from './types';

import {isSettlementStatusRated} from './settlementStatus';

const getSettlementTravelOrderFromSnapshot = (settlement: Settlement): TravelOrder | undefined => {
    // snapshot field contains saved travel order after change settlement status from rated
    return settlement && settlement.snapshot ? settlement.snapshot : undefined;
};

const getTravelOrderDryRun = (travelOrder: TravelOrder | undefined): DryRun | undefined => {
    return travelOrder && travelOrder.dry_run ? travelOrder.dry_run : undefined;
};

export const getSettlementTravelOrder = (settlement: Settlement): TravelOrder | undefined => {
    if (!settlement) {
        return undefined;
    }
    return isSettlementStatusRated(settlement)
        ? settlement.travel_order
        : getSettlementTravelOrderFromSnapshot(settlement);
};

const convertToNumber = (value: string | number): number => {
    return typeof value === 'string' ? parseInt(value as string, 10) : value;
};

export const isSettlementOwner = (settlement: Settlement): settlement is SettlementOwner => {
    const settlementTravelOrder = getSettlementTravelOrder(settlement);
    // because optimization all new settlements dont have travel order, all data from to included in to settlement
    return !settlementTravelOrder || !settlementTravelOrder.carrier;
};

export const getSettlementLoad = (settlement: Settlement): Load | undefined => {
    const settlementTravelOrder = getSettlementTravelOrder(settlement);
    return settlementTravelOrder && settlementTravelOrder.load;
};

export const getSettlementLoadNumber = (settlement: Settlement): number | undefined => {
    return settlement?.travel_order?.load?.load_id;
};

export const getSettlementTotalRate = (settlement: Settlement): string | number | undefined => {
    const settlementLoad = getSettlementLoad(settlement);
    return settlementLoad && settlementLoad.total_rate;
};

// need later fix problem with inner entities
export const getSettlementInvoice = (settlement: Settlement): any => {
    if (isSettlementOwner(settlement)) {
        return {
            invoice_number: settlement.invoice_number,
        };
    }
    const settlementLoad = getSettlementLoad(settlement);
    return settlementLoad && settlementLoad.invoice;
};

export const getSettlementTruck = (settlement: Settlement): Truck | undefined => {
    const travelOrder = getSettlementTravelOrder(settlement);
    return travelOrder && travelOrder.truck ? travelOrder.truck : undefined;
};

export const getSettlementOwnerTruck = (settlement: Settlement): Owner | null | undefined => {
    const travelOrder = getSettlementTravelOrder(settlement);
    return travelOrder?.truck_owner;
};

export const getSettlementCarrier = (settlement: Settlement): Carrier | undefined => {
    const travelOrder = getSettlementTravelOrder(settlement);
    return travelOrder && travelOrder.carrier ? travelOrder.carrier : undefined;
};

export const getSettlementCarrierTruckInfo = (settlement: Settlement): CarrierTruck | undefined => {
    const travelOrder = getSettlementTravelOrder(settlement);
    if (!travelOrder) {
        return undefined;
    }
    return getTravelOrderCarrierTruckInfo(travelOrder);
};

export const getSettlementCustomer = (settlement: Settlement): Customer | undefined => {
    const settlementLoad = getSettlementLoad(settlement);
    return settlementLoad && settlementLoad.customer;
};

export const getSettlementMiles = (settlement: Settlement): SettlementMiles => {
    const settlementTravelOrder = getSettlementTravelOrder(settlement);
    const settlementLoad = getSettlementLoad(settlement);
    const travelOrderDryRun = getTravelOrderDryRun(settlementTravelOrder);
    if (isSettlementOwner(settlement) && !travelOrderDryRun) {
        return {empty: settlement.empty_miles, loaded: settlement.loaded_miles};
    }
    if (travelOrderDryRun) {
        return {
            empty: travelOrderDryRun.dry_miles || 0,
            loaded: 0,
        };
    }
    return {
        empty: (settlementTravelOrder && settlementTravelOrder.empty_miles) || 0,
        loaded: (settlementLoad && getLoadTotalDistance(settlementLoad)) || 0,
    };
};

export const isSettlementTravelOrderDryRun = (settlement: SettlementOwner | SettlementCarrier): boolean => {
    if (isSettlementOwner(settlement)) {
        return settlement.travel_order_status === 'dry run. (Confirmed)';
    }
    const settlementTravelOrder = getSettlementTravelOrder(settlement);
    return !isEmpty(getTravelOrderDryRun(settlementTravelOrder));
};

export const getSettlementTravelOrderDryRunConfirmDate = (settlement: Settlement): string | undefined => {
    const settlementTravelOrder = getSettlementTravelOrder(settlement);
    const travelOrderDryRun = getTravelOrderDryRun(settlementTravelOrder);
    // eslint-disable-next-line no-restricted-globals
    return travelOrderDryRun?.confirm?.creation_date;
};

export const isSettlementCarrier = (settlement: Settlement): boolean => {
    const settlementTravelOrder = getSettlementTravelOrder(settlement);
    if (!settlementTravelOrder) {
        return false;
    }
    return !isEmpty(settlementTravelOrder.carrier);
};

// temp return any while type for owner not exists
// export const getSettlementOwner = (settlement: Settlement): Owner | undefined => {
//     const truck = getSettlementTruck(settlement);
//     return truck && truck.owner ? truck.owner : undefined;
// };

export const getSettlementOwner = (settlement: SettlementOwner): any => {
    return {
        owner_id: settlement.owner_id,
        owner_name: settlement.owner_name,
        owner_company: settlement.owner_company,
    };
};

export const isSettlementNew = (settlement: Settlement): boolean => {
    const settlementHistoryLength = settlement && settlement.status_history && settlement.status_history.length;
    return isSettlementStatusRated(settlement) && settlementHistoryLength === 1;
};

export const getSettlementTravelOrderLastDeliveryCheckoutTime = (settlement: Settlement): string | undefined => {
    const settlementTravelOrder = getSettlementTravelOrder(settlement);
    if (!settlementTravelOrder) {
        return undefined;
    }
    const travelOrderProgressSteps = getPickUpDeliveryProgressSteps(settlementTravelOrder);
    const lastTravelOrderStep: any = last(travelOrderProgressSteps);

    return lastTravelOrderStep?.check_out_datetime || undefined;
};

export const findSettlementByNumber = (
    settlements: Settlement[],
    settlementNumber: string | number,
): Settlement | undefined => {
    return settlements.find((settlement) => {
        return settlement.settlement_number === convertToNumber(settlementNumber);
    });
};

export const findSettlementByInvoiceNumber = (
    settlements: Settlement[],
    invoiceNumber: string | number,
): Settlement | undefined => {
    return settlements.find((settlement) => {
        const settlementInvoice = getSettlementInvoice(settlement);
        return settlementInvoice?.invoice_number === convertToNumber(invoiceNumber);
    });
};
