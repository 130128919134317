import isEmpty from 'lodash/isEmpty';

import TravelOrder from 'deprecated/core/entities/TravelOrder/types';
import CarrierTruck from 'deprecated/core/entities/TravelOrder/types/CarrierTruck';

export const getTravelOrderCarrierTruckInfo = (travelOrder: TravelOrder): CarrierTruck | undefined => {
    const travelOrderConfirm =
        travelOrder && travelOrder.carrier && travelOrder.dispatch && travelOrder.dispatch.confirm;

    if (!travelOrderConfirm || isEmpty(travelOrderConfirm)) {
        return undefined;
    }

    return {
        truckNumber: travelOrderConfirm.truck_number,
        driverName: travelOrderConfirm.driver_name,
        driverPhone: travelOrderConfirm.driver_phone,
    };
};
