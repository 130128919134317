import isEmpty from 'lodash/isEmpty';

import {getName, getNameSummaryField} from 'components/common/LocationEvents/modals/CheckCallModal/utils';
import {AddCheckCallFormValues} from 'components/common/LocationEvents/modals/CheckCallModal/types';

import {INVALID_CITY_LINE_FORMAT, EMPTY_DATE} from 'utils/validation/errorMessages';
import * as regexps from 'utils/validation/regexps';

const forBrokerSummarySectionName: keyof AddCheckCallFormValues = 'summaryItem';

export default (values: AddCheckCallFormValues): any => {
    const allErrors = {[forBrokerSummarySectionName]: {}};

    if (isEmpty(values)) {
        return {};
    }

    if (!values?.[getName('cityLine')]) {
        allErrors[getName('cityLine')] = INVALID_CITY_LINE_FORMAT;
    }

    if (!regexps.CITY_LINE_WITH_BRACKETS.test(values?.[getName('cityLine')])) {
        allErrors[getName('cityLine')] = INVALID_CITY_LINE_FORMAT;
    }

    if (!values?.[getName('dateTime')]) {
        allErrors[getName('dateTime')] = EMPTY_DATE;
    }

    if (
        values?.[forBrokerSummarySectionName]?.[getNameSummaryField('checkLocationForBroker')] &&
        values?.[forBrokerSummarySectionName]?.[getNameSummaryField('behindRealEvent')] === undefined
    ) {
        allErrors[forBrokerSummarySectionName][getNameSummaryField('behindRealEvent')] = 'select an option';
    }

    if (
        values?.[forBrokerSummarySectionName]?.[getNameSummaryField('checkLocationForBroker')] &&
        !values?.[forBrokerSummarySectionName]?.[getNameSummaryField('cityLine')]
    ) {
        allErrors[forBrokerSummarySectionName][getNameSummaryField('cityLine')] = INVALID_CITY_LINE_FORMAT;
    }

    if (
        values?.[forBrokerSummarySectionName]?.[getNameSummaryField('checkLocationForBroker')] &&
        !values?.[forBrokerSummarySectionName]?.[getNameSummaryField('dateTime')]
    ) {
        allErrors[forBrokerSummarySectionName][getNameSummaryField('dateTime')] = EMPTY_DATE;
    }

    return allErrors;
};
