import React, {useEffect} from 'react';

import {SenderType} from 'core/entities/Sender/types';

import Select from 'components/ui/Form/Select';

import useFetchingSenders from './hooks/useFetchingSenders';

type OwnProps = {
    input: {value: number; onChange: (value: number) => void};
    emailFrom?: string | null;
    senderType: SenderType;
    valueAsObject: boolean;
    meta: {form: string};
    placeholder: string;
    isDisabled: boolean;
    formGroup: boolean;
    isMulti: boolean;
};

const SendersSelectByType: React.FC<OwnProps> = (props) => {
    const {
        emailFrom,
        senderType,
        input: {value, onChange},
    } = props;

    const {sendersOptions, isSendersFetching} = useFetchingSenders(senderType);

    const defaultOption = sendersOptions?.find((option) => option.default);
    const initOption = sendersOptions?.find((option) => option.label === emailFrom);

    useEffect(() => {
        if (!value && initOption?.value) {
            onChange(initOption?.value);
        }

        if (!value && !initOption?.value && defaultOption?.value) {
            onChange(defaultOption?.value);
        }
    }, [defaultOption?.value, initOption?.value, value]);

    // @ts-ignore
    return <Select {...props} data={sendersOptions} isLoading={isSendersFetching} />;
};

export default SendersSelectByType;
