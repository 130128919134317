import {fetchResponsibleUserSettlements} from 'core/entities/Settlement/requests/settlementCarrierRequests';
import useFetchWithPaginate from 'hooks/useFetchWithPaginate';

import {UserSettlement} from '../types';

interface FetchData {
    settlements: UserSettlement[];
    error: string | undefined;
    currentPage: number | undefined;
    isFetching: boolean;
    isAllFetched: boolean;
    fetchNextPage();
}

const PER_PAGE = 10;

const fetchUserSettlements = (
    carrierID: number,
    userID: number,
    {page, perPage}: {page: string; perPage: number},
): Promise<UserSettlement[]> => {
    const nextPage = parseInt(page, 10) + 1;
    return fetchResponsibleUserSettlements(carrierID, userID, {page: nextPage, perPage})
        .then((response) => response.data)
        .catch((err) => {
            console.error('Error on fetch pay period responsible user settlements: ', err);
            return [];
        });
};

const cacheCreator = ({userID}: {userID: number}) => {
    return (pageIndex: any, previousPageData: any) => {
        if (previousPageData && previousPageData.length === 0) {
            return null;
        }
        const pageNumber = String(pageIndex);
        const cacheKey = `responsible-user-${userID}-settlements-${pageNumber}`;
        return [cacheKey, pageNumber];
    };
};

const fetchCreator = ({carrierID, userID}: {carrierID: number; userID: number}) => {
    return (_, pageNumber) => {
        return fetchUserSettlements(carrierID, userID, {page: pageNumber, perPage: PER_PAGE});
    };
};

export default ({carrier, responsibleID}: {carrier: number; responsibleID: number}): FetchData => {
    const getCacheKey = cacheCreator({userID: responsibleID});
    const fetcher = fetchCreator({carrierID: carrier, userID: responsibleID});
    const {data: settlements, error, currentPage, isFetching, isAllFetched, fetchNextPage} = useFetchWithPaginate({
        perPage: PER_PAGE,
        getKey: getCacheKey,
        fetchData: fetcher,
    });
    return {
        settlements,
        error,
        currentPage,
        isFetching,
        isAllFetched,
        fetchNextPage,
    };
};
