import React from 'react';
import {CurrencyValue} from 'utils/data/currency';
import {getPaymentLabel} from './utils';
import Label from '../Label';

interface OwnProps {
    rpm: number;
    currency: CurrencyValue;
}

const Payment: React.FC<OwnProps> = (props) => {
    const {rpm, currency} = props;
    const label = getPaymentLabel({rpm, currency});

    return <>{label && <Label subtitle={label} title="RPM" />} </>;
};

export default Payment;
