const formatPaginationParams = (pageData) => {
    const {currentPage, perPage} = pageData;

    if (parseInt(perPage, 10) === 0) {
        return {'per-page': perPage};
    }

    return {
        page: currentPage,
        'per-page': perPage,
    };
};

export default formatPaginationParams;
