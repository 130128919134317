import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button/index';

const ReOpenBatchModal = ({data: {batch}, handlers: {onReopen}, onClose}) => {
    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Open Batch</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row align-items-center">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-error" />
                    </div>
                    <div className="col-11">
                        <p>
                            Please confirm opening of the batch <strong>#{batch.number}</strong> with{' '}
                            {batch.settlements_count} settlement(s).
                        </p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} buttonSize="small" colorTheme="grey">
                    Cancel
                </Button>
                <Button onClick={onReopen} buttonSize="small" colorTheme="red">
                    Open Batch
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReOpenBatchModal;
