import {DateTime} from 'luxon';

import {
    DEFAULT_DATE_FORMAT,
    getNowDateTimeObject,
    createDateTimeFromJSDateAsObject,
    DEFAULT_DATE_AND_TIME_WITH_SECONDS_FORMAT,
} from 'utils/dateTime';

import {DATE_MASKS} from '../constants';

const isDateTimeValue = (value: any): value is DateTime => {
    return value && value.isLuxonDateTime;
};

export const generateDateTimeMask = (dateFormat: string) => {
    const separatorsRegExpPattern = /([/,.: -])/;

    const dateFormatChars = dateFormat.split(separatorsRegExpPattern);

    return dateFormatChars.map((char) => DATE_MASKS[char] || char).flat();
};

export const convertToJSDate = (dateValue: string | DateTime | undefined): Date => {
    if (!dateValue) {
        const now = new Date();
        return now;
    }

    if (isDateTimeValue(dateValue)) {
        return dateValue.toJSDate();
    }

    return DateTime.fromSQL(dateValue).toJSDate();
};

type ConvertFromJSOptions = {withSecondsFormat: boolean};
export const convertFromJSDate = (jsDate: Date, options: ConvertFromJSOptions): string => {
    const {withSecondsFormat} = options;
    const format = withSecondsFormat ? DEFAULT_DATE_AND_TIME_WITH_SECONDS_FORMAT : DEFAULT_DATE_FORMAT;
    const formattedDate = createDateTimeFromJSDateAsObject({dateTime: jsDate}).toFormat(format);

    return formattedDate;
};

export const getMinDate = (minDate: string | undefined | DateTime): Date => {
    if (minDate) {
        return convertToJSDate(minDate);
    }

    const now = getNowDateTimeObject();

    return now.minus({years: 75}).toJSDate();
};

export const getMaxDate = (maxDate: string | DateTime | undefined): Date => {
    if (maxDate) {
        return convertToJSDate(maxDate);
    }

    const now = getNowDateTimeObject();

    return now.plus({years: 30}).toJSDate();
};

export const getSelectedDate = (selectedDate: string): Date | undefined => {
    if (!selectedDate) {
        return undefined;
    }

    return convertToJSDate(selectedDate);
};
