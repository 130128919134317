import isString from 'lodash/isString';
import memoize from 'lodash/memoize';
import {DateTime, Duration, DurationLikeObject, DurationUnit} from 'luxon';

import {store} from 'store';
import {getCurrentDateFormat, getCurrentTimeFormat, getCurrentTimeZone} from 'store/reducers/appSettings/selectors';

import {TIME_ZONES} from 'utils/data/timeZones';
import userSession from 'utils/userSession';

import {
    DEFAULT_DATE_AND_TIME_FORMAT,
    DEFAULT_DATE_AND_TIME_WITH_SECONDS_FORMAT,
    DEFAULT_DATE_FORMAT,
    DEFAULT_TIME_FORMAT,
    DEFAULT_TIME_WITH_SECONDS_FORMAT,
} from './constants';
import {
    ChangedDate,
    ConvertedDate,
    CreateDateOptions,
    CreatedDate,
    CurrentDate,
    DateSettings,
    PastTime,
    RemainingTime,
    SetUnitOptions,
    Time,
} from './types';

const getFormattedSeconds = (seconds: number): number | string => {
    const MAX_SEC = 10;
    if (seconds > 0 && seconds < MAX_SEC) {
        return `0${seconds}`;
    }
    if (seconds === 0) {
        return '00';
    }
    return seconds;
};

export const getCurrentDateSettings = memoize((): DateSettings => {
    const {getState} = store;
    const state = getState();
    return {
        currentTimeZone: getCurrentTimeZone(state),
        currentDateFormat: getCurrentDateFormat(state),
        currentTimeFormat: getCurrentTimeFormat(state),
    };
}, userSession.getID);

export const getTimeZoneAbbreviation = (zone: string | null | undefined): string => {
    if (!zone) {
        return '';
    }
    try {
        const timeOffset = DateTime.local().setZone(zone).toFormat('Z');
        const zoneSettings = TIME_ZONES[zone] || {};
        const abbreviationByOffset = zoneSettings[timeOffset] || '';
        return abbreviationByOffset;
    } catch (dateTimeError) {
        console.error('Error on get time zone abbreviation', dateTimeError);
        return '';
    }
};

export const createDate = (dateFrom: string, options: CreateDateOptions = {}): CreatedDate => {
    if (!dateFrom) {
        return {} as CreatedDate;
    }
    const {currentTimeZone, currentDateFormat, currentTimeFormat} = getCurrentDateSettings();
    const fromTimeZone = options.fromTimeZone || 'utc';
    const toTimeZone = options.toTimeZone || currentTimeZone;
    const dateTimeMethod = options.fromISO ? 'fromISO' : 'fromSQL';
    const dateTime = DateTime[dateTimeMethod](dateFrom, {zone: fromTimeZone}).setZone(toTimeZone);
    const result = {
        date: dateTime.toFormat(options.dateFormat || currentDateFormat),
        time: dateTime.toFormat(options.timeFormat || currentTimeFormat),
        timeMeridiem: dateTime.toFormat('a'),
        originalDate: dateTime.toFormat(DEFAULT_DATE_FORMAT),
        originalTime: dateTime.toFormat(DEFAULT_TIME_FORMAT),
        originalTimeWithSeconds: dateTime.toFormat(DEFAULT_TIME_WITH_SECONDS_FORMAT),
        fullOriginalDateTime: dateTime.toFormat(`${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_WITH_SECONDS_FORMAT}`),
        timeZone: currentTimeZone,
        timeZoneCode: getTimeZoneAbbreviation(currentTimeZone),
        timeZoneCodeTo: getTimeZoneAbbreviation(toTimeZone),
        fullDate: dateTime.toFormat(`${currentDateFormat} ${currentTimeFormat}`),
        jsDate: dateTime.toJSDate(),
        isValid: dateTime.isValid,
    };
    return result;
};

export const getPastTimeFrom = (dateFrom: string, {timeZone}: {timeZone: string} = {timeZone: 'utc'}): PastTime => {
    if (!dateFrom) {
        return {} as PastTime;
    }
    const dateTimeNow = DateTime.local().setZone(timeZone);
    const dateTimeFrom = DateTime.fromSQL(dateFrom, {zone: timeZone});
    const getValue = (unit) => dateTimeNow.diff(dateTimeFrom, unit).toObject()[unit];
    const passedMilliseconds = getValue('milliseconds');
    const duration = Duration.fromObject({milliseconds: passedMilliseconds}).toFormat('dd:hh:mm:ss');
    const [durationInDays, durationInHours, durationInMinutes, durationInSeconds] = duration.split(':');
    return {
        years: getValue('years'),
        months: getValue('months'),
        days: getValue('days'),
        hours: getValue('hours'),
        minutes: getValue('minutes'),
        seconds: getValue('seconds'),
        milliseconds: passedMilliseconds,
        duration: {
            days: Number(durationInDays),
            hours: Number(durationInHours),
            minutes: Number(durationInMinutes),
            seconds: getFormattedSeconds(Number(durationInSeconds)),
        },
    };
};

export const getRemainingTimeTo = (
    dateTo: string,
    {timeZone}: {timeZone: string} = {timeZone: 'utc'},
): RemainingTime => {
    if (!dateTo) {
        return {duration: {}} as RemainingTime;
    }
    const dateTimeNow = DateTime.local().setZone(timeZone);
    const dateTimeTo = DateTime.fromSQL(dateTo, {zone: timeZone});
    const getValue = (unit) => dateTimeTo.diff(dateTimeNow, unit).toObject()[unit];
    const remainingMilliseconds = getValue('milliseconds');
    const isExpired = remainingMilliseconds <= 0;
    const duration = Duration.fromObject({milliseconds: remainingMilliseconds}).toFormat('dd:hh:mm:ss');
    const [durationInDays, durationInHours, durationInMinutes, durationInSeconds] = duration.split(':');
    const result = {
        years: getValue('years'),
        months: getValue('months'),
        days: getValue('days'),
        hours: getValue('hours'),
        minutes: getValue('minutes'),
        seconds: getValue('seconds'),
        milliseconds: remainingMilliseconds,
        duration: {
            days: Number(durationInDays),
            hours: Number(durationInHours),
            minutes: Number(durationInMinutes),
            seconds: getFormattedSeconds(Number(durationInSeconds)),
        },
        isExpired,
    };
    return result;
};

export const getCurrentDate = (params: {timeZone?: string} = {timeZone: undefined}): CurrentDate => {
    const {timeZone} = params;

    const {currentDateFormat, currentTimeFormat, currentTimeZone} = getCurrentDateSettings();
    const dateTimeZone = timeZone || currentTimeZone;
    const dateNow = DateTime.local().setZone(dateTimeZone);

    const result: CurrentDate = {
        date: dateNow.toFormat(currentDateFormat),
        time: dateNow.toFormat(currentTimeFormat),
        originalDate: dateNow.toFormat(DEFAULT_DATE_FORMAT),
        originalTime: dateNow.toFormat(DEFAULT_TIME_FORMAT),
        originalDateTime: dateNow.toFormat(DEFAULT_DATE_AND_TIME_WITH_SECONDS_FORMAT),
        minutes: Number(dateNow.toFormat('mm')),
        seconds: Number(dateNow.toFormat('ss')),
        timeZone: dateTimeZone,
        timeZoneCode: getTimeZoneAbbreviation(dateTimeZone),
        fullDate: dateNow.toFormat(`${currentDateFormat} ${currentTimeFormat}`),
    };

    return result;
};

export const changeDate = (
    date: string | undefined,
    changeParams: {
        operation: 'increase' | 'decrease';
        value: number | null;
        valueType: 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds';
    },
    convertParams: {
        fromTimezone: string;
    } = {fromTimezone: 'utc'},
): ChangedDate => {
    if (!date) {
        return {} as ChangedDate;
    }

    const {operation, value, valueType} = changeParams;
    const originDate = DateTime.fromSQL(date, {zone: convertParams.fromTimezone});
    const {currentDateFormat, currentTimeFormat, currentTimeZone} = getCurrentDateSettings();
    const getChangedDate = (): DateTime => {
        if (operation === 'increase') {
            return originDate.plus({[valueType]: value});
        }

        if (operation === 'decrease') {
            return originDate.minus({[valueType]: value});
        }

        return originDate;
    };
    const changedDate = getChangedDate();
    const result = {
        date: changedDate.toFormat(currentDateFormat),
        time: changedDate.toFormat(currentTimeFormat),
        minutes: Number(changedDate.toFormat('mm')),
        seconds: Number(changedDate.toFormat('ss')),
        timeZone: currentTimeZone,
        timeZoneCode: getTimeZoneAbbreviation(currentTimeZone),
        fullDate: changedDate.toFormat(`${currentDateFormat} ${currentTimeFormat}`),
        utcDate: changedDate.toFormat(DEFAULT_DATE_AND_TIME_WITH_SECONDS_FORMAT),
        dateInServerFormat: changedDate.toFormat(DEFAULT_DATE_FORMAT),
        jsDate: changedDate.toJSDate(),
    };
    return result;
};

export const convertDate = (
    date: string,
    {timeZoneFrom, timeZoneTo}: {timeZoneFrom: string; timeZoneTo: string},
): ConvertedDate => {
    if (!date) {
        return {} as ConvertedDate;
    }

    const originalDate = DateTime.fromSQL(date, {zone: timeZoneFrom});
    const {currentDateFormat, currentTimeFormat} = getCurrentDateSettings();
    const convertedDate = originalDate.setZone(timeZoneTo || 'utc');

    return {
        formattedDate: convertedDate.toFormat(currentDateFormat),
        formattedTime: convertedDate.toFormat(currentTimeFormat),
        formattedDateTime: convertedDate.toFormat(`${currentDateFormat} ${currentTimeFormat}`),
        defaultDateTime: convertedDate.toFormat(DEFAULT_DATE_AND_TIME_WITH_SECONDS_FORMAT),
        defaultDateInServerFormat: convertedDate.toFormat(DEFAULT_DATE_FORMAT),
        defaultTimeInServerFormat: convertedDate.toFormat(DEFAULT_TIME_FORMAT),
        startOfDateTime: originalDate.startOf('day').toFormat(DEFAULT_DATE_AND_TIME_WITH_SECONDS_FORMAT),
        endOfDateTime: originalDate.endOf('day').toFormat(DEFAULT_DATE_AND_TIME_WITH_SECONDS_FORMAT),
    };
};

export const setSpecifiedUnits = (dateTime: string, options: Partial<{[key in SetUnitOptions]: number}>) => {
    const {currentDateFormat, currentTimeFormat} = getCurrentDateSettings();
    const convertedDateTime = DateTime.fromSQL(dateTime, {zone: 'utc'}).set(options);

    return {
        dateInClientFormat: convertedDateTime.toFormat(currentDateFormat),
        timeInClientFormat: convertedDateTime.toFormat(currentTimeFormat),
        dateInServerFormat: convertedDateTime.toFormat(DEFAULT_DATE_FORMAT),
        timeInServerFormat: convertedDateTime.toFormat(DEFAULT_TIME_FORMAT),
        dateTimeInClientFormat: convertedDateTime.toFormat(`${currentDateFormat} ${currentTimeFormat}`),
        dateTimeInServerFormat: convertedDateTime.toFormat(DEFAULT_DATE_AND_TIME_FORMAT),
        dateTimeWithSecInServerFormat: convertedDateTime.toFormat(DEFAULT_DATE_AND_TIME_WITH_SECONDS_FORMAT),
    };
};

export const formatDate = (date: string | undefined | null, options?: {fromFormat?: string}): string => {
    if (!date) {
        return '';
    }
    const correctDateLength = 10;
    // for avoid incorrect format from API like '2020-12-09 00:00:00' we should get only '2020-12-09'
    const correctDate = date.slice(0, correctDateLength);
    const {currentDateFormat} = getCurrentDateSettings();
    const formFormat = options?.fromFormat || DEFAULT_DATE_FORMAT;
    const formattedDate = DateTime.fromFormat(correctDate, formFormat).toFormat(currentDateFormat);
    return formattedDate;
};

export const createTime = (timeFrom, options: {fromCurrentFormat?: boolean; timeFormat?: string} = {}): Time => {
    const {fromCurrentFormat, timeFormat} = options;
    const {currentTimeFormat} = getCurrentDateSettings();
    const format = fromCurrentFormat ? currentTimeFormat : DEFAULT_TIME_FORMAT;
    const timeData = DateTime.fromFormat(timeFrom, timeFormat || format);
    return {
        hours: Number(timeData.toFormat('hh')),
        minutes: Number(timeData.toFormat('mm')),
        seconds: Number(timeData.toFormat('ss')),
        timeFormat: currentTimeFormat,
        formattedTime: timeData.toFormat(currentTimeFormat),
        defaultTime: timeData.toFormat(DEFAULT_TIME_FORMAT),
    };
};

export const changeTime = (
    time: string | undefined,
    params: {
        operation: 'increase' | 'decrease';
        value: number | null;
        valueType: 'seconds' | 'minutes' | 'hours';
    },
): Time => {
    if (!time) {
        return {} as Time;
    }
    const {operation, value, valueType} = params;
    const originalTime = DateTime.fromFormat(time, DEFAULT_TIME_FORMAT);
    const {currentTimeFormat} = getCurrentDateSettings();
    const getChangedTime = (): DateTime => {
        if (operation === 'increase') {
            return originalTime.plus({[valueType]: value});
        }
        if (operation === 'decrease') {
            return originalTime.minus({[valueType]: value});
        }
        return originalTime;
    };
    const changedTime = getChangedTime();
    const result = {
        hours: Number(changedTime.toFormat('hh')),
        minutes: Number(changedTime.toFormat('mm')),
        seconds: Number(changedTime.toFormat('ss')),
        timeFormat: currentTimeFormat,
        formattedTime: changedTime.toFormat(currentTimeFormat),
        defaultTime: changedTime.toFormat(DEFAULT_TIME_FORMAT),
    };
    return result;
};

export const formatTime = (time: string): string => {
    if (!time) {
        return '';
    }
    const {currentTimeFormat} = getCurrentDateSettings();
    // sometimes api returns time in incorrect format with seconds like "08:00:00"
    // so for avoid incorrect parsing in luxon we should remove seconds from time
    // eslint-disable-next-line no-magic-numbers
    const parsedTime = time.split(':').slice(0, 2).join(':');
    const formattedTime = DateTime.fromFormat(parsedTime, DEFAULT_TIME_FORMAT).toFormat(currentTimeFormat);
    return formattedTime;
};

export const transformTime = (time: string, format: string): string => {
    const transformTimeFormat = DateTime.fromISO(time).toFormat(format);
    return transformTimeFormat;
};

export const getTimeDuration = (
    dateStart: string | DateTime,
    dateEnd: string | DateTime,
    options?: {fromSQL: boolean},
) => {
    if (!dateStart || !dateEnd) {
        return {} as Duration;
    }
    const getDateTime = (date: string | DateTime): DateTime => {
        if (isString(date)) {
            return options?.fromSQL
                ? DateTime.fromSQL(date)
                : DateTime.fromFormat(date as string, DEFAULT_DATE_AND_TIME_FORMAT);
        }
        return date as DateTime;
    };
    const dateFromObj = getDateTime(dateStart);
    const dateToObj = getDateTime(dateEnd);
    const extractor = (unit) => dateFromObj.diff(dateToObj, unit).toObject()[unit];
    const diffInMilliseconds = extractor('milliseconds');
    const duration = Duration.fromObject({milliseconds: diffInMilliseconds}).toFormat('dd:hh:mm:ss');
    const [durationInDays, durationInHours, durationInMinutes, durationInSeconds] = duration.split(':');
    return {
        days: Number(durationInDays),
        hours: Number(durationInHours),
        minutes: Number(durationInMinutes),
        seconds: Number(durationInSeconds),
    };
};

export const compareDateTime = (params: {
    startDateTime: string;
    endDateTime: string;
}): {
    startGreaterThanOrEqualEnd: boolean;
    startLessThanOrEqualEnd: boolean;
    startGreaterThanEnd: boolean;
    startLessThanEnd: boolean;
    startEqualEnd: boolean;
} => {
    const {startDateTime, endDateTime} = params;

    const startDateTimeOBJ = DateTime.fromSQL(startDateTime, {zone: 'utc'}).setZone('utc');
    const endDateTimeOBJ = DateTime.fromSQL(endDateTime, {zone: 'utc'}).setZone('utc');

    return {
        startGreaterThanOrEqualEnd: startDateTimeOBJ >= endDateTimeOBJ,
        startLessThanOrEqualEnd: startDateTimeOBJ <= endDateTimeOBJ,
        startGreaterThanEnd: startDateTimeOBJ > endDateTimeOBJ,
        startEqualEnd: startDateTimeOBJ.equals(endDateTimeOBJ),
        startLessThanEnd: startDateTimeOBJ < endDateTimeOBJ,
    };
};

export const getDateTimeDiffs = (params: {firstDateTime: string; secondDateTime: string}): DurationLikeObject => {
    const {firstDateTime, secondDateTime} = params;

    const firstDateTimeOBJ = DateTime.fromSQL(firstDateTime, {zone: 'utc'}).setZone('utc');
    const secondDateTimeOBJ = DateTime.fromSQL(secondDateTime, {zone: 'utc'}).setZone('utc');
    const units: DurationUnit[] = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];

    return firstDateTimeOBJ.diff(secondDateTimeOBJ, units).toObject();
};

export const shiftDurationToUnits = (from: DurationLikeObject, units: DurationUnit[]): Duration =>
    Duration.fromObject(from).shiftTo(...units);

// @ts-ignore
export const shiftDurationToUnitsObj = (...args): DurationObjectUnits => shiftDurationToUnits(...args).toObject();
