import React from 'react';

import countries from 'utils/data/countries';

import GeneralBadge from '../GeneralBadge';

type OwnProps = {
    language?: string;
    tooltipPosition?: 'left' | 'right' | 'bottom';
    withBorderRadius?: boolean;
};

const LanguageBadge: React.FC<OwnProps> = (props) => {
    const {language, tooltipPosition = 'left', withBorderRadius = false} = props;

    if (!language) {
        return null;
    }

    const currentLanguage = language.toUpperCase();
    const languagePriority = `language priority: ${countries[currentLanguage]?.name}`;

    return (
        <GeneralBadge
            text={currentLanguage}
            tooltip={languagePriority}
            tooltipPosition={tooltipPosition}
            withBorderRadius={withBorderRadius}
        />
    );
};

export default LanguageBadge;
