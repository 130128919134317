import {SubmissionError} from 'redux-form';

import * as appActions from 'store/actions';
import {getTruckTeam} from 'core/entities/Truck/modules/truckTeam';
import Driver from 'core/entities/Driver/types';
import {fetchDriver} from 'core/gateways/DriverApiGateway/requests';

import getFormFieldNames from '../utils/getFormFieldNames';

const submitValidation = (values, dispatch, props) => {
    return new Promise<void>((resolve, reject) => {
        const {getTruckOfferFieldName} = getFormFieldNames();
        const truckFieldName = getTruckOfferFieldName();
        const truck = values[truckFieldName];
        const getTruckError = (message: string) => new SubmissionError({[truckFieldName]: message});
        if (!truck) {
            reject(getTruckError('Choose truck'));
            return;
        }
        const {firstDriver} = getTruckTeam(truck);
        if (!firstDriver) {
            reject(getTruckError('Truck without first driver'));
            return;
        }
        dispatch(appActions.showLoader());
        fetchDriver(firstDriver.id)
            .then((response) => {
                const driverData: Driver = response.data;
                const {device_id} = driverData;
                if (!device_id) {
                    reject(getTruckError('Truck first driver without mobile app'));
                    dispatch(appActions.hideLoader());
                    return;
                }
                props.onSubmit(values);
            })
            .catch((error) => {
                console.error('Error on fetch offer truck first driver device id: ', error);
                reject(getTruckError('Error on check first driver app id'));
            })
            .finally(() => dispatch(appActions.hideLoader()));
    });
};

export default submitValidation;
