import React from 'react';
import {Field} from 'redux-form';

import CurrencySelect from 'components/ui/Form/CurrencySelect';
import CurrencyInput from 'components/ui/Form/MaskedInput/CurrencyInput';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';
import Payment from 'pages/LoadBoard/components/modals/common/Payment';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import CurrencyMap from 'utils/data/currency';

const Rate: React.FC = () => {
    const {getDriverRateFieldName, getDriverCurrencyFieldName, getDriverRPMFieldName} = getFormFieldNames();
    const currency = useGetReduxFormValue(FORM_NAME, getDriverCurrencyFieldName());
    const rpm = useGetReduxFormValue(FORM_NAME, getDriverRPMFieldName());

    return (
        <div className="container px-0">
            <div className="row">
                <div className="col pr-0">
                    <Field
                        maxLength={10}
                        placeholder="type the rate"
                        component={CurrencyInput}
                        name={getDriverRateFieldName()}
                        label="RATE"
                    />
                </div>
                <div className="col pl-1">
                    <Field
                        currencyMap={CurrencyMap}
                        component={CurrencySelect}
                        name={getDriverCurrencyFieldName()}
                        label="CURRENCY"
                        isClearable={false}
                        isDisabled={true}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Payment rpm={rpm} currency={currency} />
                </div>
            </div>
        </div>
    );
};

export default Rate;
