import {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import omit from 'lodash/omit';

import {SettlementOwner} from 'core/entities/Settlement/types';
import {fetchPayRecordSettlements} from 'core/entities/Settlement/requests/settlementOwnerRequests';
import parsePaginationHeaders from 'utils/parsePaginationHeaders';
import {getTypeFieldNameFactory} from 'utils/typeScript';

import {SettlementOwnerSearchParams} from 'pages/Settlements/types/SettlementSearchParams';
import {getPayRecordSettlements, getSearchParams} from 'pages/Settlements/selectors';
import {payRecordSettlementsReceived} from 'pages/Settlements/actions/periodActions';

import {isAllFetched} from './utils';

interface Params {
    payRecordID: number;
    useSearchParams?: boolean;
}

interface FetchData {
    settlements: SettlementOwner[];
    isFetching: boolean;
    isAllFetched: boolean;
    fetchNextPage();
}

interface FetchParams {
    payRecordID: number;
    searchParams: SettlementOwnerSearchParams;
    pagination: {page: number; perPage: number};
}

const PER_PAGE = 10;

const getInvoiceSearchParams = (
    searchParams: SettlementOwnerSearchParams,
): Omit<SettlementOwnerSearchParams, 'with_pay_corrections'> | object => {
    const getFieldName = getTypeFieldNameFactory<SettlementOwnerSearchParams>();
    return omit(searchParams, [getFieldName('with_pay_corrections')]);
};

const fetchSettlements = (params: FetchParams): Promise<{settlements: SettlementOwner[]; pagination: any}> => {
    const {payRecordID, searchParams, pagination} = params;
    const onlyInvoiceSearchParams = getInvoiceSearchParams(searchParams);
    return fetchPayRecordSettlements(payRecordID, pagination, {...onlyInvoiceSearchParams, status: 'Posted'})
        .then((response) => {
            return {settlements: response.data, pagination: parsePaginationHeaders(response.headers)};
        })
        .catch((error) => {
            console.error('Error on fetch pay record settlements: ', error);
            return {settlements: [], pagination: parsePaginationHeaders(error.headers)};
        });
};

export default ({payRecordID, useSearchParams = true}: Params): FetchData => {
    const [isFetching, setIsFetching] = useState(true);
    const ref = useRef({isAllFetched: false, page: 1});
    const searchParams = useSelector(getSearchParams) as SettlementOwnerSearchParams;
    const settlements = useSelector((state) => getPayRecordSettlements(state, payRecordID)) as SettlementOwner[];
    const dispatch = useDispatch();
    const fetchData = (page): void => {
        setIsFetching(true);

        fetchSettlements({
            payRecordID,
            searchParams: useSearchParams ? searchParams : ({} as SettlementOwnerSearchParams),
            pagination: {page, perPage: PER_PAGE},
        })
            .then((response) => {
                ref.current.isAllFetched = isAllFetched(response.pagination, response.settlements, PER_PAGE);
                dispatch(payRecordSettlementsReceived(payRecordID, response.settlements));
            })
            .finally(() => {
                setIsFetching(false);
            });
    };
    const fetchNextPage = (): void => {
        ref.current.page += 1;
        fetchData(ref.current.page);
    };

    useEffect(() => {
        fetchData(ref.current.page);
    }, []);

    return {
        settlements,
        isFetching,
        isAllFetched: ref.current.isAllFetched,
        fetchNextPage,
    };
};
