import React from 'react';

import Carrier from 'core/entities/Carrier/types';

import ArchivingErrorWrapper from 'components/ui/modals/ArchivingErrorWrapper';

type OwnProps = {
    data: {carrier: Carrier};
    onClose();
};

const ArchivingError = (props: OwnProps): JSX.Element => {
    const {
        data: {carrier},
        onClose,
    } = props;
    return (
        <ArchivingErrorWrapper onClose={onClose}>
            <div>
                <p>
                    The carrier <strong>{carrier.company_name}</strong> is in the <strong>incompleted load.</strong>{' '}
                    Moving the carrier to the archive is not possible. Please wait for T.O. to be finished before
                    continuing.
                </p>
            </div>
        </ArchivingErrorWrapper>
    );
};

export default ArchivingError;
