import {AxiosError} from 'axios';

import * as appActions from 'store/actions';
import {Quote} from 'core/entities/Quote/types';
import {Dispatcher} from 'core/entities/Dispatcher/types';
import * as requests from 'core/gateways/LoadBoardApiGateway/requests';

import * as actionCreators from '../actionCreators';

export const fetchQuoteGeneralNotes = (quote: Quote): any => (dispatch) => {
    requests
        .fetchQuoteNotes(quote)
        .then((response) => {
            if (response.generalNotes) {
                dispatch(actionCreators.receivedQuoteGeneralNotes(quote.id, response.generalNotes));
            }
        })
        .catch((error: AxiosError) => {
            console.error('Error on get quote notes: ', error);
            dispatch(appActions.handleError(error));
        })
        .finally(() => dispatch(appActions.hideLoader()));
};

export const createQuoteGeneralNote = (quote: Quote, text: string, createdBy: Dispatcher): any => (dispatch) => {
    requests
        .postQuoteGeneralNote(quote, text, createdBy)
        .then((createdNote) => {
            if (createdNote) {
                dispatch(actionCreators.createdQuoteGeneralNote(createdNote));
            }
        })
        .catch((error: AxiosError) => {
            console.error('Error on create quote general note: ', error);
            dispatch(appActions.handleError(error));
        })
        .finally(() => dispatch(appActions.hideLoader()));
};

export const deleteQuoteGeneralNote = (quote: Quote, noteID: string): any => (dispatch) => {
    requests
        .deleteQuoteGeneralNote(quote, noteID)
        .then((isDeleted) => {
            if (isDeleted) {
                dispatch(actionCreators.deletedQuoteGeneralNote(quote.id, noteID));
            }
        })
        .catch((error) => {
            console.error('Error on delete quote general note: ', error);
            dispatch(appActions.handleError(error));
        })
        .finally(() => dispatch(appActions.hideLoader()));
};
