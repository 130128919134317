import React, {ReactNode} from 'react';
import {Modal} from 'react-bootstrap';

type OwnProps = {
    title: string;
    buttons: ReactNode;
};

const SuccessModalWrapper: React.FC<OwnProps> = (props) => {
    const {title, children: successContent, buttons} = props;

    return (
        <Modal show={true} className="info-modal" backdrop="static">
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row align-items-center">
                    <div className="col-1">
                        <i className="fa fa-check feedback-icon-success" />
                    </div>
                    <div className="col-11">{successContent}</div>
                </div>
            </Modal.Body>

            <Modal.Footer>{buttons}</Modal.Footer>
        </Modal>
    );
};

export default SuccessModalWrapper;
