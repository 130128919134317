import React from 'react';

import Driver from 'core/entities/Driver/types';
import {getDriverName} from 'core/entities/Driver/modules/driverName';

type OwnProps = {
    driver: Driver;
};

const GeneralDriverWarning: React.FC<OwnProps> = (props) => {
    const {driver, children} = props;

    const driverName = getDriverName(driver);

    return (
        <div>
            {children}
            <p>
                Archiving <strong>{driverName}</strong> driver`s profile will deactivate any actions with it.&nbsp;If
                needed, you will still have the ability to restore the profile.
            </p>
        </div>
    );
};

export default GeneralDriverWarning;
