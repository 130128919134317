import Customer from 'core/entities/Customer/types';
import {Facility} from 'core/entities/Facility/types';

import {Entity} from '../components/DuplicatesEntitiesList/types';

const getFormattedCountry = (country: string): string => {
    const US_VALUE = 'US';
    const CANADA_VALUE = 'CA';
    const MEXICO_VALUE = 'MX';
    if (!country) {
        return '';
    }
    const countriesMap = {
        usa: US_VALUE,
        us: US_VALUE,
        canada: CANADA_VALUE,
        ca: CANADA_VALUE,
        mexico: MEXICO_VALUE,
        mx: MEXICO_VALUE,
    };
    const countryKey = country.trim().toLowerCase();
    return countriesMap[countryKey] || countryKey.toUpperCase();
};

export const getFacilityDuplicateData = (facility: Facility): Entity => {
    return {
        id: facility.id as number,
        companyName: facility.name,
        address: facility.address_line,
        location: {
            city: facility.city,
            state: facility.state,
            zip: facility.zip,
            country: getFormattedCountry(facility.country),
        },
    };
};

export const getCustomerDuplicateData = (customer: Customer): Entity => {
    return {
        id: customer.id,
        companyName: customer.name,
        address: customer.billing_address_line,
        location: {
            city: customer.billing_city,
            state: customer.billing_state,
            zip: customer.billing_zip,
            country: getFormattedCountry(customer.billing_country),
        },
    };
};

export const getSelectedEntityData = <T extends {id: string | number | null}>(
    selectedEntity: Entity,
    allEntities: T[],
): T | undefined => {
    return allEntities.find((e) => e.id === selectedEntity.id);
};
