import React from 'react';
import classNames from 'classnames';

import {SettlementCarrierLink} from 'components/ui/Links';

import DataLoading from 'pages/Settlements/components/common/DataLoading';
import useFetchBatchNotPostedSettlements from 'pages/Settlements/hooks/useFetchBatchNotPostedSettlements';

import styles from './notPostedSettlementsList.module.scss';

interface ComponentProps {
    carrierID: number;
    onClose();
}

const NotPostedSettlementsList = ({carrierID, onClose}: ComponentProps): JSX.Element | null => {
    const {
        settlements: notPostedSettlements,
        isFetching,
        isAllFetched,
        fetchNextPage,
    } = useFetchBatchNotPostedSettlements({
        carrierID,
    });
    if (notPostedSettlements.length === 0) {
        return null;
    }
    return (
        <>
            <div className={styles.custom_scroll__more_settlements}>
                <div className={styles.item}>
                    <i className="fa fa-exclamation-circle" /> There are a few not posted settlements matching current
                    batch:
                    <ul>
                        {notPostedSettlements.map((settlement) => {
                            return (
                                <li key={settlement.id}>
                                    Settlement{' '}
                                    <span onClick={onClose}>
                                        <SettlementCarrierLink
                                            settlementCarrier={settlement}
                                            content={`#${settlement.settlement_number}`}
                                            className="second-link"
                                        />
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <div className={classNames(styles.loading, isAllFetched && styles.loading__not_active)}>
                <DataLoading
                    isLoading={isFetching}
                    isAllLoaded={isAllFetched}
                    loadMoreTitle="load more settlements"
                    onLoadMore={fetchNextPage}
                />
            </div>
        </>
    );
};

export default NotPostedSettlementsList;
