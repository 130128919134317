import React from 'react';
import classNames from 'classnames';

import {Company, SetupActions, User} from '../../types';
import UsersList from './UsersList';
import styles from './companyItem.module.scss';

interface OwnProps {
    company: Company;
    selectedUsers: Record<string, User>;
    isOpened: boolean;
    actions: SetupActions;
}

const CompanyItem = ({company, selectedUsers, isOpened, actions}: OwnProps): JSX.Element => {
    const isOpenClassName = classNames('invoices-list', {open: isOpened});
    return (
        <div className={styles.item}>
            <div className={isOpenClassName}>
                <div onClick={() => actions.onSelectCompany(company)} className="invoices-list__header">
                    <div>
                        <strong>{company.name}</strong>
                    </div>
                    <div className="text-nowrap">
                        <strong>{company.invoicesCount}</strong> invoice(s)
                    </div>
                </div>
                <div className="invoices-list__content">
                    <div className="invoices-list__content_descr">
                        Select user responsible for the invoices to include them to the batch:
                    </div>

                    {isOpened && (
                        <UsersList
                            company={company}
                            selectedUsers={selectedUsers}
                            className={styles.list}
                            actions={actions}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default React.memo(CompanyItem);
