import React from 'react';

import SettlementOwner from 'core/entities/Settlement/types/SettlementOwner';

type OwnProps = {
    settlement: SettlementOwner | undefined;
};

const TruckInfo: React.FC<OwnProps> = ({settlement}) => {
    if (!settlement) {
        return null;
    }
    const {first_driver_name, truck_type, truck_number} = settlement;
    return (
        <>
            <div>
                <strong>#{truck_number}</strong> {truck_type}
            </div>
            <div>{first_driver_name}</div>
        </>
    );
};

export default TruckInfo;
