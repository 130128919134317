import {settlementsCarriersEvents} from '../../events';
import * as actions from './actions';

export const settlementsCarriersChannelMessageHandler = (store) => (uri, payload) => {
    const messageData = payload.msg;
    if (!messageData.event || !messageData.data) {
        return;
    }
    const {event, data} = messageData;

    switch (event) {
        case settlementsCarriersEvents.SETTLEMENT_UPDATE:
            store.dispatch(actions.updateSettlement(data));
            break;
        case settlementsCarriersEvents.BATCH_CREATE:
            store.dispatch(actions.createBatch(data));
            break;
        case settlementsCarriersEvents.BATCH_ADD_SETTLEMENT:
            store.dispatch(actions.addSettlementToBatch(data));
            break;
        case settlementsCarriersEvents.BATCH_RESET_SETTLEMENT:
            store.dispatch(actions.resetSettlementFromBatch(data));
            break;
        case settlementsCarriersEvents.BATCH_CLOSE:
            store.dispatch(actions.closeBatch(data));
            break;
        case settlementsCarriersEvents.BATCH_REOPEN:
            store.dispatch(actions.reOpenBatch(data));
            break;
        case settlementsCarriersEvents.BATCH_SEND_EMAIL:
            store.dispatch(actions.sendEmailForBatch(data));
            break;
        default:
            break;
    }
};
