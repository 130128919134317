import {LoadStop} from 'core/entities/Quote/types';
import {QuoteInitialValues} from 'pages/LoadBoard/types';
import {getLoadDestination, getLoadOrigin} from './utils';

type SearchDuplicates = {
    originCity: string;
    originState: string;
    originZip: string;
    destinationCity: string;
    destinationState: string;
    destinationZip: string;
};

export const getCityFromStop = (stop: LoadStop): string => stop.address.city;
export const getStateFromStop = (stop: LoadStop): string => stop.address.state;
export const getZipFromStop = (stop: LoadStop): string => stop.address.zip;

export default function (quote: QuoteInitialValues): SearchDuplicates {
    const origin = getLoadOrigin(quote);
    const destination = getLoadDestination(quote);
    return {
        originCity: getCityFromStop(origin),
        originState: getStateFromStop(origin),
        originZip: getZipFromStop(origin),
        destinationCity: getCityFromStop(destination),
        destinationState: getStateFromStop(destination),
        destinationZip: getZipFromStop(destination),
    };
}
