import React from 'react';
import {FixedSizeList as List} from 'react-window';

const DEFAULT_SELECT_ITEM_HEIGHT = 38;
const DEFAULT_ITEM_COUNT = 20;

// Because of performance problems with a large amount of data we use 'react-window' here.
// For more details visit https://github.com/JedWatson/react-select/issues/3128
const ReactWindowWrapper = ({children, options, maxHeight, getValue, itemHeight}) => {
    // 'react-window' works only with defined height of list item.
    const listHeight = maxHeight;
    const itemCount = children.length || DEFAULT_ITEM_COUNT;
    const elementSize = itemHeight || DEFAULT_SELECT_ITEM_HEIGHT;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * elementSize;

    if (!children.length) {
        return <div className="p10 d-flex justify-content-center">No options</div>;
    }

    return (
        <List
            className="menu-list"
            height={listHeight}
            itemCount={itemCount}
            itemSize={elementSize}
            initialScrollOffset={initialOffset}
        >
            {({index, style}) => <div style={style}>{children[index]}</div>}
        </List>
    );
};

export default ReactWindowWrapper;
