import {DateTime} from 'luxon';

import {getCurrentDateSettings} from './newDateTimeAPI';

interface DifferenceBetweenTwoDateTimes {
    dateTimeStart: DateTime;
    dateTimeEnd: DateTime;
}
export const getDifferenceBetweenTwoDateTimes = ({dateTimeStart, dateTimeEnd}: DifferenceBetweenTwoDateTimes) => {
    if (!dateTimeStart || !dateTimeEnd) {
        return {};
    }
    const extractor = (unit) => dateTimeStart.diff(dateTimeEnd, unit).toObject()[unit];

    return {
        years: extractor('years'),
        months: extractor('months'),
        days: extractor('days'),
        hours: extractor('hours'),
        minutes: extractor('minutes'),
        seconds: extractor('seconds'),
        milliseconds: extractor('milliseconds'),
    };
};

export const createDateTimeObject = ({dateTime, timeZone}: {dateTime: string; timeZone?: string}): DateTime => {
    return DateTime.fromSQL(dateTime, {zone: timeZone || 'local'});
};

export const getNowWithTimeZone = (timeZone) => {
    return DateTime.local().setZone(timeZone);
};

export function fromZeroTimeZoneAsObject(date, timeZone?: string) {
    const {currentTimeZone} = getCurrentDateSettings();
    const zone = timeZone || currentTimeZone;

    return DateTime.fromSQL(date, {zone: 'utc'}).setZone(zone);
}

export const getNowWithCurrentTimeZone = () => {
    const {currentTimeZone} = getCurrentDateSettings();
    return DateTime.local().setZone(currentTimeZone);
};

export function fromCurrentToZeroTimeZoneAsObject(date: string) {
    const {currentTimeZone} = getCurrentDateSettings();

    return DateTime.fromSQL(date, {zone: currentTimeZone}).setZone('utc');
}

// TODO - replace using this function on getCurrentDate from newDateTimeAPI
export const getNowDateTimeObject = (): DateTime => {
    const dateSettings = getCurrentDateSettings();
    return DateTime.local().setZone(dateSettings.currentTimeZone);
};

export const createDateTimeFromJSDateAsObject = ({dateTime}: {dateTime: Date}): DateTime => {
    return DateTime.fromJSDate(dateTime);
};

export const isTimeExpired = ({timeStr}) => {
    const expirationDate = DateTime.fromSQL(timeStr, {zone: 'utc'}).plus({seconds: -1});
    return DateTime.local() >= expirationDate;
};

export const coordinatedUniversalTime = (city: string): string => DateTime.local().setZone(city).toFormat('ZZ');
