import React, {useMemo} from 'react';
import {WrappedFieldArrayProps} from 'redux-form';
import {useDispatch} from 'react-redux';

import imageGalleryActions from 'store/reducers/gallery/actionCreators';

import Button from 'components/ui/Buttons/Button';
import FilePreview from 'components/ui/Files/FileUpload/components/FilePreview';

import {normalizeFile} from 'components/ui/Files/FileUpload/utils';

interface OwnProps {
    titleButton?: string;
    disabled?: boolean;
}

const FileList = (props: OwnProps & WrappedFieldArrayProps): JSX.Element => {
    const {fields, disabled, titleButton = 'Upload File'} = props;

    const dispatch = useDispatch();

    const files = fields.getAll();

    const onFileSelect = (event) => {
        if (event.target && event.target.files[0]) {
            fields.push({
                file: event.target.files[0],
            });
        }
    };

    const removeFile = (index: number): void => {
        fields.remove(index);
    };

    const filesNormalized = useMemo(
        function () {
            if (!files) {
                return [];
            }

            return files.map((file) => normalizeFile(file));
        },
        [files],
    );

    const handleOpenGallery = (index) => {
        dispatch(imageGalleryActions.openGallery({files: filesNormalized, currentItemIndex: index}));
    };

    const filePreviews = useMemo(
        function () {
            return filesNormalized.map((file, index) => (
                <FilePreview
                    key={index}
                    file={file}
                    viewInGallery={() => handleOpenGallery(index)}
                    handleRemove={() => removeFile(index)}
                />
            ));
        },
        [filesNormalized, disabled],
    );

    return (
        <div className="doc-info__upload mt15">
            {filePreviews.length > 0 && <div className="doc-list">{filePreviews}</div>}
            <div className="upload-button">
                <Button buttonSize="small" disabled={disabled}>
                    {titleButton}
                    <input onChange={onFileSelect} type="file" disabled={disabled} />
                </Button>
            </div>
        </div>
    );
};

export default FileList;
