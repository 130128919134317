import React from 'react';

import InfoBadge from 'components/ui/Badges/InfoBadge';

import {TRUCK_STATUS_AVAILABLE, TRUCK_STATUS_ON_ROUTE} from 'core/entities/Truck/constants/truckStatuses';

const StatusLabel = (props) => {
    const {statusText} = props;
    let statusLabelClass = '';

    if (statusText === TRUCK_STATUS_ON_ROUTE) {
        statusLabelClass = 'yellow';
    } else if (statusText === TRUCK_STATUS_AVAILABLE) {
        statusLabelClass = 'green';
    }

    return <InfoBadge text={statusText} colorBadge={statusLabelClass} />;
};

export default StatusLabel;
