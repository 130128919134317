import React from 'react';

import {DriverOffer, FormModeTypes, Quote} from 'core/entities/Quote/types';
import useDurationTime from 'pages/LoadBoard/hooks/useDurationTime';
import styles from './styles.module.scss';

type OwnProps = {
    quote: Quote;
    offer: DriverOffer;
    mode: FormModeTypes;
};

const formatDuration = (duration: {
    isExpired: boolean;
    time: {hours: number; minutes: number; seconds: number | string};
}): string => {
    const {time, isExpired} = duration;
    const timeValue = isExpired ? '00min 00sec' : `${time.minutes}min ${time.seconds}sec`;
    const startTime = 15;
    if (time.minutes > startTime) {
        return `${startTime}min 00sec`;
    }
    return timeValue;
};

const Duration: React.FC<OwnProps> = (props) => {
    const {quote, mode, offer} = props;
    const isUpdate = mode === 'UPDATE';
    const durationTime = useDurationTime(isUpdate ? offer.expireAt : quote.expireAt);
    return <div className={styles.timer}>{formatDuration(durationTime)}</div>;
};

export default Duration;
