import React, {useState} from 'react';
import {WrappedFieldProps} from 'redux-form';
import classNames from 'classnames';

import TextInput from 'components/ui/Form/TextInput';
import './style.scss';

interface OwnProps {
    disabled?: boolean;
    redesign?: boolean;
}

const TextInputBlockable = (props: OwnProps & WrappedFieldProps): JSX.Element => {
    const {
        disabled = false,
        meta: {invalid, dirty},
        redesign = false,
    } = props;
    const [isDisabled, setIsDisabled] = useState(disabled);
    const toggleIsDisabled = (): void => setIsDisabled(!isDisabled);
    const icon = isDisabled ? 'fa-pencil' : 'fa-check';

    return (
        <div className={classNames('text-input-blockable-wrap', {redesign})}>
            <TextInput {...props} disabled={isDisabled} />
            {!(invalid && dirty) && (
                <i className={`fa ${icon} edit-icon`} aria-hidden="true" onClick={toggleIsDisabled} />
            )}
        </div>
    );
};

export default TextInputBlockable;
