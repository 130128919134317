import React from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';

import * as selectors from '../../redux/selectors';

interface OwnProps {
    isNotificationsOpened: boolean;
    onCLick: () => void;
}

const NotificationsCount: React.FC<OwnProps> = (props) => {
    const {onCLick, isNotificationsOpened} = props;
    const unreadNotificationsCount = useSelector(selectors.getUnreadNotificationsCount);

    const btnClasses = classNames('menu-btn notification-bar-btn', {active: isNotificationsOpened});

    return (
        <button className={btnClasses} onClick={onCLick}>
            {unreadNotificationsCount > 0 && (
                <span className="notification-count-message">{unreadNotificationsCount}</span>
            )}
            <i className="fa fa-bell" aria-hidden="true" />
        </button>
    );
};

export default NotificationsCount;
