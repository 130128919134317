export type CurrencyTitle =
    | 'United States Dollar'
    | 'Euro'
    | 'British Pound'
    | 'Polish Zloty'
    | 'Mexican Peso'
    | 'Turkish Lira'
    | 'Romanian Leu';

export type CurrencyMark = '\u0024' | '\u20AC' | '\u00A3' | '\u007A\u0142' | '\u20B1' | '\u20BA' | '\u006C\u0065\u0069';

export type CurrencyValue = 'USD' | 'EUR' | 'GBR' | 'PLN' | 'MXN' | 'TRY' | 'RON';

export interface CurrencyData {
    title: CurrencyTitle;
    mark: CurrencyMark;
}

const CurrencyMap: Record<CurrencyValue, CurrencyData> = {
    USD: {
        title: 'United States Dollar',
        mark: '\u0024',
    },
    EUR: {
        title: 'Euro',
        mark: '\u20AC',
    },
    PLN: {
        title: 'Polish Zloty',
        mark: '\u007A\u0142',
    },
    GBR: {
        title: 'British Pound',
        mark: '\u00A3',
    },
    MXN: {
        title: 'Mexican Peso',
        mark: '\u20B1',
    },
    TRY: {
        title: 'Turkish Lira',
        mark: '\u20BA',
    },
    RON: {
        title: 'Romanian Leu',
        mark: '\u006C\u0065\u0069',
    },
};

export default CurrencyMap;
