import {Settlement, StatusResponsible, StatusHistoryItem} from './types';

import {statuses} from './constants';

export const getSettlementStatusInfo = (settlement: Settlement | undefined): StatusHistoryItem | undefined => {
    return settlement && settlement.last_status_history;
};

export const getSettlementStatusName = (settlement: Settlement): string | undefined => {
    const settlementStatusInfo = getSettlementStatusInfo(settlement);
    if (!settlementStatusInfo) {
        return;
    }

    return settlementStatusInfo.status && settlementStatusInfo.status.status_name;
};

export const getSettlementStatusResponsible = (settlement: Settlement): StatusResponsible | undefined => {
    const settlementStatusInfo = getSettlementStatusInfo(settlement);
    return settlementStatusInfo ? settlementStatusInfo.responsible : undefined;
};

export const isSettlementStatusRated = (settlement: Settlement | null | undefined): boolean => {
    if (!settlement) {
        return false;
    }
    const settlementStatusName = getSettlementStatusName(settlement);
    return settlementStatusName === statuses.RATED;
};

export const isSettlementStatusReadyToPost = (settlement: Settlement | null | undefined): boolean => {
    if (!settlement) {
        return false;
    }
    const settlementStatusName = getSettlementStatusName(settlement);
    return settlementStatusName === statuses.READY_TO_POST;
};

export const isSettlementStatusPosted = (settlement: Settlement | null | undefined): boolean => {
    if (!settlement) {
        return false;
    }
    const settlementStatusName = getSettlementStatusName(settlement);
    return settlementStatusName === statuses.POSTED;
};
