import React from 'react';

import styles from './styles.module.scss';

type OwnProps = {
    cityLine: string;
    arrivalDate: string;
};

const LoadStop: React.FC<OwnProps> = (props) => {
    const {cityLine, arrivalDate, children} = props;
    return (
        <div>
            <div>
                <strong>{cityLine}</strong> {children}
            </div>
            <div className={styles.dateWrapper}>
                <span>{arrivalDate}</span>
            </div>
        </div>
    );
};

export default LoadStop;
