import {SETTLEMENTS_CARRIERS_TYPE, SETTLEMENTS_OWNERS_TYPE} from 'core/entities/Settlement/constants';

import list from './list';
import currentSettlement from './currentSettlement';
import settlementsCarriersData from './settlementsCarriersData';
import settlementsOwnersData from './settlemetntsOwnersData';
import settlementsSettings from './settlementsSettings';

const getDataReducerByType = (state, action) => {
    const settlementsCurrentType = state && state.settings && state.settings.settlementsType;
    const reducersMap = {
        [SETTLEMENTS_CARRIERS_TYPE]: () => settlementsCarriersData(state.data, action, state),
        [SETTLEMENTS_OWNERS_TYPE]: () => settlementsOwnersData(state.data, action, state),
    };
    const dataReducer = reducersMap[settlementsCurrentType];
    return dataReducer ? dataReducer() : undefined;
};

export default (state = {}, action) => ({
    data: getDataReducerByType(state, action),
    list: list(state.list, action),
    current: currentSettlement(state.current, action),
    settings: settlementsSettings(state.settings, action),
});
