import React from 'react';
import classNames from 'classnames';

import TextHighlighter from 'components/ui/TextHighlighter';

import {Entity} from '../../types';

interface OwnProps {
    originalEntity: Entity;
    entityDuplicate: Entity;
    children: any;
}

const DuplicateItem = ({originalEntity, entityDuplicate, children}: OwnProps): JSX.Element => {
    const companyNameParts = originalEntity.companyName.split(' ');
    const addressParts = originalEntity.address ? originalEntity.address.split(' ') : [];
    const cityDuplicate = (
        <span className={classNames({coincidence: originalEntity.location.city === entityDuplicate.location.city})}>
            {entityDuplicate.location.city}
        </span>
    );
    const stateZipParts = [originalEntity.location.state, originalEntity.location.zip];
    const stateZipTextToHighLight = `${entityDuplicate.location.state} ${entityDuplicate.location.zip}`;
    const countryDuplicate = (
        <span
            className={classNames({coincidence: originalEntity.location.country === entityDuplicate.location.country})}
        >
            {entityDuplicate.location.country}
        </span>
    );
    return (
        <tr>
            <td>{entityDuplicate.id}</td>
            <td>
                <TextHighlighter highlightedWords={companyNameParts} text={entityDuplicate.companyName} />
            </td>
            <td>
                <TextHighlighter highlightedWords={addressParts} text={entityDuplicate.address} />
                <br />
                {cityDuplicate}, <TextHighlighter highlightedWords={stateZipParts} text={stateZipTextToHighLight} />,{' '}
                {countryDuplicate}
            </td>
            <td>{children}</td>
        </tr>
    );
};

export default DuplicateItem;
