import React, {useMemo} from 'react';

import PayRecordEmailInfo from 'pages/Settlements/components/common/PayRecordEmailInfo';

import CheckBoxInput from 'components/ui/Form/CheckBoxInput';

import {PayRecord} from 'core/entities/Settlement/types';

import PayRecordItem from './PayRecordItem';

import {isOwnerType, isCoordinatorType} from './utils';

import styles from './settlementsGroup.module.scss';

type SelectedPayRecords = {
    selectedPayRecords?: {[key: string]: PayRecord & {is_send_owner?: boolean; is_send_coordinator?: boolean}};
    onSelectPayRecord?: (p: PayRecord & {is_send_owner?: boolean; is_send_coordinator?: boolean}) => void;
};

type PayRecordsListTypes = {
    payRecords: PayRecord[];
    withSelectEmail?: boolean;
} & SelectedPayRecords;

type PayRecordItemTypes = {
    payRecord: PayRecord;
    withSelectEmail?: boolean;
} & SelectedPayRecords;

type SelectPayRecordDataTypes = {
    selectValue?: boolean;
    payRecord: PayRecord;
    email: string;
    type: string;
} & Pick<SelectedPayRecords, 'onSelectPayRecord'>;

const SelectPayRecordEmail = ({email, selectValue, onSelect, type}): JSX.Element => {
    const ownerPersonBadge = isOwnerType(type) ? '(ow)' : null;
    const coordinatorPersonBadge = isCoordinatorType(type) ? '(crd)' : null;
    const personBadge = ownerPersonBadge || coordinatorPersonBadge;

    const label = (() => {
        if (!email) {
            return null;
        }
        return (
            <>
                send pay record to: {personBadge} <strong>{email}</strong>
            </>
        );
    })();

    const input = {
        value: selectValue,
        onChange: () => {
            onSelect();
        },
    };

    return <CheckBoxInput position="right" label={label} input={input} />;
};

const SelectPayRecordData: React.FC<SelectPayRecordDataTypes> = ({
    type,
    email,
    payRecord,
    selectValue,
    onSelectPayRecord,
}: SelectPayRecordDataTypes) => {
    if (!email) {
        return null;
    }

    const coordinatorPayRecord = isCoordinatorType(type) ? payRecord?.coordinator : null;
    const ownerPayRecord = isOwnerType(type) ? payRecord?.owner : null;

    return (
        <div className={styles.send_row}>
            <SelectPayRecordEmail email={email} selectValue={selectValue} onSelect={onSelectPayRecord} type={type} />
            <span className="sep mr-3">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
            </span>
            <PayRecordEmailInfo payRecord={coordinatorPayRecord || ownerPayRecord || payRecord} />
        </div>
    );
};

const PayRecordItemBlock: React.FC<PayRecordItemTypes> = ({
    payRecord,
    withSelectEmail,
    selectedPayRecords,
    onSelectPayRecord,
}) => {
    const selectedPayRecord = selectedPayRecords && selectedPayRecords[payRecord.id];

    const showOwnerSend = payRecord?.owner?.is_send_owner;
    const showCoordinatorSend = payRecord?.coordinator?.is_send_coordinator;

    const onSelectPayRecordForOwner = () => {
        if (onSelectPayRecord) {
            onSelectPayRecord({
                ...payRecord,
                is_send_owner: !selectedPayRecord?.is_send_owner,
                is_send_coordinator: selectedPayRecord?.is_send_coordinator,
            });
        }
    };

    const onSelectPayRecordForCoordinator = () => {
        if (onSelectPayRecord) {
            onSelectPayRecord({
                ...payRecord,
                is_send_coordinator: !selectedPayRecord?.is_send_coordinator,
                is_send_owner: selectedPayRecord?.is_send_owner,
            });
        }
    };

    return (
        <PayRecordItem key={payRecord.id} payRecord={payRecord}>
            {withSelectEmail ? (
                <div className={styles.send}>
                    {showOwnerSend ? (
                        <SelectPayRecordData
                            selectValue={selectedPayRecord?.is_send_owner}
                            onSelectPayRecord={onSelectPayRecordForOwner}
                            email={payRecord?.owner?.email}
                            payRecord={payRecord}
                            type="owner"
                        />
                    ) : null}

                    {showCoordinatorSend ? (
                        <SelectPayRecordData
                            selectValue={selectedPayRecord?.is_send_coordinator}
                            onSelectPayRecord={onSelectPayRecordForCoordinator}
                            email={payRecord?.coordinator?.email}
                            payRecord={payRecord}
                            type="coordinator"
                        />
                    ) : null}
                </div>
            ) : null}
        </PayRecordItem>
    );
};

const PayRecordsList: React.FC<PayRecordsListTypes> = ({
    payRecords,
    withSelectEmail,
    selectedPayRecords,
    onSelectPayRecord,
}) => {
    const payRecordsItems = useMemo(() => {
        return payRecords.map((p) => (
            <PayRecordItemBlock
                selectedPayRecords={selectedPayRecords}
                onSelectPayRecord={onSelectPayRecord}
                withSelectEmail={withSelectEmail}
                payRecord={p}
                key={p.id}
            />
        ));
    }, [payRecords, selectedPayRecords]);

    return (
        <div>
            {withSelectEmail ? null : <p>Check Pay Records below in current pay period:</p>}
            {payRecordsItems}
        </div>
    );
};

export default PayRecordsList;
