import React from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';

import {PlaceAddress} from 'types/Address';

import useChangeReduxFormValues from 'hooks/reduxForm/useChangeReduxFormValues';

import {TRUCK_STATUS_NOT_AVAILABLE} from 'core/entities/Truck/constants/truckStatuses';
import {isTruckStatusOnRoute} from 'core/entities/Truck/statuses';
import Truck from 'core/entities/Truck/types';

import {createInitFormValues} from 'pages/Trucks/components/modals/UpdateStatusModal/utils';
import {UpdateStatusModalFormValues} from 'pages/Trucks/types/formTypes';
import useModalActions from 'pages/Trucks/hooks/useModalActions';

import TruckPreferredLoadsSelect from 'components/common/Truck/TruckPreferredLoads/components/TruckPreferredLoadsSelect';
import TextInputWithDatePicker from 'components/ui/Form/InputWithDatePicker';
import SelectTimeZone from 'components/ui/Form/SelectTimeZone';
import LocationFields from 'components/ui/Form/LocationFields';
import TimeInput from 'components/ui/Form/TimeInput';
import Button from 'components/ui/Buttons/Button';
import Select from 'components/ui/Form/Select';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import {statusOptions} from './constants';

const getName = getTypeFieldNameFactory<UpdateStatusModalFormValues>();

type OwnProps = {
    truck: Truck;

    onClose: () => void;
};

const Form: React.FC<OwnProps & InjectedFormProps<{}, OwnProps>> = (props) => {
    const {handleSubmit, form, truck, onClose} = props;

    const {number, status} = truck;

    const changeFormValues = useChangeReduxFormValues(form);
    const {changeTimeZoneByCoordinates} = useModalActions();

    const isTruckOnRoute = isTruckStatusOnRoute(status);

    const handleLocationSelect = (data: PlaceAddress) => {
        changeTimeZoneByCoordinates({formName: form, coordinates: data.coords});
    };

    const handleChangeTruckStatus = (selectedStatus) => {
        if (selectedStatus === TRUCK_STATUS_NOT_AVAILABLE) {
            const formValues = createInitFormValues({truck, selectedNotAvailableTruckStatus: true});

            changeFormValues(formValues);
        }
    };

    const chooseTruckStatusLabel = (
        <>
            <strong>TRUCK#{number} </strong>STATUS
        </>
    );

    return (
        <form onSubmit={handleSubmit}>
            <div className="p20 pb15">
                <div className="row">
                    <div className="col-sm-6">
                        <Field
                            onChange={handleChangeTruckStatus}
                            placeholder="choose truck status"
                            label={chooseTruckStatusLabel}
                            isDisabled={isTruckOnRoute}
                            name={getName('status')}
                            data={statusOptions}
                            component={Select}
                        />
                    </div>

                    <div className="col-sm-6">
                        <TruckPreferredLoadsSelect name={getName('preferredLoads')} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-8">
                        <Field
                            longitudeFieldName={`${getName('location')}.longitude`}
                            latitudeFieldName={`${getName('location')}.latitude`}
                            countryFieldName={`${getName('location')}.country`}
                            stateFieldName={`${getName('location')}.state`}
                            cityFieldName={`${getName('location')}.city`}
                            zipFieldName={`${getName('location')}.zip`}
                            name={`${getName('location')}.cityLine`}
                            onSelectLocation={handleLocationSelect}
                            component={LocationFields}
                            disabled={isTruckOnRoute}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <Field
                            name={`${getName('truckDateTime')}.date`}
                            component={TextInputWithDatePicker}
                            disabled={isTruckOnRoute}
                            label="Date"
                        />
                    </div>

                    <div className="col-md-4">
                        <Field
                            name={`${getName('truckDateTime')}.time`}
                            disabled={isTruckOnRoute}
                            component={TimeInput}
                            placeholder="time"
                            label="Time"
                        />
                    </div>

                    <div className="col-md-4">
                        <Field
                            name={`${getName('truckDateTime')}.timeZone`}
                            component={SelectTimeZone}
                            disabled={isTruckOnRoute}
                            isClearable={false}
                            label="Time zone"
                            withLongLabel
                        />
                    </div>
                </div>
            </div>

            <div className="modal-body__bottom">
                <Button colorTheme="grey" onClick={onClose}>
                    Cancel
                </Button>

                <Button type="submit" colorTheme="blue" disabled={isTruckOnRoute}>
                    Change
                </Button>
            </div>
        </form>
    );
};

export default reduxForm<{}, OwnProps>({})(Form);
