import React from 'react';

import {Quote} from 'core/entities/Quote/types';
import {getTruckPayloadUnit, getTruckPayloadValue} from 'core/entities/Quote/modules/quoteTruck';

import styles from './styles.module.scss';

const TruckEquipment: React.FC<{quote: Quote}> = ({quote}) => {
    const payload = getTruckPayloadValue(quote);
    const payloadUnit = getTruckPayloadUnit(quote);
    if (!payload) {
        return null;
    }
    return (
        <div className={styles.wrapper}>
            Payload: {payload} {payloadUnit}
        </div>
    );
};

export default TruckEquipment;
