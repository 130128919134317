import {DriverReceiverWithTruck} from 'core/entities/Quote/types';

import {getFilteredItems, getCurrentOptions} from './utils';

export type FilterOptions =
    | 'DRIVER_READ_QUOTE'
    | 'DRIVER_DECLINED_QUOTE'
    | 'DRIVER_PROVIDED_QUOTE'
    | 'ALLOWED_SEND_TO_DRIVER'
    | 'NOT_ALLOWED_SEND_TO_DRIVER';

type ReceiverFilter = {
    allItems: DriverReceiverWithTruck[];
    currentItems: DriverReceiverWithTruck[];
    activeOptions: FilterOptions[];
    sentChecked: boolean;
    notSentChecked: boolean;
    declinedChecked: boolean;
    readChecked: boolean;
    providedChecked: boolean;
};

export const driverFilterOptions: Record<
    | 'allowedSendToDriver'
    | 'notAllowedSendToDriver'
    | 'driverReadQuote'
    | 'driverDeclinedQuote'
    | 'driverProvidedQuote',
    FilterOptions
> = {
    allowedSendToDriver: 'ALLOWED_SEND_TO_DRIVER',
    notAllowedSendToDriver: 'NOT_ALLOWED_SEND_TO_DRIVER',
    driverReadQuote: 'DRIVER_READ_QUOTE',
    driverDeclinedQuote: 'DRIVER_DECLINED_QUOTE',
    driverProvidedQuote: 'DRIVER_PROVIDED_QUOTE',
} as const;

type SetItems = {
    type: 'setItems';
    payload: {driversReceivers: DriverReceiverWithTruck[]};
};

type ToggleAllowedSendToDriverActions = {
    type: 'toggleAllowedSendToDriver';
};

type ToggleNotAllowedSendToDriverActions = {
    type: 'toggleNotAllowedSendToDriver';
};

type ToggleDriverDeclinedQuoteActions = {
    type: 'toggleDriverDeclinedQuote';
};

type ToggleDriverReadQuoteActions = {
    type: 'toggleDriverReadQuote';
};

type ToggleDriverProvidedQuoteActions = {
    type: 'toggleDriverProvidedQuote';
};

type Actions =
    | SetItems
    | ToggleAllowedSendToDriverActions
    | ToggleNotAllowedSendToDriverActions
    | ToggleDriverDeclinedQuoteActions
    | ToggleDriverReadQuoteActions
    | ToggleDriverProvidedQuoteActions;

export const reducer = (state: ReceiverFilter, action: Actions): ReceiverFilter => {
    switch (action.type) {
        case 'setItems': {
            const {driversReceivers} = action.payload;
            return {
                ...state,
                allItems: driversReceivers,
                currentItems: driversReceivers,
            };
        }
        case 'toggleAllowedSendToDriver': {
            const currentActiveOptions = getCurrentOptions({
                options: state.activeOptions,
                currentOption: driverFilterOptions.allowedSendToDriver,
            });

            return {
                ...state,
                sentChecked: !state.sentChecked,
                currentItems: getFilteredItems({items: state.allItems, options: currentActiveOptions}),
                activeOptions: currentActiveOptions,
            };
        }
        case 'toggleNotAllowedSendToDriver': {
            const currentActiveOptions = getCurrentOptions({
                options: state.activeOptions,
                currentOption: driverFilterOptions.notAllowedSendToDriver,
            });

            return {
                ...state,
                notSentChecked: !state.notSentChecked,
                currentItems: getFilteredItems({items: state.allItems, options: currentActiveOptions}),
                activeOptions: currentActiveOptions,
            };
        }

        case 'toggleDriverDeclinedQuote': {
            const currentActiveOptions = getCurrentOptions({
                options: state.activeOptions,
                currentOption: driverFilterOptions.driverDeclinedQuote,
            });

            return {
                ...state,
                declinedChecked: !state.declinedChecked,
                currentItems: getFilteredItems({items: state.allItems, options: currentActiveOptions}),
                activeOptions: currentActiveOptions,
            };
        }

        case 'toggleDriverReadQuote': {
            const currentActiveOptions = getCurrentOptions({
                options: state.activeOptions,
                currentOption: driverFilterOptions.driverReadQuote,
            });

            return {
                ...state,
                readChecked: !state.readChecked,
                currentItems: getFilteredItems({items: state.allItems, options: currentActiveOptions}),
                activeOptions: currentActiveOptions,
            };
        }

        case 'toggleDriverProvidedQuote': {
            const currentActiveOptions = getCurrentOptions({
                options: state.activeOptions,
                currentOption: driverFilterOptions.driverProvidedQuote,
            });

            return {
                ...state,
                providedChecked: !state.providedChecked,
                currentItems: getFilteredItems({items: state.allItems, options: currentActiveOptions}),
                activeOptions: currentActiveOptions,
            };
        }

        default:
            return state;
    }
};
