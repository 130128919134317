import {createDate} from 'utils/dateTime';

export const getConvertedFormattedTime = (dateString: string | null): string => {
    if (!dateString) {
        return '-';
    }

    const {date, time} = createDate(dateString);

    return `${date} at ${time}`;
};
