import restApi from 'services/restapi';

const settlementsUrl = '/settlements';
const settlementsOwnersUrl = `${settlementsUrl}-owners`;
/**
 * @param {number} settlementNumber
 * @return {Promise}
 */
export const fetchSettlementOwner = (settlementNumber) => {
    return restApi.get(`${settlementsOwnersUrl}/${settlementNumber}`);
};
export const updateSettlementOwnerData = (settlementID, data) => {
    return restApi.put(`${settlementsOwnersUrl}/${settlementID}/update-data`, data);
};
/**
 * @param {Object} params
 * @return {Promise}
 */
export const fetchSettlementsOwnersList = (params = {}) => {
    const searchParams = {...params, owner: params?.owner?.id};
    return restApi.post(`${settlementsOwnersUrl}/search`, searchParams);
};

/**
 * @param {number} payPeriodID
 * @return {Promise}
 */
export const fetchSettlementsOwnerPayPeriod = (payPeriodID) => {
    return restApi.get(`${settlementsOwnersUrl}/pay-periods/${payPeriodID}`);
};
/**
 * @param {Object} settlement
 * @param {string} nextStatus
 * @return {Promise}
 */
export const setNextSettlementOwnerStatus = (settlement, nextStatus) => {
    return restApi.put(`${settlementsOwnersUrl}/${settlement.id}/next-status`, {status: nextStatus});
};
/**
 * @param {Object} settlement
 * @return {Promise}
 */
export const resetSettlementOwnerStatus = (settlement) => {
    return restApi.put(`${settlementsOwnersUrl}/${settlement.id}/reset-status`);
};
/**
 * @param {Object} params
 * @return {Promise}
 */
export const createSettlementPayPeriod = (params = {}) => {
    return restApi.post(`${settlementsOwnersUrl}/pay-periods`, params);
};
/**
 * @param {number} settlementID
 * @param {Object} params
 * @return {Promise}
 */
export const changeSettlementPayPeriod = (settlementID, params) => {
    return restApi.put(`${settlementsOwnersUrl}/${settlementID}/change-pay-period`, params);
};
/**
 * @param {Number} payPeriodID
 * @param {Object} params
 * @return {Promise}
 */
export const closeSettlementPayPeriod = (payPeriodID, params = {}) => {
    return restApi.put(`${settlementsOwnersUrl}/pay-periods/${payPeriodID}/close`, params);
};
/**
 * @param {Number} payPeriodID
 * @return {Promise}
 */
export const reopenSettlementPayPeriod = (payPeriodID) => {
    return restApi.put(`${settlementsOwnersUrl}/pay-periods/${payPeriodID}/reopen`);
};
/**
 * @param {Number} payPeriodID
 * @param {String} emailNote
 * @return {Promise}
 */
export const addSettlementPayPeriodEmailNote = (payPeriodID, emailNote) => {
    return restApi.put(`${settlementsOwnersUrl}/pay-periods/${payPeriodID}/add-note`, {note_to_email: emailNote});
};
/**
 * @param {Number} payPeriodID
 * @param {Object} params
 * @return {Promise}
 */
export const sendSettlementPayPeriodEmails = (payPeriodID, params) => {
    return restApi.put(`${settlementsOwnersUrl}/pay-periods/${payPeriodID}/send-emails`, params);
};

/**
 * @param {Object} params
 * @return {Promise}
 */
export const fetchPayPeriods = (params = {}) => {
    const searchParams = {...params, owner: params?.owner?.id};
    return restApi.post(`${settlementsOwnersUrl}/pay-periods/search`, searchParams);
};

/**
 * @return {Promise}
 */
export const fetchAllPayPeriods = () => {
    return restApi.get(`${settlementsOwnersUrl}/pay-periods`);
};

export const fetchPayPeriodNotPostedSettlements = (payPeriodID, pagination) => {
    const {page, perPage} = pagination;
    return restApi.get(`${settlementsOwnersUrl}/pay-periods/${payPeriodID}/view-settlements`, {
        params: {page, 'per-page': perPage},
    });
};

/**
 * @param {Number} payPeriodID
 * @param {Object} params
 * @return {Promise}
 */
export const postPayPeriodSettlements = (payPeriodID, params) => {
    return restApi.put(`${settlementsOwnersUrl}/pay-periods/${payPeriodID}/add-settlements`, params);
};

export const addPayCorrection = (payRecordId, params) => {
    return restApi.post(`${settlementsOwnersUrl}/pay-records/${payRecordId}/pay-correction`, params);
};

export const deletePayCorrection = (payCorrectionId) => {
    return restApi.delete(`${settlementsOwnersUrl}/pay-records/pay-correction/${payCorrectionId}`);
};

export const fetchPayRecord = (payRecordID) => {
    return restApi.get(`${settlementsOwnersUrl}/pay-record/${payRecordID}`);
};

export const fetchPeriodPayRecords = (payPeriodID, pagination, searchParams = {}) => {
    const {page, perPage} = pagination;
    const params = {...searchParams, owner: searchParams?.owner?.id};
    return restApi.get(`${settlementsOwnersUrl}/pay-periods/${payPeriodID}/pay-records`, {
        params: {page, 'per-page': perPage, ...params},
    });
};

export const fetchPayRecordSettlements = (payRecordID, pagination, searchParams = {}) => {
    const {page, perPage} = pagination;
    const params = {...searchParams, owner: searchParams?.owner?.id};
    return restApi.get(`${settlementsOwnersUrl}/pay-record/${payRecordID}/settlements`, {
        params: {page, 'per-page': perPage, ...params},
    });
};

export const fetchPayPeriodsSettings = () => {
    return restApi.get(`${settlementsOwnersUrl}/pay-periods/available-settlements-count`);
};

export const fetchPayPeriodResponsibleUsers = (periodID, pagination) => {
    const {page, perPage} = pagination;
    return restApi.get(`${settlementsOwnersUrl}/pay-periods/${periodID}/responsible-group`, {
        params: {page, 'per-page': perPage},
    });
};

export const fetchResponsibleUserSettlements = (periodID, userID, pagination) => {
    const {page, perPage} = pagination;
    return restApi.get(`${settlementsOwnersUrl}/period/${periodID}/responsible/${userID}`, {
        params: {page, 'per-page': perPage},
    });
};

export const deleteSettlementOwner = (settlement) => restApi.delete(`${settlementsOwnersUrl}/${settlement.id}`);

export const changeSettlementOwnerRequest = (settlementNumber, params) => {
    return restApi.put(`${settlementsOwnersUrl}/${settlementNumber}/change-owner`, params);
};
