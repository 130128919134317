import React, {useCallback} from 'react';
import {WrappedFieldInputProps, WrappedFieldMetaProps} from 'redux-form';

import Truck from 'core/entities/Truck/types';
import {searchTrucks} from 'core/gateways/TruckApiGateway/requests';

import AsyncSelect from 'components/ui/Form/AsyncSelect';

import {isNull} from 'lodash';
import {getTrucksList} from './utils';

interface OwnProps {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    valueAsObject: boolean;
    placeholder?: string;
    isDisabled?: boolean;
    label: string | JSX.Element;
}

const TrucksSelect = (props: OwnProps): JSX.Element => {
    const {input, meta, valueAsObject, placeholder = 'choose truck', label, isDisabled = false} = props;

    const loadOptions = {
        getInitialOption: () => {
            return new Promise((resolve) => {
                if (!input.value) {
                    resolve(undefined);
                    return;
                }
                const [initialFacility] = getTrucksList([input.value as Truck], valueAsObject);
                resolve(initialFacility);
            });
        },
        getNextOptions: async ({searchValue, page}): Promise<any> => {
            const searchedFacilities = await searchTrucks({number: searchValue, page});
            return searchedFacilities.data;
        },
        formatNextOptions: (nextOptions): any => {
            return getTrucksList(nextOptions, valueAsObject);
        },
    };

    const handleSelectOption = useCallback((option) => {
        if (isNull(option)) {
            input.onChange(option);
            return;
        }
        const res = valueAsObject ? option?.value : option?.value?.id;
        input.onChange(res);
    }, []);

    return (
        <AsyncSelect
            formInput={input}
            formMeta={meta}
            label={label}
            placeholder={placeholder}
            loadOptions={loadOptions}
            onSelectOption={handleSelectOption}
            isDisabled={isDisabled}
        />
    );
};

export default TrucksSelect;
