import React from 'react';

import {createDate} from 'utils/dateTime';

import {SettlementOwner} from 'core/entities/Settlement/types';
import {isSettlementTravelOrderDryRun} from 'core/entities/Settlement';

type OwnProps = {
    settlement: SettlementOwner;
    withPrefix?: boolean;
};

const getSettlementConvertedDate = (settlement: SettlementOwner): string | undefined => {
    return settlement ? createDate(settlement.settlement_date).fullDate : undefined;
};

const SettlementLastDeliveryCheckoutTime: React.FC<OwnProps> = ({settlement, withPrefix = false}) => {
    const settlementDate = getSettlementConvertedDate(settlement);

    return isSettlementTravelOrderDryRun(settlement) ? (
        <div>dry run: {settlementDate}</div>
    ) : (
        <div>
            {withPrefix && 'out: '}
            {settlementDate}
        </div>
    );
};

export default SettlementLastDeliveryCheckoutTime;
