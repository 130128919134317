import React from 'react';

import {getTruckTeam} from 'core/entities/Truck/modules/truckTeam';
import Truck from 'core/entities/Truck/types';

type OwnProps = {
    truck: Truck;
};

const TruckOwnerBadge: React.FC<OwnProps> = (props) => {
    const {truck} = props;

    const team = getTruckTeam(truck);

    if (team.ownerDriver) {
        return <strong>(o/d)</strong>;
    }

    return <strong>(ow)</strong>;
};

export default TruckOwnerBadge;
