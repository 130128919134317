import React from 'react';

import DistanceValue from 'pages/LoadBoard/components/common/DistanceValue';

type OwnProps = {
    distance: number;
};

const LoadRoute: React.FC<OwnProps> = (props) => {
    const {distance} = props;
    return (
        <div>
            <div>
                <i
                    style={{color: '#87969E', fontSize: 'x-large', marginLeft: 10}}
                    className="fa fa-long-arrow-right"
                    aria-hidden="true"
                />
            </div>
            <div>
                <strong>
                    <DistanceValue distance={distance} withUnit />
                </strong>
            </div>
        </div>
    );
};

export default LoadRoute;
