type MemoryUnit = 'B' | 'kB' | 'MB' | 'GB';

const memoryUnit: {[key in MemoryUnit]: MemoryUnit} = {B: 'B', kB: 'kB', MB: 'MB', GB: 'GB'};

const SECOND_POWER = 2;
const THIRD_POWER = 3;

const BYTES_IN_KILOBYTE = 1024;
const BYTES_IN_MEGABYTE = BYTES_IN_KILOBYTE ** SECOND_POWER;
const BYTES_IN_GIGABYTE = BYTES_IN_KILOBYTE ** THIRD_POWER;

export const convertSizeInBytesTo = (sizeInBytes: number, unit: MemoryUnit): number => {
    const unitsInBytes = {
        [memoryUnit.kB]: BYTES_IN_KILOBYTE,
        [memoryUnit.MB]: BYTES_IN_MEGABYTE,
        [memoryUnit.GB]: BYTES_IN_GIGABYTE,
    };

    return sizeInBytes / unitsInBytes[unit];
};

function roundDecimalPlaces(value: number): string {
    const DECIMAL_PLACES = 1;
    return value.toFixed(DECIMAL_PLACES);
}

function composeSizeString(fileSizeInBytes: number, unit: MemoryUnit): string {
    if (unit === memoryUnit.B) {
        return `${fileSizeInBytes} ${unit}`;
    }

    return `${roundDecimalPlaces(convertSizeInBytesTo(fileSizeInBytes, unit))} ${unit}`;
}

export default function formatFileSize(fileSizeInBytes: number): string {
    if (fileSizeInBytes < BYTES_IN_KILOBYTE) {
        return composeSizeString(fileSizeInBytes, memoryUnit.B);
    }

    if (fileSizeInBytes >= BYTES_IN_KILOBYTE && fileSizeInBytes < BYTES_IN_MEGABYTE) {
        return composeSizeString(fileSizeInBytes, memoryUnit.kB);
    }

    if (fileSizeInBytes >= BYTES_IN_MEGABYTE && fileSizeInBytes < BYTES_IN_GIGABYTE) {
        return composeSizeString(fileSizeInBytes, memoryUnit.MB);
    }

    return composeSizeString(fileSizeInBytes, memoryUnit.GB);
}
