import React from 'react';
import {Field} from 'redux-form';

import TrucksSelect from 'components/common/Truck/TrucksSelect';
import RequiredLabel from 'components/ui/Form/RequiredLabel';

interface OwnProps {
    fieldName: string;
    isUpdate: boolean;
}

const Truck: React.FC<OwnProps> = (props) => {
    const {fieldName, isUpdate} = props;
    return (
        <Field
            name={fieldName}
            isDisabled={isUpdate}
            component={TrucksSelect}
            valueAsObject={true}
            label={RequiredLabel('Truck#')}
            placeholder="enter truck"
        />
    );
};
export default Truck;
