import Carrier from 'core/entities/Carrier/types';

import {getCarrierPaymentAddressAllInfo} from '../getCarrierPaymentAddress';

export const getCarrierPaymentRemittanceEmail = (carrier?: Carrier | null) => carrier?.paymentSettings?.remittanceEmail;

export const getCarrierPaymentLocation = (carrier?: Carrier | null) => carrier?.paymentSettings?.location;

export const getCarrierPaymentAccount = (carrier?: Carrier | null) => carrier?.paymentSettings?.account;

export const getCarrierPaymentRouting = (carrier?: Carrier | null) => carrier?.paymentSettings?.routing;

export const getCarrierPaymentMethod = (carrier?: Carrier | null) => carrier?.paymentSettings?.method;

export const getCarrierPaymentSettings = (carrier?: Carrier | null) => carrier?.paymentSettings;

type CarrierPaymentSettingsAllInfo = {
    carrierPaymentRemittanceEmail: ReturnType<typeof getCarrierPaymentRemittanceEmail>;
    carrierPaymentAddressAllInfo: ReturnType<typeof getCarrierPaymentAddressAllInfo>;
    carrierPaymentSettings: ReturnType<typeof getCarrierPaymentSettings>;
    carrierPaymentLocation: ReturnType<typeof getCarrierPaymentLocation>;
    carrierPaymentAccount: ReturnType<typeof getCarrierPaymentAccount>;
    carrierPaymentRouting: ReturnType<typeof getCarrierPaymentRouting>;
    carrierPaymentMethod: ReturnType<typeof getCarrierPaymentMethod>;
};

export const getCarrierPaymentSettingsAllInfo = (carrier?: Carrier | null): CarrierPaymentSettingsAllInfo => {
    return {
        carrierPaymentRemittanceEmail: getCarrierPaymentRemittanceEmail(carrier),
        carrierPaymentAddressAllInfo: getCarrierPaymentAddressAllInfo(carrier),
        carrierPaymentSettings: getCarrierPaymentSettings(carrier),
        carrierPaymentLocation: getCarrierPaymentLocation(carrier),
        carrierPaymentAccount: getCarrierPaymentAccount(carrier),
        carrierPaymentRouting: getCarrierPaymentRouting(carrier),
        carrierPaymentMethod: getCarrierPaymentMethod(carrier),
    };
};
