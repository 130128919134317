import {useDispatch} from 'react-redux';

import {hideLoader, showLoader} from 'store/actions';

type ToggleLoader = {
    showLoader();
    hideLoader();
};
const useToggleLoader = (): ToggleLoader => {
    const dispatch = useDispatch();
    return {
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
    };
};

export default useToggleLoader;
