import {createSelector} from 'reselect';
import head from 'lodash/head';

import Pagination from 'types/Pagination';

export const getInvoiceFiles = (state) => state.invoices.current.invoiceFiles;
export const getTravelOrderFiles = (state) => state.invoices.current.travelOrderFiles;
export const getTravelOrderNotes = (state) => state.invoices.current.travelOrderNotes;
export const getLoadFiles = (state) => state.invoices.current.loadFiles;
export const getLoadNotes = (state) => state.invoices.current.loadNotes;
export const getInvoiceTravelOrderFiles = (state) => state.invoices.current.travelOrderFiles;
export const getCurrentCarrier = (state) => state.invoices.current.currentCarrier;
export const getCurrentSettlementFromInvoice = (state) => state.invoices.current.settlement;
export const getApproveSettlementStatus = (state) => state.invoices.data.isNeedApproveSettlement;
export const getStopsIncludedToPDF = (state) => state.invoices.current.stopsIncludedToPDF;
export const getInvoices = (state) => state.invoices.data.invoices;
export const getBatches = (state) => state.invoices.data.batches;
export const getListType = (state) => state.invoices.list.listType;
export const getSearchParams = (state) => state.invoices.list.searchParams;
export const getExpandedBatches = (state) => state.invoices.list.expandedBatches;
export const getInvoiceFromSearchParams = (state) => state.invoices.list.searchParams.invoice;
export const getRefNumberFromSearchParams = (state) => state.invoices.list.searchParams.ref_number;
export const getPagination = (state): Pagination => state.invoices.list.pagination;
export const getCurrentSorting = (state) => state.invoices.list.sorting;
export const isListPage = (state) => state.invoices.navigation.currentPage === 'list';

export const getCurrentInvoice = (state) => {
    const {allIds} = state.invoices.data.invoices;
    const firstInvoiceID = head(allIds);
    return state.invoices.data.invoices.byId[firstInvoiceID as any];
};

export const getListInvoices = createSelector([getInvoices], (invoices) => {
    return invoices ? invoices.allIds.map((invoiceID) => invoices.byId[invoiceID]) : [];
});
export const getListBatches = createSelector([getBatches], (batches) => {
    return batches ? batches.allIds.map((batchID) => batches.byId[batchID]) : [];
});
