import orderBy from 'lodash/orderBy';

import {getTravelOrderLocationEvents} from 'core/entities/TravelOrder/modules/travelOrderLocationEvents';
import {getTravelOrderNotes} from 'core/entities/TravelOrder/modules/travelOrderNotes';
import TravelOrder from 'core/entities/TravelOrder/types';

import {TABS} from 'components/ui/modals/AddTONotes/constants';

import {ModalNote} from '../types';

const sortByDateTime = (notes: ModalNote[]): ModalNote[] => orderBy(notes, (noteA) => noteA.createdAt, ['desc']);

export const getGeneralNotes = (travelOrder?: TravelOrder | null): ModalNote[] => {
    const travelOrderNotes = getTravelOrderNotes(travelOrder);

    return travelOrderNotes.map((note) => ({
        dispatcher: note.createdBy,
        isImportant: note.isImportant,
        createdAt: note.createdAt,
        text: note.note,
        type: 'general',
    }));
};

export const getEventNotes = (travelOrder?: TravelOrder | null): ModalNote[] => {
    const travelOrderLocationEvents = getTravelOrderLocationEvents(travelOrder);

    return travelOrderLocationEvents.map((note) => ({
        dispatcher: note.createdBy,
        createdAt: note.createdAt,
        isImportant: false,
        text: note.note,
        type: 'event',
    }));
};

export const getNotes = (params: {travelOrder?: TravelOrder | null; activeTab: typeof TABS[keyof typeof TABS]}) => {
    const {travelOrder, activeTab} = params;

    const generalNotes = getGeneralNotes(travelOrder).filter((note) => Boolean(note.text));
    const eventNotes = getEventNotes(travelOrder).filter((note) => Boolean(note.text));

    const notesCollection = {
        [TABS.all]: sortByDateTime([...generalNotes, ...eventNotes]),
        [TABS.general]: sortByDateTime(generalNotes),
        [TABS.event]: sortByDateTime(eventNotes),
    };

    return {
        notes: notesCollection[activeTab] || [],
    };
};
