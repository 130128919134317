import {InferActions} from 'store';

import {Networks} from 'core/entities/Driver/types';
import TravelOrderDefaultPresets from 'core/entities/TravelOrder/types/TravelOrderDefaultPresets';
import {FetchDispatcherRolesOptionsApiResponse} from 'core/gateways/DispatcherApiGateway/requests';

import {Currency} from 'types/Currency';

import * as types from './actionTypes';

export type Registry = {
    loadSources: {label: string; value: string}[];
    supportedFileTypes: string[];
    currency: Currency[];
};

export const receivedRegistry = (payload: Registry) => ({type: types.RECEIVED_REGISTRY, payload} as const);

export const truckAdditionalEquipmentOptionsReceived = (payload: {
    additionalEquipment: {label: string; value: string}[];
}) => ({type: types.TRUCK_ADDITIONAL_EQUIPMENT_RECEIVED, payload} as const);

export const truckBasicEquipmentOptionsReceived = (payload: {basicEquipment: {label: string; value: string}[]}) =>
    ({type: types.TRUCK_BASIC_EQUIPMENT_RECEIVED, payload} as const);

export const receivedUserRoleOptions = (payload: FetchDispatcherRolesOptionsApiResponse) =>
    ({type: types.RECEIVED_USER_ROLE_OPTIONS, payload} as const);

export const receivedTravelOrderPresets = (payload: TravelOrderDefaultPresets) =>
    ({type: types.RECEIVED_TRAVEL_ORDER_PRESETS, payload} as const);

export const receivedNetworksBenefits = (payload: {networks: Networks[]}) =>
    ({type: types.RECEIVED_NETWORKS_BENEFITS, payload} as const);

export const receivedNetworksOptions = (payload: {networks: Networks[]}) =>
    ({type: types.RECEIVED_NETWORKS_OPTIONS, payload} as const);

const actionCreators = {
    receivedRegistry,
    truckAdditionalEquipmentOptionsReceived,
    truckBasicEquipmentOptionsReceived,
    receivedUserRoleOptions,
    receivedTravelOrderPresets,
    receivedNetworksBenefits,
    receivedNetworksOptions,
};

export type RegistryActionsTypes = InferActions<typeof actionCreators>;

export default actionCreators;
