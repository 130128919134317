import React from 'react';

import {CurrencyValue} from 'utils/data/currency';

import Payment from 'pages/LoadBoard/components/modals/common/Payment';

const Rpm: React.FC<{rpm: number; currency: CurrencyValue}> = (props) => {
    const {rpm, currency} = props;
    return (
        <>
            {rpm !== undefined && (
                <div>
                    <Payment rpm={rpm} currency={currency} />
                </div>
            )}
        </>
    );
};

export default Rpm;
