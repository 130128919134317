import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import * as appActions from 'store/actions';
import {getEntityCommonData} from 'store/actions';
import {getAllSenders} from 'store/reducers/commonData/selectors';

import {fetchSenders as fetchSendersRequest} from 'core/entities/Sender/requests';
import {Sender, SenderType} from 'core/entities/Sender/types';

import {ENTITY_NAME_SENDERS} from 'utils/data/entityNames';

import {getSendersByType, getSendersOptions} from '../utils';

type SenderOptions = {label: string; value: number; default: boolean}[];

type FetchData = {
    sendersOptions: SenderOptions;
    sendersByType: Sender[];
    isSendersFetching: boolean;
};

export default (senderType: SenderType): FetchData => {
    const dispatch = useDispatch();
    const sendersList = useSelector(getAllSenders);
    const [isSendersFetching, setIsSendersFetching] = useState(false);

    const isFirstFetchSenders = isEmpty(sendersList);

    useEffect(() => {
        const fetchSenders = async () => {
            try {
                setIsSendersFetching(true);

                const {data} = await fetchSendersRequest();

                dispatch(getEntityCommonData(ENTITY_NAME_SENDERS, data));
            } catch (error) {
                dispatch(appActions.handleError(error));
            } finally {
                setIsSendersFetching(false);
            }
        };

        if (isFirstFetchSenders) {
            fetchSenders();
        }
    }, []);

    return {
        sendersOptions: getSendersOptions({sendersList, senderType}),
        sendersByType: getSendersByType({sendersList, senderType}),
        isSendersFetching,
    };
};
