import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import * as wsTypes from 'widgets/Chat/redux/actionTypes/wsTypes';
import * as wsActions from 'widgets/Chat/redux/actions/wsActions';
import {CHAT_EVENTS} from 'widgets/Chat/constants';

const addWebSocketListeners = ({webSocketStore, store}) => {
    if (!webSocketStore.instance) {
        return;
    }

    webSocketStore.instance.on(CHAT_EVENTS.newMessage, (newMessage) => {
        store.dispatch(wsActions.chatMessageReceived(newMessage));
    });

    webSocketStore.instance.on(CHAT_EVENTS.markAsReadByEveryDispatcher, (response) => {
        store.dispatch(wsActions.markLocalMessagesAsRead(response.data.driverID));
    });

    webSocketStore.instance.on('disconnect', (reason) => {
        const isReasonServerDisconnect = reason === 'io server disconnect';
        const isReasonClientDisconnect = reason === 'io client disconnect';
        const isReasonTransportError = reason === 'transport error';

        if (isReasonServerDisconnect || isReasonClientDisconnect || isReasonTransportError) {
            webSocketStore.instance.disconnect();
            webSocketStore.instance = null;
        }

        store.dispatch(wsActions.clearSocketID());

        console.log(`client has disconnected, reason: ${reason}`);
    });

    webSocketStore.instance.on('reconnect', () => {
        store.dispatch(wsActions.setWebSocketID({webSocket: webSocketStore.instance}));
    });
};

const getMoreMessages = (params) => {
    const {store, webSocketStore, action} = params;

    if (!webSocketStore.instance) {
        return;
    }

    webSocketStore.instance.emit(CHAT_EVENTS.getMoreMessages, action.payload, (response) => {
        if (isArray(response.data) && !isEmpty(response.data)) {
            store.dispatch(wsActions.moreMessagesForChannelReceived(action.payload.driverID, response.data.reverse()));
        }
    });
};

const sendMessage = (params) => {
    const {webSocketStore, action, store} = params;

    if (!webSocketStore.instance) {
        return;
    }

    webSocketStore.instance.emit(CHAT_EVENTS.newMessage, action.payload, (res) => {
        store.dispatch(res.error ? wsActions.chatMessageSendError(res.error) : wsActions.chatMessageReceived(res.data));
    });
};

const markAsReadByCurrentDispatcher = (params) => {
    const {webSocketStore, action} = params;

    if (!webSocketStore.instance) {
        return;
    }

    webSocketStore.instance.emit(CHAT_EVENTS.markAsReadByCurrentDispatcher, {driverID: action.payload.driverID});
};

const markAsReadByEveryDispatcher = (params) => {
    const {webSocketStore, action} = params;

    if (!webSocketStore.instance) {
        return;
    }

    webSocketStore.instance.emit(CHAT_EVENTS.markAsReadByEveryDispatcher, {driverID: action.payload.driverID});
};

const onWebSocketReceived = (params) => {
    const {store, action, webSocketStore} = params;

    if (webSocketStore.instance) {
        webSocketStore.instance.disconnect();
        webSocketStore.instance = null;
    }

    if (action?.payload?.webSocket) {
        webSocketStore.instance = action.payload.webSocket;

        store.dispatch(wsActions.setWebSocketID({webSocket: action.payload.webSocket}));

        addWebSocketListeners({webSocketStore, store});
    }
};

const disconnectWebSocket = (params) => {
    const {store, webSocketStore} = params;

    if (webSocketStore.instance) {
        webSocketStore.instance.disconnect();
        webSocketStore.instance = null;
    }

    store.dispatch(wsActions.clearSocketID());
};

const actionHandlers = {
    [wsTypes.WEB_SOCKET_CHAT_MARK_AS_READ_BY_CURRENT_DISPATCHER]: (params) => markAsReadByCurrentDispatcher(params),
    [wsTypes.WEB_SOCKET_CHAT_MARK_AS_READ_BY_EVERY_DISPATCHER]: (params) => markAsReadByEveryDispatcher(params),
    [wsTypes.WEB_SOCKET_CHAT_GET_MORE_CHANNEL_MESSAGES]: (params) => getMoreMessages(params),
    [wsTypes.WEB_SOCKET_CHAT_SEND_CHAT_MESSAGE]: (params) => sendMessage(params),

    // system actions
    [wsTypes.WEB_SOCKET_CHAT_CONNECTION_RECEIVED]: (params) => onWebSocketReceived(params),
    [wsTypes.WEB_SOCKET_CHAT_DISCONNECT]: (params) => disconnectWebSocket(params),
};

const chatWebSocketMiddleware = (store) => (next) => {
    const webSocketStore = {
        instance: null,
    };

    return (action) => {
        const actionHandler = actionHandlers[action.type];

        if (actionHandler) {
            actionHandler({store, action, webSocketStore});
        }

        return next(action);
    };
};

export default chatWebSocketMiddleware;
