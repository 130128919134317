import {checkIsTruckStatusNotAvailable} from 'core/entities/Truck/modules/truckStatus';
import Truck from 'core/entities/Truck/types';

import {getCurrentDate} from 'utils/dateTime';
import {getCityLineFromObject} from 'utils/getCityLine';

export const createInitFormValues = (params: {truck: Truck; selectedNotAvailableTruckStatus?: boolean}) => {
    const {truck, selectedNotAvailableTruckStatus = false} = params;

    const {status, preferred_loads, lastKnownData} = truck;

    const cityLine = lastKnownData?.location?.cityLine ? getCityLineFromObject(lastKnownData?.location?.cityLine) : '';
    const {originalDate: date, originalTime: time, timeZone} = getCurrentDate();

    const isTruckStatusNotAvailable = checkIsTruckStatusNotAvailable(truck);

    const lastKnownLocation = {
        longitude: lastKnownData?.location?.coordinates?.longitude,
        latitude: lastKnownData?.location?.coordinates?.latitude,
        country: lastKnownData?.location?.cityLine?.country,
        state: lastKnownData?.location?.cityLine?.state,
        city: lastKnownData?.location?.cityLine?.city,
        zip: lastKnownData?.location?.cityLine?.zip,
        cityLine,
    };

    return {
        location: isTruckStatusNotAvailable || selectedNotAvailableTruckStatus ? lastKnownLocation : null,
        preferredLoads: preferred_loads,
        truckDateTime: {
            timeZone,
            date,
            time,
        },
        status,
    };
};
