import {batch} from 'react-redux';

import * as appActions from 'store/actions';

import parsePaginationHeaders from 'utils/parsePaginationHeaders';
import formatPaginationParams from 'utils/formatPaginationParams';

import {LOAD_STATUS_TONU} from 'deprecated/core/entities/Load/modules/loadStatus';

import {
    fetchSettlementsOwnersList,
    resetSettlementOwnerStatus,
    setNextSettlementOwnerStatus,
    changeSettlementPayPeriod as changePayPeriod,
    fetchSettlementOwner,
    postPayPeriodSettlements,
    changeSettlementOwnerRequest,
} from 'core/entities/Settlement/requests/settlementOwnerRequests';
import {statuses} from 'core/entities/Settlement/constants';
import {isSettlementStatusPosted} from 'core/entities/Settlement/settlementStatus';
import {findPayPeriodSettlements} from 'core/entities/Settlement/settlementPayPeriod';
import {fetchSettlementData} from 'core/entities/Settlement/requests/common';

import * as types from '../actionTypes';
import {isSearchingByMainParams} from '../selectors';
import {changeSettlementsGroupStatus, setExpandedRow, setGroupStatusBySettlements} from './listActions';
import {handleChangeSettlementStatusError, handleChangeSettlementPayPeriodError} from './errorHandlers';
import {reFetchPayRecord} from './periodActions';

const postSettlementsToOpenedPayPeriod = ({periodID, settlementsIds}) => {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        const putData = {settlements: settlementsIds};
        postPayPeriodSettlements(periodID, putData)
            .then(({data: updatedPayPeriod}) => {
                const postedSettlements = findPayPeriodSettlements(updatedPayPeriod, settlementsIds);
                dispatch({
                    type: types.LIST_SETTLEMENTS_POSTED,
                    payload: postedSettlements,
                });
                dispatch(setExpandedRow(periodID));
                dispatch(changeSettlementsGroupStatus(statuses.POSTED));
            })
            .catch((error) => handleChangeSettlementStatusError(error, dispatch))
            .catch((error) => console.warn('Error on post settlements to pay period: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
};

export function fetchCurrentSettlement(settlementNumber) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        const settlement = await fetchSettlementOwner(settlementNumber)
            .then(({data}) => data)
            .catch((error) => console.warn('Error on fetch current settlement owner', error));

        if (!settlement) {
            dispatch(appActions.hideLoader());
            return;
        }

        fetchSettlementData(settlement)
            .then(({invoice, travelOrderFiles, travelOrderNotes, loadFiles, loadNotes}) => {
                dispatch({
                    type: types.SETTLEMENT_RECEIVED,
                    payload: {settlement, invoice, travelOrderFiles, travelOrderNotes, loadFiles, loadNotes},
                });
            })
            .catch((error) => console.warn('Error on fetch current settlement owner data: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export const changeSettlementOwner = ({settlementNumber, params}) => async (dispatch) => {
    if (!params) {
        return;
    }

    try {
        dispatch(appActions.showLoader());

        await changeSettlementOwnerRequest(settlementNumber, params);

        dispatch(fetchCurrentSettlement(settlementNumber));
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export function changeSettlementPayPeriod({settlementID, payPeriod}) {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        changePayPeriod(settlementID, {period_id: payPeriod.id})
            .then(({data: updatedSettlement}) => {
                dispatch({
                    type: types.SETTLEMENT_UPDATED,
                    payload: updatedSettlement,
                });
            })
            .catch((error) => handleChangeSettlementPayPeriodError(error, dispatch))
            .catch((error) => console.warn('Error change settlement pay period: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function searchOwnerSettlements(params) {
    const {status: selectedStatus, searchParams, pagination, sortBy} = params;
    return function (dispatch, getState) {
        const isSearchByMainParams = isSearchingByMainParams(getState());
        const fetchParams = {...searchParams, sortBy, ...formatPaginationParams(pagination)};

        if (!isSearchByMainParams) {
            fetchParams.status = selectedStatus;
            if (fetchParams.hide_tonu_processing) {
                fetchParams.load_status = LOAD_STATUS_TONU;
            }
        }

        dispatch(appActions.showLoader());

        fetchSettlementsOwnersList(fetchParams)
            .then(({data: settlements, headers}) => {
                // important! order actions matters cause after change status we clear current settlements|batches from state
                // see settlementsOwnersData reducer str 44
                // so we must first change status and then set new data
                dispatch(setGroupStatusBySettlements(settlements, isSearchByMainParams));
                dispatch({
                    type: types.LIST_SETTLEMENTS_RECEIVED,
                    payload: {settlements, pagination: parsePaginationHeaders(headers)},
                });
            })
            .catch((error) => console.warn('Error fetch list settlements owners: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function approveSettlementOwner(settlement) {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        setNextSettlementOwnerStatus(settlement, statuses.READY_TO_POST)
            .then(({data: approvedSettlement}) => {
                dispatch({
                    type: types.SETTLEMENT_STATUS_CHANGED,
                    payload: approvedSettlement,
                });
            })
            .catch((error) => handleChangeSettlementStatusError(error, dispatch))
            .catch((error) => console.warn('Error on approve settlement-owner: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function postListSettlementsToOpenedPeriod(data) {
    return function (dispatch) {
        const {period} = data;
        if (!period) {
            return;
        }
        const settlementsIds = period.users.flatMap((u) => u.settlements_ids);
        dispatch(postSettlementsToOpenedPayPeriod({periodID: period.id, settlementsIds}));
    };
}
export function postSettlementToOpenedPeriod(settlement, payPeriod) {
    return function (dispatch) {
        if (!settlement || !payPeriod) {
            return;
        }
        const settlementsIds = [settlement.id];
        dispatch(postSettlementsToOpenedPayPeriod({periodID: payPeriod.id, settlementsIds}));
    };
}
export function postSettlementToClosedPayPeriod(settlement, payPeriod) {
    return function (dispatch) {
        const putData = {
            settlements: [settlement.id],
        };
        dispatch(appActions.showLoader());
        postPayPeriodSettlements(payPeriod.id, putData)
            .then(({data: updatedPayPeriod}) => {
                const postedSettlements = findPayPeriodSettlements(updatedPayPeriod, [settlement.id]);
                dispatch({
                    type: types.SETTLEMENT_UPDATED,
                    payload: postedSettlements[0],
                });
                dispatch(setExpandedRow(payPeriod.id));
                dispatch(changeSettlementsGroupStatus(statuses.POSTED));
            })
            .catch((error) => handleChangeSettlementStatusError(error, dispatch))
            .catch((error) => console.warn('Error on post settlement-owner: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function resetSettlementOwner(settlement) {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        resetSettlementOwnerStatus(settlement)
            .then(({data: resetSettlement}) => {
                batch(async () => {
                    // after move logic to recalculate total values to api after reset posted settlement
                    // we should update pay period and pay record cause total charges and miles changed
                    if (isSettlementStatusPosted(settlement)) {
                        const updatedPayPeriod = resetSettlement.pay_period;
                        // cause API can not return updated pay record we should re fetch it
                        const updatedPayRecord = await reFetchPayRecord(settlement.pay_record.id);
                        dispatch({
                            type: types.PAY_PERIOD_UPDATED,
                            payload: updatedPayPeriod,
                        });
                        dispatch({
                            type: types.PAY_RECORD_UPDATED,
                            payload: updatedPayRecord,
                        });
                    }
                    dispatch({
                        type: types.SETTLEMENT_STATUS_CHANGED,
                        payload: resetSettlement,
                    });
                });
            })
            .catch((error) => handleChangeSettlementStatusError(error, dispatch))
            .catch((error) => console.warn('Error on reset settlement-owner status: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}
