import * as appActions from 'store/actions';
import Invoice from 'core/entities/Invoice/types';
import {
    postBatch,
    putBatch,
    generateBatchPDF,
    resetBatchInvoices as resetBatchInvoicesRequest,
    fetchBatches as fetchBatchesRequest,
    regenerateBatchFileRequest,
} from 'core/entities/Invoice/requests';

import formatPaginationParams from 'utils/formatPaginationParams';

import parsePaginationHeaders from '../../../utils/parsePaginationHeaders';
import * as batchTypes from '../actionTypes/index';
import {openBatchCreatedSuccessModal} from './modalActions';
import {
    handleCreteBatchError,
    handleUpdateBatchError,
    handleChangeBatchStatusError,
    handleInvoiceGenerateError,
    handleChangeInvoiceStatusError,
} from './errorHandlers';
import {transformSearchParamsForRequest} from './utils';

type DataForBatchParams = {
    data: {company: {entityID: number | null}; users: {invoicesIds: number[]}[]};
    action: 'create' | 'update';
};
const getDataForBatch = (params: DataForBatchParams): {companyID?: number | null; invoices: number[]} => {
    const {data, action} = params;
    const getInvoicesFromUsers = (users) => {
        return Object.values(users)
            .flatMap((u: any) => u.invoicesIds)
            .map((id) => parseInt(id, 10));
    };
    const getDataForCreateBatch = () => {
        const {company, users} = data;
        return {companyID: company.entityID || null, invoices: getInvoicesFromUsers(users)};
    };
    const getDataForUpdateBatch = () => {
        const {users} = data;
        return {invoices: getInvoicesFromUsers(users)};
    };
    return action === 'create' ? getDataForCreateBatch() : getDataForUpdateBatch();
};

export function fetchBatches(params) {
    const {searchParams, currentSortFrom, currentSortBy, pagination} = params;
    return function (dispatch) {
        const sortingParams = {sortBy: currentSortBy ? {[currentSortBy]: currentSortFrom} : {}};
        const formattedSearchParams = transformSearchParamsForRequest(searchParams);
        const fetchParams = {...formattedSearchParams, ...sortingParams, ...formatPaginationParams(pagination)};

        dispatch(appActions.showLoader());

        fetchBatchesRequest(fetchParams)
            .then(({data: batches, headers}) => {
                dispatch({
                    type: batchTypes.LIST_BATCHES_RECEIVED,
                    payload: {batches, pagination: parsePaginationHeaders(headers)},
                });
            })
            .catch((error) => dispatch(appActions.handleError(error)))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function createBatch(batch) {
    return function (dispatch) {
        dispatch(appActions.showLoader());

        const dataForCreateBatch = getDataForBatch({data: batch, action: 'create'});

        postBatch(dataForCreateBatch)
            .then(({data: createdBatch}) => {
                dispatch({
                    type: batchTypes.BATCH_CREATED,
                    payload: {batchNumber: createdBatch.number, createdBatch},
                });
                dispatch(openBatchCreatedSuccessModal(createdBatch));
            })
            .catch((error) => handleCreteBatchError(error, dispatch))
            .catch((error) => console.warn('Batch create error: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function updateBatch(batchNumber, updatedBatchData) {
    return function (dispatch) {
        dispatch(appActions.showLoader());

        const batchData = getDataForBatch({data: updatedBatchData, action: 'update'});

        putBatch(batchNumber, batchData)
            .then(({data: updatedBatch}) => {
                dispatch({
                    type: batchTypes.BATCH_UPDATED,
                    payload: {updatedBatch},
                });
            })
            .catch((error) => handleUpdateBatchError(error, dispatch))
            .catch((error) => console.warn('Batch update error: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function generateBatch(batch) {
    return function (dispatch) {
        dispatch(appActions.showLoader());

        generateBatchPDF(batch.number)
            .then(() => {
                // we dont use data from success request cause we should remove current updated batch from list
                // cause status of this batch was changed
                dispatch({
                    type: batchTypes.BATCH_POSTED,
                    payload: {postedBatch: batch},
                });
            })
            .catch((error) => handleInvoiceGenerateError(error, dispatch))
            .catch((error) => handleChangeBatchStatusError(error, dispatch))
            .catch((error) => console.warn('Batch post error: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function resetBatchInvoices(batchNumber: number, invoices: Invoice[]): (dispatch: any) => Promise<Invoice[]> {
    return function (dispatch) {
        dispatch(appActions.showLoader() as any);
        const invoiceIds = invoices?.map((i) => i.id);
        const resetParams = {invoiceIds};
        return resetBatchInvoicesRequest(batchNumber, resetParams)
            .then((response) => {
                return response.data;
            })
            .catch((error) => handleChangeInvoiceStatusError(error, dispatch))
            .catch((error) => console.warn('Batch reset invoices error: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export const regenerateBatchFile = (batch) => async (dispatch) => {
    if (!batch.number) {
        return;
    }

    try {
        dispatch(appActions.showLoader());

        await regenerateBatchFileRequest({batchNumber: batch.number});
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};
