import React from 'react';

import Button from 'components/ui/Buttons/Button/index';

import {PayPeriod} from 'core/entities/Settlement/types';

import useFetchPayPeriodPayRecords from 'pages/Settlements/hooks/useFetchPayPeriodPayRecords';
import DataLoading from 'pages/Settlements/components/common/DataLoading';

import WithoutPayRecordsWarning from '../WithoutPayRecordsWarning';
import PayRecordsList from '../PayRecordsList';
import PayPeriodTotal from '../PayPeriodTotal';

import NotPostedSettlementsWarning from './NotPostedSettlementsList';
import styles from './closePayPeriod.module.scss';

type OwnProps = {
    payPeriod: PayPeriod;
    onSubmit();
    onClose();
};

const ClosePayPeriod: React.FC<OwnProps> = ({payPeriod, onSubmit, onClose}) => {
    const {payRecords, isFetching, isAllFetched, fetchNextPage} = useFetchPayPeriodPayRecords({
        payPeriodID: payPeriod.id,
        useSearchParams: false,
        useDynamicPagination: true,
    });
    const isPayPeriodWithoutPayRecords = !isFetching && payRecords.length === 0;
    const loadingContainerClassName = payRecords.length === 0 ? styles.loader : '';
    return (
        <div className={styles.wrap}>
            <NotPostedSettlementsWarning payPeriodID={payPeriod.id} onClose={onClose} />

            <div className={styles.custom_scroll__pay_records}>
                {payRecords.length > 0 && (
                    <div className="modal-body__main">
                        <PayRecordsList payRecords={payRecords} />
                    </div>
                )}

                {isPayPeriodWithoutPayRecords && <WithoutPayRecordsWarning />}

                {!isAllFetched && (
                    <div className={loadingContainerClassName}>
                        <DataLoading
                            isLoading={isFetching}
                            isAllLoaded={isAllFetched}
                            loadMoreTitle="load more pay records"
                            onLoadMore={fetchNextPage}
                        />
                    </div>
                )}
            </div>

            <PayPeriodTotal payPeriod={payPeriod} />

            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <Button onClick={onSubmit} type="submit" colorTheme="blue">
                        Close Period
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ClosePayPeriod;
