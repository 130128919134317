import toLower from 'lodash/toLower';
import sortBy from 'lodash/sortBy';

import {AttachState} from 'pages/Loads/redux/reducers/attach';

import Carrier from 'core/entities/Carrier/types';

const sortCarriersByName = (carriers: Carrier[]): Carrier[] => sortBy(carriers, ['company_name']);

export const handleReducerBySetCarriersSearchParams = (params: {state: AttachState; companyName: string}) => {
    const {state, companyName} = params;

    const filteredCarriers = state.carriers.filter((carrier) =>
        toLower(carrier.company_name).includes(toLower(companyName)),
    );

    return {
        ...state,
        searchParams: {...state.searchParams, carriers: {company_name: companyName}},
        searchCarriers: filteredCarriers,
    };
};

export const handleReducerByReceivedCarriers = (params: {state: AttachState; carriers: Carrier[]}) => {
    const {state, carriers} = params;

    const sortedCarriers = sortCarriersByName(carriers);

    return {
        ...state,
        carriers: sortedCarriers,
        searchCarriers: sortedCarriers,
    };
};

export const handleReducerByClearedCarriersSearchParams = (params: {state: AttachState}) => {
    const {state} = params;

    return {
        ...state,
        searchCarriers: null,
        searchParams: {...state.searchParams, carriers: {company_name: ''}},
    };
};
