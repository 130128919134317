import {getTruckDriverIsResponsible} from 'core/entities/Truck/modules/truckDriverResponsible';
import {getTruckOwnerIsResponsible} from 'core/entities/Truck/modules/truckOwnerResponsible';
import {getTruckDrivers} from 'core/entities/Truck/modules/truckDrivers';
import Driver from 'core/entities/Driver/types';
import Truck from 'core/entities/Truck/types';

interface DriverCheckResult {
    isMainPerson: boolean;
    isOnlyFirstDriver: boolean;
}

const checkDriverOnTruck = (truck: Truck | undefined, driver: Driver): DriverCheckResult | undefined => {
    if (!truck || driver.is_deleted) {
        return;
    }

    const {firstDriver} = getTruckDrivers(truck) || {};
    const driverIsMainPerson = getTruckDriverIsResponsible(truck, driver);
    const driverIsOwnerAndMainPerson = driver.is_owner && getTruckOwnerIsResponsible(truck);
    const driverIsFirstDriver = driver.id === firstDriver?.id;

    const isMainPerson = driverIsMainPerson || driverIsOwnerAndMainPerson;
    const isOnlyFirstDriver = driverIsFirstDriver && !driverIsMainPerson && !driverIsOwnerAndMainPerson;

    return {isMainPerson, isOnlyFirstDriver};
};

export default checkDriverOnTruck;
