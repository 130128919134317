import React from 'react';

import {Entity} from './types';
import DuplicateItem from './components/DuplicateItem';

interface OwnProps {
    originalEntity: Entity;
    entityDuplicates: Entity[];
    children: any;
}

const DuplicatesEntitiesModal = ({originalEntity, entityDuplicates, children}: OwnProps): JSX.Element => {
    const duplicatesList = entityDuplicates.map((duplicate) => (
        <DuplicateItem key={duplicate.id} originalEntity={originalEntity} entityDuplicate={duplicate}>
            {children(duplicate)}
        </DuplicateItem>
    ));
    return <tbody>{duplicatesList}</tbody>;
};

export type {Entity};

export default DuplicatesEntitiesModal;
