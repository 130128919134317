import {FormErrors} from 'redux-form';

import * as errorMessages from 'utils/validation/errorMessages';
import * as regexps from 'utils/validation/regexps';

import {ManageAgentFormFields} from '../types';

const validate = (values: ManageAgentFormFields): FormErrors<ManageAgentFormFields> => {
    const errors: Partial<ManageAgentFormFields> = {};

    if (!values.agentName) {
        errors.agentName = "Please enter agent's full name.";
    }

    if (values.email && !regexps.EMAIL.test(values.email)) {
        errors.email = errorMessages.INVALID_EMAIL_ADDRESS;
    }

    return errors;
};

export default validate;
