import {trucksEvents} from '../../events';
import * as ownersActions from './actions';

export const ownersChannelMessageHandler = (store) => (uri, payload) => {
    const messageData = payload.msg;
    if (!messageData.event || !messageData.data) {
        return;
    }
    const {event, data} = messageData;

    switch (event) {
        case trucksEvents.STATUS_UPDATED:
            store.dispatch(ownersActions.ownersTrucksUpdateReceived(data));
            break;
        case trucksEvents.COORDINATES_UPDATED:
            store.dispatch(ownersActions.ownersTrucksCoordinatesReceived(data));
            break;
        default:
            break;
    }
};
