import * as types from 'pages/TravelOrders/redux/actionTypes/modal';

type DefaultState = {
    name: string | null;
    data: any;
};

const defaultState: DefaultState = {
    name: null,
    data: null,
};

export default function reducer(state = defaultState, action): DefaultState {
    switch (action.type) {
        case types.TRIP_MONITOR_SHOW_MODAL: {
            const {modalName, modalData} = action.payload;
            return {
                name: modalName,
                data: modalData,
            };
        }

        case types.TRIP_MONITOR_HIDE_MODAL: {
            return {
                name: null,
                data: null,
            };
        }

        default:
            return state;
    }
}
