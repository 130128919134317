import React, {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import sumBy from 'lodash/sumBy';

import imageGalleryActions from 'store/reducers/gallery/actionCreators';

import FileItem from '../FileItem';
import getGalleryFiles from '../getGalleryFiles';

import styles from './filesList.module.scss';

const isFileSelected = (file, selectedFiles) => {
    return file && selectedFiles && selectedFiles.find((selectedFile) => selectedFile.id === file.id);
};

const FilesList = (props) => {
    const {headerTitle, files, selectedFiles, onAddFile, onRemoveFile} = props;

    const dispatch = useDispatch();

    const handleChange = useCallback(function (file, isSelected) {
        if (isSelected) {
            onRemoveFile(file);
            return;
        }
        onAddFile(file);
    }, []);

    const galleryFileArray = useMemo(() => getGalleryFiles(files), [files]);
    const allFilesCount = useMemo(() => {
        return sumBy(files, (filesGroup) => filesGroup && filesGroup.files.length);
    }, [files]);

    const handleOpenGallery = (index) => {
        dispatch(imageGalleryActions.openGallery({files: galleryFileArray, currentItemIndex: index}));
    };

    let filesRenderIndex = -1;

    return (
        <div className={styles.item}>
            <div className="main-header">
                <span>
                    <strong>{headerTitle}</strong>
                </span>
                <span>
                    <strong>{allFilesCount}</strong> file(s)
                </span>
            </div>
            {allFilesCount === 0 && <div className="empty-content">there are no files</div>}
            {files.map((filesGroup, index) => {
                if (filesGroup.files.length === 0) {
                    return null;
                }

                return (
                    <div key={index}>
                        <div className="header">
                            <span>
                                <strong>{filesGroup.title}</strong>
                            </span>
                            <span>
                                <strong>{filesGroup.files.length}</strong> file(s)
                            </span>
                        </div>
                        <div className="main">
                            {filesGroup.files.map((file) => {
                                filesRenderIndex += 1;
                                const itemIndex = filesRenderIndex;

                                return (
                                    <FileItem
                                        key={file.id}
                                        file={file}
                                        isChecked={isFileSelected(file, selectedFiles)}
                                        onChange={handleChange}
                                        onView={() => handleOpenGallery(itemIndex)}
                                        showCheckBox
                                    />
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(FilesList);
