import normalizeNumberInput from 'utils/normalizeNumberInput';

import {convertMiToMm} from 'pages/LoadBoard/utils/distance';

export const getDigitalValue = (value: string): string => {
    const digitalValue = normalizeNumberInput(value);
    const nonZeroValue = Number(digitalValue) ? digitalValue : '';

    return nonZeroValue;
};

export const getConvertedDistance = (value: string): number => {
    if (!value) {
        return 0;
    }
    const numberedInput = Number(value);

    const result = convertMiToMm(numberedInput);

    return result;
};
