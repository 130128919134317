import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

import {TRUCK_RESERVE_NOTE_UPDATED} from 'store/actionTypes';
import {
    WEB_SOCKET_TRUCKS_COORDINATES_RECEIVED,
    WEB_SOCKET_TRUCKS_RESERVE_CANCELED,
    WEB_SOCKET_TRUCKS_RESERVE_RECEIVED,
    WEB_SOCKET_TRUCKS_UPDATE_RECEIVED,
} from 'store/middlewares/mainAPISocketsIO/entities/trucks/actionTypes';

import Truck from 'core/entities/Truck/types';

import * as types from 'pages/TrucksMap/actionTypes';
import {initialTruckSearchParams, truckLocationTypes} from 'pages/TrucksMap/constants';
import {mapCustomMarkersData} from 'pages/TrucksMap/reducer/mapData';
import updateOpenedMarkersByFilterValues from 'pages/TrucksMap/reducer/updateOpenedMarkersByFilterValues';
import {
    checkSearchedTrucks,
    checkTrucksBeforeRemove,
    getTruckForTravelOrderRoute,
    isEqualTrucksCoordinates,
} from 'pages/TrucksMap/utils';

import decodeText from 'utils/decodeText';

export const decodeNotes = (truck: Truck): Truck => {
    return {
        ...truck,
        dispatcher_note: truck.dispatcher_note ? decodeText(truck.dispatcher_note) : null,
        driver_note: truck.driver_note ? decodeText(truck.driver_note) : null,
    };
};

export const defaultState = {
    trucks: {
        byId: {},
        ids: [],
    },
    customMarkers: {
        crossDocs: {
            aroundClockTime: [],
            strictTime: [],
            flexibleTime: [],
        },
        rentTrucksEast: {
            penskeCompany: [],
            ryderCompany: [],
        },
        rentTrucksRest: {
            penskeCompany: [],
            ryderCompany: [],
        },
    },
    customMarkersFilters: {
        crossDocs: {
            aroundClockTime: false,
            strictTime: false,
            flexibleTime: false,
        },
        rentTrucksEast: {
            penskeCompany: false,
            ryderCompany: false,
        },
        rentTrucksRest: {
            penskeCompany: false,
            ryderCompany: false,
        },
    },
    customMarkersPopups: {
        crossDocs: {
            aroundClockTime: {},
            strictTime: {},
            flexibleTime: {},
        },
        rentTrucksEast: {
            penskeCompany: {},
            ryderCompany: {},
        },
        rentTrucksRest: {
            penskeCompany: {},
            ryderCompany: {},
        },
    },
    isHistoryMap: false,
    truckForHistory: null,
    historyPoints: [],
    historyDirections: [],
    historyRequestData: {},
    travelOrderDirections: [],
    searchParams: initialTruckSearchParams,
    truckLocationType: truckLocationTypes.CURRENT,
    polylineDirections: false,
    openedInfoWindows: [],
    openedCrossDocsInfo: {},
    generalTruckNotes: [],
    positionSettings: {},
    trackFromURL: null,
};

const splitToHashAndIds = (items): {byId: {}; ids: []} => ({
    byId: keyBy(items, 'id'),
    ids: items.map((item) => item.id),
});

const trucksMapReducer = (state = defaultState, action): any => {
    switch (action.type) {
        case types.RECEIVE_TRUCKS: {
            const {trucks, searchParams} = action.payload;

            return {
                ...state,
                searchParams: {...state.searchParams, ...searchParams},
                trucks: splitToHashAndIds(trucks),
            };
        }

        case TRUCK_RESERVE_NOTE_UPDATED:
        case types.DISPATCHER_NOTE_UPDATE: {
            const {
                trucks: {byId},
            } = state;
            const {truck, note} = action.payload;
            const truckToUpdate = byId[truck?.id];

            if (!truckToUpdate) {
                return state;
            }

            const truckWithUpdatedNote = decodeNotes({
                ...truckToUpdate,
                ...note,
            });

            return {
                ...state,
                trucks: {
                    ...state.trucks,
                    byId: {
                        ...state.trucks.byId,
                        [truckToUpdate.id]: truckWithUpdatedNote,
                    },
                },
            };
        }

        case WEB_SOCKET_TRUCKS_UPDATE_RECEIVED: {
            const newTruck = action.payload;

            const truck = state.trucks.byId[newTruck.id];

            if (!truck) {
                return state;
            }

            return {
                ...state,
                trucks: {
                    ...state.trucks,
                    byId: {
                        ...state.trucks.byId,
                        [newTruck.id]: newTruck,
                    },
                },
            };
        }

        case WEB_SOCKET_TRUCKS_COORDINATES_RECEIVED: {
            const newTruck = action.payload;

            const truck = state.trucks.byId[newTruck.truck];

            if (!truck || isEqualTrucksCoordinates(truck, newTruck)) {
                return state;
            }

            const updatedTruck = {
                ...truck,
                last_city_line: newTruck.city_line || truck.last_city_line,
                updated_at: newTruck.time,
                last_latitude: newTruck.latitude,
                last_longitude: newTruck.longitude,
            };

            return {
                ...state,
                trucks: {
                    ...state.trucks,
                    byId: {
                        ...state.trucks.byId,
                        [truck.id]: updatedTruck,
                    },
                },
            };
        }

        case WEB_SOCKET_TRUCKS_RESERVE_RECEIVED: {
            const reserveData = action.payload;

            const {truckId} = reserveData;

            const truck = state.trucks.byId[truckId];

            if (!truck) {
                return state;
            }

            return {
                ...state,
                trucks: {
                    ...state.trucks,
                    byId: {
                        ...state.trucks.byId,
                        [truckId]: {
                            ...state.trucks.byId[truckId],
                            reserve: reserveData,
                        },
                    },
                },
            };
        }

        case WEB_SOCKET_TRUCKS_RESERVE_CANCELED: {
            const truckId = action.payload;

            const truck = state.trucks.byId[truckId];

            if (!truck) {
                return state;
            }

            return {
                ...state,
                trucks: {
                    ...state.trucks,
                    byId: {
                        ...state.trucks.byId,
                        [truckId]: {
                            ...state.trucks.byId[truckId],
                            reserve: null,
                        },
                    },
                },
            };
        }

        case types.SET_HISTORY_ROUTE: {
            const {historyRoute, historyRequestData} = action.payload;
            const {historyDirections, historyPoints} = historyRoute;
            return {
                ...state,
                historyPoints,
                historyDirections,
                historyRequestData,
            };
        }

        case types.SET_HISTORY_FOR_TRAVEL_ORDER: {
            const {historyRoute, historyRequestData, googleDirectionsForTO} = action.payload;
            const {historyDirections, historyPoints} = historyRoute;
            return {
                ...state,
                historyPoints,
                historyDirections,
                historyRequestData,
                travelOrderDirections: googleDirectionsForTO,
                trucks: splitToHashAndIds(getTruckForTravelOrderRoute(historyRequestData.travelOrder)),
            };
        }

        case types.CLEAR_MOVEMENT_HISTORY: {
            return {
                ...state,
                historyPoints: [],
                historyDirections: [],
                historyRequestData: {},
                travelOrderDirections: [],
                trucks: defaultState.trucks,
                customMarkersFilters: defaultState.customMarkersFilters,
                searchParams: {...defaultState.searchParams, truckStatus: []},
            };
        }

        case types.SET_TRUCK_FOR_HISTORY: {
            const {truck} = action.payload;
            return {
                ...defaultState,
                searchParams: {...initialTruckSearchParams, truckStatus: []},
                customMarkers: state.customMarkers,
                isHistoryMap: state.isHistoryMap,
                truckForHistory: truck,
            };
        }

        case types.SWITCH_TO_HISTORY_MAP: {
            const {truckNumber} = action.payload;
            return {
                ...state,
                isHistoryMap: true,
                searchParams: truckNumber ? {...defaultState.searchParams, number: truckNumber} : state.searchParams,
            };
        }

        case types.SWITCH_TO_TRUCKS_MAP: {
            return {
                ...state,
                isHistoryMap: false,
            };
        }

        case types.SWITCH_TRUCK_LOCATION_TYPE: {
            return {
                ...state,
                truckLocationType: action.payload.type,
            };
        }

        case types.TOGGLE_POLYLINE_DIRECTIONS: {
            return {
                ...state,
                polylineDirections: !state.polylineDirections,
            };
        }

        case types.RECEIVE_TRUCKS_WITH_EMPTY_FILTERS: {
            return {
                ...state,
                trucks: splitToHashAndIds(checkTrucksBeforeRemove(Object.values(state.trucks.byId))),
                searchParams: {
                    truckStatus: [],
                    truckType: [],
                    truckTrailerType: [],
                    truckReeferType: [],
                },
                openedInfoWindows: [],
            };
        }

        case types.RECEIVE_TRUCKS_WITH_NON_EMPTY_FILTERS: {
            const {trucks, searchParams} = action.payload;
            return {
                ...state,
                trucks: splitToHashAndIds(checkSearchedTrucks(trucks, Object.values(state.trucks.byId))),
                searchParams,
                openedInfoWindows: [],
            };
        }

        case types.OPEN_TRUCK_PATH_INFO_WINDOW: {
            const {pathPointID} = action.payload;
            return {
                ...state,
                openedInfoWindows: [...state.openedInfoWindows, pathPointID],
            };
        }

        case types.SET_POSITION_SETTINGS: {
            return {
                ...state,
                positionSettings: action.payload,
            };
        }

        case types.SET_TRUCK_FROM_URL: {
            return {...state, trackFromURL: action.payload};
        }

        case types.OPEN_TRUCK_INFO_WINDOW: {
            const {truck} = action.payload;
            return {
                ...state,
                trucks: {
                    ...state.trucks,
                    byId: {
                        ...state.trucks.byId,
                        [truck.id]: {...truck, isCurrent: state?.trucks?.byId[truck.id]?.isCurrent},
                    },
                },
                openedInfoWindows: [...state.openedInfoWindows, truck.id],
            };
        }

        case types.REMOVE_TRUCK_INFO_WINDOW: {
            const {truckID} = action.payload;

            if (!truckID) {
                return {
                    ...state,
                    openedInfoWindows: [],
                };
            }

            return {
                ...state,
                openedInfoWindows: state.openedInfoWindows.filter((item) => item !== truckID),
            };
        }

        case types.RECEIVE_CUSTOM_MARKERS: {
            const {customMarkers} = action.payload;
            return {
                ...state,
                customMarkers: mapCustomMarkersData(customMarkers),
            };
        }

        case types.FILTER_CUSTOM_MARKERS: {
            const {filters} = action.payload;
            return {
                ...state,
                customMarkersFilters: {...state.customMarkersFilters, ...filters},
                customMarkersPopups: updateOpenedMarkersByFilterValues(filters, state.customMarkersPopups),
            };
        }

        case types.OPEN_CUSTOM_MARKER_DESCRIPTION_POPUP: {
            const {marker} = action.payload;
            const openedMarkersByType = state.customMarkersPopups[marker.type];
            const openedMarkersByGroup = openedMarkersByType[marker.group];
            if (openedMarkersByGroup[marker.id]) {
                return state;
            }
            return {
                ...state,
                customMarkersPopups: {
                    ...state.customMarkersPopups,
                    [marker.type]: {
                        ...openedMarkersByType,
                        [marker.group]: {
                            ...openedMarkersByGroup,
                            [marker.id]: true,
                        },
                    },
                },
            };
        }

        case types.CLOSE_CUSTOM_MARKER_DESCRIPTION_POPUP: {
            const {marker} = action.payload;
            const closedMarkersByType = state.customMarkersPopups[marker.type];
            const closedMarkersByGroup = closedMarkersByType[marker.group];
            return {
                ...state,
                customMarkersPopups: {
                    ...state.customMarkersPopups,
                    [marker.type]: {
                        ...closedMarkersByType,
                        [marker.group]: omit(closedMarkersByGroup, [marker.id]),
                    },
                },
            };
        }

        case types.CLOSE_ALL_CUSTOM_MARKER_DESCRIPTION_POPUP: {
            return {...state, customMarkersPopups: defaultState.customMarkersPopups};
        }

        case types.CLEAR_POSITION_SETTINGS: {
            return {
                ...state,
                positionSettings: {},
            };
        }

        case types.REFRESH_STATE: {
            return {
                ...defaultState,
                searchParams: {...initialTruckSearchParams, truckStatus: []},
                customMarkers: state.customMarkers,
            };
        }

        case types.CLEAR_STATE: {
            return defaultState;
        }

        default:
            return state;
    }
};

export default trucksMapReducer;
