import isEmpty from 'lodash/isEmpty';

import {TimerSettingsFormValues} from 'pages/TravelOrders/types/formTypes';
import {TripMonitorState} from 'pages/TravelOrders/redux/reducers/list';

import {EventTimer} from 'core/entities/TravelOrder/types/EventTimer';
import {transformToArrayEmails} from 'core/entities/LocationEvents/mappers/brokerUpdate';

import {transformDistance} from 'utils/distance';

export const transformEventTimerDataToRequestBody = (params: {timerSettings: TimerSettingsFormValues}) => {
    const {
        pickUp,
        delivery,
        autoCheckCallEnabled,
        marginForDelay,
        emailSenderId,
        emailsTo,
        subject,
        message,
        averageSpeedFrom,
        averageSpeedTo,
    } = params.timerSettings;

    const baseBody = {
        delivery: {
            period: delivery,
        },
        pickUp: {
            period: pickUp,
        },
        autoCheckCallSettings: null,
    };

    const optionalBody = {
        autoCheckCallSettings: {
            isEnabled: autoCheckCallEnabled,
            etaMarginForDelay: marginForDelay,
            averageSpeedFrom: Math.round(transformDistance(averageSpeedFrom).toMiles),
            averageSpeedTo: Math.round(transformDistance(averageSpeedTo).toMiles),
            emailSenderId,
            emailsTo: transformToArrayEmails(emailsTo),
            emailSubject: subject,
            emailText: message,
        },
    };

    return {...baseBody, ...(autoCheckCallEnabled ? optionalBody : {})};
};

export const handleReducerByReceivedEventTimer = (params: {
    travelOrderNumber: number;
    state: TripMonitorState;
    eventTimer: EventTimer;
}) => {
    const {eventTimer, travelOrderNumber, state} = params;

    if (!travelOrderNumber || isEmpty(state.items) || isEmpty(eventTimer)) {
        return state;
    }

    const updatedItems = state.items.map((item) => (item.number === travelOrderNumber ? {...item, eventTimer} : item));

    return {...state, items: updatedItems};
};
