export const DRIVER_SEARCH_FORM = 'chat-driver-search-form';
export const SEND_MESSAGE_FORM = 'chat-send-message-form';
export const SENDER_TYPE = 'dispatcher';
export const TEXT_FIELD = 'text';

export const CHAT_EVENTS = {
    markAsReadByCurrentDispatcher: 'markAsReadByCurrentDispatcher',
    markAsReadByEveryDispatcher: 'markAsReadByEveryDispatcher',

    markAsRead: 'markAsReadByDispatchers',
    getMoreMessages: 'getMoreMessages',
    newMessage: 'newMessage',
};

export const ROOMS = {
    common: 'common',
    driver: 'driver',
};
