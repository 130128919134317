export const RECEIVE_TRUCKS = 'TRUCKS_MAP_RECEIVE_TRUCKS';
export const TOGGLE_POLYLINE_DIRECTIONS = 'TRUCKS_MAP_TOGGLE_POLYLINE_DIRECTIONS';

export const DISPATCHER_NOTE_UPDATE = 'TRUCKS_MAP_DISPATCHER_NOTE_UPDATE';

export const RECEIVE_CUSTOM_MARKERS = 'TRUCKS_MAP_RECEIVE_CUSTOM_MARKERS';
export const FILTER_CUSTOM_MARKERS = 'TRUCKS_MAP_FILTER_CUSTOM_MARKERS';

export const CLEAR_STATE = 'TRUCKS_MAP_CLEAR_STATE';
export const REFRESH_STATE = 'TRUCKS_MAP_REFRESH_STATE';
export const CLEAR_MOVEMENT_HISTORY = 'TRUCKS_MAP_CLEAR_MOVEMENT_HISTORY';

export const SET_TRUCK_FOR_HISTORY = 'TRUCKS_MAP_SET_TRUCK_FOR_HISTORY';
export const SET_HISTORY_ROUTE = 'TRUCKS_MAP_SET_HISTORY_ROUTE';
export const OPEN_TRUCK_INFO_WINDOW = 'TRUCKS_MAP_OPEN_TRUCK_INFO_WINDOW';
export const OPEN_TRUCK_PATH_INFO_WINDOW = 'TRUCKS_MAP_OPEN_TRUCK_PATH_INFO_WINDOW';
export const REMOVE_TRUCK_INFO_WINDOW = 'TRUCKS_MAP_REMOVE_TRUCK_INFO_WINDOW';

export const SWITCH_TO_HISTORY_MAP = 'TRUCKS_MAP_SWITCH_TO_HISTORY_MAP';
export const SWITCH_TO_TRUCKS_MAP = 'TRUCKS_MAP_SWITCH_TO_TRUCKS_MAP';
export const SWITCH_TRUCK_LOCATION_TYPE = 'TRUCKS_MAP_SWITCH_TRUCK_LOCATION_TYPE';

export const OPEN_CUSTOM_MARKER_DESCRIPTION_POPUP = 'TRUCKS_MAP_SET_OPENED_CUSTOM_MARKER_DESCRIPTION_POPUP';
export const CLOSE_CUSTOM_MARKER_DESCRIPTION_POPUP = 'TRUCKS_MAP_SET_CLOSED_CUSTOM_MARKER_DESCRIPTION_POPUP';
export const CLOSE_ALL_CUSTOM_MARKER_DESCRIPTION_POPUP = 'TRUCKS_MAP_SET_CLOSED_ALL_CUSTOM_MARKER_DESCRIPTION_POPUPS';

export const SET_POSITION_SETTINGS = 'TRUCKS_MAP_SET_POSITION_SETTINGS';
export const CLEAR_POSITION_SETTINGS = 'TRUCKS_MAP_CLEAR_POSITION_SETTINGS';

export const SET_HISTORY_FOR_TRAVEL_ORDER = 'TRUCKS_MAP_SET_HISTORY_FOR_TRAVEL_ORDER';

export const RECEIVE_TRUCKS_WITH_EMPTY_FILTERS = 'TRUCKS_MAP_RECEIVE_TRUCKS_WITH_EMPTY_FILTERS';
export const RECEIVE_TRUCKS_WITH_NON_EMPTY_FILTERS = 'TRUCKS_MAP_RECEIVE_TRUCKS_WITH_NON_EMPTY_FILTERS';

export const SET_TRUCK_FROM_URL = 'TRUCKS_MAP_SET_TRUCK_FROM_URL';
