import {useDispatch, useSelector} from 'react-redux';

import {getUnreadMessagesCount, getChatOpened, getChatClientSocketId} from 'widgets/Chat/redux/selectors';
import {toggleChat} from 'widgets/Chat/redux/actions';

const useChatToggle = () => {
    const chatClientSocketId = useSelector(getChatClientSocketId);
    const unreadMessagesCount = useSelector(getUnreadMessagesCount);
    const isChatOpened = useSelector(getChatOpened);
    const dispatch = useDispatch();

    return {
        toggleChat: () => dispatch(toggleChat({opened: !isChatOpened})),

        unreadMessagesCount,
        chatClientSocketId,
        isChatOpened,
    };
};

export default useChatToggle;
