import React from 'react';

import Button from 'components/ui/Buttons/Button';

interface OwnProps {
    title: 'Merge' | 'Select';
    onSelect();
}

const SelectDuplicateButton = ({title, onSelect}: OwnProps): JSX.Element | null => {
    return (
        <Button onClick={onSelect} buttonSize="small" colorTheme="green">
            {title}
        </Button>
    );
};

export default SelectDuplicateButton;
