import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button';

const ArchivingWarnWrapper: React.FC<{title?: string; children; onClose()}> = (props) => {
    const {title = '', children: errorContent, onClose} = props;
    return (
        <Modal show={true} onHide={onClose} className="info-modal" backdrop="static">
            <Modal.Header>
                <Modal.Title>Move to archive {title && <strong>{title}</strong>}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row align-items-center">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-error" />
                    </div>
                    <div className="col-11">
                        <h5 style={{color: '#ff5253'}}>
                            <strong>Attention!</strong>
                        </h5>
                        <div>{errorContent}</div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onClose} className="mr10" colorTheme="grey" buttonSize="small" defaultFocus={true}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ArchivingWarnWrapper;
