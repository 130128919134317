import React from 'react';
import {Slide, ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import styles from './styles.module.scss';

const ToastProvider: React.FC = () => {
    return (
        <ToastContainer
            bodyClassName={styles.body}
            hideProgressBar={false}
            position="top-right"
            closeOnClick={false}
            transition={Slide}
            autoClose={20000}
            draggable={false}
        />
    );
};

export default ToastProvider;
