import {useDispatch} from 'react-redux';
import {reset} from 'redux-form';

type CallbackFunction = (...args: any[]) => void;

const useResetReduxForm = (formName: string) => {
    const dispatch = useDispatch();

    const handleReset = (callback?: CallbackFunction) => {
        dispatch(reset(formName));

        if (callback) {
            callback();
        }
    };

    return handleReset;
};

export default useResetReduxForm;
