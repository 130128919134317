import restApi, {fetchFiles} from 'services/restapi';

import {ENTITY_NAME_FACTORING_COMPANIES} from 'utils/data/entityNames';

export const createFactoringCompanyRequest = ({requestBody}) => {
    return restApi.post('/factoring-company', requestBody);
};

export const updateFactoringCompanyRequest = ({companyID, requestBody}) => {
    return restApi.put(`/factoring-company/${companyID}`, requestBody);
};

export const getFactoringCompanyRequest = ({factoringCompanyID}) => {
    return restApi.get(`/factoring-company/${factoringCompanyID}`);
};

export const getFactoringCompaniesRequest = ({requestBody}) => {
    return restApi.post('/factoring-company/list', requestBody);
};

export const getFactoringCompaniesCommonListRequest = ({requestBody}) => {
    return restApi.post('/factoring-company/common-list', requestBody);
};

export const archiveFactoringCompanyRequest = ({companyID}) => {
    return restApi.put(`/factoring-company/${companyID}/archive`);
};

export const restoreFactoringCompanyRequest = ({companyID}) => {
    return restApi.put(`/factoring-company/${companyID}/restore`);
};

export const addNoteFactoringCompanyRequest = ({companyID, requestBody}) => {
    return restApi.post(`/factoring-company/${companyID}/note`, requestBody);
};

export const deleteNoteFactoringCompanyRequest = ({companyID, noteID}) => {
    return restApi.delete(`/factoring-company/${companyID}/note/${noteID}`);
};

export const getFactoringCompanyFilesRequest = ({companyID}) => {
    return fetchFiles({id: companyID, entity: ENTITY_NAME_FACTORING_COMPANIES});
};
