import isEmpty from 'lodash/isEmpty';

import {TripMonitorState} from 'pages/TravelOrders/redux/reducers/list';

import {LocationEvent} from 'core/entities/LocationEvents/types';
import TravelOrder from 'core/entities/TravelOrder/types';

export const handleReducerByReceivedEvent = (params: {
    travelOrderNumber: number;
    state: TripMonitorState;
    etaInfo: TravelOrder['etaInfo'];
    event: LocationEvent;
}) => {
    const {event, travelOrderNumber, etaInfo, state} = params;

    if (!travelOrderNumber || isEmpty(state.items) || isEmpty(event)) {
        return state;
    }

    const updatedItems = state.items.map((item) =>
        item.number === travelOrderNumber ? {...item, etaInfo, locationEvents: [event, ...item.locationEvents]} : item,
    );

    return {...state, items: updatedItems};
};
