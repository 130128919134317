import {combineReducers} from 'redux';

import current from './loadReducer';
import modal from './modalReducer';
import list from './listReducer';
import attach from './attach';

export default combineReducers({
    current,
    attach,
    modal,
    list,
});
