import React from 'react';

import {Quote} from 'core/entities/Quote/types';
import {getTruckTypes, getTruckEquipment} from 'core/entities/Quote/modules/quoteTruck';

import QuoteTruckCertificates from 'pages/LoadBoard/components/common/QuoteTruckCertificates';
import QuoteTruckCitizenship from 'pages/LoadBoard/components/common/QuoteTruckCitizenship';
import QuoteTruckSigns from 'pages/LoadBoard/components/common/QuoteTruckSigns';
import QuoteTruckTeam from 'pages/LoadBoard/components/common/QuoteTruckTeam';
import QuoteTruckCrossBorder from 'pages/LoadBoard/components/common/QuoteTruckCrossBorder';

import TruckIcon from './components/TruckIcon';
import TruckType from './components/TruckType';
import TruckEquipment from './components/TruckEquipment';
import TruckDims from './components/TruckDims';
import TruckPayload from './components/TruckPayload';

type OwnProps = {
    quote: Quote;
};

const TruckInfo: React.FC<OwnProps> = (props) => {
    const {quote} = props;
    const truckTypes = getTruckTypes(quote);
    const truckEquipment = getTruckEquipment(quote);
    return (
        <div className="row">
            <div className="col-1">
                <TruckIcon />
            </div>
            <div className="col-5">
                <TruckType type={truckTypes} />
                <TruckEquipment equipment={truckEquipment} />
                <div className="d-flex list-with-gap">
                    <TruckDims quote={quote} />
                    <TruckPayload quote={quote} />
                </div>
            </div>
            <div className="col-6">
                <div className="d-flex">
                    <QuoteTruckCertificates quote={quote} />
                    <QuoteTruckCitizenship quote={quote} />
                    <QuoteTruckSigns quote={quote} />
                    <QuoteTruckTeam quote={quote} />
                    <QuoteTruckCrossBorder quote={quote} />
                </div>
            </div>
        </div>
    );
};

export default TruckInfo;
