import {DriverReceiverWithTruck} from 'core/entities/Quote/types';
import {
    isDriverDeclinedQuote,
    isDriverProvidedQuote,
    isDriverReadQuote,
    isAllowedSendQuoteToDriver,
    isNotAllowedSendQuoteToDriver,
} from 'core/entities/Quote/modules/quoteDriverReceiver';
import {FilterOptions, driverFilterOptions} from './index';

export const getFilteredItems = (params: {
    items: DriverReceiverWithTruck[];
    options: FilterOptions[];
}): DriverReceiverWithTruck[] => {
    const {items, options} = params;

    return items.filter((item) =>
        options.some((option) => {
            if (option === driverFilterOptions.allowedSendToDriver) {
                return isAllowedSendQuoteToDriver(item);
            }

            if (option === driverFilterOptions.notAllowedSendToDriver) {
                return isNotAllowedSendQuoteToDriver(item);
            }

            if (option === driverFilterOptions.driverReadQuote) {
                return isDriverReadQuote(item);
            }

            if (option === driverFilterOptions.driverDeclinedQuote) {
                return isDriverDeclinedQuote(item);
            }

            if (option === driverFilterOptions.driverProvidedQuote) {
                return isDriverProvidedQuote(item);
            }

            return false;
        }),
    );
};

export const getCurrentOptions = (params: {
    options: FilterOptions[];
    currentOption: FilterOptions;
}): FilterOptions[] => {
    const {options, currentOption} = params;

    const isChecked = options.find((status) => status === currentOption);
    let currentOptions: FilterOptions[] = [];

    if (isChecked) {
        currentOptions = options.filter((status) => status !== currentOption);
    } else {
        currentOptions = [...options, currentOption];
    }

    return currentOptions;
};
