import pick from 'lodash/pick';
import CurrencyMap, {CurrencyValue, CurrencyData} from 'utils/data/currency';

import {PayRecord} from 'core/entities/Settlement/types';

export const getPayRecordCurrencies = (payRecord: PayRecord): Record<CurrencyValue, CurrencyData> => {
    const {total_payments: currentPayRecordCurrencies} = payRecord;
    const currenciesCodes = currentPayRecordCurrencies.map((c) => c.currency);
    return pick(CurrencyMap, currenciesCodes);
};
