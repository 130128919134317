import Truck from 'core/entities/Truck/types';

import {ListState} from 'pages/FullAlliance/redux/reducers/list';

export const handleReducerByPartnerTruckUpdated = (params: {state: ListState; truck: Truck}) => {
    const {state, truck} = params;

    const items = state.items.map((item) => (item.id === truck.id ? truck : item));

    return {
        ...state,
        items,
    };
};
