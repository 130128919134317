import React from 'react';

import {getPickUpDeliveryInfo} from 'deprecated/core/entities/Load';

import {createDate} from 'utils/dateTime';

import {
    getSettlementLoad,
    getSettlementMiles,
    getSettlementTotalDriverPayment,
    getSettlementTravelOrder,
    getSettlementTravelOrderLastDeliveryCheckoutTime,
    isSettlementTravelOrderDryRun,
    getSettlementTravelOrderDryRunConfirmDate,
    getSettlementCurrency,
} from 'core/entities/Settlement';

import CurrencyAmount from 'components/ui/CurrencyAmount';

import {transformDistance} from 'utils/distance';

const SettlementRow = ({settlement}) => {
    const travelOrder = getSettlementTravelOrder(settlement);
    const load = getSettlementLoad(settlement);
    const isSettlementWithDryRun = isSettlementTravelOrderDryRun(settlement);
    const {firstPickUpAddress, lastDeliveryAddress} = getPickUpDeliveryInfo(load);
    const miles = getSettlementMiles(settlement);
    const totalDriverPayment = getSettlementTotalDriverPayment(settlement);
    const currency = getSettlementCurrency(settlement);
    const lastDeliveryCheckoutTime = getSettlementTravelOrderLastDeliveryCheckoutTime(settlement);
    const convertedDryRunConfirmationDate = isSettlementWithDryRun
        ? createDate(getSettlementTravelOrderDryRunConfirmDate(settlement)).fullDate
        : null;
    const convertedLastDeliveryCheckoutTime = lastDeliveryCheckoutTime
        ? createDate(lastDeliveryCheckoutTime).fullDate
        : null;

    const convertedEmptySettlementMiles = transformDistance(miles?.empty);
    const convertedLoadedSettlementMiles = transformDistance(miles?.loaded);

    return (
        <tr>
            <td>
                <div>Load #{load?.load_id}</div>
                <div>T.O #{travelOrder?.id}</div>
            </td>
            <td>
                <div>{firstPickUpAddress}</div>
                <div>{lastDeliveryAddress}</div>
            </td>
            <td>
                {isSettlementWithDryRun ? (
                    <div>{convertedEmptySettlementMiles.fromMiles}</div>
                ) : (
                    <>
                        <div>{convertedEmptySettlementMiles.fromMiles}</div>
                        <div>
                            <strong>{convertedLoadedSettlementMiles.fromMiles}</strong>
                        </div>
                    </>
                )}
            </td>
            <td>
                {isSettlementWithDryRun ? (
                    <div>dry run: {convertedDryRunConfirmationDate}</div>
                ) : (
                    <div>{convertedLastDeliveryCheckoutTime}</div>
                )}
            </td>
            <td>
                <div className="payment">
                    <CurrencyAmount amount={totalDriverPayment} currency={currency} withStrong={false} />
                </div>
            </td>
        </tr>
    );
};

export default React.memo(SettlementRow);
