import React from 'react';

import {Quote} from 'core/entities/Quote/types';
import {getDestantionStop, getDestinationAddress} from 'core/entities/Quote/modules/quoteStop';

import {getCityLineFromObject} from 'utils/getCityLine';

import {getStopDate} from 'pages/LoadBoard/utils';

import {getTimeZoneAbbreviation} from 'utils/dateTime';

import Title from '../common/Title';

type OwnProps = {
    quote: Quote;
};
const Destination: React.FC<OwnProps> = (props) => {
    const {quote} = props;
    const address = getDestinationAddress(quote);
    const destinationCityLine = getCityLineFromObject(address);
    const destinationStop = getDestantionStop(quote);
    const destinationDate = getStopDate(destinationStop);
    const timeZoneAbbreviation = destinationDate ? getTimeZoneAbbreviation(destinationStop.timeZone) : undefined;
    return (
        <div className="mb-2">
            <Title>Destination</Title>
            <strong>{destinationCityLine}</strong>
            <p>
                {destinationDate} {timeZoneAbbreviation}
            </p>
        </div>
    );
};
export default Destination;
