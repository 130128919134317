import React, {useEffect, useState} from 'react';
import {WrappedFieldProps} from 'redux-form';
import classNames from 'classnames';

import styles from './styles.module.scss';

type CheckSwitchProps = {
    onSwitch?: (value: boolean) => void;
    disabled?: boolean;
    label: string;
    className?: string;
};

const CheckSwitch: React.FC<WrappedFieldProps & CheckSwitchProps> = (props) => {
    const {
        label,
        input,
        disabled,
        meta: {dirty},
        className,
        onSwitch,
    } = props;

    const [value, setValue] = useState<boolean>(input.value);

    const switchClassNames = classNames(styles.switch, value && styles.switch__on, disabled && styles.switch__disabled);
    const labelClassNames = classNames(styles.label, disabled && styles.label__disabled);

    useEffect(() => {
        if (disabled) {
            return;
        }
        input.onChange(value);
    }, [value]);

    useEffect(() => {
        return setValue(input.value);
    }, [dirty]);

    const onClick = () => {
        setValue((prev) => {
            if (onSwitch) {
                onSwitch(!prev);
            }

            return !prev;
        });
    };

    return (
        <div className={classNames(styles.wrap, className)}>
            <div className={switchClassNames} onClick={onClick} />
            <div className={labelClassNames}>{label}</div>
        </div>
    );
};

export default CheckSwitch;
