import React from 'react';

import {checkIsCarrierGlobal} from 'core/entities/Carrier/modules';
import Carrier from 'core/entities/Carrier/types';

import styles from './styles.module.scss';

type OwnProps = {
    carrier: Carrier;
};

const CarrierItemGlobalBadge: React.FC<OwnProps> = (props) => {
    const {carrier} = props;

    const isCarrierGlobal = checkIsCarrierGlobal(carrier);

    if (!isCarrierGlobal) {
        return null;
    }

    return <div className={styles.badge}>d</div>;
};

export default CarrierItemGlobalBadge;
