import React from 'react';

import Driver from 'core/entities/Driver/types';
import Truck from 'core/entities/Truck/types';
import {getDriverName} from 'core/entities/Driver/modules/driverName';

import ArchivingWarnWrapper from 'components/ui/modals/ArchivingWarnWrapper';
import type {ArchivingWarning as ArchivingWarningType} from 'pages/Drivers/types';

import GeneralDriverWarning from './GeneralDriverWarning';
import FirstDriverWarning from './FirstDriverWarning';

type OwnProps = {
    data: {warningType: ArchivingWarningType; driver: Driver; isDriverMain: boolean; truck: Truck};
    handlers: {onCancel(); onConfirm()};
    onClose();
};

const ArchivingWarn = (props: OwnProps): JSX.Element => {
    const {
        data: {warningType, driver, isDriverMain, truck},
        handlers: {onCancel, onConfirm},
        onClose,
    } = props;
    const driverName = getDriverName(driver);
    const isGeneralDriverArchivingWarning = warningType === 'generalDriverArchiving';
    const isFirstDriverArchivingWarning = warningType === 'firstDriverArchiving';
    const header = (
        <p className="text-uppercase">
            <strong>DO YOU WANT TO ARCHIVE DRIVER {driverName}?</strong>
        </p>
    );
    return (
        <ArchivingWarnWrapper title={driverName} onClose={onClose} onCancel={onCancel} onConfirm={onConfirm}>
            {isGeneralDriverArchivingWarning && <GeneralDriverWarning driver={driver}>{header}</GeneralDriverWarning>}
            {isFirstDriverArchivingWarning && (
                <FirstDriverWarning driver={driver} isDriverMain={isDriverMain} truck={truck}>
                    {header}
                </FirstDriverWarning>
            )}
        </ArchivingWarnWrapper>
    );
};

export default ArchivingWarn;
