import {createSelector} from 'reselect';
import head from 'lodash/head';
import uniq from 'lodash/uniq';

import {getSettlementStatusName, isSettlementStatusPosted, isSettlementCarrier} from 'core/entities/Settlement';

import {isSearchByMainParams} from 'pages/Settlements/utils';

const getBatchFromProps = (state, props) => props.batch;

const getBatchFilteredSettlements = (batch, allSettlements) => {
    return batch.settlements
        .map((s) => s.id)
        .reduce((result, settlementID) => {
            const batchSettlementFromMap = allSettlements.byId[settlementID];
            if (batchSettlementFromMap && isSettlementStatusPosted(batchSettlementFromMap)) {
                result.push(batchSettlementFromMap);
            }
            return result;
        }, []);
};

const getFirstObjectFromMap = (map) => {
    if (!map || !map.allIds || !map.byId) {
        return undefined;
    }

    const {allIds} = map;
    const firstID = head(allIds);
    return map.byId[firstID];
};

export const getSettlementsType = (state) => state?.settlements?.settings?.settlementsType;
export const isListPage = (state) => state?.settlements?.settings?.activePage === undefined;

export const getCurrentSettlement = (state) => {
    if (!state?.settlements?.data) {
        return undefined;
    }
    const {allIds} = state?.settlements?.data?.settlements;
    const firstSettlementID = head(allIds);
    return state?.settlements?.data?.settlements?.byId[firstSettlementID];
};
export const getSettlements = (state) => state?.settlements?.data?.settlements;
export const getBatches = (state) => state?.settlements?.data?.batches;
export const getPayPeriods = (state) => state?.settlements?.data?.payPeriods;
export const getAllPayPeriods = (state) => state?.settlements?.data?.allPayPeriods;
export const getPayPeriodsByStatus = (state) => state?.settlements?.data?.payPeriods;
export const getPayRecords = (state) => state?.settlements?.data?.payRecords;
export const getPayPeriodPayRecords = createSelector(
    [getPayRecords, (state, payPeriodID) => payPeriodID],
    (payRecords, payPeriodID) => {
        const {byId} = payRecords;
        return Object.values(byId).filter((payRecord) => payRecord.payPeriodID === payPeriodID);
    },
);
export const getPayRecordSettlements = createSelector(
    [getSettlements, (state, payRecordID) => payRecordID],
    (settlements, payRecordID) => {
        const {byId} = settlements;
        return Object.values(byId).filter((settlement) => {
            return settlement?.pay_record?.id === payRecordID && isSettlementStatusPosted(settlement);
        });
    },
);
export const getGroupStatus = (state) => state?.settlements?.list?.groupStatus;
export const getExpandedRowID = (state) => state?.settlements?.list?.expandedRowID;
export const getSearchParams = (state) => state?.settlements?.list?.searchParams;
export const getViewAllSearchedItems = (state) => state?.settlements?.list?.viewAllSearchedItems;
export const getInvoiceFromSearchParams = (state) => state?.settlements?.list?.searchParams.invoice;
export const getSettlementFromSearchParams = (state) => state?.settlements?.list?.searchParams.settlement;
export const getPagination = (state) => state?.settlements?.list?.pagination;
export const getCommand = (state) => state?.settlements?.data?.command;
export const getSettlementInvoice = (state) => state?.settlements?.current?.invoice;
export const getTravelOrderFiles = (state) => state?.settlements?.current?.travelOrderFiles;
export const getTravelOrderNotes = (state) => state?.settlements?.current?.travelOrderNotes;
export const getLoadFiles = (state) => state?.settlements?.current?.loadFiles;
export const getLoadNotes = (state) => state?.settlements?.current?.loadNotes;
export const getCurrentCarrier = (state) => state?.settlements?.current?.currentCarrier;

export const getFilteredSettlements = createSelector([getSettlements, getGroupStatus], (settlements, groupStatus) => {
    return uniq(settlements?.allIds)
        .map((settlementID) => settlements.byId[settlementID] || {})
        .filter((settlement) => getSettlementStatusName(settlement) === groupStatus);
});

export const getBatchesList = createSelector([getBatches], (batches) =>
    batches ? batches.allIds.map((batchID) => batches.byId[batchID]) : [],
);

export const getBatchSettlements = createSelector(
    [getSettlements, (state, batchID) => batchID],
    (settlements, batchID) => {
        const {byId} = settlements;
        return Object.values(byId).filter((settlement) => {
            return settlement?.batch?.id === batchID && isSettlementStatusPosted(settlement);
        });
    },
);

export const makeGetBatchSettlements = () => {
    return createSelector([getBatchFromProps, getSettlements], (batch, allSettlements) =>
        getBatchFilteredSettlements(batch, allSettlements),
    );
};

export const getPayPeriodsList = createSelector([getPayPeriods], (payPeriods) =>
    payPeriods ? payPeriods.allIds.map((periodID) => payPeriods.byId[periodID]) : [],
);

export const getPayPeriodById = (state, payPeriodID) => {
    const payPeriods = getPayPeriods(state);
    return payPeriods.byId[payPeriodID];
};

export const getCurrentSettlementBatch = createSelector(
    [getCurrentSettlement, getBatches],
    (currentSettlement, batches) => {
        if (isSettlementCarrier(currentSettlement)) {
            return getFirstObjectFromMap(batches);
        }
        return undefined;
    },
);

export const getCurrentSettlementPayPeriod = createSelector(
    [getCurrentSettlement, getPayPeriods],
    (currentSettlement, payPeriods) => {
        if (!isSettlementCarrier(currentSettlement)) {
            return getFirstObjectFromMap(payPeriods);
        }
        return undefined;
    },
);

export const isSearchingByMainParams = (state) => {
    const searchParams = getSearchParams(state);
    return isSearchByMainParams(searchParams);
};
