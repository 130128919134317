import React from 'react';

import {LoadBoardNotification} from 'widgets/Notifications/types';

const NotificationMessage: React.FC<{notification: LoadBoardNotification}> = (props) => {
    const {notification} = props;
    const {offerNumber, quoteNumber, origin, destination} = notification;
    const offerNumberTitle = `New offer #${offerNumber}-${quoteNumber}`;
    const quoteNumberTitle = `Quote #${quoteNumber}`;
    const originCityLine = `${origin.city}, ${origin.state}`;
    const destinationCityLine = `${destination.city}, ${destination.state}`;
    return (
        <p>
            {offerNumberTitle} for <strong>{quoteNumberTitle}</strong> {originCityLine} - {destinationCityLine}
        </p>
    );
};

export default NotificationMessage;
