import React from 'react';
import {Modal} from 'react-bootstrap';

import Truck from 'core/entities/Truck/types';

import {QuoteInitialValues} from 'pages/LoadBoard/types';

import Form from './components/Form';

import SpinIcon from './components/SpinIcon';
import useCheckEmptyReceivers from './components/Form/hooks/useCheckEmptyReceivers';

type OwnProps = {
    data: {
        fetchAllTNBTrucks: () => Promise<Truck[]>;
        quoteInitialData: QuoteInitialValues;
    };
    handlers: {
        onCreate(data: QuoteInitialValues): void;
    };
    onClose(): void;
};

export const FORM_NAME = 'crete-quote-form';

const CreateQuoteModal: React.FC<OwnProps> = (props) => {
    const {
        handlers,
        data: {fetchAllTNBTrucks, quoteInitialData},
        onClose,
    } = props;

    const isEmptyReceivers = useCheckEmptyReceivers();

    return (
        <Modal
            show={true}
            dialogClassName="standard-form-modal"
            onHide={onClose}
            className="info-modal"
            backdrop="static"
        >
            <Modal.Header>
                <div className="modal-header__top">
                    <h4 className="modal-title">
                        {isEmptyReceivers ? <SpinIcon /> : null} Create and Send Quote
                        {isEmptyReceivers ? (
                            <div className="modal-subtitle">
                                <i className="fa fa-info-circle" /> The list of recipients is loading. Please wait for
                                it to fully upload to Send Quote
                            </div>
                        ) : (
                            <div className="modal-subtitle">
                                <i className="fa fa-info-circle" /> The results of the search will be shown after the{' '}
                                <strong>First Pickup Location</strong> and <strong>Radius Search</strong> data will be
                                filled in.
                            </div>
                        )}
                    </h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form
                    initialValues={quoteInitialData}
                    onFetchTrucks={fetchAllTNBTrucks}
                    onSubmit={handlers.onCreate}
                    onClose={onClose}
                    form={FORM_NAME}
                />
            </Modal.Body>
        </Modal>
    );
};

export default CreateQuoteModal;
