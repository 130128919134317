import {useSelector} from 'react-redux';

import {getCurrentRegion} from 'store/reducers/appSettings/selectors';

import {isEuropeRegion} from 'utils/data/region';

import {europeTruckOptions, generalTruckOptions} from '../constants';

export default () => {
    const currentRegion = useSelector(getCurrentRegion);
    const isEurope = isEuropeRegion(currentRegion);

    return isEurope ? europeTruckOptions : generalTruckOptions;
};
