import toNumber from 'lodash/toNumber';
import sum from 'lodash/sum';

import * as stopModules from 'core/entities/TravelOrder/modules/travelOrderStops';
import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';
import TravelOrder from 'core/entities/TravelOrder/types';

import {transformDistance} from 'utils/distance';

export const getTravelOrderEmptyMiles = (travelOrder?: TravelOrder | null): number => travelOrder?.emptyMiles || 0;

// SUM OF MILES BETWEEN STOPS
export const getTravelOrderStopsMiles = (travelOrder?: TravelOrder | null): number => {
    if (!travelOrder) {
        return 0;
    }

    const travelOrderStops = stopModules.getTravelOrderStops(travelOrder);
    const lastDeliveryStopID = stopModules.getTravelOrderLastDeliveryStopID(travelOrder);
    const stopsWithOutLastDelivery = travelOrderStops.filter((stop) => stop.id !== lastDeliveryStopID);
    const arrayOfMiles = stopsWithOutLastDelivery.map((stop) => toNumber(stop?.distanceToNextStop || 0));
    const sumOfMiles = sum(arrayOfMiles);

    return sumOfMiles;
};

// SUM OF MILES BETWEEN STOPS AND EMPTY MILES
export const getTravelOrderTotalMiles = (travelOrder?: TravelOrder | null): number => {
    if (!travelOrder) {
        return 0;
    }

    const emptyMiles = getTravelOrderEmptyMiles(travelOrder);
    const sumOfMilesBetweenStops = getTravelOrderStopsMiles(travelOrder);

    return sum([emptyMiles, sumOfMilesBetweenStops]);
};

export const getTravelOrderDistances = (travelOrder?: TravelOrder | null) => ({
    totalMiles: travelOrder ? transformDistance(getTravelOrderTotalMiles(travelOrder)) : null,
    stopsMiles: travelOrder ? transformDistance(getTravelOrderStopsMiles(travelOrder)) : null,
    emptyMiles: travelOrder ? transformDistance(getTravelOrderEmptyMiles(travelOrder)) : null,
});

export const getTravelOrderStopDistanceToNextStop = (stop?: TravelOrderStop): number =>
    toNumber(stop?.distanceToNextStop ?? 0);

export const getTravelOrderStopDistanceToNextStopByID = (params: StopInfoParams): number =>
    getTravelOrderStopDistanceToNextStop(stopModules.getTravelOrderStopByID(params));

export const getTravelOrderStopDistance = (stop?: TravelOrderStop) => ({
    toNextStop: stop ? transformDistance(getTravelOrderStopDistanceToNextStop(stop)) : null,
});

export const getTravelOrderStopDistanceByID = (params: StopInfoParams) =>
    getTravelOrderStopDistance(stopModules.getTravelOrderStopByID(params));
