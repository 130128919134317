import Carrier from 'core/entities/Carrier/types';
import * as appActions from 'store/actions';

export const sendEmailToCarrier = (carrier: Carrier | null | undefined) => (dispatch) => {
    const emailParams = {emailTo: carrier?.email, nameTo: carrier?.company_name};

    if (!emailParams.emailTo || !emailParams.nameTo) {
        return;
    }

    dispatch(appActions.toggleSendEmail(emailParams));
};
