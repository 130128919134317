import {Address} from 'core/entities/Quote/types';
import {UNKNOWN_CITY, UNKNOWN_STATE} from 'utils/constants/cityLine';

export const getQuoteDuplicateCityLine = (address: Address): string => {
    const {city, state} = address;
    const formattedCity = city || UNKNOWN_CITY;
    const formattedState = state || UNKNOWN_STATE;

    return `${formattedCity}, ${formattedState}`;
};
