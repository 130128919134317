import React, {useMemo} from 'react';

import Button from 'components/ui/Buttons/Button';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import {QuoteReceiver} from 'pages/LoadBoard/types';
import SpinIcon from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/SpinIcon';

import getFormFieldNames from '../../utils/getFormFieldNames';
import useCheckEmptyReceivers from '../../hooks/useCheckEmptyReceivers';

import styles from './styles.module.scss';

const SubmitButton: React.FC<{formName: string}> = (props) => {
    const {formName} = props;
    const {getReceiversListFiledName} = getFormFieldNames();
    const receivers = (useGetReduxFormValue(formName, getReceiversListFiledName()) as QuoteReceiver[]) || [];
    const allowedReceivesCount = useMemo(() => {
        const allowedReceivers = receivers.filter((r) => r.isReceiveQuoteAllowed);
        return allowedReceivers.length;
    }, [receivers]);

    const isEmptyReceivers = useCheckEmptyReceivers();

    return (
        <div className={styles.items}>
            {isEmptyReceivers ? <SpinIcon /> : null}
            <Button
                disabled={!allowedReceivesCount}
                type="submit"
                colorTheme="green"
                buttonSize="small"
                defaultFocus={true}
            >
                SEND QUOTE
            </Button>
        </div>
    );
};

export default SubmitButton;
