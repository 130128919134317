import React from 'react';
import {Modal} from 'react-bootstrap';

import {getSettlementBatchCarrierName} from 'core/entities/Settlement';

import CloseBatch from './CloseBatch';
import SendBatchEmail from './SendBatchEmail';

const TitleForCloseBatch = ({batch}) => {
    const batchCarrierName = getSettlementBatchCarrierName(batch);
    return (
        <span>
            Close <strong>Batch #{batch.number}</strong> - {batchCarrierName}
        </span>
    );
};

const TitleForEditBatch = ({batch}) => {
    const batchCarrierName = getSettlementBatchCarrierName(batch);
    return (
        <span>
            <strong>Batch #{batch.number}</strong> - {batchCarrierName} (closed)
        </span>
    );
};

const EditSettlementsBatchModal = (props) => {
    const {
        onClose,
        data: {batch, isClosing},
        handlers: {onReopen, onSubmit, onDelete},
    } = props;
    return (
        <Modal
            // this prop set for avoid bug with incorrect focus in case when some component with OverlayTrigger using
            // inside modal, see BatchNote component
            enforceFocus={false}
            show={true}
            onHide={onClose}
            dialogClassName="standard-form-modal"
            size="lg"
            backdrop="static"
        >
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>
                        {isClosing ? <TitleForCloseBatch batch={batch} /> : <TitleForEditBatch batch={batch} />}
                    </Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                {isClosing ? (
                    <CloseBatch batch={batch} onSubmit={onSubmit} onClose={onClose} />
                ) : (
                    <SendBatchEmail
                        batch={batch}
                        onSubmit={onSubmit}
                        isBatchCanBeReopened={true}
                        onClose={onClose}
                        onReopen={onReopen}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
};

export default React.memo(EditSettlementsBatchModal);
