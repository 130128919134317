import toNumber from 'lodash/toNumber';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';

import {BatchResponsibleUser} from 'pages/Settlements/components/common/modals/SetupSettlementsBatchModal/types';

export const getUsersPayments = (users: BatchResponsibleUser[]): {amount: number; currency: string}[] => {
    const allPayments = users.flatMap((u) => u.total_charges);
    const paymentsByCurrency = groupBy(allPayments, 'currency');

    return Object.entries(paymentsByCurrency).map(([currency, values]) => {
        return {
            currency,
            amount: sumBy(values, (item) => toNumber(item.amount)),
        };
    });
};
