import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button/index';

const ErrorChangeStatusModal = ({onClose, data}) => {
    const defaultMessage = 'The action cannot be completed because it was done by another user.';
    const message = data?.message || defaultMessage;
    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Error</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-error" />
                    </div>
                    <div className="col-11">
                        <p className="mt-2">{message}</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div />
                <Button onClick={onClose} buttonSize="small" colorTheme="red">
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorChangeStatusModal;
