import React from 'react';

import Truck from 'core/entities/Truck/types';

import {TruckLink} from 'components/ui/Links';

const DriverTruckOnRoute: React.FC<{truck: Truck}> = (props) => {
    const {truck} = props;
    return (
        <div>
            <div>
                The driver is attached to{' '}
                <strong>
                    <TruckLink truck={truck} content={`truck # ${truck.number}`} className="second-link" />.
                </strong>{' '}
            </div>
            <div>
                This truck is <strong>on route</strong> now. Removing drivers from such trucks is not &nbsp; possible.
                Please, wait for the truck to complete the travel order.
            </div>
        </div>
    );
};

export default DriverTruckOnRoute;
