import React, {useMemo} from 'react';
import {WrappedFieldProps} from 'redux-form';

import Select, {OwnProps} from 'components/ui/Form/Select';

import {createTimeZoneOptionsWithLongLabel} from './utils';

const SelectTimeZone: React.FC<Omit<OwnProps, 'data'> & WrappedFieldProps> = (props) => {
    const timeZones = useMemo(createTimeZoneOptionsWithLongLabel, []);
    return <Select {...props} data={timeZones} />;
};

export default SelectTimeZone;
