import React from 'react';

import MultiValueContainer from 'components/ui/Form/EmailsSelect/MultiValueContainer';
import normalizeEmails from 'components/ui/Form/EmailsSelect/normalizeEmails';
import Select from 'components/ui/Form/Select';

import * as regexps from 'utils/validation/regexps';

const EmailsSelect = (props) => {
    const {withCustomMultiValueContainer = true, ...rest} = props;

    const emailValidation = (inputValue) => {
        if (inputValue) {
            const normalizedValue = normalizeEmails(inputValue);
            const result = normalizedValue.split(',').map((value) => regexps.EMAIL.test(value));

            return result.every((element) => element === true);
        }

        return false;
    };

    const getNewOptionData = (value) => ({
        value: normalizeEmails(value),
        label: value,
    });

    if (withCustomMultiValueContainer) {
        return (
            <Select
                {...rest}
                isValidNewOption={emailValidation}
                getNewOptionData={getNewOptionData}
                components={{MultiValueContainer}}
                isCreatable
                isMulti
            />
        );
    }

    return (
        <Select {...rest} isValidNewOption={emailValidation} getNewOptionData={getNewOptionData} isCreatable isMulti />
    );
};

export default EmailsSelect;
