import sumBy from 'lodash/sumBy';
import toNumber from 'lodash/toNumber';
import groupBy from 'lodash/groupBy';

import {CurrencyValue} from 'utils/data/currency';

import {checkIsDeduction, checkIsReimbursement} from 'core/entities/Settlement/payCorrections';

import {PayRecord, PayCorrection} from './types';
import {findSettlementByNumber, findSettlementByInvoiceNumber} from './settlement';

type PayRecordCurrencyPayment = {
    amount: number;
    currency: CurrencyValue;
    reimbursements: {
        totalAmount: number;
        items: PayCorrection[];
    };
    deductions: {
        totalAmount: number;
        items: PayCorrection[];
    };
};

export const getPayRecordDeductionsSum = (payRecord: PayRecord): number => {
    const {pay_corrections: payCorrections} = payRecord;
    const deductions = payCorrections.filter(checkIsDeduction);

    return sumBy(deductions, (deduction: PayCorrection): number => {
        return toNumber(deduction?.amount) || 0;
    });
};

export const getPayRecordReimbursementsSum = (payRecord: PayRecord): number => {
    const {pay_corrections: payCorrections} = payRecord;
    const reimbursements = payCorrections.filter(checkIsReimbursement);

    return sumBy(reimbursements, (reimbursement: PayCorrection): number => {
        return toNumber(reimbursement?.amount) || 0;
    });
};

export const getPayRecordCurrencyPayments = (payRecord: PayRecord): PayRecordCurrencyPayment[] => {
    const {total_payments: totalPayments = [], pay_corrections: payCorrections} = payRecord;
    const currencyField: keyof PayCorrection = 'currency';
    const payCorrectionsByCurrency = groupBy(payCorrections, currencyField);
    return totalPayments.map((payment) => {
        const currentPayCorrections =
            (payCorrectionsByCurrency[payment.currency] as PayCorrection[]) || ([] as PayCorrection[]);
        const currentReimbursements = currentPayCorrections.filter(
            (payCorrection) => payCorrection.type === 'reimbursement',
        );
        const currentDeductions = currentPayCorrections.filter((payCorrection) => payCorrection.type === 'deduction');
        const reimbursementsTotalAmount = sumBy(currentReimbursements, (r) => r.amount);
        const deductionsTotalAmount = sumBy(currentDeductions, (d) => d.amount);
        return {
            ...payment,
            reimbursements: {items: currentReimbursements, totalAmount: reimbursementsTotalAmount},
            deductions: {items: currentDeductions, totalAmount: deductionsTotalAmount},
        };
    });
};

export const getPayRecordsWithSettlements = (payRecords: PayRecord[]): PayRecord[] => {
    return payRecords.filter((payRecord) => payRecord && payRecord.settlements_total > 0);
};

export const findPayRecordBySettlementNumber = (
    payRecords: PayRecord[],
    settlementNumber: string,
): PayRecord | undefined => {
    return payRecords.find((payRecord) => {
        const settlement = findSettlementByNumber(payRecord.settlements, settlementNumber);
        return Boolean(settlement);
    });
};

export const findPayRecordBySettlementInvoiceNumber = (
    payRecords: PayRecord[],
    invoiceNumber: string,
): PayRecord | undefined => {
    return payRecords.find((payRecord) => {
        const settlement = findSettlementByInvoiceNumber(payRecord.settlements, invoiceNumber);
        return Boolean(settlement);
    });
};
