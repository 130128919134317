import React from 'react';
import PayloadInput from './components/PayloadInput';
import Unit from './components/Unit';

const Payload: React.FC = () => {
    return (
        <>
            <h5 className="mb-2">Truck Details</h5>
            <Unit />
            <PayloadInput />
        </>
    );
};

export default Payload;
