import React from 'react';
import {Field} from 'redux-form';
import classNames from 'classnames';

import {removeNonDigitAndLettersChars, removeNonLettersAndSpaceChars} from 'utils';
import * as errorMessages from 'utils/validation/errorMessages';
import * as regexps from 'utils/validation/regexps';
import {isValidLength} from 'utils/validation';

import TextInput from 'components/ui/Form/TextInput';

import {LOAD_BOARD_LENGTH, MAX_COUNT_FIELDS, START_LENGTH} from './constants';

import styles from './loadBoardsList.module.scss';

const valueNameValidate = (valueName: string): string | undefined => {
    if (valueName && !regexps.LETTERS_AND_SPACE.test(valueName)) {
        return errorMessages.ONLY_LETTERS;
    }

    if (valueName && !isValidLength(START_LENGTH, LOAD_BOARD_LENGTH, valueName)) {
        return 'Load board name must contain more than 1 symbol, please retype it.';
    }
};

const valueIDValidate = (valueName: string): string | undefined => {
    if (valueName && !regexps.LETTERS_OR_DIGITS.test(valueName)) {
        return errorMessages.LETTERS_AND_DIGITS;
    }

    if (valueName && !isValidLength(START_LENGTH, LOAD_BOARD_LENGTH, valueName)) {
        return 'Load board ID must contain more than 1 symbol, please retype it.';
    }
};

const LoadBoardsList = ({fields, disabled}: any): JSX.Element => {
    const loadBoardsFields = fields.map((field, index) => {
        const realIndex = index + 1;
        const labelName = `Load board #${realIndex} name`;
        const labelID = `Load board #${realIndex} ID`;

        return (
            <div className="row" key={index}>
                <div className="col-3">
                    <Field
                        name={`${field}.name`}
                        type="text"
                        label={labelName}
                        component={TextInput}
                        maxLength={LOAD_BOARD_LENGTH}
                        placeholder="enter name"
                        validate={[valueNameValidate]}
                        disabled={disabled}
                        normalize={removeNonLettersAndSpaceChars}
                    />
                </div>
                <div className="col-3">
                    <Field
                        name={`${field}.id`}
                        type="text"
                        label={labelID}
                        component={TextInput}
                        maxLength={LOAD_BOARD_LENGTH}
                        placeholder="enter ID"
                        validate={[valueIDValidate]}
                        disabled={disabled}
                        normalize={removeNonDigitAndLettersChars}
                    />
                </div>
            </div>
        );
    });

    return (
        <>
            {loadBoardsFields}

            {fields.length < MAX_COUNT_FIELDS && (
                <span
                    onClick={() => {
                        fields.push('');
                    }}
                    className={classNames(styles.add_button, disabled && styles.add_button_disabled)}
                >
                    <i className="fa fa-plus" />
                    <span className="second-link">add load board</span>
                </span>
            )}
        </>
    );
};

export default LoadBoardsList;
