export const TRAVEL_ORDER_ACTIONS = {
    CANCEL_FINISH_CONFIRM: 'cancel-finish-confirm',
    CANCEL_CHECK_OUT: 'cancel-check-out',
    CANCEL_CHECK_IN: 'cancel-check-in',
    CANCEL_DRY_RUN: 'cancel-dry-run',
    RE_DISPATCH: 'redispatch',
    CANCEL_BOL: 'cancel-bol',
    SEND_FILES: 'send-files',
    CANCEL_POD: 'cancel-pod',
    CHECK_OUT: 'check-out',
    SIGN_POD: 'sign-pod',
    CHECK_IN: 'check-in',
    DISPATCH: 'dispatch',
    ADD_BOL: 'add-bol',
    SET_PAY: 'set-pay',
    DRY_RUN: 'dry-run',
    OPEN_RC: 'open_rc',
    FINISH: 'finish',
    CANCEL: 'cancel',
    VIEW: 'view',
    EDIT: 'edit',
} as const;
