import React, {useState, useEffect} from 'react';
import round from 'lodash/round';

import {getNowWithCurrentTimeZone, fromZeroTimeZoneAsObject, getDifferenceBetweenTwoDateTimes} from 'utils/dateTime';

interface TimeDiff {
    minutes: number;
}
const TEN_MINUTES = 10;
const ONE_HOUR = 60;
// eslint-disable-next-line
const formatWithZero = value => (value < 10 && value >= 0 ? `0${value}` : value);

const getTimeDiffElements = (diff: TimeDiff): JSX.Element | null => {
    const isTimeDiffLessThanTenMinutes = diff.minutes < ONE_HOUR && diff.minutes >= 0 && diff.minutes < TEN_MINUTES;
    const isTimeDiffMoreThanTenMinAndLessHour = diff.minutes < ONE_HOUR && diff.minutes > TEN_MINUTES;
    const isTimeDiffMoreThanOneHour = diff.minutes > ONE_HOUR;

    if (isTimeDiffLessThanTenMinutes) {
        return null;
    }

    if (isTimeDiffMoreThanTenMinAndLessHour) {
        return <span>{formatWithZero(diff.minutes)}m ago</span>;
    }

    if (isTimeDiffMoreThanOneHour) {
        return (
            <span>
                <strong>over an hour</strong>
            </span>
        );
    }

    return null;
};

interface OwnProps {
    startingDate?: string; // only UTC-0
}

const TimeOvering = (props: OwnProps): JSX.Element | null => {
    const {startingDate} = props;
    const [timeDiff, setTimeDiff] = useState<TimeDiff | undefined>(undefined);

    useEffect(() => {
        if (!startingDate) {
            return;
        }

        const ONE_SEC = 1000;
        const intervalID = window.setInterval(() => {
            const now = getNowWithCurrentTimeZone();
            const timeOfLastLocationUpdate = fromZeroTimeZoneAsObject(startingDate);
            const remainingTime = getDifferenceBetweenTwoDateTimes({
                dateTimeStart: now,
                dateTimeEnd: timeOfLastLocationUpdate,
            });
            const {minutes} = remainingTime;

            setTimeDiff({minutes: round(minutes)});
        }, ONE_SEC);
        return () => window.clearInterval(intervalID);
    }, []);

    if (!startingDate || !timeDiff) {
        return null;
    }
    return getTimeDiffElements(timeDiff);
};

export default TimeOvering;
