import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import config from 'config';

import Driver from 'core/entities/Driver/types';

import * as tsTypes from 'widgets/Chat/types';

import File from 'types/File';

import {DriversChannels} from './index';

interface FilteredChannels {
    channelsWithUnreadMessages: string[];
    channelsWithReadMessages: string[];
}

const sortChannelsByLastMessageInsert = (channelsIds: string[], driverChannelsMap: DriversChannels): string[] => {
    return orderBy(
        channelsIds,
        (channelID: string) => {
            const {lastMessageInsertDate} = driverChannelsMap.byDriverID[channelID] || {};

            if (!lastMessageInsertDate) {
                return 0;
            }

            return new Date(lastMessageInsertDate);
        },
        ['desc'],
    );
};

export const sortChannelsByLastMessageInsertDate = (driverChannels: DriversChannels): DriversChannels => {
    // eslint-disable-next-line no-console
    console.time('Chat messages sorting time');
    const {channelsWithUnreadMessages, channelsWithReadMessages} = driverChannels.allIds.reduce(
        (result, channelID: string): FilteredChannels => {
            const channel: tsTypes.DriverChannel = driverChannels.byDriverID[channelID];
            if (channel.unreadMessagesCount > 0) {
                result.channelsWithUnreadMessages.push(channelID);
            } else {
                result.channelsWithReadMessages.push(channelID);
            }
            return result;
        },
        {channelsWithUnreadMessages: [], channelsWithReadMessages: []} as FilteredChannels,
    );
    const result = {
        byDriverID: driverChannels.byDriverID,
        allIds: [
            ...sortChannelsByLastMessageInsert(channelsWithUnreadMessages, driverChannels),
            ...sortChannelsByLastMessageInsert(channelsWithReadMessages, driverChannels),
        ],
    };
    // eslint-disable-next-line no-console
    console.timeEnd('Chat messages sorting time');
    return result;
};

function checkIsMessageUnread(message: tsTypes.RawMessageFromApi, currentUserId: number): boolean {
    return !message.isReadByEveryDispatcher && !message.readByUserIds.includes(currentUserId);
}

export const mapMessage = (
    message: tsTypes.RawMessageFromApi,
    driversMap,
    dispatchersMap,
    currentUserId: number,
): tsTypes.Message => {
    const driver = message.senderType === 'driver' ? driversMap[message.senderID] : null;
    const dispatcher = message.senderType === 'dispatcher' ? dispatchersMap[message.senderID] : null;
    const isUnread = checkIsMessageUnread(message, currentUserId);

    return {
        id: message.id,
        driver,
        dispatcher,
        attachments: message.attachments.map(
            (attachment: tsTypes.Attachment): tsTypes.NormalizedFile => {
                const extension = last(attachment.originalName.split('.'));
                return {
                    url: `${window.location.origin}/api/chat/${attachment.url}`,
                    thumbUrl: attachment.thumbUrl
                        ? `${window.location.origin}/api/chat/${attachment.thumbUrl}`
                        : undefined,
                    name: attachment.originalName,
                    size: attachment.size,
                    // @ts-ignore
                    extension,
                    extraData: null,
                    isBlob: false,
                };
            },
        ),
        channelID: message.channelID,
        createdAt: message.createdAt,
        text: message.text,
        isNotification: message.isNotification,
        isUnread,
    };
};

export const mapDriversChannels = (
    driversMap,
    driversAvatarsMap,
    dispatchersMap,
    channelsMap,
    currentUserId,
): DriversChannels => {
    const driversIds = Object.keys(driversMap) as string[];
    const drivers = Object.values(driversMap) as Driver[];
    const driversChannelsMap = drivers.reduce((result, driver: Driver): {[key: number]: tsTypes.DriverChannel} => {
        const channelWithDriver: tsTypes.RawDriverChannelFromApi = channelsMap[driver.id];
        const driverAvatar = driversAvatarsMap[driver.id] as File;
        result[driver.id] = {
            id: driver.id,
            channelID: channelWithDriver ? channelWithDriver.id : undefined,
            driver,
            driverAvatar: {
                thumb: driverAvatar && driverAvatar.thumb ? `${config.PUBLIC_URL}/web/${driverAvatar.thumb}` : null,
                fullSize:
                    driverAvatar && driverAvatar.file_url
                        ? `${config.PUBLIC_URL}/web/${driverAvatar.file_url}${driverAvatar.file_name}`
                        : null,
            },
            messages: channelWithDriver
                ? channelWithDriver.messages.map((msg) => mapMessage(msg, driversMap, dispatchersMap, currentUserId))
                : [],
            unreadMessagesCount: channelWithDriver ? channelWithDriver.dispatchersUnreadMessageCount : 0,
            lastMessageInsertDate: channelWithDriver ? channelWithDriver.lastMessageInsertDate : null,
        };
        return result;
    }, {} as {[key: number]: tsTypes.DriverChannel});
    return {
        byDriverID: driversChannelsMap,
        allIds: driversIds,
    };
};
