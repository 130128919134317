import React from 'react';

import {AutoCheckCallErrors} from 'core/entities/TravelOrder/types/AutoCheckCallSettings';

import {CreatedDate} from 'utils/dateTime/types';

import styles from '../../styles.module.scss';

type OwnProps = {
    dateTime: CreatedDate;
    error: AutoCheckCallErrors['dateTime'];
};

const DateAndTime: React.FC<OwnProps> = ({error, dateTime}) => {
    const {date, time} = dateTime;

    if (!error) {
        return (
            <span>
                <strong>{date}</strong> {time}
            </span>
        );
    }

    return (
        <span>
            <span className={styles.errorBgWithBorder}>
                <strong>{date}</strong> {time}
            </span>
            <span className="ml10">Check call time is late.</span>
        </span>
    );
};

export default DateAndTime;
