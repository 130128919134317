import React from 'react';
import {connect} from 'react-redux';
import {change as reduxFormChange, formValueSelector, reduxForm} from 'redux-form';

import Button from 'components/ui/Buttons/Button';

import FilesList from './FilesList';
import * as selectors from './selectors';

const FORM_NAME = 'generate-invoice-pdf-form';

const GenerateInvoicePDFModal = (props) => {
    const {handleSubmit, invoiceFiles, selectedFiles, invoice, onClose, onAddFile, onRemoveFile} = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="custom-scroll">
                <div className="modal-body__files">
                    <div className="control-label">
                        Check to include files to the <strong>Invoice#{invoice.invoice_number}</strong>
                    </div>
                    <div className="files-group">
                        <FilesList
                            key="load-files"
                            headerTitle="Load"
                            files={invoiceFiles.load}
                            selectedFiles={selectedFiles}
                            onAddFile={onAddFile}
                            onRemoveFile={onRemoveFile}
                        />
                        <FilesList
                            key="travel-order-files"
                            headerTitle="Travel Order"
                            files={invoiceFiles.travelOrder}
                            selectedFiles={selectedFiles}
                            onAddFile={onAddFile}
                            onRemoveFile={onRemoveFile}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-body__bottom">
                <Button onClick={onClose}>Cancel</Button>
                <Button colorTheme="blue" type="submit">
                    Generate PDF
                </Button>
            </div>
        </form>
    );
};

const selector = (form, ...other) => formValueSelector(form)(...other);

export default connect(
    (state, ownProps) => {
        const {initialValues, invoiceFiles} = selectors.getInitValues(state, ownProps);
        const selectedFiles = selector(FORM_NAME, state, 'files');
        return {
            initialValues,
            invoiceFiles,
            selectedFiles,
        };
    },
    (dispatch) => ({
        onAddFile: (file) =>
            dispatch((_, getState) => {
                const allFiles = selector(FORM_NAME, getState(), 'files');
                dispatch(reduxFormChange(FORM_NAME, 'files', [...allFiles, file]));
            }),

        onRemoveFile: (file) =>
            dispatch((_, getState) => {
                const allFiles = selector(FORM_NAME, getState(), 'files');
                const filteredFiles = allFiles.filter((f) => f.id !== file.id);
                dispatch(reduxFormChange(FORM_NAME, 'files', filteredFiles));
            }),
    }),
)(reduxForm({form: FORM_NAME})(GenerateInvoicePDFModal));
