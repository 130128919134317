import React, {useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';

import StoredFile, {GroupedFiles, FileNew as StoredFileNew} from 'types/File';

import useTravelOrderActions from 'pages/TravelOrders/hooks/useTravelOrderActions';

import {checkIsTravelOrderOtherFileType} from 'core/entities/TravelOrder/modules/travelOrderAttachments/otherAttachments';
import FilesList from 'components/ui/Files/FileUpload/components/FilesList/FilesList';
import {NormalizedFileData} from 'components/ui/Files/FileUpload/types';
import {normalizeFile} from 'components/ui/Files/FileUpload/utils';

function normalizeFiles(
    files: (StoredFile | StoredFileNew)[] | GroupedFiles,
    handleRemove: (fileID: number) => void,
): NormalizedFileData {
    if (isEmpty(files)) {
        return [];
    }

    if (Array.isArray(files)) {
        return files.filter(Boolean).map((item) => normalizeFile(item));
    }

    return Object.entries(files).reduce((result, [fileTypeKey, fileArray]) => {
        result[fileTypeKey] = fileArray.map((item) => {
            const isOtherFile = checkIsTravelOrderOtherFileType(item.attachType);

            if (isOtherFile) {
                return normalizeFile(item, {isNeedComposeBadge: false, remove: () => handleRemove(item.id)});
            }

            return normalizeFile(item, {isNeedComposeBadge: false});
        });
        return result;
    }, {});
}

interface OwnProps {
    files: (StoredFile | StoredFileNew)[] | GroupedFiles;
    title?: string;
    gridView?: boolean;
    groupByKey?: boolean;
    isReversed?: boolean;
    viewButton?: boolean;
}

const FilesListView = (props: OwnProps): JSX.Element => {
    const {files} = props;

    const {removeTravelOrderOtherFileByID} = useTravelOrderActions();

    const normalizedFiles = useMemo(() => normalizeFiles(files, removeTravelOrderOtherFileByID), [files]);

    return <FilesList {...props} filesData={normalizedFiles} />;
};

export default FilesListView;
