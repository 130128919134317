import React, {ReactNode} from 'react';
import classNames from 'classnames';

import styles from './fileSection.module.scss';

interface OwnProps {
    title: string;
    fileCount: number;
    allSelected: boolean;
    gridView: boolean;
    children: ReactNode;
    onSelectAll(): void;
}

const FileSection = (props: OwnProps): JSX.Element => {
    const {title, fileCount, children, allSelected, gridView, onSelectAll} = props;
    const filesClassName = classNames('files', {list: !gridView});
    return (
        <div className={styles.stop}>
            <div className="header">
                <div className="header_left">
                    <strong>{title}</strong>
                </div>
                <div className="header_right">
                    <span>
                        <strong>{fileCount}</strong> file(s)
                    </span>
                    <div className="checkbox-custom__block">
                        <label>
                            <input
                                onChange={onSelectAll}
                                checked={allSelected}
                                className="checkbox"
                                type="checkbox"
                                name="checkbox-test"
                            />
                            <span className="checkbox-custom__item" />
                        </label>
                    </div>
                </div>
            </div>
            <div className={filesClassName}>{children}</div>
        </div>
    );
};

export default FileSection;
