import {handleError, hideLoader, showLoader} from 'store/actions';

import {TripMonitorColorSettings} from 'core/entities/Dispatcher/types/Preferences';
import {updateDispatcherPreferences} from 'core/gateways/DispatcherApiGateway/requests';

import {fetchFiles} from 'services/restapi';

import actionCreators from './actionCreators';
import {getCurrentUserID, getCurrentUserPreferences} from './selectors';

export const setUserTripMonitorColorSettings = (colorTheme: string, colorSettings?: TripMonitorColorSettings) => {
    return (dispatch, getState) => {
        const state = getState();
        const userPreferences = getCurrentUserPreferences(state);

        const emptyPreferences = {
            colorSettings: {
                tripMonitor: {currentTheme: '', customColors: {}},
            },
        };

        const currentUserPreferences = userPreferences || emptyPreferences;
        const currentUserColorSettings = currentUserPreferences?.colorSettings || emptyPreferences.colorSettings;
        const currentUserTripMonitorColorSettings = currentUserColorSettings?.tripMonitor;
        const updatedPreferences = {
            ...currentUserPreferences,
            colorSettings: {
                ...currentUserColorSettings,
                tripMonitor: {
                    currentTheme: colorTheme,
                    customColors: colorSettings || currentUserTripMonitorColorSettings.customColors,
                },
            },
        };

        dispatch(showLoader());
        updateDispatcherPreferences(JSON.stringify(updatedPreferences))
            .then(() => {
                dispatch(actionCreators.setUserPreferences(updatedPreferences));
            })
            .catch((error) => {
                console.error('Error on update current user preferences: ', error);
            })
            .finally(() => {
                dispatch(hideLoader());
            });
    };
};

export const fetchCurrentUserAvatar = (): any => async (dispatch, getState) => {
    const userId = getCurrentUserID(getState());

    const avatar = await fetchFiles({entity: `dispatchers`, id: userId, type: 'avatar'})
        .then((res) => {
            if ('data' in res) {
                return res.data?.[0];
            }
        })
        .catch((error) => dispatch(handleError(error)));

    dispatch(actionCreators.setCurrentUserAvatar(avatar));
};
