import * as actionTypes from 'pages/MyCompany/redux/actionTypes';

import Carrier from 'core/entities/Carrier/types';

export type MyCompanyCardState = {
    data: Carrier | null;
};

const defaultState: MyCompanyCardState = {
    data: null,
};

const cardReducer = (state: MyCompanyCardState = defaultState, action): MyCompanyCardState => {
    switch (action.type) {
        case actionTypes.MY_COMPANY_RECEIVED: {
            return {
                ...state,
                data: action.payload.myCompany,
            };
        }

        case actionTypes.MY_COMPANY_STATE_CLEARED: {
            return defaultState;
        }

        default:
            return state;
    }
};

export default cardReducer;
