import React from 'react';

import {getTruckSigns} from 'core/entities/Quote/modules/quoteTruck';
import {Quote} from 'core/entities/Quote/types';
import GeneralBadge from 'components/ui/Badges/GeneralBadge';

const QuoteTruckSigns: React.FC<{quote: Quote}> = (props) => {
    const {quote} = props;
    const signs = getTruckSigns(quote);
    return (
        <div className="ml-1">
            {!signs && <GeneralBadge isTooltip={false} text="no signs" />}
            {signs && <GeneralBadge isTooltip={false} text="with signs" />}
        </div>
    );
};
export default QuoteTruckSigns;
