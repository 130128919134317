import React from 'react';

import {getTruckDrivers} from 'core/entities/Truck/modules/truckDrivers';
import Driver from 'core/entities/Driver/types';
import Truck from 'core/entities/Truck/types';

type OwnProps = {
    truck?: Truck;
    driver: Driver;
};

const TruckDriverBadge: React.FC<OwnProps> = (props) => {
    const {truck, driver} = props;

    const {firstDriver, secondDriver} = getTruckDrivers(truck) || {};

    const isFirstDriver = firstDriver?.id === driver.id;
    const isSecondDriver = secondDriver?.id === driver.id;

    if (isFirstDriver) {
        return <strong>(1dr)</strong>;
    }

    if (isSecondDriver) {
        return <strong>(2dr)</strong>;
    }

    if (!truck && driver) {
        return <strong>(dr)</strong>;
    }

    return null;
};

export default TruckDriverBadge;
