import React from 'react';

import Driver from 'core/entities/Driver/types';
import Truck from 'core/entities/Truck/types';
import {getDriverName} from 'core/entities/Driver/modules/driverName';

import ArchivingErrorWrapper from 'components/ui/modals/ArchivingErrorWrapper';

import DriverTruckOnRoute from './DriverTruckOnRoute';

type OwnProps = {
    data: {driver: Driver; truck: Truck};
    onClose();
};

const ArchivingError = (props: OwnProps): JSX.Element => {
    const {
        data: {driver, truck},
        onClose,
    } = props;
    const driverName = getDriverName(driver);
    return (
        <ArchivingErrorWrapper title={driverName} onClose={onClose}>
            <DriverTruckOnRoute truck={truck} />
        </ArchivingErrorWrapper>
    );
};

export default ArchivingError;
