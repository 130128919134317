import React, {useCallback, useMemo} from 'react';
import {Modal} from 'react-bootstrap';

import ModalProviderProps from 'components/ui/ModalProvider/types/ModalProviderProps';

import {convertToLbs} from 'utils/weightConverter';

import FormData from './types/FormData';
import Form from './Form';

interface Data {
    stopIndex: number;
    isLastStop?: boolean;
}

interface Handlers {
    onAddStop(index: number, stop: FormData): void;
}

const AddVirtualStopModal = (props: ModalProviderProps<Data, Handlers>): JSX.Element => {
    const {
        data: {stopIndex, isLastStop},
        handlers: {onAddStop},
        onClose,
    } = props;
    const isFirstStop = stopIndex === 0;
    const initialValues = useMemo(function (): Pick<FormData, 'is_pick_up' | 'is_virtual' | 'unit'> {
        return {
            unit: 'lbs',
            is_pick_up: !isLastStop,
            is_virtual: true,
        };
    }, []);
    const handleSubmit = useCallback(function (stop: FormData): void {
        const weight = convertToLbs(stop.unit, stop.weight);
        onAddStop(stopIndex, {...stop, weight: weight || undefined});
        onClose();
    }, []);
    return (
        <Modal onHide={onClose} dialogClassName="standard-form-modal" size="sm" show>
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Adding new stop</Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    isFirstStop={isFirstStop}
                    isLastStop={isLastStop}
                    onCancel={onClose}
                />
            </Modal.Body>
        </Modal>
    );
};

export default AddVirtualStopModal;
