export const JOIN_ROOM_EVENT = 'join_room';
export const LEAVE_ROOM_EVENT = 'leave_room';

export const QUOTE_ACTIVATED_EVENT = 'quote_activated';
export const DRIVER_OFFER_CREATED_DISPATCHER_NOTIFICATION_EVENT = 'driver_offer_created_dispatcher_notification';
export const DRIVER_OFFER_CREATED_EVENT = 'driver_offer_created';
export const DRIVER_OFFER_UPDATED_EVENT = 'driver_offer_updated';
export const DRIVER_OFFER_DECLINED_EVENT = 'driver_offer_declined';
export const DRIVER_OFFER_STATUS_CHANGED_EVENT = 'driver_offer_status_changed';
export const GENERAL_NOTE_CREATED_EVENT = 'general_note_created';
export const GENERAL_NOTE_DELETED_EVENT = 'general_note_deleted';
