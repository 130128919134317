import keyBy from 'lodash/keyBy';

import {PermissionsKeys, PermissionsValues} from 'core/entities/Permissions';

import type {PermissionsActionsTypes} from './actionCreators';
import * as types from './actionTypes';

export type PermissionsState = {[key in PermissionsKeys]: PermissionsValues} | {};

const defaultState: PermissionsState = {};

const permissions = (state: PermissionsState = defaultState, action: PermissionsActionsTypes): PermissionsState => {
    switch (action.type) {
        case types.SET_PERMISSIONS: {
            const computedPermissions = action.payload || [];

            return keyBy(computedPermissions, 'value');
        }

        default:
            return state;
    }
};

export default permissions;
