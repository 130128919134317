import React from 'react';
import {Quote} from 'core/entities/Quote/types';
import {getDestinationAddress, getOriginAddress, getOriginStop} from 'core/entities/Quote/modules/quoteStop';
import {getStopDate} from 'pages/LoadBoard/utils';
import StopCityLine from 'pages/LoadBoard/components/modals/QuoteDuplicatesModal/components/common/StopCityLine';

const QuoteStopsInfo: React.FC<{quote: Quote}> = (props) => {
    const {quote} = props;

    const origin = getOriginStop(quote);
    const originAddress = getOriginAddress(quote);
    const destinationAddress = getDestinationAddress(quote);

    return (
        <div className="col-6">
            <div>
                <StopCityLine originAddress={originAddress} destinationAddress={destinationAddress} />
            </div>
            <div>{getStopDate(origin)}</div>
        </div>
    );
};

export default QuoteStopsInfo;
