import React from 'react';

import Truck from 'core/entities/Truck/types';
import Owner from 'core/entities/Owner/types';
import Driver from 'core/entities/Driver/types';
import Carrier from 'core/entities/Carrier/types';

import {getDriverName} from 'core/entities/Driver/modules/driverName';
import {getOwnerName} from 'core/entities/Owner/modules/ownerName';

import {TruckLink, OwnerLink, DriverLink, CarrierLink} from 'components/ui/Links';

const TruckInfo: React.FC<{truck: Truck}> = (props) => {
    const {truck} = props;
    return (
        <>
            <TruckLink truck={truck} content={`Truck #${truck.number}`} className="second-link" />
        </>
    );
};

const OwnerInfo: React.FC<{owner: Owner}> = (props) => {
    const {owner} = props;
    const ownerName = getOwnerName(owner);
    return (
        <>
            <OwnerLink owner={owner} content={ownerName} className="second-link" />
        </>
    );
};

const DriverInfo: React.FC<{driver: Driver}> = (props) => {
    const {driver} = props;
    const driverName = getDriverName(driver);
    return (
        <>
            <DriverLink driver={driver} content={driverName} className="second-link" />
        </>
    );
};

const CarrierInfo: React.FC<{carrier: Carrier}> = (props) => {
    const {carrier} = props;
    return (
        <>
            <CarrierLink carrier={carrier} content={carrier.company_name} className="second-link" />
        </>
    );
};

type OwnProps = {
    errorData: {
        entity: unknown;
        entityType: string;
    };
};

const ExistsArchivedEntityError: React.FC<OwnProps> = (props) => {
    const {errorData} = props;
    if (!errorData.entityType || !errorData.entity) {
        return null;
    }
    const showArchivedTruckError = errorData.entityType === 'trucks';
    const showArchivedOwnerError = errorData.entityType === 'owners';
    const showArchivedDriverError = errorData.entityType === 'drivers';
    const showArchivedCarrierError = errorData.entityType === 'carriers';
    return (
        <>
            <div className="attention">Attention</div>
            <p className="mt-2">
                <span>You can&apos;t cancel finish confirmation because</span>
                <br />
                {showArchivedTruckError && <TruckInfo truck={errorData.entity as Truck} />}
                {showArchivedOwnerError && <OwnerInfo owner={errorData.entity as Owner} />}
                {showArchivedDriverError && <DriverInfo driver={errorData.entity as Driver} />}
                {showArchivedCarrierError && <CarrierInfo carrier={errorData.entity as Carrier} />}{' '}
                <strong>is archived now</strong>.
            </p>
            <p>
                {showArchivedTruckError && <>You need to restore this truck. </>}
                {showArchivedOwnerError && (
                    <>
                        Please, restore this owner, then the truck, and the first driver, <br />
                        which were in this Travel Order.{' '}
                    </>
                )}
                {showArchivedDriverError && <>You need to restore this driver. </>}
                {showArchivedCarrierError && <>You need to restore this carrier. </>}
                Then you can cancel finish confirmation.
            </p>
        </>
    );
};

export default ExistsArchivedEntityError;
