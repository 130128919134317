import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

import * as appActions from 'store/actions';

import {convertToLbs} from 'utils/weightConverter';

import {getFilesFromProgressStep} from 'deprecated/core/entities/TravelOrder/travelOrder';
import {FILE_TYPES} from 'deprecated/core/entities/TravelOrder/constants/travel-order';
import {updateTravelOrderFiles} from 'deprecated/core/entities/TravelOrder/travelOrderFiles';

import {isSettlementCarrier} from 'core/entities/Settlement';
import {updateSettlementOwnerData} from 'core/entities/Settlement/requests/settlementOwnerRequests';
import {updateSettlementCarrierData} from 'core/entities/Settlement/requests/settlementCarrierRequests';

import * as types from 'pages/Settlements/actionTypes';
import * as selectors from 'pages/Settlements/selectors';

import {createDate} from 'utils/dateTime';

const convertDateToUtcTime = ({date, time, timezone: timeZoneFrom}) => {
    const originTime = createDate(`${date} ${time}`, {fromTimeZone: timeZoneFrom || 'utc', toTimeZone: 'utc'});

    return originTime.fullOriginalDateTime;
};

// temp for test
const delay = ({timeOut}) => {
    return new Promise((resolve) => {
        window.setTimeout(resolve, timeOut);
    });
};

const getUpdateSettlementLoadAction = (currentSettlement, updatedLoad) => {
    return {
        type: types.SETTLEMENT_LOAD_UPDATED,
        payload: {settlementID: currentSettlement.id, updatedLoad},
    };
};

const getUpdateSettlementTravelOrderAction = (currentSettlement, updatedTravelOrder) => {
    return {
        type: types.SETTLEMENT_TRAVEL_ORDER_UPDATED,
        payload: {settlementID: currentSettlement.id, updatedTravelOrder},
    };
};

export function addSettlementDeduction(deduction) {
    return function (dispatch) {
        dispatch(appActions.showLoader());

        // need add api request for update settlement when api will be done
        dispatch({
            type: types.SETTLEMENT_DEDUCTION_ADDED,
            payload: {deduction: {...deduction, id: new Date().getTime()}},
        });

        dispatch(appActions.hideLoader());
    };
}

export function deleteSettlementDeduction(deduction) {
    return function (dispatch) {
        dispatch(appActions.showLoader());

        // need add api request for update settlement when api will be done
        dispatch({
            type: types.SETTLEMENT_DEDUCTION_DELETED,
            payload: {deduction},
        });

        dispatch(appActions.hideLoader());
    };
}

export function addSettlementReimbursement(reimbursement) {
    return function (dispatch) {
        dispatch(appActions.showLoader());

        // need add api request for update settlement when api will be done
        dispatch({
            type: types.SETTLEMENT_REIMBURSEMENT_ADDED,
            payload: {reimbursement: {...reimbursement, id: new Date().getTime()}},
        });

        dispatch(appActions.hideLoader());
    };
}

export function deleteSettlementReimbursement(reimbursement) {
    return function (dispatch) {
        dispatch(appActions.showLoader());

        // need add api request for update settlement when api will be done
        dispatch({
            type: types.SETTLEMENT_REIMBURSEMENT_DELETED,
            payload: {reimbursement},
        });

        dispatch(appActions.hideLoader());
    };
}

function receiveFiles(updatedFiles, filesType) {
    return {
        type: types.SETTLEMENT_TRAVEL_ORDER_UPDATED_FILES,
        payload: {type: filesType, files: updatedFiles},
    };
}

const addFiles = async (props) => {
    const {travelOrderId, stopId, allTravelOrderFiles, updateFiles, filesType, dispatch} = props;
    const filesInStep = getFilesFromProgressStep(stopId, allTravelOrderFiles, filesType);
    dispatch(appActions.showLoader());
    try {
        const updatedFiles = await updateTravelOrderFiles(travelOrderId, filesInStep, updateFiles, filesType);
        dispatch(receiveFiles(updatedFiles, filesType));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export function changeStops(stopsInfo) {
    return async function (dispatch, getState) {
        dispatch(appActions.showLoader());

        const postTravelOrderFiles = async (travelOrder) => {
            const allTravelOrderFiles = selectors.getTravelOrderFiles(getState());
            const travelOrderStopInfo = travelOrder && travelOrder.stop;
            const {bolFiles} = travelOrderStopInfo;

            const currentPods = travelOrderStopInfo.pods || [];
            const podFiles = currentPods.flatMap((item) => item.files).filter((file) => Boolean(file)) || [];

            if (!isEmpty(podFiles)) {
                await addFiles({
                    travelOrderId: stopsInfo.travelOrder.id,
                    stopId: stopsInfo.travelOrder.stop.id,
                    allTravelOrderFiles,
                    updateFiles: podFiles,
                    filesType: FILE_TYPES.pod,
                    dispatch,
                });
            }

            if (!isEmpty(bolFiles)) {
                await addFiles({
                    travelOrderId: stopsInfo.travelOrder.id,
                    stopId: stopsInfo.travelOrder.stop.id,
                    allTravelOrderFiles,
                    updateFiles: bolFiles,
                    filesType: FILE_TYPES.bol,
                    dispatch,
                });
            }
        };

        const changeTravelOrderDataFormat = (travelOrder) => {
            const travelOrderStopInfo = travelOrder && travelOrder.stop;
            if (!travelOrderStopInfo) {
                return {};
            }

            const {checkInTime, checkOutTime, bols, bolFiles, ...restData} = travelOrderStopInfo;

            const newBoLs = (bols || []).map((bol) => {
                return omit(
                    {
                        ...bol,
                        unit: bol.weight.unit,
                        is_different_address: bol.isDifferentAddress,
                        weight: convertToLbs(bol.weight.unit, bol.weight.amount),
                    },
                    ['isDifferentAddress'],
                );
            });

            return {
                stop: {
                    ...restData,
                    bols: newBoLs,
                    check_in_datetime: convertDateToUtcTime(checkInTime),
                    check_in_timezone: checkInTime.timezone,

                    check_out_datetime: convertDateToUtcTime(checkOutTime),
                    check_out_timezone: checkOutTime.timezone,
                },
            };
        };

        const {load, travelOrder} = stopsInfo;
        const loadInfo = load ? {load} : {};
        const travelOrderInfo = travelOrder ? {travelOrder: changeTravelOrderDataFormat(travelOrder)} : {};
        const updatedData = {
            ...loadInfo,
            ...travelOrderInfo,
        };
        const currentSettlement = selectors.getCurrentSettlement(getState());
        const updater = isSettlementCarrier(currentSettlement)
            ? updateSettlementCarrierData
            : updateSettlementOwnerData;

        try {
            const response = await updater(currentSettlement.id, updatedData);

            const isLoadUpdated = updatedData.load;
            const isTravelOrderUpdated = updatedData.travelOrder;

            await postTravelOrderFiles(travelOrder).catch((e) => console.warn('Error on update settlement data: ', e));

            if (isLoadUpdated && !isTravelOrderUpdated) {
                dispatch(getUpdateSettlementLoadAction(currentSettlement, response.data));
            } else if (isTravelOrderUpdated) {
                dispatch(getUpdateSettlementTravelOrderAction(currentSettlement, response.data));
            }
        } catch (e) {
            console.warn('Error on update settlement data: ', e);
        } finally {
            dispatch(appActions.hideLoader());
        }
    };
}

export function changeDriverPayment(data) {
    return function (dispatch, getState) {
        dispatch(appActions.showLoader());
        const currentSettlement = selectors.getCurrentSettlement(getState());
        const updater = isSettlementCarrier(currentSettlement)
            ? updateSettlementCarrierData
            : updateSettlementOwnerData;
        const updatedRate = omit(data, ['travelOrderID']);
        updater(currentSettlement.id, {travelOrder: {rate: updatedRate}})
            .then(() => {
                dispatch({
                    type: types.SETTLEMENT_DRIVER_PAYMENT_UPDATED,
                    payload: {currentSettlement, updatedRate},
                });
            })
            .catch((error) => console.warn('Error on update settlement rate: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function changeTotalAmount(totalAmount) {
    return async function (dispatch) {
        // eslint-disable-next-line
        console.info("changeTotalAmount ====>>> ", totalAmount);

        dispatch(appActions.showLoader());

        await delay({timeOut: 500});

        dispatch(appActions.hideLoader());
    };
}
