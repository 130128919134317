import React, {useCallback} from 'react';
import {Modal} from 'react-bootstrap';

import PayPeriodTitle from './PayPeriodTitle';
import ClosePayPeriod from './ClosePayPeriod';
import SendPayPeriodEmails from './SendPayPeriodEmails';

const EditSettlementsPayPeriodModal = ({onClose, data: {payPeriod, isClosing}, handlers: {onSubmit, onReopen}}) => {
    const handleCloseSubmit = useCallback(() => {
        onSubmit({...payPeriod});
    }, [payPeriod]);
    return (
        <Modal
            // this prop set for avoid bug with incorrect focus in case when some component with OverlayTrigger using
            // inside modal, see BatchNote component
            enforceFocus={false}
            show={true}
            onHide={onClose}
            dialogClassName="standard-form-modal"
            size="lg"
            backdrop="static"
        >
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>
                        <PayPeriodTitle payPeriod={payPeriod} />
                    </Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                {isClosing ? (
                    <ClosePayPeriod payPeriod={payPeriod} onClose={onClose} onSubmit={handleCloseSubmit} />
                ) : (
                    <SendPayPeriodEmails
                        payPeriod={payPeriod}
                        onSubmit={onSubmit}
                        onReopen={onReopen}
                        isPayPeriodCanBeReopened={true}
                        onClose={onClose}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
};

export default EditSettlementsPayPeriodModal;
