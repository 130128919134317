import React from 'react';
import {Address} from 'core/entities/Quote/types';
import {getQuoteDuplicateCityLine} from './utils';

type OwnProps = {
    originAddress: Address;
    destinationAddress: Address;
};
const StopCityLine: React.FC<OwnProps> = (props) => {
    const {originAddress, destinationAddress} = props;
    const originCityLine = getQuoteDuplicateCityLine(originAddress);
    const destinationCityLine = getQuoteDuplicateCityLine(destinationAddress);
    return (
        <>
            {originCityLine} - {destinationCityLine}
        </>
    );
};

export default StopCityLine;
