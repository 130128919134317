import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';

import Form from './Form';

class PasswordModal extends Component {
    constructor(props) {
        super(props);
        const {
            data: {entity},
        } = props;

        this.initValues = {
            email: entity.email,
        };
    }

    onSubmit = (fields) => {
        const {handlers, onClose} = this.props;

        handlers.handleSubmit({fields});
        onClose();
    };

    render() {
        const {onClose} = this.props;

        return (
            <Modal show={true} onHide={onClose} dialogClassName="custom-modal standard-form-modal" size="sm">
                <Modal.Header>
                    <div className="modal-header__top">
                        <Modal.Title>Change driver&apos;s password</Modal.Title>

                        <button onClick={onClose} type="button" className="close">
                            <i className="fa fa-times" aria-hidden="true" />
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Form
                            form="change-driver-password"
                            onSubmit={this.onSubmit}
                            onClose={onClose}
                            initialValues={this.initValues}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default PasswordModal;
