import React from 'react';

import {checkIsCarrierApproved} from 'core/entities/Carrier/modules';
import Carrier from 'core/entities/Carrier/types';

import InfoBadge from 'components/ui/Badges/InfoBadge';

type OwnProps = {
    carrier: Carrier;
};

const CarrierItemNotApprovedBadge: React.FC<OwnProps> = (props) => {
    const {carrier} = props;

    const isCarrierApproved = checkIsCarrierApproved(carrier);

    if (isCarrierApproved) {
        return null;
    }

    return (
        <>
            <div className="mt5" />

            <InfoBadge text="Not approved company" colorBadge="red" isUpperCase={true} />
        </>
    );
};

export default CarrierItemNotApprovedBadge;
