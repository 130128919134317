import isEmpty from 'lodash/isEmpty';

import TravelOrder from 'core/entities/TravelOrder/types';
import Carrier from 'core/entities/Carrier/types';

export const getCarrierFromTheTravelOrder = (travelOrder?: TravelOrder | null): Carrier | undefined | null =>
    travelOrder?.carrier;

export const isTravelOrderCreatedByAttachCarrier = (travelOrder?: TravelOrder | null): boolean =>
    !isEmpty(travelOrder?.carrier);

export const isTravelOrderHasGlobalCarrier = (travelOrder?: TravelOrder | null): boolean =>
    Boolean(travelOrder?.carrier?.is_global);

export const isTravelOrderHasCarrier = (travelOrder?: TravelOrder | null): boolean => !isEmpty(travelOrder?.carrier);
