import React from 'react';
import GeneralBadge from 'components/ui/Badges/GeneralBadge';
import {getCertificateLabel} from './utils';

const Certificate: React.FC<{certificate: string}> = (props) => {
    const {certificate} = props;
    const label = getCertificateLabel(certificate);
    if (!label) {
        return null;
    }

    return <GeneralBadge isTooltip={false} text={label} />;
};

export default Certificate;
