import {AppState} from 'store';

import {Sender} from 'core/entities/Sender/types';

export const getCurrentCarrier = (state: AppState) => state.app.commonData.current_carrier;
export const getAllSenders = (state: AppState): Sender[] => state.app.commonData.senders;
export const getAllDispatchers = (state: AppState) => state.app.commonData.dispatchers;
export const getAllCarriers = (state: AppState) => state.app.commonData.carriers;
export const getOwners = (state: AppState) => state.app.commonData.owners;
export const getIsCarriersFetching = (state: AppState) => state.app.isCarriersFetching;
export const getIsDispatchersFetching = (state: AppState) => state.app.isDispatchersFetching;
export const getIsDataLoading = (state: AppState): boolean => state.app.isDataLoading;
