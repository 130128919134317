import {isFunction, isPlainObject} from 'lodash';
import io from 'socket.io-client';

import appConfig from 'config';

import {DriverOffer, GeneralNote, Quote} from 'core/entities/Quote/types';
import {Notification as DriverOfferCratedDispatcherNotification} from 'core/gateways/LoadBoardApiGateway/requests/types';

import {
    DRIVER_OFFER_CREATED_DISPATCHER_NOTIFICATION_EVENT,
    DRIVER_OFFER_CREATED_EVENT,
    DRIVER_OFFER_STATUS_CHANGED_EVENT,
    DRIVER_OFFER_UPDATED_EVENT,
    GENERAL_NOTE_CREATED_EVENT,
    DRIVER_OFFER_DECLINED_EVENT,
    GENERAL_NOTE_DELETED_EVENT,
    JOIN_ROOM_EVENT,
    LEAVE_ROOM_EVENT,
    QUOTE_ACTIVATED_EVENT,
} from './wsEvents';
import restApi from './http';

type Socket = ReturnType<typeof io>;

type EventName =
    | typeof JOIN_ROOM_EVENT
    | typeof LEAVE_ROOM_EVENT
    | typeof QUOTE_ACTIVATED_EVENT
    | typeof DRIVER_OFFER_CREATED_DISPATCHER_NOTIFICATION_EVENT
    | typeof DRIVER_OFFER_CREATED_EVENT
    | typeof DRIVER_OFFER_UPDATED_EVENT
    | typeof DRIVER_OFFER_DECLINED_EVENT
    | typeof DRIVER_OFFER_STATUS_CHANGED_EVENT
    | typeof GENERAL_NOTE_CREATED_EVENT
    | typeof GENERAL_NOTE_DELETED_EVENT;

type EventQuoteActivated = {
    name: typeof QUOTE_ACTIVATED_EVENT;
    payload: {quoteID: string; status: Quote['status']; notifiedDriversCount: number};
};

type EventDriverOfferCreatedDispatcherNotification = {
    name: typeof DRIVER_OFFER_CREATED_DISPATCHER_NOTIFICATION_EVENT;
    payload: DriverOfferCratedDispatcherNotification;
};

type EventDriverOfferCreated = {
    name: typeof DRIVER_OFFER_CREATED_EVENT;
    payload: DriverOffer;
};

type EventDriverOfferUpdated = {
    name: typeof DRIVER_OFFER_UPDATED_EVENT;
    payload: DriverOffer;
};

type EventDriverOfferDeclined = {
    name: typeof DRIVER_OFFER_DECLINED_EVENT;
    payload: DriverOffer;
};

type EventDriverOfferStatusChanged = {
    name: typeof DRIVER_OFFER_STATUS_CHANGED_EVENT;
    payload: {id: string; quoteID: string; status: string};
};

type EventGeneralNoteCreated = {
    name: typeof GENERAL_NOTE_CREATED_EVENT;
    payload: GeneralNote;
};

type EventGeneralNoteDeleted = {
    name: typeof GENERAL_NOTE_DELETED_EVENT;
    payload: GeneralNote;
};

type WsEventHandler<Event> = (e: Event) => void;

type Handlers = {
    subscribeOnQuoteActivatedEvent(handler: (e: EventQuoteActivated) => void): void;
    unSubscribeFromQuoteActivatedEvent(): void;

    subscribeOnDriverOfferCreatedDispatcherNotification(
        handler: (e: EventDriverOfferCreatedDispatcherNotification) => void,
    );
    unSubscribeFromDriverOfferCreatedDispatcherNotification(): void;
    subscribeOnDriverOfferCreatedEvent(handler: (e: EventDriverOfferCreated) => void);
    unSubscribeFromDriverOfferCreatedEvent(): void;

    subscribeOnDriverOfferUpdatedEvent(handler: (e: EventDriverOfferUpdated) => void);
    unSubscribeFromDriverOfferUpdatedEvent(): void;

    subscribeOnDriverOfferDeclinedEvent(handler: (e: EventDriverOfferDeclined) => void);
    unSubscribeFromDriverOfferDeclinedEvent(): void;

    subscribeOnDriverOfferStatusChangedEvent(handler: (e: EventDriverOfferStatusChanged) => void);
    unSubscribeFromDriverOfferStatusChangedEvent(): void;

    subscribeOnGeneralNoteCreatedEvent(handler: (e: EventGeneralNoteCreated) => void);
    unSubscribeFromGeneralNoteCreatedEvent(): void;

    subscribeOnGeneralNoteDeletedEvent(handler: (e: EventGeneralNoteDeleted) => void);
    unSubscribeFromGeneralNoteDeletedEvent(): void;

    disconnectFromDriversOffersRoom(): void;
};

const AUCTION_QUOTES_ROOM = 'auction_quotes';

const getWsTokenRequest = (): Promise<string> => {
    const url = 'auth/ws';
    return restApi
        .post(url)
        .then((response) => response.data.token)
        .catch((error) => {
            console.error('Error on get ws auth token:', error);
        });
};

const connectToSocketIO = (authToken) => {
    const isDev = appConfig.NODE_ENV === 'development';
    const socketUrl = isDev ? `ws://localhost:9999` : `${window.location.hostname}`;

    return new Promise((resolve, reject) => {
        const socket = io(socketUrl, {
            transports: ['websocket'],
            path: isDev ? '' : '/api/auction/socket.io',
            reconnection: true,
        });

        socket.on('connect', () => {
            // eslint-disable-next-line
            console.log('client success connected to websocket, socket.id = ', socket.id);

            socket.emit('authenticate', {accessToken: authToken});

            socket.emit(JOIN_ROOM_EVENT, {name: AUCTION_QUOTES_ROOM});

            resolve(socket);
        });

        socket.on('disconnect', (reason: any) => {
            // eslint-disable-next-line
            console.log('client disconnected from websocket, reason: ', reason);
            // dispatch(disconnected());
            // const RECONNECT_TIMEOUT = 5000;
            // window.setTimeout(() => dispatch(reconnect()), RECONNECT_TIMEOUT);

            reject(reason);
        });
    });
};

let websocketConnect;

const connectToWs = (): Promise<Socket> => {
    if (websocketConnect) {
        return websocketConnect;
    }

    websocketConnect = getWsTokenRequest().then(connectToSocketIO);

    return websocketConnect;
};

const getEventData = <T>(data: unknown): T | undefined => {
    if (!isPlainObject(data)) {
        return undefined;
    }
    return data as T;
};

const subscribeOnEvent = (
    socket: Socket,
    eventName: EventName,
    handler:
        | WsEventHandler<EventQuoteActivated>
        | WsEventHandler<EventDriverOfferCreatedDispatcherNotification>
        | WsEventHandler<EventDriverOfferCreated>
        | WsEventHandler<EventDriverOfferUpdated>
        | WsEventHandler<EventDriverOfferDeclined>
        | WsEventHandler<EventDriverOfferStatusChanged>
        | WsEventHandler<EventGeneralNoteCreated>
        | WsEventHandler<EventGeneralNoteDeleted>,
) => {
    if (!isFunction(handler)) {
        console.warn('Incorrect data on subscribe to ws events: ', {eventName, handler});
        return;
    }
    socket.on(eventName, (data) => {
        const eventData = getEventData(data);
        if (!eventData) {
            console.warn('Incorrect response data received on event: ', eventName);
            return;
        }
        handler({
            name: eventName,
            payload: eventData,
        } as any);
    });
};

const unSubscribeFromEvent = (socket: Socket, eventName: EventName) => {
    socket.off(eventName);
};

const websocket = async (): Promise<Handlers> => {
    const connectedSocket = await connectToWs();

    return {
        // QUOTE ACTIVATED EVENT
        subscribeOnQuoteActivatedEvent: (handler) => subscribeOnEvent(connectedSocket, QUOTE_ACTIVATED_EVENT, handler),
        unSubscribeFromQuoteActivatedEvent: () => unSubscribeFromEvent(connectedSocket, QUOTE_ACTIVATED_EVENT),

        // DRIVER OFFER CREATED EVENT
        subscribeOnDriverOfferCreatedDispatcherNotification: (handler) => {
            subscribeOnEvent(connectedSocket, DRIVER_OFFER_CREATED_DISPATCHER_NOTIFICATION_EVENT, handler);
        },
        unSubscribeFromDriverOfferCreatedDispatcherNotification: () => {
            unSubscribeFromEvent(connectedSocket, DRIVER_OFFER_CREATED_DISPATCHER_NOTIFICATION_EVENT);
        },
        subscribeOnDriverOfferCreatedEvent: (handler) => {
            subscribeOnEvent(connectedSocket, DRIVER_OFFER_CREATED_EVENT, handler);
        },
        unSubscribeFromDriverOfferCreatedEvent: () => unSubscribeFromEvent(connectedSocket, DRIVER_OFFER_CREATED_EVENT),

        // DRIVER OFFER UPDATED EVENT
        subscribeOnDriverOfferUpdatedEvent: (handler) => {
            subscribeOnEvent(connectedSocket, DRIVER_OFFER_UPDATED_EVENT, handler);
        },
        unSubscribeFromDriverOfferUpdatedEvent: () => unSubscribeFromEvent(connectedSocket, DRIVER_OFFER_UPDATED_EVENT),

        // DRIVER OFFER DECLINED EVENT
        subscribeOnDriverOfferDeclinedEvent: (handler) => {
            subscribeOnEvent(connectedSocket, DRIVER_OFFER_DECLINED_EVENT, handler);
        },
        unSubscribeFromDriverOfferDeclinedEvent: () =>
            unSubscribeFromEvent(connectedSocket, DRIVER_OFFER_DECLINED_EVENT),

        // DRIVER OFFER STATUS CHANGED EVENT
        subscribeOnDriverOfferStatusChangedEvent: (handler) => {
            subscribeOnEvent(connectedSocket, DRIVER_OFFER_STATUS_CHANGED_EVENT, handler);
        },
        unSubscribeFromDriverOfferStatusChangedEvent: () => {
            unSubscribeFromEvent(connectedSocket, DRIVER_OFFER_STATUS_CHANGED_EVENT);
        },

        // GENERAL NOTE CREATED EVENT
        subscribeOnGeneralNoteCreatedEvent: (handler) => {
            subscribeOnEvent(connectedSocket, GENERAL_NOTE_CREATED_EVENT, handler);
        },
        unSubscribeFromGeneralNoteCreatedEvent: () => {
            unSubscribeFromEvent(connectedSocket, GENERAL_NOTE_CREATED_EVENT);
        },

        // GENERAL NOTE DELETED EVENT
        subscribeOnGeneralNoteDeletedEvent: (handler) => {
            subscribeOnEvent(connectedSocket, GENERAL_NOTE_DELETED_EVENT, handler);
        },
        unSubscribeFromGeneralNoteDeletedEvent: () => {
            unSubscribeFromEvent(connectedSocket, GENERAL_NOTE_DELETED_EVENT);
        },

        disconnectFromDriversOffersRoom: () => {
            connectedSocket.emit(LEAVE_ROOM_EVENT, {name: AUCTION_QUOTES_ROOM});
        },
    };
};

export default websocket;
