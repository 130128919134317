import React from 'react';

import useGetEmptyTrucksError from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/hooks/useGetEmptyTrucksError';
import BlueCheckbox from '../common/BlueCheckbox';
import styles from './styles.module.scss';

interface OwnProps {
    selectedTrucksLength: number;
    allTrucksLength: number;
    isAllSelected: boolean;
    onSelectAllTrucks: () => void;
}

const CheckAllTrucks: React.FC<OwnProps> = (props) => {
    const {selectedTrucksLength, isAllSelected, onSelectAllTrucks, allTrucksLength} = props;
    const error = useGetEmptyTrucksError();
    return (
        <div className={styles.wrapper}>
            <div className={styles.counter}>
                <BlueCheckbox
                    onChange={onSelectAllTrucks}
                    isChecked={isAllSelected}
                    label={`${selectedTrucksLength}/${allTrucksLength} drivers`}
                />
            </div>
            {error && <div className={styles.error}>{error}</div>}
        </div>
    );
};

export default CheckAllTrucks;
