import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import * as wsRequests from 'services/chatApi/websocket';

import {websocketConnectionReceived} from 'widgets/Chat/redux/actions/wsActions';
import {getChatClientSocketId} from 'widgets/Chat/redux/selectors';
import {fetchData} from 'widgets/Chat/redux/actions';
import * as requests from 'widgets/Chat/requests';

const DELAY = 2000;

const useChatAlert = () => {
    const [state, setState] = useState({connecting: false, failure: false, success: false});
    const chatClientSocketId = useSelector(getChatClientSocketId);
    const dispatch = useDispatch();

    useEffect(() => {
        if (state.connecting) {
            setState((prev) => ({...prev, failure: false, success: false}));
        }
    }, [state.connecting]);

    useEffect(() => {
        if (state.success || state.failure) {
            setTimeout(() => {
                setState((prev) => ({...prev, failure: false, success: false}));
            }, DELAY);
        }
    }, [state.success, state.failure]);

    const connectionRequest = async () => {
        if (state.connecting) {
            return;
        }

        try {
            setState((prev) => ({...prev, connecting: true}));

            await requests.chatAuthenticateRequest();

            const webSocket = await wsRequests.chatWSConnectRequest();

            await dispatch(fetchData());

            dispatch(websocketConnectionReceived({webSocket}));

            setState((prev) => ({...prev, success: true}));
        } catch (e) {
            setState((prev) => ({...prev, failure: true}));
        } finally {
            setState((prev) => ({...prev, connecting: false}));
        }
    };

    return {
        connecting: state.connecting,
        failure: state.failure,
        success: state.success,

        chatClientSocketId,
        connectionRequest,
    };
};

export default useChatAlert;
