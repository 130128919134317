export const DISPATCHER_RECEIVED = 'DISPATCHER_RECEIVED';
export const TOGGLE_RESET_FORM_MODAL = 'DISPATCHER_CONTAINER_TOGGLE_RESET_FORM_MODAL';
export const FORM_TAB_CHANGED = 'DISPATCHER_FORM_TAB_CHANGED';
export const CLEAR_DISPATCHER_STATE = 'DISPATCHER_CLEAR_DISPATCHER_STATE';
export const RECEIVED_DISPATCHERS = 'DISPATCHERS_CONTAINER_RECEIVED_DISPATCHERS';
export const DISPATCHER_DELETED = 'DISPATCHERS_CONTAINER_DISPATCHER_DELETED';
export const DISPATCHER_ARCHIVED = 'DISPATCHERS_CONTAINER_DISPATCHER_ARCHIVED';
export const DISPATCHER_RESTORED = 'DISPATCHERS_CONTAINER_DISPATCHER_RESTORED';
export const SET_SEARCH_DATA = 'DISPATCHERS_CONTAINER_SET_SEARCH_DATA';
export const SET_PAGINATION = 'DISPATCHERS_CONTAINER_SET_PAGINATION';
export const CLEAR_STATE = 'DISPATCHERS_CONTAINER_CLEAR_STATE';
export const USERS_SET_LIST_TAB = 'USERS_SET_LIST_TAB';
export const LIST_USERS_BY_SEARCH_RECEIVED = 'LIST_USERS_BY_SEARCH_RECEIVED';
export const LIST_USERS_TOGGLE_SEARCH_FILTER = 'LIST_USERS_TOGGLE_SEARCH_FILTER';
