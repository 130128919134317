import React from 'react';
import GeneralBadge from 'components/ui/Badges/GeneralBadge';
import {getCrossBorder} from './utils';

const CrossBorder: React.FC<{crossBorder: string}> = (props) => {
    const {crossBorder} = props;
    const label = getCrossBorder(crossBorder);
    return (
        <div className="ml-1">
            {label === 'Canada' && (
                <GeneralBadge text="CA" tooltip="allowed to cross Canada borders" tooltipPosition="right" />
            )}
            {label === 'Mexico' && (
                <GeneralBadge text="MX" tooltip="allowed to cross Mexico borders" tooltipPosition="right" />
            )}
        </div>
    );
};

export default CrossBorder;
