import {cardActionCreators, archivingActionCreators} from 'pages/FactoringCompanies/redux/actions';
import * as actionTypes from 'pages/FactoringCompanies/redux/actionTypes';

import {FactoringCompany} from 'core/entities/FactoringCompany/types';

import {InferActions} from 'store';

import File from 'types/File';

export type CardState = {
    current: (FactoringCompany & {files?: File[]}) | null;
};

export const defaultState: CardState = {
    current: null,
};

const actionsForInferType = {
    ...cardActionCreators,
    factoringCompanyArchivedFromCard: archivingActionCreators.factoringCompanyArchivedFromCard,
};

export type ActionsTypes = InferActions<typeof actionsForInferType>;

export default function (state: CardState = defaultState, action: ActionsTypes): CardState {
    switch (action.type) {
        case actionTypes.FACTORING_COMPANY_RECEIVED: {
            return {
                ...state,
                current: action.payload,
            };
        }

        case actionTypes.FACTORING_COMPANY_FILES_RECEIVED: {
            return {
                ...state,
                current: state.current ? {...state.current, files: action.payload} : null,
            };
        }

        case actionTypes.FACTORING_COMPANY_ARCHIVED_FROM_CARD: {
            return {
                ...state,
                current: state.current ? {...state.current, ...action.payload.company} : null,
            };
        }

        case actionTypes.CLEAR_FACTORING_COMPANY_STATE: {
            return defaultState;
        }

        // no default
    }

    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
