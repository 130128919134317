export const TIME_ZONES = {
    'America/Anchorage': {name: 'America/Anchorage', '-9': 'AKST', '-8': 'AKDT'},
    'America/Juneau': {name: 'America/Juneau', '-9': 'AKST', '-8': 'AKDT'},
    'America/Metlakatla': {name: 'America/Metlakatla', '-9': 'AKST', '-8': 'AKDT'},
    'America/Nome': {name: 'America/Nome', '-9': 'AKST', '-8': 'AKDT'},
    'America/Sitka': {name: 'America/Sitka', '-9': 'AKST', '-8': 'AKDT'},
    'America/Yakutat': {name: 'America/Yakutat', '-9': 'AKST', '-8': 'AKDT'},
    'America/Dawson_Creek': {name: 'America/Dawson_Creek', '-7': 'MST'},
    'America/Fort_Nelson': {name: 'America/Fort_Nelson', '-7': 'MST'},
    'America/Hermosillo': {name: 'America/Hermosillo', '-7': 'MST'},
    'America/Phoenix': {name: 'America/Phoenix', '-7': 'MST'},
    'America/Creston': {name: 'America/Creston', '-7': 'MST'},
    'America/Dawson': {name: 'America/Dawson', '-8': 'PST', '-7': 'PDT'},
    'America/Los_Angeles': {name: 'America/Los_Angeles', '-8': 'PST', '-7': 'PDT'},
    'America/Tijuana': {name: 'America/Tijuana', '-8': 'PST', '-7': 'PDT'},
    'America/Vancouver': {name: 'America/Vancouver', '-8': 'PST', '-7': 'PDT'},
    'America/Whitehorse': {name: 'America/Whitehorse', '-8': 'PST', '-7': 'PDT'},
    'America/Belize': {name: 'America/Belize', '-6': 'CST'},
    'America/Costa_Rica': {name: 'America/Costa_Rica', '-6': 'CST'},
    'America/El_Salvador': {name: 'America/El_Salvador', '-6': 'CST'},
    'America/Guatemala': {name: 'America/Guatemala', '-6': 'CST'},
    'America/Managua': {name: 'America/Managua', '-6': 'CST'},
    'America/Regina': {name: 'America/Regina', '-6': 'CST'},
    'America/Swift_Current': {name: 'America/Swift_Current', '-6': 'CST'},
    'America/Tegucigalpa': {name: 'America/Tegucigalpa', '-6': 'CST'},
    'America/Boise': {name: 'America/Boise', '-7': 'MST', '-6': 'MDT'},
    'America/Cambridge_Bay': {name: 'America/Cambridge_Bay', '-7': 'MST', '-6': 'MDT'},
    'America/Chihuahua': {name: 'America/Chihuahua', '-7': 'MST', '-6': 'MDT'},
    'America/Denver': {name: 'America/Denver', '-7': 'MST', '-6': 'MDT'},
    'America/Edmonton': {name: 'America/Edmonton', '-7': 'MST', '-6': 'MDT'},
    'America/Inuvik': {name: 'America/Inuvik', '-7': 'MST', '-6': 'MDT'},
    'America/Mazatlan': {name: 'America/Mazatlan', '-7': 'MST', '-6': 'MDT'},
    'America/Ojinaga': {name: 'America/Ojinaga', '-7': 'MST', '-6': 'MDT'},
    'America/Yellowknife': {name: 'America/Yellowknife', '-7': 'MST', '-6': 'MDT'},
    'America/Bahia_Banderas': {name: 'America/Bahia_Banderas', '-6': 'CST', '-5': 'CDT'},
    'America/Chicago': {name: 'America/Chicago', '-6': 'CST', '-5': 'CDT'},
    'America/Indiana/Knox': {name: 'America/Indiana/Knox', '-6': 'CST', '-5': 'CDT'},
    'America/Indiana/Tell_City': {name: 'America/Indiana/Tell_City', '-6': 'CST', '-5': 'CDT'},
    'America/Matamoros': {name: 'America/Matamoros', '-6': 'CST', '-5': 'CDT'},
    'America/Menominee': {name: 'America/Menominee', '-6': 'CST', '-5': 'CDT'},
    'America/Merida': {name: 'America/Merida', '-6': 'CST', '-5': 'CDT'},
    'America/Mexico_City': {name: 'America/Mexico_City', '-6': 'CST', '-5': 'CDT'},
    'America/Monterrey': {name: 'America/Monterrey', '-6': 'CST', '-5': 'CDT'},
    'America/North_Dakota/Beulah': {name: 'America/North_Dakota/Beulah', '-6': 'CST', '-5': 'CDT'},
    'America/North_Dakota/Center': {name: 'America/North_Dakota/Center', '-6': 'CST', '-5': 'CDT'},
    'America/North_Dakota/New_Salem': {name: 'America/North_Dakota/New_Salem', '-6': 'CST', '-5': 'CDT'},
    'America/Rainy_River': {name: 'America/Rainy_River', '-6': 'CST', '-5': 'CDT'},
    'America/Rankin_Inlet': {name: 'America/Rankin_Inlet', '-6': 'CST', '-5': 'CDT'},
    'America/Resolute': {name: 'America/Resolute', '-6': 'CST', '-5': 'CDT'},
    'America/Winnipeg': {name: 'America/Winnipeg', '-6': 'CST', '-5': 'CDT'},
    'America/Havana': {name: 'America/Havana', '-5': 'CST', '-4': 'CDT'},
    'America/Atikokan': {name: 'America/Atikokan', '-5': 'EST'},
    'America/Cancun': {name: 'America/Cancun', '-5': 'EST'},
    'America/Detroit': {name: 'America/Detroit', '-5': 'EST', '-4': 'EDT'},
    'America/Fort_Wayne': {name: 'America/Fort_Wayne', '-5': 'EST', '-4': 'EDT'},
    'America/Grand_Turk': {name: 'America/Grand_Turk', '-5': 'EST', '-4': 'EDT'},
    'America/Indiana/Indianapolis': {name: 'America/Indiana/Indianapolis', '-5': 'EST', '-4': 'EDT'},
    'America/Indiana/Marengo': {name: 'America/Indiana/Marengo', '-5': 'EST', '-4': 'EDT'},
    'America/Indiana/Petersburg': {name: 'America/Indiana/Petersburg', '-5': 'EST', '-4': 'EDT'},
    'America/Indiana/Vevay': {name: 'America/Indiana/Vevay', '-5': 'EST', '-4': 'EDT'},
    'America/Indiana/Vincennes': {name: 'America/Indiana/Vincennes', '-5': 'EST', '-4': 'EDT'},
    'America/Indiana/Winamac': {name: 'America/Indiana/Winamac', '-5': 'EST', '-4': 'EDT'},
    'America/Iqaluit': {name: 'America/Iqaluit', '-5': 'EST', '-4': 'EDT'},
    'America/Kentucky/Louisville': {name: 'America/Kentucky/Louisville', '-5': 'EST', '-4': 'EDT'},
    'America/Kentucky/Monticello': {name: 'America/Kentucky/Monticello', '-5': 'EST', '-4': 'EDT'},
    'America/Nassau': {name: 'America/Nassau', '-5': 'EST', '-4': 'EDT'},
    'America/New_York': {name: 'America/New_York', '-5': 'EST', '-4': 'EDT'},
    'America/Nipigon': {name: 'America/Nipigon', '-5': 'EST', '-4': 'EDT'},
    'America/Pangnirtung': {name: 'America/Pangnirtung', '-5': 'EST', '-4': 'EDT'},
    'America/Port-au-Prince': {name: 'America/Port-au-Prince', '-5': 'EST', '-4': 'EDT'},
    'America/Thunder_Bay': {name: 'America/Thunder_Bay', '-5': 'EST', '-4': 'EDT'},
    'America/Toronto': {name: 'America/Toronto', '-5': 'EST', '-4': 'EDT'},
    'America/Glace_Bay': {name: 'America/Glace_Bay', '-4': 'AST', '-3': 'ADT'},
    'America/Goose_Bay': {name: 'America/Goose_Bay', '-4': 'AST', '-3': 'ADT'},
    'America/Halifax': {name: 'America/Halifax', '-4': 'AST', '-3': 'ADT'},
    'America/Moncton': {name: 'America/Moncton', '-4': 'AST', '-3': 'ADT'},
    'America/Thule': {name: 'America/Thule', '-4': 'AST', '-3': 'ADT'},
    'Atlantic/Bermuda': {name: 'Atlantic/Bermuda', '-4': 'AST', '-3': 'ADT'},
    'America/St_Johns': {name: 'America/St_Johns', '-3:30': 'NST', '-2:30': 'NDT'},
    'Europe/London': {name: 'Europe/London', '+0': 'GMT', '+1': 'BST'},
    'Europe/Dublin': {name: 'Europe/Dublin', '+0': 'GMT', '+1': 'IST'},
    'Europe/Lisbon': {name: 'Europe/Lisbon', '+0': 'WET', '+1': 'WEST'},
    'Europe/Amsterdam': {name: 'Europe/Amsterdam', '+1': 'CET', '+2': 'CEST'},
    'Europe/Andorra': {name: 'Europe/Andorra', '+1': 'CET', '+2': 'CEST'},
    'Europe/Belgrade': {name: 'Europe/Belgrade', '+1': 'CET', '+2': 'CEST'},
    'Europe/Berlin': {name: 'Europe/Berlin', '+1': 'CET', '+2': 'CEST'},
    'Europe/Brussels': {name: 'Europe/Brussels', '+1': 'CET', '+2': 'CEST'},
    'Europe/Budapest': {name: 'Europe/Budapest', '+1': 'CET', '+2': 'CEST'},
    'Europe/Copenhagen': {name: 'Europe/Copenhagen', '+1': 'CET', '+2': 'CEST'},
    'Europe/Gibraltar': {name: 'Europe/Gibraltar', '+1': 'CET', '+2': 'CEST'},
    'Europe/Luxembourg': {name: 'Europe/Luxembourg', '+1': 'CET', '+2': 'CEST'},
    'Europe/Madrid': {name: 'Europe/Madrid', '+1': 'CET', '+2': 'CEST'},
    'Europe/Malta': {name: 'Europe/Malta', '+1': 'CET', '+2': 'CEST'},
    'Europe/Monaco': {name: 'Europe/Monaco', '+1': 'CET', '+2': 'CEST'},
    'Europe/Oslo': {name: 'Europe/Oslo', '+1': 'CET', '+2': 'CEST'},
    'Europe/Paris': {name: 'Europe/Paris', '+1': 'CET', '+2': 'CEST'},
    'Europe/Prague': {name: 'Europe/Prague', '+1': 'CET', '+2': 'CEST'},
    'Europe/Rome': {name: 'Europe/Rome', '+1': 'CET', '+2': 'CEST'},
    'Europe/Stockholm': {name: 'Europe/Stockholm', '+1': 'CET', '+2': 'CEST'},
    'Europe/Tirane': {name: 'Europe/Tirane', '+1': 'CET', '+2': 'CEST'},
    'Europe/Vienna': {name: 'Europe/Vienna', '+1': 'CET', '+2': 'CEST'},
    'Europe/Warsaw': {name: 'Europe/Warsaw', '+1': 'CET', '+2': 'CEST'},
    'Europe/Zurich': {name: 'Europe/Zurich', '+1': 'CET', '+2': 'CEST'},
    'Europe/Kaliningrad': {name: 'Europe/Kaliningrad', '+2': 'EET'},
    'Europe/Athens': {name: 'Europe/Athens', '+2': 'EET', '+3': 'EEST'},
    'Europe/Bucharest': {name: 'Europe/Bucharest', '+2': 'EET', '+3': 'EEST'},
    'Europe/Chisinau': {name: 'Europe/Chisinau', '+2': 'EET', '+3': 'EEST'},
    'Europe/Helsinki': {name: 'Europe/Helsinki', '+2': 'EET', '+3': 'EEST'},
    'Europe/Kiev': {name: 'Europe/Kiev', '+2': 'EET', '+3': 'EEST'},
    'Europe/Riga': {name: 'Europe/Riga', '+2': 'EET', '+3': 'EEST'},
    'Europe/Sofia': {name: 'Europe/Sofia', '+2': 'EET', '+3': 'EEST'},
    'Europe/Tallinn': {name: 'Europe/Tallinn', '+2': 'EET', '+3': 'EEST'},
    'Europe/Uzhgorod': {name: 'Europe/Uzhgorod', '+2': 'EET', '+3': 'EEST'},
    'Europe/Vilnius': {name: 'Europe/Vilnius', '+2': 'EET', '+3': 'EEST'},
    'Europe/Zaporozhye': {name: 'Europe/Zaporozhye', '+2': 'EET', '+3': 'EEST'},
    'Europe/Moscow': {name: 'Europe/Moscow', '+3': 'MSK'},
    'Europe/Minsk': {name: 'Europe/Minsk', '+3': 'MSK'},
    'Europe/Simferopol': {name: 'Europe/Simferopol', '+3': 'MSK'},
    'Europe/Kirov': {name: 'Europe/Kirov', '+3': 'MSK'},
    'America/Danmarkshavn': {name: 'America/Danmarkshavn', '+0': 'GMT'},
    'Atlantic/Reykjavik': {name: 'Atlantic/Reykjavik', '+0': 'GMT'},
    'Europe/Istanbul': {name: 'Europe/Istanbul', '+3': 'TRT'},
    'Europe/Astrakhan': {name: 'Europe/Astrakhan', '+4': 'SST'},
    'Europe/Samara': {name: 'Europe/Samara', '+4': 'SST'},
    'Europe/Saratov': {name: 'Europe/Saratov', '+4': 'SST'},
    'Europe/Ulyanovsk': {name: 'Europe/Ulyanovsk', '+4': 'SST'},
    'Europe/Volgograd': {name: 'Europe/Volgograd', '+4': 'VST'},
} as const;
