import React, {Component} from 'react';
import {connect} from 'react-redux';
import {change, arrayPush, arrayUnshift, arrayRemove} from 'redux-form';

import {getRemovedFileIdsPath} from 'components/ui/Files/FileUpload/utils/mapExistingFilesFromFormState';

const withFormActionsCreator = (WrappedComponent) =>
    class extends Component<any> {
        pushFileToDeleteList = ({id}) => {
            const {pathToDeletedItems} = this.props;
            const pathToDeletedFileIds = getRemovedFileIdsPath(pathToDeletedItems);

            return this.props.arrayPush(pathToDeletedFileIds, id);
        };

        render() {
            return <WrappedComponent {...this.props} pushFileToDeleteList={this.pushFileToDeleteList} />;
        }
    };

const mapDispatchToProps = (dispatch, {meta: {form}}) => ({
    change: (fieldName, value) => dispatch(change(form, fieldName, value)),
    arrayPush: (fieldName, value) => dispatch(arrayPush(form, fieldName, value)),
    arrayUnshift: (fieldName, value) => dispatch(arrayUnshift(form, fieldName, value)),
    arrayRemove: (fieldName, value) => dispatch(arrayRemove(form, fieldName, value)),
});

export default (WrappedComponent) => connect(null, mapDispatchToProps)(withFormActionsCreator(WrappedComponent));
