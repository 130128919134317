import Driver from 'core/entities/Driver/types';
import Truck from 'core/entities/Truck/types';

export const getTruckDriverIsResponsible = (truck?: Truck | null, driver?: Driver | null): boolean => {
    if (!truck) {
        return false;
    }

    const {mainContact} = truck;

    if (!mainContact) {
        return false;
    }

    return Boolean(driver?.id) && mainContact.type === '1dr' && mainContact.id === driver?.id;
};
