import React from 'react';
import {reduxForm, Field} from 'redux-form';

import Button from 'components/ui/Buttons/Button';
import TextArea from 'components/ui/Form/TextArea';

import './style.scss';

const validate = (values) => {
    const errors = {};

    if (!values.ownerId) {
        errors.ownerId = 'Please, choose owner from the list.';
    }

    return errors;
};

const Form = (props) => {
    const {handleSubmit, onClose, pristine, submitting} = props;

    return (
        <form onSubmit={handleSubmit} className="tm-confirm tm-check-in">
            <div className="modal-body__main note-container">
                <Field
                    name="text"
                    type="text"
                    placeholder="type a note about current driver"
                    component={TextArea}
                    rows={5}
                />
            </div>
            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <Button type="submit" colorTheme="green" disabled={pristine || submitting}>
                        Save
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    validate,
})(Form);
