import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import getFormFieldNames from '../utils/getFormFieldNames';
import {FORM_NAME} from '../index';

interface Result {
    setPaymentRpm: (rpm: number | undefined) => void;
}
export default function (): Result {
    const {getPaymentRpmOfferFieldName} = getFormFieldNames();
    const dispatch = useChangeReduxFormValue(FORM_NAME);

    return {
        setPaymentRpm: (rpm) => dispatch(getPaymentRpmOfferFieldName(), rpm),
    };
}
