import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {Field, formValueSelector, reduxForm, InjectedFormProps} from 'redux-form';

import {AppState} from 'store';
import {getTypeFieldNameFactory} from 'utils/typeScript';
import {PayRecord, PayCorrection} from 'core/entities/Settlement/types';
import Button from 'components/ui/Buttons/Button/index';
import TextArea from 'components/ui/Form/TextArea';
import CurrencyInput from 'components/ui/Form/MaskedInput/CurrencyInput';
import RequiredLabel from 'components/ui/Form/RequiredLabel';
import CurrencySelect from 'components/ui/Form/CurrencySelect';

import styles from './payCorrectionForm.module.scss';
import {SettlementReimbursementFormValues} from './types/formTypes';
import validation from './validation';
import {getPayRecordCurrencies} from './utils';

interface OwnProps {
    payRecord: PayRecord;
    payCorrectionType: PayCorrection['type'];
    onClose();
}

interface FormStateProps {
    reasonComment: string;
}

const FORM_NAME = 'add-pay-correction-form';
const COMMENT_MAX_LENGTH = 200;

const getName = getTypeFieldNameFactory<SettlementReimbursementFormValues>();

const Form = (props: InjectedFormProps<{}, OwnProps & FormStateProps> & OwnProps & FormStateProps): JSX.Element => {
    const {handleSubmit, pristine, submitting, reasonComment, payRecord, payCorrectionType, onClose} = props;
    const currencyInputPrefix = payCorrectionType === 'deduction' ? '- ' : '+ ';
    const isDeduction = payCorrectionType === 'deduction';
    const payRecordCurrencies = useMemo(() => getPayRecordCurrencies(payRecord), [payRecord]);
    return (
        <form onSubmit={handleSubmit} className="custom-redesign-form">
            <div className={styles.form}>
                <div className="row">
                    <div className="col-md-3">
                        <Field
                            name={getName('amount')}
                            type="text"
                            label={RequiredLabel(<strong>Amount</strong>)}
                            component={CurrencyInput}
                            prefix={currencyInputPrefix}
                        />
                    </div>
                    <div className="col-md-5">
                        <Field
                            name={getName('currency')}
                            label={RequiredLabel(<strong>Currency</strong>)}
                            component={CurrencySelect}
                            placeholder="choose currency"
                            redesign={true}
                            currencyMap={payRecordCurrencies}
                        />
                    </div>
                </div>
                <Field
                    name={getName('comment')}
                    type="text"
                    maxLength={COMMENT_MAX_LENGTH}
                    placeholder="brief comment"
                    component={TextArea}
                />
                <div className="count-brief-comment">
                    {reasonComment.length} / {COMMENT_MAX_LENGTH}
                </div>
            </div>
            <div className={styles.bottom}>
                <div />
                <div>
                    <Button onClick={onClose} className="custom-design" buttonSize="link">
                        Cancel
                    </Button>
                    <Button
                        disabled={pristine || submitting}
                        type="submit"
                        colorTheme={isDeduction ? 'red' : 'green'}
                        className="custom-design ml-1"
                    >
                        {isDeduction ? 'Deduct' : 'Reimburse '}
                    </Button>
                </div>
            </div>
        </form>
    );
};

const selector = formValueSelector(FORM_NAME);

const ReduxForm = reduxForm<{}, OwnProps & FormStateProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: validation,
})(Form);

export default connect((state: AppState, props: OwnProps) => {
    return {
        reasonComment: selector(state, getName('comment')) || '',
        initialValues: {
            [getName('amount')]: 0,
            [getName('type')]: props.payCorrectionType,
        },
    };
})(ReduxForm);
