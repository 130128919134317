import Pagination from 'types/Pagination';

import {LogItem, SearchParams} from 'pages/Logs/types';
import * as types from 'pages/Logs/actionTypes/list';

import {mapLogsItems} from './utils';

export interface ListState {
    items: LogItem[];
    pagination: Pagination;
    searchParams: SearchParams;
    externalSearchParams: any;
}

export const defaultState: ListState = {
    items: [],
    pagination: {
        currentPage: 1,
        perPage: 50,
        totalItemsCount: 0,
        pagesCount: 0,
    },
    searchParams: {} as SearchParams,
    externalSearchParams: {},
};

export default function listReducer(state = defaultState, action: types.ListTypes): ListState {
    switch (action.type) {
        case types.RECEIVED_LOGS: {
            const {logs} = action.payload;
            return {
                ...state,
                items: mapLogsItems(logs),
                pagination: action.payload.pagination,
                externalSearchParams: {},
            };
        }

        case types.SET_CURRENT_PAGE: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: action.payload,
                },
            };
        }

        case types.SET_PER_PAGE: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                },
            };
        }

        case types.SET_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: action.payload,
            };
        }

        case types.SET_LOGS_EXTERNAL_PARAMS: {
            return {
                ...state,
                externalSearchParams: action.payload,
            };
        }

        case types.CLEAR_STATE: {
            return defaultState;
        }

        default:
            return state;
    }
}
