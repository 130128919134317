import React from 'react';
import {useLocation} from 'react-router';

import ButtonLink from 'components/ui/Buttons/ButtonLink';
import Button from 'components/ui/Buttons/Button';

import Driver from 'core/entities/Driver/types';
import Truck from 'core/entities/Truck/types';
import {getTruckLinks, getDriverLinks} from 'routing/linkBuilder';

const ButtonsBlock: React.FC<{
    driver: Driver;
    truck: Truck | undefined | null;
    withUpdateTruckButton: boolean;
    onViewArchivedList();
    onClose();
}> = (props) => {
    const {driver, truck, withUpdateTruckButton, onViewArchivedList, onClose} = props;
    const {update: updateTruckLink} = getTruckLinks(truck);
    const {view: viewDriverLink, list: listDriversLink} = getDriverLinks(driver);
    const location = useLocation();
    const isListPage = location?.pathname === listDriversLink;
    return (
        <>
            <div style={{visibility: withUpdateTruckButton ? 'visible' : 'hidden'}}>
                {updateTruckLink && (
                    <ButtonLink href={updateTruckLink} buttonSize="medium" colorTheme="grey" className="mr10">
                        Update Truck
                    </ButtonLink>
                )}
            </div>

            <div className="d-flex">
                <Button onClick={onViewArchivedList} buttonSize="medium" colorTheme="grey" className="mr10">
                    View Archive
                </Button>
                {isListPage ? (
                    <ButtonLink href={viewDriverLink} buttonSize="medium" colorTheme="green" className="mr10">
                        View Profile
                    </ButtonLink>
                ) : (
                    <Button onClick={onClose} buttonSize="medium" colorTheme="green" className="mr10">
                        View Profile
                    </Button>
                )}
            </div>
        </>
    );
};

export default ButtonsBlock;
