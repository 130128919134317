import {useDispatch} from 'react-redux';
import {useState} from 'react';

import TravelOrder from 'core/entities/TravelOrder/types';

import {addTravelOrderNote} from '../redux/actions';

type UseAddTONotes = {travelOrder?: TravelOrder | null; onSuccessAddedNote?: (travelOrder: TravelOrder) => void};

export const useAddTONotes = (params: UseAddTONotes) => {
    const {travelOrder, onSuccessAddedNote} = params;

    const [modalTO, setModalTO] = useState(travelOrder);
    const dispatch = useDispatch();

    const addTONote = (newNote) => {
        dispatch(addTravelOrderNote({newNote, travelOrder, setModalTO, onSuccessAddedNote}));
    };

    return {modalTO, addTONote};
};
