import React from 'react';
import {Field} from 'redux-form';
import TextArea from 'components/ui/Form/TextArea';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';

const Quote: React.FC = (): JSX.Element => {
    const {getNoteAboutQuoteFieldName} = getFormFieldNames();
    return (
        <Field
            name={getNoteAboutQuoteFieldName()}
            label="QUOTE FROM"
            maxLength={100}
            component={TextArea}
            placeholder="enter quote from"
        />
    );
};
export default Quote;
