import {SortFrom} from '../store/reducers/sort/types';

const getNextSorting = (currentSortFrom: SortFrom): SortFrom => {
    if (!currentSortFrom) {
        return 'asc';
    }
    if (currentSortFrom === 'asc') {
        return 'desc';
    }
    if (currentSortFrom === 'desc') {
        return undefined;
    }
};

export default getNextSorting;
