import {useSelector} from 'react-redux';

import {getSidebarExpanded, getSidebarMenu} from 'store/reducers/appSettings/selectors';

import {SidebarItems} from 'core/entities/AppSettings/types';

import useAuction from 'hooks/useAuction';

type UseSidebarHook = {
    menuItems: SidebarItems;
    isSidebarExpanded: boolean;
};

const useSidebar = (): UseSidebarHook => {
    const sidebarMenu = useSelector(getSidebarMenu);
    const isSidebarExpanded = useSelector(getSidebarExpanded);
    // TODO: temporary, logic need to be moved to backend
    const isAuctionEnabled = useAuction();

    return {
        menuItems: sidebarMenu.filter((item) => (item.name === 'auction' ? isAuctionEnabled : true)),
        isSidebarExpanded,
    };
};

export default useSidebar;
