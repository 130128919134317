import {useDispatch, useSelector} from 'react-redux';

import * as trucksNearbyModalActions from 'pages/TrucksNearby/redux/actions/modals';
import * as trucksNearbyListActions from 'pages/TrucksNearby/redux/actions/list';

import {getCurrentModalID} from 'components/ui/ModalProvider/selectors';
import {closeModal} from 'components/ui/ModalProvider/actions';

import Truck from 'core/entities/Truck/types';

type UseModalActions = {
    openEditTruckNotesModal: (params: {truck: Truck}) => void;
    sendQuoteToUsers: (params: {formData}) => void;
    openSendQuoteModal: () => void;
    closeModal: () => void;
};

const useModalActions = (): UseModalActions => {
    const currentModalID = useSelector(getCurrentModalID);
    const dispatch = useDispatch();

    return {
        openEditTruckNotesModal: (params: {truck: Truck}) => dispatch(trucksNearbyModalActions.editTruckNotes(params)),
        sendQuoteToUsers: (params: {formData}) => dispatch(trucksNearbyListActions.sendQuoteToUsers(params)),
        openSendQuoteModal: () => dispatch(trucksNearbyListActions.openSendQuoteModal()),
        closeModal: () => dispatch(closeModal(currentModalID)),
    };
};

export default useModalActions;
