import {SETTLEMENTS} from 'utils/data/pages';
import {
    getPageNameFromChangeLocationAction,
    getRouteParamsFromChangeLocationAction,
    isChangeLocationAction,
} from 'routing/utils';

import {SETTLEMENTS_CARRIERS_TYPE, SETTLEMENTS_OWNERS_TYPE} from 'core/entities/Settlement/constants';

const defaultState = {
    settlementsType: null,
    activePage: null,
};

export default function settlementsSettingsReducer(state = defaultState, action) {
    if (isChangeLocationAction(action)) {
        const pageName = getPageNameFromChangeLocationAction(action);
        const routeParams = getRouteParamsFromChangeLocationAction(action) || {};

        if (pageName === SETTLEMENTS && routeParams.type === SETTLEMENTS_CARRIERS_TYPE) {
            return {...state, settlementsType: SETTLEMENTS_CARRIERS_TYPE, activePage: routeParams.action};
        }

        if (pageName === SETTLEMENTS && routeParams.type === SETTLEMENTS_OWNERS_TYPE) {
            return {...state, settlementsType: SETTLEMENTS_OWNERS_TYPE, activePage: routeParams.action};
        }
    }

    return state;
}
