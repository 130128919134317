import {ThunkAction} from 'redux-thunk';

import * as appActions from 'store/actions';
import {AppState} from 'store';

import Pagination from 'types/Pagination';

import {fetchCarrierList} from 'core/gateways/CarrierApiGateway/requests';
import trucksRequests from 'core/gateways/TruckApiGateway/requests';
import * as loadRequests from 'core/useCases/Load';

import {checkIsCarrierApproved} from 'core/entities/Carrier/modules';
import Carrier from 'core/entities/Carrier/types';
import Truck from 'core/entities/Truck/types';
import Load from 'core/entities/Load/types';

import * as globalModalActions from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import {transformTrucksSearchDataToRequestBody} from 'pages/Loads/redux/mappers/attach/trucks';
import {CarriersSearchFormValues, TrucksSearchFormValues} from 'pages/Loads/types/formTypes';
import {ATTACH_LOAD_TABS, MODAL_NAMES} from 'pages/Loads/constants/loadConstants';
import * as attachActionTypes from 'pages/Loads/redux/actionTypes/attach';
import * as attachSelectors from 'pages/Loads/redux/selectors/attach';

import {actionCreators as loadListActionCreators} from 'pages/Loads/redux/actionCreators/list';
import {actionCreators as modalActionCreators} from 'pages/Loads/redux/actionCreators/modal';
import {actionCreators as loadActionCreators} from 'pages/Loads/redux/actionCreators';

import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import validateDataBeforeCreateTravelOrder from './validateDataBeforeCreateTravelOrder';
import {isNeedSetTruckLocation, setTruckLocation} from './utils';

import * as modalActions from '../modalActions';

type AttachThunkAction = ThunkAction<void, AppState, unknown, any>;

export const attachActionCreators = {
    changeTab: (payload: {tab: string}) => ({type: attachActionTypes.SET_TAB, payload} as const),
    setPagination: (payload: {pagination: Partial<Pagination>}) =>
        ({type: attachActionTypes.SET_TRUCKS_PAGINATION, payload} as const),
    setSearchTrucks: (payload: {
        trucks: Truck[];
        searchParams: any;
        pagination?: Pagination;
        isAvailableMoreTrucks?: boolean;
    }) => ({type: attachActionTypes.RECEIVED_TRUCKS, payload} as const),
    receivedMoreTrucks: (payload: {
        trucks: Truck[];
        searchParams: any;
        pagination: Pagination;
        isAvailableMoreTrucks: boolean;
    }) => ({type: attachActionTypes.RECEIVED_MORE_TRUCKS, payload} as const),
    setSearchCarriersParams: (payload: {searchParams: any}) =>
        ({type: attachActionTypes.SET_CARRIERS_SEARCH_PARAMS, payload} as const),
    resetSearchCarriersParams: () => ({type: attachActionTypes.CLEARED_CARRIERS_SEARCH_PARAMS} as const),
    receivedCarriers: (payload: {carriers: Carrier[]}) =>
        ({type: attachActionTypes.RECEIVED_CARRIERS, payload} as const),
    clearState: () => ({type: attachActionTypes.CLEARED_STATE} as const),
};

const getGlobalErrorModalAction = (errorMessage: string) => {
    return globalModalActions.openModal({
        modalName: commonModalNames.informationModal,
        data: {
            rightButtonTitle: 'Ok',
            bodyType: 'ErrorForm',
            buttonType: 'danger',
            title: 'Error',
            errorMessage,
        },
        handlers: {
            leftButtonHandler: () => {},
            rightButtonHandler: () => {},
        },
    });
};

export const fetchCarriers = (): AttachThunkAction => async (dispatch) => {
    const params = {
        'per-page': 0,
    };

    try {
        const {data} = await fetchCarrierList(params);

        dispatch(attachActionCreators.receivedCarriers({carriers: data}));
    } catch (error) {
        dispatch(appActions.handleError(error));
    }
};

export const setPagination = (params: {currentPage: number}): AttachThunkAction => (dispatch) => {
    const {currentPage} = params;

    dispatch(attachActionCreators.setPagination({pagination: {currentPage}}));
};

export const checkIsAvailableMoreTrucks = (params: {trucks: Truck[]; parsedPagination: Pagination}) => {
    const {trucks, parsedPagination} = params;

    const {pagesCount, currentPage, perPage} = parsedPagination;

    const trucksCountLessThanPerPage = trucks.length < perPage;
    const lastPage = currentPage === pagesCount;

    return !(trucksCountLessThanPerPage || lastPage);
};

export const searchTrucks = (params: {formData: Partial<TrucksSearchFormValues>}): AttachThunkAction => async (
    dispatch,
) => {
    const {formData} = params;

    dispatch(setPagination({currentPage: 1}));

    const requestPayload = transformTrucksSearchDataToRequestBody({filters: formData});

    try {
        dispatch(appActions.showLoader());

        const res = await trucksRequests.searchTrucks(requestPayload);

        const trucks = res.data;
        const parsedPagination = parsePaginationHeaders(res.headers);
        const isAvailableMoreTrucks = checkIsAvailableMoreTrucks({trucks, parsedPagination});

        dispatch(
            attachActionCreators.setSearchTrucks({
                searchParams: requestPayload,
                pagination: parsedPagination,
                isAvailableMoreTrucks,
                trucks,
            }),
        );
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const resetTrucks = (): AttachThunkAction => (dispatch) => {
    dispatch(attachActionCreators.setSearchTrucks({trucks: [], searchParams: {}}));
};

export const setSearchCarriersParams = (params: {formData: Partial<CarriersSearchFormValues>}): AttachThunkAction => (
    dispatch,
) => {
    const {formData} = params;

    dispatch(attachActionCreators.setSearchCarriersParams({searchParams: formData}));
};

export const resetSearchCarriersParams = (): AttachThunkAction => (dispatch) => {
    dispatch(attachActionCreators.resetSearchCarriersParams());
};

export const getMoreTrucksForAttaching = (): AttachThunkAction => async (dispatch, getState) => {
    const trucksPagination = attachSelectors.getTrucksPagination(getState());
    const searchParams = attachSelectors.getTrucksSearchParams(getState());

    const currentPage = trucksPagination.currentPage + 1;

    dispatch(setPagination({currentPage}));

    const requestPayload = {
        ...searchParams,
        page: currentPage,
    };

    try {
        dispatch(appActions.showLoader());

        const res = await trucksRequests.searchTrucks(requestPayload);

        const trucks = res.data || [];
        const parsedPagination = parsePaginationHeaders(res.headers);
        const isAvailableMoreTrucks = checkIsAvailableMoreTrucks({trucks, parsedPagination});

        dispatch(
            attachActionCreators.receivedMoreTrucks({
                pagination: parsedPagination,
                isAvailableMoreTrucks,
                searchParams,
                trucks,
            }),
        );
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const createTravelOrderWithTruck = (params: {load: Load; truck?: Truck}): AttachThunkAction => async (
    dispatch,
) => {
    const {load, truck} = params;

    if (!truck) {
        return null;
    }

    try {
        let truckWithLocation = {};

        if (isNeedSetTruckLocation(truck)) {
            truckWithLocation = await setTruckLocation(truck, dispatch);
        }

        const truckToRequest = {...truck, ...truckWithLocation};

        const isAttachValid = await validateDataBeforeCreateTravelOrder(load, truckToRequest, dispatch);

        if (!isAttachValid) {
            return;
        }

        dispatch(appActions.showLoader());

        const {createdTravelOrderNumber, loadNumber} = await loadRequests.createTravelOrderWithTruck({
            load,
            truck: truckToRequest,
        });

        dispatch(loadListActionCreators.travelOrderCreated({loadNumber}));

        const {load: loadWithCreatedTravelOrder} = await loadRequests.getLoad({loadNumber});

        dispatch(loadActionCreators.fetchLoad({load: loadWithCreatedTravelOrder}));

        dispatch(globalModalActions.closeAll());

        dispatch(
            modalActionCreators.showModal({
                modalName: MODAL_NAMES.travelOrderAttachedModal,
                modalData: {createdTravelOrderNumber},
            }),
        );
    } catch (error) {
        dispatch(globalModalActions.closeAll());

        dispatch(
            getGlobalErrorModalAction(
                `Unable to attach ${truck.status} truck#${truck.number} now, please try again later.`,
            ),
        );
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const confirmationNotApprovedCarrierModal = (params: {load: Load; carrier?: Carrier}): AttachThunkAction => (
    dispatch,
) => {
    const {load, carrier} = params;

    if (!carrier) {
        return null;
    }

    dispatch(globalModalActions.closeAll());

    dispatch(
        modalActionCreators.showModal({
            modalName: MODAL_NAMES.confirmationNotApprovedCarrierModal,
            modalData: {load, carrier},
        }),
    );
};

export const createTravelOrderWithCarrier = (params: {load: Load; carrier?: Carrier}): AttachThunkAction => async (
    dispatch,
) => {
    const {load, carrier} = params;

    if (!carrier) {
        return null;
    }

    try {
        dispatch(appActions.showLoader());

        const {createdTravelOrderNumber, loadNumber} = await loadRequests.createTravelOrderWithCarrier({
            loadNumber: load.number,
            carrierId: carrier.id,
        });

        dispatch(loadListActionCreators.travelOrderCreated({loadNumber}));

        const {load: loadWithCreatedTravelOrder} = await loadRequests.getLoad({loadNumber});

        dispatch(loadActionCreators.fetchLoad({load: loadWithCreatedTravelOrder}));

        dispatch(globalModalActions.closeAll());

        dispatch(
            modalActionCreators.showModal({
                modalName: MODAL_NAMES.travelOrderAttachedModal,
                modalData: {createdTravelOrderNumber},
            }),
        );
    } catch (error) {
        dispatch(globalModalActions.closeAll());

        dispatch(
            getGlobalErrorModalAction(
                `Unable to attach the carrier ${carrier?.company_name} now, please try again later.`,
            ),
        );
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const createTravelOrder = (params: {load: Load; carrier?: Carrier; truck?: Truck}): AttachThunkAction => (
    dispatch,
    getState,
) => {
    const {load, carrier, truck} = params;

    const activeTab = attachSelectors.getAttachModalActiveTab(getState());

    const isCarrierApproved = checkIsCarrierApproved(carrier);

    if (activeTab === ATTACH_LOAD_TABS.CARRIERS && !isCarrierApproved) {
        dispatch(confirmationNotApprovedCarrierModal({load, carrier}));
        return;
    }

    if (activeTab === ATTACH_LOAD_TABS.CARRIERS) {
        dispatch(createTravelOrderWithCarrier({load, carrier}));
        return;
    }

    dispatch(createTravelOrderWithTruck({load, truck}));
};
