import React from 'react';
import isEmpty from 'lodash/isEmpty';

import DataLoading from 'pages/Invoices/components/common/DataLoading';
import AllDataLoaded from 'pages/Invoices/components/common/AllDataLoaded';

import {Company, SetupActions, User} from '../../../types';
import useFetchCompanyUsers from '../../../hooks/useFetchCompanyUsers';
import UserInvoices from '../UserInvoices';
import CheckAllInvoices from '../CheckAllInvoices';
import styles from '../companyItem.module.scss';

interface OwnProps {
    company: Company;
    selectedUsers: Record<string, User>;
    className: string;
    actions: SetupActions;
}

const UsersList = ({company, selectedUsers, className, actions}: OwnProps): JSX.Element => {
    const {users, isFetching, isAllFetched, fetchNextPage} = useFetchCompanyUsers({
        company,
    });
    const isAllSelected = !isEmpty(selectedUsers) && Object.keys(selectedUsers).length === users.length;
    const isAllVisibleUsersSelected = isAllSelected;
    const isAllExistsUsersSelected = isAllSelected && isAllFetched;
    const usersRows = users.map((user, userIndex) => {
        return (
            <UserInvoices
                key={userIndex}
                user={user}
                isSelected={user.id in selectedUsers}
                className={className}
                onSelectUser={() => actions.onSelectUser(user)}
            />
        );
    });

    return (
        <div>
            <CheckAllInvoices
                isChecked={isAllSelected}
                className={styles.list}
                onChange={() => actions.onSelectAllUsers(users)}
            />

            <AllDataLoaded
                isAllVisibleItemsSelected={isAllVisibleUsersSelected}
                isAllExistsItemsSelected={isAllExistsUsersSelected}
            />

            {usersRows}

            <DataLoading
                isLoading={isFetching}
                isAllLoaded={isAllFetched}
                onLoadMore={fetchNextPage}
                loadMoreTitle="load more users"
            />
        </div>
    );
};

export default UsersList;
