import Truck from 'core/entities/Truck/types';

import {isTimeExpired} from 'utils/dateTime';

export const getTruckReserveInfo = (truck: Truck) => {
    const {reserve} = truck;

    if (!reserve) {
        return null;
    }

    const isReserved = !isTimeExpired({timeStr: reserve.dateTo});

    return {
        reservedByEmail: reserve?.reserver?.dispatcherEmail,
        reservedById: reserve?.reserver?.dispatcherId,
        waitingRC: reserve.holdForRc,
        isReserved,
    };
};
