import {RoutesNames} from 'routing/types';
import {ROUTES_PATHS} from 'routing/constants';

import {tripMonitorSSEEventHandler, trucksNearbySSEEventHandler} from './handlers';

export const handlersMap: Partial<Record<RoutesNames, any>> = {
    [ROUTES_PATHS.tripMonitor]: tripMonitorSSEEventHandler,
    [ROUTES_PATHS.trucksNearby]: trucksNearbySSEEventHandler,
};

export const pathsMap = {
    [ROUTES_PATHS.tripMonitor]: 'trip_monitor',
    [ROUTES_PATHS.trucksNearby]: 'tnb',
};
