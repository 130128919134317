import Driver from 'core/entities/Driver/types';

const loggedInFields = ['device_type', 'device_id', 'device_app_version', 'device_info'] as const;

export const checkIsDriverLoggedIn = (driver?: Driver | null): boolean => {
    if (!driver) {
        return false;
    }

    return loggedInFields.some((field) => Boolean(driver[field]));
};
