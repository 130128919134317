import React from 'react';

const formatMinutes = ({hours, min}) => {
    const MINUTES_IN_HOUR = 60;

    if (hours) {
        return hours * MINUTES_IN_HOUR + min;
    }

    return min;
};

const TruckReservingTimer = (props) => {
    const {timer} = props;

    const {hours, min, sec} = timer?.expiresIn || {};

    const isExpireInfoEmpty = hours === undefined && min === undefined && sec === undefined;

    if (timer.isExpired || !timer.expiresIn || isExpireInfoEmpty) {
        return null;
    }

    const formattedMinutes = formatMinutes({hours, min});

    return (
        <span className="timer">
            <b>{formattedMinutes}</b>
            <span style={{textTransform: 'lowercase', fontWeight: 'normal'}}>min</span>&nbsp;:&nbsp;<b>{sec}</b>
            <span style={{textTransform: 'lowercase', fontWeight: 'normal'}}>sec</span>
        </span>
    );
};

export default TruckReservingTimer;
