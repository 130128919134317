import getText from 'utils/getText';
import {UploadedFilesRC} from '../types';

export const getUploadedFilesRC = (
    documents?: {alias: string; file: File; tmpID: string; documentNumber: number; documentPage: number}[],
): UploadedFilesRC[] | null => {
    if (!documents) {
        return null;
    }

    return [
        {
            type: 'rc',
            items:
                documents?.map(({documentNumber, documentPage, file, tmpID}) => {
                    return {
                        badge: getText('rc'),
                        documentNumber,
                        documentPage,
                        file,
                        tmpID,
                    };
                }) || [],
        },
    ];
};
