import isEqual from 'lodash/isEqual';

import {Agent} from 'core/entities/Agent';

import {InferActions} from 'store';

import * as actionTypes from 'pages/Customers/redux/actionTypes/agents';
import actionCreators from 'pages/Customers/redux/actionCreators/agents';

import Pagination from 'types/Pagination';

type ActionTypes = InferActions<typeof actionCreators>;

export type AgentsState = {
    agentsList: Agent[];
    showArchived: boolean;
    pagination: Pagination;
};

const defaultState: AgentsState = {
    agentsList: [],
    showArchived: false,
    pagination: {
        currentPage: 1,
        perPage: 50,
        pagesCount: 0,
        totalItemsCount: 0,
    },
};

const agents = (state = defaultState, action: ActionTypes): AgentsState => {
    switch (action.type) {
        case actionTypes.AGENTS_RECEIVED: {
            return {
                ...state,
                agentsList: action.payload.agents,
                pagination: isEqual(state.pagination, action.payload.pagination)
                    ? state.pagination
                    : action.payload.pagination,
            };
        }

        case actionTypes.AGENTS_SHOW_ARCHIVED_SWITCHED: {
            return {
                ...state,
                showArchived: action.payload,
                agentsList: [],
                pagination: {
                    ...state.pagination,
                    currentPage: 1,
                },
            };
        }

        case actionTypes.AGENTS_PAGINATION_PARAMS_RECEIVED: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload,
                },
            };
        }

        case actionTypes.AGENTS_STATE_CLEARED: {
            return defaultState;
        }

        // no default
    }

    const _exhaustiveCheck = action;

    return state;
};

export default agents;
