import last from 'lodash/last';

export function getNextGroupStartingIndex<T>(groupedFilesEntries: [string, T[]][], currentGroupIndex: number): number {
    if (currentGroupIndex === 0) {
        return 0;
    }
    const groupsUntilIndex = groupedFilesEntries.slice(0, currentGroupIndex);
    const flattenedFiles = groupsUntilIndex.reduce((result: T[], value): T[] => {
        // @ts-ignore
        const group: T[] = last(value);
        return result.concat(group);
    }, []);
    return flattenedFiles ? flattenedFiles.length : 0;
}

export function checkIfFileNameMatchesFilter(fileName: string, filter: string): boolean {
    if (!filter) {
        return true;
    }
    const fileNameLowerCase = fileName.toLowerCase();
    const filterLowerCase = filter.toLowerCase();
    return fileNameLowerCase.includes(filterLowerCase);
}

export function getUpdatedTransformStyle(transform: string, angle: number): string {
    return `${transform.replace(/rotate\([0-9]+deg\)/, '').trim()} rotate(${angle}deg)`;
}
