import * as actionTypes from './actionTypes';

export function updateSettlement(data) {
    return {
        type: actionTypes.WEB_SOCKET_SETTLEMENTS_OWNERS_SETTLEMENT_UPDATED,
        payload: data,
    };
}

export function createPayPeriod(data) {
    return {
        type: actionTypes.WEB_SOCKET_SETTLEMENTS_OWNERS_PAY_PERIOD_CREATED,
        payload: data,
    };
}

export function updatePayPeriod(data) {
    return {
        type: actionTypes.WEB_SOCKET_SETTLEMENTS_OWNERS_PAY_PERIOD_UPDATED,
        payload: data,
    };
}

export function updatePayRecord(data) {
    return {
        type: actionTypes.WEB_SOCKET_SETTLEMENTS_OWNERS_PAY_RECORD_UPDATED,
        payload: data,
    };
}
