import {fetchCustomerUsers} from 'core/entities/Invoice/requests/index';

import useFetchWithPaginate from 'hooks/useFetchWithPaginate';

import useInvoicesSearchParams from 'pages/Invoices/hooks/useInvoicesSearchParams';
import {transformSearchParamsForRequest} from 'pages/Invoices/hooks/utils';

import {Company, User} from '../types';
import {getCustomerUsers} from '../utils';

interface FetchParams {
    company: Company;
}

interface FetchResult {
    users: User[];
    error: string | undefined;
    currentPage: number | undefined;
    isFetching: boolean;
    isAllFetched: boolean;
    fetchNextPage();
}

const PER_PAGE = 10;

const fetchPeriodResponsibleUsers = (
    companyID: number | null,
    {page, perPage}: {page: string; perPage: number},
    searchParams,
): Promise<User[]> => {
    const nextPage = parseInt(page, 10) + 1;
    return fetchCustomerUsers({customerID: companyID, pagination: {page: nextPage, perPage}}, searchParams)
        .then((response) => getCustomerUsers(response.data))
        .catch((err) => {
            console.error('Error on fetch company users: ', err);
            return [];
        });
};

const cacheCreator = (companyID: number) => {
    return (pageIndex: any, previousPageData: any) => {
        if (previousPageData && previousPageData.length === 0) {
            return null;
        }
        const pageNumber = String(pageIndex);
        const cacheKey = `pay-period-${companyID}-responsible-users-${pageNumber}`;
        return [cacheKey, pageNumber];
    };
};

const fetcherCreator = (companyID: number | null, searchParams) => {
    return (...args): any => {
        const [_, pageNumber] = args;
        return fetchPeriodResponsibleUsers(companyID, {page: pageNumber, perPage: PER_PAGE}, searchParams);
    };
};

export default (params: FetchParams): FetchResult => {
    const {company} = params;
    // cause factoring company doesnt have id we use generated id
    const getCacheKey = cacheCreator(company.keyID);
    const searchParams = useInvoicesSearchParams();
    const transformSearchParams = transformSearchParamsForRequest(searchParams);
    const fetcher = fetcherCreator(company.entityID, transformSearchParams);
    const {data: users, error, currentPage, isFetching, isAllFetched, fetchNextPage} = useFetchWithPaginate({
        perPage: PER_PAGE,
        getKey: getCacheKey,
        fetchData: fetcher,
    });
    return {
        users,
        error,
        currentPage,
        isFetching,
        isAllFetched,
        fetchNextPage,
    };
};
