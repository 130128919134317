export const validation = (values) => {
    const errors: any = {};

    if (!values.phoneListWithTruckNumbers) {
        errors.phoneListWithTruckNumbers = 'Please, select trucks.';
    }

    if (!values.message.trim()) {
        errors.message = 'Please, enter text message for quote.';
    }

    return errors;
};
