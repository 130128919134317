import React from 'react';
import {Field} from 'redux-form';

import RequiredLabel from 'components/ui/Form/RequiredLabel';
import DistanceInput from 'pages/LoadBoard/components/common/DistanceInput';

interface OwnProps {
    fieldName: string;
    isUpdate: boolean;
}

const EmptyMiles: React.FC<OwnProps> = (props) => {
    const {fieldName, isUpdate} = props;
    return (
        <Field
            label={RequiredLabel('Miles away')}
            name={fieldName}
            component={DistanceInput}
            placeholder="enter miles"
            isDisabled={isUpdate}
            maxLength={6}
        />
    );
};

export default EmptyMiles;
