import React from 'react';

import styles from './allDataLoaded.module.scss';

interface OwnProps {
    isAllVisibleItemsSelected: boolean;
    isAllExistsItemsSelected: boolean;
}

const AllDataLoaded = ({isAllVisibleItemsSelected, isAllExistsItemsSelected}: OwnProps): JSX.Element | null => {
    return (
        <>
            {isAllVisibleItemsSelected && !isAllExistsItemsSelected && (
                <div className={styles.all_visible}>
                    {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                    <div>// all loaded users on this modal are selected</div>
                    {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                    <div>// load more below to re-select next loaded</div>
                </div>
            )}
            {isAllVisibleItemsSelected && isAllExistsItemsSelected && (
                <div className={styles.all_exists}>
                    {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                    <div>// all responsible users for the invoices are selected</div>
                </div>
            )}
        </>
    );
};

export default AllDataLoaded;
