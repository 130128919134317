import React from 'react';
import classNames from 'classnames';

import './style.scss';

class DropDownRadioInput extends React.PureComponent {
    constructor(props) {
        super(props);

        const [initialItem] = props.items.filter((item) => item.value === props.input.value);

        this.state = {
            expanded: false,
            checked: Boolean(props.input.value),
            btnTitle: props.btnTitle || (initialItem && initialItem.btnTitle) || '',
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate() {
        this.setDropDownBtnTitleAndChecked();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setDropDownBtnTitleAndChecked = () => {
        const {input, items, btnTitle} = this.props;
        const [currentItem] = items.filter((item) => item.value === input.value);
        this.setState({
            checked: Boolean(this.props.input.value),
            btnTitle: (currentItem && currentItem.btnTitle) || btnTitle,
        });
    };

    handleClickOutside = (event) => {
        if (this.state.expanded && this.containerNode && !this.containerNode.contains(event.target)) {
            this.setState({expanded: false});
        }
    };

    toggleExpand = () => {
        const {disabled} = this.props;
        if (disabled) {
            return;
        }
        this.setState((prevState) => ({expanded: !prevState.expanded}));
    };

    render() {
        const {
            items,
            input: {onChange},
            disabled,
            btnTitle,
            btnIcons,
        } = this.props;

        const onClickHandler = (item) => {
            if (disabled) {
                return;
            }

            onChange(item.value);

            this.setState({btnTitle: item.btnTitle || btnTitle});
        };

        const stateCls = this.state.expanded ? 'open ' : '';
        const buttonIcons = {
            up: (btnIcons && btnIcons.up) || 'fa-angle-double-up',
            down: (btnIcons && btnIcons.down) || 'fa-angle-double-down',
        };
        const stateIconCls = this.state.expanded ? buttonIcons.up : buttonIcons.down;
        const btnCheckedCls = this.state.checked ? 'checked ' : '';
        const disabledCls = disabled ? 'not-allowed ' : '';

        return (
            <span className={classNames('dropdown-radio-component', {disabled})}>
                <div
                    ref={(el) => {
                        this.containerNode = el;
                    }}
                    className="dropdown-btn-component"
                >
                    <div
                        onClick={!disabled ? this.toggleExpand : null}
                        className={`radio-select ${stateCls} ${btnCheckedCls}`}
                    >
                        <div className={`radio-select__item ${disabledCls}`}>
                            {this.state.btnTitle}
                            <i className={`fa ${stateIconCls}`} aria-hidden="true" />
                        </div>
                        <div
                            ref={(el) => {
                                this.contentNode = el;
                            }}
                            className="radio-select__list"
                        >
                            <ul>
                                {items.map((item, index) => {
                                    const checkedItemCls = this.props.input.value === item.value ? 'checked' : '';

                                    return (
                                        <li
                                            key={`drpdwn_radio_${index}`}
                                            role="menuitem"
                                            className={`${checkedItemCls}`}
                                            // eslint-disable-next-line
                                            onClick={() => { onClickHandler(item);} }
                                        >
                                            {item.label}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </span>
        );
    }
}

export default DropDownRadioInput;
