import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import classNames from 'classnames';

import {getTravelOrderNumber} from 'core/entities/TravelOrder/modules/common/getTravelOrderNumber';
import TravelOrder from 'core/entities/TravelOrder/types';
import {PERMISSIONS} from 'core/entities/Permissions';

import useCheckIfUserHasPermission from 'hooks/useCheckIfUserHasPermission';

import NotesList from './components/NotesList';
import NotesForm from './components/NotesForm';

import {createInitFormValues} from './utils';

import {useAddTONotes} from './hooks';
import Tabs from './components/Tabs';
import {TABS} from './constants';

import styles from './styles.module.scss';

type OwnProps = {
    handlers: {onSuccessAddedNote?: (travelOrder: TravelOrder) => void};
    data: {travelOrder?: TravelOrder | null};
    onClose: () => void;
};

const AddTONotes: React.FC<OwnProps> = (props) => {
    const {data, handlers, onClose} = props;

    const {onSuccessAddedNote} = handlers;
    const {travelOrder} = data;

    const [activeTab, setActiveTab] = useState<typeof TABS[keyof typeof TABS]>(TABS.all);
    const {modalTO, addTONote} = useAddTONotes({travelOrder, onSuccessAddedNote});
    const permissionUpdateTravelOrder = useCheckIfUserHasPermission(PERMISSIONS.TRAVEL_ORDER_UPDATE);

    const toNumber = getTravelOrderNumber(travelOrder);

    const initFormValues = createInitFormValues(travelOrder);

    const modalWrapper = classNames(styles.modal, 'modal-content', 'modal-note-dialogs');

    return (
        <div className={modalWrapper}>
            <Modal.Header>
                <Modal.Title>
                    <strong>TO#{toNumber}</strong> Notes
                </Modal.Title>

                <button type="button" className="close" onClick={onClose}>
                    <i className="fa fa-times" aria-hidden="true" />
                </button>
            </Modal.Header>

            <Modal.Body>
                <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

                <NotesList travelOrder={modalTO} activeTab={activeTab} />

                {!(activeTab === TABS.event) ? (
                    <NotesForm
                        initialValues={initFormValues}
                        onSubmit={addTONote}
                        disabled={!permissionUpdateTravelOrder}
                    />
                ) : null}
            </Modal.Body>
        </div>
    );
};

export default AddTONotes;
