import * as truckStatuses from 'core/entities/Truck/constants/truckStatuses';
import Truck from 'core/entities/Truck/types';

export const getTruckStatus = (truck?: Truck | null) => truck?.status;

export const checkIsTruckStatusWillBeAvailableAt = (truck?: Truck | null): boolean =>
    truck?.status === truckStatuses.TRUCK_STATUS_WILL_BE_AVAILABLE_AT;

export const checkIsTruckStatusNotAvailable = (truck?: Truck | null): boolean =>
    truck?.status === truckStatuses.TRUCK_STATUS_NOT_AVAILABLE;

export const checkIsTruckStatusAvailable = (truck?: Truck | null): boolean =>
    truck?.status === truckStatuses.TRUCK_STATUS_AVAILABLE;

export const checkIsTruckStatusOnRoute = (truck?: Truck | null): boolean =>
    truck?.status === truckStatuses.TRUCK_STATUS_ON_ROUTE;

export const checkTruckStatusIsInactive = (status?: string): boolean =>
    truckStatuses.TRUCK_INACTIVE_STATUSES.includes(status || '');
