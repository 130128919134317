import {InferActions} from 'store';

import * as types from './actionTypes';
import {SortBy, SortFrom, SortListName} from './types';

export const setSortBy = (params: {sortBy: SortBy; sortListName: SortListName; sortFrom?: SortFrom}) =>
    ({type: types.LIST_SORT_CHANGED, payload: params} as const);

const actionCreators = {
    setSortBy,
};

export type SortActionsTypes = InferActions<typeof actionCreators>;

export default actionCreators;
