import {showAlert} from 'store/actions';
import restApi from 'services/restapi';
import * as appActions from 'store/actions';

import {SendEmailData, SendEmailFormValues} from './types';

const EMAIL_URL = `/email`;

type Payload = SendEmailFormValues & Pick<SendEmailData, 'emailTo'>;

export const sendEmailMessage = (payload: Payload, onClose) => async (dispatch) => {
    const {subject, text, sender, emailTo} = payload;

    const body = {
        to: emailTo,
        subject,
        template: 'messages',
        body: {text},
    };

    try {
        dispatch(appActions.showLoader());

        await restApi.post(`${EMAIL_URL}/${sender}`, body);

        dispatch(showAlert('Email sent successfully!', 'success'));
    } catch {
        dispatch(showAlert('Email sending error!', 'danger'));
    } finally {
        dispatch(appActions.hideLoader());
        onClose();
    }
};
