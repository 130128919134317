import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type OwnProps = {
    text: string;
    color?: 'yellow' | 'red' | 'blue' | 'green';
};

const StatusBadge: React.FC<OwnProps> = ({text, color}) => {
    const getBackgroundClassname = () => {
        if (color === 'yellow') {
            return styles.yellow;
        }

        if (color === 'red') {
            return styles.red;
        }

        if (color === 'blue') {
            return styles.blue;
        }

        if (color === 'green') {
            return styles.green;
        }

        return styles.gray;
    };

    const statusBadgeClassNames = classNames(styles.wrap, getBackgroundClassname());

    return <div className={statusBadgeClassNames}>{text}</div>;
};

export default StatusBadge;
