import * as types from 'pages/TravelOrders/redux/actionTypes/travelOrder';

import TravelOrder from 'core/entities/TravelOrder/types';

type DefaultState = {
    current: TravelOrder | null;
};

const defaultState: DefaultState = {
    current: null,
};

const travelOrder = (state = defaultState, action): DefaultState => {
    switch (action.type) {
        case types.FETCHING_TRAVEL_ORDER_RECEIVED: {
            return {
                ...state,
                current: action.payload,
            };
        }

        case types.TRAVEL_ORDER_SEND_THIRD_API_REQUEST_TOGGLE: {
            return {
                ...state,
                current: state.current
                    ? {...state.current, isNeedToSendThirdApiRequest: action.payload.isNeedToSendThirdApiRequest}
                    : null,
            };
        }

        case types.TRAVEL_ORDER_STATE_CLEARED: {
            return defaultState;
        }

        default:
            return state;
    }
};

export default travelOrder;
