import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button/index';

import styles from './websocketUpdateModal.module.scss';

interface OwnProps {
    data: {
        title: string;
        body: string;
    };
    handlers: {
        onSubmit();
    };
    onClose();
}

const WebsocketUpdateModal = (props: OwnProps): JSX.Element | null => {
    const {
        data: {title, body},
        onClose,
        handlers: {onSubmit},
    } = props;
    const handleOk = (): void => {
        onClose();
        onSubmit();
    };
    return (
        <Modal show={true} dialogClassName="custom-dialogs" backdrop="static">
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.body}>{body}</div>
            </Modal.Body>
            <Modal.Footer>
                <div />
                <Button type="submit" onClick={handleOk} colorTheme="red" className="custom-design ml-1">
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default WebsocketUpdateModal;
