import Load from 'core/entities/Load/types';

import {getRemainingTimeTo} from 'utils/dateTime';

export const checkLoadExpirationDateIsEarlier = (loadStops: Load['stops'], expiration?: string | null): boolean => {
    if (!expiration) {
        return false;
    }

    const timeToExpiration = getRemainingTimeTo(expiration);

    return loadStops?.every(({dateEnd}) => {
        const timeToLoadExpiration = getRemainingTimeTo(dateEnd);

        return timeToLoadExpiration.days < timeToExpiration.days;
    });
};
