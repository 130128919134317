import {isChangeLocationAction} from 'routing/utils/index';

import * as types from '../actionTypes';
import settlementUpdateCommonReducer from './settlementUpdateCommonReducer';
import locationChangeCommonReducer from './locationChangeCommonReducer';
import settlementsOwnersWebsocketReducer from './settlementsOwnersWebsocketReducer';
import {
    getTableData,
    addItemToTable,
    addItemsToTable,
    updateItemInTable,
    updateItemsInTable,
    removeItemsFromTable,
    isSettlementUpdateCommonAction,
    isWebsocketAction,
    isSelectedSearchParams,
    removeItemInTable,
} from './utils';

const defaultState = {
    pagination: {
        payPeriods: {},
    },
    settlements: {byId: {}, allIds: []},
    payPeriods: {byId: {}, allIds: []},
    payRecords: {byId: {}, allIds: []},
    allPayPeriods: [],
};

export default function settlementsOwnersDataReducer(state = defaultState, action, pageState) {
    if (isChangeLocationAction(action)) {
        return locationChangeCommonReducer(state, defaultState, action);
    }

    if (isSettlementUpdateCommonAction(action)) {
        return settlementUpdateCommonReducer(state, action);
    }

    if (isWebsocketAction(action) && !isSelectedSearchParams(pageState)) {
        return settlementsOwnersWebsocketReducer(state, action, pageState);
    }

    switch (action.type) {
        // clear all data on change filter cause for each status list has different data sets
        case types.FILTER_STATUS_CHANGED:
        case types.LIST_SEARCH_PARAMS_CHANGED:
        case types.PERIODS_DATA_CLEARED: {
            return {
                // clear all pay periods data for avoid incorrect data after change list pay periods and list settlements
                // allPayPeriods using for dropdown with all pay periods which can used on any sub page,
                // and we can have case when user creates new pay period and then go to settlements page for add this created pay period to any settlements
                // for this case we should not clear all data about pay periods
                ...state,
                allPayPeriods: state.allPayPeriods,
                payPeriods: defaultState.payPeriods,
                payRecords: defaultState.payRecords,
                settlements: defaultState.settlements,
            };
        }

        case types.LIST_SETTLEMENTS_RECEIVED: {
            const {settlements} = action.payload;
            return {
                ...state,
                settlements: getTableData(settlements),
            };
        }

        case types.LIST_PAY_RECORD_SETTLEMENTS_RECEIVED: {
            const {payRecordID, settlements} = action.payload;
            // only in this case API return list settlements without pay records
            // we should add pay record manual for keep data consistency
            const settlementsWithPayRecord = settlements.map((s) => {
                if (!s.pay_record) {
                    return {...s, pay_record: {id: payRecordID}};
                }
                return s;
            });
            const updatedSettlements =
                state.settlements.allIds.length === 0
                    ? getTableData(settlementsWithPayRecord)
                    : addItemsToTable(state.settlements, settlementsWithPayRecord);
            return {
                ...state,
                settlements: updatedSettlements,
            };
        }

        case types.SETTLEMENT_RECEIVED: {
            const {settlement} = action.payload;
            const settlementPayPeriod = settlement.pay_period || null;
            return {
                ...state,
                settlements: addItemToTable(state.settlements, settlement),
                payPeriods: settlementPayPeriod
                    ? addItemToTable(state.payPeriods, settlementPayPeriod)
                    : state.payPeriods,
            };
        }

        case types.LIST_PAY_PERIODS_RECEIVED: {
            const {payPeriods} = action.payload;
            return {
                ...state,
                payPeriods: getTableData(payPeriods),
            };
        }

        case types.LIST_PAY_RECORDS_RECEIVED: {
            const {payPeriodID, payRecords} = action.payload;
            const payRecordsWithPayPeriod = payRecords.map((p) => ({...p, payPeriodID}));
            const updatedPayRecords =
                state.payRecords.allIds.length === 0
                    ? getTableData(payRecordsWithPayPeriod)
                    : addItemsToTable(state.payRecords, payRecordsWithPayPeriod);
            return {
                ...state,
                payRecords: updatedPayRecords,
            };
        }

        case types.LIST_SEARCHED_PAY_RECORDS_RECEIVED: {
            const {payPeriodID, payRecords} = action.payload;
            if (!payRecords || payRecords.length === 0) {
                return state;
            }
            const payRecordsWithPayPeriod = payRecords.map((p) => ({...p, payPeriodID}));
            return {
                ...state,
                payRecords: addItemsToTable(state.payRecords, payRecordsWithPayPeriod),
            };
        }

        case types.PAY_PERIOD_CREATED: {
            const createdPayPeriod = action.payload;
            const isCreatedPeriodInCurrentState = createdPayPeriod && state.payPeriods.byId[createdPayPeriod.id];

            if (!createdPayPeriod || isCreatedPeriodInCurrentState) {
                return state;
            }

            return {
                ...state,
                payPeriods: addItemToTable(state.payPeriods, createdPayPeriod),
            };
        }

        case types.PAY_PERIOD_POSTED: {
            const postedPayPeriod = action.payload;
            return {
                ...state,
                payPeriods: {...state.payPeriods, [postedPayPeriod.id]: postedPayPeriod},
            };
        }

        case types.PAY_PERIOD_UPDATED: {
            const updatedPayPeriod = action.payload;
            const isUpdatedPayPeriodInCurrentState = updatedPayPeriod?.id in state.payPeriods.byId;

            if (!isUpdatedPayPeriodInCurrentState) {
                return state;
            }

            return {
                ...state,
                payPeriods: updateItemInTable(state.payPeriods, updatedPayPeriod),
            };
        }

        case types.PAY_RECORD_UPDATED: {
            const updatedPayRecord = action.payload;
            const isUpdatedPayRecordInCurrentState = updatedPayRecord?.id in state.payRecords.byId;

            if (!isUpdatedPayRecordInCurrentState) {
                return state;
            }
            const updatedPayRecordWithPayPeriodID = {
                ...updatedPayRecord,
                payPeriodID: updatedPayRecord?.pay_period?.id,
            };
            return {
                ...state,
                payRecords: updateItemInTable(state.payRecords, updatedPayRecordWithPayPeriodID),
            };
        }

        case types.PAY_PERIOD_PAY_RECORDS_CLEARED: {
            const {payPeriodID} = action.payload;
            const currentPayRecords = state.payRecords;
            const payPeriodPayRecords = Object.values(currentPayRecords.byId).filter((payRecord) => {
                return payRecord.payPeriodID === payPeriodID;
            });
            return {
                ...state,
                payRecords: removeItemsFromTable(state.payRecords, payPeriodPayRecords),
            };
        }

        case types.PAY_CORRECTION_ADDED:
        case types.PAY_CORRECTION_REMOVED: {
            const {payRecord} = action.payload;
            const updatedPayRecord = {...payRecord, payPeriodID: payRecord?.pay_period?.id};
            const updatedPayPeriod = updatedPayRecord.pay_period;
            return {
                ...state,
                payRecords: updateItemInTable(state.payRecords, updatedPayRecord),
                payPeriods: updateItemInTable(state.payPeriods, updatedPayPeriod),
            };
        }

        case types.ALL_PAY_PERIODS_RECEIVED: {
            return {
                ...state,
                allPayPeriods: action.payload,
            };
        }

        case types.LIST_SETTLEMENTS_POSTED: {
            const listPostedSettlements = action.payload;
            return {
                ...state,
                settlements: updateItemsInTable(state.settlements, listPostedSettlements),
            };
        }

        case types.SETTLEMENT_UPDATED:
        case types.SETTLEMENT_STATUS_CHANGED: {
            const updatedSettlement = action.payload;
            const isUpdatedSettlementInCurrentState = updatedSettlement?.id in state.settlements.byId;
            if (!updatedSettlement || !isUpdatedSettlementInCurrentState) {
                return state;
            }
            return {
                ...state,
                settlements: updateItemInTable(state.settlements, updatedSettlement),
            };
        }

        case types.REMOVE_SETTLEMENT_FROM_THE_LIST: {
            const {payload: oldSettlement} = action;

            return {...state, settlements: removeItemInTable(state.settlements, oldSettlement)};
        }

        default:
            return state;
    }
}
