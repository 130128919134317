import {useSelector} from 'react-redux';

import {Quote} from 'core/entities/Quote/types';
import {Dispatcher} from 'core/entities/Dispatcher/types';

import {getQuoteByID} from 'pages/LoadBoard/redux/selectors';

import {Notes, NoteItem} from '../components/Notes/types';

const mapNoteForDrivers = (notForDrivers: {text: string; createdAt: string}, quote: Quote): NoteItem => {
    return {
        text: notForDrivers.text,
        createdBy: quote.createdBy,
        createdAt: notForDrivers.createdAt,
        type: 'forDrivers',
    };
};

const mapGeneralNote = (generalNote: {
    id: string;
    text: string;
    createdAt: string;
    createdBy?: Dispatcher | undefined | null;
}): NoteItem => {
    return {
        id: generalNote.id,
        text: generalNote.text,
        createdBy: generalNote.createdBy,
        createdAt: generalNote.createdAt,
        type: 'general',
    };
};

type ExcludesFalse = <T>(x: T | null) => x is T;

const useGetQuoteNotes = (quoteID: string): Notes => {
    const quote = useSelector((state) => getQuoteByID(state as any, quoteID));

    if (!quote) {
        return {
            allNotes: [],
            generalNotes: [],
            noteForDrivers: null,
        };
    }

    const {notes} = quote;
    const generalNotes = notes?.general;
    const mappedGeneralNotes = generalNotes ? generalNotes.map(mapGeneralNote).reverse() : [];
    const notForAllQuoteDrivers = notes?.forDrivers
        ? mapNoteForDrivers({text: notes?.forDrivers, createdAt: quote.createdAt}, quote)
        : null;
    return {
        allNotes: [...mappedGeneralNotes, notForAllQuoteDrivers].filter((Boolean as any) as ExcludesFalse),
        generalNotes: mappedGeneralNotes,
        noteForDrivers: notForAllQuoteDrivers || null,
    };
};

export default useGetQuoteNotes;
