import SettlementSearchParams from 'pages/Settlements/types/SettlementSearchParams';

const getNewSearchParamsByMainFields = (
    searchParams: SettlementSearchParams | undefined,
): SettlementSearchParams | undefined => {
    if (!searchParams || (!searchParams.settlement && !searchParams.invoice)) {
        return searchParams;
    }

    if (searchParams.settlement) {
        return {
            settlement: searchParams.settlement,
        };
    }

    if (searchParams.invoice) {
        return {
            invoice: searchParams.invoice,
        };
    }
};

export default getNewSearchParamsByMainFields;
