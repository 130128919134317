import React from 'react';
import capitalize from 'lodash/capitalize';

import {getEventTitle} from 'core/entities/LocationEvents/modules/eventTitle';
import {LocationEventsTypes} from 'core/entities/LocationEvents/constants';

type OwnProps = {
    name: LocationEventsTypes;
    isCapitalized?: boolean;
};

const EventName: React.FC<OwnProps> = ({name, isCapitalized = false}) => {
    const title = getEventTitle(name);
    const formatted = isCapitalized ? capitalize(title) : title;

    return <strong>{formatted}</strong>;
};

export default EventName;
