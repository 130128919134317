import React from 'react';
import classNames from 'classnames';

import TooltipCustom from 'components/ui/Form/TooltipCustom';
import getValidationState from '../getValidationState';

import styles from './radioSwitch.module.scss';

interface RadioItem {
    label: string;
    value: string;
}

interface OwnProps {
    meta: any;
    items: RadioItem[];
    input: {value: string; onChange};
    disabled: boolean;
    label?: string;
}

const RadioSwitch = ({items, meta, input, disabled, label}: OwnProps): JSX.Element | null => {
    const isValidationSuccess = getValidationState(meta);
    const getHandler = (fieldValue) => () => {
        if (disabled) {
            return;
        }
        input.onChange(fieldValue);
    };
    const radioElements = items.map((item, index) => {
        const radioSwitcherClassNames = classNames(
            styles.item,
            item.value === input.value && styles.checked,
            !isValidationSuccess && styles.error,
        );
        return (
            <div key={index} onClick={getHandler(item.value)} className={radioSwitcherClassNames}>
                {item.label}
            </div>
        );
    });

    return (
        <div className={styles.wrap}>
            <div className={styles.label}>{label}</div>
            <div className={styles.elements}>
                {radioElements}{' '}
                {!isValidationSuccess ? (
                    <TooltipCustom placement="bottom">
                        <span>{meta.error}</span>
                    </TooltipCustom>
                ) : null}
            </div>
        </div>
    );
};

export default RadioSwitch;
