import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface OwnProps {
    checked: boolean;
    symbol?: 'check' | 'minus';
    onSelect();
}

const SelectCheckbox = (props: OwnProps): JSX.Element => {
    const {checked, symbol = 'check', onSelect} = props;
    const classNameCheckedSymbol = classNames(
        styles.checkbox__checked,
        symbol === 'check' ? styles.checkbox__checked_symbol_check : styles.checkbox__checked_symbol_minus,
    );
    const fullClassName = classNames(styles.checkbox, checked && classNameCheckedSymbol);
    return <div onClick={onSelect} className={fullClassName} />;
};

export default SelectCheckbox;
