import {FactoringCompany} from 'core/entities/FactoringCompany/types';

import GeneralNote from 'types/GeneralNote';

export const transferNewNotesToOldInterface = (params: {company: FactoringCompany | null}): GeneralNote[] => {
    const {company} = params;

    if (!company?.id && !company?.notes) {
        return [];
    }

    const transferredNotes = (company?.notes || []).map((note) => ({
        dispatcher: note.createdByDispatcher,
        is_important: note.isImportant,
        post_date: note.createdAt,
        entity_id: company.id,
        text: note.note,
        entity_name: '',
        id: note.id,
    }));

    return transferredNotes;
};
