import isEmpty from 'lodash/isEmpty';
import React, {useEffect, useState} from 'react';
import {WrappedFieldInputProps} from 'redux-form';
import {WrappedFieldProps} from 'redux-form/lib/Field';

import TextInputWithDatePicker from 'components/ui/Form/InputWithDatePicker';
import TimeInput from 'components/ui/Form/TimeInput';

import {getCurrentDate, getNowWithCurrentTimeZone} from 'utils/dateTime';

import {getDateAndTimeFromFullDate, getUpdatedFieldValue, isInvalidDateTime} from './utils';

interface OwnProps extends WrappedFieldProps {
    dateLabel: string;
    datePlaceholder: string;
    timeLabel: string;
    disabled: boolean;
}

const DateTimeField: React.FC<OwnProps> = ({dateLabel, timeLabel, datePlaceholder, disabled, ...field}) => {
    const {input, meta} = field;
    const {value, onChange} = input;
    const [date, setDate] = useState<string>();
    const [time, setTime] = useState<string>();
    const visibleDateIntervalFrom = getNowWithCurrentTimeZone().minus({years: 1});
    const visibleDateIntervalTo = getNowWithCurrentTimeZone().plus({years: 1});

    const handleBlur = (): void => {
        if (value === '' || value === null || value === undefined) {
            return;
        }
        const currentInputValue = value;
        if (currentInputValue) {
            input.onBlur(currentInputValue);
        }
    };

    const handleChangeTime = (inputTime): void => {
        if (isInvalidDateTime(inputTime)) {
            const defaultStartTime = '00:00';
            setTime(defaultStartTime);
            return;
        }

        const {originalDate} = getCurrentDate();

        setTime(inputTime);

        if (!date) {
            setDate(originalDate);
        }
    };
    const handleChangeDate = (inputDate): void => {
        if (isEmpty(inputDate)) {
            return;
        }

        setDate(inputDate);
    };
    const dateInputProps: WrappedFieldInputProps = {
        value: date,
        onBlur: handleBlur,
        onChange: handleChangeDate,
        onDragStart: () => {},
        onDrop: () => {},
        onFocus: () => {},
        name: '',
    };

    const timeInputProps: WrappedFieldInputProps = {
        value: time,
        name: '',
        onBlur: handleBlur,
        onChange: handleChangeTime,
        onDragStart: () => {},
        onDrop: () => {},
        onFocus: () => {},
    };

    useEffect(() => {
        const updatedDate = getUpdatedFieldValue({time, date});
        onChange(updatedDate);
    }, [date, time]);
    useEffect(() => {
        if (isEmpty(input.value)) {
            setDate(input.value);
            setTime(input.value);
        }
    }, [input.value]);

    useEffect(() => {
        const {time: initialTime, date: initialDate} = getDateAndTimeFromFullDate(value);

        setDate(initialDate);
        setTime(initialTime);
    }, []);
    return (
        <div className="d-flex flex-row">
            <div className="w-50">
                <TextInputWithDatePicker
                    input={dateInputProps}
                    meta={meta}
                    type="text"
                    label={dateLabel}
                    placeholder={datePlaceholder}
                    minDate={visibleDateIntervalFrom}
                    maxDate={visibleDateIntervalTo}
                    showSideButtons
                    disabled={disabled}
                />
            </div>
            <div className="pl-1 w-50">
                <TimeInput input={timeInputProps} meta={meta} label={timeLabel} disabled={disabled} />
            </div>
        </div>
    );
};

export default DateTimeField;
