import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button';

const ArchivingWarnWrapper: React.FC<{
    title?: string;
    children;
    onClose();
    onCancel();
    onConfirm();
}> = (props) => {
    const {title = '', children: warningContent, onClose, onCancel, onConfirm} = props;
    const handleCancelArchive = () => {
        onCancel();
        onClose();
    };
    return (
        <Modal show={true} onHide={onClose} className="info-modal" backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    Move to archive {title && <strong className="line_break_any">{title}</strong>}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row align-items-center">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-error" />
                    </div>
                    <div className="col-11">
                        <div>{warningContent}</div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    onClick={handleCancelArchive}
                    className="mr10"
                    colorTheme="grey"
                    buttonSize="small"
                    defaultFocus={true}
                >
                    KEEP
                </Button>
                <Button onClick={onConfirm} className="mr10" colorTheme="red" buttonSize="small" defaultFocus={true}>
                    ARCHIVE
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ArchivingWarnWrapper;
