import {statuses} from 'core/entities/Settlement/constants';
import {isChangeLocationAction} from 'routing/utils';

import * as types from '../actionTypes';

import locationChangeCommonReducer from './locationChangeCommonReducer';

const defaultState = {
    searchParams: {},
    groupStatus: statuses.RATED,
    expandedRowID: null,
    viewAllSearchedItems: false,
    pagination: {
        currentPage: 1,
        totalItemsCount: 0,
        perPage: 50,
    },
};

export default function listReducer(state = defaultState, action) {
    if (isChangeLocationAction(action)) {
        return locationChangeCommonReducer(state, defaultState, action);
    }

    switch (action.type) {
        case types.LIST_SETTLEMENTS_RECEIVED:
        case types.LIST_BATCHES_RECEIVED:
        case types.LIST_PAY_PERIODS_RECEIVED: {
            const {pagination} = action.payload;
            return {
                ...state,
                pagination,
            };
        }

        case types.FILTER_STATUS_CHANGED: {
            const {groupStatus} = action.payload;
            return {
                ...state,
                groupStatus,
                expandedRowID: state.groupStatus === statuses.READY_TO_POST ? state.expandedRowID : null,
                // reset pagination page on change on select new status
                pagination: {
                    currentPage: 1,
                    totalItemsCount: 0,
                    perPage: 50,
                },
            };
        }

        case types.LIST_SET_EXPANDED_ROW: {
            return {
                ...state,
                expandedRowID: action.payload,
            };
        }

        case types.LIST_SEARCH_PARAMS_CHANGED: {
            return {
                ...state,
                expandedRowID: null,
                searchParams: action.payload,
                viewAllSearchedItems: false,
            };
        }

        case types.LIST_PAGINATION_CURRENT_PAGE_CHANGED: {
            return {
                ...state,
                pagination: {...state.pagination, currentPage: action.payload},
            };
        }

        case types.LIST_PAGINATION_PER_PAGE_CHANGED: {
            return {
                ...state,
                pagination: {...state.pagination, perPage: action.payload},
            };
        }

        case types.LIST_SEARCH_VIEW_ALL_SEARCHED_ITEMS: {
            return {
                ...state,
                viewAllSearchedItems: true,
            };
        }

        default:
            return state;
    }
}
