export const TIME_INTERVALS = 15;
export const YEAR_DROPDOWN_NUMBER = 75;

export const DATE_MASKS = {
    // for Date part
    dd: [/[0-3]/, /[0-9]/],
    MM: [/[0-1]/, /[0-9]/],
    yyyy: [/[1-2]/, /[09]/, /\d/, /\d/],
    yy: [/\d/, /\d/],

    // for Time part
    HH: [/[0-2]/, /[0-9]/],
    mm: [/[0-5]/, /[0-9]/],
} as const;
