import React from 'react';

import {Quote} from 'core/entities/Quote/types';
import {getQuoteDims, getQuoteDimsUnit} from 'core/entities/Quote/modules/quoteFreights';

import DimensionView from './components/DimensionView';

const QuoteLoadDimensions: React.FC<{quote: Quote}> = (props) => {
    const {quote} = props;
    const dimensions = getQuoteDims(quote);
    const unit = getQuoteDimsUnit(quote);

    const dims = dimensions.map(({width, length, height}, index) => (
        <DimensionView key={index} units={unit} length={length} width={width} height={height} />
    ));

    if (dims.length === 0) {
        return null;
    }

    return <div>{dims}</div>;
};

export default QuoteLoadDimensions;
