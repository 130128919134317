import head from 'lodash/head';
import last from 'lodash/last';
import keyBy from 'lodash/keyBy';
import sumBy from 'lodash/sumBy';

import Load, {PickupStop, DeliveryStop} from 'core/entities/Load/types';
import composeFacilityCityLine from 'core/entities/Facility/composeFacilityCityLine';

import {isPickupStop, isDeliveryStop} from './index';

type Counters = {numberByOrder: number; numberByType: number};
type TotalFreights = {pieces: number; weight: number; isHazmat: boolean; isStackable: boolean; unit: string};
type ExtendedPickupStop = PickupStop & Counters & {totalFreights: TotalFreights};
type ExtendedDeliveryStop = DeliveryStop & Counters;
export type ExtendedLoadStop = ExtendedPickupStop | ExtendedDeliveryStop;
export type StopsInfo = {
    loadNumber: number;
    allStops: (ExtendedPickupStop | ExtendedDeliveryStop)[];
    allPickupStops: ExtendedPickupStop[];
    allDeliveryStops: ExtendedDeliveryStop[];
    firstPickupStop: ExtendedPickupStop;
    lastDeliveryStop: ExtendedDeliveryStop;
    firstPickupStopAddress: string;
    lastDeliveryStopAddress: string;
    allStopsLength: number;
};

const getStopsCountersMap = (
    allPickupStops: PickupStop[],
    allDeliveryStops: DeliveryStop[],
): Record<string, {id: number; orderByType: number}> => {
    const getMapWithNumbers = (listItems) => listItems.map((item, index) => ({id: item.id, orderByType: index + 1}));
    return keyBy([...getMapWithNumbers(allPickupStops), ...getMapWithNumbers(allDeliveryStops)], 'id');
};

const getStopAddress = (stop: PickupStop | DeliveryStop | undefined): string => {
    if (!stop) {
        return '';
    }
    const {facility} = stop;
    return facility ? composeFacilityCityLine(facility) : '';
};

const getTotalFreights = (stop: PickupStop): TotalFreights => {
    const {freights} = stop;
    const getPiecesSum = (items: PickupStop['freights']): number => sumBy(items, (item) => Number(item.pieces) || 0);
    const getWeightSum = (items: PickupStop['freights']): number =>
        sumBy(items, (item) => Number(item.weight.amount) || 0);
    const getUnit = (items: PickupStop['freights']): string => {
        const lastItem = last(items);
        return lastItem?.weight?.unit || '';
    };
    return {
        pieces: getPiecesSum(freights),
        weight: getWeightSum(freights),
        unit: getUnit(freights),
        isHazmat: freights.some((freight) => Boolean(freight?.isHazmat)),
        isStackable: freights.some((freight) => Boolean(freight?.isStackable)),
    };
};

const getExtendedStopData = (
    stop: PickupStop | DeliveryStop,
    additionalStopData: Record<string, {orderByType: number}>,
): Omit<ExtendedPickupStop, 'totalFreights'> | ExtendedDeliveryStop => {
    const additionalData = additionalStopData[stop?.id];
    return {
        ...stop,
        numberByOrder: stop?.orderNumber,
        numberByType: additionalData?.orderByType,
    };
};

export const getStopsInfo = (load: Load): StopsInfo => {
    const allPickupStops = load.stops.filter(isPickupStop);
    const allDeliveryStops = load.stops.filter(isDeliveryStop);
    const countersMap = getStopsCountersMap(allPickupStops, allDeliveryStops);
    const allStops = load.stops.map((s) => {
        const extendedData = getExtendedStopData(s, countersMap);
        if (isPickupStop(extendedData)) {
            return {...extendedData, totalFreights: getTotalFreights(extendedData)};
        }
        return extendedData;
    });
    const firstPickUp = head(allStops);
    const lastDelivery = last(allStops);
    return {
        loadNumber: load.number,
        allStops,
        allPickupStops: allPickupStops.map((s) => getExtendedStopData(s, countersMap)) as ExtendedPickupStop[],
        allDeliveryStops: allDeliveryStops.map((s) => getExtendedStopData(s, countersMap)) as ExtendedDeliveryStop[],
        firstPickupStop: getExtendedStopData(firstPickUp as PickupStop, countersMap) as ExtendedPickupStop,
        lastDeliveryStop: getExtendedStopData(lastDelivery as DeliveryStop, countersMap) as ExtendedDeliveryStop,
        firstPickupStopAddress: getStopAddress(firstPickUp),
        lastDeliveryStopAddress: getStopAddress(lastDelivery),
        allStopsLength: load.stops.length,
    };
};
