import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Modal, Row, Col} from 'react-bootstrap';

import {getTruckNumber} from 'core/entities/Truck/modules/common/getters/getTruckNumber';
import {getEventBrokerUpdate} from 'core/entities/LocationEvents/modules/brokerUpdate';
import {LocationEventData} from 'core/entities/LocationEvents/types';
import TravelOrder from 'core/entities/TravelOrder/types';

import Button from 'components/ui/Buttons/Button';

import {getUpdatedValues} from './utils';

import styles from './styles.module.scss';

type OwnProps = {
    data: {
        event: LocationEventData;
        travelOrder: TravelOrder;
    };
    onClose(): void;
};

const ViewBrokerUpdateModal: React.FC<OwnProps> = ({data, onClose}) => {
    const {event, travelOrder} = data;

    const brokerUpdate = getEventBrokerUpdate(event);

    const {message = '', subject, from, to} = getUpdatedValues(brokerUpdate);

    const truckNumber = getTruckNumber(travelOrder?.truck);

    return (
        <Modal
            show={true}
            onHide={onClose}
            dialogClassName="custom-modal standard-form-modal"
            backdrop="static"
            size="sm"
        >
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Location Event Info: {truckNumber ? `Truck #${truckNumber}` : null}</Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.content}>
                    <div className={styles.emails}>
                        <Row>
                            <Col md={1}>
                                <strong>From:</strong>
                            </Col>
                            <Col>{from}</Col>
                        </Row>
                        <Row>
                            <Col md={1}>
                                <strong>To:</strong>
                            </Col>
                            <Col>{to}</Col>
                        </Row>
                    </div>
                    <div className={styles.sendData}>
                        <div className={styles.message}>
                            <div className={styles.subject}>
                                <strong>{subject}</strong>
                            </div>
                            <div className={styles.text}>{message}</div>
                        </div>
                        <CopyToClipboard text={message}>
                            <Button colorTheme="white" buttonIcon="files-o" buttonSize="icon" />
                        </CopyToClipboard>
                    </div>
                </div>
                <div className="modal-body__bottom">
                    <div />
                    <Button onClick={onClose}>Cancel</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ViewBrokerUpdateModal;
