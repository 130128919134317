import Truck from 'core/entities/Truck/types';

export const getTruckCoordinatorIsResponsible = (truck?: Truck | null): boolean => {
    if (!truck) {
        return false;
    }

    const {mainContact} = truck;

    if (!mainContact) {
        return false;
    }

    return mainContact.type === 'crd';
};
