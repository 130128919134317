import React from 'react';
import {Modal} from 'react-bootstrap';
import Button from 'components/ui/Buttons/Button';

export default class Alert extends React.PureComponent {
    render() {
        const {data: {title, onOkText, alertType} = {}, onClose} = this.props;

        let cls;
        switch (alertType) {
            case 'success':
                cls = 'modal_success';
                break;
            case 'danger':
                cls = 'modal_danger';
                break;
            case 'warning':
                cls = 'modal_warning';
                break;
            default:
                cls = '';
        }

        return (
            <Modal show={true} backdrop={false}>
                <Modal.Header>
                    <Modal.Title>
                        <div className={`static_modal ${cls}`}>{title}</div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <div />
                    <Button
                        defaultFocus={true}
                        colorTheme="blue"
                        buttonSize="small"
                        onClick={onClose}
                        className="button-lg-last"
                    >
                        {onOkText || 'Ok'}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
