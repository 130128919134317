import isNumber from 'lodash/isNumber';

import {UNKNOWN_CITY, UNKNOWN_COUNTRY, UNKNOWN_STATE, UNKNOWN_ZIP_CODE} from './constants/cityLine';
import getCountryByName from './getCountryByName';

type CityKeys = 'city' | 'state' | 'zip' | 'country';
type CityLine = {[key in CityKeys]: string | null | undefined};

const getStateAndZip = (stateAndZip: string | undefined | null): string[] => {
    if (!stateAndZip) {
        return [];
    }

    if (stateAndZip.includes(UNKNOWN_ZIP_CODE)) {
        return [stateAndZip.split(UNKNOWN_ZIP_CODE)[0], UNKNOWN_ZIP_CODE];
    }

    const stateAndZipArray = stateAndZip.split(' ');

    return [
        stateAndZipArray.filter((_, i) => i < stateAndZipArray.length - 1).join(' '),
        stateAndZipArray[stateAndZipArray.length - 1],
    ];
};

const transformCityLine = (city_line: CityLine): string => {
    const {city, state, zip, country} = city_line;

    if (!city && !state && !zip && !country) {
        return '';
    }

    const formattedCity = city || UNKNOWN_CITY;
    const formattedState = state || UNKNOWN_STATE;
    const formattedZip = zip || UNKNOWN_ZIP_CODE;

    const trimmedCountry = country && country.trim();
    const formattedCountry = trimmedCountry
        ? getCountryByName(trimmedCountry)?.code || trimmedCountry.toUpperCase()
        : UNKNOWN_COUNTRY;

    return [formattedCity, formattedState, formattedZip, formattedCountry]
        .map((item) => {
            return isNumber(item) ? String(item).trim() : item.trim();
        })
        .join(', ');
};

export const getCityLineFromString = (city_line: string | null | undefined): string => {
    if (!city_line?.trim()) {
        return '';
    }

    const [city, state, zip, country] = city_line.split(',').map((item) => item.trim());

    if (!country) {
        const [newState, newZip] = getStateAndZip(state);
        return transformCityLine({city, state: newState, zip: newZip, country: zip});
    }

    return transformCityLine({city, state, zip, country});
};

export const getCityLineFromObject = (city_line: CityLine): string => (city_line ? transformCityLine(city_line) : '');
