import {countBy, filter, flatMap, keys, map, words} from 'lodash';

import Customer from 'core/entities/Customer/types';

const findCoincidenceWords = (params: {customersToMerge: Customer[] | null; prop: keyof Customer}) => {
    const {customersToMerge, prop} = params;

    const propertyValues = map(customersToMerge, prop);
    const wordsPropertyValues = flatMap(propertyValues, (value: string) => words(value));
    const countWords = countBy(wordsPropertyValues);

    const coincidences = filter(keys(countWords), (key) => countWords[key] > 1);

    return coincidences;
};

export const getMatchingCustomersWords = (customersToMerge: Customer[] | null) => {
    const matchedCustomersBillingAddressLine = findCoincidenceWords({customersToMerge, prop: 'billing_address_line'});
    const matchedCustomersName = findCoincidenceWords({customersToMerge, prop: 'name'});
    const matchedCustomersBillingAdditionalAddressLine = findCoincidenceWords({
        customersToMerge,
        prop: 'billing_additional_address_line',
    });
    const matchedCustomersCityLine = [
        ...findCoincidenceWords({customersToMerge, prop: 'billing_country'}),
        ...findCoincidenceWords({customersToMerge, prop: 'billing_state'}),
        ...findCoincidenceWords({customersToMerge, prop: 'billing_city'}),
        ...findCoincidenceWords({customersToMerge, prop: 'billing_zip'}),
    ];

    return {
        matchedCustomersBillingAdditionalAddressLine,
        matchedCustomersBillingAddressLine,
        matchedCustomersCityLine,
        matchedCustomersName,
    };
};
