import React from 'react';

import CopyToClipBoardWrapper from 'components/ui/CopyToClipBoardWrapper';
import CopyToClipboard from 'components/ui/CopyToClipboard';
import {TruckLink} from 'components/ui/Links';

import Truck from 'core/entities/Truck/types';

import {TooltipWrapper, TooltipWrapperWithCopyToClipBoard} from './wrappers';
import {getNumberText} from './utils';

type TruckNumberAsLinkOwnProps = {
    truck: Truck;
    prefix?: string;
    linkClass?: string;
    typeTooltipContent?: 'link' | 'button';
};

export const TruckNumberAsNumber: React.FC<{truck: Truck; prefix?: string}> = ({truck, prefix = ''}) => (
    <TooltipWrapper truck={truck}>{getNumberText(truck, prefix)}</TooltipWrapper>
);

export const TruckNumberAsLink: React.FC<TruckNumberAsLinkOwnProps> = (props) => {
    const {truck, linkClass = 'second-link', prefix = '', typeTooltipContent = 'link'} = props;

    const numberText = getNumberText(truck, prefix);

    return (
        <TooltipWrapper truck={truck} typeTooltipContent={typeTooltipContent}>
            <TruckLink truck={truck} content={numberText} className={linkClass} />
        </TooltipWrapper>
    );
};

export const TruckNumberWithCopyToClipBoard: React.FC<TruckNumberAsLinkOwnProps> = (props) => {
    const {truck, linkClass = 'second-link', prefix = ''} = props;

    return (
        <TooltipWrapperWithCopyToClipBoard truck={truck}>
            <CopyToClipBoardWrapper dataForCopy={truck.number}>
                <TruckLink content={getNumberText(truck, prefix)} className={linkClass} truck={truck} />
            </CopyToClipBoardWrapper>
        </TooltipWrapperWithCopyToClipBoard>
    );
};

export const CopyableTruckNumberLink: React.FC<TruckNumberAsLinkOwnProps> = (props) => {
    const {truck, linkClass = 'second-link', prefix = ''} = props;

    return (
        <CopyToClipboard textToCopy={truck.number}>
            <TruckLink content={getNumberText(truck, prefix)} className={linkClass} truck={truck} />
        </CopyToClipboard>
    );
};
