import React, {useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {getCurrentUserID} from 'store/reducers/userData/selectors';
import {AppState} from 'store';

import {getTruckTeam} from 'core/entities/Truck/modules/truckTeam';
import {getDriverName} from 'core/entities/Driver/modules/driverName';

import * as wsActions from 'widgets/Chat/redux/actions/wsActions';
import DriverAvatar from 'widgets/Chat/components/DriverAvatar';
import ListMessages from 'widgets/Chat/components/ListMessages';
import MessageForm from 'widgets/Chat/components/MessageForm';
import ChatAlert from 'widgets/Chat/components/ChatAlert';
import * as selectors from 'widgets/Chat/redux/selectors';
import * as actions from 'widgets/Chat/redux/actions';

import {DriverLink, TruckLink} from 'components/ui/Links';
import Button from 'components/ui/Buttons/Button';
import Tooltip from 'components/ui/Tooltip';

import {phoneNumberWithBraces} from 'utils';

import {DriverChannel, DriverTruckData} from '../../types';
import CurrentTravelOrder from './CurrentTravelOrder';
import styles from './driverChannelExpanded.module.scss';

interface OwnProps {
    onLeaveChannel();
    onSendMessage(newMessage);
}

interface StateProps {
    channel: DriverChannel;
    currentDispatcherID: number;
    driverTruckData: DriverTruckData | null;
}

interface DispatchProps {
    fetchTruckData(driverID: number);
    fetchChannelMoreMessages(channel: DriverChannel);
    fetchChannelAllMessages(channel: DriverChannel);
    markAsReadByEveryDispatcher(driverID: number): void;
}

const DriverChannelExpanded = ({
    channel,
    currentDispatcherID,
    driverTruckData,
    fetchTruckData,
    fetchChannelMoreMessages,
    fetchChannelAllMessages,
    onLeaveChannel,
    onSendMessage,
    markAsReadByEveryDispatcher,
}: OwnProps & StateProps & DispatchProps): JSX.Element => {
    const truckTeam = getTruckTeam(driverTruckData);
    const driverTruck = channel.driver.truck && channel.driver.truck;
    const driverTitle = `${channel.driver.is_owner ? '(o/d)' : '(1dr)'} ${getDriverName(channel.driver)}`;
    const fetchMoreMessages = useCallback(() => {
        fetchChannelMoreMessages(channel);
    }, [channel]);
    const onMarkAsReadForAllDispatchers = useCallback(() => {
        markAsReadByEveryDispatcher(channel.driver.id);
    }, [channel]);
    const onFetchAllMessages = useCallback(() => {
        fetchChannelAllMessages(channel);
    }, [channel]);
    useEffect(() => {
        fetchTruckData(channel.driver.id);
    }, []);

    return (
        <div className={styles.room}>
            <div className="back-to-list" onClick={onLeaveChannel}>
                <i className="fa fa-arrow-left" aria-hidden="true" /> Back to the list
            </div>

            <ChatAlert />

            <div className="top-driver-info">
                <div className="top-driver-info__left">
                    <DriverAvatar driver={channel.driver} avatar={channel.driverAvatar} />

                    <div className="top-driver-info__text">
                        <div className="top-driver-info__text_title ellips-text">
                            <DriverLink content={driverTitle} driver={channel.driver} className="second-link" />
                        </div>
                        <div
                            className={classNames('top-driver-info__text_phone', {main: truckTeam.isDriverResponsible})}
                        >
                            {phoneNumberWithBraces(channel.driver.mobile_phone)}
                        </div>

                        <div className="top-driver-info__text_truck-to">
                            {driverTruck && (
                                <>
                                    Truck{' '}
                                    <TruckLink
                                        truck={driverTruck}
                                        content={`#${driverTruck.number}`}
                                        className="second-link"
                                    />
                                </>
                            )}
                            {driverTruckData && <CurrentTravelOrder truckData={driverTruckData} />}
                        </div>
                    </div>
                    <div className="action-btns">
                        <Tooltip tooltipContent="mark as read for all users" position="right" typeContent="button">
                            <Button buttonIcon="check" buttonSize="icon" onClick={onMarkAsReadForAllDispatchers} />
                        </Tooltip>
                        <Tooltip
                            tooltipContent="load all messages, use CTRL + F to search"
                            position="right"
                            typeContent="button"
                        >
                            <Button buttonIcon="refresh" buttonSize="icon" onClick={onFetchAllMessages} />
                        </Tooltip>
                    </div>
                </div>
                <div className="top-driver-info__right">
                    {truckTeam.isDriverResponsible && <span className="badge main">main</span>}
                    <span className="badge">{channel.driver.language}</span>
                </div>
            </div>

            <ListMessages
                messages={channel.messages}
                currentDispatcherID={currentDispatcherID}
                onFetchMoreMessages={fetchMoreMessages}
            />

            <div className="send-message">
                <MessageForm onSubmit={onSendMessage} />
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    currentDispatcherID: getCurrentUserID(state),
    channel: selectors.getSelectedChannel(state),
    driverTruckData: selectors.getSelectedChannelTruckData(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchTruckData: (driverID) => dispatch(actions.fetchDataForDriverChannel(driverID)),
    fetchChannelMoreMessages: (channel) => dispatch(actions.fetchChannelMoreMessages(channel)),
    fetchChannelAllMessages: (channel) => dispatch(actions.fetchChannelAllMessages(channel)),
    markAsReadByEveryDispatcher: (data) => dispatch(wsActions.markAsReadByEveryDispatcher(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriverChannelExpanded);
