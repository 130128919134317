import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import * as appActions from 'store/actions';

import {isSettlementCarrier} from 'core/entities/Settlement';
import {statuses as settlementsStatuses} from 'core/entities/Settlement/constants';
import Invoice from 'core/entities/Invoice/types';
import {statuses} from 'core/entities/Invoice/constants';
import {getInvoiceCurrentPDF, getInvoiceTravelOrder, isInvoiceInBatch} from 'core/entities/Invoice/invoice';
import {
    fetchInvoice,
    fetchInvoiceBatches,
    fetchInvoices as fetchInvoicesRequest,
    generateInvoicePDF,
    resetInvoiceStatus as resetInvoiceStatusReq,
    updateInvoiceData,
    setNextInvoiceStatus,
    addNoteToInvoice as addNoteToInvoiceRequest,
    changeInvoiceDueDate as changeInvoiceDueDateRequest,
} from 'core/entities/Invoice/requests';
import {setReadyToPostSettlementCarrierStatus} from 'core/entities/Settlement/requests/settlementCarrierRequests';
import {setNextSettlementOwnerStatus} from 'core/entities/Settlement/requests/settlementOwnerRequests';
import {createCustomer} from 'core/gateways/CustomerApiGateway/requests';

import {getRefNumber} from 'deprecated/core/entities/Load/refNumber';
import {FILE_TYPES} from 'deprecated/core/entities/TravelOrder/constants/travel-order';
import {getPickUpDeliveryProgressSteps} from 'deprecated/core/entities/TravelOrder/travelOrder';
import {updateTravelOrderFiles} from 'deprecated/core/entities/TravelOrder/travelOrderFiles';

import {openChatChannelWithFilledMessages} from 'widgets/Chat/redux/actions';

import openNewWindow from 'utils/openNewWindow';
import getFilePath from 'utils/getFilePath';
import {convertToLbs} from 'utils/weightConverter';
import {createDate} from 'utils/dateTime';
import formatPaginationParams from 'utils/formatPaginationParams';
import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import {PostFilesProps} from '../types';
import * as selectors from '../selectors/index';
import * as invoiceActionTypes from '../actionTypes/index';
import {resetBatchInvoices} from './batchActions';
import {showSuccessfulCreateCustomerModal, openChangeStatusFailedModal} from './modalActions';
import fetchInvoiceData from './fetchInvoiceData';
import {handleChangeInvoiceStatusError, handleInvoiceGenerateError} from './errorHandlers';
import {transformSearchParamsForRequest, getMessageDataForDriverWithoutPOD} from './utils';

const convertDateToUtcTime = ({date, time, timezone: timeZoneFrom}) => {
    const originTime = createDate(`${date} ${time}`, {fromTimeZone: timeZoneFrom || 'utc', toTimeZone: 'utc'});

    return originTime.fullOriginalDateTime;
};

const getUpdateTravelOrderLoadAction = (travelOrder) => {
    return {
        type: invoiceActionTypes.INVOICE_LOAD_TRAVEL_ORDER_DATA_UPDATED,
        payload: travelOrder,
    };
};

const getUpdateLoadCustomerAction = (invoice, customer) => {
    return {
        type: invoiceActionTypes.INVOICE_LOAD_CUSTOMER_UPDATED,
        payload: {invoiceID: invoice.id, customer},
    };
};

const getUpdateDueDateAction = (invoiceID, dueDate) => {
    return {
        type: invoiceActionTypes.INVOICE_DUE_DATE_UPDATED,
        payload: {invoiceID, dueDate},
    };
};

const getUpdateLoadRefNumberAction = (invoice, refNumber) => {
    return {
        type: invoiceActionTypes.INVOICE_LOAD_REF_NUMBER_UPDATED,
        payload: {invoiceID: invoice.id, refNumber},
    };
};

const getUpdateInvoiceLoadAction = (invoice, load) => {
    return {
        type: invoiceActionTypes.INVOICE_LOAD_UPDATED,
        payload: {invoiceID: invoice.id, load},
    };
};

const getUpdateInvoiceTravelOrderAction = (invoice, travelOrder) => {
    return {
        type: invoiceActionTypes.INVOICE_TRAVEL_ORDER_UPDATED,
        payload: {invoiceID: invoice.id, travelOrder},
    };
};

const getUpdateInvoiceLoadAndTravelOrderAction = (invoice, loadWithTravelOrder) => {
    return {
        type: invoiceActionTypes.INVOICE_LOAD_AND_TRAVEL_ORDER_UPDATED,
        payload: {invoiceID: invoice.id, loadWithTravelOrder},
    };
};

const getUpdateLoadTotalRateAction = (invoice, totalRate) => {
    return {
        type: invoiceActionTypes.INVOICE_LOAD_TOTAL_RATE_UPDATED,
        payload: {invoiceID: invoice.id, totalRate},
    };
};

export function fetchInvoices(params) {
    const {searchParams, currentSortFrom, currentSortBy, pagination} = params;
    return function (dispatch) {
        dispatch(appActions.showLoader());

        const sortingParams = {sortBy: currentSortBy ? {[currentSortBy]: currentSortFrom} : {}};
        const formattedSearchParams = transformSearchParamsForRequest(searchParams);
        const fetchParams = {...formattedSearchParams, ...sortingParams, ...formatPaginationParams(pagination)};

        fetchInvoicesRequest(fetchParams)
            .then(({data: invoices, headers}) => {
                dispatch({
                    type: invoiceActionTypes.LIST_INVOICES_RECEIVED,
                    payload: {invoices, pagination: parsePaginationHeaders(headers)},
                });
            })
            .catch((error) => dispatch(appActions.handleError(error)))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export const resetInvoiceStatus = (invoice) => {
    return function (dispatch) {
        const resetInvoiceInBatch = () => {
            const resetFromBatchRequest = dispatch(resetBatchInvoices(invoice.batch_number, [invoice]));
            // cause resetBatchInvoice returns batch data so we should re fetch invoice for correct update in state
            return resetFromBatchRequest.then(() => fetchInvoice(invoice.invoice_number));
        };
        const resetGeneralInvoice = () => {
            return resetInvoiceStatusReq(invoice);
        };
        const invoiceResetRequest = isInvoiceInBatch(invoice) ? resetInvoiceInBatch : resetGeneralInvoice;

        dispatch(appActions.showLoader());

        invoiceResetRequest()
            .then((updatedInvoice) => {
                dispatch({
                    type: invoiceActionTypes.INVOICE_STATUS_CHANGED,
                    payload: {updatedInvoice},
                });
            })
            .catch((error) => handleChangeInvoiceStatusError(error, dispatch))
            .catch((error) => console.warn('Error reset invoice: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
};

export function fetchCurrentInvoice(invoiceNumber) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        const invoiceRequest = fetchInvoice(invoiceNumber).catch((error) => dispatch(appActions.handleError(error)));
        const invoiceBatchesRequest = fetchInvoiceBatches(invoiceNumber).catch(() => []);
        const [invoice, invoiceBatches] = await Promise.all([invoiceRequest, invoiceBatchesRequest]);

        if (!invoice) {
            dispatch(appActions.hideLoader());
            return;
        }

        const {settlement, loadFiles, loadNotes, travelOrderFiles, travelOrderNotes} = await fetchInvoiceData(invoice);

        dispatch({
            type: invoiceActionTypes.INVOICE_DATA_RECEIVED,
            payload: {
                invoice,
                invoiceBatches,
                settlement,
                loadFiles,
                loadNotes,
                travelOrderFiles,
                travelOrderNotes,
            },
        });

        dispatch(appActions.hideLoader());
    };
}

export const approveInvoice = (invoice) => {
    return async function (dispatch, getState) {
        dispatch(appActions.showLoader());

        const checkHasVirtualStops = () => {
            const pickUpInfo = invoice?.load?.pick_up_info || [];
            return pickUpInfo.some((stop) => stop.is_virtual);
        };

        const getInvoiceStopInfo = () => {
            const {pick_up_info: pickUpInfo} = invoice.load;
            const travelOrder = getInvoiceTravelOrder(invoice);
            const travelOrderProgressSteps = getPickUpDeliveryProgressSteps(travelOrder);

            return {
                pickUpInfo,
                travelOrderProgressSteps,
            };
        };

        const getDataForApproveInvoice = (stopsIncludedToPDF) => {
            let dataFoRApprove: any = {
                status: statuses.READY_TO_GENERATE,
            };

            const isInvoiceWithOnlyVirtualStops = checkHasVirtualStops() && stopsIncludedToPDF?.length === 0;
            const isInvoiceWithVirtualStops = checkHasVirtualStops() && stopsIncludedToPDF?.length > 0;

            // virtual stops can be added from invoice view card
            if (isInvoiceWithOnlyVirtualStops) {
                dataFoRApprove = {...dataFoRApprove, ...getInvoiceStopInfo(), stopsIncludedToPDF};
                dataFoRApprove.pickUpInfo = dataFoRApprove.pickUpInfo.filter((item) => item.is_virtual);
                dataFoRApprove.travelOrderProgressSteps = dataFoRApprove.travelOrderProgressSteps.filter(
                    (item) => item.is_virtual,
                );
                return dataFoRApprove;
            }

            if (isInvoiceWithVirtualStops) {
                dataFoRApprove = {...dataFoRApprove, ...getInvoiceStopInfo(), stopsIncludedToPDF};
                return dataFoRApprove;
            }

            // stops for include to PDF can be selected from view card
            if (stopsIncludedToPDF?.length > 0) {
                dataFoRApprove = {...dataFoRApprove, stopsIncludedToPDF};
            }

            return dataFoRApprove;
        };

        try {
            const state = getState();
            const isNeedApproveSettlement = selectors.getApproveSettlementStatus(state);
            const stopsIncludedToPDF = selectors.getStopsIncludedToPDF(state);
            const invoiceStatusInfo = getDataForApproveInvoice(stopsIncludedToPDF);
            const approveInvoiceRequest = setNextInvoiceStatus(invoice, invoiceStatusInfo).catch((error) =>
                handleChangeInvoiceStatusError(error, dispatch),
            );
            const requests = [approveInvoiceRequest];

            if (isNeedApproveSettlement) {
                const currentSettlement = selectors.getCurrentSettlementFromInvoice(getState());
                const approveSettlementRequest = isSettlementCarrier(currentSettlement)
                    ? setReadyToPostSettlementCarrierStatus(currentSettlement)
                    : setNextSettlementOwnerStatus(currentSettlement, settlementsStatuses.READY_TO_POST);

                requests.push(approveSettlementRequest);
            }

            const [updatedInvoice] = await Promise.all(requests);

            dispatch({
                type: invoiceActionTypes.INVOICE_STATUS_CHANGED,
                payload: {updatedInvoice},
            });

            dispatch(appActions.hideLoader());
        } catch (error) {
            console.warn('Error on approve invoice: ', error);
            dispatch(appActions.hideLoader());
        }
    };
};

export const generateInvoice = (invoice, settingsForGenerate) => {
    return function (dispatch) {
        dispatch(appActions.showLoader());

        generateInvoicePDF(invoice.id, settingsForGenerate)
            .then((updatedInvoice) => {
                dispatch({
                    type: invoiceActionTypes.INVOICE_STATUS_CHANGED,
                    payload: {updatedInvoice},
                });

                dispatch({
                    type: invoiceActionTypes.INVOICE_FILES_UPDATED,
                    payload: {updatedInvoice},
                });
            })
            .catch((error) => handleInvoiceGenerateError(error, dispatch))
            .catch((error) => handleChangeInvoiceStatusError(error, dispatch))
            .catch((error) => console.warn('Error generation invoice: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
};

export function changeInvoiceLoadCustomer(newCustomer, {withLoader = true} = {}) {
    return function (dispatch, getState) {
        if (withLoader) {
            dispatch(appActions.showLoader());
        }
        const currentInvoice = selectors.getCurrentInvoice(getState());
        const load = {
            customer: newCustomer,
        };
        return updateInvoiceData(currentInvoice.id, {load})
            .then((response) => {
                dispatch(getUpdateLoadCustomerAction(currentInvoice, response.customer));
            })
            .catch((e) => dispatch(appActions.handleError(e)))
            .finally(() => withLoader && dispatch(appActions.hideLoader()));
    };
}

export function createInvoiceCustomer(invoice, newCustomerData) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());
        try {
            const createdCustomer = await createCustomer(newCustomerData).then((response) => response.data);
            await dispatch(changeInvoiceLoadCustomer(createdCustomer, {withLoader: false}));
            dispatch(appActions.addCreatedCustomer(createdCustomer));
            dispatch(showSuccessfulCreateCustomerModal(createdCustomer));
        } catch (e) {
            dispatch(appActions.handleError(e));
        } finally {
            dispatch(appActions.hideLoader());
        }
    };
}

export function changeInvoiceLoadRefNumber(refNumbersList) {
    return function (dispatch, getState) {
        dispatch(appActions.showLoader());
        const currentInvoice = selectors.getCurrentInvoice(getState());
        const load = {
            ref_number: getRefNumber(refNumbersList),
        };
        updateInvoiceData(currentInvoice.id, {load})
            .then((response) => {
                dispatch(getUpdateLoadRefNumberAction(currentInvoice, response.ref_number));
            })
            .catch((e) => console.warn(`Error updating invoice load ref number: ${e}`))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

const receiveFiles = (updatedFiles) => {
    return {
        type: invoiceActionTypes.INVOICE_LOAD_AND_TRAVEL_ORDER_UPDATED_FILES,
        payload: {files: updatedFiles},
    };
};

const getFilesOnStop = (stopId, allTravelOrderFiles) => {
    return allTravelOrderFiles.filter((file) => {
        const extraData = file.extra_data;

        if (!extraData) {
            return false;
        }

        return stopId === extraData.stepID;
    });
};

const addFiles = async (props: PostFilesProps) => {
    const {travelOrderId, stopId, allTravelOrderFiles, updateFiles, typeFiles, dispatch} = props;
    const filesInStep = getFilesOnStop(stopId, allTravelOrderFiles);
    dispatch(appActions.showLoader());
    try {
        const updatedFiles = await updateTravelOrderFiles(travelOrderId, filesInStep, updateFiles, typeFiles);
        dispatch(receiveFiles(updatedFiles));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export function changeInvoiceTravelOrderStops(stopsInfo) {
    return async function (dispatch, getState) {
        dispatch(appActions.showLoader());

        const {
            invoices: {
                current: {travelOrderFiles: allTravelOrderFiles},
            },
        } = getState();

        const postTravelOrderFiles = async (travelOrder) => {
            const travelOrderStopInfo = travelOrder && travelOrder.stop;

            const {bolFiles} = travelOrderStopInfo;

            const currentPods = travelOrderStopInfo.pods || [];
            const podFiles = currentPods.flatMap((item) => item.files).filter((file) => Boolean(file)) || [];

            if (!isEmpty(podFiles)) {
                await addFiles({
                    travelOrderId: stopsInfo.travelOrder.id,
                    stopId: stopsInfo.travelOrder.stop.id,
                    allTravelOrderFiles,
                    updateFiles: podFiles,
                    typeFiles: FILE_TYPES.pod,
                    dispatch,
                });
            }

            if (!isEmpty(bolFiles)) {
                await addFiles({
                    travelOrderId: stopsInfo.travelOrder.id,
                    stopId: stopsInfo.travelOrder.stop.id,
                    allTravelOrderFiles,
                    updateFiles: bolFiles,
                    typeFiles: FILE_TYPES.bol,
                    dispatch,
                });
            }
        };

        const changeTravelOrderDataFormat = (travelOrder) => {
            const travelOrderStopInfo = travelOrder && travelOrder.stop;
            if (!travelOrderStopInfo) {
                return {};
            }
            const {checkInTime, checkOutTime, bols, bolFiles, ...restData} = travelOrderStopInfo;

            const newBoLs = (bols || []).map((bol) => {
                return omit(
                    {
                        ...bol,
                        unit: bol.weight.unit,
                        is_different_address: bol.isDifferentAddress,
                        weight: convertToLbs(bol.weight.unit, bol.weight.amount),
                    },
                    ['isDifferentAddress'],
                );
            });

            return {
                stop: {
                    ...restData,
                    bols: newBoLs,
                    check_in_datetime: convertDateToUtcTime(checkInTime),
                    check_in_timezone: checkInTime.timezone,

                    check_out_datetime: convertDateToUtcTime(checkOutTime),
                    check_out_timezone: checkOutTime.timezone,
                },
            };
        };

        const currentInvoice = selectors.getCurrentInvoice(getState());
        const {load, travelOrder} = stopsInfo;
        const loadInfo = load ? {load} : {};
        const travelOrderInfo = travelOrder ? {travelOrder: changeTravelOrderDataFormat(travelOrder)} : {};
        const updatedData = {
            ...loadInfo,
            ...travelOrderInfo,
        };

        if (travelOrder) {
            await postTravelOrderFiles(travelOrder);
        }

        updateInvoiceData(currentInvoice.id, updatedData)
            .then((response) => {
                const isLoadUpdated = updatedData.load;
                const isTravelOrderUpdated = updatedData.travelOrder;

                if (isLoadUpdated && !isTravelOrderUpdated) {
                    dispatch(getUpdateInvoiceLoadAction(currentInvoice, response));
                } else if (isTravelOrderUpdated && !isLoadUpdated) {
                    dispatch(getUpdateInvoiceTravelOrderAction(currentInvoice, response));
                } else {
                    dispatch(getUpdateInvoiceLoadAndTravelOrderAction(currentInvoice, response));
                }
            })
            .catch((error) => console.warn('Error update invoice load and travel order stops ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function changeInvoiceDriverPayment(rate) {
    return function (dispatch, getState) {
        dispatch(appActions.showLoader());

        const currentInvoice = selectors.getCurrentInvoice(getState());

        updateInvoiceData(currentInvoice.id, {travelOrder: {rate}})
            .then((response) => {
                dispatch(getUpdateTravelOrderLoadAction(response));
            })
            .catch((error) => console.warn('Error update invoice driver payment ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function changeInvoiceTotalAmount(rate) {
    return function (dispatch, getState) {
        dispatch(appActions.showLoader());
        // some invoices has only loads without travel orders so we should update them in different way
        const isUpdateInvoiceTravelOrder = rate && rate.travelOrderID;
        const putData = isUpdateInvoiceTravelOrder
            ? {load: {total_rate: rate.total_amount}, travelOrder: {rate}}
            : {load: {total_rate: rate.total_amount}};
        const currentInvoice = selectors.getCurrentInvoice(getState());
        updateInvoiceData(currentInvoice.id, putData)
            .then((response) => {
                if (isUpdateInvoiceTravelOrder) {
                    dispatch(getUpdateInvoiceTravelOrderAction(currentInvoice, response));
                } else {
                    const totalAmount = response && response.total_rate;
                    dispatch(getUpdateLoadTotalRateAction(currentInvoice, totalAmount));
                }
            })
            .catch((error) => console.warn('Error update invoice total amount ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function setApproveSettlementStatus(status) {
    return {
        type: invoiceActionTypes.INVOICE_SET_APPROVE_SETTLEMENT_STATUS,
        payload: {
            isNeedApproveSettlement: status,
        },
    };
}

export function openInvoicePDF(invoice) {
    return function () {
        const pdfOfSelectedInvoice = getInvoiceCurrentPDF(invoice);

        if (pdfOfSelectedInvoice) {
            openNewWindow({newWindowUrl: getFilePath(pdfOfSelectedInvoice), newWindowTitle: 'Invoice'});
        }
    };
}

export function includeStopToPDF(stopID: string) {
    return {
        type: invoiceActionTypes.INVOICE_TOGGLE_INCLUDE_LOAD_STOP_TO_PDF,
        payload: stopID,
    };
}

export function openChatWithNoPodDriverWarning(invoice: Invoice) {
    return async function (dispatch) {
        const fetchedInvoiceWithFullData = await fetchInvoice(invoice.invoice_number);

        const messageData = await getMessageDataForDriverWithoutPOD(fetchedInvoiceWithFullData);

        if (!messageData) {
            return;
        }

        dispatch(openChatChannelWithFilledMessages(messageData.driverID, messageData.text));
    };
}

export function insertVirtualStop(invoice, stopIndex, stopData) {
    return {
        type: invoiceActionTypes.INVOICE_INSERT_VIRTUAL_STOP,
        payload: {invoiceID: invoice.id, stopIndex, stopData},
    };
}

export function removeVirtualStop(stopIndex) {
    return function (dispatch, getState) {
        const state = getState();
        const currentInvoice = selectors.getCurrentInvoice(state);
        dispatch({
            type: invoiceActionTypes.INVOICE_REMOVE_VIRTUAL_STOP,
            payload: {invoiceID: currentInvoice.id, stopIndex},
        });
    };
}

export const addNoteToInvoice = (note?: string) => async (dispatch, getState) => {
    const state = getState();

    const currentInvoice = selectors.getCurrentInvoice(state);

    try {
        dispatch(appActions.showLoader());

        await addNoteToInvoiceRequest(currentInvoice.id, note);

        dispatch(fetchCurrentInvoice(currentInvoice.invoice_number));
    } catch (error) {
        dispatch(
            openChangeStatusFailedModal(
                'The action cannot be completed because it was done by another user. Please reload page.',
            ),
        );
        console.log('Error on add note to invoice: ', error);
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export function changeInvoiceDueDate(date: {dueDate: string}) {
    return function (dispatch, getState) {
        dispatch(appActions.showLoader());
        const state = getState();
        const currentInvoice = selectors.getCurrentInvoice(state);
        changeInvoiceDueDateRequest(currentInvoice.id, date)
            .then(() => {
                dispatch(getUpdateDueDateAction(currentInvoice.id, date.dueDate));
            })
            .catch((error) => console.warn('Error on change due date to invoice: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}
