import React from 'react';

import LENGTH_UNITS from 'utils/data/lengthUnits';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import DimensionsUnitsOfMeasurement from 'components/ui/Form/DimensionsUnitsOfMeasurement';

import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';
import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import useFormActions from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/hooks/useFormActions';

const Unit: React.FC = () => {
    const {getTruckDimensionUnitFieldName} = getFormFieldNames();

    const {
        setTruckDimensionUnit,
        setTuckDimensionHeight,
        setTuckDimensionWidth,
        setTuckDimensionLength,
    } = useFormActions();

    const dimensionUnit = useGetReduxFormValue(FORM_NAME, getTruckDimensionUnitFieldName());

    const setPayloadUnit = (unit): void => {
        setTruckDimensionUnit(unit);
    };

    const clearPayload = (): void => {
        setTuckDimensionHeight(null);
        setTuckDimensionWidth(null);
        setTuckDimensionLength(null);
    };

    return (
        <DimensionsUnitsOfMeasurement
            unit={dimensionUnit}
            setUnit={setPayloadUnit}
            clearFields={clearPayload}
            disabled={false}
        />
    );
};
export default Unit;
