import {AppThunkExtraArgument} from 'store';
import {showAlert} from 'store/actions';
import {hideLoader, showLoader} from 'store/reducers/commonData/actionCreators';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';

import {SendDataType} from '../types';
import {transformSupportMessageData} from '../utils';

export const sendSupportMessage = (data: SendDataType, onClose: () => void): AppThunkExtraArgument => async (
    dispatch,
    getState,
    {api},
) => {
    const {userData} = getState();

    const name = getDispatcherName(userData);

    dispatch(showLoader());

    try {
        await api.default.post('/email/support', transformSupportMessageData({...data, name}), {
            headers: {'Content-Type': 'multipart/form-data'},
        });
        dispatch(showAlert('Email sent successfully!', 'success'));
    } catch (e) {
        dispatch(showAlert('Email sending error!', 'danger'));
    } finally {
        dispatch(hideLoader());
        onClose();
    }
};
