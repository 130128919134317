import {fetchBatchResponsibleUsersRequest} from 'core/entities/Settlement/requests/settlementCarrierRequests';

import useFetchWithPaginate from 'hooks/useFetchWithPaginate';
import {BatchResponsibleUser} from '../types';

interface FetchData {
    users: BatchResponsibleUser[];
    error: string | undefined;
    currentPage: number | undefined;
    isFetching: boolean;
    isAllFetched: boolean;
    fetchNextPage();
}

const PER_PAGE = 10;

const fetchBatchResponsibleUsers = (
    carrierID: number,
    {page, perPage}: {page: string; perPage: number},
): Promise<BatchResponsibleUser[]> => {
    const nextPage = parseInt(page, 10) + 1;
    return fetchBatchResponsibleUsersRequest(carrierID, {page: nextPage, perPage})
        .then((response) => response.data)
        .catch((err) => {
            console.error('Error on fetch batch responsible users: ', err);
            return [];
        });
};

const cacheCreator = ({carrierID}: {carrierID: number}) => {
    return (pageIndex: any, previousPageData: any) => {
        if (previousPageData && previousPageData.length === 0) {
            return null;
        }
        const pageNumber = String(pageIndex);
        const cacheKey = `batch-${carrierID}-responsible-users-${pageNumber}`;
        return [cacheKey, pageNumber];
    };
};

const fetcherCreator = ({carrierID}: {carrierID: number}) => {
    return (...args): any => {
        const [_, pageNumber] = args;
        return fetchBatchResponsibleUsers(carrierID, {page: pageNumber, perPage: PER_PAGE});
    };
};

export default ({carrierID}: {carrierID: number}): FetchData => {
    const getCacheKey = cacheCreator({carrierID});
    const fetcher = fetcherCreator({carrierID});
    const {data: users, error, currentPage, isFetching, isAllFetched, fetchNextPage} = useFetchWithPaginate({
        perPage: PER_PAGE,
        getKey: getCacheKey,
        fetchData: fetcher,
    });
    return {
        users,
        error,
        currentPage,
        isFetching,
        isAllFetched,
        fetchNextPage,
    };
};
