import Truck from 'core/entities/Truck/types';

export const getNumberText = (truck: Truck, prefix: string) => {
    const trailer = truck.cargo_number ? `-${truck.cargo_number}` : '';
    const reefer = truck.is_reefer ? '-R' : '';
    return `${prefix}${truck.number}${trailer}${reefer}`;
};

export const getTooltipText = (truck: Truck) => {
    const trailerTooltip = truck.cargo_number ? `-trailer#${truck.cargo_number}` : '';
    const reeferTooltip = truck.is_reefer ? '-Reefer' : '';
    return `truck#${truck.number}${trailerTooltip}${reeferTooltip}`;
};

export const showTooltip = (truck: Truck) => Boolean(truck.cargo_number || truck.is_reefer);
