import React, {useEffect} from 'react';

import {connect} from 'react-redux';

import DriverChannelExpanded from './components/DriverChannelExpanded';
import DriversChannelsList from './components/DriversChannelsList';
import useChatActions from './hooks/useChatActions';
import ChatSearch from './components/DriverSearch';
import ChatAlert from './components/ChatAlert';
import * as selectors from './redux/selectors';

import styles from './chat.module.scss';

const Chat = (props) => {
    const {selectedChannelDriverID, searchParams, isChatOpened, channels} = props;

    const actions = useChatActions();

    useEffect(() => {
        actions.init();
    }, []);

    if (!isChatOpened) {
        return null;
    }

    return (
        <div className={styles.wrap}>
            {selectedChannelDriverID ? (
                <DriverChannelExpanded
                    onLeaveChannel={actions.leaveDriverChannel}
                    onSendMessage={actions.sendMessageToChannel}
                />
            ) : null}

            <div>
                <div className={styles.top}>
                    <div className="chat-title">Driver’s Chat</div>
                </div>

                <ChatAlert />

                <div className={styles.search}>
                    <ChatSearch onSubmit={actions.driverSearch} />
                </div>

                {channels ? (
                    <DriversChannelsList
                        channels={channels}
                        searchParams={searchParams}
                        onEnterChannel={actions.enterToDriverChannel}
                    />
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    selectedChannelDriverID: selectors.getSelectedChannelDriverID(state),
    searchParams: selectors.getSearchParams(state),
    isChatOpened: selectors.getChatOpened(state),
    channels: selectors.getAllChannels(state),
});

export default connect(mapStateToProps)(Chat);
