import React from 'react';
import classNames from 'classnames';

import {createDate} from 'utils/dateTime';

import type Invoice from 'core/entities/Invoice/types';
import {getInvoiceLoad, getInvoiceStatusInfo} from 'core/entities/Invoice';

import CurrencyAmount from 'components/ui/CurrencyAmount';
import LoadRefNumbersList from 'components/common/Load/LoadRefNumbersList';
import LoadTempControlBadge from 'components/common/Load/LoadTempControlBadge';

import InvoiceNumberInfo from 'pages/Invoices/components/common/InvoiceNumberInfo';
import {DisplayInvoicesMode} from 'pages/Invoices/components/common/modals/BatchInvoicesModal/types';

import SelectCheckbox from '../SelectCheckbox';
import ActionButtons from './ActionButtons';

import styles from './styles.module.scss';

type OwnProps = {
    invoice: Invoice;
    displayMode: DisplayInvoicesMode;
    isSelected: boolean;
    onOpenInvoicePDF();
    onSelect();
};

const InvoiceRow = (props: OwnProps): JSX.Element => {
    const {invoice, displayMode, isSelected, onSelect, onOpenInvoicePDF} = props;

    const load = getInvoiceLoad(invoice);
    const invoiceStatusInfo = getInvoiceStatusInfo(invoice) || {};
    const invoiceStatusDate = invoiceStatusInfo.created_at ? createDate(invoiceStatusInfo.created_at).fullDate : null;
    const isDisplayResetInvoice = displayMode === 'reset';
    const showSelectCheckbox = displayMode === 'general';
    const mainClass = classNames(styles.row, isDisplayResetInvoice && styles.row_reset);

    return (
        <tr className={mainClass}>
            <td>
                <div className={styles.row__select}>
                    <div>{showSelectCheckbox && <SelectCheckbox checked={isSelected} onSelect={onSelect} />}</div>
                    <div>
                        <InvoiceNumberInfo invoice={invoice} isDisabled={isDisplayResetInvoice}>
                            <LoadTempControlBadge load={load} />
                        </InvoiceNumberInfo>
                    </div>
                </div>
            </td>
            <td>{invoiceStatusDate}</td>
            <td className="ref-td">
                <LoadRefNumbersList load={load} isDisabled={isDisplayResetInvoice} />
            </td>
            <td>
                <CurrencyAmount amount={invoice.total_charges} currency={invoice.currency} />
            </td>
            <td>
                <ActionButtons invoice={invoice} displayMode={displayMode} onOpeInvoicePDF={onOpenInvoicePDF} />
            </td>
        </tr>
    );
};

function invoicePropsAreEqual(prevProps: OwnProps, nextProps: OwnProps) {
    return prevProps.invoice === nextProps.invoice && prevProps.isSelected === nextProps.isSelected;
}

export default React.memo(InvoiceRow, invoicePropsAreEqual);
