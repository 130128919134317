import {getTravelOrderStopByID} from 'core/entities/TravelOrder/modules/travelOrderStops/getTravelOrderStops';
import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';
import {STOP_STEPS} from 'core/entities/TravelOrder/constants';

export const isTravelOrderStopStepAddDocument = (travelOrderStop?: TravelOrderStop): boolean =>
    travelOrderStop?.step === STOP_STEPS.ADD_DOCUMENT;

export const isTravelOrderStopStepCheckOut = (travelOrderStop?: TravelOrderStop): boolean =>
    travelOrderStop?.step === STOP_STEPS.CHECK_OUT;

export const isTravelOrderStopStepCheckIn = (travelOrderStop?: TravelOrderStop): boolean =>
    travelOrderStop?.step === STOP_STEPS.CHECK_IN;

export const getTravelOrderStopStepsByID = (
    params: StopInfoParams,
): {isStepAddDocument: boolean; isStepCheckOut: boolean; isStepCheckIn: boolean} => {
    const travelOrderStop = getTravelOrderStopByID(params);

    const isStepAddDocument = isTravelOrderStopStepAddDocument(travelOrderStop);
    const isStepCheckOut = isTravelOrderStopStepCheckOut(travelOrderStop);
    const isStepCheckIn = isTravelOrderStopStepCheckIn(travelOrderStop);

    return {isStepAddDocument, isStepCheckOut, isStepCheckIn};
};
