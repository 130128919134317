import {getEntityFieldName} from 'core/entities/Facility/types';

export default {
    id: getEntityFieldName('id'),
    name: getEntityFieldName('name'),
    latitude: getEntityFieldName('latitude'),
    longitude: getEntityFieldName('longitude'),
    address_line: getEntityFieldName('address_line'),
    additional_address_line: getEntityFieldName('additional_address_line'),
    city: getEntityFieldName('city'),
    state: getEntityFieldName('state'),
    zip: getEntityFieldName('zip'),
    country: getEntityFieldName('country'),
    phone: getEntityFieldName('phone'),
    fax: getEntityFieldName('fax'),
    contact: getEntityFieldName('contact'),
    email: getEntityFieldName('email'),
    note: getEntityFieldName('note'),
    working_days: getEntityFieldName('working_days'),
    working_hours_from: getEntityFieldName('working_hours_from'),
    working_hours_to: getEntityFieldName('working_hours_to'),
    is_active: getEntityFieldName('is_active'),
    is_deleted: getEntityFieldName('is_deleted'),
    // custom field for validate select city line
    location: 'location',
};
