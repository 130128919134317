import React from 'react';

import {DriverReceiverWithTruck} from 'core/entities/Quote/types';
import {
    getCountDriversWhoAllowedSendQuote,
    getCountDriversWhoNotAllowedSendQuote,
    getCountDriversWhoDeclinedQuote,
    getCountDriversWhoReadQuote,
    getCountDriversWhoProvidedQuote,
} from 'core/entities/Quote/modules/quoteDriverReceiver';

import CheckBox from './components/CheckBox';
import Counter from './components/Counter';

type OwnProps = {
    driversReceivers: DriverReceiverWithTruck[];
    sentItemsChecked: boolean;
    notSentItemsChecked: boolean;
    declinedItemsChecked: boolean;
    readItemsChecked: boolean;
    providedItemsChecked: boolean;
    toggleSent: () => void;
    toggleNotSent: () => void;
    toggleDeclined: () => void;
    toggleRead: () => void;
    toggleProvided: () => void;
};

const Options: React.FC<OwnProps> = (props) => {
    const {
        driversReceivers,
        sentItemsChecked,
        notSentItemsChecked,
        declinedItemsChecked,
        readItemsChecked,
        providedItemsChecked,
        toggleSent,
        toggleNotSent,
        toggleDeclined,
        toggleRead,
        toggleProvided,
    } = props;
    const driversWhoAllowedSendQuoteCounter = getCountDriversWhoAllowedSendQuote(driversReceivers);
    const driversWhoNotAllowedSendQuoteCounter = getCountDriversWhoNotAllowedSendQuote(driversReceivers);
    const driversWhoDeclinedQuoteCounter = getCountDriversWhoDeclinedQuote(driversReceivers);
    const driversWhoReadQuoteCounter = getCountDriversWhoReadQuote(driversReceivers);
    const driversWhoProvidedQuoteCounter = getCountDriversWhoProvidedQuote(driversReceivers);

    return (
        <div className="d-flex">
            <div className="d-flex list-with-gap-10 mr-20">
                <div className="mr-10">Statuses: </div>
                <CheckBox checked={sentItemsChecked} onSelect={toggleSent} />
                SENT
                <Counter counter={driversWhoAllowedSendQuoteCounter} />
            </div>

            <div className="d-flex list-with-gap-10">
                <CheckBox checked={notSentItemsChecked} onSelect={toggleNotSent} />
                WASN`T SENT
                <Counter counter={driversWhoNotAllowedSendQuoteCounter} />
            </div>

            <div className="d-flex list-with-gap-10">
                <CheckBox checked={declinedItemsChecked} onSelect={toggleDeclined} />
                DECLINED
                <Counter counter={driversWhoDeclinedQuoteCounter} />
            </div>

            <div className="d-flex list-with-gap-10">
                <CheckBox checked={readItemsChecked} onSelect={toggleRead} />
                OPENED
                <Counter counter={driversWhoReadQuoteCounter} />
            </div>

            <div className="d-flex list-with-gap-10">
                <CheckBox checked={providedItemsChecked} onSelect={toggleProvided} />
                PROVIDED
                <Counter counter={driversWhoProvidedQuoteCounter} />
            </div>
        </div>
    );
};

export default Options;
