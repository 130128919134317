import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button';

import {ADD_OFFER_DUPLICATE_ERROR, ADD_OFFER_MAX_LIMIT_ERROR} from 'pages/LoadBoard/constants/offerErrors';

import styles from './styles.module.scss';

interface OwnProps {
    data: {
        errorCode: string;
    };
    onClose(): void;
    handlers: {onSubmit(): void};
}

const OfferErrors: React.FC<OwnProps> = (props) => {
    const {data, onClose} = props;
    const {errorCode} = data;
    const isShowAddOfferDuplicateError = errorCode === ADD_OFFER_DUPLICATE_ERROR;
    const isShowAddOfferMaxLimitError = errorCode === ADD_OFFER_MAX_LIMIT_ERROR;
    return (
        <Modal show={true} onHide={onClose} className="info-modal" backdrop="static">
            <Modal.Header>
                <Modal.Title>Error Add New Offer</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.body}>
                <i className="fa fa-exclamation feedback-icon-error" />
                <div className={styles.wrapper}>
                    {isShowAddOfferDuplicateError && (
                        <div>There`s already an offer from this truck. One Truck - one offer, one quote each</div>
                    )}
                    {isShowAddOfferMaxLimitError && (
                        <div>
                            <div>You have already used maximum number of offers for this truck.</div>
                            <div>To create new offers, wait until the timer for the previous ones has expired.</div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer className={styles.footer}>
                <Button onClick={onClose} className="mr10" colorTheme="red" buttonSize="small" defaultFocus={true}>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OfferErrors;
