import keyBy from 'lodash/keyBy';

import {DriverChannel, DriverTruckData, MessageStatus} from 'widgets/Chat/types';
import * as wsTypes from 'widgets/Chat/redux/actionTypes/wsTypes';
import * as types from 'widgets/Chat/redux/actionTypes';

import {Dispatcher} from 'core/entities/Dispatcher/types';
import Driver from 'core/entities/Driver/types';

import {mapDriversChannels, mapMessage, sortChannelsByLastMessageInsertDate} from './utils';

export interface DriversChannels {
    byDriverID: {[key: string]: DriverChannel};
    allIds: string[];
}

export interface ChatState {
    channels: DriversChannels | {byDriverID: {}; allIds: []};
    allDrivers: {
        [key: string]: Driver;
    };
    allDispatchers: {
        [key: string]: Dispatcher;
    };
    searchParams: {
        truckNumber?: string;
    };
    selectedChannelData: {
        driverID: number | null;
        truck: DriverTruckData | null;
    };
    opened: boolean;
    clientSocketId: string | null;
    messageStatus: MessageStatus;
}

const initialState = {
    channels: {byDriverID: {}, allIds: []},
    selectedChannelData: {
        driverID: null,
        truck: null,
    },
    allDrivers: {},
    allDispatchers: {},
    searchParams: {},
    opened: false,
    clientSocketId: null,
    messageStatus: 'idle' as MessageStatus,
};

export default function chatReducer(state: ChatState = initialState, action: types.ChatActionTypes): ChatState {
    switch (action.type) {
        case types.DATA_RECEIVED: {
            const {drivers, driversAvatars, dispatchers, channels, currentUser} = action.payload;
            const driversMap = keyBy(drivers, 'id');
            const driversAvatarsMap = keyBy(driversAvatars, 'driver_id');
            const dispatchersMap = keyBy(dispatchers, 'id');
            const channelsMap = keyBy(channels, 'driverID');
            const mappedDriversChannels = mapDriversChannels(
                driversMap,
                driversAvatarsMap,
                dispatchersMap,
                channelsMap,
                currentUser.id,
            );
            const sortedDriversChannels = sortChannelsByLastMessageInsertDate(mappedDriversChannels);
            return {
                ...state,
                channels: sortedDriversChannels,
                allDrivers: driversMap,
                allDispatchers: dispatchersMap,
            };
        }

        case types.ENTER_TO_DRIVER_CHANNEL: {
            const {driverID} = action.payload;
            return {
                ...state,
                selectedChannelData: {
                    ...state.selectedChannelData,
                    driverID,
                },
            };
        }

        case types.FETCH_TRUCK_DATA_FOR_DRIVER_CHANNEL: {
            const {driverTruckData} = action.payload;
            return {
                ...state,
                selectedChannelData: {
                    ...state.selectedChannelData,
                    truck: driverTruckData,
                },
            };
        }

        case types.LEAVE_DRIVER_CHANNEL: {
            const sortedChannels = sortChannelsByLastMessageInsertDate(state.channels);

            return {
                ...state,
                channels: sortedChannels,
                selectedChannelData: {
                    driverID: null,
                    truck: null,
                },
                searchParams: {},
            };
        }

        case types.SET_DRIVER_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: action.payload,
            };
        }

        case wsTypes.WEB_SOCKET_CHAT_MORE_CHANNEL_MESSAGES_RECEIVED: {
            const {driverID, messages, currentUser} = action.payload;
            const currentDriverChannel: DriverChannel = state.channels.byDriverID[driverID];
            if (!currentDriverChannel) {
                return state;
            }
            const mappedMessages = messages.map((m) =>
                mapMessage(m, state.allDrivers, state.allDispatchers, currentUser.id),
            );
            const updatedDriverChannel = {
                ...currentDriverChannel,
                messages: [...mappedMessages, ...currentDriverChannel.messages],
            };
            return {
                ...state,
                channels: {
                    ...state.channels,
                    byDriverID: {...state.channels.byDriverID, [driverID]: updatedDriverChannel},
                },
            };
        }

        case wsTypes.WEB_SOCKET_CHAT_RECEIVED_CHAT_MESSAGE: {
            const channelWithNewMessage = action.payload;

            if (!channelWithNewMessage) {
                return {...state, messageStatus: 'failure'};
            }

            const {
                message: {driverID, newMessage, lastMessageInsertDate},
                currentUser,
            } = channelWithNewMessage;

            if (!driverID || !newMessage) {
                return {...state, messageStatus: 'failure'};
            }

            const currentDriverChannel: DriverChannel = state.channels.byDriverID[driverID];

            if (!currentDriverChannel) {
                return {...state, messageStatus: 'failure'};
            }

            // temp solution - in some cases and not for all OS(windows, mac) after re login without reload page
            // socket io starts send added message to sender and we have bug with duplicated messages
            // for avoid this issue we check existing new message in current channel
            const isNewMessageExistsInChannel = currentDriverChannel.messages.some(
                (message) => message.id === newMessage.id,
            );

            if (isNewMessageExistsInChannel) {
                return {...state, messageStatus: 'failure'};
            }

            const newMessageWithMappedData = mapMessage(
                newMessage,
                state.allDrivers,
                state.allDispatchers,
                currentUser.id,
            );

            const updatedDriverChannel = {
                ...currentDriverChannel,
                // while driver doesnt have messages his channel will be without id, so after we get first message we add channel id
                channelID:
                    currentDriverChannel.channelID === undefined
                        ? newMessage.channelID
                        : currentDriverChannel.channelID,
                lastMessageInsertDate,
                messages: [...currentDriverChannel.messages, newMessageWithMappedData],
                unreadMessagesCount: newMessageWithMappedData.driver
                    ? currentDriverChannel.unreadMessagesCount + 1
                    : currentDriverChannel.unreadMessagesCount,
            };

            const currentChannels = {
                ...state.channels,
                byDriverID: {...state.channels.byDriverID, [driverID]: updatedDriverChannel},
            };

            const sortedDriversChannels = sortChannelsByLastMessageInsertDate(currentChannels);

            return {
                ...state,
                messageStatus: 'success',
                channels: sortedDriversChannels,
            };
        }

        case wsTypes.WEB_SOCKET_CHAT_MARK_LOCAL_MESSAGES_AS_READ: {
            const {driverID} = action.payload;
            const channel: DriverChannel = state.channels.byDriverID[driverID];

            if (!channel || channel.unreadMessagesCount === 0) {
                return state;
            }

            const readMessages = channel.messages.map((msg) => ({...msg, isUnread: false}));
            return {
                ...state,
                channels: {
                    ...state.channels,
                    byDriverID: {
                        ...state.channels.byDriverID,
                        [driverID]: {...channel, unreadMessagesCount: 0, messages: readMessages},
                    },
                },
            };
        }

        case types.TOGGLE_CHAT: {
            return {
                ...state,
                opened: action.payload.opened,
            };
        }

        case types.SENDING_MESSAGE: {
            const {messageStatus} = action.payload;
            return {
                ...state,
                messageStatus,
            };
        }

        case wsTypes.WEB_SOCKET_CHAT_SET_SOCKET_ID:
            return {
                ...state,
                clientSocketId: action.payload.socketId,
                messageStatus: 'idle',
            };

        case wsTypes.WEB_SOCKET_CHAT_CLEAR_SOCKET_ID:
            return {
                ...state,
                clientSocketId: null,
                messageStatus: 'idle',
            };

        case wsTypes.WEB_SOCKET_CHAT_SEND_CHAT_MESSAGE_ERROR:
            return {
                ...state,
                messageStatus: 'failure',
            };

        default:
            return state;
    }
}
