import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import mapValues from 'lodash/mapValues';

import {PayRecord} from 'core/entities/Settlement/types';

interface PayRecordsFormattedData {
    period_pay_records: {[key: number]: {is_send_owner?: boolean; is_send_coordinator?: boolean}};
}

export const getFormattedPayRecords = (
    payRecords: PayRecord & {is_send_owner?: boolean; is_send_coordinator?: boolean}[],
): PayRecordsFormattedData => {
    const periodPayRecords = mapValues(keyBy(payRecords, 'id'), (value) =>
        pick(value, ['is_send_owner', 'is_send_coordinator']),
    );

    return {
        period_pay_records: periodPayRecords,
    };
};
