import React from 'react';
import {Modal} from 'react-bootstrap';

import Truck from 'core/entities/Truck/types';
import Button from 'components/ui/Buttons/Button';

import {
    CANCEL_TO_FINISH_EXISTS_ARCHIVED_ENTITY_ERROR,
    CANCEL_TO_FINISH_TRUCK_ON_HOLD_ERROR,
    CANCEL_TO_FINISH_TRUCK_WITHOUT_FIRST_DRIVER_ERROR,
} from 'pages/TravelOrders/constants/errors';

import TruckOnHoldError from './components/TruckOnHoldError';
import TruckWithoutFirstDriverError from './components/TruckWithoutFirstDriverError';
import ExistsArchivedEntityError from './components/ExistsArchivedEntityError';

type TruckOnHoldData = {
    errorType: typeof CANCEL_TO_FINISH_TRUCK_ON_HOLD_ERROR;
    errorData: {
        entity: Truck;
    };
};

type TruckWithoutFirstDriverData = {
    errorType: typeof CANCEL_TO_FINISH_TRUCK_WITHOUT_FIRST_DRIVER_ERROR;
    errorData: {
        entity: Truck;
    };
};

type ExistsArchivedEntityData = {
    errorType: typeof CANCEL_TO_FINISH_EXISTS_ARCHIVED_ENTITY_ERROR;
    errorData: {
        entityType: string;
        entity: unknown;
    };
};

type OwnProps = {
    data: TruckOnHoldData | TruckWithoutFirstDriverData | ExistsArchivedEntityData;
    onClose: void;
};

const isShowTruckOnHoldError = (data: OwnProps['data']): boolean => {
    return data.errorType === CANCEL_TO_FINISH_TRUCK_ON_HOLD_ERROR;
};

const isShowTruckWithoutDriversError = (data: OwnProps['data']): boolean => {
    return data.errorType === CANCEL_TO_FINISH_TRUCK_WITHOUT_FIRST_DRIVER_ERROR;
};

const isShowExistArchivedEntityError = (data: OwnProps['data']): boolean => {
    return data.errorType === CANCEL_TO_FINISH_EXISTS_ARCHIVED_ENTITY_ERROR;
};

const ErrorCancelFinishModal: React.FC<OwnProps> = (props) => {
    const {data, onClose} = props;
    const showTruckOnHoldError = isShowTruckOnHoldError(data);
    const showTruckWithoutFirstDriver = isShowTruckWithoutDriversError(data);
    const showExistsArchivedEntityError = isShowExistArchivedEntityError(data);
    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    {showTruckOnHoldError && <Modal.Title>Truck is on hold</Modal.Title>}
                    {showTruckWithoutFirstDriver && <Modal.Title>Archived data</Modal.Title>}
                    {showExistsArchivedEntityError && <Modal.Title>Archived data</Modal.Title>}
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-error" />
                    </div>
                    <div className="col-11">
                        {showTruckOnHoldError && <TruckOnHoldError truck={data.errorData.entity as Truck} />}

                        {showTruckWithoutFirstDriver && (
                            <TruckWithoutFirstDriverError truck={data.errorData.entity as Truck} />
                        )}

                        {showExistsArchivedEntityError && (
                            <ExistsArchivedEntityError
                                errorData={data.errorData as ExistsArchivedEntityData['errorData']}
                            />
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div />
                <Button onClick={onClose} buttonSize="small" colorTheme="grey">
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorCancelFinishModal;
