import React, {useState, useRef} from 'react';
import {Tooltip, Overlay} from 'react-bootstrap';
import debounce from 'lodash/debounce';

import {TooltipPlacement} from 'components/ui/TooltipBootstrap/types';

import styles from './styles.module.scss';

const DELAY_TIME = 350;

type OwnProps = {
    tooltipContent?: JSX.Element | string;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName: string;
};

const TooltipOverlay: React.FC<OwnProps> = (props) => {
    const {tooltipPlacement = 'top', tooltipContent, children, tooltipClassName} = props;

    const [show, setShow] = useState(false);
    const ref = useRef(null);

    const debouncedFunc = debounce(() => setShow(true), DELAY_TIME);

    const onMouseLeave = () => {
        setShow(false);
        debouncedFunc.cancel();
    };

    return (
        <div ref={ref} onMouseEnter={debouncedFunc} onMouseLeave={onMouseLeave} className={styles.container}>
            {children}

            <Overlay target={ref.current} show={show} placement={tooltipPlacement}>
                <Tooltip id={`tooltip-${tooltipPlacement}`} className={tooltipClassName}>
                    <div>{tooltipContent}</div>
                </Tooltip>
            </Overlay>
        </div>
    );
};

export default TooltipOverlay;
