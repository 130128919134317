import ArchivingError from './ArchivingError';
import ArchivingWarn from './ArchivingWarn';
import ArchivingSuccess from './ArchivingSuccess';

export const modalNames = {
    archivingError: 'FACILITY_PAGE_ARCHIVING_ERROR_MODAL',
    archivingWarn: 'FACILITY_PAGE_ARCHIVING_WARN_MODAL',
    archivingSuccess: 'FACILITY_PAGE_ARCHIVING_SUCCESS_MODAL',
};

const modalMap = {
    [modalNames.archivingError]: ArchivingError,
    [modalNames.archivingWarn]: ArchivingWarn,
    [modalNames.archivingSuccess]: ArchivingSuccess,
};

export default modalMap;
