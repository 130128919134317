import React from 'react';
import classNames from 'classnames';

import {getDriverName} from 'core/entities/Driver/modules/driverName';
import {PERMISSIONS} from 'core/entities/Permissions';
import {isMemberDriver} from 'core/entities/Truck/modules/truckTeamInfo';
import Truck from 'core/entities/Truck/types';
import {TruckMember} from 'core/entities/Truck/types/Team';

import TruckDriverBadge from 'components/common/Truck/TruckDriverBadge';
import LanguageBadge from 'components/ui/Badges/LanguageBadge';
import {DriverLink} from 'components/ui/Links';

import {phoneNumberWithBraces} from 'utils';

import useCheckIfUserHasPermission from 'hooks/useCheckIfUserHasPermission';

type OwnProps = {
    truckMember: TruckMember;
    truck: Truck;
};

const TruckDriverBlock: React.FC<OwnProps> = (props) => {
    const {truck, truckMember} = props;

    const isHaveViewDriverPermission = useCheckIfUserHasPermission(PERMISSIONS.DRIVER_VIEW);

    const {isResponsible, entity: driver} = truckMember;

    const isDriver = isMemberDriver(driver);

    if (!isDriver) {
        return null;
    }

    const isMainClassName = classNames({'is-main': isResponsible});

    return (
        <>
            {isResponsible ? <div className="badge">m</div> : null}

            <div>
                <TruckDriverBadge truck={truck} driver={driver} />
                &nbsp;
                <DriverLink
                    driver={driver}
                    className="second-link"
                    content={getDriverName(driver)}
                    disabled={!isHaveViewDriverPermission}
                />
            </div>

            <div>
                <span className={isMainClassName}>{phoneNumberWithBraces(driver.mobile_phone)}</span>
                &nbsp;
                <LanguageBadge language={driver.language} tooltipPosition="right" />
            </div>
        </>
    );
};

export default TruckDriverBlock;
