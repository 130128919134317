export const GENERAL_TRUCK_TYPES = {
    STRAIGHT_TRUCK_REEFER: 'Straight truck Reefer',
    BOX_TRUCK_REEFER: 'Box truck Reefer',
    STRAIGHT_TRUCK: 'Straight truck',
    ISOTHERM_TRUCK: 'Isotherm truck',
    BOBTAIL_TRUCK: 'Bobtail truck',
    SEMI_TRUCK: 'Semi truck',
    REEFER_VAN: 'Reefer van',
    TENTED_BOX: 'Tented Box',
    CARGO_VAN: 'Cargo van',
    BOX_TRUCK: 'Box truck',
    CARHAULER: 'Carhauler',
    HOTSHOT: 'Hotshot',
} as const;

export const EUROPE_TRUCK_TYPES = {
    CURTAINSIDER: 'Curtainsider',
    TAUTLINER: 'Tautliner',
} as const;

export const GENERAL_TRUCK_SELECT_OPTIONS = [
    {label: GENERAL_TRUCK_TYPES.CARGO_VAN, value: GENERAL_TRUCK_TYPES.CARGO_VAN},
    {label: GENERAL_TRUCK_TYPES.REEFER_VAN, value: GENERAL_TRUCK_TYPES.REEFER_VAN, icon: 'fa-snowflake-o'},
    {label: GENERAL_TRUCK_TYPES.BOX_TRUCK, value: GENERAL_TRUCK_TYPES.BOX_TRUCK},
    {label: GENERAL_TRUCK_TYPES.BOX_TRUCK_REEFER, value: GENERAL_TRUCK_TYPES.BOX_TRUCK_REEFER, icon: 'fa-snowflake-o'},
    {label: GENERAL_TRUCK_TYPES.STRAIGHT_TRUCK, value: GENERAL_TRUCK_TYPES.STRAIGHT_TRUCK},
    {
        label: GENERAL_TRUCK_TYPES.STRAIGHT_TRUCK_REEFER,
        value: GENERAL_TRUCK_TYPES.STRAIGHT_TRUCK_REEFER,
        icon: 'fa-snowflake-o',
    },
    {label: 'Semi-truck', value: GENERAL_TRUCK_TYPES.SEMI_TRUCK},
    {label: GENERAL_TRUCK_TYPES.HOTSHOT, value: GENERAL_TRUCK_TYPES.HOTSHOT},
    {label: GENERAL_TRUCK_TYPES.CARHAULER, value: GENERAL_TRUCK_TYPES.CARHAULER},
    {label: GENERAL_TRUCK_TYPES.TENTED_BOX, value: GENERAL_TRUCK_TYPES.TENTED_BOX},
    {label: GENERAL_TRUCK_TYPES.ISOTHERM_TRUCK, value: GENERAL_TRUCK_TYPES.ISOTHERM_TRUCK, icon: 'fa-snowflake-o'},
    {label: GENERAL_TRUCK_TYPES.BOBTAIL_TRUCK, value: GENERAL_TRUCK_TYPES.BOBTAIL_TRUCK},
];

export const EUROPE_TRUCK_SELECT_OPTIONS = [
    ...GENERAL_TRUCK_SELECT_OPTIONS,

    {label: 'Tautliner-truck', value: EUROPE_TRUCK_TYPES.TAUTLINER},
    {label: EUROPE_TRUCK_TYPES.CURTAINSIDER, value: EUROPE_TRUCK_TYPES.CURTAINSIDER},
];
