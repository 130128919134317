import omitBy from 'lodash/omitBy';
import {QuoteSearchParams} from 'pages/LoadBoard/types';

type Params = Omit<QuoteSearchParams, 'createdBy'> & {dispatcherID?: number};

const getDispatcherId = (params: QuoteSearchParams): number | undefined => params.createdBy?.id;

export default function (params: QuoteSearchParams): Params {
    const updatedParams = {
        ...params,
        quoteNumber: Number(params.quoteNumber),
        dispatcherID: getDispatcherId(params),
    };
    return omitBy(updatedParams, (value) => !value);
}
