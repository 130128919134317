import {EntityListFromAPI} from '../types';

const entitySettings: Record<
    EntityListFromAPI,
    {label: string; titleSingular: string; titlePlural: string; apiAlias: EntityListFromAPI}
> = {
    Load: {
        label: 'Load Details',
        titlePlural: 'Loads',
        titleSingular: 'load',
        apiAlias: 'Load',
    },
    TravelOrder: {
        label: 'Travel order Details',
        titlePlural: 'Travel orders',
        titleSingular: 'travel order',
        apiAlias: 'TravelOrder',
    },
    Owners: {
        label: 'Owner Details',
        titlePlural: 'Owners',
        titleSingular: 'owner',
        apiAlias: 'Owners',
    },
    Drivers: {
        label: 'Driver Details',
        titlePlural: 'Drivers',
        titleSingular: 'driver',
        apiAlias: 'Drivers',
    },
    Trucks: {
        label: 'Truck Details',
        titlePlural: 'Trucks',
        titleSingular: 'truck',
        apiAlias: 'Trucks',
    },
    Carrier: {
        label: 'Carrier Details',
        titlePlural: 'Carriers',
        titleSingular: 'carrier',
        apiAlias: 'Carrier',
    },
    Customers: {
        label: 'Customer Details',
        titlePlural: 'Customers',
        titleSingular: 'customer',
        apiAlias: 'Customers',
    },
    Shippers: {
        label: 'Facility Details',
        titlePlural: 'Facilities',
        titleSingular: 'facility',
        apiAlias: 'Shippers',
    },
    FactoringCompany: {
        label: 'Factoring Company Details',
        titlePlural: 'Factoring Companies',
        titleSingular: 'factoringCompany',
        apiAlias: 'FactoringCompany',
    },
    Dispatchers: {
        label: 'User Details',
        titlePlural: 'Users',
        titleSingular: 'user',
        apiAlias: 'Dispatchers',
    },
    OwnersSettlements: {
        label: 'Owner Settlement Details',
        titlePlural: 'Owners Settlements',
        titleSingular: 'owner settlement',
        apiAlias: 'OwnersSettlements',
    },
    Invoices: {
        label: 'Invoice Details',
        titlePlural: 'Invoices',
        titleSingular: 'invoice',
        apiAlias: 'Invoices',
    },
    CarrierSettlement: {
        label: 'Carrier settlement',
        titlePlural: 'Carrier settlements',
        titleSingular: 'carrier settlement',
        apiAlias: 'CarrierSettlement',
    },
};

export default entitySettings;
