import head from 'lodash/head';
import omit from 'lodash/omit';

import restApi from 'services/restapi';

import {SearchParamsForBatchGeneration} from '../types/SearchParams';

const invoiceUrl = '/invoices';
const batchUrl = `${invoiceUrl}/batches`;

/**
 * @param {Object} params
 * @return {Promise}
 */

export const addNoteToInvoice = (invoiceID, note) => {
    return restApi.post(`${invoiceUrl}/${invoiceID}/note`, {note});
};

export const fetchInvoices = (params) => restApi.post(`${invoiceUrl}/search`, params);

export const fetchGeneralBatchInvoices = (batchNumber, searchParams, pagination) => {
    const requestParams = {...searchParams, page: pagination.page, 'per-page': pagination.perPage};
    return restApi.post(`invoices-by-batch/${batchNumber}`, requestParams);
};

export const fetchResetBatchInvoices = (batchNumber, searchParams, pagination) => {
    const requestParams = {...searchParams, page: pagination.page, 'per-page': pagination.perPage};
    return restApi.post(`reseted-invoices-by-batch/${batchNumber}`, requestParams);
};

export const resetBatchInvoices = (batchNumber, params) => {
    return restApi.put(`${batchUrl}/${batchNumber}/reset-invoices`, params);
};

export const fetchBatchFiles = (batchNumber: number, pagination: {page: number; perPage: number}) => {
    const requestParams = {page: pagination.page, 'per-page': pagination.perPage};
    return restApi.get(`files-by-batch/${batchNumber}`, {params: requestParams});
};

/**
 * @param {number} invoiceNumber
 * @return {Promise}
 */
export const fetchInvoice = (invoiceNumber) =>
    restApi.get(`${invoiceUrl}/${invoiceNumber}`).then((response) => response.data);

/**
 * @param {number} invoiceNumber
 * @return {Promise}
 */
export const fetchInvoiceBatches = (invoiceNumber) =>
    restApi.get(`${invoiceUrl}/${invoiceNumber}/batches`).then((response) => response.data);

/**
 * @param {number} invoiceID
 * @param {Object} data
 * @return {Promise}
 */
export const updateInvoiceData = (invoiceID, data) =>
    restApi.put(`${invoiceUrl}/${invoiceID}/update-data`, data).then((response) => response.data);

/**
 * @param {Object} invoice
 * @param {Object} statusData
 * @return {Promise}
 */
export const setNextInvoiceStatus = (invoice, statusData) => {
    return restApi.put(`${invoiceUrl}/${invoice.id}/next-status`, statusData).then((response) => response.data);
};

/**
 * @param {Object} invoice
 * @return {Promise}
 */
export const resetInvoiceStatus = (invoice) => {
    return restApi.put(`${invoiceUrl}/${invoice.id}/reset-status`).then((response) => response.data);
};

/**
 * Reset status of selected batch invoice and returns updated invoice
 * @param {object} invoiceData - settings of invoice
 * @return {Promise}
 */
export const resetBatchInvoiceStatus = (invoiceData) => {
    return restApi.put(`${batchUrl}/reset-invoice`, invoiceData).then((response) => omit(response.data, ['invoice']));
};

/**
 * @param {Object} params
 * @return {Promise}
 */

export const fetchBatches = (params) => restApi.post('/batches/search', params);

export const fetchFactoredCustomerCompany = (searchParams?: SearchParamsForBatchGeneration) => {
    const params = {billingMethod: 'factored', ...searchParams};
    return restApi.post('customers-invoices-count', params);
};

export const fetchDirectCustomerCompanies = (searchParams?: SearchParamsForBatchGeneration) => {
    const params = {billingMethod: 'direct', ...searchParams};
    return restApi.post('customers-invoices-count', params);
};

export const fetchCustomerCompany = (customerID: number, searchParams?: SearchParamsForBatchGeneration) => {
    const params = {...searchParams};
    return restApi.post(`customer-invoices-count/${customerID}`, params);
};

type FetchCustomerUsersParams = {
    customerID: number | null;
    pagination: {page: number; perPage: number};
};
export const fetchCustomerUsers = (params: FetchCustomerUsersParams, searchParams?: SearchParamsForBatchGeneration) => {
    const {customerID, pagination} = params;
    const requestParams = {
        customer: customerID || 'factored',
        page: pagination.page,
        'per-page': pagination.perPage,
        ...searchParams,
    };
    return restApi.post('invoices-by-customer', requestParams);
};

/**
 * @param {Object} batchData
 * @return {Promise}
 */
export const postBatch = (batchData) => restApi.post(batchUrl, {batch: batchData});

/**
 * @param {number} batchNumber
 * @param {Object} batchData
 * @return {Promise}
 */
export const putBatch = (batchNumber, batchData) => restApi.put(`${batchUrl}/${batchNumber}`, batchData);

/**
 * @param {number} invoiceID
 * @param {Object} pdfSettings
 * @return {Promise}
 */
export const generateInvoicePDF = (invoiceID, pdfSettings) =>
    restApi.post(`${invoiceUrl}/${invoiceID}/generate-invoice`, pdfSettings).then(({data}) => data);

/**
 * @param {number} batchNumber
 * @return {Promise}
 */
export const generateBatchPDF = (batchNumber) =>
    restApi.post(`${batchUrl}/${batchNumber}/generate-batch`).then(({data}) => head(data));

/**
 * Reset status of selected invoice inside batch and returns batch
 * @param {number} batchNumber
 * @param {Object} data
 * @return {Promise}
 */
export const resetInvoiceStatusInBatch = (batchNumber, data) => {
    return restApi
        .put(`${batchUrl}/${batchNumber}/reset-invoice`, data)
        .then((response) => omit(response.data, ['invoice']));
};

export const changeInvoiceDueDate = (invoiceID, date) => {
    return restApi.put(`${invoiceUrl}/${invoiceID}/due-date`, date);
};

export const regenerateBatchFileRequest = ({batchNumber}) => {
    return restApi.put(`${batchUrl}/${batchNumber}/regenerate-batch-file`);
};
