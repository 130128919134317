import {useDispatch} from 'react-redux';

import {Quote} from 'core/entities/Quote/types';

import {QuoteSearchParams} from '../types';
import * as actionCreators from '../redux/actionCreators';
import {fetchQuoteDriversReceivers, resendQuote, openArchiveQuoteModal} from '../redux/actions';

type QuoteActions = {
    fetchDriversReceivers: (quote: Quote) => void;
    toggleExpandQuoteOffers: (quote: Quote) => void;
    archiveQuote: (quote: Quote) => void;
    resendQuote: (quote: Quote) => void;
    setSearchParams: (params: QuoteSearchParams) => void;
};

export default (): QuoteActions => {
    const dispatch = useDispatch();
    return {
        fetchDriversReceivers: (quote: Quote) => dispatch(fetchQuoteDriversReceivers(quote)),
        toggleExpandQuoteOffers: (quote) => dispatch(actionCreators.toggleExpandQuoteOffers(quote)),
        archiveQuote: (quote) => dispatch(openArchiveQuoteModal(quote)),
        resendQuote: (quote) => dispatch(resendQuote(quote)),
        setSearchParams: (params) => dispatch(actionCreators.setSearchParams(params)),
    };
};
