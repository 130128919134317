import React from 'react';
import {useDispatch} from 'react-redux';
import {Modal} from 'react-bootstrap';

import Form from './components/Form';
import * as actions from './actions';

import {SendEmailData} from './types';

import styles from './styles.module.scss';

type OwnProps = {
    data: SendEmailData;
    onClose: () => void;
};

const SendEmail: React.FC<OwnProps> = ({data: {emailTo, nameTo}, onClose}) => {
    const dispatch = useDispatch();

    const onSendMessage = (payload): void => {
        dispatch(actions.sendEmailMessage({...payload, emailTo}, onClose));
    };

    return (
        <Modal
            dialogClassName={`standard-form-modal modal-sm ${styles.modal}`}
            bsPrefix="modal"
            show={true}
            onHide={onClose}
            backdrop="static"
        >
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>New Email</Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSendMessage} nameTo={nameTo} emailTo={emailTo} />
            </Modal.Body>
        </Modal>
    );
};

export default SendEmail;
