export const REQUIRED = 'Required';
export const LOCATION_REQUIRED = 'Please enter location.';
export const CITY_REQUIRED = 'Please enter city.';
export const STATE_REQUIRED = 'Please enter state.';
export const ZIP_REQUIRED = 'Please enter zip.';
export const COUNTRY_REQUIRED = 'Please enter country.';
export const MIN_VALID_STRING = 'Support only letters and digits, min two characters';
export const MAX_VALID_STRING = (max) => `should be max ${max} characters`;
export const LETTERS_AND_DIGITS = 'Input must contain only letters and digits, please retype it.';

export const NOT_CHOOSE_HIRED_BY = 'Please, choose the dispatcher from the list.';
export const NOT_CHOOSE_HIRE_DATE = 'Please, choose the date from calendar or type mm/dd/yyyy.';
export const NOT_CHOSEN_DATE = 'Please, choose the date from calendar or type mm/dd/yyyy.';
export const NOT_CHOSEN_COUNTRY_OF_BIRTH = 'Please, choose the place (country) of birth.';

export const INVALID_LENGTH = (min, max) => `Length must be between ${min} and ${max} letters and digits.`;
export const INVALID_NUMBER = (min, max) => `Number must be between ${min} and ${max} symbols.`;
export const INVALID_NICKNAME = 'Support only letters and digits, "-", ".", "_" max 45 characters';
export const ONLY_DIGITS = 'Only digits support';
export const ONLY_INTEGER_OR_FRACTIONAL_NUMBERS = 'Only integer or fractional numbers support';
export const ONLY_LETTERS = 'Input must contain only letters, please retype it.';
export const DIGITS_AND_SYMBOLS = 'Input must contain digits and symbols, please retype it.';
export const INVALID_DATE_FORMAT = 'Invalid date format, use "MM/DD/YY" (12/31/2000)';
export const INVALID_YEAR = 'Invalid year';
export const INVALID_CITY = 'Invalid city';
export const INVALID_TIME = 'Invalid time';
export const INVALID_WEBSITE = 'Invalid website/domain name, must be like: "domain.com';
export const INVALID_PHONE_NUMBER = 'Invalid phone/fax number format, use: "1(123)123-1234 ext 123"';
export const INVALID_PHONE_EXT = 'Extension must contain more than 1 digit, please retype it.';
export const INVALID_ADDRESS_FORMAT = 'Invalid address format, use format like "34 Street 7"';
export const INVALID_CITY_LINE_FORMAT = 'Invalid city line format, must be like "New York, NY, 10007, US"';
export const INVALID_VINCODE_LENGTH = 'Min 17 characters required';
export const INVALID_MC_US_DOT_NUMBER = 'Not valid MC/USDOT number, format must be like: "1Q23TR9K"';
export const INVALID_EMAIL_ADDRESS = 'Please enter a valid email address.';
export const INVALID_NAME = 'Input must contain only letters, please retype it.';
export const INVALID_MOBILE_PHONE = 'Please enter a valid phone number';
export const INVALID_POLICY_NUMBER = 'Input must contain only digits and letters, please retype it.';

export const IF_EMPTY_NAMES = (user, fistLastFull) => `Please enter ${user}'s ${fistLastFull} name.`;
export const IF_EMPTY_NAME_OF = (user, fistLastFull) => `Please enter ${fistLastFull} name of ${user}.`;
export const IF_EMPTY_ADDRESS = 'Please enter house number and street name.';
export const ADDRESS_LINE_REQUIRED = 'Please enter building number and street name.';
export const IF_EMPTY_EMAIL = 'Please enter email address.';
export const IF_EMPTY_SUBJECT = 'Please enter subject.';
export const IF_EMPTY_MESSAGE = 'Please enter message.';
export const IF_EMPTY_CSZ = (user, cityStateZipCountry) => `Please enter ${user}’s ${cityStateZipCountry}.`;

export const EMPTY_TRUCK_TYPE = 'Please, choose truck type';
export const EMPTY_TIME_FROM = 'Please, type time from';
export const EMPTY_TIME_TO = 'Please, type time to';
export const EMPTY_TIME = 'Please, type time';
export const EMPTY_DATE = 'Please, select date';
export const EMPTY_DATE_TIME = 'Please choose date and time';
export const END_DATE_LESS_START = 'End date must by greater or equal start date';

export const EMPTY_INSIDE_DIMS = 'Please enter inside dimensions of the truck';
export const EMPTY_DOOR_DIMS = 'Please enter door dimensions of the truck';

export const REQUIRED_VIN_CODE = 'Please enter a valid VIN code';

export const REQUIRED_MOBILE_PHONE = 'Please enter a phone number.';

export const INPUT_MUST_CONTAIN_DIGITS_OR_LETTERS = 'input must contain digits or letters';
export const CHECKBOX_REQUIRED_SEND_EMAIL = 'You should specify who will receive Pay Records: Owner or Coordinator';
export const PLEASE_CHOOSE_OWNER = 'Please, choose the owner from the list';

export const MUST_CONTAIN_LETTERS = 'Input must contain letters, please retype it';
