import React from 'react';
import {Modal} from 'react-bootstrap';

import {PayPeriod} from 'core/entities/Settlement/types';

import Form from './Form';

interface OwnProps {
    data: {payPeriods: PayPeriod[]};
    handlers: {onSubmitPayPeriod()};
    onClose();
}

const SetupPayPeriodModal = ({data: {payPeriods}, handlers: {onSubmitPayPeriod}, onClose}: OwnProps): JSX.Element => {
    return (
        <Modal show={true} onHide={onClose} dialogClassName="standard-form-modal" size="sm" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Post Settlements</Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form payPeriods={payPeriods} onSubmit={onSubmitPayPeriod} onClose={onClose} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default React.memo(SetupPayPeriodModal);
