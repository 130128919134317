import React from 'react';
import last from 'lodash/last';

import {createDate} from 'utils/dateTime';

import {getDriverName} from 'core/entities/Driver/modules/driverName';

import DriverAvatar from 'widgets/Chat/components/DriverAvatar';

import {DriverChannel as DriverChannelType} from '../../types';

import LastMessageContent from './LastMessageContent';
import styles from './driverChannel.module.scss';

interface OwnProps {
    channel: DriverChannelType;
    onEnterChannel(driverID: number);
}

const gerDriverTruckNumber = (driver): number | false => driver && driver.truck && driver.truck.number;

const DriverChannel: React.FC<OwnProps> = ({channel, onEnterChannel}) => {
    const lastMessage = last(channel.messages);
    const driverID = channel.driver.id;
    const driverIsOwner = channel.driver.is_owner;
    const driverName = getDriverName(channel.driver);
    const driverTruckNumber = gerDriverTruckNumber(channel.driver);
    const getLastMessage = (): JSX.Element => {
        if (!lastMessage) {
            return (
                <div className="driver-main-info__text_no-message">
                    <em>press to start chat</em>
                </div>
            );
        }
        return (
            <>
                <LastMessageContent lastMessage={lastMessage} />
                <div className="driver-main-info__text_date">
                    {createDate(lastMessage?.createdAt, {fromISO: true}).fullDate}
                </div>
            </>
        );
    };
    return (
        <div className={styles.item} key={driverID} onClick={() => onEnterChannel(driverID)}>
            <div className="driver-main-info">
                <DriverAvatar driver={channel.driver} avatar={channel.driverAvatar} withLink={false} />
                <div className="driver-main-info__text">
                    <div className="driver-main-info__text_title">
                        <strong>
                            #{driverTruckNumber} {driverName}
                        </strong>
                    </div>
                    {getLastMessage()}
                </div>
            </div>
            <div className="driver-type">
                <div>
                    <strong>{driverIsOwner ? '(o/d)' : '(1dr)'}</strong>
                </div>
                {channel.unreadMessagesCount > 0 && <div className="new-massage">{channel.unreadMessagesCount}</div>}
            </div>
        </div>
    );
};

export default React.memo(DriverChannel);
