import React from 'react';
import {Modal} from 'react-bootstrap';
import classNames from 'classnames';

import Truck from 'core/entities/Truck/types';
import {getNumberText} from 'components/common/Truck/TruckNumber/utils';

import TruckNotes from '../../TruckNotes';

import styles from './truckNotesModal.module.scss';

interface OwnProps {
    data: {
        truck: Truck;
    };
    handlers: {
        handleReceiveDispatcherNote();
    };
    onClose;
}

const TruckNotesModal = (props: OwnProps): JSX.Element | null => {
    const {
        data: {truck},
        handlers: {handleReceiveDispatcherNote},
    } = props;

    const truckNumber = getNumberText(truck, '#');

    return (
        <Modal show={true} dialogClassName={classNames(styles.modal, 'modal-note-dialogs')} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <strong>Truck {truckNumber}</strong> Notes
                </Modal.Title>
                <button type="button" className="close" onClick={props.onClose}>
                    <i className="fa fa-times" aria-hidden="true" />
                </button>
            </Modal.Header>
            <Modal.Body>
                <TruckNotes truck={truck} receiveDispatcherNote={handleReceiveDispatcherNote} />
            </Modal.Body>
        </Modal>
    );
};

export default TruckNotesModal;
