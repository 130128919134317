import React from 'react';
import ReactDom from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {PersistGate} from 'redux-persist/lib/integration/react';

import {store, persistor, history} from './store';
import App from './App';

window.WS = window.WS || {};

const AppComponent = (
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </PersistGate>
    </Provider>
);

ReactDom.render(AppComponent, document.getElementById('root'));
