export const GENERAL_TRAILER_TYPES = {
    CARHAULER_TRAILER: 'Carhauler trailer',
    STEPDECK_TRAILER: 'Stepdeck trailer',
    FLATBED_TRAILER: 'Flatbed trailer',
    REEFER_TRAILER: 'Reefer trailer',
    VAN_TRAILER: 'Van trailer',
    TENTED_VAN: 'Tented van',
    CONESTOGA: 'Conestoga',
    CONTAINER: 'Container',
    ISOTHERM: 'Isotherm',
    DRAYAGE: 'Drayage',
} as const;

export const EUROPE_TRAILER_TYPES = {
    CURTAINSIDER: 'Curtainsider',
    TAUTLINER: 'Tautliner',
} as const;

export const GENERAL_TRAILER_TYPES_SELECT_OPTIONS = [
    {label: 'Van', value: GENERAL_TRAILER_TYPES.VAN_TRAILER},
    {label: 'Reefer', value: GENERAL_TRAILER_TYPES.REEFER_TRAILER, icon: 'fa-snowflake-o'},
    {label: 'Flatbed', value: GENERAL_TRAILER_TYPES.FLATBED_TRAILER},
    {label: 'Stepdeck', value: GENERAL_TRAILER_TYPES.STEPDECK_TRAILER},
    {label: 'Carhauler', value: GENERAL_TRAILER_TYPES.CARHAULER_TRAILER},
    {
        label: GENERAL_TRAILER_TYPES.TENTED_VAN,
        value: GENERAL_TRAILER_TYPES.TENTED_VAN,
    },
    {label: GENERAL_TRAILER_TYPES.ISOTHERM, value: GENERAL_TRAILER_TYPES.ISOTHERM, icon: 'fa-snowflake-o'},
    {label: GENERAL_TRAILER_TYPES.CONESTOGA, value: GENERAL_TRAILER_TYPES.CONESTOGA},
    {label: GENERAL_TRAILER_TYPES.DRAYAGE, value: GENERAL_TRAILER_TYPES.DRAYAGE},
    {label: GENERAL_TRAILER_TYPES.CONTAINER, value: GENERAL_TRAILER_TYPES.CONTAINER},
];

export const EUROPE_TRAILER_TYPES_SELECT_OPTIONS = [
    ...GENERAL_TRAILER_TYPES_SELECT_OPTIONS,

    {label: EUROPE_TRAILER_TYPES.TAUTLINER, value: EUROPE_TRAILER_TYPES.TAUTLINER},
    {label: EUROPE_TRAILER_TYPES.CURTAINSIDER, value: EUROPE_TRAILER_TYPES.CURTAINSIDER},
];
