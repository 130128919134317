import React from 'react';

import {SettlementOwner, SettlementCarrier} from 'core/entities/Settlement/types';
import {isSettlementTravelOrderDryRun} from 'core/entities/Settlement';

import {transformDistance} from 'utils/distance';

import {getMilesFromSettlement} from './utils';

type OwnProps = {
    settlement: SettlementOwner | SettlementCarrier;
};

const SettlementMilesInfo: React.FC<OwnProps> = ({settlement}) => {
    const settlementMiles = getMilesFromSettlement(settlement);
    const isDryRun = isSettlementTravelOrderDryRun(settlement);

    const convertedEmptyMiles = transformDistance(settlementMiles.empty);
    const convertedLoadedMiles = transformDistance(settlementMiles.loaded);

    if (isDryRun) {
        return <span>{convertedEmptyMiles.fromMiles}</span>;
    }

    return (
        <>
            <span>{convertedEmptyMiles.fromMiles}</span>
            <br />
            <strong>{convertedLoadedMiles.fromMiles}</strong>
        </>
    );
};

export default React.memo(SettlementMilesInfo);
