import React, {useState, useEffect, useRef} from 'react';
import {change, WrappedFieldProps} from 'redux-form';

import InputWithPlacesAutocomplete, {AddressData} from 'components/ui/Form/InputWithPlacesAutocomplete';

import RequiredLabel from '../RequiredLabel';

interface SelectedLocation extends AddressData {
    cityLine: string;
}

type OwnProps = {
    label?: JSX.Element | string;
    longitudeFieldName?: string;
    latitudeFieldName?: string;
    countryFieldName?: string;
    stateFieldName?: string;
    withSubmitBtn?: boolean;
    cityFieldName?: string;
    zipFieldName?: string;
    placeholder?: string;
    isStrictZip: boolean;
    isRequired: boolean;
    isDisabled: boolean;
    skipLabel?: boolean;
    clearable?: boolean;

    // handlers
    onSelectLocation?: (params: SelectedLocation) => void;
    onGetAddress: (address: AddressData) => void;
};

const LocationFields = (props: WrappedFieldProps & OwnProps): JSX.Element => {
    const {
        isStrictZip = false,
        longitudeFieldName,
        isDisabled = false,
        latitudeFieldName,
        isRequired = true,
        countryFieldName,
        stateFieldName,
        cityFieldName,
        withSubmitBtn,
        zipFieldName,
        placeholder,
        clearable,
        skipLabel,
        input,
        label,
        meta,

        // handlers
        onSelectLocation,
        onGetAddress,
    } = props;

    const ref = useRef<string>(input.value);
    const [currentLocation, setCurrentLocation] = useState(input.value || '');

    const changeFields = (placeItem: AddressData): void => {
        const changeFormField = (fieldName: string, fieldValue: string): void => {
            meta.dispatch(change(meta.form, fieldName, fieldValue));
        };
        if (cityFieldName) {
            changeFormField(cityFieldName, placeItem.address.city);
        }
        if (stateFieldName) {
            changeFormField(stateFieldName, placeItem.address.state);
        }
        if (zipFieldName) {
            changeFormField(zipFieldName, placeItem.address.zip);
        }
        if (countryFieldName) {
            changeFormField(countryFieldName, placeItem.address.country);
        }
        if (latitudeFieldName) {
            changeFormField(latitudeFieldName, placeItem.coords.lat);
        }
        if (longitudeFieldName) {
            changeFormField(longitudeFieldName, placeItem.coords.lng);
        }
    };

    const customInput = {
        name: input.name,
        onChange: (changedLocation: string) => {
            setCurrentLocation(changedLocation);
            if (!changedLocation && input.value) {
                input.onChange('');
            }
        },
        onBlur: () => {
            const locationFromLastSelectedPlace = ref.current;
            if (currentLocation && currentLocation !== locationFromLastSelectedPlace) {
                setCurrentLocation(locationFromLastSelectedPlace);
            }
        },
        value: currentLocation,
    };

    const handleSelectPlace = (place: AddressData): void => {
        input.onChange(place.cityLine);
        ref.current = place.cityLine;
        changeFields(place);
        if (onSelectLocation) {
            onSelectLocation(place);
        }
    };

    const newLabel = isRequired ? RequiredLabel('Location') : 'Location';

    // effect for reset inner input value after form reset
    useEffect(() => {
        if (input.value !== currentLocation) {
            setCurrentLocation(input.value);
        }
    }, [input.value, meta.initial]);

    return (
        <div className="city-state-block_item">
            <InputWithPlacesAutocomplete
                placeholder={placeholder || 'enter location'}
                label={skipLabel ? null : label || newLabel}
                withSubmitBtn={withSubmitBtn}
                isStrictZip={isStrictZip}
                withUnknownValues={true}
                disabled={isDisabled}
                clearable={clearable}
                searchByCity={false}
                input={customInput}
                isOpen={false}
                meta={meta}
                // handlers
                onSelectPlace={handleSelectPlace}
                onGetAddress={onGetAddress}
            />
        </div>
    );
};

export default LocationFields;
