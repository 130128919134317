const getParsedJsonOrNull = (json) => {
    let result;

    try {
        result = JSON.parse(json);
    } catch (e) {
        result = null;
    }

    return result;
};

export default getParsedJsonOrNull;
