import React, {PureComponent} from 'react';
import classNames from 'classnames';
import Button from 'components/ui/Buttons/Button';
import Tooltip from '../Tooltip';

export default class ButtonsBlock extends PureComponent {
    state = {
        expanded: false,
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setContainerRef = (element) => {
        this.containerNode = element;
    };

    handleClickOutside = (event) => {
        if (this.state.expanded && this.containerNode && !this.containerNode.contains(event.target)) {
            this.setState({expanded: false});
        }
    };

    toggleExpand = () => {
        this.setState((prevState) => ({expanded: !prevState.expanded}));
    };

    render() {
        const {buttonIcon = 'ellipsis-v', buttonSize = 'icon', className = 'btn-link', disabled} = this.props;
        const containerClassName = classNames('button-block', this.state.expanded && 'active');

        return (
            <div ref={this.setContainerRef} className={containerClassName}>
                <Tooltip tooltipContent="view more" typeContent="button" position="right">
                    <Button
                        onClick={this.toggleExpand}
                        buttonIcon={buttonIcon}
                        buttonSize={buttonSize}
                        className={className}
                        disabled={disabled}
                    />
                    <div className="button-block__list">{this.props.children}</div>
                </Tooltip>
            </div>
        );
    }
}
