import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import {isChangeLocationAction} from 'routing/utils/index';
import Invoice from 'core/entities/Invoice/types';
import Batch from 'core/entities/Invoice/types/Batch';
import {addVirtualStopToInvoice, removeVirtualStopFromInvoice} from 'core/entities/Invoice';

import {getData, updateItem, NormalizedData} from 'utils/normalizer';

import * as types from '../actionTypes/index';
import {isWebsocketAction, updateInvoiceLoadData, updateInvoiceData} from './utils';
import websockets from './websockets';
import locationChangeCommonReducer from './locationChangeCommonReducer';

export type DataState = {
    invoices: NormalizedData<Invoice>;
    batches: NormalizedData<Batch>;
};

const defaultState: DataState = {
    invoices: {
        byId: {},
        allIds: [],
    },
    batches: {
        byId: {},
        allIds: [],
    },
};

export const isInvoicesExists = (state: DataState): boolean => {
    return !isEmpty(state.invoices.byId);
};

export const isInvoiceInState = (state: DataState, invoice: Invoice): boolean => {
    return !isEmpty(state.invoices.byId[invoice.id]);
};

export const isBatchesExists = (state: DataState): boolean => {
    return !isEmpty(state.batches.byId);
};

export const isBatchInState = (state: DataState, batch: Batch): boolean => {
    return !isEmpty(state.batches.byId[batch.id]);
};

export const getInvoiceByID = (state: DataState, invoiceID: number): Invoice | undefined => {
    return state.invoices.byId[invoiceID];
};

export const getBatchByID = (state: DataState, batchID: number): Batch | undefined => {
    return state.batches.byId[batchID];
};

const listInvoicesReducer = (state = defaultState, action) => {
    if (isChangeLocationAction(action)) {
        return locationChangeCommonReducer(state, defaultState, action);
    }

    if (isWebsocketAction(action)) {
        return websockets(state, action);
    }

    switch (action.type) {
        case types.INVOICE_DATA_RECEIVED: {
            const {invoice} = action.payload;
            return {
                ...state,
                invoices: getData<Invoice>([invoice]),
            };
        }

        case types.LIST_INVOICES_RECEIVED: {
            const {invoices, pagination} = action.payload;
            return {
                ...state,
                invoices: getData<Invoice>(invoices),
                pagination,
            };
        }

        case types.LIST_BATCHES_RECEIVED: {
            const {batches} = action.payload;
            return {
                ...state,
                batches: getData<Batch>(batches),
            };
        }

        case types.INVOICE_STATUS_CHANGED: {
            const {updatedInvoice} = action.payload;
            if (!isInvoicesExists(state) || !updatedInvoice) {
                return state;
            }
            return {
                ...state,
                invoices: updateItem<Invoice>(state.invoices, updatedInvoice),
            };
        }

        case types.INVOICE_LOAD_UPDATED: {
            const {invoiceID, load} = action.payload;
            const currentInvoice = getInvoiceByID(state, invoiceID);
            const travelOrderOfCurrentLoad = currentInvoice?.load?.travel_order;
            if (!currentInvoice) {
                return state;
            }
            const updatedInvoice = {
                ...currentInvoice,
                load: {
                    ...load,
                    travel_order: travelOrderOfCurrentLoad,
                },
            };
            return {
                ...state,
                invoices: updateItem<Invoice>(state.invoices, updatedInvoice),
            };
        }

        case types.INVOICE_LOAD_CUSTOMER_UPDATED: {
            const {invoiceID, customer} = action.payload;
            const currentInvoice = getInvoiceByID(state, invoiceID);
            if (!currentInvoice || !customer) {
                return state;
            }
            const updatedInvoice = updateInvoiceLoadData(currentInvoice, {fieldName: 'customer', fieldValue: customer});
            return {
                ...state,
                invoices: updateItem<Invoice>(state.invoices, updatedInvoice),
            };
        }

        case types.INVOICE_DUE_DATE_UPDATED: {
            const {invoiceID, dueDate} = action.payload;
            const currentInvoice = getInvoiceByID(state, invoiceID);
            if (!currentInvoice && !dueDate) {
                return state;
            }
            const updatedInvoice = updateInvoiceData(currentInvoice, {
                fieldName: 'due_date',
                fieldValue: dueDate,
            });
            return {
                ...state,
                invoices: updateItem<Invoice>(state.invoices, updatedInvoice),
            };
        }

        case types.INVOICE_LOAD_REF_NUMBER_UPDATED: {
            const {invoiceID, refNumber} = action.payload;
            const currentInvoice = getInvoiceByID(state, invoiceID);
            if (!currentInvoice || !refNumber) {
                return state;
            }
            const updatedInvoice = updateInvoiceLoadData(currentInvoice, {
                fieldName: 'ref_number',
                fieldValue: refNumber,
            });
            return {
                ...state,
                invoices: updateItem<Invoice>(state.invoices, updatedInvoice),
            };
        }

        case types.INVOICE_TRAVEL_ORDER_UPDATED: {
            const {invoiceID, travelOrder} = action.payload;
            const currentInvoice = getInvoiceByID(state, invoiceID);

            if (!currentInvoice || !travelOrder) {
                return state;
            }
            const updatedInvoice = {
                ...currentInvoice,
                load: {
                    ...currentInvoice.load,
                    travel_order: [travelOrder],
                },
            };
            return {
                ...state,
                invoices: updateItem<Invoice>(state.invoices, updatedInvoice),
            };
        }

        case types.INVOICE_LOAD_AND_TRAVEL_ORDER_UPDATED: {
            const {invoiceID, loadWithTravelOrder} = action.payload;
            const currentInvoice = getInvoiceByID(state, invoiceID);
            if (!currentInvoice || !loadWithTravelOrder) {
                return state;
            }
            // because for invoice using different data structure between load and travel order
            // for this case wen we want update load and travel order data we should manually create needed data structure
            const loadDataUsingInInvoice = {
                ...loadWithTravelOrder.load,
                travel_order: [omit(loadWithTravelOrder, ['load'])],
            };
            const updatedInvoice = {
                ...currentInvoice,
                load: loadDataUsingInInvoice,
            };
            return {
                ...state,
                invoices: updateItem<Invoice>(state.invoices, updatedInvoice),
            };
        }

        case types.INVOICE_LOAD_TOTAL_RATE_UPDATED: {
            const {invoiceID, totalRate} = action.payload;
            const currentInvoice = getInvoiceByID(state, invoiceID);
            if (!currentInvoice || !totalRate) {
                return state;
            }
            const updatedInvoice = updateInvoiceLoadData(currentInvoice, {
                fieldName: 'total_rate',
                fieldValue: totalRate,
            });
            return {
                ...state,
                invoices: updateItem<Invoice>(state.invoices, updatedInvoice),
            };
        }

        case types.INVOICE_SET_APPROVE_SETTLEMENT_STATUS: {
            const {isNeedApproveSettlement} = action.payload;

            return {
                ...state,
                isNeedApproveSettlement,
            };
        }

        case types.INVOICE_INSERT_VIRTUAL_STOP: {
            const {invoiceID, stopIndex, stopData} = action.payload;
            const currentInvoice = getInvoiceByID(state, invoiceID);
            if (!currentInvoice || stopIndex === undefined || stopData === undefined) {
                return state;
            }
            const updatedInvoice = addVirtualStopToInvoice(currentInvoice, stopIndex, stopData);

            return {
                ...state,
                // @ts-ignore
                invoices: updateItem<Invoice>(state.invoices, updatedInvoice as Invoice),
            };
        }

        case types.INVOICE_REMOVE_VIRTUAL_STOP: {
            const {invoiceID, stopIndex} = action.payload;
            const currentInvoice = getInvoiceByID(state, invoiceID);
            if (!currentInvoice || stopIndex === undefined) {
                return state;
            }
            const updatedInvoice = removeVirtualStopFromInvoice(currentInvoice, stopIndex);

            return {
                ...state,
                invoices: updateItem<Invoice>(state.invoices, updatedInvoice as Invoice),
            };
        }

        case types.BATCH_UPDATED: {
            const {updatedBatch} = action.payload;

            if (!isBatchesExists(state) || !updatedBatch) {
                return state;
            }
            return {
                ...state,
                batches: updateItem<Batch>(state.batches, updatedBatch),
            };
        }

        case types.BATCH_POSTED: {
            const {postedBatch} = action.payload;

            if (!isBatchesExists(state) || !postedBatch) {
                return state;
            }

            return {
                ...state,
                batches: updateItem<Batch>(state.batches, postedBatch),
            };
        }

        default:
            return state;
    }
};

export default listInvoicesReducer;
