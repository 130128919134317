import React from 'react';

import styles from './withoutPayRecords.module.scss';

const WithoutPayRecordsWarning = (): JSX.Element | null => {
    return (
        <div className={styles.message}>
            <div className="modal-body__without-settlements">
                <i className="fa fa-exclamation-circle" /> There are no pay records in current pay period.
            </div>
        </div>
    );
};

export default WithoutPayRecordsWarning;
