import React, {useReducer, useEffect} from 'react';

import {DriverReceiverWithTruck} from 'core/entities/Quote/types';

import Options from './components/Options';
import ReceiverItem from './components/ReceiverItem';

import {reducer, driverFilterOptions} from './state';
import styles from './styles.module.scss';

type OwnProps = {
    driversReceivers: DriverReceiverWithTruck[];
};

const ListReceivers: React.FC<OwnProps> = (props) => {
    const {driversReceivers} = props;

    const [state, dispatch] = useReducer(reducer, {
        allItems: [],
        currentItems: [],
        activeOptions: [
            driverFilterOptions.allowedSendToDriver,
            driverFilterOptions.notAllowedSendToDriver,
            driverFilterOptions.driverDeclinedQuote,
            driverFilterOptions.driverReadQuote,
            driverFilterOptions.driverProvidedQuote,
        ],
        sentChecked: true,
        notSentChecked: true,
        declinedChecked: true,
        readChecked: true,
        providedChecked: true,
    });

    const toggleAllowedSentItems = (): void => {
        dispatch({type: 'toggleAllowedSendToDriver'});
    };

    const toggleNotAllowedSentItems = (): void => {
        dispatch({type: 'toggleNotAllowedSendToDriver'});
    };

    const toggleDeclinedItems = (): void => {
        dispatch({type: 'toggleDriverDeclinedQuote'});
    };

    const toggleReadItems = (): void => {
        dispatch({type: 'toggleDriverReadQuote'});
    };

    const toggleProvidedItems = (): void => {
        dispatch({type: 'toggleDriverProvidedQuote'});
    };

    useEffect(() => {
        dispatch({type: 'setItems', payload: {driversReceivers}});
    }, [driversReceivers]);

    return (
        <div>
            <div className={styles.statusWrapper}>
                <Options
                    driversReceivers={driversReceivers}
                    sentItemsChecked={state.sentChecked}
                    notSentItemsChecked={state.notSentChecked}
                    declinedItemsChecked={state.declinedChecked}
                    readItemsChecked={state.readChecked}
                    providedItemsChecked={state.providedChecked}
                    toggleSent={toggleAllowedSentItems}
                    toggleNotSent={toggleNotAllowedSentItems}
                    toggleDeclined={toggleDeclinedItems}
                    toggleRead={toggleReadItems}
                    toggleProvided={toggleProvidedItems}
                />
            </div>
            <div className={`standard-table-style ${styles.scroll}`}>
                <table className={`table ${styles.table}`}>
                    <thead>
                        <tr>
                            <th>TRUCKS</th>
                            <th>MAIN CONTACT</th>
                            <th>LOCATIONS</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.currentItems.map((r, index) => (
                            <ReceiverItem key={index} receiver={r} />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ListReceivers;
