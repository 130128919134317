import React from 'react';
import {Modal} from 'react-bootstrap';

import Batch from 'core/entities/Invoice/types/Batch';

import Button from 'components/ui/Buttons/Button/index';

type OwnProps = {
    onClose();
    data: {batch: Batch};
};

const BatchSuccessCreatedModal = (props: OwnProps): JSX.Element => {
    const {
        onClose,
        data: {batch},
    } = props;
    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Successful create</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <p className="mb10">
                            Batch <strong>#{batch.number}</strong> has been created successfully.
                        </p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} buttonSize="small" colorTheme="green">
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BatchSuccessCreatedModal;
