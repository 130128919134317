import * as appActions from 'store/actions';
import {showFormClearFieldsWarning} from 'store/actions/modalActions';

import {fetchLoadFiles} from 'deprecated/core/entities/Load/requests';
import {fetchTravelOrder, fetchTravelOrderFiles} from 'deprecated/core/gateways/TravelOrderApiGateway/requests';

import Invoice from 'core/entities/Invoice/types';
import Batch from 'core/entities/Invoice/types/Batch';
import {SearchParamsForBatchGeneration} from 'core/entities/Invoice/types/SearchParams';
import {
    fetchFactoredCustomerCompany,
    fetchDirectCustomerCompanies,
    fetchCustomerCompany,
} from 'core/entities/Invoice/requests';
import {getInvoiceTravelOrder, getInvoiceLoad} from 'core/entities/Invoice';

import {closeAll, openModal, closeModal} from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import {ENTITY_NAME_CUSTOMER} from 'utils/data/entityNames';

import * as invoiceActions from './invoiceActions';
import * as selectors from '../selectors';
import {billingModalNames} from '../components/common/modals/modalMap';

import {generateInvoice, resetInvoiceStatus, createInvoiceCustomer, insertVirtualStop} from './invoiceActions';
import {createBatch, generateBatch, updateBatch, resetBatchInvoices} from './batchActions';

const getFactoredCustomersCompany = (searchParams?: SearchParamsForBatchGeneration) => {
    return fetchFactoredCustomerCompany(searchParams)
        .then((response) => response.data)
        .catch((err) => {
            console.error('Error on fetch factored invoices for setup batch: ', err);
            return {};
        });
};

const getDirectCustomerCompanies = (searchParams: SearchParamsForBatchGeneration) => {
    return fetchDirectCustomerCompanies(searchParams)
        .then((response) => response.data)
        .catch((err) => {
            console.error('Error on fetch direct invoices for setup batch: ', err);
            return {};
        });
};

const getBatchCustomerCompany = (batchCustomer) => {
    return fetchCustomerCompany(batchCustomer.id)
        .then((response) => response.data)
        .catch((err) => {
            console.error('Error on fetch batch customer company for edit batch: ', err);
            return {};
        });
};

const fetchDataForSetupBatch = (searchParams: SearchParamsForBatchGeneration) => {
    return Promise.all([getFactoredCustomersCompany(searchParams), getDirectCustomerCompanies(searchParams)]);
};

export function openApproveInvoiceModal(invoice) {
    return function (dispatch) {
        const openApproveInvoiceModalAction = openModal({
            modalName: billingModalNames.approveInvoice,
            handlers: {
                onApproveInvoice: () => dispatch(invoiceActions.approveInvoice(invoice)),
            },
            data: {invoice},
        });
        dispatch(openApproveInvoiceModalAction);
    };
}

export function openResetInvoiceStatusModal(invoice: Invoice) {
    return function (dispatch) {
        const openApproveInvoiceModalAction = openModal({
            modalName: billingModalNames.resetInvoice,
            handlers: {
                onResetInvoice: () => {
                    dispatch(resetInvoiceStatus(invoice));
                    dispatch(closeAll());
                },
            },
            data: {invoice},
        });
        dispatch(openApproveInvoiceModalAction);
    };
}

export function openGenerateInvoicePDFModal(invoice) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        const invoiceTravelOrder = getInvoiceTravelOrder(invoice);
        const invoiceLoad = getInvoiceLoad(invoice);
        const requests = [fetchLoadFiles(invoiceLoad?.id)];
        if (invoiceTravelOrder) {
            requests.push(fetchTravelOrder(invoiceTravelOrder.id));
            requests.push(fetchTravelOrderFiles(invoiceTravelOrder.id));
        }
        const [loadFiles, fullTravelOrder, travelOrderFiles = []] = await Promise.all(requests);
        const openSetupBatchModalAction = openModal({
            modalName: billingModalNames.generatePDF,
            handlers: {
                onGeneratePDF: (settingsForGenerate) => {
                    dispatch(generateInvoice(invoice, settingsForGenerate));
                    dispatch(closeAll());
                },
            },
            data: {invoice, loadFiles, fullTravelOrder, travelOrderFiles},
        });

        dispatch(openSetupBatchModalAction);
        dispatch(appActions.hideLoader());
    };
}

export function openSetupBatchModal(searchParams: SearchParamsForBatchGeneration) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        const [factoredCompany, directCompanies] = await fetchDataForSetupBatch(searchParams);

        dispatch(appActions.hideLoader());

        const openSetupBatchModalAction = openModal({
            modalName: billingModalNames.setupBatch,
            handlers: {
                onSubmitBatch: (submitData) => {
                    dispatch(createBatch(submitData));
                    dispatch(closeAll());
                },
            },
            data: {factoredCompany, directCompanies},
        });
        dispatch(openSetupBatchModalAction);
    };
}

export function openBatchCreatedSuccessModal(batch) {
    return function (dispatch) {
        const action = openModal({
            modalName: billingModalNames.batchSuccessCreatedModal,
            data: {batch},
        });
        dispatch(action);
    };
}

export function openEditBatchModal(batch) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        let directCompany;
        let factoredCompany;

        dispatch(appActions.hideLoader());

        if (batch.customer) {
            directCompany = await getBatchCustomerCompany(batch.customer);
        } else {
            factoredCompany = await getFactoredCustomersCompany();
        }

        const openSetupBatchModalAction = openModal({
            modalName: billingModalNames.setupBatch,
            handlers: {
                onSubmitBatch: (submitData) => {
                    dispatch(updateBatch(batch.number, submitData));
                    dispatch(closeAll());
                },
            },
            data: {factoredCompany, directCompanies: directCompany ? [directCompany] : [], batch, isEdit: true},
        });

        dispatch(openSetupBatchModalAction);
    };
}

export function openPostBatchModal(batch) {
    return function (dispatch) {
        const openSetupBatchModalAction = openModal({
            modalName: billingModalNames.postBatch,
            handlers: {
                onPostBatch: () => {
                    dispatch(generateBatch(batch));
                    dispatch(closeAll());
                },
            },
            data: {batch},
        });
        dispatch(openSetupBatchModalAction);
    };
}

export function openGenerationFailedModal(errorFiles) {
    return function (dispatch) {
        const openGenerationFailedModalAction = openModal({
            modalName: billingModalNames.generationFailed,
            data: {errorFiles},
        });
        dispatch(openGenerationFailedModalAction);
    };
}

export function openChangeStatusFailedModal(message?) {
    return function (dispatch) {
        const openChangeStatusFailedAction = openModal({
            modalName: billingModalNames.errorChangeStatus,
            data: {message},
        });
        dispatch(openChangeStatusFailedAction);
    };
}

export function openResetInvoiceFailedModal() {
    return function (dispatch) {
        const openChangeStatusFailedAction = openModal({
            modalName: billingModalNames.errorResetInvoice,
        });
        dispatch(openChangeStatusFailedAction);
    };
}

export function openCreateInvoiceCustomerModal() {
    return function (dispatch, getState) {
        const openCreateCustomerModalAction = openModal({
            modalName: billingModalNames.createCustomer,
            data: {},
            handlers: {
                onReset: (formName) => dispatch(showFormClearFieldsWarning(formName)),
                onCancel: () => {
                    dispatch(
                        openModal({
                            modalName: commonModalNames.informationModal,
                            data: {
                                title: 'Cancel Create',
                                leftButtonTitle: `Keep Creating`,
                                rightButtonTitle: `Cancel Creating`,
                                bodyType: 'CancelForm',
                                buttonType: 'danger',
                            },
                            handlers: {
                                leftButtonHandler: () => {},
                                rightButtonHandler: () => dispatch(closeAll()),
                            },
                        }),
                    );
                },
                onCreate: (customerData) => {
                    const currentInvoice = selectors.getCurrentInvoice(getState());
                    dispatch(createInvoiceCustomer(currentInvoice, customerData));
                    dispatch(closeAll());
                },
            },
        });
        dispatch(openCreateCustomerModalAction);
    };
}

export function showSuccessfulCreateCustomerModal(createdCustomer) {
    return function (dispatch) {
        dispatch(
            openModal({
                modalName: commonModalNames.informationModal,
                data: {
                    title: 'Successful Create',
                    toProfileLabel: createdCustomer.name,
                    entityName: ENTITY_NAME_CUSTOMER,
                    entity: createdCustomer,
                    bodyType: 'SuccessFormCreate',
                    buttonType: 'success',
                },
                handlers: {
                    rightButtonHandler: () => {},
                },
            }),
        );
    };
}

export function showAddVirtualStopModal(data) {
    return function (dispatch, getState) {
        const state = getState();
        const currentInvoice = selectors.getCurrentInvoice(state);
        dispatch(
            openModal({
                modalName: billingModalNames.addVirtualStop,
                data,
                handlers: {
                    onAddStop: (stopIndex, stopData) =>
                        dispatch(insertVirtualStop(currentInvoice, stopIndex, stopData)),
                },
            }),
        );
    };
}

export function openBatchGeneralInvoicesModal(batch) {
    return function (dispatch) {
        if (!batch) {
            return;
        }
        const openModalAction = openModal({
            modalName: billingModalNames.batchInvoicesModal,
            data: {batch, displayOnlyResetInvoices: false},
            handlers: {},
        });
        dispatch(openModalAction);
    };
}

export function openBatchResetInvoicesModal(batch) {
    return function (dispatch) {
        if (!batch) {
            return;
        }
        const openModalAction = openModal({
            modalName: billingModalNames.batchInvoicesModal,
            data: {batch, displayOnlyResetInvoices: true},
            handlers: {},
        });
        dispatch(openModalAction);
    };
}

export function openResetBatchInvoicesModal(batch: Batch, invoices: Invoice[]): (dispatch: any) => Promise<void> {
    return function (dispatch): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!batch || !invoices) {
                reject(new Error('Incorrect batch or invoices on reset'));
                return;
            }
            let resetModalID;
            const openModalAction = openModal({
                modalName: billingModalNames.resetBatchInvoicesModal,
                data: {batch, invoices},
                handlers: {
                    onSubmit: () => {
                        const reset = dispatch(resetBatchInvoices(batch.number, invoices));
                        reset.then((result) => {
                            resolve(result);
                            dispatch(closeModal(resetModalID));
                        });
                    },
                },
            });
            resetModalID = openModalAction?.payload?.id;
            dispatch(openModalAction);
        });
    };
}
