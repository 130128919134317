import React from 'react';

import {getRemainingTimeTo} from 'utils/dateTime';

import {ReservingTimerContext} from './ReservingTimerContext';
import {IsTruckReservedContext} from './IsTruckReservedContext';

const withTruckReservingTimer = (WrappedComponent) =>
    class extends React.PureComponent {
        constructor(props) {
            super(props);

            const truckReserving = props.truck?.reserve;
            const isExpired = truckReserving ? this.getRemainingReserveDate(truckReserving)?.isExpired : false;
            this.state = {
                expiresIn: null,
                isExpired,
            };
        }

        componentDidMount() {
            const truckReserving = this.props.truck?.reserve;
            if (!truckReserving) {
                return;
            }
            const {isExpired} = this.getRemainingReserveDate(truckReserving);
            if (!isExpired) {
                this.createTimer();
            }
        }

        componentDidUpdate(prevProps) {
            const prevReserving = prevProps.truck.reserve;
            const currentReserving = this.props.truck.reserve;
            const isTruckReservedFirstTime = !prevReserving && currentReserving;
            const isTruckReservingUpdated =
                prevReserving && currentReserving && prevReserving.dateTo !== currentReserving.dateTo;

            if (isTruckReservedFirstTime || isTruckReservingUpdated) {
                this.updateStateFromProps();
                this.createTimer();
                return;
            }

            const {isExpired: isCurrentReservingExpired} = this.getRemainingReserveDate(currentReserving);
            if (isCurrentReservingExpired) {
                // this.updateStateFromProps();
                this.clearTimer();
                return;
            }

            const isReservingDeleted = prevReserving && !currentReserving;
            if (isReservingDeleted) {
                this.updateStateFromProps();
                this.clearTimer();
            }
        }

        componentWillUnmount() {
            window.clearInterval(this.intervalID);
        }

        setExpired = () => {
            this.setState(() => ({
                expiresIn: null,
                isExpired: true,
            }));
        };

        getRemainingReserveDate = (truckReserving) => {
            const remainingTime = getRemainingTimeTo(truckReserving?.dateTo);
            return {
                hours: remainingTime.duration.hours,
                min: remainingTime.duration.minutes,
                sec: remainingTime.duration.seconds,
                isExpired: remainingTime.isExpired,
            };
        };

        createTimer = () => {
            window.clearInterval(this.intervalID);
            const intervalTime = 1000;

            this.intervalID = window.setInterval(() => {
                const currentReserving = this.props.truck.reserve;
                const {hours, min, sec, isExpired} = this.getRemainingReserveDate(currentReserving);

                if (!isExpired) {
                    this.setState(() => ({
                        expiresIn: {
                            hours,
                            min,
                            sec,
                        },
                    }));
                } else {
                    window.clearInterval(this.intervalID);

                    this.setState(() => ({
                        expiresIn: null,
                        isExpired: true,
                    }));
                }
            }, intervalTime);
        };

        clearTimer = () => {
            window.clearInterval(this.intervalID);
        };

        updateStateFromProps = () => {
            const currentReserving = this.props.truck.reserve;
            const {hours, min, sec, isExpired} = this.getRemainingReserveDate(currentReserving);
            this.setState(() => ({
                expiresIn: {hours, min, sec},
                isExpired,
            }));
        };

        render() {
            const {truck} = this.props;
            const isReserved = Boolean(truck.reserve && !this.state.isExpired);
            return (
                <IsTruckReservedContext.Provider value={isReserved}>
                    <ReservingTimerContext.Provider value={this.state}>
                        <WrappedComponent {...this.props} />
                    </ReservingTimerContext.Provider>
                </IsTruckReservedContext.Provider>
            );
        }
    };

export default withTruckReservingTimer;
