import CheckCallModal from './CheckCallModal';
import BanEventModal from './BanEventModal';
import ViewBrokerUpdateModal from './ViewBrokerUpdateModal';
import BrokerUpdateModal from './BrokerUpdateSendModal';
import AutoCheckCallInfoModal from './AutoCheckCallInfoModal';

export const locationEventsModalNames = {
    checkCall: 'LOCATION_EVENT_MODALS_CHECK_CALL',
    banLocationEvent: 'LOCATION_EVENT_MODALS_BAN_EVENT',
    viewBrokerUpdate: 'LOCATION_EVENT_VIEW_BROKER_UPDATE',
    brokerUpdateModal: 'LOCATION_EVENT_BROKER_UPDATE_MODAL',
    autoCheckCallInfoModal: 'LOCATION_EVENT_AUTO_CHECK_CALL_INFO_MODAL',
};

const modalMap = {
    [locationEventsModalNames.checkCall]: CheckCallModal,
    [locationEventsModalNames.banLocationEvent]: BanEventModal,
    [locationEventsModalNames.viewBrokerUpdate]: ViewBrokerUpdateModal,
    [locationEventsModalNames.brokerUpdateModal]: BrokerUpdateModal,
    [locationEventsModalNames.autoCheckCallInfoModal]: AutoCheckCallInfoModal,
};

export default modalMap;
