import {InferActions} from 'store';

import {Dispatcher} from 'core/entities/Dispatcher/types';

import * as types from './actionTypes';

export const setCurrentUser = (user: Dispatcher) => ({type: types.SET_CURRENT_USER, payload: user} as const);

export const setUserPreferences = (preferences) =>
    ({type: types.SET_CURRENT_USER_PREFERENCES, payload: preferences} as const);

export const updateCurrentUser = (payload: Partial<Dispatcher>) =>
    ({type: types.UPDATE_CURRENT_USER, payload} as const);

export const setCurrentUserAvatar = (avatar) => ({type: types.SET_CURRENT_USER_AVATAR, payload: avatar} as const);

const actionCreators = {
    setCurrentUser,
    setUserPreferences,
    updateCurrentUser,
    setCurrentUserAvatar,
};

export type UserDataActionsTypes = InferActions<typeof actionCreators>;

export default actionCreators;
