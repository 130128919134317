import {createSelector} from 'reselect';

import FIELDS from './fields';

const getPayPeriodFromProps = (state, props) => props.payPeriod;

export const getSendPayPeriodEmailsInitialData = createSelector([getPayPeriodFromProps], (payPeriod) => {
    return {
        [FIELDS.payRecords]: {},
        [FIELDS.emailNote]: payPeriod.note_to_email,
    };
});
