import React from 'react';

import Truck from 'core/entities/Truck/types';

import TruckNumber from './components/TruckNumber';
import TruckStatus from './components/TruckStatus';
import MainContact from './components/MainContact';
import Phone from './components/Phone';
import OriginAway from './components/OriginAway';

interface OwnProps {
    truck: Truck;
    isTruckSelected: boolean;
    onSelectTruck: (truck: Truck) => void;
}

const Row: React.FC<OwnProps> = (props) => {
    const {truck, isTruckSelected, onSelectTruck} = props;
    return (
        <tr>
            <TruckNumber onSelectTruck={onSelectTruck} isChecked={isTruckSelected} truck={truck} />
            <TruckStatus truck={truck} />
            <MainContact truck={truck} />
            <Phone truck={truck} />
            <OriginAway truck={truck} />
        </tr>
    );
};

export default Row;
