import React from 'react';
import {Field} from 'redux-form';
import LengthMeasureInput from 'components/ui/Form/LengthMeasureInput';
import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';

type OwnProps = {
    units: string;
};

const DimensionHeight: React.FC<OwnProps> = (props) => {
    const {units} = props;
    const {getTruckDimensionHeightFieldName} = getFormFieldNames();
    const heightFieldName = getTruckDimensionHeightFieldName();
    return (
        <>
            <Field name={heightFieldName} component={LengthMeasureInput} label="height" units={units} />
        </>
    );
};
export default DimensionHeight;
