import React from 'react';
import classNames from 'classnames';

import {getOwnerCoordinatorName} from 'core/entities/Owner/modules/ownerCoordinatorName';
import {isMemberOwnerCoordinator} from 'core/entities/Truck/modules/truckTeamInfo';
import {TruckMember} from 'core/entities/Truck/types/Team';

import LanguageBadge from 'components/ui/Badges/LanguageBadge';
import {OwnerLink} from 'components/ui/Links';

import {phoneNumberWithBraces} from 'utils';

type OwnProps = {
    truckMember: TruckMember;
};

const TruckOwnerCoordinatorBlock: React.FC<OwnProps> = (props) => {
    const {truckMember} = props;

    const {isResponsible, entity: ownerCoordinator} = truckMember;

    const isOwnerCoordinator = isMemberOwnerCoordinator(ownerCoordinator);

    if (!isOwnerCoordinator) {
        return null;
    }

    const isMainClassName = classNames({'is-main': isResponsible});

    return (
        <>
            {isResponsible ? <div className="badge">m</div> : null}

            <div>
                <strong>(crd)</strong>
                &nbsp;
                <OwnerLink
                    owner={ownerCoordinator}
                    content={getOwnerCoordinatorName(ownerCoordinator)}
                    className="second-link"
                />
            </div>

            <div>
                <span className={isMainClassName}>
                    {phoneNumberWithBraces(ownerCoordinator.coordinator_mobile_phone)}
                </span>
                &nbsp;
                <LanguageBadge language={ownerCoordinator.coordinator_language} tooltipPosition="right" />
            </div>
        </>
    );
};

export default TruckOwnerCoordinatorBlock;
