import * as appActions from 'store/actions';

import {getGeneratedRCFileHandlers} from 'core/entities/TravelOrder/modules/travelOrderAttachments/generatedAttachments';
import {getTravelOrderNumber} from 'core/entities/TravelOrder/modules/common/getTravelOrderNumber';
import {isTravelOrderHasCarrier} from 'core/entities/TravelOrder/modules/travelOrderCarrier';
import * as toRequests from 'core/gateways/TravelOrderApiGateway/requests/indexNew';

import {getCurrentTravelOrder} from 'pages/TravelOrders/redux/selectors';

export const generateRateConfirmation = () => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);

    const isTOHasCarrier = isTravelOrderHasCarrier(travelOrder);

    try {
        const {data} = await toRequests.generateTravelOrderRateConfirmationRequest({toNumber});

        if (isTOHasCarrier) {
            const {onOpenRC} = getGeneratedRCFileHandlers({files: data || []});

            onOpenRC();
        }
    } catch (e) {
        dispatch(appActions.handleError(e));
    }
};
