import React from 'react';
import {Field} from 'redux-form';

import RequiredLabel from 'components/ui/Form/RequiredLabel';
import InputWithPlacesAutocomplete, {AddressData} from 'components/ui/Form/InputWithPlacesAutocomplete';
import LocationInfo from 'components/ui/LocationInfo';

import {getName} from 'components/common/LocationEvents/modals/CheckCallModal/utils';

import {getCityLineFromString} from 'utils/getCityLine';

import TimeDataPicker from '../TimeDataPicker';

import styles from './styles.module.scss';

type OwnType = {
    form: string;
    mostRecentTruckLocation: {
        cityLine: string;
        latitude: number;
        longitude: number;
        dateTime: string;
    };

    updateCoordinates: (address: AddressData) => void;
    copyLastKnowLocationFromTruck: () => void;
};

const LastKnowLocationInfo: React.FC<OwnType> = (props) => {
    const {
        form,
        mostRecentTruckLocation: {cityLine, longitude, dateTime, latitude},
        updateCoordinates,
        copyLastKnowLocationFromTruck,
    } = props;

    return (
        <div className={styles.wrap}>
            {cityLine ? (
                <div className={styles.location}>
                    <LocationInfo
                        onArrowClick={copyLastKnowLocationFromTruck}
                        title="Last known location:"
                        tooltipText="copy truck coordinates"
                        dateTime={dateTime}
                        location={getCityLineFromString(cityLine)}
                        clipBoardText={`${latitude}, ${longitude}`}
                    />
                </div>
            ) : null}

            <div className={styles.form}>
                <Field
                    type="text"
                    name={getName('cityLine')}
                    withUnknownValues={true}
                    placeholder="enter location"
                    onGetAddress={updateCoordinates}
                    component={InputWithPlacesAutocomplete}
                    label={RequiredLabel('New Location')}
                />

                <TimeDataPicker form={form} showReset={true} timeZoneDisabled={true} />
            </div>
        </div>
    );
};

export default LastKnowLocationInfo;
