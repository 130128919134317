import {useDispatch, useSelector} from 'react-redux';

import {getSupportEmail, getSupportPhone} from 'store/reducers/appSettings/selectors';

import {phoneNumberWithBraces} from 'utils';

import {singleCallOpenModal} from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import {UseSupportItemType} from '../types';

export const useSupportItem = (): UseSupportItemType => {
    const dispatch = useDispatch();
    const phone = useSelector(getSupportPhone);
    const email = useSelector(getSupportEmail);
    return {
        openSupportModal: () =>
            dispatch(
                singleCallOpenModal({
                    modalName: commonModalNames.supportModal,
                    data: {
                        title: 'Give Feedback',
                    },
                }),
            ),
        phone: phoneNumberWithBraces(phone),
        email,
    };
};
