import decodeText from 'utils/decodeText';
import {messagesEvents} from '../../events';
import * as messagesActions from './actions';

export const messagesChannelMessageHandler = (store) => (uri, payload) => {
    const messageData = payload.msg;

    if (!messageData.event || !messageData.data) {
        return;
    }

    const {event, data} = messageData;

    data.text = data.text ? decodeText(data.text) : '';

    switch (event) {
        case messagesEvents.DRIVER_MESSAGE_RECEIVED:
            store.dispatch(messagesActions.driverMessageReceived(data));
            break;
        case messagesEvents.DISPATCHER_MESSAGE_RECEIVED:
            store.dispatch(messagesActions.dispatcherMessageReceived(data));
            break;
        default:
            break;
    }
};
