import React from 'react';
import {WrappedFieldProps} from 'redux-form';

import Select from '../Select';

import DropDownOption from './components/Option';

type Option = {label: string; value: any};
type OwnProps = WrappedFieldProps & {
    data: Option[];
    isClearable?: boolean;
    valueAsArray?: boolean;
    placeholder?: string;
    className?: string;
    name: string;
};

const DropDownCheckbox: React.FC<OwnProps> = (props) => {
    const renderOption = (optionProps) => <DropDownOption {...optionProps} input={props.input} />;

    return <Select {...props} components={{Option: renderOption}} />;
};

export default DropDownCheckbox;
