import {InferActions} from 'store';
import {getData, addItem, updateItem, removeItem, NormalizedData} from 'utils/normalizer';

import {LoadBoardNotification} from '../../types';
import * as actionTypes from '../actionTypes';
import * as actionCreators from '../actionCreators';
import websocketsReducer from './websockets';
import {NotificationsState} from './types';

export const defaultState: NotificationsState = {
    items: {
        byId: {},
        allIds: [],
    },
    counters: {
        unread: 0,
    },
};

type ListReducerActions = Pick<
    typeof actionCreators,
    | 'receivedListLoadBoardNotifications'
    | 'readLoadBoardNotification'
    | 'removeLoadBoardNotification'
    | 'removeAllLoadBoardNotifications'
>;

type ActionsTypes = InferActions<ListReducerActions>;

const isWebsocketAction = (action: {type: string}): boolean => {
    return action.type.endsWith('_WS') || action.type.startsWith('WEB_SOCKET_');
};

export default function listReducer(state = defaultState, action: ActionsTypes): NotificationsState {
    if (isWebsocketAction(action)) {
        return websocketsReducer(state, action as any);
    }

    switch (action.type) {
        case actionTypes.RECEIVED_LIST_LOAD_BOARD_NOTIFICATIONS: {
            return {
                ...state,
                items: getData(action.payload.notifications),
                counters: action.payload.counters,
            };
        }

        case actionTypes.READ_LOAD_BOARD_NOTIFICATION: {
            const {notificationID} = action.payload;
            const notification = state.items.byId[notificationID];

            if (!notification) {
                return state;
            }

            const readNotification: LoadBoardNotification = {...notification, status: 'READ'};
            return {
                ...state,
                items: updateItem(state.items, readNotification),
                counters: {unread: state.counters.unread - 1},
            };
        }

        case actionTypes.REMOVE_LOAD_BOARD_NOTIFICATION: {
            const {notificationID} = action.payload;
            const notification = state.items.byId[notificationID];

            return {
                ...state,
                items: removeItem(state.items, notification),
                counters: {unread: state.counters.unread - 1},
            };
        }

        case actionTypes.REMOVE_ALL_LOAD_BOARD_NOTIFICATIONS: {
            return {
                ...state,
                items: {byId: {}, allIds: []},
                counters: {unread: 0},
            };
        }
        // no default
    }

    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
