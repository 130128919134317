import React, {useEffect} from 'react';
import {FormGroup, FormControl, FormLabel} from 'react-bootstrap';
import {WrappedFieldProps} from 'redux-form';

import {convertMmToMiles} from 'pages/LoadBoard/utils/distance';

import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';
import getValidationState from 'components/ui/Form/getValidationState';

import {getDigitalValue, getConvertedDistance} from './utils';

type OwnProps = {
    label: string;
    errorTooltipPlacement: string;
    formGroup: boolean;
    maxLength: number;
    disabled: boolean;
    placeholder: string;
};

const DistanceInput: React.FC<OwnProps & WrappedFieldProps> = (props) => {
    const {
        input: {onChange, onBlur},
        meta,
        meta: {asyncValidating},
        label,
        errorTooltipPlacement,
        formGroup = true,
        maxLength,
        disabled,
        placeholder: placeHolder,
    } = props;

    const [distanceValue, setDistanceValue] = React.useState('');
    const valid = getValidationState(meta);

    const handleInputChange = (e): void => {
        const {value} = e.target;

        const digitalValue = getDigitalValue(value);

        setDistanceValue(digitalValue);

        const distance = getConvertedDistance(digitalValue);

        onChange(distance);
    };

    const handleOnBlur = (e): void => {
        const {value} = e.target;

        const digitalValue = getDigitalValue(value);

        setDistanceValue(digitalValue);

        const distance = getConvertedDistance(digitalValue);

        onBlur(distance);
    };

    useEffect(() => {
        const currentValue = props.input.value;

        if (!currentValue) {
            return;
        }
        const res = convertMmToMiles(currentValue);
        setDistanceValue(`${res}`);
    }, [props.input.value]);

    const input = (
        <FormControl
            {...props.input}
            maxLength={maxLength}
            placeholder={placeHolder}
            value={distanceValue}
            onBlur={handleOnBlur}
            onChange={handleInputChange}
            disabled={disabled || asyncValidating}
        />
    );

    const errorGroupClass = valid ? '' : 'has-feedback has-error';

    if (formGroup) {
        return (
            <FormGroup className={`${errorGroupClass}`}>
                {label && <FormLabel>{label}</FormLabel>}
                <OverlayTriggerCustom inputMeta={!disabled && meta} placement={errorTooltipPlacement}>
                    {input}
                </OverlayTriggerCustom>
            </FormGroup>
        );
    }

    return input;
};

export default DistanceInput;
