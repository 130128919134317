import React from 'react';
import classNames from 'classnames';

import {BasePlacement} from 'types/BasePlacement';

import styles from './styles.module.scss';

interface OwnProps {
    className?: string;
    tooltipContent: any;
    disallowSelect?: boolean;
    display?: 'block' | 'flex';
    position?: BasePlacement;
    typeContent?: 'link' | 'button' | 'marker';
    children: any;
    onClick?(): void;
}

const Tooltip = (props: OwnProps): JSX.Element => {
    const {
        disallowSelect = false,
        typeContent = 'link',
        tooltipContent,
        className,
        children,
        position,
        onClick,
        display,
    } = props;

    const elementClass = classNames(
        styles.tooltip,
        display === 'block' && styles.block,
        onClick && styles.event,
        className,
    );

    const contentClasses = classNames({
        [styles.content]: true,
        [styles.right]: position === 'right',
        [styles.bottom]: position === 'bottom',
        [styles.disallow_select]: disallowSelect,
        [styles.button]: typeContent === 'button',
        [styles.marker]: typeContent === 'marker',
    });

    return (
        <div onClick={onClick} className={elementClass}>
            <div className={styles.child}>{children}</div>
            {tooltipContent && (
                <div className={contentClasses}>
                    <div>{tooltipContent}</div>
                </div>
            )}
        </div>
    );
};

export default Tooltip;
