/**
 * Decodes control sequences of characters in the component
 * @param {string} text
 */
export default function decodeText(text) {
    if (!text) {
        return '';
    }
    let result;
    try {
        result = decodeURIComponent(text);
    } catch (err) {
        result = text;
    }
    return result;
}
