import React from 'react';

import DataLoading from 'pages/Settlements/components/common/DataLoading';
import useFetchResponsibleUserSettlements from '../../../hooks/useFetchResponsibleUserSettlements';

import SettlementInfo from './SettlementInfo';

interface OwnProps {
    className: string;
    payPeriodID: number;
    userID: number;
}

const ListUserSettlements = ({className, payPeriodID, userID}: OwnProps): JSX.Element => {
    const {settlements, isFetching, isAllFetched, fetchNextPage} = useFetchResponsibleUserSettlements({
        payPeriodID,
        responsibleUserID: userID,
    });
    const settlementsRows = settlements.map((settlement) => {
        return <SettlementInfo key={settlement.id} settlement={settlement} />;
    });
    return (
        <div className={className}>
            {settlementsRows.length > 0 && settlementsRows}
            <DataLoading
                isLoading={isFetching}
                isAllLoaded={isAllFetched}
                onLoadMore={fetchNextPage}
                loadMoreTitle="load more settlements"
            />
        </div>
    );
};

export default React.memo(ListUserSettlements);
