import React from 'react';
import {Field} from 'redux-form';

import CheckBoxInput from 'components/ui/Form/CheckBoxInput';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';

const FreightIsStackable: React.FC = () => {
    const {getLoadFreightIsStackableFieldName} = getFormFieldNames();
    return (
        <Field
            name={getLoadFreightIsStackableFieldName()}
            component={CheckBoxInput}
            label={<span className="text-capitalize ml-1">Stackable</span>}
            position="right"
            normalize={(value) => Boolean(value)}
        />
    );
};

export default FreightIsStackable;
