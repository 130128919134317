import {useRef} from 'react';

import Truck from 'core/entities/Truck/types';

import {QuoteReceiver} from 'pages/LoadBoard/types/formTypes';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import useFormActions from '../../../hooks/useFormActions';
import getFormFieldNames from '../../../utils/getFormFieldNames';

interface Result {
    allTrucks: Truck[];
    selectedTrucks: Truck[];
    selectedTrucksLength: number;
    allTrucksLength: number;
    isAllSelected: boolean;
    onSelectAllTrucks: () => void;
    onSelectTruck: (truck: Truck) => void;
}

const updateAllReceivers = (params: {receivers: QuoteReceiver[]; isReceiveQuoteAllowed: boolean}): QuoteReceiver[] => {
    const {receivers, isReceiveQuoteAllowed} = params;

    return receivers.map((r) => ({...r, isReceiveQuoteAllowed}));
};

const updateAllReceiverWithSendAllowedFlag = (receivers: QuoteReceiver[]): QuoteReceiver[] => {
    return updateAllReceivers({receivers, isReceiveQuoteAllowed: true});
};

const updateAllReceiverWithSendNotAllowedFlag = (receivers: QuoteReceiver[]): QuoteReceiver[] => {
    return updateAllReceivers({receivers, isReceiveQuoteAllowed: false});
};

const updateOneReceiver = (params: {
    receivers: QuoteReceiver[];
    receiver: QuoteReceiver;
    isReceiveQuoteAllowed: boolean;
}): QuoteReceiver[] => {
    const {receivers, receiver, isReceiveQuoteAllowed} = params;
    return receivers.map((r) => {
        if (r.truck.id === receiver.truck.id) {
            return {...r, isReceiveQuoteAllowed};
        }
        return r;
    });
};

const updateOneRecipientSendAllowed = (receivers: QuoteReceiver[], receiver: QuoteReceiver): QuoteReceiver[] => {
    return updateOneReceiver({receivers, receiver, isReceiveQuoteAllowed: true});
};
const updateOneRecipientSendNotAllowed = (receivers: QuoteReceiver[], receiver: QuoteReceiver): QuoteReceiver[] => {
    return updateOneReceiver({receivers, receiver, isReceiveQuoteAllowed: false});
};

const findReceiversByTruckIds = (receivers: QuoteReceiver[], truckIds: number[]): QuoteReceiver[] => {
    const truckIdsSet = new Set(truckIds);
    return receivers.filter((r) => truckIdsSet.has(r.truck.id));
};

const findReceiversTrucks = (receivers: QuoteReceiver[], truckIds: number[]): Truck[] => {
    const truckIdsSet = new Set(truckIds);
    return receivers.reduce<Truck[]>((result, r) => {
        if (truckIdsSet.has(r.truck.id)) {
            result.push(r.truck);
        }
        return result;
    }, []);
};

const findSentReceiversTrucks = (receivers: QuoteReceiver[]): Truck[] => {
    return receivers.reduce<Truck[]>((trucks, receiver) => {
        if (receiver.isReceiveQuoteAllowed) {
            trucks.push(receiver.truck);
        }
        return trucks;
    }, []);
};

export default function (formName: string): Result {
    const ref = useRef<{isInitialTrucksSet: boolean; initialTrucksIds: number[]}>({
        isInitialTrucksSet: false,
        initialTrucksIds: [],
    });
    const {getReceiversListFiledName} = getFormFieldNames();
    const receivers = (useGetReduxFormValue(formName, getReceiversListFiledName()) as QuoteReceiver[]) || [];
    const selectedTrucks = findSentReceiversTrucks(receivers);

    if (!ref.current.isInitialTrucksSet) {
        ref.current.initialTrucksIds = selectedTrucks.map((t) => t.id);
        ref.current.isInitialTrucksSet = true;
    }

    const {setTrucksReceivers} = useFormActions();
    const initialTrucksLength = ref.current.initialTrucksIds.length;
    const selectedTrucksLength = selectedTrucks?.length;
    const isAllSelected = initialTrucksLength === selectedTrucksLength;

    const selectAllTrucks = (): void => {
        const initialReceivers = findReceiversByTruckIds(receivers, ref.current.initialTrucksIds);
        const initialSentReceivers = updateAllReceiverWithSendAllowedFlag(initialReceivers);
        setTrucksReceivers(initialSentReceivers);
    };

    const clearSelectedTrucks = (): void => {
        const allNotSentReceivers = updateAllReceiverWithSendNotAllowedFlag(receivers);
        setTrucksReceivers(allNotSentReceivers);
    };

    const addTruckToSelectedList = (truck: Truck): void => {
        const [receiver] = findReceiversByTruckIds(receivers, [truck.id]);
        if (!receiver) {
            return;
        }
        const updatedReceivers = updateOneRecipientSendAllowed(receivers, receiver);
        setTrucksReceivers(updatedReceivers);
    };

    const removeTruckFromSelectedList = (truck: Truck): void => {
        const [receiver] = findReceiversByTruckIds(receivers, [truck.id]);
        if (!receiver) {
            return;
        }
        const updatedReceivers = updateOneRecipientSendNotAllowed(receivers, receiver);
        setTrucksReceivers(updatedReceivers);
    };

    const onSelectAllTrucks = (): void => {
        if (isAllSelected) {
            clearSelectedTrucks();
            return;
        }
        selectAllTrucks();
    };

    const onSelectTruck = (truck: Truck): void => {
        const isTruckAlreadySelected = selectedTrucks.find((selectedTruck) => selectedTruck.id === truck.id);
        if (isTruckAlreadySelected) {
            return removeTruckFromSelectedList(truck);
        }

        return addTruckToSelectedList(truck);
    };

    return {
        allTrucks: ref.current.isInitialTrucksSet ? findReceiversTrucks(receivers, ref.current.initialTrucksIds) : [],
        selectedTrucks,
        selectedTrucksLength,
        allTrucksLength: initialTrucksLength,
        isAllSelected,
        onSelectAllTrucks,
        onSelectTruck,
    };
}
