import {TOFile} from 'types/File';

import {ATTACH_TYPES} from 'core/entities/TravelOrder/constants/attachTypes';
import TravelOrder from 'core/entities/TravelOrder/types';

import getFilePath from 'utils/files/getFilePath';
import openNewWindow from 'utils/openNewWindow';

import {getTravelOrderFiles} from '../index';

export const getTravelOrderRCGeneratedFile = (travelOrder?: TravelOrder | null): TOFile | undefined => {
    const travelOrderFiles = getTravelOrderFiles(travelOrder);
    const rcGeneratedFile = (travelOrderFiles || []).find((file) => file.attachType === ATTACH_TYPES.RC_GENERATED);

    return rcGeneratedFile;
};

export const getGeneratedRCFileHandlers = (params: {files: TOFile[]}) => {
    const {files} = params;

    const rcGeneratedFile = (files || []).find((file) => file.attachType === ATTACH_TYPES.RC_GENERATED);
    const filePath = getFilePath(rcGeneratedFile);

    const onOpenRC = () => {
        if (rcGeneratedFile && filePath) {
            openNewWindow({newWindowUrl: filePath});
        }
    };

    return {onOpenRC};
};
