import {ONLY_NUMBERS} from 'utils/validation/regexps';
import LENGTH_UNITS from 'utils/data/lengthUnits';

export const MAX_IN_VALUE = 12;
export const MAX_CM_VALUE = 100;
export const FIELD_LENGTH = 4;

export const checkDimensions = (units, measurements) => {
    if (units === LENGTH_UNITS.FOOT && measurements < MAX_IN_VALUE) {
        return true;
    }

    if (units === LENGTH_UNITS.METER && measurements < MAX_CM_VALUE) {
        return true;
    }

    if (
        (units === LENGTH_UNITS.INCH || units === LENGTH_UNITS.CENTIMETER) &&
        measurements.toString().length <= FIELD_LENGTH
    ) {
        return true;
    }

    return false;
};

export const updateFirstInput = (value) => (value === '' || ONLY_NUMBERS.test(value)) && value.length <= FIELD_LENGTH;
export const updateSecondInput = (value, units) =>
    (value === '' || ONLY_NUMBERS.test(value)) && checkDimensions(units, value);
