import isEmpty from 'lodash/isEmpty';

const isSomeGroupOpened = (markersGroup) => {
    return Object.values(markersGroup).some((groupWithOpenedMarkers) => {
        return !isEmpty(groupWithOpenedMarkers);
    });
};

const isExistsOpenedCrossDocsMarkers = (allOpenedMarkers): boolean => {
    return isSomeGroupOpened(allOpenedMarkers.crossDocs);
};

const isExistsOpenedRentTrucksEastMarkers = (allOpenedMarkers): boolean => {
    return isSomeGroupOpened(allOpenedMarkers.rentTrucksEast);
};

const isExistsOpenedRentTrucksRestMarkers = (allOpenedMarkers): boolean => {
    return isSomeGroupOpened(allOpenedMarkers.rentTrucksRest);
};

const someCrossDocsDisabledInFilters = (crossDocsFilters) => {
    return !crossDocsFilters.aroundClockTime || !crossDocsFilters.strictTime || !crossDocsFilters.flexibleTime;
};

const someRentTrucksEastDisabledDisabledInFilters = (rentTrucksEastFilters) => {
    return !rentTrucksEastFilters.penskeCompany || !rentTrucksEastFilters.ryderCompany;
};

const someRentTrucksRestDisabledDisabledInFilters = (rentTrucksEastFilters) => {
    return !rentTrucksEastFilters.penskeCompany || !rentTrucksEastFilters.ryderCompany;
};

const getMarkersWithClosedCrossDocs = (filters, allOpenedMarkers) => {
    return {
        ...allOpenedMarkers,
        crossDocs: {
            strictTime: filters?.strictTime ? allOpenedMarkers.crossDocs.strictTime : {},
            aroundClockTime: filters?.aroundClockTime ? allOpenedMarkers.crossDocs.aroundClockTime : {},
            flexibleTime: filters?.flexibleTime ? allOpenedMarkers.crossDocs.flexibleTime : {},
        },
    };
};

const getMarkersWithClosedRentTrucksEast = (filters, allOpenedMarkers) => {
    return {
        ...allOpenedMarkers,
        rentTrucksEast: {
            penskeCompany: filters.penskeCompany ? allOpenedMarkers.rentTrucksEast.penskeCompany : {},
            ryderCompany: filters.ryderCompany ? allOpenedMarkers.rentTrucksEast.ryderCompany : {},
        },
    };
};

const getMarkersWithClosedRentTrucksRest = (filters, allOpenedMarkers) => {
    return {
        ...allOpenedMarkers,
        rentTrucksRest: {
            penskeCompany: filters.penskeCompany ? allOpenedMarkers.rentTrucksRest.penskeCompany : {},
            ryderCompany: filters.ryderCompany ? allOpenedMarkers.rentTrucksRest.ryderCompany : {},
        },
    };
};

export default function (filters, allOpenedMarkers) {
    if (isExistsOpenedCrossDocsMarkers(allOpenedMarkers) && someCrossDocsDisabledInFilters(filters)) {
        return getMarkersWithClosedCrossDocs(filters.crossDocs, allOpenedMarkers);
    }
    if (isExistsOpenedRentTrucksEastMarkers(allOpenedMarkers) && someRentTrucksEastDisabledDisabledInFilters(filters)) {
        return getMarkersWithClosedRentTrucksEast(filters.rentTrucksEast, allOpenedMarkers);
    }
    if (isExistsOpenedRentTrucksRestMarkers(allOpenedMarkers) && someRentTrucksRestDisabledDisabledInFilters(filters)) {
        return getMarkersWithClosedRentTrucksRest(filters.rentTrucksRest, allOpenedMarkers);
    }
    return allOpenedMarkers;
}
