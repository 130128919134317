export const SHOW_LOADER = 'APP_SHOW_LOADER' as const;
export const HIDE_LOADER = 'APP_HIDE_LOADER' as const;
export const START_LOADING_DATA = 'APP_START_LOADING_DATA' as const;
export const END_LOADING_DATA = 'APP_END_LOADING_DATA' as const;
export const COMMON_DATA_ENTITY_CREATED = 'APP_COMMON_DATA_ENTITY_CREATED' as const;
export const GET_COMMON_DATA = 'APP_GET_COMMON_DATA' as const;
export const START_FETCHING_DISPATCHERS_COMMON_DATA = 'START_FETCHING_DISPATCHERS_COMMON_DATA' as const;
export const FINISH_FETCHING_DISPATCHERS_COMMON_DATA = 'FINISH_FETCHING_DISPATCHERS_COMMON_DATA' as const;
export const START_FETCHING_CARRIERS_COMMON_DATA = 'START_FETCHING_CARRIERS_COMMON_DATA' as const;
export const FINISH_FETCHING_CARRIERS_COMMON_DATA = 'FINISH_FETCHING_CARRIERS_COMMON_DATA' as const;
export const MAP_API_LOADED = 'APP_MAP_API_LOADED' as const;
