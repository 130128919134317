import React from 'react';
import {Field} from 'redux-form';

import RequiredLabel from 'components/ui/Form/RequiredLabel';
import WeightMeasureInput from 'components/ui/Form/WeightMeasureInput';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';

const FreightWeight: React.FC = () => {
    const {getLoadFreightWeightFieldName, getLoadFreightLengthUnitFieldName} = getFormFieldNames();
    const placeholderWeightUnit = useGetReduxFormValue(FORM_NAME, getLoadFreightLengthUnitFieldName());
    return (
        <>
            <Field
                type="text"
                maxLength={9}
                name={getLoadFreightWeightFieldName()}
                component={WeightMeasureInput}
                units={placeholderWeightUnit}
                label={RequiredLabel('Weight')}
                placeholder={placeholderWeightUnit}
            />
        </>
    );
};

export default FreightWeight;
