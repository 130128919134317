import React from 'react';
import {Field} from 'redux-form';

import RequiredLabel from 'components/ui/Form/RequiredLabel';

import DateTimeField from '../common/DateTimeField';

interface OwnProps {
    dateFromFiledName: string;
    required?: boolean;
    disabled: boolean;
}

const DateTimeAsapDirect: React.FC<OwnProps> = (props) => {
    const {dateFromFiledName, required, disabled} = props;

    const dateLabel = required ? RequiredLabel('DATE') : 'DATE';
    const timeLabel = required ? RequiredLabel('BY') : 'BY';
    return (
        <Field
            dateLabel={dateLabel}
            datePlaceholder="choose date"
            timeLabel={timeLabel}
            name={dateFromFiledName}
            component={DateTimeField}
            disabled={disabled}
        />
    );
};

export default DateTimeAsapDirect;
