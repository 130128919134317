import * as messageHandlers from 'store/middlewares/mainAPISocketsIO/entities/channelMessageHandlers';
import websocketChannels from 'store/middlewares/mainAPISocketsIO/websocketChannels';

import * as listPages from 'utils/data/pages';

export const pagesChannelsMap = {
    [listPages.INVOICES]: [
        {channel: websocketChannels.settlementsOwners, handler: messageHandlers.invoicesChannelMessageHandler},
        {channel: websocketChannels.invoices, handler: messageHandlers.invoicesChannelMessageHandler},
    ],

    [listPages.SETTLEMENTS_CARRIERS]: [
        {
            channel: websocketChannels.settlementsCarriers,
            handler: messageHandlers.settlementsCarriersChannelMessageHandler,
        },
    ],

    [listPages.SETTLEMENTS_OWNERS]: [
        {channel: websocketChannels.settlementsOwners, handler: messageHandlers.settlementsOwnersChannelMessageHandler},
    ],

    [listPages.TRUCKS]: [{channel: websocketChannels.trucks, handler: messageHandlers.trucksChannelMessageHandler}],

    [listPages.TRUCKS_MAP]: [{channel: websocketChannels.trucks, handler: messageHandlers.trucksChannelMessageHandler}],

    [listPages.TRUCKS_NEAR_BY]: [
        {channel: websocketChannels.trucks, handler: messageHandlers.trucksChannelMessageHandler},
    ],

    [listPages.FULL_ALLIANCE]: [
        {channel: websocketChannels.trucks, handler: messageHandlers.trucksChannelMessageHandler},
    ],

    [listPages.OWNERS]: [{channel: websocketChannels.trucks, handler: messageHandlers.ownersChannelMessageHandler}],

    [listPages.DRIVERS]: [{channel: websocketChannels.trucks, handler: messageHandlers.driversChannelMessageHandler}],

    [listPages.LOADS]: [
        {channel: websocketChannels.loads, handler: messageHandlers.loadsChannelMessageHandler},
        {channel: websocketChannels.trucks, handler: messageHandlers.trucksChannelMessageHandler},
    ],

    [listPages.TRIP_MONITOR]: [
        {channel: websocketChannels.tripMonitor, handler: messageHandlers.tripMonitorChannelMessageHandler},
        {channel: websocketChannels.files, handler: messageHandlers.filesChannelMessageHandler},
    ],

    [listPages.LOAD_BOARD]: [
        {channel: websocketChannels.trucks, handler: messageHandlers.loadBoardTrucksChannelMessageHandler},
    ],
};
