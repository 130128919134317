import React, {useEffect} from 'react';
import {Field} from 'redux-form';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';

import {getCustomerBillingMethod} from 'core/entities/Customer/modules/customerBilling';

import {MergeCustomersFormValues} from 'pages/Customers/types/formTypes';
import useMergeState from 'pages/Customers/hooks/useMergeState';

import RadioInput from 'components/ui/Form/RadioInput';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<MergeCustomersFormValues>();

type OwnProps = {
    formName: string;
};

const CustomersBillingWarning: React.FC<OwnProps> = (props) => {
    const {formName} = props;

    const initRadioButtonValue = useChangeReduxFormValue(formName);
    const {customersToMerge} = useMergeState();

    const [sourceCustomer, destinationCustomer] = customersToMerge || [];

    const sourceCustomerBillingMethod = getCustomerBillingMethod(sourceCustomer);
    const destinationCustomerBillingMethod = getCustomerBillingMethod(destinationCustomer);

    useEffect(() => {
        initRadioButtonValue(getName('billingMethod'), destinationCustomerBillingMethod);
    }, []);

    const isCustomersHasDifferentBillingMethods = sourceCustomerBillingMethod !== destinationCustomerBillingMethod;

    if (!isCustomersHasDifferentBillingMethods) {
        return null;
    }

    return (
        <div className={styles.warning}>
            <div className={styles.warning__info}>
                <i className="fa fa-exclamation-circle" aria-hidden="true" />

                <div className="ml10">
                    The merging of Customers with identifiers <strong>ID#{sourceCustomer?.id}</strong> and{' '}
                    <strong>ID#{destinationCustomer?.id}</strong> cannot be performed due to a mismatch of Billing
                    methods. To perform a merge, both customers must have the same Billing method. Please set the same
                    Billing method for both customers and repeat the operation again.
                </div>
            </div>

            <div className="p15">
                <strong>
                    Please select a billing method that will be applied to all invoices when they are merged.
                </strong>

                <div className="mb10" />

                <Field
                    name={getName('billingMethod')}
                    component={RadioInput}
                    items={[
                        {label: 'Direct', value: 'direct'},
                        {label: 'Factored', value: 'factored'},
                    ]}
                />
            </div>
        </div>
    );
};

export default CustomersBillingWarning;
