import {InferActions} from 'store';

import * as types from './actionTypes';

export const showLoader = () => ({type: types.SHOW_LOADER} as const);

export const hideLoader = () => ({type: types.HIDE_LOADER} as const);

export const startLoadingData = () => ({type: types.START_LOADING_DATA} as const);

export const endLoadingData = () => ({type: types.END_LOADING_DATA} as const);

export const getEntityCommonData = (entityName, data) =>
    ({type: types.GET_COMMON_DATA, payload: {entityName, data}} as const);

export const createCommonDataEntity = (entityName, data) =>
    ({type: types.COMMON_DATA_ENTITY_CREATED, payload: {entityName, data}} as const);

export const startFetchingDispatchersCommonData = () => ({type: types.START_FETCHING_DISPATCHERS_COMMON_DATA} as const);

export const finishFetchingDispatchersCommonData = () =>
    ({type: types.FINISH_FETCHING_DISPATCHERS_COMMON_DATA} as const);

export const startFetchingCarriersCommonData = () => ({type: types.START_FETCHING_CARRIERS_COMMON_DATA} as const);

export const finishFetchingCarriersCommonData = () => ({type: types.FINISH_FETCHING_CARRIERS_COMMON_DATA} as const);

export const setMapApiIsLoaded = () => ({type: types.MAP_API_LOADED} as const);

const actionCreators = {
    showLoader,
    hideLoader,
    startLoadingData,
    endLoadingData,
    getEntityCommonData,
    createCommonDataEntity,
    startFetchingDispatchersCommonData,
    finishFetchingDispatchersCommonData,
    startFetchingCarriersCommonData,
    finishFetchingCarriersCommonData,
    setMapApiIsLoaded,
};

export type ActionsTypes = InferActions<typeof actionCreators>;

export default actionCreators;
