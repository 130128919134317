import Carrier from 'core/entities/Carrier/types';
import {Dispatcher} from 'core/entities/Dispatcher/types';
import Driver from 'core/entities/Driver/types';
import {TIME_FRAME_APPT, TIME_FRAME_ASAP, TIME_FRAME_DIRECT, TIME_FRAME_FCFS} from 'core/entities/Load/constants';
import Owner from 'core/entities/Owner/types';
import TravelOrder from 'core/entities/TravelOrder/types';

import {TemperatureUnits} from 'utils/data/temperatureUnits';

import {PlaceAddress} from 'types/Address';
import File from 'types/File';

import {doorTypes} from '../constants/doorTypes';

type UnknownEntity = Record<string, any>;

export interface TruckStopDateFrame {
    dateEnd: string;
    dateStart: string;
    timeFrame: typeof TIME_FRAME_FCFS | typeof TIME_FRAME_APPT | typeof TIME_FRAME_ASAP | typeof TIME_FRAME_DIRECT;
    timeZone: string;
}

export interface TruckCoordinates {
    latitude: number;
    longitude: number;
}

export interface TruckLocation {
    cityLine: string;
    coordinates: TruckCoordinates;
}

export interface TruckAvailabilityData {
    dateTime: string;
    location: TruckLocation;
    stopDateFrame: TruckStopDateFrame | null;
}

type LastKnownLocation = {
    coordinates: TruckCoordinates | null;
    cityLine: PlaceAddress['address'];
};

export type ReeferTruckTemperatureRange = {
    degreesUnit: TemperatureUnits;
    rangeMin: number | string | null;
    rangeMax: number | string | null;
};

type LastKnownData = {
    location: LastKnownLocation;
    dateTime: string;
};

type Reserver = {
    dispatcherEmail: string;
    dispatcherName: string;
    dispatcherId: number;
    serverPrefix: string;
    serverName: string;
};

type Reserve = {
    truckNumber?: string;
    reserver: Reserver;
    holdForRc: boolean;
    external: boolean;
    dateFrom: string;
    truckId: number;
    dateTo: string;
};

type MainContact = {
    id: number;
    name: string;
    phone: string;
    truck_id: string;
    type: '1dr' | 'ow' | 'crd' | 'o/d';
};

export interface Truck {
    id: number;
    owner: Owner;
    drivers: Driver[];
    carrier?: Carrier | null;
    status_dispatcher?: UnknownEntity;
    dispatcher_note_creator?: UnknownEntity;
    reserve?: Reserve | null;
    number: string;
    status: string;
    status_date?: string;
    isPriority: boolean;
    status_latitude?: string;
    status_longitude?: string;
    status_city_line?: string;
    last_latitude?: string;
    last_longitude?: string;
    last_city_line?: string;
    lastKnownData: LastKnownData | null;
    rating?: number;
    hired_date?: string;
    driver_note?: string | null;
    dispatcher_note?: string;
    dispatcher_note_updated_at?: string;
    vincode?: string;
    license_plate?: string | null;
    license_state?: string;
    model?: string;
    year?: string;
    type?: string;
    trailer_type?: string;
    color?: string;
    updated_at?: string;
    generalTotalNotes?: number | null;
    gross_weight?: number | null;
    gross_weight_units?: string;
    door_type?: keyof typeof doorTypes;
    hired_by?: Dispatcher;
    door_dims_height?: number;
    inside_dims_width?: number;
    inside_dims_height?: number;
    inside_dims_length?: number;
    insurance_expiration?: string | null;
    registration_expiration: string | null;
    make?: string;
    payload: number | null;
    payload_units?: string;
    dims_units?: string;
    planned_to_count: number;
    door_dims_width?: number;
    valid_dims_width?: number;
    valid_dims_height?: number;
    valid_dims_length?: number;
    equipment: string | null;
    is_deleted: boolean;
    second_driver_updates: string | null;
    first_driver_updates: string | null;
    owner_updates?: string;
    additional_equipment: string | null;
    responsible: boolean;
    is_owner_coordinator_responsible: boolean;
    company_signs: boolean;
    location_same_from: string | null;
    current_travel_order_number: number | null;
    distance?: number;
    homeDistance?: number;
    cargo_number: string | null;
    is_reefer: boolean | null;
    license_country: string | null;
    preferred_loads: string;
    crm_id: number | null;
    files: {
        photos?: File[];
    };
    travelOrder?: TravelOrder[];
    last_stop_timezone: string;
    availabilityData: TruckAvailabilityData | null;
    duplicatePosition?: string; // custom front field
    mainContact: MainContact;
    temperatureRange: ReeferTruckTemperatureRange | null;
}

export type TruckOnMap = Pick<
    Truck,
    | 'id'
    | 'number'
    | 'type'
    | 'status'
    | 'trailer_type'
    | 'last_city_line'
    | 'last_latitude'
    | 'last_longitude'
    | 'is_reefer'
>;

export interface HistoryPoint {
    city_line: string;
    id: string;
    lat: string;
    lng: string;
    time: string;
    truck_id: number;
}

export interface HistoryRequestData {
    dateEndInUTC: string;
    dateStartInUTC: string;
    truck: TruckOnMap;
    travelOrder: TravelOrder;
}

export const getEntityFieldName = (name: keyof Truck): string => name;

export default Truck;
