import {connect} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {formValueSelector} from 'redux-form';

import {AppState} from 'store';

import {NormalizedFile} from 'components/ui/Files/FileUpload/types';
import FilesList from 'components/ui/Files/FileUpload/components/FilesList/FilesList';
import {
    isUploadedFile,
    normalizeStoredFile,
    normalizeUploadedFile,
    normalizeNewStoredFile,
} from 'components/ui/Files/FileUpload/utils';
import {getRemovedFileIdsPath} from 'components/ui/Files/FileUpload/utils/mapExistingFilesFromFormState';
import withFormActionCreators from 'components/ui/Files/FileUpload/hocs/withFormActionCreators';

import {isStoredFile, isNewStoredFile} from 'utils/files';

interface PublicProps {
    meta: {form: string};
    fileTypesList: string[];
    pathToDeletedItems: string;
    arrayRemove(inputName: string, index: number): void;
    pushFileToDeleteList(file: {id: number}): void;
}

interface StateProps {
    filesData: NormalizedFile[];
}

function mapStateToProps(state: AppState, props: PublicProps): StateProps {
    const {
        meta: {form},
        fileTypesList,
        pathToDeletedItems,
        arrayRemove,
        pushFileToDeleteList,
    } = props;
    const selector = formValueSelector(form);
    const pathToDeletedFileIds = getRemovedFileIdsPath(pathToDeletedItems);
    const removedFilesIDs = selector(state, pathToDeletedFileIds) || [];

    const filesData = fileTypesList.reduce((result: NormalizedFile[], fileType): NormalizedFile[] => {
        const files = selector(state, fileType);
        if (isEmpty(files)) {
            return result;
        }
        return result.concat(
            files.reduce((accumulator, file, index) => {
                if (isUploadedFile(file)) {
                    accumulator.push(normalizeUploadedFile(file, {remove: () => arrayRemove(fileType, index)}));
                    return accumulator;
                }
                if (!removedFilesIDs.includes(file?.id) && isStoredFile(file)) {
                    accumulator.push(normalizeStoredFile(file, {remove: () => pushFileToDeleteList(file)}));
                }
                if (!removedFilesIDs.includes(file?.id) && isNewStoredFile(file)) {
                    accumulator.push(normalizeNewStoredFile(file, {remove: () => pushFileToDeleteList(file)}));
                }
                return accumulator;
            }, []),
        );
    }, []);

    return {filesData};
}

export default withFormActionCreators(connect(mapStateToProps)(FilesList));
