import isEqual from 'lodash/isEqual';

import {getCarrierPaymentAddressAllInfo} from 'core/entities/Carrier/modules/carrierPaymentSettings';
import Carrier from 'core/entities/Carrier/types';

import {getCarrierPhysicalAddressAllInfo} from '../getCarrierPhysicalAddress';
import {getCarrierBillingAddressAllInfo} from '../getCarrierBillingAddress';

export const checkIsCarrierBillingAddressSameAsPhysical = (carrier?: Carrier | null): boolean => {
    const carrierPhysicalAddress = getCarrierPhysicalAddressAllInfo(carrier);
    const carrierBillingAddress = getCarrierBillingAddressAllInfo(carrier);

    return isEqual(Object.values(carrierPhysicalAddress), Object.values(carrierBillingAddress));
};

export const checkIsCarrierBankingAddressSameAsBilling = (carrier?: Carrier | null): boolean => {
    const carrierBillingAddress = getCarrierBillingAddressAllInfo(carrier);
    const carrierPaymentAddress = getCarrierPaymentAddressAllInfo(carrier);

    return isEqual(Object.values(carrierBillingAddress), Object.values(carrierPaymentAddress));
};
