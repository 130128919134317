import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {GetConfirmEventDataFromRequest} from 'core/entities/LocationEvents/types';

import {setConfirm} from 'components/common/LocationEvents/modals/CheckCallModal/redux';
import {AddCheckCallFormValues, SetConfirmFormData} from 'components/common/LocationEvents/modals/CheckCallModal/types';

import useGetReduxFormValues from 'hooks/reduxForm/useGetReduxFormValues';

export default ({travelOrderNumber, form}: SetConfirmFormData): GetConfirmEventDataFromRequest => {
    const dispatch = useDispatch();
    const [confirmData, setConfirmData] = useState<GetConfirmEventDataFromRequest>({miles: 0, speed: 0});

    const createFormValue = useGetReduxFormValues<AddCheckCallFormValues>(form);

    useEffect(() => {
        dispatch(setConfirm({travelOrderNumber, setConfirmData, createFormValue}));
    }, []);

    return {...confirmData};
};
