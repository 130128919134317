import {LOCATION_CHANGE} from 'connected-react-router';
import {matchPath, match} from 'react-router';

import paths from 'routing/paths';

let prevPathname = '';

const getPageName = (location: string): string => {
    const locationParts = location.split('/');
    return locationParts[1];
};

const getRouteParams = (newLocation: string, pageName: string): match | null => {
    const pathSettings = paths[pageName];
    if (pathSettings) {
        return matchPath(newLocation, {
            path: pathSettings,
        });
    }
    return null;
};

const routerLocation = () => (next) => (action) => {
    if (action.type === LOCATION_CHANGE) {
        const location = action.payload.location.pathname;
        const pageName = getPageName(location);
        const newAction = {
            ...action,
            payload: {
                ...action.payload,
                routeParams: getRouteParams(location, pageName),
                location: {
                    ...action.payload.location,
                    pageName,
                    prevPathname,
                },
            },
        };
        prevPathname = location;
        return next(newAction);
    }
    return next(action);
};
export default routerLocation;
