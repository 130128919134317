import React, {Fragment} from 'react';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';

import OverlayTriggerCustom from '../OverlayTriggerCustom';

import './style.scss';

const CheckBoxInput = (props) => {
    const {
        label,
        input: {value, onChange},
        position = 'left',
        disabled,
        meta,
        className,
        redesign,
        formatValueBeforeChange,
    } = props;
    const handleCheck = () => {
        if (disabled) {
            return;
        }
        const nextValue = !value;
        if (isFunction(formatValueBeforeChange)) {
            onChange(formatValueBeforeChange(nextValue));
        } else {
            onChange(nextValue);
        }
    };

    const checkValidation = () => {
        if (!meta) {
            return null;
        }
        if (meta.pristine && meta.submitFailed && meta.error) {
            return 'error';
        }
        if (meta.pristine) {
            return null;
        }
        if (meta.error) {
            return 'error';
        }
        if (!meta.active) {
            return null;
        }
        return 'success';
    };

    const containerClassName = classNames(
        `checkbox_single_component ${checkValidation(meta) ? 'has-error' : ''}`.trim(),
        className,
        {redesign},
    );
    const checkedClassName = `iw_cb_shell ${value ? 'iw_cb_shell_checked' : ''}`.trim();
    const disabledClassName = `${disabled ? 'disabled' : ''}`.trim();

    return (
        <div className={containerClassName}>
            <OverlayTriggerCustom inputMeta={meta} placement="bottom">
                <div
                    // eslint-disable-next-line
                    onClick={handleCheck}
                    className={`${checkedClassName} ${disabledClassName}`}
                >
                    {position === 'left' ? (
                        <Fragment>
                            <label>{label}</label>&nbsp;
                            <span className="cb_square" />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <span className="cb_square" />
                            <label>{label}</label>
                        </Fragment>
                    )}
                </div>
            </OverlayTriggerCustom>
        </div>
    );
};

export default CheckBoxInput;
