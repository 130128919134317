import isArray from 'lodash/isArray';
import flattenDeep from 'lodash/flattenDeep';

import * as appActions from 'store/actions';

import formatPaginationParams from 'utils/formatPaginationParams';
import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import {getSettlementCarrier, isSettlementBatchOpened} from 'core/entities/Settlement';
import {statuses} from 'core/entities/Settlement/constants';
import {
    fetchAllBatches,
    addSettlementBatchEmailNote,
    closeSettlementBatch,
    createSettlementBatch,
    fetchCarrierBatches,
    reopenSettlementBatch,
    sendSettlementBatchEmail,
    updateSettlementBatch,
} from 'core/entities/Settlement/requests/settlementCarrierRequests';

import * as types from 'pages/Settlements/actionTypes';
import {isListPage, getCurrentSettlement} from 'pages/Settlements/selectors';

import {changeSettlementsGroupStatus, expandAndHighlightRow, setExpandedRow} from './listActions';
import {
    handleCreteBatchError,
    handleCloseBatchError,
    handleOpenBatchError,
    handleChangeSettlementStatusError,
} from './errorHandlers';
import {getFormattedBatchSendEmail} from './utils/formatSettlementCarrierData';

const getDataForCreateUpdateBatch = (carrierID, settlements) => {
    if (!carrierID || !settlements || !isArray(settlements)) {
        console.error('Incorrect data for create update batch: ', {carrierID, settlements});
        return;
    }
    return {
        batch: {carrierID, settlements: settlements.map((s) => s.id)},
    };
};

const getDataForCreateUpdateBatchForSetupBatchModal = (carrierID, users) => {
    if (!carrierID || !users || !isArray(users)) {
        console.error('Incorrect data for create update batch: ', {carrierID, users});
        return;
    }
    const settlementsToUsers = users.map((s) => s.settlements_ids);
    return {
        batch: {carrierID, settlements: flattenDeep(settlementsToUsers)},
    };
};

const handleUpdateBatch = (changedBatch, dispatch, state) => {
    // for case when we on list page we just change group status on POSTED batches
    if (isListPage(state)) {
        dispatch(setExpandedRow(changedBatch.id));
        dispatch(changeSettlementsGroupStatus(statuses.POSTED));
    } else {
        // for case when we on page with concrete settlement after create new batch
        // this settlement we should be updated cause after create batch this settlement will have
        // changed status data
        const currentSettlement = getCurrentSettlement(state);
        const currentSettlementFromCreatedBatch = changedBatch.settlements.find((s) => s.id === currentSettlement.id);
        dispatch({
            type: types.SETTLEMENT_STATUS_CHANGED,
            payload: {settlement: currentSettlementFromCreatedBatch},
        });
    }
};

export function createBatch(data) {
    return function (dispatch, getState) {
        if (!data.batch || !data.batch.carrierID || !isArray(data.batch.settlements)) {
            console.warn('Incorrect data on create new batch: ', data);
            return;
        }
        dispatch(appActions.showLoader());
        createSettlementBatch(data)
            .then(({data: createdBatch}) => {
                handleUpdateBatch(createdBatch, dispatch, getState());
            })
            .catch((error) => handleCreteBatchError(error, dispatch))
            .catch((error) => console.warn('Error on create batch: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function addSettlementsToBatch(batchID, data) {
    return function (dispatch, getState) {
        if (!data.batch || !data.batch.carrierID || !isArray(data.batch.settlements)) {
            console.warn('Incorrect data on add settlements to batch: ', data);
            return;
        }
        dispatch(appActions.showLoader());
        updateSettlementBatch(batchID, data)
            .then(({data: updatedBatch}) => {
                handleUpdateBatch(updatedBatch, dispatch, getState());
            })
            .catch((error) => handleChangeSettlementStatusError(error, dispatch))
            .catch((error) => console.warn('Error add settlements to exists batch: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function setupBatch(data) {
    return async function (dispatch) {
        const {id: carrierID} = data.company.carrier;
        let carrierBatches;

        dispatch(appActions.showLoader());

        try {
            carrierBatches = await fetchCarrierBatches(carrierID).then((response) => response.data);
        } catch (e) {
            dispatch(appActions.handleError(e));
        } finally {
            dispatch(appActions.hideLoader());
        }

        const carrierOpenedBatch = carrierBatches.find(isSettlementBatchOpened);
        const dataForCreateUpdate = getDataForCreateUpdateBatchForSetupBatchModal(
            carrierID,
            Object.values(data.company.users),
        );
        if (carrierOpenedBatch) {
            dispatch(addSettlementsToBatch(carrierOpenedBatch.id, dataForCreateUpdate));
        } else {
            dispatch(createBatch(dataForCreateUpdate));
        }
    };
}

export function postBatch(data) {
    return function (dispatch) {
        const isNeedCreateNewBatch = !data.batch;
        const isNeedAddSettlementToOpenedBatch = data.batch && isSettlementBatchOpened(data.batch);
        const isNeedAddSettlementToClosedBatch = data.batch && !isSettlementBatchOpened(data.batch);
        const settlementCarrier = getSettlementCarrier(data.settlement);

        if (isNeedCreateNewBatch) {
            const dataForCreateBatch = getDataForCreateUpdateBatch(settlementCarrier.id, [data.settlement]);
            dispatch(createBatch(dataForCreateBatch));
        }
        if (isNeedAddSettlementToOpenedBatch) {
            const dataForUpdateBatch = getDataForCreateUpdateBatch(settlementCarrier.id, [data.settlement]);
            dispatch(addSettlementsToBatch(data.batch.id, dataForUpdateBatch));
        }
        if (isNeedAddSettlementToClosedBatch) {
            // for case when we add settlement to closed batch we should re calculate total info about settlement
            // cause this total data will be used on API for re generate new PDF document
            const dataForUpdateBatchWithFormattedSettlement = getDataForCreateUpdateBatch(settlementCarrier.id, [
                data.settlement,
            ]);
            dispatch(addSettlementsToBatch(data.batch.id, dataForUpdateBatchWithFormattedSettlement));
        }
    };
}

export function closeBatch(batch) {
    return function (dispatch) {
        dispatch(appActions.showLoader());

        closeSettlementBatch(batch.id)
            .then(({data: updatedBatch}) => {
                dispatch({
                    type: types.BATCH_UPDATED,
                    payload: updatedBatch,
                });
            })
            .catch((error) => handleCloseBatchError(error, dispatch))
            .catch((error) => console.warn('Error on close batch: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function reOpenBatch(batch) {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        reopenSettlementBatch(batch.id)
            .then(({data: updatedBatch}) => {
                dispatch({
                    type: types.BATCH_UPDATED,
                    payload: updatedBatch,
                });
            })
            .catch((error) => handleOpenBatchError(error, dispatch))
            .catch((error) => console.warn('Error on re-open batch: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function updateBatchEmailInfo(batch, info) {
    return async function (dispatch) {
        const isEmailNoteChanged = batch.note_to_email !== info.emailNote;
        const addEmailNoteToBatch = () => {
            return addSettlementBatchEmailNote(batch.id, info.emailNote)
                .then((response) => response.data)
                .catch((e) => console.warn('Error on add email note: ', e));
        };
        const sendEmailToBatchCarrier = () => {
            const formattedBatch = getFormattedBatchSendEmail(info.emailSender);
            return sendSettlementBatchEmail(batch.id, formattedBatch)
                .then((response) => response.data)
                .catch((e) => console.warn('Error on send batch email: ', e));
        };
        const updateBatch = (updatedBatch) => {
            if (updatedBatch) {
                dispatch({
                    type: types.BATCH_UPDATED,
                    payload: updatedBatch,
                });
            }
        };

        if (isEmailNoteChanged && !info.sendEmail) {
            dispatch(appActions.showLoader());
            addEmailNoteToBatch()
                .then(updateBatch)
                .finally(() => dispatch(appActions.hideLoader()));
        } else if (info.sendEmail && !isEmailNoteChanged) {
            dispatch(appActions.showLoader());
            sendEmailToBatchCarrier()
                .then(updateBatch)
                .finally(() => dispatch(appActions.hideLoader()));
        } else {
            dispatch(appActions.showLoader());
            await addEmailNoteToBatch().then(updateBatch);
            await sendEmailToBatchCarrier().then(updateBatch);
            dispatch(appActions.hideLoader());
        }
    };
}

export function searchBatches({status: selectedStatus, searchParams, pagination, sortBy}) {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        fetchAllBatches({status: selectedStatus, ...searchParams, sortBy, ...formatPaginationParams(pagination)})
            .then(({data: batches, headers}) => {
                dispatch(expandAndHighlightRow(batches));

                dispatch({
                    type: types.LIST_BATCHES_RECEIVED,
                    payload: {batches, searchParams, pagination: parsePaginationHeaders(headers)},
                });
            })
            .catch((error) => console.warn('Error fetch list batches: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function batchSettlementsReceived(batchID, settlements) {
    return function (dispatch) {
        dispatch({
            type: types.LIST_BATCHES_SETTLEMENTS_RECEIVED,
            payload: {batchID, settlements},
        });
    };
}

export function clearBatchesData() {
    return function (dispatch) {
        dispatch({
            type: types.BATCHES_DATA_CLEARED,
        });
    };
}
