import * as actionTypes from './actionTypes';

export function truckLocationSameFromReceived(data) {
    return {
        type: actionTypes.WEB_SOCKET_TRAVEL_ORDER_TRUCK_LOCATION_SAME_FROM_CHANGED,
        payload: data,
    };
}

export function travelOrderUpdate(payload, websocketEvent) {
    return {
        type: actionTypes.WEB_SOCKET_TRAVEL_ORDER_UPDATED,
        // added for show log in store-logger
        websocketEvent,
        payload,
    };
}

export function travelOrderFilePosted(travelOrderID, files) {
    return {
        type: actionTypes.WEB_SOCKET_TRAVEL_ORDER_FILES_POSTED,
        payload: {travelOrderID: parseInt(travelOrderID, 10), files},
    };
}

export function travelOrderFileDeleted(travelOrderID, files) {
    return {
        type: actionTypes.WEB_SOCKET_TRAVEL_ORDER_FILES_DELETED,
        payload: {travelOrderID, files},
    };
}
