import keyBy from 'lodash/keyBy';

import Carrier from 'core/entities/Carrier/types';

import * as tabs from '../constants/tabs';
import * as types from '../actionTypes';

const HTTP_STATUS_OK = 200;

export interface CurrentState {
    current: Carrier | null;
    activeTab: string;
    isCurrentCarrier?: boolean;
}

const defaultState: CurrentState = {
    current: null,
    activeTab: tabs.GENERAL.section,
};

const extendCarrier = (carrier: Carrier): Carrier => {
    Object.keys(carrier).forEach((key) => {
        carrier[key] = carrier[key] === 'null' ? null : carrier[key];
    });

    return {
        ...carrier,
        existsFiles: carrier.files ? carrier.files.slice() : [],
        files: [],
    };
};

export default function carrierReducer(state = defaultState, action): CurrentState {
    switch (action.type) {
        case types.RECEIVED_CARRIER:
        case types.UPDATED_CARRIER:
        case types.CREATED_CARRIER: {
            const {carrier, isCurrentCarrier, files, deletedFiles} = action.payload;

            const oldFiles = (state.current && state.current.existsFiles) || [];
            const oldFilesObj = (state.current && keyBy(oldFiles, 'id')) || {};

            // filter new files, from those which already in state
            const newFiles = (files && files.filter((file) => !oldFilesObj[file.id])) || [];

            carrier.files = [...oldFiles, ...newFiles];

            const newCarrier = extendCarrier(carrier);

            const deletedFilesObj =
                deletedFiles &&
                keyBy(
                    deletedFiles.filter((file) => file.response.status === HTTP_STATUS_OK),
                    'id',
                );

            if (deletedFilesObj) {
                newCarrier.existsFiles = newCarrier.existsFiles.filter((file) => !deletedFilesObj[file.id]);
            }

            return {
                ...state,
                current: newCarrier,
                isCurrentCarrier,
            };
        }

        case types.CARRIER_ARCHIVED:
        case types.CARRIER_RESTORED: {
            const {carrier: updatedCarrier} = action.payload;
            if (!state.current || !updatedCarrier) {
                return state;
            }
            return {...state, current: {...state.current, is_deleted: updatedCarrier.is_deleted}};
        }

        case types.FORM_TAB_CHANGED: {
            return {
                ...state,
                activeTab: action.payload.activeTab,
            };
        }

        case types.CLEAR_STATE: {
            return defaultState;
        }

        default:
            return state;
    }
}
