import React from 'react';

import Tooltip from 'components/ui/Tooltip';

import {SettlementOwner} from 'core/entities/Settlement/types';
import {isSettlementFuturePayPeriod, isSettlementPastDuePayPeriod} from 'core/entities/Settlement';

type OwnProps = {
    settlement: SettlementOwner;
};

const SettlementPayPeriodBadge: React.FC<OwnProps> = ({settlement}) => {
    if (isSettlementFuturePayPeriod(settlement)) {
        return (
            <Tooltip className="d-inline-block icon-tooltip" tooltipContent="future pay period">
                <div className="badge">FPP</div>
            </Tooltip>
        );
    }
    if (isSettlementPastDuePayPeriod(settlement)) {
        return (
            <Tooltip className="d-inline-block icon-tooltip" tooltipContent="period past due">
                <div className="badge">past due</div>
            </Tooltip>
        );
    }
    return null;
};

export default SettlementPayPeriodBadge;
