import React from 'react';

type OwnProps = {
    toProfileLabel: string;
    entityName: string;
};

const ArchiveForm: React.FC<OwnProps> = ({entityName, toProfileLabel}) => {
    return (
        <div className="row align-items-center">
            <div className="col-1">
                <i className="fa fa-exclamation feedback-icon-error" />
            </div>
            <div className="col-11">
                <p>
                    Archiving <strong>{toProfileLabel}</strong>&nbsp;
                    {entityName}&rsquo;s profile will deactivate any actions with it. &nbsp;If needed, you will still
                    have the ability to restore the profile.
                </p>
            </div>
        </div>
    );
};

export default ArchiveForm;
