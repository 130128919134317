import React from 'react';

import Button from 'components/ui/Buttons/Button/index';

import ResetCheckbox from '../SelectCheckbox';

import styles from './styles.module.scss';

interface OwnProps {
    allItemsCount: number;
    currentItemsCount: number;
    selectedItemsCount: number;
    onSelectAll();
    onResetAll();
}

const SelectedItemsInfo = (props: OwnProps): JSX.Element => {
    const {allItemsCount, currentItemsCount, selectedItemsCount, onSelectAll, onResetAll} = props;
    const isAllSelected = currentItemsCount === selectedItemsCount;
    const isResetDisabled = selectedItemsCount === 0;
    return (
        <div className={styles.container}>
            <div className={styles.selected_items}>
                <ResetCheckbox
                    onSelect={onSelectAll}
                    checked={currentItemsCount > 0}
                    symbol={isAllSelected ? 'check' : 'minus'}
                />
                <div>
                    {selectedItemsCount} / {allItemsCount} invoices
                </div>
            </div>
            <Button disabled={isResetDisabled} onClick={onResetAll} buttonSize="small" colorTheme="grey">
                Reset Status
            </Button>
        </div>
    );
};

export default SelectedItemsInfo;
