import {FormErrors} from 'redux-form';

import {isValidLength} from 'utils/validation';
import * as regexps from 'utils/validation/regexps';
import * as errorMessages from 'utils/validation/errorMessages';

import {ChangePasswordModalFormValues} from 'pages/Dispatchers/types';

const validate = (values: ChangePasswordModalFormValues): FormErrors<ChangePasswordModalFormValues> => {
    const errors: FormErrors<ChangePasswordModalFormValues> = {};
    const MIN_LENGTH = 6;
    const MAX_LENGTH = 16;

    if (!values.current_password) {
        errors.current_password = errorMessages.REQUIRED;
    }

    if (!values.password) {
        errors.password = errorMessages.REQUIRED;
    } else if (!regexps.LETTERS_AND_DIGITS.test(values.password)) {
        errors.password = 'Password must contain letters and numbers.';
    } else if (values.password && !isValidLength(MIN_LENGTH, MAX_LENGTH, values.password)) {
        errors.password = errorMessages.INVALID_LENGTH(MIN_LENGTH, MAX_LENGTH);
    } else if (!regexps.MIN_VALID_STRING.test(values.password)) {
        errors.password = errorMessages.MIN_VALID_STRING;
    }

    if (!values.confirm_password) {
        errors.confirm_password = errorMessages.REQUIRED;
    } else if (!regexps.LETTERS_AND_DIGITS.test(values.confirm_password)) {
        errors.confirm_password = 'Password must contain letters and numbers.';
    } else if (values.confirm_password && !isValidLength(MIN_LENGTH, MAX_LENGTH, values.confirm_password)) {
        errors.confirm_password = errorMessages.INVALID_LENGTH(MIN_LENGTH, MAX_LENGTH);
    } else if (values.password !== values.confirm_password) {
        errors.confirm_password = 'Password and confirm password must be the same';
    }

    return errors;
};

export default validate;
