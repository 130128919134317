import React, {useMemo} from 'react';

import {getSettlementLoadNumber, getSettlementTotalDriverPayment} from 'core/entities/Settlement';
import {SettlementOwner} from 'core/entities/Settlement/types';
import CurrencyAmount from 'components/ui/CurrencyAmount';

import SettlementOriginDestinationInfo from 'pages/Settlements/components/common/SettlementOriginDestinationInfo/index';
import SettlementMilesInfo from 'pages/Settlements/components/common/SettlementMilesInfo/index';
import SettlementLastDeliveryCheckoutTime from 'pages/Settlements/components/common/SettlementLastDeliveryCheckoutTime';

import TruckInfo from './TruckInfo';

interface OwnProps {
    settlement: SettlementOwner;
    isNew: boolean;
}

const SettlementRow: React.FC<OwnProps> = ({settlement, isNew}) => {
    const loadNumber = getSettlementLoadNumber(settlement);
    const totalPayment = useMemo(() => {
        return getSettlementTotalDriverPayment(settlement);
    }, [settlement]);

    return (
        <tr>
            <td>
                {loadNumber && <div>Load #{loadNumber}</div>}
                {settlement.settlement_number && <div>T.O #{settlement.settlement_number}</div>}
            </td>
            <td>
                {isNew && <span className="badge-new">new</span>}
                <TruckInfo settlement={settlement} />
            </td>
            <td>
                <SettlementOriginDestinationInfo settlement={settlement} />
            </td>
            <td>
                <SettlementMilesInfo settlement={settlement} />
            </td>
            <td>
                <SettlementLastDeliveryCheckoutTime settlement={settlement} />
            </td>
            <td>
                <div className="payment">
                    <CurrencyAmount currency={settlement.currency} amount={totalPayment} withStrong={false} />
                </div>
            </td>
        </tr>
    );
};

export default SettlementRow;
