import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {reduxForm, Field, InjectedFormProps, reset} from 'redux-form';

import * as filesValidation from 'widgets/Chat/components/MessageForm/utils';
import {SEND_MESSAGE_FORM, TEXT_FIELD} from 'widgets/Chat/constants';
import useChatState from 'widgets/Chat/hooks/useChatState';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import Button from 'components/ui/Buttons/Button';

import ResizableTextarea from './components/ResizableTextarea';
import AttachmentsUpload from './components/AttachmentsUpload';
import SendingInfo from './components/SendingInfo';
import Alert from './components/Alert';

import styles from './styles.module.scss';

const ATTACHMENTS_FIELD = 'attachments';

const MessageForm: React.FC<InjectedFormProps> = (props) => {
    const {handleSubmit, pristine, form} = props;

    const attachments = useGetReduxFormValue(form, 'attachments');
    const {chatClientSocketId, chatMessageStatus} = useChatState();
    const dispatch = useDispatch();

    const {isSomeAttachmentTypeUnsupported} = filesValidation.checkAttachmentsTypeValidation({files: attachments});
    const {isSomeFileGte50MB} = filesValidation.calculateAttachmentsSizeValidation({files: attachments});

    useEffect(() => {
        if (chatMessageStatus === 'success') {
            dispatch(reset(form));
        }
    }, [chatMessageStatus]);

    const isSendingStatus = chatMessageStatus === 'sending';

    const isSubmitDisabled =
        isSomeAttachmentTypeUnsupported || !chatClientSocketId || isSomeFileGte50MB || isSendingStatus || pristine;

    const isTextAreaDisabled =
        isSomeFileGte50MB || isSomeAttachmentTypeUnsupported || !chatClientSocketId || isSendingStatus;

    return (
        <form onSubmit={handleSubmit} className={styles.send}>
            <SendingInfo />
            <Alert form={form} />

            <div className={styles.body}>
                <Field
                    disabled={!chatClientSocketId || isSendingStatus}
                    existingFilesKey="attachments"
                    component={AttachmentsUpload}
                    name={ATTACHMENTS_FIELD}
                    type="file"
                />

                <div className="d-flex align-items-end">
                    <Field
                        disabled={isTextAreaDisabled}
                        onSubmitByEnter={handleSubmit}
                        component={ResizableTextarea}
                        name={TEXT_FIELD}
                        maxLength="750"
                        autoFocus
                    />

                    <Button
                        disabled={isSubmitDisabled}
                        buttonIcon="location-arrow"
                        className="button-search"
                        colorTheme="grey"
                        buttonSize="icon"
                        type="submit"
                    />
                </div>
            </div>
        </form>
    );
};

export default reduxForm({form: SEND_MESSAGE_FORM})(MessageForm);
