import React from 'react';
import {Quote} from 'core/entities/Quote/types';
import {QuoteInitialValues} from 'pages/LoadBoard/types';
import {getDestinationAddress, getOriginAddress} from './utils';
import styles from './styles.module.scss';
import StopCityLine from '../common/StopCityLine';

type OwnProps = {
    quotes: Quote[];
    formValues: QuoteInitialValues;
};

const QuoteListInfo: React.FC<OwnProps> = (props) => {
    const {quotes, formValues} = props;

    const originAddress = getOriginAddress(formValues);
    const destinationAddress = getDestinationAddress(formValues);
    return (
        <div className={styles.wrapper}>
            <i className={`fa fa-exclamation-triangle ${styles.icon} pr-1`} aria-hidden="true" />
            <strong>{quotes.length}</strong> Similar quotes were found based on the entered data:
            <p>
                <strong>
                    <StopCityLine originAddress={originAddress} destinationAddress={destinationAddress} />
                </strong>
            </p>
        </div>
    );
};

export default QuoteListInfo;
