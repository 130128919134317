import {ThunkAction} from 'redux-thunk';

import {AppState} from 'store';
import * as appActions from 'store/actions';

import {GetCheckCallEventDataFromRequest} from 'core/entities/LocationEvents/types';
import {getTravelOrderNumber} from 'core/entities/TravelOrder/modules/common/getTravelOrderNumber';
import TravelOrder from 'core/entities/TravelOrder/types';
import locationEventApiGateway from 'core/gateways/LocationEventApiGateway';

import {AddCheckCallFormValues} from '../../types';
import {transformSetConfirmDataToRequestBody} from '../mappers';

type ThunkActionTypes = ThunkAction<void, AppState, unknown, any>;

export const setCheckCall = (
    travelOrder: TravelOrder,
    dispatchSetCheckCallData: (dataFromRequest: GetCheckCallEventDataFromRequest) => void,
): ThunkActionTypes => async (dispatch) => {
    const travelOrderNumber = getTravelOrderNumber(travelOrder);

    if (!travelOrderNumber) {
        return false;
    }

    try {
        dispatch(appActions.showLoader());

        const checkCallDataFromRequest = await locationEventApiGateway.getCheckCallEventData(travelOrderNumber);

        dispatchSetCheckCallData({...checkCallDataFromRequest, travelOrder});
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const setConfirm = ({
    travelOrderNumber,
    setConfirmData,
    createFormValue,
}: {
    travelOrderNumber: number;
    createFormValue: AddCheckCallFormValues;
    setConfirmData: (dataFromRequest) => void;
}): ThunkActionTypes => async (dispatch) => {
    if (!travelOrderNumber) {
        return false;
    }

    try {
        dispatch(appActions.showLoader());

        const postData = transformSetConfirmDataToRequestBody(createFormValue);

        const confirmDataFromRequest = await locationEventApiGateway.getConfirmEventData({
            travelOrderNumber,
            postData,
        });

        setConfirmData({...confirmDataFromRequest});
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};
