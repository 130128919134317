export default function (file) {
    const {extra_data: jsonExtraData} = file;
    let parsedData;

    if (!jsonExtraData) {
        return file;
    }

    try {
        parsedData = JSON.parse(jsonExtraData);
    } catch (e) {
        console.warn('Incorrect extra_data in file: ', file);
    }

    if (!parsedData) {
        return file;
    }

    return {...file, extra_data: parsedData};
}
