import Carrier from 'core/entities/Carrier/types';

export const getCarrierPhysicalAdditionalAddressLine = (carrier?: Carrier | null) =>
    carrier?.physical_additional_address_line;

export const getCarrierPhysicalAddressLine = (carrier?: Carrier | null) => carrier?.physical_address_line;

export const getCarrierPhysicalCountry = (carrier?: Carrier | null) => carrier?.physical_country;

export const getCarrierPhysicalState = (carrier?: Carrier | null) => carrier?.physical_state;

export const getCarrierPhysicalCity = (carrier?: Carrier | null) => carrier?.physical_city;

export const getCarrierPhysicalZip = (carrier?: Carrier | null) => carrier?.physical_zip;

type CarrierPhysicalAddressAllInfo = {
    carrierPhysicalAdditionalAddressLine: ReturnType<typeof getCarrierPhysicalAdditionalAddressLine>;
    carrierPhysicalAddressLine: ReturnType<typeof getCarrierPhysicalAddressLine>;
    carrierPhysicalCountry: ReturnType<typeof getCarrierPhysicalCountry>;
    carrierPhysicalState: ReturnType<typeof getCarrierPhysicalState>;
    carrierPhysicalCity: ReturnType<typeof getCarrierPhysicalCity>;
    carrierPhysicalZip: ReturnType<typeof getCarrierPhysicalZip>;
};

export const getCarrierPhysicalAddressAllInfo = (carrier?: Carrier | null): CarrierPhysicalAddressAllInfo => {
    return {
        carrierPhysicalAdditionalAddressLine: getCarrierPhysicalAdditionalAddressLine(carrier),
        carrierPhysicalAddressLine: getCarrierPhysicalAddressLine(carrier),
        carrierPhysicalCountry: getCarrierPhysicalCountry(carrier),
        carrierPhysicalState: getCarrierPhysicalState(carrier),
        carrierPhysicalCity: getCarrierPhysicalCity(carrier),
        carrierPhysicalZip: getCarrierPhysicalZip(carrier),
    };
};
