import React, {useEffect, useState} from 'react';
import {Field} from 'redux-form';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import {FIELDS} from 'core/entities/Customer/constants/fields';

import CheckSwitch from 'components/ui/Form/CheckSwitch';

import ApiConnectionSelect from './components/ApiConnectionSelect';

import styles from './styles.module.scss';

type OwnProps = {
    disabled?: boolean;
    form: string;
};

const CustomerApiBroker: React.FC<OwnProps> = ({form, disabled}) => {
    const isApiBroker = useGetReduxFormValue(form, FIELDS.isThirdApiBroker);
    const changeFormValue = useChangeReduxFormValue(form);

    const [viewCustomerApiBroker, setViewCustomerApiBroker] = useState<boolean>(isApiBroker);

    useEffect(() => {
        if (!isApiBroker) {
            changeFormValue(FIELDS.apiConnection, null);
        }
        setViewCustomerApiBroker(isApiBroker);
    }, [isApiBroker]);

    return (
        <div className={styles.apiBroker}>
            <Field
                name={FIELDS.isThirdApiBroker}
                label="ENABLE AUTOUPDATES"
                component={CheckSwitch}
                disabled={disabled}
            />

            {viewCustomerApiBroker ? <ApiConnectionSelect disabled={disabled} /> : null}
        </div>
    );
};

export default CustomerApiBroker;
