import React from 'react';
import {Modal} from 'react-bootstrap';

import Form from './Form';

import styles from './main.module.scss';

const GenerateInvoicePDFModal = (props) => {
    const {
        onClose,
        data: {invoice, loadFiles, fullTravelOrder, travelOrderFiles},
        handlers: {onGeneratePDF},
    } = props;
    return (
        <Modal
            show={true}
            onHide={onClose}
            dialogClassName={`standard-form-modal modal-md ${styles.invoice}`}
            backdrop="static"
        >
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>
                        <strong>#{invoice.invoice_number}</strong>&nbsp;Generation Settings
                    </Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form
                    invoice={invoice}
                    loadFiles={loadFiles}
                    fullTravelOrder={fullTravelOrder}
                    travelOrderFiles={travelOrderFiles}
                    onClose={onClose}
                    onSubmit={onGeneratePDF}
                />
            </Modal.Body>
        </Modal>
    );
};

export default GenerateInvoicePDFModal;
