import React from 'react';
import {Quote} from 'core/entities/Quote/types';
import QuoteRow from './components/QuoteRow';

import styles from './styles.module.scss';

const QuoteList: React.FC<{quotes: Quote[]}> = (props) => {
    const {quotes} = props;
    return (
        <div className={styles.wrapper}>
            <div className="overflow-hidden">
                {quotes.map((quote) => (
                    <QuoteRow quote={quote} key={quote.id} />
                ))}
            </div>
        </div>
    );
};

export default QuoteList;
