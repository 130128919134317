import React, {useState} from 'react';
import classNames from 'classnames';

import {FileNew} from 'types/File';

import useRefWithOutsideClickHandler from 'hooks/useRefWithOutsideClickHandler';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';
import {Dispatcher} from 'core/entities/Dispatcher/types';

import ButtonsBlock from 'components/ui/ButtonsBlock';
import Button from 'components/ui/Buttons/Button';
import Tooltip from 'components/ui/Tooltip';

import formatFileSize from 'utils/files/formatFileSize';
import getFilePath from 'utils/files/getFilePath';
import {createDate} from 'utils/dateTime';

import {checkIsFileHasAcceptedImageType, trimTypePrefix} from './utils';

import styles from './styles.module.scss';

type OwnProps = {
    postedBy?: Dispatcher;
    isSelected: boolean;
    gridView: boolean;
    file: FileNew;

    onSelect: () => void;
    onView: () => void;
};

const FilePreview = (props: OwnProps): JSX.Element => {
    const {file, isSelected, gridView, onSelect, onView, postedBy} = props;

    const [isFocused, setIsVisible] = useState(false);
    const containerNode = useRefWithOutsideClickHandler(isFocused, setIsVisible);

    const filePath = getFilePath(file);
    const fileName = file.displayName;
    const fileSize = file.fileSize && formatFileSize(file.fileSize);
    const fileType = trimTypePrefix(file.mimeType || file.type);
    const isImage = checkIsFileHasAcceptedImageType(fileType);
    const postedByName = getDispatcherName(postedBy, true);
    const dateOfCreation = createDate(file.uploadedAt).fullDate;
    const isImageShown = isImage && gridView;

    const fileItemClassName = classNames('file-item', {'active-state': isFocused}, {list: !gridView});

    return (
        <div ref={containerNode} className={styles.item}>
            <div className={fileItemClassName}>
                <div className="file-item-block">
                    <div className="file-preview">
                        <ButtonsBlock className="action-buttons">
                            <Button onClick={onView}>view</Button>
                            <a className="button button-grey" href={filePath} download={fileName}>
                                download
                            </a>
                            <Button disabled>delete</Button>
                        </ButtonsBlock>
                        <div className="file-preview__hover" onClick={onView} />
                        {isImageShown ? (
                            <img src={filePath} alt="" />
                        ) : (
                            <div className="file-thumbnail">{fileType}</div>
                        )}
                    </div>
                    <div className="file-name-tooltip" onClick={() => setIsVisible(true)}>
                        <Tooltip tooltipContent={`${file.displayName}`} display="block">
                            {gridView ? (
                                <div className="file-name">{file.displayName}</div>
                            ) : (
                                <div className="file-name">
                                    <a className="second-link" href={filePath} download={fileName}>
                                        {file.displayName}
                                    </a>
                                </div>
                            )}
                        </Tooltip>
                        {fileSize && <div className="file-size">{fileSize}</div>}
                        <div className="file-dispatcher">
                            added by: {postedByName} {dateOfCreation}
                        </div>
                    </div>
                </div>
                <div className="checkbox-custom__block">
                    <label>
                        <input
                            onChange={onSelect}
                            checked={isSelected}
                            className="checkbox"
                            type="checkbox"
                            name="checkbox-test"
                        />
                        <span className="checkbox-custom__item" />
                    </label>
                </div>

                <div className="is-sending">
                    {/* Условие с отправкой файла */}
                    {/* for list */}
                    {/* <div className="status"> */}
                    {/*    <em>not sent</em> */}
                    {/* </div> */}
                    {/* for grid */}
                    {/* <i className="fa fa-check-circle" /> */}
                </div>
            </div>
        </div>
    );
};

export default FilePreview;
