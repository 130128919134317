import {InferActions} from 'store';

import Truck from 'core/entities/Truck/types';

import {ATTACH_LOAD_TABS, MAX_COUNT_TRUCKS_IN_ONE_PAGE} from 'pages/Loads/constants/loadConstants';
import * as attachCarriersMappers from 'pages/Loads/redux/mappers/attach/carriers';
import * as attachTrucksMappers from 'pages/Loads/redux/mappers/attach/trucks';
import {attachActionCreators} from 'pages/Loads/redux/actions/attach';
import * as types from 'pages/Loads/redux/actionTypes/attach';
import Carrier from 'core/entities/Carrier/types';

type ActionTypes = InferActions<typeof attachActionCreators>;

export type AttachState = {
    searchParams: {trucks: {}; carriers: {company_name: string}};
    searchCarriers: Carrier[] | null;
    isAvailableMoreTrucks: boolean;
    pagination: {trucks: any};
    carriers: Carrier[];
    activeTab: string;
    trucks: Truck[];
};

const defaultState: AttachState = {
    pagination: {trucks: {currentPage: 1, perPage: MAX_COUNT_TRUCKS_IN_ONE_PAGE}},
    searchParams: {trucks: {}, carriers: {company_name: ''}},
    activeTab: ATTACH_LOAD_TABS.TRUCKS,
    isAvailableMoreTrucks: false,
    searchCarriers: null,
    carriers: [],
    trucks: [],
};

const attach = (state = defaultState, action: ActionTypes): AttachState => {
    switch (action.type) {
        case types.SET_TAB: {
            return {
                ...state,
                activeTab: action.payload.tab,
            };
        }

        case types.SET_TRUCKS_PAGINATION:
            return attachTrucksMappers.handleReducerBySetTrucksPagination({
                state,
                pagination: action.payload.pagination,
            });

        case types.RECEIVED_TRUCKS: {
            const {trucks, searchParams, pagination, isAvailableMoreTrucks} = action.payload;

            return attachTrucksMappers.handleReducerByReceivedTrucks({
                state,
                trucks,
                pagination,
                searchParams,
                isAvailableMoreTrucks,
            });
        }

        case types.RECEIVED_CARRIERS: {
            const {carriers} = action.payload;

            return attachCarriersMappers.handleReducerByReceivedCarriers({state, carriers});
        }

        case types.RECEIVED_MORE_TRUCKS: {
            const {trucks, isAvailableMoreTrucks} = action.payload;

            return attachTrucksMappers.handleReducerByReceivedMoreTrucks({
                state,
                isAvailableMoreTrucks,
                additionalTrucks: trucks,
            });
        }

        case types.SET_CARRIERS_SEARCH_PARAMS: {
            const {searchParams} = action.payload;

            return attachCarriersMappers.handleReducerBySetCarriersSearchParams({
                state,
                companyName: searchParams.company_name,
            });
        }

        case types.CLEARED_CARRIERS_SEARCH_PARAMS: {
            return attachCarriersMappers.handleReducerByClearedCarriersSearchParams({state});
        }

        case types.CLEARED_STATE: {
            return {...defaultState};
        }

        // no default
    }

    const _exhaustiveCheck = action;

    return state;
};

export default attach;
