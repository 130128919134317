import React from 'react';

import {statuses} from 'core/entities/Invoice/constants';

interface OwnProps {
    invoiceStatus: string;
    isInvoiceReset: boolean;
}

const ResetBadge = ({invoiceStatus, isInvoiceReset}: OwnProps): JSX.Element | null => {
    if (isInvoiceReset && invoiceStatus === statuses.POSTED) {
        return <div className="revert-invoice">reset</div>;
    }

    if (isInvoiceReset && invoiceStatus !== statuses.POSTED) {
        return <div className="revert-invoice">revised</div>;
    }

    return null;
};

export default ResetBadge;
