import * as actionTypes from './actionTypes';

export function updateInvoice(data) {
    return {
        type: actionTypes.WEB_SOCKET_BILLING_INVOICE_UPDATED,
        payload: data,
    };
}

export function updateInvoiceStatusToReadyToGenerate(data) {
    return {
        type: actionTypes.WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_READY_TO_GENERATE,
        payload: data,
    };
}

export function updateInvoiceStatusToGenerated(data) {
    return {
        type: actionTypes.WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_GENERATED,
        payload: data,
    };
}

export function updateInvoiceStatusToReadyToPost(data) {
    return {
        type: actionTypes.WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_READY_TO_POST,
        payload: data,
    };
}

export function updateInvoiceStatusToPosted(data) {
    return {
        type: actionTypes.WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_POSTED,
        payload: data,
    };
}

export function resetInvoice(data) {
    return {
        type: actionTypes.WEB_SOCKET_BILLING_INVOICE_RESET,
        payload: data,
    };
}

export function updateBatch(data) {
    return {
        type: actionTypes.WEB_SOCKET_BILLING_BATCH_UPDATED,
        payload: data,
    };
}

export function updateInvoiceSettlement(data) {
    return {
        type: actionTypes.WEB_SOCKET_INVOICE_SETTLEMENT_UPDATED,
        payload: {
            updatedSettlement: data,
        },
    };
}

export function createBatch(data) {
    return {
        type: actionTypes.WEB_SOCKET_INVOICES_BATCH_CREATED,
        payload: {batch: data},
    };
}

export function generateBatch(data) {
    return {
        type: actionTypes.WEB_SOCKET_INVOICES_BATCH_GENERATED,
        payload: {batch: data},
    };
}

export function resetInvoiceFromBatch(data) {
    return {
        type: actionTypes.WEB_SOCKET_INVOICES_INVOICE_RESET_FROM_BATCH,
        payload: {batch: data},
    };
}

export function addInvoicesToBatch(data) {
    return {
        type: actionTypes.WEB_SOCKET_INVOICES_INVOICE_ADDED_TO_BATCH,
        payload: {batch: data},
    };
}
