export const WEB_SOCKET_BILLING_INVOICE_UPDATED = 'WEB_SOCKET_BILLING_INVOICE_UPDATED';
// eslint-disable-next-line
export const WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_READY_TO_GENERATE = 'WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_READY_TO_GENERATE';
// eslint-disable-next-line
export const WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_GENERATED = 'WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_GENERATED';
// eslint-disable-next-line
export const WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_READY_TO_POST = 'WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_READY_TO_POST';
// eslint-disable-next-line
export const WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_POSTED = 'WEB_SOCKET_BILLING_INVOICE_STATUS_CHANGED_TO_POSTED';
export const WEB_SOCKET_BILLING_INVOICE_RESET = 'WEB_SOCKET_BILLING_INVOICE_RESET';
export const WEB_SOCKET_BILLING_BATCH_CREATED = 'WEB_SOCKET_BILLING_BATCH_CREATED';
export const WEB_SOCKET_BILLING_BATCH_UPDATED = 'WEB_SOCKET_BILLING_BATCH_UPDATED';
export const WEB_SOCKET_INVOICE_SETTLEMENT_UPDATED = 'WEB_SOCKET_INVOICE_SETTLEMENT_UPDATED';

export const WEB_SOCKET_INVOICES_BATCH_CREATED = 'WEB_SOCKET_INVOICES_BATCH_CREATED';
export const WEB_SOCKET_INVOICES_BATCH_GENERATED = 'WEB_SOCKET_INVOICES_BATCH_GENERATED';
export const WEB_SOCKET_INVOICES_INVOICE_RESET_FROM_BATCH = 'WEB_SOCKET_INVOICES_INVOICE_RESET_FROM_BATCH';
export const WEB_SOCKET_INVOICES_INVOICE_ADDED_TO_BATCH = 'WEB_SOCKET_INVOICES_INVOICE_ADDED_TO_BATCH';
