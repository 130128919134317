import React, {useState, useEffect} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {Form} from 'react-bootstrap';
import classNames from 'classnames';
import {change, setSubmitFailed, WrappedFieldProps} from 'redux-form';

import OverlayTriggerCustom from '../OverlayTriggerCustom';
import getValidationState from '../getValidationState';

import styles from './styles.module.scss';

const EXCLUDED_COUNTRIES = {
    bz: 'Belize',
    cr: 'Costa Rica',
    sv: 'El Salvador',
    gu: 'Guam',
    gt: 'Guatemala',
    hn: 'Honduras',
    ni: 'Nicaragua',
    pa: 'Panama',
};

const PREFERRED_COUNTRIES = {
    us: 'United States',
    ca: 'Canada',
    mx: 'Mexico',
};

const REGIONS = {
    'north-america': 'North-America',
    'central-america': 'Central-America',
    europe: 'Europe',
};

interface OwnProps {
    label?: string | JSX.Element;
    disabled: boolean;
}

const PHONE_UPDATE_TIME = 200;

const PhoneWithLocalization: React.FC<WrappedFieldProps & OwnProps> = (props) => {
    const {label, disabled, input, meta} = props;
    const [phoneInputValue, setPhoneInputValue] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [countryPhone, setCountryPhone] = useState('');
    const validate = getValidationState(meta);
    const wrapClassNames = validate ? classNames(styles.phone_wrap) : classNames(styles.phone_wrap, styles.phone_error);

    /**
     * In the <PhoneInput> set option 'autoFormat' by phone and country number.
     * USA has equal country code as Canada: +1.
     * If select country Canada and continue filling the input phone
     * the country flag automatically changed to the USA flag.
     * For solve this mistake <PhoneInput> was created uncontrollable,
     * and now input value could be '' or correct phone number if form has initial values.
     * If we fill phone input and will want to clear or reset field we can't do it
     * because phone input's value is empty and now we should use setTimeout in the useEffect hook.
     *
     * Steps to reproduce:
     * - component mounted and value is empty - from initial value is empty to.
     * - fill input whole or in part - PhoneInput's value still is empty.
     * - click <Clear Fields> button - triggered reset form and phone field must be empty but default <PhoneInput> is empty
     *   and component don't rerendered so we set some value and after some time we set correct value by setTimeout in the hook.
     * */

    useEffect(() => {
        const formPhone = input.value;
        const inputCode = countryCode ? `+${countryCode}` : '+1';

        if (!meta.active && !meta.touched && !formPhone) {
            setPhoneInputValue('+');
            setTimeout(() => setPhoneInputValue(inputCode), PHONE_UPDATE_TIME);
        } else if (!meta.active && !meta.touched && formPhone) {
            setPhoneInputValue(countryPhone ? `+${countryPhone}` : `+${formPhone}`);
            setTimeout(() => setPhoneInputValue(formPhone), PHONE_UPDATE_TIME);
        }
    }, [meta.touched, input.value, countryPhone, countryCode, meta.active]);

    const onChange = (value, data) => {
        const code = data.dialCode;
        const phoneLength = [...data.format].filter((i) => i === '.').length;
        const phoneWithOutCode = value.slice(code.length);
        const phone = [code, phoneWithOutCode];

        setCountryCode(code);
        setCountryPhone(phone.join(' '));

        if (phone.join('').length !== phoneLength && phoneWithOutCode.length > 0) {
            meta.dispatch(change(meta.form, input.name, ' ', true));
        } else if (phone.join('').length !== phoneLength && phoneWithOutCode.length === 0) {
            meta.dispatch(change(meta.form, input.name, null, true));
            meta.dispatch(setSubmitFailed(meta.form, input.name));
        } else {
            input.onChange(phone.join(' '));
        }
    };

    const errorIcon = !validate && (
        <Form.Control.Feedback type="invalid" className="error">
            <i className="fa fa-exclamation-circle" />
        </Form.Control.Feedback>
    );

    const customMasks = {
        de: '(...) ....-....',
        nl: '(..) ...-....',
    };

    return (
        <div className={wrapClassNames}>
            <div className={styles.phone_label}>{label}</div>

            <OverlayTriggerCustom inputMeta={meta} placement="bottom">
                <PhoneInput
                    country="us"
                    value={phoneInputValue}
                    regions={Object.keys(REGIONS)}
                    excludeCountries={Object.keys(EXCLUDED_COUNTRIES)}
                    preferredCountries={Object.keys(PREFERRED_COUNTRIES)}
                    enableSearch={true}
                    onChange={onChange}
                    disabled={disabled}
                    onFocus={(e) => input.onFocus(e)}
                    inputClass={styles.phone_input}
                    countryCodeEditable={false}
                    inputStyle={{width: '100%'}}
                    defaultMask="... ... ..."
                    masks={customMasks}
                />
            </OverlayTriggerCustom>

            <div className={styles.phone_error__icon}>{errorIcon}</div>
        </div>
    );
};

export default PhoneWithLocalization;
