import {useDispatch} from 'react-redux';
import {ThunkAction} from 'redux-thunk';

import {AppState} from 'store';
import {ThunkResult} from 'types/ThunkResult';

import type Batch from 'core/entities/Invoice/types/Batch';
import type Invoice from 'core/entities/Invoice/types';

import type {ActionTypes} from '../actionTypes';
import * as modalActions from '../actions/modalActions';
import useInvoicesSearchParams from './useInvoicesSearchParams';
import {transformSearchParamsForRequest} from './utils';
import * as batchActions from '../actions/batchActions';

type BatchActions = {
    openSetupBatchModal: () => ThunkAction<void, AppState, unknown, ActionTypes>;
    openEditBatchModal: (batch: Batch) => ThunkAction<void, AppState, unknown, ActionTypes>;
    openPostBatchModal: (batch: Batch) => ThunkAction<void, AppState, unknown, ActionTypes>;
    resetBatchInvoices: (batch: Batch, invoices: Invoice[]) => any;
    onViewBatchInvoices: (batch: Batch) => ThunkResult<void, ActionTypes>;
    onViewBatchResetInvoices: (batch: Batch) => ThunkResult<void, ActionTypes>;
    regenerateBatchFile: (batch: Batch) => ThunkResult<void, ActionTypes>;
};

export default (): BatchActions => {
    const dispatch = useDispatch();
    const searchParams = useInvoicesSearchParams();
    const transformSearchParams = transformSearchParamsForRequest(searchParams);

    return {
        openSetupBatchModal: () => dispatch(modalActions.openSetupBatchModal(transformSearchParams)),
        openEditBatchModal: (batch) => dispatch(modalActions.openEditBatchModal(batch)),
        openPostBatchModal: (batch) => dispatch(modalActions.openPostBatchModal(batch)),
        resetBatchInvoices: (batch, invoices) => dispatch(modalActions.openResetBatchInvoicesModal(batch, invoices)),
        onViewBatchInvoices: (batch) => dispatch(modalActions.openBatchGeneralInvoicesModal(batch)),
        onViewBatchResetInvoices: (batch) => dispatch(modalActions.openBatchResetInvoicesModal(batch)),
        regenerateBatchFile: (batch) => dispatch(batchActions.regenerateBatchFile(batch)),
    };
};
