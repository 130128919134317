import React from 'react';
import {FormGroup, FormLabel} from 'react-bootstrap';

import styles from './styles.module.scss';

class MinutesInput extends React.PureComponent {
    constructor(props) {
        super(props);

        this.inputNumber = {};
    }

    onChangeHandler = (e) => {
        const {limit = Infinity, step = 1} = this.props;

        // remove leading zeros
        let value = parseInt(e.target.value.replace(/^0+/, ''), 10);

        if (!Number.isNaN(value)) {
            value = value <= limit ? value : limit;
        } else {
            value = step;
        }

        this.props.input.onChange(value);
    };

    increase = () => {
        this.inputNumber.stepUp();
        this.props.input.onChange(parseInt(this.inputNumber.value, 10));
    };

    decrease = () => {
        this.inputNumber.stepDown();
        this.props.input.onChange(parseInt(this.inputNumber.value, 10));
    };

    render() {
        const {
            input,
            input: {value, name},
            step,
            limit,
            label,
        } = this.props;

        return (
            <div className={styles.minutes_selector_component}>
                <FormGroup>
                    {label && <FormLabel>{label}</FormLabel>}
                    <div className={styles.wrapper}>
                        <div className={styles.minutes_selector}>
                            <input
                                ref={(elem) => {
                                    this.inputNumber = elem;
                                }}
                                className="form-control"
                                value={value}
                                onChange={this.onChangeHandler}
                                type="number"
                                max={limit}
                                min={step}
                                step={step}
                                id={name}
                            />
                            <label htmlFor={name} className={styles.unit}>
                                min
                            </label>

                            <button
                                className={`button button-grey ${styles.decrease}`}
                                onClick={this.decrease}
                                type="button"
                            >
                                <i className="fa fa-angle-left" aria-hidden="true" />
                            </button>

                            <button
                                className={`button button-grey ${styles.increase}`}
                                onClick={this.increase}
                                type="button"
                            >
                                <i className="fa fa-angle-right" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </FormGroup>
            </div>
        );
    }
}

export default MinutesInput;
