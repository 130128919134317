import {SettlementCarrier} from 'core/entities/Settlement/types';
import {fetchBatchNotPostedSettlements} from 'core/entities/Settlement/requests/settlementCarrierRequests';

import useFetchWithPaginate from 'hooks/useFetchWithPaginate';

interface FetchData {
    settlements: SettlementCarrier[];
    error: string | undefined;
    currentPage: number | undefined;
    isFetching: boolean;
    isAllFetched: boolean;
    fetchNextPage();
}

const PER_PAGE = 10;

const fetchSettlements = (
    carrierID: number,
    {page, perPage}: {page: string; perPage: number},
): Promise<SettlementCarrier[]> => {
    const nextPage = parseInt(page, 10) + 1;
    return fetchBatchNotPostedSettlements(carrierID, {page: nextPage, perPage})
        .then((response) => response.data)
        .catch((error) => {
            console.error('Error on fetch batch not posted settlements: ', error);
            return [];
        });
};

const cacheCreator = ({carrierID, cachePrefix}: {carrierID: number; cachePrefix: string}) => {
    return (pageIndex: any, previousPageData: any) => {
        if (previousPageData && previousPageData.length === 0) {
            return null;
        }
        const pageNumber = String(pageIndex);
        const cacheKey = `${cachePrefix}-${carrierID}-settlements-page-${pageNumber}`;
        return [cacheKey, pageNumber];
    };
};

const fetcherCreator = ({carrierID}: {carrierID: number}) => {
    return (_, pageNumber) => {
        return fetchSettlements(carrierID, {page: pageNumber, perPage: PER_PAGE});
    };
};

export default ({
    carrierID,
    cachePrefix = 'batch-not-posted-settlements',
}: {
    carrierID: number;
    cachePrefix?: string;
}): FetchData => {
    const getCacheKey = cacheCreator({carrierID, cachePrefix});
    const fetcher = fetcherCreator({carrierID});
    const {data: settlements, error, currentPage, isFetching, isAllFetched, fetchNextPage} = useFetchWithPaginate({
        perPage: PER_PAGE,
        getKey: getCacheKey,
        fetchData: fetcher,
    });
    return {
        settlements,
        error,
        currentPage,
        isFetching,
        isAllFetched,
        fetchNextPage,
    };
};
