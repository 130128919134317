import React from 'react';
import {useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';
import omit from 'lodash/omit';

import {Dispatcher} from 'core/entities/Dispatcher/types';

import {getCurrentDispatcher} from 'store/reducers/userData/selectors';

import Form from './components/Form';

type OwnProps = {
    data: {
        entity: Dispatcher;
    };
    handlers: {
        changeAccountPassword: (fields) => void;
        changeDispatcherPassword: (fields) => void;
    };
    onClose: () => void;
};

const ChangePasswordModal: React.FC<OwnProps> = (props) => {
    const {data, handlers, onClose} = props;
    const signedInDispatcher = useSelector(getCurrentDispatcher);

    const isOwnProfile = String(signedInDispatcher.id) === String(data.entity.id);

    const initialValues = {
        email: data.entity.email,
    };

    const handleSubmit = (formFields) => {
        const fields = omit(formFields, 'email');

        if (isOwnProfile) {
            handlers.changeAccountPassword(fields);
        } else {
            handlers.changeDispatcherPassword(fields);
        }

        onClose();
    };

    return (
        <Modal show={true} onHide={onClose} dialogClassName="custom-modal standard-form-modal" size="sm">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Change dispatcher&apos;s password</Modal.Title>

                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form
                        form="change-dispatcher-password"
                        onSubmit={handleSubmit}
                        onClose={onClose}
                        initialValues={initialValues}
                        isOwnProfile={isOwnProfile}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ChangePasswordModal;
