export default {
    citizen: 'citizen',
    resident: 'resident',
    NL: 'nl',
    other: 'other',
};

export const citizenshipEU = {
    eu_citizen: 'EU citizen',
    foreigner: 'foreigner',
};
