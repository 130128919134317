import React from 'react';
import {connect, batch as batchDispatch} from 'react-redux';
import {reduxForm, formValueSelector, change as reduxFormChange, InjectedFormProps} from 'redux-form';
import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';

import Batch from 'core/entities/Invoice/types/Batch';
import Button from 'components/ui/Buttons/Button';
import {getTypeFieldNameFactory} from 'utils/typeScript/index';

import {Company, User, SetupActions, FormValues} from './types';
import CompaniesList from './components/CompaniesList';
import SelectedCompanyInfo from './components/SelectedUsersInfo';
import styles from './setupBatch.module.scss';

interface OwnProps {
    batch: Batch;
    companies: Company[];
    isEdit: boolean;
    onClose();
}

interface StateProps {
    selectedCompany: Company | undefined;
    selectedUsers: Record<string, User>;
    // eslint-disable-next-line react/no-unused-prop-types
    initialValues: any;
}

interface DispatchProps {
    actions: SetupActions;
}

type AllFormProps = OwnProps & StateProps & DispatchProps;

const FORM_NAME = 'billing-setup-batch-form';
const initialValues: FormValues = {company: undefined, users: {}};
const getName = getTypeFieldNameFactory<FormValues>();

const Form = (props: AllFormProps & InjectedFormProps<{}, AllFormProps>): JSX.Element => {
    const {
        handleSubmit,
        pristine,
        submitting,
        isEdit,
        batch,
        companies,
        selectedUsers,
        selectedCompany,
        onClose,
        actions,
    } = props;
    const isUsersSelected = !isEmpty(selectedUsers);
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className="custom-scroll">
                <div className="modal-body__main">
                    {!isEdit && <p className="mb15">Choose needed company:</p>}

                    <CompaniesList
                        companies={companies}
                        selectedCompany={selectedCompany}
                        selectedUsers={selectedUsers}
                        actions={actions}
                    />
                </div>
            </div>

            <SelectedCompanyInfo
                companyName={selectedCompany?.name}
                users={selectedUsers}
                batchNumber={batch?.number}
                isEdit={isEdit}
            />

            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <Button disabled={pristine || submitting || !isUsersSelected} type="submit" colorTheme="blue">
                        Save
                    </Button>
                </div>
            </div>
        </form>
    );
};

const ReduxForm = reduxForm<{}, AllFormProps>({form: FORM_NAME})(Form);

const formSelector = formValueSelector(FORM_NAME);

export default connect(
    (state): StateProps => {
        return {
            selectedCompany: formSelector(state, getName('company')),
            selectedUsers: formSelector(state, getName('users')),
            initialValues,
        };
    },
    (dispatch): DispatchProps => {
        return {
            actions: {
                onSelectCompany: (company: Company) => {
                    // @ts-ignore
                    return dispatch((_, getState) => {
                        const currentValue = formSelector(getState(), getName('company')) as Company;
                        if (!currentValue || currentValue.entityID !== company.entityID) {
                            batchDispatch(() => {
                                dispatch(reduxFormChange(FORM_NAME, getName('company'), company));
                                dispatch(reduxFormChange(FORM_NAME, getName('users'), {}));
                            });
                        }
                        if (currentValue && currentValue.entityID === company.entityID) {
                            batchDispatch(() => {
                                dispatch(reduxFormChange(FORM_NAME, getName('company'), undefined));
                                dispatch(reduxFormChange(FORM_NAME, getName('users'), {}));
                            });
                        }
                    });
                },

                onSelectUser: (selectedUser) => {
                    // @ts-ignore
                    return dispatch((_, getState) => {
                        const currentUsers = formSelector(getState(), getName('users')) as Record<string, User>;
                        if (selectedUser.id in currentUsers) {
                            dispatch(reduxFormChange(FORM_NAME, getName('users'), omit(currentUsers, selectedUser.id)));
                        } else {
                            const addNewUserAction = reduxFormChange(FORM_NAME, getName('users'), {
                                ...currentUsers,
                                [selectedUser.id]: selectedUser,
                            });
                            dispatch(addNewUserAction);
                        }
                    });
                },

                onSelectAllUsers: (allUsers: User[]) => {
                    // @ts-ignore
                    return dispatch((_, getState) => {
                        const currentUsers = formSelector(getState(), getName('users')) as Record<string, User>;
                        if (Object.keys(currentUsers).length === allUsers.length) {
                            dispatch(reduxFormChange(FORM_NAME, getName('users'), {}));
                        } else {
                            const addNewUserAction = reduxFormChange(
                                FORM_NAME,
                                getName('users'),
                                keyBy(allUsers, 'id'),
                            );
                            dispatch(addNewUserAction);
                        }
                    });
                },
            },
        };
    },
)(ReduxForm);
