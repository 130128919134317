import React from 'react';

import useGetDomainSettings from 'hooks/useGetDomainSettings';

type OwnProps = {
    isSidebarExpanded: boolean;
};

const Logo: React.FC<OwnProps> = ({isSidebarExpanded}) => {
    const {companyFullName, companyShortName} = useGetDomainSettings();

    const logo = isSidebarExpanded ? companyFullName : companyShortName;

    return <h1>{logo}</h1>;
};

export default Logo;
