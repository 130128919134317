import React, {useEffect} from 'react';
import {Modal} from 'react-bootstrap';

import {MergeCustomersFormValues} from 'pages/Customers/types/formTypes';
import useModalActions from 'pages/Customers/hooks/useModalActions';
import useMergeActions from 'pages/Customers/hooks/useMergeActions';

import Form from './components/Form';

const CUSTOMERS_MERGE_FORM_NAME = 'customers-merge-form';

const MergeCustomers: React.FC = () => {
    const {submitMergeCustomers, clearCustomersToMerge} = useMergeActions();
    const {closeModal} = useModalActions();

    useEffect(() => () => clearCustomersToMerge(), []);

    const handleSubmit = (formData: Partial<MergeCustomersFormValues>) => {
        submitMergeCustomers({formData, formName: CUSTOMERS_MERGE_FORM_NAME});
    };

    return (
        <Modal show={true} onHide={closeModal} dialogClassName="standard-form-modal modal-md" backdrop="static">
            <Modal.Header className="modal-header__top">
                <Modal.Title className="font-weight-bold">Merge Customer account into another</Modal.Title>

                <button onClick={closeModal} type="button" className="close">
                    <i className="fa fa-times" aria-hidden="true" />
                </button>
            </Modal.Header>

            <Modal.Body>
                <Form form={CUSTOMERS_MERGE_FORM_NAME} onSubmit={handleSubmit} />
            </Modal.Body>
        </Modal>
    );
};

export default MergeCustomers;
