import {CROSS_SERVER_TRUCK_RESERVATION_CANCELED, CROSS_SERVER_TRUCK_RESERVATION_COMPLETED} from '../actionTypes';

export const completeCrossServerTruckReservation = (payload) => ({
    type: CROSS_SERVER_TRUCK_RESERVATION_COMPLETED,
    payload,
});

export const cancelCrossServerTruckReservation = (payload) => ({
    type: CROSS_SERVER_TRUCK_RESERVATION_CANCELED,
    payload,
});
