import React from 'react';

import {Quote} from 'core/entities/Quote/types';
import {getTruckCitizenships} from 'core/entities/Quote/modules/quoteTruck';

import CitizenshipBadge from 'components/ui/Badges/Citizenship';

interface OwnProps {
    quote: Quote;
}

const QuoteTruckCitizenship: React.FC<OwnProps> = (props) => {
    const {quote} = props;
    const citizenshipValues = getTruckCitizenships(quote);
    return (
        <div className="d-flex  flex-wrap">
            {citizenshipValues.map((citizenship) => (
                <div className="mr-1 mt-1" key={citizenship}>
                    <CitizenshipBadge citizenship={citizenship} />
                </div>
            ))}
        </div>
    );
};

export default QuoteTruckCitizenship;
