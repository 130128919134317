import {DriverOffer} from 'core/entities/Quote/types';
import {createDate, getRemainingTimeTo} from 'utils/dateTime';

export const isDriverOfferApproved = (driverOffer: DriverOffer): boolean => {
    const {status} = driverOffer;
    return status === 'APPROVED';
};

export const isDriverOfferNotApproved = (driverOffer: DriverOffer): boolean => {
    const {status} = driverOffer;
    return status === 'NOT_APPROVED';
};

export const isDriverOfferExpired = (driverOffer: DriverOffer): boolean => {
    const {isExpired, expireAt} = driverOffer;
    if (isExpired) {
        return true;
    }
    const {originalDate, originalTime, timeZone} = createDate(expireAt, {fromISO: true});
    const {minutes} = getRemainingTimeTo(`${originalDate} ${originalTime}`, {timeZone});
    return minutes < 0;
};

export const isDriverOfferDeactivated = (driverOffer: DriverOffer): boolean => {
    const {status} = driverOffer;
    return status === 'DEACTIVATED';
};

export const isDriverOfferArchived = (driverOffer: DriverOffer): boolean => {
    const {status} = driverOffer;
    return status === 'ARCHIVED';
};

export const isDriverOfferRead = (driverOffer: DriverOffer): boolean => {
    return Boolean(driverOffer?.isReadByAllDispatchers);
};
