import React from 'react';

import {Quote} from 'core/entities/Quote/types';

import OriginDestination from './components/OriginDestination';
import PayloadInfo from './components/PayloadInfo';
import TruckInfo from './components/TruckInfo';

import styles from './styles.module.scss';

type OwnProps = {
    quote: Quote;
};

const LoadInfo: React.FC<OwnProps> = (props) => {
    const {quote} = props;
    return (
        <div>
            <div className={styles.originDestinationWrapper}>
                <OriginDestination quote={quote} />
            </div>

            <div className={styles.payloadWrapper}>
                <PayloadInfo quote={quote} />
            </div>

            <div className={styles.truckWrapper}>
                <TruckInfo quote={quote} />
            </div>
        </div>
    );
};

export default LoadInfo;
