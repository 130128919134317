import {push} from 'connected-react-router';

import {getLoadBoardLinks} from 'routing/linkBuilder';

import {setSearchParamsQuoteNumber} from '../actionCreators';

export function goToAuctionQuote(quoteNumber: number) {
    return function (dispatch) {
        const {list: linkToList} = getLoadBoardLinks();
        dispatch(setSearchParamsQuoteNumber(quoteNumber));
        dispatch(push(linkToList));
    };
}
