import {createSelector} from 'reselect';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

import {transformFactoringCompanyDataToRequestBody} from 'pages/FactoringCompanies/redux/mappers/create';
import {FactoringCompanyFormValues} from 'pages/FactoringCompanies/types/formTypes';
import * as actionTypes from 'pages/FactoringCompanies/redux/actionTypes';
import * as selectors from 'pages/FactoringCompanies/redux/selectors';

import {ENTITY_NAME_FACTORING_COMPANIES, ENTITY_NAME_FACTORING_COMPANY} from 'utils/data/entityNames';
import formatFileExtraData from 'utils/files/formatFileExtraData';

import {transferNewNotesToOldInterface} from 'core/entities/FactoringCompany/utils';
import * as requests from 'core/gateways/FactoringCompaniesApiGateway/requests';
import {FactoringCompany} from 'core/entities/FactoringCompany/types';

import * as modalActions from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import * as appModalActions from 'store/actions/modalActions';
import * as appActions from 'store/actions';

import {postFiles, deleteFiles} from 'services/restapi';

import StoredFile from 'types/File';

export const cardActionCreators = {
    factoringCompanyReceived: (payload: FactoringCompany) =>
        ({type: actionTypes.FACTORING_COMPANY_RECEIVED, payload} as const),
    clearFactoringCompanyState: () => ({type: actionTypes.CLEAR_FACTORING_COMPANY_STATE} as const),
    factoringCompanyFilesReceived: (payload: StoredFile[]) =>
        ({type: actionTypes.FACTORING_COMPANY_FILES_RECEIVED, payload} as const),
};

const showSuccessModal = (params: {company: FactoringCompany; action: 'created' | 'updated' | 'restored'}) => (
    dispatch,
) => {
    const {company, action} = params;

    const data = {
        entityName: ENTITY_NAME_FACTORING_COMPANY,
        customEntityName: "factoring company's",
        entityLabel: company.companyName,
        entity: company,
        action,
    };

    dispatch(appModalActions.showEntityActionSuccess(data));
};

export const createFactoringCompany = (params: {company: FactoringCompanyFormValues}) => async (dispatch) => {
    const {company} = params;

    const transformedFactoringCompanyDataToRequestBody = transformFactoringCompanyDataToRequestBody({company});

    try {
        dispatch(appActions.showLoader());

        const requestParams = {requestBody: transformedFactoringCompanyDataToRequestBody};

        const {data: updatesCompany} = await requests.createFactoringCompanyRequest(requestParams);

        dispatch(showSuccessModal({company: updatesCompany, action: 'created'}));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const postFactoringCompanyFiles = (params: {company: FactoringCompanyFormValues}) => async () => {
    const {company} = params;

    const newFiles = Object.entries(company?.newFiles || {});

    if (!newFiles?.length) {
        return Promise.resolve();
    }

    const promises = newFiles.map(([attachType, files]) => {
        if (!files?.length) {
            return null;
        }

        const extraData = files.map((item) => formatFileExtraData(item));
        const paramsForPost = {entity: ENTITY_NAME_FACTORING_COMPANIES, type: attachType, id: company.id, extraData};
        const filesForPost = files.map((fileObject) => fileObject.file);

        return postFiles(filesForPost, paramsForPost);
    });

    try {
        await Promise.all(promises);
    } catch (e) {
        console.log('Error posting files', e);
    }
};

const deleteFactoringCompanyFiles = (params: {company: FactoringCompanyFormValues}) => async () => {
    const {company} = params;

    if (isEmpty(company?.files?.fileIDsToBeRemoved)) {
        return;
    }

    try {
        await deleteFiles(company?.files?.fileIDsToBeRemoved);
    } catch (e) {
        console.log('Error deleting files', e);
    }
};

export const updateFactoringCompany = (params: {company: FactoringCompanyFormValues}) => async (dispatch) => {
    const {company} = params;

    if (!company.id) {
        return;
    }

    const transformedFactoringCompanyDataToRequestBody = transformFactoringCompanyDataToRequestBody({company});

    try {
        dispatch(appActions.showLoader());

        const requestParams = {companyID: company.id, requestBody: transformedFactoringCompanyDataToRequestBody};

        const {data: updatesCompany} = await requests.updateFactoringCompanyRequest(requestParams);

        await dispatch(deleteFactoringCompanyFiles({company}));
        await dispatch(postFactoringCompanyFiles({company}));

        dispatch(showSuccessModal({company: updatesCompany, action: company.is_deleted ? 'restored' : 'updated'}));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const getFactoringCompanyFiles = (params: {companyID?: string}) => async () => {
    const {companyID} = params;

    if (!companyID) {
        return [];
    }

    try {
        const res = await requests.getFactoringCompanyFilesRequest({companyID});

        if ('data' in res && isArray(res.data)) {
            return res.data;
        }

        return [];
    } catch (error) {
        console.log('Error fetching files', error);

        return [];
    }
};

export const getFactoringCompany = (params: {factoringCompanyID?: string}) => async (dispatch) => {
    const {factoringCompanyID} = params;

    if (!factoringCompanyID) {
        return;
    }

    try {
        dispatch(appActions.showLoader());

        const {data} = await requests.getFactoringCompanyRequest({factoringCompanyID});

        const files = await dispatch(getFactoringCompanyFiles({companyID: factoringCompanyID}));

        dispatch(cardActionCreators.factoringCompanyReceived(data));
        dispatch(cardActionCreators.factoringCompanyFilesReceived(files));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const deleteNote = (params: {noteID: number; company: FactoringCompany}) => async (dispatch) => {
    const {noteID, company} = params;

    if (!noteID || !company) {
        return;
    }

    try {
        dispatch(appActions.showLoader());

        const {data} = await requests.deleteNoteFactoringCompanyRequest({companyID: company.id, noteID});

        dispatch(cardActionCreators.factoringCompanyReceived(data));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const addNote = (params: {note: {text: string}; company: FactoringCompany}) => async (dispatch) => {
    const {note, company} = params;

    if (!note?.text) {
        return;
    }

    try {
        dispatch(appActions.showLoader());

        const requestParams = {companyID: company.id, requestBody: {note: note.text}};

        const {data} = await requests.addNoteFactoringCompanyRequest(requestParams);

        dispatch(cardActionCreators.factoringCompanyReceived(data));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const showGeneralNotesModal = (params: {company: FactoringCompany; addNoteDisabled: boolean}) => (dispatch) => {
    const {company, addNoteDisabled} = params;

    const notesSelector = createSelector(selectors.getCurrenFactoringCompany, (currentCompany) =>
        transferNewNotesToOldInterface({company: currentCompany}).reverse(),
    );

    const modalData = {entity: company, title: `Factoring Company's ${company.companyName} Notes`, addNoteDisabled};
    const modalHandlers = {onSubmit: (note) => dispatch(addNote({note, company}))};
    const modalName = commonModalNames.generalNotes;
    const modalSelectors = {notesSelector};

    dispatch(modalActions.openModal({modalName, data: modalData, handlers: modalHandlers, selectors: modalSelectors}));
};
