import {useEffect, useState} from 'react';

import {getRemainingTimeTo} from 'utils/dateTime';

import {getQuoteDate} from 'pages/LoadBoard/utils';

const getRemainingTimeToExpire = (expireAt): ReturnType<typeof getRemainingTimeTo>['duration'] => {
    const quoteDate = getQuoteDate(expireAt);
    if (!quoteDate) {
        return {days: 0, hours: 0, minutes: 0, seconds: 0};
    }
    const {originalDate, originalTime, timeZone} = quoteDate;
    const {duration, isExpired} = getRemainingTimeTo(`${originalDate} ${originalTime}`, {timeZone});
    if (isExpired) {
        return {days: 0, hours: 0, minutes: 0, seconds: 0};
    }
    return duration;
};

const checkIsTimeExpired = (time: {hours: number; minutes: number; seconds: number | string}): boolean => {
    const {hours, minutes, seconds} = time;
    return hours === 0 && minutes === 0 && Number(seconds) === 0;
};

type DurationTime = {
    time: {hours: number; minutes: number; seconds: number | string};
    isExpired: boolean;
};

const useDurationTime = (expireAt: string, onExpire?: () => void): DurationTime => {
    const timeToExpire = getRemainingTimeToExpire(expireAt);
    const [time, setTime] = useState<DurationTime>({
        time: timeToExpire,
        isExpired: checkIsTimeExpired(timeToExpire),
    });

    useEffect(() => {
        const SECOND = 1000;
        const intervalID = setInterval(() => {
            const nextRemainingTime = getRemainingTimeToExpire(expireAt);
            const isTimeExpired = checkIsTimeExpired(nextRemainingTime);
            if (isTimeExpired) {
                setTime({time: {hours: 0, minutes: 0, seconds: 0}, isExpired: true});
                clearInterval(intervalID);
                if (onExpire) {
                    onExpire();
                }
                return;
            }
            setTime({time: nextRemainingTime, isExpired: false});
        }, SECOND);

        return () => {
            clearInterval(intervalID);
        };
    }, [expireAt]);
    return time;
};

export default useDurationTime;
