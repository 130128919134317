import {AppState} from 'store';
import {getPagePermissions} from 'store/reducers/userPermissions/selectors';

import * as pages from 'utils/data/pages';

export const getCurrentDriver = (state: AppState) => state.drivers.current.current;
export const getInitialDataForCreateDriver = (state: AppState) => state.drivers.current.initialData;
export const getListDrivers = (state: AppState) => state.drivers.list.list;
export const getFormActiveTab = (state: AppState) => state.drivers.current.activeTab;
export const getListSearchParams = (state: AppState) => state.drivers.list.searchParams;
export const getListPagination = (state: AppState) => state.drivers.list.pagination;
export const getListTab = (state: AppState) => state.drivers.list.activeListTab;
export const getDriversPermissions = (state: AppState) => getPagePermissions(state, pages.DRIVERS);
