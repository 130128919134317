import {Dispatcher} from 'core/entities/Dispatcher/types';
import {BOOKING_DISPATCHER} from 'utils/data/roles';

function checkHasBookingDispatcherRole(dispatcher: Dispatcher): boolean {
    const {user} = dispatcher;
    if (!user || !user.roles) {
        return false;
    }

    return user.roles.includes(BOOKING_DISPATCHER);
}

export default checkHasBookingDispatcherRole;
