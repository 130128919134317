import Invoice from 'core/entities/Invoice/types';
import Batch from 'core/entities/Invoice/types/Batch';

export const LIST_INVOICES_RECEIVED = 'INVOICES_COMPONENT_LIST_INVOICES_RECEIVED';
export const LIST_BATCHES_RECEIVED = 'INVOICES_COMPONENT_LIST_BATCHES_RECEIVED';
export const LIST_PAGINATION_CURRENT_PAGE_CHANGED = 'INVOICES_COMPONENT_LIST_PAGINATION_CURRENT_PAGE_CHANGED';
export const LIST_TYPE_CHANGED = 'INVOICES_COMPONENT_LIST_TYPE_CHANGED';
export const LIST_SORTING_CHANGED = 'INVOICES_COMPONENT_LIST_SORTING_CHANGED';
export const LIST_PAGINATION_CHANGED = 'INVOICES_COMPONENT_LIST_PAGINATION_CHANGED';
export const LIST_SEARCH_PARAMS_CHANGED = 'INVOICES_COMPONENT_LIST_SEARCH_PARAMS_CHANGED';

export const INVOICE_DATA_RECEIVED = 'INVOICES_COMPONENT_DATA_INVOICE_RECEIVED';
export const INVOICE_STATUS_CHANGED = 'INVOICES_COMPONENT_INVOICE_STATUS_CHANGED';
export const INVOICE_FILES_UPDATED = 'INVOICES_COMPONENT_INVOICE_FILES_UPDATED';
export const INVOICE_LOAD_TRAVEL_ORDER_DATA_UPDATED = 'INVOICES_COMPONENT_INVOICE_LOAD_TRAVEL_ORDER_DATA_UPDATED';
export const INVOICE_SET_APPROVE_SETTLEMENT_STATUS = 'INVOICE_SET_APPROVE_SETTLEMENT_STATUS';
export const INVOICE_TOGGLE_INCLUDE_LOAD_STOP_TO_PDF = 'INVOICE_TOGGLE_INCLUDE_LOAD_STOP_TO_PDF';
export const INVOICE_INSERT_VIRTUAL_STOP = 'INVOICE_INSERT_VIRTUAL_STOP';
export const INVOICE_REMOVE_VIRTUAL_STOP = 'INVOICE_REMOVE_VIRTUAL_STOP';

export const INVOICE_LOAD_CUSTOMER_UPDATED = 'INVOICES_COMPONENT_INVOICE_LOAD_CUSTOMER_UPDATED';
export const INVOICE_LOAD_REF_NUMBER_UPDATED = 'INVOICES_COMPONENT_INVOICE_LOAD_REF_NUMBER_UPDATED';
export const INVOICE_DUE_DATE_UPDATED = 'INVOICES_COMPONENT_INVOICE_DUE_DATE_UPDATED';
export const INVOICE_LOAD_UPDATED = 'INVOICES_COMPONENT_INVOICE_LOAD_UPDATED';
export const INVOICE_TRAVEL_ORDER_UPDATED = 'INVOICES_COMPONENT_INVOICE_TRAVEL_ORDER_UPDATED';
export const INVOICE_LOAD_AND_TRAVEL_ORDER_UPDATED = 'INVOICES_COMPONENT_INVOICE_LOAD_AND_TRAVEL_ORDER_UPDATED';
export const INVOICE_LOAD_AND_TRAVEL_ORDER_UPDATED_FILES =
    'INVOICES_COMPONENT_INVOICE_LOAD_AND_TRAVEL_ORDER_UPDATED_FILES';

export const INVOICE_LOAD_TOTAL_RATE_UPDATED = 'INVOICES_COMPONENT_INVOICE_LOAD_TOTAL_RATE_UPDATED';

export const BATCH_CREATED = 'INVOICES_COMPONENT_BATCH_CREATED';
export const BATCH_UPDATED = 'INVOICES_COMPONENT_BATCH_UPDATED';
export const BATCH_POSTED = 'INVOICES_COMPONENT_BATCH_POSTED';
export const BATCH_INVOICES_RESET = 'INVOICES_COMPONENT_BATCH_INVOICES_RESET';

type ResetBatchInvoices = {
    type: typeof BATCH_INVOICES_RESET;
    payload: {batch: Batch; invoices: Invoice[]};
};

export type ActionTypes = ResetBatchInvoices;
