import {useSelector} from 'react-redux';
import {getFormSyncErrors} from 'redux-form';

const useGetReduxFormError = (formName: string, fieldName: string) => {
    const selector = getFormSyncErrors(formName);
    const formErrors = useSelector((state) => selector(state));
    return formErrors[fieldName];
};

export default useGetReduxFormError;
