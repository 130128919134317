import React from 'react';
import {connect} from 'react-redux';
import {change, formValueSelector, submit} from 'redux-form';

import Button from 'components/ui/Buttons/Button';
import ButtonLink from 'components/ui/Buttons/ButtonLink';

const getIsSubmitDisabled = ({withRestore, isEntityDeleted, pristine, submitting, asyncValidating}) => {
    const isFormDisabled = pristine || submitting || asyncValidating;
    if (!withRestore) {
        return isFormDisabled;
    }
    if (isEntityDeleted) {
        return false;
    }
    return isFormDisabled;
};

const getSubmitTitle = ({originalSubmitTitle, withRestore, isEntityDeleted}) => {
    if (!withRestore) {
        return originalSubmitTitle;
    }
    if (isEntityDeleted) {
        return 'SAVE AND RESTORE';
    }
    return originalSubmitTitle;
};

const FormActionButtons = (props) => {
    const {
        showPrevStepBtn = false,
        handlePrevStep = () => {},
        showClearBtn = true,
        submitTitle,
        pristine,
        submitting,
        reset,
        history,
        onCancel,
        className = '',
        prevPathname,
        asyncValidating,
        entityUrl,
        currentEntityId,
        withRestore = false,
        isEntityDeleted,
        runSubmit,
        disabled,
    } = props;

    const isFormUpdate = Boolean(currentEntityId);
    const isSubmitDisabled = getIsSubmitDisabled({withRestore, isEntityDeleted, submitting, pristine, asyncValidating});
    const buttonSubmitTitle = getSubmitTitle({
        originalSubmitTitle: submitTitle,
        withRestore,
        isEntityDeleted,
    });

    const renderCancelButton = () => {
        if (entityUrl) {
            return (
                <ButtonLink href={entityUrl} colorTheme="grey">
                    Cancel
                </ButtonLink>
            );
        }

        return (
            <Button
                // eslint-disable-next-line
                onClick={() => {
                    if (onCancel) {
                        onCancel();
                    } else if (history) {
                        history.push(prevPathname);
                    }
                }}
                colorTheme="grey"
            >
                Cancel
            </Button>
        );
    };

    return (
        <div className={className || 'form-action-btns'}>
            <div>
                {renderCancelButton()}

                {showClearBtn && (
                    <Button
                        style={{marginLeft: 10}}
                        disabled={pristine || submitting}
                        onClick={reset}
                        buttonSize="medium"
                        colorTheme="grey"
                    >
                        {props.isUpdate || isFormUpdate ? 'Reset' : 'Clear all fields'}
                    </Button>
                )}
            </div>

            <div>
                {showPrevStepBtn && (
                    <Button
                        // eslint-disable-next-line
                        onClick={handlePrevStep}
                        buttonSize="large"
                        colorTheme="grey"
                    >
                        Previous step
                    </Button>
                )}
                <Button
                    onClick={runSubmit}
                    type="button"
                    style={{marginLeft: 10}}
                    disabled={isSubmitDisabled || disabled}
                    buttonSize="large"
                    colorTheme="green"
                >
                    {buttonSubmitTitle}
                </Button>
            </div>
        </div>
    );
};

// for unit testing
export {FormActionButtons};

const selector = (form, ...other) => formValueSelector(form)(...other);
export default connect(
    (state, props) => ({
        currentEntityId: selector(props.form, state, 'id'),
        isEntityDeleted: selector(props.form, state, 'is_deleted'),
        prevPathname: state.router.location.prevPathname,
    }),
    (dispatch, ownProps) => ({
        runSubmit: () => {
            // we using this magic with add hidden field for avoid issue with redux-form when we cant call submit without change fields
            // cause we using update forms for restore entities we should use submit for restore but we cant do it cause form dont have changes!
            dispatch(change(ownProps.form, '__changed', true));
            window.setTimeout(() => {
                dispatch(submit(ownProps.form));
            });
        },
    }),
)(FormActionButtons);
