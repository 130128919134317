import type {CelebrationActionsTypes} from './actionCreators';
import * as types from './actionTypes';

export type CelebrationState = {
    isCelebration: boolean;
    isAnimate: boolean;
};

const defaultState = {
    isCelebration: false,
    isAnimate: false,
};

const celebration = (state: CelebrationState = defaultState, action: CelebrationActionsTypes) => {
    switch (action.type) {
        case types.CELEBRATION_CHANGE_SNOW:
            return {
                ...state,
                isCelebration: action.payload,
            };

        case types.CELEBRATION_CALL_ANIMATION:
            return {
                ...state,
                isAnimate: action.payload,
            };

        case types.CELEBRATION_END_ANIMATION:
            return {
                ...state,
                isAnimate: action.payload.isAnimate,
            };

        // no default
    }

    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
};

export default celebration;
