import {useDispatch, useSelector} from 'react-redux';

import * as modalActions from 'pages/Customers/redux/actions/modals';

import {getCurrentModalID} from 'components/ui/ModalProvider/selectors';
import {closeModal} from 'components/ui/ModalProvider/actions';

type UseModalActions = {
    showMergeCustomersModal: () => void;
    closeModal: () => void;
};

const useModalActions = (): UseModalActions => {
    const currentModalID = useSelector(getCurrentModalID);
    const dispatch = useDispatch();

    return {
        showMergeCustomersModal: () => dispatch(modalActions.showMergeCustomersModal()),
        closeModal: () => dispatch(closeModal(currentModalID)),
    };
};

export default useModalActions;
