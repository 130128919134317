const openNewWindow = ({newWindowUrl, newWindowTitle = ''}) => {
    const win = window.open(newWindowUrl, '_blank');

    if (win) {
        win.document.title = newWindowTitle;
        win.focus();
    }
};

export default openNewWindow;
