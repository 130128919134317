import WEIGHT_UNITS from 'utils/data/weightUnits';

export default function (freights) {
    if (freights.every((freight) => freight.unit === WEIGHT_UNITS.KG)) {
        return WEIGHT_UNITS.KG;
    }
    if (freights.every((freight) => freight.unit === WEIGHT_UNITS.LBS)) {
        return WEIGHT_UNITS.LBS;
    }

    if (freights.every((freight) => freight.unit === WEIGHT_UNITS.TON)) {
        return WEIGHT_UNITS.TON;
    }
    return undefined;
}
