export const activeItems = [
    {label: 'Active', value: true},
    {label: 'Inactive', value: false},
];

export const activeItemsWithOnHold = [
    {label: 'Active', value: true},
    {label: 'On Hold', value: false},
];

export const genderItems = [
    {label: 'Male', value: 'Male'},
    {label: 'Female', value: 'Female'},
];

export const billingMethod = [
    {label: 'Factored', value: 'factored'},
    {label: 'Direct', value: 'direct'},
];

export const paymentTerms = [
    {label: 'Due on receipt', value: 'due on receipt'},
    {label: 'Net 10 Days', value: 'net 10 days'},
    {label: 'Net 15 Days', value: 'net 15 days'},
    {label: 'Net 30 Days', value: 'net 30 days'},
    {label: 'Net 45 Days', value: 'net 45 days'},
    {label: 'Net 60 Days', value: 'net 60 days'},
];

export const preferredCarrierItems = [
    {label: 'Preferred carrier', value: 'Preferred carrier'},
    {label: 'Outside carrier', value: 'Outside carrier'},
    {label: 'Blacklist', value: 'Blacklist'},
];

export const driverTypeItems = [
    {label: 'Owner', value: true},
    {label: 'Driver', value: false},
];

export const canadaItems = [
    {label: 'Allowed', value: true},
    {label: 'Not Allowed', value: false},
];

export const statusItems = [
    {label: 'Waiting for Approval', value: 'waiting for approval', disabled: false},
    {label: 'Active', value: 'active', disabled: false},
    {label: 'Retired', value: 'retired', disabled: false},
];

export const licenseType = [
    {label: 'Standard', value: 'Standard'},
    {label: 'Enhanced', value: 'Enhanced'},
    {label: 'Commercial', value: 'Commercial'},
];

export const paymentMethods = {
    bankTransfer: {label: 'Bank Transfer', value: 'Bank Transfer'},
    check: {label: 'Check', value: 'Check'},
} as const;

export const billingMethods = {
    direct: {label: 'Direct', value: 'direct'},
    factored: {label: 'Factored', value: 'factored'},
} as const;

export const quickPayMethods = {
    commission: {label: 'Commission', value: 'commission'},
    interestRate: {label: 'Interest Rate, %', value: 'interest_rate'},
} as const;
