import React from 'react';

import CheckBoxInput from 'components/ui/Form/CheckBoxInput';

import styles from './styles.module.scss';

interface OwnProps {
    onChange(): void;
    isChecked: boolean;
    label: string;
}

const BlueCheckbox: React.FC<OwnProps> = (props) => {
    const {onChange, isChecked, label} = props;
    return (
        <CheckBoxInput
            label={label}
            input={{
                value: isChecked,
                onChange,
            }}
            position="right"
            className={styles.checkbox}
        />
    );
};

export default BlueCheckbox;
