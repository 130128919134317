import {AppState} from 'store';

import {ListState} from '../reducers/list';

export const getSearchParams = (state: AppState): ListState['searchParams'] => state.fullAlliance.list.searchParams;
export const getExpandedIDs = (state: AppState): ListState['expandedIDs'] => state.fullAlliance.list.expandedIDs;
export const getPagination = (state: AppState): ListState['pagination'] => state.fullAlliance.list.pagination;
export const getSorting = (state: AppState): ListState['sorting'] => state.fullAlliance.list.sorting;
export const getTrucks = (state: AppState): ListState['items'] => state.fullAlliance.list.items;
export const getDestinationPoint = (state: AppState): ListState['locationPoints']['destination'] =>
    state.fullAlliance.list.locationPoints?.destination;
export const getOriginPoint = (state: AppState): ListState['locationPoints']['origin'] =>
    state.fullAlliance.list.locationPoints?.origin;
