import React from 'react';

import Invoice from 'core/entities/Invoice/types';
import Tooltip from 'components/ui/Tooltip';

import useInvoiceActions from 'pages/Invoices/hooks/useInvoiceActions';
import style from './style.module.scss';

const NoPodBadge: React.FC<{invoice: Invoice}> = (props): JSX.Element | null => {
    const {invoice} = props;
    const {openChatWithNoPodDriverWarning} = useInvoiceActions();
    const handleClick = () => openChatWithNoPodDriverWarning(invoice);
    if (!invoice.has_files_on_last_delivery) {
        return (
            <>
                <Tooltip className="d-inline-block btn-tooltip" tooltipContent={<span>Click to open chat</span>}>
                    <div className={`mt-1 ${style.no_pod_badge}`} onClick={handleClick}>
                        no PoD
                    </div>
                </Tooltip>
            </>
        );
    }
    return null;
};

export default NoPodBadge;
