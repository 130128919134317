import {getTravelOrderStopByID} from 'core/entities/TravelOrder/modules/travelOrderStops/getTravelOrderStops';
import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';

export const isVehicleOnRoute = (travelOrderStop?: TravelOrderStop): boolean =>
    Boolean(travelOrderStop?.isVehicleOnRoute);

export const getTravelOrderStopIsVehicleOnRouteByID = (params: StopInfoParams): boolean => {
    const travelOrderStop = getTravelOrderStopByID(params);

    return isVehicleOnRoute(travelOrderStop);
};
