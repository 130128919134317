import {getOwnerCoordinatorName} from 'core/entities/Owner/modules/ownerCoordinatorName';
import {getDriverName} from 'core/entities/Driver/modules/driverName';
import {getOwnerName} from 'core/entities/Owner/modules/ownerName';
import Truck from 'core/entities/Truck/types';

import {getTruckCoordinatorIsResponsible} from '../truckOwnerCoordinatorResponsible';
import {getTruckOwnerIsResponsible} from '../truckOwnerResponsible';
import {getOwnerTruckDriver} from '../truckDrivers';
import {getTruckTeam} from '../truckTeam';

export const getMainPersonPhone = (truck?: Truck | null): string => {
    const truckTeam = getTruckTeam(truck);

    if (truckTeam.isCoordinatorResponsible) {
        return truckTeam?.ownerCoordinator?.coordinator_mobile_phone || '';
    }

    if (truckTeam.isOwnerResponsible) {
        return truckTeam?.owner?.mobile_phone || '';
    }

    return truckTeam?.firstDriver?.mobile_phone || '';
};

export const getTruckMainPerson = (truck?: Truck | null) => {
    const truckTeam = getTruckTeam(truck);

    if (truckTeam.isCoordinatorResponsible) {
        return truckTeam?.ownerCoordinator;
    }

    if (truckTeam.isOwnerResponsible) {
        return truckTeam?.owner;
    }

    return truckTeam?.firstDriver;
};

export const getTruckMainPersonName = (truck?: Truck | null) => {
    const truckTeam = getTruckTeam(truck);

    if (truckTeam.isCoordinatorResponsible) {
        return getOwnerCoordinatorName(truckTeam?.ownerCoordinator);
    }

    if (truckTeam.isOwnerResponsible) {
        return getOwnerName(truckTeam?.owner);
    }

    return getDriverName(truckTeam?.firstDriver);
};

export const getTruckMainPersonCitizenship = (truck?: Truck | null) => {
    const truckTeam = getTruckTeam(truck);

    if (truckTeam.isCoordinatorResponsible) {
        return truckTeam.ownerCoordinator?.citizenship;
    }

    if (truckTeam.isOwnerResponsible) {
        return truckTeam.owner?.citizenship;
    }

    return truckTeam.firstDriver?.citizenship;
};

export const getTruckMainPersonPrefix = (truck?: Truck | null): string => {
    const isCoordinatorResponsible = getTruckCoordinatorIsResponsible(truck);
    const isOwnerResponsible = getTruckOwnerIsResponsible(truck);
    const ownerDriver = getOwnerTruckDriver(truck);

    if (isCoordinatorResponsible) {
        return '(crd)';
    }

    if (isOwnerResponsible && ownerDriver) {
        return '(o/d)';
    }

    if (isOwnerResponsible && !ownerDriver) {
        return '(ow)';
    }

    return '(1dr)';
};
