import {createSelector} from 'reselect';
import compact from 'lodash/compact';

import SelectOption from 'types/SelectOption';

import {getAllDispatchers} from 'store/reducers/commonData/selectors';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';
import checkHasBookingDispatcherRole from 'core/entities/Dispatcher/checkHasBookingDispatcherRole';
import {Dispatcher} from 'core/entities/Dispatcher/types';
import {checkHasRole} from 'core/entities/Dispatcher/roles';

import {UserRole} from 'utils/data/roles';

interface DispatcherOption extends SelectOption {
    isDisabled?: boolean;
}

interface GroupedDispatcherOptions {
    label: string;
    options: DispatcherOption[];
}

function getDispatcherOption(dispatcher: Dispatcher, valueAsObject?: boolean): DispatcherOption {
    return {
        value: valueAsObject ? dispatcher : dispatcher.id,
        label: getDispatcherName(dispatcher),
        isDisabled: dispatcher.is_hidden,
    };
}

const checkHiddenDispatcher = (dispatchers: DispatcherOption[], skipHidden?: boolean): DispatcherOption[] =>
    skipHidden ? dispatchers?.filter((dispatcher) => !dispatcher.isDisabled) : dispatchers;

function getDispatcherOptionList(
    dispatchers?: Dispatcher[],
    params?: {valueAsObject?: boolean; skipHidden?: boolean; includeRoles?: UserRole[]},
): DispatcherOption[] {
    if (!dispatchers) {
        return [];
    }

    const dispatcherOptions = compact(
        dispatchers.map((dispatcher) => {
            if (params?.includeRoles) {
                const isCheckRole = params.includeRoles.find((role) => checkHasRole(dispatcher, role));
                return isCheckRole ? getDispatcherOption(dispatcher, params?.valueAsObject) : null;
            }

            return getDispatcherOption(dispatcher, params?.valueAsObject);
        }),
    );

    return checkHiddenDispatcher(dispatcherOptions, params?.skipHidden);
}

export function groupByRole(
    dispatchers?: Dispatcher[],
    params?: {valueAsObject?: boolean; skipHidden?: boolean},
): GroupedDispatcherOptions[] {
    if (!dispatchers) {
        return [];
    }

    const dispatcherOptions = dispatchers.reduce(
        function (result: GroupedDispatcherOptions[], dispatcher: Dispatcher) {
            const hasRoleBookingDispatcher = checkHasBookingDispatcherRole(dispatcher);
            if (hasRoleBookingDispatcher) {
                const bookingDispatchersOptionGroup = result[0];
                bookingDispatchersOptionGroup.options.push(getDispatcherOption(dispatcher, params?.valueAsObject));

                return result;
            }
            const otherDispatchersOptionGroup = result[1];
            otherDispatchersOptionGroup.options.push(getDispatcherOption(dispatcher, params?.valueAsObject));

            return result;
        },
        [
            {label: 'Booking Dispatchers', options: []},
            {label: 'Other Dispatchers', options: []},
        ],
    );

    return dispatcherOptions.map((item) => ({
        ...item,
        options: checkHiddenDispatcher(item.options, params?.skipHidden),
    }));
}

export const getDispatchersList = createSelector([getAllDispatchers, (_, params) => params], getDispatcherOptionList);

export const getDispatchersGroupedByRole = createSelector([getAllDispatchers, (_, params) => params], groupByRole);
