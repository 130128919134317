import React from 'react';
import {Modal} from 'react-bootstrap';
import {Quote} from 'core/entities/Quote/types';
import {QuoteInitialValues} from 'pages/LoadBoard/types';
import Button from 'components/ui/Buttons/Button';

import styles from './styles.module.scss';
import QuoteList from './components/QuoteList';
import QuoteListInfo from './components/QuoteListInfo';

type OwnProps = {
    data: {
        quotes: Quote[];
        formValues: QuoteInitialValues;
    };
    handlers: {
        submit(): void;
        cancel(): void;
    };
};

const QuoteDuplicatesModal: React.FC<OwnProps> = (props) => {
    const {handlers, data} = props;
    return (
        <Modal
            show={true}
            dialogClassName={styles.dialog}
            onHide={handlers.cancel}
            className="info-modal"
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>
                    <strong>Duplicates found</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.body}>
                <QuoteListInfo quotes={data.quotes} formValues={data.formValues} />
                <QuoteList quotes={data.quotes} />
            </Modal.Body>
            <Modal.Footer className="justify-content-end">
                <Button
                    onClick={handlers.cancel}
                    className="mr10"
                    colorTheme="grey"
                    buttonSize="small"
                    defaultFocus={true}
                >
                    CANCEL
                </Button>
                <Button
                    onClick={handlers.submit}
                    className="mr10"
                    colorTheme="green"
                    buttonSize="small"
                    defaultFocus={true}
                >
                    CREATE ANYWAY
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default QuoteDuplicatesModal;
