import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface OwnProps {
    checked: boolean;
    onSelect();
}

const Checkbox = (props: OwnProps): JSX.Element => {
    const {checked, onSelect} = props;
    const fullClassName = classNames(styles.checkbox, checked && styles.checkbox__checked);
    return <div onClick={onSelect} className={fullClassName} />;
};

export default Checkbox;
