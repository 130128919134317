import {Dispatch} from 'redux';
import {change} from 'redux-form';

import {getTimeZoneByCoordinates} from 'services/geocodingApi';

import * as appModalActions from 'store/actions/modalActions';
import {AppThunk} from 'store';

import {PlaceAddress} from 'types/Address';

import Truck from 'core/entities/Truck/types';

import {openModal} from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import {ENTITY_NAME_TRUCK} from 'utils/data/entityNames';
import {getTypeFieldNameFactory} from 'utils/typeScript';

import {goToTruckList, goToTruckView, postGeneralNote, updateTruckDispatcherNote} from './common';
import {selectCurrentTruck, selectCurrentTruckNotes} from '../selectors';
import {UpdateStatusModalFormValues} from '../types/formTypes';
import {modalNames} from '../components/modals/modalMap';
import {updateStatus} from './list';

const getName = getTypeFieldNameFactory<UpdateStatusModalFormValues>();

export function showTruckSuccessfulCreateModal(truck: Truck): AppThunk {
    return function (dispatch) {
        const title = 'Successful Create';
        const bodyType = 'SuccessFormCreate';
        const toProfileLabel = `#${truck.number}`;

        const action = openModal({
            modalName: commonModalNames.informationModal,
            data: {
                title,
                bodyType,
                toProfileLabel,
                entity: truck,
                entityName: ENTITY_NAME_TRUCK,
                buttonType: 'success',
            },
            handlers: {
                leftButtonHandler: () => dispatch(goToTruckView(truck)),
                rightButtonHandler: () => dispatch(goToTruckList()),
            },
        });
        dispatch(action);
    };
}

export function showTruckSuccessfulUpdateModal(truck: Truck): AppThunk {
    return function (dispatch) {
        const title = 'Successful Update';
        const bodyType = 'SuccessFormUpdate';
        const toProfileLabel = `#${truck.number}`;

        const action = openModal({
            modalName: commonModalNames.informationModal,
            data: {
                title,
                bodyType,
                toProfileLabel,
                entity: truck,
                entityName: ENTITY_NAME_TRUCK,
                buttonType: 'success',
            },
            handlers: {
                leftButtonHandler: () => dispatch(goToTruckView(truck)),
                rightButtonHandler: () => dispatch(goToTruckList()),
            },
        });
        dispatch(action);
    };
}

/*
*
*  const {facility, action} = params;
        const successAction = appModalActions.showEntityActionSuccess({
            entity: facility as any,
            entityName: ENTITY_NAME_FACILITY,
            entityLabel: facility.name,
            action,
        });

        dispatch(successAction);
*
*
* */

export const showSuccessModal = (params: {truck: Truck; action: 'created' | 'updated' | 'restored'}) => {
    return function (dispatch) {
        const {truck, action} = params;
        const label = `#${truck.number}`;
        const successAction = appModalActions.showEntityActionSuccess({
            entity: truck,
            entityName: ENTITY_NAME_TRUCK,
            entityLabel: label,
            action,
        });

        dispatch(successAction);
    };
};

export function showArchiveModal(truck: Truck, onConfirm: () => void): AppThunk {
    return function (dispatch: Dispatch) {
        const {number, is_deleted} = truck;
        const title = is_deleted ? 'Restore' : 'Archive';
        const bodyType = is_deleted ? 'RestoreForm' : 'ArchiveForm';

        dispatch(
            openModal({
                modalName: commonModalNames.informationModal,
                data: {
                    title: `${title} Truck`,
                    toProfileLabel: `#${number}`,
                    entityName: ENTITY_NAME_TRUCK,
                    leftButtonTitle: 'KEEP',
                    rightButtonTitle: title,
                    buttonType: 'danger',
                    bodyType,
                },
                handlers: {
                    leftButtonHandler: () => {},
                    rightButtonHandler: onConfirm,
                },
            }),
        );
    };
}

export function showGeneralNotesModal(permissionUpdateTruck: boolean): AppThunk {
    return function (dispatch, getState) {
        const state = getState();
        const currentTruck = selectCurrentTruck(state) as Truck;
        const title = `Truck#${currentTruck.number} Notes`;
        const onSubmit = (note: {text: string}): void => dispatch(postGeneralNote(note.text, currentTruck.id));

        dispatch(
            openModal({
                modalName: commonModalNames.generalNotes,
                data: {entity: currentTruck, title, addNoteDisabled: !permissionUpdateTruck},
                handlers: {onSubmit},
                selectors: {
                    notesSelector: selectCurrentTruckNotes,
                },
            }),
        );
    };
}

export const showStatusUpdateModal = (truck: Truck): AppThunk => (dispatch) => {
    dispatch(
        openModal({
            modalName: modalNames.updateStatusModal,
            data: {truck},
            handlers: {onSubmit: (formData) => dispatch(updateStatus({truck, formData}))},
        }),
    );
};

export function editTruckDispatcherNote(truck: Truck) {
    return function (dispatch) {
        const onSubmitHandler = (truckID: number, text: string): void => {
            dispatch(updateTruckDispatcherNote(truck, text));
        };
        dispatch(appModalActions.showTruckDispatcherNote(truck, onSubmitHandler));
    };
}

export const changeTimeZoneByCoordinates = (params: {formName: string; coordinates: PlaceAddress['coords']}) => async (
    dispatch,
) => {
    const {formName, coordinates} = params;

    const {timeZone} = await getTimeZoneByCoordinates({
        lat: coordinates.lat,
        lng: coordinates.lng,
    });

    dispatch(change(formName, `${getName('truckDateTime')}.timeZone`, timeZone));
};
