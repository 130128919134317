import React, {useState, useCallback, useMemo, ReactNodeArray, ReactNode} from 'react';
import {connect} from 'react-redux';
import {WrappedFieldProps} from 'redux-form';
import {FormControl} from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import {v4 as uuidv4} from 'uuid';

import SelectOption from 'types/SelectOption';
import StoredFile from 'types/File';
import {UploadedFile, FormActionCreators} from 'components/ui/Files/FileUpload/types';
import Select from 'components/ui/Form/Select';
import FilePreview from 'components/ui/Files/FileUpload/components/FilePreview';
import ButtonFileInput from 'components/ui/Files/FileUpload/components/ButtonFileInput';
import withFormActionCreators from 'components/ui/Files/FileUpload/hocs/withFormActionCreators';
import {normalizeFile} from 'components/ui/Files/FileUpload/utils';
import {mapExistingFilesFromFormState} from 'components/ui/Files/FileUpload/utils/mapExistingFilesFromFormState';
import styles from 'components/ui/Files/FileUpload/components/BadgeFileInput/badgeFile.module.scss';

interface FileWithBadge extends UploadedFile {
    badge?: string;
}

interface OwnProps {
    existingFiles?: StoredFile[];
    fileIDsToBeRemoved?: number[];
    maxFileCount?: number;
    badgeText?: string;
    badgeInput?: 'textinput' | 'dropdown';
    dropdownData?: SelectOption[];
    dropdownCreatable?: boolean;
    warnOnRemove?: boolean;
}

const BadgeFileInput = (props: OwnProps & FormActionCreators & WrappedFieldProps): JSX.Element => {
    const {
        input: {name: inputName, value: inputValue},
        existingFiles,
        fileIDsToBeRemoved,
        maxFileCount,
        badgeText = '',
        badgeInput,
        dropdownData,
        dropdownCreatable,
        arrayPush,
        arrayRemove,
        pushFileToDeleteList,
        warnOnRemove,
    } = props;
    const [badge, setBadge] = useState(badgeText);
    const handleTextInputChange = useCallback(function (event: React.FormEvent<any>): void {
        const {value = ''} = event.currentTarget;
        setBadge(value);
    }, []);
    const handleDropdownChange = useCallback((value: string): void => setBadge(value), []);
    const handleChange = useCallback(
        function (event: React.FormEvent<HTMLInputElement>): void {
            const file = head(event.currentTarget.files);
            if (!file) {
                return;
            }
            arrayPush(inputName, {file, badge, tmpID: uuidv4()});
            setBadge(badgeText);
        },
        [badge],
    );
    const filteredExistingFiles = useMemo(
        function (): StoredFile[] {
            if (!existingFiles) {
                return [];
            }
            if (!fileIDsToBeRemoved || isEmpty(fileIDsToBeRemoved)) {
                return existingFiles;
            }
            return existingFiles.filter((file: StoredFile): boolean => !fileIDsToBeRemoved.includes(file.id));
        },
        [fileIDsToBeRemoved],
    );
    const newFiles = useMemo(() => inputValue || [], [inputValue]);
    function renderExistingFilePreviews(): ReactNodeArray {
        return filteredExistingFiles.map(
            (file: StoredFile, index: number): ReactNode => (
                <FilePreview
                    key={`${file.file_name}_${index}`}
                    file={normalizeFile(file)}
                    handleRemove={() => pushFileToDeleteList(file)}
                    warnOnRemove={warnOnRemove}
                />
            ),
        );
    }
    function renderNewFilePreviews(): ReactNodeArray {
        if (isEmpty(newFiles)) {
            return [];
        }
        return newFiles.map(
            (file: FileWithBadge, index: number): ReactNode => (
                <FilePreview
                    key={`${file.file.name}_${index}`}
                    file={normalizeFile(file)}
                    handleRemove={() => arrayRemove(inputName, index)}
                    warnOnRemove={warnOnRemove}
                />
            ),
        );
    }
    const existingFilePreviews = useMemo(renderExistingFilePreviews, [filteredExistingFiles]);
    const newFilePreviews = useMemo(renderNewFilePreviews, [newFiles]);
    const totalFileCount = filteredExistingFiles.concat(newFiles).length;
    const isFileLimitReached = maxFileCount && maxFileCount === totalFileCount;
    return (
        <div className={styles.input}>
            {newFilePreviews}
            {existingFilePreviews}
            {!isFileLimitReached && (
                <div className="button-block">
                    {badgeInput === 'textinput' && (
                        <FormControl
                            maxLength={75}
                            type="text"
                            value={badge}
                            onChange={handleTextInputChange}
                            placeholder="type badge name"
                            style={{borderRadius: '4px', width: 180}}
                        />
                    )}
                    {badgeInput === 'dropdown' && (
                        <Select
                            type="text"
                            placeholder="choose type"
                            menuPlacement="top"
                            formGroup={false}
                            data={dropdownData}
                            input={{
                                value: badge,
                                onChange: handleDropdownChange,
                            }}
                            isCreatable={dropdownCreatable}
                        />
                    )}
                    <ButtonFileInput inputName={inputName} onChange={handleChange}>
                        UPLOAD
                    </ButtonFileInput>
                </div>
            )}
        </div>
    );
};

export default connect(mapExistingFilesFromFormState)(withFormActionCreators(BadgeFileInput));
