import React from 'react';
import {isEmpty} from 'lodash';

import {Quote, TruckDimensions} from 'core/entities/Quote/types';
import {getTruckDimensions} from 'core/entities/Quote/modules/quoteTruck';

import QuoteTruckDimensions from 'pages/LoadBoard/components/common/QuoteTruckDimensions';

import styles from './styles.module.scss';

const TruckDims: React.FC<{quote: Quote}> = ({quote}) => {
    const truckDimensions = getTruckDimensions(quote);
    if (isEmpty(truckDimensions)) {
        return null;
    }
    return (
        // <div className={styles.wrapper}>
        <div className={styles.wrapper}>
            DIMS: <QuoteTruckDimensions dimensions={truckDimensions as TruckDimensions} />
        </div>
    );
};

export default TruckDims;
