import {AxiosError} from 'axios';

import * as appActions from 'store/actions';
import {getCurrentDispatcher} from 'store/reducers/userData/selectors';
import * as requests from 'core/gateways/LoadBoardApiGateway/requests';
import {DriverOffer, Quote} from 'core/entities/Quote/types';

import {DriverOfferFormValues} from '../../types';
import * as actionCreators from '../actionCreators';
import {
    openSuccessChangedOfferModal,
    openSuccessCreateOfferModal,
    openSuccessDeactivateOfferModal,
} from './modalActions';
import transformOfferToPostRequest from './mappers/transformOfferToPostRequest';
import transformOfferToUpdateRequest from './mappers/transformOfferToUpdateRequest';
import {handleAddOfferError} from './errorHandlers';

export const fetchQuoteOffers = (quoteID: string): any => (dispatch: any) => {
    requests.fetchQuoteOffers(quoteID).then((offers) => {
        dispatch(actionCreators.receivedDriverOffers(offers, quoteID));
    });
};

export const addDriverOffer = (offer: DriverOfferFormValues): any => async (dispatch, getState) => {
    const state = getState();
    const currentDispatcher = getCurrentDispatcher(state);
    const transformedOffer = await transformOfferToPostRequest(offer, currentDispatcher);
    dispatch(appActions.showLoader());
    return requests
        .postOfferByDispatcher((transformedOffer as any) as DriverOffer)
        .then((createdOffer) => {
            if (createdOffer) {
                dispatch(openSuccessCreateOfferModal(createdOffer.number));
                dispatch(actionCreators.addedDriverOffer(createdOffer));
            }
        })
        .catch((error) => handleAddOfferError(error, dispatch))
        .catch((error: AxiosError) => {
            console.error('Error on create offer: ', error);
        })
        .finally(() => {
            dispatch(appActions.hideLoader());
        });
};

export const updateDriverOffer = (quote: Quote, offer: DriverOffer): any => (dispatch, getState) => {
    const state = getState();
    const currentDispatcher = getCurrentDispatcher(state);
    const offerData = transformOfferToUpdateRequest(offer, currentDispatcher);
    dispatch(appActions.showLoader());
    return requests
        .updateOfferByDispatcher(offerData)
        .then((updatedOffer) => {
            if (updatedOffer) {
                dispatch(openSuccessChangedOfferModal(quote, offer));
                dispatch(actionCreators.updateDriverOffer(updatedOffer));
            }
        })
        .catch((error: AxiosError) => {
            console.error('Error on update offer: ', error);
            dispatch(appActions.handleError(error));
        })
        .finally(() => {
            dispatch(appActions.hideLoader());
        });
};

export const readDriverOffer = (offer: DriverOffer): any => (dispatch) => {
    dispatch(appActions.showLoader());
    return requests
        .readOfferByDispatcher(offer)
        .then((result) => {
            if (result.success) {
                dispatch(actionCreators.readDriverOffer(offer.id));
            }
        })
        .catch((error: AxiosError) => {
            console.error('Error on read offer: ', error);
            dispatch(appActions.handleError(error));
        })
        .finally(() => {
            dispatch(appActions.hideLoader());
        });
};

export const approveDriverOffer = (offer: DriverOffer): any => (dispatch) => {
    dispatch(appActions.showLoader());
    return requests
        .approveOffer(offer)
        .then((approvedOffer) => {
            if (approvedOffer) {
                dispatch(actionCreators.changeStatusDriverOffer(approvedOffer));
            }
        })
        .catch((error: AxiosError) => {
            console.error('Error on approve offer: ', error);
            dispatch(appActions.handleError(error));
        })
        .finally(() => {
            dispatch(appActions.hideLoader());
        });
};

export const unApproveDriverOffer = (offer: DriverOffer): any => (dispatch) => {
    dispatch(appActions.showLoader());
    return requests
        .unApproveOffer(offer)
        .then((offerWithCanceledApprove) => {
            if (offerWithCanceledApprove) {
                dispatch(actionCreators.changeStatusDriverOffer(offerWithCanceledApprove));
            }
        })
        .catch((error: AxiosError) => {
            console.error('Error on cancel approve offer: ', error);
            dispatch(appActions.handleError(error));
        })
        .finally(() => {
            dispatch(appActions.hideLoader());
        });
};

export const cancelDriverOfferStatus = (offer: DriverOffer): any => (dispatch) => {
    dispatch(appActions.showLoader());
    return requests
        .cancelOfferStatus(offer)
        .then((offerWithResetStatus) => {
            if (offerWithResetStatus) {
                dispatch(actionCreators.changeStatusDriverOffer(offerWithResetStatus));
            }
        })
        .catch((error: AxiosError) => {
            console.error('Error on cancel reset offer status: ', error);
            dispatch(appActions.handleError(error));
        })
        .finally(() => {
            dispatch(appActions.hideLoader());
        });
};

export const deactivateDriverOffer = (quote: Quote, offer: DriverOffer): any => (dispatch) => {
    dispatch(appActions.showLoader());
    return requests
        .deactivateOffer(offer)
        .then((deactivatedOffer) => {
            dispatch(openSuccessDeactivateOfferModal(quote, offer));
            if (deactivatedOffer) {
                dispatch(actionCreators.changeStatusDriverOffer(deactivatedOffer));
            }
        })
        .catch((error: AxiosError) => {
            console.error('Error on deactivate offer: ', error);
            dispatch(appActions.handleError(error));
        })
        .finally(() => {
            dispatch(appActions.hideLoader());
        });
};
