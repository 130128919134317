import type {CreateTravelOrderWithTruckParams, CreateTravelOrderWithTruckResult, LoadApiGateway} from '../types';

const createTravelOrderWithTruck = async function (
    data: CreateTravelOrderWithTruckParams,
    deps: {
        loadApiGateway: LoadApiGateway;
    },
): Promise<CreateTravelOrderWithTruckResult> {
    const {load, truck} = data;
    const {loadApiGateway} = deps;

    const {toNumber, loadNumber} = await loadApiGateway.createTravelOrderWithTruck({
        loadNumber: load.number,
        truckNumber: truck.number,
    });

    return {
        createdTravelOrderNumber: toNumber,
        loadNumber,
    };
};

export default createTravelOrderWithTruck;
