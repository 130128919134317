import React from 'react';

import {Quote} from 'core/entities/Quote/types';
import {getQuotePieces, getQuoteWeight} from 'core/entities/Quote/modules/quoteFreights';

import Dimensions from 'pages/LoadBoard/components/common/QuoteLoadDimensions';

import styles from './styles.module.scss';

type OwnProps = {
    quote: Quote;
};

const PayloadInfo: React.FC<OwnProps> = (props) => {
    const {quote} = props;
    const pieces = getQuotePieces(quote);
    const weight = getQuoteWeight(quote);
    return (
        <div className={styles.wrapper}>
            <div className={styles.infoIcon}>
                <i className="fa fa-info-circle" />
                <span />
            </div>
            <div>Pieces: {pieces}</div>
            <div>Weight: {weight}</div>
            <div className={styles.dimsWrapper}>
                <Dimensions quote={quote} />
            </div>
        </div>
    );
};

export default PayloadInfo;
