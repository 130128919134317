import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type OwnProps = {
    type: 'success' | 'failure';
    show: boolean;
};

const Message: React.FC<OwnProps> = (props) => {
    const {type, show} = props;

    if (!show) {
        return null;
    }

    const failureIcon = <i className="fa fa-exclamation-triangle" />;
    const successIcon = <i className="fa fa-check" />;

    const failureMessage = 'Something went wrong. Please try later...';
    const successMessage = 'Connection has been restored.';

    return (
        <div
            className={classNames(styles.wrap, {
                [styles.success]: type === 'success',
                [styles.failure]: type === 'failure',
            })}
        >
            <div className={styles.icon}>{type === 'success' ? successIcon : failureIcon}</div>
            <div> {type === 'success' ? successMessage : failureMessage}</div>
        </div>
    );
};

export default Message;
