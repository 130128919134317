import React from 'react';
import {WrappedFieldProps} from 'redux-form';

import TextInput from 'components/ui/Form/TextInput';

import WEIGHT_UNITS from 'utils/data/weightUnits';
import {TWO_DECIMAL_DIGITS_AFTER_COMMA, THREE_DECIMAL_DIGITS_AFTER_COMMA} from 'utils/validation/regexps';

interface OwnProps {
    label: string | JSX.Element;
    units: string;
    normalize?: (str: string) => string;
    maxLength?: number;
    disabled?: boolean;
}

const checkWeight = {
    [WEIGHT_UNITS.TON]: (payload: string) => payload.match(THREE_DECIMAL_DIGITS_AFTER_COMMA),
    DEFAULT: (payload: string) => payload.match(TWO_DECIMAL_DIGITS_AFTER_COMMA),
};

const WeightMeasureInput: React.FC<WrappedFieldProps & OwnProps> = (props) => {
    const {units, disabled, input} = props;

    const onChange = (e) => {
        const target = e.target.value || '';
        const isWeight = checkWeight[units] || checkWeight.DEFAULT;

        if (isWeight(target) || target === '') {
            input.onChange(target);
        }
    };

    const onBlur = () => input.onBlur(input.value);

    return (
        <TextInput
            {...props}
            onChange={onChange}
            onBlur={onBlur}
            value={input.value}
            onFocus={input.onFocus}
            disabled={disabled}
        />
    );
};

export default WeightMeasureInput;
