import React from 'react';
import classNames from 'classnames';
import sumBy from 'lodash/sumBy';
import isEmpty from 'lodash/isEmpty';

import {User} from '../../types';

type UsersMap = Record<string, User>;

interface OwnProps {
    companyName: string | undefined;
    users: UsersMap;
    isEdit: boolean;
    batchNumber: number | undefined;
}

const getUsersInvoicesLength = (users: UsersMap): number => {
    if (isEmpty(users)) {
        return 0;
    }
    return sumBy(Object.values(users), (u: User) => u.invoicesCount);
};

const SelectedUsersInfo = ({companyName, users, isEdit, batchNumber}: OwnProps): JSX.Element => {
    const selectedUsersInvoicesLength = getUsersInvoicesLength(users);
    const selectedInvoicesClassName = classNames({'no-invoices': selectedUsersInvoicesLength === 0});
    const saveMessage = isEdit
        ? `Press save to add selected invoices to the batch #${batchNumber}.`
        : `Press save to form batch from the chosen parameters.`;
    return (
        <div className="modal-body__select-info">
            <div className={selectedInvoicesClassName}>
                <p>
                    {!companyName && !selectedUsersInvoicesLength ? (
                        <strong>Selected: no company, 0 invoices selected.</strong>
                    ) : (
                        <strong>
                            Selected: {companyName}, {selectedUsersInvoicesLength} invoices
                        </strong>
                    )}
                </p>
                <p>{saveMessage}</p>
            </div>
        </div>
    );
};

export default React.memo(SelectedUsersInfo);
