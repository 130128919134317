import React from 'react';
import ReactSelect from 'react-select';
import {WrappedFieldProps} from 'redux-form';

import SelectOption from 'types/SelectOption';

import {flattenGroupedOptions} from '../../utils';

type OwnProps = {
    options: SelectOption[];
};

const getDefaultOption = (inputValue, options): SelectOption | null => {
    const listOptions = flattenGroupedOptions(options);
    const defOption = listOptions.find((option) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return inputValue?.id === option?.value?.id;
    });
    // important for case when default option dosent exists return null
    // cause with other falsy values like undefined ReactSelect wont be reset value
    return defOption || null;
};

const onChangeHandler = (option, props: OwnProps & WrappedFieldProps): void => {
    const currentValue = option?.value || null;
    props.input.onChange(currentValue);
};

const ObjectValuesSelect: React.FC<OwnProps & WrappedFieldProps> = (props) => {
    const {input, options} = props;
    const defaultOption = getDefaultOption(input.value, options);
    const selectProps = {
        ...props,
        options,
        value: defaultOption,
        onChange: (option) => onChangeHandler(option, props),
    };
    return <ReactSelect {...selectProps} />;
};

export default ObjectValuesSelect;
