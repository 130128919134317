import {Sort, SortFrom} from 'store/reducers/sort/types';

import getNextSorting from 'utils/getNextSorting';

import type {SortActionsTypes} from './actionCreators';
import * as types from './actionTypes';

const defaultState: Sort = {
    drivers: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    carriers: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    customers: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    facilities: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    factoringCompanies: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    invoicesBatches: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    invoicesBatchesModal: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    invoices: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    settlementsBatches: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    settlementsPayPeriods: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    settlementsCarriers: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    settlementsOwner: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    trucks: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    owners: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    ownerTrucks: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    quotes: {
        sortBy: undefined,
        sortFrom: undefined,
    },
    loads: {
        sortBy: 'firstPickUp',
        sortFrom: 'asc',
    },
};

export default function sort(state = defaultState, action: SortActionsTypes) {
    switch (action.type) {
        case types.LIST_SORT_CHANGED: {
            const {sortListName, sortBy, sortFrom} = action.payload;
            const currentSortBy = state[sortListName].sortBy;
            const currentSortFrom = state[sortListName].sortFrom;

            let nextSortFrom: SortFrom = 'asc';

            if (sortFrom) {
                nextSortFrom = sortFrom;
            } else if (currentSortBy === sortBy) {
                nextSortFrom = getNextSorting(currentSortFrom);
            }

            return {
                ...state,
                [sortListName]: {
                    sortFrom: nextSortFrom,
                    sortBy,
                },
            };
        }
        default:
            return state;
    }
}
