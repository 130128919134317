import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import mapKeys from 'lodash/mapKeys';
import compact from 'lodash/compact';

import {FileNew} from 'types/File';

import {FileMetaData} from 'core/entities/TravelOrder/types/Files';

import {FormFileWithID} from 'pages/TravelOrders/types/formTypes';

export const getCorrespondingAttachments = (params: {
    formAttachments: (Partial<FileNew> | {file: File; tmpID: string})[]; // attachments from the modals, form
    filesIds: Record<number, string>;
}): {uploadedFormData: FormData | null} => {
    const {formAttachments, filesIds} = params;

    if (isEmpty(formAttachments)) {
        return {uploadedFormData: null};
    }
    const newFormFiles = (formAttachments || []).filter((attachment) => 'tmpID' in attachment) as {
        tmpID: string;
        file: File;
    }[];

    const uploadedFormData = new FormData();

    const attachmentsFileIds = mapKeys(filesIds, (value, key) => `attachments[${key}]`);

    mapValues(attachmentsFileIds, (value, key) => {
        const file = newFormFiles.find((item) => item.tmpID === value)?.file as File;
        return uploadedFormData.append(key, file);
    });

    return {uploadedFormData: isEmpty(attachmentsFileIds) ? null : uploadedFormData};
};

export const getAttachmentsMetaData = (files?: (Partial<FileNew> | FormFileWithID)[]): FileMetaData[] | undefined => {
    const attachments = files?.map((item) => {
        if ('tmpID' in item) {
            return {
                tmpId: item.tmpID,
                fileName: item.file.name,
                mimeType: item.file.type,
                documentNumber: item.documentNumber,
            };
        }
        return null;
    });

    return compact(attachments).length ? compact(attachments) : undefined;
};
