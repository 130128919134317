import {
    formatCoordinates as googleFormatCoordinates,
    loadMapApi as googleLoadMapApi,
    checkGoogleApiScript as googleCheckGoogleApiScript,
    requestPlaces as googleRequestPlaces,
    requestPlaceDetails as googleRequestPlaceDetails,
    makeDirectionsRequestData as googleMakeDirectionsRequestData,
    makeDirectionsRequestDataFromAllChainedRoutePoints as googleMakeDirectionsRequestDataFromAllChainedRoutePoints,
    requestDirections as googleRequestDirections,
} from 'services/mapsApi/googleapi';
import getGeoDataByAddress from 'services/mapsApi/expediteApi/getGeoDataByAddress';
import getGeoDataByCityLine from 'services/mapsApi/expediteApi/getGeoDataByCityLine';

import {getDistanceFromOriginsToDestinations} from './expediteApi/distanceMatrix';

export {getDistanceFromOriginsToDestinations};

export const formatCoordinates = googleFormatCoordinates;
export const loadMapApi = googleLoadMapApi;
export const checkGoogleApiScript = googleCheckGoogleApiScript;
export const requestPlaces = googleRequestPlaces;
export const requestPlaceDetails = googleRequestPlaceDetails;
export const makeDirectionsRequestData = googleMakeDirectionsRequestData;
export const makeDirectionsRequestDataFromAllChainedRoutePoints = googleMakeDirectionsRequestDataFromAllChainedRoutePoints;
export const requestDirections = googleRequestDirections;

export {getGeoDataByAddress};
export {getGeoDataByCityLine};
