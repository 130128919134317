import {createSelector} from 'reselect';

import {TIME_ZONES} from 'utils/data/timeZones';
import {convertDate, changeDate} from 'utils/dateTime';

import FIELDS from './fields';
import {getNowWithNewYorkTimeZone, getDateStart, getDateEnd} from './utils';

const getLastPayPeriodFromProps = (state, props) => props.lastPayPeriod;

// set date from with 0 hours and 0 minutes and date to with 23 hours minutes and seconds
// it is requirements from API team for simplify search with dates in DB
export const getInitialData = createSelector([getLastPayPeriodFromProps], (lastPayPeriod) => {
    if (!lastPayPeriod || !lastPayPeriod.date_to) {
        const nowInNewYorkTimezone = getNowWithNewYorkTimeZone();
        return {
            [FIELDS.dateFrom]: '1970-01-01 00:00:00',
            [FIELDS.dateTo]: getDateEnd(nowInNewYorkTimezone),
            [FIELDS.timeZone]: TIME_ZONES['America/New_York'].name,
        };
    }

    const convertToNY = convertDate(lastPayPeriod.date_to, {
        timeZoneFrom: 'utc',
        timeZoneTo: TIME_ZONES['America/New_York'].name,
    }).defaultDateTime;

    const nextDayAfterEndOfLastPayPeriod = changeDate(convertToNY, {
        operation: 'increase',
        value: 1,
        valueType: 'days',
    }).utcDate;

    const oneWeekAfterDateFrom = changeDate(convertToNY, {
        operation: 'increase',
        value: 8,
        valueType: 'days',
    }).utcDate;

    return {
        [FIELDS.dateFrom]: getDateStart(nextDayAfterEndOfLastPayPeriod),
        [FIELDS.dateTo]: getDateEnd(oneWeekAfterDateFrom),
        [FIELDS.timeZone]: TIME_ZONES['America/New_York'].name,
    };
});
