import React from 'react';

import {Company, User, SetupActions} from '../../types';
import CompanyItem from '../CompanyItem';

interface OwnProps {
    companies: Company[];
    selectedCompany: Company | undefined;
    selectedUsers: Record<string, User>;
    actions: SetupActions;
}

const Container = ({children}): JSX.Element => <div className="invoices-group">{children}</div>;

const CompaniesList = (props: OwnProps): JSX.Element => {
    const {companies, selectedCompany, selectedUsers, actions} = props;
    if (companies.length === 0) {
        return (
            <Container>
                <p className="mb15">There are no invoices to adding them to the batch.</p>
            </Container>
        );
    }
    return (
        <Container>
            {companies.map((company) => {
                if (company.invoicesCount === 0) {
                    return (
                        <p key={company.keyID} className="mb15">
                            There are no invoices to adding them to the batch.
                        </p>
                    );
                }
                return (
                    <CompanyItem
                        key={company.keyID}
                        company={company}
                        selectedUsers={selectedUsers}
                        isOpened={selectedCompany?.entityID === company.entityID}
                        actions={actions}
                    />
                );
            })}
        </Container>
    );
};

export default CompaniesList;
