import React from 'react';

import {DriverReceiverWithTruck} from 'core/entities/Quote/types';
import {
    isAllowedSendQuoteToDriver,
    isDriverDeclinedQuote,
    isDriverReadQuote,
    isDriverProvidedQuote,
} from 'core/entities/Quote/modules/quoteDriverReceiver';

import StatusBadge from './components/StatusBadge';

const QuoteSentInfo: React.FC<{receiver: DriverReceiverWithTruck}> = (props) => {
    const {receiver} = props;

    const getStatusText = (): string => {
        if (isAllowedSendQuoteToDriver(receiver)) {
            return 'sent';
        }

        if (isDriverDeclinedQuote(receiver)) {
            return 'declined';
        }

        if (isDriverReadQuote(receiver)) {
            return 'opened';
        }

        if (isDriverProvidedQuote(receiver)) {
            return 'provided';
        }

        return 'wasn`t sent';
    };

    const getStatusColor = () => {
        if (isAllowedSendQuoteToDriver(receiver)) {
            return 'yellow';
        }

        if (isDriverDeclinedQuote(receiver)) {
            return 'red';
        }

        if (isDriverReadQuote(receiver)) {
            return 'blue';
        }

        if (isDriverProvidedQuote(receiver)) {
            return 'green';
        }

        return undefined;
    };

    const statusText = getStatusText();
    const statusColor = getStatusColor();

    return (
        <div>
            <StatusBadge text={statusText} color={statusColor} />
        </div>
    );
};

export default QuoteSentInfo;
