import React from 'react';

import {Quote} from 'core/entities/Quote/types';
import {getQuoteDistance} from 'core/entities/Quote/modules/quoteLoad';
import {getQuoteWeight, getQuoteWeightUnit} from 'core/entities/Quote/modules/quoteFreights';

import {weightView} from 'utils/weightConverter';

import DistanceValue from 'pages/LoadBoard/components/common/DistanceValue';

const QuoteFreightInfo: React.FC<{quote: Quote}> = (props) => {
    const {quote} = props;

    const distance = getQuoteDistance(quote);
    const weight = getQuoteWeight(quote);
    const weightUnit = getQuoteWeightUnit(quote);

    return (
        <div className="col-2">
            <DistanceValue distance={distance} withUnit />
            <div>{weightView(weightUnit, weight)}</div>
        </div>
    );
};

export default QuoteFreightInfo;
