import {getTravelOrderStopByID} from 'core/entities/TravelOrder/modules/travelOrderStops';
import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';

import {createDate} from 'utils/dateTime';

type TransformDateTime = {
    dateTimeInTheClientFormat: string;
    dateInTheClientFormat: string;
    timeInTheClientFormat: string;
    clientTimeZone: string;

    dateTimeInTheServerFormat: string;
    dateInTheServerFormat: string;
    timeInTheServerFormat: string;

    originalDateInTheClientFormat: string;
    originalTimeInTheClientFormat: string;
};
const transformDateTime = (params: {dateTime?: string | null}): TransformDateTime | null => {
    const {dateTime} = params;

    if (!dateTime) {
        return null;
    }

    const dateTimeInTheClientFormat = createDate(dateTime);
    const dateTimeInTheServerFormat = createDate(dateTime, {toTimeZone: 'utc'});

    if (!dateTimeInTheClientFormat.isValid || !dateTimeInTheServerFormat.isValid) {
        return null;
    }

    return {
        dateTimeInTheClientFormat: dateTimeInTheClientFormat.fullDate,
        dateInTheClientFormat: dateTimeInTheClientFormat.date,
        timeInTheClientFormat: dateTimeInTheClientFormat.time,
        clientTimeZone: dateTimeInTheClientFormat.timeZone,

        dateTimeInTheServerFormat: dateTimeInTheServerFormat.fullOriginalDateTime,
        dateInTheServerFormat: dateTimeInTheServerFormat.originalDate,
        timeInTheServerFormat: dateTimeInTheServerFormat.originalTime,

        originalDateInTheClientFormat: dateTimeInTheClientFormat.originalDate,
        originalTimeInTheClientFormat: dateTimeInTheClientFormat.originalTime,
    };
};

const getOrderNumberByStops = (stop?: TravelOrderStop): number | undefined => stop?.meta?.orderNumberByStops;
const getOrderNumberByType = (stop?: TravelOrderStop): number | undefined => stop?.meta?.orderNumberByType;
const getTotalStopsByType = (stop?: TravelOrderStop): number | undefined => stop?.meta?.totalStopsByType;
const getStopMetaStatus = (stop?: TravelOrderStop): string | undefined => stop?.meta?.status;
const getStopCheckOutDateTime = (stop?: TravelOrderStop): TransformDateTime | null =>
    transformDateTime({dateTime: stop?.checkOutDate});
const getStopLoadedUnloadedDateTime = (stop?: TravelOrderStop): TransformDateTime | null =>
    transformDateTime({dateTime: stop?.documentActionDate});
const getStopCheckInDateTime = (stop?: TravelOrderStop): TransformDateTime | null =>
    transformDateTime({dateTime: stop?.checkInDate});
const getStopOrderNumber = (stop?: TravelOrderStop): number | undefined => stop?.orderNumber;
const getStopID = (stop?: TravelOrderStop): string | undefined => stop?.id;
const getStopStatus = (stop?: TravelOrderStop): string | undefined => stop?.status;
const getPrevStopID = (stop?: TravelOrderStop): string | undefined | null => stop?.prevStop;

export type TOStopInfo = {
    checkOutDateTime: ReturnType<typeof getStopCheckOutDateTime>;
    loadedUnloadedDateTime: ReturnType<typeof getStopLoadedUnloadedDateTime>;
    orderNumberByStops: ReturnType<typeof getOrderNumberByStops>;
    checkInDateTime: ReturnType<typeof getStopCheckInDateTime>;
    orderNumberByType: ReturnType<typeof getOrderNumberByType>;
    totalStopsByType: ReturnType<typeof getTotalStopsByType>;
    orderNumber: ReturnType<typeof getStopOrderNumber>;
    metaStatus: ReturnType<typeof getStopMetaStatus>;
    prevStopID: ReturnType<typeof getPrevStopID>;
    status: ReturnType<typeof getStopStatus>;
    id: ReturnType<typeof getStopID>;
};

export const getTravelOrderStopInfo = (stop?: TravelOrderStop): TOStopInfo => ({
    checkOutDateTime: getStopCheckOutDateTime(stop),
    loadedUnloadedDateTime: getStopLoadedUnloadedDateTime(stop),
    orderNumberByStops: getOrderNumberByStops(stop),
    orderNumberByType: getOrderNumberByType(stop),
    checkInDateTime: getStopCheckInDateTime(stop),
    totalStopsByType: getTotalStopsByType(stop),
    orderNumber: getStopOrderNumber(stop),
    metaStatus: getStopMetaStatus(stop),
    prevStopID: getPrevStopID(stop),
    status: getStopStatus(stop),
    id: getStopID(stop),
});

export const getTravelOrderStopInfoByID = (params: StopInfoParams): TOStopInfo =>
    getTravelOrderStopInfo(getTravelOrderStopByID(params));
