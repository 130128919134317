import React from 'react';
import {Quote} from 'core/entities/Quote/types';
import {getTruckTeam} from 'core/entities/Quote/modules/quoteTruck';
import GeneralBadge from 'components/ui/Badges/GeneralBadge';

const QuoteTruckTeam: React.FC<{quote: Quote}> = (props) => {
    const {quote} = props;
    const team = getTruckTeam(quote);
    return (
        <div className="ml-1">
            {team && (
                <GeneralBadge
                    text={<i className="fa fa-users" />}
                    tooltip="team"
                    withBorderRadius={true}
                    tooltipPosition="right"
                />
            )}
        </div>
    );
};
export default QuoteTruckTeam;
