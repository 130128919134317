import React from 'react';

import WeightsUnitsOfMeasurement from 'components/ui/Form/WeightsUnitsOfMeasurement';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';
import useFormActions from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/hooks/useFormActions';

const FreightUnits: React.FC = () => {
    const {getLoadFreightLengthUnitFieldName} = getFormFieldNames();
    const {setFreightWeightUnit, setFreightWeightFieldName} = useFormActions();
    const weightUnit = useGetReduxFormValue(FORM_NAME, getLoadFreightLengthUnitFieldName());

    const setPayloadUnit = (unit): void => {
        setFreightWeightUnit(unit);
    };

    const clearPayload = (): void => {
        setFreightWeightFieldName(null);
    };

    return (
        <WeightsUnitsOfMeasurement
            unit={weightUnit}
            setUnit={setPayloadUnit}
            clearFields={clearPayload}
            disabled={false}
        />
    );
};

export default FreightUnits;
