import isEmpty from 'lodash/isEmpty';

import {TripMonitorState} from 'pages/TravelOrders/redux/reducers/list';

import {AddBrokerUpdateResponse, LocationEvent} from 'core/entities/LocationEvents/types';

export const handleReducerByAddBrokerUpdate = (params: {
    travelOrderNumber: number;
    state: TripMonitorState;
    data: AddBrokerUpdateResponse;
}) => {
    const {data, travelOrderNumber, state} = params;

    if (!travelOrderNumber || isEmpty(state.items) || isEmpty(data)) {
        return state;
    }

    const updateLocationEvents = (locationEvents: LocationEvent[], eventID) => {
        return locationEvents.map((event) =>
            event.id === eventID ? {...event, brokerUpdate: data.brokerUpdate} : event,
        );
    };

    const updatedItems = state.items.map((item) =>
        item.number === travelOrderNumber
            ? {...item, locationEvents: updateLocationEvents(item.locationEvents, data.eventId)}
            : item,
    );

    return {...state, items: updatedItems};
};
