import React from 'react';
import {Modal} from 'react-bootstrap';

import {Settlement} from 'core/entities/Settlement/types';

import {SettlementLink} from 'components/ui/Links';
import Button from 'components/ui/Buttons/Button';

import styles from './styles.module.scss';

interface OwnProps {
    data: {settlement: Settlement};
    handlers: {onConfirm(): void};
    onClose();
}

const DeletingWarnModal: React.FC<OwnProps> = (props) => {
    const {handlers, onClose, data} = props;
    const {onConfirm} = handlers;

    const onHide = () => onClose();

    return (
        <Modal className={styles.warn_modal} backdrop="static" show={true} dialogClassName="info-modal">
            <Modal.Header>
                <Modal.Title>
                    <div className={styles.modal_title}>Delete Settlement</div>
                </Modal.Title>
                <Button onClick={() => onHide()} buttonSize="icon" className="custom-design close-button">
                    <i className="fa fa-times" aria-hidden="true" />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.entry}>
                    <div className={styles.warn_icon}>
                        <i className="fa fa-exclamation-triangle" />
                    </div>
                    <div className={styles.warn_info}>
                        <div className={styles.title}>
                            <strong>Delete Settlement </strong>
                            <SettlementLink
                                content={`#${data.settlement.settlement_number}`}
                                settlement={data.settlement}
                                className="second-link"
                            />
                            ?
                        </div>
                        <p>All data will be lost.</p>
                        <p className="mb-4">
                            <strong>This cannot be undone!</strong>
                        </p>
                        <p>
                            <span>If you are not sure, please </span>
                            <SettlementLink
                                content="check information here."
                                settlement={data.settlement}
                                className="main-link"
                            />
                        </p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onHide()}>no, keep</Button>
                <Button onClick={() => onConfirm()} colorTheme="red">
                    yes, delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeletingWarnModal;
