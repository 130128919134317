import toLower from 'lodash/toLower';

import {getTravelOrderStopInfo} from 'core/entities/TravelOrder/modules/travelOrderStops';
import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';
import {ATTACH_TYPES} from 'core/entities/TravelOrder/constants/attachTypes';
import TravelOrder from 'core/entities/TravelOrder/types';

import {FileNew, TOFile} from 'types/File';

import getText from 'utils/getText';

export const getTravelOrderFiles = (travelOrder?: TravelOrder | null): TravelOrder['files'] => travelOrder?.files || [];

export const getTravelOrderAttachmentBadge = (params: {
    type?: TOFile['attachType'];
    fileIndex?: number;
    documentNumber?: number;
    stop?: TravelOrderStop;
}) => {
    const {type = '', fileIndex = 0, documentNumber, stop} = params;

    const {orderNumberByType} = getTravelOrderStopInfo(stop);

    const list = {
        [ATTACH_TYPES.TRAVEL_ORDER_FILE]: `T.O. ${toLower(getText('rateConfirmation'))} #${fileIndex + 1}`,
        [ATTACH_TYPES.RC_SIGNED]: `T.O. ${toLower(getText('rateConfirmation'))} #${fileIndex + 1}`,
        [ATTACH_TYPES.POD_FILE]: `(${orderNumberByType})delivery photo#${documentNumber}/${fileIndex + 1}`,
        [ATTACH_TYPES.BOL_FILE]: `(${orderNumberByType})pickup photo#${fileIndex + 1}`,
        [ATTACH_TYPES.TRAVEL_ORDER_OTHER_FILE]: `Other files`,
    };

    return list[type];
};

export const transferTOFileToNewFileInterface = (params: {
    file?: TOFile;
    fileIndex?: number;
    stop?: TravelOrderStop;
}): Partial<FileNew> => {
    const {file, fileIndex, stop} = params;

    const {attachType, documentNumber} = file || {};

    const badge = getTravelOrderAttachmentBadge({
        type: attachType,
        fileIndex,
        documentNumber,
        stop,
    });

    return {
        displayName: file?.displayName,
        attachType: file?.attachType,
        dispatcher: file?.dispatcher,
        uploadedAt: file?.uploadedAt,
        thumbName: file?.thumbName,
        fileSize: file?.fileSize,
        filePath: file?.filePath,
        fileName: file?.fileName,
        type: file?.ext,
        documentNumber,
        id: file?.id,
        badge,
    };
};
