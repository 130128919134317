export default (meta = {}) => {
    const {pristine, submitFailed, error, active, touched} = meta;
    if (!touched) {
        // to not show "error message" before first blur event (imitation of "blur validation")
        return true;
    }
    if (pristine && submitFailed && error) {
        return false;
    }
    if (pristine) {
        return true;
    }
    if (error) {
        return false;
    }
    if (!active) {
        return true;
    }
    return true;
};
