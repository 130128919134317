import {
    TruckDriverCertificate,
    TruckDriverCitizenship,
    TruckWithDriversTeam,
    DurationInMinutes,
    NoteForDrivers,
    NoteAboutQuote,
    TruckSigns,
    TruckEquipment,
    ReceiversList,
    TruckType,
    LoadFreightPieces,
    LoadFreightWeight,
    TruckDriverCrossBorder,
    DriverRate,
    DriverCurrency,
    DriverRPM,
    RouteDistanceKey,
    NoteForDispatchers,
    LoadFreightLengthUnit,
    LoadFreightDimensionUnit,
    LoadFreightDimensionItemsKey,
    LoadFreightDimensionLengthKey,
    LoadFreightDimensionWidthKey,
    LoadFreightDimensionHeightKey,
    TruckDimensionKey,
    TruckPayloadUnit,
    TruckPayloadValue,
    TruckDimensionUnit,
    TruckDimensionLength,
    TruckDimensionWidth,
    TruckDimensionHeight,
    TruckStatus,
    RadiusSearchDistanceKey,
    LoadStopsCoordinatesKey,
    LoadStopsDateFrom,
    LoadStopsDateTo,
    LoadStopsTimeFrame,
    LoadStopsTimezone,
    LoadStopsCityLine,
    LoadStopsAddressCity,
    LoadStopsAddressState,
    LoadStopsAddressZip,
    LoadStopsAddressCountry,
    LoadStopsCoordinatesLat,
    LoadStopsCoordinatesLng,
    LoadStopsKey,
    RouteIsAirportKey,
    LoadIsHotKey,
    LoadFreightIsStackableKey,
} from '../types';

interface Result {
    getRadiusSearchDistanceKey: () => RadiusSearchDistanceKey;
    getRouteDistanceFieldName: () => RouteDistanceKey;
    getIsAirportFieldName: () => RouteIsAirportKey;
    getIsHotFieldName: () => LoadIsHotKey;
    getReceiversListFiledName: () => ReceiversList;
    getTruckSignsFieldName: () => TruckSigns;
    getTruckTypeFieldName: () => TruckType;
    getTruckEquipmentFieldName: () => TruckEquipment;
    getTruckDriverCertificateFieldName: () => TruckDriverCertificate;
    getTruckDriverCitizenshipFieldName: () => TruckDriverCitizenship;
    getTruckWithDriversTeamFieldName: () => TruckWithDriversTeam;
    getTruckDriverCrossBorderFieldName: () => TruckDriverCrossBorder;
    getTruckPayloadUnitFieldName: () => TruckPayloadUnit;
    getTruckPayloadValueFieldName: () => TruckPayloadValue;
    getTruckDimensionUnitFieldName: () => TruckDimensionUnit;
    getTruckDimensionLengthFieldName: () => TruckDimensionLength;
    getTruckDimensionWidthFieldName: () => TruckDimensionWidth;
    getTruckDimensionHeightFieldName: () => TruckDimensionHeight;
    getTruckDimensionFieldName: () => TruckDimensionKey;
    getTruckTypeStatus: () => TruckStatus;
    getDurationInMinutesFieldName: () => DurationInMinutes;
    getDriverRateFieldName: () => DriverRate;
    getDriverCurrencyFieldName: () => DriverCurrency;
    getDriverRPMFieldName: () => DriverRPM;
    getNoteForDriversFieldName: () => NoteForDrivers;
    getNoteAboutQuoteFieldName: () => NoteAboutQuote;
    getNoteForDispatchersFieldName: () => NoteForDispatchers;
    getLoadFreightPiecesFieldName: () => LoadFreightPieces;
    getLoadFreightWeightFieldName: () => LoadFreightWeight;
    getLoadFreightLengthUnitFieldName: () => LoadFreightLengthUnit;
    getLoadFreightDimensionUnitFieldName: () => LoadFreightDimensionUnit;
    getLoadFreightDimensionItems: () => LoadFreightDimensionItemsKey;
    getLoadFreightDimensionLengthFieldName: (index: number) => LoadFreightDimensionLengthKey;
    getLoadFreightDimensionWidthFieldName: (index: number) => LoadFreightDimensionWidthKey;
    getLoadFreightDimensionHeightFieldName: (index: number) => LoadFreightDimensionHeightKey;
    getStopCoordinatesFieldName: (index: number) => LoadStopsCoordinatesKey;
    getStopDateFromFieldName: (index: number) => LoadStopsDateFrom;
    getStopDateToFieldName: (index: number) => LoadStopsDateTo;
    getStopTimeFrameFieldName: (index: number) => LoadStopsTimeFrame;
    getStopTimezoneFieldName: (index: number) => LoadStopsTimezone;
    getStopCityLineFieldName: (index: number) => LoadStopsCityLine;
    getStopAddressCityFieldName: (index: number) => LoadStopsAddressCity;
    getStopAddressStateFieldName: (index: number) => LoadStopsAddressState;
    getStopAddressZipFieldName: (index: number) => LoadStopsAddressZip;
    getStopAddressCountryFieldName: (index: number) => LoadStopsAddressCountry;
    getStopCoordinatesLatFieldName: (index: number) => LoadStopsCoordinatesLat;
    getStopCoordinatesLngFieldName: (index: number) => LoadStopsCoordinatesLng;
    getStopsFieldName: () => LoadStopsKey;
    getLoadFreightIsStackableFieldName: () => LoadFreightIsStackableKey;
}

const getFormFieldNames = (): Result => {
    return {
        // RADIUS SEARCH DISTANCE
        getRadiusSearchDistanceKey: (): RadiusSearchDistanceKey => 'radiusSearchDistance',

        //  ROUTE
        getRouteDistanceFieldName: () => 'route.distance',
        getIsAirportFieldName: () => 'route.isAirport',

        // STOPS
        getIsHotFieldName: () => 'load.isHot',
        getStopsFieldName: () => 'load.stops',
        getStopCoordinatesFieldName: (index): LoadStopsCoordinatesKey => `load.stops.[${index}].coordinates` as const,
        getStopDateFromFieldName: (index): LoadStopsDateFrom => `load.stops.[${index}].dateFrom` as const,
        getStopDateToFieldName: (index): LoadStopsDateTo => `load.stops.[${index}].dateTo` as const,
        getStopTimeFrameFieldName: (index): LoadStopsTimeFrame => `load.stops.[${index}].timeFrame` as const,
        getStopTimezoneFieldName: (index): LoadStopsTimezone => `load.stops.[${index}].timeZone` as const,
        getStopCityLineFieldName: (index): LoadStopsCityLine => `load.stops.[${index}].address.cityLine` as const,
        getStopAddressCityFieldName: (index): LoadStopsAddressCity => `load.stops.[${index}].address.city` as const,
        getStopAddressStateFieldName: (index): LoadStopsAddressState => `load.stops.[${index}].address.state` as const,
        getStopAddressZipFieldName: (index): LoadStopsAddressZip => `load.stops.[${index}].address.zip` as const,
        getStopAddressCountryFieldName: (index): LoadStopsAddressCountry =>
            `load.stops.[${index}].address.country` as const,
        getStopCoordinatesLatFieldName: (index): LoadStopsCoordinatesLat =>
            `load.stops.[${index}].coordinates.latitude` as const,
        getStopCoordinatesLngFieldName: (index): LoadStopsCoordinatesLng =>
            `load.stops.[${index}].coordinates.longitude` as const,

        //  RECEIVERS LIST
        getReceiversListFiledName: (): ReceiversList => 'receivers',

        // TRUCK INFO
        getTruckSignsFieldName: (): TruckSigns => 'truck.withSigns',
        getTruckTypeFieldName: (): TruckType => 'truck.types',
        getTruckEquipmentFieldName: (): TruckEquipment => 'truck.equipment',
        getTruckDriverCertificateFieldName: (): TruckDriverCertificate => 'truck.driverCertificates',
        getTruckDriverCitizenshipFieldName: (): TruckDriverCitizenship => 'truck.driverCitizenship',
        getTruckWithDriversTeamFieldName: (): TruckWithDriversTeam => 'truck.withDriversTeam',
        getTruckDriverCrossBorderFieldName: (): TruckDriverCrossBorder => 'truck.driverCrossBorder',
        getTruckPayloadUnitFieldName: (): TruckPayloadUnit => 'truck.payload.unit',
        getTruckPayloadValueFieldName: (): TruckPayloadValue => 'truck.payload.value',
        getTruckDimensionFieldName: (): TruckDimensionKey => 'truck.dimensions',
        getTruckDimensionUnitFieldName: (): TruckDimensionUnit => 'truck.dimensions.unit',
        getTruckDimensionLengthFieldName: (): TruckDimensionLength => 'truck.dimensions.length',
        getTruckDimensionWidthFieldName: (): TruckDimensionWidth => 'truck.dimensions.width',
        getTruckDimensionHeightFieldName: (): TruckDimensionHeight => 'truck.dimensions.height',
        getTruckTypeStatus: (): TruckStatus => 'truck.statuses',
        // LOAD LIFETIME
        getDurationInMinutesFieldName: (): DurationInMinutes => 'durationInMinutes',

        //  PAYMENT
        getDriverRateFieldName: (): DriverRate => 'driverPayment.rate',
        getDriverCurrencyFieldName: (): DriverCurrency => 'driverPayment.currency',
        getDriverRPMFieldName: (): DriverRPM => 'driverPayment.rpm',

        //  NOTES
        getNoteForDriversFieldName: (): NoteForDrivers => 'notes.forDrivers',
        getNoteForDispatchersFieldName: (): NoteForDispatchers => 'notes.forDispatchers',
        getNoteAboutQuoteFieldName: (): NoteAboutQuote => 'notes.aboutQuote',

        //  FREIGHTS
        getLoadFreightPiecesFieldName: (): LoadFreightPieces => 'load.freight.pieces',
        getLoadFreightWeightFieldName: (): LoadFreightWeight => 'load.freight.weight',
        getLoadFreightLengthUnitFieldName: (): LoadFreightLengthUnit => 'load.freight.weightUnit',
        getLoadFreightDimensionUnitFieldName: (): LoadFreightDimensionUnit => 'load.freight.dimensions.unit',
        getLoadFreightDimensionItems: (): LoadFreightDimensionItemsKey => 'load.freight.dimensions.items',
        getLoadFreightDimensionLengthFieldName: (index): LoadFreightDimensionLengthKey =>
            `load.freight.dimensions.items.[${index}].length` as const,
        getLoadFreightDimensionWidthFieldName: (index): LoadFreightDimensionWidthKey =>
            `load.freight.dimensions.items.[${index}].width` as const,
        getLoadFreightDimensionHeightFieldName: (index): LoadFreightDimensionHeightKey =>
            `load.freight.dimensions.items.[${index}].height` as const,
        getLoadFreightIsStackableFieldName: () => 'load.freight.isStackable',
    };
};

export default getFormFieldNames;
