import * as types from './actionTypes';

const defaultState = {
    user: {
        authToken: null,
        data: null,
    },
    isLoading: false,
    isError: false,
};

export default function app(state = defaultState, action) {
    switch (action.type) {
        case types.SHOW_ERROR: {
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        }

        case types.START_USER_LOGIN: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case types.HIDE_ERROR: {
            return {
                ...state,
                isError: false,
            };
        }

        default:
            return state;
    }
}
