import {useDispatch} from 'react-redux';
import * as modalActions from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

interface Result {
    openCancelConfirmModal: (rightButtonHandler: () => void) => void;
}

export default function (): Result {
    const dispatch = useDispatch();
    const title = 'Cancel Create';
    const buttonActionTitle = 'Creating';
    const params = {
        modalName: commonModalNames.informationModal,
        data: {
            title,
            leftButtonTitle: `KEEP ${buttonActionTitle}`,
            rightButtonTitle: `Cancel ${buttonActionTitle}`,
            bodyType: 'CancelForm',
            buttonType: 'danger',
        },
        handlers: {
            leftButtonHandler: () => {},
        },
    };
    return {
        openCancelConfirmModal: (rightButtonHandler) =>
            dispatch(modalActions.openModal({...params, handlers: {...params.handlers, rightButtonHandler}})),
    };
}
