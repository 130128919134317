import React from 'react';

import {createDate} from 'utils/dateTime';

import {PayRecord, ResponsibleOwnerPayRecord, ResponsibleCoordinatorPayRecord} from 'core/entities/Settlement/types';

import styles from './payRecordEmailNotSent.module.scss';

interface OwnProps {
    payRecord: PayRecord | ResponsibleOwnerPayRecord | ResponsibleCoordinatorPayRecord;
}

const PayRecordEmailSent = ({date}): JSX.Element => {
    const {fullDate: convertedTimeOfSent} = createDate(date);

    return (
        <div>
            sent <strong>{convertedTimeOfSent}</strong>
        </div>
    );
};

const PayRecordEmailNotSent: React.FC = () => (
    <div className={styles.item}>
        <p>
            <em>pay record was not sent</em>
        </p>
    </div>
);

const EmailInfo: React.FC<OwnProps> = ({payRecord}) => {
    return payRecord?.is_sent ? <PayRecordEmailSent date={payRecord.sent_at} /> : <PayRecordEmailNotSent />;
};

export default EmailInfo;
