import {LOAD_TYPES} from 'core/entities/Load/constants';

type LoadTypesKeys = keyof typeof LOAD_TYPES;
export type LoadTypes = typeof LOAD_TYPES[LoadTypesKeys];

export const loadTypesOptions: {label: string; value: LoadTypes}[] = [
    {label: LOAD_TYPES.CONESTOGA, value: LOAD_TYPES.CONESTOGA},
    {label: LOAD_TYPES.DRAYAGE, value: LOAD_TYPES.DRAYAGE},
    {label: LOAD_TYPES.EXPEDITE, value: LOAD_TYPES.EXPEDITE},
    {label: LOAD_TYPES.POWER_ONLY, value: LOAD_TYPES.POWER_ONLY},
    {label: LOAD_TYPES.REEFER, value: LOAD_TYPES.REEFER},
    {label: LOAD_TYPES.UNDEFINED, value: LOAD_TYPES.UNDEFINED},
];
