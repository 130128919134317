import React from 'react';
import {useSelector} from 'react-redux';

import emptyNotificationsIcon from './images/empty-notifications.svg';
import NotificationItem from './components/NotificationItem';
import styles from './notificationList.module.scss';
import * as selectors from '../../redux/selectors';

const NotificationsList: React.FC = () => {
    const notifications = useSelector(selectors.getNotifications);
    if (Object.keys(notifications.byId).length === 0) {
        return (
            <div className={styles.empty_notifications}>
                <img src={emptyNotificationsIcon} alt="" />
                <p className="mt-2">No new messages</p>
            </div>
        );
    }
    return (
        <div className={styles.notifications_list}>
            {notifications.allIds.map((id) => {
                const notification = notifications.byId[id];
                if (!notification) {
                    return null;
                }
                return <NotificationItem key={notification.id} notification={notification} />;
            })}
        </div>
    );
};

export default NotificationsList;
