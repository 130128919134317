import React from 'react';

import Truck from 'core/entities/Truck/types';

import {TruckLink} from 'components/ui/Links';

const TruckWithoutFirstDriverError: React.FC<{truck: Truck}> = (props) => {
    const {truck} = props;
    if (!truck) {
        return null;
    }
    return (
        <>
            <div className="attention">Attention</div>
            <p className="mt-2">
                You can&apos;t cancel finish confirmation because <br />
                <strong>
                    <TruckLink truck={truck} content={`Truck #${truck?.number}`} className="second-link" /> without
                    first driver
                </strong>
                . <br />
                Please, attach the first driver who was in this Travel Order to <br />
                resolve this issue. Then you can cancel finish confirmation.
            </p>
        </>
    );
};

export default TruckWithoutFirstDriverError;
