import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button/index';

import PayRecord from 'core/entities/Settlement/types/PayRecord';
import PayCorrection from 'core/entities/Settlement/types/PayCorrection';

import Form from './Form';

interface OwnProps {
    data: {payRecord: PayRecord; payCorrectionType: PayCorrection['type']};
    handlers: {onSubmit(): void; onDelete(): void};
    onClose();
}

const PayRecordDeductionPaymentModal = ({data, handlers: {onSubmit}, onClose}: OwnProps): JSX.Element => {
    const {payRecord, payCorrectionType} = data;

    return (
        <Modal show={true} onHide={onClose} dialogClassName="custom-dialogs" backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <span className="text-capitalize">{payCorrectionType}</span>
                </Modal.Title>
                <Button onClick={onClose} buttonSize="icon" className="custom-design close-button">
                    <i className="fa fa-times" aria-hidden="true" />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={onSubmit}
                    payRecord={payRecord}
                    payCorrectionType={payCorrectionType}
                    onClose={onClose}
                />
            </Modal.Body>
        </Modal>
    );
};

export default PayRecordDeductionPaymentModal;
