import {InferActions} from 'store';

import * as types from './actionTypes';

export const setAdminSettings = (payload) => ({type: types.SET_ADMIN_SETTINGS, payload} as const);

const actionCreators = {
    setAdminSettings,
};

export type AdminSettingsActionsTypes = InferActions<typeof actionCreators>;

export default actionCreators;
