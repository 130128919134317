import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

const SpinIcon = () => {
    const className = 'fa fa-spinner fa-pulse fa-x';
    return <i className={classNames(className, styles.item)} />;
};

export default SpinIcon;
