import {
    getPageNameFromChangeLocationAction,
    getRouteParamsFromChangeLocationAction,
    getIsLocationChanged,
} from 'routing/utils';
import {INVOICES} from 'utils/data/pages';

export default (currentState, defaultState, locationChangeAction) => {
    if (!getIsLocationChanged(locationChangeAction)) {
        return currentState;
    }

    const pageName = getPageNameFromChangeLocationAction(locationChangeAction);
    const routeParams = getRouteParamsFromChangeLocationAction(locationChangeAction) || {};
    const isBeforeNavigateToAnotherPage = pageName !== INVOICES;
    const isBeforeNavigateToInvoiceView = pageName === INVOICES && routeParams.action === 'view';
    const isBeforeNavigateToInvoicesList = pageName === INVOICES && !routeParams.action;

    if (isBeforeNavigateToAnotherPage || isBeforeNavigateToInvoiceView || isBeforeNavigateToInvoicesList) {
        return defaultState;
    }

    return currentState;
};
