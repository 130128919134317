import {PayModalFormValues} from 'pages/TravelOrders/types/formTypes';

import {transformDistance} from 'utils/distance';

type PayDataToRequestBody = Pick<PayModalFormValues, 'miles' | 'driverPayment' | 'extra'>;

export const transformPayDataToRequestBody = (data: PayModalFormValues): PayDataToRequestBody => {
    const {driverPayment, miles, extra} = data;

    const {toMiles} = transformDistance(miles);

    const payDataForRequest = {
        driverPayment: driverPayment || 0,
        miles: toMiles || 0,
        extra: extra || 0,
    };

    return payDataForRequest;
};
