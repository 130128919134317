import React from 'react';
import classNames from 'classnames';
import sumBy from 'lodash/sumBy';

import {OpenedPayPeriod, PayPeriodResponsibleUser} from '../types';

import UsersPayments from './components/UsersPayments';

interface OwnProps {
    selectedPayPeriod: OpenedPayPeriod | undefined;
}

const getLengthOfSelectedSettlements = (selectedPayPeriod: OpenedPayPeriod | undefined): number => {
    if (!selectedPayPeriod) {
        return 0;
    }
    return sumBy(selectedPayPeriod.users, (user: PayPeriodResponsibleUser) => {
        return user.settlements_count;
    });
};

const SelectedPayPeriodInfo = ({selectedPayPeriod}: OwnProps): JSX.Element => {
    const selectedSettlementsLength = getLengthOfSelectedSettlements(selectedPayPeriod);
    const selectedSettlementsClassName = classNames({'no-invoices': selectedSettlementsLength === 0});
    return (
        <div className="modal-body__select-info">
            <div className={selectedSettlementsClassName}>
                <div>
                    {!selectedPayPeriod ? (
                        <strong>Selected: no pay period, 0 settlements, $0</strong>
                    ) : (
                        <div>
                            <div>
                                <strong>
                                    Selected: {selectedPayPeriod.title}, {selectedSettlementsLength} settlement(s)
                                </strong>
                            </div>
                            <div>
                                <UsersPayments users={selectedPayPeriod.users} />
                            </div>
                        </div>
                    )}
                </div>
                <p>Press post to move chosen settlements in its pay period.</p>
            </div>
        </div>
    );
};

export default React.memo(SelectedPayPeriodInfo);
