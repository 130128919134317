import React from 'react';
import {ClearFieldsAction, Field} from 'redux-form';
import classNames from 'classnames';

import {useGetIsReduxFormHasInitialStateFields} from 'pages/TravelOrders/components/common/modals/TimerSettingsModal/components/Form/hooks';
import TimeSelector from 'pages/TravelOrders/components/common/modals/TimerSettingsModal/components/Form/components/TimeSelector';

import {CHECK_CALL_INTERVAL_CONFIG} from 'pages/TravelOrders/components/common/modals/TimerSettingsModal/constants';

import {getName} from 'pages/TravelOrders/components/common/modals/TimerSettingsModal/components/Form/utils';

import styles from '../../styles.module.scss';

type OwnProps = {
    form: string;
    truckNumber: string;
    clearFields: ClearFieldsAction;
};

const CheckCallIntervalSection: React.FC<OwnProps> = (props) => {
    const {truckNumber, clearFields, form} = props;

    const isPickupResetBtnDisabled = useGetIsReduxFormHasInitialStateFields(form, getName('pickUp'));
    const isDeliveryResetBtnDisabled = useGetIsReduxFormHasInitialStateFields(form, getName('delivery'));

    // @ts-ignore
    const handleReset = (...args) => clearFields(...args);

    return (
        <div className={classNames(styles.sectionWrapper, styles.section)}>
            <div>
                <i className="fa fa-clock-o" aria-hidden="true" />
            </div>
            <div>
                <h4 className={styles.sectionTitle}>CHECK CALL INTERVAL FOR TRUCK #{truckNumber}:</h4>
                <p className={styles.subTitle}>
                    Set the update frequency and Check Call Interval for Truck. Once the timer expires, you&apos;ll
                    receive a reminder prompting you to send updates.
                </p>
                <div className={styles.timeSelectorWrapper}>
                    <Field
                        name={getName('pickUp')}
                        component={TimeSelector}
                        label={
                            <span>
                                ON THE WAY TO THE <strong>PICK UP</strong> EVERY:
                            </span>
                        }
                        onReset={handleReset}
                        resetBtnDisabled={isPickupResetBtnDisabled}
                        maxHours={CHECK_CALL_INTERVAL_CONFIG.maxHours}
                        minutesStep={CHECK_CALL_INTERVAL_CONFIG.minutesStep}
                    />
                    <Field
                        name={getName('delivery')}
                        component={TimeSelector}
                        label={
                            <span>
                                ON THE WAY TO THE <strong>DELIVERY</strong> EVERY:
                            </span>
                        }
                        onReset={handleReset}
                        resetBtnDisabled={isDeliveryResetBtnDisabled}
                        maxHours={CHECK_CALL_INTERVAL_CONFIG.maxHours}
                        minutesStep={CHECK_CALL_INTERVAL_CONFIG.minutesStep}
                    />
                </div>
            </div>
        </div>
    );
};

export default CheckCallIntervalSection;
