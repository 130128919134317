import React from 'react';
import {Modal} from 'react-bootstrap';

const ArchivingSuccessWrapper: React.FC<{title?: string; children; buttons}> = (props) => {
    const {children: successContent, buttons} = props;
    return (
        <Modal show={true} className="info-modal" backdrop="static">
            <Modal.Header>
                <Modal.Title>Successful moved to Archive</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row align-items-center">
                    <div className="col-1">
                        <i className="fa fa-check feedback-icon-success" />
                    </div>
                    <div className="col-11">{successContent}</div>
                </div>
            </Modal.Body>

            <Modal.Footer>{buttons}</Modal.Footer>
        </Modal>
    );
};

export default ArchivingSuccessWrapper;
