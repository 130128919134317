import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';

import {LOCATION_EVENTS_TYPES} from 'core/entities/LocationEvents/constants';
import {AddCheckCallRequestBody} from 'core/entities/LocationEvents/types';

import {AddCheckCallFormValues} from 'components/common/LocationEvents/modals/CheckCallModal/types';

import {convertDate} from 'utils/dateTime';

export const transformAddEventDataToRequestBody = (
    createFormValue: AddCheckCallFormValues,
): AddCheckCallRequestBody => {
    const {summaryItem: formSummaryItem} = createFormValue || {};

    const getTruckDateTimeToUTC = (dateTime: string): string => {
        return convertDate(dateTime, {timeZoneTo: 'utc', timeZoneFrom: createFormValue.timezone}).defaultDateTime;
    };

    const getSummaryItem = () => {
        if (!createFormValue.summaryItem.checkLocationForBroker) {
            return;
        }

        return {
            forBroker: {
                eventType: LOCATION_EVENTS_TYPES.CHECK_CALL,
                latitude: createFormValue.summaryItem.latitude,
                longitude: createFormValue.summaryItem.longitude,
                cityLine: createFormValue.summaryItem.cityLine,
                expectedTime: createFormValue.summaryItem.dateTime
                    ? getTruckDateTimeToUTC(createFormValue.summaryItem.dateTime)
                    : null,
                timeZone: createFormValue.timezone,
                loadNumber: createFormValue.summaryItem.loadNumber,
                behindReal: createFormValue.summaryItem.behindRealEvent,
                distanceToRealLocation: createFormValue.summaryItem.distanceToRealLocation,
            },
        };
    };

    const summaryItem = getSummaryItem();

    return {
        loadNumbers: createFormValue.loadNumbers,
        latitude: isNumber(createFormValue.latitude) ? createFormValue.latitude : null,
        longitude: isNumber(createFormValue.longitude) ? createFormValue.longitude : null,
        timeZone: createFormValue.timezone,
        cityLine: createFormValue.cityLine || null,
        eventDate: createFormValue.dateTime ? getTruckDateTimeToUTC(createFormValue.dateTime) : null,

        ...(isBoolean(formSummaryItem.isSendToBrokerSystem)
            ? {isSendToBrokerSystem: formSummaryItem.isSendToBrokerSystem}
            : {}),

        note: createFormValue.note,
        ...summaryItem,
    };
};
