import {useSWRInfinite, cache} from 'swr';
import {useEffect} from 'react';

interface FetchData {
    data: any[];
    error: string | undefined;
    currentPage: number | undefined;
    isFetching: boolean;
    isAllFetched: boolean;
    fetchNextPage();
    changeData(...params: any[]): void;
}

interface FetchParams {
    perPage: number;
    fetchData(...params: any[]);
    getKey(...params: any[]);
}

export default ({perPage, getKey, fetchData}: FetchParams): FetchData => {
    const {data, error, size: page, setSize: setPage, mutate} = useSWRInfinite(getKey, fetchData, {
        revalidateOnFocus: false,
    });
    const currentPage = page || 0;
    const fetchedDataForCurrentPage = data?.[currentPage - 1];
    const isAllFetched =
        fetchedDataForCurrentPage !== undefined &&
        (fetchedDataForCurrentPage?.length === 0 || fetchedDataForCurrentPage?.length < perPage);
    const isFetching = !isAllFetched && fetchedDataForCurrentPage === undefined;
    const fetchNextPage = (): void => {
        // @ts-ignore
        setPage(page + 1);
    };

    useEffect(() => {
        return function () {
            cache.clear();
        };
    }, []);

    if (error) {
        console.warn('Error on fetch paginated data: ', error);
    }

    return {
        data: data ? data.flat().filter(Boolean) : [],
        error,
        currentPage: page,
        isFetching,
        isAllFetched,
        fetchNextPage,
        changeData: mutate,
    };
};
