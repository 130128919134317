import React from 'react';
import Truck from 'core/entities/Truck/types';

import TruckStatusIcon from 'components/common/Truck/TruckStatus';
import TruckCurrentTravelOrder from 'components/common/Truck/TruckCurrentTravelOrder';
import PlannedTravelOrdersBadge from 'components/ui/Badges/PlannedTravelOrdersBadge';

import styles from './styles.module.scss';

const TruckStatus: React.FC<{truck: Truck}> = (props) => {
    const {truck} = props;
    return (
        <td className={styles.wrapper}>
            <TruckStatusIcon status={truck.status} />
            <TruckCurrentTravelOrder truck={truck} />
            <PlannedTravelOrdersBadge truck={truck} />
        </td>
    );
};

export default TruckStatus;
