import {fetchTravelOrderFiles, fetchTravelOrderNotes} from 'deprecated/core/gateways/TravelOrderApiGateway/requests';
import {fetchLoadFiles, fetchLoadNotes} from 'deprecated/core/entities/Load/requests';

import {getSettlementInvoice, getSettlementLoad, getSettlementTravelOrder} from 'core/entities/Settlement/settlement';
import {isInvoiceDeleted} from 'core/entities/Invoice/invoice';
import {fetchInvoice} from 'core/entities/Invoice/requests';

export const fetchSettlementInvoice = (settlement) => {
    const settlementInvoice = getSettlementInvoice(settlement);
    if (!settlementInvoice || isInvoiceDeleted(settlementInvoice)) {
        return Promise.resolve(undefined);
    }

    return fetchInvoice(settlementInvoice.invoice_number).catch((error) =>
        console.warn('Error on fetch settlement invoice: ', error),
    );
};

export const fetchSettlementData = async (settlement) => {
    const travelOrder = getSettlementTravelOrder(settlement);
    const load = getSettlementLoad(settlement);

    if (!travelOrder) {
        throw Error('Incorrect settlement travel order!');
    }

    if (!load) {
        throw Error('Incorrect settlement load!');
    }

    const [invoice, travelOrderFiles, travelOrderNotes, loadFiles, loadNotes] = await Promise.all([
        fetchSettlementInvoice(settlement),
        fetchTravelOrderFiles(travelOrder.id),
        fetchTravelOrderNotes(travelOrder.id),
        fetchLoadFiles(load.id),
        fetchLoadNotes(load.id),
    ]);

    return {
        invoice,
        travelOrderFiles,
        travelOrderNotes,
        loadFiles,
        loadNotes,
    };
};
