import React from 'react';

import {checkIsDeduction} from 'core/entities/Settlement/payCorrections';
import {PayCorrection} from 'core/entities/Settlement/types';
import CurrencyAmount from 'components/ui/CurrencyAmount';

interface OwnProps {
    payCorrection: PayCorrection;
}

const PayCorrectionRow = ({payCorrection}: OwnProps): JSX.Element => {
    const {comment, amount, currency} = payCorrection;
    const isDeduction = checkIsDeduction(payCorrection);
    return (
        <tr>
            <td colSpan={5}>{comment}</td>
            <td style={{width: '190px', paddingRight: '30px'}}>
                <div className="payment">
                    <span>{isDeduction ? '-' : ''}</span>
                    <CurrencyAmount amount={amount} currency={currency} />
                </div>
            </td>
        </tr>
    );
};

export default PayCorrectionRow;
