import {loadEvents} from '../../events';
import * as loadsActions from './actions';

export const loadsChannelMessageHandler = (store) => (uri, payload) => {
    const messageData = payload.msg;

    if (!messageData.event || !messageData.data) {
        return;
    }

    const {event, data} = messageData;

    switch (event) {
        case loadEvents.DATA_UPDATED:
            store.dispatch(loadsActions.loadsDataUpdateReceived(data));
            break;
        case loadEvents.FILES_POSTED:
            store.dispatch(loadsActions.loadsFilesPostReceived(data));
            break;
        case loadEvents.FILES_DELETED:
            store.dispatch(loadsActions.loadsFilesDeleteReceived(data));
            break;
        default:
            break;
    }
};
