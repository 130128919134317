import isEmpty from 'lodash/isEmpty';

import * as appActions from 'store/actions';
import {AppThunk} from 'store';

import {getTruckByNumber, updateTruckStatus} from 'core/gateways/TruckApiGateway/requests';
import * as truckStatuses from 'core/entities/Truck/constants/truckStatuses';
import Truck from 'core/entities/Truck/types';

import {transformAddTruckLocationFormDataToRequestBody} from 'pages/Loads/redux/mappers/attach/trucks';

import {AddTruckLocationFormValues} from 'components/ui/modals/AddTruckLocationModal/types';
import * as globalModalActions from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

export const addTruckLocation = (truck: Truck, formData: AddTruckLocationFormValues): AppThunk => async (dispatch) => {
    const {id: truckID, number: truckNumber} = truck;

    const requestBody = transformAddTruckLocationFormDataToRequestBody({truck, formData});

    try {
        dispatch(appActions.showLoader());

        await updateTruckStatus({truckID, requestBody});

        dispatch(globalModalActions.closeAll());
    } catch (error) {
        dispatch(globalModalActions.closeAll());
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const getAddTruckLocationAction = ({truckForAttach, onLocationChange}) => {
    return function (dispatch) {
        const openModalAction = globalModalActions.openModal({
            modalName: commonModalNames.addTruckLocationModal,
            handlers: {
                onSubmit: async (data) => {
                    const request = dispatch(addTruckLocation(truckForAttach, data));
                    const updateTruck = await request;
                    onLocationChange(updateTruck);
                },
            },
            data: {
                truckForAttach,
            },
        });
        dispatch(openModalAction);
    };
};

export const isNeedSetTruckLocation = (truckForAttach: Truck): boolean => {
    const statusesForCheckingLocation = [
        truckStatuses.TRUCK_STATUS_AVAILABLE,
        truckStatuses.TRUCK_STATUS_NOT_AVAILABLE,
        truckStatuses.TRUCK_STATUS_WILL_BE_AVAILABLE_AT,
    ];
    return statusesForCheckingLocation.includes(truckForAttach.status) && isEmpty(truckForAttach.last_city_line);
};

export const setTruckLocation = (truckForAttach: Truck, dispatch: any): Promise<Truck> => {
    return new Promise<Truck>((resolve) => {
        const warningAction = getAddTruckLocationAction({
            truckForAttach,
            onLocationChange: (truckData) => {
                resolve(truckData);
            },
        });
        dispatch(warningAction);
    });
};
