import type {AuthActionsTypes} from './actionCreators';
import * as types from './actionTypes';

export type AuthState = {
    token: string | null;
    refreshToken: string | null;
    expiresIn: number | null;
};

const defaultState: AuthState = {
    token: null,
    refreshToken: null,
    expiresIn: null,
};

const authReducer = (state = defaultState, action: AuthActionsTypes): AuthState => {
    switch (action.type) {
        case types.USER_LOGIN: {
            return {
                ...state,
                token: action.payload.access_token,
                refreshToken: action.payload.refresh_token,
                expiresIn: action.payload.expires_in,
            };
        }

        default:
            return state;
    }
};

export default authReducer;
