import React from 'react';

import styles from './styles.module.scss';

const LoadInfo: React.FC = () => {
    return (
        <div className={styles.wrap}>
            <div className={styles.icon}>
                <i className="fa fa-cube" />
            </div>

            <div className={styles.info}>
                <div className={styles.title}>LOAD INFO</div>
                <div className={styles.description}>Adding event to load summary.</div>
            </div>
        </div>
    );
};

export default LoadInfo;
