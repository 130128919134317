import React, {useMemo} from 'react';

import {PayRecord} from 'core/entities/Settlement/types';

import DataLoading from 'pages/Settlements/components/common/DataLoading';
import useFetchPayRecordSettlements from 'pages/Settlements/hooks/useFetchPayRecordSettlements';

import {getDistanceSettings} from 'utils/distance';

import SettlementRow from './SettlementRow';
import PayCorrectionRow from './PayCorrectionRow';

interface Props {
    payRecord: PayRecord;
}

const SettlementsList = ({payRecord}: Props): JSX.Element => {
    const {settlements, isFetching, isAllFetched, fetchNextPage} = useFetchPayRecordSettlements({
        payRecordID: payRecord.id,
        useSearchParams: false,
    });
    const {pay_corrections: payCorrections} = payRecord;
    const settlementRows = useMemo(() => {
        return settlements.map((s) => <SettlementRow key={s.id} settlement={s} />);
    }, [settlements]);
    const payCorrectionRows = useMemo(() => {
        return payCorrections.map((payCorrection) => (
            <PayCorrectionRow key={payCorrection.id} payCorrection={payCorrection} />
        ));
    }, [payCorrections]);

    const distanceSettings = getDistanceSettings();

    return (
        <div className="main open">
            {settlementRows.length > 0 && (
                <table className="standard-table-style settlements-group-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Truck and Driver</th>
                            <th>Origin - Destination</th>
                            <th>{distanceSettings.longUnit}</th>
                            <th>Check OUT info</th>
                            <th>Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {settlementRows}
                        {payCorrectionRows}
                    </tbody>
                </table>
            )}

            <br />

            <DataLoading
                isLoading={isFetching}
                isAllLoaded={isAllFetched}
                loadMoreTitle="load more settlements"
                onLoadMore={fetchNextPage}
            />
        </div>
    );
};

export default SettlementsList;
