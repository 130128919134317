import React, {useCallback} from 'react';
import {WrappedFieldInputProps, WrappedFieldMetaProps} from 'redux-form';

import {Facility} from 'core/entities/Facility/types';
import {searchFacilitiesByTitle} from 'core/gateways/FacilityApiGateway/requests';

import AsyncSelect from 'components/ui/Form/AsyncSelect';

import {getFacilitiesList} from './utils';

interface OwnProps {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    valueAsObject: boolean;
    placeholder?: string;
    isFullAddressShowing: boolean;
    isDisabled?: boolean;
    label?: string | JSX.Element;
}

const FULL_ADDRESS_SELECT_HEIGHT = 80;

const FacilitiesSelect = (props: OwnProps): JSX.Element => {
    const {
        input,
        meta,
        valueAsObject,
        placeholder = 'choose facility',
        isFullAddressShowing = false,
        isDisabled = false,
        label = '',
    } = props;

    const loadOptions = {
        getInitialOption: () => {
            return new Promise((resolve) => {
                if (!input.value) {
                    resolve(undefined);
                    return;
                }

                const [initialFacility] = getFacilitiesList(
                    [input.value as Facility],
                    valueAsObject,
                    isFullAddressShowing,
                );

                resolve(initialFacility);
            });
        },
        getNextOptions: async ({searchValue, page}): Promise<any> => {
            const searchedFacilities = await searchFacilitiesByTitle({title: searchValue, page, perPage: 10});
            return searchedFacilities.data;
        },
        formatNextOptions: (nextOptions): any => {
            return getFacilitiesList(nextOptions, valueAsObject, isFullAddressShowing);
        },
    };

    const handleSelectOption = useCallback((option) => {
        const res = valueAsObject ? option?.value : option?.value?.id;

        input.onChange(res);
    }, []);

    const optionHeight = isFullAddressShowing ? FULL_ADDRESS_SELECT_HEIGHT : undefined;

    return (
        <AsyncSelect
            formInput={input}
            formMeta={meta}
            label={label}
            placeholder={placeholder}
            loadOptions={loadOptions}
            optionHeight={optionHeight}
            onSelectOption={handleSelectOption}
            isDisabled={isDisabled}
        />
    );
};

export default FacilitiesSelect;
