import React from 'react';

const CancelForm = () => {
    return (
        <div className="row align-items-center">
            <div className="col-1">
                <i className="fa fa-exclamation feedback-icon-error" />
            </div>
            <div className="col-11">
                <p>You have unsaved data on this page that might be lost.</p>
            </div>
        </div>
    );
};

export default CancelForm;
