import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

import Owner from 'core/entities/Owner/types';
import {getOwnerName} from 'core/entities/Owner/modules/ownerName';
import {getOwnerPhone} from 'core/entities/Owner/modules/ownerPhone';

import {phoneNumberWithBraces} from 'utils';

const getOwnersWithoutDrivers = (owners: Owner[]) => {
    return compact(
        // eslint-disable-next-line array-callback-return
        owners.map((owner) => {
            const isOwnerDriver = !isEmpty(owner.drivers) && owner.drivers.find((item) => item.is_owner);

            if (!isOwnerDriver) {
                return owner;
            }
        }),
    );
};

export const getOwnersList = (owners: Owner[], valueAsObject: boolean, withoutOwnerDriver?: boolean) => {
    if (!owners) {
        return [];
    }

    if (withoutOwnerDriver) {
        return getOwnersWithoutDrivers(owners).map((owner) => {
            return {
                value: owner,
                label: getOwnerName(owner),
            };
        });
    }

    return owners.map((owner) => ({
        value: owner,
        label: getOwnerName(owner),
    }));
};

export const transformOwnersLabel = (owners: {label: string; value: Owner}[]) =>
    owners.map((owner) => {
        const label = `(ow) ${owner.label} ${phoneNumberWithBraces(getOwnerPhone(owner.value))}`;
        return {
            ...owner,
            label,
        };
    });
