import React from 'react';

type OwnProps = {
    data: {dispatcherId: number; sourceId: number; destinationId: number};
};

const UnsuccessfulMergedCustomers: React.FC<OwnProps> = (props) => {
    const {data} = props;

    const {sourceId, destinationId} = data;

    return (
        <div>
            The merging process of Customer <strong>ID#{sourceId}</strong> and <strong>ID#{destinationId}</strong> has
            failed to be completed. Please try again.
        </div>
    );
};

export default UnsuccessfulMergedCustomers;
