import React from 'react';

import {Quote} from 'core/entities/Quote/types';
import {getOriginAddress, getOriginStop} from 'core/entities/Quote/modules/quoteStop';

import {getTimeZoneAbbreviation} from 'utils/dateTime';
import {getCityLineFromObject} from 'utils/getCityLine';

import {getStopDate} from 'pages/LoadBoard/utils';

import Title from '../common/Title';

type OwnProps = {
    quote: Quote;
};
const Origin: React.FC<OwnProps> = (props) => {
    const {quote} = props;
    const address = getOriginAddress(quote);
    const quoteCityLine = getCityLineFromObject(address);
    const originStop = getOriginStop(quote);
    const timeZoneAbbreviation = getTimeZoneAbbreviation(originStop.timeZone);
    const originDate = getStopDate(originStop);
    return (
        <div className="mb-2">
            <Title>Origin</Title>
            <strong>{quoteCityLine}</strong>
            <p>
                {originDate} {timeZoneAbbreviation}
            </p>
        </div>
    );
};

export default Origin;
