import {handleError} from 'store/actions/common';

import actionCreators from './actionCreators';

export const fetchSupportData = (): any => async (dispatch, _, {api}) => {
    try {
        const {data} = await api.default.get('/email/support');

        dispatch(actionCreators.setSupportData(data));
    } catch (e) {
        dispatch(handleError(e));
    }
};
