import React from 'react';

import Truck from 'core/entities/Truck/types';

import ArchivingErrorWrapper from 'components/ui/modals/ArchivingErrorWrapper';
import {TruckLink} from 'components/ui/Links';

type OwnProps = {
    data: {truck: Truck};
    onClose();
};

const ArchivingError = (props: OwnProps): JSX.Element => {
    const {
        data: {truck},
        onClose,
    } = props;
    const truckNumber = `truck #${truck.number}`;
    return (
        <ArchivingErrorWrapper title={truckNumber} onClose={onClose}>
            <div>
                <p>
                    Owner`s{' '}
                    <strong>
                        <TruckLink truck={truck} content={`truck #${truck.number}`} className="second-link" />
                    </strong>{' '}
                    is on the route now. Removing owners of such trucks is not possible. Please, wait for the truck to
                    complete the travel order.
                </p>
            </div>
        </ArchivingErrorWrapper>
    );
};

export default ArchivingError;
