import {combineReducers} from 'redux';

import travelOrder from './travelOrder';
import modal from './modal';
import list from './list';

export default combineReducers({
    travelOrder,
    modal,
    list,
});
