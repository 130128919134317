import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import classNames from 'classnames';

import Button from 'components/ui/Buttons/Button';

import {TripMonitorColorSettings} from 'core/entities/Dispatcher/types/Preferences';

import type {Color} from './types';
import ColorItem from './components/ColorItem';

import styles from './styles.module.scss';

interface OwnProps {
    data: {
        colorSettings: TripMonitorColorSettings;
    };
    handlers: {onSaveSettings(changedSettings: TripMonitorColorSettings)};
    onClose(): void;
}

const ColorSettingsModal = (props: OwnProps): JSX.Element => {
    const {
        data: {colorSettings},
        handlers: {onSaveSettings},
        onClose,
    } = props;

    const [currentSettings, setCurrentSettings] = useState<TripMonitorColorSettings>(colorSettings);

    const handleChangeMediumDelayBackgroundColor = (color: Color) => {
        setCurrentSettings({...currentSettings, mediumDurationOnStopBgColor: color.hex});
    };
    const handleChangeMediumDelayBorderColor = (color: Color) => {
        setCurrentSettings({...currentSettings, mediumDurationOnStopBrColor: color.hex});
    };
    const handleChangeLongDelayBackgroundColor = (color: Color) => {
        setCurrentSettings({...currentSettings, longDurationOnStopBgColor: color.hex});
    };
    const handleChangeLongDelayBorderColor = (color: Color) => {
        setCurrentSettings({...currentSettings, longDurationOnStopBrColor: color.hex});
    };
    const handleChangeEtaBackgroundColor = (color: Color) => {
        setCurrentSettings({...currentSettings, etaBgColor: color.hex});
    };
    const handleChangeEtaColumnBorderColor = (color: Color) => {
        setCurrentSettings({...currentSettings, etaColumnBrColor: color.hex});
    };
    const handleChangeLastEventColumnBackgroundColor = (color: Color) => {
        setCurrentSettings({...currentSettings, progressInfoColumnBgColor: color.hex});
    };
    const handleChangeLastEventColumnBorderColor = (color: Color) => {
        setCurrentSettings({...currentSettings, progressInfoColumnBorderColor: color.hex});
    };
    const handleLateBadgeBackgroundColor = (color: Color) => {
        setCurrentSettings({...currentSettings, lateBadgeBgColor: color.hex});
    };
    const handleChangeLateBadgeTextColor = (color: Color) => {
        setCurrentSettings({...currentSettings, lateBadgeTextColor: color.hex});
    };
    const handleResetSettings = () => {
        setCurrentSettings(colorSettings);
    };
    const handleSaveSettings = () => {
        onSaveSettings(currentSettings);
    };
    return (
        <div className={classNames(styles.modal, 'modal-content', 'custom-dialogs')}>
            <Modal.Header>
                <Modal.Title>
                    <strong>
                        Color settings <i className="fa fa-paint-brush" />
                    </strong>
                </Modal.Title>
                <button onClick={onClose} type="button" className="close">
                    <i className="fa fa-times" aria-hidden="true" />
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.description}>You can configure the color scheme in the Trip Monitor.</div>
                <div className={styles.settings}>
                    <div className={styles.settings__row}>
                        <div className={styles.settings__title}>Column Travel Order</div>
                        <div className="row">
                            <div className="col-12">
                                <div className={styles.colors_group__title}>
                                    {'>'} <strong>45</strong>min
                                </div>
                                <div className={styles.colors_group__horizontal}>
                                    <ColorItem
                                        label="background color"
                                        color={currentSettings.mediumDurationOnStopBgColor}
                                        onSelectColor={handleChangeMediumDelayBackgroundColor}
                                    />

                                    <ColorItem
                                        label="border color"
                                        color={currentSettings.mediumDurationOnStopBrColor}
                                        onSelectColor={handleChangeMediumDelayBorderColor}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className={styles.colors_group__title}>
                                    {'>'} <strong>120</strong>min
                                </div>
                                <div className={styles.colors_group__horizontal}>
                                    <ColorItem
                                        label="background color"
                                        color={currentSettings.longDurationOnStopBgColor}
                                        onSelectColor={handleChangeLongDelayBackgroundColor}
                                    />

                                    <ColorItem
                                        label="border color"
                                        color={currentSettings.longDurationOnStopBrColor}
                                        onSelectColor={handleChangeLongDelayBorderColor}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.settings__row}>
                        <div className={styles.settings__title}>ETA</div>
                        <div className={styles.colors_group__horizontal}>
                            <ColorItem
                                label="badge ETA"
                                color={currentSettings.etaBgColor}
                                onSelectColor={handleChangeEtaBackgroundColor}
                            />

                            <ColorItem
                                label="border in the table"
                                color={currentSettings.etaColumnBrColor}
                                onSelectColor={handleChangeEtaColumnBorderColor}
                            />
                        </div>
                    </div>
                    <div className={styles.setting__row}>
                        <div className="row">
                            <div className="col-12">
                                <div className={styles.settings__title}>Progress Info</div>
                                <div className={styles.colors_group__horizontal}>
                                    <div className={styles.colors_group__vertical}>
                                        <ColorItem
                                            label="background color"
                                            color={currentSettings.progressInfoColumnBgColor}
                                            onSelectColor={handleChangeLastEventColumnBackgroundColor}
                                        />

                                        <ColorItem
                                            label="border color"
                                            color={currentSettings.progressInfoColumnBorderColor}
                                            onSelectColor={handleChangeLastEventColumnBorderColor}
                                        />
                                    </div>
                                    <div className={styles.colors_group__vertical}>
                                        <ColorItem
                                            label="badge (late) background"
                                            color={currentSettings.lateBadgeBgColor}
                                            onSelectColor={handleLateBadgeBackgroundColor}
                                        />

                                        <ColorItem
                                            label="badge (late) text"
                                            color={currentSettings.lateBadgeTextColor}
                                            onSelectColor={handleChangeLateBadgeTextColor}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button onClick={handleResetSettings} type="submit" colorTheme="grey">
                        Reset
                    </Button>
                    <Button onClick={handleSaveSettings} type="submit" colorTheme="blue">
                        Apply
                    </Button>
                </div>
            </Modal.Body>
        </div>
    );
};

export default ColorSettingsModal;
