import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import head from 'lodash/head';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import split from 'lodash/split';

import {UploadedLoadFiles, MetaData} from '../types';

const getBadgeText = (badge: string): string => {
    return head(split(badge, '#')) as string;
};
export const transformMetaDataToRequest = (files?: UploadedLoadFiles[]): {files: MetaData[]} => {
    const filesForApiFormat = files?.map((fileData) => {
        if (fileData.type === 'rc') {
            return fileData.items.map(({file, documentNumber, documentPage, badge, tmpID}) => {
                const badgeText = getBadgeText(badge);
                return {
                    attachType: 'rc',
                    badge: badgeText,
                    documentNumber,
                    documentPage,
                    fileName: file.name,
                    mimeType: file.type,
                    tmpId: tmpID,
                };
            });
        }
        if (fileData.type === 'bol') {
            return fileData.items.map(({file, badge, documentNumber, tmpID}) => {
                const badgeText = getBadgeText(badge);
                return {
                    attachType: 'bol',
                    badge: badgeText,
                    documentNumber,
                    fileName: file.name,
                    mimeType: file.type,
                    tmpId: tmpID,
                };
            });
        }
        if (fileData.type === 'pod') {
            return fileData.items.map(({file, badge, documentNumber, tmpID}) => {
                const badgeText = getBadgeText(badge);
                return {
                    attachType: 'pod',
                    badge: badgeText,
                    documentNumber,
                    fileName: file.name,
                    mimeType: file.type,
                    tmpId: tmpID,
                };
            });
        }
        if (fileData.type === 'extra') {
            return fileData.items.map(({file, badge, tmpID}) => {
                return {
                    attachType: 'extra',
                    badge,
                    fileName: file.name,
                    mimeType: file.type,
                    tmpId: tmpID,
                };
            });
        }
        if (fileData.type === 'files') {
            return fileData.items.map(({file, tmpID}) => {
                return {
                    attachType: 'files',
                    fileName: file.name,
                    mimeType: file.type,
                    tmpId: tmpID,
                };
            });
        }
        return [];
    });

    return {files: compact(flatten(filesForApiFormat))};
};
export const transformFilesDataToRequest = (
    files: UploadedLoadFiles[],
    filesIds: Record<number, string>[],
): FormData => {
    const formData = new FormData();

    const filesForApiFormat = files.map((fileData): {file: File; tmpID: string}[] => fileData?.items || []);

    const filesForApiFormatCompact = compact(flatten(filesForApiFormat));

    const attachmentsFileIds = mapKeys(filesIds, (value, key) => `attachments[${key}]`);

    mapValues(attachmentsFileIds, (value, key) => {
        const file = filesForApiFormatCompact.find((item) => item.tmpID === value)?.file as File;
        return formData.append(key, file);
    });

    return formData;
};
