import React, {useMemo, useEffect} from 'react';
import {useLocation} from 'react-router';
import {connect} from 'react-redux';

import {AppState} from 'store';
import {modalHandlers as allModalHandlers} from 'components/ui/ModalProvider/ModalHandlers';

import {commonModalNames} from 'components/ui/modals/modalMap';
import {locationEventsModalNames} from 'components/common/LocationEvents/modals/modalMap';

import {travelOrderModalNames} from 'pages/TravelOrders/components/common/modals/modalMap';

import {Modal} from './types/Modal';
import combinedModalMap from './combinedModalMap';
import * as actions from './actions';
import {getModals} from './selectors';

import styles from './styles.module.scss';

interface StateProps {
    modals: Modal[];
}

interface DispatchProps {
    closeModal(id: string);
    closeAllModals();
}

const fastModals = new Set([
    travelOrderModalNames.timerSettingsModal,
    travelOrderModalNames.colorSettingsModal,
    locationEventsModalNames.checkCall,
    locationEventsModalNames.banLocationEvent,
    locationEventsModalNames.brokerUpdateModal,
    locationEventsModalNames.viewBrokerUpdate,
    commonModalNames.addNotesModal,
    commonModalNames.generalNotes,
    commonModalNames.plannedTravelOrders,
]);

const getModalComponent = (modalName: string) => {
    return combinedModalMap[modalName];
};

const getModalHandlers = (modalID: string) => {
    return allModalHandlers.getById(modalID) || {};
};

const CustomModalWrapper = ({
    ModalComponent,
    modalID,
    modalData,
    modalHandlers,
    modalSelectors,
    onClose,
}: {
    ModalComponent: any;
    modalID: string;
    modalData: any;
    modalHandlers: any;
    modalSelectors: any;
    onClose(id: string);
}): JSX.Element | null => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = '15px';
        return function () {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '0px';
        };
    }, []);
    return (
        <>
            <div className="modal-backdrop show" />
            <div
                role="dialog"
                aria-modal="true"
                className={`${styles.animate} modal show d-block modal-overflow-y-auto`}
                tabIndex={-1}
            >
                <ModalComponent
                    key={modalID}
                    onClose={() => onClose(modalID)}
                    data={modalData}
                    handlers={modalHandlers}
                    selectors={modalSelectors}
                />
            </div>
        </>
    );
};

const GeneralModalWrapper = (props): JSX.Element => {
    const {ModalComponent, modalID, modalData, modalHandlers, modalSelectors, onClose} = props;
    return (
        <div className={styles['modal-container']}>
            <ModalComponent
                key={modalID}
                onClose={() => onClose(modalID)}
                data={modalData}
                handlers={modalHandlers}
                selectors={modalSelectors}
            />
        </div>
    );
};

const ModalProvider = (props: StateProps & DispatchProps): JSX.Element | null => {
    const {modals, closeModal, closeAllModals} = props;
    const location = useLocation();
    useEffect(() => {
        if (modals && modals.length > 0) {
            closeAllModals();
        }
    }, [location?.pathname]);
    const modalItems = useMemo(() => {
        if (!modals || modals.length === 0) {
            return null;
        }
        return modals.map((modal) => {
            const ModalComponent = getModalComponent(modal.modalName);
            const {handlers = {}, selectors = {}} = getModalHandlers(modal.id);

            if (!ModalComponent) {
                const error = `no such modalName - "${modal.modalName}",you must import modalNames into components/ModalProvider/combinedModalMap`;
                console.error(error);
                return null;
            }

            if (fastModals.has(modal.modalName)) {
                return (
                    <CustomModalWrapper
                        key={modal.id}
                        ModalComponent={ModalComponent}
                        modalID={modal.id}
                        modalData={modal.data}
                        modalHandlers={handlers}
                        modalSelectors={selectors}
                        onClose={closeModal}
                    />
                );
            }

            return (
                <GeneralModalWrapper
                    key={modal.id}
                    ModalComponent={ModalComponent}
                    modalID={modal.id}
                    modalData={modal.data}
                    modalHandlers={handlers}
                    modalSelectors={selectors}
                    onClose={closeModal}
                />
            );
        });
    }, [modals]);

    if (modals.length === 0) {
        return null;
    }

    return <div className={styles.modalContainer}>{modalItems}</div>;
};

// for tests
export {ModalProvider};

export default connect(
    (state: AppState): StateProps => ({
        modals: getModals(state),
    }),
    (dispatch): DispatchProps => ({
        closeModal: (id) => dispatch(actions.closeModal(id)),
        closeAllModals: () => dispatch(actions.closeAll()),
    }),
)(ModalProvider);
