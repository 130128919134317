import React from 'react';
import {Modal} from 'react-bootstrap';

import {DriverOffer, FormModeTypes, Quote} from 'core/entities/Quote/types';

import {DriverOfferFormValues} from 'pages/LoadBoard/types';
import {Dispatcher} from 'core/entities/Dispatcher/types';
import LoadInformation from './components/LoadInformation';
import Origin from './components/Origin';
import Destination from './components/Destination';
import QuoteDuration from './components/QuoteDuration';
import CreatedBy from './components/CreatedBy';
import Form from './components/Form';
import styles from './styles.module.scss';
import UpdatedBy from './components/UpdatedBy';
import Title from './components/common/Title';

type OwnProps = {
    data: {
        quote: Quote;
        offer: DriverOffer;
        mode: FormModeTypes;
        createdByDispatcher?: Dispatcher;
        updatedByDispatcher?: Dispatcher;
    };
    handlers: {
        onSubmit(data: DriverOfferFormValues): void;
    };
    onClose(): void;
};

const DriverOfferModal: React.FC<OwnProps> = (props) => {
    const {data, handlers, onClose} = props;
    const isUpdate = data.mode === 'UPDATE';
    const modalHeaderTitle = isUpdate ? 'Update Offer' : 'Add offer';
    const createdDriver = data.offer.createdByRole === 'DRIVER';
    return (
        <Modal
            show={true}
            dialogClassName="standard-form-modal"
            onHide={onClose}
            className="info-modal"
            backdrop="static"
        >
            <Modal.Header>
                <div className="modal-header__top">
                    <h4>
                        <strong>{modalHeaderTitle}</strong>
                    </h4>
                    <button className="close" onClick={onClose} type="button">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className={styles.body}>
                <div className={styles.wrapper}>
                    <div className="row">
                        <div className="col-12">
                            <div className="offer_id d-flex justify-content-between align-items-center mt-2 mb-2">
                                <h2>Offer # {data.quote.number}</h2>
                                <QuoteDuration quote={data.quote} offer={data.offer} mode={data.mode} />
                            </div>
                        </div>
                        <div className="col-6">
                            <LoadInformation quote={data.quote} />
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col-12">
                                    <Origin quote={data.quote} />
                                </div>
                                <div className="col-12">
                                    <Destination quote={data.quote} />
                                </div>
                            </div>
                        </div>
                        {data.createdByDispatcher && (
                            <>
                                <div className="col-6">
                                    <CreatedBy createdByDispatcher={data.createdByDispatcher} offer={data.offer} />
                                </div>
                            </>
                        )}
                        {createdDriver && (
                            <div className="col-6">
                                <Title>Created By</Title>
                                <p>Mobile App</p>
                            </div>
                        )}
                        {data.updatedByDispatcher && (
                            <>
                                <div className="col-6">
                                    <UpdatedBy updatedByDispatcher={data.updatedByDispatcher} offer={data.offer} />
                                </div>
                            </>
                        )}
                        <div className="col-12">
                            <Form
                                quote={data.quote}
                                initialValues={data.offer}
                                mode={data.mode}
                                onSubmit={handlers.onSubmit}
                                onClose={onClose}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default DriverOfferModal;
