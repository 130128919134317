import isNumber from 'lodash/isNumber';
import CurrencyMap, {CurrencyValue} from 'utils/data/currency';

export const getPaymentLabel = ({rpm, currency}: {rpm: number; currency: CurrencyValue}): string | undefined => {
    if (!isNumber(rpm) || !currency) {
        return;
    }

    return `${CurrencyMap[currency].mark} ${rpm} ${currency}`;
};
