import {TRAVEL_ORDER_STATUSES} from 'core/entities/TravelOrder/constants';
import TravelOrder from 'core/entities/TravelOrder/types';

export const isTravelOrderDispatchedNotConfirmed = (travelOrder?: TravelOrder | null): boolean =>
    travelOrder?.status === TRAVEL_ORDER_STATUSES.DISPATCHED_NOT_CONFIRMED;

export const isTravelOrderDryRunNotConfirmed = (travelOrder?: TravelOrder | null): boolean =>
    travelOrder?.status === TRAVEL_ORDER_STATUSES.DRY_RUN_NOT_CONFIRMED;

export const isTravelOrderNotDispatched = (travelOrder?: TravelOrder | null): boolean =>
    travelOrder?.status === TRAVEL_ORDER_STATUSES.NOT_DISPATCHED;

export const isTravelOrderOnRoute = (travelOrder?: TravelOrder | null): boolean =>
    travelOrder?.status === TRAVEL_ORDER_STATUSES.ON_ROUTE;

export const isTravelOrderFinished = (travelOrder?: TravelOrder | null): boolean =>
    travelOrder?.status === TRAVEL_ORDER_STATUSES.FINISHED;

export const isTravelOrderDryRun = (travelOrder?: TravelOrder | null): boolean =>
    travelOrder?.status === TRAVEL_ORDER_STATUSES.DRY_RUN;

export const isTravelOrderNoPay = (travelOrder?: TravelOrder | null): boolean =>
    travelOrder?.status === TRAVEL_ORDER_STATUSES.NO_PAY;

export const getTravelOrderStatuses = (travelOrder?: TravelOrder | null) => {
    const isTODispatchedNotConfirmed = isTravelOrderDispatchedNotConfirmed(travelOrder);
    const isTODryRunNotConfirmed = isTravelOrderDryRunNotConfirmed(travelOrder);
    const isTONotDispatched = isTravelOrderNotDispatched(travelOrder);
    const isTOOnRoute = isTravelOrderOnRoute(travelOrder);
    const isTOFinished = isTravelOrderFinished(travelOrder);
    const isTODryRun = isTravelOrderDryRun(travelOrder);
    const isTONoPay = isTravelOrderNoPay(travelOrder);

    return {
        isTODispatchedNotConfirmed,
        isTODryRunNotConfirmed,
        isTONotDispatched,
        isTOOnRoute,
        isTOFinished,
        isTODryRun,
        isTONoPay,
    };
};
