import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {initialize, InjectedFormProps} from 'redux-form';

import * as appActions from 'store/actions';

import useGetReduxFormValues from 'hooks/reduxForm/useGetReduxFormValues';

import {createCustomerAgent, updateCustomerAgent} from 'pages/Customers/redux/actions/agents';

import {ModalOwnProps, ManageAgentFormFields} from '../types';

type UseManageAgentReturnType = {
    isClearFieldsBtnDisabled: boolean;
    isSubmitBtnDisabled: boolean;
    submitBtnTitle: string;
    customerName: string;
    onClose: () => void;
    onSubmit: () => void;
    onClearFields: () => void;
};

const useManageAgent = (
    props: InjectedFormProps<ManageAgentFormFields, ModalOwnProps> & ModalOwnProps,
): UseManageAgentReturnType => {
    const {onClose, data, form, handleSubmit, valid, handlers} = props;
    const {customerName, customerId, modalType} = data;

    const dispatch = useDispatch();
    const formValues = useGetReduxFormValues<ManageAgentFormFields>(form);

    const handleSubmitForm = async (fields): Promise<void> => {
        let responseData;

        try {
            if (modalType === 'create') {
                const agent = await dispatch(createCustomerAgent({customerId, fields}));

                responseData = agent;
            } else if (modalType === 'update') {
                await dispatch(updateCustomerAgent({customerId, agentId: data.agent.id, fields}));

                responseData = {agentName: fields.agentName};
            }

            onClose();
            handlers.onSubmit(responseData);
        } catch (e) {
            dispatch(appActions.handleError(e));
        }
    };

    const handleClearFields = () => {
        dispatch(initialize(form, {agentName: '', phone: '', email: '', office: ''}));
    };

    useEffect(() => {
        if (modalType === 'update') {
            const {agentName, phone, office, email} = data.agent;

            dispatch(initialize(form, {agentName, phone, email, office}));
        }
    }, []);

    const fieldsEmpty = Object.values(formValues).filter(Boolean).length === 0;

    return {
        customerName,
        isClearFieldsBtnDisabled: fieldsEmpty,
        isSubmitBtnDisabled: !valid,
        submitBtnTitle: modalType === 'create' ? 'Create agent' : 'Save changes',
        onClose,
        onSubmit: handleSubmit(handleSubmitForm),
        onClearFields: handleClearFields,
    };
};

export default useManageAgent;
