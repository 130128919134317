import React, {useState, useEffect} from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import isEqual from 'lodash/isEqual';

import {SENDER_TYPE_BILLING} from 'core/entities/Sender/constants';
import {getDefaultEmail, getDefaultID, getSenderByID} from 'core/entities/Sender/modules/senderDefault';

import Button from 'components/ui/Buttons/Button';

import SendersSelectByType from 'components/common/Sender/SendersSelectByType';
import useFetchingSenders from 'components/common/Sender/SendersSelectByType/hooks/useFetchingSenders';

const SelectEmailSender = (props) => {
    const {
        input: {onChange, value},
    } = props;

    const {sendersByType} = useFetchingSenders(SENDER_TYPE_BILLING);

    const [showPopover, setShowPopover] = useState<boolean>(true);
    const [selectedSender, setSelectedSender] = useState<number>(value);

    const defaultEmail = getDefaultEmail(sendersByType);
    const defaultValue = getDefaultID(sendersByType);
    const currentSender = getSenderByID({senders: sendersByType, currentSenderID: value});
    const currentSenderEmail = currentSender?.email;

    const isPristine = isEqual(selectedSender, value);

    const handleOnchange = () => {
        onChange(selectedSender);
        setShowPopover(false);
    };

    useEffect(() => {
        onChange(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        if (!showPopover) {
            setShowPopover(true);
        }
    }, [showPopover]);

    const popoverNote = (
        <Popover id="add-note" className="popover__add-note">
            <Popover.Title as="h3">Email from Settings:</Popover.Title>
            <Popover.Content>
                <div className="standard-form-modal">
                    <div className="modal-body__main">
                        <label>FROM</label>
                        <SendersSelectByType
                            {...props}
                            placeholder="enter sender email"
                            isClearable={false}
                            senderType={SENDER_TYPE_BILLING}
                            input={{value: selectedSender, onChange: setSelectedSender}}
                        />
                    </div>
                    <div className="modal-body__bottom">
                        <Button onClick={handleOnchange} colorTheme="green" buttonSize="small" disabled={isPristine}>
                            Ok
                        </Button>
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    );
    return (
        <div className="data">
            <div className="d-flex align-items-center">
                <div>emails from: {currentSenderEmail || defaultEmail}</div>

                <div className="right-block">
                    {showPopover ? (
                        <OverlayTrigger rootClose={true} trigger="click" overlay={popoverNote} placement="left">
                            <Button buttonIcon="refresh" buttonSize="icon" className="btn-link" />
                        </OverlayTrigger>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default SelectEmailSender;
