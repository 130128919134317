import head from 'lodash/head';

import {fetchLoadFiles, fetchLoadNotes} from 'deprecated/core/entities/Load/requests';
import {fetchTravelOrderFiles, fetchTravelOrderNotes} from 'deprecated/core/gateways/TravelOrderApiGateway/requests';
import Load from 'deprecated/core/entities/Load/types';
import TravelOrder from 'deprecated/core/entities/TravelOrder/types';

import Invoice from 'core/entities/Invoice/types';
import {getInvoiceTravelOrder} from 'core/entities/Invoice/invoice';
import {fetchSettlementsOwnersList} from 'core/entities/Settlement/requests/settlementOwnerRequests';
import {fetchSettlementsCarriersList} from 'core/entities/Settlement/requests/settlementCarrierRequests';

interface LoadData {
    loadFiles: any[];
    loadNotes: any[];
}

interface TravelOrderData {
    settlement: any;
    travelOrderFiles: any;
    travelOrderNotes: any;
}

interface InvoiceData {
    loadFiles: any;
    loadNotes: any;
    travelOrderFiles: any;
    travelOrderNotes: any;
    settlement: any;
}

const fetchLoadData = async (load: Load | undefined | null): Promise<LoadData> => {
    if (!load) {
        return Promise.resolve({} as LoadData);
    }
    const [loadFiles, loadNotes] = await Promise.all([fetchLoadFiles(load.id), fetchLoadNotes(load.id)]);
    return {loadFiles, loadNotes};
};

const fetchTravelOrderData = async (
    travelOrder: TravelOrder | undefined | null,
    invoiceNumber: number,
): Promise<TravelOrderData> => {
    if (!travelOrder) {
        return Promise.resolve({} as TravelOrderData);
    }
    const settlementRequest = travelOrder.carrier
        ? fetchSettlementsCarriersList({invoice: invoiceNumber})
        : fetchSettlementsOwnersList({invoice: invoiceNumber});
    const [travelOrderFiles, travelOrderNotes, settlementsResponse] = await Promise.all([
        fetchTravelOrderFiles(travelOrder.id),
        fetchTravelOrderNotes(travelOrder.id),
        settlementRequest,
    ]);
    return {travelOrderFiles, travelOrderNotes, settlement: head(settlementsResponse.data)};
};

export default async function (invoice: Invoice): Promise<InvoiceData> {
    const {load} = invoice;
    const travelOrder = getInvoiceTravelOrder(invoice);
    const [loadData, travelOrderData] = await Promise.all([
        fetchLoadData(load),
        fetchTravelOrderData(travelOrder, invoice.invoice_number),
    ]);
    return {...loadData, ...travelOrderData};
}
