import {AxiosResponse} from 'axios';

import Pagination from 'types/Pagination';
import auctionApi from 'services/auctionApi/http';
import {Quote, DriverReceiverWithTruck, DriverOffer} from 'core/entities/Quote/types';
import {Dispatcher} from 'core/entities/Dispatcher/types';

import {QuotesSearchResponse, Notification, NotificationsResponse, StopsCoordinates} from './types';

const QUOTES_URL = `/quotes`;
const NOTIFICATIONS_URL = `/dispatcher-notifications`;
const ROUTE_URL = '/route';

const getPagination = (auctionApiPagination?: {
    page: number;
    perPage: number;
    pagesCount: number;
    allItemsCount: number;
}): Pagination => {
    return {
        currentPage: auctionApiPagination?.page || 0,
        perPage: auctionApiPagination?.perPage || 0,
        pagesCount: auctionApiPagination?.pagesCount || 0,
        totalItemsCount: auctionApiPagination?.allItemsCount || 0,
    };
};

export const searchQuotes = (params: {
    searchParams: any;
    paginationParams: {page: number; perPage: number};
    isArchived: boolean;
}): Promise<QuotesSearchResponse> => {
    const {searchParams, paginationParams, isArchived} = params;
    return auctionApi
        .post(
            `${QUOTES_URL}/search`,
            {...searchParams, isArchived},
            {
                params: paginationParams,
            },
        )
        .then((response) => {
            return {
                data: response.data?.items || [],
                pagination: getPagination(response.data.pagination),
            };
        })
        .catch((error: any) => {
            console.error('Error on search quotes:', error);
            return {
                data: [],
                pagination: getPagination(),
            };
        });
};

export const postQuote = (params: unknown): Promise<Quote> => {
    return auctionApi
        .post(QUOTES_URL, params)
        .then((response) => {
            return response.data;
        })
        .catch((error: any) => {
            console.error('Error on create quote: ', error);
        });
};

export const resendQuote = (quote: Quote): Promise<Quote> => {
    return new Promise((resolve, reject) => {
        const timeout = 3000;
        window.setTimeout(() => {
            auctionApi
                .put(`${QUOTES_URL}/${quote.id}/resend`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    console.error('Error on resend quote: ', error);
                    reject(error);
                });
        }, timeout);
    });
};

export const archiveQuote = (quote: Quote): Promise<AxiosResponse<Quote>> => {
    return new Promise((resolve, reject) => {
        const timeout = 3000;
        window.setTimeout(() => {
            auctionApi
                .put(`${QUOTES_URL}/${quote.id}/archive`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    console.error('Error on archive quote: ', error);
                    reject(error);
                });
        }, timeout);
    });
};

export const searchDuplicatesQuote = (params: unknown): Promise<Quote[]> => {
    return auctionApi
        .post(`${QUOTES_URL}/search/duplicated`, params)
        .then((response) => response?.data || [])
        .catch((error: any) => {
            console.error('Error on search quote duplicates: ', error);
            return [];
        });
};

export const fetchQuoteOffers = (quoteID: string): Promise<DriverOffer[]> => {
    return auctionApi
        .get(`${QUOTES_URL}/${quoteID}/drivers-offers`)
        .then((response) => response.data || [])
        .catch((error: any) => {
            console.error(`Error on get quote:${quoteID} offers: `, error);
            return [];
        });
};

export const postOfferByDispatcher = (offer: DriverOffer): Promise<DriverOffer> => {
    return auctionApi.post(`${QUOTES_URL}/${offer.quoteID}/dispatcher-offers`, offer).then((response) => {
        return response.data;
    });
};

export const updateOfferByDispatcher = (offer: DriverOffer): Promise<DriverOffer | void> => {
    return auctionApi.put(`${QUOTES_URL}/${offer.quoteID}/dispatcher-offers/${offer.id}`, offer).then((response) => {
        return response.data;
    });
};

export const readOfferByDispatcher = (offer: DriverOffer): Promise<{success: boolean}> => {
    return auctionApi.put(`${QUOTES_URL}/${offer.quoteID}/drivers-offers/${offer.id}/read`).then((response) => {
        return response.data;
    });
};

const updateOfferStatus = (
    offer: DriverOffer,
    newStatus: DriverOffer['status'],
): Promise<AxiosResponse<DriverOffer>> => {
    return auctionApi.put(`${QUOTES_URL}/${offer.quoteID}/drivers-offers/${offer.id}/status`, {status: newStatus});
};

export const approveOffer = (offer: DriverOffer): Promise<DriverOffer | void> => {
    return updateOfferStatus(offer, 'APPROVED').then((response) => {
        return response.data;
    });
};

export const unApproveOffer = (offer: DriverOffer): Promise<DriverOffer | void> => {
    return updateOfferStatus(offer, 'NOT_APPROVED').then((response) => {
        return response.data;
    });
};

export const cancelOfferStatus = (offer: DriverOffer): Promise<DriverOffer | void> => {
    return updateOfferStatus(offer, 'CREATED').then((response) => {
        return response.data;
    });
};

export const deactivateOffer = (offer: DriverOffer): Promise<DriverOffer | void> => {
    return updateOfferStatus(offer, 'DEACTIVATED').then((response) => {
        return response.data;
    });
};

export const fetchQuoteDriversReceivers = (quote: Quote): Promise<DriverReceiverWithTruck[]> => {
    return auctionApi
        .get(`${QUOTES_URL}/${quote.id}/drivers-receivers`)
        .then((response) => response?.data || [])
        .catch((error: any) => {
            console.error('Error on fetch quote drivers-receivers: ', error);
            return [];
        });
};

export const fetchQuoteNotes = (quote: Quote): Promise<any> => {
    return auctionApi
        .get(`${QUOTES_URL}/${quote.id}/notes`)
        .then((response) => response?.data || {})
        .catch((error: any) => {
            console.error('Error on fetch quote notes: ', error);
            return {};
        });
};

export const postQuoteGeneralNote = (quote: Quote, text: string, createdBy: Dispatcher): Promise<any> => {
    const createdByData = {
        id: createdBy.id,
        fake_full_name: createdBy.fake_full_name,
        full_name: createdBy.full_name,
    };
    return auctionApi
        .post(`${QUOTES_URL}/${quote.id}/general-notes`, {text, dispatcherID: createdBy.id, dispatcher: createdByData})
        .then((response) => response?.data || {})
        .catch((error: any) => {
            console.error('Error on post quote general note: ', error);
            return {};
        });
};

export const deleteQuoteGeneralNote = (quote: Quote, noteID: string): Promise<boolean> => {
    return auctionApi
        .delete(`${QUOTES_URL}/${quote.id}/general-notes/${noteID}`)
        .then((response) => Boolean(response?.data.success))
        .catch((error: any) => {
            console.error('Error on delete quote general note: ', error);
            return false;
        });
};

export const fetchDispatcherNotifications = (dispatcherID: number): Promise<NotificationsResponse> => {
    return auctionApi
        .get(NOTIFICATIONS_URL, {params: {dispatcherID}})
        .then((response) => {
            return {
                items: response.data?.items || [],
                counters: response.data?.counters || {unread: 0},
                pagination: getPagination(response.data.pagination),
            };
        })
        .catch((error: any) => {
            console.error('Error on fetch load board dispatcher notifications: ', error);
            return {
                items: [],
                counters: {unread: 0},
                pagination: getPagination(),
            };
        });
};

export const readDispatcherNotification = (notification: Notification): Promise<Notification | void> => {
    const {id: notificationID} = notification;
    return auctionApi
        .put(`${NOTIFICATIONS_URL}/${notificationID}/read`)
        .then((response) => {
            return response.data as Notification;
        })
        .catch((error: any) => {
            console.error('Error on read load board dispatcher notification: ', error);
        });
};

export const deleteDispatcherNotification = (notification: Notification): Promise<boolean> => {
    const {id: notificationID} = notification;
    return auctionApi
        .delete(`${NOTIFICATIONS_URL}/${notificationID}`)
        .then((response) => {
            return Boolean(response?.data.success);
        })
        .catch((error: any) => {
            console.error('Error on delete load board dispatcher notification: ', error);
            return false;
        });
};

export const deleteAllDispatcherNotifications = (dispatcherID: number): Promise<boolean> => {
    return auctionApi
        .delete(NOTIFICATIONS_URL, {params: {dispatcherID}})
        .then((response) => {
            return Boolean(response?.data.success);
        })
        .catch((error: any) => {
            console.error('Error on delete load board all dispatcher notification: ', error);
            return false;
        });
};

export const getDistanceOfCoordinates = (params: StopsCoordinates): Promise<number> => {
    return auctionApi
        .post(`${ROUTE_URL}/calculate-distance`, params)
        .then((response) => {
            return response.data.distance;
        })
        .catch((error: any) => {
            console.error('Error on get route distance: ', error);
            return false;
        });
};
