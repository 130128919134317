import last from 'lodash/last';
import sortBy from 'lodash/sortBy';

import * as appActions from 'store/actions';

import {closeAll, openModal} from 'components/ui/ModalProvider/actions';

import {
    getSettlementCarrier,
    getSettlementPayPeriod,
    isSettlementBatchOpened,
    isSettlementBatchClosed,
} from 'core/entities/Settlement';
import {
    fetchCarrierBatches,
    fetchBatchesForSetupForm,
} from 'core/entities/Settlement/requests/settlementCarrierRequests';
import {
    fetchSettlementsOwnerPayPeriod,
    fetchPayPeriods,
    fetchPayPeriodsSettings,
} from 'core/entities/Settlement/requests/settlementOwnerRequests';
import {SETTLEMENTS_CARRIERS_TYPE} from 'core/entities/Settlement/constants';

import {
    addSettlementDeduction,
    addSettlementReimbursement,
    deleteSettlementDeduction,
    deleteSettlementReimbursement,
} from 'pages/Settlements/actions/commonActions';
import {
    setupBatch,
    closeBatch,
    postBatch,
    reOpenBatch,
    updateBatchEmailInfo,
} from 'pages/Settlements/actions/batchActions';
import {
    updatePayPeriod,
    createPayPeriod,
    closePayPeriod,
    reOpenPayPeriod,
    addPayCorrection,
    deletePayCorrection,
} from 'pages/Settlements/actions/periodActions';
import {
    approveSettlementCarrier,
    resetSettlementFromView,
    resetSettlementFromList,
} from 'pages/Settlements/actions/settlementCarrierActions';
import {
    approveSettlementOwner,
    postListSettlementsToOpenedPeriod,
    postSettlementToOpenedPeriod,
    postSettlementToClosedPayPeriod,
    resetSettlementOwner,
} from 'pages/Settlements/actions/settlementOwnerActions';

import * as settlementsSelectors from 'pages/Settlements/selectors';
import {settlementsModalNames} from 'pages/Settlements/components/common/modals/modalMap';

import {clearLastCommand} from './listActions';

const getApproveSettlementAction = (settlement, state) => {
    const settlementType = settlementsSelectors.getSettlementsType(state);
    return settlementType === SETTLEMENTS_CARRIERS_TYPE
        ? approveSettlementCarrier(settlement)
        : approveSettlementOwner(settlement);
};

const getResetSettlementAction = (settlement, state) => {
    const settlementType = settlementsSelectors.getSettlementsType(state);
    const isListPage = settlementsSelectors.isListPage(state);
    const resetSettlementCarrier = isListPage
        ? resetSettlementFromList(settlement)
        : resetSettlementFromView(settlement);
    return settlementType === SETTLEMENTS_CARRIERS_TYPE ? resetSettlementCarrier : resetSettlementOwner(settlement);
};

const fetchAllPeyPeriodsForSetupPostSettlements = () => {
    return fetchPayPeriodsSettings()
        .then((response) => response.data)
        .catch((error) => {
            console.warn('Error on fetch data for setup pay periods: ', error);
            return [];
        });
};

const fetchPayPeriodForSetupPostSettlements = (payPeriodID) => {
    return fetchSettlementsOwnerPayPeriod(payPeriodID)
        .then((response) => response.data)
        .catch((error) => {
            console.warn('Error on fetch data for setup pay periods: ', error);
            return [];
        });
};

const fetchCompaniesForSetupPostSettlements = () => {
    return fetchBatchesForSetupForm()
        .then((response) => response.data)
        .catch((error) => {
            console.warn('Error on fetch data for setup batch: ', error);
            return [];
        });
};

const getPostSettlementAction = (settlement, state) => {
    const openPostSettlementCarrierModal = () => {
        return function (dispatch) {
            dispatch(appActions.showLoader());
            const carrier = getSettlementCarrier(settlement);
            fetchCarrierBatches(carrier.id)
                .then(({data: carrierBatches}) => {
                    const openedBatch = carrierBatches.find(isSettlementBatchOpened);
                    const closedBatches = carrierBatches.filter(isSettlementBatchClosed);
                    const openPostSettlementModalAction = openModal({
                        modalName: settlementsModalNames.postSettlementCarrierModal,
                        handlers: {
                            onSubmit: (data) => {
                                dispatch(postBatch(data));
                                dispatch(closeAll());
                            },
                        },
                        data: {settlement, openedBatch, closedBatches},
                    });
                    dispatch(openPostSettlementModalAction);
                })
                .finally(() => dispatch(appActions.hideLoader()));
        };
    };
    const openPostSettlementOwnerModal = () => {
        return function (dispatch) {
            const payPeriod = getSettlementPayPeriod(settlement);
            if (!payPeriod) {
                return;
            }
            dispatch(appActions.showLoader());
            fetchSettlementsOwnerPayPeriod(payPeriod.id)
                .then(({data: fetchedPayPeriod}) => {
                    const openCloseBatchModalAction = openModal({
                        modalName: settlementsModalNames.postSettlementOwnerModal,
                        handlers: {
                            onPostToOpenedPeriod: () => {
                                dispatch(postSettlementToOpenedPeriod(settlement, payPeriod));
                                dispatch(closeAll());
                            },
                            onPostToClosedPeriod: () => {
                                dispatch(postSettlementToClosedPayPeriod(settlement, payPeriod));
                                dispatch(closeAll());
                            },
                        },
                        data: {settlement, payPeriod: fetchedPayPeriod},
                    });
                    dispatch(openCloseBatchModalAction);
                })
                .catch((error) => console.warn('Error on fetch pay period before post settlement: ', error))
                .finally(() => dispatch(appActions.hideLoader()));
        };
    };
    const settlementType = settlementsSelectors.getSettlementsType(state);
    return settlementType === SETTLEMENTS_CARRIERS_TYPE
        ? openPostSettlementCarrierModal(settlement)
        : openPostSettlementOwnerModal(settlement);
};

export function openApproveSettlementModal(settlement) {
    return function (dispatch, getState) {
        const approveSettlementAction = getApproveSettlementAction(settlement, getState());
        const openApproveInvoiceModalAction = openModal({
            modalName: settlementsModalNames.approveSettlement,
            handlers: {
                onApproveSettlement: () => {
                    dispatch(approveSettlementAction);
                    dispatch(closeAll());
                },
            },
            data: {settlement},
        });
        dispatch(openApproveInvoiceModalAction);
    };
}

export function openResetSettlementStatusModal(settlement) {
    return function (dispatch, getState) {
        const resetSettlementAction = getResetSettlementAction(settlement, getState());
        const openApproveInvoiceModalAction = openModal({
            modalName: settlementsModalNames.resetSettlementStatus,
            handlers: {
                onReset: () => {
                    dispatch(resetSettlementAction);
                    dispatch(closeAll());
                },
            },
            data: {settlement},
        });
        dispatch(openApproveInvoiceModalAction);
    };
}

export function openPostSettlementModal(settlement) {
    return function (dispatch, getState) {
        const postAction = getPostSettlementAction(settlement, getState());
        dispatch(postAction);
    };
}

export function openSetupBatchModal() {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        const companiesSettings = await fetchCompaniesForSetupPostSettlements();

        dispatch(appActions.hideLoader());

        const openSetupBatchModalAction = openModal({
            modalName: settlementsModalNames.setupBatchModal,
            handlers: {
                onSubmitBatch: (submitData) => {
                    dispatch(setupBatch(submitData));
                    dispatch(closeAll());
                },
            },
            data: {companies: companiesSettings},
        });

        dispatch(openSetupBatchModalAction);
    };
}

export function openCreatePayPeriodModal() {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        fetchPayPeriods()
            .then(({data: allPayPeriods}) => {
                const lastPayPeriod = last(sortBy(allPayPeriods, ['id']));
                const openSetupPayPeriodModalAction = openModal({
                    modalName: settlementsModalNames.createPayPeriodModal,
                    handlers: {
                        onSubmit: (submitData) => {
                            dispatch(createPayPeriod(submitData));
                            dispatch(closeAll());
                        },
                    },
                    data: {lastPayPeriod},
                });
                dispatch(openSetupPayPeriodModalAction);
            })
            .catch((error) => console.warn('Error on fetch all pay periods before create new: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function openSetupPayPeriodModal() {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        const payPeriodsSettings = await fetchAllPeyPeriodsForSetupPostSettlements();

        dispatch(appActions.hideLoader());

        const openSetupPayPeriodModalAction = openModal({
            modalName: settlementsModalNames.setupPayPeriodModal,
            handlers: {
                onSubmitPayPeriod: (data) => {
                    dispatch(postListSettlementsToOpenedPeriod(data));
                    dispatch(closeAll());
                },
            },
            data: {payPeriods: payPeriodsSettings},
        });

        dispatch(openSetupPayPeriodModalAction);
    };
}

export function openSendBatchEmailModal(batch) {
    return function (dispatch) {
        const getReOpenBatchModalAction = () => {
            return openModal({
                modalName: settlementsModalNames.reOpenBatchModal,
                handlers: {
                    onReopen: () => {
                        dispatch(reOpenBatch(batch));
                        dispatch(closeAll());
                    },
                },
                data: {batch},
            });
        };
        const openEditBatchModalAction = openModal({
            modalName: settlementsModalNames.editBatchModal,
            handlers: {
                onReopen: () => {
                    dispatch(closeAll());
                    dispatch(getReOpenBatchModalAction());
                },
                onSubmit: (data) => {
                    dispatch(updateBatchEmailInfo(batch, data));
                    dispatch(closeAll());
                },
            },
            data: {batch},
        });

        dispatch(openEditBatchModalAction);
    };
}

export function openCloseBatchModal(batch) {
    return function (dispatch) {
        const openCloseBatchModalAction = openModal({
            modalName: settlementsModalNames.editBatchModal,
            handlers: {
                onSubmit: (batchSettlements) => {
                    dispatch(closeBatch(batch, batchSettlements));
                    dispatch(closeAll());
                },
            },
            data: {batch, isClosing: true},
        });
        dispatch(openCloseBatchModalAction);
    };
}

export function openClosePayPeriodModal(payPeriodID) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        const payPeriod = await fetchPayPeriodForSetupPostSettlements(payPeriodID);

        dispatch(appActions.hideLoader());
        const openSetupBatchModalAction = openModal({
            modalName: settlementsModalNames.editPayPeriodModal,
            handlers: {
                // IMPORTANT I specifically use periodFromForm instead param payPeriod for avoid
                // bug with no consistent data after period update like as example after reset some settlement
                // this reset settlement will be removed from periodFromFor cause inside form i am using selector for it
                onSubmit: (periodFromForm) => {
                    dispatch(closePayPeriod(periodFromForm));
                    dispatch(closeAll());
                },
            },
            data: {payPeriod, isClosing: true},
        });
        dispatch(openSetupBatchModalAction);
    };
}

export function openSendPayPeriodEmailsModal(payPeriodID) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        const payPeriod = await fetchPayPeriodForSetupPostSettlements(payPeriodID);

        dispatch(appActions.hideLoader());
        const getReOpenPayPeriodModalAction = () => {
            return openModal({
                modalName: settlementsModalNames.reOpenPayPeriodModal,
                handlers: {
                    onReopen: () => {
                        dispatch(reOpenPayPeriod(payPeriod));
                        dispatch(closeAll());
                    },
                },
                data: {payPeriod},
            });
        };
        const openEditPayPeriodModalAction = openModal({
            modalName: settlementsModalNames.editPayPeriodModal,
            handlers: {
                onSubmit: (updatedData) => {
                    dispatch(updatePayPeriod(payPeriod, updatedData));
                    dispatch(closeAll());
                },
                onReopen: () => {
                    dispatch(closeAll());
                    dispatch(getReOpenPayPeriodModalAction());
                },
            },
            data: {payPeriod},
        });

        dispatch(openEditPayPeriodModalAction);
    };
}

export function openConfirmDeleteSettlementDeductionModal(deduction) {
    return function (dispatch) {
        const action = openModal({
            modalName: settlementsModalNames.deleteSettlementDeductionModal,
            handlers: {
                onDelete: () => {
                    dispatch(deleteSettlementDeduction(deduction));
                    dispatch(closeAll());
                },
            },
            data: {deduction},
        });
        dispatch(action);
    };
}

export function openDeductionPaymentModal(settlement, deduction) {
    return function (dispatch) {
        const openDeductionPaymentModalAction = openModal({
            modalName: settlementsModalNames.settlementDeductionPaymentModal,
            handlers: {
                onSubmit: (newDeduction) => {
                    dispatch(addSettlementDeduction(newDeduction));
                    dispatch(closeAll());
                },
                onDelete: () => {
                    dispatch(closeAll());
                    dispatch(openConfirmDeleteSettlementDeductionModal(deduction));
                },
            },
            data: {settlement, deduction},
        });
        dispatch(openDeductionPaymentModalAction);
    };
}

export function openConfirmDeleteSettlementReimbursementModal(reimbursement) {
    return function (dispatch) {
        const action = openModal({
            modalName: settlementsModalNames.confirmDeleteReimbursementModal,
            handlers: {
                onDelete: () => {
                    dispatch(closeAll());
                    dispatch(deleteSettlementReimbursement(reimbursement));
                },
            },
            data: {reimbursement},
        });
        dispatch(action);
    };
}

export function openReimbursementPaymentModal(settlement, reimbursement) {
    return function (dispatch) {
        const openReimbursementPaymentModalAction = openModal({
            modalName: settlementsModalNames.reimbursementPaymentModal,
            handlers: {
                onSubmit: (newReimbursement) => {
                    dispatch(addSettlementReimbursement(newReimbursement));
                    dispatch(closeAll());
                },
                onDelete: () => {
                    dispatch(closeAll());
                    dispatch(openConfirmDeleteSettlementReimbursementModal(reimbursement));
                },
            },
            data: {settlement, reimbursement},
        });
        dispatch(openReimbursementPaymentModalAction);
    };
}

export function openPeriodModal(payPeriod) {
    return function (dispatch) {
        const openPeriodModalAction = openModal({
            modalName: settlementsModalNames.warningOpenPeriodModal,
            handlers: {
                onSubmit: () => {
                    dispatch(closeAll());
                },
            },
            data: {payPeriod},
        });
        dispatch(openPeriodModalAction);
    };
}

export function openChangeStatusFailedModal(message) {
    return function (dispatch) {
        const openChangeStatusFailedAction = openModal({
            modalName: settlementsModalNames.errorChangeStatus,
            data: {message},
        });
        dispatch(openChangeStatusFailedAction);
    };
}

export function openAddPayCorrection(payRecord, payCorrectionType) {
    return function (dispatch) {
        dispatch(
            openModal({
                modalName: settlementsModalNames.payRecordDeductionPaymentModal,
                data: {payRecord, payCorrectionType},
                handlers: {
                    onSubmit: (payCorrection) => {
                        dispatch(addPayCorrection(payRecord, payCorrection));
                        dispatch(closeAll());
                    },
                },
            }),
        );
    };
}

export function openDeletePayCorrectionModal(payRecord, payCorrection) {
    return function (dispatch) {
        dispatch(
            openModal({
                modalName: settlementsModalNames.deletePayCorrectionModal,
                data: {payCorrection},
                handlers: {
                    onDelete: () => {
                        dispatch(deletePayCorrection(payRecord, payCorrection));
                        dispatch(closeAll());
                    },
                },
            }),
        );
    };
}

export function openWebsocketUpdateBatchCreateModal(modalData) {
    return function (dispatch) {
        if (!modalData) {
            return;
        }
        const openModalAction = openModal({
            modalName: settlementsModalNames.websocketUpdateModal,
            data: {
                title: 'Batch Create',
                body: `Batch #${modalData.batch_id} created. Please reload this page.`,
            },
            handlers: {
                onOk: () => {
                    dispatch(clearLastCommand());
                },
            },
        });
        dispatch(openModalAction);
    };
}

export function openWebsocketUpdateBatchAddSettlementsToBatch(modalData) {
    return function (dispatch) {
        if (!modalData) {
            return;
        }
        const openModalAction = openModal({
            modalName: settlementsModalNames.websocketUpdateModal,
            data: {
                title: 'Add Settlements to Batch',
                body: `${modalData.settlements_ids.length} Settlement(s)  added to Batch #${modalData.batch_id}. Please reload this page.`,
            },
            handlers: {
                onSubmit: () => {
                    dispatch(clearLastCommand());
                },
            },
        });
        dispatch(openModalAction);
    };
}

export function openWebsocketUpdateBatchResetSettlementsFromBatch(modalData) {
    return function (dispatch) {
        if (!modalData) {
            return;
        }
        const openModalAction = openModal({
            modalName: settlementsModalNames.websocketUpdateModal,
            data: {
                title: 'Reset Settlement',
                body: `Settlement #${modalData.settlement_number} resetted from Batch #${modalData.batch_id}. Please reload this page.`,
            },
            handlers: {
                onSubmit: () => {
                    dispatch(clearLastCommand());
                },
            },
        });
        dispatch(openModalAction);
    };
}

export function openWebsocketUpdateBatchCloseBatch(modalData) {
    return function (dispatch) {
        if (!modalData) {
            return;
        }
        const openModalAction = openModal({
            modalName: settlementsModalNames.websocketUpdateModal,
            data: {
                title: 'Close Batch',
                body: `Batch #${modalData.batch_id} closed. Please reload this page.`,
            },
            handlers: {
                onSubmit: () => {
                    dispatch(clearLastCommand());
                },
            },
        });
        dispatch(openModalAction);
    };
}

export function openWebsocketUpdateBatchReOpenBatch(modalData) {
    return function (dispatch) {
        if (!modalData) {
            return;
        }
        const openModalAction = openModal({
            modalName: settlementsModalNames.websocketUpdateModal,
            data: {
                title: 'Re-open Batch',
                body: `Batch #${modalData.batch_id} re-opened. Please reload this page.`,
            },
            handlers: {
                onSubmit: () => {
                    dispatch(clearLastCommand());
                },
            },
        });
        dispatch(openModalAction);
    };
}

export function openWebsocketUpdateBatchSendEmailForBatch(modalData) {
    return function (dispatch) {
        if (!modalData) {
            return;
        }
        const openModalAction = openModal({
            modalName: settlementsModalNames.websocketUpdateModal,
            data: {
                title: 'Sent email',
                body: `Batch #${modalData.batch_id} email was sent. Please reload this page.`,
            },
            handlers: {
                onSubmit: () => {
                    dispatch(clearLastCommand());
                },
            },
        });
        dispatch(openModalAction);
    };
}
