import {
    AddCheckCallRequestBody,
    GetBrokerUpdateDataFromRequest,
    GetCheckCallEventDataFromRequest,
    LocationEvent,
    BrokerUpdateRequestBody,
    GetConfirmEventDataFromRequest,
    GetConfirmEventDataToRequest,
    AddCheckCallResponse,
    AddBrokerUpdateResponse,
} from 'core/entities/LocationEvents/types';

import {
    addCheckCallEventRequest,
    fetchAllLocationEventsForLoad,
    fetchAllLocationEventsForTravelOrder,
    getBrokerUpdateRequest,
    sendBrokerUpdateRequest,
    banLocationEventRequest,
    unBanLocationEventRequest,
    getCheckCallEventDataRequest,
    getConfirmEventDataRequest,
    updateLocationEventNoteRequest,
} from './requests';

const locationEventApiGateway = {
    addCheckCallEvent: ({
        travelOrderNumber,
        postData,
    }: {
        travelOrderNumber: number;
        postData: AddCheckCallRequestBody;
    }): Promise<AddCheckCallResponse[]> => {
        return addCheckCallEventRequest(travelOrderNumber, postData).then((response) => response.data);
    },
    fetchAllLocationEventsForLoad: (loadNumber: number): Promise<LocationEvent[]> => {
        return fetchAllLocationEventsForLoad(loadNumber).then((res) => res.data);
    },

    fetchAllLocationEventsForTravelOrder: (travelOrderNumber: number): Promise<LocationEvent[]> => {
        return fetchAllLocationEventsForTravelOrder(travelOrderNumber).then((res) => res.data);
    },

    banLocationEvent: (eventID: string, travelOrderNumber: number): Promise<any> => {
        return banLocationEventRequest(eventID, travelOrderNumber);
    },

    unBanLocationEvent: (eventID: string, travelOrderNumber: number): Promise<any> => {
        return unBanLocationEventRequest(eventID, travelOrderNumber);
    },

    updateLocationEventNote: (eventID: string, travelOrderNumber: number, note: string): Promise<any> => {
        return updateLocationEventNoteRequest(eventID, travelOrderNumber, note);
    },

    getBrokerUpdate: (eventID: string, travelOrderNumber: number): Promise<GetBrokerUpdateDataFromRequest> => {
        return getBrokerUpdateRequest(travelOrderNumber, eventID).then((res) => res.data);
    },

    sendBrokerUpdate: (
        eventID: string,
        travelOrderNumber: number,
        sendBrokerUpdateData: BrokerUpdateRequestBody,
    ): Promise<AddBrokerUpdateResponse> => {
        return sendBrokerUpdateRequest(eventID, travelOrderNumber, sendBrokerUpdateData).then(
            (response) => response.data,
        );
    },

    getCheckCallEventData: (travelOrderNumber: number): Promise<GetCheckCallEventDataFromRequest> => {
        return getCheckCallEventDataRequest(travelOrderNumber).then((res) => res.data);
    },
    getConfirmEventData: ({
        travelOrderNumber,
        postData,
    }: GetConfirmEventDataToRequest): Promise<GetConfirmEventDataFromRequest> => {
        return getConfirmEventDataRequest({travelOrderNumber, postData}).then((res) => res.data);
    },
};

export default locationEventApiGateway;
