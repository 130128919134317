import omit from 'lodash/omit';

import {SearchParamsForBatchGeneration} from 'core/entities/Invoice/types/SearchParams';

import {SearchFormParams} from '../types';

export const isAllFetched = (
    pagination: {pagesCount: number; currentPage: number},
    responseItems: any[],
    perPage: number,
): boolean => {
    const isNoMoreData = responseItems.length < perPage;
    const isLastPage = pagination.pagesCount === pagination.currentPage;
    return isNoMoreData || isLastPage;
};

export const transformSearchParamsForRequest = (searchParams: SearchFormParams): SearchParamsForBatchGeneration => {
    const loadType = searchParams?.loadType;
    const transformSearchParams = omit({...searchParams, loadType}, ['status', 'billingMethod']);
    return transformSearchParams;
};
