import React from 'react';

import {PayPeriod} from 'core/entities/Settlement/types';
import {getPayPeriodDateEnd} from 'core/entities/Settlement/settlementPayPeriod';

type OwnProps = {
    period: PayPeriod;
    withStatus?: boolean;
};

const getPeriodStatusTitle = (period: PayPeriod): string => {
    return period.is_opened ? '(open).' : '(closed).';
};

const PayPeriodTitle: React.FC<OwnProps> = ({period, withStatus = true}) => {
    if (!period) {
        return null;
    }
    const periodDateEnd = getPayPeriodDateEnd(period);
    return (
        <span>
            Pay Period{' '}
            <strong>
                #{period.number} - {periodDateEnd} {withStatus && getPeriodStatusTitle(period)}
            </strong>
        </span>
    );
};

export default PayPeriodTitle;
