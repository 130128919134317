import {ReDispatchesItem} from 'core/entities/TravelOrder/types/ReDispatch';
import TravelOrder from 'core/entities/TravelOrder/types';

export const isTravelOrderDispatched = (travelOrder?: TravelOrder | null): boolean =>
    Boolean(travelOrder?.isDispatched);

export const isTravelOrderNeedConfirmReDispatch = (travelOrder?: TravelOrder | null): boolean =>
    Boolean(travelOrder?.needConfirmRedispatch);

export const isTravelOrderNeedReDispatch = (travelOrder?: TravelOrder | null): boolean =>
    Boolean(travelOrder?.needRedispatch);

export const getReDispatchesFromTheTravelOrder = (travelOrder?: TravelOrder | null): ReDispatchesItem[] =>
    travelOrder?.reDispatches || [];
