import * as actionTypes from './actionTypes';

export function updateSettlement(data) {
    return {
        type: actionTypes.WEB_SOCKET_SETTLEMENTS_CARRIERS_SETTLEMENT_UPDATED,
        payload: data,
    };
}

export function createBatch(data) {
    return {
        type: actionTypes.WEB_SOCKET_SETTLEMENTS_CARRIERS_BATCH_CREATED,
        payload: data,
    };
}

export function addSettlementToBatch(data) {
    return {
        type: actionTypes.WEB_SOCKET_ADD_SETTLEMENTS_TO_CARRIERS_BATCH,
        payload: data,
    };
}

export function resetSettlementFromBatch(data) {
    return {
        type: actionTypes.WEB_SOCKET_RESET_SETTLEMENTS_FROM_CARRIERS_BATCH,
        payload: data,
    };
}

export function closeBatch(data) {
    return {
        type: actionTypes.WEB_SOCKET_CLOSE_CARRIERS_BATCH,
        payload: data,
    };
}

export function reOpenBatch(data) {
    return {
        type: actionTypes.WEB_SOCKET_REOPEN_CARRIERS_BATCH,
        payload: data,
    };
}

export function sendEmailForBatch(data) {
    return {
        type: actionTypes.WEB_SOCKET_SEND_EMAIL_FOR_CARRIERS_BATCH,
        payload: data,
    };
}
