export default (dispatcher, isCanBeFromMobile?: boolean): string => {
    if (!dispatcher) {
        return isCanBeFromMobile ? 'mobile store' : '';
    }

    const {fake_full_name = '', full_name = ''} = dispatcher;

    if (!fake_full_name) {
        return full_name;
    }

    return `${fake_full_name}`.trim();
};
