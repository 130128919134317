export const LOAD_INIT_DATA_FETCHED = 'LOAD_COMPONENT_INIT_DATA_FETCHED';
export const LOAD_INIT_DATA_TRAVEL_ORDER_FETCHED = 'LOAD_INIT_DATA_TRAVEL_ORDER_FETCHED';
export const LOAD_GENERAL_NOTE_RECEIVED = 'LOAD_GENERAL_NOTE_RECEIVED';
export const CHANGE_FORM_STEP = 'LOAD_COMPONENT_CHANGE_FORM_STEP';
export const LOAD_CREATED = 'LOAD_COMPONENT_LOAD_CREATED';
export const LOAD_ADDED_NOTE_TO_TRAVEL_ORDER = 'LOAD_COMPONENT_ADDED_NOTE_TO_TRAVEL_ORDER';
export const LOAD_STATE_CLEAR = 'LOADS_COMPONENT_CURRENT_LOAD_STATE_CLEAR';
export const CURRENT_LOAD_START_LOADING = 'CURRENT_LOAD_START_LOADING';
export const CURRENT_LOAD_STOP_LOADING = 'CURRENT_LOAD_STOP_LOADING';
export const CREATE_LOAD_COPY = 'LOAD_COMPONENT_CREATE_LOAD_COPY';
export const CLEAR_LOAD_COPY = 'LOAD_COMPONENT_CLEAR_LOAD_COPY';
export const TOGGLE_EXPAND_PICK_UP_DELIVERY_ITEM = 'LOAD_COMPONENT_TOGGLE_EXPAND_PICK_UP_DELIVERY_ITEM';
export const EXPAND_PICK_UP_DELIVERY_ITEMS = 'LOAD_COMPONENT_EXPAND_PICK_UP_DELIVERY_ITEMS';
export const TOGGLE_EXPAND_PICK_UP_DELIVERY_ITEMS_ALL = 'LOAD_COMPONENT_TOGGLE_EXPAND_PICK_UP_DELIVERY_ITEMS_ALL';
