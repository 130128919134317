import {isEmpty} from 'lodash';

import Driver from 'core/entities/Driver/types';
import Owner from 'core/entities/Owner/types';
import Truck from 'core/entities/Truck/types';

import {TruckMember, TruckMembers} from 'core/entities/Truck/types/Team';
import {TRUCK_TEAM_TYPES} from 'core/entities/Truck/constants/truckTeamTypes';

import {getTruckTeam} from '../truckTeam';

const filterTruckMembers = (arr: TruckMembers): TruckMembers => arr.filter((data) => data?.entity);

export const isMemberDriver = (member: TruckMember['entity']): member is Driver => {
    if (!member || isEmpty(member)) {
        return false;
    }

    if ('is_owner' in member) {
        return !member.is_owner;
    }

    return false;
};

export const isMemberOwner = (member: TruckMember['entity']): member is Owner => !isEmpty(member);

export const isMemberOwnerCoordinator = (member: TruckMember['entity']): member is Owner => {
    if (!member || isEmpty(member)) {
        return false;
    }

    if ('owner_with_coordinator' in member) {
        return Boolean(member.owner_with_coordinator);
    }

    return false;
};

export const getTruckTeamInfo = (truck: Truck): TruckMembers => {
    const team = getTruckTeam(truck);
    const truckTeamInfo: TruckMembers = [];

    const {isOwnerResponsible, isCoordinatorResponsible, isDriverResponsible: isFirstDriverResponsible} = team;
    const isFirstDriverOwnerDriver = team.firstDriver?.is_owner;

    if (!team.ownerDriver && !team.ownerCoordinator && team.owner) {
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.FIRST_DRIVER,
            entity: team.firstDriver,
            isResponsible: Boolean(isFirstDriverResponsible),
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER,
            entity: team.owner,
            isResponsible: Boolean(isOwnerResponsible),
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.SECOND_DRIVER,
            entity: team.secondDriver,
            isResponsible: false,
        });

        return filterTruckMembers(truckTeamInfo);
    }

    if (!team.ownerDriver && team.ownerCoordinator && team.owner) {
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.FIRST_DRIVER,
            entity: team.firstDriver,
            isResponsible: Boolean(isFirstDriverResponsible),
        });

        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER,
            entity: team.owner,
            isResponsible: Boolean(isOwnerResponsible),
        });

        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_COORDINATOR,
            entity: team.ownerCoordinator,
            isResponsible: Boolean(isCoordinatorResponsible),
        });

        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.SECOND_DRIVER,
            entity: team.secondDriver,
            isResponsible: false,
        });

        return filterTruckMembers(truckTeamInfo);
    }

    if (team.ownerDriver && !team.ownerCoordinator && isFirstDriverOwnerDriver) {
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_DRIVER,
            entity: team.owner,
            isResponsible: Boolean(isOwnerResponsible),
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.SECOND_DRIVER,
            entity: team.secondDriver,
            isResponsible: false,
        });

        return filterTruckMembers(truckTeamInfo);
    }

    if (team.ownerDriver && !team.ownerCoordinator && !isFirstDriverOwnerDriver) {
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_DRIVER,
            entity: team.owner,
            isResponsible: Boolean(isOwnerResponsible),
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.FIRST_DRIVER,
            entity: team.firstDriver,
            isResponsible: Boolean(isFirstDriverResponsible),
        });

        return filterTruckMembers(truckTeamInfo);
    }

    if (team.ownerDriver && team.ownerCoordinator && isFirstDriverOwnerDriver) {
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_DRIVER,
            entity: team.owner,
            isResponsible: Boolean(isOwnerResponsible),
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_COORDINATOR,
            entity: team.ownerCoordinator,
            isResponsible: Boolean(isCoordinatorResponsible),
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.SECOND_DRIVER,
            entity: team.secondDriver,
            isResponsible: false,
        });

        return filterTruckMembers(truckTeamInfo);
    }

    if (team.ownerDriver && team.ownerCoordinator && !isFirstDriverOwnerDriver) {
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_DRIVER,
            entity: team.owner,
            isResponsible: Boolean(isOwnerResponsible),
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_COORDINATOR,
            entity: team.ownerCoordinator,
            isResponsible: Boolean(isCoordinatorResponsible),
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.FIRST_DRIVER,
            entity: team.firstDriver,
            isResponsible: Boolean(isFirstDriverResponsible),
        });

        return filterTruckMembers(truckTeamInfo);
    }

    return [];
};
