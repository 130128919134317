import React from 'react';

import useChatAlert from './hooks/useChatAlert';
import Message from './components/Message';
import Warning from './components/Warning';

const ChatAlert = () => {
    const {connecting, failure, success, chatClientSocketId, connectionRequest} = useChatAlert();

    return (
        <>
            <Message show={success || failure} type={success ? 'success' : 'failure'} />
            <Warning chatClientSocketId={chatClientSocketId} connect={connectionRequest} connecting={connecting} />
        </>
    );
};

export default ChatAlert;
