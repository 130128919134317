import React from 'react';
import {useDispatch} from 'react-redux';
import {Field, change} from 'redux-form';
import classNames from 'classnames';

import {FIELDS} from 'core/entities/TravelOrder/constants/travel-order';
import TimeInput from 'components/ui/Form/TimeInput';
import Button from 'components/ui/Buttons/Button';
import TextInputWithDatePicker from 'components/ui/Form/InputWithDatePicker';
import Tooltip from 'components/ui/Tooltip';
import SelectTimeZone from 'components/ui/Form/SelectTimeZone';
import RequiredLabel from 'components/ui/Form/RequiredLabel';

import {getCurrentDate, getNowWithCurrentTimeZone} from 'utils/dateTime';

import {validateTime, validateDate} from './validate';
import styles from './timeDataPicker.module.scss';

interface OwnProps {
    form: string;
    formSection?: string;
    showMessage?: boolean;
    showReset?: boolean;
    fieldsDisabled?: boolean;
    timeZoneDisabled?: boolean;
}

const TimeDataPicker = (props: OwnProps): JSX.Element => {
    const {
        form,
        formSection,
        showMessage = true,
        showReset = true,
        fieldsDisabled = false,
        timeZoneDisabled = true,
    } = props;
    const dispatch = useDispatch();
    const {originalDate: currentDate, originalTime: currentTime} = getCurrentDate();
    const resetValues = (): void => {
        const getFieldPath = (field): string => {
            return formSection ? `${formSection}.${field}` : field;
        };
        dispatch(change(form, getFieldPath(FIELDS.time), currentTime));
        dispatch(change(form, getFieldPath(FIELDS.date), currentDate));
    };

    const visibleDateIntervalFrom = getNowWithCurrentTimeZone().minus({years: 1});
    const visibleDateIntervalTo = getNowWithCurrentTimeZone().plus({years: 1});

    return (
        <div className={classNames(showMessage ? 'modal-body__timezone' : `${styles.noBorder}`, styles.item)}>
            {showMessage && <p className="mb-1">Please pick the correct time and date.</p>}

            <div className="timezone-fields">
                <Field
                    name={`${FIELDS.time}`}
                    component={TimeInput}
                    label={RequiredLabel('Time')}
                    disabled={fieldsDisabled}
                    validate={[validateTime]}
                />

                <Field
                    name={`${FIELDS.timeZone}`}
                    label={RequiredLabel('Timezone')}
                    component={SelectTimeZone}
                    isClearable={false}
                    isDisabled={fieldsDisabled || timeZoneDisabled}
                    withLongLabel
                />

                <Field
                    name={FIELDS.date}
                    type="text"
                    component={TextInputWithDatePicker}
                    minDate={visibleDateIntervalFrom}
                    maxDate={visibleDateIntervalTo}
                    label={RequiredLabel('Date')}
                    placeholder="choose pick up date"
                    disabled={fieldsDisabled}
                    validate={[validateDate]}
                />

                {showReset && (
                    <Tooltip tooltipContent="reset&nbsp;to&nbsp;default" typeContent="button" position="bottom">
                        <Button
                            onClick={resetValues}
                            buttonSize="icon"
                            buttonIcon="repeat"
                            colorTheme="white"
                            className="mt-4"
                        />
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default TimeDataPicker;
