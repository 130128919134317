import trimStart from 'lodash/trimStart';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';

export const getFullName = ({full_name} = {}) => full_name;

/* eslint-disable no-confusing-arrow */
export const normalizeMultipleSelect = (value) => (Array.isArray(value) && isEmpty(value) ? null : value);

export const removeNonDigitAndLettersChars = (string) => string.replace(/[^0-9a-zA-Z]+/g, '');

export const removeNonLettersAndSpaceChars = (string) => trimStart(string.replace(/[^a-zA-Z ]+/g, ''));

export const allowSymbolsDigitsAndSpecificSymbols = (string) => string.replace(/[^0-9a-zA-Z(),.:?!=/\\#№+-]+/g, '');

export const normalizeNumberLetterHyphenInput = (value) => value.replace(/[^a-zA-Z0-9-]/g, '');

export const removeNonDigitChars = (string) => string.replace(/\D+/g, '');

export const phoneNumberWithBraces = (phoneString) => {
    if (!phoneString) {
        return null;
    }
    const [phoneCodeValue, phoneNumberValue] = phoneString.split(' ');
    if (!phoneNumberValue || !phoneNumberValue) {
        return `+${phoneString}`;
    }
    const numberWithBraces = phoneNumberValue.replace(/[^\d]+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    return `+${phoneCodeValue} ${numberWithBraces}`;
};

export const arrayIntersect = (firstArr, secondArr) => {
    if (firstArr.constructor === Array && secondArr.constructor === Array) {
        return firstArr.some((item) => secondArr.includes(item));
    }

    return null;
};

export const getNumberValue = (value) => {
    const convertedValue = Number(value);
    return !isNaN(convertedValue) ? convertedValue : 0;
};

// eslint-disable-next-line no-magic-numbers
export const getToFixedNumberValue = (value, {fractionDigits = 2} = {}) => {
    if (!isNumber(value) || isNaN(value)) {
        return 0;
    }
    return Number(value.toFixed(fractionDigits));
};

export const replaceFalsyValueWithEmptyString = (value) => {
    if (!value) {
        return '';
    }

    return value;
};
