import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import Truck from 'core/entities/Truck/types';
import Load from 'core/entities/Load/types';

import {isSettlementCarrier, isSettlementOwner} from 'core/entities/Settlement/settlement';
import {FactoringCompany} from 'core/entities/FactoringCompany/types';
import * as entityNames from 'utils/data/entityNames';
import TravelOrder from 'core/entities/TravelOrder/types';
import Carrier from 'core/entities/Carrier/types';
import Customer from 'core/entities/Customer/types';
import {Dispatcher} from 'core/entities/Dispatcher/types';
import Driver from 'core/entities/Driver/types';
import Owner from 'core/entities/Owner/types';
import {Facility} from 'core/entities/Facility/types';
import Invoice from 'core/entities/Invoice/types';

const getLink = (props, content) => (
    <strong>
        <Link {...props}>{content}</Link>
    </strong>
);

const getEntityViewUpdateLink = (entityName, entity, action, subPage?) => {
    if (entityName === entityNames.ENTITY_NAME_COMPANY) {
        const companyType = entity && entity.is_customer ? 'customers' : 'brokers';
        return `/${companyType}/${action}/${entity.id}`;
    }
    if (entityName === entityNames.ENTITY_NAME_CUSTOMER) {
        return `/customers/${action}/${entity.id}`;
    }
    if (entityName === entityNames.ENTITY_NAME_DISPATCHER) {
        return `/dispatchers/${action}/${entity.id}`;
    }
    if (entityName === entityNames.ENTITY_NAME_LOAD) {
        const loadNumber = entity.number;
        return `/loads/${action}/${loadNumber}`;
    }
    if (entityName === entityNames.ENTITY_NAME_INVOICE) {
        if (!entity.invoice_number) {
            return '';
        }
        return `/invoices/${action}/${entity.invoice_number}`;
    }
    if (entityName === entityNames.ENTITY_NAME_SETTLEMENT_CARRIER) {
        return `/settlements/carriers/${action}/${entity.settlement_number}`;
    }
    if (entityName === entityNames.ENTITY_NAME_SETTLEMENT_OWNER) {
        return `/settlements/owners/${action}/${entity.settlement_number}`;
    }
    if (entityName === entityNames.ENTITY_NAME_SETTLEMENT) {
        if (isSettlementCarrier(entity)) {
            return `/settlements/carriers/${action}/${entity.settlement_number}`;
        }
        if (isSettlementOwner(entity)) {
            return `/settlements/owners/${action}/${entity.settlement_number}`;
        }
    }
    if (entityName === entityNames.ENTITY_NAME_FACILITY) {
        return `/facilities/${action}/${entity.id}`;
    }
    if (entityName === entityNames.ENTITY_NAME_FACTORING_COMPANY) {
        return `/factoring-companies/${action}/${entity.id}`;
    }
    if (entityName === entityNames.ENTITY_NAME_OWNER) {
        return `/owners/${action}/${entity.id}`;
    }
    if (entityName === entityNames.ENTITY_NAME_DRIVER) {
        return `/drivers/${action}/${entity.id}`;
    }
    if (entityName === entityNames.ENTITY_NAME_OWNER) {
        return `/owners/${action}/${entity.id}`;
    }
    if (entityName === entityNames.ENTITY_NAME_CARRIER) {
        return `/carriers/${action}/${entity.id}`;
    }
    if (entityName === entityNames.ENTITY_NAME_TRUCK) {
        return `/trucks/${action}/${entity.number}`;
    }
    if (entityName === entityNames.ENTITY_NAME_TRAVEL_ORDER) {
        const travelOrderUrl = `/trip-monitor/travel-order/${entity.number}`;
        if (subPage) {
            return `${travelOrderUrl}/${subPage}`;
        }
        return travelOrderUrl;
    }

    if (entityName === entityNames.ENTITY_NAME_TRIP_TRAVEL_ORDER) {
        const travelOrderUrl = `/trip/travel-order/${entity.id}`;
        if (subPage) {
            return `${travelOrderUrl}/${subPage}`;
        }
        return travelOrderUrl;
    }

    if (entityName === entityNames.ENTITY_NAME_TRIP_UPDATE) {
        const tripUpdateUrl = `/trip/update/${entity.id}`;
        if (subPage) {
            return `${tripUpdateUrl}/${subPage}`;
        }
        return tripUpdateUrl;
    }

    return '';
};

const getEntityLinkCreate = (entityName) => {
    if (entityName === entityNames.ENTITY_NAME_LOAD) {
        return `/loads/create`;
    }

    return '';
};

const getEntityLinkList = (entityName) => {
    if (entityName === entityNames.ENTITY_NAME_LOAD) {
        return `/loads`;
    }

    return '';
};

// this function is deprecated
export const getEntityUpdateLink = (params) => {
    const {entityName, entityData, linkContent, linkClassName, onlyString, subPage} = params;
    const link = getEntityViewUpdateLink(entityName, entityData, 'update', subPage);
    if (onlyString) {
        return link;
    }
    if (!link) {
        return null;
    }
    return getLink(
        {
            to: getEntityViewUpdateLink(entityName, entityData, 'update', subPage),
            className: linkClassName,
        },
        linkContent,
    );
};

// this function is deprecated
export const getEntityViewLink = (params) => {
    const {entityName, subPage, entityData, linkContent, linkClassName, onlyString, target} = params;
    const link = getEntityViewUpdateLink(entityName, entityData, 'view', subPage);
    if (onlyString) {
        return link;
    }
    if (!link) {
        return null;
    }
    const fullClassName = classNames(linkClassName, {'archived-link': entityData?.is_deleted});
    return getLink(
        {
            to: getEntityViewUpdateLink(entityName, entityData, 'view', subPage),
            className: fullClassName,
            target,
        },
        linkContent,
    );
};

// this function is deprecated
export const getEntitySummaryLink = (params) => {
    const {entityName, subPage, entityData, linkContent, linkClassName, onlyString, target} = params;
    const link = getEntityViewUpdateLink(entityName, entityData, 'summary', subPage);
    if (onlyString) {
        return link;
    }
    if (!link) {
        return null;
    }
    const fullClassName = classNames(linkClassName, {'archived-link': entityData?.is_deleted});
    return getLink(
        {
            to: getEntityViewUpdateLink(entityName, entityData, 'summary', subPage),
            className: fullClassName,
            target,
        },
        linkContent,
    );
};

// this function is deprecated
export const getEntityCreateLink = (params) => {
    const {entityName, linkContent, linkClassName, onlyString} = params;
    if (onlyString) {
        return getEntityLinkCreate(entityName);
    }
    return getLink(
        {
            to: getEntityLinkCreate(entityName),
            className: linkClassName,
        },
        linkContent,
    );
};

// this function is deprecated
export const getEntityListLink = (params) => {
    const {entityName, linkContent, linkClassName, onlyString} = params;
    if (onlyString) {
        return getEntityLinkList(entityName);
    }
    return getLink(
        {
            to: getEntityLinkList(entityName),
            className: linkClassName,
        },
        linkContent,
    );
};

type DefaultLinks = {
    list: string;
    create: string;
    view: string;
    update: string;
};

type PageLinksCreator<Entity, EntityLinks = DefaultLinks> = (entity?: Entity | null | undefined) => EntityLinks;

export const getTruckLinks: PageLinksCreator<Truck> = (truck) => {
    return {
        list: '/trucks',
        create: '/trucks/create',
        view: truck ? `/trucks/view/${truck.number}` : '',
        update: truck ? `/trucks/update/${truck.number}` : '',
    };
};

export const getLoadLinks: PageLinksCreator<Load> = (load) => {
    return {
        list: '/loads',
        create: '/loads/create',
        view: load ? `/loads/view/${load.number}` : '',
        update: load ? `/loads/update/${load.number}` : '',
        summary: load ? `/loads/summary/${load.number}` : '',
    };
};

export const getCarrierLinks: PageLinksCreator<Carrier> = (carrier) => {
    return {
        list: '/carriers',
        create: '/carriers/create',
        view: carrier ? `/carriers/view/${carrier.id}` : '',
        update: carrier ? `/carriers/update/${carrier.id}` : '',
    };
};

export const getCustomerLinks: PageLinksCreator<Customer> = (customer) => {
    return {
        list: '/customers',
        create: '/customers/create',
        view: customer ? `/customers/view/${customer.id}` : '',
        update: customer ? `/customers/update/${customer.id}` : '',
    };
};

export const getDispatcherLinks: PageLinksCreator<Dispatcher> = (dispatcher) => {
    return {
        list: '/dispatchers',
        create: '/dispatchers/create',
        view: dispatcher ? `/dispatchers/view/${dispatcher.id}` : '',
        update: dispatcher ? `/dispatchers/update/${dispatcher.id}` : '',
    };
};

export const getDriverLinks: PageLinksCreator<Driver> = (driver) => {
    return {
        list: '/drivers',
        create: '/drivers/create',
        view: driver ? `/drivers/view/${driver.id}` : '',
        update: driver ? `/drivers/update/${driver.id}` : '',
    };
};

export const getOwnerLinks: PageLinksCreator<Owner> = (owner) => {
    return {
        list: '/owners',
        create: '/owners/create',
        view: owner ? `/owners/view/${owner.id}` : '',
        update: owner ? `/owners/update/${owner.id}` : '',
    };
};

export const getFacilityLinks: PageLinksCreator<Facility> = (facility) => {
    return {
        list: '/facilities',
        create: '/facilities/create',
        view: facility ? `/facilities/view/${facility.id}` : '',
        update: facility ? `/facilities/update/${facility.id}` : '',
    };
};

export const getFactoringCompaniesLinks: PageLinksCreator<FactoringCompany> = (company) => {
    return {
        list: '/factoring-companies',
        create: '/factoring-companies/create',
        view: company ? `/factoring-companies/view/${company.id}` : '',
        update: company ? `/factoring-companies/update/${company.id}` : '',
    };
};

export const getInvoiceLinks: PageLinksCreator<Invoice> = (invoice) => {
    return {
        list: '/invoices',
        create: '',
        view: invoice ? `/invoices/view/${invoice.invoice_number}` : '',
        update: '',
    };
};

export const getLoadBoardLinks: PageLinksCreator<null> = () => {
    return {
        list: '/auction',
        create: '',
        view: '',
        update: '',
    };
};
