import {createSelector} from 'reselect';

import {Company} from 'core/entities/Settlement/types';
import {OpenedCompany} from './types';

export const getCompaniesWithSettlements = createSelector([(state, props) => props.companies], (companies) => {
    return companies.map(
        (company: Company): OpenedCompany => {
            return {
                id: company.id,
                title: company.company_name,
                settlementsCount: company.settlements_count,
                carrier: {
                    id: company.id,
                    is_global: company.is_global,
                },
                batchId: company.batch_id,
                users: [],
            };
        },
    );
});
