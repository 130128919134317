import React from 'react';

import {FilesListView} from 'components/ui/Files/FileUpload';

import DataLoading from 'pages/Invoices/components/common/DataLoading';
import useFetchBatchFiles from 'pages/Invoices/hooks/useFetchBatchFiles';

interface OwnProps {
    batchNumber: number;
}

const BatchesFiles = (props: OwnProps): JSX.Element => {
    const {batchNumber} = props;
    const {files, isFetching, isAllFetched, fetchNextPage} = useFetchBatchFiles({batchNumber});
    return (
        <div className="modal-body__files">
            <p className="mb-2">Check invoices in the batch:</p>

            <FilesListView title="Batch" files={files} viewButton={true} />

            <br />

            <DataLoading
                isLoading={isFetching}
                isAllLoaded={isAllFetched}
                onLoadMore={fetchNextPage}
                loadMoreTitle="load more files"
            />
        </div>
    );
};

export default BatchesFiles;
