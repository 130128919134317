import {getRemainingTimeTo} from 'utils/dateTime';

export const getDelayStatus = (params: {date: string}): 'low' | 'normal' | 'high' | null => {
    const {date} = params;

    const {duration} = getRemainingTimeTo(date) || {};

    const oneHour = 1;
    const threeHours = 3;

    const normal = duration?.hours > oneHour && duration?.hours <= threeHours ? 'normal' : null;
    const low = duration?.hours > threeHours ? 'low' : null;
    const high = duration?.hours <= oneHour ? 'high' : null;

    return normal || low || high;
};
