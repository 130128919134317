export const TRUCK_STATUS_AVAILABLE = 'Available';
export const TRUCK_STATUS_NOT_AVAILABLE = 'Not Available';
export const TRUCK_STATUS_WILL_BE_AVAILABLE_AT = 'Will be available at';
export const TRUCK_STATUS_ON_ROUTE = 'On route';
export const TRUCK_STATUS_ON_HOLD = 'On hold';
export const TRUCK_STATUS_ON_VACATION = 'On vacation';
export const TRUCK_STATUS_WAITING_FOR_APPROVAL = 'Waiting for approval';
export const TRUCK_STATUS_RETIRED = 'Retired';

export const CREATE_STATUSES_LIST = [
    {label: TRUCK_STATUS_NOT_AVAILABLE, value: TRUCK_STATUS_NOT_AVAILABLE},
    {label: TRUCK_STATUS_WAITING_FOR_APPROVAL, value: TRUCK_STATUS_WAITING_FOR_APPROVAL},
];

export const TRUCK_STATUSES_AVAILABLE_FOR_RESERVING = [
    TRUCK_STATUS_AVAILABLE,
    TRUCK_STATUS_NOT_AVAILABLE,
    TRUCK_STATUS_WILL_BE_AVAILABLE_AT,
    TRUCK_STATUS_ON_ROUTE,
];

export const TRUCK_INACTIVE_STATUSES = [
    TRUCK_STATUS_ON_HOLD,
    TRUCK_STATUS_ON_VACATION,
    TRUCK_STATUS_WAITING_FOR_APPROVAL,
    TRUCK_STATUS_RETIRED,
];

export const TRUCK_INACTIVE_STATUS_LIST = [
    {label: TRUCK_STATUS_ON_HOLD, value: TRUCK_STATUS_ON_HOLD},
    {label: TRUCK_STATUS_ON_VACATION, value: TRUCK_STATUS_ON_VACATION},
    {label: TRUCK_STATUS_WAITING_FOR_APPROVAL, value: TRUCK_STATUS_WAITING_FOR_APPROVAL},
    {label: TRUCK_STATUS_RETIRED, value: TRUCK_STATUS_RETIRED},
];

export const UPDATE_STATUS_LIST = [
    {label: TRUCK_STATUS_AVAILABLE, value: TRUCK_STATUS_AVAILABLE},
    {label: TRUCK_STATUS_NOT_AVAILABLE, value: TRUCK_STATUS_NOT_AVAILABLE},
    {label: TRUCK_STATUS_ON_HOLD, value: TRUCK_STATUS_ON_HOLD},
    {label: TRUCK_STATUS_ON_VACATION, value: TRUCK_STATUS_ON_VACATION},
    {label: TRUCK_STATUS_WAITING_FOR_APPROVAL, value: TRUCK_STATUS_WAITING_FOR_APPROVAL},
    {label: TRUCK_STATUS_RETIRED, value: TRUCK_STATUS_RETIRED},
];

export const TRUCK_STATUS_ITEMS_FOR_TRUCKS_NEARBY = [
    {label: TRUCK_STATUS_AVAILABLE, value: TRUCK_STATUS_AVAILABLE},
    {label: TRUCK_STATUS_WILL_BE_AVAILABLE_AT, value: TRUCK_STATUS_WILL_BE_AVAILABLE_AT},
    {label: TRUCK_STATUS_ON_ROUTE, value: TRUCK_STATUS_ON_ROUTE},
    {label: TRUCK_STATUS_NOT_AVAILABLE, value: TRUCK_STATUS_NOT_AVAILABLE},
];

export const INITIAL_TRUCK_STATUSES_FOR_TRUCKS_NEARBY = TRUCK_STATUS_ITEMS_FOR_TRUCKS_NEARBY.filter(
    (status) => status.value !== TRUCK_STATUS_NOT_AVAILABLE,
)
    .map((status) => status.value)
    .join(',');

export default [
    {label: TRUCK_STATUS_AVAILABLE, value: TRUCK_STATUS_AVAILABLE},
    {label: TRUCK_STATUS_NOT_AVAILABLE, value: TRUCK_STATUS_NOT_AVAILABLE},
    {label: TRUCK_STATUS_WILL_BE_AVAILABLE_AT, value: TRUCK_STATUS_WILL_BE_AVAILABLE_AT},
    {label: TRUCK_STATUS_ON_ROUTE, value: TRUCK_STATUS_ON_ROUTE},

    {label: TRUCK_STATUS_ON_HOLD, value: TRUCK_STATUS_ON_HOLD},
    {label: TRUCK_STATUS_ON_VACATION, value: TRUCK_STATUS_ON_VACATION},
    {label: TRUCK_STATUS_WAITING_FOR_APPROVAL, value: TRUCK_STATUS_WAITING_FOR_APPROVAL},
    {label: TRUCK_STATUS_RETIRED, value: TRUCK_STATUS_RETIRED},
];
