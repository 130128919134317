import React from 'react';
import {useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button';

import Customer from 'core/entities/Customer/types';
import {Facility} from 'core/entities/Facility/types';

import DuplicatesCustomers from './components/DuplicatesCustomers';
import DuplicatesFacilities from './components/DuplicatesFacilities';
import styles from './duplicatesEntities.module.scss';
import {getIsCustomerCreate, getIsFacilityCreate} from './selectors';

type OwnProps = {
    data: {
        type: 'customer' | 'facility';
        entity: Customer | Facility;
        duplicates: (Customer | Facility)[];
    };
    handlers: {
        onSelectDuplicate();
        onForceCreate();
    };
    onClose();
};

const DuplicatesEntitiesModal: React.FC<OwnProps> = (props) => {
    const {
        data: {type, entity, duplicates},
        handlers: {onSelectDuplicate, onForceCreate},
        onClose,
    } = props;
    const isCalledFromCustomerCreateForm = useSelector(getIsCustomerCreate);
    const isCalledFromFacilityCreateForm = useSelector(getIsFacilityCreate);
    return (
        <Modal show={true} onHide={onClose} className={styles.modal} backdrop="static">
            <Modal.Header>
                <Modal.Title>Duplicates found</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {type === 'customer' && (
                    <DuplicatesCustomers
                        customer={entity as Customer}
                        customerDuplicates={duplicates as Customer[]}
                        onSelectCustomerDuplicate={onSelectDuplicate}
                    />
                )}

                {type === 'facility' && (
                    <DuplicatesFacilities
                        facility={entity as Facility}
                        facilityDuplicates={duplicates as Facility[]}
                        onSelectFacilityDuplicate={onSelectDuplicate}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <div />
                <div>
                    {(isCalledFromCustomerCreateForm || isCalledFromFacilityCreateForm) && (
                        <Button onClick={onClose} buttonSize="small" colorTheme="grey" className="mr10">
                            Keep creating
                        </Button>
                    )}

                    <Button onClick={onForceCreate} buttonSize="small" colorTheme="grey">
                        CREATE ANYWAY
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default DuplicatesEntitiesModal;
