import {ThunkAction} from 'redux-thunk';

import {AppState} from 'store';
import * as appActions from 'store/actions';

import Load from 'core/entities/Load/types';
import {addLoadNotes as addLoadNotesUseCase} from 'core/useCases/Load';

import {actionCreators} from 'pages/Loads/redux/actionCreators/list';
import {openTruckNearbyPageWithExternalSearchParams} from 'pages/TrucksNearby/redux/actions/public';

import * as entityTypes from 'utils/data/entityNames';

type ThunkActionTypes = ThunkAction<void, AppState, unknown, any>;

export const addLoadNote = (newNote: {text: string}, loadNumber: number): ThunkActionTypes => async (dispatch) => {
    try {
        dispatch(appActions.showLoader());

        const {updatedLoad} = await addLoadNotesUseCase(loadNumber, {note: newNote.text, isImportant: false});

        if (updatedLoad) {
            dispatch(actionCreators.receiveLoadNote({loadNotes: updatedLoad.notes, loadNumber}));
        }
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const openTruckNearbyPageWithLoadParams = (load: Load): ThunkActionTypes => (dispatch) => {
    dispatch(openTruckNearbyPageWithExternalSearchParams({entity: load, type: entityTypes.ENTITY_NAME_LOAD}));
};

export const clearState = (): ThunkActionTypes => (dispatch) => {
    dispatch(actionCreators.clearState());
};
