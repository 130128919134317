import isArray from 'lodash/isArray';

import parseFileExtraData from 'utils/files/parseFileExtraData';

import * as travelOrdersActions from '../travelOrders/actions';

export const filesChannelMessageHandler = (store) => (uri, payload) => {
    const messageData = payload.msg;
    if (!messageData.event || !messageData.data) {
        return;
    }
    const {event, data: files} = messageData;
    const [entity, entityID, actionWithFiles] = event.split('.');
    const convertedID = parseInt(entityID, 10);
    if (!entity || !convertedID || !actionWithFiles || !isArray(files)) {
        console.warn('Incorrect websocket message for update files');
        return;
    }
    const filteredFiles = files.filter(Boolean).map(parseFileExtraData);

    if (filteredFiles.length === 0) {
        console.warn('Error: files from websocket update are empty!');
    }

    if (entity === 'travel-order' && actionWithFiles === 'files_posted') {
        store.dispatch(travelOrdersActions.travelOrderFilePosted(convertedID, filteredFiles));
    }

    if (entity === 'travel-order' && actionWithFiles === 'files_deleted') {
        store.dispatch(travelOrdersActions.travelOrderFileDeleted(convertedID, filteredFiles));
    }
};
