import io from 'socket.io-client';

import {getUserAccessToken} from 'store/reducers/auth/selectors';

import appConfig from 'config';

import restapi from 'services/restapi';

import * as types from './actionTypes';

export function init() {
    return {
        type: types.WEB_SOCKET_INIT,
    };
}

export function reconnect() {
    return {
        type: types.WEB_SOCKET_RECONNECT,
    };
}

export function connected({socket}) {
    return {
        type: types.WEB_SOCKET_CONNECTED,
        payload: {wsSocketId: socket.id},
    };
}

export function disconnect() {
    return {
        type: types.WEB_SOCKET_DISCONNECT,
    };
}

export function disconnected() {
    return {
        type: types.WEB_SOCKET_DISCONNECTED,
    };
}

export function getTempWsToken() {
    return restapi
        .post(`/ws/token`)
        .then((res) => res.data && res.data.temp_token)
        .catch((error) => {
            console.error(error);
        });
}

const makeSocketUrl = ({tempWsToken}) => {
    return appConfig.NODE_ENV === 'development'
        ? `ws://localhost:3001/${appConfig.REACT_APP_PREFIX}?token=${tempWsToken}`
        : `wss://${window.location.hostname}/${appConfig.REACT_APP_PREFIX}?token=${tempWsToken}`;
};

export function connect() {
    return async function (dispatch, getState) {
        const tempWsToken = await getTempWsToken();
        const socketUrl = makeSocketUrl({tempWsToken});
        const accessToken = getUserAccessToken(getState());

        return new Promise((resolve, reject) => {
            const RECONNECT_TIMEOUT = 5000;
            const socket = io(socketUrl, {
                transports: ['websocket', 'polling'],
                reconnection: false,
                path: appConfig.NODE_ENV === 'development' ? '' : '/api/socket.io',
            });
            socket.on('connect', () => {
                // eslint-disable-next-line
                console.log('Websocket connect, socket.id = ', socket.id);
                socket.emit('authenticate', {accessToken});
                dispatch(connected({socket}));
                resolve(socket);
            });
            socket.on('disconnect', (reason) => {
                console.warn('Websocket disconnect: ', reason);
                dispatch(disconnected());
                window.setTimeout(() => dispatch(reconnect()), RECONNECT_TIMEOUT);
                reject(reason);
            });
            socket.on('error', (error) => {
                console.warn('Websocket error: ', error);
                reject(error);
            });
            socket.on('connect_error', (error) => {
                console.warn('Websocket connect_error: ', error);
                reject(error);
            });
            socket.on('connect_timeout', (error) => {
                console.warn('Websocket connect_timeout: ', error);
                reject(error);
            });
        });
    };
}
