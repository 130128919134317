import restApi from 'services/restapi';

export const baseTruckCancelReservationRequest = ({truckID}) => restApi.put(`/v2/truck/${truckID}/reserve/release`);
export const crossServerTruckCancelReservationRequest = ({truckID, carrierID}) =>
    restApi.put(`/v2/truck/${truckID}/reserve/${carrierID}/release`);

export const baseTransferReservationRequest = ({truckID, requestBody}) =>
    restApi.put(`/v2/truck/${truckID}/reserve/transfer`, requestBody);
export const baseHoldForRCReservationRequest = ({truckID}) => restApi.put(`/v2/truck/${truckID}/reserve/hold-for-rc`);
export const baseByTimeReservationRequest = ({truckID, requestBody}) =>
    restApi.put(`/v2/truck/${truckID}/reserve`, requestBody);

export const crossServerHoldForRCReservationRequest = ({truckID, carrierID}) =>
    restApi.put(`/v2/truck/${truckID}/reserve/${carrierID}/hold-for-rc`);
export const crossServerByTimeReservationRequest = ({truckID, carrierID, requestBody}) =>
    restApi.put(`/v2/truck/${truckID}/reserve/${carrierID}`, requestBody);
