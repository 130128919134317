import React, {useEffect, useRef} from 'react';
import {isEmpty} from 'lodash';
import classNames from 'classnames';
import {useCopyToClipboard, useHoverDirty} from 'react-use';

import styles from './styles.module.scss';

type OwnProps = {
    textToCopy: string | number;
    children: React.ReactNode;
};

const CopyToClipboard: React.FC<OwnProps> = (props) => {
    const {children, textToCopy} = props;

    const hoverRef = useRef(null);
    const isHovering = useHoverDirty(hoverRef);
    const [state, copyToClipboard] = useCopyToClipboard();

    useEffect(() => {
        // Clear state, because 'Copied' text stays all the time
        state.value = undefined;
    }, [state]);

    if (!textToCopy) {
        return null;
    }

    const handleCopyToClipboard = () => copyToClipboard(String(textToCopy));

    const isTextCopied = !isEmpty(state.value);

    return (
        <div className={styles.container} ref={hoverRef}>
            {children}

            <div className={classNames(styles.wrap, {[styles.visible]: isHovering})}>
                <div className={styles.copy} onClick={handleCopyToClipboard}>
                    <i className="fa fa-copy" />
                </div>

                <div className={classNames(styles.tooltip)}>
                    {isTextCopied ? <i className="fa fa-check" /> : null}

                    <span className={classNames({[styles.copied]: isTextCopied})}>
                        {isTextCopied ? 'Copied' : 'Copy'}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CopyToClipboard;
