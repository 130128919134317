import React from 'react';
import {Field} from 'redux-form';
import {components} from 'react-select';

import Select from 'components/ui/Form/Select';

import useGetTruckTypesOptions from '../../hooks/useGetTruckTypesOptions';

const {Option, MultiValue, SingleValue} = components;

const icons = {
    snowflake: <i className="fa fa-snowflake-o" />,
};

const OptionWithIcon = (props) => {
    return (
        <Option {...props}>
            {props.data.label} {icons[props.data.icon]}
        </Option>
    );
};

const SingleValueWithIcon = (props) => {
    const currentValue = props.options.find((e) => e.value === props.data.value);
    return (
        <SingleValue {...props}>
            {props.data.label} {icons[currentValue?.icon]}
        </SingleValue>
    );
};

const MultiValueWithIcon = (props) => {
    const currentValue = props.options.find((e) => e.value === props.data.value);
    return (
        <MultiValue {...props}>
            {props.data.label} {icons[currentValue?.icon]}
        </MultiValue>
    );
};

interface OwnProps {
    name: string;
    placeholder: string;
    label: string;
    isMulti?: boolean;
    isDisabled?: boolean;
    isClearable?: boolean;
    closeMenuOnSelect?: boolean;
}

const TruckTypesSelect: React.FC<OwnProps> = (props) => {
    const {name, placeholder, label, isMulti = true, isDisabled, isClearable, closeMenuOnSelect = false} = props;
    const currentTrucksTypeOptions = useGetTruckTypesOptions();

    return (
        <>
            <label className="form-label">{label}</label>
            <Field
                name={name}
                placeholder={placeholder}
                component={Select}
                data={currentTrucksTypeOptions}
                closeMenuOnSelect={closeMenuOnSelect}
                components={{
                    Option: OptionWithIcon,
                    SingleValue: SingleValueWithIcon,
                    MultiValue: MultiValueWithIcon,
                }}
                isMulti={isMulti}
                isDisabled={isDisabled}
                isClearable={isClearable}
                valueAsArray={true}
            />
        </>
    );
};

export default TruckTypesSelect;
