import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import * as types from '../actionTypes';

const settlementChangeActions = new Set([
    types.SETTLEMENT_DRIVER_PAYMENT_UPDATED,
    types.SETTLEMENT_TRAVEL_ORDER_UPDATED,
    types.SETTLEMENT_LOAD_UPDATED,
]);

export const getTableData = (data) => {
    const map = keyBy(data, 'id');
    return {
        byId: map,
        allIds: data.map((item) => item.id),
    };
};

export const addItemToTable = (table, newItem, {toBegin = false} = {}) => {
    const allIds = toBegin ? [newItem.id, ...table.allIds] : [...table.allIds, newItem.id];
    return {
        byId: {...table.byId, [newItem.id]: newItem},
        allIds,
    };
};

export const addItemsToTable = (table, newItems) => {
    const newItemsMap = keyBy(newItems, 'id');
    const newItemsIds = newItems.map((i) => i.id);
    return {
        byId: {...table.byId, ...newItemsMap},
        allIds: [...table.allIds, ...newItemsIds],
    };
};

export const updateItemInTable = (table, newItem) => {
    return {
        byId: {...table.byId, [newItem.id]: newItem},
        allIds: table.allIds,
    };
};

export const updateItemsInTable = (table, items) => {
    const map = keyBy(items, 'id');
    return {
        byId: {...table.byId, ...map},
        allIds: table.allIds,
    };
};

export const removeItemsFromTable = (table, items) => {
    const idsForRemove = items.map((i) => i.id);
    return {
        byId: omit(table.byId, idsForRemove),
        allIds: table.allIds.filter((currentID) => !idsForRemove.includes(currentID)),
    };
};

export const removeItemInTable = (table, tableItem) => {
    return {
        byId: omit(table.byId, [tableItem.id]),
        allIds: table.allIds.filter((id) => id !== tableItem.id),
    };
};

export const isSettlementUpdateCommonAction = (action) => {
    return settlementChangeActions.has(action.type);
};

export const isWebsocketAction = (action) => {
    return action.type.startsWith('WEB_SOCKET');
};

export const isSelectedSearchParams = (pageState) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const searchParams = pageState?.list?.searchParams;
    return pageState && !isEmpty(searchParams);
};
