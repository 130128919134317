import React, {Fragment} from 'react';

import {Batch} from 'core/entities/Settlement/types';
import CurrencyAmount from 'components/ui/CurrencyAmount';

interface OwnProps {
    batch: Batch;
}

const BatchTotalPays = ({batch}: OwnProps): JSX.Element => {
    const allPays = batch.batch_total_charges;
    if (!allPays || allPays.length === 0) {
        return <CurrencyAmount currency="USD" amount={0} />;
    }
    const allPaysItems = allPays.map((payment) => {
        return (
            <Fragment key={payment.id}>
                <CurrencyAmount currency={payment.currency} amount={payment.total_charges} /> <br />{' '}
            </Fragment>
        );
    });
    return <span>{allPaysItems}</span>;
};

export default BatchTotalPays;
