import {EUROPE_TRUCK_TYPES, GENERAL_TRUCK_TYPES} from 'core/entities/Truck/constants/truckTypes';

export type Icon = 'snowflake';

export const generalTruckOptions = [
    {label: GENERAL_TRUCK_TYPES.CARGO_VAN, value: GENERAL_TRUCK_TYPES.CARGO_VAN},
    {label: GENERAL_TRUCK_TYPES.REEFER_VAN, value: GENERAL_TRUCK_TYPES.REEFER_VAN, icon: 'snowflake'},
    {label: GENERAL_TRUCK_TYPES.BOX_TRUCK, value: GENERAL_TRUCK_TYPES.BOX_TRUCK},
    {label: GENERAL_TRUCK_TYPES.BOX_TRUCK_REEFER, value: GENERAL_TRUCK_TYPES.BOX_TRUCK_REEFER, icon: 'snowflake'},
    {label: GENERAL_TRUCK_TYPES.STRAIGHT_TRUCK, value: GENERAL_TRUCK_TYPES.STRAIGHT_TRUCK},
    {
        label: GENERAL_TRUCK_TYPES.STRAIGHT_TRUCK_REEFER,
        value: GENERAL_TRUCK_TYPES.STRAIGHT_TRUCK_REEFER,
        icon: 'snowflake',
    },
    {label: 'Semi-truck', value: GENERAL_TRUCK_TYPES.SEMI_TRUCK},
    {label: GENERAL_TRUCK_TYPES.HOTSHOT, value: GENERAL_TRUCK_TYPES.HOTSHOT},
    {label: GENERAL_TRUCK_TYPES.CARHAULER, value: GENERAL_TRUCK_TYPES.CARHAULER},
    {label: GENERAL_TRUCK_TYPES.TENTED_BOX, value: GENERAL_TRUCK_TYPES.TENTED_BOX},
    {label: GENERAL_TRUCK_TYPES.ISOTHERM_TRUCK, value: GENERAL_TRUCK_TYPES.ISOTHERM_TRUCK, icon: 'snowflake'},
    {label: GENERAL_TRUCK_TYPES.BOBTAIL_TRUCK, value: GENERAL_TRUCK_TYPES.BOBTAIL_TRUCK},
];

export const europeTruckOptions = [
    ...generalTruckOptions,
    {label: 'Tautliner-truck', value: EUROPE_TRUCK_TYPES.TAUTLINER},
    {label: EUROPE_TRUCK_TYPES.CURTAINSIDER, value: EUROPE_TRUCK_TYPES.CURTAINSIDER},
];
