import {useSelector} from 'react-redux';
import {formValueSelector} from 'redux-form';

const useGetReduxFormValue = <T>(formName: string, fieldName: string): T | any => {
    const selector = formValueSelector(formName);
    const fieldValue = useSelector((state) => selector(state, fieldName));
    return fieldValue;
};

export default useGetReduxFormValue;
