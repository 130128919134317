import React from 'react';
import {Quote} from 'core/entities/Quote/types';
import {getTruckTypes} from 'core/entities/Quote/modules/quoteTruck';
import {getCurrency, getRate} from 'core/entities/Quote/modules/quoteDriverPayment';
import Payment from './components/Payment';

const QuoteTruckAndPaymentInfo: React.FC<{quote: Quote}> = (props) => {
    const {quote} = props;

    const truckTypeList = getTruckTypes(quote);
    const currency = getCurrency(quote);
    const rate = getRate(quote);
    const truckType = truckTypeList.join(',');
    return (
        <>
            <div className="col-4">
                <div>{truckType}</div>
                {currency && <Payment currency={currency} rate={rate} />}
            </div>
        </>
    );
};

export default QuoteTruckAndPaymentInfo;
