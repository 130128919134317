export const signsItems = [
    {label: '- None -', value: '', btnTitle: 'Signs'},
    {label: 'With Signs', value: true, btnTitle: 'With Signs'},
    {label: 'Without Signs', value: false, btnTitle: 'Without Signs'},
];

export const reservationItems = [
    {label: '- None -', value: '', btnTitle: 'Reservation'},
    {label: 'Reserved', value: true, btnTitle: 'Reserved'},
    {label: 'Not Reserved', value: false, btnTitle: 'Not Reserved'},
];
