import {toast} from 'react-toastify';

import notifications from 'components/ui/notifications';

import {LoadApiGateway, UpdateFilesLoadParams} from '../types';
import {transformFilesDataToRequest} from './utils';

const changeLoadFiles = async function (
    loadNumber: number,
    data: UpdateFilesLoadParams & {fileIds: Record<number, string>[]},
    deps: {
        loadApiGateway: LoadApiGateway;
    },
) {
    const {loadApiGateway} = deps;
    const {addedFiles, fileIds} = data;

    const isNeedToAddLoadFiles = addedFiles?.length && fileIds;

    if (!isNeedToAddLoadFiles) {
        return;
    }

    try {
        const convertedLoadFiles = transformFilesDataToRequest(addedFiles, fileIds);

        await loadApiGateway.addLoadFiles({loadNumber, files: convertedLoadFiles});

        toast.success(notifications.filesSuccessfulAdded, {data: {}});
    } catch (e) {
        toast.error(notifications.filesUnsuccessfulAdded, {data: {}});
    }
};

export default changeLoadFiles;
