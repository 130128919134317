import certificates from 'utils/data/certificates';
import lengthUnits from 'utils/data/lengthUnits';
import weightUnits from 'utils/data/weightUnits';
import countries from 'utils/data/countries';

export type TrucksOwningType = 'OWN' | 'PARTNERS';

export type SortBy = 'origin' | 'destination';

export const awaySortValues: {origin: SortBy; destination: SortBy} = {
    origin: 'origin',
    destination: 'destination',
};

export const awaySortItems = [
    {label: 'Origin Away', value: awaySortValues.origin, btnTitle: 'Origin Away'},
    {label: 'Destination Away', value: awaySortValues.destination, btnTitle: 'Destination Away'},
];

export const SEARCH_FORM_NAME = 'trucks-nearby-search';

export const TRUCKS_OWNING_TYPE: {OWN: TrucksOwningType; PARTNERS: TrucksOwningType} = {
    OWN: 'OWN',
    PARTNERS: 'PARTNERS',
};

export const PARTNER_TRUCKS_RADIUS_SEARCH_LIMIT = 1000;
export const OWN_TRUCKS_RADIUS_SEARCH_LIMIT = 1000;

export const certificatesItemsNA = [
    {label: certificates.hazmat.text, value: certificates.hazmat.value},
    {label: certificates.tsa.title, value: certificates.tsa.value},
    {label: certificates.twic.title, value: certificates.twic.value},
    {label: certificates.tanker_endorsement.title, value: certificates.tanker_endorsement.value},
];

export const certificatesItemsEU = [
    {label: certificates.adr.title, value: certificates.adr.value},
    {label: certificates.loi_macron.title, value: certificates.loi_macron.value},
    {label: certificates.mi_log.title, value: certificates.mi_log.value},
];

export const crossBorderNA = [
    {label: countries.CA.name, value: countries.CA.code},
    {label: countries.MX.name, value: countries.MX.code},
];

export const crossBorderEU = [
    {label: countries.GB.name, value: countries.GB.code},
    {label: countries.CH.name, value: countries.CH.code},
];

export const weightsUnit = Object.values(weightUnits).map((unit) => ({label: unit, value: unit, btnTitle: unit}));
export const dimsUnit = Object.values(lengthUnits).map((unit) => ({label: unit, value: unit, btnTitle: unit}));

export const TNB_TABS = {
    PARTNERS: 'PARTNERS',
    OWN: 'OWN',
} as const;
