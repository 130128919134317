import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';

import {NormalizedFile} from 'components/ui/Files/FileUpload/types';
import {normalizeFile} from 'components/ui/Files/FileUpload/utils';

import StoredFile from 'types/File';

interface FileGroup {
    title: string;
    files: StoredFile[] | StoredFile[][];
}

export default function getGalleryFiles(files: FileGroup[]): NormalizedFile[] {
    if (isEmpty(files)) {
        return [];
    }

    return files.reduce(function (result: NormalizedFile[], fileGroup) {
        const flattenedFiles: StoredFile[] = flatten(fileGroup.files);
        const normalizedFiles: NormalizedFile[] = flattenedFiles.map(
            (file: StoredFile) => normalizeFile(file) as NormalizedFile,
        );

        result.push(...normalizedFiles);
        return result;
    }, []);
}
