import {getTravelOrderStopByID} from 'core/entities/TravelOrder/modules/travelOrderStops/getTravelOrderStops';
import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';
import {STOP_STATUSES} from 'core/entities/TravelOrder/constants';

export const isTravelOrderStopStatusInProgress = (travelOrderStop?: TravelOrderStop): boolean =>
    travelOrderStop?.status === STOP_STATUSES.IN_PROGRESS;

export const isTravelOrderStopStatusCompleted = (travelOrderStop?: TravelOrderStop): boolean =>
    travelOrderStop?.status === STOP_STATUSES.COMPLETED;

export const isTravelOrderStopStatusPlanned = (travelOrderStop?: TravelOrderStop): boolean =>
    travelOrderStop?.status === STOP_STATUSES.PLANNED;

export const getTravelOrderStopStatusesByID = (
    params: StopInfoParams,
): {isStopInProgress: boolean; isStopCompleted: boolean; isStopPlanned: boolean} => {
    const travelOrderStop = getTravelOrderStopByID(params);

    const isStopInProgress = isTravelOrderStopStatusInProgress(travelOrderStop);
    const isStopCompleted = isTravelOrderStopStatusCompleted(travelOrderStop);
    const isStopPlanned = isTravelOrderStopStatusPlanned(travelOrderStop);

    return {isStopInProgress, isStopCompleted, isStopPlanned};
};
