import getText from 'utils/getText';

export const modalNames = {
    payModal: 'PAY MODAL',
    successModal: 'SUCCESS MODAL',
    dispatchReDispatchModal: '(RE)DISPATCH MODAL',
    confirmDispatchReDispatchModal: 'CONFIRM (RE)DISPATCHED MODAL',
    confirmDryRunModal: 'CONFIRM_DRY_RUN_MODAL',
    checkInModal: 'CHECK IN MODAL',

    verificationPickUpModal: 'VERIFICATION PICK UP INFO MODAL',
    verificationDeliveryModal: 'VERIFICATION GTG MODAL',

    finishModal: 'FINISH MODAL',

    cancelCheckOutModal: 'CANCEL CHECK OUT MODAL',
    cancelCheckInModal: 'CANCEL CHECK IN MODAL',
    cancelSignPODModal: 'CANCEL SIGN POD MODAL',
    cancelAddBOLModal: 'CANCEL ADD BOL MODAL',
    cancelFinishModal: 'CANCEL FINISH MODAL',

    addProofOfDeliveryModal: 'ADD PROOF OF DELIVERY INFORMATION MODAL',
    addBillOfLadingModal: 'ADD BILL OF LADING INFORMATION MODAL',
    deadheadDetailsModal: 'DEADHEAD DETAILS MODAL',
    dryRunDetailsModal: 'DRY_RUN_DETAILS_MODAL',
    pickUpDetailsModal: 'PICK UP DETAILS MODAL',
    deliveryDetailsModal: 'DELIVERY DETAILS MODAL',

    successfulFinishCarrierModal: 'SUCCESSFUL FINISH CARRIER MODAL',
    successfulFinishTruckModal: 'SUCCESSFUL FINISH TRUCK MODAL',

    errorChangeTravelOrderStatusModal: 'ERROR CHANGE TRAVEL ORDER STATUS MODAL',

    cancelNotPlannedTOWithoutPlannedTOModal: 'CANCEL NOT PLANNED TO WITHOUT PLANNED TO MODAL',
    cancelNotPlannedTOWithPlannedTOModal: 'CANCEL NOT PLANNED TO WITH PLANNED TO MODAL',
    cancelPlannedTravelOrderModal: 'CANCEL PLANNED TRAVEL ORDER MODAL',

    cancelDryRunModal: 'CANCEL_DRY_RUN_MODAL',
    notSignedBolModal: 'NOT_SIGNED_BOL_MODAL',
    dryRun: 'DRY_RUN_MODAL',
    cancelPickUpDelivery: 'CANCEL TRAVEL ORDER PICKUP DELIVERY MODAL',
    cancelFinish: 'CANCEL TRAVEL ORDER FINISH MODAL',
    addBrokerUpdateModal: 'BROKER UPDATE MODAL',

    checkCallLocationEventModal: 'CHECK CALL LOCATION EVENT MODAL',
    confirmCheckCallLocationEventModal: 'CONFIRM CHECK CALL LOCATION EVENT MODAL',
    deleteCheckCallLocationEventModal: 'DELETE CHECK CALL LOCATION EVENT MODAL',
    viewBrokerUpdateLocationEventModal: 'VIEW BROKER UPDATE LOCATION EVENT MODAL',

    addTravelOrderNoteModal: 'TRAVEL ORDER NOTES MODAL',
    addLoadNoteModal: 'LOAD NOTES MODAL',
    timerSettings: 'TIMER SETTINGS MODAL',
    emailAttachmentsModal: 'EMAIL ATTACHMENTS MODAL',
    addTravelOrderGeneralNoteModal: 'ADD TRAVEL ORDER GENERAL NOTE MODAL',
};

export const TABS = {
    progressInfo: 'progress-info',
    tripSummary: 'trip-summary',
    infoAndUpdates: 'events',
    locationEvents: 'location-events',
    changesLog: 'changes-log',
    files: 'files',
};

export const signedRcFileButton = `Upload Signed ${getText('rc')}`;

export const DISPATCH_FORM_NAME = 'travel-order-dispatch-form';

//  CANCEL TRAVEL ORDER FORM NAMES
export const NOT_PLANNED_TO_WITH_PLANNED_TO = 'cancel-not-planned-travel-order-with-planned-travel-order';
export const NOT_PLANNED_TO_WITHOUT_PLANNED_TO = 'cancel-not-planned-travel-order-without-planned-travel-order';
