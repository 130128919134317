import isEmpty from 'lodash/isEmpty';

import * as formTypes from 'pages/TravelOrders/types/formTypes';

import {getCorrespondingAttachments} from '../index';

export const transformSignedAttachmentsToRequestBody = (params: {
    data:
        | formTypes.EditDeadheadFilesFormValues
        | formTypes.DryRunConfirmFormValues
        | formTypes.ConfirmModalFormValues
        | formTypes.DryRunModalFormValues;
    filesIds: Record<number, string>;
}): {uploadedFormData: FormData | null} => {
    const {data, filesIds} = params;

    if (isEmpty(data)) {
        return {uploadedFormData: null};
    }

    const {attachments} = data;

    const correspondingAttachments = getCorrespondingAttachments({
        formAttachments: attachments || [],
        filesIds,
    });

    return correspondingAttachments;
};
