export const RECEIVED_CARRIER = 'CARRIER_RECEIVED_CARRIER';
export const CREATED_CARRIER = 'CARRIER_CREATED_CARRIER';
export const UPDATED_CARRIER = 'CARRIER_UPDATED_CARRIER';
export const CARRIER_ARCHIVED = 'CARRIER_ARCHIVED';
export const CARRIER_RESTORED = 'CARRIER_RESTORED';
export const FORM_TAB_CHANGED = 'CARRIER_FORM_TAB_CHANGED';
export const CLEAR_STATE = 'CARRIER_CLEAR_STATE';
export const RECEIVED_CARRIERS = 'CARRIERS_RECEIVED_CARRIERS';
export const SET_SEARCH_PARAMS = 'CARRIERS_SET_SEARCH_PARAMS';
export const SET_PAGINATION = 'CARRIERS_SET_PAGINATION';
