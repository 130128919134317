import Load from 'core/entities/Load/types';

import {AddLoadNote, AddLoadNotesResult, LoadApiGateway} from '../types';
import {getLoad as getLoadUseCase} from '../index';

const addLoadNotes = async function (
    loadNumber: number,
    data: AddLoadNote,
    deps: {
        loadApiGateway: LoadApiGateway;
    },
): Promise<AddLoadNotesResult> {
    const {loadApiGateway} = deps;

    const load = {number: loadNumber} as Load;

    await loadApiGateway.addLoadGeneralNote({
        load,
        note: data.note,
        isImportant: data.isImportant,
    });
    const {load: loadUpdate} = await getLoadUseCase({loadNumber});

    return {
        updatedLoad: loadUpdate,
    };
};

export default addLoadNotes;
