import {AppState} from 'store';

import {AttachState} from '../reducers/attach';

export const getSearchCarriers = (state: AppState): AttachState['searchCarriers'] => state.loads.attach.searchCarriers;
export const getAttachModalActiveTab = (state: AppState): AttachState['activeTab'] => state.loads.attach.activeTab;
export const checkIsAvailableMoreTrucks = (state: AppState): boolean => state.loads.attach.isAvailableMoreTrucks;
export const getCarriers = (state: AppState): AttachState['carriers'] => state.loads.attach.carriers;
export const getCarriersSearchParams = (state: AppState) => state.loads.attach.searchParams.carriers;
export const getTrucksSearchParams = (state: AppState) => state.loads.attach.searchParams.trucks;
export const getTrucks = (state: AppState): AttachState['trucks'] => state.loads.attach.trucks;
export const getTrucksPagination = (state: AppState) => state.loads.attach.pagination.trucks;
