import React from 'react';
import isArray from 'lodash/isArray';

import composeFacilityCityLine from 'core/entities/Facility/composeFacilityCityLine';

import {Facility} from 'core/entities/Facility/types';

const getFacilitiesListWithFullAddress = (facility: Facility) => {
    const address: string | JSX.Element = facility.address_line ? facility.address_line : <em> no info</em>;
    const cityLine: string = composeFacilityCityLine(facility);
    const labelElement: JSX.Element = (
        <div>
            <span>{facility.name}</span>
            <span> {address}</span>
            <span> {cityLine}</span>
        </div>
    );

    return {
        value: facility,
        label: labelElement,
    };
};

export const getFacilitiesList = (facilities: Facility[], valueAsObject, isFullAddressShowing) => {
    if (!facilities || !isArray(facilities)) {
        return [];
    }

    return facilities.map((facility: Facility) => {
        if (isFullAddressShowing) {
            return getFacilitiesListWithFullAddress(facility);
        }

        return {
            value: valueAsObject ? facility : facility.id,
            label: facility.name,
        };
    });
};
