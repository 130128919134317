import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';
import {getTravelOrderStops} from 'core/entities/TravelOrder/modules/travelOrderStops';
import TravelOrder from 'core/entities/TravelOrder/types';

export const getTravelOrderLastDeliveryStop = (travelOrder?: TravelOrder | null): TravelOrderStop | undefined => {
    const toStops = getTravelOrderStops(travelOrder);
    const lastDeliveryStop = toStops.find((stop) => travelOrder?.lastDelivery === stop.id);

    return lastDeliveryStop;
};
