import {transferTOFileToNewFileInterface} from 'core/entities/TravelOrder/modules/travelOrderAttachments';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';

import {FileNew, TOFile} from 'types/File';

import {getTravelOrderStopByID, getTravelOrderStopLoadStopInfoByID, getTravelOrderStopPODsByID} from '..';

export const getTravelOrderPickUpFilesByID = (params: StopInfoParams): TOFile[] => {
    const {stopID, travelOrder} = params;

    const {isPickup} = getTravelOrderStopLoadStopInfoByID({stopID, travelOrder});
    const toStop = getTravelOrderStopByID({stopID, travelOrder});

    return isPickup ? toStop?.files || [] : [];
};

export const getTravelOrderDeliveryFilesByID = (params: StopInfoParams): TOFile[] => {
    const {stopID, travelOrder} = params;

    const {isDelivery} = getTravelOrderStopLoadStopInfoByID({stopID, travelOrder});
    const pods = getTravelOrderStopPODsByID({stopID, travelOrder});

    const podsFiles = pods.map((pod) => pod.files).flat();

    return isDelivery ? podsFiles : [];
};

export const getTravelOrderStopFilesByID = (params: StopInfoParams): TOFile[] => {
    const {stopID, travelOrder} = params;

    const travelOrderDeliveryFilesByID = getTravelOrderDeliveryFilesByID({stopID, travelOrder});
    const {isDelivery, isPickup} = getTravelOrderStopLoadStopInfoByID({stopID, travelOrder});
    const travelOrderPickUpFilesByID = getTravelOrderPickUpFilesByID({stopID, travelOrder});

    const deliveryFiles = isDelivery ? travelOrderDeliveryFilesByID : null;
    const pickUpFile = isPickup ? travelOrderPickUpFilesByID : null;

    return deliveryFiles || pickUpFile || [];
};

export const getTravelOrderStopTransferredFilesByID = (params: StopInfoParams): Partial<FileNew>[] => {
    const toStopFiles = getTravelOrderStopFilesByID(params);
    const stop = getTravelOrderStopByID(params);

    return toStopFiles.map((file, fileIndex) => transferTOFileToNewFileInterface({file, fileIndex, stop}));
};
