import {InferActions} from 'store';

import Load from 'core/entities/Load/types';

import {NewNote} from 'types/NewNote';
import Pagination from 'types/Pagination';

import {SearchParams} from '../actions/listActions/loadsSearchActions';
import * as actionTypes from '../actionTypes/listActionTypes';

export const actionCreators = {
    receiveLoadNote: (payload: {loadNotes: NewNote[]; loadNumber: number}) =>
        ({type: actionTypes.RECEIVE_NOTE, payload} as const),
    receiveLoad: (payload: {load?: Load}) => ({type: actionTypes.RECEIVE_LOAD, payload} as const),
    fetchLoads: (payload: {loads: Load[]; pagination: Pagination}) =>
        ({type: actionTypes.LIST_DATA_RECEIVED, payload} as const),
    clearState: () => ({type: actionTypes.CLEAR_STATE} as const),
    advancedSearchParams: (payload: SearchParams | {}) =>
        ({type: actionTypes.SET_ADVANCED_SEARCH_PARAMS, payload} as const),
    setStatusSearchParams: (payload: string[]) => ({type: actionTypes.SET_STATUS_SEARCH_PARAMS, payload} as const),
    listDataCleared: () => ({type: actionTypes.LIST_DATA_CLEARED} as const),
    travelOrderCreated: (payload: {loadNumber: number}) => ({type: actionTypes.TRAVEL_ORDER_CREATED, payload} as const),
};

export type ActionsTypes = InferActions<typeof actionCreators>;
