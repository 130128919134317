import isObject from 'lodash/isObject';

import {statuses} from 'core/entities/Settlement/constants';
import {
    WEB_SOCKET_SETTLEMENTS_OWNERS_SETTLEMENT_UPDATED,
    WEB_SOCKET_SETTLEMENTS_OWNERS_PAY_PERIOD_CREATED,
    WEB_SOCKET_SETTLEMENTS_OWNERS_PAY_PERIOD_UPDATED,
    WEB_SOCKET_SETTLEMENTS_OWNERS_PAY_RECORD_UPDATED,
} from 'store/middlewares/mainAPISocketsIO/entities/settlementsOwners/actionTypes';

import {addItemToTable, updateItemInTable, updateItemsInTable} from './utils';

export default function settlementsOwnersWebsocketReducer(state, action, allPageState) {
    switch (action.type) {
        case WEB_SOCKET_SETTLEMENTS_OWNERS_SETTLEMENT_UPDATED: {
            const updatedSettlement = action.payload;
            if (!updatedSettlement || !isObject(updatedSettlement)) {
                return state;
            }

            const isUpdatedSettlementInCurrentState = Boolean(state.settlements.byId[updatedSettlement.id]);

            if (isUpdatedSettlementInCurrentState) {
                return {...state, settlements: updateItemInTable(state.settlements, updatedSettlement)};
            }

            const currentListStatus = allPageState?.list?.groupStatus;
            // as example for case when we view list "Rated" settlements and some users reset some settlement
            // from "Ready to Post" or from "Posted" status so we should add this updated settlement to current state
            if (currentListStatus === statuses.RATED) {
                return {...state, settlements: addItemToTable(state.settlements, updatedSettlement)};
            }

            return state;
        }

        case WEB_SOCKET_SETTLEMENTS_OWNERS_PAY_PERIOD_CREATED: {
            const createdPayPeriod = action.payload;

            if (!createdPayPeriod || !isObject(createdPayPeriod) || state.payPeriods.byId[createdPayPeriod.id]) {
                return state;
            }

            return {
                ...state,
                payPeriods: addItemToTable(state.payPeriods, createdPayPeriod, {toBegin: true}),
                allPayPeriods: [...state.allPayPeriods, createdPayPeriod],
            };
        }

        case WEB_SOCKET_SETTLEMENTS_OWNERS_PAY_PERIOD_UPDATED: {
            const updatedPayPeriod = action.payload;

            if (!updatedPayPeriod || !isObject(updatedPayPeriod)) {
                return state;
            }

            const isUpdatedPeriodInCurrentState = Boolean(state.payPeriods.byId[updatedPayPeriod.id]);
            const isUpdatedPeriodInCommonList = state.allPayPeriods.find((p) => p.id === updatedPayPeriod.id);

            if (!isUpdatedPeriodInCurrentState && !isUpdatedPeriodInCommonList) {
                return state;
            }

            const allPayPeriods = isUpdatedPeriodInCommonList
                ? state.allPayPeriods.map((p) => (p.id === updatedPayPeriod.id ? updatedPayPeriod : p))
                : state.allPayPeriods;
            const payPeriods = isUpdatedPeriodInCurrentState
                ? updateItemsInTable(state.payPeriods, [updatedPayPeriod])
                : state.payPeriods;
            return {
                ...state,
                allPayPeriods,
                payPeriods,
            };
        }

        case WEB_SOCKET_SETTLEMENTS_OWNERS_PAY_RECORD_UPDATED: {
            const updatedPayRecord = action.payload;
            if (!updatedPayRecord || !isObject(updatedPayRecord)) {
                return state;
            }

            const isUpdatedPayRecordInCurrentState = updatedPayRecord.id in state.payRecords.byId;

            if (isUpdatedPayRecordInCurrentState) {
                return {...state, payRecords: updateItemInTable(state.payRecords, updatedPayRecord)};
            }

            return state;
        }

        default:
            return state;
    }
}
