import {getEntityFieldName} from './types';

export const bookingInfoFields = {
    userID: 'user',
    dispatcher: 'dispatcher',
    bookedWith: getEntityFieldName('customer'),
    agents: 'agents_to_load',
    sender: 'sender',
    emails: getEntityFieldName('emails'),
    sendUpdates: getEntityFieldName('send_updates'),

    refNumber: getEntityFieldName('ref_number'),
    // dynamic field
    refNumbersList: 'refNumbersList',
    emailsList: 'emailsList',

    totalRate: getEntityFieldName('total_rate'),
    currency: 'currency',

    rcDocs: 'rc',
    bolDocs: 'bol',
    podDocs: 'pod',
    files: 'files',
    extraFiles: 'extra_files',
};

export const originDestinationFields = {
    status: getEntityFieldName('status'),
    tempMin: getEntityFieldName('temp_min'),
    tempMax: getEntityFieldName('temp_max'),
    checkInAs: getEntityFieldName('check_in_as'),
    carrier: 'carrier',
    pickUpInfo: 'pick_up_info',
    shipper: 'shippers',
    isPickUp: 'is_pick_up',
    dispatcher: 'dispatcher',
    urgency: 'urgency',
    sendTimeToDriver: 'send_time',
    broker: 'broker',
    customer: getEntityFieldName('customer'),
    date: 'date',
    dispatcher_note: 'dispatcher_note',
    timeStart: 'time_start',
    timeEnd: 'time_end',
    timeZone: 'timezone',
    truckDriversTeam: getEntityFieldName('is_team'),
    truckType: getEntityFieldName('truck_type'),
    travelOrder: 'travel_order',
    pickUpDeliveryNote: getEntityFieldName('note'),
    noteToTruck: getEntityFieldName('note_to_truck'),
    notes: 'load_notes',
    isDriversTeam: getEntityFieldName('is_team'),
    freightInfo: 'freight_info',
    freightUnit: 'unit',
    freightPieces: 'pieces',
    freightWeight: 'weight',
    freightDimsLength: 'dims_length',
    freightDimsHeight: 'dims_height',
    freightDimsWidth: 'dims_width',
    freightStackable: 'is_stackable',
    freightHazmat: 'is_hazmat',
    shipperLink: 'shippers',
    dispatchersAssignToLoad: 'dispatchers_assign_to_load',
};

export const otherFields = {
    loadNumber: 'load_id',
    notAssigned: 'notAssigned',
    loadType: 'loadType',
    distance: getEntityFieldName('distance'),
    loadRoute: getEntityFieldName('load_route'),
};

export default {...bookingInfoFields, ...originDestinationFields, ...otherFields};
