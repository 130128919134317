import React, {Fragment} from 'react';

import {PayRecord} from 'core/entities/Settlement/types';
import CurrencyAmount from 'components/ui/CurrencyAmount';

interface OwnProps {
    payRecord: PayRecord;
}

const PayRecordTotalPays = ({payRecord}: OwnProps): JSX.Element => {
    const allPays = payRecord.total_payments;
    if (!allPays || allPays.length === 0) {
        return <CurrencyAmount currency="USD" amount={0} />;
    }
    const allPaysItems = allPays.map((payment) => {
        return (
            <Fragment key={payment.currency}>
                <CurrencyAmount currency={payment.currency} amount={payment.amount} /> <br />{' '}
            </Fragment>
        );
    });
    return <span>{allPaysItems}</span>;
};

export default PayRecordTotalPays;
