import Carrier from 'core/entities/Carrier/types';

export const getCarrierBillingAdditionalAddressLine = (carrier?: Carrier | null) =>
    carrier?.billing_additional_address_line;

export const getCarrierBillingAddressLine = (carrier?: Carrier | null) => carrier?.billing_address_line;

export const getCarrierBillingCountry = (carrier?: Carrier | null) => carrier?.billing_country;

export const getCarrierBillingState = (carrier?: Carrier | null) => carrier?.billing_state;

export const getCarrierBillingCity = (carrier?: Carrier | null) => carrier?.billing_city;

export const getCarrierBillingZip = (carrier?: Carrier | null) => carrier?.billing_zip;

type CarrierBillingAddressAllInfo = {
    carrierBillingAdditionalAddressLine: ReturnType<typeof getCarrierBillingAdditionalAddressLine>;
    carrierBillingAddressLine: ReturnType<typeof getCarrierBillingAddressLine>;
    carrierBillingCountry: ReturnType<typeof getCarrierBillingCountry>;
    carrierBillingState: ReturnType<typeof getCarrierBillingState>;
    carrierBillingCity: ReturnType<typeof getCarrierBillingCity>;
    carrierBillingZip: ReturnType<typeof getCarrierBillingZip>;
};

export const getCarrierBillingAddressAllInfo = (carrier?: Carrier | null): CarrierBillingAddressAllInfo => {
    return {
        carrierBillingAdditionalAddressLine: getCarrierBillingAdditionalAddressLine(carrier),
        carrierBillingAddressLine: getCarrierBillingAddressLine(carrier),
        carrierBillingCountry: getCarrierBillingCountry(carrier),
        carrierBillingState: getCarrierBillingState(carrier),
        carrierBillingCity: getCarrierBillingCity(carrier),
        carrierBillingZip: getCarrierBillingZip(carrier),
    };
};
