import React, {useState, useEffect} from 'react';

import {getCurrentDate} from 'utils/dateTime';

import './style.scss';

const convertSecondsToMS = (seconds) => {
    const msInSecond = 1000;
    return seconds * msInSecond;
};

const CurrentTime = (): JSX.Element => {
    const [currentTime, setCurrentTime] = useState<string>('');
    const [currentZone, setCurrentZone] = useState<string>('');
    useEffect(() => {
        const currentDate = getCurrentDate();

        setCurrentTime(currentDate.time);
        setCurrentZone(currentDate.timeZoneCode);

        const interval = setInterval(() => {
            const updatedDate = getCurrentDate();

            setCurrentTime(updatedDate.time);
        }, convertSecondsToMS(1));
        return () => clearInterval(interval);
    }, []);
    return (
        <div>
            <div className="clock-container">
                <div className="time-container">{currentTime}</div>
                <div className="timezone-container">{currentZone}</div>
            </div>
        </div>
    );
};

export default CurrentTime;
