import uniqBy from 'lodash/uniqBy';
import {WEB_SOCKET_INVOICE_SETTLEMENT_UPDATED} from 'store/middlewares/mainAPISocketsIO/entities/invoices/actionTypes';
import {isChangeLocationAction} from 'routing/utils/index';

import {Settlement} from 'core/entities/Settlement/types';
import {getInvoiceFilesHistory} from 'core/entities/Invoice';

import * as types from '../actionTypes/index';
import {getInvoiceFiles, getStopsIncludedToPDF} from './utils';
import locationChangeCommonReducer from './locationChangeCommonReducer';

type InvoiceState = {
    settlement: Settlement | null;
    invoiceFiles: unknown[];
    stopsIncludedToPDF: unknown[];
    travelOrderFiles: unknown[];
};

const defaultState: InvoiceState = {
    settlement: null,
    invoiceFiles: [],
    stopsIncludedToPDF: [],
    travelOrderFiles: [],
};

const invoiceReducer = (state = defaultState, action) => {
    if (isChangeLocationAction(action)) {
        return locationChangeCommonReducer(state, defaultState, action);
    }
    switch (action.type) {
        case types.INVOICE_DATA_RECEIVED: {
            const {
                invoice,
                settlement,
                invoiceBatches,
                travelOrderFiles,
                travelOrderNotes,
                loadFiles,
                loadNotes,
            } = action.payload;
            return {
                ...state,
                settlement,
                invoiceFiles: getInvoiceFiles(invoice, invoiceBatches),
                travelOrderFiles,
                travelOrderNotes,
                loadFiles,
                loadNotes,
                stopsIncludedToPDF: getStopsIncludedToPDF(invoice),
                isNeedApproveSettlement: false,
            };
        }

        case types.INVOICE_LOAD_AND_TRAVEL_ORDER_UPDATED_FILES: {
            const {files} = action.payload;

            const allFilesWithoutDuplicate = uniqBy([...state.travelOrderFiles, ...files], 'id');

            return {
                ...state,
                travelOrderFiles: allFilesWithoutDuplicate,
            };
        }

        case types.INVOICE_FILES_UPDATED: {
            const {updatedInvoice} = action.payload;

            return {
                ...state,
                invoiceFiles: {...state?.invoiceFiles, invoice: getInvoiceFilesHistory(updatedInvoice)},
            };
        }

        case types.INVOICE_TOGGLE_INCLUDE_LOAD_STOP_TO_PDF: {
            const stopID = action.payload;
            const isIncluded = state.stopsIncludedToPDF.includes(stopID);
            return {
                ...state,
                stopsIncludedToPDF: isIncluded
                    ? state.stopsIncludedToPDF.filter((item) => item !== stopID)
                    : [...state.stopsIncludedToPDF, stopID],
            };
        }

        case WEB_SOCKET_INVOICE_SETTLEMENT_UPDATED: {
            if (!action.payload) {
                return state;
            }
            const {updatedSettlement} = action.payload;
            const updatedSettlementID = updatedSettlement?.id;
            const currentSettlementID = state?.settlement?.id;
            // for case when we have some update from another user updates another settlement
            // we should not update current data in state
            if (currentSettlementID !== updatedSettlementID) {
                return state;
            }
            return {
                ...state,
                settlement: updatedSettlement,
            };
        }

        default:
            return state;
    }
};

export default invoiceReducer;
