import React from 'react';

import {Dimensions} from 'core/entities/AppSettings/types';

import UnitOfMeasurementSkipField from 'components/ui/Form/UnitOfMeasurementSkipField';

import LENGTH_UNITS from 'utils/data/lengthUnits';

const dimensions: Array<{name: Dimensions; value: Dimensions}> = [
    {name: LENGTH_UNITS.FOOT, value: LENGTH_UNITS.FOOT},
    {name: LENGTH_UNITS.INCH, value: LENGTH_UNITS.INCH},
    {name: LENGTH_UNITS.METER, value: LENGTH_UNITS.METER},
    {name: LENGTH_UNITS.CENTIMETER, value: LENGTH_UNITS.CENTIMETER},
];

type OwnProps = {
    unit?: string;
    setUnit: (unit) => void;
    clearFields: () => void;
    disabled?: boolean;
};

const DimensionsUnitsOfMeasurement: React.FC<OwnProps> = (props) => {
    const {unit, setUnit, clearFields, disabled} = props;

    return (
        <UnitOfMeasurementSkipField
            unitOptions={dimensions}
            unit={unit}
            setUnit={setUnit}
            clearFields={clearFields}
            disabled={disabled}
        />
    );
};

export default DimensionsUnitsOfMeasurement;
