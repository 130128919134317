import React from 'react';
import classNames from 'classnames';

import {OpenedPayPeriod, PayPeriodResponsibleUser} from '../types';
import ListResponsibleUsers from './ListResponsibleUsers';
import styles from './payPeriodInfo.module.scss';

interface OwnProps {
    payPeriod: OpenedPayPeriod;
    isOpened: boolean;
    selectedUsers: PayPeriodResponsibleUser[];
    onClickHeader();
    onSelectUser(user: PayPeriodResponsibleUser);
    onSelectAllUsers(allUsers: PayPeriodResponsibleUser[]);
}

const PayPeriodInfo = ({
    payPeriod,
    isOpened,
    selectedUsers,
    onClickHeader,
    onSelectUser,
    onSelectAllUsers,
}: OwnProps): JSX.Element => {
    const isOpenClassName = classNames('settlements-list', {open: isOpened});

    return (
        <div className={styles.item}>
            <div className={isOpenClassName}>
                <div onClick={onClickHeader} className="settlements-list__header">
                    <div>
                        <div>
                            <strong>{payPeriod.title} (open)</strong>
                        </div>
                    </div>

                    <div>
                        <strong>{payPeriod.settlementsCount}</strong> settlements(s)
                    </div>
                </div>

                {isOpened && (
                    <ListResponsibleUsers
                        payPeriod={payPeriod}
                        selectedUsers={selectedUsers}
                        onSelectUser={onSelectUser}
                        onSelectAllUsers={onSelectAllUsers}
                    />
                )}
            </div>
        </div>
    );
};

export default React.memo(PayPeriodInfo);
