import React from 'react';
import {Field} from 'redux-form';
import {useSelector} from 'react-redux';

import {normalizeMultipleSelect} from 'utils';

import Select from 'components/ui/Form/Select';

import {getTruckAdditionalEquipment, getTruckBasicEquipment} from 'store/reducers/registry/selectors';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';

const Equipment: React.FC = (): JSX.Element => {
    const {getTruckEquipmentFieldName} = getFormFieldNames();

    const truckBasicEquipment = useSelector(getTruckBasicEquipment);
    const truckAdditionalEquipment = useSelector(getTruckAdditionalEquipment);

    const truckAllEquipment = [...truckBasicEquipment, ...truckAdditionalEquipment];

    return (
        <Field
            label="Equipment"
            name={getTruckEquipmentFieldName()}
            type="text"
            data={truckAllEquipment}
            component={Select}
            placeholder="choose or type the equipment"
            normalize={normalizeMultipleSelect}
            closeMenuOnSelect={false}
            isMulti
            isCreatable
        />
    );
};

export default Equipment;
