import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button/index';

const SuccessfulChangePasswordModal = (props) => {
    const {onClose} = props;

    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Successful Change Password</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row flex-block">
                    <div className="col-1">
                        <i className="fa fa-check feedback-icon-success" />
                    </div>
                    <div className="col-11">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <p>Driver's password has been changed successfully.</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div />
                <Button onClick={onClose} buttonSize="small" colorTheme="blue">
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SuccessfulChangePasswordModal;
