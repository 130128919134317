import React from 'react';
import {Field} from 'redux-form';
import RequiredLabel from 'components/ui/Form/RequiredLabel';

import TextInput from 'components/ui/Form/TextInput';
import {removeNonDigitChars} from 'utils';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';

const FreightPieces: React.FC = () => {
    const {getLoadFreightPiecesFieldName} = getFormFieldNames();
    return (
        <>
            <Field
                name={getLoadFreightPiecesFieldName()}
                component={TextInput}
                label={RequiredLabel('Pieces')}
                placeholder="pcs"
                maxLength="8"
                normalize={removeNonDigitChars}
            />
        </>
    );
};

export default FreightPieces;
