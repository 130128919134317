import React from 'react';
import {useDispatch} from 'react-redux';

import {openModal} from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

interface OwnProps {
    onDelete(): void;
    isShownDeleteButton: boolean;
}
const DeleteNote: React.FC<OwnProps> = (props) => {
    const {onDelete, isShownDeleteButton} = props;
    const dispatch = useDispatch();
    const handleNoteDelete = (): void => {
        const errorMessage = 'Are you sure you want to delete the note?';
        dispatch(
            openModal({
                modalName: commonModalNames.informationModal,
                data: {
                    title: `Delete Note`,
                    errorMessage,
                    leftButtonTitle: 'cancel',
                    rightButtonTitle: 'delete',
                    bodyType: 'ErrorForm',
                    buttonType: 'danger',
                },
                handlers: {
                    leftButtonHandler: () => {},
                    rightButtonHandler: () => onDelete(),
                },
            }),
        );
    };
    return (
        <>
            {isShownDeleteButton && (
                <a onClick={handleNoteDelete}>
                    <i className="fa fa-trash" aria-hidden="true" />
                </a>
            )}
        </>
    );
};

export default DeleteNote;
