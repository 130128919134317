export type LoadBoard = {
    name: string;
    id: number;
};

type Customer = {
    id: number;
    name: string;
    main_phone: string;
    after_hours_phone: string | null;
    on_hold_reason: string | null;
    fax: string | null;
    email: string | null;
    website: string | null;
    notes: string | null;
    is_customer: boolean;
    mc_number: string | null;
    identifier_number: string | null;
    identifier_type: string | null;
    reg_number: string | null;
    us_dot_number: string | null;
    billing_address_line: string | null;
    billing_additional_address_line: string | null;
    billing_city: string;
    billing_state: string;
    billing_zip: string;
    billing_country: string;
    billing_method: string;
    payment_terms: string | null;
    in_black_list: boolean;
    is_active: boolean;
    is_deleted: boolean;
    load_boards: LoadBoard[];
    customerType: string;
    customerTypeNote?: string;
    isThirdApiBroker: boolean;
    apiConnection?: string;
    isDispatchlandCustomer?: boolean;
    isMerging: boolean;

    externalApiIdentifier?: string | null;
    isExternalCustomer?: boolean;
};

export const getEntityFieldName = (name: keyof Customer): string => name;

export default Customer;
