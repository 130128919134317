import {FileNew, TOFile} from 'types/File';

import {ATTACH_TYPES} from 'core/entities/TravelOrder/constants/attachTypes';
import TravelOrder from 'core/entities/TravelOrder/types';

import {getTravelOrderFiles, transferTOFileToNewFileInterface} from '../index';

export const getTravelOrderOtherFiles = (travelOrder?: TravelOrder | null): TOFile[] => {
    const travelOrderFiles = getTravelOrderFiles(travelOrder);

    const otherFiles = (travelOrderFiles || []).filter(
        (attachment) => attachment.attachType === ATTACH_TYPES.TRAVEL_ORDER_OTHER_FILE,
    );

    return otherFiles;
};

export const transferTravelOrderOtherAttachments = (travelOrder?: TravelOrder | null): Partial<FileNew>[] => {
    const travelOrderOtherFiles = getTravelOrderOtherFiles(travelOrder);

    return travelOrderOtherFiles.map((file, fileIndex) => transferTOFileToNewFileInterface({file, fileIndex}));
};

export const getGroupedTravelOrderOtherAttachments = (params: {
    travelOrder?: TravelOrder | null;
}): {[key: string]: Partial<FileNew>[]} => {
    const {travelOrder} = params;

    const transferredTravelOrderOtherAttachments = transferTravelOrderOtherAttachments(travelOrder);

    return {'Other files': transferredTravelOrderOtherAttachments};
};

export const checkIsTravelOrderOtherFileType = (fileType: TOFile['attachType']): boolean =>
    fileType === ATTACH_TYPES.TRAVEL_ORDER_OTHER_FILE;
