import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';
import TravelOrder from 'core/entities/TravelOrder/types';

export const getTravelOrderStops = (travelOrder?: TravelOrder | null): TravelOrderStop[] => travelOrder?.toStops || [];

export const getTravelOrderStopByID = (params: StopInfoParams): TravelOrderStop | undefined =>
    getTravelOrderStops(params.travelOrder).find((item) => item.id === params.stopID);

export const getTravelOrderCurrentStopID = (travelOrder?: TravelOrder | null): string | undefined =>
    travelOrder?.currentStop;

export const getTravelOrderFirstPickUpStopID = (travelOrder?: TravelOrder | null): string | undefined =>
    travelOrder?.firstPickUp;

export const getTravelOrderLastDeliveryStopID = (travelOrder?: TravelOrder | null): string | undefined =>
    travelOrder?.lastDelivery;

export const getTravelOrderNextStopID = (travelOrder?: TravelOrder | null): string | null | undefined =>
    travelOrder?.nextStop;
