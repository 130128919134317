import React, {useCallback, useMemo} from 'react';
import {connect} from 'react-redux';
import {formValueSelector, reduxForm, change as reduxFormChange, InjectedFormProps, Field} from 'redux-form';
import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';

import Button from 'components/ui/Buttons/Button/index';

import {PayPeriod, PayRecord} from 'core/entities/Settlement/types';
import {getPayRecordsWithSettlements} from 'core/entities/Settlement';

import useFetchPayPeriodPayRecords from 'pages/Settlements/hooks/useFetchPayPeriodPayRecords';

import CloseReopenInfo from 'pages/Settlements/components/common/CloseReopenInfo';
import EmailNote from 'pages/Settlements/components/common/EmailNote';
import SendEmailButton from 'pages/Settlements/components/common/SendEmailButton';
import DataLoading from 'pages/Settlements/components/common/DataLoading';

import WithoutPayRecordsWarning from '../WithoutPayRecordsWarning';

import PayRecordsList from '../PayRecordsList';
import PayPeriodTotal from '../PayPeriodTotal';

import EmailsSettings from './EmailsSettings';
import FIELDS from './fields';
import * as selectors from './selectors';
import {isAllPayRecordsSelected} from './utils';
import styles from './sendPayPeriodEmails.module.scss';

const FORM_NAME = 'send-period-emails-modal';
const formSelector = formValueSelector(FORM_NAME);

interface OwnProps {
    payPeriod: PayPeriod;
    isPayPeriodCanBeReopened: boolean;
    onReopen(p: PayPeriod);
    onClose();
}

interface StateProps {
    isEmailNoteChanged: boolean;
    selectedPayRecords: {[key: string]: PayRecord};
    // eslint-disable-next-line react/no-unused-prop-types
    initialValues: any;
}

interface DispatchProps {
    onSelectPayRecord(p: PayRecord);
    onSelectAllPayRecords(payRecords: PayRecord[]);
}

type AllFormProps = OwnProps & StateProps & DispatchProps;

const SendPayPeriodEmails = (props: AllFormProps & InjectedFormProps<{}, AllFormProps>): JSX.Element => {
    const {
        handleSubmit,
        payPeriod,
        isEmailNoteChanged,
        isPayPeriodCanBeReopened,
        selectedPayRecords,
        onReopen,
        onClose,
        onSelectPayRecord,
        onSelectAllPayRecords,
    } = props;
    const {payRecords, isFetching, isAllFetched, fetchNextPage} = useFetchPayPeriodPayRecords({
        payPeriodID: payPeriod.id,
        useSearchParams: false,
        useDynamicPagination: true,
    });
    const handleSelectAllPayRecords = useCallback(() => {
        onSelectAllPayRecords(payRecords);
    }, [payRecords]);
    const payRecordsWithSettlements = getPayRecordsWithSettlements(payRecords);
    const isAllPayRecordsEmailsSelected = useMemo(() => isAllPayRecordsSelected(selectedPayRecords, payRecords), [
        selectedPayRecords,
        payRecords,
    ]);
    const isAllExistsPayRecordsSelected = isAllPayRecordsEmailsSelected && isAllFetched;
    const isEmailSentForAnyPayRecord = useMemo(() => {
        return payRecords.some((p) => p.is_sent);
    }, [payRecords]);
    const isNeedSendEmail = !isEmpty(selectedPayRecords);
    const isPayRecordWithoutSettlements = !isFetching && payRecordsWithSettlements.length === 0;
    const loaderClassName = payRecords.length === 0 ? styles.loader : '';
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className="custom-scroll">
                <CloseReopenInfo
                    closedBy={payPeriod.closed_by}
                    closedWhen={payPeriod.closed_at}
                    isCanBeReopened={isPayPeriodCanBeReopened}
                    reopenTitle="open period"
                    onReopen={onReopen}
                />
                {payRecordsWithSettlements.length > 0 && (
                    <div className="modal-body__main">
                        <EmailsSettings
                            isAllVisiblePayRecordsSelected={isAllPayRecordsEmailsSelected}
                            isAllExistsPayRecordsSelected={isAllExistsPayRecordsSelected}
                            onSelectAllVisible={handleSelectAllPayRecords}
                        />
                        <PayRecordsList
                            payRecords={payRecordsWithSettlements}
                            withSelectEmail={true}
                            selectedPayRecords={selectedPayRecords}
                            onSelectPayRecord={onSelectPayRecord}
                        />
                    </div>
                )}

                {isPayRecordWithoutSettlements && <WithoutPayRecordsWarning />}

                <div className={loaderClassName}>
                    <DataLoading
                        isLoading={isFetching}
                        isAllLoaded={isAllFetched}
                        loadMoreTitle="load more pay records"
                        onLoadMore={fetchNextPage}
                    />
                </div>

                <Field
                    name={FIELDS.emailNote}
                    title="Pay Period Note"
                    component={EmailNote}
                    isEmailSent={isEmailSentForAnyPayRecord}
                />
            </div>

            <PayPeriodTotal payPeriod={payPeriod} />

            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <SendEmailButton isEmailNoteChanged={isEmailNoteChanged} isNeedSendEmail={isNeedSendEmail} />
                </div>
            </div>
        </form>
    );
};

const ReduxForm = reduxForm<{}, AllFormProps>({
    form: FORM_NAME,
})(SendPayPeriodEmails);

export default connect(
    (state, ownProps: OwnProps): StateProps => ({
        isEmailNoteChanged: formSelector(state, FIELDS.emailNote) !== ownProps.payPeriod.note_to_email,
        selectedPayRecords: formSelector(state, FIELDS.payRecords),
        initialValues: selectors.getSendPayPeriodEmailsInitialData(state, ownProps),
    }),
    (dispatch): DispatchProps => {
        const getCurrentPayRecords = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return dispatch((_, getState) => {
                return formSelector(getState(), FIELDS.payRecords);
            });
        };
        const addSelectedPayRecord = (currentPayRecords, selectedPayRecord) => {
            const addAction = reduxFormChange(FORM_NAME, FIELDS.payRecords, {
                ...currentPayRecords,
                [selectedPayRecord.id]: selectedPayRecord,
            });
            dispatch(addAction);
        };
        const removeSelectedPayRecord = (currentPayRecords, selectedPayRecord) => {
            const removeAction = reduxFormChange(
                FORM_NAME,
                FIELDS.payRecords,
                omit(currentPayRecords, [selectedPayRecord.id]),
            );
            dispatch(removeAction);
        };
        const selectAllPayRecords = (allPayRecords) => {
            const selectedAllPayRecords = mapValues(keyBy(allPayRecords, 'id'), (value) => {
                return {
                    ...value,
                    is_send_owner: value?.owner?.is_send_owner || undefined,
                    is_send_coordinator: value?.coordinator?.is_send_coordinator || undefined,
                };
            });

            const addAllAction = reduxFormChange(FORM_NAME, FIELDS.payRecords, selectedAllPayRecords);
            dispatch(addAllAction);
        };
        const unSelectAllPayRecords = () => {
            dispatch(reduxFormChange(FORM_NAME, FIELDS.payRecords, {}));
        };
        return {
            onSelectPayRecord: (
                selectedPayRecord: PayRecord & {is_send_owner?: boolean; is_send_coordinator?: boolean},
            ) => {
                const currentPayRecords = getCurrentPayRecords();
                const {id, is_send_coordinator, is_send_owner} = selectedPayRecord;
                const checkSelectPayRecord = id in currentPayRecords && !is_send_owner && !is_send_coordinator;
                if (checkSelectPayRecord) {
                    removeSelectedPayRecord(currentPayRecords, selectedPayRecord);
                } else {
                    addSelectedPayRecord(currentPayRecords, selectedPayRecord);
                }
            },

            onSelectAllPayRecords: (allPayRecords: PayRecord[]) => {
                const currentPayRecords = getCurrentPayRecords();
                if (isAllPayRecordsSelected(currentPayRecords, allPayRecords)) {
                    unSelectAllPayRecords();
                } else {
                    selectAllPayRecords(allPayRecords);
                }
            },
        };
    },
)(ReduxForm);
