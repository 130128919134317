import React from 'react';
import classNames from 'classnames';
import sumBy from 'lodash/sumBy';

import {OpenedCompany, BatchResponsibleUser} from '../types';

import UsersPayments from './components/UsersPayments';

interface OwnProps {
    selectedBatch: OpenedCompany | undefined;
}

const getLengthOfSelectedSettlements = (selectedBatch: OpenedCompany | undefined): number => {
    if (!selectedBatch) {
        return 0;
    }
    return sumBy(selectedBatch.users, (user: BatchResponsibleUser) => {
        return user.settlements_count;
    });
};

const SelectedBatchInfo = ({selectedBatch}: OwnProps): JSX.Element => {
    const selectedSettlementsLength = getLengthOfSelectedSettlements(selectedBatch);
    const selectedSettlementsClassName = classNames({'no-invoices': selectedSettlementsLength === 0});
    return (
        <div className="modal-body__select-info">
            <div className={selectedSettlementsClassName}>
                <div>
                    {!selectedBatch ? (
                        <strong>Selected: no batch, 0 settlements, $0</strong>
                    ) : (
                        <div>
                            <div>
                                <strong>
                                    Selected: {selectedBatch.title}, {selectedSettlementsLength} settlement(s)
                                </strong>
                            </div>
                            <div>
                                <UsersPayments users={selectedBatch.users} />
                            </div>
                        </div>
                    )}
                </div>
                <p>Press post to move chosen settlements in its pay period.</p>
            </div>
        </div>
    );
};

export default React.memo(SelectedBatchInfo);
