import values from 'lodash/values';
import map from 'lodash/map';

import {getTimeZoneAbbreviation, coordinatedUniversalTime} from 'utils/dateTime';
import {TIME_ZONES} from 'utils/data/timeZones';

const createLongLabel = (city: string): string =>
    `${getTimeZoneAbbreviation(city)} - ${city} (UTC ${coordinatedUniversalTime(city)})`;

export const createTimeZoneOptionsWithLongLabel = () =>
    map(values(TIME_ZONES), (zone) => ({
        label: getTimeZoneAbbreviation(zone.name),
        longLabel: createLongLabel(zone.name),
        value: zone.name,
    }));
