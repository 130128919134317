import {
    RawCustomMarkers,
    CrossDocsRawData,
    RentTrucksEastRawData,
    RentTrucksRestRawData,
    CustomMarkers,
    CustomMarker,
    AllMarkerTypes,
    AllMarkerGroups,
    CrossDocStrictTime,
    CrossDocAroundClockTime,
    CrossDocFlexibleTime,
    PenskeCompanyRentTrucksRest,
    RyderCompanyRentTrucksRest,
    PenskeCompanyRentTrucksEast,
    RyderCompanyRentTrucksEast,
} from 'pages/TrucksMap/types';

const formatMarkers = (
    markers: CustomMarker[] | undefined,
    markerType: AllMarkerTypes,
    markerGroup: AllMarkerGroups,
): CustomMarker[] => {
    if (!markers) {
        return [];
    }
    return markers.map((marker, markerIndex) => ({
        ...marker,
        id: `${markerType}_${markerGroup}_${markerIndex}`,
        type: markerType,
        group: markerGroup,
    }));
};

const getCrossDocsMappedData = (crossDocsRawData: CrossDocsRawData): CustomMarkers['crossDocs'] => {
    const strictTimeCrossDocsMarkers: CrossDocStrictTime = '#icon-1686-E65100';
    const aroundTimeCrossDocsMarkers: CrossDocAroundClockTime = '#icon-1686-0F9D58';
    const flexibleTimeCrossDocsMarkers: CrossDocFlexibleTime = '#icon-1686-FFEA00';
    return {
        strictTime: formatMarkers(crossDocsRawData?.markers[strictTimeCrossDocsMarkers], 'crossDocs', 'strictTime'),
        aroundClockTime: formatMarkers(
            crossDocsRawData?.markers[aroundTimeCrossDocsMarkers],
            'crossDocs',
            'aroundClockTime',
        ),
        flexibleTime: formatMarkers(
            crossDocsRawData?.markers[flexibleTimeCrossDocsMarkers],
            'crossDocs',
            'flexibleTime',
        ),
    };
};

const getRentTrucksRestMappedData = (rentTrucksRestRawData: RentTrucksRestRawData): CustomMarkers['rentTrucksRest'] => {
    const penskeCompanyRentTrucksRestMarkers: PenskeCompanyRentTrucksRest = '#icon-1722-0288D1';
    const ryderCompanyRentTrucksRestMarkers: RyderCompanyRentTrucksRest = '#icon-1722-E65100';
    return {
        penskeCompany: formatMarkers(
            rentTrucksRestRawData?.markers[penskeCompanyRentTrucksRestMarkers],
            'rentTrucksRest',
            'penskeCompany',
        ),
        ryderCompany: formatMarkers(
            rentTrucksRestRawData?.markers[ryderCompanyRentTrucksRestMarkers],
            'rentTrucksRest',
            'ryderCompany',
        ),
    };
};

const getRentTrucksEastMappedData = (rentTrucksEastRawData: RentTrucksEastRawData): CustomMarkers['rentTrucksEast'] => {
    const penskeCompanyRentTrucksEastMarkers: PenskeCompanyRentTrucksEast = '#icon-1722-0288D1';
    const ryderCompanyRentTrucksEastMarkers: RyderCompanyRentTrucksEast = '#icon-1722-FF5252';
    return {
        penskeCompany: formatMarkers(
            rentTrucksEastRawData?.markers[penskeCompanyRentTrucksEastMarkers],
            'rentTrucksEast',
            'penskeCompany',
        ),
        ryderCompany: formatMarkers(
            rentTrucksEastRawData?.markers[ryderCompanyRentTrucksEastMarkers],
            'rentTrucksEast',
            'ryderCompany',
        ),
    };
};

export const mapCustomMarkersData = (rawCustomMarkers: RawCustomMarkers): CustomMarkers => {
    const [crossDocsRawData, rentTrucksRestRawData, rentTrucksEastRawData] = rawCustomMarkers;
    return {
        crossDocs: getCrossDocsMappedData(crossDocsRawData),
        rentTrucksRest: getRentTrucksRestMappedData(rentTrucksRestRawData),
        rentTrucksEast: getRentTrucksEastMappedData(rentTrucksEastRawData),
    };
};
