import {invoicesEvents, settlementsCarriersEvents} from '../../events';
import * as invoicesActions from './actions';

export const invoicesChannelMessageHandler = (store) => (uri, payload) => {
    const messageData = payload.msg;
    if (!messageData.event || !messageData.data) {
        return;
    }
    const {event, data} = messageData;

    switch (event) {
        case invoicesEvents.INVOICE_UPDATE:
            store.dispatch(invoicesActions.updateInvoice(data));
            break;
        case invoicesEvents.INVOICE_RESET:
            store.dispatch(invoicesActions.resetInvoice(data));
            break;
        case invoicesEvents.INVOICES_ADD_TO_BATCH:
            store.dispatch(invoicesActions.addInvoicesToBatch(data));
            break;
        case invoicesEvents.INVOICE_RESET_FROM_BATCH:
            store.dispatch(invoicesActions.resetInvoiceFromBatch(data));
            break;
        case invoicesEvents.BATCH_CREATE:
            store.dispatch(invoicesActions.createBatch(data));
            break;
        case invoicesEvents.BATCH_GENERATE:
            store.dispatch(invoicesActions.generateBatch(data));
            break; /*
        case invoicesEvents.BATCH_UPDATE:
            store.dispatch(invoicesActions.updateBatch(data));
            break; */
        case settlementsCarriersEvents.SETTLEMENT_UPDATE:
            store.dispatch(invoicesActions.updateInvoiceSettlement(data));
            break;
        default:
            break;
    }
};
