import {ThunkAction} from 'redux-thunk';
import {stopSubmit} from 'redux-form';

import * as appActions from 'store/actions';
import {AppState} from 'store';

import {APP_VALIDATION_ERROR_TYPE} from 'services/restapi/constants';

import * as customerRequests from 'core/gateways/CustomerApiGateway/requests';
import Customer from 'core/entities/Customer/types';

import {transformFormDataToRequestBody} from 'pages/Customers/redux/mappers/merge';
import * as actionMergeTypes from 'pages/Customers/redux/actionTypes/merge';
import * as selectors from 'pages/Customers/redux/selectors';
import * as formTypes from 'pages/Customers/types/formTypes';

import {closeAll} from 'components/ui/ModalProvider/actions';

import {showMergeCustomersRequestSuccessModal} from '../modals';
import {fetchCustomers} from '../list';

type ThunkActionTypes = ThunkAction<void, AppState, unknown, any>;

export const mergeActionCreators = {
    clearedCustomersToMerge: () => ({type: actionMergeTypes.CLEARED_CUSTOMERS_TO_MERGE_STATE} as const),
    receivedCustomersToMerge: (payload: Customer[]) =>
        ({type: actionMergeTypes.RECEIVED_CUSTOMERS_TO_MERGE, payload} as const),
};

export const fetchCustomersToMerge = (params: {
    formData: Partial<formTypes.MergeCustomersFormValues>;
    formName: string;
}): ThunkActionTypes => async (dispatch) => {
    const {formData, formName} = params;

    const {sourceID, destinationID} = transformFormDataToRequestBody({formData});

    try {
        dispatch(appActions.showLoader());

        const {data} = await customerRequests.fetchCustomersToMerge({sourceID, destinationID});

        dispatch(mergeActionCreators.receivedCustomersToMerge(data));
    } catch (error: any) {
        if (error.data.type === APP_VALIDATION_ERROR_TYPE) {
            dispatch(stopSubmit(formName, error.data.data));
        } else {
            dispatch(appActions.handleError(error));
        }
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const confirmMergeCustomers = (params: {
    formData: Partial<formTypes.MergeCustomersFormValues>;
}): ThunkActionTypes => async (dispatch) => {
    const {formData} = params;

    const {sourceID, destinationID, billingMethod} = transformFormDataToRequestBody({formData});

    const requestBody = {
        billingMethod,
    };

    try {
        dispatch(appActions.showLoader());

        await customerRequests.mergeCustomersRequest({sourceID, destinationID, requestBody});

        dispatch(closeAll());
        dispatch(fetchCustomers());
        dispatch(showMergeCustomersRequestSuccessModal());
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const submitMergeCustomers = (params: {
    formData: Partial<formTypes.MergeCustomersFormValues>;
    formName: string;
}): ThunkActionTypes => (dispatch, getState) => {
    const {formData, formName} = params;

    const state = getState();

    const customersToMerge = selectors.getCustomersToMerge(state);

    if (!customersToMerge) {
        dispatch(fetchCustomersToMerge({formData, formName}));
        return;
    }

    dispatch(confirmMergeCustomers({formData}));
};

export const clearCustomersToMerge = (): ThunkActionTypes => (dispatch) => {
    dispatch(mergeActionCreators.clearedCustomersToMerge());
};
