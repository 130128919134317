import React, {useCallback} from 'react';
import {WrappedFieldProps, reset, submit} from 'redux-form';
import Dropzone, {DropzoneOptions} from 'react-dropzone';
import {v4 as uuidv4} from 'uuid';

import withFormActionCreators from 'components/ui/Files/FileUpload/hocs/withFormActionCreators';
import {ACCEPTED_DROPZONE_FILE_TYPES} from 'components/ui/Files/FileUpload/constants';
import {FormActionCreators} from 'components/ui/Files/FileUpload/types';

import styles from './styles.module.scss';

const MAX_FILE_SIZE_IN_BYTES = 104857600; // 100 mb

type OwnProps = {
    submitOnDrop?: boolean;
};

const DropzoneFileInput: React.FC<FormActionCreators & WrappedFieldProps & DropzoneOptions & OwnProps> = (props) => {
    const {input, arrayPush, meta, submitOnDrop = false, disabled = false} = props;

    const {dispatch, form} = meta;

    const handleDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file: File) => arrayPush(input.name, {file, tmpID: uuidv4()}));

        if (submitOnDrop) {
            dispatch(submit(form));
            dispatch(reset(form));
        }
    }, []);

    return (
        <div className={styles.wrap}>
            <Dropzone
                onDrop={handleDrop}
                maxSize={MAX_FILE_SIZE_IN_BYTES}
                accept={ACCEPTED_DROPZONE_FILE_TYPES}
                disabled={disabled}
            >
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps({className: `drop-zone-container ${disabled ? 'disabled' : ''}`})}>
                        <div className="drop-zone-title">
                            <input {...getInputProps()} />
                            <p>
                                <i className="fa fa-download" aria-hidden="true" />
                                &nbsp;Drag and drop a file(s) here to upload or{' '}
                                <span className="main-link">select a file</span>
                            </p>
                        </div>
                    </div>
                )}
            </Dropzone>
        </div>
    );
};

export default withFormActionCreators(DropzoneFileInput);
