import React from 'react';
import {useDispatch} from 'react-redux';

import {goToAuctionQuote} from 'pages/LoadBoard/redux/actions/public';

const QuoteLink: React.FC<{quoteNumber: number}> = (props) => {
    const {quoteNumber} = props;
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(goToAuctionQuote(quoteNumber));
    };
    return <a onClick={handleClick}>Go to quote</a>;
};

export default QuoteLink;
