/**
 * Transforms first char of the string to uppercase and returns new string
 * Unlike lodash/capitalize doesn't affect any of the subsequent chars
 * @param {string} value
 * @returns {string}
 */
export default function capitalize(value: string | null | undefined): string {
    if (!value || !value.trim()) {
        return '';
    }
    const firstChar = value.charAt(0);

    return `${firstChar.toUpperCase()}${value.slice(1)}`;
}
