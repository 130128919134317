import UpdateStatusModal from './UpdateStatusModal';
import ArchivingSuccess from './ArchivingSuccess';
import ArchivingError from './ArchivingError';
import ArchivingWarn from './ArchivingWarn';

export const modalNames = {
    archivingSuccess: 'TRUCKS/ARCHIVING_SUCCESS_MODAL',
    updateStatusModal: 'TRUCKS/UPDATE_STATUS_MODAL',
    archivingError: 'TRUCKS/ARCHIVING_ERROR_MODAL',
    archivingWarn: 'TRUCKS/ARCHIVING_WARN_MODAL',
};

const modalMap = {
    [modalNames.updateStatusModal]: UpdateStatusModal,
    [modalNames.archivingSuccess]: ArchivingSuccess,
    [modalNames.archivingError]: ArchivingError,
    [modalNames.archivingWarn]: ArchivingWarn,
};

export default modalMap;
