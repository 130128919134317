import {
    getPageNameFromChangeLocationAction,
    getRouteParamsFromChangeLocationAction,
    getIsLocationChanged,
} from 'routing/utils';
import {SETTLEMENTS} from 'utils/data/pages';
import {SETTLEMENTS_CARRIERS_TYPE, SETTLEMENTS_OWNERS_TYPE} from 'core/entities/Settlement/constants';

export default (currentState, defaultState, locationChangeAction) => {
    if (!getIsLocationChanged(locationChangeAction)) {
        return currentState;
    }

    const pageName = getPageNameFromChangeLocationAction(locationChangeAction);
    const routeParams = getRouteParamsFromChangeLocationAction(locationChangeAction) || {};
    const isBeforeNavigateToAnotherPage = pageName !== SETTLEMENTS;
    const isBeforeNavigateToSettlementsCarriersList =
        pageName === SETTLEMENTS && routeParams.type === SETTLEMENTS_CARRIERS_TYPE && !routeParams.action;
    const isBeforeNavigateToSettlementsCarriersView =
        pageName === SETTLEMENTS && routeParams.type === SETTLEMENTS_CARRIERS_TYPE && routeParams.action === 'view';
    const isBeforeNavigateToSettlementsOwnersList =
        pageName === SETTLEMENTS && routeParams.type === SETTLEMENTS_OWNERS_TYPE && !routeParams.action;
    const isBeforeNavigateToSettlementsOwnersView =
        pageName === SETTLEMENTS && routeParams.type === SETTLEMENTS_OWNERS_TYPE && routeParams.action === 'view';
    // cause we have common state for list and view pages we should clear state before navigate to selected page
    if (
        isBeforeNavigateToAnotherPage ||
        isBeforeNavigateToSettlementsCarriersList ||
        isBeforeNavigateToSettlementsCarriersView ||
        isBeforeNavigateToSettlementsOwnersList ||
        isBeforeNavigateToSettlementsOwnersView
    ) {
        return defaultState;
    }

    return currentState;
};
