import * as globalModalActions from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import * as travelOrderStopsModule from 'core/entities/TravelOrder/modules/travelOrderStops';
import {getTruckLastPlannedTravelOrder} from 'core/entities/Truck/truckTravelOrders';
import {getPickUpDeliveryInfo} from 'core/entities/Load/modules/loadStop';
import TravelOrder from 'core/entities/TravelOrder/types';
import Load, {PickupStop} from 'core/entities/Load/types';
import Truck from 'core/entities/Truck/types';

import {createDate} from 'utils/dateTime';

const getAttachmentIsNotPossibleAction = ({
    currentLoad,
    truckForAttach,
}: {
    currentLoad: Load;
    truckForAttach: Truck;
}): any => {
    const message = `
        Unfortunately, you are not able to attach truck #${truckForAttach.number} to load #${currentLoad.number}, since the load's pick-up date is earlier than the date of pick-up of the active load.
        Please, check the pick-up date of the planned load. It should be later than the pick-up date of the active load or the same day at least.
    `;
    return globalModalActions.openModal({
        modalName: commonModalNames.informationModal,
        data: {
            title: 'Attachment is not possible',
            errorMessage: message,
            rightButtonTitle: 'Ok',
            bodyType: 'ErrorForm',
            buttonType: 'danger',
        },
        handlers: {
            leftButtonHandler: () => {},
            rightButtonHandler: () => {},
        },
    });
};

const getAttachmentWarningAction = ({
    truckForAttach,
    onContinue,
    onCancel,
}: {
    truckForAttach: Truck;
    onContinue();
    onCancel();
}): any => {
    const message = `
        Please, be aware that truck #${truckForAttach.number} has an active load with a pick-up date the same as the pick-up date of the load that you are trying to attach.
        Make sure that the order of the loads is correct and that the truck is on time for both of the loads.
    `;
    return globalModalActions.openModal({
        modalName: commonModalNames.informationModal,
        data: {
            title: 'Attachment warning',
            errorMessage: message,
            leftButtonTitle: 'Cancel',
            rightButtonTitle: 'Attach anyway',
            bodyType: 'Warning',
            buttonType: 'warning',
        },
        handlers: {
            leftButtonHandler: onCancel,
            rightButtonHandler: onContinue,
        },
    });
};

const getFirstPickupDateFromCurrentLoad = (currentLoad: Load): string | undefined => {
    const {firstPickUp: firstPickupOfCurrentLoad}: {firstPickUp: PickupStop} = getPickUpDeliveryInfo(currentLoad);
    return firstPickupOfCurrentLoad ? firstPickupOfCurrentLoad.dateStart : undefined;
};

const getFirstPickUpDateFromTruckLastTravelOrder = (truckLastTravelOrder: TravelOrder): string | undefined => {
    if (!truckLastTravelOrder) {
        return undefined;
    }

    const firstPickupStopID = travelOrderStopsModule.getTravelOrderFirstPickUpStopID(truckLastTravelOrder);
    const firstPickup = travelOrderStopsModule.getTravelOrderStopByID({
        stopID: firstPickupStopID,
        travelOrder: truckLastTravelOrder,
    });

    return firstPickup?.loadStop?.dateStart || undefined;
};

const isPickupCurrentLoadBeforeLastPlannedTravelOrder = (
    currentLoad: Load,
    lastPlannedTravelOrder: TravelOrder,
): boolean => {
    const firstPickupDateOfCurrentLoad = getFirstPickupDateFromCurrentLoad(currentLoad);
    const firstPickupDateOfAttachedTruckLastTravelOrder = getFirstPickUpDateFromTruckLastTravelOrder(
        lastPlannedTravelOrder,
    );

    return (
        Boolean(firstPickupDateOfCurrentLoad) &&
        Boolean(firstPickupDateOfAttachedTruckLastTravelOrder) &&
        createDate(firstPickupDateOfCurrentLoad as string, {toTimeZone: 'utc'}).jsDate <
            createDate(firstPickupDateOfAttachedTruckLastTravelOrder as string, {toTimeZone: 'utc'}).jsDate
    );
};

const isPickupCurrentLoadInSameDayAsLastPlannedTravelOrder = (
    currentLoad: Load,
    truckLastPlannedTravelOrder: TravelOrder,
): boolean => {
    const firstPickupDateOfCurrentLoad = getFirstPickupDateFromCurrentLoad(currentLoad);
    const firstPickupDateOfAttachedTruckLastTravelOrder = getFirstPickUpDateFromTruckLastTravelOrder(
        truckLastPlannedTravelOrder,
    );
    return (
        firstPickupDateOfCurrentLoad !== undefined &&
        firstPickupDateOfAttachedTruckLastTravelOrder !== undefined &&
        firstPickupDateOfCurrentLoad === firstPickupDateOfAttachedTruckLastTravelOrder
    );
};

export default function (currentLoad: Load, truckForAttach: Truck, dispatch: any): Promise<boolean> {
    const truckLastPlannedTravelOrder: TravelOrder = getTruckLastPlannedTravelOrder(truckForAttach);
    // skip validation for trucks without travel orders
    if (!truckLastPlannedTravelOrder) {
        return Promise.resolve(true);
    }

    if (isPickupCurrentLoadBeforeLastPlannedTravelOrder(currentLoad, truckLastPlannedTravelOrder)) {
        const errorAction = getAttachmentIsNotPossibleAction({currentLoad, truckForAttach});
        dispatch(errorAction);
        return Promise.resolve(false);
    }

    if (isPickupCurrentLoadInSameDayAsLastPlannedTravelOrder(currentLoad, truckLastPlannedTravelOrder)) {
        return new Promise<boolean>((resolve) => {
            const warningAction = getAttachmentWarningAction({
                truckForAttach,
                onContinue: () => resolve(true),
                onCancel: () => resolve(false),
            });
            dispatch(warningAction);
        });
    }

    return Promise.resolve(true);
}
