import React, {useState, useMemo, Fragment} from 'react';
import classNames from 'classnames';
import isArray from 'lodash/isArray';

import CheckBoxInput from 'components/ui/Form/CheckBoxInput';
import Button from 'components/ui/Buttons/Button';
import CurrencyAmount from 'components/ui/CurrencyAmount';

import getUserName from 'core/entities/Dispatcher/getDispatcherName';

import {PayPeriodResponsibleUser} from '../../../types';
import ListUserSettlements from './ListUserSettlements';
import styles from './responsibleUser.module.scss';

interface OwnProps {
    payPeriodID: number;
    user: PayPeriodResponsibleUser;
    selectedUsers: PayPeriodResponsibleUser[];
    onSelectUser();
}

const isUserSelected = (
    allSelectedUsers: PayPeriodResponsibleUser[],
    currentUser: PayPeriodResponsibleUser,
): boolean => {
    return allSelectedUsers.some((user) => user.id === currentUser.id);
};

const ResponsibleUser = ({payPeriodID, user, selectedUsers, onSelectUser}: OwnProps): JSX.Element => {
    const [open, setOpen] = useState(false);
    const userName = getUserName(user);
    const rowClassNameItemList = classNames('settlement-item-list', {open});
    const rowClassNameItemTitle = classNames('settlement-item', {open});
    const settlementsLength = user.settlements_count;
    const totalPayments = isArray(user.settlements_total_pays) ? user.settlements_total_pays : [];
    const isChecked = useMemo(() => {
        return isUserSelected(selectedUsers, user);
    }, [selectedUsers, user]);
    return (
        <div className={styles.list}>
            <div className={rowClassNameItemTitle}>
                <div className="settlement-item__title">
                    <CheckBoxInput
                        label={userName}
                        input={{
                            value: isChecked,
                            onChange: onSelectUser,
                        }}
                        position="right"
                    />
                </div>
                {settlementsLength > 0 && (
                    <div className="d-flex align-items-center">
                        <div className="settlement-item__count">{settlementsLength}</div>
                        <div className="sep">
                            <div className="dot" />
                            <div className="dot" />
                            <div className="dot" />
                        </div>
                        <div>
                            {totalPayments.map((payment, index) => {
                                return (
                                    <Fragment key={index}>
                                        <CurrencyAmount amount={payment.amount} currency={payment.currency} />
                                        <br />
                                    </Fragment>
                                );
                            })}
                        </div>
                        <Button
                            buttonIcon={open ? 'angle-up' : 'angle-down'}
                            onClick={() => setOpen(!open)}
                            buttonSize="icon"
                            className="btn-link"
                        />
                    </div>
                )}
            </div>
            <div className={styles.expand}>
                {open && (
                    <ListUserSettlements
                        className={rowClassNameItemList}
                        payPeriodID={payPeriodID}
                        userID={user.id as number}
                    />
                )}
            </div>
        </div>
    );
};

export default React.memo(ResponsibleUser);
