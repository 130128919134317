import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';

import {FileNew as StoredFile, GroupedFiles as Files} from 'types/File';
import openNewWindow from 'utils/openNewWindow';
import getFilePath from 'utils/files/getFilePath';

export function checkIfFilesAreSelected(selectedFileIds: number[], ...testedFileIds: number[]): boolean {
    if (isEmpty(selectedFileIds) || isEmpty(testedFileIds)) {
        return false;
    }
    return testedFileIds.every((fileId: number): boolean => selectedFileIds.includes(fileId));
}

export function getGroupedFilesCount(files: Files): number {
    return flatten(Object.values(files)).length;
}

export function checkIfFileNameMatchesFilter(fileName: string, filter: string): boolean {
    if (!filter) {
        return true;
    }
    const fileNameLowerCase = fileName.toLowerCase();
    const filterLowerCase = filter.toLowerCase();
    return fileNameLowerCase.includes(filterLowerCase);
}

export const isPdfFile = (file: StoredFile): boolean => file.type === 'application/pdf';

export const openPdfFileInNewTab = (file: StoredFile): void => {
    openNewWindow({newWindowUrl: getFilePath(file)});
};
