import Truck from 'core/entities/Truck/types';

import {
    WEB_SOCKET_LOAD_BOARD_TRUCKS_RESERVE_RECEIVED,
    WEB_SOCKET_LOAD_BOARD_TRUCKS_RESERVE_CANCELED,
} from './actionTypes';

export function trucksReserveChanged(reserve?: Truck['reserve'] | null) {
    return {
        type: WEB_SOCKET_LOAD_BOARD_TRUCKS_RESERVE_RECEIVED,
        payload: {reserve},
    } as const;
}

export function trucksReserveCanceled(truckID?: number | null) {
    return {
        type: WEB_SOCKET_LOAD_BOARD_TRUCKS_RESERVE_CANCELED,
        payload: {truckID},
    } as const;
}
