import React from 'react';
import {Modal} from 'react-bootstrap';

import Batch from 'core/entities/Invoice/types/Batch';
import Button from 'components/ui/Buttons/Button/index';

interface OwnProps {
    data: {batch: Batch};
    onClose();
    handlers: {
        onOk();
    };
}

const InvoicesAddedToBatchInfo = (props: OwnProps): JSX.Element => {
    const {
        data: {batch},
        onClose,
        handlers: {onOk},
    } = props;
    const handleOk = (): void => {
        onClose();
        onOk();
    };
    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>New invoices</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <p className="mb10">New invoices added to batch #{batch.number}.</p>
                        <p className="mb10">Please reload the page to see updated data.</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleOk} buttonSize="small" colorTheme="blue">
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InvoicesAddedToBatchInfo;
