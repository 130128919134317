import {
    WEB_SOCKET_LOADS_FILES_DELETE_RECEIVED,
    WEB_SOCKET_LOADS_FILES_POST_RECEIVED,
    WEB_SOCKET_LOADS_UPDATE_RECEIVED,
} from './actionTypes';

export function loadsDataUpdateReceived(load) {
    return function (dispatch) {
        dispatch({
            type: WEB_SOCKET_LOADS_UPDATE_RECEIVED,
            payload: load,
        });
    };
}

export function loadsFilesPostReceived(data) {
    return function (dispatch) {
        dispatch({
            type: WEB_SOCKET_LOADS_FILES_POST_RECEIVED,
            payload: {...data, files: data.files.filter(Boolean)},
        });
    };
}

export function loadsFilesDeleteReceived(data) {
    return function (dispatch) {
        dispatch({
            type: WEB_SOCKET_LOADS_FILES_DELETE_RECEIVED,
            payload: data,
        });
    };
}
