import useGetReduxFormError from 'hooks/reduxForm/useGetReduxFormError';

import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';
import getFormFieldNames from '../utils/getFormFieldNames';

export default function () {
    const {getReceiversListFiledName} = getFormFieldNames();
    const selectedTrucksError = useGetReduxFormError(FORM_NAME, getReceiversListFiledName());
    return selectedTrucksError;
}
