import {InferActions} from 'store';

import {AppSettings} from 'core/entities/AppSettings/types';

import * as types from './actionTypes';

import {AppSettingsState} from '.';

export const setAppSettings = (payload: AppSettings) => ({type: types.SET_APP_SETTINGS, payload} as const);

export const setSupportData = (payload: AppSettingsState['support']) =>
    ({type: types.SET_SUPPORT_DATA, payload} as const);

export const toggleSidebar = (value: AppSettingsState['isSidebarExpanded']) =>
    ({type: types.TOGGLE_SIDEBAR, payload: !value} as const);

export const setTripMonitorUpdatingTime = (time: AppSettingsState['updatingTravelOrdersTime']) =>
    ({type: types.SET_TRAVEL_ORDERS_UPDATING_TIME, payload: time} as const);

export const setMapApiKeys = (payload: AppSettingsState['mapApiKey']) =>
    ({type: types.SET_MAP_API_KEYS, payload} as const);

const actionCreators = {
    setAppSettings,
    setSupportData,
    toggleSidebar,
    setTripMonitorUpdatingTime,
    setMapApiKeys,
};

export type AppSettingsActionsTypes = InferActions<typeof actionCreators>;

export default actionCreators;
