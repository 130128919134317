import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {getTypeFieldNameFactory} from 'utils/typeScript';
import {InjectedFormProps, Field, reduxForm} from 'redux-form';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import {AddGeneralNote} from 'components/ui/modals/AddTONotes/types';
import CheckBoxInput from 'components/ui/Form/CheckBoxInput';
import TextArea from 'components/ui/Form/TextArea';
import Button from 'components/ui/Buttons/Button';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<AddGeneralNote>();

const ADD_TO_NOTES_FORM_NAME = 'add-notes-modal-form';

type OwnProps = {
    disabled?: boolean;
};

const NotesForm: React.FC<OwnProps & InjectedFormProps<{}>> = (props) => {
    const {handleSubmit, reset, disabled = false} = props;

    const noteInTheArea = useGetReduxFormValue(ADD_TO_NOTES_FORM_NAME, getName('note'));

    const onSubmit = (note): void => {
        handleSubmit(note);
        reset();
    };

    return (
        <form onSubmit={onSubmit}>
            <div className={styles.add_note}>
                <label className="form-label">
                    <strong>New General Note:</strong>
                </label>
                <Field
                    placeholder="type a note here"
                    name={getName('note')}
                    component={TextArea}
                    maxLength="750"
                    rows="2"
                    disabled={disabled}
                />
            </div>
            <div className={styles.bottom}>
                <div />
                <div className={styles.bottom__actions}>
                    <Field name={getName('isImportant')} label="important" position="left" component={CheckBoxInput} />
                    <Button
                        disabled={isEmpty(noteInTheArea) || disabled}
                        type="submit"
                        colorTheme="blue"
                        className="ml-1"
                    >
                        Add a Note
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default reduxForm<{}, OwnProps>({form: ADD_TO_NOTES_FORM_NAME})(NotesForm);
