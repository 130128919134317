import React from 'react';
import {useSelector} from 'react-redux';

import {Weight} from 'core/entities/AppSettings/types';

import {getCurrentWeightFormat} from 'store/reducers/appSettings/selectors';

import WEIGHT_UNITS from 'utils/data/weightUnits';

import UnitOfMeasurementSkipField from 'components/ui/Form/UnitOfMeasurementSkipField';

const weights: Array<{name: Weight; value: Weight}> = [
    {name: WEIGHT_UNITS.LBS, value: WEIGHT_UNITS.LBS},
    {name: WEIGHT_UNITS.KG, value: WEIGHT_UNITS.KG},
    {name: WEIGHT_UNITS.TON, value: WEIGHT_UNITS.TON},
];

type OwnProps = {
    unit?: string;
    setUnit: (unit) => void;
    clearFields: () => void;
    disabled?: boolean;
};

const WeightsUnitsOfMeasurement: React.FC<OwnProps> = (props) => {
    const {unit, setUnit, clearFields, disabled} = props;

    return (
        <UnitOfMeasurementSkipField
            unitOptions={weights}
            unit={unit}
            setUnit={setUnit}
            clearFields={clearFields}
            disabled={disabled}
        />
    );
};

export default WeightsUnitsOfMeasurement;
