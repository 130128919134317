import * as commonModalActions from 'store/actions/modalActions';

import {DispatcherNoteApi} from 'core/entities/Truck/types/Note';
import Truck from 'core/entities/Truck/types';

import * as listAction from 'pages/TrucksNearby/redux/actions/list';

export const editTruckNotes = (params: {truck: Truck}) => (dispatch) => {
    const {truck} = params;

    const handleReceiveDispatcherNote = (currentTruck: Truck, note: DispatcherNoteApi) => {
        dispatch(listAction.receiveDispatcherNote({truck: currentTruck, note}));
    };

    dispatch(commonModalActions.showTruckNotesModal(truck, handleReceiveDispatcherNote));
};
