import React from 'react';
import isNumber from 'lodash/isNumber';

import Truck from 'core/entities/Truck/types';

import DistanceValue from 'pages/LoadBoard/components/common/DistanceValue';

type OwnProps = {
    truck: Truck;
};

const TruckDistances: React.FC<OwnProps> = (props) => {
    const {truck} = props;

    const {distance, homeDistance} = truck || {};

    return (
        <>
            {isNumber(distance) ? (
                <div>
                    <strong>
                        <i className="fa fa-compass" /> <DistanceValue distance={distance} withUnit />
                    </strong>
                </div>
            ) : null}
            {isNumber(homeDistance) ? (
                <div>
                    <i className="fa fa-home" /> <DistanceValue distance={homeDistance} withUnit />
                </div>
            ) : null}
        </>
    );
};

export default TruckDistances;
