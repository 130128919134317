import {useUpdateEffect} from 'react-use';

import {getTimeZoneByCoordinates} from 'services/geocodingApi';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import useFormActions from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/hooks/useFormActions';

const useRecalculateTimeZones = (formName: string, index: number): void => {
    const {setStopTimeZone} = useFormActions();
    const {getStopCoordinatesFieldName} = getFormFieldNames();

    const originCoordinates = useGetReduxFormValue(formName, getStopCoordinatesFieldName(index));
    const originLatitude = originCoordinates?.latitude;
    const originLongitude = originCoordinates?.longitude;

    useUpdateEffect(() => {
        if (!originLatitude || !originLongitude) {
            return;
        }
        getTimeZoneByCoordinates({lat: originLatitude, lng: originLongitude})
            .then((res) => {
                const calculatedTimeZone = res?.timeZone;
                if (!calculatedTimeZone) {
                    console.error('Timezone of selected stop is empty!', res);
                    return;
                }
                setStopTimeZone(calculatedTimeZone, index);
            })
            .catch((error) => {
                console.error('Error on get stop timezone: ', error);
            });
    }, [originLatitude, originLongitude]);
};

export default useRecalculateTimeZones;
