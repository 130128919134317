import React from 'react';
import {Field} from 'redux-form';
import classNames from 'classnames';

import CheckSwitch from 'components/ui/Form/CheckSwitch';

import {getName} from 'pages/TravelOrders/components/common/modals/TimerSettingsModal/components/Form/utils';

import styles from '../../styles.module.scss';

const AutoCheckCallSwitch: React.FC = () => {
    return (
        <div className={classNames(styles.section, styles.blueBg)}>
            <Field name={getName('autoCheckCallEnabled')} component={CheckSwitch} className="mt10" />
            <div>
                <p>
                    <strong>Automation update check call</strong>
                </p>
                <p className={styles.subTitle}>
                    In automatic mode, events will be generated according to the specified parameters and automatically
                    sent to the broker. If any issues arise preventing event creation, the system will notify you.
                </p>
            </div>
        </div>
    );
};

export default AutoCheckCallSwitch;
