import {Sender} from '../../types';

export const getDefaultSender = (senders: Sender[]): Sender | undefined => senders?.find((item) => item.by_default);

export const getDefaultEmail = (senders: Sender[]): string | undefined => getDefaultSender(senders)?.email;

export const getDefaultID = (senders: Sender[]): number | undefined => getDefaultSender(senders)?.id;

export const getSenderByID = ({
    senders,
    currentSenderID,
}: {
    senders: Sender[];
    currentSenderID: number;
}): Sender | undefined => senders?.find((item) => item.id === currentSenderID);
