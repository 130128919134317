import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import omitBy from 'lodash/omitBy';
import toNumber from 'lodash/toNumber';

import {getAttachmentsMetaData} from 'pages/TravelOrders/redux/mappers/attachments';
import {AddBillOfLadingModalFormValues} from 'pages/TravelOrders/types/formTypes';

import {createDate} from 'utils/dateTime';
import {convertToLbs, transformWeightToNumber} from 'utils/weightConverter';

import {getCorrespondingAttachments} from '../attachments';

export const transformAddBOLDataToRequestBody = (bolInfoData: AddBillOfLadingModalFormValues) => {
    const {timeZone, date, time, freights, attachments} = bolInfoData;

    const {fullOriginalDateTime: dateTime, isValid} = createDate(`${date} ${time}`, {
        fromTimeZone: timeZone,
        toTimeZone: 'utc',
    });

    const prepareWeight = (weight: AddBillOfLadingModalFormValues['freights'][number]['weight']) => ({
        amount: convertToLbs(weight?.unit, transformWeightToNumber(weight?.amount)),
        unit: weight?.unit,
    });

    const convertedFreights = freights.map((freight) => ({
        rangeMax: isNumber(freight.rangeMax) ? freight?.rangeMax : null,
        rangeMin: isNumber(freight.rangeMin) ? freight?.rangeMin : null,
        isDifferentAddress: freight?.isDifferentAddress || null,
        withTempControl: freight?.withTempControl || null,
        pieces: toNumber(freight?.pieces || 0),
        freezeMode: freight?.freezeMode || null,
        degreesUnit: freight?.degreesUnit || null,
        weight: prepareWeight(freight.weight),
        number: freight?.number || null,
        commodity: freight?.commodity || null,
        seal: freight?.seal || null,
    }));

    const clearedFreights = convertedFreights.map((item) => omitBy(item, (value) => isNull(value)));
    const convertedAttachments = getAttachmentsMetaData(attachments);

    return {
        documentActionTimeZone: isValid && timeZone ? timeZone : null,
        documentActionDateTime: isValid ? dateTime : null,

        files: convertedAttachments,
        bols: clearedFreights,
    };
};

export const transformAddBOLFilesToRequestBody = (
    bolInfoData: AddBillOfLadingModalFormValues,
    filesIds: Record<number, string>,
): FormData | null => {
    const {attachments} = bolInfoData;

    const {uploadedFormData} = getCorrespondingAttachments({formAttachments: attachments, filesIds});

    return uploadedFormData;
};
