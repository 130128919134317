import {createSelector} from 'reselect';

export const selectPagePermissions = (state, page) =>
    state.userPermissions && state.userPermissions.pages && state.userPermissions.pages[page];
export const selectMenuPermissions = (state) => state.userPermissions && state.userPermissions.menu;
export const selectActionsPermissions = (state) => state.userPermissions && state.userPermissions.actions;

export const getPagePermissions = createSelector([selectPagePermissions], (pagePermissions) => pagePermissions || {});

export const getMenuPermissions = createSelector([selectMenuPermissions], (menuPermissions) => menuPermissions || {});

export const getActionsPermissions = createSelector(
    [selectActionsPermissions],
    (actionsPermissions) => actionsPermissions || {},
);
