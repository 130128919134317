import React from 'react';

import type Batch from 'core/entities/Invoice/types/Batch';
import {getBatchStatusName} from 'core/entities/Invoice';
import BatchCustomerLink from 'pages/Invoices/components/common/BatchCustomerLink';
import BatchCustomerAddress from 'pages/Invoices/components/common/BatchCustomerAddress';

import style from './style.module.scss';

type OwnProps = {
    batch: Batch;
    isExpanded: boolean;
    onToggleExpand();
};

const BatchInfoHeader = (props: OwnProps): JSX.Element => {
    const {batch, isExpanded, onToggleExpand} = props;
    const batchStatus = getBatchStatusName(batch);
    return (
        <div className={style.batch_info_header_container}>
            <div>
                <strong>batch #{batch.number}</strong>
                <br />
                <strong>{batch.invoice_count}</strong> invoices - <strong>{batchStatus}</strong>
            </div>
            <BatchCustomerLink batch={batch} />
            <BatchCustomerAddress batch={batch} />
            <div className={style.button_filter}>
                <span className="main-link" onClick={onToggleExpand}>
                    <i className="fa fa-filter" aria-hidden="true" /> {isExpanded ? 'Hide' : 'Show'} invoices filters
                </span>
            </div>
        </div>
    );
};

export default BatchInfoHeader;
