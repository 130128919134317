import React from 'react';

import Button from 'components/ui/Buttons/Button';

import styles from './styles.module.scss';

export type ControlsAction = 'inc' | 'dec';

type OwnProps = {
    disabled?: boolean;
    onClick: (action: ControlsAction) => void;
};

const Controls: React.FC<OwnProps> = ({onClick, disabled = false}) => {
    return (
        <div className={styles.controls}>
            <Button
                disabled={disabled}
                buttonIcon="caret-up"
                className={styles.controlBtn}
                onClick={() => onClick('inc')}
            />
            <Button
                disabled={disabled}
                buttonIcon="caret-down"
                className={styles.controlBtn}
                onClick={() => onClick('dec')}
            />
        </div>
    );
};

export default Controls;
