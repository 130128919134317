import React, {ReactNodeArray} from 'react';
import isEmpty from 'lodash/isEmpty';
import Linkify from 'linkify-react';

import DispatchersExtension from 'components/common/Dispatcher/DispatchersExtension';
import FilePreview from 'components/ui/Files/FileUpload/components/FilePreview';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';

import {Message, NormalizedFile} from 'widgets/Chat/types';

import {createDate} from 'utils/dateTime';

import MessageNotificationItem from '../MessageNotificationItem';

import styles from './styles.module.scss';

const renderFilePreviews = (files: NormalizedFile[]): ReactNodeArray =>
    files.map((file: NormalizedFile, fileIndex: number): JSX.Element | undefined => {
        return <FilePreview key={fileIndex} file={file} grid={true} linkInNewTab={true} />;
    });

type OwnProps = {
    message: Message;
};

const MessageItem: React.FC<OwnProps> = ({message}) => {
    const messageCreationDate = createDate(message.createdAt, {fromISO: true}).fullDate;
    const {isNotification, driver: driverFromMessage, dispatcher: dispatcherFromMessage} = message;

    const messageBody = (
        <>
            {message.text && !isNotification && (
                <div className="message-text">
                    <Linkify options={{target: '_blank', className: 'message-link'}}>{message.text}</Linkify>
                </div>
            )}

            {isNotification && <MessageNotificationItem message={message} />}

            {!isEmpty(message.attachments) && (
                <div className="message-attach">{renderFilePreviews(message.attachments)}</div>
            )}
        </>
    );

    if (driverFromMessage) {
        return (
            <div className={styles.driver} key={message.id}>
                {messageBody}
                <div className="message-date">{messageCreationDate}</div>
                {message.isUnread && (
                    <div className="message-status">
                        <span className="status">new</span>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className={styles.dispatcher} key={message.id}>
            {dispatcherFromMessage && (
                <div className="message-from">
                    {getDispatcherName(dispatcherFromMessage)}
                    <span className="sep">
                        <span className="dot" />
                        <span className="dot" />
                        <span className="dot" />
                    </span>
                    <DispatchersExtension extension={dispatcherFromMessage.extension} />
                </div>
            )}
            {messageBody}
            <div className="message-date">{messageCreationDate}</div>
        </div>
    );
};

export default MessageItem;
