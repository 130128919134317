import React from 'react';

import styles from './styles.module.scss';

const TruckIcon: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <i className="fa fa-truck fa-flip-horizontal" />
        </div>
    );
};

export default TruckIcon;
