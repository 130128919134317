import React from 'react';
import {useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';

import * as commonDataSelectors from 'store/reducers/commonData/selectors';

import {getCarrierName} from 'core/entities/Carrier/modules';
import Batch from 'core/entities/Invoice/types/Batch';

import {getCityLineFromObject} from 'utils/getCityLine';

import Button from 'components/ui/Buttons/Button/index';

import BatchTotalCharges from 'pages/Invoices/components/common/BatchTotalCharges';

import BatchFiles from './components/BatchFiles';
import styles from './postBatch.module.scss';

interface OwnProps {
    data: {
        batch: Batch;
    };
    handlers: {onPostBatch()};
    onClose();
}

const PostBatchModal = (props: OwnProps): JSX.Element => {
    const {
        data: {
            batch,
            batch: {number: batchNumber, customer},
        },
        handlers: {onPostBatch},
        onClose,
    } = props;
    const isCustomer = Boolean(customer);
    const currentCarrier = useSelector(commonDataSelectors.getCurrentCarrier);
    const companyName = isCustomer ? (
        <strong>{getCarrierName(currentCarrier)}</strong>
    ) : (
        <strong>{currentCarrier.factoring_company_name}</strong>
    );
    const carrierBillingAddress = getCityLineFromObject({
        city: currentCarrier.billing_city,
        state: currentCarrier.billing_state,
        zip: currentCarrier.billing_zip,
        country: currentCarrier.billing_country,
    });
    const carrierFactoringAddress = getCityLineFromObject({
        city: currentCarrier.factoring_company_physical_city,
        state: currentCarrier.factoring_company_physical_state,
        zip: currentCarrier.factoring_company_physical_zip,
        country: currentCarrier.factoring_company_physical_country,
    });
    return (
        <Modal show={true} onHide={onClose} dialogClassName="standard-form-modal" size="sm" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Generate the Batch #{batchNumber}</Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.main}>
                    <div className="modal-body__main">
                        <div className="flex-block">
                            <div>
                                {isCustomer ? (
                                    <>
                                        {companyName}
                                        <div>{currentCarrier.billing_address_line}</div>
                                        <div>{carrierBillingAddress}</div>
                                    </>
                                ) : (
                                    <>
                                        {companyName}
                                        <div>{currentCarrier.factoring_company_billing_address_line}</div>
                                        <div>{carrierFactoringAddress}</div>
                                    </>
                                )}
                            </div>
                            <div className="text-right">
                                <p>
                                    <strong>{batch.invoice_count}</strong> invoices
                                </p>
                                <p>
                                    total charges <BatchTotalCharges batch={batch} />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="custom-scroll">
                        <BatchFiles batchNumber={batchNumber} />
                    </div>
                </div>

                <div className="modal-body__bottom">
                    <Button onClick={onClose} buttonSize="small" colorTheme="grey">
                        Cancel
                    </Button>
                    <Button onClick={onPostBatch} buttonSize="small" colorTheme="blue">
                        Generate PDF
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PostBatchModal;
