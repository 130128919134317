// https://github.com/uuidjs/uuid#deep-requires-now-deprecated
import {v4 as uuidv4} from 'uuid';

import {modalHandlers} from 'components/ui/ModalProvider/ModalHandlers';
import * as types from './actionTypes';

export function openModal(params) {
    const {handlers, selectors, data, modalName} = params;
    const modalID = `${uuidv4()}`;
    modalHandlers.setById({id: modalID, functions: {handlers, selectors}});
    return {
        type: types.OPEN_MODAL,
        payload: {
            id: modalID,
            modalName,
            data,
        },
    };
}

export function closeModal(id) {
    modalHandlers.resetById(id);
    return {
        type: types.CLOSE_MODAL,
        payload: {id},
    };
}

export function closeAll() {
    modalHandlers.resetAll();
    return {
        type: types.CLOSE_ALL_MODALS,
    };
}

export function singleCallOpenModal(params) {
    return (dispatch, getState) => {
        const {modals} = getState();
        const idx = modals.items.findIndex((item) => item.modalName === params.modalName);
        if (idx < 0) {
            dispatch(openModal(params));
        }
    };
}
