import pick from 'lodash/pick';

import {InferActions} from 'store';

import {actionCreators as actionCreatorsForLoadList} from '../../actionCreators/list';
import {actionCreators as actionCreatorsForLoad} from '../../actionCreators';
import * as currentLoadTypes from '../../actionTypes/loadActionTypes';
import * as listTypes from '../../actionTypes/listActionTypes';
import * as types from '../../actionTypes/modalActionTypes';
import {actionCreators} from '../../actionCreators/modal';

const CurrentReducerActions = actionCreators;

const CurrentReducerActionsForLoad = pick(actionCreatorsForLoad, ['clearState']);
const CurrentReducerActionsForLoadList = pick(actionCreatorsForLoadList, ['clearState']);

type ActionTypes = InferActions<
    typeof CurrentReducerActions | typeof CurrentReducerActionsForLoad | typeof CurrentReducerActionsForLoadList
>;

export type ModalState = {
    name: string | null;
    data: any;
    keepOpenModalName?: string | null;
};

const defaultState: ModalState = {name: null, data: null, keepOpenModalName: null};

export default function reducer(state = defaultState, action: ActionTypes): ModalState {
    switch (action.type) {
        case types.LOADS_SHOW_MODAL: {
            const {modalName, modalData, keepOpenModalName} = action.payload;
            return {
                name: modalName,
                data: modalData,
                keepOpenModalName,
            };
        }

        case types.LOADS_HIDE_MODAL: {
            return {
                name: state.keepOpenModalName || null,
                data: null,
                keepOpenModalName: null,
            };
        }

        case listTypes.CLEAR_STATE:
        case currentLoadTypes.LOAD_STATE_CLEAR: {
            return defaultState;
        }

        // no default
    }
    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
