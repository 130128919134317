import React from 'react';
import Truck from 'core/entities/Truck/types';
import BlueCheckbox from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/components/QuoteReceivers/components/common/BlueCheckbox';
import styles from './styles.module.scss';

interface OwnProps {
    truck: Truck;
    isChecked: boolean;
    onSelectTruck: (truck: Truck) => void;
}

const TruckNumber: React.FC<OwnProps> = (props) => {
    const {truck, isChecked, onSelectTruck} = props;

    return (
        <td className={styles.wrapper}>
            <BlueCheckbox onChange={() => onSelectTruck(truck)} isChecked={isChecked} label={`#${truck.number}`} />
        </td>
    );
};

export default TruckNumber;
