import React from 'react';
import isNil from 'lodash/isNil';

import {AutoCheckCallErrors} from 'core/entities/TravelOrder/types/AutoCheckCallSettings';

import styles from '../../styles.module.scss';

type OwnProps = {
    location: string;
    error?: AutoCheckCallErrors['location'];
};

const Location: React.FC<OwnProps> = ({error, location}) => {
    if (!error) {
        return <span>{location}</span>;
    }

    return (
        <span className={styles.locationError}>
            <i className="fa fa-exclamation-circle" />
            <span>The current location hasn&apos;t been updated in over 15 minutes.</span>
        </span>
    );
};

export default Location;
