import React from 'react';

import {getCustomerBillingAddressAllInfo} from 'core/entities/Customer/modules/customerBilling/getCustomerBillingAddress';
import {getCustomerBillingMethod} from 'core/entities/Customer/modules/customerBilling/getCustomerBillingMethod';
import Customer from 'core/entities/Customer/types';

import {getCityLineFromObject} from 'utils/getCityLine';

import TextHighlighter from 'components/ui/TextHighlighter';

import useMergeState from 'pages/Customers/hooks/useMergeState';

import {getMatchingCustomersWords} from './utils';

type OwnProps = {
    customer: Customer;
};

const CustomerToMergeRow: React.FC<OwnProps> = (props) => {
    const {customer} = props;

    const {customersToMerge} = useMergeState();

    const customerBillingMethod = getCustomerBillingMethod(customer);

    const {
        matchedCustomersName,
        matchedCustomersCityLine,
        matchedCustomersBillingAdditionalAddressLine,
        matchedCustomersBillingAddressLine,
    } = getMatchingCustomersWords(customersToMerge);

    const {
        customerBillingAdditionalAddressLine,
        customerBillingAddressLine,
        customerBillingCountry,
        customerBillingState,
        customerBillingCity,
        customerBillingZip,
    } = getCustomerBillingAddressAllInfo(customer);

    const customerCityLine = getCityLineFromObject({
        country: customerBillingCountry,
        state: customerBillingState,
        city: customerBillingCity,
        zip: customerBillingZip,
    });

    return (
        <tr>
            <td>{customer.id}</td>

            <td>
                <TextHighlighter text={customer.name} highlightedWords={matchedCustomersName} />
            </td>

            <td>
                <div className="maxw250">
                    <div>
                        <TextHighlighter
                            text={customerBillingAddressLine}
                            highlightedWords={matchedCustomersBillingAddressLine}
                        />
                    </div>

                    <div>
                        <TextHighlighter
                            text={customerBillingAdditionalAddressLine}
                            highlightedWords={matchedCustomersBillingAdditionalAddressLine}
                        />
                    </div>

                    <TextHighlighter text={customerCityLine} highlightedWords={matchedCustomersCityLine} />
                </div>
            </td>

            <td className="text-capitalize">{customerBillingMethod}</td>
        </tr>
    );
};

export default CustomerToMergeRow;
