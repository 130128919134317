import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector, InjectedFormProps} from 'redux-form';

import Button from 'components/ui/Buttons/Button/index';
import TextInputWithDatePicker from 'components/ui/Form/InputWithDatePicker';
import RequiredLabel from 'components/ui/Form/RequiredLabel';

import FIELDS from './fields';

import * as selectors from './selectors';

import {getDateEnd} from './utils';

const FORM_NAME = 'create-pay-period-modal';
const selector = formValueSelector(FORM_NAME);

type OwnProps = {
    lastPayPeriod: {} | {[key: string]: any};
    onClose: () => void;
    selectedDateFrom: string;
    selectedDateTo: string;
};

const Form: React.FC<OwnProps & InjectedFormProps<{}, OwnProps>> = (props) => {
    const {handleSubmit, change, onClose, selectedDateFrom, selectedDateTo} = props;
    const isDatePeriodCorrect = selectedDateFrom && selectedDateTo;

    const changeSelectedDateTo = (selectedValue) => change(FIELDS.dateTo, getDateEnd(selectedValue));

    /**
     * In this modal, fields with datepicker need using ONLY with <withHoursMinSec> and <inLocalZone> props.
     * Modal's initial date coming in utc format and then converting to the New York,
     * according date and time in modal necessary show ONLY in the New York format
     * (default - date in the datepicker transform from utc to the CLIENT format)
     */

    return (
        <form onSubmit={handleSubmit}>
            <div className="modal-body__main">
                <div className="row">
                    <div className="col-4">
                        <Field
                            label={RequiredLabel('Date from:')}
                            name={FIELDS.dateFrom}
                            type="text"
                            component={TextInputWithDatePicker}
                            disabled={selectedDateFrom}
                            withHoursMinSec
                            inLocalZone
                        />
                    </div>
                    <div className="col-4">
                        <Field
                            label={RequiredLabel('Date to:')}
                            name={FIELDS.dateTo}
                            type="text"
                            component={TextInputWithDatePicker}
                            minDate={selectedDateFrom}
                            isEditableFromKeyBoard={false}
                            onChange={changeSelectedDateTo}
                            withHoursMinSec
                            inLocalZone
                        />
                    </div>
                </div>
            </div>
            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <Button disabled={!isDatePeriodCorrect} type="submit" colorTheme="blue">
                        Create
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default connect((state, ownProps) => ({
    selectedDateFrom: selector(state, FIELDS.dateFrom),
    selectedDateTo: selector(state, FIELDS.dateTo),
    initialValues: selectors.getInitialData(state, ownProps),
}))(
    reduxForm<{}, OwnProps>({form: FORM_NAME})(Form),
);
