import React from 'react';

import {getDriverName} from 'core/entities/Driver/modules/driverName';
import Driver from 'core/entities/Driver/types';

import {DriverLink} from 'components/ui/Links';

import {phoneNumberWithBraces} from 'utils';

type OwnProps = {
    driver: Driver;
};

const DriverDuplicateError: React.FC<OwnProps> = (props) => {
    const {driver} = props;

    return (
        <div>
            <p>You cannot create driver because a user with typed data already exists:</p>
            <DriverLink driver={driver} className="second-link" content={getDriverName(driver)} />
            &nbsp;
            {phoneNumberWithBraces(driver.mobile_phone)}
        </div>
    );
};

export default DriverDuplicateError;
