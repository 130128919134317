import isNumber from 'lodash/isNumber';

import {convertDate} from 'utils/dateTime';

import {AddCheckCallFormValues} from '../../types';

type SetConfirmData = {
    eventDate: string | null;
    timeZone: string;
    latitude: number | null;
    longitude: number | null;
    forBroker: {
        loadNumber: number | null;
        latitude: number | null;
        longitude: number | null;
    }[];
    loadNumbers: number[];
};

export const transformSetConfirmDataToRequestBody = (createFormValue: AddCheckCallFormValues): SetConfirmData => {
    const getTruckDateTimeToUTC = (dateTime: string): string => {
        return convertDate(dateTime, {timeZoneTo: 'utc', timeZoneFrom: createFormValue.timezone}).defaultDateTime;
    };

    return {
        eventDate: createFormValue.dateTime ? getTruckDateTimeToUTC(createFormValue.dateTime) : null,
        timeZone: createFormValue.timezone,
        latitude: isNumber(createFormValue.latitude) ? createFormValue.latitude : null,
        longitude: isNumber(createFormValue.longitude) ? createFormValue.longitude : null,
        forBroker: [
            {
                loadNumber: isNumber(createFormValue.summaryItem.loadNumber)
                    ? createFormValue.summaryItem.loadNumber
                    : null,
                latitude: isNumber(createFormValue.summaryItem.latitude) ? createFormValue.summaryItem.latitude : null,
                longitude: isNumber(createFormValue.summaryItem.longitude)
                    ? createFormValue.summaryItem.longitude
                    : null,
            },
        ],
        loadNumbers: createFormValue.loadNumbers,
    };
};
