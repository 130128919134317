import React from 'react';

import Carrier from 'core/entities/Carrier/types';

import ArchivingWarnWrapper from 'components/ui/modals/ArchivingWarnWrapper';
import {CarrierLink} from 'components/ui/Links';

type OwnProps = {
    data: {carrier: Carrier};
    handlers: {onCancel(); onConfirm()};
    onClose();
};

const ArchivingWarn = (props: OwnProps): JSX.Element => {
    const {
        data: {carrier},
        handlers: {onCancel, onConfirm},
        onClose,
    } = props;
    return (
        <ArchivingWarnWrapper title={carrier.company_name} onClose={onClose} onCancel={onCancel} onConfirm={onConfirm}>
            <p>
                <strong>DO YOU WANT TO ARCHIVE THE CARRIER?</strong>
            </p>
            <p>
                {' '}
                The archived carrier`s profile <strong>{carrier.company_name}</strong> will disable any actions with
                it.&nbsp;If needed, you will be able to restore the profile.
            </p>
        </ArchivingWarnWrapper>
    );
};

export default ArchivingWarn;
