import React from 'react';

import styles from './withoutSettlements.module.scss';

const WithoutSettlementsMessage = () => {
    return (
        <div className={styles.message}>
            <div className="modal-body__without-settlements">
                <i className="fa fa-exclamation-circle" /> There are no pay records in current pay period.
            </div>
        </div>
    );
};

export default WithoutSettlementsMessage;
