export const countries = {
    US: {fullName: 'US - USA', name: 'USA', code: 'US'},
    CA: {fullName: 'CA - Canada', name: 'Canada', code: 'CA'},
    MX: {fullName: 'MX - Mexico', name: 'Mexico', code: 'MX'},
    GB: {fullName: 'GB - Great Britain', name: 'Great Britain', code: 'GB'},
    CH: {fullName: 'CH - Switzerland', name: 'Switzerland', code: 'CH'},

    AT: {fullName: 'AT - Austria', name: 'Austria', code: 'AT'},
    BE: {fullName: 'BE - Belgium', name: 'Belgium', code: 'BE'},
    BG: {fullName: 'BG - Bulgarian', name: 'Bulgarian', code: 'BG'},
    HR: {fullName: 'HR - Croatian', name: 'Croatian', code: 'HR'},
    CZ: {fullName: 'CZ - Czech Republic', name: 'Czech Republic', code: 'CZ'},
    DK: {fullName: 'DK - Denmark', name: 'Denmark', code: 'DK'},
    EE: {fullName: 'EE - Estonia', name: 'Estonia', code: 'EE'},
    FR: {fullName: 'FR - French', name: 'French', code: 'FR'},
    DE: {fullName: 'DE - German', name: 'German', code: 'DE'},
    HU: {fullName: 'HU - Hungary', name: 'Hungary', code: 'HU'},
    IT: {fullName: 'IT - Italian', name: 'Italian', code: 'IT'},
    LV: {fullName: 'LV - Latvia', name: 'Latvia', code: 'LV'},
    LT: {fullName: 'LT - Lithuanian', name: 'Lithuanian', code: 'LT'},
    LU: {fullName: 'LU - Luxembourg', name: 'Luxembourg', code: 'LU'},
    NL: {fullName: 'NL - Netherlands', name: 'Netherlands', code: 'NL'},
    PL: {fullName: 'PL - Polish', name: 'Polish', code: 'PL'},
    PT: {fullName: 'PT - Portugal', name: 'Portugal', code: 'PT'},
    RO: {fullName: 'RO - Romanian', name: 'Romanian', code: 'RO'},
    RU: {fullName: 'RU - Russian', name: 'Russian', code: 'RU'},
    SK: {fullName: 'SK - Slovakia', name: 'Slovakia', code: 'SK'},
    SI: {fullName: 'SI - Slovenia', name: 'Slovenia', code: 'SI'},
    ES: {fullName: 'ES - Spanish', name: 'Spanish', code: 'ES'},
    UA: {fullName: 'UA - Ukrainian', name: 'Ukrainian', code: 'UA'},
    EN: {fullName: 'EN - English', name: 'English', code: 'EN'},
    AM: {fullName: 'AM - Amharic', name: 'Amharic', code: 'AM'},
    AR: {fullName: 'AR - Arabic', name: 'Arabic', code: 'AR'},
    HI: {fullName: 'HI - Hindi', name: 'Hindi', code: 'HI'},
    SR: {fullName: 'SR - Serbian', name: 'Serbian', code: 'SR'},
    SO: {fullName: 'SO - Somali', name: 'Somali', code: 'SO'},
    SW: {fullName: 'SW - Swahili', name: 'Swahili', code: 'SW'},
    TI: {fullName: 'TI - Tigrinya', name: 'Tigrinya', code: 'TI'},
    TR: {fullName: 'TR - Turkish', name: 'Turkish', code: 'TR'},
    UR: {fullName: 'UR - Urdu', name: 'Urdu', code: 'UR'},
    UZ: {fullName: 'UZ - Uzbek', name: 'Uzbek', code: 'UZ'},
    MK: {fullName: 'MK - Macedonian', name: 'Macedonian', code: 'MK'},
    FA: {fullName: 'FA - Persian', name: 'Persian', code: 'FA'},
    YO: {fullName: 'YO - Yoruba', name: 'Yoruba', code: 'YO'},
    BA: {fullName: 'BA - Bosnian', name: 'Bosnian', code: 'BA'},
};

export const countriesOfBirth = {
    AD: {name: 'Andorra', code: 'AD'},
    AE: {name: 'United Arab Emirates', code: 'AE'},
    AF: {name: 'Afghanistan', code: 'AF'},
    AG: {name: 'Antigua and Barbuda', code: 'AG'},
    AI: {name: 'Anguilla', code: 'AI'},
    AL: {name: 'Albania', code: 'AL'},
    AM: {name: 'Armenia', code: 'AM'},
    AN: {name: 'Netherlands Antilles', code: 'AN'},
    AO: {name: 'Angola', code: 'AO'},
    AQ: {name: 'Antarctica', code: 'AQ'},
    AR: {name: 'Argentina', code: 'AR'},
    AS: {name: 'American Samoa', code: 'AS'},
    AT: {name: 'Austria', code: 'AT'},
    AU: {name: 'Australia', code: 'AU'},
    AW: {name: 'Aruba', code: 'AW'},
    AX: {name: 'Aland Islands', code: 'AX'},
    AZ: {name: 'Azerbaijan', code: 'AZ'},
    BA: {name: 'Bosnia and Herzegovina', code: 'BA'},
    BB: {name: 'Barbados', code: 'BB'},
    BD: {name: 'Bangladesh', code: 'BD'},
    BE: {name: 'Belgium', code: 'BE'},
    BF: {name: 'Burkina Faso', code: 'BF'},
    BG: {name: 'Bulgaria', code: 'BG'},
    BH: {name: 'Bahrain', code: 'BH'},
    BI: {name: 'Burundi', code: 'BI'},
    BJ: {name: 'Benin', code: 'BJ'},
    BL: {name: 'Saint Barthélemy', code: 'BL'},
    BM: {name: 'Bermuda', code: 'BM'},
    BN: {name: 'Brunei', code: 'BN'},
    BO: {name: 'Bolivia', code: 'BO'},
    BQ: {name: 'Bonaire', code: 'BQ'},
    BR: {name: 'Brazil', code: 'BR'},
    BS: {name: 'Bahamas', code: 'BS'},
    BT: {name: 'Bhutan', code: 'BT'},
    BV: {name: 'Bouvet Island', code: 'BV'},
    BW: {name: 'Botswana', code: 'BW'},
    BY: {name: 'Belarus', code: 'BY'},
    BZ: {name: 'Belize', code: 'BZ'},
    CA: {name: 'Canada', code: 'CA'},
    CC: {name: 'Cocos (Keeling) Islands', code: 'CC'},
    CD: {name: 'Congo', code: 'CD'},
    CF: {name: 'Central African Republic', code: 'CF'},
    CG: {name: 'Congo', code: 'CG'},
    CH: {name: 'Switzerland', code: 'CH'},
    CI: {name: "Côte d'Ivoire", code: 'CI'},
    CK: {name: 'Cook Islands', code: 'CK'},
    CL: {name: 'Chile', code: 'CL'},
    CM: {name: 'Cameroon', code: 'CM'},
    CN: {name: 'China', code: 'CN'},
    CO: {name: 'Colombia', code: 'CO'},
    CR: {name: 'Costa Rica', code: 'CR'},
    CU: {name: 'Cuba', code: 'CU'},
    CV: {name: 'Cabo Verde', code: 'CV'},
    CW: {name: 'Curaçao', code: 'CW'},
    CX: {name: 'Christmas Island', code: 'CX'},
    CY: {name: 'Cyprus', code: 'CY'},
    CZ: {name: 'Czech Republic', code: 'CZ'},
    DE: {name: 'Germany', code: 'DE'},
    DJ: {name: 'Djibouti', code: 'DJ'},
    DK: {name: 'Denmark', code: 'DK'},
    DM: {name: 'Dominica', code: 'DM'},
    DO: {name: 'Dominican Republic', code: 'DO'},
    DZ: {name: 'Algeria', code: 'DZ'},
    EC: {name: 'Ecuador', code: 'EC'},
    EE: {name: 'Estonia', code: 'EE'},
    EG: {name: 'Egypt', code: 'EG'},
    EH: {name: 'Western Sahara', code: 'EH'},
    ER: {name: 'Eritrea', code: 'ER'},
    ES: {name: 'Spain', code: 'ES'},
    ET: {name: 'Ethiopia', code: 'ET'},
    FI: {name: 'Finland', code: 'FI'},
    FJ: {name: 'Fiji', code: 'FJ'},
    FK: {name: 'Falkland Islands (Malvinas)', code: 'FK'},
    FM: {name: 'Micronesia', code: 'FM'},
    FO: {name: 'Faroe Islands', code: 'FO'},
    FR: {name: 'France', code: 'FR'},
    GA: {name: 'Gabon', code: 'GA'},
    GB: {name: 'United Kingdom', code: 'GB'},
    GD: {name: 'Grenada', code: 'GD'},
    GE: {name: 'Georgia', code: 'GE'},
    GF: {name: 'French Guiana', code: 'GF'},
    GG: {name: 'Guernsey', code: 'GG'},
    GH: {name: 'Ghana', code: 'GH'},
    GI: {name: 'Gibraltar', code: 'GI'},
    GL: {name: 'Greenland', code: 'GL'},
    GM: {name: 'Gambia', code: 'GM'},
    GN: {name: 'Guinea', code: 'GN'},
    GP: {name: 'Guadeloupe', code: 'GP'},
    GQ: {name: 'Equatorial Guinea', code: 'GQ'},
    GR: {name: 'Greece', code: 'GR'},
    GS: {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
    GT: {name: 'Guatemala', code: 'GT'},
    GU: {name: 'Guam', code: 'GU'},
    GW: {name: 'Guinea-Bissau', code: 'GW'},
    GY: {name: 'Guyana', code: 'GY'},
    HK: {name: 'Hong Kong', code: 'HK'},
    HM: {name: 'Heard Island and McDonald Islands', code: 'HM'},
    HN: {name: 'Honduras', code: 'HN'},
    HR: {name: 'Croatia', code: 'HR'},
    HT: {name: 'Haiti', code: 'HT'},
    HU: {name: 'Hungary', code: 'HU'},
    ID: {name: 'Indonesia', code: 'ID'},
    IE: {name: 'Ireland', code: 'IE'},
    IL: {name: 'Israel', code: 'IL'},
    IM: {name: 'Isle of Man', code: 'IM'},
    IN: {name: 'India', code: 'IN'},
    IO: {name: 'British Indian Ocean Territory', code: 'IO'},
    IQ: {name: 'Iraq', code: 'IQ'},
    IR: {name: 'Iran', code: 'IR'},
    IS: {name: 'Iceland', code: 'IS'},
    IT: {name: 'Italy', code: 'IT'},
    JE: {name: 'Jersey', code: 'JE'},
    JM: {name: 'Jamaica', code: 'JM'},
    JO: {name: 'Jordan', code: 'JO'},
    JP: {name: 'Japan', code: 'JP'},
    KE: {name: 'Kenya', code: 'KE'},
    KG: {name: 'Kyrgyzstan', code: 'KG'},
    KH: {name: 'Cambodia', code: 'KH'},
    KI: {name: 'Kiribati', code: 'KI'},
    KM: {name: 'Comoros', code: 'KM'},
    KN: {name: 'Saint Kitts and Nevis', code: 'KN'},
    KP: {name: 'Korea', code: 'KP'},
    KR: {name: 'Korea', code: 'KR'},
    KW: {name: 'Kuwait', code: 'KW'},
    KY: {name: 'Cayman Islands', code: 'KY'},
    KZ: {name: 'Kazakhstan', code: 'KZ'},
    LA: {name: "Lao People's Democratic Republic", code: 'LA'},
    LB: {name: 'Lebanon', code: 'LB'},
    LC: {name: 'Saint Lucia', code: 'LC'},
    LI: {name: 'Liechtenstein', code: 'LI'},
    LK: {name: 'Sri Lanka', code: 'LK'},
    LR: {name: 'Liberia', code: 'LR'},
    LS: {name: 'Lesotho', code: 'LS'},
    LT: {name: 'Lithuania', code: 'LT'},
    LU: {name: 'Luxembourg', code: 'LU'},
    LV: {name: 'Latvia', code: 'LV'},
    LY: {name: 'Libya', code: 'LY'},
    MA: {name: 'Morocco', code: 'MA'},
    MC: {name: 'Monaco', code: 'MC'},
    MD: {name: 'Moldova', code: 'MD'},
    ME: {name: 'Montenegro', code: 'ME'},
    MF: {name: 'Saint Martin', code: 'MF'},
    MG: {name: 'Madagascar', code: 'MG'},
    MH: {name: 'Marshall Islands', code: 'MH'},
    MK: {name: 'Macedonia', code: 'MK'},
    ML: {name: 'Mali', code: 'ML'},
    MM: {name: 'Myanmar', code: 'MM'},
    MN: {name: 'Mongolia', code: 'MN'},
    MO: {name: 'Macao', code: 'MO'},
    MP: {name: 'Northern Mariana Islands', code: 'MP'},
    MQ: {name: 'Martinique', code: 'MQ'},
    MR: {name: 'Mauritania', code: 'MR'},
    MS: {name: 'Montserrat', code: 'MS'},
    MT: {name: 'Malta', code: 'MT'},
    MU: {name: 'Mauritius', code: 'MU'},
    MV: {name: 'Maldives', code: 'MV'},
    MW: {name: 'Malawi', code: 'MW'},
    MX: {name: 'Mexico', code: 'MX'},
    MY: {name: 'Malaysia', code: 'MY'},
    MZ: {name: 'Mozambique', code: 'MZ'},
    NA: {name: 'Namibia', code: 'NA'},
    NC: {name: 'New Caledonia', code: 'NC'},
    NE: {name: 'Niger', code: 'NE'},
    NF: {name: 'Norfolk Island', code: 'NF'},
    NG: {name: 'Nigeria', code: 'NG'},
    NI: {name: 'Nicaragua', code: 'NI'},
    NL: {name: 'Netherlands', code: 'NL'},
    NO: {name: 'Norway', code: 'NO'},
    NP: {name: 'Nepal', code: 'NP'},
    NR: {name: 'Nauru', code: 'NR'},
    NU: {name: 'Niue', code: 'NU'},
    NZ: {name: 'New Zealand', code: 'NZ'},
    OM: {name: 'Oman', code: 'OM'},
    PA: {name: 'Panama', code: 'PA'},
    PE: {name: 'Peru', code: 'PE'},
    PF: {name: 'French Polynesia', code: 'PF'},
    PG: {name: 'Papua New Guinea', code: 'PG'},
    PH: {name: 'Philippines', code: 'PH'},
    PK: {name: 'Pakistan', code: 'PK'},
    PL: {name: 'Poland', code: 'PL'},
    PM: {name: 'Saint Pierre and Miquelon', code: 'PM'},
    PN: {name: 'Pitcairn', code: 'PN'},
    PR: {name: 'Puerto Rico', code: 'PR'},
    PS: {name: 'Palestine', code: 'PS'},
    PT: {name: 'Portugal', code: 'PT'},
    PW: {name: 'Palau', code: 'PW'},
    PY: {name: 'Paraguay', code: 'PY'},
    QA: {name: 'Qatar', code: 'QA'},
    RE: {name: 'Réunion', code: 'RE'},
    RO: {name: 'Romania', code: 'RO'},
    RS: {name: 'Serbia', code: 'RS'},
    RU: {name: 'Russian Federation', code: 'RU'},
    RW: {name: 'Rwanda', code: 'RW'},
    SA: {name: 'Saudi Arabia', code: 'SA'},
    SB: {name: 'Solomon Islands', code: 'SB'},
    SC: {name: 'Seychelles', code: 'SC'},
    SD: {name: 'Sudan', code: 'SD'},
    SE: {name: 'Sweden', code: 'SE'},
    SG: {name: 'Singapore', code: 'SG'},
    SH: {name: 'Saint Helena', code: 'SH'},
    SI: {name: 'Slovenia', code: 'SI'},
    SJ: {name: 'Svalbard and Jan Mayen', code: 'SJ'},
    SK: {name: 'Slovakia', code: 'SK'},
    SL: {name: 'Sierra Leone', code: 'SL'},
    SM: {name: 'San Marino', code: 'SM'},
    SN: {name: 'Senegal', code: 'SN'},
    SO: {name: 'Somalia', code: 'SO'},
    SR: {name: 'Suriname', code: 'SR'},
    SS: {name: 'South Sudan', code: 'SS'},
    ST: {name: 'Sao Tome and Principe', code: 'ST'},
    SV: {name: 'El Salvador', code: 'SV'},
    SX: {name: 'Sint Maarten', code: 'SX'},
    SY: {name: 'Syrian Arab Republic', code: 'SY'},
    SZ: {name: 'Swaziland', code: 'SZ'},
    TC: {name: 'Turks and Caicos Islands', code: 'TC'},
    TD: {name: 'Chad', code: 'TD'},
    TF: {name: 'French Southern Territories', code: 'TF'},
    TG: {name: 'Togo', code: 'TG'},
    TH: {name: 'Thailand', code: 'TH'},
    TJ: {name: 'Tajikistan', code: 'TJ'},
    TK: {name: 'Tokelau', code: 'TK'},
    TL: {name: 'Timor-Leste', code: 'TL'},
    TM: {name: 'Turkmenistan', code: 'TM'},
    TN: {name: 'Tunisia', code: 'TN'},
    TO: {name: 'Tonga', code: 'TO'},
    TR: {name: 'Turkey', code: 'TR'},
    TT: {name: 'Trinidad and Tobago', code: 'TT'},
    TV: {name: 'Tuvalu', code: 'TV'},
    TW: {name: 'Taiwan', code: 'TW'},
    TZ: {name: 'Tanzania', code: 'TZ'},
    UA: {name: 'Ukraine', code: 'UA'},
    UG: {name: 'Uganda', code: 'UG'},
    UM: {name: 'United States Minor Outlying Islands', code: 'UM'},
    UR: {name: 'USSR', code: 'UR'},
    US: {name: 'United States', code: 'US'},
    UY: {name: 'Uruguay', code: 'UY'},
    UZ: {name: 'Uzbekistan', code: 'UZ'},
    VA: {name: 'Holy See', code: 'VA'},
    VC: {name: 'Saint Vincent and the Grenadines', code: 'VC'},
    VE: {name: 'Venezuela', code: 'VE'},
    VG: {name: 'Virgin Islands', code: 'VG'},
    VI: {name: 'Virgin Islands', code: 'VI'},
    VN: {name: 'Viet Nam', code: 'VN'},
    VU: {name: 'Vanuatu', code: 'VU'},
    WB: {name: 'West Bank', code: 'WB'},
    WF: {name: 'Wallis and Futuna', code: 'WF'},
    WS: {name: 'Samoa', code: 'WS'},
    XK: {name: 'Kosovo', code: 'XK'},
    YE: {name: 'Yemen', code: 'YE'},
    YT: {name: 'Mayotte', code: 'YT'},
    ZA: {name: 'South Africa', code: 'ZA'},
    ZM: {name: 'Zambia', code: 'ZM'},
    ZW: {name: 'Zimbabwe', code: 'ZW'},
};

export default countries;
