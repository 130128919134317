import React from 'react';

import {AutoCheckCallEvent} from 'core/entities/TravelOrder/types/AutoCheckCallSettings';

import {transformDistance} from 'utils/distance';

import TooltipBootstrap from 'components/ui/TooltipBootstrap';

type OwnProps = {
    left: AutoCheckCallEvent['milesLeft'];
};

const DistanceLeft: React.FC<OwnProps> = ({left}) => {
    const {fromMiles, shortUnit} = transformDistance(left);

    return (
        <TooltipBootstrap tooltipContent="to the next stop">
            <span>
                left: {fromMiles} {shortUnit}&nbsp;
            </span>
        </TooltipBootstrap>
    );
};

export default DistanceLeft;
