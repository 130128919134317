import {driverActionStatus} from 'core/entities/Quote/constants/driverActionStatus';

import {DriverReceiverWithTruck, Quote} from '../../types';

type QuoteDriverReceiver = Quote['driversReceivers'][number];

export const isAllowedSendQuoteToDriver = (quoteDriverReceiver: QuoteDriverReceiver): boolean => {
    return quoteDriverReceiver.actionStatus === driverActionStatus.none && quoteDriverReceiver.isReceiveQuoteAllowed;
};

export const isNotAllowedSendQuoteToDriver = (receiver: DriverReceiverWithTruck): boolean => {
    return receiver.actionStatus === driverActionStatus.none && !receiver.isReceiveQuoteAllowed;
};

export const isDriverDeclinedQuote = (quoteDriverReceiver: QuoteDriverReceiver): boolean => {
    return quoteDriverReceiver.actionStatus === driverActionStatus.declined;
};

export const isDriverReadQuote = (quoteDriverReceiver: QuoteDriverReceiver): boolean => {
    return quoteDriverReceiver.actionStatus === driverActionStatus.read;
};

export const isDriverProvidedQuote = (quoteDriverReceiver: QuoteDriverReceiver): boolean => {
    return quoteDriverReceiver.actionStatus === driverActionStatus.provided;
};

export const getCountDriversWhoAllowedSendQuote = (allDrivers: QuoteDriverReceiver[]): number => {
    return allDrivers.filter((i) => i.isReceiveQuoteAllowed && i.actionStatus === driverActionStatus.none).length;
};

export const getCountDriversWhoNotAllowedSendQuote = (allDrivers: QuoteDriverReceiver[]): number => {
    return allDrivers.filter((i) => !i.isReceiveQuoteAllowed && i.actionStatus === driverActionStatus.none).length;
};

export const getCountDriversWhoDeclinedQuote = (allDrivers: QuoteDriverReceiver[]): number => {
    return allDrivers.filter((i) => i.actionStatus === driverActionStatus.declined).length;
};

export const getCountDriversWhoReadQuote = (allDrivers: QuoteDriverReceiver[]): number => {
    return allDrivers.filter((i) => i.actionStatus === driverActionStatus.read).length;
};

export const getCountDriversWhoProvidedQuote = (allDrivers: QuoteDriverReceiver[]): number => {
    return allDrivers.filter((i) => i.actionStatus === driverActionStatus.provided).length;
};

export const getTruckLocationCityLine = (quoteDriverReceiver: QuoteDriverReceiver): string => {
    const {truckLocation} = quoteDriverReceiver;
    return [truckLocation.city, truckLocation.state, truckLocation.zip, truckLocation.country]
        .filter(Boolean)
        .join(', ');
};
