import {ThunkAction} from 'redux-thunk';

import * as appActions from 'store/actions';
import {AppState} from 'store';

import {getTravelOrderNumber} from 'core/entities/TravelOrder/modules/common/getTravelOrderNumber';
import * as toRequests from 'core/gateways/TravelOrderApiGateway/requests/indexNew';

import * as types from 'pages/TravelOrders/redux/actionTypes/travelOrder';
import {getCurrentTravelOrder} from 'pages/TravelOrders/redux/selectors';

type ThunkActionTypes = ThunkAction<void, AppState, unknown, any>;

const brokerActionCreators = {
    sendThirdApiRequestToggle: (payload: {isNeedToSendThirdApiRequest: boolean}) =>
        ({type: types.TRAVEL_ORDER_SEND_THIRD_API_REQUEST_TOGGLE, payload} as const),
};

export const toggleIsNeedSendThirdApiRequest = (): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);

    try {
        dispatch(appActions.showLoader());

        const updatedToggle = {isNeedToSendThirdApiRequest: !travelOrder?.isNeedToSendThirdApiRequest};
        const requestParams = {toNumber, requestBody: updatedToggle};

        await toRequests.setToggleThirdApiRequestSendingStatus(requestParams);

        dispatch(brokerActionCreators.sendThirdApiRequestToggle(updatedToggle));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};
