import restApi from 'services/restapi';
import {getGeneralNotes} from 'services/restapi/generalNotes';

import parseFileExtraData from 'utils/files/parseFileExtraData';
import {ENTITY_NAME_LOADS} from 'utils/data/entityNames';

const fetchFiles = async (loadID, filesType) => {
    let result = [];

    try {
        const getParams = {entity: 'loads', id: loadID, type: filesType};
        result = await restApi('files', {params: getParams}).then(
            (response) => response.data && response.data.map(parseFileExtraData),
        );
    } catch (error) {
        console.warn('Fetch load files error: ', error);
    }

    return result;
};

export const fetchLoadFiles = (loadID, filesType) => fetchFiles(loadID, filesType);

export const fetchLoadNotes = (loadID) => {
    const params = {entity_name: ENTITY_NAME_LOADS, entity_id: loadID};
    return getGeneralNotes(params).then(({data}) => data);
};
