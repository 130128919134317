import {FieldArrayFieldsProps} from 'redux-form';

import {LoadStop} from 'pages/LoadBoard/types';

const MIN_STOPS_LENGTH = 2;
const FIRST_STOP_INDEX = 0;
const SECOND_STOP_INDEX = 1;

type ActionProps = {
    index: number;
    fields: FieldArrayFieldsProps<LoadStop>;
};

export const getIsItemCanBeMovedUp = ({index, fields}: ActionProps): boolean => {
    const LAST_STOP_INDEX = fields.length - 1;
    const prevStop = fields.get(index - 1);

    const isLessThanMinStopsCount = fields.length <= MIN_STOPS_LENGTH;
    const isCurrentStopFirst = index === FIRST_STOP_INDEX;
    const isCurrentStopSecond = index === SECOND_STOP_INDEX;
    const isCurrentStopLastAndStopUpIsPickup = index === LAST_STOP_INDEX && prevStop.type === 'PICKUP';

    if (isLessThanMinStopsCount || isCurrentStopFirst || isCurrentStopSecond || isCurrentStopLastAndStopUpIsPickup) {
        return false;
    }

    return true;
};

export const getIsItemCanBeMovedDown = ({index, fields}: ActionProps): boolean => {
    // eslint-disable-next-line no-magic-numbers
    const PRE_LAST_STOP_INDEX = fields.length - 2;
    const LAST_STOP_INDEX = fields.length - 1;

    const currStop = fields.get(index);

    const isLessThanMinStopsCount = fields.length <= MIN_STOPS_LENGTH;
    const isCurrentStopFirst = index === FIRST_STOP_INDEX;
    const isCurrentStopLast = index === LAST_STOP_INDEX;
    const isCurrentPreLastStopIsPickup = index === PRE_LAST_STOP_INDEX && currStop.type === 'PICKUP';

    if (isLessThanMinStopsCount || isCurrentStopLast || isCurrentStopFirst || isCurrentPreLastStopIsPickup) {
        return false;
    }

    return true;
};

export const getIsStopCanBeDeleted = ({index, fields}: ActionProps): boolean => {
    const LAST_STOP_INDEX = fields.length - 1;

    const prevStop = fields.get(index - 1);

    const isLessThanMinStopsCount = fields.length <= MIN_STOPS_LENGTH;
    const isCurrentStopIsFirst = index === FIRST_STOP_INDEX;
    const isCurrentStopIsLastAndPrevIsPickup = index === LAST_STOP_INDEX && prevStop.type === 'PICKUP';

    if (isLessThanMinStopsCount || isCurrentStopIsFirst || isCurrentStopIsLastAndPrevIsPickup) {
        return false;
    }

    return true;
};
