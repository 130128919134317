import {useDispatch} from 'react-redux';

import {LoadBoardNotification} from '../types';
import {
    fetchLoadBoardNotifications,
    readLoadBoardNotification,
    removeLoadBoardNotification,
    removeAllLoadBoardNotification,
} from '../redux/actions';
import * as actionCreators from '../redux/actionCreators';

type Actions = {
    fetchNotifications: () => void;
    readNotification: (notification: LoadBoardNotification) => void;
    removeNotification: (notification: LoadBoardNotification) => void;
    removeAllNotifications: () => void;
    removeNewNotification: (notification: LoadBoardNotification) => void;
};

export default (): Actions => {
    const dispatch = useDispatch();
    return {
        fetchNotifications: () => dispatch(fetchLoadBoardNotifications()),
        readNotification: (notification) => dispatch(readLoadBoardNotification(notification)),
        removeNotification: (notification) => dispatch(removeLoadBoardNotification(notification)),
        removeAllNotifications: () => dispatch(removeAllLoadBoardNotification()),
        removeNewNotification: (notification) =>
            dispatch(actionCreators.removeNewLoadBoardNotificationWS(notification)),
    };
};
