import React from 'react';

type OwnProps = {
    label: string;
    stopNumber: number;
    className: string;
    children?: React.ReactNode;
};

const Title: React.FC<OwnProps> = ({label, stopNumber, className, children}) => {
    return (
        <div className={`${className} mb-2 d-flex justify-content-between`}>
            <h6 className="mb-2">
                {`Stop (${stopNumber})`} <strong>{`${label}`}</strong>
            </h6>
            {children}
        </div>
    );
};

export default Title;
