export const TRUCK_PREFERRED_LOADS_LOCAL_TRIP = 'local';
export const TRUCK_PREFERRED_LOADS_LONG_TRIP = 'long';
export const TRUCK_PREFERRED_LOADS_ANY = 'any';

export const TRUCK_PREFERRED_LOADS = {
    [TRUCK_PREFERRED_LOADS_LOCAL_TRIP]: 'Local Trip',
    [TRUCK_PREFERRED_LOADS_LONG_TRIP]: 'Long Trip',
    [TRUCK_PREFERRED_LOADS_ANY]: 'Any',
} as const;

export const TRUCK_PREFERRED_LOADS_SELECT_OPTIONS = [
    {label: 'Local Trip', value: TRUCK_PREFERRED_LOADS_LOCAL_TRIP},
    {label: 'Long Trip', value: TRUCK_PREFERRED_LOADS_LONG_TRIP},
    {label: 'Any', value: TRUCK_PREFERRED_LOADS_ANY},
];
