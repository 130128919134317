import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';

import {isPayPeriodClosed, isSettlementStatusPosted} from 'core/entities/Settlement';

import {createDate} from 'utils/dateTime';

import SettlementSearchParams from '../types/SettlementSearchParams';

export const isSearchByMainParams = (searchParams: SettlementSearchParams): boolean => {
    return Boolean(searchParams.settlement || searchParams.invoice);
};

export const isAllSettlementsChecked = (settlements, selectedSettlements) => {
    if (isEmpty(settlements) && isEmpty(selectedSettlements)) {
        return false;
    }

    return difference(Object.keys(settlements), Object.keys(selectedSettlements)).length === 0;
};

export const convertToUTCTime = (date, {timeZoneFrom}) => {
    const convertedDate = createDate(date, {fromTimeZone: timeZoneFrom || 'utc', toTimeZone: 'utc'});

    if (!convertedDate.isValid) {
        return '';
    }

    return convertedDate.fullOriginalDateTime;
};

export const isSettlementOwnerResetDisabled = (settlement) => {
    const period = settlement?.pay_period;
    const payPeriodClosed = Boolean(period) && isPayPeriodClosed(period);

    return payPeriodClosed && isSettlementStatusPosted(settlement);
};
