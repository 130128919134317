import Customer from 'core/entities/Customer/types';

export const checkIsCustomerHasBillingMethod = (customer?: Customer | null) => Boolean(customer?.billing_method);

export const getCustomerBillingMethod = (customer?: Customer | null) => customer?.billing_method;

export const checkIsCustomerHasFactoredBillingMethod = (customer?: Customer | null) =>
    /factored/i.test(String(customer?.billing_method));

export const checkIsCustomerHasDirectBillingMethod = (customer?: Customer | null) =>
    /direct/i.test(String(customer?.billing_method));
