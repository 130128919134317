import React from 'react';

import {Quote} from 'core/entities/Quote/types';
import {getTruckCrossBorder} from 'core/entities/Quote/modules/quoteTruck';

import CrossBorder from './components/CrossBorderBadge';

const QuoteTruckCrossBorder: React.FC<{quote: Quote}> = (props) => {
    const {quote} = props;
    const crossBorders = getTruckCrossBorder(quote);
    return (
        <>
            {crossBorders.map((crossBorder) => (
                <CrossBorder key={crossBorder} crossBorder={crossBorder} />
            ))}
        </>
    );
};
export default QuoteTruckCrossBorder;
