import ApproveSettlementModal from './ApproveSettlementModal';
import ResetSettlementStatusModal from './ResetSettlementStatusModal';
import SetupSettlementsBatchModal from './SetupSettlementsBatchModal';
import SetupSettlementsPayPeriodModal from './SetupSettlementsPayPeriodModal';
import EditSettlementsBatchModal from './EditSettlementsBatchModal';
import EditSettlementsPayPeriodModal from './EditSettlementsPayPeriodModal';
import SettlementDeductionPaymentModal from './SettlementDeductionPaymentModal';
import SettlementReimbursementPaymentModal from './SettlementReimbursementPaymentModal';
import AddPayCorrectionModal from './AddPayCorrectionModal';
import DeletePayCorrectionModal from './DeletePayCorrectionModal';
import CreatePayPeriodModal from './CreatePayPeriodModal';
import PostSettlementCarrierModal from './PostSettlementCarrierModal';
import PostSettlementOwnerModal from './PostSettlementOwnerModal';
import ReOpenBatchModal from './ReOpenBatchModal';
import ReOpenPayPeriodModal from './ReOpenPayPeriodModal';
import ErrorChangeStatusModal from './ErrorChangeStatusModal';
import WebsocketUpdateModal from './WebsocketUpdateModal';
import DeletingWarnModal from './DeletingWarnModal';

export const settlementsModalNames = {
    approveSettlement: 'APPROVE_SETTLEMENT_MODAL',
    resetSettlementStatus: 'RESET_SETTLEMENT_STATUS_MODAL',
    generatePDF: 'GENERATE_SETTLEMENT_PDF_MODAL',
    setupBatchModal: 'SETTLEMENTS_SETUP_BATCH_MODAL',
    setupPayPeriodModal: 'SETTLEMENTS_SETUP_PAY_PERIOD_MODAL',
    editBatchModal: 'SETTLEMENTS_EDIT_BATCH_MODAL',
    editPayPeriodModal: 'SETTLEMENTS_EDIT_PAY_PERIOD_MODAL',
    reOpenBatchModal: 'RE_OPEN_BATCH_MODAL',
    reOpenPayPeriodModal: 'RE_OPEN_PAY_PERIOD_MODAL',
    settlementDeductionPaymentModal: 'SETTLEMENT_DEDUCTION_PAYMENT_MODAL',
    settlementReimbursementPaymentModal: 'SETTLEMENT_REIMBURSEMENT_PAYMENT_MODAL',
    payRecordDeductionPaymentModal: 'PAY_RECORD_DEDUCTION_PAYMENT_MODAL',
    payRecordReimbursementPaymentModal: 'PAY_RECORD_REIMBURSEMENT_PAYMENT_MODAL',
    deletePayCorrectionModal: 'DELETE_PAY_RECORD_DEDUCTION_MODAL',
    deletePayRecordReimbursementModal: 'DELETE_PAY_RECORD_REIMBURSEMENT_MODAL',
    createPayPeriodModal: 'CREATE_PAY_PERIOD_MODAL',
    postSettlementCarrierModal: 'POST_SETTLEMENT_CARRIER_MODAL',
    postSettlementOwnerModal: 'POST_SETTLEMENT_OWNER_MODAL',
    errorChangeStatus: 'ERROR_CHANGE_STATUS_SETTLEMENT_OWNER_MODAL',
    deletingWarnModal: 'DELETING_SETTLEMENT_WARN_MODAL',
    websocketUpdateModal: 'WEBSOCKET_UPDATE_MODAL',
};

const modalMap = {
    [settlementsModalNames.approveSettlement]: ApproveSettlementModal,
    [settlementsModalNames.resetSettlementStatus]: ResetSettlementStatusModal,
    [settlementsModalNames.setupBatchModal]: SetupSettlementsBatchModal,
    [settlementsModalNames.setupPayPeriodModal]: SetupSettlementsPayPeriodModal,
    [settlementsModalNames.editBatchModal]: EditSettlementsBatchModal,
    [settlementsModalNames.editPayPeriodModal]: EditSettlementsPayPeriodModal,
    [settlementsModalNames.reOpenBatchModal]: ReOpenBatchModal,
    [settlementsModalNames.reOpenPayPeriodModal]: ReOpenPayPeriodModal,
    [settlementsModalNames.payRecordDeductionPaymentModal]: AddPayCorrectionModal,
    [settlementsModalNames.deletePayCorrectionModal]: DeletePayCorrectionModal,
    [settlementsModalNames.settlementDeductionPaymentModal]: SettlementDeductionPaymentModal,
    [settlementsModalNames.settlementReimbursementPaymentModal]: SettlementReimbursementPaymentModal,
    [settlementsModalNames.createPayPeriodModal]: CreatePayPeriodModal,
    [settlementsModalNames.postSettlementCarrierModal]: PostSettlementCarrierModal,
    [settlementsModalNames.postSettlementOwnerModal]: PostSettlementOwnerModal,
    [settlementsModalNames.errorChangeStatus]: ErrorChangeStatusModal,
    [settlementsModalNames.deletingWarnModal]: DeletingWarnModal,
    [settlementsModalNames.websocketUpdateModal]: WebsocketUpdateModal,
};

export default modalMap;
