export const FILE_IDS_TO_BE_REMOVED_KEY = 'fileIDsToBeRemoved';

export const COMMON_ATTACHMENT_TYPES = {
    // types for files uploaded to server
    insuranceFile: 'insurance_file',
    generalFiles: 'general_files',
    otherFiles: 'other_files',
    photos: 'photos',

    // types for files uploaded to form but not submitted to server
    newGeneralFiles: 'new_general_files',
    newOtherFiles: 'new_other_files',
    newPhotos: 'new_photos',
};

export const ACCEPTED_FILE_TYPES = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/pdf',
    'text/plain',
    'text/csv',
    // .doc
    'application/msword',
    // .docx
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // .odt
    'application/vnd.oasis.opendocument.text',
    // .xls
    'application/vnd.ms-excel',
    // .xlsx
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const ACCEPTED_IMAGE_TYPES = [
    'png',
    'jpg',
    'jpeg',
    'tiff',
    'gif',
    'bmp',
    'image/jpeg',
    'image/png',
    'image/gif',
];

const ALLOWED_IMAGE_MIME_TYPES = {
    'image/jpeg': ['.jpeg'],
    'image/jpg': ['.jpg'],
    'image/png': ['.png'],
    'image/bmp': ['.bmp'],
};

const ALLOWED_FILE_MIME_TYPES = {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.oasis.opendocument.text': ['.odt'],
    'application/vnd.ms-excel': ['.xls'],
    'application/msword': ['.doc'],
    'application/pdf': ['.pdf'],
    'text/plain': ['.txt'],
};

export const ACCEPTED_DROPZONE_FILE_TYPES = {
    ...ALLOWED_IMAGE_MIME_TYPES,
    ...ALLOWED_FILE_MIME_TYPES,
};
