import React from 'react';
import {Modal} from 'react-bootstrap';
import Button from 'components/ui/Buttons/Button';

const NavigationConfirm = ({onClose, handlers: {onConfirm}}, isCreate) => (
    <Modal show={true} className="navigate-modal">
        <Modal.Header>
            {isCreate ? <Modal.Title>Cancel Create</Modal.Title> : <Modal.Title>Cancel Update</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
            <div style={{display: 'flex'}}>
                <div className="warning-icon-block pull-left">
                    <i className="fa fa-exclamation" aria-hidden="true" />
                </div>
                <div className="pull-left">
                    <div>You have unsaved data on this page that might be lost.</div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button colorTheme="grey" buttonSize="small" onClick={onClose} className="pull-left">
                Close
            </Button>

            <Button
                colorTheme="red"
                buttonSize="small"
                // eslint-disable-next-line
                onClick={() => {
                    onConfirm();
                    onClose();
                }}
            >
                Confirm
            </Button>
        </Modal.Footer>
    </Modal>
);

export default NavigationConfirm;
