import React from 'react';
import {useLocation} from 'react-router';

import Truck from 'core/entities/Truck/types';

import ArchivingSuccessWrapper from 'components/ui/modals/ArchivingSuccessWrapper';
import ButtonLink from 'components/ui/Buttons/ButtonLink';
import Button from 'components/ui/Buttons/Button';
import {getTruckLinks} from 'routing/linkBuilder';

type OwnProps = {
    data: {truck: Truck};
    handlers: {onViewArchivedList()};
    onClose();
};
const ArchivingSuccess: React.FC<OwnProps> = (props) => {
    const {
        data: {truck},
        handlers: {onViewArchivedList},
        onClose,
    } = props;
    const {view: viewLink, list: listTrucksLink} = getTruckLinks(truck);
    const location = useLocation();
    const isListPage = location?.pathname === listTrucksLink;
    const buttons = (
        <>
            <div />
            <div className="d-flex justify-content-end">
                <Button onClick={onViewArchivedList} buttonSize="medium" colorTheme="grey" className="mr10">
                    View Archive
                </Button>

                {isListPage ? (
                    <ButtonLink href={viewLink} buttonSize="medium" colorTheme="green" className="mr10">
                        View Profile
                    </ButtonLink>
                ) : (
                    <Button onClick={onClose} buttonSize="medium" colorTheme="green" className="mr10">
                        View Profile
                    </Button>
                )}
            </div>
        </>
    );
    return (
        <ArchivingSuccessWrapper buttons={buttons}>
            <p>
                <strong>#{truck.number}</strong> truck`s profile has been moved to archive successfully.
            </p>
        </ArchivingSuccessWrapper>
    );
};

export default ArchivingSuccess;
