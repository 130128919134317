export const RECEIVED_GENERAL_NOTE_TRAVEL_ORDER = 'TRIP_MONITOR/RECEIVED_GENERAL_NOTE_TRAVEL_ORDER' as const;
export const SET_TRIP_MONITOR_META_INFORMATION = 'TRIP_MONITOR/SET_TRIP_MONITOR_META_INFORMATION' as const;
export const RECEIVED_LOAD_NOTE_TRAVEL_ORDER = 'TRIP_MONITOR/RECEIVED_LOAD_NOTE_TRAVEL_ORDER' as const;
export const SET_TRIP_MONITOR_SEARCH_PARAMS = 'TRIP_MONITOR/SET_TRIP_MONITOR_SEARCH_PARAMS' as const;
export const SET_TRIP_MONITOR_PAGINATION = 'TRIP_MONITOR/SET_TRIP_MONITOR_PAGINATION' as const;
export const SET_TRIP_MONITOR_SORT_ORDER = 'TRIP_MONITOR/SET_TRIP_MONITOR_SORT_ORDER' as const;
export const ADD_BROKER_UPDATE_TO_EVENT = 'TRIP_MONITOR/ADD_BROKER_UPDATE_TO_EVENT' as const;
export const SET_TRIP_MONITOR_SORT_BY = 'TRIP_MONITOR/SET_TRIP_MONITOR_SORT_BY' as const;
export const TRIP_MONITOR_AUTO_EVENT_STATUS_RECEIVED = 'TRIP_MONITOR/AUTO_EVENT_STATUS_RECEIVED' as const;
export const CLEAR_TRIP_MONITOR_STATE = 'TRIP_MONITOR/CLEAR_TRIP_MONITOR_STATE' as const;
export const TRAVEL_ORDERS_RECEIVED = 'TRIP_MONITOR/TRAVEL_ORDERS_RECEIVED' as const;
export const SET_TRIP_MONITOR_TAB = 'TRIP_MONITOR/SET_TRIP_MONITOR_TAB' as const;
export const EVENT_TIMER_RECEIVED = 'TRIP_MONITOR/EVENT_TIMER_RECEIVED' as const;
export const SSE_DATA_RECEIVED = 'SSE/TRIP_MONITOR/DATA_RECEIVED' as const;
export const EVENT_RECEIVED = 'TRIP_MONITOR/EVENT_RECEIVED' as const;
