import * as actions from 'store/middlewares/mainAPISocketsIO/entities/notifications/actions';
import {notificationsEvents} from 'store/middlewares/mainAPISocketsIO/events';

export const notificationsChannelMessageHandler = (store) => (_, payload) => {
    const {msg} = payload || {};

    if (!msg.event || !msg.data) {
        return;
    }

    const {event, data} = msg;

    switch (event) {
        case notificationsEvents.CUSTOMER_SUCCESSFUL_MERGED:
            store.dispatch(actions.showSuccessfulMergedCustomersNotification(data));
            break;
        case notificationsEvents.CUSTOMER_UNSUCCESSFUL_MERGED:
            store.dispatch(actions.showUnsuccessfulMergedCustomersNotification(data));
            break;
        default:
            break;
    }
};
