import {AppState} from 'store';
import {getPagePermissions} from 'store/reducers/userPermissions/selectors';
import permissionsFields from 'utils/data/permissions';
import * as pages from 'utils/data/pages';
import {Quote, DriverOffer} from 'core/entities/Quote/types';

const getLoadBoardPagePermissions = (state: AppState) => getPagePermissions(state, pages.LOAD_BOARD);

export const getQuotes = (state: AppState) => state.loadBoard.list.quotes;
export const getQuotesListType = (state: AppState) => state.loadBoard.list.type;
export const getQuoteByID = (state: AppState, quoteID: string): Quote | undefined =>
    state.loadBoard.list.quotes.byId[quoteID];

export const getQuoteDriversReceivers = (state: AppState) => {
    return state.loadBoard.list.quoteReceivers;
};

export const getQuoteOffersIsExpanded = (state: AppState, quoteID: string): boolean => {
    return state.loadBoard.list.expandedOffers[quoteID] === true;
};

export const getOfferIsExpired = (state: AppState, offerID: string): boolean => {
    return state.loadBoard.list.expiredOffers[offerID] === true;
};

export const getQuoteOffers = (state: AppState, quoteID: Quote['id']): DriverOffer[] => {
    const loadBoardState = state.loadBoard.list;
    return loadBoardState.offers.allIds.reduce((result, offerID) => {
        const offer = loadBoardState.offers.byId[offerID];
        if (offer.quoteID === quoteID) {
            result.push(offer);
        }
        return result;
    }, [] as DriverOffer[]);
};

export const getSearchParams = (state: AppState) => state.loadBoard.list.searchParams;
export const getPagination = (state: AppState) => state.loadBoard.list.pagination;
export const getIsQuiteSending = (state: AppState, quoteID: Quote['id']) => {
    return Boolean(state.loadBoard.list.sendingQuotes[quoteID]);
};
export const getPermissionCreate = (state: AppState) => {
    const pagePermission = getLoadBoardPagePermissions(state);
    return pagePermission[permissionsFields.create] !== false;
};
export const getPermissionView = (state: AppState) => {
    const pagePermission = getLoadBoardPagePermissions(state);
    return pagePermission[permissionsFields.view] !== false;
};
export const getPermissionUpdate = (state: AppState) => {
    const pagePermission = getLoadBoardPagePermissions(state);
    return pagePermission[permissionsFields.update] !== false;
};
export const getPermissionDelete = (state: AppState) => {
    const pagePermission = getLoadBoardPagePermissions(state);
    return pagePermission[permissionsFields.delete] !== false;
};
export const getPermissionList = (state: AppState) => {
    const pagePermission = getLoadBoardPagePermissions(state);
    return pagePermission[permissionsFields.list] !== false;
};
