import {getCurrentDispatcher} from 'store/reducers/userData/selectors';

import {openModal, closeModal, closeAll} from 'components/ui/ModalProvider/actions';
import {getDriverOfferNumber} from 'core/entities/Quote/modules/offer/driverOfferNumber';
import {Quote, DriverOffer, FormModeTypes} from 'core/entities/Quote/types';
import {fetchDispatcher} from 'core/gateways/DispatcherApiGateway/requests';

import {goToArchivedList, goToGeneralList} from 'pages/LoadBoard/redux/actionCreators';
import {QuoteInitialValues} from 'pages/LoadBoard/types';
import {quoteModalNames} from 'pages/LoadBoard/components/modals/modalMap';

import Truck from 'core/entities/Truck/types';
import {createQuote, archiveQuote} from './quotesActions';
import {
    addDriverOffer,
    updateDriverOffer,
    deactivateDriverOffer,
    approveDriverOffer,
    unApproveDriverOffer,
    cancelDriverOfferStatus,
} from './offerActions';
import {fetchQuoteGeneralNotes, createQuoteGeneralNote, deleteQuoteGeneralNote} from './notesActions';

const getDispatcher = (dispatcherId?: number | null) => {
    if (!dispatcherId) {
        return;
    }
    return fetchDispatcher(dispatcherId).then((response) => {
        return response.data;
    });
};

export const openCreateQuoteModal = (params: {
    fetchAllTNBTrucks: () => Promise<Truck[]>;
    quoteInitialData: QuoteInitialValues;
}) => (dispatch) => {
    let resetModalID;
    const successModalAction = openModal({
        modalName: quoteModalNames.createQuoteModal,
        data: params,
        handlers: {
            onCreate: (data: QuoteInitialValues) => {
                dispatch(createQuote(data)).then(() => dispatch(closeModal(resetModalID)));
            },
        },
    });
    resetModalID = successModalAction?.payload?.id;
    dispatch(successModalAction);
};

export const openArchiveQuoteModal = (quote: Quote) => (dispatch) => {
    let archiveModalID;
    const successModalAction = openModal({
        modalName: quoteModalNames.archiveQuoteModal,
        data: quote,
        handlers: {
            onArchive: (data) => {
                dispatch(closeModal(archiveModalID));
                dispatch(archiveQuote(data));
            },
        },
    });
    archiveModalID = successModalAction?.payload?.id;
    dispatch(successModalAction);
};
export const openCreateDriverOfferModal = (quote: Quote) => (dispatch) => {
    const mode: FormModeTypes = 'CREATE';
    const successModalAction = openModal({
        modalName: quoteModalNames.driverOfferModal,
        data: {
            quote,
            offer: {
                quoteID: quote.id,
                truck: undefined,
                payment: {
                    rate: undefined,
                    rpm: undefined,
                    currency: 'USD',
                },
                emptyDistance: undefined,
                note: undefined,
            },
            mode,
        },
        handlers: {
            onSubmit: (data) => {
                dispatch(addDriverOffer(data));
            },
        },
    });
    dispatch(successModalAction);
};

export const openUpdateDriverOfferModal = (quote: Quote, offer: DriverOffer) => async (dispatch) => {
    const mode: FormModeTypes = 'UPDATE';
    const createdByDispatcher = await getDispatcher(offer.createdByDispatcherID);
    const updatedByDispatcher = await getDispatcher(offer.updatedByDispatcherID);
    const successModalAction = openModal({
        modalName: quoteModalNames.driverOfferModal,
        data: {
            quote,
            offer,
            mode,
            createdByDispatcher,
            updatedByDispatcher,
        },
        handlers: {
            onSubmit: (data) => {
                dispatch(updateDriverOffer(quote, data));
            },
        },
    });
    dispatch(successModalAction);
};

export const openSuccessModal = (quoteNumber: number) => (dispatch) => {
    dispatch(
        openModal({
            modalName: quoteModalNames.successCreateQuoteModal,
            data: {quoteNumber},
            handlers: {},
        }),
    );
};
export const openSuccessCreateOfferModal = (quoteNumber: number) => (dispatch) => {
    dispatch(closeAll());
    dispatch(
        openModal({
            modalName: quoteModalNames.successCreateOfferModal,
            data: {quoteNumber},
        }),
    );
};
export const openSuccessChangedOfferModal = (quote: Quote, offer: DriverOffer) => (dispatch) => {
    dispatch(closeAll());
    dispatch(
        openModal({
            modalName: quoteModalNames.successChangedOfferModal,
            data: {offerNumber: getDriverOfferNumber(offer, quote)},
        }),
    );
};

export const openSuccessArchivedQuoteModal = (quoteNumber: number) => (dispatch) => {
    let modalID;
    const action = openModal({
        modalName: quoteModalNames.successArchivedQuoteModal,
        data: {quoteNumber},
        handlers: {
            goToGeneralList: () => {
                dispatch(goToGeneralList());
                dispatch(closeModal(modalID));
            },
            goToArchivedList: () => {
                dispatch(goToArchivedList());
                dispatch(closeModal(modalID));
            },
        },
    });
    modalID = action?.payload?.id;
    dispatch(action);
};

export const openDuplicatesQuoteModal = (formValues: QuoteInitialValues, duplicateQuotes: Quote[]) => (dispatch) => {
    return new Promise((resolve) => {
        let resetModalID;
        dispatch(closeAll());
        const duplicatesQuoteModalAction = openModal({
            modalName: quoteModalNames.duplicatesQuoteModal,
            data: {
                quotes: duplicateQuotes,
                formValues,
            },
            handlers: {
                submit: () => {
                    dispatch(closeModal(resetModalID));
                    resolve(true);
                },
                cancel: () => {
                    dispatch(closeModal(resetModalID));
                    resolve(false);
                },
            },
        });
        resetModalID = duplicatesQuoteModalAction?.payload?.id;
        dispatch(duplicatesQuoteModalAction);
    });
};

export const openQuoteNotesModal = (quote: Quote) => (dispatch, getState) => {
    dispatch(closeAll());

    dispatch(fetchQuoteGeneralNotes(quote));

    const currentDispatcher = getCurrentDispatcher(getState());
    const openDelay = 300;
    window.setTimeout(() => {
        const quoteNotesModalAction = openModal({
            modalName: quoteModalNames.quoteNotesModal,
            data: {
                quoteID: quote.id,
            },
            handlers: {
                addGeneralNote: (note: {text: string}) => {
                    const {text} = note;
                    dispatch(createQuoteGeneralNote(quote, text, currentDispatcher));
                },
                deleteGeneralNote: (note: {id: string}) => {
                    const {id} = note;
                    dispatch(deleteQuoteGeneralNote(quote, id));
                },
            },
        });
        dispatch(quoteNotesModalAction);
    }, openDelay);
};

export const openQuoteReceiversModal = (quote: Quote) => (dispatch) => {
    const openQuoteReceiversModalAction = openModal({
        modalName: quoteModalNames.quoteReceiversModal,
        data: {
            quote,
        },
    });
    dispatch(openQuoteReceiversModalAction);
};

export const openDriverOfferModal = (quote: Quote, offer?: DriverOffer) => (dispatch) => {
    const successModalAction = openModal({
        modalName: quoteModalNames.driverOfferModal,
        data: {
            quote,
            offer,
        },
        handlers: {
            onCreate: (data) => {
                dispatch(addDriverOffer(data));
            },
        },
    });
    dispatch(successModalAction);
};

export const openApproveDriverOfferModal = (quote: Quote, offer: DriverOffer) => (dispatch) => {
    const successModalAction = openModal({
        modalName: quoteModalNames.approveOfferModal,
        data: {
            offerNumber: getDriverOfferNumber(offer, quote),
        },
        handlers: {
            onSubmit: () => {
                dispatch(approveDriverOffer(offer));
                dispatch(closeAll());
            },
        },
    });
    dispatch(successModalAction);
};

export const openUnApproveDriverOfferModal = (quote: Quote, offer: DriverOffer) => (dispatch) => {
    const successModalAction = openModal({
        modalName: quoteModalNames.unApproveOfferModal,
        data: {
            offerNumber: getDriverOfferNumber(offer, quote),
        },
        handlers: {
            onSubmit: () => {
                dispatch(unApproveDriverOffer(offer));
                dispatch(closeAll());
            },
        },
    });
    dispatch(successModalAction);
};

export const openCancelStatusDriverOfferModal = (quote: Quote, offer: DriverOffer) => (dispatch) => {
    const successModalAction = openModal({
        modalName: quoteModalNames.cancelOfferStatusModal,
        data: {
            offerNumber: getDriverOfferNumber(offer, quote),
            offerStatus: offer.status,
        },
        handlers: {
            onSubmit: () => {
                dispatch(cancelDriverOfferStatus(offer));
                dispatch(closeAll());
            },
        },
    });
    dispatch(successModalAction);
};

export const openDeactivateOfferModal = (quote: Quote, offer: DriverOffer) => (dispatch) => {
    const quoteNotesModalAction = openModal({
        modalName: quoteModalNames.deactivateOfferModal,
        data: {
            offerNumber: getDriverOfferNumber(offer, quote),
        },
        handlers: {
            onSubmit: () => {
                dispatch(deactivateDriverOffer(quote, offer));
            },
        },
    });

    dispatch(quoteNotesModalAction);
};

export const openSuccessDeactivateOfferModal = (quote: Quote, offer: DriverOffer) => (dispatch) => {
    dispatch(closeAll());
    dispatch(
        openModal({
            modalName: quoteModalNames.successDeactivateOfferModal,
            data: {offerNumber: getDriverOfferNumber(offer, quote)},
        }),
    );
};

export const openOfferErrorsModal = (errorCode: string) => (dispatch) => {
    dispatch(closeAll());
    dispatch(
        openModal({
            modalName: quoteModalNames.offerErrorsModal,
            data: {errorCode},
        }),
    );
};
