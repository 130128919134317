import React from 'react';
import classNames from 'classnames';

import {AutoCheckCallErrors, AutoCheckCallEvent} from 'core/entities/TravelOrder/types/AutoCheckCallSettings';

import {getDurationStrFrom} from './utils';

import styles from '../../styles.module.scss';

type OwnProps = {
    date: string;
    error: AutoCheckCallErrors['eta'];
    late: AutoCheckCallEvent['etaLate'];
};

const ETAInfoDate: React.FC<OwnProps> = ({late, date, error}) => {
    if (!error) {
        return <span>ETA {date}</span>;
    }

    const lateStr = getDurationStrFrom({minutes: late});

    return (
        <span>
            <span className={styles.errorBgWithBorder}>ETA {date}</span>
            <span className={classNames(styles.bgPink, 'ml10')}>
                Late <strong>{lateStr}</strong>
            </span>
        </span>
    );
};

export default ETAInfoDate;
