import React from 'react';

import styles from 'components/ui/Files/FileUpload/components/FilesList/fileList.module.scss';

interface OwnProps {
    title: string;
    fileCount: number;
}

const FileSection = ({title, fileCount}: OwnProps): JSX.Element => (
    <div className={styles.header}>
        <div>
            <strong>{title}</strong>
        </div>
        <div>
            <strong>{fileCount}</strong>&nbsp;file(s)
        </div>
    </div>
);

export default FileSection;
