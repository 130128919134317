import React from 'react';
import {getTruckEquipment, getTruckTypes} from 'core/entities/Quote/modules/quoteTruck';
import {Quote} from 'core/entities/Quote/types';
import {
    getQuoteDims,
    getQuoteDimsUnit,
    getQuotePieces,
    isQuoteDimenstionEmpty,
} from 'core/entities/Quote/modules/quoteFreights';
import {isEmpty} from 'lodash';
import {getCurrency, getRate} from 'core/entities/Quote/modules/quoteDriverPayment';
import CurrencyAmount from 'components/ui/CurrencyAmount';
import LengthView from 'components/ui/LengthView';
import InfoItem from '../common/InfoItem';
import Title from '../common/Title';

type OwnProps = {
    quote: Quote;
};

const LoadInformation: React.FC<OwnProps> = (props) => {
    const {quote} = props;
    const truckTypesList = getTruckTypes(quote);
    const truckEquipmentList = getTruckEquipment(quote);
    const getJoinStringArray = (value: string[]): string => value.join(', ');
    const truckTypes = getJoinStringArray(truckTypesList);
    const truckEquipment = getJoinStringArray(truckEquipmentList);
    const pieces = getQuotePieces(quote);
    const currency = getCurrency(quote);
    const rate = getRate(quote);
    const dimensions = getQuoteDims(quote);
    const unit = getQuoteDimsUnit(quote);
    return (
        <div className="mb-2">
            <Title>Load Information</Title>
            {!isEmpty(truckTypes) && <InfoItem label="Truck Type">{truckTypes}</InfoItem>}
            {!isEmpty(truckEquipment) && <InfoItem label="Equipment">{truckEquipment}</InfoItem>}
            {!isQuoteDimenstionEmpty(quote) && (
                <InfoItem label="DIMS">
                    {dimensions.map(({width, length, height}, index) => (
                        <LengthView key={index} units={unit} length={length} width={width} height={height} />
                    ))}
                </InfoItem>
            )}
            {pieces && <InfoItem label="Pieces">{pieces}</InfoItem>}
            {rate && currency && (
                <InfoItem label="Rate">
                    <CurrencyAmount amount={rate} currency={currency} withStrong />
                </InfoItem>
            )}
        </div>
    );
};

export default LoadInformation;
