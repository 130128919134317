import {showFormClearFieldsWarning} from 'store/actions/modalActions';

import {getCurrentTravelOrder} from 'core/entities/Load/modules/loadTravelOrder';
import Load from 'core/entities/Load/types';
import TravelOrder from 'core/entities/TravelOrder/types';

import * as modalActions from 'components/ui/ModalProvider/actions';
import {openModal} from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import {loadsModalNames} from 'pages/Loads/components/common/modals/modalMap';
import * as constants from 'pages/Loads/constants/loadConstants';
import {actionCreators as actionCreatorsList} from 'pages/Loads/redux/actionCreators';
import {actionCreators} from 'pages/Loads/redux/actionCreators/modal';
import {getLoad, getLoadNotes} from 'pages/Loads/redux/selectors/common';
import * as listSelectors from 'pages/Loads/redux/selectors/listSelectors';
import transferNewNotesToOldInterface from 'pages/Loads/utils/transferNewNotesToOldInterface';

import {addLoadNote as addLoadNoteList} from '../listActions';
import {addLoadNote as addLoadNoteView} from '../loadActions';

export function closeModal() {
    return function (dispatch, getState) {
        const {
            loads: {
                modal: {name: currentModalName},
            },
        } = getState();
        dispatch(actionCreators.hideModal({modalName: currentModalName}));
    };
}

export function showCustomerCreateModal(formData) {
    return function (dispatch) {
        dispatch(actionCreators.showModal({modalName: constants.MODAL_NAMES.createCompanyModal, modalData: formData}));
    };
}

export function showCustomerDuplicates({customer, customerDuplicates, onForceCreate, onSelectDuplicate}) {
    return function (dispatch) {
        const closeCurrentCreateNewCustomerModal = closeModal();
        dispatch(closeCurrentCreateNewCustomerModal);

        const openModalAction = openModal({
            modalName: commonModalNames.duplicatesEntitiesModal,
            handlers: {
                onForceCreate: () => {
                    dispatch(modalActions.closeAll());
                    onForceCreate();
                },
                onSelectDuplicate: (customerDuplicate) => {
                    dispatch(modalActions.closeAll());
                    onSelectDuplicate(customerDuplicate);
                },
            },
            data: {
                entity: customer,
                duplicates: customerDuplicates,
                type: 'customer',
            },
        });
        dispatch(openModalAction);
    };
}

export function showFacilityDuplicates({facility, facilityDuplicates, onForceCreate, onSelectDuplicate}) {
    return function (dispatch) {
        const closeCurrentCreateNewFacilityModal = closeModal();
        dispatch(closeCurrentCreateNewFacilityModal);

        const openModalAction = openModal({
            modalName: commonModalNames.duplicatesEntitiesModal,
            handlers: {
                onForceCreate: () => {
                    dispatch(modalActions.closeAll());
                    onForceCreate();
                },
                onSelectDuplicate: (facilityDuplicate) => {
                    dispatch(modalActions.closeAll());
                    onSelectDuplicate(facilityDuplicate);
                },
            },
            data: {
                entity: facility,
                duplicates: facilityDuplicates,
                type: 'facility',
            },
        });
        dispatch(openModalAction);
    };
}

export function showFacilityCreateModal(formData) {
    return function (dispatch) {
        dispatch(actionCreators.showModal({modalName: constants.MODAL_NAMES.createFacility, modalData: {formData}}));
    };
}

export const showSuccessfulCreateModal = (entity, entityName: string, rightButtonTitle?: string) => {
    return function (dispatch) {
        dispatch(
            modalActions.openModal({
                modalName: commonModalNames.informationModal,
                data: {
                    title: 'Successful Create',
                    toProfileLabel: entity.name,
                    entityName,
                    entity,
                    rightButtonTitle,
                    bodyType: 'SuccessFormCreate',
                    isShowEntityViewButton: false,
                    buttonType: 'success',
                },
                handlers: {
                    rightButtonHandler: () => {},
                },
            }),
        );
    };
};

export function showCreateCancelWarning() {
    return function (dispatch) {
        dispatch(
            modalActions.openModal({
                modalName: commonModalNames.informationModal,
                data: {
                    title: 'Cancel Create',
                    leftButtonTitle: `Keep Creating`,
                    rightButtonTitle: `Cancel Creating`,
                    bodyType: 'CancelForm',
                    buttonType: 'danger',
                },
                handlers: {
                    leftButtonHandler: () => {},
                    rightButtonHandler: () => dispatch(closeModal()),
                },
            }),
        );
    };
}

export const showAttachLoadModal = (load) => (dispatch) => {
    dispatch(openModal({handlers: {}, modalName: loadsModalNames.attachLoadModal, data: {load}}));
};

export const showAddLoadTravelOrderGeneralNoteModal = () => (dispatch, getState) => {
    const state = getState();
    const currentLoadFromViewPages = getLoad(state);
    const loadTravelOrder = getCurrentTravelOrder(currentLoadFromViewPages);

    if (!loadTravelOrder) {
        return null;
    }

    const {addNotesModal} = commonModalNames;

    const modalHandlers = {
        onSuccessAddedNote: (newTravelOrder: TravelOrder) =>
            dispatch(actionCreatorsList.fetchTravelOrder({travelOrder: newTravelOrder})),
    };

    dispatch(openModal({handlers: modalHandlers, modalName: addNotesModal, data: {travelOrder: loadTravelOrder}}));
};

export function showConfirmClearCreateLoad(onConfirm) {
    return function (dispatch) {
        dispatch(showFormClearFieldsWarning(constants.CREATE_LOAD_FORM_NAME, onConfirm));
    };
}

export function showLoadNotesModal(load: Load, {permissionUpdateLoad}: {permissionUpdateLoad: boolean}) {
    return function (dispatch, getState) {
        const state = getState();
        const currentLoadFromViewPages = getLoad(state);
        const handlerAddLoadNoteLoad = (note) => {
            if (currentLoadFromViewPages) {
                return addLoadNoteView(note, currentLoadFromViewPages.number);
            }
            return addLoadNoteList(note, load.number);
        };

        const openModalAction = openModal({
            modalName: commonModalNames.generalNotes,
            data: {
                title: `Load#${currentLoadFromViewPages?.number || load?.number} Notes`,
                addNoteDisabled: !permissionUpdateLoad,
            },
            handlers: {
                // to submit new api call for add note to load
                onSubmit: (note) => dispatch(handlerAddLoadNoteLoad(note)),
            },
            // using selector cause after success add new note we should get updated notes
            // we cant use default data flow cause load notes modal not exists in current component
            // so we cant update data after regular render
            selectors: {
                notesSelector: (currentState) => {
                    // temp fix bug, need refactor all loads data in reducers
                    if (currentLoadFromViewPages) {
                        return transferNewNotesToOldInterface(getLoadNotes(currentState));
                    }

                    const currentLoadFromList = listSelectors.getLoadByNumber(currentState, load?.number);
                    return transferNewNotesToOldInterface(currentLoadFromList?.notes);
                },
            },
        });

        dispatch(openModalAction);
    };
}
