import {combineReducers} from 'redux';

import list from './list';
import current from './currentInvoice';
import data from './invoicesData';
import navigationData from './navigationData';

export default combineReducers({
    data,
    list,
    current,
    navigation: navigationData,
});
