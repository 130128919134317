import * as types from '../actionTypes';
import {updateItemInTable} from './utils';

export default function (state, action) {
    switch (action.type) {
        case types.SETTLEMENT_DRIVER_PAYMENT_UPDATED: {
            const {currentSettlement, updatedRate} = action.payload;
            const updatedSettlement = {
                ...currentSettlement,
                total_pay: updatedRate.driver_payment,
                travel_order: {
                    ...currentSettlement.travel_order,
                    rate: {...currentSettlement.travel_order.rate, ...updatedRate},
                },
            };
            return {
                ...state,
                settlements: updateItemInTable(state.settlements, updatedSettlement),
            };
        }

        case types.SETTLEMENT_LOAD_UPDATED: {
            const {settlementID, updatedLoad} = action.payload;
            const currentSettlement = state.settlements.byId[settlementID];
            const updatedSettlement = {
                ...currentSettlement,
                travel_order: {
                    ...currentSettlement.travel_order,
                    load: updatedLoad,
                },
            };
            return {
                ...state,
                settlements: updateItemInTable(state.settlements, updatedSettlement),
            };
        }

        case types.SETTLEMENT_TRAVEL_ORDER_UPDATED: {
            const {settlementID, updatedTravelOrder} = action.payload;
            const currentSettlement = state.settlements.byId[settlementID];
            const updatedSettlement = {
                ...currentSettlement,
                travel_order: updatedTravelOrder,
            };
            return {
                ...state,
                settlements: updateItemInTable(state.settlements, updatedSettlement),
            };
        }

        default:
            return state;
    }
}
