import restApi from 'services/restapi';
import {getGeneralNotes} from 'services/restapi/generalNotes';

import {ENTITY_NAME_TRAVEL_ORDER} from 'utils/data/entityNames';
import parseFileExtraData from 'utils/files/parseFileExtraData';

const fetchFiles = async (travelOrderID, filesType) => {
    let result = [];

    try {
        const getParams = {entity: 'travel-order', id: travelOrderID, type: filesType};
        result = await restApi('files', {params: getParams}).then(
            (response) => response.data && response.data.map(parseFileExtraData),
        );
    } catch (error) {
        console.warn('Fetch travel order files error: ', error);
    }

    return result;
};

export const fetchTravelOrder = (travelOrderID) => restApi(`/to/${travelOrderID}`).then((response) => response.data);

export const fetchTravelOrderFiles = (travelOrderID, filesType) => fetchFiles(travelOrderID, filesType);

export const fetchTravelOrderNotes = (travelOrderID) => {
    const params = {entity_name: ENTITY_NAME_TRAVEL_ORDER, entity_id: travelOrderID};
    return getGeneralNotes(params).then(({data}) => data);
};
