import React from 'react';
import classNames from 'classnames';

import CarrierBadge from 'pages/Settlements/components/common/CarrierBadge';

import ListResponsibleUsers from './ListResponsibleUsers';
import {OpenedCompany, BatchResponsibleUser} from '../types';

import styles from './companyInfo.module.scss';

interface OwnProps {
    company: OpenedCompany;
    isOpened: boolean;
    selectedUsers: BatchResponsibleUser[];

    onClickHeader();

    onSelectUser(user: BatchResponsibleUser);

    onSelectAllUsers(allUsers: BatchResponsibleUser[]);
}

const CarrierBatchInfo = ({batchId}) => {
    if (!batchId) {
        return <span>new</span>;
    }
    return <span>#{batchId} (open)</span>;
};

const CompanyInfo = ({
    company,
    isOpened,
    selectedUsers,
    onClickHeader,
    onSelectUser,
    onSelectAllUsers,
}: OwnProps): JSX.Element => {
    const isOpenClassName = classNames('settlements-list', {open: isOpened});

    return (
        <div className={styles.item}>
            <div className={isOpenClassName}>
                <div onClick={onClickHeader} className="settlements-list__header">
                    <div className="company-name w-50">
                        <div>
                            <strong>{company.title}</strong>
                        </div>
                        <CarrierBadge carrier={company.carrier} />
                    </div>
                    <div className={styles.batch}>
                        <CarrierBatchInfo batchId={company.batchId} />
                        <div>
                            <strong>{company.settlementsCount}</strong> settlements(s)
                        </div>
                    </div>
                </div>

                {isOpened && (
                    <ListResponsibleUsers
                        company={company}
                        selectedUsers={selectedUsers}
                        onSelectUser={onSelectUser}
                        onSelectAllUsers={onSelectAllUsers}
                    />
                )}
            </div>
        </div>
    );
};

export default React.memo(CompanyInfo);
