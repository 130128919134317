import {AppState} from 'store';

import {CustomersState} from '../reducers/list';
import {MergeState} from '../reducers/merge';

export * from './agents';

export const getCurrentCustomer = (state) => state.customers.customer.current;

export const getShowDeleted = (state) => state.adminSettings.customers.showDeleted;

// merge's selectors ↓
export const getCustomersToMerge = (state: AppState): MergeState['customersToMerge'] =>
    state.customers.merge.customersToMerge;

// list's selectors ↓
export const getSearchParams = (state: AppState): CustomersState['searchParams'] => state.customers.list.searchParams;
export const getPagination = (state: AppState): CustomersState['pagination'] => state.customers.list.pagination;
export const getCustomersList = (state: AppState): CustomersState['items'] => state.customers.list.items;
