import React from 'react';

import {isPayPeriodOpened} from 'core/entities/Settlement/settlementPayPeriod';

import PostToOpenedPeriod from './PostToOpenedPeriod';
import PostToClosedPeriod from './PostToClosedPeriod';

const PostSettlementOwnerModal = ({
    onClose,
    data: {settlement, payPeriod},
    handlers: {onPostToOpenedPeriod, onPostToClosedPeriod},
}): JSX.Element => {
    const isOpenedPayPeriod = isPayPeriodOpened(payPeriod);
    return isOpenedPayPeriod ? (
        <PostToOpenedPeriod
            settlement={settlement}
            period={payPeriod}
            onPost={onPostToOpenedPeriod}
            onClose={onClose}
        />
    ) : (
        <PostToClosedPeriod
            settlement={settlement}
            period={payPeriod}
            onPost={onPostToClosedPeriod}
            onClose={onClose}
        />
    );
};

export default PostSettlementOwnerModal;
