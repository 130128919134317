import React from 'react';
import classNames from 'classnames';

import useMergeState from 'pages/Customers/hooks/useMergeState';

import CustomersBillingWarning from './components/CustomersBillingWarning';
import CustomerToMergeRow from './components/CustomerToMergeRow';

import styles from './styles.module.scss';

type OwnProps = {
    formName: string;
};

const CustomersMergeTable: React.FC<OwnProps> = (props) => {
    const {formName} = props;

    const {customersToMerge} = useMergeState();

    if (!customersToMerge) {
        return null;
    }

    const renderCustomers = customersToMerge.map((customer) => (
        <CustomerToMergeRow key={customer.id} customer={customer} />
    ));

    return (
        <>
            <div className="bottom-hr mt5" />

            <p className="mt20">You are about to Merge Customer account into another:</p>

            <div className={classNames(styles.table, 'standard-table-style')}>
                <table className="table mb0">
                    <thead>
                        <tr>
                            <th>ID#</th>

                            <th>COMPANY NAME</th>

                            <th>BILLING ADDRESS</th>

                            <th>BILLING METHOD</th>
                        </tr>
                    </thead>

                    <tbody className="table-body-bordered">{renderCustomers}</tbody>
                </table>
            </div>

            <CustomersBillingWarning formName={formName} />
        </>
    );
};

export default CustomersMergeTable;
