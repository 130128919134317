import React from 'react';

import {SettlementOwner, SettlementCarrier} from 'core/entities/Settlement/types';
import {getOriginDestination} from './utils';

type OwnProps = {
    settlement: SettlementOwner | SettlementCarrier;
};

const SettlementOriginDestinationInfo: React.FC<OwnProps> = ({settlement}) => {
    const {origin, destination} = getOriginDestination(settlement);
    return (
        <>
            <div className="ellips-text">{origin}</div>
            <div className="ellips-text">{destination}</div>
        </>
    );
};

export default React.memo(SettlementOriginDestinationInfo);
