import {TIME_FRAME_FCFS, TIME_FRAME_APPT, TIME_FRAME_ASAP, TIME_FRAME_DIRECT} from 'core/entities/Load/constants';

export const pickupTimeFrames = [
    {label: TIME_FRAME_FCFS, value: TIME_FRAME_FCFS},
    {label: TIME_FRAME_APPT, value: TIME_FRAME_APPT},
    {label: TIME_FRAME_ASAP, value: TIME_FRAME_ASAP},
];

export const deliveryTimeFrames = [
    {label: TIME_FRAME_FCFS, value: TIME_FRAME_FCFS},
    {label: TIME_FRAME_APPT, value: TIME_FRAME_APPT},
    {label: TIME_FRAME_DIRECT, value: TIME_FRAME_DIRECT},
];
