import * as errorMessages from 'utils/validation/errorMessages';
import * as regexps from 'utils/validation/regexps';

import {AddTruckLocationFormValues} from '../types';

function validateTime(time: string): boolean {
    return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
}

const validate = (values: AddTruckLocationFormValues) => {
    const {date, time, location} = values;
    const errors: any = {location: {}};

    if (!date || !regexps.DATE.test(date)) {
        errors.date = errorMessages.REQUIRED;
    }

    if (!time || !validateTime(time)) {
        errors.time = errorMessages.REQUIRED;
    }

    if (!location?.cityLine) {
        errors.location.cityLine = errorMessages.REQUIRED;
    }

    if (!location?.cityLine || (location?.cityLine && !regexps.CITY_LINE_WITH_BRACKETS.test(location?.cityLine))) {
        errors.location.cityLine = errorMessages.INVALID_CITY_LINE_FORMAT;
    }

    return errors;
};

export default validate;
