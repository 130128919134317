import React from 'react';

import styles from './styles.module.scss';

const LoadIcon: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <i className="fa fa-cube" />
        </div>
    );
};

export default LoadIcon;
