import React, {useRef, useState} from 'react';
import {FormGroup} from 'react-bootstrap';
import {useClickAway} from 'react-use';
import classNames from 'classnames';
import uniq from 'lodash/uniq';
import {WrappedFieldProps} from 'redux-form';

import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';

import getValidationState from '../getValidationState';

import {flattenGroupedItems} from './utils';

import './style.scss';

interface Item {
    label: string;
    value: string;
}

interface ItemsGroup {
    label: string;
    items: Item[];
}

type OwnProps = {
    items: (Item | ItemsGroup)[];
    btnTitle: string;
};

const DropDownCheckBoxMulti: React.FC<OwnProps & WrappedFieldProps> = (props) => {
    const [isExpanded, setExpanded] = useState(false);

    const containerNode = useRef<HTMLDivElement>(null);
    const checkBoxListNode = useRef<HTMLDivElement>(null);

    useClickAway(containerNode, () => {
        setExpanded(false);
    });

    const toggleExpand = (event) => {
        const isClickOnCheckBoxList = checkBoxListNode.current && checkBoxListNode.current.contains(event.target);
        if (!isClickOnCheckBoxList) {
            setExpanded(!isExpanded);
        }
    };

    const removeValueFromList = (list, chbxValue) => list.filter((item) => chbxValue !== item);
    const removeMultipleValuesFromList = (list, valuesToRemove) =>
        list.filter((item) => !valuesToRemove.includes(item));

    const getSelectedValues = (value) => (value && value.split(',')) || [];
    const getIsGroupSelected = (groupStatusValues, selectedValues) =>
        groupStatusValues.every((elem) => selectedValues.includes(elem));

    const onItemClickHandler = (clickedItem) => {
        const {
            input: {value, onChange},
        } = props;
        const selectedValues = getSelectedValues(value);

        const {value: checkBoxValue} = clickedItem;

        if (selectedValues.includes(checkBoxValue)) {
            const newValues = removeValueFromList(selectedValues, checkBoxValue);
            onChange(newValues.join(','));
        } else {
            selectedValues.push(checkBoxValue);
            onChange(selectedValues.join(','));
        }
    };

    const getGroupStatusValues = (groupHeaderItemValue) => {
        const {items} = props;
        const group = items.find((elem) => groupHeaderItemValue === elem.label);
        if (group && 'items' in group) {
            return group.items.map((item) => item.value);
        }
    };

    const onGroupHeaderClickHandler = (clickedItem) => {
        const {
            input: {value, onChange},
        } = props;
        const selectedValues = getSelectedValues(value);
        const groupStatusValues = getGroupStatusValues(clickedItem.value);
        const isGroupSelected = getIsGroupSelected(groupStatusValues, selectedValues);

        if (isGroupSelected) {
            const newValues = removeMultipleValuesFromList(selectedValues, groupStatusValues);
            onChange(newValues.join(','));
        } else {
            onChange(uniq(selectedValues.concat(groupStatusValues)).join(','));
        }
    };

    const onClickHandler = (clickedItem) => {
        if (clickedItem.isGroupHeader) {
            onGroupHeaderClickHandler(clickedItem);
        } else {
            onItemClickHandler(clickedItem);
        }
    };

    const getIsCheckedGroupHeader = (selectedValues, checkBoxItem) => {
        const groupStatusValues = getGroupStatusValues(checkBoxItem.value);
        return getIsGroupSelected(groupStatusValues, selectedValues);
    };

    const getIsCheckedItem = (checkBoxItem) => {
        const {
            input: {value},
        } = props;
        const selectedValues = getSelectedValues(value);

        if (checkBoxItem.isGroupHeader) {
            return getIsCheckedGroupHeader(selectedValues, checkBoxItem);
        }
        return selectedValues.includes(checkBoxItem.value);
    };

    const {items, input, meta} = props;

    const isValid = getValidationState(meta);

    const stateCls = isExpanded ? 'open ' : '';
    const stateIconCls = isExpanded ? 'fa-angle-double-up ' : 'fa-angle-double-down';
    const isAnyCheckedClass = input.value ? 'checked ' : '';

    const dataItems = flattenGroupedItems(items);

    return (
        <div className="dropdown-multi-chbx-component">
            <OverlayTriggerCustom inputMeta={meta} placement="top">
                <div ref={containerNode} className="dropdown-btn-component">
                    <div onClick={toggleExpand} className={`check-select ${stateCls} ${isAnyCheckedClass}`}>
                        <div className="check-select__item">
                            {props.btnTitle}
                            <i className={`fa ${stateIconCls}`} aria-hidden="true" />
                        </div>
                        <div ref={checkBoxListNode} className="check-select__list">
                            <FormGroup className={!isValid ? 'has-feedback has-error' : ''}>
                                {dataItems.map((item, index) => {
                                    const itemClass = classNames('iw_cb_shell', {
                                        iw_cb_shell_checked: getIsCheckedItem(item),
                                        is_group_header: item.isGroupHeader,
                                    });

                                    return (
                                        <div key={index}>
                                            <div onClick={() => onClickHandler(item)} className={itemClass}>
                                                <span style={{marginRight: 10}} className="cb_square" />
                                                <label>{item.title || item.label}</label>&nbsp;
                                            </div>
                                        </div>
                                    );
                                })}
                            </FormGroup>
                        </div>
                    </div>
                </div>
            </OverlayTriggerCustom>
        </div>
    );
};

export default React.memo(DropDownCheckBoxMulti);
