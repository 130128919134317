import React from 'react';
import {v4 as uuidv4} from 'uuid';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';

import {TooltipPlacement} from 'components/ui/TooltipBootstrap/types';

import styles from './styles.module.scss';

type OwnProps = {
    tooltipContent?: JSX.Element | string;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName: string;
};

const TooltipOverlayTrigger: React.FC<OwnProps> = (props) => {
    const {tooltipPlacement, tooltipContent, children, tooltipClassName} = props;

    const tooltip = (
        <Tooltip id={uuidv4()} className={tooltipClassName}>
            {tooltipContent}
        </Tooltip>
    );

    return (
        <OverlayTrigger placement={tooltipPlacement} overlay={tooltip} delay={{show: 350, hide: 0}}>
            <div className={styles.container}>{children}</div>
        </OverlayTrigger>
    );
};

export default TooltipOverlayTrigger;
