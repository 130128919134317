import * as truckStatuses from 'core/entities/Truck/constants/truckStatuses';
import {truckLocationTypes} from 'pages/TrucksMap/constants';

export default function getTruckLocation(truck, {truckLocationType} = {}) {
    if (truckLocationType === truckLocationTypes.CURRENT) {
        return {
            lat: parseFloat(truck.last_latitude),
            lng: parseFloat(truck.last_longitude),
        };
    }
    if (truck.status === truckStatuses.TRUCK_STATUS_ON_ROUTE) {
        // last delivery coordinates
        return {
            lat: parseFloat(truck.status_latitude),
            lng: parseFloat(truck.status_longitude),
        };
    }
    if (truck.status === truckStatuses.TRUCK_STATUS_WILL_BE_AVAILABLE_AT) {
        return {
            lat: parseFloat(truck.status_latitude),
            lng: parseFloat(truck.status_longitude),
        };
    }
    return {
        lat: parseFloat(truck.last_latitude),
        lng: parseFloat(truck.last_longitude),
    };
}
