import isEmpty from 'lodash/isEmpty';

import type {CompleteLoadWithTONUParams, LoadApiGateway} from '../types';

import {getUploadedFilesRC} from './utils';
import {changeLoadFiles as changeLoadFilesUseCase} from '../index';
import {transformMetaDataToRequest} from '../changeLoadFiles/utils';

const completeLoadWithTONU = async function (
    data: CompleteLoadWithTONUParams,
    deps: {
        loadApiGateway: LoadApiGateway;
    },
) {
    const {loadNumber, tonuRate, tonuDocuments} = data;
    const {loadApiGateway} = deps;

    const documentsForUpload = getUploadedFilesRC(tonuDocuments);
    const addLoadFiles = documentsForUpload ? transformMetaDataToRequest(documentsForUpload) : undefined;

    const {files} = await loadApiGateway.changeLoadStatusToTONU(loadNumber, {
        totalCharge: tonuRate,
        files: addLoadFiles?.files,
    });

    if (!isEmpty(files) && documentsForUpload) {
        await changeLoadFilesUseCase(loadNumber, {addedFiles: documentsForUpload, fileIds: files});
    }
};

export default completeLoadWithTONU;
