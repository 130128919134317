import {createBrowserHistory} from 'history';
import {Action, applyMiddleware, compose, createStore} from 'redux';
import {persistStore} from 'redux-persist';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';

import config from 'config';

import {createMiddlewares} from './middlewares';
import rootReducers from './reducers';

const history = createBrowserHistory();
const STORE_ID = 'storeId';
const newAppBuildId: string = config.BUILD_HASH;
const savedStoreId = localStorage.getItem(STORE_ID);

// Clear all localStorage if we have new build id.
if (savedStoreId && savedStoreId !== newAppBuildId) {
    localStorage.clear();
    localStorage.setItem(STORE_ID, newAppBuildId);
}

if (!savedStoreId) {
    localStorage.setItem(STORE_ID, newAppBuildId);
}

const middlewares = createMiddlewares(history);

// @ts-ignore: app devtools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducers, composeEnhancers(applyMiddleware(...middlewares)));
const persistor = persistStore(store);

export type AppState = ReturnType<typeof rootReducers>;

export type AppThunk = ThunkAction<void, AppState, undefined, Action<string>>;
export type AppThunkExtraArgument = ThunkAction<void, AppState, any, Action<string>>;

export type ThunkDispatchCustom = ThunkDispatch<AppState, undefined, Action<string>>;

export type InferActions<T> = T extends {[keys: string]: (...args: any[]) => infer U} ? U : never;

export {store, persistor, history};
