import React from 'react';
import {FormGroup, FormLabel} from 'react-bootstrap';

import './style.scss';

const RadioInput = (props) => {
    const {
        items,
        label = '',
        input: {value, onChange},
        disabled = false,
    } = props;

    const getHandler = (fieldValue) => () => {
        if (disabled) {
            return;
        }
        onChange(fieldValue);
    };

    const radioElements = items.map((item, index) => {
        const checkedClassName = item.value === value ? 'iw_rb_checked' : '';
        const disabledClassName = item.disabled || disabled ? 'disabled' : '';
        return (
            <div
                key={index}
                onClick={getHandler(item.value)}
                className={`iw_rb_shell ${checkedClassName} ${disabledClassName}`}
            >
                <span className="rb_square" />
                <label style={{lineHeight: '34px'}}>{item.label}</label>
            </div>
        );
    });

    return (
        <FormGroup>
            {label && <FormLabel>{label}</FormLabel>}
            <div className="iw_rb_shell iw_rb_horisontal">{radioElements}</div>
        </FormGroup>
    );
};

export default RadioInput;
