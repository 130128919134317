import React from 'react';
import {Field} from 'redux-form';

import RequiredLabel from 'components/ui/Form/RequiredLabel';

import DateTimeField from '../common/DateTimeField';

interface OwnProps {
    dateFromFiledName: string;
    dateToFiledName: string;
    required?: boolean;
    disabled: boolean;
}

const DateTimeFCFS: React.FC<OwnProps> = (props) => {
    const {dateToFiledName, dateFromFiledName, required, disabled} = props;
    const dateFromLabel = required ? RequiredLabel('DATE FROM') : 'DATE FROM';
    const timeFromLabel = required ? RequiredLabel('TIME FROM') : 'TIME FROM';
    const dateToLabel = required ? RequiredLabel('DATE TO') : 'DATE TO';
    const timeToLabel = required ? RequiredLabel('TIME TO') : 'TIME TO';
    return (
        <div>
            <Field
                dateLabel={dateFromLabel}
                datePlaceholder="choose date"
                timeLabel={timeFromLabel}
                name={dateFromFiledName}
                component={DateTimeField}
                disabled={disabled}
            />
            <Field
                dateLabel={dateToLabel}
                datePlaceholder="choose date"
                timeLabel={timeToLabel}
                name={dateToFiledName}
                component={DateTimeField}
                disabled={disabled}
            />
        </div>
    );
};

export default DateTimeFCFS;
