import React from 'react';
import classNames from 'classnames';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import styles from './clipboard.module.scss';

interface OwnProps {
    clipBoardText: string;
    children?: JSX.Element;
    disabled?: boolean;
}

const Clipboard = ({clipBoardText, children, disabled = false}: OwnProps): JSX.Element => {
    return (
        <CopyToClipboard text={clipBoardText}>
            {children || (
                <button type="button" className={classNames('copy-button', styles.button, {disabled})}>
                    <i className="fa fa-copy" />
                </button>
            )}
        </CopyToClipboard>
    );
};

export default Clipboard;
