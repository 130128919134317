import sumBy from 'lodash/sumBy';
import head from 'lodash/head';
import last from 'lodash/last';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

import * as loadStatuses from 'deprecated/core/entities/Load/modules/loadStatus';
import getCommonFreightUnits from 'deprecated/core/entities/Load/getCommonFreightUnits';
import {originDestinationFields as loadFields} from 'deprecated/core/entities/Load/fields';

import {createDateTimeObject, getDifferenceBetweenTwoDateTimes} from 'utils/dateTime';

import composeFacilityCityLine from 'core/entities/Facility/composeFacilityCityLine';

const getPiecesSum = (items) => sumBy(items, (item) => parseInt(item.pieces, 10) || 0);
const getWeightSum = (items) =>
    sumBy(items, (item) => (item?.weight ? Number(item?.weight?.toString().replace(/,/g, '.')) : 0));
const getUnit = (items) => {
    const lastItem = last(items);
    return lastItem && lastItem.unit;
};

const getDimsUnits = (items) => {
    const lastItem = last(items);
    return lastItem && lastItem.dims_units;
};

export const isPickUp = (pickUpDelivery) => pickUpDelivery.is_pick_up;

export const getAllPickUps = ({[loadFields.pickUpInfo]: pickUpInfo}) =>
    pickUpInfo.filter((item) => item[loadFields.isPickUp]);

export const getAllDeliveries = ({[loadFields.pickUpInfo]: pickUpInfo}) =>
    pickUpInfo.filter((item) => !item[loadFields.isPickUp]);

export const getPickUpDeliveryNumberMap = (load) => {
    const allPickUps = getAllPickUps(load);
    const allDeliveries = getAllDeliveries(load);
    const getMapWithNumbers = (listItems) => listItems.map((item, index) => ({id: item.id, number: index + 1}));
    return keyBy([...getMapWithNumbers(allPickUps), ...getMapWithNumbers(allDeliveries)], 'id');
};

/**
 * @typedef {Object} FreightTotalInfo
 * @property {number} pieces
 * @property {number} weight
 * @property {string} unit - Shouldn't be used and will be removed later
 * @property {string} commonUnits
 * @property {boolean} isHazmat
 * @property {boolean} isStackable
 */

/**
 *
 * @param {Array} freightInfo
 * @return {FreightTotalInfo}
 */
export const getTotalFreightsInfo = (freightInfo) => ({
    pieces: getPiecesSum(freightInfo),
    weight: getWeightSum(freightInfo),
    unit: getUnit(freightInfo),
    dims_units: getDimsUnits(freightInfo),
    commonUnits: getCommonFreightUnits(freightInfo),
    isHazmat: freightInfo.some((freight) => freight && Boolean(freight.is_hazmat)),
    isStackable: freightInfo.some((freight) => freight && Boolean(freight.is_stackable)),
});

export const getPickUpTotalFreightsInfo = (
    {id: loadID, temp_min, temp_max, [loadFields.pickUpInfo]: pickUpInfo},
    excludedFields,
) => {
    const tempMin = parseInt(temp_min, 10);
    const tempMax = parseInt(temp_max, 10);
    return pickUpInfo
        .filter((pickUpDelivery) => pickUpDelivery.is_pick_up)
        .map((pickUp) => {
            const pickupTotalFreightInfo = getTotalFreightsInfo(pickUp.freight_info);
            const totalFreightInfo = excludedFields
                ? omit(pickupTotalFreightInfo, excludedFields)
                : pickupTotalFreightInfo;
            return {
                id: pickUp.id,
                loadID,
                tempMin,
                tempMax,
                isTempRange: Number.isInteger(tempMin) && Number.isInteger(tempMax),
                ...totalFreightInfo,
                freights: pickUp.freight_info,
            };
        });
};

export const getPickUpDeliveryInfo = (load, {withAllAddresses = false} = {}) => {
    const allPickUps = getAllPickUps(load);
    const allDeliveries = getAllDeliveries(load);
    const firstPickUp = head(allPickUps);
    const lastDelivery = last(allDeliveries);
    const getPickUpDeliveryAddress = (pickUpDelivery) =>
        pickUpDelivery && pickUpDelivery.shippers ? composeFacilityCityLine(pickUpDelivery.shippers) : '';
    const firstPickUpAddress = getPickUpDeliveryAddress(firstPickUp);
    const lastDeliveryAddress = getPickUpDeliveryAddress(lastDelivery);

    return {
        id: load.id,
        allPickUps,
        allDeliveries,
        firstPickUp,
        lastDelivery,
        firstPickUpAddress,
        lastDeliveryAddress,
        allStopsAddresses: withAllAddresses ? load.pick_up_info.map(getPickUpDeliveryAddress) : undefined,
        allStopsLength: load.pick_up_info.length,
        numbersMap: getPickUpDeliveryNumberMap(load),
    };
};

export const getPickUpDeliveryExpireData = (load, currentTime, currentTimeZone) => {
    const {status, [loadFields.pickUpInfo]: loadPickUpsDeliveries} = load;
    const availableStatuses = [
        loadStatuses.LOAD_STATUS_AVAILABLE,
        loadStatuses.LOAD_STATUS_PLANNED,
        loadStatuses.LOAD_STATUS_IN_PROGRESS,
    ];

    if (!availableStatuses.includes(status)) {
        return [];
    }

    return loadPickUpsDeliveries
        .map((pickUpDelivery) => {
            const {id, date, time_end, timezone} = pickUpDelivery;

            if (!date || !time_end || !timezone) {
                return null;
            }

            const originPickUpTimeEnd = createDateTimeObject({dateTime: `${date} ${time_end}`, timeZone: timezone});
            const convertedPickUpTimeEnd = originPickUpTimeEnd.setZone(currentTimeZone);
            const expiresTimeAsHours = getDifferenceBetweenTwoDateTimes({
                dateTimeStart: convertedPickUpTimeEnd,
                dateTimeEnd: currentTime,
            }).hours;
            const oneHour = 1;
            const threeHours = 3;
            if (expiresTimeAsHours) {
                return {
                    id,
                    hours: expiresTimeAsHours,
                    expiresSoon: expiresTimeAsHours > oneHour && expiresTimeAsHours <= threeHours,
                    expiresVerySoon: expiresTimeAsHours <= oneHour,
                };
            }

            return null;
        })
        .filter(Boolean);
};

export const getTotalDistance = (load) => {
    const {distance} = load;
    if (!distance) {
        return 0;
    }
    if (Number.isInteger(distance)) {
        return distance;
    }
    return parseFloat(distance.split(',').reduce((number, val) => number + parseFloat(val), 0));
};

export const getDistanceSegments = ({distance = null}) => {
    if (!distance) {
        return [];
    }
    if (Number.isInteger(distance)) {
        return [distance];
    }
    return distance.split(',');
};
