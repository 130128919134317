import * as loadStatuses from 'core/entities/Load/modules/loadStatus';

export const CREATE_LOAD_FORM_NAME = 'create-load-form';
export const UPDATE_LOAD_FORM_NAME = 'load-update-form';
export const CREATE_MODE_LOAD_FORM = 'create';
export const UPDATE_MODE_LOAD_FORM = 'update';

export const FORM_ORIGIN_DESTINATION_STEP = 'load';
export const FORM_BOOKING_INFO_STEP = 'general';
export const FORM_FILES_STEP = 'files';

export const MODAL_NAMES = {
    confirmationNotApprovedCarrierModal: 'CONFIRMATION NOT APPROVED CARRIER MODAL',
    successfulLoadNumberReserveModal: 'SUCCESSFUL LOAD NUMBER RESERVE MODAL',
    completeWaitingTonuModal: 'COMPLETE WAITING FOR TONU MODAL',
    completeLoadWithTonuModal: 'COMPLETE LOAD WITH TONU MODAL',
    backToWaitingTonuModal: 'BACK TO WAITING FOR TONU MODAL',
    travelOrderAttachedModal: 'TRAVEL ORDER ATTACHED MODAL',
    cancelWaitingTonuModal: 'CANCEL WAITING FOR TONU MODAL',
    documentsExpirationModal: 'DOCUMENTS EXPIRATION MODAL',
    duplicatesCustomerModal: 'DUPLICATES CUSTOMER MODAL',
    successfullyUpdatedModal: 'SUCCESSFUL UPDATE MODAL',
    travelOrderNotesModal: 'TRAVEL ORDER NOTES MODAL',
    createFacility: 'CREATE FACILITY RECEIVER MODAL',
    successfulCreateModal: 'SUCCESSFUL CREATE MODAL',
    backToAvailableModal: 'BACK TO AVAILABLE MODAL',
    clearAllFieldsModal: 'CLEAR ALL FIELDS MODAL',
    createCompanyModal: 'CREATE COMPANY MODAL',
    cancelCreateModal: 'CANCEL CREATE MODAL',
    loadInvoiceModal: 'LOAD INVOICE MODAL',
    resetStopsModal: 'RESET STOPS MODAL',
    loadNotesModal: 'LOAD NOTES MODAL',
    cancelLoad: 'CANCEL_LOAD_MODAL',
};

export const FILE_TYPES = ['rcDocs', 'bolDocs', 'podDocs', 'files', 'extraFiles', 'travelOrderBol', 'travelOrderPod'];

export const AVAILABLE_LOADS_STATUSES = [
    loadStatuses.LOAD_STATUS_AVAILABLE,
    loadStatuses.LOAD_STATUS_IN_PROGRESS,
    loadStatuses.LOAD_STATUS_PLANNED,
];

export const DONE_LOADS_STATUSES = [
    loadStatuses.LOAD_STATUS_COMPLETED,
    loadStatuses.LOAD_STATUS_TONU,
    loadStatuses.LOAD_STATUS_CANCELED,
    loadStatuses.LOAD_STATUS_WAITING_FOR_TONU,
    loadStatuses.LOAD_STATUS_NO_TONU,
];

export const ATTACH_LOAD_TABS = {
    CARRIERS: 'CARRIERS',
    TRUCKS: 'TRUCKS',
} as const;

export const MAX_COUNT_TRUCKS_IN_ONE_PAGE = 8;
export const MAX_COUNT_CARRIERS_IN_ONE_PAGE = 12;

export const DEFAULT_CURRENT_PAGE = 1;
export const DEFAULT_PER_PAGE_COUNT = 50;
