import {LoadStop} from 'core/entities/Quote/types';
import {isPickupStop, isDeliveryStop} from 'core/entities/Quote/modules/quoteStop';
import {TIME_FRAME_ASAP, TIME_FRAME_APPT, TIME_FRAME_DIRECT} from 'core/entities/Load/constants';

import {createDate, getTimeZoneAbbreviation} from 'utils/dateTime';
import {getCityLineFromObject} from 'utils/getCityLine';

type AppDate = ReturnType<typeof createDate>;

type NumberedStop = LoadStop & {stopNumber: number};

export const getNumberedStops = (stops: LoadStop[]): NumberedStop[] => {
    let pickupNumber = 1;
    let deliveryNumber = 1;
    const numberedStops = stops.map((stop) => {
        const numberedData = {stopNumber: 0};
        if (isPickupStop(stop)) {
            numberedData.stopNumber = pickupNumber;
            pickupNumber += 1;
        }
        if (isDeliveryStop(stop)) {
            numberedData.stopNumber = deliveryNumber;
            deliveryNumber += 1;
        }
        return {...stop, ...numberedData};
    });

    return numberedStops;
};

export const getQuoteDate = (
    date: string | null | undefined,
    options?: {convertToTimeZone?: string; dateFormat?: string},
): AppDate | undefined => {
    if (!date) {
        return undefined;
    }
    return createDate(date, {
        fromISO: true,
        fromTimeZone: 'utc',
        toTimeZone: options?.convertToTimeZone,
        dateFormat: options?.dateFormat,
    });
};

export const getStopDate = (stop: LoadStop): string => {
    const {dateTo, dateFrom, timeFrame, timeZone} = stop;
    const startDate = getQuoteDate(dateFrom, {convertToTimeZone: timeZone});
    const endDate = getQuoteDate(dateTo, {convertToTimeZone: timeZone});
    const timeFrameInLoverCase = timeFrame?.toLowerCase();

    if (startDate) {
        if (timeFrame === TIME_FRAME_DIRECT || timeFrame === TIME_FRAME_ASAP) {
            return `${startDate.date} ${timeFrameInLoverCase} by ${startDate.time}`;
        }

        if (timeFrame === TIME_FRAME_APPT) {
            return `${startDate.date} ${timeFrameInLoverCase} at ${startDate.time}`;
        }
    }

    if (startDate && endDate) {
        return `${startDate.fullDate} - ${endDate.fullDate}`;
    }

    return '';
};

export const getStopCityLine = (stop: LoadStop) => {
    const stopAddress = stop.address;
    return getCityLineFromObject(stopAddress);
};

export const getStopArrivalDate = (stop: LoadStop) => {
    const stopDate = getStopDate(stop);
    if (!stopDate) {
        return '';
    }
    const timeZoneAbbreviation = getTimeZoneAbbreviation(stop.timeZone);
    return `${stopDate} ${timeZoneAbbreviation}`;
};

export const isDestinationDateGreaterOriginDate = (
    fromDate: string | undefined,
    toDate: string | undefined,
): boolean => {
    if (!fromDate || !toDate) {
        return false;
    }
    const getFromDate = new Date(fromDate);
    const getToDate = new Date(toDate);
    return getFromDate.getTime() > getToDate.getTime();
};
