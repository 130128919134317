import React from 'react';
import {Field} from 'redux-form';
import CheckBoxInput from 'components/ui/Form/CheckBoxInput';
import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import styles from './styles.module.scss';

const Team: React.FC = () => {
    const {getTruckWithDriversTeamFieldName} = getFormFieldNames();

    return (
        <div className={styles.check_bg}>
            <Field
                name={getTruckWithDriversTeamFieldName()}
                label="Team"
                position="right"
                component={CheckBoxInput}
                normalize={(value) => (value ? 1 : '')}
            />
        </div>
    );
};
export default Team;
