import * as stopModules from 'core/entities/TravelOrder/modules/travelOrderStops';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';

import {getTravelOrderStopReDispatchesInfoByID} from '../getTravelOrderStopReDispatches';
import {getTravelOrderStopFreightsInfoByID} from '../getTravelOrderStopFreightsInfo';
import {getTravelOrderStopLoadStopInfoByID} from '../getTravelOrderStopLoadStopInfo';
import {getTravelOrderStopCommonInfo} from '../getTravelOrderStopCommonInfo';
import {getTravelOrderStopBOLsInfoByID} from '../getTravelOrderStopBOLsInfo';
import {getTravelOrderStopActions} from '../getTravelOrderStopActions';
import {getTravelOrderStopInfoByID} from '../getTravelOrderStopInfo';
import {getTravelOrderStopLoadByID} from '../getTravelOrderStopLoad';

export const getExpandedStopInfoByID = (params: StopInfoParams) => {
    const {stopID, travelOrder} = params;

    const stopReDispatches = getTravelOrderStopReDispatchesInfoByID({stopID, travelOrder});
    const toStopFreightsInfo = getTravelOrderStopFreightsInfoByID({stopID, travelOrder});
    const loadStopInfo = getTravelOrderStopLoadStopInfoByID({stopID, travelOrder});
    const toStopBOLsInfo = getTravelOrderStopBOLsInfoByID({stopID, travelOrder});
    const toStopActions = getTravelOrderStopActions({stopID, travelOrder});
    const commonInfo = getTravelOrderStopCommonInfo({stopID, travelOrder});
    const toStopInfo = getTravelOrderStopInfoByID({stopID, travelOrder});
    const stopLoad = getTravelOrderStopLoadByID({stopID, travelOrder});

    const stopStatuses = stopModules.getTravelOrderStopStatusesByID({stopID, travelOrder});
    const toStopPODs = stopModules.getTravelOrderStopPODsByID({stopID, travelOrder});
    const stopSteps = stopModules.getTravelOrderStopStepsByID({stopID, travelOrder});

    return {
        // info
        reDispatches: stopReDispatches,
        freights: toStopFreightsInfo,
        stopActions: toStopActions,
        loadStop: loadStopInfo,
        bols: toStopBOLsInfo,
        common: commonInfo,
        pods: toStopPODs,
        stop: toStopInfo,
        load: stopLoad,

        // step statuses
        isStepAddDocument: stopSteps.isStepAddDocument,
        isStepCheckOut: stopSteps.isStepCheckOut,
        isStepCheckIn: stopSteps.isStepCheckIn,

        // stop statuses
        isStopInProgress: stopStatuses.isStopInProgress,
        isStopCompleted: stopStatuses.isStopCompleted,
        isStopPlanned: stopStatuses.isStopPlanned,
    };
};
