import isObject from 'lodash/isObject';
import {isChangeLocationAction} from 'routing/utils/index';

import * as types from '../actionTypes';

import {
    getTableData,
    addItemToTable,
    updateItemInTable,
    isSettlementUpdateCommonAction,
    isWebsocketAction,
    isSelectedSearchParams,
    addItemsToTable,
    updateItemsInTable,
    removeItemInTable,
} from './utils';
import settlementUpdateCommonReducer from './settlementUpdateCommonReducer';
import locationChangeCommonReducer from './locationChangeCommonReducer';
import settlementsCarriersWebsocketReducer from './settlementsCarriersWebsocketReducer';

const defaultState = {
    settlements: {byId: {}, allIds: []},
    batches: {byId: {}, allIds: []},
    allBatches: [],

    command: {
        name: undefined,
        data: {},
    },
};

export default function settlementsCarriersDataReducer(state = defaultState, action, pageState) {
    if (isChangeLocationAction(action)) {
        return locationChangeCommonReducer(state, defaultState, action);
    }

    if (isSettlementUpdateCommonAction(action)) {
        return settlementUpdateCommonReducer(state, action);
    }

    if (isWebsocketAction(action) && !isSelectedSearchParams(pageState)) {
        return settlementsCarriersWebsocketReducer(state, action);
    }

    switch (action.type) {
        // clear all data on change filter cause for each status list has different data sets
        case types.FILTER_STATUS_CHANGED:
        case types.LIST_SEARCH_PARAMS_CHANGED:
        case types.BATCHES_DATA_CLEARED: {
            return {
                // clear all batches data for avoid incorrect data after change list batches and list settlements
                // allBatches using for dropdown with all batches which can used on any sub page,
                // and we can have case when user creates new batch and then go to settlements page for add this created batch to any settlements
                // for this case we should not clear all data about batches
                ...state,
                allBatches: state.allBatches,
                batches: defaultState.batches,
                settlements: defaultState.settlements,
            };
        }

        case types.LIST_SETTLEMENTS_RECEIVED: {
            const {settlements} = action.payload;
            return {
                ...state,
                settlements: getTableData(settlements),
            };
        }

        case types.SETTLEMENT_RECEIVED: {
            const {settlement} = action.payload;
            const settlementBatch = settlement.batch || null;
            return {
                ...state,
                settlements: addItemToTable(state.settlements, settlement),
                batches: settlementBatch ? addItemToTable(state.batches, settlementBatch) : state.batches,
            };
        }

        case types.LIST_BATCHES_SETTLEMENTS_RECEIVED: {
            const {batchID, settlements} = action.payload;
            // only in this case API return list settlements without pay records
            // we should add pay record manual for keep data consistency
            const settlementsWithBatch = settlements.map((s) => {
                if (!s.batch) {
                    return {...s, batch: {id: batchID}};
                }
                return s;
            });
            const updatedSettlements =
                state.settlements.allIds.length === 0
                    ? getTableData(settlementsWithBatch)
                    : addItemsToTable(state.settlements, settlementsWithBatch);
            return {
                ...state,
                settlements: updatedSettlements,
            };
        }

        case types.LIST_BATCHES_RECEIVED: {
            const {batches} = action.payload;
            return {
                ...state,
                batches: getTableData(batches),
            };
        }

        case types.BATCH_CREATED: {
            const createdBatch = action.payload;
            return {
                ...state,
                batches: addItemToTable(state.batches, createdBatch),
            };
        }

        case types.BATCH_UPDATED: {
            const updatedBatch = action.payload;
            return {
                ...state,
                batches: updateItemInTable(state.batches, updatedBatch),
            };
        }

        case types.ALL_BATCHES_RECEIVED: {
            return {
                ...state,
                allBatches: action.payload,
            };
        }

        case types.SETTLEMENT_UPDATED: {
            const updatedSettlement = action.payload;
            const isUpdatedSettlementInCurrentState = updatedSettlement?.id in state.settlements.byId;
            if (!updatedSettlement || !isUpdatedSettlementInCurrentState) {
                return state;
            }
            return {
                ...state,
                settlements: updateItemInTable(state.settlements, updatedSettlement),
            };
        }

        case types.SETTLEMENT_STATUS_CHANGED: {
            const {batch: updatedBatch, settlement: updatedSettlement} = action.payload;
            const isUpdatedSettlementInCurrentState = updatedSettlement?.id in state.settlements.byId;

            if (
                (!updatedBatch || !isObject(updatedBatch)) &&
                (!updatedSettlement || !isUpdatedSettlementInCurrentState)
            ) {
                return state;
            }
            // this case when settlement to reset from status ready to post
            if (
                (!updatedBatch || !isObject(updatedBatch)) &&
                (updatedSettlement || isUpdatedSettlementInCurrentState)
            ) {
                return {
                    ...state,
                    settlements: updateItemInTable(state.settlements, updatedSettlement),
                };
            }

            if (updatedBatch?.settlements_count === 0) {
                return {
                    ...state,
                    batches: removeItemInTable(state.batches, updatedBatch),
                    settlements: updateItemInTable(state.settlements, updatedSettlement),
                };
            }

            const isUpdatedBatchInCurrentState = Boolean(state.batches.byId[updatedBatch.id]);
            const isUpdatedBatchInCommonList = state.allBatches.find((p) => p.id === updatedBatch.id);
            if (!isUpdatedBatchInCurrentState && !isUpdatedBatchInCommonList) {
                return state;
            }
            const allBatches = isUpdatedBatchInCommonList
                ? state.allBatches.map((p) => (p.id === updatedBatch.id ? updatedBatch : p))
                : state.allBatches;
            const batches = isUpdatedBatchInCurrentState
                ? updateItemsInTable(state.batches, [updatedBatch])
                : state.batches;

            return {
                ...state,
                allBatches,
                batches,
                settlements: updateItemInTable(state.settlements, updatedSettlement),
            };
        }

        case types.REMOVE_SETTLEMENT_FROM_THE_LIST: {
            const {payload: oldSettlement} = action;

            return {...state, settlements: removeItemInTable(state.settlements, oldSettlement)};
        }

        case types.LIST_CLEAR_LAST_COMMAND: {
            return {...state, command: defaultState.command};
        }
        default:
            return state;
    }
}
