import isEmpty from 'lodash/isEmpty';

import {IF_EMPTY_EMAIL} from 'utils/validation/errorMessages';

import {TimerSettingsFormValues} from 'pages/TravelOrders/types/formTypes';

type Errors = Partial<Record<keyof TimerSettingsFormValues, string>>;

export default (values: TimerSettingsFormValues): Errors => {
    const errors: Errors = {};

    if (isEmpty(values)) {
        return {};
    }

    if (!values.emailSenderId) {
        errors.emailSenderId = IF_EMPTY_EMAIL;
    }

    if (!values.emailsTo) {
        errors.emailsTo = IF_EMPTY_EMAIL;
    }

    return errors;
};
