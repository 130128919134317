import React from 'react';

type OwnProps = {
    data: {
        invalidDocuments: string[];
    };
};

const TruckHasExpiredDocumentsError: React.FC<OwnProps> = (props) => {
    const {data} = props;

    if (!data) {
        return null;
    }

    const {invalidDocuments} = data;
    const docsStr = invalidDocuments.map((doc) => (
        <>
            - {doc}.<br />
        </>
    ));

    return (
        <div>
            The truck status is “Waiting for approval” because the document(s) are expired or the field is empty:
            <br />
            <br />
            {docsStr}
            <br />
            Update the data, to change to the active status.
        </div>
    );
};

export default TruckHasExpiredDocumentsError;
