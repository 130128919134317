import React from 'react';
import {useSelector} from 'react-redux';

import {composeCustomerBillingCityLine} from 'core/entities/Customer/composeCustomerCityLine';
import Customer from 'core/entities/Customer/types';

import {getCustomerDuplicateData, getSelectedEntityData} from '../../utils';
import {getIsCustomerCreate} from '../../selectors';
import DuplicatesCount from '../DuplicatesCount';
import DuplicatesEntitiesList, {Entity} from '../DuplicatesEntitiesList';
import SelectDuplicateButton from '../SelectDuplicateButton';

interface OwnProps {
    customer: Customer;
    customerDuplicates: Customer[];
    onSelectCustomerDuplicate(c: Customer);
}

const getMappedCustomersDuplicates = (customers: Customer[]): Entity[] => {
    return customers.map(getCustomerDuplicateData);
};

const DuplicatesCustomers = ({
    customer,
    customerDuplicates,
    onSelectCustomerDuplicate,
}: OwnProps): JSX.Element | null => {
    const isCalledFromCustomerCreateForm = useSelector(getIsCustomerCreate);
    const customerCityLine = composeCustomerBillingCityLine(customer);
    const entityWithDuplicates = getCustomerDuplicateData(customer);
    const entitiesWithDuplicates = getMappedCustomersDuplicates(customerDuplicates);
    const handleSelectDuplicatedCustomer = (entityDuplicate: Entity): void => {
        const selectedDuplicatedCustomer = getSelectedEntityData<Customer>(entityDuplicate, customerDuplicates);
        if (selectedDuplicatedCustomer) {
            onSelectCustomerDuplicate(selectedDuplicatedCustomer);
        }
    };
    return (
        <div className="custom-scroll">
            <div className="general-data">
                <DuplicatesCount count={customerDuplicates.length} duplicateType="customer" />

                <div className="general-data__company-data">
                    <strong>{customer.name}</strong>
                    <br />
                    <span>{customer.billing_address_line}</span> <br />
                    <span>{customerCityLine}</span>
                </div>
                <div className="general-data__info-merge">
                    {isCalledFromCustomerCreateForm ? (
                        <>
                            <div>You can merge current customer with the one below</div>
                            <div className="warning-info">
                                <i className="fa fa-info-circle" aria-hidden="true" />
                                Entered data will not exchange the info
                            </div>
                        </>
                    ) : (
                        <div>You can insert booked with customer by selecting one below</div>
                    )}
                </div>
            </div>
            <div className="standard-table-style">
                <table className="table table-duplicates">
                    <thead>
                        <tr>
                            <th>ID#</th>
                            <th>COMPANY NAME</th>
                            <th>BILLING ADDRESS</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <DuplicatesEntitiesList
                        originalEntity={entityWithDuplicates}
                        entityDuplicates={entitiesWithDuplicates}
                    >
                        {(duplicate) => {
                            return (
                                <SelectDuplicateButton
                                    title={isCalledFromCustomerCreateForm ? 'Merge' : 'Select'}
                                    onSelect={() => handleSelectDuplicatedCustomer(duplicate)}
                                />
                            );
                        }}
                    </DuplicatesEntitiesList>
                </table>
            </div>
        </div>
    );
};

export default DuplicatesCustomers;
