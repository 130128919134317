export const SORT_BY_VALUES = {
    lastAppointment: 'lastAppointment',
    recentlyAdded: 'recentlyAdded',
    mostDelayed: 'mostDelayed',
    timerEnding: 'timerEnding',
    lastEvent: 'lastEvent',
    doneSpeed: 'doneSpeed',
    leftMiles: 'leftMiles',
} as const;

export const SORT_ORDER = {
    DESC: 'desc',
    ASC: 'asc',
} as const;

export const TRIP_MONITOR_TABS = {
    CARRIER: 'CARRIER',
    TRUCK: 'TRUCK',
    ALL: 'ALL',
} as const;
