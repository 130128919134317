import React from 'react';

import Button from 'components/ui/Buttons/Button';
import {Quote} from 'core/entities/Quote/types';

import useDurationTime from 'pages/LoadBoard/hooks/useDurationTime';

type OwnProps = {
    title: string;
    quote: Quote;
};

const SubmitButton: React.FC<OwnProps> = (props) => {
    const {title, quote} = props;
    const {isExpired: isQuoteExpired} = useDurationTime(quote.expireAt);
    return (
        <Button
            type="submit"
            className="mr10 ml-auto"
            colorTheme="green"
            buttonSize="small"
            defaultFocus={true}
            disabled={isQuoteExpired}
        >
            {title}
        </Button>
    );
};

export default SubmitButton;
