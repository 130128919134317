import React from 'react';
import TruckTypesSelect from 'components/common/Truck/TruckTypes/components/TruckTypesSelect';
import RequiredLabel from 'components/ui/Form/RequiredLabel';
import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';

const TruckType: React.FC = () => {
    const {getTruckTypeFieldName} = getFormFieldNames();

    return (
        <TruckTypesSelect
            name={getTruckTypeFieldName()}
            placeholder="choose or type the truck type"
            label={RequiredLabel('Truck type:')}
        />
    );
};
export default TruckType;
