import {EmptyMiles, Note, PaymentCurrency, PaymentRate, PaymentRpm, Truck} from '../types';

interface Result {
    getPaymentRateOfferFieldName: () => PaymentRate;
    getPaymentRpmOfferFieldName: () => PaymentRpm;
    getPaymentCurrencyOfferFieldName: () => PaymentCurrency;
    getTruckOfferFieldName: () => Truck;
    getNoteOfferFieldName: () => Note;
    getEmptyMilesOfferFieldName: () => EmptyMiles;
}
const getFormFieldNames = (): Result => {
    return {
        getPaymentRateOfferFieldName: (): PaymentRate => 'payment.rate',
        getPaymentRpmOfferFieldName: (): PaymentRpm => 'payment.rpm',
        getPaymentCurrencyOfferFieldName: (): PaymentCurrency => 'payment.currency',
        getTruckOfferFieldName: (): Truck => 'truck',
        getNoteOfferFieldName: (): Note => 'note',
        getEmptyMilesOfferFieldName: (): EmptyMiles => 'emptyDistance',
    };
};
export default getFormFieldNames;
