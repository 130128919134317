import React, {useEffect, useState} from 'react';

import Clipboard from 'components/ui/Clipboard';
import Tooltip from 'components/ui/Tooltip';

import styles from './clipboardWrapper.module.scss';

interface OwnProps {
    tooltipPosition?: 'left' | 'right' | 'bottom';
    position?: 'absolute' | 'relative';
    tooltip: string | JSX.Element;
    children?: JSX.Element;
    clipBoardText: string;
}

const timeForShowCopied = 500;

const ClipboardWrapper = (props: OwnProps): JSX.Element => {
    const {clipBoardText, tooltip, children, position = 'absolute', tooltipPosition} = props;

    const [showCopied, setShowCopied] = useState<boolean>(false);

    const handleCopy = (): void => {
        if (clipBoardText) {
            setShowCopied(true);
        }
    };

    useEffect(() => {
        if (showCopied) {
            const timeOut = setTimeout(() => {
                setShowCopied(false);
            }, timeForShowCopied);

            return () => clearTimeout(timeOut);
        }
    }, [showCopied, clipBoardText]);

    const className = position === 'absolute' ? styles.clipboard_wrap_absolute : styles.clipboard_wrap_relative;

    return (
        <div className={className} onClick={handleCopy}>
            {showCopied && (
                <div className={styles.copied}>
                    <i className="fa fa-check" /> copied
                </div>
            )}
            <Tooltip tooltipContent={tooltip} position={tooltipPosition}>
                <Clipboard clipBoardText={clipBoardText}>{children}</Clipboard>
            </Tooltip>
        </div>
    );
};
export default ClipboardWrapper;
