import {LoadBoardNotification} from '../../types';
import * as types from '../actionTypes';

export const receivedListLoadBoardNotifications = (
    notifications: LoadBoardNotification[],
    counters: {unread: number},
) => {
    return {
        type: types.RECEIVED_LIST_LOAD_BOARD_NOTIFICATIONS,
        payload: {notifications, counters},
    } as const;
};

export const receivedNewLoadBoardNotificationWS = (notification: LoadBoardNotification) => {
    return {
        type: types.RECEIVED_NEW_LOAD_BOARD_NOTIFICATION_WS,
        payload: {notification},
    } as const;
};
export const removeNewLoadBoardNotificationWS = (notification: LoadBoardNotification) => {
    return {
        type: types.REMOVE_NEW_LOAD_BOARD_NOTIFICATION_WS,
        payload: {notification},
    } as const;
};

export const readLoadBoardNotification = (notificationID: string) => {
    return {
        type: types.READ_LOAD_BOARD_NOTIFICATION,
        payload: {notificationID},
    } as const;
};

export const removeLoadBoardNotification = (notificationID: string) => {
    return {
        type: types.REMOVE_LOAD_BOARD_NOTIFICATION,
        payload: {notificationID},
    } as const;
};

export const removeAllLoadBoardNotifications = (dispatcherID: number) => {
    return {
        type: types.REMOVE_ALL_LOAD_BOARD_NOTIFICATIONS,
        payload: {dispatcherID},
    } as const;
};
