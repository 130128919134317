import React from 'react';

import CheckBoxInput from 'components/ui/Form/CheckBoxInput';

interface OwnProps {
    isChecked: boolean;
    className: string;
    onChange();
}

const CheckAllInvoices = ({isChecked, className, onChange}: OwnProps): JSX.Element => {
    return (
        <div className={className}>
            <div className="invoice-item">
                <div className="invoice-item__title">
                    <CheckBoxInput
                        label="Select All"
                        input={{
                            value: isChecked,
                            onChange,
                        }}
                        position="right"
                    />
                </div>
            </div>
        </div>
    );
};

export default CheckAllInvoices;
