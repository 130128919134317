export const NAME_REQUIRED = 'Please enter Company Name.';

export const PHONE_REQUIRED = 'Please enter a phone number.';

export const CITY_REQUIRED = 'Please enter city.';

export const STATE_REQUIRED = 'Please choose state from the list.';

export const COUNTRY_REQUIRED = 'Please choose country from the list.';

export const ONLY_LETTERS_AND_SYMBOLS = 'Input must contain only letters and symbols, please retype it';

export const ON_HOLD_REASON_REQUIRED = 'Please select on hold reason.';

export const REG_MORE_DIGITS = 'REG# must contain more than 1 symbol, please retype it.';

export const MC_MORE_DIGITS = 'MC/FF/MX# must contain more than 1 digit, please retype it.';

export const IDENTIFIER_NUMBER_UNIQUENESS_CHECK_ERROR_EU = 'REG# is already taken, please type another.';

export const IDENTIFIER_NUMBER_UNIQUENESS_CHECK_ERROR_NA = 'MC/FF/MX# is already taken, please type another.';

export const CUSTOMER_TYPE_CHOOSE_ERROR = 'Please choose customer type.';

export const CUSTOMER_TYPE_ENTER_ERROR = 'Please enter customer type.';

export const CUSTOMER_API_CONNECTION_ERROR = 'Please choose API connection.';

export const CUSTOMER_EXTERNAL_API_IDENTIFIER_ERROR = 'Please enter broker unique code';
