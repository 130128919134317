import countries from 'utils/data/countries';

const crossBorder = [
    {label: countries.CA.name, value: countries.CA.name},
    {label: countries.MX.name, value: countries.MX.name},
];
export const getCrossBorder = (crossBorderValue: string): string | undefined => {
    const initialCrossBorder = crossBorder.find(({value}) => value === crossBorderValue);
    return initialCrossBorder?.value;
};
