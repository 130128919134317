import React from 'react';
import {Field} from 'redux-form';

import {FIELDS} from 'core/entities/Customer/constants/fields';

import RequiredLabel from 'components/ui/Form/RequiredLabel';
import Select from 'components/ui/Form/Select';

import useApiConnection from './hooks/useApiConnection';

type OwnProps = {
    disabled?: boolean;
};

const ApiConnectionSelect: React.FC<OwnProps> = (props) => {
    const {disabled} = props;

    const {apiConnections} = useApiConnection();

    return (
        <div className="row">
            <div className="col-md-6">
                <Field
                    label={RequiredLabel('Api Connection')}
                    placeholder="choose API connection"
                    name={FIELDS.apiConnection}
                    data={apiConnections}
                    isDisabled={disabled}
                    component={Select}
                    type="text"
                />
            </div>
        </div>
    );
};

export default ApiConnectionSelect;
