import {AxiosResponse} from 'axios';

import Customer from 'core/entities/Customer/types';
import {Agent} from 'core/entities/Agent';
import restapi, {fetchFiles} from 'services/restapi';
import {getGeneralNotes, postGeneralNote} from 'services/restapi/generalNotes';
import {ENTITY_NAME_CUSTOMERS} from 'utils/data/entityNames';
import {APP_DOMAIN_LOGIC_ERROR_STATUS} from 'services/restapi/constants';

import Pagination from 'types/Pagination';

interface Identifier {
    type: string;
    number: string;
}

const apiUrl = `/${ENTITY_NAME_CUSTOMERS}`;

export const searchCustomers = (params): Promise<AxiosResponse> => {
    return restapi.post(`${apiUrl}/search`, params);
};

export const searchIdentifierNumberDuplicates = async (identifier: Identifier) => {
    const duplicates = await restapi
        .post('/customers/identifier/unique-check', identifier)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.error('Error fetch unique check', error);
        });
    return duplicates;
};

export function searchCustomerDuplicates(customer: Customer): Promise<Customer[]> {
    const searchParams = {
        title: customer.name,
        address_line: customer.billing_address_line,
        city: customer.billing_city,
        state: customer.billing_state,
    };
    return restapi
        .post(`${apiUrl}/merge/search`, searchParams)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.error('Error on search customers duplicates: ', err);
            return [];
        });
}

export function searchCustomersByTitle(params) {
    return restapi.post(`${apiUrl}/title/search`, params);
}

export const fetchCustomersList = (params): Promise<AxiosResponse> => {
    return restapi.get(apiUrl, {params});
};

export const fetchCustomer = (customerID: number): Promise<AxiosResponse> => {
    return restapi.get(`${apiUrl}/${customerID}`);
};

export function fetchCustomerNotes(customerId: number) {
    const params = {entity_name: ENTITY_NAME_CUSTOMERS, entity_id: customerId};
    return getGeneralNotes(params).then(({data}) => data);
}

export function fetchCustomerFiles(customerId: number) {
    const params = {id: customerId, entity: ENTITY_NAME_CUSTOMERS};
    return fetchFiles(params).then((res) => {
        if ('data' in res) {
            return res.data;
        }
    });
}

export const updateCustomer = (customer: Customer): Promise<AxiosResponse> => {
    return restapi.put(`${apiUrl}/${customer.id}`, customer);
};

export const createCustomer = (customer: Customer): Promise<AxiosResponse> => {
    return restapi.post(apiUrl, customer);
};

export function createCustomerNote(customerId: number, text: string) {
    const postData = {
        entity_id: customerId,
        entity_name: ENTITY_NAME_CUSTOMERS,
        text,
    };
    return postGeneralNote(postData).then(({data}) => data);
}

type ValidateResult = {
    result: 'error' | 'success';
    errors?: {isCustomerHasUncompletedLoad?: boolean; isCustomerHasUncompletedInvoice?: boolean};
};
export const validateArchiveCustomer = (customer: Customer): Promise<ValidateResult> => {
    return restapi
        .get(`${apiUrl}/${customer.id}/validate-archive`)
        .then<ValidateResult>(() => {
            return {result: 'success'};
        })
        .catch<ValidateResult>((error) => {
            const APP_DOMAIN_LOGIC_ERROR_CUSTOMER_HAS_UNCOMPLETED_LOAD = 'UNCOMPLETED_LOAD';
            const APP_DOMAIN_LOGIC_ERROR_CUSTOMER_HAS_UNCOMPLETED_INVOICE = 'UNCOMPLETED_INVOICE';

            if (error.status !== APP_DOMAIN_LOGIC_ERROR_STATUS) {
                throw error;
            }

            const errorType = error.data?.type;

            if (errorType === APP_DOMAIN_LOGIC_ERROR_CUSTOMER_HAS_UNCOMPLETED_LOAD) {
                return {result: 'error', errors: {isCustomerHasUncompletedLoad: true}};
            }

            if (errorType === APP_DOMAIN_LOGIC_ERROR_CUSTOMER_HAS_UNCOMPLETED_INVOICE) {
                return {result: 'error', errors: {isCustomerHasUncompletedInvoice: true}};
            }

            console.error('Unknown domain logic error: ', error);
            throw error;
        });
};

export const archiveCustomer = (customer: Customer): Promise<Customer> => {
    return restapi.put(`${apiUrl}/${customer.id}/archive`).then(() => ({...customer, is_deleted: true}));
};

export const restoreCustomer = (customer: Customer): Promise<Customer> => {
    return restapi.put(`${apiUrl}/${customer.id}/restore`).then(() => ({...customer, is_deleted: false}));
};

export const fetchCustomersToMerge = ({sourceID, destinationID}) => {
    return restapi.get(`/customer/merge/${sourceID}/into/${destinationID}`);
};

export const mergeCustomersRequest = ({sourceID, destinationID, requestBody}) => {
    return restapi.post(`/customer/merge/${sourceID}/into/${destinationID}`, requestBody);
};

export const fetchCustomerAgents = (
    customerId: string,
    pagination: Partial<Pagination>,
): Promise<AxiosResponse<Agent[]>> => {
    const params = {page: pagination.currentPage, 'per-page': pagination.perPage};

    return restapi.get(`/customer/${customerId}/agent`, {params});
};

export const fetchCustomerAgentsArchived = (
    customerId: string,
    pagination: Partial<Pagination>,
): Promise<AxiosResponse<Agent[]>> => {
    const params = {page: pagination.currentPage, 'per-page': pagination.perPage};

    return restapi.get(`/customer/${customerId}/agent/archive`, {params});
};

export const createCustomerAgentRequest = ({customerId, body}): Promise<AxiosResponse<boolean>> => {
    return restapi.post(`/customer/${customerId}/agent`, body);
};

export const updateCustomerAgentRequest = ({customerId, agentId, body}): Promise<AxiosResponse<boolean>> => {
    return restapi.put(`/customer/${customerId}/agent/${agentId}`, body);
};

export const archiveCustomerAgentRequest = ({customerId, agentId}): Promise<AxiosResponse<boolean>> => {
    return restapi.put(`/customer/${customerId}/agent/${agentId}/archive`);
};

export const restoreCustomerAgentRequest = ({customerId, agentId}): Promise<AxiosResponse<boolean>> => {
    return restapi.put(`/customer/${customerId}/agent/${agentId}/restore`);
};

export const fetchCustomerApiConnections = () => {
    return restapi.options('/customer/api-broker');
};
