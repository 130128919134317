import pick from 'lodash/pick';
import {InferActions} from 'store';
import * as types from '../../actionTypes/listActionTypes';
import {LoadState} from './index';

import {actionCreators} from '../../actionCreators/list';

const CurrentReducerActions = pick(actionCreators, ['advancedSearchParams', 'setStatusSearchParams']);

type ActionTypes = InferActions<typeof CurrentReducerActions>;

export default function searchParams(
    state: Pick<LoadState['searchParams'], 'advancedParams' | 'statuses'>,
    action: ActionTypes,
): Pick<LoadState['searchParams'], 'advancedParams' | 'statuses'> {
    switch (action.type) {
        case types.SET_STATUS_SEARCH_PARAMS: {
            return {
                ...state,
                statuses: action.payload,
            };
        }

        case types.SET_ADVANCED_SEARCH_PARAMS: {
            return {
                ...state,
                advancedParams: action.payload,
            };
        }

        // no default
    }

    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
