import {useState} from 'react';
import head from 'lodash/head';

interface StepManager {
    currentStep: string;
    selectFirstStep(): void;
    selectNextStep(): void;
    selectPrevStep(): void;
}

function useFormStepManager(steps: string[]): StepManager {
    const firstStep: any = head(steps);
    const [currentStep, setCurrentStep] = useState(firstStep);
    function selectFirstStep(): void {
        setCurrentStep(firstStep);
    }
    function selectNextStep(): void {
        const nextTabIndex = steps.indexOf(currentStep) + 1;
        setCurrentStep(steps[nextTabIndex]);
    }

    function selectPrevStep(): void {
        const previousTabIndex = steps.indexOf(currentStep) - 1;
        setCurrentStep(steps[previousTabIndex]);
    }

    return {currentStep, selectFirstStep, selectNextStep, selectPrevStep};
}

export default useFormStepManager;
