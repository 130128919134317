// eslint-disable-next-line max-classes-per-file
import React, {Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';
import {Prompt} from 'react-router-dom';
import {push} from 'connected-react-router';
import isFunction from 'lodash/isFunction';
import isUndefined from 'lodash/isUndefined';

import * as modalActions from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

function withNavigationConfirm(WrappedForm, navigationConfirmChecker) {
    // we using this wrapper for avoid bug with incorrect order replacing child ui-components on re-render parent
    // same issue https://github.com/ReactTraining/react-router/issues/5707
    // eslint-disable-next-line
    class PromptWrapper extends PureComponent {
        state = {
            showPrompt: false,
        };

        componentDidMount() {
            // eslint-disable-next-line
            this.setState({showPrompt: true});
        }

        render() {
            if (this.state.showPrompt) {
                return <Prompt when={this.props.when} message={this.props.message} />;
            }

            return null;
        }
    }
    // eslint-disable-next-line
    class WithNavigationConfirmWrapper extends PureComponent {
        state = {
            transitionBlocked: false,
        };

        handleLocationChange = (location) => {
            if (!this.state.transitionBlocked) {
                const isFormInitialValuesIDSet =
                    this.props.initialValues &&
                    (!isUndefined(this.props.initialValues.id) || !isUndefined(this.props.initialValues.number));
                const title = isFormInitialValuesIDSet ? 'Cancel Update' : 'Cancel Create';
                const buttonActionTitle = isFormInitialValuesIDSet ? 'Updating' : 'Creating';

                this.props.openModal({
                    modalName: commonModalNames.informationModal,
                    data: {
                        title,
                        leftButtonTitle: `KEEP ${buttonActionTitle}`,
                        rightButtonTitle: `Cancel ${buttonActionTitle}`,
                        bodyType: 'CancelForm',
                        buttonType: 'danger',
                    },
                    handlers: {
                        leftButtonHandler: () => {},
                        rightButtonHandler: () => this.confirmLocationChange(location),
                    },
                });
            }
            return this.state.transitionBlocked;
        };

        confirmLocationChange(location) {
            this.setState(
                (state) => ({
                    ...state,
                    transitionBlocked: true,
                }),
                () => this.props.redirect(location.pathname),
            );
        }

        render() {
            return (
                <Fragment>
                    <PromptWrapper when={this.props.needNavigationConfirm} message={this.handleLocationChange} />

                    <WrappedForm {...this.props} />
                </Fragment>
            );
        }
    }

    return connect(
        (state, ownProps) => {
            const {dirty: isFormFieldsChanged, submitSucceeded, valid} = ownProps;

            let needConfirm = isFormFieldsChanged;

            // works - if next tab has validation
            const needConfirmAfterSwitchToNextTab = submitSucceeded && !valid;

            if (submitSucceeded) {
                needConfirm = needConfirmAfterSwitchToNextTab;
            }

            return {
                needNavigationConfirm: isFunction(navigationConfirmChecker)
                    ? navigationConfirmChecker(state, ownProps)
                    : needConfirm,
            };
        },
        (dispatch) => ({
            redirect: (path) => dispatch(push(path)),
            openModal: (params) => dispatch(modalActions.openModal(params)),
        }),
    )(WithNavigationConfirmWrapper);
}

export default withNavigationConfirm;
