import {createSelector} from 'reselect';
import sumBy from 'lodash/sumBy';

import {AppState} from 'store';
import {MessageStatus} from 'widgets/Chat/types';

export const getChannelByDriverID = (allChannels, driverID) => {
    if (!allChannels || !driverID) {
        return undefined;
    }
    return allChannels.byDriverID ? allChannels.byDriverID[driverID] : undefined;
};
export const getAllChannels = (state: AppState) => state.chat.channels;
export const getSelectedChannelDriverID = (state: AppState) => state.chat.selectedChannelData.driverID;
export const getSelectedChannel = (state: AppState) => {
    const driverID = getSelectedChannelDriverID(state);
    return getChannelByDriverID(state.chat.channels, driverID);
};
export const getSelectedChannelTruckData = (state: AppState) => state.chat.selectedChannelData.truck;
export const getSearchParams = (state: AppState) => state.chat.searchParams;
export const getUnreadMessagesCount = createSelector([getAllChannels], (allChannels) => {
    return sumBy(Object.values(allChannels.byDriverID), (channel) => channel.unreadMessagesCount);
});

export const getChatOpened = (state: AppState): boolean => state.chat.opened;
export const getChatClientSocketId = (state: AppState): string | null => state.chat.clientSocketId;
export const getMessageStatus = (state: AppState): MessageStatus => state.chat.messageStatus;
