import groupBy from 'lodash/groupBy';

import {FILE_TYPES as LOADS_FILES_TYPES} from 'deprecated/core/entities/Load/constants';

/**
 * @typedef {Object} GroupedLoadsFiles
 * @property {Array.<Object>} rc array of load rc files or empty array
 * @property {Array.<Object>} pod array of load pod files or empty array
 * @property {Array.<Object>} files array of load regular files or empty array
 * @property {Array.<Object>} extra_files array of load extra files or empty array
 */
/**
 * Converts all files of load entity to map with attach type as key.
 * @param {Array.<Object>} allLoadFiles
 * @returns {GroupedLoadsFiles}
 */
export const getLoadFilesMap = (allLoadFiles) => {
    const groupedLoadFiles = groupBy(allLoadFiles, 'attach_type');
    return {
        rc: groupedLoadFiles[LOADS_FILES_TYPES.rcFiles] || [],
        pod: groupedLoadFiles[LOADS_FILES_TYPES.podFiles] || [],
        files: groupedLoadFiles[LOADS_FILES_TYPES.regularFiles] || [],
        extra_files: groupedLoadFiles[LOADS_FILES_TYPES.extraFiles] || [],
    };
};
