import loadApiGateway from '../../gateways/LoadApiGateway';
import getLoadUseCase from './getLoad';
import createLoadUseCase from './createLoad';
import createTravelOrderWithTruckUseCase from './createTravelOrderWithTruck';
import createTravelOrderWithCarrierUseCase from './createTravelOrderWithCarrier';
import updateLoadUseCase from './updateLoad';
import completeLoadWithTonuUseCase from './completeLoadWithTONU';
import addLoadNotesUseCase from './addLoadNotes';
import changeLoadFilesUseCase from './changeLoadFiles';

import type {
    GetLoadParams,
    CreateLoadParams,
    CreateTravelOrderWithTruckParams,
    UpdateLoadParams,
    CompleteLoadWithTONUParams,
    CreateTravelOrderWithCarrierParams,
    AddLoadNote,
} from './types';
import {UpdateFilesLoadParams} from './types';

export const getLoad = (data: GetLoadParams): ReturnType<typeof getLoadUseCase> => {
    return getLoadUseCase(data, {loadApiGateway});
};

export const createLoad = (data: CreateLoadParams): ReturnType<typeof createLoadUseCase> => {
    return createLoadUseCase(data, {loadApiGateway});
};

export const createTravelOrderWithTruck = (
    data: CreateTravelOrderWithTruckParams,
): ReturnType<typeof createTravelOrderWithTruckUseCase> => {
    return createTravelOrderWithTruckUseCase(data, {loadApiGateway});
};

export const createTravelOrderWithCarrier = (
    data: CreateTravelOrderWithCarrierParams,
): ReturnType<typeof createTravelOrderWithCarrierUseCase> => {
    return createTravelOrderWithCarrierUseCase(data, {loadApiGateway});
};

export const updateLoad = (data: UpdateLoadParams): ReturnType<typeof updateLoadUseCase> => {
    return updateLoadUseCase(data, {loadApiGateway});
};

export const completeLoadWithTONU = (
    data: CompleteLoadWithTONUParams,
): ReturnType<typeof completeLoadWithTonuUseCase> => {
    return completeLoadWithTonuUseCase(data, {loadApiGateway});
};

export const addLoadNotes = (loadNumber: number, data: AddLoadNote): ReturnType<typeof addLoadNotesUseCase> => {
    return addLoadNotesUseCase(loadNumber, data, {loadApiGateway});
};

export const changeLoadFiles = (
    loadNumber: number,
    data: UpdateFilesLoadParams & {fileIds: Record<number, string>[]},
): ReturnType<typeof changeLoadFilesUseCase> => {
    return changeLoadFilesUseCase(loadNumber, data, {loadApiGateway});
};
