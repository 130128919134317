import React, {useState} from 'react';
import classNames from 'classnames';

import useFetchNotifications from './hooks/useFetchNotifications';
import useConnectToAuctionNotifications from './hooks/useConnectToAuctionNotifications';
import {usePlaySoundOnGetNewNotification} from './hooks/useSoundNewNotification';
import NotificationsCount from './components/NotificationsCount';
import NotificationsHeader from './components/NotificationsHeader';
import NotificationsList from './components/NotificationsList';
import NewNotification from './components/NewNotification';

import './styles.scss';

const Notifications: React.FC = () => {
    const [isNotificationsOpened, setNotificationsOpen] = useState(false);
    const contentClassName = classNames('notification-menu', {active: isNotificationsOpened});
    const switchEnabled = () => {
        setNotificationsOpen((isOpened) => !isOpened);
    };

    usePlaySoundOnGetNewNotification();
    useFetchNotifications();
    useConnectToAuctionNotifications();

    return (
        <div className="notification-widget">
            <NotificationsCount onCLick={switchEnabled} isNotificationsOpened={isNotificationsOpened} />
            <NewNotification />
            <div className={contentClassName}>
                <NotificationsHeader />
                <NotificationsList />
            </div>
        </div>
    );
};

export default Notifications;
