export default {
    settlement: 'settlement',
    invoice: 'invoice',
    owner: 'owner',
    owner_company: 'owner_company',
    truck: 'truck',
    dispatcher: 'dispatcher',
    pay_period: 'pay_period',
    past_due: 'past_due',
    future_pay_period: 'future_pay_period',
    total_pay_from: 'total_pay_from',
    total_pay_to: 'total_pay_to',
    dry_run: 'dry_run',
    hide_tonu_processing: 'hide_tonu_processing',
    forced: 'forced',
    with_pay_corrections: 'with_pay_corrections',
};
