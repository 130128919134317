import React from 'react';

import styles from '../../styles.module.scss';

const WarningMessage: React.FC = () => (
    <div className={styles.warning}>
        <i className="fa fa-exclamation-circle" />
        <p>
            <span>
                The creation and sending parameters were not fulfilled, most likely due to an inability to obtain the
                correct location or a delay in the truck&apos;s progress. Please contact the driver to confirm their
                current location, and after obtaining the information, <strong>add the event manually.</strong>
            </span>
        </p>
    </div>
);

export default WarningMessage;
