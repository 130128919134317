import React, {PureComponent} from 'react';
import {Modal} from 'react-bootstrap';
import isPlainObject from 'lodash/isPlainObject';
import Button from 'components/ui/Buttons/Button';

export default class Confirm extends PureComponent {
    onOk = () => {
        const {onClose, handlers} = this.props;
        if (handlers.onOk) {
            handlers.onOk();
            onClose();
        } else {
            onClose();
        }
    };

    render() {
        const {
            data: {body, title, type, showCancel, onCancelText, onOkText},
            onClose,
        } = this.props;

        let cls;
        switch (type) {
            case 'success':
                cls = 'modal_success';
                break;
            case 'danger':
                cls = 'modal_danger';
                break;
            case 'warning':
                cls = 'modal_warning';
                break;
            default:
                cls = '';
        }
        let bodyContent = '';

        if (body) {
            if (Array.isArray(body)) {
                bodyContent = body.map((el, i) => <div key={i}>{el}</div>);
            } else if (isPlainObject(body)) {
                bodyContent = Object.keys(body).map((el, i) => (
                    <div key={i}>
                        {el}: {body[el]}
                    </div>
                ));
            } else {
                bodyContent = body;
            }
        }

        return (
            <Modal show={true} backdrop={false}>
                <Modal.Header>
                    <Modal.Title>
                        <div className={`static_modal ${cls}`}>{title}</div>
                    </Modal.Title>
                </Modal.Header>
                {body && (
                    <Modal.Body>
                        <div className="custom-scroll">{bodyContent}</div>
                    </Modal.Body>
                )}
                <Modal.Footer>
                    {showCancel ? (
                        <Button colorTheme="grey" buttonSize="small" onClick={onClose} className="button-lg-last">
                            {onCancelText || 'Cancel'}
                        </Button>
                    ) : (
                        <div />
                    )}

                    <Button colorTheme="blue" buttonSize="small" onClick={this.onOk} className="button-lg-last">
                        {onOkText || 'Ok'}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
