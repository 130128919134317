import React, {useState} from 'react';
import {Field} from 'redux-form';
import classNames from 'classnames';

import {formatCoordinates} from 'services/mapsApi';

import {LastEventForCurrentSummary} from 'core/entities/LocationEvents/types';

import {CustomerLink, LoadLink} from 'components/ui/Links';
import CheckBoxInput from 'components/ui/Form/CheckBoxInput';
import CheckSwitch from 'components/ui/Form/CheckSwitch';
import Button from 'components/ui/Buttons/Button';
import RadioSwitch from 'components/ui/Form/RadioSwitch';
import RequiredLabel from 'components/ui/Form/RequiredLabel';
import InputWithPlacesAutocomplete, {AddressData} from 'components/ui/Form/InputWithPlacesAutocomplete';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';
import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';

import {getFieldNameWithSummarySection} from 'components/common/LocationEvents/modals/CheckCallModal/utils';
import BadgeLocationForBroker from 'components/common/LocationEvents/modals/CheckCallModal/common/BadgeLocationForBroker';

import TimeDataPicker from '../../TimeDataPicker';

import LoadSummaryLastEvent from '../LoadSummaryLastEvent';

import styles from './styles.module.scss';

const LoadSummaryItem: React.FC<{
    form: string;
    formSection: string;
    summary: {
        loadNumber: number;
        customerID: number;
        customerName: string;
        lastEventForCurrentSummary: LastEventForCurrentSummary;
    };
}> = (props) => {
    const {
        form,
        formSection,
        summary: {loadNumber, lastEventForCurrentSummary, customerName, customerID},
    } = props;

    const [open, setOpen] = useState(true);

    const checkLocationForBroker = useGetReduxFormValue(
        form,
        getFieldNameWithSummarySection(formSection, `checkLocationForBroker`),
    );
    const changeFormValue = useChangeReduxFormValue(form);

    const handleSelectItemFromAutocomplete = (item: AddressData): void => {
        if (item) {
            const formattedCords = formatCoordinates(item.coords);
            changeFormValue(getFieldNameWithSummarySection(formSection, 'latitude'), formattedCords.lat);
            changeFormValue(getFieldNameWithSummarySection(formSection, 'longitude'), formattedCords.lng);
        }
    };

    return (
        <div className={styles.wrap}>
            <div className={styles.header}>
                <div className={styles.checkInfo}>
                    <Field
                        name={getFieldNameWithSummarySection(formSection, `sendToSummary`)}
                        component={CheckBoxInput}
                        position="right"
                        disabled={true}
                        className={styles.checkbox}
                    />
                    Load Summary{' '}
                    <LoadLink
                        action="summary"
                        load={{number: loadNumber}}
                        content={`#${loadNumber}`}
                        className={classNames(styles.summary, 'second-link')}
                    />
                    <CustomerLink customer={{id: customerID}} content={customerName} className="second-link" />
                </div>
                <div className={styles.badgeInfo}>
                    <BadgeLocationForBroker isRealLocation={!checkLocationForBroker} />
                    <Button
                        onClick={() => setOpen(!open)}
                        buttonIcon={open ? 'angle-up' : 'angle-down'}
                        buttonSize="icon"
                        className="btn-link"
                    />
                </div>
            </div>
            {open ? (
                <div className={styles.main}>
                    <LoadSummaryLastEvent lastEvent={lastEventForCurrentSummary} />
                    <Field
                        name={getFieldNameWithSummarySection(formSection, `checkLocationForBroker`)}
                        component={CheckSwitch}
                        label="Edit Location For Broker (LFB)"
                        className={styles.switch}
                    />
                    {checkLocationForBroker ? (
                        <div className={styles.form}>
                            <Field
                                name={getFieldNameWithSummarySection(formSection, 'behindRealEvent')}
                                component={RadioSwitch}
                                items={[
                                    {label: 'behind real', value: true},
                                    {label: 'ahead real', value: false},
                                ]}
                                label={RequiredLabel('Event Position:')}
                            />
                            <div className={styles.location}>
                                <Field
                                    type="text"
                                    name={getFieldNameWithSummarySection(formSection, 'cityLine')}
                                    withUnknownValues={true}
                                    placeholder="enter location"
                                    onGetAddress={handleSelectItemFromAutocomplete}
                                    component={InputWithPlacesAutocomplete}
                                    label={RequiredLabel('New Location')}
                                />
                                <TimeDataPicker
                                    form={form}
                                    formSection={formSection}
                                    showReset={true}
                                    timeZoneDisabled={true}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className={styles.info}>
                            Truck <strong>real location</strong> will be added to the Load Summary event.
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default LoadSummaryItem;
