import {
    TRUCK_STATUS_AVAILABLE,
    TRUCK_STATUS_NOT_AVAILABLE,
    TRUCK_STATUS_ON_ROUTE,
    TRUCK_STATUS_WILL_BE_AVAILABLE_AT,
} from 'core/entities/Truck/constants/truckStatuses';

import {StoreIcon, TruckIcon, SearchFormValues} from '../types';

export const truckStatusesForGeneralMap = [
    {label: TRUCK_STATUS_AVAILABLE, value: TRUCK_STATUS_AVAILABLE},
    {label: 'Will be available', value: TRUCK_STATUS_WILL_BE_AVAILABLE_AT},
    {label: TRUCK_STATUS_ON_ROUTE, value: TRUCK_STATUS_ON_ROUTE},
    {label: TRUCK_STATUS_NOT_AVAILABLE, value: TRUCK_STATUS_NOT_AVAILABLE},
];

export const initialTruckSearchParams: SearchFormValues = {
    truckStatus: [TRUCK_STATUS_AVAILABLE],
    truckType: [],
    truckTrailerType: [],
    truckReeferType: [],
};

export const STORE_ICON: StoreIcon = '1686';
export const TRUCK_ICON: TruckIcon = '1722';

export const mapTypeIds = {
    roadmap: 'roadmap',
    hybrid: 'hybrid',
};

export const truckLocationTypes = {
    CURRENT: 'CURRENT',
    FINAL: 'FINAL',
};

export const mapForms = {
    searchByTruckNumberForm: 'search-by-truck-number',
    searchByTruckStatusForm: 'search-by-truck-status',
    searchLocationForm: 'search-location',
    trucksTimelineForm: 'trucks-timeline',
    statusFiltersForm: 'status-filters',
    locationFiltersForm: 'location-filters',
    trucksDetailsFiltersForm: 'trucks-details-filters',
    crossDocksFiltersForm: 'cross-docks-filters',
    rentTrucksFiltersForm: 'rent-trucks-filters',
};

export const TRUCK_WITH_REEFER = 'With reefer';
export const TRUCK_WITHOUT_REEFER = 'Without reefer';

export const reeferTypes = [
    {label: TRUCK_WITH_REEFER, value: TRUCK_WITH_REEFER},
    {label: TRUCK_WITHOUT_REEFER, value: TRUCK_WITHOUT_REEFER},
];
