import {formValueSelector} from 'redux-form';

import {AppState} from 'store';
import {File as StoreFile} from 'types/File';
import {FILE_IDS_TO_BE_REMOVED_KEY} from 'components/ui/Files/FileUpload/constants';

export interface FormFileProps {
    existingFiles?: StoreFile[];
    fileIDsToBeRemoved?: number[];
}

interface PublicProps {
    meta: {form: string};
    existingFilesKey: string;
    pathToDeletedItems?: string;
}

export function getRemovedFileIdsPath(pathToDeletedItems?: string): string {
    return pathToDeletedItems ? `${pathToDeletedItems}.${FILE_IDS_TO_BE_REMOVED_KEY}` : FILE_IDS_TO_BE_REMOVED_KEY;
}

export function mapExistingFilesFromFormState(state: AppState, props: PublicProps): FormFileProps {
    const {
        meta: {form},
        existingFilesKey,
        pathToDeletedItems,
    } = props;
    const selector = formValueSelector(form);
    const fileIDsToBeRemovedPath = getRemovedFileIdsPath(pathToDeletedItems);
    return {
        existingFiles: selector(state, existingFilesKey),
        fileIDsToBeRemoved: selector(state, fileIDsToBeRemovedPath),
    };
}
