import * as loadBoardRequests from 'core/gateways/LoadBoardApiGateway/requests';
import {getCurrentDispatcher} from 'store/reducers/userData/selectors';

import {LoadBoardNotification} from '../../types';
import * as actionCreators from '../actionCreators';

export function fetchLoadBoardNotifications() {
    return function (dispatch, getState) {
        const state = getState();
        const currentDispatcher = getCurrentDispatcher(state);
        loadBoardRequests
            .fetchDispatcherNotifications(currentDispatcher.id)
            .then((response) => {
                const {items: notifications, counters} = response;
                dispatch(actionCreators.receivedListLoadBoardNotifications(notifications, counters));
            })
            .catch((err) => {
                console.error('Error on fetch load board notifications: ', err);
            });
    };
}

export function readLoadBoardNotification(notification: LoadBoardNotification) {
    return function (dispatch) {
        loadBoardRequests
            .readDispatcherNotification(notification)
            .then(() => {
                dispatch(actionCreators.readLoadBoardNotification(notification.id));
            })
            .catch((err) => {
                console.error('Error on read load board notification: ', err);
            });
    };
}

export function removeLoadBoardNotification(notification: LoadBoardNotification) {
    return function (dispatch) {
        loadBoardRequests
            .deleteDispatcherNotification(notification)
            .then(() => {
                dispatch(actionCreators.removeLoadBoardNotification(notification.id));
            })
            .catch((err) => {
                console.error('Error on delete load board notification: ', err);
            });
    };
}

export function removeAllLoadBoardNotification() {
    return function (dispatch, getState) {
        const state = getState();
        const currentDispatcher = getCurrentDispatcher(state);
        loadBoardRequests
            .deleteAllDispatcherNotifications(currentDispatcher.id)
            .then(() => {
                dispatch(actionCreators.removeAllLoadBoardNotifications(currentDispatcher.id));
            })
            .catch((err) => {
                console.error('Error on delete all load board notification: ', err);
            });
    };
}
