import CreateQuoteModal from './CreateQuoteModal';
import SuccessfullyCreateQuote from './SuccessfullyCreateQuote';
import QuoteDuplicatesModal from './QuoteDuplicatesModal';
import QuoteNotesModal from './QuoteNotesModal';
import QuoteReceiversModal from './QuoteReceiversModal';
import ArchiveQuoteModal from './ArchiveQuoteModal';
import SuccessfullyArchiveQuote from './SuccessfullyArchiveQuote';
import SuccessfullyCreateOffer from './SuccessfullyCreateOffer';
import SuccessfullyChangedDriverOfferModal from './SuccessfullyChangedDriverOfferModal';
import DriverOfferModal from './DriverOfferModal';
import OfferApproveModal from './OfferApproveModal';
import OfferUnApproveModal from './OfferUnApproveModal';
import OfferCancelStatusModal from './OfferCancelStatusModal';
import OfferDeactivateModal from './OfferDeactivateModal';
import SuccessfullyDeactivateOffer from './SuccessfullyDeactivateOffer';
import OfferErrors from './OfferErrors';

export const quoteModalNames = {
    createQuoteModal: 'CREATE_QUOTE',
    successCreateQuoteModal: 'SUCCESS_CREATE_QUOTE',
    duplicatesQuoteModal: 'DUPLICATES_QUOTE',
    quoteNotesModal: 'QUOTE_NOTES',
    quoteReceiversModal: 'QUOTE_RECEIVERS',
    archiveQuoteModal: 'ARCHIVE_QUOTE',
    successArchivedQuoteModal: 'SUCCESS_ARCHIVE_QUOTE',
    successCreateOfferModal: 'SUCCESS_CREATE_OFFER',
    driverOfferModal: 'QUOTE_DRIVER_OFFER',
    approveOfferModal: 'APPROVE_QUOTE_DRIVER_OFFER_MODAL',
    unApproveOfferModal: 'NOT_APPROVE_QUOTE_DRIVER_OFFER_MODAL',
    cancelOfferStatusModal: 'CANCEL_STATUS_QUOTE_DRIVER_OFFER_MODAL',
    deactivateOfferModal: 'DEACTIVATE_QUOTE_DRIVER_OFFER_MODAL',
    successChangedOfferModal: 'SUCCESS_CHANGED_OFFER',
    successDeactivateOfferModal: 'SUCCESS_DELETE_OFFER',
    offerErrorsModal: 'AUCTION_OFFER_ERRORS_MODAl',
};

const modalMap = {
    [quoteModalNames.createQuoteModal]: CreateQuoteModal,
    [quoteModalNames.successCreateQuoteModal]: SuccessfullyCreateQuote,
    [quoteModalNames.duplicatesQuoteModal]: QuoteDuplicatesModal,
    [quoteModalNames.quoteNotesModal]: QuoteNotesModal,
    [quoteModalNames.quoteReceiversModal]: QuoteReceiversModal,
    [quoteModalNames.driverOfferModal]: DriverOfferModal,
    [quoteModalNames.archiveQuoteModal]: ArchiveQuoteModal,
    [quoteModalNames.successArchivedQuoteModal]: SuccessfullyArchiveQuote,
    [quoteModalNames.successCreateOfferModal]: SuccessfullyCreateOffer,
    [quoteModalNames.approveOfferModal]: OfferApproveModal,
    [quoteModalNames.unApproveOfferModal]: OfferUnApproveModal,
    [quoteModalNames.cancelOfferStatusModal]: OfferCancelStatusModal,
    [quoteModalNames.deactivateOfferModal]: OfferDeactivateModal,
    [quoteModalNames.successChangedOfferModal]: SuccessfullyChangedDriverOfferModal,
    [quoteModalNames.successDeactivateOfferModal]: SuccessfullyDeactivateOffer,
    [quoteModalNames.offerErrorsModal]: OfferErrors,
};

export default modalMap;
