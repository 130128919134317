import React from 'react';

import {TIME_FRAME_FCFS, TIME_FRAME_APPT} from 'core/entities/Load/constants';

import DateTimeFCFS from './components/DateTimeFCFS';
import DateTimeAPPT from './components/DateTimeAPPT';
import DateTimeAsapDirect from './components/DateTimeAsapDirect';

interface OwnProps {
    dateFromFiledName: string;
    dateToFiledName: string;
    timeFrame: string;
    required?: boolean;
    isDisabled?: boolean;
}

const DateTime: React.FC<OwnProps> = (props) => {
    const {dateToFiledName, dateFromFiledName, timeFrame, required, isDisabled = false} = props;
    if (timeFrame === TIME_FRAME_FCFS) {
        return (
            <DateTimeFCFS
                required={required}
                dateFromFiledName={dateFromFiledName}
                dateToFiledName={dateToFiledName}
                disabled={isDisabled}
            />
        );
    }

    if (timeFrame === TIME_FRAME_APPT) {
        return <DateTimeAPPT required={required} dateFromFiledName={dateFromFiledName} disabled={isDisabled} />;
    }

    return <DateTimeAsapDirect required={required} dateFromFiledName={dateFromFiledName} disabled={isDisabled} />;
};

export default DateTime;
