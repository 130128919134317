// https://github.com/uuidjs/uuid#deep-requires-now-deprecated
import {v4 as uuidv4} from 'uuid';

import isEmpty from 'lodash/isEmpty';

import {Pickup} from 'deprecated/core/entities/Load/types/Pickup';
import {Delivery} from 'deprecated/core/entities/Load/types/Delivery';
import {Freight} from 'deprecated/core/entities/Load/types/Freight';
import Dispatch from 'deprecated/core/entities/TravelOrder/types/Dispatch';
import TravelOrder from 'deprecated/core/entities/TravelOrder/types';

import {Facility} from 'core/entities/Facility/types';
import {getInvoiceLoad, getInvoiceTravelOrder} from 'core/entities/Invoice/invoice';
import Invoice from 'core/entities/Invoice/types';

import VirtualStopFormData from 'pages/Invoices/components/common/modals/AddVirtualStopModal/types/FormData';

type VirtualFreight = Pick<Freight, 'pieces' | 'weight' | 'unit'>;

interface VirtualPickUp {
    shippers: Facility;
    freight_info: [VirtualFreight];
    step_number: number;
    is_pick_up: true;
    is_virtual: true;
}

interface VirtualDelivery {
    shippers: Facility;
    step_number: number;
    freight_info: [];
    is_pick_up: false;
    is_virtual: true;
}

type PickUpInfo = Pickup | Delivery | VirtualPickUp | VirtualDelivery;

function insertAtIndex(array: any[], index: number, element: any): any[] {
    if (isEmpty(array)) {
        return [];
    }
    const arrayCopy = array.slice();
    arrayCopy.splice(index, 0, element);

    return arrayCopy;
}

function deleteAtIndex(array: any[], index): any[] {
    if (isEmpty(array)) {
        return [];
    }

    const arrayCopy = array.slice();
    arrayCopy.splice(index, 1);

    return arrayCopy;
}

function getTravelOrderSteps(travelOrder?: TravelOrder): any[] | undefined {
    if (!travelOrder?.dispatch) {
        return undefined;
    }

    return travelOrder.dispatch.steps;
}

function formStopName(stop: any, stepNumber: number): string {
    const {
        pick_up_info_to: {is_pick_up: isPickUp},
    } = stop;

    return isPickUp ? `STOP (${stepNumber}) Pick Up` : `STOP (${stepNumber}) Delivery`;
}

function formFreight(virtualStop: VirtualStopFormData): VirtualFreight | undefined {
    const {pieces, weight, unit} = virtualStop;
    if (!pieces || !weight || !unit) {
        return;
    }

    return {pieces, weight, unit};
}

function formVirtualLoadPickUpInfo(virtualStop: VirtualStopFormData) {
    const {is_pick_up: isPickUp, shippers} = virtualStop;
    const id = uuidv4();

    if (isPickUp) {
        const freight = formFreight(virtualStop);

        return {
            id,
            shippers,
            is_pick_up: isPickUp,
            freight_info: [freight],
            is_virtual: true,
        };
    }

    return {
        id,
        shippers,
        is_pick_up: isPickUp,
        freight_info: [],
        is_virtual: true,
    };
}

function addVirtualStopToLoadPickupInfo(
    pickupInfo: PickUpInfo[],
    stopIndex: number,
    virtualStop: VirtualStopFormData,
): PickUpInfo[] {
    return insertAtIndex(pickupInfo, stopIndex, formVirtualLoadPickUpInfo(virtualStop)).map((stop, index) => {
        const stepNumber = index + 1;

        return {
            ...stop,
            step_number: stepNumber,
        };
    });
}

function formVirtualTravelOrderStop(virtualStop: VirtualStopFormData) {
    const {is_pick_up: isPickUp, bol, shippers, pod_signed_by: podSignedBy} = virtualStop;
    if (isPickUp) {
        const freight = formFreight(virtualStop);
        const bols = [{number: bol, ...freight}];

        return {
            pod_signed_by: podSignedBy,
            pick_up_info_to: {
                shippers,
                bols,
                is_pick_up: isPickUp,
                freight_info: [freight],
            },
            is_virtual: true,
        };
    }

    return {
        pod_signed_by: podSignedBy,
        pick_up_info_to: {
            shippers,
            is_pick_up: isPickUp,
            freight_info: [],
        },
        is_virtual: true,
    };
}

function addVirtualStopToTravelOrder(
    travelOrder: TravelOrder,
    stopIndex: number,
    virtualStop: VirtualStopFormData,
): TravelOrder {
    const steps = getTravelOrderSteps(travelOrder);
    if (!steps) {
        return travelOrder;
    }
    const [deadhead, ...stops] = steps;
    const modifiedStops = insertAtIndex(stops, stopIndex, formVirtualTravelOrderStop(virtualStop)).map(
        (stop, index) => {
            const stepNumber = index + 1;
            const name = formStopName(stop, stepNumber);

            return {
                ...stop,
                name,
                step_number: stepNumber,
            };
        },
    );

    return {
        ...travelOrder,
        dispatch: {
            ...(travelOrder.dispatch as Dispatch),
            steps: [deadhead, ...modifiedStops],
        },
    };
}

export function addVirtualStopToInvoice(invoice: Invoice, stopIndex: number, virtualStop: VirtualStopFormData) {
    const {
        load: {pick_up_info: pickupInfo},
    } = invoice;
    const travelOrder = getInvoiceTravelOrder(invoice);
    const modifiedPickupInfo = addVirtualStopToLoadPickupInfo(pickupInfo, stopIndex, virtualStop);
    const modifiedTravelOrder = addVirtualStopToTravelOrder(travelOrder, stopIndex, virtualStop);

    return {
        ...invoice,
        load: {
            ...invoice.load,
            pick_up_info: modifiedPickupInfo,
            travel_order: [modifiedTravelOrder],
        },
    };
}

function removeVirtualStopFromLoadPickupInfo(pickupInfo: PickUpInfo[], stopIndex: number): PickUpInfo[] {
    return deleteAtIndex(pickupInfo, stopIndex).map((stop, index) => {
        const stepNumber = index + 1;

        return {
            ...stop,
            step_number: stepNumber,
        };
    });
}

function removeVirtualStopFromTravelOrder(travelOrder: TravelOrder, stopIndex: number): TravelOrder {
    const steps = getTravelOrderSteps(travelOrder);
    if (!steps) {
        return travelOrder;
    }
    const [deadhead, ...stops] = steps;
    const modifiedStops = deleteAtIndex(stops, stopIndex).map((stop, index) => {
        const stepNumber = index + 1;
        const name = formStopName(stop, stepNumber);

        return {
            ...stop,
            name,
            step_number: stepNumber,
        };
    });

    return {
        ...travelOrder,
        dispatch: {
            ...(travelOrder.dispatch as Dispatch),
            steps: [deadhead, ...modifiedStops],
        },
    };
}

export function removeVirtualStopFromInvoice(invoice, stopIndex: number) {
    const load = getInvoiceLoad(invoice);
    const {pick_up_info: pickupInfo} = load;
    const travelOrder = getInvoiceTravelOrder(invoice);
    const modifiedPickupInfo = removeVirtualStopFromLoadPickupInfo(pickupInfo, stopIndex);
    const modifiedTravelOrder = removeVirtualStopFromTravelOrder(travelOrder, stopIndex);

    return {
        ...invoice,
        load: {
            ...invoice.load,
            pick_up_info: modifiedPickupInfo,
            travel_order: [modifiedTravelOrder],
        },
    };
}
