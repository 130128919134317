import {useDispatch} from 'react-redux';

import * as wsActions from 'widgets/Chat/redux/actions/wsActions';
import * as actions from 'widgets/Chat/redux/actions';

const useChatActions = () => {
    const dispatch = useDispatch();

    return {
        websocketConnectionReceived: ({webSocket}) => dispatch(wsActions.websocketConnectionReceived({webSocket})),
        sendMessageToChannel: (newMessage) => dispatch(actions.sendMessageToChannel(newMessage)),
        enterToDriverChannel: (driverID) => dispatch(actions.enterToDriverChannel(driverID)),
        driverSearch: (params) => dispatch(actions.driverSearch(params)),
        leaveDriverChannel: () => dispatch(actions.leaveDriverChannel()),
        chatAuthenticate: () => dispatch(wsActions.chatAuthenticate()),
        chatWSConnect: () => dispatch(wsActions.chatWSConnect()),
        init: () => dispatch(actions.init()),
    };
};

export default useChatActions;
