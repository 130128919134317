import {toast} from 'react-toastify';

import {getCurrentDispatcher} from 'store/reducers/userData/selectors';

import notifications from 'components/ui/notifications';

export const showSuccessfulMergedCustomersNotification = (msgData) => (_, getState) => {
    const {dispatcherId, sourceId, destinationId} = msgData || {};

    const dispatcher = getCurrentDispatcher(getState());

    if (dispatcher?.id === dispatcherId && sourceId && destinationId) {
        toast.success(notifications.customersSuccessfulMerged, {data: msgData});
    }
};

export const showUnsuccessfulMergedCustomersNotification = (msgData) => (_, getState) => {
    const {dispatcherId, sourceId, destinationId} = msgData || {};

    const dispatcher = getCurrentDispatcher(getState());

    if (dispatcher?.id === dispatcherId && sourceId && destinationId) {
        toast.error(notifications.customersUnsuccessfulMerged, {data: msgData});
    }
};
