import React from 'react';

import {Agent} from 'core/entities/Agent';

import Button from 'components/ui/Buttons/Button';
import SuccessModalWrapper from 'components/ui/modals/SuccessModalWrapper';

export type SuccessAgentModalActions = 'create' | 'load create' | 'update' | 'archive' | 'restore';

export type SuccessAgentModalButtonsActions = 'view list' | 'view archive' | 'view profile' | 'ok' | '';

export type SuccessAgentModalData = {
    agentName: Agent['agentName'];
    action: SuccessAgentModalActions;
};

type OwnProps = {
    data: SuccessAgentModalData;
    handlers: {
        onViewList: (params: {action: SuccessAgentModalButtonsActions; onClose: () => void}) => void;
    };
    onClose: () => void;
};

type SettingsByAction = {
    title: string;
    btn: SuccessAgentModalButtonsActions;
    successMessage: React.ReactNode;
};

const getSettingsByAction = (action: SuccessAgentModalActions, agentName): SettingsByAction => {
    if (action === 'load create') {
        return {
            title: 'Successful Create',
            btn: 'ok',
            successMessage: (
                <>
                    <strong>{agentName}</strong> agent’s profile has been created successfully.
                </>
            ),
        };
    }
    if (action === 'create') {
        return {
            title: 'Successful Create',
            btn: 'view list',
            successMessage: (
                <>
                    <strong>{agentName}</strong> agent’s profile has been created successfully.
                </>
            ),
        };
    }
    if (action === 'update') {
        return {
            title: 'Successful Update',
            btn: 'view list',
            successMessage: (
                <>
                    <strong>{agentName}</strong> agent’s profile has been updated successfully.
                </>
            ),
        };
    }
    if (action === 'archive') {
        return {
            title: 'Successful moved to Archive',
            btn: 'view archive',
            successMessage: (
                <>
                    <strong>{agentName}</strong> agent’s profile has been moved to archive.
                </>
            ),
        };
    }
    if (action === 'restore') {
        return {
            title: 'Successful restored',
            btn: 'view list',
            successMessage: (
                <>
                    <strong>{agentName}</strong> agent’s profile has been restored successfully.
                </>
            ),
        };
    }

    return {
        title: '',
        btn: '',
        successMessage: '',
    };
};

const SuccessAgentModal: React.FC<OwnProps> = (props) => {
    const {
        data: {agentName, action},
        handlers: {onViewList},
        onClose,
    } = props;

    const {title, btn, successMessage} = getSettingsByAction(action, agentName);

    const handleBtnClick = () => {
        onViewList({action: btn, onClose});
    };

    const buttons = (
        <div className="d-flex flex-fill justify-content-end">
            <Button onClick={handleBtnClick} buttonSize="medium" colorTheme="grey" className="mr10">
                {btn}
            </Button>
        </div>
    );

    return (
        <SuccessModalWrapper title={title} buttons={buttons}>
            <p>{successMessage}</p>
        </SuccessModalWrapper>
    );
};

export default SuccessAgentModal;
