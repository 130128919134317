import {getTravelOrderStopByID} from 'core/entities/TravelOrder/modules/travelOrderStops';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';
import Load from 'core/entities/Load/types';

export const getTravelOrderStopLoadByID = (params: StopInfoParams): Load | undefined => {
    const {stopID, travelOrder} = params;

    const toStop = getTravelOrderStopByID({stopID, travelOrder});
    const foundLoad = travelOrder?.loads?.find((load) => load.number === toStop?.loadStop?.loadNumber);

    return foundLoad;
};
