import {useEffect} from 'react';
import isEmpty from 'lodash/isEmpty';

import Truck from 'core/entities/Truck/types';
import useGetReduxFormValues from 'hooks/reduxForm/useGetReduxFormValues';

import {QuoteInitialValues} from 'pages/LoadBoard/types';
import {getQuoteReceivers} from 'pages/LoadBoard/mappers/mapExternalParamsToQuoteInitial';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';

import getFormFieldNames from '../../../utils/getFormFieldNames';

type OwnProps = {
    formName: string;
    fetchAllTrucks: () => Promise<Truck[]>;
};

const useFetchTrucks = (props: OwnProps): void => {
    const {formName, fetchAllTrucks} = props;

    const {getReceiversListFiledName} = getFormFieldNames();
    const formValues = useGetReduxFormValues(formName) as QuoteInitialValues;
    const changeFormValue = useChangeReduxFormValue(formName);

    const {receivers} = formValues;

    useEffect(() => {
        if (isEmpty(receivers)) {
            fetchAllTrucks().then((items) => changeFormValue(getReceiversListFiledName(), getQuoteReceivers(items)));
        }
    }, [receivers]);
};

export default useFetchTrucks;
