import React, {useState, useEffect} from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button';

import styles from './emailNote.module.scss';

const EmailNote = (props) => {
    const {
        title,
        input: {onChange, value},
        isEmailSent,
    } = props;
    const [text, setText] = useState(value);
    const [showPopover, setShowPopover] = useState(true);
    const isTextChanged = value !== text;
    const handleOnchange = () => {
        if (!text) {
            onChange(null);
            return;
        }
        onChange(text);
        setShowPopover(false);
    };
    const addNoteButton = (
        <div className="right-block">
            <Button disabled={isEmailSent} buttonIcon="pencil" buttonSize="icon" className="btn-link" />
        </div>
    );
    useEffect(() => {
        if (!showPopover) {
            setShowPopover(true);
        }
    }, [showPopover]);
    useEffect(() => {
        if (value !== text) {
            setText(value);
        }
    }, [value]);
    const popoverNote = (
        <Popover id="add-note" className="popover__add-note">
            <Popover.Title as="h3">{title}:</Popover.Title>
            <Popover.Content>
                <div className="standard-form-modal">
                    <div className="modal-body__main">
                        <textarea
                            className="form-control"
                            value={text}
                            onChange={(event) => setText(event.target.value)}
                            placeholder="type a note"
                            maxLength="140"
                            rows={4}
                        />

                        <div className="add-note-descr">
                            <span>The note will be send to the selected emails</span>
                            <span>{text.length}/140</span>
                        </div>
                    </div>
                    <div className="modal-body__bottom">
                        <div>
                            <Button
                                disabled={!isTextChanged}
                                onClick={handleOnchange}
                                colorTheme="green"
                                buttonSize="small"
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    );
    return (
        <div className={styles.item}>
            <div className="title">
                <strong>{title}</strong>
            </div>
            <div className="data">
                {value ? (
                    <div className="note-text ellips-text">{value}</div>
                ) : (
                    <div>
                        <em>add note</em>
                    </div>
                )}
                {isEmailSent ? (
                    addNoteButton
                ) : (
                    <>
                        {showPopover && (
                            <OverlayTrigger rootClose={true} trigger="click" overlay={popoverNote} placement="left">
                                {addNoteButton}
                            </OverlayTrigger>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default React.memo(EmailNote);
