import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

import {CHECK_CALL_TABS} from '../../constants';

type OwnProps = {
    currentStep: string;
    steps: typeof CHECK_CALL_TABS;
};

const FormSteps: React.FC<OwnProps> = ({currentStep, steps}) => {
    const currentStepIndex = steps.indexOf(currentStep);
    const tabSteps = steps.map((step, index) => {
        const isDisabled = index > currentStepIndex;
        const isCompleted = index < currentStepIndex;

        return (
            <div
                key={index}
                className={classNames(
                    styles.step,
                    isDisabled && styles.step__disabled,
                    isCompleted && styles.step__completed,
                )}
            >
                {step}
            </div>
        );
    });

    return <div className={styles.steps}>{tabSteps}</div>;
};

export default FormSteps;
