import isArray from 'lodash/isArray';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';

import {convertDate} from 'utils/dateTime';
import {TIME_ZONES} from 'utils/data/timeZones';
import {CurrencyValue} from 'utils/data/currency';

import {isSettlementStatusRated, isSettlementStatusReadyToPost, isSettlementStatusPosted} from './settlementStatus';
import {SettlementOwner, PayPeriod, PayRecord, PayCorrection} from './types';
import {periodTypes} from './constants';

type PayPeriodCurrencyPayment = {
    amount: number;
    currency: CurrencyValue;
    reimbursements: {
        totalAmount: number;
        items: PayCorrection[];
    };
    deductions: {
        totalAmount: number;
        items: PayCorrection[];
    };
};

export const getPayPeriodDateEnd = (payPeriod: PayPeriod): string | undefined => {
    const convertedDateTo = convertDate(payPeriod.date_to, {
        timeZoneFrom: 'utc',
        timeZoneTo: TIME_ZONES['America/New_York'].name,
    });
    return payPeriod.date_to ? convertedDateTo.formattedDate : undefined;
};

export const isPayPeriodOpened = (payPeriod: PayPeriod): boolean => {
    return payPeriod && payPeriod.is_opened;
};

export const isPayPeriodClosed = (payPeriod: PayPeriod): boolean => {
    return payPeriod && !payPeriod.is_opened;
};

export const getSettlementPayPeriod = (settlement: SettlementOwner): PayPeriod | undefined => {
    const {pay_period: payPeriod} = settlement;
    return payPeriod || undefined;
};

export const findPayPeriodPayRecordByOwner = (period: PayPeriod, owner: any): PayRecord | undefined => {
    if (!period || !period.pay_records || !owner) {
        return undefined;
    }
    return period.pay_records.find((payRecord) => {
        const ownerFromPayRecord = payRecord.owner;
        return ownerFromPayRecord && ownerFromPayRecord.id === owner.owner_id;
    });
};

export const findPayPeriodSettlements = (
    period: PayPeriod,
    settlementsIds: Array<number>,
): SettlementOwner[] | undefined => {
    if (!period || !isArray(period.pay_records) || !isArray(settlementsIds)) {
        return undefined;
    }
    const settlementsIdsSet = new Set(settlementsIds);
    return period.pay_records
        .flatMap((payRecord) => (payRecord && payRecord.settlements ? payRecord.settlements : []))
        .filter((s) => s && settlementsIdsSet.has(s.id));
};

export const getPayPeriodAllSettlements = (period: PayPeriod): SettlementOwner[] | undefined => {
    if (!period || !isArray(period.pay_records)) {
        return undefined;
    }
    return period.pay_records.flatMap((payRecord) => (payRecord && payRecord.settlements ? payRecord.settlements : []));
};

// REST API returns pay periods with settlements without link between settlement => payPeriod in order to avoid recursive data
// setPayPeriod param using for case when we want to set this relation manual
export const getPayPeriodPostedSettlements = (period: PayPeriod, {setPayPeriod}): SettlementOwner[] | undefined => {
    if (!period || !isArray(period.pay_records)) {
        return undefined;
    }
    const payPeriodPostedSettlements = period.pay_records
        .flatMap((payRecord) => (payRecord && payRecord.settlements ? payRecord.settlements : []))
        .filter(isSettlementStatusPosted);
    if (setPayPeriod) {
        return payPeriodPostedSettlements.map((s) => ({...s, pay_period: period}));
    }
    return payPeriodPostedSettlements;
};

export const getPayPeriodNotPostedSettlements = (payPeriod: PayPeriod): SettlementOwner[] => {
    return payPeriod.pay_records.reduce((result: SettlementOwner[], payRecord) => {
        const payRecordSettlements = (payRecord && payRecord.settlements) || [];
        const notPostedSettlementsFromPayRecord = payRecordSettlements.filter((s) => !isSettlementStatusPosted(s));
        return [...result, ...notPostedSettlementsFromPayRecord];
    }, []);
};

export const isSettlementFuturePayPeriod = (settlement: SettlementOwner): boolean => {
    return settlement.no_period_reason === periodTypes.FPP;
};

export const isSettlementPastDuePayPeriod = (settlement: SettlementOwner): boolean => {
    return settlement.no_period_reason === periodTypes.PAST_DUE;
};

export const isSettlementPayPeriodOpened = (settlement: SettlementOwner): boolean => {
    const {pay_period: payPeriod} = settlement;
    return payPeriod ? isPayPeriodOpened(payPeriod) : false;
};

export const isSettlementPayPeriodCanBeChanged = (settlement: SettlementOwner): boolean => {
    return isSettlementStatusRated(settlement) || isSettlementStatusReadyToPost(settlement);
};

export const getPayPeriodCurrencyPayments = (payPeriod: PayPeriod): PayPeriodCurrencyPayment[] => {
    const {total_payments: totalPayments = [], pay_corrections: payCorrections} = payPeriod;
    const currencyField: keyof PayCorrection = 'currency';
    const payCorrectionsByCurrency = groupBy(payCorrections, currencyField);
    return totalPayments.map((payment) => {
        const currentPayCorrections =
            (payCorrectionsByCurrency[payment.currency] as PayCorrection[]) || ([] as PayCorrection[]);
        const currentReimbursements = currentPayCorrections.filter(
            (payCorrection) => payCorrection.type === 'reimbursement',
        );
        const currentDeductions = currentPayCorrections.filter((payCorrection) => payCorrection.type === 'deduction');
        const reimbursementsTotalAmount = sumBy(currentReimbursements, (r) => r.amount);
        const deductionsTotalAmount = sumBy(currentDeductions, (d) => d.amount);
        return {
            ...payment,
            reimbursements: {items: currentReimbursements, totalAmount: reimbursementsTotalAmount},
            deductions: {items: currentDeductions, totalAmount: deductionsTotalAmount},
        };
    });
};
