import {InferActions} from 'store';
import {addItem} from 'utils/normalizer';

import * as actionCreators from '../actionCreators';
import * as actionTypes from '../actionTypes';
import {NotificationsState} from './types';

type WebsocketAuctionWsActions = Pick<
    typeof actionCreators,
    'receivedNewLoadBoardNotificationWS' | 'removeNewLoadBoardNotificationWS'
>;

type ActionsTypes = InferActions<WebsocketAuctionWsActions>;

export default function websocketsReducer(state: NotificationsState, action: ActionsTypes): NotificationsState {
    switch (action.type) {
        case actionTypes.RECEIVED_NEW_LOAD_BOARD_NOTIFICATION_WS: {
            return {
                ...state,
                items: addItem(state.items, action.payload.notification, {insertToBegin: true}),
                counters: {unread: state.counters.unread + 1},
                newNotification: action.payload.notification,
            };
        }

        case actionTypes.REMOVE_NEW_LOAD_BOARD_NOTIFICATION_WS: {
            return {
                ...state,
                newNotification: undefined,
            };
        }

        default: {
            return state;
        }
    }
}
