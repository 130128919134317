import React from 'react';
import classNames from 'classnames';

import type {CurrencyValue} from 'utils/data/currency';
import currencyData from 'utils/data/currency';

interface OwnProps {
    currency?: CurrencyValue;
    withStrong?: boolean;
    amount?: string | number;
    isActive?: boolean;
}

const CurrencyAmount: React.FC<OwnProps> = (props) => {
    const {currency, amount, withStrong = true, isActive = true} = props;
    const currencyKey = currency ? currency.toUpperCase() : 'USD';
    const currencySettings = currencyData[currencyKey];
    const value = currencySettings ? (
        <span>
            {currencySettings.mark} <span className={classNames({inactive: !isActive})}>{amount}</span> {currencyKey}
        </span>
    ) : null;

    if (withStrong) {
        return <strong>{value}</strong>;
    }
    return value;
};

export default CurrencyAmount;
