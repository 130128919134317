import React, {useState} from 'react';
import classNames from 'classnames';
import {Field} from 'redux-form';

import UnderlineButton from 'components/ui/Buttons/UnderlineButton';
import TextArea from 'components/ui/Form/TextArea';

import {getName} from 'components/common/LocationEvents/modals/CheckCallModal/utils';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import styles from './styles.module.scss';

const NoteInfo: React.FC<{form: string}> = ({form}) => {
    const noteValues = useGetReduxFormValue(form, getName('note'));
    const [isDisplayAddNoteField, setIsDisplayAddNoteField] = useState(Boolean(noteValues));

    const underlineButtonText = !isDisplayAddNoteField ? 'add note' : 'delete note';

    return (
        <div className={styles.wrap}>
            <div className={classNames({[styles.button]: isDisplayAddNoteField})}>
                <UnderlineButton
                    text={underlineButtonText}
                    isNeedButtonIcon={!isDisplayAddNoteField}
                    onClick={() => setIsDisplayAddNoteField(!isDisplayAddNoteField)}
                />
            </div>

            {isDisplayAddNoteField ? (
                <Field
                    name={getName('note')}
                    type="text"
                    maxLength="500"
                    component={TextArea}
                    rows="2"
                    label="Note:"
                    placeholder="enter a note here"
                />
            ) : null}
        </div>
    );
};

export default NoteInfo;
