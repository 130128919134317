import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';

import * as appActions from 'store/actions';

import {
    addSettlementPayPeriodEmailNote,
    closeSettlementPayPeriod,
    createSettlementPayPeriod,
    fetchAllPayPeriods as fetchAllExistsPayPeriods,
    fetchPayPeriods,
    reopenSettlementPayPeriod,
    sendSettlementPayPeriodEmails,
    addPayCorrection as addPayCorrectionRequest,
    deletePayCorrection as deletePayCorrectionRequest,
    fetchPayRecord,
} from 'core/entities/Settlement/requests/settlementOwnerRequests';

import formatPaginationParams from 'utils/formatPaginationParams';
import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import {convertToUTCTime} from 'pages/Settlements/utils';
import * as types from 'pages/Settlements/actionTypes';

import {handleChangeSettlementPayPeriodError} from './errorHandlers';
import {getFormattedPayRecords} from './utils/formatSettlementOwnerData';
import {expandAndHighlightRow} from './listActions';

export const reFetchPayRecord = (payRecordID) => {
    return fetchPayRecord(payRecordID)
        .then((response) => head(response.data))
        .catch((error) => console.error('Error on re fetch pay record: ', error));
};

export function createPayPeriod(data) {
    return function (dispatch) {
        const {dateFrom, dateTo, timeZone} = data;

        if (!dateFrom || !dateTo || !timeZone) {
            console.warn('Incorrect data on create new pay period!');
            return;
        }

        const convertedDateFrom = convertToUTCTime(dateFrom, {timeZoneFrom: timeZone});
        const convertedDateTo = convertToUTCTime(dateTo, {timeZoneFrom: timeZone});

        dispatch(appActions.showLoader());

        createSettlementPayPeriod({date_from: convertedDateFrom, date_to: convertedDateTo})
            .then(({data: createdPayPeriod}) => {
                dispatch({
                    type: types.PAY_PERIOD_CREATED,
                    payload: createdPayPeriod,
                });
            })
            .catch((error) => handleChangeSettlementPayPeriodError(error, dispatch))
            .catch((error) => console.warn('Error on create pay period: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function updatePayPeriod(payPeriod, updatedData) {
    return async function (dispatch) {
        const {note_to_email: currentEmailNote} = payPeriod;
        const {payRecords, emailNote: updatedEmailNote, emailSender} = updatedData;
        const isNeedSentPayRecordsEmails = !isEmpty(payRecords);
        const isNeedUpdatePayPeriodEmailNote = currentEmailNote !== updatedEmailNote;
        const isNeedOnlySendPayPeriodEmails = isNeedSentPayRecordsEmails && !isNeedUpdatePayPeriodEmailNote;
        const isNeedOnlyUpdateEmailNote = isNeedUpdatePayPeriodEmailNote && !isNeedSentPayRecordsEmails;
        const dispatchUpdatePayPeriod = (updatedPayPeriod) => {
            if (updatedPayPeriod) {
                dispatch({
                    type: types.PAY_PERIOD_UPDATED,
                    payload: updatedPayPeriod,
                });
            }
        };
        const addEmailNoteToPayPeriod = () => {
            return addSettlementPayPeriodEmailNote(payPeriod.id, updatedEmailNote)
                .then((response) => response.data)
                .catch((error) => console.warn('Error on add email note to pay period: ', error));
        };
        const sendPayPeriodEmails = () => {
            const payRecordsData = getFormattedPayRecords(Object.values(payRecords));
            const emailsData = {
                email_from: emailSender,
                ...payRecordsData,
            };
            return sendSettlementPayPeriodEmails(payPeriod.id, emailsData)
                .then((response) => response.data)
                .catch((error) => console.warn('Error on send pay period emails: ', error));
        };

        if (isNeedOnlyUpdateEmailNote) {
            dispatch(appActions.showLoader());
            addEmailNoteToPayPeriod()
                .then(dispatchUpdatePayPeriod)
                .finally(() => dispatch(appActions.hideLoader()));
        } else if (isNeedOnlySendPayPeriodEmails) {
            dispatch(appActions.showLoader());
            sendPayPeriodEmails()
                .then(dispatchUpdatePayPeriod)
                .finally(() => dispatch(appActions.hideLoader()));
        } else {
            dispatch(appActions.showLoader());
            await addEmailNoteToPayPeriod().then(dispatchUpdatePayPeriod);
            await sendPayPeriodEmails().then(dispatchUpdatePayPeriod);
            dispatch(appActions.hideLoader());
        }
    };
}

export function closePayPeriod(payPeriod) {
    return function (dispatch) {
        dispatch(appActions.showLoader());

        closeSettlementPayPeriod(payPeriod.id)
            .then(({data: closedPayPeriod}) => {
                dispatch({
                    type: types.PAY_PERIOD_UPDATED,
                    payload: closedPayPeriod,
                });
            })
            .catch((error) => handleChangeSettlementPayPeriodError(error, dispatch))
            .catch((error) => console.warn('Error on close pay period: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function reOpenPayPeriod(payPeriod) {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        reopenSettlementPayPeriod(payPeriod.id)
            .then(({data: openedPayPeriod}) => {
                dispatch({
                    type: types.PAY_PERIOD_UPDATED,
                    payload: openedPayPeriod,
                });
            })
            .catch((error) => handleChangeSettlementPayPeriodError(error, dispatch))
            .catch((error) => console.warn('Error on reopen pay period: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function fetchAllPayPeriods() {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        fetchAllExistsPayPeriods()
            .then(({data: payPeriods}) => {
                dispatch({
                    type: types.ALL_PAY_PERIODS_RECEIVED,
                    payload: payPeriods,
                });
            })
            .catch((error) => console.warn('Error fetch all pay periods: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function searchPayPeriods(params) {
    const {status: selectedStatus, searchParams, pagination, sortBy} = params;
    return function (dispatch) {
        dispatch(appActions.showLoader());
        fetchPayPeriods({status: selectedStatus, ...searchParams, sortBy, ...formatPaginationParams(pagination)})
            .then(({data: payPeriods, headers}) => {
                dispatch(expandAndHighlightRow(payPeriods));
                dispatch({
                    type: types.LIST_PAY_PERIODS_RECEIVED,
                    payload: {
                        payPeriods,
                        searchParams,
                        pagination: parsePaginationHeaders(headers),
                    },
                });
            })
            .catch((error) => console.warn('Error fetch list pay periods: ', error))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

function receivePayCorrection(payRecord) {
    return {
        type: types.PAY_CORRECTION_ADDED,
        payload: {payRecord},
    };
}

export function addPayCorrection(payRecord, payCorrectionFields) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        try {
            await addPayCorrectionRequest(payRecord.id, payCorrectionFields);
            const updatedPayRecord = await reFetchPayRecord(payRecord.id);
            dispatch(receivePayCorrection(updatedPayRecord));
        } catch (error) {
            dispatch(appActions.handleError(error));
        } finally {
            dispatch(appActions.hideLoader());
        }
    };
}

function payCorrectionRemoved(payRecord) {
    return {type: types.PAY_CORRECTION_REMOVED, payload: {payRecord}};
}

export function deletePayCorrection(payRecord, payCorrection) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        try {
            await deletePayCorrectionRequest(payCorrection.id);
            const updatedPayRecord = await reFetchPayRecord(payRecord.id);
            dispatch(payCorrectionRemoved(updatedPayRecord));
        } catch (error) {
            dispatch(appActions.handleError(error));
        } finally {
            dispatch(appActions.hideLoader());
        }
    };
}

export function clearPayPeriodsData() {
    return function (dispatch) {
        dispatch({
            type: types.PERIODS_DATA_CLEARED,
        });
    };
}

export function payPeriodPayRecordsReceived(payPeriodID, payRecords) {
    return function (dispatch) {
        dispatch({
            type: types.LIST_PAY_RECORDS_RECEIVED,
            payload: {payPeriodID, payRecords},
        });
    };
}

export function searchedPayRecordsReceived(payPeriodID, payRecords, searchParams) {
    return function (dispatch) {
        dispatch({
            type: types.LIST_SEARCHED_PAY_RECORDS_RECEIVED,
            payload: {payPeriodID, payRecords, searchParams},
        });
    };
}

export function payRecordSettlementsReceived(payRecordID, settlements) {
    return function (dispatch) {
        dispatch({
            type: types.LIST_PAY_RECORD_SETTLEMENTS_RECEIVED,
            payload: {payRecordID, settlements},
        });
    };
}

export function clearPayPeriodPayRecords(payPeriodID) {
    return function (dispatch) {
        dispatch({
            type: types.PAY_PERIOD_PAY_RECORDS_CLEARED,
            payload: {payPeriodID},
        });
    };
}
