import {FormErrors} from 'redux-form';
import Owner from 'core/entities/Owner/types';

interface FormData {
    owner: Owner;
}

function validate(values): FormErrors<FormData> {
    const {owner} = values;
    const errors: FormErrors<FormData> = {};

    if (!owner) {
        errors.owner = 'Please, choose owner from the list.';
    }

    return errors;
}

export default validate;
