import React, {useState} from 'react';

import Button from 'components/ui/Buttons/Button/index';
import Tooltip from 'components/ui/Tooltip';

import {getOwnerName} from 'core/entities/Owner/modules/ownerName';
import {PayRecord} from 'core/entities/Settlement/types';

import PayRecordTotalInfo from 'pages/Settlements/components/common/PayRecordTotalInfo';

import SettlementsList from './SettlementsList';
import styles from './payRecordItem.module.scss';

type OwnProps = {
    payRecord: PayRecord;
    isOpened?: boolean;
    children?: React.ReactNode;
};

const PayRecordItem: React.FC<OwnProps> = ({payRecord, isOpened = false, children}) => {
    const {owner, email} = payRecord;
    const emailList = email ? email.split(',').join(', ') : null;
    const ownerName = getOwnerName(owner);
    const ownerCompanyName = owner.document_company_name ? `(${owner.document_company_name})` : null;
    const [open, setOpen] = useState(isOpened);
    return (
        <div className={styles.item}>
            <div className="header">
                <div>
                    (ow) <strong>{ownerName}</strong> {ownerCompanyName}
                </div>
                <div>
                    <strong>{payRecord.settlements_total}</strong> settlement(s)
                    <Tooltip
                        tooltipContent={open ? 'hide details' : 'view details'}
                        typeContent="button"
                        position="right"
                    >
                        <Button
                            buttonIcon={open ? 'angle-up' : 'angle-down'}
                            buttonSize="icon"
                            className="btn-link"
                            onClick={() => setOpen(!open)}
                        />
                    </Tooltip>
                </div>
            </div>

            {open && <SettlementsList payRecord={payRecord} />}

            <PayRecordTotalInfo payRecord={payRecord} isExpanded={open}>
                {children || emailList}
            </PayRecordTotalInfo>
        </div>
    );
};

export default PayRecordItem;
