import {useSelector} from 'react-redux';

import {getCurrentRegion} from 'store/reducers/appSettings/selectors';

import {isEuropeRegion, isNorthAmericaRegion} from 'utils/data/region';

type UseCurrentRegion = {
    isNorthAmerica: boolean;
    isEurope: boolean;
};

const useCurrentRegion = (): UseCurrentRegion => {
    const currentRegion = useSelector(getCurrentRegion);

    const isNorthAmerica = isNorthAmericaRegion(currentRegion);
    const isEurope = isEuropeRegion(currentRegion);

    return {isEurope, isNorthAmerica};
};

export default useCurrentRegion;
