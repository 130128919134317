import React from 'react';

import Wrapper from '../common/Wrapper';
import FreightUnits from './components/FreightUnits';
import FreightPieces from './components/FreightPieces';
import FreightWeight from './components/FreightWeight';
import DimensionUnits from './components/DimensionUnits';
import DimensionList from './components/DimensionList';
import FreightIsStackable from './components/FreightIsStackable';

const LoadFreightInfo: React.FC = () => {
    return (
        <Wrapper>
            <div className="row">
                <div className="col-12 mb-3 mt-3">
                    <h3>Freight</h3>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <FreightUnits />
                        </div>
                        <div className="col-4 pr-0">
                            <FreightPieces />
                        </div>
                        <div className="col-3 d-flex align-items-center">
                            <FreightIsStackable />
                        </div>
                        <div className="col-5 pl-0">
                            <FreightWeight />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <DimensionUnits />
                        </div>
                        <div className="col-12">
                            <DimensionList />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default React.memo(LoadFreightInfo);
