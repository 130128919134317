import React from 'react';

import citizenshipValues, {citizenshipEU} from 'utils/data/citizenship';

import GeneralBadge from '../GeneralBadge';

const Citizenship = ({citizenship, ...rest}) => {
    let title = '';
    let tooltipText = '';

    if (citizenship === citizenshipValues.citizen) {
        title = 'CIT';
        tooltipText = citizenship;
    } else if (citizenship === citizenshipValues.resident) {
        title = 'RES';
        tooltipText = citizenship;
    } else if (citizenship === citizenshipValues.NL) {
        title = 'NL';
        tooltipText = citizenship;
    } else if (citizenship === citizenshipValues.other) {
        title = 'OTH';
        tooltipText = 'Other';
    } else if (citizenship === citizenshipEU.eu_citizen) {
        title = 'cit';
        tooltipText = 'EU citizen';
    } else if (citizenship === citizenshipEU.foreigner) {
        title = 'frg';
        tooltipText = 'foreigner';
    } else {
        return null;
    }

    return <GeneralBadge text={title} tooltip={tooltipText} tooltipPosition="right" {...rest} />;
};

export default Citizenship;
