import React from 'react';
import {Modal} from 'react-bootstrap';
import Button from 'components/ui/Buttons/Button';

const SuccessModal: React.FC<{onClear: () => void; onClose: () => void}> = (props) => {
    const {onClear, onClose} = props;

    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Clear All Fields</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-reset" />
                    </div>
                    <div className="col-11">
                        <p className="mb10">
                            The new entered information will be lost, and you have the information which were
                            automatically filled from the search
                        </p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} buttonSize="small" colorTheme="grey">
                    Keep Creating
                </Button>
                <Button onClick={onClear} buttonSize="small" colorTheme="blue">
                    Clear Fields
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SuccessModal;
