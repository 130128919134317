import React from 'react';
import {InjectedFormProps, reduxForm} from 'redux-form';

import withNavigationConfirm from 'hocs/withNavigationConfirm';

import {GetCheckCallEventDataFromRequest} from 'core/entities/LocationEvents/types';

import Button from 'components/ui/Buttons/Button';
import {AddressData} from 'components/ui/Form/InputWithPlacesAutocomplete';

import VehicleInfo from './components/VehicleInfo';
import LastKnowLocationInfo from './components/LastKnowLocationInfo';
import NoteInfo from './components/NoteInfo';
import LoadsSummaryInfo from './components/LoadsSummaryInfo';

import validate from './validation';

type OwnProps = {
    form: string;
    travelOrderNumber: number;
    checkCallData?: GetCheckCallEventDataFromRequest;
    copyLastKnowLocationFromTruck: () => void;
    updateCoordinatesFromAutocomplete: (address: AddressData) => void;
    onClose(): void;
};

const CreateForm: React.FC<OwnProps & InjectedFormProps<{}, OwnProps>> = (props) => {
    const {
        handleSubmit,
        onClose,
        form,
        travelOrderNumber,
        checkCallData,
        copyLastKnowLocationFromTruck,
        updateCoordinatesFromAutocomplete,
    } = props;

    if (!checkCallData) {
        return null;
    }

    const {mostRecentTruckLocation, summaryList} = checkCallData;

    return (
        <form onSubmit={handleSubmit}>
            <VehicleInfo travelOrderNumber={travelOrderNumber} />

            <LastKnowLocationInfo
                form={form}
                mostRecentTruckLocation={mostRecentTruckLocation}
                copyLastKnowLocationFromTruck={copyLastKnowLocationFromTruck}
                updateCoordinates={updateCoordinatesFromAutocomplete}
            />

            <NoteInfo form={form} />

            <LoadsSummaryInfo form={form} items={summaryList} />

            <div className="modal-body__bottom">
                <Button onClick={onClose}>Close</Button>

                <Button type="submit" colorTheme="blue">
                    Next Step
                </Button>
            </div>
        </form>
    );
};

const WrappedForm = withNavigationConfirm(CreateForm);

const ReduxFrom = reduxForm<{}, OwnProps>({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(WrappedForm);

export default ReduxFrom;
