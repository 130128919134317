import React, {ReactNode} from 'react';

import {Agent} from 'core/entities/Agent';

import WarningModalWrapper from 'components/ui/modals/WarningModalWrapper';
import Button from 'components/ui/Buttons/Button';

export type WarningAgentModalActions = 'archive' | 'restore' | '';

export type WarningAgentModalData = {
    agentName: Agent['agentName'];
    action: WarningAgentModalActions;
};

type OwnProps = {
    data: WarningAgentModalData;
    handlers: {
        onConfirm: () => void;
        onCancel: () => void;
    };
    onClose: () => void;
};

type SettingsByAction = {
    title: string;
    btn: WarningAgentModalActions;
    warningMessage: ReactNode;
};

const getSettingsByAction = (action: WarningAgentModalActions, agentName): SettingsByAction => {
    if (action === 'archive') {
        return {
            title: 'Move to archive',
            btn: 'archive',
            warningMessage: (
                <>
                    <p>
                        <strong>DO YOU WANT TO ARCHIVE THE AGENT?</strong>
                    </p>
                    <p>
                        The archived agents profile <strong>{agentName}</strong> will disable any actions with it.&nbsp;
                        If needed, you can still the ability to restore the profile.
                    </p>
                </>
            ),
        };
    }
    if (action === 'restore') {
        return {
            title: 'Restore Profile',
            btn: 'restore',
            warningMessage: (
                <>
                    <p>
                        <strong>DO YOU WANT TO ARCHIVE THE AGENT?</strong>
                    </p>
                    <p>
                        Restoring the agent profile <strong>{agentName}</strong> will activate all possible actions with
                        it.
                    </p>
                </>
            ),
        };
    }

    return {
        title: '',
        btn: '',
        warningMessage: '',
    };
};

const WarningAgentModal: React.FC<OwnProps> = (props) => {
    const {
        data: {agentName, action},
        handlers: {onConfirm, onCancel},
        onClose,
    } = props;

    const {title, btn, warningMessage} = getSettingsByAction(action, agentName);

    const handleConfirm = (): void => {
        onConfirm();
        onClose();
    };

    const handleCancel = (): void => {
        onCancel();
        onClose();
    };

    const buttons = (
        <>
            <Button onClick={handleCancel} className="mr10" colorTheme="grey" buttonSize="small" defaultFocus={true}>
                KEEP
            </Button>
            <Button onClick={handleConfirm} className="mr10" colorTheme="red" buttonSize="small" defaultFocus={true}>
                {btn}
            </Button>
        </>
    );

    return (
        <WarningModalWrapper title={title} onClose={onClose} buttons={buttons}>
            {warningMessage}
        </WarningModalWrapper>
    );
};

export default WarningAgentModal;
