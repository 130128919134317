import React from 'react';
import Note from './components/Note';
import Quote from './components/Quote';
import Wrapper from '../common/Wrapper';
import getFormFieldNames from '../../utils/getFormFieldNames';

const NotesInfo: React.FC = () => {
    const {getNoteForDriversFieldName, getNoteForDispatchersFieldName} = getFormFieldNames();
    return (
        <Wrapper>
            <div className="row pt-2">
                <div className="col-6">
                    <Note name={getNoteForDispatchersFieldName()} label="GENERAL NOTE" />
                </div>
                <div className="col-6">
                    <Note name={getNoteForDriversFieldName()} label="NOTE FOR DRIVERS" />
                </div>
                <div className="col-12">
                    <Quote />
                </div>
            </div>
        </Wrapper>
    );
};

export default React.memo(NotesInfo);
