import React from 'react';

const TruckType: React.FC<{type: string[]}> = ({type}) => {
    const truckTypes = type.map((t, index) => {
        const isLastItem = index === type.length - 1;
        return isLastItem ? <strong key={t}>{t}</strong> : <strong key={t}>{t}, </strong>;
    });
    return <div>{truckTypes}</div>;
};

export default TruckType;
