import React from 'react';
import {FormGroup, FormControl, FormLabel} from 'react-bootstrap';
import classNames from 'classnames';

import getValidationState from './getValidationState';
import OverlayTriggerCustom from './OverlayTriggerCustom';

const TextArea = ({input, meta, errorTooltipPlacement, formGroup = true, ...rest}) => {
    const isValid = getValidationState(meta);

    if (formGroup === false) {
        return <FormControl {...input} onChange={input.onChange} as="textarea" {...rest} />;
    }

    return (
        <FormGroup controlId="formControlsTextarea" className={classNames({'has-feedback has-error': !isValid})}>
            {rest.label && <FormLabel>{rest.label}</FormLabel>}
            <OverlayTriggerCustom inputMeta={meta} placement={errorTooltipPlacement}>
                <FormControl {...input} onChange={input.onChange} as="textarea" {...rest} />
            </OverlayTriggerCustom>
        </FormGroup>
    );
};

export default TextArea;
