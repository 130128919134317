import {convertDate} from 'utils/dateTime';
import {LoadStop} from 'core/entities/Quote/types';

import {QuoteInitialValues} from 'pages/LoadBoard/types';

const convertDateToUtcTime = (date: string | null | undefined, timeZoneFrom: string): string => {
    if (!date) {
        return '';
    }
    const convertedDate = convertDate(date, {timeZoneFrom, timeZoneTo: 'utc'});
    return new Date(`${convertedDate.defaultDateTime} UTC`).toISOString();
};

const mapLoadStopData = (loadStop: LoadStop): LoadStop => {
    return {
        ...loadStop,
        dateFrom: loadStop.dateFrom ? convertDateToUtcTime(loadStop.dateFrom, loadStop.timeZone) : undefined,
        dateTo: loadStop.dateTo ? convertDateToUtcTime(loadStop.dateTo, loadStop.timeZone) : undefined,
    };
};

export const getLoadOrigin = (formQuote: QuoteInitialValues): LoadStop => {
    return mapLoadStopData(formQuote.load.stops[0] as LoadStop);
};

export const getLoadDestination = (formQuote: QuoteInitialValues): LoadStop => {
    const lastIndex = formQuote.load.stops.length - 1;
    return mapLoadStopData(formQuote.load.stops[lastIndex] as LoadStop);
};

export const getLoadStops = (formQuote: QuoteInitialValues): LoadStop[] => {
    return formQuote.load.stops.map((stop) => mapLoadStopData(stop as LoadStop));
};
