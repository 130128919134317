import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button/index';

import styles from './generationFailed.module.scss';

type OwnProps = {
    onClose: () => void;
    data: {errorFiles: string[]};
};

const GenerationFailedModal: React.FC<OwnProps> = (props) => {
    const {
        onClose,
        data: {errorFiles},
    } = props;

    if (!errorFiles || errorFiles.length === 0) {
        return null;
    }

    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Generation Failed</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row align-items-center">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-error" />
                    </div>
                    <div className="col-11">
                        <p>The Invoice can’t be generated with the following file(s):</p>
                    </div>
                </div>
                <div className={styles.list}>
                    {errorFiles.map((fileName) => {
                        return (
                            <div key={fileName} className="file-item">
                                <div className="file-item__title">
                                    <span>{fileName}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div />
                <Button onClick={onClose} buttonSize="small" colorTheme="red">
                    ok
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default GenerationFailedModal;
