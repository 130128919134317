import React, {ReactNode, useState, useCallback} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {showDeleteFileWarning} from 'store/actions/modalActions';

import formatFileSize from 'utils/files/formatFileSize';
import useRefWithOutsideClickHandler from 'hooks/useRefWithOutsideClickHandler';
import Button from 'components/ui/Buttons/Button';
import ButtonLink from 'components/ui/Buttons/ButtonLink';
import Tooltip from 'components/ui/Tooltip';

import {ifFileHasPdfExtension} from '../../utils';
import {NormalizedFile, NormalizedFileWithoutExtraData} from '../../types';
import FilePreviewImage from './FilePreviewImage';
import styles from './file.module.scss';

interface OwnProps {
    file: NormalizedFile | NormalizedFileWithoutExtraData;
    children?: ReactNode;
    grid?: boolean;
    viewButton?: boolean;
    viewInGalleryButton?: boolean;
    linkInNewTab?: boolean;
    warnOnRemove?: boolean;
    disableRemove?: boolean;
    handleRemove?(): void;
    viewInGallery?(): void;
}

interface DispatchProps {
    showWarning(callback: () => void): void;
}

const FilePreview = (props: OwnProps & DispatchProps): JSX.Element => {
    const {
        file,
        children,
        handleRemove,
        viewInGallery,
        viewInGalleryButton = true,
        grid = false,
        viewButton = false,
        linkInNewTab = false,
        warnOnRemove = true,
        disableRemove = false,
        showWarning,
    } = props;

    const [isDropDownOpened, setIsDropDownOpened] = useState(false);
    const containerNode = useRefWithOutsideClickHandler(isDropDownOpened, setIsDropDownOpened);
    const activeClassName = classNames({'active-state': isDropDownOpened});
    const remove = useCallback(
        function (): void {
            if (!handleRemove) {
                return;
            }
            if (warnOnRemove) {
                return showWarning(handleRemove);
            }
            handleRemove();
        },
        [handleRemove],
    );
    const isPdfFile = ifFileHasPdfExtension(file);

    return (
        <div ref={containerNode} className={styles.items}>
            <div className={`${file.isBlob ? 'file-item__new' : ''} ${activeClassName} file-item`}>
                <FilePreviewImage
                    gridView={grid}
                    file={file}
                    handleRemove={remove}
                    isFileRemovable={Boolean(handleRemove)}
                    viewInGallery={viewInGallery}
                    linkInNewTab={linkInNewTab}
                />
                <div className="file-name-tooltip" onClick={() => setIsDropDownOpened(true)}>
                    <Tooltip tooltipContent={`${file.name}`} display="block">
                        <div className="file-name">{file.name}</div>
                    </Tooltip>
                    {file.size && <div className="file-size">{formatFileSize(file.size)}</div>}
                </div>
                <div className="file-buttons">
                    {file.badge && <span className="file-badge">{file.badge}</span>}
                    {file.isBlob && <span className="new-file">new file</span>}
                    {!isPdfFile && viewInGallery && viewInGalleryButton && (
                        <Button
                            onClick={viewInGallery}
                            buttonIcon="eye"
                            buttonSize="icon"
                            className="file-buttons__view"
                        />
                    )}
                    {(isPdfFile || viewButton) && (
                        <ButtonLink
                            href={file.url}
                            target="_blank"
                            buttonIcon="eye"
                            buttonSize="icon"
                            className="file-buttons__view"
                        />
                    )}
                    {!file.isBlob && (
                        <a
                            href={file.url}
                            className="button button-with-icon button-grey button-icon file-buttons__download"
                            download={file.name}
                        >
                            <i className="fa fa-download" aria-hidden="true" />
                        </a>
                    )}
                    {handleRemove && (
                        <Button
                            buttonIcon="trash"
                            buttonSize="icon"
                            className="file-buttons__trash"
                            onClick={remove}
                            disabled={disableRemove}
                        />
                    )}
                    {children && (
                        <>
                            <span className="sep">
                                <span className="dot" />
                                <span className="dot" />
                                <span className="dot" />
                            </span>
                            {children}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {
        showWarning: (callback) => dispatch(showDeleteFileWarning(callback)),
    };
}

export default connect(undefined, mapDispatchToProps)(FilePreview);
