import {useDispatch} from 'react-redux';

import {CarriersSearchFormValues, TrucksSearchFormValues} from 'pages/Loads/types/formTypes';
import {attachActionCreators} from 'pages/Loads/redux/actions/attach';
import * as attachActions from 'pages/Loads/redux/actions/attach';

type UseAttachActions = {
    setSearchCarriersParams: (params: {formData: Partial<CarriersSearchFormValues>}) => void;
    searchTrucks: (params: {formData: Partial<TrucksSearchFormValues>}) => void;
    changeTab: (params: {tab: string}) => void;
    resetSearchCarriersParams: () => void;
    getMoreTrucksForAttaching: () => void;
    fetchCarriers: () => void;
    resetTrucks: () => void;
    clearState: () => void;
};

const useAttachActions = (): UseAttachActions => {
    const dispatch = useDispatch();

    return {
        setSearchCarriersParams: (params) => dispatch(attachActions.setSearchCarriersParams(params)),
        resetSearchCarriersParams: () => dispatch(attachActions.resetSearchCarriersParams()),
        getMoreTrucksForAttaching: () => dispatch(attachActions.getMoreTrucksForAttaching()),
        changeTab: (params) => dispatch(attachActionCreators.changeTab(params)),
        searchTrucks: (params) => dispatch(attachActions.searchTrucks(params)),
        clearState: () => dispatch(attachActionCreators.clearState()),
        fetchCarriers: () => dispatch(attachActions.fetchCarriers()),
        resetTrucks: () => dispatch(attachActions.resetTrucks()),
    };
};

export default useAttachActions;
