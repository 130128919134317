interface GoogleApiConfig {
    GOOGLE_MAPS_API_KEY: string;
    GOOGLE_PLATFORM_ACCOUNT_EMAIL: string;
}

interface AppConfig extends GoogleApiConfig {
    REACT_APP_CLUSTER: string;
    REACT_APP_PREFIX: string;
    ENVIRONMENT_TYPE: any;
    isTestMode: boolean;
    PUBLIC_URL: string;
    NODE_ENV: string;
    BUILD_HASH: any;
}

const developmentGoogleConfig: GoogleApiConfig = {
    GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyCUadimaiSC-U4MYdugPZmBoLXgxuWAoCU',
    GOOGLE_PLATFORM_ACCOUNT_EMAIL: process.env.REACT_APP_GOOGLE_PLATFORM_ACCOUNT_EMAIL || 'mapradtest@gmail.com',
};

const productionGoogleConfig: GoogleApiConfig = {
    // eslint-disable-next-line
    GOOGLE_MAPS_API_KEY: window?.__BUILD_CONFIG__?.REACT_APP_GOOGLE_MAPS_API_KEY || process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    // eslint-disable-next-line
    GOOGLE_PLATFORM_ACCOUNT_EMAIL: window?.__BUILD_CONFIG__?.REACT_APP_GOOGLE_PLATFORM_ACCOUNT_EMAIL || process.env.REACT_APP_GOOGLE_PLATFORM_ACCOUNT_EMAIL,
};

const googleConfig = process.env.NODE_ENV === 'development' ? developmentGoogleConfig : productionGoogleConfig;

const appConfig: AppConfig = {
    ...googleConfig,
    BUILD_HASH:
        process.env.NODE_ENV === 'development'
            ? '123456789'
            : window?.__BUILD_CONFIG__?.REACT_APP_BUILD_ID || process.env.REACT_APP_BUILD_ID,
    ENVIRONMENT_TYPE: window?.__BUILD_CONFIG__?.REACT_APP_ENV || process.env.REACT_APP_ENV,

    REACT_APP_PREFIX: window?.__BUILD_CONFIG__?.REACT_APP_PREFIX || process.env.REACT_APP_PREFIX || 'local',
    REACT_APP_CLUSTER: window?.__BUILD_CONFIG__?.REACT_APP_CLUSTER || process.env.REACT_APP_CLUSTER || 'local',

    PUBLIC_URL: window?.__BUILD_CONFIG__?.PUBLIC_URL || process.env.PUBLIC_URL,
    NODE_ENV: window?.__BUILD_CONFIG__?.NODE_ENV || process.env.NODE_ENV,
    isTestMode: process.env.NODE_ENV === 'test',
};

export default appConfig;
