import Carrier from 'core/entities/Carrier/types';

export const checkIsCarrierApproved = (carrier?: Carrier | null): boolean => Boolean(carrier?.relation_status);

export const checkIsCarrierGlobal = (carrier?: Carrier | null): boolean => Boolean(carrier?.is_global);

export const getCarrierName = (carrier?: Carrier | null): string => carrier?.company_name || '';

export const getCarrierMotorNumber = (carrier?: Carrier | null) => carrier?.mc_number;

export const getCarrierRegNumber = (carrier?: Carrier | null) => carrier?.reg_number;

export const getCarrierPhoneNumber = (carrier?: Carrier | null) => carrier?.phone;

export const getCarrierEmail = (carrier?: Carrier | null) => carrier?.email;
