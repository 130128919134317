import React from 'react';

import Button from 'components/ui/Buttons/Button/index';

import {Batch} from 'core/entities/Settlement/types';

import SettlementsList from '../SettlementsList';
import NotPostedSettlementsWarning from './NotPostedSettlementsList';
import styles from './closeBatch.module.scss';

type OwnProps = {
    batch: Batch;
    onSubmit();
    onClose();
};

const ClosePayPeriod: React.FC<OwnProps> = ({batch, onSubmit, onClose}) => {
    return (
        <>
            <div className={styles.wrap}>
                <NotPostedSettlementsWarning carrierID={batch.carrier.id} onClose={onClose} />

                <div className="modal-body__main">
                    <div>Check Pay Record below in current batch:</div>
                    <SettlementsList batch={batch} />
                </div>
            </div>
            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <Button onClick={onSubmit} type="submit" colorTheme="blue">
                        Close Batch
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ClosePayPeriod;
