import {useDispatch} from 'react-redux';
import {change} from 'redux-form';

const useChangeReduxFormValues = (formName: string) => {
    const dispatch = useDispatch();

    return (values: any) =>
        Object.entries(values || {}).forEach(([field, value]) => dispatch(change(formName, field, value)));
};

export default useChangeReduxFormValues;
