import isNumber from 'lodash/isNumber';
import pick from 'lodash/pick';

import {isTravelOrderNeedConfirmReDispatch} from 'core/entities/TravelOrder/modules/travelOrderDispatch';
import {isTravelOrderHasCarrier} from 'core/entities/TravelOrder/modules/travelOrderCarrier';
import TravelOrder from 'core/entities/TravelOrder/types';

import {getAttachmentsMetaData} from 'pages/TravelOrders/redux/mappers/attachments';
import {ConfirmModalFormValues} from 'pages/TravelOrders/types/formTypes';

import {createDate} from 'utils/dateTime';

export const transformConfirmDataToRequestBody = (params: {
    confirmData: ConfirmModalFormValues;
    travelOrder?: TravelOrder | null;
}) => {
    const {confirmData, travelOrder} = params;

    const {
        carrierTruckNumber,
        carrierDriverPhone,
        carrierDriverName,
        longitude,
        cityLine,
        latitude,
        timeZone,
        date,
        time,
    } = confirmData;

    const isTONeedConfirmReDispatch = isTravelOrderNeedConfirmReDispatch(travelOrder);
    const isTOHasCarrier = isTravelOrderHasCarrier(travelOrder);

    const createdDateTime = createDate(`${date} ${time}`, {fromTimeZone: timeZone, toTimeZone: 'utc'});
    const {fullOriginalDateTime: dateTime, isValid} = createdDateTime;

    const {attachments} = confirmData;
    const convertedAttachments = getAttachmentsMetaData(attachments);

    const truckValues = {
        longitude: isNumber(longitude) ? longitude : null,
        latitude: isNumber(latitude) ? latitude : null,
        confirmDateTime: isValid ? dateTime : null,
        cityLine: cityLine || null,
    };

    const additionalCarrierValues = {
        truckNumber: carrierTruckNumber || null,
        driverPhone: carrierDriverPhone || null,
        driverName: carrierDriverName || null,
        files: convertedAttachments,
    };

    const pickedTruckValues = isTONeedConfirmReDispatch ? pick(truckValues, ['confirmDateTime']) : truckValues;

    if (isTOHasCarrier) {
        return {...pickedTruckValues, ...additionalCarrierValues};
    }

    return pickedTruckValues;
};
