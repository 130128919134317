import {InferActions} from 'store';

import * as types from './actionTypes';
import {FileType} from './types';

type GalleryFilesPayload = {
    files: FileType[];
    currentItemIndex?: number;
};

export const openGallery = (payload: GalleryFilesPayload) => ({type: types.OPEN, payload} as const);

export const closeGallery = () => ({type: types.CLOSE} as const);

const actionCreators = {
    openGallery,
    closeGallery,
};

export type GalleryActionsTypes = InferActions<typeof actionCreators>;

export default actionCreators;
