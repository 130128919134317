import restApi from 'services/restapi';

export const generateOwnTrucksReportRequest = ({requestPayload}) => {
    return restApi.post('/trucks/trucks-nearby/export', requestPayload);
};

export const generatePartnerTrucksReportRequest = ({requestPayload}) => {
    return restApi.post('/trucks/trucks-nearby/partner/export', requestPayload);
};

export const postQuoteSms = ({requestPayload}) => {
    return restApi.post('/messages/mass', requestPayload);
};

export const getOwnTrucks = ({requestPayload}) => {
    return restApi.post('/trucks/trucks-nearby', requestPayload);
};

export const getFullOwnTrucks = ({requestPayload}) => {
    return restApi.post('/trucks/trucks-nearby/full-list', requestPayload);
};

export const getPartnersTrucks = ({requestPayload}) => {
    return restApi.post('/trucks/trucks-nearby/partner', requestPayload);
};

export const getLatestPartnerTruckData = ({globalID, vinCode}) => {
    return restApi.get(`/trucks/trucks-nearby/partner/${globalID}/truck/${vinCode}`);
};
