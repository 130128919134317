import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {getUserAccessToken} from 'store/reducers/auth/selectors';

import EventSourceService from 'services/EventSourceService';

const useSSE = () => {
    const token = useSelector(getUserAccessToken);
    const location = useLocation();
    const dispatch = useDispatch();
    const SSEService = useRef<EventSourceService | null>(null);

    useEffect(() => {
        if (token) {
            SSEService.current = new EventSourceService({token, dispatch});
        }

        return () => {
            SSEService.current?.disconnect();
            SSEService.current = null;
        };
    }, [token]);

    useEffect(() => {
        SSEService.current?.connect(location.pathname);

        return () => {
            SSEService.current?.disconnect();
        };
    }, [location]);
};

export default useSSE;
