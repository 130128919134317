import {getReDispatchesFromTheTravelOrder} from 'core/entities/TravelOrder/modules/travelOrderDispatch';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';
import {ReDispatchesItem} from 'core/entities/TravelOrder/types/ReDispatch';

export const getTravelOrderStopReDispatchesInfoByID = (params: StopInfoParams): ReDispatchesItem[] => {
    const {travelOrder, stopID} = params;

    const reDispatches = getReDispatchesFromTheTravelOrder(travelOrder);
    const stopReDispatches = reDispatches.filter((reDispatch) => reDispatch.stopId === stopID);

    return stopReDispatches;
};
