import {LOCATION_CHANGE} from 'connected-react-router';

export * from './getRouteParams';

const redirectPrefix = '?redirect=';

export const encodePath = (path = '') => {
    return `${redirectPrefix}${encodeURIComponent(path)}`;
};

export const decodePath = (path = '') => {
    return decodeURIComponent(path).replace(redirectPrefix, '');
};

export const isChangeLocationAction = (action) => {
    return action && action.type === LOCATION_CHANGE;
};

export const getRouteParamsFromChangeLocationAction = (action) => {
    if (!isChangeLocationAction(action)) {
        console.warn('Incorrect action for get route params: ', action);

        return;
    }

    return action.payload?.routeParams?.params;
};

export const getPageNameFromChangeLocationAction = (action) => {
    if (!isChangeLocationAction(action)) {
        console.warn('Incorrect action for get page name: ', action);

        return;
    }

    return action.payload?.location?.pageName;
};

export const getIsLocationChanged = (action) => {
    if (!isChangeLocationAction(action)) {
        console.warn('Incorrect action for check change page: ', action);

        return;
    }

    const {pathname, prevPathname} = action.payload?.location;

    return pathname !== prevPathname;
};
