import React from 'react';
import {Field} from 'redux-form';

import WeightMeasureInput from 'components/ui/Form/WeightMeasureInput';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';

const PayloadInput: React.FC = () => {
    const {getTruckPayloadValueFieldName, getTruckPayloadUnitFieldName} = getFormFieldNames();
    const payloadUnit = useGetReduxFormValue(FORM_NAME, getTruckPayloadUnitFieldName());
    return (
        <div className="mt-2">
            <Field
                name={getTruckPayloadValueFieldName()}
                component={WeightMeasureInput}
                label="PAYLOAD"
                placeholder={payloadUnit}
                maxLength="9"
            />
        </div>
    );
};

export default PayloadInput;
