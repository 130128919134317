import head from 'lodash/head';

import {GetCheckCallEventDataFromRequest} from 'core/entities/LocationEvents/types';

import {getTypeFieldNameFactory} from 'utils/typeScript';
import {getCurrentDate} from 'utils/dateTime';

import {AddCheckCallFormValues, SummaryFormValues, SummarySection} from '../types';

export const getFieldPath = ({formSection, field}: {formSection?: string; field: string}): string => {
    return formSection ? `${formSection}.${field}` : field;
};

export const getName = getTypeFieldNameFactory<AddCheckCallFormValues>();

export const getNameSummaryField = (field: keyof SummaryFormValues): string => field;

export const getFieldNameWithSummarySection = (formSection: string, field: keyof SummarySection): string =>
    getFieldPath({formSection, field});

export const createInitFormValues = (checkCallData: GetCheckCallEventDataFromRequest): AddCheckCallFormValues => {
    const {timeZone} = getCurrentDate();

    const loadNumbers = checkCallData.summaryList.map((item) => item.loadNumber);

    return {
        longitude: undefined,
        latitude: undefined,
        cityLine: undefined,
        dateTime: undefined,
        timezone: timeZone,
        note: '',

        summaryItem: {
            checkLocationForBroker: false,
            loadNumber: head(loadNumbers),
            isSendToBrokerSystem: false,
            isSendToBrokerUpdate: false,
            sendToSummary: true,
        },

        loadNumbers,
    };
};
