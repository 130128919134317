import {InferActions} from 'store';

import Pagination from 'types/Pagination';

import * as loadStatuses from 'core/entities/Load/modules/loadStatus';
import Load from 'core/entities/Load/types';
import {LOAD_STATUSES_ALL} from 'core/entities/Load/modules/loadStatus';

import * as types from 'pages/Loads/redux/actionTypes/listActionTypes';
import {DEFAULT_CURRENT_PAGE, DEFAULT_PER_PAGE_COUNT} from 'pages/Loads/constants/loadConstants';

import {actionCreators} from '../../actionCreators/list';
import searchParamsReducer from './searchParamsReducer';

const CurrentReducerActions = actionCreators;

type ActionTypes = InferActions<typeof CurrentReducerActions>;

export interface LoadState {
    items: Load[] | null;
    pagination: Partial<Pagination>;
    expandedItems: Load[];
    expiresPickUpsParams: any;
    pickUpDeliveryDistances: number[];
    selectedLoad: Load | null;
    advancedSearchResultCount: number | null;
    searchParams: {
        statuses: typeof LOAD_STATUSES_ALL;
        advancedParams: any;
    };
}

const defaultState: LoadState = {
    items: null,
    pagination: {
        currentPage: DEFAULT_CURRENT_PAGE,
        perPage: DEFAULT_PER_PAGE_COUNT,
    },
    expandedItems: [],
    expiresPickUpsParams: null,
    pickUpDeliveryDistances: [],
    selectedLoad: null,
    advancedSearchResultCount: null,
    searchParams: {
        statuses: [loadStatuses.LOAD_STATUS_AVAILABLE],
        advancedParams: {},
    },
};

export default function loadsList(state = defaultState, action: ActionTypes): LoadState {
    switch (action.type) {
        case types.RECEIVE_NOTE: {
            const {loadNotes, loadNumber} = action.payload;
            const loads = state.items?.map(
                (load): Load => {
                    if (load.number === loadNumber) {
                        return {
                            ...load,
                            notes: loadNotes,
                        };
                    }
                    return load;
                },
            );
            return {
                ...state,
                items: loads as Load[],
            };
        }

        case types.RECEIVE_LOAD: {
            const {load} = action.payload;

            const loads = state.items?.map((item): Load => (item.number === load?.number ? {...load} : item));

            return {
                ...state,
                items: loads as Load[],
            };
        }

        case types.LIST_DATA_RECEIVED: {
            const {loads, pagination} = action.payload;
            return {
                ...state,
                items: loads,
                pagination,
            };
        }

        case types.LIST_DATA_CLEARED: {
            return {
                ...state,
                items: [],
                pagination: {
                    currentPage: DEFAULT_CURRENT_PAGE,
                    perPage: DEFAULT_PER_PAGE_COUNT,
                },
            };
        }

        case types.TRAVEL_ORDER_CREATED: {
            // for case when we can add travel order from load page we should check list and skip this action
            if (!state.items) {
                return state;
            }
            const {loadNumber} = action.payload;
            return {
                ...state,
                items: state.items.filter((load) => load.number !== loadNumber),
            };
        }

        case types.SET_STATUS_SEARCH_PARAMS:
        case types.SET_ADVANCED_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: searchParamsReducer(state.searchParams, action),
            };
        }

        case types.CLEAR_STATE: {
            return {...defaultState};
        }

        // no default
    }

    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
