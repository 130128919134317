import {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {PayRecord} from 'core/entities/Settlement/types';
import {fetchPeriodPayRecords} from 'core/entities/Settlement/requests/settlementOwnerRequests';
import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import SearchParams from 'pages/Settlements/types/SettlementSearchParams';
import {getPayPeriodPayRecords, getSearchParams, getPayPeriodById} from 'pages/Settlements/selectors';
import {payPeriodPayRecordsReceived} from 'pages/Settlements/actions/periodActions';

import {isAllFetched, calculateCountPayRecordsForFetch} from './utils';

interface Params {
    payPeriodID: number;
    useSearchParams?: boolean;
    useDynamicPagination?: boolean;
}

interface FetchData {
    payRecords: PayRecord[];
    isFetching: boolean;
    isAllFetched: boolean;
    fetchNextPage();
}

interface FetchParams {
    payPeriodID: number;
    searchParams?: SearchParams;
    pagination: {page: number; perPage: number};
}

const PER_PAGE = 10;

const fetchPayRecords = (params: FetchParams): Promise<{payRecords: PayRecord[]; pagination: any}> => {
    const {payPeriodID, searchParams = {}, pagination} = params;
    return fetchPeriodPayRecords(payPeriodID, pagination, searchParams)
        .then((response) => {
            return {payRecords: response.data, pagination: parsePaginationHeaders(response.headers)};
        })
        .catch((error) => {
            console.error('Error on fetch pay period pay records: ', error);
            return {payRecords: [], pagination: parsePaginationHeaders(error.headers)};
        });
};

export default ({payPeriodID, useSearchParams = true, useDynamicPagination = false}: Params): FetchData => {
    const ref = useRef({isAllFetched: false, page: 1});
    const [isFetching, setIsFetching] = useState(true);
    const payPeriodsSearchParams = useSelector(getSearchParams);
    const payRecords = useSelector((state) => getPayPeriodPayRecords(state, payPeriodID)) as PayRecord[];
    const dispatch = useDispatch();
    const payPeriod = useSelector((state) => getPayPeriodById(state, payPeriodID));
    const payRecordsCountForFetch =
        payPeriod && useDynamicPagination ? calculateCountPayRecordsForFetch(payPeriod) : PER_PAGE;
    const searchParams = useSearchParams ? (payPeriodsSearchParams as SearchParams) : ({} as SearchParams);
    const fetchData = (paginationPage): void => {
        setIsFetching(true);
        fetchPayRecords({
            payPeriodID,
            searchParams,
            pagination: {
                page: paginationPage,
                perPage: payRecordsCountForFetch,
            },
        })
            .then((response) => {
                ref.current.isAllFetched = isAllFetched(response.pagination, response.payRecords, PER_PAGE);
                dispatch(payPeriodPayRecordsReceived(payPeriodID, response.payRecords));
            })
            .finally(() => {
                setIsFetching(false);
            });
    };
    const fetchNextPage = (): void => {
        ref.current.page += 1;
        fetchData(ref.current.page);
    };
    useEffect(() => {
        fetchData(ref.current.page);
    }, []);

    return {
        payRecords,
        isFetching,
        isAllFetched: ref.current.isAllFetched,
        fetchNextPage,
    };
};
