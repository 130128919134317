import {InferActions} from 'store';

import * as actionTypes from '../actionTypes/modalActionTypes';

export const actionCreators = {
    hideModal: (payload: {modalName: string | null}) => ({type: actionTypes.LOADS_HIDE_MODAL, payload} as const),
    showModal: (payload: {modalName: string | null; modalData?: any; keepOpenModalName?: string | null}) =>
        ({
            type: actionTypes.LOADS_SHOW_MODAL,
            payload,
        } as const),
};

export type ActionsTypes = InferActions<typeof actionCreators>;
