import React, {ChangeEvent, useRef} from 'react';
import {FormGroup, FormLabel} from 'react-bootstrap';

import {WrappedFieldInputProps} from 'redux-form/lib/Field';
import styles from './styles.module.scss';

interface OwnProps {
    input: WrappedFieldInputProps;
    step?: number;
    limit?: number;
    label: string;
}

const MinutesInput: React.FC<OwnProps> = (props) => {
    const {input, step = 1, limit = Infinity, label} = props;
    const inputNumber = useRef<HTMLInputElement>(null);
    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
        const {target} = event;
        let value = parseInt(target.value, 10);

        if (!Number.isNaN(value)) {
            value = value <= limit ? value : limit;
        } else {
            value = step;
        }

        input.onChange(value);
    };

    const increase = (): void => {
        if (inputNumber.current) {
            inputNumber.current?.stepUp();
            input.onChange(parseInt(inputNumber.current.value, 10));
        }
    };

    const decrease = (): void => {
        if (inputNumber.current) {
            inputNumber.current?.stepDown();
            input.onChange(parseInt(inputNumber.current?.value, 10));
        }
    };
    return (
        <div className={styles.wrapper}>
            <FormGroup>
                {label && <FormLabel>{label}</FormLabel>}
                <div className={styles.minutesSelector}>
                    <input
                        ref={inputNumber}
                        className="form-control"
                        value={input.value}
                        onChange={onChangeHandler}
                        type="number"
                        max={limit}
                        min={step}
                        step={step}
                        id={input.name}
                    />
                    <label htmlFor={input.name} className={styles.unit}>
                        min
                    </label>

                    <button className={`button button-grey ${styles.decrease}`} onClick={decrease} type="button">
                        <i className="fa fa-angle-left" aria-hidden="true" />
                    </button>

                    <button className={`button button-grey ${styles.increase}`} onClick={increase} type="button">
                        <i className="fa fa-angle-right" aria-hidden="true" />
                    </button>
                </div>
            </FormGroup>
        </div>
    );
};

export default MinutesInput;
