import {AppState} from 'store';
import {ThunkAction} from 'redux-thunk';

import {modalNames} from 'pages/Customers/components/common/modals/modalMap';

import {commonModalNames} from 'components/ui/modals/modalMap';
import {openModal} from 'components/ui/ModalProvider/actions';

type ThunkActionTypes = ThunkAction<void, AppState, unknown, any>;

export const showMergeCustomersModal = (): ThunkActionTypes => (dispatch) => {
    dispatch(openModal({modalName: modalNames.mergeCustomers}));
};

export const showMergeCustomersRequestSuccessModal = (): ThunkActionTypes => (dispatch) => {
    const data = {
        message: 'Request to merge customers was sent successfully',
        rightButtonTitle: 'Ok',
        buttonType: 'success',
        bodyType: 'Success',
        title: 'Success',
    };

    dispatch(openModal({modalName: commonModalNames.informationModal, data}));
};
