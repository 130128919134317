import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';
import Load from 'core/entities/Load/types';

import {getTravelOrderStopByID} from '..';

export const getTravelOrderStopLoad = (params: StopInfoParams): Load | undefined => {
    const {stopID, travelOrder} = params;

    const toStop = getTravelOrderStopByID({stopID, travelOrder});
    const foundLoad = travelOrder?.loads.find((load) => load.number === toStop?.loadStop?.loadNumber);

    return foundLoad;
};

export const isOnRouteCurrentStop = (params: StopInfoParams): boolean => {
    const {stopID, travelOrder} = params;

    const currentStop = getTravelOrderStopByID({stopID, travelOrder});

    return Boolean(currentStop && currentStop.orderNumber > 1 && currentStop?.isVehicleOnRoute);
};
