import isEmpty from 'lodash/isEmpty';
import {Dimension, Quote} from '../../types';

export const getQuoteWeight = (quote: Quote): number => quote.load.freight.weight;
export const getQuoteWeightUnit = (quote: Quote): string => quote.load.freight.weightUnit;

export const getQuoteDims = (quote: Quote): Dimension['items'] => quote.load.freight.dimensions.items;
export const getQuoteDimsUnit = (quote: Quote): Dimension['unit'] => quote.load.freight.dimensions.unit;

export const getQuotePieces = (quote: Quote): number => quote.load.freight.pieces;

export const isQuoteDimenstionEmpty = (quote: Quote): boolean => {
    const dimension = getQuoteDims(quote);

    return isEmpty(dimension);
};

export const getIsStackable = (quote: Quote): boolean => quote.load.freight.isStackable;
