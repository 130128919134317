import Owner from 'core/entities/Owner/types';

import * as types from 'pages/Owners/actionTypes';

import Pagination from 'types/Pagination';

interface State {
    data: Owner[];
    searchParams: {[key: string]: any};
    pagination: Partial<Pagination>;
}

// exported for testing purposes only
export const defaultState = {
    data: [],
    searchParams: {},
    pagination: {
        currentPage: 1,
        perPage: 50,
    },
};

export default function owners(state: State = defaultState, action): State {
    switch (action.type) {
        case types.OWNERS_RECEIVED: {
            return {
                ...state,
                data: action.payload.data,
                pagination: action.payload.pagination,
            };
        }

        case types.OWNER_ARCHIVED: {
            const {owner: archivedOwner} = action.payload;
            return {
                ...state,
                data: state.data.filter((owner) => owner.id !== archivedOwner.id),
            };
        }

        case types.OWNERS_SET_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: action.payload,
            };
        }

        case types.OWNERS_SET_CURRENT_PAGE: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: action.payload,
                },
            };
        }

        case types.OWNERS_SET_PAGINATION: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload,
                },
            };
        }

        case types.CLEAR_STATE: {
            return defaultState;
        }

        default:
            return state;
    }
}
