import React from 'react';
import {FieldArray} from 'redux-form';

import {FIELDS} from 'core/entities/Customer/constants/fields';

import LoadBoardsList from './components/LoadBoardsList/index';

interface OwnProps {
    disabled: boolean;
}

const LoadBoardsInformationEU = ({disabled}: OwnProps): JSX.Element => {
    return (
        <>
            <h3>Load Boards Information:</h3>
            <FieldArray name={FIELDS.load_boards} component={LoadBoardsList} disabled={disabled} />
        </>
    );
};

export default LoadBoardsInformationEU;
