import head from 'lodash/head';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';

import TravelOrder from 'deprecated/core/entities/TravelOrder/types';

import Invoice from 'core/entities/Invoice/types';
import {
    isInvoiceStatusPosted,
    isInvoiceStatusRated,
    isInvoiceStatusReadyToPost,
} from 'core/entities/Invoice/invoiceStatus';
import {getInvoiceLoadFromSnapshot, getInvoiceTravelOrderFromSnapshot} from 'core/entities/Invoice/invoiceSnapshot';

export const getInvoiceCurrentPDF = (invoice) => {
    return invoice && invoice.last_status_history && invoice.last_status_history.file;
};

export const getInvoiceLoad = (invoice) => {
    if (!invoice) {
        return undefined;
    }

    if (isInvoiceStatusRated(invoice)) {
        return invoice.load;
    }

    return getInvoiceLoadFromSnapshot(invoice);
};

export const getInvoiceTotalRate = (invoice) => {
    const load = getInvoiceLoad(invoice);
    return load && load.total_rate;
};

export const getInvoiceTravelOrder = (invoice): any => {
    if (!invoice) {
        return undefined;
    }
    if (isInvoiceStatusRated(invoice)) {
        const {load} = invoice;
        return head(load.travel_order);
    }
    return getInvoiceTravelOrderFromSnapshot(invoice);
};

export const getInvoiceCustomer = (invoice) => {
    if (!invoice) {
        return undefined;
    }
    const load = getInvoiceLoad(invoice);
    return load && load.customer;
};

export const isInvoiceResetFromBatch = (invoice: Invoice): boolean => {
    const batchNumber = invoice.batch_number;
    if (!batchNumber) {
        return false;
    }
    const isCorrectListBatches = (listBatches: string | null): listBatches is string => {
        return listBatches !== null && isString(listBatches);
    };
    const getFormattedListBatchesFromWhereInvoiceWasReset = (listBatches: string): number[] => {
        return listBatches.split(',').map(Number);
    };
    const listBatches = invoice.reseted_from_batches;
    if (!isCorrectListBatches(listBatches)) {
        return false;
    }
    const formattedListBatchesFromWhereInvoiceWasReset = getFormattedListBatchesFromWhereInvoiceWasReset(listBatches);
    return formattedListBatchesFromWhereInvoiceWasReset.includes(batchNumber);
};

export const isInvoiceDeleted = (invoice): boolean => {
    return Boolean(invoice?.is_deleted);
};

export const isTravelOrderDryRun = (travelOrder?: TravelOrder | null): boolean =>
    Boolean(travelOrder) && !isEmpty(travelOrder?.dry_run);

export const isInvoiceInBatch = (invoice: Invoice): boolean => {
    return isInvoiceStatusReadyToPost(invoice) || isInvoiceStatusPosted(invoice);
};
