import React from 'react';
import * as entityNames from 'utils/data/entityNames';

import DriverDuplicateError from 'components/common/Driver/DriverDuplicateError';
import OwnerDuplicateError from 'components/common/Owner/OwnerDuplicateError';

const entityErrorComponentsMap = {
    [entityNames.ENTITY_NAME_DRIVER]: (driver) => () => <DriverDuplicateError driver={driver} />,
    [entityNames.ENTITY_NAME_OWNER]: (owner) => () => <OwnerDuplicateError owner={owner} />,
};

type OwnProps = {
    message?: string;
    data: {entityName: string; entity?: string};
};

const ErrorForm: React.FC<OwnProps> = ({message, data: {entityName, entity}}) => {
    let ErrorComponent = () => <p>{message}</p>;

    if (entityName && entity) {
        const makeErrorComponent = entityErrorComponentsMap[entityName];
        ErrorComponent = makeErrorComponent(entity);
    }

    return (
        <div className="row align-items-center">
            <div className="col-1">
                <i className="fa fa-exclamation feedback-icon-error" />
            </div>
            <div className="col-11">
                <ErrorComponent />
            </div>
        </div>
    );
};

export default ErrorForm;
