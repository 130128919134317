import {Log} from 'core/entities/Log/types';

import Pagination from 'types/Pagination';

import {SearchParams} from '../types';

export const RECEIVED_LOGS = 'LOGS_PAGE_RECEIVED_LOGS' as const;
export const SET_CURRENT_PAGE = 'LOGS_PAGE_SET_CURRENT_PAGE' as const;
export const SET_PER_PAGE = 'LOGS_PAGE_SET_PER_PAGE' as const;
export const SET_SEARCH_PARAMS = 'LOGS_PAGE_SET_SEARCH_PARAMS' as const;
export const CLEAR_STATE = 'LOGS_PAGE_CLEAR_STATE' as const;
export const SET_LOGS_EXTERNAL_PARAMS = 'APP_SET_LOGS_EXTERNAL_PARAMS' as const;

export interface ReceivedLogs {
    type: typeof RECEIVED_LOGS;
    payload: {
        logs: Log[];
        pagination: Pagination;
    };
}

export interface SetCurrentPage {
    type: typeof SET_CURRENT_PAGE;
    payload: number;
}

export interface SetPerPage {
    type: typeof SET_PER_PAGE;
    payload: number;
}

export interface SetSearchParams {
    type: typeof SET_SEARCH_PARAMS;
    payload: SearchParams;
}

export interface ClearState {
    type: typeof CLEAR_STATE;
}

export interface AppSetLogsExternalParams {
    type: typeof SET_LOGS_EXTERNAL_PARAMS;
    payload: any;
}

export type ListTypes =
    | ReceivedLogs
    | SetCurrentPage
    | SetPerPage
    | SetSearchParams
    | ClearState
    | AppSetLogsExternalParams;
