import React from 'react';

import Truck from 'core/entities/Truck/types';

import {TruckLink} from 'components/ui/Links';

const TruckOnHoldError: React.FC<{truck: Truck}> = (props) => {
    const {truck} = props;
    if (!truck) {
        return null;
    }
    return (
        <p className="mt-2">
            You can&apos;t cancel finish confirmation because{' '}
            <strong>
                <TruckLink truck={truck} content={`Truck #${truck?.number}`} className="second-link" /> is on hold
            </strong>
            . <br />
            Please contact HR to resolve this issue.
        </p>
    );
};

export default TruckOnHoldError;
