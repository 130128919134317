import React from 'react';
import {Modal} from 'react-bootstrap';

import Form from './Form';

const CreatePayPeriodModal: React.FC<any> = (props) => {
    const {onClose, data, handlers} = props;
    const titleText = data.lastPayPeriod ? 'New Pay Period' : 'First Pay Period';

    return (
        <Modal show={true} onHide={onClose} dialogClassName="standard-form-modal" size="sm" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>{titleText}</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form lastPayPeriod={data.lastPayPeriod} onSubmit={handlers.onSubmit} onClose={onClose} />
            </Modal.Body>
        </Modal>
    );
};

export default React.memo(CreatePayPeriodModal);
