import {SettlementCarrier, Batch} from './types';

export const getSettlementBatch = (settlement: SettlementCarrier): Batch | undefined => {
    return (settlement && settlement.batch) || undefined;
};

export const getSettlementBatchCarrierName = (settlementBatch: Batch): string | undefined => {
    return (settlementBatch && settlementBatch.carrier && settlementBatch.carrier.company_name) || undefined;
};

export const isSettlementBatchOpened = (settlementBatch: Batch): boolean => {
    return settlementBatch && settlementBatch.is_opened;
};

export const isSettlementBatchClosed = (settlementBatch: Batch): boolean => {
    return settlementBatch && !settlementBatch.is_opened;
};

// in one moment of time we can have only one opened batch
// so for check possibility to reopen batch we should try find another opened batch with same carrier as selected batch
export const isSettlementBatchCanBeReopened = (settlementBatch: Batch, allBatches: Batch[]): boolean => {
    const carrierOfSelectedBatch = settlementBatch.carrier;
    const allBatchesWithSameCarrier = allBatches.filter((b) => b.carrier && b.carrier.id === carrierOfSelectedBatch.id);
    const openedBatchWithSelectedCarrier = allBatchesWithSameCarrier.find(isSettlementBatchOpened);
    return !openedBatchWithSelectedCarrier;
};
