import React from 'react';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';
import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';
import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';

import DimensionHeight from './components/DimensionHeight';
import DimensionLength from './components/DimensionLength';
import DimensionWidth from './components/DimensionWidth';

const DimensionInput: React.FC = () => {
    const {getTruckDimensionUnitFieldName} = getFormFieldNames();
    const dimensionUnit = useGetReduxFormValue(FORM_NAME, getTruckDimensionUnitFieldName());
    return (
        <>
            <DimensionLength units={dimensionUnit} />
            <span className="m-2">x</span>
            <DimensionWidth units={dimensionUnit} />
            <span className="m-2">x</span>
            <DimensionHeight units={dimensionUnit} />
        </>
    );
};

export default DimensionInput;
