export const RATED = 'Rated';
export const READY_TO_GENERATE = 'Ready to Generate';
export const GENERATED = 'Generated';
export const READY_TO_POST = 'Ready to Post';
export const POSTED = 'Posted';

export const statuses = {
    RATED,
    READY_TO_GENERATE,
    GENERATED,
    READY_TO_POST,
    POSTED,
};

export const accountsReceivableStatuses = {
    NOT_RECEIVED: 'Not Received',
    TBD_PAYMENTS: 'TBD Payments',
    RECEIVED: 'Received',
};
