import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';

const isLastDelivery = (params: StopInfoParams): boolean => params.travelOrder?.lastDelivery === params?.stopID;
const isFirstPickUp = (params: StopInfoParams): boolean => params.travelOrder?.firstPickUp === params?.stopID;
const isCurrentStop = (params: StopInfoParams): boolean => params.travelOrder?.currentStop === params?.stopID;

export type TOCommonInfo = {
    isLastDelivery: ReturnType<typeof isLastDelivery>;
    isFirstPickUp: ReturnType<typeof isFirstPickUp>;
    isCurrentStop: ReturnType<typeof isCurrentStop>;
};

export const getTravelOrderStopCommonInfo = (params: StopInfoParams): TOCommonInfo => ({
    isLastDelivery: isLastDelivery(params),
    isFirstPickUp: isFirstPickUp(params),
    isCurrentStop: isCurrentStop(params),
});
