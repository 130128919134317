import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector, change as reduxFormChange, InjectedFormProps} from 'redux-form';

import {AppState} from 'store';

import {Company} from 'core/entities/Settlement/types';

import Button from 'components/ui/Buttons/Button';

import {OpenedCompany, BatchResponsibleUser} from './types';
import SelectedBatchInfo from './SelectedBatchInfo';
import CompanyInfo from './CompanyInfo';
import * as selectors from './selectors';
import styles from './form.module.scss';

interface OwnProps {
    // eslint-disable-next-line react/no-unused-prop-types
    companies: Company[];
    onClose();
}

interface StateProps {
    allCompanies: OpenedCompany[];
    selectedCompany: OpenedCompany;
    // eslint-disable-next-line react/no-unused-prop-types
    initialValues: {
        company: OpenedCompany;
    };
}

interface DispatchProps {
    onSelectBatch(batch: OpenedCompany): void;
    onSelectResponsibleUser(user: BatchResponsibleUser): void;
    onSelectAllBatchUsers(users: BatchResponsibleUser[]): void;
}

type AllFormProps = OwnProps & StateProps & DispatchProps;

const FORM_NAME = 'settlements-setup-batch-form';
const COMPANY_FIELD_NAME = 'company';

const initialValues: {company: OpenedCompany} = {
    company: {
        // @ts-ignore
        id: undefined,
        // @ts-ignore
        batchId: undefined,
        title: '',
        carrier: {},
        settlementsCount: 0,
        users: [],
    },
};

const formSelector = formValueSelector(FORM_NAME);

const Form = (props: AllFormProps & InjectedFormProps<{}, AllFormProps>): JSX.Element => {
    const {
        handleSubmit,
        pristine,
        submitting,
        allCompanies,
        selectedCompany = {} as OpenedCompany,
        onSelectBatch,
        onSelectResponsibleUser,
        onSelectAllBatchUsers,
        onClose,
    } = props;
    const selectedUsers = selectedCompany.users || [];
    const batches = useMemo(() => {
        return allCompanies.map((company) => {
            const selectedUsersInCompany = selectedCompany?.id === company.id ? selectedUsers : [];
            return (
                <CompanyInfo
                    key={company.id}
                    company={company}
                    isOpened={selectedCompany.id === company.id}
                    selectedUsers={selectedUsersInCompany}
                    onClickHeader={() => onSelectBatch(company)}
                    onSelectUser={onSelectResponsibleUser}
                    onSelectAllUsers={onSelectAllBatchUsers}
                />
            );
        });
    }, [allCompanies, selectedCompany.id, selectedCompany.users]);

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className="custom-scroll">
                <div className="modal-body__main">
                    <p className="mb15">Choose needed batches:</p>
                    <div className="invoices-group">{batches}</div>
                </div>
            </div>

            <SelectedBatchInfo selectedBatch={selectedCompany} />

            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <Button
                        disabled={pristine || submitting || selectedUsers.length === 0}
                        type="submit"
                        colorTheme="blue"
                    >
                        Post
                    </Button>
                </div>
            </div>
        </form>
    );
};

const ReduxForm = reduxForm<{}, AllFormProps>({form: FORM_NAME})(Form);

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
    const companies = selectors.getCompaniesWithSettlements(state, ownProps);
    return {
        allCompanies: companies,
        initialValues: {
            company: {
                // @ts-ignore
                id: undefined,
                // @ts-ignore
                batchId: undefined,
                title: '',
                carrier: {},
                settlementsCount: 0,
                users: [],
            },
        },
        selectedCompany: formSelector(state, COMPANY_FIELD_NAME),
    };
};

const dispatchToProps = (dispatch): DispatchProps => {
    return {
        onSelectBatch: (company) => {
            return dispatch((_, getState) => {
                const currentPeriod = formSelector(getState(), COMPANY_FIELD_NAME);
                if (currentPeriod.id === company.id) {
                    dispatch(reduxFormChange(FORM_NAME, COMPANY_FIELD_NAME, initialValues.company));
                    return;
                }
                dispatch(reduxFormChange(FORM_NAME, COMPANY_FIELD_NAME, company));
            });
        },

        onSelectResponsibleUser: (selectedUser) => {
            return dispatch((_, getState) => {
                const currentBatch = formSelector(getState(), COMPANY_FIELD_NAME);
                const isUserInBatch = currentBatch.users.some((user) => user.id === selectedUser.id);
                if (isUserInBatch) {
                    const usersWithoutSelected = currentBatch.users.filter((user) => user.id !== selectedUser.id);
                    const updatedPayPeriod = {
                        ...currentBatch,
                        users: usersWithoutSelected,
                    };
                    dispatch(reduxFormChange(FORM_NAME, COMPANY_FIELD_NAME, updatedPayPeriod));
                } else {
                    const updatedBatch = {
                        ...currentBatch,
                        users: [...currentBatch.users, selectedUser],
                    };
                    dispatch(reduxFormChange(FORM_NAME, COMPANY_FIELD_NAME, updatedBatch));
                }
            });
        },

        onSelectAllBatchUsers: (allUsers) => {
            return dispatch((_, getState) => {
                const currentBatch = formSelector(getState(), COMPANY_FIELD_NAME);
                if (currentBatch.users.length === 0) {
                    dispatch(reduxFormChange(FORM_NAME, COMPANY_FIELD_NAME, {...currentBatch, users: allUsers}));
                } else {
                    dispatch(reduxFormChange(FORM_NAME, COMPANY_FIELD_NAME, {...currentBatch, users: []}));
                }
            });
        },
    };
};

export default connect(mapStateToProps, dispatchToProps)(ReduxForm);
