import {createSelector, createSelectorCreator, defaultMemoize} from 'reselect';
import isEqual from 'lodash/isEqual';
import toLower from 'lodash/toLower';

import {getPagePermissions} from 'store/reducers/userPermissions/selectors';
import {AppState} from 'store';

import getTruckLocation from 'core/entities/Truck/getTruckLocation';

import {TRUCKS_OWNING_TYPE} from 'pages/TrucksNearby/constants';

import permissionsFields from 'utils/data/permissions';
import * as pages from 'utils/data/pages';

import {ListState} from '../reducers/list';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getTrucksOwningType = (state) => state?.trucksNearby?.shared?.trucksOwningType;

export const getStateByOwningType = (state) =>
    getTrucksOwningType(state) === TRUCKS_OWNING_TYPE.OWN
        ? state?.trucksNearby?.ownTrucks
        : state?.trucksNearby?.partnersTrucks;

export const checkIsOwnTrucksTab = (state) => getTrucksOwningType(state) === TRUCKS_OWNING_TYPE.OWN;

// Base
export const getTruck = (state, truckID) => getStateByOwningType(state)?.trucks.byId[truckID];
export const getOpenedInfoWindows = (state) => getStateByOwningType(state)?.openedInfoWindows;
export const getTrucksIDs = (state) => getStateByOwningType(state)?.itemIdsOnPage;
export const getAllTrucksIDs = (state) => getStateByOwningType(state)?.trucks.ids;
export const getAllTrucks = (state) => getStateByOwningType(state)?.trucks.byId;
export const checkIsOpenTruckInfoWindow = (state, truckID) =>
    getStateByOwningType(state).openedInfoWindows.includes(truckID);

// Map
export const checkIsMapExpanded = (state: AppState) => getStateByOwningType(state)?.mapExpanded;
export const checkIsMapLoaded = (state: AppState) => getStateByOwningType(state)?.mapLoaded;
export const checkIsMapLarge = (state: AppState) => getStateByOwningType(state)?.mapLarge;
export const checkIsMapApiLoaded = (state: AppState) => state.app.mapApiLoaded;

export const selectTruckPosition = createSelector([getTruck], (truck) => getTruckLocation(truck));

// next selector is used for "caching" truck position object ({lat, lng}),
// while changing other truck props, not related with coordinates
export const makeSelectMarkerPosition = () => createDeepEqualSelector(selectTruckPosition, (position) => position);

// Permissions
export const getTrucksPermissions = (state) => getPagePermissions(state, pages.TRUCKS);
export const getPermissionList = (state) => {
    const permissions = getTrucksPermissions(state);

    return permissions[permissionsFields.list] !== false;
};

// NEW SELECTORS ↓
export const getCorrespondingReducer = (state) => state.trucksNearby[toLower(state.trucksNearby.own.tab)];

export const getSearchParams = (state): ListState['searchParams'] => getCorrespondingReducer(state).searchParams;
export const getListPagination = (state): ListState['pagination'] => getCorrespondingReducer(state).pagination;
export const getExpandedIDs = (state): ListState['expandedIDs'] => getCorrespondingReducer(state).expandedIDs;
export const getPagination = (state): ListState['pagination'] => getCorrespondingReducer(state).pagination;
export const getSorting = (state): ListState['sorting'] => getCorrespondingReducer(state).sorting;
export const getTrucks = (state): ListState['items'] => getCorrespondingReducer(state).items;
export const getListTab = (state): ListState['tab'] => getCorrespondingReducer(state).tab;
export const getDestinationPoint = (state): ListState['locationPoints']['destination'] =>
    getCorrespondingReducer(state).locationPoints?.destination;
export const getExternalSearchParams = (state): ListState['externalSearchParams'] =>
    getCorrespondingReducer(state).externalSearchParams;
export const getOriginPoint = (state): ListState['locationPoints']['origin'] =>
    getCorrespondingReducer(state).locationPoints?.origin;

// For "New quote" modal
export const getDeliveryCityLine = (state): ListState['searchParams']['destinationPoint'] =>
    getCorrespondingReducer(state).searchParams?.destinationPoint;
export const getPickupCityLine = (state): ListState['searchParams']['originPoint'] =>
    getCorrespondingReducer(state).searchParams?.originPoint;

export const generateQuoteTemplate = createSelector(
    [getPickupCityLine, getDeliveryCityLine],
    (pickupCityLine, deliveryCityLine) => {
        const quoteTemplateArray: string[] = [];

        // Template header
        quoteTemplateArray.push('Delta Express Quote');

        const [deliveryCity, deliveryState] = deliveryCityLine?.split(',') || [];
        const [pickupCity, pickupState] = pickupCityLine?.split(',') || [];

        // Pickup details
        quoteTemplateArray.push(`Pick up\nDate and time: TBD\n${pickupCity}, ${pickupState}`);

        // Delivery details
        let deliveryDetails = `Delivery\nDate and time: TBD`;

        if (deliveryCityLine) {
            deliveryDetails += `\n${deliveryCity}, ${deliveryState}`;
        } else {
            deliveryDetails += `\nCity, ST (TBD)`;
        }

        quoteTemplateArray.push(deliveryDetails);

        // Pieces and weight
        quoteTemplateArray.push('0 pcs 0 lbs');

        // Reply text
        quoteTemplateArray.push(
            'If you interested - fill in the following template and use it as reply text:\n' +
                `your truck #\nrate, $\nlane ${pickupState} - ${deliveryState || 'TBD'}`,
        );

        // Warning text
        quoteTemplateArray.push(
            'Please be aware that you are under 15 mins reserve for Delta Express once you replied to this quote',
        );

        return quoteTemplateArray.join('\n\n');
    },
);
