import isPlainObject from 'lodash/isPlainObject';

import config from 'config';

/**
 * Returns the path to the file using its URL and name.
 * @param {object} file
 */
export default (file) => {
    if (!file || !isPlainObject(file)) {
        return '';
    }

    const {file_url, file_name} = file;

    if (!file_url || !file_name) {
        return '';
    }

    return `${config.PUBLIC_URL}/web/${file_url}${file_name}`;
};
