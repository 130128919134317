import ArchivingSuccess from './ArchivingSuccess';
import ArchivingError from './ArchivingError';
import ArchivingWarn from './ArchivingWarn';

export const modalNames = {
    archivingSuccess: 'FACTORING_COMPANIES_PAGE_ARCHIVING_SUCCESS_MODAL',
    archivingError: 'FACTORING_COMPANIES_PAGE_ARCHIVING_ERROR_MODAL',
    archivingWarn: 'FACTORING_COMPANIES_PAGE_ARCHIVING_WARN_MODAL',
};

const modalMap = {
    [modalNames.archivingSuccess]: ArchivingSuccess,
    [modalNames.archivingError]: ArchivingError,
    [modalNames.archivingWarn]: ArchivingWarn,
};

export default modalMap;
