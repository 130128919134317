import React from 'react';
import {Field} from 'redux-form';
import LengthMeasureInput from 'components/ui/Form/LengthMeasureInput';
import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';

type OwnProps = {
    units: string;
};

const DimensionLength: React.FC<OwnProps> = (props) => {
    const {units} = props;
    const {getTruckDimensionLengthFieldName} = getFormFieldNames();
    const lengthFieldName = getTruckDimensionLengthFieldName();
    return (
        <>
            <Field name={lengthFieldName} component={LengthMeasureInput} label="length" units={units} />
        </>
    );
};
export default DimensionLength;
