import PERMISSIONS from 'utils/data/permissions';
import * as pages from 'utils/data/pages';

export default {
    pages: {
        [pages.TRUCKS]: {
            [PERMISSIONS.list]: false,
            [PERMISSIONS.view]: false,
            [PERMISSIONS.create]: false,
            [PERMISSIONS.update]: false,
            [PERMISSIONS.delete]: false,
        },
        [pages.OWNERS]: {
            [PERMISSIONS.list]: false,
            [PERMISSIONS.view]: false,
            [PERMISSIONS.create]: false,
            [PERMISSIONS.update]: false,
            [PERMISSIONS.delete]: false,
        },
        [pages.DRIVERS]: {
            [PERMISSIONS.list]: false,
            [PERMISSIONS.view]: false,
            [PERMISSIONS.create]: false,
            [PERMISSIONS.update]: false,
            [PERMISSIONS.delete]: false,
        },
        [pages.LOADS]: {
            [PERMISSIONS.list]: false,
            [PERMISSIONS.view]: false,
            [PERMISSIONS.create]: false,
            [PERMISSIONS.update]: false,
            [PERMISSIONS.delete]: false,
        },
        [pages.REPORTS]: {
            [PERMISSIONS.list]: false,
        },
        [pages.DISPATCHERS]: {
            [PERMISSIONS.list]: true,
            [PERMISSIONS.view]: true,
            [PERMISSIONS.create]: true,
            [PERMISSIONS.update]: true,
            [PERMISSIONS.delete]: true,
        },
        [pages.CARRIERS]: {
            [PERMISSIONS.list]: false,
            [PERMISSIONS.view]: false,
            [PERMISSIONS.create]: false,
            [PERMISSIONS.update]: false,
            [PERMISSIONS.delete]: false,
        },
        [pages.CUSTOMERS]: {
            [PERMISSIONS.list]: false,
            [PERMISSIONS.view]: false,
            [PERMISSIONS.create]: false,
            [PERMISSIONS.update]: false,
            [PERMISSIONS.delete]: false,
        },
        [pages.FACILITIES]: {
            [PERMISSIONS.list]: true,
            [PERMISSIONS.view]: true,
            [PERMISSIONS.create]: true,
            [PERMISSIONS.update]: true,
            [PERMISSIONS.delete]: true,
        },
        [pages.FACTORING_COMPANIES]: {
            [PERMISSIONS.list]: true,
            [PERMISSIONS.view]: true,
            [PERMISSIONS.create]: true,
            [PERMISSIONS.update]: true,
            [PERMISSIONS.delete]: true,
        },
        [pages.TRIP_MONITOR]: {
            [PERMISSIONS.list]: false,
        },
        [pages.INVOICES]: {
            [PERMISSIONS.list]: false,
        },
        [pages.SETTLEMENTS]: {
            [PERMISSIONS.list]: false,
            [PERMISSIONS.delete]: false,
        },
        [pages.LOGS]: {
            [PERMISSIONS.list]: true,
        },
        [pages.LOAD_BOARD]: {
            [PERMISSIONS.list]: false,
        },
    },

    menu: {
        notifications: false,
        chat: false,
        leftNav: [pages.DISPATCHERS],
    },
};
