import React from 'react';
import Unit from './component/Unit';
import DimensionInput from './component/DimensionInput';

const Dimension: React.FC = () => {
    return (
        <>
            <h5 className="mb-2">Truck`s DIMS</h5>
            <Unit />
            <div className="mt-2">
                <DimensionInput />
            </div>
        </>
    );
};
export default Dimension;
