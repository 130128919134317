import React from 'react';

import {SettlementOwner} from 'core/entities/Settlement/types';
import {getSettlementLoadNumber} from 'core/entities/Settlement/settlement';
import CurrencyAmount from 'components/ui/CurrencyAmount';

import SettlementOriginDestinationInfo from 'pages/Settlements/components/common/SettlementOriginDestinationInfo/index';
import SettlementMilesInfo from 'pages/Settlements/components/common/SettlementMilesInfo/index';
import SettlementLastDeliveryCheckoutTime from 'pages/Settlements/components/common/SettlementLastDeliveryCheckoutTime';

import SettlementTruckInfo from './SettlementTruckInfo';

interface Props {
    settlement: SettlementOwner;
}

const SettlementRow = ({settlement}: Props): JSX.Element => {
    const loadNumber = getSettlementLoadNumber(settlement);
    return (
        <tr>
            <td>
                {loadNumber && <div>Load #{loadNumber}</div>}
                {settlement.settlement_number && <div>T.O #{settlement.settlement_number}</div>}
            </td>
            <td>
                <SettlementTruckInfo settlement={settlement} />
            </td>
            <td>
                <SettlementOriginDestinationInfo settlement={settlement} />
            </td>
            <td>
                <SettlementMilesInfo settlement={settlement} />
            </td>
            <td>
                <SettlementLastDeliveryCheckoutTime settlement={settlement} />
            </td>
            <td>
                <div className="payment">
                    <CurrencyAmount amount={settlement.total_pay} currency={settlement.currency} />
                </div>
            </td>
        </tr>
    );
};

export default SettlementRow;
