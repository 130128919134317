import React, {Fragment} from 'react';

import Batch from 'core/entities/Invoice/types/Batch';
import CurrencyAmount from 'components/ui/CurrencyAmount';

interface OwnProps {
    batch: Batch;
}

const BatchTotalCharges = ({batch}: OwnProps): JSX.Element => {
    const allTotalCharges = batch.batch_total_charges;
    if (!allTotalCharges || allTotalCharges.length === 0) {
        return <strong>-</strong>;
    }
    const allTotalChargesItems = allTotalCharges.map((payment) => {
        return (
            <Fragment key={payment.currency}>
                <CurrencyAmount currency={payment.currency} amount={payment.total_charges} /> <br />{' '}
            </Fragment>
        );
    });
    return <span>{allTotalChargesItems}</span>;
};

export default BatchTotalCharges;
