import React, {useEffect} from 'react';
import {Field} from 'redux-form';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import {FIELDS} from 'core/entities/Customer/constants/fields';

import RequiredLabel from 'components/ui/Form/RequiredLabel';
import CheckSwitch from 'components/ui/Form/CheckSwitch';
import TextInput from 'components/ui/Form/TextInput';

import styles from './styles.module.scss';

type OwnProps = {
    disabled?: boolean;
    form: string;
};

const BrokerAdditionalInfo: React.FC<OwnProps> = (props) => {
    const {form, disabled} = props;

    const isExternalCustomer = useGetReduxFormValue(form, FIELDS.isExternalCustomer);
    const changeFormValue = useChangeReduxFormValue(form);

    useEffect(() => {
        if (!isExternalCustomer) {
            changeFormValue(FIELDS.externalApiIdentifier, null);
        }
    }, [isExternalCustomer]);

    return (
        <div className={styles.container}>
            <Field name={FIELDS.isExternalCustomer} component={CheckSwitch} label="Enable API" disabled={disabled} />

            {isExternalCustomer ? (
                <div className="row">
                    <div className="col-6">
                        <Field
                            label={RequiredLabel('Broker unique code')}
                            placeholder="Enter broker unique code"
                            name={FIELDS.externalApiIdentifier}
                            component={TextInput}
                            disabled={disabled}
                            maxLength={7}
                            type="text"
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default BrokerAdditionalInfo;
