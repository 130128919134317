import React from 'react';

import {FormGroup, FormControl, Form} from 'react-bootstrap';
import Button from 'components/ui/Buttons/Button';
import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';
import getValidationState from 'components/ui/Form/getValidationState';
import styles from './searchInput.module.scss';

export default (props) => {
    const {
        meta,
        meta: {asyncValidating},
        errorTooltipPlacement,
        formGroup = true,
        bsPrefix,
        maxLength,
        clearable,
        onSearch,
    } = props;

    const value = props.value || props.input.value;
    const ref = props.ref || props.input.ref;
    const onChange = props.onChange || props.input.onChange;
    const onBlur = props.onBlur || props.input.onBlur;
    const onKeyDown = props.onKeyDown || props.input.onKeyDown;
    const disabled = props.disabled || props.input.disabled;
    const type = props.type || props.input.type;
    const {autoFocus, placeholder: placeHolder} = props;
    const valid = getValidationState(meta);

    const input = (
        <FormControl
            {...props.input}
            autoFocus={autoFocus}
            maxLength={maxLength}
            placeholder={placeHolder}
            bsPrefix={bsPrefix}
            // off by default
            autoComplete={props.autocomplete || 'off'}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            ref={ref}
            disabled={disabled || asyncValidating}
            type={type}
        />
    );

    const clearValue = () => {
        onChange('');
        setTimeout(onSearch, 0);
    };

    const errorGroupClass = valid ? '' : 'has-feedback has-error';

    if (formGroup) {
        return (
            <FormGroup className={`${styles.item} ${errorGroupClass}`}>
                <OverlayTriggerCustom inputMeta={meta} placement={errorTooltipPlacement}>
                    {input}
                    <button className="search-btn" type="submit">
                        <i className="fa fa-search" aria-hidden="true" />
                    </button>
                </OverlayTriggerCustom>
                {clearable && value && (
                    <div className="clear-btn">
                        <Button buttonIcon="times" buttonSize="icon" onClick={clearValue} />
                    </div>
                )}
                {!clearable && (
                    <Form.Control.Feedback type="invalid" className={valid ? '' : 'error'}>
                        <i className="fa fa-exclamation-circle" />
                    </Form.Control.Feedback>
                )}
            </FormGroup>
        );
    }

    return input;
};
