export default {
    // CRUD
    create: 'create',
    view: 'view',
    update: 'update',
    list: 'list',
    delete: 'delete',

    deleteNotes: 'deleteNotes',
};
