import React from 'react';
import classNames from 'classnames';

import {ReservingTimerContext} from 'hocs/withTruckReservingTimer/ReservingTimerContext';

import Truck from 'core/entities/Truck/types';

import useAttachState from 'pages/Loads/components/common/modals/AttachModal/hooks/useAttachState';

import TruckReservingTimer from 'components/common/Truck/TruckReservingTimer';
import TooltipBootstrap from 'components/ui/TooltipBootstrap';

import {createDate, getDateTimeDiffs} from 'utils/dateTime';

import styles from './styles.module.scss';

type OwnProps = {
    truck: Truck;
};

const TruckItemReservingInfo: React.FC<OwnProps> = (props) => {
    const {truck} = props;

    const {currentDispatcherID} = useAttachState();

    if (!truck.reserve) {
        return null;
    }

    const {reserve} = truck;
    const {dateFrom, dateTo, reserver} = reserve;
    const {dispatcherId, dispatcherName} = reserver;

    const isReservedByCurrentDispatcher = dispatcherId === currentDispatcherID;

    const reserveDateDiff = getDateTimeDiffs({firstDateTime: dateTo, secondDateTime: dateFrom});
    const reserveDateFrom = createDate(dateFrom);
    const reserveDateTo = createDate(dateTo);

    const tooltipContent = (
        <div className="text-left">
            <div>RESERVED BY:</div>
            <div>
                <strong>{dispatcherName}</strong>
            </div>
            <div>
                since <strong>{reserveDateFrom?.fullDate}</strong> for <strong>{reserveDateDiff.minutes} min</strong>
            </div>
            <div>RELEASE:</div>
            <div>
                <strong>{reserveDateTo.date}</strong> at <strong>{reserveDateTo.time}</strong>
            </div>
            &nbsp;in&nbsp;(&nbsp;
            <ReservingTimerContext.Consumer>
                {(timer) => <TruckReservingTimer timer={timer} />}
            </ReservingTimerContext.Consumer>
            &nbsp;)
        </div>
    );

    return (
        <div className={classNames(styles.reserve, {[styles.myself]: isReservedByCurrentDispatcher})}>
            <TooltipBootstrap tooltipContent={tooltipContent}>
                <div className={styles.content}>R</div>
            </TooltipBootstrap>
        </div>
    );
};

export default TruckItemReservingInfo;
