import React from 'react';

import {CurrencyData, CurrencyValue} from 'utils/data/currency';

import styles from './styles.module.scss';

const CurrencyOptionsList = (currencyMap: Record<CurrencyValue, CurrencyData>) =>
    Object.entries(currencyMap).map(([currencyCode, currencyData]) => {
        const title = (
            <span>
                <span className={styles.mark}>{currencyData.mark}</span> {currencyData.title}{' '}
                <span className="text-uppercase">{currencyCode}</span>
            </span>
        );
        return {
            label: title,
            value: currencyCode,
        };
    });

export default CurrencyOptionsList;
