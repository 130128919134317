import React from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';

import SelectOption from 'types/SelectOption';

import {flattenGroupedOptions} from '../../utils';

type OwnProps = {
    meta: {initial: string};
    input: {value: string; onChange(value: string)};
    options: SelectOption[];
    valueAsArray?: boolean;
    valueAsObject?: boolean;
    delimiter?: string;
    isCreatable?: boolean;
};

const getInputValueFromProps = (props: OwnProps): string => {
    return props.input?.value;
};

const getDelimiterFromProps = (props: OwnProps): string => {
    return props.delimiter || ',';
};

const isStringValue = (x: any): x is string => {
    return isString(x);
};

const isArrayValue = (x: any): x is string[] => {
    return isArray(x);
};

const getOptionsFromInputCurrentValue = ({
    inputValue,
    delimiter = ',',
    options,
}: {
    inputValue: string | string[];
    delimiter: string | undefined;
    options: SelectOption[];
}): SelectOption[] => {
    let currentValues: string[] = [];
    if (isStringValue(inputValue)) {
        currentValues = inputValue.split(delimiter);
    }
    if (isArrayValue(inputValue)) {
        currentValues = inputValue;
    }
    return currentValues.filter(Boolean).map((itemValue) => {
        const selectedOption = options.find((option) => option && option.value === itemValue);
        // if option created manually it not exists in options so we using it value for label
        const label = selectedOption ? selectedOption.label : itemValue;
        return {label, value: itemValue};
    });
};

const getSelectedOptions = (props: OwnProps, options): SelectOption[] => {
    const inputValue = getInputValueFromProps(props);
    const listOptions: any = flattenGroupedOptions(options);
    return getOptionsFromInputCurrentValue({inputValue, options: listOptions, delimiter: props.delimiter});
};

// not use spread optionsFromInputCurrentValue from bug https://rhinocodes.atlassian.net/browse/REX-7262
// worked incorrectly, the selected options were additionally duplicated in the multi-select
const getAllOptions = (props: OwnProps): SelectOption[] => {
    const {options} = props;
    const inputValue = getInputValueFromProps(props);
    const optionsFromInputCurrentValue = getOptionsFromInputCurrentValue({
        inputValue,
        delimiter: getDelimiterFromProps(props),
        options,
    });

    return [...options, ...optionsFromInputCurrentValue];
};

const onChangeHandler = (option, props: OwnProps): void => {
    const {input, valueAsObject, valueAsArray} = props;

    if (!option) {
        input.onChange('');
    }

    if (valueAsObject) {
        input.onChange(option);
        return;
    }

    const values = option?.map((el) => el.value);
    if (valueAsArray) {
        input.onChange(values);
        return;
    }

    const delimiter = getDelimiterFromProps(props);
    const joinedValue = values?.join(delimiter);
    input.onChange(joinedValue);
};

const MultiValuesSelect: React.FC<OwnProps> = (props) => {
    const {options} = props;
    const selectedOptions = getSelectedOptions(props, options);

    const selectProps = {
        ...props,
        options,
        value: props.valueAsObject ? props.input.value : selectedOptions,
        onChange: (option) => onChangeHandler(option, props),
    };

    const SelectComponent = props.isCreatable ? CreatableSelect : ReactSelect;

    // @ts-ignore
    return <SelectComponent {...selectProps} />;
};

export default MultiValuesSelect;
