import React from 'react';

import CurrencyAmount from 'components/ui/CurrencyAmount';

import {PayPeriodResponsibleUser} from '../../../types';

import {getUsersPayments} from './utils';

interface OwnProps {
    users: PayPeriodResponsibleUser[] | undefined;
}

const UsersPayments = ({users}: OwnProps): JSX.Element => {
    if (!users || users.length === 0) {
        return <strong>$ 0 USD.</strong>;
    }
    const payments = getUsersPayments(users);
    return (
        <span>
            {payments.map((p, index) => {
                const isLastItem = payments.length - 1 === index;
                return (
                    <span key={p.currency}>
                        <CurrencyAmount key={p.currency} amount={p.amount} currency={p.currency as any} />
                        {!isLastItem && ', '}
                    </span>
                );
            })}
        </span>
    );
};

export default UsersPayments;
