import Batch from 'core/entities/Invoice/types/Batch';
import {statuses} from 'core/entities/Invoice/constants';
import {getBatchStatusName} from 'core/entities/Invoice/batch';

export const isBatchStatusReadyToPost = (batch: Batch): boolean => {
    const status = getBatchStatusName(batch);
    return status === statuses.READY_TO_POST;
};

export const isBatchStatusPosted = (batch: Batch): boolean => {
    const status = getBatchStatusName(batch);
    return status === statuses.POSTED;
};
