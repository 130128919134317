import React from 'react';
import isEmpty from 'lodash/isEmpty';

import decodeText from 'utils/decodeText';
import {DispatcherLink} from 'components/ui/Links';
import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';

import {getQuoteDate} from 'pages/LoadBoard/utils';
import {NoteItem as NoteItemData} from 'pages/LoadBoard/components/modals/QuoteNotesModal/components/Notes/types';

import styles from './styles.module.scss';

const getNoteCreateDate = (note: NoteItemData): string => {
    if (!note?.createdAt) {
        return '';
    }
    const dateOfCreation = getQuoteDate(note.createdAt, {dateFormat: 'dd/MM/yy'});
    return `${dateOfCreation?.date} at ${dateOfCreation?.time}`;
};

const NoteItem: React.FC<{note: NoteItemData}> = (props) => {
    const {note, children} = props;
    const dispatcherLink = !isEmpty(note.createdBy) ? (
        <>
            <DispatcherLink
                dispatcher={note.createdBy}
                content={getDispatcherName(note.createdBy)}
                className="second-link"
            />
        </>
    ) : null;
    const noteType = note?.type === 'general' ? 'general note' : 'note for drivers';
    const timeOfCreate = getNoteCreateDate(note);
    const noteSplitInLines = note.text ? decodeText(note.text).split('\n') : [];
    return (
        <div className={styles.list__item}>
            <div className={styles.list__item_header}>
                <div className={styles.list__item_header_link}>
                    <strong>{dispatcherLink}</strong>
                </div>
                <div className={styles.list__item_header_desc}>
                    <strong>{noteType}</strong> <span>{timeOfCreate}</span> {children}
                </div>
            </div>

            <div className={styles.list__item_content}>
                {noteSplitInLines.map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </div>
        </div>
    );
};

export default NoteItem;
