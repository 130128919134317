import React from 'react';
import {Modal} from 'react-bootstrap';
import {Quote} from 'core/entities/Quote/types';
import Button from 'components/ui/Buttons/Button';
import {getDestinationAddress, getOriginAddress} from 'core/entities/Quote/modules/quoteStop';
import {getCityLineFromObject} from 'utils/getCityLine';
import styles from './styles.module.scss';

interface OwnProps {
    data: Quote;
    onClose(): void;
    handlers: {onArchive(data: Quote): void};
}
const DeleteQuoteModal: React.FC<OwnProps> = (props) => {
    const {onClose, data, handlers} = props;
    const originAddress = getOriginAddress(data);
    const quoteCityLine = getCityLineFromObject(originAddress);
    const destinationAddress = getDestinationAddress(data);
    const destinationCityLine = getCityLineFromObject(destinationAddress);
    return (
        <Modal show={true} onHide={onClose} className="info-modal" backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <h4>Do you want to archive quote?</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.body}>
                <i className="fa fa-exclamation feedback-icon-error" />
                <div className={styles.wrapper}>
                    The quote
                    <strong>
                        {' '}
                        #{data.number} {quoteCityLine} - {destinationCityLine}{' '}
                    </strong>
                    will be archived without the possibility to return
                </div>
            </Modal.Body>
            <Modal.Footer className={styles.footer}>
                <Button onClick={onClose} className="mr10" colorTheme="grey" buttonSize="small" defaultFocus={true}>
                    Close
                </Button>
                <Button
                    onClick={() => handlers.onArchive(data)}
                    className="mr10"
                    colorTheme="red"
                    buttonSize="small"
                    defaultFocus={true}
                >
                    Archive
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteQuoteModal;
