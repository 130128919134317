import React from 'react';
import {Quote} from 'core/entities/Quote/types';
import {getTruckCertificates} from 'core/entities/Quote/modules/quoteTruck';
import Certificate from './components/Certificate';

const QuoteTruckCertificates: React.FC<{quote: Quote}> = (props) => {
    const {quote} = props;
    const certificates = getTruckCertificates(quote);
    return (
        <div className="d-flex flex-wrap">
            {certificates.map((certificate, index) => (
                <div className="mr-1  mt-1" key={index}>
                    <Certificate key={index} certificate={certificate} />
                </div>
            ))}
        </div>
    );
};

export default QuoteTruckCertificates;
