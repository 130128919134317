import {FactoringCompaniesReducerSearchParams} from 'pages/FactoringCompanies/types/common';
import * as actionTypes from 'pages/FactoringCompanies/redux/actionTypes';
import {listActionCreators} from 'pages/FactoringCompanies/redux/actions';

import {FactoringCompany} from 'core/entities/FactoringCompany/types';

import Pagination from 'types/Pagination';

import {InferActions} from 'store';

export type ListState = {
    searchParams: FactoringCompaniesReducerSearchParams;
    items: FactoringCompany[];
    pagination: Pagination;
    isDeleted: boolean;
};

export const defaultState: ListState = {
    items: [],
    pagination: {
        totalItemsCount: 0,
        currentPage: 1,
        pagesCount: 0,
        perPage: 50,
    },
    searchParams: {},
    isDeleted: false,
};

export type ActionsTypes = InferActions<typeof listActionCreators>;

export default function (state: ListState = defaultState, action: ActionsTypes): ListState {
    switch (action.type) {
        case actionTypes.FACTORING_COMPANIES_RECEIVED: {
            return {
                ...state,
                items: action.payload.factoringCompanies,
            };
        }

        case actionTypes.SET_FACTORING_COMPANIES_PAGINATION: {
            return {
                ...state,
                pagination: {...state.pagination, ...action.payload.pagination},
            };
        }

        case actionTypes.SET_FACTORING_COMPANIES_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: action.payload.searchParams,
            };
        }

        case actionTypes.SET_FACTORING_COMPANIES_LIST_TYPE: {
            return {
                ...state,
                isDeleted: action.payload.isDeleted,
            };
        }

        case actionTypes.CLEAR_FACTORING_COMPANIES_LIST_STATE: {
            return defaultState;
        }

        // no default
    }

    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
