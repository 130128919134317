import {WEB_SOCKET_OWNERS_TRUCKS_UPDATE_RECEIVED} from 'store/middlewares/mainAPISocketsIO/entities/owners/actionTypes';
import {WEB_SOCKET_TRUCKS_COORDINATES_RECEIVED} from 'store/middlewares/mainAPISocketsIO/entities/trucks/actionTypes';

import {Dispatcher} from 'core/entities/Dispatcher/types';
import Owner from 'core/entities/Owner/types';
import {mergeTruckLocationWebsocketUpdate} from 'core/entities/Truck';

import * as types from '../actionTypes';
import * as tabs from '../components/Forms/Tabs/tabConfig';

interface State {
    data: Owner | null;
    dispatchers: Dispatcher[];
    showResetFormModal: boolean;
    showSuccessModal: boolean;
    showUpdateModal: boolean;
    activeTab: string;
}

// exported for testing purposes only
export const defaultState: State = {
    data: null,
    dispatchers: [],

    showResetFormModal: false,
    showSuccessModal: false,
    showUpdateModal: false,

    activeTab: tabs.GENERAL.section,
};

const owner = (state: State = defaultState, action): State => {
    switch (action.type) {
        case types.OWNER_RECEIVED: {
            return {
                ...state,
                data: action.payload,
            };
        }

        case types.OWNER_ARCHIVED: {
            const {owner: archivedOwner} = action.payload as {owner: Owner};
            if (!state.data) {
                return state;
            }
            return {
                ...state,
                data: {...state.data, ...archivedOwner},
            };
        }

        case types.OWNER_GENERAL_NOTE_RECEIVED: {
            if (!state.data) {
                return state;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    generalNotes: [action.payload, ...[state.data?.generalNotes || []]],
                },
            };
        }

        case types.OWNER_GENERAL_NOTE_DELETED: {
            if (!state.data) {
                return state;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    generalNotes: state.data?.generalNotes?.filter?.((item) => item.id !== action.payload.id),
                },
            };
        }

        case WEB_SOCKET_OWNERS_TRUCKS_UPDATE_RECEIVED: {
            if (!state.data) {
                return state;
            }
            const updatedTruck = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    trucks: state.data.trucks.map((item) => {
                        if (parseInt(`${item.id}`, 10) !== parseInt(updatedTruck.id, 10)) {
                            return item;
                        }

                        return {
                            ...item,
                            ...updatedTruck,
                        };
                    }),
                },
            };
        }

        case WEB_SOCKET_TRUCKS_COORDINATES_RECEIVED: {
            if (!state.data) {
                return state;
            }
            const updatedLocation = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    trucks: state.data.trucks.map((item) => {
                        if (parseInt(`${item.id}`, 10) !== parseInt(updatedLocation.truck, 10)) {
                            return item;
                        }

                        return mergeTruckLocationWebsocketUpdate(item, updatedLocation);
                    }),
                },
            };
        }
        case types.TOGGLE_RESET_FORM_MODAL: {
            return {
                ...state,
                showResetFormModal: !state.showResetFormModal,
            };
        }
        case types.TOGGLE_CREATE_MODAL: {
            return {
                ...state,
                showSuccessModal: !state.showSuccessModal,
            };
        }
        case types.TOGGLE_UPDATE_MODAL: {
            return {
                ...state,
                showUpdateModal: !state.showUpdateModal,
            };
        }

        case types.DISPATCHERS_RECEIVED: {
            return {
                ...state,
                dispatchers: action.payload,
            };
        }

        case types.FORM_TAB_CHANGE:
            return {
                ...state,
                activeTab: action.payload.activeTab,
            };

        case types.CLEAR_STATE:
            return defaultState;

        default:
            return state;
    }
};

export default owner;
