import {getTravelOrderStopByID} from 'core/entities/TravelOrder/modules/travelOrderStops';
import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';

import {createDate, getTimeZoneAbbreviation} from 'utils/dateTime';

const getCreatedDateTime = (params: Partial<{dateTime: string; timeZoneFrom: string}>) => {
    const {dateTime, timeZoneFrom} = params;

    const {isValid, date, time} = createDate(dateTime || '', {fromTimeZone: timeZoneFrom || 'utc'});

    return {
        dateInTheClientFormat: isValid ? date : '',
        timeInTheClientFormat: isValid ? time : '',
    };
};

const getTravelOrderStopLoadStopDateTime = (stop?: TravelOrderStop) => {
    const {timeFrame, timeZone, dateEnd, dateStart} = stop?.loadStop || {};

    const createdStartDateTime = getCreatedDateTime({dateTime: dateStart, timeZoneFrom: timeZone});
    const createdEndDateTime = getCreatedDateTime({dateTime: dateEnd, timeZoneFrom: timeZone});
    const timeZoneCode = getTimeZoneAbbreviation(timeZone);

    return {
        dateStartInTheClientFormat: createdStartDateTime.dateInTheClientFormat,
        timeStartInTheClientFormat: createdStartDateTime.timeInTheClientFormat,

        dateEndInTheClientFormat: createdEndDateTime.dateInTheClientFormat,
        timeEndInTheClientFormat: createdEndDateTime.timeInTheClientFormat,

        timeZoneCode,
        timeFrame,
    };
};

export const getTravelOrderStopLoadStopDateTimeByID = (params: StopInfoParams) =>
    getTravelOrderStopLoadStopDateTime(getTravelOrderStopByID(params));
