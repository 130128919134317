import React from 'react';
import Truck from 'core/entities/Truck/types';
import Tooltip from 'components/ui/Tooltip';

import {showTooltip, getTooltipText} from '../utils';

export const TooltipWrapper: React.FC<{truck: Truck; typeTooltipContent?: 'link' | 'button'}> = (props) => {
    const {truck, typeTooltipContent = 'link'} = props;
    if (showTooltip(truck)) {
        return (
            <Tooltip tooltipContent={getTooltipText(truck)} typeContent={typeTooltipContent}>
                {props.children}
            </Tooltip>
        );
    }

    return <>{props.children}</>;
};

export const TooltipWrapperWithCopyToClipBoard: React.FC<{truck: Truck; typeTooltipContent?: 'link' | 'button'}> = (
    props,
) => {
    const {truck, typeTooltipContent = 'link'} = props;
    const toolTipContent = showTooltip(truck) ? (
        <div>
            <div>{getTooltipText(truck)}</div>
            <div>Double click to copy the #{truck.number}</div>
        </div>
    ) : (
        `Double click to copy the #${truck.number}`
    );

    return (
        <Tooltip tooltipContent={toolTipContent} typeContent={typeTooltipContent}>
            {props.children}
        </Tooltip>
    );
};
