import React from 'react';

type OwnProps = {
    toProfileLabel: string;
    entityName: string;
    customEntityName?: string;
};

const SuccessFormCreate: React.FC<OwnProps> = ({entityName, toProfileLabel, customEntityName}) => {
    return (
        <div className="row align-items-center">
            <div className="col-1">
                <i className="fa fa-check feedback-icon-success" />
            </div>
            <div className="col-11">
                <p>
                    <strong>{toProfileLabel}</strong>&nbsp;
                    {customEntityName || <>{entityName}&rsquo;s</>} profile has been created successfully.
                </p>
            </div>
        </div>
    );
};

export default SuccessFormCreate;
