import flowRight from 'lodash/flowRight';

import {SidebarItemFromAPI, SidebarItems} from 'core/entities/AppSettings/types';

import {ROUTES_PATHS, SIDEBAR_ICONS} from 'routing/constants';

const withPath = (item: SidebarItemFromAPI) => {
    return ROUTES_PATHS[item.name] ? {...item, path: ROUTES_PATHS[item.name]} : item;
};

const withIcon = (item: SidebarItemFromAPI) => {
    return SIDEBAR_ICONS[item.name] ? {...item, icon: SIDEBAR_ICONS[item.name]} : item;
};

const withChildren = (fn) => (item: SidebarItemFromAPI) => {
    return item.subMenu ? {...item, subMenu: fn(item.subMenu)} : item;
};

export const getMenuItems = (items: Array<SidebarItemFromAPI>): SidebarItems => {
    return items.map(flowRight([withIcon, withPath, withChildren(getMenuItems)]));
};

export const dateFormatsMap = {
    'dd-mm-yyyy': 'dd-MM-yyyy',
    'dd.mm.yyyy': 'dd.MM.yyyy',
    'dd/mm/yyyy': 'dd/MM/yyyy',
    'mm-dd-yyyy': 'MM-dd-yyyy',
    'mm.dd.yyyy': 'MM.dd.yyyy',
    'mm/dd/yyyy': 'MM/dd/yyyy',
    'yyyy-mm-dd': 'yyyy-MM-dd',
    'yyyy.mm.dd': 'yyyy.MM.dd',
    'yyyy/mm/dd': 'yyyy/MM/dd',
};

export const timeFormatsMap = {
    'hh:mm:ss': 'HH:mm',
    'hh.mm.ss': 'HH.mm',
    'hh,mm,ss': 'HH,mm',
};
