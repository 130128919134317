import isNumber from 'lodash/isNumber';

import {Quote, Payload, DriverReceiver} from 'core/entities/Quote/types';
import Truck from 'core/entities/Truck/types';
import {getTruckTeam as getTruckTeamData} from 'core/entities/Truck/modules/truckTeam';
import {convertToLbs} from 'utils/weightConverter';

import {DimensionItems, QuoteInitialValues, Dimension, TruckDimension} from 'pages/LoadBoard/types';
import {getLoadStops} from './utils';

type QuoteReceiversData = Omit<DriverReceiver, 'id' | 'actionStatus'>;
type QuotePostLoad = Omit<Quote['load'], 'origin' | 'destination'>;

type QuotePostData = Omit<Partial<Quote>, 'load' | 'truck' | 'route' | 'notes' | 'driversReceivers'> & {
    dispatcherID: number;
    load: QuotePostLoad;
    truck: Partial<Quote['truck']>;
    route: {isAirport: boolean; distance: number};
    driversReceivers: QuoteReceiversData[];
    durationInMinutes: number;
    notes: {
        forDrivers?: string;
        forDispatchers?: string;
        aboutQuote?: string;
    };
};

const convertStringToStringArray = (data?: string): string[] | undefined => {
    return data?.length ? data?.split(',') : undefined;
};

const convertDimensionsValueToNumbers = (dimension: Partial<DimensionItems>): DimensionItems | undefined => {
    if (!dimension.length && !dimension.width && !dimension.height) {
        return;
    }
    return {
        length: Number(dimension.length) || 0,
        width: Number(dimension.width) || 0,
        height: Number(dimension.height) || 0,
    };
};

const getTuckTypes = (formQuote: QuoteInitialValues): string[] => formQuote.truck.types;
const getTuckEquipment = (formQuote: QuoteInitialValues): string | undefined => formQuote.truck.equipment;
const getTuckWithDriversTeam = (formQuote: QuoteInitialValues): boolean => Boolean(formQuote.truck.withDriversTeam);
const getDriverCertificates = (formQuote: QuoteInitialValues): string | undefined => formQuote.truck.driverCertificates;
const getDriverCitizenship = (formQuote: QuoteInitialValues): string | undefined => formQuote.truck.driverCitizenship;
const getDriverCrossBorder = (formQuote: QuoteInitialValues): string | undefined => formQuote.truck.driverCrossBorder;
const getWithSigns = (formQuote: QuoteInitialValues): boolean | undefined => formQuote.truck.withSigns;

const getTrucksPayload = (formQuote: QuoteInitialValues): Payload | undefined => {
    const {payload} = formQuote.truck;
    if (payload.value === undefined) {
        return undefined;
    }
    return {
        ...payload,
        value: Number(payload.value),
    };
};

const getTruckDimension = (formQuote: QuoteInitialValues): TruckDimension | undefined => {
    const {dimensions} = formQuote.truck;
    const {unit, ...truckDimension} = dimensions;
    const dimensionValue = convertDimensionsValueToNumbers(truckDimension);

    if (!dimensionValue) {
        return;
    }

    return {
        unit,
        ...dimensionValue,
    };
};

const getFreightPieces = (formQuote: QuoteInitialValues): number => Number(formQuote.load.freight.pieces);
const getFreightWeight = (formQuote: QuoteInitialValues): number => {
    const loadFreightWeightUnit = formQuote.load.freight.weightUnit;
    const loadFreightWeightValue = Number(formQuote.load.freight.weight);
    return convertToLbs(loadFreightWeightUnit, loadFreightWeightValue) || 0;
};
const getFreightDimensions = (formQuote: QuoteInitialValues): Dimension => {
    const {dimensions} = formQuote.load.freight;
    const items = dimensions.items.map((dimension) => convertDimensionsValueToNumbers(dimension));

    return {...dimensions, items: items.filter(Boolean) as DimensionItems[]};
};

const getDriverPayment = (formQuote: QuoteInitialValues): Quote['driverPayment'] | undefined => {
    const {driverPayment} = formQuote;
    if (!isNumber(driverPayment.rpm) || !isNumber(driverPayment.rate)) {
        return;
    }

    return {
        ...driverPayment,
        rate: Number(driverPayment.rate),
        rpm: Number(driverPayment.rpm),
    };
};

const getTruckCurrentLocation = (truck: Truck): DriverReceiver['truckLocation'] => {
    const {status_city_line: truckStatusLocation, status_latitude, status_longitude} = truck;
    if (!truckStatusLocation) {
        return {
            city: '',
            state: '',
            zip: '',
            country: '',
            latitude: null,
            longitude: null,
        };
    }
    const [city, state, zip, country] = truckStatusLocation.split(',').map((item) => item.trim());
    return {
        city,
        state,
        zip,
        country,
        latitude: Number(status_latitude) || null,
        longitude: Number(status_longitude) || null,
    };
};

const getQuoteReceivers = (formQuote: QuoteInitialValues): QuoteReceiversData[] => {
    return formQuote.receivers.reduce<QuoteReceiversData[]>((result, quoteReceiver) => {
        const {truck, isReceiveQuoteAllowed} = quoteReceiver;
        const truckTeam = getTruckTeamData(truck);
        if (!truckTeam.firstDriver?.id) {
            return result;
        }
        const truckCurrentLocation = getTruckCurrentLocation(truck);
        result.push({
            driverID: truckTeam.firstDriver.id,
            truckID: truck.id,
            truckLocation: truckCurrentLocation,
            isReceiveQuoteAllowed,
            distanceToLoad: truck.distance || 0,
        });
        return result;
    }, []);
};

export default function (formQuote: QuoteInitialValues, currentUserID: number): QuotePostData {
    const truckTypes = getTuckTypes(formQuote);
    const {notes, durationInMinutes, radiusSearchDistance} = formQuote;
    const equipment = getTuckEquipment(formQuote);
    const driverCertificates = getDriverCertificates(formQuote);
    const driverCitizenship = getDriverCitizenship(formQuote);
    const driverCrossBorder = getDriverCrossBorder(formQuote);
    const withSigns = getWithSigns(formQuote);
    const stops = getLoadStops(formQuote);
    const receiversList = getQuoteReceivers(formQuote);
    const freightPieces = getFreightPieces(formQuote);
    const freightWeight = getFreightWeight(formQuote);
    const freightDimensions = getFreightDimensions(formQuote);
    const truckPayload = getTrucksPayload(formQuote);
    const truckDimension = getTruckDimension(formQuote);
    const withDriversTeam = getTuckWithDriversTeam(formQuote);
    const driverPayment = getDriverPayment(formQuote);

    return {
        load: {
            ...formQuote.load,
            stops,
            freight: {
                ...formQuote.load.freight,
                pieces: freightPieces,
                weight: freightWeight,
                dimensions: freightDimensions,
            },
        },
        truck: {
            ...formQuote.truck,
            types: truckTypes || [],
            equipment: convertStringToStringArray(equipment),
            driverCertificates: convertStringToStringArray(driverCertificates),
            driverCitizenship: convertStringToStringArray(driverCitizenship),
            driverCrossBorder: convertStringToStringArray(driverCrossBorder),
            payload: truckPayload,
            dimensions: truckDimension,
            withDriversTeam,
            withSigns,
        },
        route: {
            distance: formQuote.route.distance,
            isAirport: Boolean(formQuote.route.isAirport),
        },
        driverPayment,
        driversReceivers: receiversList,
        dispatcherID: currentUserID,
        durationInMinutes,
        radiusSearchDistance,
        notes: {
            forDrivers: notes.forDrivers,
            forDispatchers: notes.forDispatchers,
            aboutQuote: notes.aboutQuote,
        },
    };
}
