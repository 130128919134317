import {useDispatch} from 'react-redux';

import useCheckIfUserHasPermission from 'hooks/useCheckIfUserHasPermission';

import * as attachActions from 'pages/Loads/redux/actions/attach';

import {PERMISSIONS} from 'core/entities/Permissions';
import Carrier from 'core/entities/Carrier/types';
import Truck from 'core/entities/Truck/types';
import Load from 'core/entities/Load/types';

import * as modalActions from '../redux/actions/modalActions';
import {actionCreators} from '../redux/actionCreators/modal';
import {MODAL_NAMES} from '../constants/loadConstants';

type ModalActions = {
    showDocumentsExpirationModal: (load: Load, truck: Truck, keepOpenModalName?: string) => void;
    createTravelOrder: (params: {load: Load; carrier?: Carrier; truck?: Truck}) => void;
    createTravelOrderWithCarrier: (params: {load: Load; carrier?: Carrier}) => void;
    showLoadCompleteWaitingTonuModal: () => void;
    showLoadBackToWaitingTonuModal: () => void;
    showLoadCancelWaitingTonuModal: () => void;
    closeDocumentsExpirationModal: () => void;
    showLoadTravelOrderNotesModal: () => void;
    showLoadBackToAvailableModal: () => void;
    showLoadCompleteTonuModal: () => void;
    showCancelCreateWarning: () => void;
    showLoadInvoiceModal: () => void;
    showLoadCancelModal: () => void;
    showAttachLoadModal: () => void;
    showLoadNotesModal: () => void;
    closeModal: () => void;
};

export default (load?: Load | null): ModalActions => {
    const dispatch = useDispatch();
    const permissionUpdateLoad = useCheckIfUserHasPermission(PERMISSIONS.LOAD_UPDATE);

    return {
        showLoadCancelModal: () =>
            dispatch(actionCreators.showModal({modalName: MODAL_NAMES.cancelLoad, modalData: load})),
        showLoadNotesModal: () => dispatch(modalActions.showLoadNotesModal(load as Load, {permissionUpdateLoad})),
        showLoadTravelOrderNotesModal: () => dispatch(modalActions.showAddLoadTravelOrderGeneralNoteModal()),
        showLoadInvoiceModal: () =>
            dispatch(actionCreators.showModal({modalName: MODAL_NAMES.loadInvoiceModal, modalData: load})),
        showLoadCompleteTonuModal: () =>
            dispatch(actionCreators.showModal({modalName: MODAL_NAMES.completeLoadWithTonuModal, modalData: load})),
        showLoadBackToWaitingTonuModal: () => {
            dispatch(actionCreators.showModal({modalName: MODAL_NAMES.backToWaitingTonuModal, modalData: load}));
        },
        showLoadBackToAvailableModal: () =>
            dispatch(actionCreators.showModal({modalName: MODAL_NAMES.backToAvailableModal, modalData: load})),
        showLoadCompleteWaitingTonuModal: () => {
            dispatch(actionCreators.showModal({modalName: MODAL_NAMES.completeWaitingTonuModal, modalData: load}));
        },
        showLoadCancelWaitingTonuModal: () => {
            dispatch(actionCreators.showModal({modalName: MODAL_NAMES.cancelWaitingTonuModal, modalData: load}));
        },
        showDocumentsExpirationModal: (loadData, truck, keepOpenModalName) => {
            dispatch(
                actionCreators.showModal({
                    modalName: MODAL_NAMES.documentsExpirationModal,
                    modalData: {load: loadData, truck},
                    keepOpenModalName,
                }),
            );
        },
        closeDocumentsExpirationModal: () =>
            dispatch(actionCreators.hideModal({modalName: MODAL_NAMES.documentsExpirationModal})),
        showCancelCreateWarning: () => dispatch(modalActions.showCreateCancelWarning()),
        showAttachLoadModal: () => dispatch(modalActions.showAttachLoadModal(load)),
        createTravelOrder: (params: {load: Load; carrier?: Carrier; truck?: Truck}) =>
            dispatch(attachActions.createTravelOrder(params)),
        createTravelOrderWithCarrier: (params: {load: Load; carrier?: Carrier}) =>
            dispatch(attachActions.createTravelOrderWithCarrier(params)),
        closeModal: () => dispatch(modalActions.closeModal()),
    };
};
