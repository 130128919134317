import {bookingInfoFields} from './fields';

export const getRefNumbersList = (load) => {
    const refNumber = load[bookingInfoFields.refNumber] || '';
    return refNumber.split(',').filter(Boolean);
};

export const getRefNumber = (listOfRefNumbers) => {
    if (!listOfRefNumbers || listOfRefNumbers.length === 0) {
        return '';
    }
    return listOfRefNumbers.filter(Boolean).join(',');
};
