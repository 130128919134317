import React from 'react';
import {ListGroup, ListGroupItem} from 'react-bootstrap';
import classNames from 'classnames';

import {LoadBoardNotification} from 'widgets/Notifications/types';
import useLoadBoardNotificationActions from 'widgets/Notifications/hooks/useLoadBoardNotificationActions';
import QuoteLink from 'widgets/Notifications/components/common/QuoteLink';
import NotificationMessage from 'widgets/Notifications/components/common/NotificationMessage';
import {getDuration} from 'widgets/Notifications/utils';

import closeIcon from 'widgets/Notifications/images/close.svg';
import styles from './notificationList.module.scss';

const NotificationItem: React.FC<{notification: LoadBoardNotification}> = (props) => {
    const {notification} = props;
    const {readNotification, removeNotification} = useLoadBoardNotificationActions();
    const date = getDuration(notification.createdAt);
    const isUnReadStatus = notification.status === 'UNREAD';
    const listGroupClassName = classNames(isUnReadStatus ? `${styles.disabled}` : ``, styles.list_group_item);
    const handleReadNotification = () => {
        if (isUnReadStatus) {
            readNotification(notification);
        }
    };
    return (
        <>
            <ListGroup className={styles.list_group}>
                <ListGroupItem className={listGroupClassName} onClick={handleReadNotification}>
                    <div className={styles.wrapper}>
                        <h5 className={styles.title}>NEW offer!</h5>
                        <p className={styles.date}>{date}</p>
                        <button
                            type="button"
                            className={`${styles.close_button} close`}
                            onClick={() => removeNotification(notification)}
                        >
                            <img src={closeIcon} alt="close" />
                        </button>
                    </div>
                    <div className={styles.message}>
                        <NotificationMessage notification={notification} />
                    </div>
                    <span className="main-link">
                        <QuoteLink quoteNumber={notification.quoteNumber} />
                    </span>
                </ListGroupItem>
            </ListGroup>
        </>
    );
};

export default NotificationItem;
