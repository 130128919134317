import ArchivingSuccess from './ArchivingSuccess';
import ArchivingError from './ArchivingError';
import MergeCustomers from './MergeCustomers';
import ArchivingWarn from './ArchivingWarn';
import ManageAgentModal from './ManageAgentModal';
import WarningAgentModal from './WarningAgentModal';
import SuccessAgentModal from './SuccessAgentModal';

export const modalNames = {
    archivingSuccess: 'ARCHIVING/ARCHIVING_SUCCESS_MODAL',
    archivingError: 'ARCHIVING/ARCHIVING_ERROR_MODAL',
    archivingWarn: 'ARCHIVING/ARCHIVING_WARN_MODAL',
    mergeCustomers: 'MERGE/MERGE_CUSTOMERS_MODAL',
    manageAgentModal: 'CUSTOMERS/AGENT_MANAGE_MODAL',
    warningAgentModal: 'CUSTOMERS/AGENT_WARNING_MODAL',
    successAgentModal: 'CUSTOMERS/AGENT_SUCCESS_MODAL',
    errorAgentModal: 'CUSTOMERS/AGENT_ERROR_MODAL',
};

const modalMap = {
    [modalNames.archivingSuccess]: ArchivingSuccess,
    [modalNames.archivingError]: ArchivingError,
    [modalNames.mergeCustomers]: MergeCustomers,
    [modalNames.archivingWarn]: ArchivingWarn,
    [modalNames.manageAgentModal]: ManageAgentModal,
    [modalNames.warningAgentModal]: WarningAgentModal,
    [modalNames.successAgentModal]: SuccessAgentModal,
    // [modalNames.errorAgentModal]: ErrorAgentModal,
};

export default modalMap;
