export const invoicesEvents = {
    INVOICE_UPDATE: 'invoiceUpdate',
    INVOICE_STATUS_CHANGED_TO_READY_TO_GENERATE: 'invoiceStatusChangedToReadyToGenerate',
    INVOICE_STATUS_CHANGED_TO_GENERATED: 'invoiceStatusChangedToGenerated',
    INVOICE_STATUS_CHANGED_TO_READY_TO_POST: 'invoiceStatusChangedToReadyToPost',
    INVOICE_STATUS_CHANGED_TO_POSTED: 'invoiceStatusChangedToPosted',
    INVOICE_RESET: 'resetInvoice',
    INVOICE_RESET_FROM_BATCH: 'resetInvoiceFromBatch',
    INVOICES_ADD_TO_BATCH: 'addInvoicesToBatch',
    BATCH_CREATE: 'batchCreate',
    BATCH_GENERATE: 'batchGenerate',
    BATCH_UPDATE: 'batchUpdate',
};

export const settlementsCarriersEvents = {
    SETTLEMENT_UPDATE: 'settlementUpdate',
    BATCH_CREATE: 'batchCreate',
    BATCH_ADD_SETTLEMENT: 'batchAddSettlement',
    BATCH_RESET_SETTLEMENT: 'batchResetSettlement',
    BATCH_CLOSE: 'batchClose',
    BATCH_REOPEN: 'batchReopen',
    BATCH_SEND_EMAIL: 'batchSendEmail',
};

export const settlementsOwnersEvents = {
    SETTLEMENT_CREATE: 'settlementCreate',
    SETTLEMENT_UPDATE: 'settlementUpdate',
    PAY_PERIOD_CREATE: 'payPeriodCreate',
    PAY_PERIOD_UPDATE: 'payPeriodUpdate',
    PAY_RECORD_UPDATE: 'payRecordUpdate',
};

export const travelOrdersEvents = {
    CHANGE_LOCATION_SAME_FROM: 'locationSameFrom',
    TRAVEL_ORDER_UPDATE: 'travelOrderUpdate',
    DRY_RUN_CREATED: 'dryRun_created',
    DRY_RUN_UPDATED: 'dryRun_updated',
    DRY_RUN_CONFIRMED: 'dryRun_confirmed',
    DRY_RUN_CANCELED: 'dryRun_canceled',
};

export const loadEvents = {
    FILES_POSTED: 'loadsFilesPosted',
    FILES_DELETED: 'loadsFileDeleted',
    DATA_UPDATED: 'loadUpdated',
};

export const messagesEvents = {
    DRIVER_MESSAGE_RECEIVED: 'driverMessageReceived',
    DISPATCHER_MESSAGE_RECEIVED: 'dispatcherMessageReceived',
};

export const notificationsEvents = {
    CUSTOMER_UNSUCCESSFUL_MERGED: 'customerUnsuccessfulMerged',
    CUSTOMER_SUCCESSFUL_MERGED: 'customerSuccessfulMerged',
};

export const statusLocationEvents = {
    UPDATE: 'statusLocationUpdate',
    BOOK: 'statusLocationBook',
    DELETE: 'statusLocationDelete',
    DELETE_ALL: 'statusLocationDeleteAll',
};

export const trucksEvents = {
    TRUCK_UPDATED: 'truckUpdated',
    STATUS_UPDATED: 'truckStatusUpdated',
    COORDINATES_UPDATED: 'truckCoordinatesUpdated',
    RESERVATION_UPDATED: 'truckReserveUpdated',
};

export const trackingEvents = {
    TIMER_SET: 'timerSetted',
    TIMERS_UPDATED: 'timersUpdated',
    TIMER_DELETED: 'timerDeleted',
    GROUPS_UPDATED: 'groupsUpdated',
    GROUP_DISABLED: 'groupDisabled',
    TRUCK_COORDINATES_UPDATED: 'truckCoordinatesUpdated',
};
