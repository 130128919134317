import * as wsTtypes from 'store/middlewares/mainAPISocketsIO/actionTypes';

interface SessionData {
    wsSocketId: null | string;
    wsConnecting: boolean;
    wsConnectionFailed: boolean;
}

export const defaultState: SessionData = {
    wsSocketId: null,
    wsConnecting: false,
    wsConnectionFailed: false,
};

export default function sessionData(state = defaultState, action): SessionData {
    switch (action.type) {
        case wsTtypes.WEB_SOCKET_INIT:
        case wsTtypes.WEB_SOCKET_RECONNECT:
            return {
                ...state,
                wsConnecting: true,
            };
        case wsTtypes.WEB_SOCKET_CONNECTED:
            return {
                ...state,
                wsSocketId: action.payload.wsSocketId,
                wsConnecting: false,
                wsConnectionFailed: false,
            };

        case wsTtypes.WEB_SOCKET_DISCONNECTED:
            return {
                ...state,
                wsSocketId: null,
                wsConnecting: false,
                wsConnectionFailed: true,
            };

        default:
            return state;
    }
}
