import {CUSTOMER_TYPES} from 'core/entities/Customer/constants/customerTypes';

type CustomerTypesKeys = keyof typeof CUSTOMER_TYPES;
export type CustomerTypes = typeof CUSTOMER_TYPES[CustomerTypesKeys];

export const customerTypesOptions: {label: CustomerTypes; value: CustomerTypes}[] = [
    {label: CUSTOMER_TYPES.BROKER_DOMESTIC, value: CUSTOMER_TYPES.BROKER_DOMESTIC},
    {label: CUSTOMER_TYPES.BROKER_INTERNATIONAL, value: CUSTOMER_TYPES.BROKER_INTERNATIONAL},
    {label: CUSTOMER_TYPES.FREIGHT_FORWARDER_DOMESTIC, value: CUSTOMER_TYPES.FREIGHT_FORWARDER_DOMESTIC},
    {label: CUSTOMER_TYPES.FREIGHT_FORWARDER_INTERNATIONAL, value: CUSTOMER_TYPES.FREIGHT_FORWARDER_INTERNATIONAL},
    {label: CUSTOMER_TYPES.CONSIGNEE_DOMESTIC, value: CUSTOMER_TYPES.CONSIGNEE_DOMESTIC},
    {label: CUSTOMER_TYPES.CONSIGNEE_INTERNATIONAL, value: CUSTOMER_TYPES.CONSIGNEE_INTERNATIONAL},
    {label: CUSTOMER_TYPES.OTHER, value: CUSTOMER_TYPES.OTHER},
];
