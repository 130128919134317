import React, {useCallback, useMemo} from 'react';
import MaskedInput from 'react-text-mask';
import {WrappedFieldProps, WrappedFieldInputProps} from 'redux-form';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import Tooltip from 'components/ui/Tooltip';
import FormGroupWithValidation from 'components/ui/Form/FormGroupWithValidation';
import {validateNumber, removePrefix} from './helpers';

import styles from './currencyInput.module.scss';

interface OwnProps {
    label?: string;
    prefix?: string;
    placeholder?: string;
    isDisabled?: boolean;
    formGroup?: boolean;
    withoutVAT?: boolean;
}

interface MaskOptions {
    prefix: string;
}

function buildCurrencyMask({prefix}: MaskOptions): any {
    return createNumberMask({
        prefix,
        integerLimit: 6,
        allowDecimal: true,
        thousandsSeparatorSymbol: '',
        allowLeadingZeroes: true,
        allowNegative: false,
    });
}

const CurrencyInput = (props: OwnProps & WrappedFieldProps): JSX.Element => {
    const {
        input: {onChange, onBlur, onFocus},
        input,
        meta,
        label,
        isDisabled,
        placeholder,
        formGroup = true,
        prefix = '',
        withoutVAT = true,
    } = props;
    const currencyMask = useMemo(() => buildCurrencyMask({prefix}), []);

    function getNormalizedInputValue(event: React.FormEvent<HTMLInputElement>): number | undefined {
        const {value} = event.currentTarget;

        return removePrefix(value, prefix);
    }

    function decorateEventHandler(handler) {
        return function (event: React.FormEvent<HTMLInputElement>) {
            const normalizedValue = getNormalizedInputValue(event);
            if (!validateNumber(normalizedValue)) {
                handler('');

                return;
            }
            handler(normalizedValue);
        };
    }

    const handleChange = useCallback(decorateEventHandler(onChange), []);
    const handleBlur = useCallback(decorateEventHandler(onBlur), []);

    const inputComponent = (
        <MaskedInput
            value={input.value}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={onFocus}
            disabled={isDisabled}
            mask={currencyMask}
            className="form-control"
            autoComplete="off"
            placeholder={placeholder}
            guide={false}
        />
    );

    if (formGroup) {
        return (
            <FormGroupWithValidation meta={meta} label={label}>
                <>
                    {/** **************** add VAT in 30 sprint **************** */}
                    {/* {withoutVAT && ( */}
                    {/*    <div className={styles.info_tooltip}> */}
                    {/*        <Tooltip className="pic icon-tooltip" tooltipContent="total charges without VAT"> */}
                    {/*            <i className="fa fa-question-circle" /> */}
                    {/*        </Tooltip> */}
                    {/*    </div> */}
                    {/* )} */}
                    {inputComponent}
                </>
            </FormGroupWithValidation>
        );
    }

    return inputComponent;
};

export default CurrencyInput;
