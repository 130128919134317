import React from 'react';

import Button from 'components/ui/Buttons/Button/index';

type OwnProps = {
    isNeedSendEmail: boolean;
    isEmailNoteChanged: boolean;
};

const SendEmailButton: React.FC<OwnProps> = ({isNeedSendEmail, isEmailNoteChanged}) => {
    return (
        <div>
            {!isNeedSendEmail && isEmailNoteChanged && (
                <Button type="submit" colorTheme="blue">
                    Save
                </Button>
            )}
            {isNeedSendEmail && (
                <Button type="submit" colorTheme="blue">
                    Send to Email
                </Button>
            )}
            {!isEmailNoteChanged && !isNeedSendEmail && (
                <Button disabled={true} type="submit" colorTheme="blue">
                    Send to Email
                </Button>
            )}
        </div>
    );
};

export default SendEmailButton;
