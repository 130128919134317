import {DurationLikeObject} from 'luxon';

import {shiftDurationToUnits} from 'utils/dateTime';

export const getDurationStrFrom = (fromUnits: DurationLikeObject) => {
    const [days, hours, minutes] = shiftDurationToUnits(fromUnits, ['days', 'hours', 'minutes'])
        .toFormat('d h mm')
        .split(' ');

    const daysStr = Number(days) ? `${days}d` : '';
    const hoursStr = Number(hours) ? `${hours}h` : '';
    const minutesStr = Number(minutes) ? `${minutes}min` : '';

    return `${daysStr} ${hoursStr} ${minutesStr}`;
};
