import React from 'react';
import {reduxForm, InjectedFormProps} from 'redux-form';

import withNavigationConfirm from 'hocs/withNavigationConfirm';
import Button from 'components/ui/Buttons/Button';

import Truck from 'core/entities/Truck/types';

import {QuoteInitialValues} from 'pages/LoadBoard/types';

import OriginDestinationInfo from './components/OriginDestinationInfo';
import RateInfo from './components/RateInfo';
import LoadFreightInfo from './components/LoadFreightInfo';
import TruckInfo from './components/TruckInfo';
import QuoteReceivers from './components/QuoteReceivers';
import NotesInfo from './components/NotesInfo';
import useRecalculateRpm from './hooks/useRecalculateRpm';
import useRecalculateDistance from './hooks/useRecalculateDistance';
import useCancelConfirmModalActions from './hooks/useCancelConfirmModalActions';
import validate from './validation';
import ClearButton from './components/ClearButton';
import SubmitButton from './components/SubmitButton';
import styles from './styles.module.scss';

type OwnProps = {
    onFetchTrucks: () => Promise<Truck[]>;
    onClose();
};

const Form: React.FC<OwnProps & InjectedFormProps<QuoteInitialValues, OwnProps>> = (props) => {
    const {handleSubmit, onClose, reset, dirty, form, onFetchTrucks} = props;
    const {openCancelConfirmModal} = useCancelConfirmModalActions();

    const handleClose = (): void => {
        if (!dirty) {
            return onClose();
        }
        openCancelConfirmModal(onClose);
    };

    useRecalculateRpm(form);

    useRecalculateDistance(form);

    return (
        <form onSubmit={handleSubmit} className="standard-form-modal">
            <div>
                <div className={styles.wrapper}>
                    <OriginDestinationInfo />
                    <RateInfo />
                    <LoadFreightInfo />
                    <TruckInfo />
                    <QuoteReceivers formName={form} fetchAllTrucks={onFetchTrucks} />
                    <NotesInfo />
                </div>

                <div className="modal-body__bottom">
                    <div>
                        <Button
                            onClick={handleClose}
                            className="mr10"
                            colorTheme="grey"
                            buttonSize="small"
                            defaultFocus={true}
                        >
                            CANCEL
                        </Button>
                        <ClearButton dirty={dirty} reset={reset} />
                    </div>
                    <SubmitButton formName={form} />
                </div>
            </div>
        </form>
    );
};

const WithNavigationControlForm = withNavigationConfirm(Form);

const ReduxForm = reduxForm<QuoteInitialValues, OwnProps>({
    validate,
})(WithNavigationControlForm);

export default ReduxForm;
