import PlannedTravelOrdersModal from 'components/common/TravelOrder/modals/PlannedTravelOrders';
import EditTruckDispatcherNoteModal from 'components/common/Truck/modals/DispatcherNoteModal';
import TruckNotesModal from 'components/common/Truck/modals/TruckNotesModal';

import DuplicatesEntitiesModal from './DuplicatesEntitiesModal';
import InformationModal from './InformationModal';
import Alert from './Alert';
import Confirm from './Confirm';
import NavigationConfirm from './NavigationConfirm';
import SendEmail from './SendEmailModal';
import GeneralNotesModal from './Notes';
import SupportModal from './SupportModal';
import AddTruckLocationModal from './AddTruckLocationModal';
import AddTONotes from './AddTONotes';

export const commonModalNames = {
    informationModal: 'COMMON_MODALS_INFORMATION_MODAL',
    alert: 'COMMON_MODALS_ALERT',
    confirm: 'COMMON_MODALS_CONFIRM',
    navigationConfirm: 'COMMON_MODALS_NAVIGATION_CONFIRM',
    sendEmail: 'COMMON_MODALS_SEND_EMAIL',
    generalNotes: 'COMMON_MODALS_GENERAL_NOTES',
    plannedTravelOrders: 'COMMON_MODALS_PLANNED_TRAVEL_ORDERS',
    supportModal: 'SUPPORT_MODAL',
    duplicatesEntitiesModal: 'DUPLICATES_ENTITIES_MODAL',
    addTruckLocationModal: 'ADD_TRUCK_LOCATION_MODAL',
    addNotesModal: 'TRAVEL_ORDER_ADD_NOTES_MODAL',
    truckDispatcherNoteModal: 'COMMON_MODALS_TRUCK_DISPATCHER_NOTE',
    truckNotesModal: 'TRUCK_NOTES_MODAL',
};

const modalMap = {
    [commonModalNames.informationModal]: InformationModal,
    [commonModalNames.alert]: Alert,
    [commonModalNames.confirm]: Confirm,
    [commonModalNames.navigationConfirm]: NavigationConfirm,
    [commonModalNames.sendEmail]: SendEmail,
    [commonModalNames.generalNotes]: GeneralNotesModal,
    [commonModalNames.plannedTravelOrders]: PlannedTravelOrdersModal,
    [commonModalNames.supportModal]: SupportModal,
    [commonModalNames.addTruckLocationModal]: AddTruckLocationModal,
    [commonModalNames.duplicatesEntitiesModal]: DuplicatesEntitiesModal,
    [commonModalNames.addNotesModal]: AddTONotes,
    [commonModalNames.truckDispatcherNoteModal]: EditTruckDispatcherNoteModal,
    [commonModalNames.truckNotesModal]: TruckNotesModal,
};

export default modalMap;
