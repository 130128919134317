import SelectOption from 'types/SelectOption';
import React, {useMemo, useCallback} from 'react';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector, Field, InjectedFormProps} from 'redux-form';

import {AppState} from 'store';

import {Facility} from 'core/entities/Facility/types';
import FacilitiesSelect from 'components/common/Facility/FacilitiesSelect';
import composeFacilityCityLine from 'core/entities/Facility/composeFacilityCityLine';

import Button from 'components/ui/Buttons/Button';
import TextInput from 'components/ui/Form/TextInput';
import Select from 'components/ui/Form/Select';
import RequiredLabel from 'components/ui/Form/RequiredLabel';
import WeightMeasureInput from 'components/ui/Form/WeightMeasureInput';
import WeightsUnitsOfMeasurement from 'components/ui/Form/WeightsUnitsOfMeasurement';

import {removeNonDigitChars} from 'utils';
import getText from 'utils/getText';

import FormData from './types/FormData';
import validate from './validate';
import styles from './addvirtualStopModal.module.scss';

interface OwnProps {
    isFirstStop: boolean;
    isLastStop?: boolean;
}

interface StateProps {
    isPickUp: boolean;
    selectedFacility?: Facility;
    selectedUnit?: string;
}

interface OwnProps {
    onCancel(): void;
}

const formName = 'add_virtual_stop_to_invoice';

const PickupFields = ({selectedUnit, onChangeUnit}): JSX.Element => {
    const bol = getText('bol').toLowerCase();
    const billOfLading = getText('billOfLading').toLowerCase();
    return (
        <>
            <div className="row">
                <div className="col-3">
                    <Field
                        name="pieces"
                        component={TextInput}
                        label={RequiredLabel('pieces')}
                        maxLength={8}
                        placeholder="pcs"
                        normalize={removeNonDigitChars}
                    />
                </div>
                <div className="col-3">
                    <Field
                        type="text"
                        maxLength={9}
                        name="weight"
                        component={WeightMeasureInput}
                        units="lbs"
                        placeholder="lbs"
                        label={RequiredLabel('weight')}
                    />
                </div>
                <div className="col-3">
                    <div className="measurement-buttons">
                        <WeightsUnitsOfMeasurement unit={selectedUnit} setUnit={onChangeUnit} clearFields={() => {}} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <Field name="bol" component={TextInput} label={`${bol}#`} placeholder={billOfLading} />
                </div>
            </div>
        </>
    );
};

const DeliveryFields = (): JSX.Element => (
    <div className="row">
        <div className="col-6">
            <Field
                name="pod_signed_by"
                placeholder="type name and surname"
                component={TextInput}
                label={RequiredLabel('pod signed by')}
            />
        </div>
    </div>
);

const Form = (props: OwnProps & StateProps & InjectedFormProps<FormData, OwnProps & StateProps>): JSX.Element => {
    const {isFirstStop, isLastStop, handleSubmit, change, onCancel, selectedFacility, selectedUnit, isPickUp} = props;
    const isPickUpItems = useMemo(function (): SelectOption[] {
        return [
            {label: 'pick up', value: true, isDisabled: isLastStop},
            {label: 'delivery', value: false, isDisabled: isFirstStop},
        ];
    }, []);
    const facilityCityLine = useMemo(
        function () {
            if (!selectedFacility) {
                return;
            }

            return composeFacilityCityLine(selectedFacility);
        },
        [selectedFacility],
    );
    const handleIsPickupChange = useCallback(function (value) {
        if (value) {
            change('pod_signed_by', '');
            change('unit', 'lbs');

            return;
        }
        change('pieces', '');
        change('weight', '');
        change('unit', '');
        change('bol', '');
    }, []);

    const handleChangeUnit = (unit) => {
        change('unit', unit);
    };
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className="content">
                <Field
                    name="is_pick_up"
                    component={Select}
                    data={isPickUpItems}
                    label="stop type"
                    isClearable={false}
                    onChange={handleIsPickupChange}
                />
                <Field name="shippers" component={FacilitiesSelect} label="facility" valueAsObject />
                {selectedFacility && (
                    <p>
                        <span>{selectedFacility.address_line}</span>
                        <br />
                        <span>{facilityCityLine}</span>
                    </p>
                )}
                {isPickUp ? (
                    <PickupFields selectedUnit={selectedUnit} onChangeUnit={handleChangeUnit} />
                ) : (
                    <DeliveryFields />
                )}
            </div>
            <div className="footer">
                <Button onClick={onCancel}>cancel</Button>
                <Button colorTheme="blue" type="submit">
                    add stop
                </Button>
            </div>
        </form>
    );
};

const ReduxForm = reduxForm<FormData, OwnProps & StateProps>({form: formName, validate});

export default connect(
    (state: AppState): StateProps => ({
        isPickUp: formValueSelector(formName)(state, 'is_pick_up'),
        selectedUnit: formValueSelector(formName)(state, 'unit'),
        selectedFacility: formValueSelector(formName)(state, 'shippers'),
    }),
)(ReduxForm(Form));
