import React from 'react';
import last from 'lodash/last';

import {Message, NormalizedFile} from '../../types';

type OwnProps = {
    lastMessage: Message;
};

const LastMessageContent: React.FC<OwnProps> = ({lastMessage}) => {
    const lastMessageText = lastMessage.text;
    const lastMessageLastAttachment = last(lastMessage.attachments) as NormalizedFile;
    const {isNotification} = lastMessage;

    const getLastMessageContent = () => {
        if (lastMessageLastAttachment) {
            return (
                <>
                    <i className="fa fa-paperclip" aria-hidden="true" /> {lastMessageLastAttachment.name}
                </>
            );
        }
        if (isNotification) {
            return (
                <div className="notification">
                    <strong>{lastMessageText}</strong>
                </div>
            );
        }

        return lastMessageText;
    };

    return <div className="driver-main-info__text_massage ellips-text">{getLastMessageContent()}</div>;
};

export default LastMessageContent;
