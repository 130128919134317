import React from 'react';

import ArchivingWarnWrapper from 'components/ui/modals/ArchivingWarnWrapper';

import {FactoringCompany} from 'core/entities/FactoringCompany/types';

type OwnProps = {
    handlers: {onCancel: () => void; onConfirm: () => void};
    data: {factoringCompany: FactoringCompany};
    onClose: () => void;
};

const ArchivingWarn: React.FC<OwnProps> = (props) => {
    const {data, handlers, onClose} = props;

    const {onCancel, onConfirm} = handlers || {};
    const {factoringCompany} = data || {};

    return (
        <ArchivingWarnWrapper
            title={factoringCompany.companyName}
            onConfirm={onConfirm}
            onCancel={onCancel}
            onClose={onClose}
        >
            <p>
                <strong>DO YOU WANT TO ARCHIVE THE FACTORING COMPANY?</strong>
            </p>
            <p>
                The archived factoring company`s profile <strong>{factoringCompany.companyName}</strong> will disable
                any actions with it.&nbsp;If needed, you will be able to restore the profile.
            </p>
        </ArchivingWarnWrapper>
    );
};

export default ArchivingWarn;
