import AttachModal from './AttachModal';

export const loadsModalNames = {
    attachLoadModal: 'ATTACH_LOAD_MODAL',
};

const modalMap = {
    [loadsModalNames.attachLoadModal]: AttachModal,
};

export default modalMap;
