import {FactoringCompanyFormValues} from 'pages/FactoringCompanies/types/formTypes';

import {paymentMethods} from 'utils/data/radioInputItems';

export const transformFactoringCompanyDataToRequestBody = (params: {company: FactoringCompanyFormValues}) => {
    const {company} = params;

    const bankTransferFields = {
        account: company.bankingAccount || null,
        routing: company.bankingRouting || null,
    };

    const checkFields = {
        additionalAddressLine: company.bankingAddressLine2 || null,
        addressLine: company.bankingAddressLine1 || null,
        cityLine: {
            country: company.bankingLocation?.country || null,
            state: company.bankingLocation?.state || null,
            city: company.bankingLocation?.city || null,
            zip: company.bankingLocation?.zip || null,
        },
    };
    const paymentSettingLocation = {
        zip: company.bankingLocation?.zip,
        city: company.bankingLocation?.city,
        state: company.bankingLocation?.state,
        country: company.bankingLocation?.country,
        cityLine: company.bankingLocation?.location,
    };

    return {
        companyName: company.companyName || null,

        physicalAddress: {
            additionalAddressLine: company.physicalAddressLine2 || null,
            addressLine: company.physicalAddressLine1 || null,
            cityLine: {
                country: company.physicalLocation?.country || null,
                state: company.physicalLocation?.state || null,
                city: company.physicalLocation?.city || null,
                zip: company.physicalLocation?.zip || null,
            },
        },

        billingAddress: {
            additionalAddressLine: company.billingAddressLine2 || null,
            addressLine: company.billingAddressLine1 || null,
            cityLine: {
                country: company.billingLocation?.country || null,
                state: company.billingLocation?.state || null,
                city: company.billingLocation?.city || null,
                zip: company.billingLocation?.zip || null,
            },
        },

        contactInformation: {
            webAddress: company.contactWebAddress || null,
            contactPerson: company.contactPerson || null,
            email: company.contactEmail || null,
            phone: company.contactPhone || null,
            fax: company.contactFax || null,
            ext: company.contactExt || null,
        },

        paymentSettings: {
            account: company.bankingAccount || null,
            routing: company.bankingRouting || null,
            method: company.bankingPaymentMethod || null,
            addressLine1: company.bankingAddressLine1 || null,
            addressLine2: company.bankingAddressLine2 || null,
            remittanceEmail: company.bankingRemittanceEmail || null,
            location: company.bankingLocation ? paymentSettingLocation : null,

            // fields for <BANK TRANSFER> payment method
            ...(company.bankingPaymentMethod === paymentMethods.bankTransfer.value ? bankTransferFields : {}),

            // fields for <CHECK> payment method
            ...(company.bankingPaymentMethod === paymentMethods.check.value ? checkFields : {}),
        },

        // flag: is_deleted - only for entity restoring
        ...(company.is_deleted ? {is_deleted: false} : {}),
    };
};
