import {AppState} from 'store';

import TravelOrder from 'core/entities/TravelOrder/types';

import {TripMonitorState} from 'pages/TravelOrders/redux/reducers/list';

export const getTripMonitorModalData = (state: AppState): {[key: string]: any} | null => state.tripMonitor.modal.data;
export const getCurrentTravelOrder = (state: AppState): TravelOrder | null => state.tripMonitor.travelOrder.current;
export const getCurrentModalName = (state: AppState) => state.tripMonitor.modal.name;

// list's selectors ↓
export const getTripMonitorTravelOrders = (state: AppState): TripMonitorState['items'] => state.tripMonitor.list.items;
export const getTripMonitorPagination = (state): TripMonitorState['pagination'] => state.tripMonitor.list.pagination;
export const getTripMonitorMetaInformation = (state): TripMonitorState['meta'] => state.tripMonitor.list.meta;
export const getTripMonitorSorting = (state): TripMonitorState['sorting'] => state.tripMonitor.list.sorting;
export const getTripMonitorTab = (state): TripMonitorState['tab'] => state.tripMonitor.list.tab;
export const getTripMonitorSearchParams = (state: AppState): TripMonitorState['searchParams'] =>
    state.tripMonitor.list.searchParams;
export const getTravelOrdersIDsSet = (state: AppState): TripMonitorState['idsSet'] => state.tripMonitor.list.idsSet;
