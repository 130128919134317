import * as appActions from 'store/actions';
import {ThunkAction} from 'redux-thunk';
import {AppState} from 'store';

import {getTravelOrderNumber} from 'core/entities/TravelOrder/modules/common/getTravelOrderNumber';
import {isTravelOrderHasCarrier} from 'core/entities/TravelOrder/modules/travelOrderCarrier';
import * as toRequests from 'core/gateways/TravelOrderApiGateway/requests/indexNew';

import {transformSignedAttachmentsToRequestBody} from 'pages/TravelOrders/redux/mappers/attachments/signedAttachments';
import {generateRateConfirmation} from 'pages/TravelOrders/redux/actions/travelOrder/rateConfirmation';
import {DryRunConfirmFormValues, DryRunModalFormValues} from 'pages/TravelOrders/types/formTypes';
import {getCurrentTravelOrder} from 'pages/TravelOrders/redux/selectors';
import * as dryRunMappers from 'pages/TravelOrders/redux/mappers/dryRun';
import * as modalActions from 'pages/TravelOrders/redux/actions/modals';
import {fetchTO} from 'pages/TravelOrders/redux/actions/travelOrder';

type ThunkActionTypes = ThunkAction<void, AppState, unknown, any>;

export const setDryRun = (dryRunData: DryRunModalFormValues): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const travelOrderNumber = getTravelOrderNumber(travelOrder);

    if (!travelOrderNumber) {
        return false;
    }

    const requestBody = dryRunMappers.transformDryRunDataToRequest({dryRunData, travelOrder});

    try {
        dispatch(appActions.showLoader());

        await toRequests.setTravelOrderDryRunRequest({travelOrderNumber, requestBody});

        await dispatch(generateRateConfirmation());

        dispatch(modalActions.closeModal());

        dispatch(fetchTO(travelOrderNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const editDryRunAttachments = (
    editDryRunData: DryRunModalFormValues,
    filesIds: Record<number, string>,
): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);
    const isTOHasCarrier = isTravelOrderHasCarrier(travelOrder);

    if (!isTOHasCarrier) {
        return;
    }

    const transformedConfirmFiles = transformSignedAttachmentsToRequestBody({
        data: editDryRunData,
        filesIds,
    });

    const {uploadedFormData} = transformedConfirmFiles;

    if (uploadedFormData) {
        const uploadingRequestParams = {toNumber, requestBody: uploadedFormData};
        await toRequests.addTravelOrderAttachmentsRequest(uploadingRequestParams).catch((e) => console.log(e));
    }
};

export const editDryRun = (editDryRunData: DryRunModalFormValues): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const travelOrderNumber = getTravelOrderNumber(travelOrder);

    if (!travelOrderNumber) {
        return;
    }

    const requestBody = dryRunMappers.transformDryRunDataToRequest({dryRunData: editDryRunData, travelOrder});

    try {
        dispatch(appActions.showLoader());

        const {
            data: {files},
        } = await toRequests.editTravelOrderDryRunRequest({travelOrderNumber, requestBody});

        await dispatch(editDryRunAttachments(editDryRunData, files));

        await dispatch(generateRateConfirmation());

        dispatch(modalActions.closeModal());

        dispatch(fetchTO(travelOrderNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const confirmDryRunAttachments = (
    confirmDryRunData: DryRunConfirmFormValues,
    filesIds: Record<number, string>,
): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);
    const isTOHasCarrier = isTravelOrderHasCarrier(travelOrder);

    if (!isTOHasCarrier) {
        return;
    }

    const transformedConfirmFiles = transformSignedAttachmentsToRequestBody({
        data: confirmDryRunData,
        filesIds,
    });

    const {uploadedFormData} = transformedConfirmFiles;

    if (uploadedFormData) {
        const uploadingRequestParams = {toNumber, requestBody: uploadedFormData};
        await toRequests.addTravelOrderAttachmentsRequest(uploadingRequestParams).catch((e) => console.log(e));
    }
};

export const confirmDryRun = (confirmDryRunData: DryRunConfirmFormValues): ThunkActionTypes => async (
    dispatch,
    getState,
) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const travelOrderNumber = getTravelOrderNumber(travelOrder);

    const requestBody = dryRunMappers.transformDryRunConfirmDataToRequest({confirmDryRunData, travelOrder});

    try {
        dispatch(appActions.showLoader());

        const {
            data: {files},
        } = await toRequests.confirmTravelOrderDryRunRequest({travelOrderNumber, requestBody});

        await dispatch(confirmDryRunAttachments(confirmDryRunData, files));

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(travelOrderNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const cancelDryRun = (): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const travelOrderNumber = getTravelOrderNumber(travelOrder);

    if (!travelOrderNumber) {
        return;
    }

    try {
        dispatch(appActions.showLoader());

        await toRequests.cancelTravelOrderDryRunRequest({travelOrderNumber});

        dispatch(modalActions.closeModal());

        await dispatch(fetchTO(travelOrderNumber));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};
