import map from 'lodash/map';

import {Networks} from 'core/entities/Driver/types';

import {RegistryState} from '../index';

export const transformNetworksBenefitsToSelectOptions = (params: {state: RegistryState; networks: Networks[]}) => {
    const {state, networks} = params;

    const networksOptions = map(networks, (value) => ({label: value.name, value: value.id}));

    return {
        ...state,
        networksOptions,
    };
};
