import React from 'react';
import {Field} from 'redux-form';
import RequiredLabel from 'components/ui/Form/RequiredLabel';
import SelectTimeZone from 'components/ui/Form/SelectTimeZone';

import styles from './styles.module.scss';

interface OwnProps {
    name: string;
    isDisabled?: boolean;
}

const Timezone: React.FC<OwnProps> = ({name, isDisabled = false}) => {
    return (
        <div className={styles.wrapper}>
            <Field
                name={name}
                label={RequiredLabel('Time zone')}
                component={SelectTimeZone}
                isClearable={false}
                withLongLabel
                isDisabled={isDisabled}
            />
        </div>
    );
};

export default Timezone;
