import {CROSS_SERVER_TRUCK_RESERVATION_CANCELED, CROSS_SERVER_TRUCK_RESERVATION_COMPLETED} from 'store/actionTypes';
import * as socketsActionTypes from 'store/middlewares/mainAPISocketsIO/entities/trucks/actionTypes';

import Truck from 'core/entities/Truck/types';

import {awaySortValues, SortBy, TNB_TABS} from 'pages/TrucksNearby/constants';
import * as types from 'pages/TrucksNearby/redux/actionTypes';
import * as mappers from 'pages/TrucksNearby/redux/mappers';
import {TrucksNearbySearchFormValues} from 'pages/TrucksNearby/types/formTypes';

import {PlaceAddress} from 'types/Address';
import Pagination from 'types/Pagination';

type Tabs = typeof TNB_TABS[keyof typeof TNB_TABS];

type Sorting = {
    sortBy: SortBy;
};

type LocationPoints = {
    destination: PlaceAddress | null;
    origin: PlaceAddress | null;
};

export type ListState = {
    externalSearchParams: Partial<TrucksNearbySearchFormValues> | null;
    searchParams: Partial<TrucksNearbySearchFormValues>;
    locationPoints: LocationPoints;
    pagination: Pagination;
    expandedIDs: number[];
    sorting: Sorting;
    items: Truck[];
    trucksIdsSet: Set<number>;
    tab: Tabs;
};

const defaultListState: ListState = {
    pagination: {totalItemsCount: 0, currentPage: 1, pagesCount: 0, perPage: 50},
    locationPoints: {origin: null, destination: null},
    sorting: {sortBy: awaySortValues.origin},
    externalSearchParams: null,
    tab: TNB_TABS.OWN,
    searchParams: {},
    expandedIDs: [],
    items: [],
    trucksIdsSet: new Set([]),
};

const listReducer = (tab: Tabs) => (state = defaultListState, action): ListState => {
    if (tab !== state.tab && !(action.type === types.LIST_TAB_RECEIVED)) {
        return state;
    }

    if (
        state.tab === TNB_TABS.PARTNERS &&
        (action.type === socketsActionTypes.WEB_SOCKET_TRUCKS_RESERVE_RECEIVED ||
            action.type === socketsActionTypes.WEB_SOCKET_TRUCKS_RESERVE_CANCELED ||
            action.type === socketsActionTypes.WEB_SOCKET_TRUCKS_UPDATE_RECEIVED)
    ) {
        return state;
    }

    switch (action.type) {
        case types.TRUCKS_RECEIVED: {
            return {
                ...state,
                items: action.payload.items,
                trucksIdsSet: new Set(action.payload.items.map((item) => item.id)),
            };
        }

        case types.PAGINATION_RECEIVED: {
            return {...state, pagination: action.payload.pagination};
        }

        case types.SEARCH_PARAMS_RECEIVED: {
            return mappers.handleReducerBySetSearchParams({state, formData: action.payload.searchParams});
        }

        case types.EXPANDED_IDS_RECEIVED: {
            return {...state, expandedIDs: action.payload.expandedIDs};
        }

        case types.LIST_TAB_RECEIVED: {
            return {...state, tab: action.payload.tab};
        }

        case types.LIST_SORT_BY_RECEIVED: {
            return {...state, sorting: {...state.sorting, sortBy: action.payload.sortBy}};
        }

        case types.LOCATION_POINTS_RECEIVED: {
            return {...state, locationPoints: {origin: action.payload.origin, destination: action.payload.destination}};
        }

        case types.EXTERNAL_SEARCH_PARAMS_RECEIVED: {
            return {...state, externalSearchParams: action.payload.externalSearchParams};
        }

        case types.EXTERNAL_SEARCH_PARAMS_CLEARED: {
            return {...state, externalSearchParams: null};
        }

        case socketsActionTypes.WEB_SOCKET_TRUCKS_RESERVE_RECEIVED: {
            return mappers.handleReducerByWSReserveReceived({state, reserveData: action.payload});
        }

        case socketsActionTypes.WEB_SOCKET_TRUCKS_RESERVE_CANCELED: {
            return mappers.handleReducerByWSReserveCanceled({state, truckID: action.payload});
        }

        case socketsActionTypes.WEB_SOCKET_TRUCKS_UPDATE_RECEIVED: {
            return mappers.handleReducerByWSReserveUpdateReceived({state, newTruck: action.payload});
        }

        case CROSS_SERVER_TRUCK_RESERVATION_COMPLETED: {
            return mappers.handleReducerByCrossServerReservationCompleted({state, reserveData: action.payload});
        }

        case CROSS_SERVER_TRUCK_RESERVATION_CANCELED: {
            return mappers.handleReducerByCrossServerReservationCanceled({state, reserveData: action.payload});
        }

        case types.SSE_DATA_RECEIVED: {
            return mappers.handleReducerByReceivedSSEData({state, data: action.payload.data});
        }

        case types.LIST_STATE_CLEARED: {
            return {...defaultListState, tab: state.tab};
        }

        default:
            return state;
    }
};

export default listReducer;
