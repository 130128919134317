import React from 'react';
import trim from 'lodash/trim';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';

import Button from 'components/ui/Buttons/Button';
import Tooltip from 'components/ui/Tooltip';
import TextInput from 'components/ui/Form/TextInput';
import LoadTypeCheckBoxMulti from 'components/common/Load/LoadTypeCheckBoxMulti';
import TextInputWithDatePicker from 'components/ui/Form/InputWithDatePicker';

import {getTypeFieldNameFactory} from 'utils/typeScript';
import {removeNonDigitChars} from 'utils';

import {BatchInvoicesSearchParams} from '../../types';

import style from './style.module.scss';

interface OwnProps {
    // eslint-disable-next-line react/no-unused-prop-types
    onSubmit(params: any);
    resetSearchParams();
}
const getFieldName = getTypeFieldNameFactory<BatchInvoicesSearchParams>();

const InvoicesSearch = (props: InjectedFormProps<{}, OwnProps> & OwnProps): JSX.Element => {
    const {handleSubmit, reset, pristine, resetSearchParams} = props;

    const resetFilter = () => {
        reset();
        resetSearchParams();
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="custom-form">
                <div className={style.search_fields}>
                    <Field
                        name={getFieldName('invoiceNumber')}
                        component={TextInput}
                        normalize={removeNonDigitChars}
                        type="text"
                        placeholder="invoice#"
                    />
                    <Field
                        name={getFieldName('refNumber')}
                        placeholder="reference#"
                        component={TextInput}
                        normalize={trim}
                    />
                    <div className={style.search_fields__dates}>
                        <Field
                            name={getFieldName('dateFrom')}
                            type="text"
                            className="form-control"
                            placeholder="from"
                            component={TextInputWithDatePicker}
                        />
                        <span>-</span>
                        <Field
                            name={getFieldName('dateTo')}
                            type="text"
                            className="form-control"
                            placeholder="to"
                            component={TextInputWithDatePicker}
                        />
                    </div>
                    <LoadTypeCheckBoxMulti name={getFieldName('loadType')} />
                    <Tooltip tooltipContent="clear fields" typeContent="button">
                        <Button
                            onClick={resetFilter}
                            disabled={pristine}
                            buttonSize="icon"
                            colorTheme="grey"
                            buttonIcon="undo"
                        />
                    </Tooltip>
                    <Button
                        disabled={pristine}
                        className="submit-btn apply"
                        type="submit"
                        colorTheme={pristine ? 'grey' : 'green'}
                    >
                        Apply and Search
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default reduxForm<{}, OwnProps>({
    form: 'batch-invoices-search-form',
})(InvoicesSearch);
