import axios from 'axios';

import {store} from 'store';
import {getUserAccessToken} from 'store/reducers/auth/selectors';

import {getChatClientSocketId} from 'widgets/Chat/redux/selectors';

const chatApi = axios.create({baseURL: '/api/chat'});

// add user auth token to each ajax request
chatApi.interceptors.request.use(
    (config) => {
        const state = store.getState();

        const clientSocketId = getChatClientSocketId(state);
        const userAuthKey = getUserAccessToken(state);

        config.headers['Accept-Language'] = 'en';

        if (config.url === '/channels/authenticate') {
            config.headers.Authorization = `Bearer ${userAuthKey}`;
        }

        config.headers.clientSocketId = clientSocketId;

        return config;
    },
    (error) => Promise.reject(error),
);

chatApi.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error.response);
    },
);

export default chatApi;
