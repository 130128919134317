import isString from 'lodash/isString';

import {openChangeStatusFailedModal} from './modalActions';

const BAD_REQUEST_STATUS = 400;
const settlementShouldBeReadyToPostMsg = 'Settlement must be in status Ready to Post, current status Posted';

const getErrorWithTextMessage = (error, message) => {
    const errorStatus = error.status;
    const errorMessage = isString(error.data) ? error.data : undefined;
    return errorStatus === BAD_REQUEST_STATUS && errorMessage && errorMessage.includes(message);
};

export const handleChangeSettlementStatusError = (error, dispatch) => {
    const isSettlementAlreadyInStatus = getErrorWithTextMessage(error, 'Settlement already in status:');
    const isSettlementAlreadyResetFromBatch = getErrorWithTextMessage(error, 'Settlement is already reset from batch');
    const isSettlementPayPeriodAlreadyClosed = getErrorWithTextMessage(
        error,
        'Can not reset settlement from closed pay period',
    );
    const isSettlementMustBeReadyToPost = getErrorWithTextMessage(error, settlementShouldBeReadyToPostMsg);
    const isSettlementInappropriateStatus = getErrorWithTextMessage(error, ' only. Current status is ');

    if (
        isSettlementAlreadyInStatus ||
        isSettlementAlreadyResetFromBatch ||
        isSettlementPayPeriodAlreadyClosed ||
        isSettlementMustBeReadyToPost ||
        isSettlementInappropriateStatus
    ) {
        const message = isSettlementPayPeriodAlreadyClosed
            ? 'Can not reset settlement from closed pay period.'
            : undefined;
        dispatch(openChangeStatusFailedModal(message));
    }

    return Promise.reject(error);
};

export const handleCreteBatchError = (error, dispatch) => {
    const isIncorrectSettlement = getErrorWithTextMessage(error, 'has opened batch');
    const isSettlementMustBeReadyToPost = getErrorWithTextMessage(error, settlementShouldBeReadyToPostMsg);
    if (isIncorrectSettlement || isSettlementMustBeReadyToPost) {
        dispatch(openChangeStatusFailedModal());
    }
    return Promise.reject(error);
};

export const handleCloseBatchError = (error, dispatch) => {
    const isIncorrectSettlement = getErrorWithTextMessage(error, 'Batch already closed');
    if (isIncorrectSettlement) {
        dispatch(openChangeStatusFailedModal());
    }
    return Promise.reject(error);
};

export const handleOpenBatchError = (error, dispatch) => {
    const isIncorrectSettlement = getErrorWithTextMessage(error, 'Batch already opened');
    if (isIncorrectSettlement) {
        dispatch(openChangeStatusFailedModal());
    }
    return Promise.reject(error);
};

export const handleChangeSettlementPayPeriodError = (error, dispatch) => {
    const isSettlementPayPeriodAlreadyChanged = getErrorWithTextMessage(error, 'Settlement pay period already changed');
    const isSettlementPayPeriodAlreadyOpened = getErrorWithTextMessage(error, 'Pay Period already opened');
    const isSettlementPayPeriodAlreadyClosed = getErrorWithTextMessage(error, 'Pay Period already closed');
    const isSettlementPayPeriodAlreadyCreated = getErrorWithTextMessage(error, 'param intersect with exists period');
    const isSettlementInappropriateStatus = getErrorWithTextMessage(error, ' only. Current status is ');

    if (
        isSettlementPayPeriodAlreadyChanged ||
        isSettlementPayPeriodAlreadyOpened ||
        isSettlementPayPeriodAlreadyClosed ||
        isSettlementPayPeriodAlreadyCreated ||
        isSettlementInappropriateStatus
    ) {
        dispatch(openChangeStatusFailedModal());
    }

    return Promise.reject(error);
};
