import DeleteDriverModal from './DeleteDriverModal';
import RestoreDriverModal from './RestoreDriverModal';
import ChooseOwnerModal from './ChooseOwnerModal';
import PasswordModal from './PasswordModal';
import SuccessfulChangePasswordModal from './SuccessfulChangePasswordModal';
import ResetAllFields from './ResetAllFields';
import ClearAllFields from './ClearAllFields';
import ArchivingError from './ArchivingError';
import ArchivingWarn from './ArchivingWarn';
import ArchivingSuccess from './ArchivingSuccess';
import RestoreDriverAccountModal from './RestoreDriverAccountModal';

export const modalNames = {
    passwordModal: 'DRIVERS_PASSWORD_MODAL',
    chooseOwnerModal: 'DRIVERS_CHOOSE_OWNER_MODAL',
    deleteDriverModal: 'DRIVERS_DELETE_DRIVER_MODAL',
    restoreDriverModal: 'DRIVERS_RESTORE_DRIVER_MODAL',
    resetFormModal: 'DRIVERS_RESET_FORM_MODAL',
    clearFormModal: 'DRIVERS_CLEAR_FORM_MODAL',
    successfulChangePasswordModal: 'DRIVERS_SUCCESSFUL_CHANGE_PASSWORD_MODAL',
    archivingError: 'DRIVERS_ARCHIVING_ERROR_MODAL',
    archivingWarn: 'DRIVERS_ARCHIVING_WARN_MODAL',
    archivingSuccess: 'DRIVERS_ARCHIVING_SUCCESS_MODAL',
    restoreDriverAccountModal: 'RESTORE_DRIVER_ACCOUNT_MODAL',
};

const modalMap = {
    [modalNames.deleteDriverModal]: DeleteDriverModal,
    [modalNames.restoreDriverModal]: RestoreDriverModal,
    [modalNames.chooseOwnerModal]: ChooseOwnerModal,
    [modalNames.passwordModal]: PasswordModal,
    [modalNames.successfulChangePasswordModal]: SuccessfulChangePasswordModal,
    [modalNames.resetFormModal]: ResetAllFields,
    [modalNames.clearFormModal]: ClearAllFields,
    [modalNames.archivingError]: ArchivingError,
    [modalNames.archivingWarn]: ArchivingWarn,
    [modalNames.archivingSuccess]: ArchivingSuccess,
    [modalNames.restoreDriverAccountModal]: RestoreDriverAccountModal,
};

export default modalMap;
