import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import websocket from 'services/auctionApi/websocket';
import useCurrentDispatcher from 'hooks/useCurrentDispatcher';

import {receivedNewLoadBoardNotificationWS} from '../redux/actionCreators';

const useConnectToAuctionNotifications = () => {
    const dispatch = useDispatch();
    const currentDispatcher = useCurrentDispatcher();
    useEffect(() => {
        let disconnectOnDidMount;

        const connect = async () => {
            const {
                subscribeOnDriverOfferCreatedDispatcherNotification,
                unSubscribeFromDriverOfferCreatedDispatcherNotification,
            } = await websocket();

            subscribeOnDriverOfferCreatedDispatcherNotification((eventData) => {
                const notification = eventData.payload;
                const isNotificationForCurrentDispatcher = currentDispatcher.id === notification.dispatcherReceiver.id;
                if (isNotificationForCurrentDispatcher) {
                    dispatch(receivedNewLoadBoardNotificationWS(notification));
                }
            });

            disconnectOnDidMount = () => {
                unSubscribeFromDriverOfferCreatedDispatcherNotification();
            };
        };

        connect().catch((error) => console.error('Error connect to auction notifications!', error));

        return () => {
            if (disconnectOnDidMount) {
                disconnectOnDidMount();
            }
        };
    }, []);
};

export default useConnectToAuctionNotifications;
