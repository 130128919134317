import {fetchEventSource} from '@microsoft/fetch-event-source';

import {ThunkDispatchCustom} from 'store';
import {logoutWithReload} from 'store/actions';

import appConfig from 'config';

import {HTTP_UNAUTHORIZED} from 'services/restapi/constants';

import {handlersMap, pathsMap} from './constants';

const API_URL = 'api/sse/';

class EventSourceService {
    constructor({token, dispatch}) {
        this.token = token;
        this.dispatch = dispatch;
        this.controller = null;
    }

    token: string;

    dispatch: ThunkDispatchCustom;

    controller: AbortController | null;

    private getUrlByPathName = (pathName) =>
        `${API_URL}${pathsMap[pathName]}_${appConfig.REACT_APP_CLUSTER}_${appConfig.REACT_APP_PREFIX}`;

    private parseData = (data: string) => {
        try {
            return JSON.parse(data);
        } catch (e) {
            console.error('sse data has been parsed with error', e);
        }
    };

    private onDataReceived = (pathName: string, data?: string) => {
        const handler = handlersMap[pathName];

        if (!data || !handler) {
            return;
        }

        handler({dispatch: this.dispatch, data: this.parseData(data)});
    };

    connect = async (pathName: string) => {
        if (!pathsMap[pathName]) {
            return;
        }

        this.controller = new AbortController();

        await fetchEventSource(this.getUrlByPathName(pathName), {
            headers: {Authorization: `Bearer ${this.token}`},
            signal: this.controller.signal,
            openWhenHidden: true,
            // eslint-disable-next-line require-await
            onopen: async (response) => {
                // eslint-disable-next-line no-console
                console.info(`✅ sse has opened with status code: ${response.status}`);

                if (response.status === HTTP_UNAUTHORIZED) {
                    this.dispatch(logoutWithReload());
                }
            },
            onmessage: (event) => this.onDataReceived(pathName, event.data),
            onclose: () => console.error('sse server has closed the connection unexpectedly'),
            onerror: (err) => console.error(err),
        });
    };

    disconnect = () => {
        this.controller?.abort();
        this.controller = null;
    };
}

export default EventSourceService;
