import React from 'react';

import {SettlementOwner} from 'core/entities/Settlement/types';

interface Props {
    settlement: SettlementOwner;
}

const SettlementTruckInfo = ({settlement}: Props): JSX.Element | null => {
    return (
        <>
            <div>
                <strong>#{settlement.truck_number}</strong> {settlement.truck_type}
            </div>
            <div>{settlement.first_driver_name}</div>
        </>
    );
};

export default SettlementTruckInfo;
