/* eslint "no-magic-numbers": "off" */

import loadGoogleMapsAPI from 'load-google-maps-api';
import head from 'lodash/head';
import last from 'lodash/last';

import {store} from 'store';
import * as appActions from 'store/actions';
import appSettingsActions from 'store/reducers/appSettings/actionCreators';
import {setMapApiIsLoaded} from 'store/reducers/commonData/actionCreators';

import makeChainedChunks from 'utils/makeChainedChunks';

import {apiKeys} from './apiKeys';

const googleApiStr = 'script[src*="maps.googleapis.com/maps/api/js?callback=__googleMapsApiOnLoadCallback"]';

export function checkGoogleApiScript() {
    const {getState, dispatch} = store;

    const tag = document.querySelector(googleApiStr);

    if (!getState().app.mapApiLoaded && tag) {
        dispatch(setMapApiIsLoaded());
    }
}

/**
 * Loads script tag with map library with options
 */
function loadScriptApi(dispatch, mapApiKey) {
    const options = {
        key: mapApiKey.key,
        language: 'en',
        libraries: ['geometry', 'drawing', 'places'],
    };

    return loadGoogleMapsAPI(options)
        .then(() => {
            dispatch(appSettingsActions.setMapApiKeys(mapApiKey));
            dispatch(setMapApiIsLoaded());
        })
        .catch((err) => {
            console.error(err);
        });
}

export function loadMapApi() {
    const state = store.getState();

    const index = state.appSettings?.mapApiKey?.id - 1;

    const mapApiKey = {
        id: apiKeys[index]?.id,
        key: apiKeys[index]?.key,
        email: apiKeys[index]?.email,
    };

    // if page doesn't have a script tag with maps api, then insert script tag
    if (state.app.mapApiLoaded === false) {
        loadScriptApi(store.dispatch, mapApiKey);
    }
}

export function refreshApiToken() {
    const {dispatch} = window.__store__;

    dispatch(appActions.hideLoader());
    dispatch(appActions.showAlert('Sorry, geocoding failed!', 'danger'));
}

export function requestDirections(points, params) {
    let tryAgain = true;

    const defaultParams = {
        origin: points.origin,
        destination: points.destination,
        waypoints: points.waypoints,
        optimizeWaypoints: false,
        travelMode: window.google.maps.TravelMode.DRIVING,
    };

    const fetchData = (directionsService, resolve) => {
        directionsService.route({...defaultParams, ...params}, (response, status) => {
            if (status === 'OVER_QUERY_LIMIT' && tryAgain) {
                setTimeout(() => {
                    fetchData(directionsService, resolve);
                    tryAgain = false;
                }, 500);
            } else if (status === 'OVER_QUERY_LIMIT') {
                console.error('GOOGLE API ERROR -> OVER QUERY LIMIT');
                refreshApiToken();
            } else {
                resolve({data: response, status});
            }
        });
    };

    return new Promise((resolve) => {
        const directionsService = new window.google.maps.DirectionsService();
        fetchData(directionsService, resolve);
    });
}

const formatAddress = (address) => ({
    ...address,
    streetNumber: address.streetNumber,
    street: address.street,
    city: address.city,
    state: address.state,
    zip: address.zip,
    country: address.country,
});

const parseAddressComponents = (addressComponents) => {
    const address = {
        streetNumber: 'unknown street number',
        street: 'unknown street',
        city: 'unknown city',
        state: 'unknown state',
        zip: 'unknown zip code',
        country: 'unknown country',

        hasStreetNumber: false,
        hasStreet: false,
        hasCity: false,
        hasState: false,
        hasZip: false,
        hasCountry: false,

        hasCityLine: false,
    };

    addressComponents.forEach((component) => {
        switch (component.types[0]) {
            case 'street_number':
                address.streetNumber = component.short_name;
                address.hasStreetNumber = true;
                break;
            case 'route':
                address.street = component.long_name;
                address.hasStreet = true;
                break;
            case 'locality':
                address.city = component.long_name;
                address.hasCity = true;
                break;
            case 'administrative_area_level_1':
                address.state = component.short_name;
                address.hasState = true;
                break;
            case 'postal_code':
                address.zip = component.short_name;
                address.hasZip = true;
                break;
            case 'country':
                address.country = component.short_name;
                address.hasCountry = true;
                break;
            default:
                break;
        }
    });

    const {hasCity, hasState, hasZip, hasCountry} = address;

    if (hasCity && hasState && hasZip && hasCountry) {
        address.hasCityLine = true;
    }

    return formatAddress(address);
};

/**
 * Returns reduced array with first, last and every "delayNum" element
 * @param delayNum
 * @param fullArr
 * @returns {Array}
 */
const reduceArr = (delayNum, fullArr) => {
    const len = fullArr.length;
    const newArr = [];

    for (let i = 0; i < len; i += delayNum) {
        // push first and every delayNum* element
        if (i !== len - 1) {
            newArr.push(fullArr[i]);
        }
    }

    // push last point
    newArr.push(fullArr[len - 1]);
    return newArr;
};

export const parsePlacesResults = (results) => {
    const address = parseAddressComponents(results.address_components);

    return {
        address,
        lat: results.geometry.location.lat(),
        lng: results.geometry.location.lng(),
        placeId: results.place_id,
    };
};

export function requestPlaceDetails({placeId}) {
    let tryAgain = true;

    const fetchData = (placesService, resolve) => {
        placesService.getDetails(
            {
                placeId,
                fields: ['name', 'geometry.location', 'place_id', 'formatted_address', 'address_components'],
            },
            (response, status) => {
                if (status === 'OVER_QUERY_LIMIT' && tryAgain) {
                    setTimeout(() => {
                        fetchData(placesService, resolve);
                        tryAgain = false;
                    }, 500);
                } else if (status === 'OVER_QUERY_LIMIT') {
                    console.error('GOOGLE API ERROR -> OVER QUERY LIMIT');
                    refreshApiToken();
                } else {
                    resolve({data: parsePlacesResults(response), status});
                }
            },
        );
    };

    return new Promise((resolve) => {
        const fakeMapContainer = document.createElement('DIV');
        const placesService = new window.google.maps.places.PlacesService(fakeMapContainer);
        fetchData(placesService, resolve);
    });
}

export function requestPlaces({value, searchOptions, searchCountries}) {
    let tryAgain = true;

    const fetchData = (autocompleteService, resolve) => {
        autocompleteService.getPlacePredictions(
            {
                input: value,
                types: searchOptions,
                componentRestrictions: {country: searchCountries},
            },
            (response, status) => {
                if (status === 'OVER_QUERY_LIMIT' && tryAgain) {
                    setTimeout(() => {
                        fetchData(autocompleteService, resolve);
                        tryAgain = false;
                    }, 100);
                } else if (status === 'OVER_QUERY_LIMIT') {
                    console.error('GOOGLE API ERROR -> OVER QUERY LIMIT');
                    refreshApiToken();
                } else if (response) {
                    const results = response.map((place) => ({
                        title: place.description,
                        placeId: place.place_id,
                        details: place,
                    }));
                    resolve({data: results, status});
                }
            },
        );
    };

    return new Promise((resolve) => {
        const autocompleteService = new window.google.maps.places.AutocompleteService();
        fetchData(autocompleteService, resolve);
    });
}

export function formatCoordinates(obj) {
    const lat = obj.lat || obj.latitude;
    const lng = obj.lng || obj.longitude;
    return {lat: parseFloat(lat), lng: parseFloat(lng)};
}

/**
 * Checks array length and if array too big, returns reduced array
 * (example if fullArr.length == 500, returns [fullArr[0], fullArr[5], fullArr[10] ... fullArr[fullArr.length-1]])
 * @param fullArr
 * @returns {*}
 * returns approximately value of 'max' argument
 */
export function reducePath(fullArr, max) {
    const len = fullArr.length;
    let delayNum;

    if (len > 100 && len < 200) {
        delayNum = Math.floor(len / (max / 2));
        delayNum = delayNum === 0 ? 1 : delayNum;
        return reduceArr(delayNum, fullArr);
    }

    if (len >= 200) {
        delayNum = Math.floor(len / max);
        delayNum = delayNum === 0 ? 1 : delayNum;
        return reduceArr(delayNum, fullArr);
    }

    return fullArr;
}

const strToLatLng = (lat, lng) => ({lat: parseFloat(lat), lng: parseFloat(lng)});

export const convertPointsChunksToDirectionsRequestFormat = (pointsChunks) => {
    const directionsRequests = [];

    pointsChunks.forEach((chunk) => {
        if (!Array.isArray(chunk)) {
            return;
        }
        const origin = head(chunk);
        const destination = last(chunk);
        const waypoints = chunk.slice(1, -1);

        directionsRequests.push({
            origin: strToLatLng(origin.lat, origin.lng),
            destination: strToLatLng(destination.lat, destination.lng),
            waypoints: waypoints.map((point) => ({
                location: strToLatLng(point.lat, point.lng),
                stopover: true,
            })),
        });
    });
    return {directionsRequests};
};

/**
 * for 'google maps directionsService'
 * google api allows only 24 points (origin, destination and 22 waypoints)
 */
export function makeDirectionsRequestData(allPoints, {reducePoints = true, chunkSize = 24} = {}) {
    const locationPoints = reducePoints ? reducePath(allPoints, 100) : allPoints;
    const chainedPointsChunks = makeChainedChunks(locationPoints, chunkSize);
    const {directionsRequests} = convertPointsChunksToDirectionsRequestFormat(chainedPointsChunks);
    return {directionsRequests};
}

export function makeDirectionsRequestDataFromAllChainedRoutePoints(chainedPointsChunks) {
    const {directionsRequests} = convertPointsChunksToDirectionsRequestFormat(chainedPointsChunks);
    return {directionsRequests};
}
