import React from 'react';
import Truck from 'core/entities/Truck/types';
import {getMainContactPhone} from './utils';

import styles from './styles.module.scss';

const Phone: React.FC<{truck: Truck}> = (props) => {
    const {truck} = props;

    return (
        <td className={styles.wrapper}>
            <span className="is-main">{getMainContactPhone(truck)}</span>
        </td>
    );
};

export default Phone;
