import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';

import {createDateTimeObject, getDifferenceBetweenTwoDateTimes, getNowWithTimeZone} from 'utils/dateTime';
import {getCityLineFromString} from 'utils/getCityLine';

import {isTruckStatusAvailable, isTruckStatusOnRoute, isTruckStatusWillBeAvailable} from './statuses';

const isOwnerResponsiblePersonOnTruck = (truck) => Boolean(truck?.responsible);

export const isTruckStatusUpdatedManual = (truck) => truck && !isEmpty(truck.status_dispatcher);

export const isTruckStopped = (truck) => {
    if (!truck || !isTruckStatusOnRoute(truck.status) || !truck.location_same_from) {
        return false;
    }

    const timeOfSameLocation = truck.location_same_from;
    const now = getNowWithTimeZone('utc');
    const duration = getDifferenceBetweenTwoDateTimes({
        dateTimeStart: now,
        dateTimeEnd: createDateTimeObject({dateTime: timeOfSameLocation, timeZone: 'utc'}),
    });
    const diffInMinutes = duration.minutes;
    const maxCountMinutesInSamePlace = 20;

    return diffInMinutes > maxCountMinutesInSamePlace;
};

export const getResponsiblePerson = (truck) => {
    if (!truck || isEmpty(truck)) {
        return null;
    }

    const {owner, drivers} = truck;

    if (isOwnerResponsiblePersonOnTruck(truck)) {
        return owner;
    }

    if (truck.is_deleted) {
        return head(drivers);
    }

    return drivers.find((d) => d.is_main);
};

export const getTruckLocationCityLine = (truck, {useLastCityLine} = {}) => {
    // status_city_line = Availability Location(Final Location)
    // last_city_line = Last Known Location(Current Location)
    const {status, status_city_line, last_city_line} = truck;

    const isCommonStatuses =
        isTruckStatusWillBeAvailable(status) || isTruckStatusAvailable(status) || isTruckStatusOnRoute(status);

    if (isCommonStatuses && !useLastCityLine) {
        return getCityLineFromString(status_city_line);
    }

    if (!isCommonStatuses && !useLastCityLine) {
        return '';
    }

    if (isCommonStatuses && useLastCityLine) {
        return getCityLineFromString(last_city_line);
    }

    if (!isCommonStatuses && useLastCityLine) {
        return getCityLineFromString(last_city_line);
    }

    return '';
};

export const getTruckDriversAccesses = (truck) => {
    if (!truck || !truck.drivers || truck.drivers.length === 0) {
        return {};
    }
    const teamLength = 2;
    return {
        isCanada: truck.drivers.every((d) => d?.canada),
        isMexico: truck.drivers.every((d) => d?.mexico),

        isGreatBritain: truck.drivers.every((d) => d?.great_britain),
        isSwitzerland: truck.drivers.every((d) => d?.switzerland),

        isHazmat: truck.drivers.some((d) => d?.hazmat_certificate),
        isTSA: truck.drivers.some((d) => d?.tsa_certificate),
        isTWIC: truck.drivers.some((d) => d?.twic_certificate),
        isTanker: truck.drivers.some((d) => d?.tanker_endorsement),

        isAdr: truck.drivers.some((d) => d?.adr_certificate),
        isMiLog: truck.drivers.some((d) => d?.mi_log_certificate),
        isLoiMacron: truck.drivers.some((d) => d?.loi_macron_certificate),

        isTeam: truck.drivers.length === teamLength,
    };
};

export const isTruckOwnerAlsoDriver = (truck) => {
    const {drivers} = truck;
    if (!drivers || !drivers.length) {
        return false;
    }
    return drivers.some((d) => d?.is_owner);
};

export const mergeTruckLocationWebsocketUpdate = (truckObject, locationObject) => ({
    ...truckObject,
    last_city_line: locationObject.city_line || truckObject.last_city_line,
    updated_at: locationObject.time,
    coordinates: [
        {
            ...locationObject,
            truck_id: locationObject.truck,
            city_line: locationObject.city_line,
        },
    ],
});
