import {WEB_SOCKET_DISPATCHER_MESSAGE_RECEIVED, WEB_SOCKET_DRIVER_MESSAGE_RECEIVED} from './actionTypes';

export function driverMessageReceived(message) {
    return {
        type: WEB_SOCKET_DRIVER_MESSAGE_RECEIVED,
        payload: message,
    };
}

export function dispatcherMessageReceived(message) {
    return {
        type: WEB_SOCKET_DISPATCHER_MESSAGE_RECEIVED,
        payload: message,
    };
}
