import config from 'config';

import {File as StoredFile, FileNew as StoredFileNew, TOFile} from 'types/File';

import {isStoredFile, isNewStoredFile} from 'utils/files';

const getThumbPath = (file?: StoredFile | StoredFileNew | TOFile | null): string => {
    if (!file) {
        return '';
    }

    if (isNewStoredFile(file)) {
        return `${config.PUBLIC_URL}/web/${file.thumbName}`;
    }

    if (isStoredFile(file)) {
        return `${config.PUBLIC_URL}/web/${file.thumb}`;
    }

    return '';
};

export default getThumbPath;
