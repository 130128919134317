import {push} from 'connected-react-router';

import {getFactoringCompaniesByTabType} from 'pages/FactoringCompanies/redux/actions';
import {modalNames} from 'pages/FactoringCompanies/components/modals/modalMap';
import * as actionTypes from 'pages/FactoringCompanies/redux/actionTypes';

import * as requests from 'core/gateways/FactoringCompaniesApiGateway/requests';
import {FactoringCompany} from 'core/entities/FactoringCompany/types';

import * as modalActions from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import {getFactoringCompaniesLinks} from 'routing/linkBuilder';

import * as appActions from 'store/actions';

export const archivingActionCreators = {
    factoringCompanyArchivedFromList: (payload: {company: FactoringCompany}) =>
        ({type: actionTypes.FACTORING_COMPANY_ARCHIVED_FROM_LIST, payload} as const),
    factoringCompanyArchivedFromCard: (payload: {company: FactoringCompany}) =>
        ({type: actionTypes.FACTORING_COMPANY_ARCHIVED_FROM_CARD, payload} as const),
};

const navigateToUpdatePage = (company: FactoringCompany) => (dispatch) => {
    const {update: pathToUpdatePage} = getFactoringCompaniesLinks(company);
    dispatch(push(pathToUpdatePage));
};

const navigateToListPage = (company: FactoringCompany) => (dispatch) => {
    const {list: pathToListPage} = getFactoringCompaniesLinks(company);
    dispatch(push(pathToListPage));
};

const showSuccessArchiving = (params: {company: FactoringCompany}) => (dispatch) => {
    const {company} = params;

    const viewArchivedTrucksAction = () => {
        dispatch(navigateToListPage(company));
        dispatch(modalActions.closeAll());
        dispatch(getFactoringCompaniesByTabType({tabType: 'archived'}));
    };

    const modalHandlers = {onViewArchivedList: viewArchivedTrucksAction};
    const modalName = modalNames.archivingSuccess;
    const modalData = {factoringCompany: company};

    dispatch(modalActions.closeAll());

    dispatch(modalActions.openModal({modalName, data: modalData, handlers: modalHandlers}));
};

const showArchivingWarning = (params: {company: FactoringCompany}) => (dispatch) => {
    const {company} = params;

    return new Promise((resolve) => {
        const onCancel = () => resolve(false);
        const onConfirm = () => resolve(true);

        const modalName = modalNames.archivingWarn;
        const modalData = {factoringCompany: company};
        const modalHandlers = {onCancel, onConfirm};

        dispatch(modalActions.openModal({modalName, data: modalData, handlers: modalHandlers}));
    });
};

const confirmArchiving = (params: {company: FactoringCompany}) => async (dispatch) => {
    try {
        const isAgreeWithArchive = await dispatch(showArchivingWarning(params));

        return isAgreeWithArchive;
    } catch (e) {
        return false;
    }
};

export const archiveFactoringCompany = (params: {company: FactoringCompany; type: 'card' | 'list'}) => async (
    dispatch,
) => {
    const {company, type} = params;

    try {
        const isConfirmed = await dispatch(confirmArchiving(params));

        if (!isConfirmed) {
            return;
        }

        dispatch(appActions.showLoader());

        const {data} = await requests.archiveFactoringCompanyRequest({companyID: company.id});

        if (type === 'card') {
            dispatch(archivingActionCreators.factoringCompanyArchivedFromCard({company: data}));
        }

        if (type === 'list') {
            dispatch(archivingActionCreators.factoringCompanyArchivedFromList({company: data}));
        }

        dispatch(showSuccessArchiving({company: data}));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const confirmRestore = (params: {company: FactoringCompany}) => (dispatch) => {
    const {company} = params;

    return new Promise((resolve) => {
        const modalHandlers = {leftButtonHandler: () => resolve(false), rightButtonHandler: () => resolve(true)};
        const modalName = commonModalNames.informationModal;
        const modalData = {
            toProfileLabel: company.companyName,
            title: 'Restore Factoring Company',
            entityName: 'factoring company',
            rightButtonTitle: 'Restore',
            leftButtonTitle: 'KEEP',
            bodyType: 'RestoreForm',
            buttonType: 'danger',
        };

        dispatch(modalActions.openModal({modalName, data: modalData, handlers: modalHandlers}));
    });
};

export const restoreFactoringCompany = (params: {company: FactoringCompany}) => async (dispatch) => {
    const {company} = params;

    const isConfirmed = await dispatch(confirmRestore(params));

    if (!isConfirmed) {
        return;
    }

    dispatch(navigateToUpdatePage(company));
};
