import {SettlementOwner} from 'core/entities/Settlement/types';
import {fetchPayPeriodNotPostedSettlements} from 'core/entities/Settlement/requests/settlementOwnerRequests';

import useFetchWithPaginate from 'hooks/useFetchWithPaginate';

interface FetchData {
    settlements: SettlementOwner[];
    error: string | undefined;
    currentPage: number | undefined;
    isFetching: boolean;
    isAllFetched: boolean;
    fetchNextPage();
}

const PER_PAGE = 10;

const fetchSettlements = (
    payPeriodID: number,
    {page, perPage}: {page: string; perPage: number},
): Promise<SettlementOwner[]> => {
    const nextPage = parseInt(page, 10) + 1;
    return fetchPayPeriodNotPostedSettlements(payPeriodID, {page: nextPage, perPage})
        .then((response) => response.data)
        .catch((error) => {
            console.error('Error on fetch pay period not posted settlements: ', error);
            return [];
        });
};

const cacheCreator = ({payPeriodID, cachePrefix}: {payPeriodID: number; cachePrefix: string}) => {
    return (pageIndex: any, previousPageData: any) => {
        if (previousPageData && previousPageData.length === 0) {
            return null;
        }
        const pageNumber = String(pageIndex);
        const cacheKey = `${cachePrefix}-${payPeriodID}-settlements-page-${pageNumber}`;
        return [cacheKey, pageNumber];
    };
};

const fetcherCreator = ({payPeriodID}: {payPeriodID: number}) => {
    return (_, pageNumber) => {
        return fetchSettlements(payPeriodID, {page: pageNumber, perPage: PER_PAGE});
    };
};

export default ({
    payPeriodID,
    cachePrefix = 'pay-record-not-posted-settlements',
}: {
    payPeriodID: number;
    cachePrefix?: string;
}): FetchData => {
    const getCacheKey = cacheCreator({payPeriodID, cachePrefix});
    const fetcher = fetcherCreator({payPeriodID});
    const {data: settlements, error, currentPage, isFetching, isAllFetched, fetchNextPage} = useFetchWithPaginate({
        perPage: PER_PAGE,
        getKey: getCacheKey,
        fetchData: fetcher,
    });
    return {
        settlements,
        error,
        currentPage,
        isFetching,
        isAllFetched,
        fetchNextPage,
    };
};
