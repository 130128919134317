import React from 'react';

import ArchivingErrorWrapper from 'components/ui/modals/ArchivingErrorWrapper';

import {FactoringCompany} from 'core/entities/FactoringCompany/types';

type OwnProps = {
    data: {factoringCompany: FactoringCompany};
    onClose: () => void;
};

const ArchivingError: React.FC<OwnProps> = (props: OwnProps) => {
    const {data, onClose} = props;

    const {factoringCompany} = data || {};

    return (
        <ArchivingErrorWrapper onClose={onClose}>
            <div>
                <p>
                    The factoring company <strong>{factoringCompany.companyName}</strong> is in the{' '}
                    <strong>incompleted load.</strong> Moving the factoring company to the archive is not possible.
                    Please wait for T.O. to be finished before continuing.
                </p>
            </div>
        </ArchivingErrorWrapper>
    );
};

export default ArchivingError;
