import React from 'react';

import CheckBoxInput from 'components/ui/Form/CheckBoxInput';

import {User} from '../../../types';

interface OwnProps {
    user: User;
    isSelected: boolean;
    className: string;
    onSelectUser();
}

const UserInvoices = (props: OwnProps): JSX.Element => {
    const {user, isSelected, className, onSelectUser} = props;
    return (
        <div className={className}>
            <div className="invoice-item">
                <div className="invoice-item__title">
                    <CheckBoxInput
                        label={user.name}
                        input={{
                            value: isSelected,
                            onChange: onSelectUser,
                        }}
                        position="right"
                    />
                </div>
                <div className="invoice-item__count">{user.invoicesCount}</div>
            </div>
        </div>
    );
};

export default UserInvoices;
