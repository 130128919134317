import React from 'react';

type OwnProps = {
    data: {dispatcherId: number; sourceId: number; destinationId: number};
};

const SuccessfulMergedCustomers: React.FC<OwnProps> = (props) => {
    const {data} = props;

    const {sourceId, destinationId} = data;

    return (
        <div>
            The merging process of Customer <strong>ID#{sourceId}</strong> and <strong>ID#{destinationId}</strong> has
            been completed successfully. <br /> Refresh the page: Customers.
        </div>
    );
};

export default SuccessfulMergedCustomers;
