import React from 'react';
import {useLocation} from 'react-router';

import ArchivingSuccessWrapper from 'components/ui/modals/ArchivingSuccessWrapper';
import ButtonLink from 'components/ui/Buttons/ButtonLink';
import Button from 'components/ui/Buttons/Button';

import {FactoringCompany} from 'core/entities/FactoringCompany/types';

import {getFactoringCompaniesLinks} from 'routing/linkBuilder';

type OwnProps = {
    handlers: {onViewArchivedList: () => void};
    data: {factoringCompany: FactoringCompany};
    onClose: () => void;
};

const ArchivingSuccess: React.FC<OwnProps> = (props) => {
    const {data, handlers, onClose} = props;

    const location = useLocation();

    const {onViewArchivedList} = handlers || {};
    const {factoringCompany} = data || {};

    const {view: viewLink, list: listLink} = getFactoringCompaniesLinks(factoringCompany);

    const isListPage = location?.pathname === listLink;

    const buttons = (
        <>
            <div />
            <div className="d-flex justify-content-end">
                <Button onClick={onViewArchivedList} buttonSize="medium" colorTheme="grey" className="mr10">
                    View Archive
                </Button>

                {isListPage ? (
                    <ButtonLink href={viewLink} buttonSize="medium" colorTheme="green" className="mr10">
                        View Profile
                    </ButtonLink>
                ) : (
                    <Button onClick={onClose} buttonSize="medium" colorTheme="green" className="mr10">
                        View Profile
                    </Button>
                )}
            </div>
        </>
    );

    return (
        <ArchivingSuccessWrapper buttons={buttons}>
            <p>
                <strong>{factoringCompany.companyName}</strong> profile has been moved to archive successfully.
            </p>
        </ArchivingSuccessWrapper>
    );
};

export default ArchivingSuccess;
