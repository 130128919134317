import {v4 as uuidv4} from 'uuid';

let userSessionID: string | undefined;

const setID = (): void => {
    userSessionID = uuidv4();
};

const getID = (): string => {
    if (!userSessionID) {
        setID();
    }
    return userSessionID as string;
};

export default {getID, setID};
