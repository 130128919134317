import {combineReducers} from 'redux';

import customer from './customer';
import merge from './merge';
import list from './list';
import agents from './agents';

export default combineReducers({
    customer,
    merge,
    list,
    agents,
});
