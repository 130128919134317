import React from 'react';
import {useDispatch} from 'react-redux';
import {Field, change} from 'redux-form';

import Button from 'components/ui/Buttons/Button';
import TextInputWithDatePicker from 'components/ui/Form/InputWithDatePicker';
import SelectTimeZone from 'components/ui/Form/SelectTimeZone';
import RequiredLabel from 'components/ui/Form/RequiredLabel';

import {getFieldPath, getName} from 'components/common/LocationEvents/modals/CheckCallModal/utils';

import {getCurrentDate, getCurrentDateSettings} from 'utils/dateTime';

import styles from './styles.module.scss';

type OwnProps = {
    timeZoneDisabled?: boolean;
    formSection?: string;
    showReset?: boolean;
    form: string;
};

const TimeDataPicker: React.FC<OwnProps> = (props) => {
    const {form, formSection, showReset = true, timeZoneDisabled = true} = props;

    const dispatch = useDispatch();
    const {originalDateTime: currentDate} = getCurrentDate();

    const fieldName = getFieldPath({formSection, field: getName('dateTime')});

    const resetValues = (): void => {
        dispatch(change(form, fieldName, currentDate));
    };

    const {currentDateFormat, currentTimeFormat} = getCurrentDateSettings();

    const currentFormat = `${currentDateFormat} ${currentTimeFormat}`;

    return (
        <div className={styles.item}>
            <Field
                name={fieldName}
                type="text"
                component={TextInputWithDatePicker}
                label={RequiredLabel('Date and Time')}
                placeholder="date and time"
                showTimeSelect={true}
                withHoursMinSec={true}
                dateFormat={currentFormat}
            />

            <Field
                name={getName('timezone')}
                label={RequiredLabel('Timezone')}
                component={SelectTimeZone}
                isClearable={false}
                isDisabled={timeZoneDisabled}
                withLongLabel
            />

            {showReset ? (
                <Button onClick={resetValues} buttonSize="small" colorTheme="white">
                    NOW
                </Button>
            ) : null}
        </div>
    );
};

export default TimeDataPicker;
