import isEmpty from 'lodash/isEmpty';
import drop from 'lodash/drop';

import {TripMonitorState} from 'pages/TravelOrders/redux/reducers/list';

import {TripMonitorSSEData} from 'core/entities/TravelOrder/types/SSE';

export const handleReducerByReceivedSSEData = (params: {state: TripMonitorState; data: TripMonitorSSEData[]}) => {
    const {data, state} = params;

    if (isEmpty(state.items) || isEmpty(data)) {
        return state;
    }

    const relevantData = data.filter((item) => state.idsSet.has(item.number));

    if (isEmpty(relevantData)) {
        return state;
    }

    const updatedItems = state.items.map((item) => {
        const correspondingTravelOrder = relevantData.find((itm) => item.number === itm.number);

        if (correspondingTravelOrder) {
            const {
                locationEvents,
                isDispatched,
                lastDelivery,
                currentStop,
                truckState,
                eventTimer,
                nextStop,
                etaInfo,
                toStops,
            } = correspondingTravelOrder;

            return {
                ...item,
                locationEvents: [locationEvents[0], ...drop(item?.locationEvents || [])].filter(Boolean),
                toStops: isEmpty(toStops) ? item.toStops : toStops,
                isDispatched: Boolean(isDispatched),
                lastDelivery,
                currentStop,
                truckState,
                eventTimer,
                nextStop,
                etaInfo,
            };
        }

        return item;
    });

    return {...state, items: updatedItems};
};
