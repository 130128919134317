import React from 'react';

interface OwnProps {
    count: number;
    duplicateType: 'facility' | 'customer';
}

const getFacilitiesDuplicatesCountDescription = (count: number): string => {
    const facilityTitle = count > 1 ? 'facilities' : 'facility';
    return `similar ${facilityTitle} found by entered data`;
};

const getCustomersDuplicatesCountDescription = (count: number): string => {
    const customerTitle = count > 1 ? 'companies' : 'company';
    return `similar ${customerTitle} found by entered data`;
};

const getDescriptionByDuplicateType = (duplicateType: OwnProps['duplicateType'], duplicatesCount: number): string => {
    if (duplicateType === 'facility') {
        return getFacilitiesDuplicatesCountDescription(duplicatesCount);
    }

    if (duplicateType === 'customer') {
        return getCustomersDuplicatesCountDescription(duplicatesCount);
    }

    return '';
};

const DuplicatesCount = ({count, duplicateType}: OwnProps): JSX.Element | null => {
    const description = getDescriptionByDuplicateType(duplicateType, count);
    return (
        <div className="general-data__count-duplicate-companies">
            <strong>{count}</strong> {description}
        </div>
    );
};

export default DuplicatesCount;
