import React, {useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';
import {FixedSizeList as OptimizedList} from 'react-window';

import {DriverChannel as DriverChannelType} from '../../types';
import DriverChannel from '../DriverChannel';

import styles from './driverChannelsList.module.scss';

interface OwnProps {
    channels: {allIds: string[]; byDriverID: {[key: string]: DriverChannelType}};
    searchParams?: {truckNumber: string};
    onEnterChannel(driverID: number);
}

const DriversChannelsList: React.FC<OwnProps> = ({channels, searchParams = {}, onEnterChannel}) => {
    const channelsRows = useMemo(() => {
        let currentChannelsIds = channels.allIds;
        if (!isEmpty(searchParams) && searchParams.truckNumber) {
            currentChannelsIds = channels.allIds.filter((channelID) => {
                const channel = channels.byDriverID[channelID];
                if (!channel) {
                    return false;
                }
                const {truck} = channel.driver;
                return truck && truck.number === searchParams.truckNumber;
            });
        }
        return currentChannelsIds.map((channelID) => {
            const driverChannel = channels.byDriverID[channelID];
            return <DriverChannel key={driverChannel.id} channel={driverChannel} onEnterChannel={onEnterChannel} />;
        });
    }, [channels, searchParams]);

    return (
        <OptimizedList
            className={styles.scroll}
            height={window.innerHeight}
            itemSize={90}
            itemCount={channelsRows.length}
        >
            {({index, style}) => <div style={style}>{channelsRows[index]}</div>}
        </OptimizedList>
    );
};

export default React.memo(DriversChannelsList);
