import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button';

import {PayPeriod, SettlementOwner} from 'core/entities/Settlement/types';

import PayPeriodTitle from './PayPeriodTitle';

type OwnProps = {
    settlement: SettlementOwner;
    period: PayPeriod;
    onPost();
    onClose();
};

const PostToOpenedPeriod: React.FC<OwnProps> = ({settlement, period, onPost, onClose}) => {
    return (
        <Modal show={true} onHide={onClose} dialogClassName="standard-form-modal" size="sm" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Post Settlement</Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="custom-scroll">
                    <div className="modal-body__main">
                        <div>
                            You are about to post Settlement #{settlement.settlement_number} to the following:
                            <br />
                            <PayPeriodTitle period={period} />
                        </div>
                    </div>
                </div>
                <div className="modal-body__bottom">
                    <div>
                        <Button onClick={onClose}>Cancel</Button>
                    </div>
                    <div>
                        <Button onClick={onPost} type="button" colorTheme="blue">
                            Post
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PostToOpenedPeriod;
