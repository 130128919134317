import {combineReducers} from 'redux';

import {TNB_TABS} from 'pages/TrucksNearby/constants';

import listReducer from './list';

export default combineReducers({
    partners: listReducer(TNB_TABS.PARTNERS),
    own: listReducer(TNB_TABS.OWN),
});
