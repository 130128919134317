import {DispatchReDispatchModalFormValues} from 'pages/TravelOrders/types/formTypes';

export const transformDispatchDataToRequestBody = (
    data: DispatchReDispatchModalFormValues,
): {
    notes: {note: string | null; stopId: string}[];
    sendTime: {[key: string]: boolean};
    serviceRequirements: string;
    forPayment: string;
} => {
    const {toStops} = data;

    const clearedStops = toStops.filter((stop) => stop?.dispatchNote?.trim() && stop.id);
    const notes = clearedStops.map((stop) => ({note: stop.dispatchNote, stopId: stop.id}));

    const sendTime = {};
    toStops.forEach((stop) => {
        sendTime[stop.id] = Boolean(stop.sendTime);
    });

    return {
        serviceRequirements: data.serviceRequirementsNote || '',
        forPayment: data.paymentRequirementsNote || '',
        sendTime,
        notes,
    };
};
