import {handleError} from 'store/actions/common';

import {fetchCurrentCarrier as fetchCurrentCarrierApiReq} from 'core/gateways/CarrierApiGateway/requests';

import {fetchCarriers, fetchDispatchers} from 'services/restapi';

import * as entityNames from 'utils/data/entityNames';

import actionCreators from './actionCreators';

export const fetchAllDispatchers = ({isMinRequest} = {isMinRequest: false}) => async (dispatch, getState) => {
    const {isDispatchersFetching} = getState().app;

    if (!isDispatchersFetching) {
        dispatch(actionCreators.startFetchingDispatchersCommonData());

        const data = await fetchDispatchers({'per-page': 0, isMinRequest})
            .then((res) => {
                if ('data' in res) {
                    return res.data;
                }
            })
            .catch((error) => dispatch(handleError(error)));

        dispatch(actionCreators.getEntityCommonData(entityNames.ENTITY_NAME_DISPATCHERS, data));

        dispatch(actionCreators.finishFetchingDispatchersCommonData());
    }
};

export const fetchAllCarriers = () => async (dispatch, getState) => {
    const {isCarriersFetching} = getState().app;

    if (!isCarriersFetching) {
        dispatch(actionCreators.startFetchingCarriersCommonData());

        const data = await fetchCarriers({'per-page': 0})
            .then((res) => {
                if ('data' in res) {
                    return res.data;
                }
            })
            .catch((error) => dispatch(handleError(error)));

        dispatch(actionCreators.getEntityCommonData(entityNames.ENTITY_NAME_CARRIERS, data));

        dispatch(actionCreators.finishFetchingCarriersCommonData());
    }
};

export const addCreatedCustomer = (customer) => (dispatch) => {
    dispatch(actionCreators.createCommonDataEntity(entityNames.ENTITY_NAME_CUSTOMERS, customer));
};

export const fetchCurrentCarrier = (): any => async (dispatch) => {
    const data = await fetchCurrentCarrierApiReq()
        .then((res) => res.data)
        .catch((error) => dispatch(handleError(error)));

    dispatch(actionCreators.getEntityCommonData(entityNames.ENTITY_NAME_CURRENT_CARRIER, data));
};
