import React from 'react';
import {Field} from 'redux-form';

import RadioInput from 'components/ui/Form/RadioInput';
import identifierTypes from 'utils/data/identifierTypes';

interface OwnProps {
    name: string;
    disabled?: boolean;
}

const identifierItems = [
    {label: `${identifierTypes.MC.shortName}#`, value: identifierTypes.MC.shortName},
    {label: `${identifierTypes.FF.shortName}#`, value: identifierTypes.FF.shortName},
    {label: `${identifierTypes.MX.shortName}#`, value: identifierTypes.MX.shortName},
];

const IdentifierTypes: React.FC<OwnProps> = (props: OwnProps) => {
    const {name, disabled} = props;
    return <Field name={name} component={RadioInput} label=" " items={identifierItems} disabled={disabled} />;
};

export default IdentifierTypes;
