import React, {PureComponent} from 'react';
import {Modal} from 'react-bootstrap';

import Form from './Form';

class ReimbursementPaymentModal extends PureComponent {
    render() {
        const {
            onClose,
            data: {settlement, reimbursement},
            handlers: {onSubmit, onDelete},
        } = this.props;
        const modalTitle = reimbursement ? `Reimbursement` : `New Reimbursement`;
        return (
            <Modal show={true} onHide={onClose} dialogClassName="standard-form-modal" size="sm" backdrop="static">
                <Modal.Header>
                    <div className="modal-header__top">
                        <Modal.Title>{modalTitle}</Modal.Title>
                        <button onClick={onClose} type="button" className="close">
                            <i className="fa fa-times" aria-hidden="true" />
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        settlement={settlement}
                        reimbursement={reimbursement}
                        onSubmit={onSubmit}
                        onDelete={onDelete}
                        onClose={onClose}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}

export default ReimbursementPaymentModal;
