import isEmpty from 'lodash/isEmpty';

import {TrucksNearbySSEData} from 'core/entities/Truck/types/sse';

import {ListState} from 'pages/TrucksNearby/redux/reducers/list';

export const handleReducerByReceivedSSEData = (params: {data: TrucksNearbySSEData[]; state: ListState}): ListState => {
    const {state, data} = params;

    if (isEmpty(state.items) || isEmpty(data)) {
        return state;
    }

    const relevantData = data.filter((item) => state.trucksIdsSet.has(item.id));

    if (isEmpty(relevantData)) {
        return state;
    }

    const updatedItems = state.items.map((item) => {
        const correspondingTruck = relevantData.find((itm) => item.id === itm.id);

        if (correspondingTruck) {
            return {
                ...item,
                status: correspondingTruck.status,
                last_city_line: correspondingTruck.last_city_line,
                status_city_line: correspondingTruck.status_city_line,
                status_date: correspondingTruck.status_date,
                updated_at: correspondingTruck.updated_at,
                availabilityData: correspondingTruck.availabilityData,
                reserve: correspondingTruck.reserve,
            };
        }

        return item;
    });

    return {
        ...state,
        items: updatedItems,
    };
};
