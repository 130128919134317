import React from 'react';
import {Field} from 'redux-form';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';
import {LoadStop} from 'pages/LoadBoard/types';

import DistanceInput from 'pages/LoadBoard/components/common/DistanceInput';

import styles from './styles.module.scss';

const Distance: React.FC = () => {
    const {getRouteDistanceFieldName, getStopsFieldName} = getFormFieldNames();
    const stops: LoadStop[] = useGetReduxFormValue(FORM_NAME, getStopsFieldName());

    const picks = stops.filter((stop) => stop.type === 'PICKUP').length;
    const delivers = stops.filter((stop) => stop.type === 'DELIVERY').length;

    return (
        <div className="d-flex list-with-gap-10 align-items-lg-baseline">
            <div>
                <strong>Distance:</strong>
            </div>
            <div className="position-relative">
                <div style={{width: 115}}>
                    <Field name={getRouteDistanceFieldName()} component={DistanceInput} maxLength="5" />
                </div>
                <span className="position-absolute" style={{top: 5, right: 10}}>
                    mi
                </span>
            </div>
            <div className="ml-2">
                <strong>All Stops:</strong>
            </div>
            <div className={styles.countStopsBox}>
                <strong>{picks}</strong> pick up(s), <strong>{delivers}</strong> drop(s)
            </div>
        </div>
    );
};

export default Distance;
