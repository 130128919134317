/**
 * Example
 * input: '  aa   aa  '
 * result: 'aa aa'
 */
export default function trimAndReduceSpaces(value: string | null | undefined): string {
    if (!value || !value.trim()) {
        return '';
    }
    return value.replace(/\s\s+/g, ' '); // replace extra spaces with one
}
