import React, {useEffect, useRef} from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import head from 'lodash/head';

import MessageItem from '../MessageItem';
import {Message} from '../../types';

import styles from './styles.module.scss';

const scrollToMessage = (id): void => {
    const messageEl = document.getElementById(`${id}`);

    if (messageEl) {
        messageEl.scrollIntoView({behavior: 'smooth'});
    }
};

const DEBOUNCE_TIME_OUT = 300;

type OwnProps = {
    onFetchMoreMessages: () => void;
    currentDispatcherID: number;
    messages: Message[];
};

const ListMessages: React.FC<OwnProps> = (props) => {
    const {messages, onFetchMoreMessages} = props;

    const prevInnerDivHeight = useRef<number | null>(null);
    const prevFirstMessageID = useRef<string | null>(null);
    const prevLastMessageID = useRef<string | null>(null);

    const outerDiv = useRef<HTMLDivElement | null>(null);
    const innerDiv = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (isEmpty(messages)) {
            return;
        }

        const {id: firstMessageID} = head(messages) || {};
        const {id: lastMessageID} = last(messages) || {};

        const prevFirstID = prevFirstMessageID.current || null;
        const prevLastID = prevLastMessageID.current || null;

        const outerDivHeight = outerDiv?.current?.clientHeight || 0;
        const innerDivHeight = innerDiv?.current?.clientHeight || 0;
        const outerDivScrollTop = outerDiv?.current?.scrollTop || 0;
        const prevInnerHeight = prevInnerDivHeight?.current || 0;

        const outerPaddings = 30; // 15 + 15 | padding-top + padding-bottom
        const outerBorders = 4; // 2 + 2 | border-top + border-bottom
        const scrollFromTopPixels = innerDivHeight - outerDivHeight + outerPaddings + outerBorders;

        const messageScrollHeight = 170;
        const scrollFromTheBottom = Math.abs(Math.round(outerDivScrollTop - (prevInnerHeight - outerDivHeight)));

        const invokeScroll = () => {
            if (!prevInnerHeight) {
                outerDiv?.current?.scrollTo({top: scrollFromTopPixels, left: 0, behavior: 'auto'});
                return;
            }

            if (prevInnerHeight && prevInnerHeight < outerDivHeight && innerDivHeight > outerDivHeight) {
                outerDiv?.current?.scrollTo({top: scrollFromTopPixels, left: 0, behavior: 'auto'});
                return;
            }

            if (prevInnerHeight && innerDivHeight > outerDivHeight && scrollFromTheBottom < messageScrollHeight) {
                outerDiv?.current?.scrollTo({top: scrollFromTopPixels, left: 0, behavior: 'smooth'});
                return;
            }

            const isPrevFetched = prevInnerHeight && lastMessageID === prevLastID && firstMessageID !== prevFirstID;

            if (isPrevFetched && innerDivHeight > outerDivHeight && outerDivScrollTop === 0) {
                scrollToMessage(prevFirstID);
            }
        };

        invokeScroll();

        prevFirstMessageID.current = firstMessageID || null;
        prevLastMessageID.current = lastMessageID || null;
        prevInnerDivHeight.current = innerDivHeight;
    }, [messages]);

    useEffect(() => {
        const scrollHandler = (): void => {
            if (outerDiv.current && outerDiv.current.scrollTop === 0) {
                onFetchMoreMessages();
            }
        };

        const debouncedScrollHandler = debounce(scrollHandler, DEBOUNCE_TIME_OUT);

        if (outerDiv.current) {
            outerDiv.current.addEventListener('scroll', debouncedScrollHandler, {capture: false, passive: true});
        }

        return () => {
            if (outerDiv.current) {
                outerDiv.current.removeEventListener('scroll', debouncedScrollHandler);
            }
        };
    });

    const mgs = messages.map((message) => (
        <div key={message.id} id={message.id}>
            <MessageItem message={message} />
        </div>
    ));

    return (
        <div className={styles.container} ref={outerDiv}>
            <div className={styles.messages} ref={innerDiv}>
                {mgs}
            </div>
        </div>
    );
};

export default ListMessages;
