import React from 'react';
import {InjectedFormProps, reduxForm} from 'redux-form';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';
import Button from 'components/ui/Buttons/Button';
import {FormModeTypes, Quote} from 'core/entities/Quote/types';

import {DriverOfferFormValues} from 'pages/LoadBoard/types/formTypes';
import useRecalculateRpm from './hooks/useRecalculateRpm';
import Notes from './components/Notes';
import EmptyMiles from './components/EmptyMiles';
import Truck from './components/Truck';
import Rate from './components/Rate';
import Rpm from './components/Rpm';
import SubmitButton from './components/SubmitButton';
import getFormFieldNames from './utils/getFormFieldNames';
import validation from './validation/validation';
import submitValidation from './validation/submitValidation';

export const FORM_NAME = 'crete-offer-form';

type OwnProps = {
    quote: Quote;
    mode: FormModeTypes;
    onClose();
};

const Form: React.FC<OwnProps & InjectedFormProps<DriverOfferFormValues, OwnProps>> = (props) => {
    const {quote, mode, onClose, reset, dirty, handleSubmit} = props;
    const {
        getTruckOfferFieldName,
        getEmptyMilesOfferFieldName,
        getPaymentRateOfferFieldName,
        getNoteOfferFieldName,
        getPaymentRpmOfferFieldName,
        getPaymentCurrencyOfferFieldName,
    } = getFormFieldNames();
    const rpm = useGetReduxFormValue(FORM_NAME, getPaymentRpmOfferFieldName());
    const currency = useGetReduxFormValue(FORM_NAME, getPaymentCurrencyOfferFieldName());
    const isUpdate = mode === 'UPDATE';
    const submitBtnTitle = isUpdate ? 'Update Offer' : 'Add offer';

    useRecalculateRpm(FORM_NAME, quote);

    return (
        <form onSubmit={handleSubmit(submitValidation)} className="standard-form-modal">
            <div className="mb-2">
                <div className="row">
                    <div className="col-6">
                        <Truck fieldName={getTruckOfferFieldName()} isUpdate={isUpdate} />
                    </div>
                    <div className="col-6">
                        <EmptyMiles fieldName={getEmptyMilesOfferFieldName()} isUpdate={isUpdate} />
                    </div>
                    <div className="col-6">
                        <Rate fieldName={getPaymentRateOfferFieldName()} />
                    </div>
                    <div className="col-6">
                        <Notes fieldName={getNoteOfferFieldName()} />
                    </div>
                    <div className="col-6">
                        <Rpm rpm={rpm} currency={currency} />
                    </div>
                </div>
            </div>
            <div className="modal-body__bottom">
                <Button onClick={onClose} className="mr10" colorTheme="grey" buttonSize="small" defaultFocus={true}>
                    CANCEL
                </Button>
                <Button
                    disabled={!dirty}
                    className="mr10"
                    colorTheme="grey"
                    buttonSize="small"
                    defaultFocus={true}
                    onClick={reset}
                >
                    CLEAR ALL FIELDS
                </Button>
                <SubmitButton title={submitBtnTitle} quote={quote} />
            </div>
        </form>
    );
};

const ReduxForm = reduxForm<DriverOfferFormValues, OwnProps>({
    validate: validation,
    form: FORM_NAME,
})(Form);

export default ReduxForm;
