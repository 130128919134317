import {useDispatch} from 'react-redux';
import {change} from 'redux-form';

type ChangeReduxFormValue = (fieldName: string, fieldValue: any) => void;

const useChangeReduxFormValue = (formName: string): ChangeReduxFormValue => {
    const dispatch = useDispatch();

    return (fieldName, fieldValue) => dispatch(change(formName, fieldName, fieldValue));
};

export default useChangeReduxFormValue;
