import React, {useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';
import {destroy} from 'redux-form';

import {getTravelOrderNumber} from 'core/entities/TravelOrder/modules/common/getTravelOrderNumber';
import TravelOrder from 'core/entities/TravelOrder/types';

import useGetReduxFormValues from 'hooks/reduxForm/useGetReduxFormValues';
import useFormStepManager from 'hooks/useFormStepManager';

import ConfirmForm from './components/ConfirmForm';
import CreateForm from './components/CreateForm';
import FormSteps from './components/FormSteps';
import useCreateFormData from './hooks/useCreateFormData';

import {CHECK_CALL_TABS, CONFIRM_TAB, CREATE_TAB, FORM_NAME} from './constants';
import {AddCheckCallFormValues} from './types';

import styles from './styles.module.scss';

type OwnProps = {
    handlers: {onAddEvent(eventData: AddCheckCallFormValues): void};
    data: {travelOrder: TravelOrder; truckNumber?: string};
    onClose(): void;
};

const CheckCallModal: React.FC<OwnProps> = (props) => {
    const {
        data: {travelOrder, truckNumber},
        handlers: {onAddEvent},
        onClose,
    } = props;

    const {currentStep, selectNextStep, selectPrevStep} = useFormStepManager(CHECK_CALL_TABS);
    const createFormValue = useGetReduxFormValues<AddCheckCallFormValues>(FORM_NAME);
    const dispatch = useDispatch();

    const travelOrderNumber = getTravelOrderNumber(travelOrder);

    const handleSubmit = (): void => onAddEvent(createFormValue);

    useEffect(() => {
        dispatch(destroy(FORM_NAME));
    }, []);

    const createdFormData = useCreateFormData({form: FORM_NAME, travelOrder});
    const {checkCallData, updateCoordinatesFromAutocomplete, copyLastKnowLocationFromTruck} = createdFormData;

    if (!travelOrderNumber) {
        return null;
    }

    const isCurrentStepCreateTab = currentStep === CREATE_TAB;
    const isCurrentStepConfirmTab = currentStep === CONFIRM_TAB;

    return (
        <div className={classNames(styles.modal, 'standard-form-modal')}>
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>
                        Check Call
                        {truckNumber ? (
                            <span>
                                : <strong>Truck #{truckNumber}</strong>
                            </span>
                        ) : null}
                    </Modal.Title>

                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>

            <Modal.Body>
                <FormSteps steps={CHECK_CALL_TABS} currentStep={currentStep} />

                {isCurrentStepCreateTab ? (
                    <CreateForm
                        updateCoordinatesFromAutocomplete={updateCoordinatesFromAutocomplete}
                        copyLastKnowLocationFromTruck={copyLastKnowLocationFromTruck}
                        travelOrderNumber={travelOrderNumber}
                        checkCallData={checkCallData}
                        onSubmit={selectNextStep}
                        onClose={onClose}
                        form={FORM_NAME}
                    />
                ) : null}

                {isCurrentStepConfirmTab ? (
                    <ConfirmForm
                        travelOrderNumber={travelOrderNumber}
                        handlePrev={selectPrevStep}
                        onSubmit={handleSubmit}
                        form={FORM_NAME}
                    />
                ) : null}
            </Modal.Body>
        </div>
    );
};

export default CheckCallModal;
