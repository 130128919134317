import React from 'react';

import Truck from 'core/entities/Truck/types';
import withTruckReservingTimer from 'hocs/withTruckReservingTimer';
import TruckReserveButtonMain from 'components/common/Truck/TruckReserveButton';

interface OwnProps {
    truck?: Truck | null;
    isDisabled: boolean;
}
const TruckReserveButton: React.FC<OwnProps> = (props) => {
    const {truck, isDisabled} = props;
    if (!truck) {
        return null;
    }
    return (
        <TruckReserveButtonMain
            truck={truck}
            switchToLinkOnReserve={false}
            isDisabled={isDisabled}
            isCloseOnlyReserveModalOnSuccess={true}
        />
    );
};

export default withTruckReservingTimer(React.memo(TruckReserveButton));
