import React from 'react';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button/index';
import {InvoiceLink} from 'components/ui/Links';

import {isListPage} from 'pages/Invoices/selectors';
import getText from 'utils/getText';

const MessageForList = ({invoice, onClose}) => {
    return (
        <p>
            If you are not sure, please{' '}
            <span onClick={onClose}>
                <InvoiceLink invoice={invoice} content="check information here." className="main-link" />
            </span>
        </p>
    );
};

const MessageForView = () => {
    return <p>If you are not sure, please check information.</p>;
};

const ApproveInvoiceModal = (props) => {
    const {
        onClose,
        data: {invoice},
        handlers: {onApproveInvoice},
        isList,
    } = props;
    const onApprove = () => {
        onApproveInvoice();
        onClose();
    };
    const rateConfirmation = getText('rateConfirmation');
    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Approving Information</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <p className="mb10">
                            By approving the information you agree that all data in the <strong>trip summary</strong> of{' '}
                            <br />
                            <strong>Load #{invoice.load.load_id}</strong> corresponds to the information in:
                        </p>
                        <ul className="mb10">
                            <li>
                                - customer’s <strong>{rateConfirmation};</strong>
                            </li>
                            <li>
                                - <strong>signed PoD;</strong>
                            </li>
                            <li>- another attached documents.</li>
                        </ul>
                        {isList ? <MessageForList invoice={invoice} onClose={onClose} /> : <MessageForView />}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} buttonSize="small" colorTheme="grey">
                    Cancel
                </Button>
                <Button onClick={onApprove} buttonSize="small" colorTheme="blue">
                    APPROVE
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default connect((state) => {
    return {
        isList: isListPage(state),
    };
})(ApproveInvoiceModal);
