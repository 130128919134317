import countries from 'utils/data/countries';

interface Country {
    fullName: string;
    name: string;
    code: string;
}

const getCountryByName = (country: string): Country | undefined => {
    const filterCountry = (_country: Country) => _country.name.toLowerCase() === country.toLowerCase();
    return Object.values(countries).find(filterCountry);
};

export default getCountryByName;
