import {statuses} from 'core/entities/Invoice/constants';

const parseFileExtraData = (file) => {
    try {
        return JSON.parse(file.extra_data);
    } catch (e) {
        return undefined;
    }
};

const getFileData = ({file, responsible, isReset}) => {
    return {...file, responsible, extra_data: parseFileExtraData(file), isReset};
};
/**
 *  Get invoice files from invoice history changes and group these files by type
 * @param {Object} invoice
 * @return {Object}
 */
export const getInvoiceFilesHistory = (invoice) => {
    if (!invoice || !invoice.status_history) {
        return undefined;
    }
    const invoiceChangesHistory = invoice.status_history;
    const {invoicesFiles} = invoiceChangesHistory.reduce(
        (result, changeHistoryItem) => {
            const {file, responsible, reseted_from_batch} = changeHistoryItem;
            if (!file) {
                return result;
            }

            if (!result.invoicesFilesMap[file.file_name]) {
                result.invoicesFiles.push(getFileData({file, responsible, isReset: reseted_from_batch}));
                result.invoicesFilesMap[file.file_name] = true;
            }

            return result;
        },
        {invoicesFiles: [], invoicesFilesMap: {}},
    );

    return invoicesFiles;
};

export const getBatchFile = (batch) => {
    if (!batch || !batch.file || !batch.status_history) {
        return undefined;
    }

    const {file, status_history: batchChangesHistory} = batch;
    // batch files structure different than in invoices we should get responsible for create file manual
    // because file in batch can be created only in "Posted" status we get responsible person from last "Posted" status history item
    const lastPostedStatusHistory = batchChangesHistory.find((historyItem) => {
        const historyStatusName = historyItem && historyItem.status && historyItem.status.status_name;
        return historyStatusName === statuses.POSTED;
    });

    return {...file, responsible: lastPostedStatusHistory.responsible};
};
