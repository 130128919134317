import React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';

import {PermissionsValues} from 'core/entities/Permissions';

import paths from 'routing/paths';

import useCheckIfUserHasPermission from 'hooks/useCheckIfUserHasPermission';

type OwnProps = {
    permission: PermissionsValues;
    redirectPath?: string;
};

const PermittedRoute: React.FC<RouteProps & OwnProps> = ({permission, redirectPath, ...props}) => {
    const isRoutePermitted = useCheckIfUserHasPermission(permission);

    if (isRoutePermitted) {
        return <Route {...props} />;
    }

    const redirectTo = redirectPath || `${paths['not-found']}`;

    return <Redirect to={redirectTo} />;
};

export default PermittedRoute;
