import {getTravelOrderNumber} from 'core/entities/TravelOrder/modules/common/getTravelOrderNumber';
import * as toRequests from 'core/gateways/TravelOrderApiGateway/requests/indexNew';
import TravelOrder from 'core/entities/TravelOrder/types';

import {AddGeneralNote} from 'components/ui/modals/AddTONotes/types';

import * as appActions from 'store/actions';

type AddTravelOrderNote = {
    onSuccessAddedNote?: (travelOrder: TravelOrder) => void;
    setModalTO: (travelOrder: TravelOrder) => void;
    travelOrder?: TravelOrder | null;
    newNote: AddGeneralNote;
};

export const addTravelOrderNote = (params: AddTravelOrderNote) => async (dispatch) => {
    const {newNote, travelOrder, setModalTO, onSuccessAddedNote} = params;
    const {note, isImportant} = newNote;

    const toNumber = getTravelOrderNumber(travelOrder);

    if (!toNumber) {
        return;
    }

    const requestParams = {requestBody: {note, isImportant}, travelOrderNumber: toNumber};

    try {
        dispatch(appActions.showLoader());

        await toRequests.addTravelOrderNoteRequest(requestParams);

        const {data: travelOrderWithNewNotes} = await toRequests.fetchTravelOrder(toNumber);

        if (onSuccessAddedNote) {
            onSuccessAddedNote(travelOrderWithNewNotes);
        }

        setModalTO(travelOrderWithNewNotes);
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};
