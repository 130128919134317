import useSound from 'use-sound';
import {useEffect} from 'react';
import {useLocalStorage} from 'react-use';
import {useSelector} from 'react-redux';

import * as selectors from '../redux/selectors';
// @ts-ignore
import notificationSound from '../sound/notification.mp3';

function useSwitchPlaySoundOnGetNewNotification() {
    const [sound, setSound] = useLocalStorage('isSoundNotification', true);
    const switchEnabled = () => {
        setSound(!sound);
    };
    return {
        sound,
        switchEnabled,
    };
}
const usePlaySoundOnGetNewNotification = (): void => {
    const {sound} = useSwitchPlaySoundOnGetNewNotification();
    const newNotification = useSelector(selectors.getNewNotification);
    const [play] = useSound(notificationSound);
    useEffect(() => {
        if (sound && newNotification) {
            play();
        }
    }, [newNotification]);
};
export {useSwitchPlaySoundOnGetNewNotification, usePlaySoundOnGetNewNotification};
