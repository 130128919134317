import React, {useState} from 'react';

import Button from 'components/ui/Buttons/Button/index';
import Tooltip from 'components/ui/Tooltip';

import {PayRecord, SettlementOwner} from 'core/entities/Settlement/types';
import {getValueConvertedToNumber} from 'core/entities/Settlement/settlementsTotal';

import AddToExistsPayRecord from './AddToExistsPayRecord';
import AddToNewPayRecord from './AddToNewPayRecord';
import styles from './payRecordInfo.module.scss';

interface Props {
    owner: any;
    newSettlement?: SettlementOwner;
    payRecord: PayRecord | undefined;
}

const SettlementInfo = ({owner, newSettlement, payRecord}: Props): JSX.Element => {
    const ownerName = owner.owner_name;
    const ownerCompanyName = owner.owner_company ? `(${owner.owner_company})` : null;
    const [open, setOpen] = useState(true);
    const allCountsSettlements = payRecord ? getValueConvertedToNumber(payRecord.settlements_total) + 1 : 1;
    return (
        <div className={styles.item}>
            <div className="header">
                <div>
                    (ow) <strong>{ownerName}</strong> {ownerCompanyName}
                </div>
                <div>
                    <strong>{allCountsSettlements}</strong> settlement(s)
                    <Tooltip
                        className="d-inline-block icon-tooltip with-right-tooltip"
                        tooltipContent={open ? 'hide details' : 'view details'}
                    >
                        <Button
                            buttonIcon={open ? 'angle-up' : 'angle-down'}
                            buttonSize="icon"
                            className="btn-link"
                            onClick={() => setOpen(!open)}
                        />
                    </Tooltip>
                </div>
            </div>

            {payRecord ? (
                <AddToExistsPayRecord
                    newSettlement={newSettlement as SettlementOwner}
                    existsPayRecord={payRecord}
                    isExpanded={open}
                />
            ) : (
                <AddToNewPayRecord settlement={newSettlement} isExpanded={open} />
            )}
        </div>
    );
};

// @ts-ignore
export default SettlementInfo;
