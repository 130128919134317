import React from 'react';

import useTruckTeamSlider from 'core/entities/Truck/hooks/useTruckTeamSlider';
import Truck from 'core/entities/Truck/types';

import TruckOwnerCoordinatorBlock from './components/TruckOwnerCoordinatorBlock';
import TruckDriverBlock from './components/TruckDriverBlock';
import TruckOwnerBlock from './components/TruckOwnerBlock';

import styles from './styles.module.scss';

type OwnProps = {
    truck: Truck;
};

const TruckItemTeamSlider: React.FC<OwnProps> = (props) => {
    const {truck} = props;

    const truckTeamSlider = useTruckTeamSlider(truck);

    if (!truckTeamSlider || !truck) {
        return null;
    }

    const {
        currentMember,
        onSelectNext,
        onSelectPrev,
        isCoordinatorInfo,
        isDriverInfo,
        isOwnerInfo,
        totalSlides,
    } = truckTeamSlider;

    const coordinatorBlock = isCoordinatorInfo ? <TruckOwnerCoordinatorBlock truckMember={currentMember} /> : null;
    const driverBlock = isDriverInfo ? <TruckDriverBlock truck={truck} truckMember={currentMember} /> : null;
    const ownerBlock = isOwnerInfo ? <TruckOwnerBlock truck={truck} truckMember={currentMember} /> : null;

    return (
        <div className={styles.slider}>
            {totalSlides > 1 ? (
                <>
                    <div className={styles['left-arrow']} onClick={onSelectPrev}>
                        <i className="fa fa-angle-left" />
                    </div>

                    <div className={styles['right-arrow']} onClick={onSelectNext}>
                        <i className="fa fa-angle-right" />
                    </div>
                </>
            ) : null}

            {driverBlock}

            {ownerBlock}

            {coordinatorBlock}
        </div>
    );
};

export default TruckItemTeamSlider;
