export const getPickUpDeliveryProgressSteps = (travelOrder) => {
    if (!travelOrder || !travelOrder.dispatch || !travelOrder.dispatch.steps) {
        return [];
    }
    const {
        dispatch: {steps},
    } = travelOrder;
    // remove unnecessary first "deadhead" step
    return steps.slice(1);
};

export const getFilesFromProgressStep = (stepID, travelOrderFiles, fileType) => {
    const listAllExistsFiles = travelOrderFiles[fileType] || [];
    return listAllExistsFiles.filter((file) => {
        const extraData = file.extra_data;

        if (!extraData) {
            return false;
        }

        return stepID === extraData.stepID;
    });
};
