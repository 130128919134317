import React from 'react';

import styles from './styles.module.scss';

type OwnProps = {
    isNeedButtonIcon?: boolean;
    buttonIcon?: string;
    onClick: () => void;
    disabled?: boolean;
    text: string;
};

const UnderlineButton: React.FC<OwnProps> = (props) => {
    const {onClick, text, disabled = false, buttonIcon = 'plus', isNeedButtonIcon = true} = props;

    return (
        <button className={styles.button} onClick={onClick} type="button" disabled={disabled}>
            {isNeedButtonIcon ? <i className={`fa fa-${buttonIcon}`} /> : null}

            <p>{text}</p>
        </button>
    );
};

export default UnderlineButton;
