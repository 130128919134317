import React from 'react';
import {Modal} from 'react-bootstrap';
import classNames from 'classnames';

import TravelOrder from 'core/entities/TravelOrder/types';
import {getTravelOrderNextStopID, getTravelOrderStopByID} from 'core/entities/TravelOrder/modules/travelOrderStops';
import {getTravelOrderEventTimer} from 'core/entities/TravelOrder/modules/travelOrderEventTimer';
import {getTravelOrderLastLocationEvent} from 'core/entities/TravelOrder/modules/travelOrderLocationEvents';

import TimeFrameView from 'components/common/Load/TimeFrameView';

import {createDate} from 'utils/dateTime';
import {getCityLineFromObject} from 'utils/getCityLine';

import WarningMessage from './components/WarningMessage';
import InfoMessage from './components/InfoMessage';
import DateAndTime from './components/DateAndTime';
import ETAInfoDate from './components/ETAInfoDate';
import DistanceDone from './components/DistanceDone';
import Location from './components/Location';
import AverageSpeed from './components/AverageSpeed';
import DistanceLeft from './components/DistanceLeft';

import styles from './styles.module.scss';

type OwnProps = {
    data: {
        travelOrder: TravelOrder;
    };
    onClose: () => void;
};

const bodyTitleSuccess = 'PREVIOUS AUTO CHECK CALL INFO';
const bodyTitleWarning = 'PROBLEMS OF CREATION AUTO CHECK CALL';

const AutoCheckCallInfoModal: React.FC<OwnProps> = (props) => {
    const {
        onClose,
        data: {travelOrder},
    } = props;

    const timer = getTravelOrderEventTimer(travelOrder);

    if (!timer?.autoCheckCallSettings) {
        return null;
    }

    const lastLocationEvent = getTravelOrderLastLocationEvent(travelOrder);

    const {event, status, etaMarginForDelay} = timer?.autoCheckCallSettings;

    const successState = status === 'inProgress';

    const modalBodyTitle = successState ? bodyTitleSuccess : bodyTitleWarning;
    const headerMessage = successState ? <InfoMessage /> : <WarningMessage />;

    const renderEventInfo = () => {
        if (!event || (successState && !lastLocationEvent?.isAutoCreated)) {
            return <p className={styles.sectionSubTitle}>Automation update check call not yet created</p>;
        }

        const dateTime = createDate(event.dateTime);
        const etaDate = createDate(event.eta);

        const location = getCityLineFromObject(event.location);

        const nextStop = getTravelOrderStopByID({stopID: getTravelOrderNextStopID(travelOrder), travelOrder});

        const {errors} = event;

        return (
            <div>
                <div className={styles.dataRow}>
                    <span className={styles.rowLeft}>Location:</span>
                    <div className={styles.rowRight}>
                        <Location error={errors.location} location={location} />
                    </div>
                </div>
                <div className={styles.dataRow}>
                    <span className={styles.rowLeft}>Date and time:</span>
                    <div className={styles.rowRight}>
                        <DateAndTime error={errors.dateTime} dateTime={dateTime} />
                    </div>
                </div>
                <div className={styles.dataRow}>
                    <span className={styles.rowLeft}>ETA:</span>
                    <div className={styles.rowRight}>
                        <ETAInfoDate date={etaDate.fullDate} error={errors.eta} late={event.etaLate} />
                        <div>
                            <span className="mr10">Schedule time:</span>
                            <TimeFrameView loadStop={nextStop?.loadStop} inLine={true} />
                        </div>
                        <span>Margin for delay - {etaMarginForDelay} min</span>
                    </div>
                </div>
                <div className={styles.dataRow}>
                    <span className={styles.rowLeft}>Distance done:</span>
                    <div className={styles.rowRight}>
                        <DistanceDone
                            error={errors.distance}
                            distanceDiffer={event.milesDiffer}
                            distance={event.distanceDone}
                        />
                    </div>
                </div>
                <div className={styles.dataRow}>
                    <span className={styles.rowLeft}>Average speed:</span>
                    <div className={styles.rowRight}>
                        <AverageSpeed error={errors?.averageSpeed} speed={event.averageSpeed} />
                        <DistanceLeft left={event.milesLeft} />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            dialogClassName={classNames('custom-modal', 'standard-form-modal', styles.modal)}
            backdrop="static"
            size="sm"
        >
            <Modal.Header className="modal-header__top">
                <Modal.Title>Auto Check Call information</Modal.Title>

                <button onClick={onClose} type="button" className="close">
                    <i className="fa fa-times" aria-hidden="true" />
                </button>
            </Modal.Header>

            <Modal.Body>
                <div className={styles.modalContentWrapper}>
                    {headerMessage}

                    <div className={styles.modalContent}>
                        <h4 className={styles.sectionTitle}>{modalBodyTitle}</h4>

                        {renderEventInfo()}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AutoCheckCallInfoModal;
