import {createSelector} from 'reselect';

import FIELDS from './fields';

const getBatchFromProps = (state, props) => props.batch;

export const getSendBatchEmailInitialData = createSelector([getBatchFromProps], (batch) => {
    return {
        [FIELDS.settlements]: {},
        [FIELDS.emailNote]: batch.note_to_email,
    };
});
