import React from 'react';
import {Modal} from 'react-bootstrap';

import Truck from 'core/entities/Truck/types';

import {UpdateStatusModalFormValues} from 'pages/Trucks/types/formTypes';

import Form from './components/Form';

import {createInitFormValues} from './utils';
import validate from './validation';

const TRUCK_LIST_STATUS_FORM_NAME = 'truck-list-status-form';

type OwnProps = {
    handlers: {onSubmit: (formData: Partial<UpdateStatusModalFormValues>) => void};
    data: {truck: Truck};
    onClose: () => void;
};

const UpdateStatusModal: React.FC<OwnProps> = (props) => {
    const {
        handlers: {onSubmit},
        data: {truck},
        onClose,
    } = props;

    const initialValues = createInitFormValues({truck});

    return (
        <Modal show={true} onHide={onClose} className="standard-form-modal" backdrop="static">
            <Modal.Header className="modal-header__top">
                <Modal.Title>Status and Location</Modal.Title>

                <button onClick={onClose} type="button" className="close">
                    <i className="fa fa-times" aria-hidden="true" />
                </button>
            </Modal.Header>

            <Modal.Body>
                <Form
                    form={TRUCK_LIST_STATUS_FORM_NAME}
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={onSubmit}
                    onClose={onClose}
                    truck={truck}
                />
            </Modal.Body>
        </Modal>
    );
};

export default UpdateStatusModal;
