import React from 'react';

const Header: React.FC = () => {
    return (
        <thead>
            <tr>
                <th className="text-center">TRUCK#</th>
                <th className="text-center">STATUS</th>
                <th>MAIN CONTACT</th>
                <th>MOBILE PHONE</th>
                <th className="text-center">ORIGIN AWAY</th>
            </tr>
        </thead>
    );
};

export default Header;
