import React from 'react';

import {FieldArrayFieldsProps} from 'redux-form';

import Button from 'components/ui/Buttons/Button';

import {LoadStop} from 'pages/LoadBoard/types';

import ButtonsBlock from './components/ButtonBlock';
import {getIsItemCanBeMovedUp, getIsItemCanBeMovedDown, getIsStopCanBeDeleted} from './utils';

type OwnProps = {
    currentItemIndex: number;
    fields: FieldArrayFieldsProps<LoadStop>;
    isExpanded: boolean;
    isLast: boolean;
    onToggleExpand: () => void;
    onCreatePickUp: () => void;
    onCreateDelivery: () => void;
};
const StopActions: React.FC<OwnProps> = ({
    currentItemIndex,
    fields,
    isExpanded,
    isLast,
    onToggleExpand,
    onCreatePickUp,
    onCreateDelivery,
}) => {
    const changeItemOrder = (oldItemIndex: number, newItemIndex: number): void =>
        fields.swap(oldItemIndex, newItemIndex);
    const removeItem = (itemIndex: number): void => fields.remove(itemIndex);

    const isItemCanBeMovedUp = getIsItemCanBeMovedUp({index: currentItemIndex, fields});
    const isItemCanBeMovedDown = getIsItemCanBeMovedDown({index: currentItemIndex, fields});
    const isStopCanBeDeleted = getIsStopCanBeDeleted({index: currentItemIndex, fields});

    return (
        <div className="d-flex justify-content-end">
            <Button
                onClick={() => changeItemOrder(currentItemIndex, currentItemIndex - 1)}
                buttonIcon="arrow-up"
                buttonSize="icon"
                className="btn-link mr10"
                disabled={!isItemCanBeMovedUp}
            />
            <Button
                onClick={() => changeItemOrder(currentItemIndex, currentItemIndex + 1)}
                buttonIcon="arrow-down"
                buttonSize="icon"
                className="btn-link mr10"
                disabled={!isItemCanBeMovedDown}
            />

            <Button
                onClick={() => removeItem(currentItemIndex)}
                buttonIcon="trash"
                buttonSize="icon"
                className="btn-link mr10"
                disabled={!isStopCanBeDeleted}
            />

            <ButtonsBlock>
                {!isLast && (
                    <Button onClick={onCreatePickUp} colorTheme="white" buttonSize="full-width" buttonIcon="level-down">
                        Add Pickup
                    </Button>
                )}

                <Button onClick={onCreateDelivery} colorTheme="white" buttonSize="full-width" buttonIcon="level-up">
                    Add Delivery
                </Button>
            </ButtonsBlock>

            <Button
                onClick={onToggleExpand}
                buttonIcon={`${isExpanded ? 'angle-up' : 'angle-down'}`}
                buttonSize="icon"
                className="btn-link"
            />
        </div>
    );
};

export default StopActions;
