import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {initialize} from 'redux-form';

import {getName, createInitFormValues} from 'components/common/LocationEvents/modals/CheckCallModal/utils';
import {setCheckCall} from 'components/common/LocationEvents/modals/CheckCallModal/redux';
import {AddressData} from 'components/ui/Form/InputWithPlacesAutocomplete';

import {GetCheckCallEventDataFromRequest} from 'core/entities/LocationEvents/types';
import TravelOrder from 'core/entities/TravelOrder/types';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';

import {formatCoordinates} from 'services/mapsApi';

import {createDate} from 'utils/dateTime';

type CreateFormData = {
    updateCoordinatesFromAutocomplete: (address: AddressData) => void;
    checkCallData?: GetCheckCallEventDataFromRequest;
    copyLastKnowLocationFromTruck: () => void;
};

export default ({travelOrder, form}: {travelOrder: TravelOrder; form: string}): CreateFormData => {
    const [checkCallData, setCheckCallData] = useState<GetCheckCallEventDataFromRequest>();
    const changeFormValue = useChangeReduxFormValue(form);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCheckCall(travelOrder, setCheckCallData));
    }, []);

    useEffect(() => {
        if (checkCallData) {
            const initData = createInitFormValues(checkCallData);
            dispatch(initialize(form, initData));
        }
    }, [checkCallData]);

    const updateCoordinatesFromAutocomplete = (address: AddressData): void => {
        const formattedCords = formatCoordinates(address.coords);
        changeFormValue(getName('latitude'), formattedCords.lat);
        changeFormValue(getName('longitude'), formattedCords.lng);
    };

    const copyLastKnowLocationFromTruck = (): void => {
        if (checkCallData) {
            const {
                mostRecentTruckLocation: {cityLine, dateTime, longitude, latitude},
            } = checkCallData;
            const {fullOriginalDateTime} = createDate(dateTime);
            changeFormValue(getName('cityLine'), cityLine);
            changeFormValue(getName('longitude'), longitude);
            changeFormValue(getName('latitude'), latitude);
            changeFormValue(getName('dateTime'), fullOriginalDateTime);
        }
    };

    return {checkCallData, updateCoordinatesFromAutocomplete, copyLastKnowLocationFromTruck};
};
