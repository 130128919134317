import Truck from 'core/entities/Truck/types';
import {getTruckTeam} from 'core/entities/Truck/modules/truckTeam';
import {getOwnerName} from 'core/entities/Owner/modules/ownerName';
import {getOwnerCoordinatorName} from 'core/entities/Owner/modules/ownerCoordinatorName';
import {getDriverName} from 'core/entities/Driver/modules/driverName';

export const getMainContact = (truck: Truck): string | undefined => {
    const truckTeam = getTruckTeam(truck);
    const {isCoordinatorResponsible, owner, firstDriver, mainContactPersonTitle, isOwnerResponsible} = truckTeam;
    if (isCoordinatorResponsible) {
        return `${mainContactPersonTitle} ${getOwnerCoordinatorName(truckTeam.owner)}`;
    }

    if (isOwnerResponsible && owner) {
        return `${mainContactPersonTitle} ${getOwnerName(truckTeam.owner)}`;
    }

    if (firstDriver) {
        return `${mainContactPersonTitle} ${getDriverName(truckTeam.firstDriver)}`;
    }
};
