import React from 'react';
import classNames from 'classnames';

import {getDelayStatus} from './utils';

import styles from './styles.module.scss';

type OwnProps = {
    endDate: string;
    isHighlightDelay?: boolean;
};

const DelayHighlighting: React.FC<OwnProps> = (props) => {
    const {endDate, isHighlightDelay = false, children} = props;

    if (!isHighlightDelay || !endDate) {
        return <>{children}</>;
    }

    const delayStatus = getDelayStatus({date: endDate});

    return <div className={classNames(styles.container, styles[`${delayStatus}`])}>{children}</div>;
};

export default DelayHighlighting;
