import React from 'react';

import {LastEventForCurrentSummary} from 'core/entities/LocationEvents/types';

import LoadInfo from './LoadInfo';
import LoadSummaryItem from './LoadSummaryItem';

type OwnType = {
    form: string;
    items: {
        loadNumber: number;
        customerID: number;
        customerName: string;
        lastEventForCurrentSummary: LastEventForCurrentSummary;
    }[];
};

const LoadsSummaryInfo: React.FC<OwnType> = (props) => {
    const {form, items} = props;

    const summaryList = items.map((item, index) => (
        <LoadSummaryItem key={index} form={form} formSection="summaryItem" summary={item} />
    ));

    return (
        <>
            <LoadInfo />
            {summaryList}
        </>
    );
};

export default LoadsSummaryInfo;
