import React from 'react';
import {useLocation} from 'react-router';

import Owner from 'core/entities/Owner/types';
import {getOwnerName} from 'core/entities/Owner/modules/ownerName';

import ArchivingSuccessWrapper from 'components/ui/modals/ArchivingSuccessWrapper';
import ButtonLink from 'components/ui/Buttons/ButtonLink';
import Button from 'components/ui/Buttons/Button';
import {getOwnerLinks} from 'routing/linkBuilder';

type OwnProps = {
    data: {owner: Owner};
    handlers: {onViewArchivedList()};
    onClose();
};
const ArchivingSuccess: React.FC<OwnProps> = (props) => {
    const {
        data: {owner},
        handlers: {onViewArchivedList},
        onClose,
    } = props;
    const ownerName = getOwnerName(owner);
    const {view: viewLink, list: listOwnersLink} = getOwnerLinks(owner);
    const location = useLocation();
    const isListPage = location?.pathname === listOwnersLink;
    const buttons = (
        <>
            <div />
            <div className="d-flex justify-content-end">
                <Button onClick={onViewArchivedList} buttonSize="medium" colorTheme="grey" className="mr10">
                    View Archive
                </Button>

                {isListPage ? (
                    <ButtonLink href={viewLink} buttonSize="medium" colorTheme="green" className="mr10">
                        View Profile
                    </ButtonLink>
                ) : (
                    <Button onClick={onClose} buttonSize="medium" colorTheme="green" className="mr10">
                        View Profile
                    </Button>
                )}
            </div>
        </>
    );
    return (
        <ArchivingSuccessWrapper buttons={buttons}>
            <p>
                <strong>Owner {ownerName}</strong> profile has been moved to archive successfully.
            </p>
        </ArchivingSuccessWrapper>
    );
};

export default ArchivingSuccess;
