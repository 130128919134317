import * as truckStatuses from 'core/entities/Truck/constants/truckStatuses';

export const statusOptions = [
    {
        label: truckStatuses.TRUCK_STATUS_AVAILABLE,
        value: truckStatuses.TRUCK_STATUS_AVAILABLE,
    },
    {
        label: truckStatuses.TRUCK_STATUS_WILL_BE_AVAILABLE_AT,
        value: truckStatuses.TRUCK_STATUS_WILL_BE_AVAILABLE_AT,
    },
    {
        label: truckStatuses.TRUCK_STATUS_NOT_AVAILABLE,
        value: truckStatuses.TRUCK_STATUS_NOT_AVAILABLE,
    },
] as const;
