// https://github.com/uuidjs/uuid#deep-requires-now-deprecated
import {v4 as uuidv4} from 'uuid';

import restapi from 'services/restapi/index';

import {PlaceItem} from './types';

export const getPlaces = (query: string, isStrictZip = false): Promise<PlaceItem[] | undefined> => {
    const URL = '/map/places';

    return restapi
        .get(`${URL}?q=${query}&strictZip=${isStrictZip}`)
        .then((res) => {
            // in some cases like with ru lang API return 200 response with empty body
            if (res && res.data) {
                return res.data.map((item) => ({...item, id: uuidv4()}));
            }
            return [];
        })
        .catch((error) => {
            console.error('Error on get map places: ', error);
        });
};

export const getPlaceByGeocoding = (params: {lat: string; lng: string}): Promise<PlaceItem | undefined> => {
    const URL = '/map/reverse';
    return restapi
        .get(`${URL}`, {params})
        .then((res) => {
            return {...res.data, id: uuidv4()};
        })
        .catch((error) => {
            console.error('Error on get map place by coords', error);
        });
};
