import React from 'react';
import {Field} from 'redux-form';
import CurrencyInput from 'components/ui/Form/MaskedInput/CurrencyInput';
import RequiredLabel from 'components/ui/Form/RequiredLabel';

const Rate: React.FC<{fieldName: string}> = (props) => {
    const {fieldName} = props;
    return <Field label={RequiredLabel('Rate')} name={fieldName} component={CurrencyInput} placeholder="enter rate" />;
};

export default Rate;
