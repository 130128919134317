import {AxiosResponse} from 'axios';

import Carrier from 'core/entities/Carrier/types';
import {Dispatcher} from 'core/entities/Dispatcher/types';
import {LOAD_STATUS_IN_PROGRESS} from 'core/entities/Load/modules/loadStatus';
import Load from 'core/entities/Load/types';
import {CreateUpdateLoadParamsForApi, MetaData} from 'core/useCases/Load/types';

import restApi from 'services/restapi';

import {ENTITY_NAME_LOADS} from 'utils/data/entityNames';
import parseFileExtraData from 'utils/files/parseFileExtraData';

import {FileNew} from 'types/File';
import GeneralNote from 'types/GeneralNote';

const URL = `/${ENTITY_NAME_LOADS}`;

export const fetchLoadFiles = async (loadID: number, filesType?: string) => {
    let result = [];

    try {
        const getParams = {entity: 'loads', id: loadID, type: filesType};
        result = await restApi('files', {params: getParams}).then(
            (response) => response.data && response.data.map(parseFileExtraData),
        );
    } catch (error) {
        console.warn('Fetch load files error: ', error);
    }

    return result;
};

export const createLoad = (loadData: CreateUpdateLoadParamsForApi): Promise<AxiosResponse> => {
    return restApi.post(URL, loadData);
};

export const updateLoad = (changedLoad: CreateUpdateLoadParamsForApi): Promise<AxiosResponse> => {
    return restApi.put(`load/${changedLoad.number}`, changedLoad);
};

export const updateLoadFilesMetaRequest = (loadNumber: number, dataForRequest): Promise<AxiosResponse> => {
    return restApi.post(`load/${loadNumber}/files/meta`, dataForRequest);
};

export const addLoadAllFiles = (loadNumber: number, filesData): Promise<AxiosResponse> => {
    return restApi.post(`load/${loadNumber}/files`, filesData, {headers: {'Content-Type': 'multipart/form-data'}});
};

export const getLoad = (loadID: number): Promise<AxiosResponse> => {
    return restApi.get(`sp-loads/${loadID}`);
};

export const updateLoadEmails = (loadID: number, emails: string): Promise<AxiosResponse> => {
    return restApi.put(`${URL}/${loadID}/email`, {emails});
};

type LoadSearchParams = Partial<{
    loadNumber: number;
    refNumbers: string;
    travelOrderNumber: number;

    truckNumber: string;
    carrierId: number | null;
    firstPickUpFacilityId: number | null;
    lastDeliveryFacilityId: number | null;
    bookedCustomerId: number | null;
    bookedByDispatcherId: number | null;
    dispatchersAssignToLoad: number[] | null;
    truckTypes: string[] | null;
    trailerTypes: string[] | null;
    truckEquipment: string[] | null;

    bookedDateFrom: string;
    bookedDateTo: string;
    firstPickUpCity: string;
    firstPickUpState: string;
    firstPickUpZip: string;
    firstPickUpDateFrom: string;
    firstPickUpDateTo: string;
    lastDeliveryCity: string;
    lastDeliveryState: string;
    lastDeliveryZip: string;
    lastDeliveryDateFrom: string;
    lastDeliveryDateTo: string;
    loadStatus: string[];

    carrier?: number;
    customer?: number;
    status: string[];
}>;

export const addLoadGeneralNoteRequest = (loadNumber: number, params: {note: string; isImportant?: boolean}) =>
    restApi.put<GeneralNote>(`/loads/${loadNumber}/note`, params);

export const searchLoads = (params: LoadSearchParams): Promise<AxiosResponse> => {
    return restApi.post(`sp-loads`, params);
};

export const searchInProgressLoadsByCarrier = (carrier: Carrier): Promise<Load[]> => {
    const searchParams = {carrierId: carrier.id, loadStatus: [LOAD_STATUS_IN_PROGRESS]};
    return searchLoads(searchParams).then((response) => response.data);
};

export const searchLoadsByDispatcherAndStatus = (dispatcher: Dispatcher, listStatuses: string[]): Promise<Load[]> => {
    const searchParams = {bookedByDispatcherId: dispatcher.id, loadStatus: listStatuses};
    return searchLoads(searchParams).then((response) => response.data);
};

export const createTravelOrderWithTruck = ({truckNumber, loadNumber}): Promise<AxiosResponse> => {
    return restApi.post(`/travel-order/attach/truck/${truckNumber}/load/${loadNumber}`);
};

export const createTravelOrderWithCarrier = ({carrierId, loadNumber}): Promise<AxiosResponse> => {
    return restApi.post(`/travel-order/attach/carrier/${carrierId}/load/${loadNumber}`);
};

export const generateLoadBOL = (loadNumber: number): Promise<AxiosResponse> => {
    return restApi.post(`/loads/${loadNumber}/generate-bol`);
};

export const generateLoadInvoice = (loadNumber: number, files: FileNew[]): Promise<AxiosResponse> => {
    return restApi.post(`/loads/${loadNumber}/generate-invoice`, files);
};

export const cancelLoadRequest = (
    loadNumber: number,
    params: {makeWaitingForTONU: boolean; note: string; isImportant: boolean},
): Promise<AxiosResponse> => {
    return restApi.put(`/load/${loadNumber}/cancel`, params);
};

export const changeLoadStatusToTONURequest = (
    loadNumber: number,
    params: {totalCharge: number; files?: MetaData[]},
): Promise<AxiosResponse> => {
    return restApi.put(`/load/${loadNumber}/tonu`, params);
};

export const changeLoadStatusToNoTONURequest = (
    loadNumber: number,
    params: {note: string; isImportant: boolean},
): Promise<AxiosResponse> => {
    return restApi.put(`/load/${loadNumber}/no-tonu`, params);
};

export const changeLoadStatusToAvailableRequest = (loadNumber: number): Promise<AxiosResponse> => {
    return restApi.put(`/load/${loadNumber}/back-to-available`);
};

export const changeLoadStatusToWaitingForTONURequest = (loadNumber: number): Promise<AxiosResponse> => {
    return restApi.put(`/load/${loadNumber}/back-to-waiting`);
};

const commoditiesBaseURL = 'registry/commodity';

export const sendUpdatedCommoditiesRequest = (commodities: string[]) => {
    return restApi.post(commoditiesBaseURL, {commodities});
};

export const fetchSimilarCommoditiesList = (input: string) => {
    return restApi.get(`${commoditiesBaseURL}/${input}`);
};
