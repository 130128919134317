import {RoutesPaths, SidebarIcons} from 'routing/types';

export const ROUTES_PATHS: RoutesPaths = {
    login: '/login',
    notFound: '',
    trucks: '',
    trucksList: '/trucks',
    map: '/map',
    trucksNearby: '/trucks-nearby',
    auction: '/auction',
    loads: '/loads',
    tripMonitor: '/trip-monitor',
    billing: '',
    invoices: '/invoices',
    ownerSettlements: '/settlements/owners',
    carrierSettlements: '/settlements/carriers',
    owners: '/owners',
    drivers: '/drivers',
    users: '/dispatchers',
    contragents: '',
    carriers: '/carriers',
    customers: '/customers',
    facilities: '/facilities',
    factoringCompanies: '/factoring-companies',
    reports: '/reports',
    changesLog: '/changes-log',
    releaseNotes: '/release-notes',
    myCompany: '/my-company',
    fullAlliance: '/full-alliance',
} as const;

export const SIDEBAR_ICONS: SidebarIcons = {
    trucks: 'fa fa-truck',
    auction: 'fa fa-cubes',
    loads: 'fa fa-cube',
    tripMonitor: 'fa fa-road',
    billing: 'fa fa-bar-chart',
    owners: 'fa fa-user',
    drivers: 'fa fa-car',
    users: 'fa fa-users',
    contragents: 'fa fa-shield',
    reports: 'fa fa-list-alt',
    changesLog: 'fa fa-history',
    releaseNotes: 'fa fa-sticky-note',
    myCompany: 'fa fa-address-card',
} as const;
