import React from 'react';

import Customer from 'core/entities/Customer/types';

import ArchivingErrorWrapper from 'components/ui/modals/ArchivingErrorWrapper';

type OwnProps = {
    data: {
        customer: Customer;
    };

    onClose: () => void;
};

const ArchivingError: React.FC<OwnProps> = (props) => {
    const {onClose} = props;

    return (
        <ArchivingErrorWrapper onClose={onClose}>
            <div>
                <p>
                    The customer is in the <strong>incompleted load</strong> or invoice status is not &#39;posted&#39;.
                    Moving the customer to the archive is not possible. Please wait for the Travel Order to be finished
                    and the invoice to be posted.
                </p>
            </div>
        </ArchivingErrorWrapper>
    );
};

export default ArchivingError;
