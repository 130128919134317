import {ListState} from 'pages/TrucksNearby/redux/reducers/list';

import Truck from 'core/entities/Truck/types';

export const handleReducerByWSReserveReceived = (params: {state: ListState; reserveData: Truck['reserve']}) => {
    const {state, reserveData} = params;

    const items = state.items.map((item) =>
        item.id === reserveData?.truckId ? {...item, reserve: {...reserveData}} : item,
    );

    return {
        ...state,
        items,
    };
};

export const handleReducerByWSReserveCanceled = (params: {state: ListState; truckID: Truck['id']}) => {
    const {state, truckID} = params;

    const items = state.items.map((item) => (item.id === truckID ? {...item, reserve: null} : item));

    return {
        ...state,
        items,
    };
};

export const handleReducerByWSReserveUpdateReceived = (params: {state: ListState; newTruck: Truck}) => {
    const {state, newTruck} = params;

    const items = state.items.map((item) => (item.id === newTruck.id ? {...newTruck} : item));

    return {
        ...state,
        items,
    };
};

export const handleReducerByCrossServerReservationCompleted = (params: {
    state: ListState;
    reserveData: Truck['reserve'];
}) => {
    const {state, reserveData} = params;

    const items = state.items.map((item) =>
        item.id === reserveData?.truckId ? {...item, reserve: {...reserveData}} : item,
    );

    return {
        ...state,
        items,
    };
};

export const handleReducerByCrossServerReservationCanceled = (params: {
    state: ListState;
    reserveData: Truck['reserve'];
}) => {
    const {state, reserveData} = params;

    const items = state.items.map((item) => (item.id === reserveData?.truckId ? {...item, reserve: null} : item));

    return {
        ...state,
        items,
    };
};
