import React from 'react';
import {reduxForm, InjectedFormProps, ClearFieldsAction} from 'redux-form';

import TravelOrder from 'core/entities/TravelOrder/types';
import {getTruckFromTheTravelOrder} from 'core/entities/TravelOrder/modules/travelOrderTruck';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import Button from 'components/ui/Buttons/Button';

import {getName} from 'pages/TravelOrders/components/common/modals/TimerSettingsModal/components/Form/utils';

import CheckCallIntervalSection from './components/CheckCallIntervalSection';
import AutoCheckCallSwitch from './components/AutoCheckCallSwitch';
import CheckCallTruckInfoSection from './components/CheckCallTruckInfoSection';
import CheckCallSendToSection from './components/CheckCallSendToSection';

import validation from './validation';

import styles from './styles.module.scss';

const FORM = 'timer-settings-form';

type OwnProps = {
    travelOrder: TravelOrder;
    onClose: () => void;
    clearFields: ClearFieldsAction;
    brokerEmailsOptions: Array<{label: string; value: string}>;
    emailFrom: string;
    distanceDiffer: number;
    canCreateAutoCheckCall: boolean;
};

const Form: React.FC<OwnProps & InjectedFormProps<{}, OwnProps>> = (props) => {
    const {
        form,
        handleSubmit,
        onClose,
        travelOrder,
        clearFields,
        brokerEmailsOptions,
        emailFrom,
        canCreateAutoCheckCall,
        distanceDiffer,
    } = props;

    const autoCheckCallEnabled = useGetReduxFormValue(form, getName('autoCheckCallEnabled'));

    const truck = getTruckFromTheTravelOrder(travelOrder);

    return (
        <form onSubmit={handleSubmit} className={styles.container}>
            <CheckCallIntervalSection truckNumber={truck?.number || ''} form={form} clearFields={clearFields} />

            {canCreateAutoCheckCall ? (
                <div className={styles.sectionWrapper}>
                    <AutoCheckCallSwitch />

                    {autoCheckCallEnabled ? (
                        <>
                            <CheckCallTruckInfoSection
                                form={form}
                                distanceDiffer={distanceDiffer}
                                clearFields={clearFields}
                            />

                            <CheckCallSendToSection
                                form={form}
                                emailFrom={emailFrom}
                                brokerEmailsOptions={brokerEmailsOptions}
                            />
                        </>
                    ) : null}
                </div>
            ) : null}

            <div className="modal-body__bottom">
                <Button onClick={onClose} colorTheme="grey" buttonSize="small">
                    Cancel
                </Button>

                <Button type="submit" colorTheme="blue" buttonSize="small">
                    Save
                </Button>
            </div>
        </form>
    );
};

export default reduxForm<{}, OwnProps>({
    form: FORM,
    validate: validation,
})(Form);
