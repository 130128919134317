import React from 'react';

import {getCarrierPhysicalAddressAllInfo} from 'core/entities/Carrier/modules/carrierAddress';
import Carrier from 'core/entities/Carrier/types';

type OwnProps = {
    carrier: Carrier;
};

const CarrierItemPhysicalAddress: React.FC<OwnProps> = (props) => {
    const {carrier} = props;

    const {
        carrierPhysicalAddressLine,
        carrierPhysicalState,
        carrierPhysicalCity,
        carrierPhysicalZip,
    } = getCarrierPhysicalAddressAllInfo(carrier);

    return (
        <div className="p10">
            <div>{carrierPhysicalAddressLine}</div>

            <div>
                {carrierPhysicalCity}, {carrierPhysicalState} {carrierPhysicalZip}
            </div>
        </div>
    );
};

export default CarrierItemPhysicalAddress;
