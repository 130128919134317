import React, {useState} from 'react';
import {components} from 'react-select';

import Button from 'components/ui/Buttons/Button';

const HIDE_COPIED_TIMEOUT = 300;

const MultiValueContainer = (multivalueContainerProps) => {
    const {value} = multivalueContainerProps.data;

    const [isCopies, setIsCopied] = useState(false);

    const handleOnCopy = async () => {
        try {
            await navigator.clipboard.writeText(value);
            setIsCopied(true);

            setTimeout(() => {
                setIsCopied(false);
            }, HIDE_COPIED_TIMEOUT);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="email-select-value-container">
            <div onClick={handleOnCopy}>
                <Button
                    colorTheme="grey"
                    buttonSize="xs"
                    buttonIcon={isCopies ? 'check' : 'files-o'}
                    style={{margin: '2px 0'}}
                />
            </div>
            <components.MultiValueContainer {...multivalueContainerProps} />
        </div>
    );
};

export default MultiValueContainer;
