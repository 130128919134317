import isEmpty from 'lodash/isEmpty';

import {store} from 'store';
import {getCurrentRegion} from 'store/reducers/appSettings/selectors';

import {CUSTOMER_TYPES} from 'core/entities/Customer/constants/customerTypes';
import {FIELDS} from 'core/entities/Customer/constants/fields';
import * as customersErrorMessages from 'core/entities/Customer/validation/errorMessages';

import {isEuropeRegion} from 'utils/data/region';
import {isValidLength} from 'utils/validation';
import * as errorMessages from 'utils/validation/errorMessages';
import * as regexps from 'utils/validation/regexps';

const minRegAndMcNumber = 2;
const maxRegAndMcNumber = 7;

export default (values) => {
    const currentRegion = getCurrentRegion(store.getState());
    const isEurope = isEuropeRegion(currentRegion);
    const errors = {load_boards: []};

    if (!values[FIELDS.name] || !values[FIELDS.name].trim()) {
        errors[FIELDS.name] = customersErrorMessages.NAME_REQUIRED;
    }

    if (!values[FIELDS.customerType]) {
        errors[FIELDS.customerType] = customersErrorMessages.CUSTOMER_TYPE_CHOOSE_ERROR;
    }

    if (
        values[FIELDS.customerType] === CUSTOMER_TYPES.OTHER &&
        (!values[FIELDS.customerTypeNote] || !values[FIELDS.customerTypeNote].trim())
    ) {
        errors[FIELDS.customerTypeNote] = customersErrorMessages.CUSTOMER_TYPE_ENTER_ERROR;
    }

    if (values[FIELDS.isThirdApiBroker] && !values[FIELDS.apiConnection]) {
        errors[FIELDS.apiConnection] = customersErrorMessages.CUSTOMER_API_CONNECTION_ERROR;
    }

    if (values[FIELDS.isExternalCustomer] && !values[FIELDS.externalApiIdentifier]) {
        errors[FIELDS.externalApiIdentifier] = customersErrorMessages.CUSTOMER_EXTERNAL_API_IDENTIFIER_ERROR;
    }

    if (values[FIELDS.reg_number] && !isValidLength(minRegAndMcNumber, maxRegAndMcNumber, values[FIELDS.reg_number])) {
        errors[FIELDS.reg_number] = customersErrorMessages.REG_MORE_DIGITS;
    }

    if (values[FIELDS.mc_number] && !isValidLength(minRegAndMcNumber, maxRegAndMcNumber, values[FIELDS.mc_number])) {
        errors[FIELDS.mc_number] = customersErrorMessages.MC_MORE_DIGITS;
    }

    if (!values[FIELDS.is_active] && !values[FIELDS.on_hold_reason]) {
        errors[FIELDS.on_hold_reason] = customersErrorMessages.ON_HOLD_REASON_REQUIRED;
    }

    if (!values[FIELDS.main_phone]) {
        errors[FIELDS.main_phone] = customersErrorMessages.PHONE_REQUIRED;
    } else if (!values[FIELDS.main_phone].trim()) {
        errors[FIELDS.main_phone] = errorMessages.INVALID_MOBILE_PHONE;
    }

    if (values[FIELDS.fax] && !values[FIELDS.fax].trim()) {
        errors[FIELDS.fax] = errorMessages.INVALID_MOBILE_PHONE;
    }

    if (values[FIELDS.email] && !regexps.EMAIL.test(values[FIELDS.email])) {
        errors[FIELDS.email] = errorMessages.INVALID_EMAIL_ADDRESS;
    }

    if (values[FIELDS.website] && !regexps.DOMAIN.test(values[FIELDS.website])) {
        errors[FIELDS.website] = errorMessages.INVALID_WEBSITE;
    }

    if (!values[FIELDS.billing_address_line]) {
        errors[FIELDS.billing_address_line] = errorMessages.ADDRESS_LINE_REQUIRED;
    }

    if (!values[FIELDS.billing_city]) {
        errors[FIELDS.billing_city] = errorMessages.CITY_REQUIRED;
    } else if (values[FIELDS.billing_city] && !values[FIELDS.billing_city].match(regexps.DIGITS_OR_LETTERS)) {
        errors[FIELDS.billing_city] = errorMessages.INPUT_MUST_CONTAIN_DIGITS_OR_LETTERS;
    }

    if (!isEurope && !values[FIELDS.billing_state]) {
        errors[FIELDS.billing_state] = errorMessages.STATE_REQUIRED;
    } else if (values[FIELDS.billing_state] && !values[FIELDS.billing_state].match(regexps.DIGITS_OR_LETTERS)) {
        errors[FIELDS.billing_state] = errorMessages.INPUT_MUST_CONTAIN_DIGITS_OR_LETTERS;
    }

    if (!isEurope && !values[FIELDS.billing_zip]) {
        errors[FIELDS.billing_zip] = errorMessages.ZIP_REQUIRED;
    } else if (values[FIELDS.billing_zip] && !values[FIELDS.billing_zip].match(regexps.DIGITS_OR_LETTERS)) {
        errors[FIELDS.billing_zip] = errorMessages.INPUT_MUST_CONTAIN_DIGITS_OR_LETTERS;
    }

    if (!values[FIELDS.billing_country]) {
        errors[FIELDS.billing_country] = errorMessages.COUNTRY_REQUIRED;
    } else if (values[FIELDS.billing_country] && !values[FIELDS.billing_country].match(regexps.DIGITS_OR_LETTERS)) {
        errors[FIELDS.billing_country] = errorMessages.INPUT_MUST_CONTAIN_DIGITS_OR_LETTERS;
    }

    if (values[FIELDS.load_boards] && values[FIELDS.load_boards].length > 0) {
        if (isEmpty(values[FIELDS.load_boards])) {
            return [];
        }

        values[FIELDS.load_boards].forEach((item, itemIndex) => {
            const itemError = {};

            if (!item.name && item.id) {
                itemError.name = '"name" and "ID" fields must be populated together. Please enter name.';
            }

            if (item.name && !item.id) {
                itemError.id = '"name" and "ID" fields must be populated together. Please enter ID.';
            }

            if (!isEmpty(itemError)) {
                errors.load_boards[itemIndex] = itemError;
            }
        });
    }

    if (!errors.load_boards.length) {
        delete errors.load_boards;
    }

    return errors;
};
