import React from 'react';

import Batch from 'core/entities/Invoice/types/Batch';
import Customer from 'core/entities/Customer/types';
import {CustomerLink} from 'components/ui/Links';

interface OwnProps {
    batch: Batch;
}

const getCustomerBillingMethodTitle = (customer: Customer | null | undefined): string => {
    if (!customer) {
        return '';
    }
    if (customer.billing_method === 'factored') {
        return 'factoring company';
    }
    return customer.billing_method;
};

const BatchCustomerLink = (props: OwnProps): JSX.Element => {
    const {batch} = props;
    return (
        <div>
            {batch.customer ? (
                <div>
                    <CustomerLink customer={batch.customer} content={batch.customer.name} className="second-link" />
                    <div>{getCustomerBillingMethodTitle(batch.customer)}</div>
                </div>
            ) : (
                'Factoring Companies'
            )}
        </div>
    );
};

export default BatchCustomerLink;
