import groupBy from 'lodash/groupBy';

import {Dispatcher} from 'core/entities/Dispatcher/types';
import {TIME_FRAME_FCFS} from 'core/entities/Load/constants';
import * as statuses from 'core/entities/Load/modules/loadStatus';
import Load, {DeliveryStop, PickupStop} from 'core/entities/Load/types';
import {STOP_STATUSES} from 'core/entities/TravelOrder/constants';
import {GENERAL_TRUCK_TYPES} from 'core/entities/Truck/constants/truckTypes';
import {convertToStopDataForUpdate, transformEmailsFromApi} from 'core/useCases/Load/utils';

import {isEuropeRegion} from 'utils/data/region';
import {getCurrentDate} from 'utils/dateTime';

import {EmailsToUpdate} from '../components/common/Forms/GeneralInfo/hooks/useEmailsToUpdate';
import {DynamicFileFields, LoadFormValues} from '../types/formTypes';

type LoadStop = PickupStop | DeliveryStop;
type LoadCreateStopInitValues = Partial<LoadStop>[];
type LoadCreateRateInitValues = Partial<Load['rate']>;
type LoadCreateValues = Partial<Omit<LoadFormValues, 'stops' | 'files' | 'rate' | 'emailsToUpdate'>> & {
    stops: LoadCreateStopInitValues;
    emailsToUpdate: EmailsToUpdate;
    rate: LoadCreateRateInitValues;
};
type LoadFilesInitValues = {
    files: {
        load: DynamicFileFields;
    };
};
type LoadCreateInitValue = LoadCreateValues & LoadFilesInitValues;

const truckTypesInitialValues = [
    GENERAL_TRUCK_TYPES.BOX_TRUCK,
    GENERAL_TRUCK_TYPES.CARGO_VAN,
    GENERAL_TRUCK_TYPES.STRAIGHT_TRUCK,
];

export const getIsUpdatesSentToDispatcherEmail = (load: Load): boolean =>
    load.emailsToUpdate.includes(load?.bookedByDispatcher?.email);
export const getIsUpdatesSentToSalesRepEmail = (load: Load): boolean =>
    load.emailsToUpdate.includes(load?.bookedBySalesRep?.email);

export const getIsUpdatesSentToAgentEmail = (load: Load): boolean => load.emailsToUpdate.includes(load.agent?.email);

export const getInitValuesForLoadCreate = ({
    currentRegion,
    currentTimeZone,
    defaultWeight,
    defaultDimensions,
}): Partial<LoadCreateInitValue> => {
    const isEurope = isEuropeRegion(currentRegion);
    const {originalDate: currentDate} = getCurrentDate();
    const dateStart = `${currentDate} 08:00`;
    const dateEnd = `${currentDate} 15:00`;
    const loadStops: LoadCreateStopInitValues = [
        {
            type: 'pick up',
            orderNumber: 1,
            facility: null,
            facilityId: null,
            timeFrame: TIME_FRAME_FCFS,
            dateStart,
            dateEnd,
            timeZone: currentTimeZone,
            note: '',
            status: STOP_STATUSES.PLANNED,
            freights: [
                {
                    id: undefined as any,
                    pieces: null as any,
                    weight: {
                        amount: null,
                        unit: defaultWeight,
                    },
                    dims: {
                        unit: defaultDimensions,
                        length: null,
                        height: null,
                        width: null,
                    },
                    isStackable: false,
                    isHazmat: false,
                },
            ],
        },
        {
            type: 'delivery',
            orderNumber: 2,
            timeFrame: TIME_FRAME_FCFS,
            dateStart,
            dateEnd,
            timeZone: currentTimeZone,
            note: '',
            status: STOP_STATUSES.PLANNED,
        },
    ];

    return {
        sourceBoard: null,
        status: statuses.LOAD_STATUS_AVAILABLE,
        rate: {
            amount: undefined,
            currency: isEurope ? 'EUR' : 'USD',
        },
        refNumbers: [''],
        emailsToUpdate: {
            salesRep: '',
            dispatcher: '',
            agent: '',
            additional: [],
        },
        stops: loadStops,
        truckTempMin: undefined as any,
        truckTempMax: undefined as any,
        truckTypes: truckTypesInitialValues,
        teamDriversOnly: false,
        dispatchersAssignToLoad: undefined,
        truckWithTempControl: false,
        files: {
            load: {
                rc: [],
                bol: [],
                pod: [],
                files: [],
                extra: [],
            },
        },
    };
};

export const getInitValuesForLoadUpdate = (existsLoad: Load) => {
    const emailsToUpdate = transformEmailsFromApi(existsLoad);
    const bookedBySalesRep = existsLoad.bookedBySalesRep
        ? {value: existsLoad.bookedBySalesRep, label: existsLoad.bookedBySalesRep.fake_full_name}
        : null;

    return {
        ...existsLoad,
        emailsToUpdate,
        bookedByDispatcher: {value: existsLoad.bookedByDispatcher, label: existsLoad.bookedByDispatcher.fake_full_name},
        bookedBySalesRep,
        sourceBoard: existsLoad?.source ? {label: existsLoad.source, value: existsLoad.source} : null,
        stops: existsLoad.stops.map(convertToStopDataForUpdate),
        files: {load: groupBy(existsLoad.files, 'attachType')},
        dispatchersAssignToLoad: existsLoad.dispatchersAssignToLoad?.map((dispatcher: Dispatcher) => dispatcher.id),
        dispatcher_send_updates: getIsUpdatesSentToDispatcherEmail(existsLoad),
        salesRep_send_updates: getIsUpdatesSentToSalesRepEmail(existsLoad),
        agent_send_updates: getIsUpdatesSentToAgentEmail(existsLoad),
    };
};
