import {getEntityFieldName} from 'core/entities/Customer/types';

export const FIELDS = {
    id: getEntityFieldName('id'),
    name: getEntityFieldName('name'),
    main_phone: getEntityFieldName('main_phone'),
    after_hours_phone: getEntityFieldName('after_hours_phone'),
    fax: getEntityFieldName('fax'),
    email: getEntityFieldName('email'),
    website: getEntityFieldName('website'),

    billing_address_line: getEntityFieldName('billing_address_line'),
    billing_additional_address_line: getEntityFieldName('billing_additional_address_line'),
    billing_city: getEntityFieldName('billing_city'),
    billing_state: getEntityFieldName('billing_state'),
    billing_zip: getEntityFieldName('billing_zip'),
    billing_country: getEntityFieldName('billing_country'),
    billing_method: getEntityFieldName('billing_method'),
    payment_terms: getEntityFieldName('payment_terms'),

    mc_number: getEntityFieldName('mc_number'),
    identifier_number: getEntityFieldName('identifier_number'),
    identifier_type: getEntityFieldName('identifier_type'),
    reg_number: getEntityFieldName('reg_number'),
    us_dot_number: getEntityFieldName('us_dot_number'),
    notes: getEntityFieldName('notes'),
    in_black_list: getEntityFieldName('in_black_list'),
    is_active: getEntityFieldName('is_active'),
    is_customer: getEntityFieldName('is_customer'),
    is_deleted: getEntityFieldName('is_deleted'),
    on_hold_reason: getEntityFieldName('on_hold_reason'),

    load_boards: getEntityFieldName('load_boards'),
    customerType: getEntityFieldName('customerType'),
    customerTypeNote: getEntityFieldName('customerTypeNote'),

    isThirdApiBroker: getEntityFieldName('isThirdApiBroker'),
    apiConnection: getEntityFieldName('apiConnection'),
    isDispatchlandCustomer: getEntityFieldName('isDispatchlandCustomer'),

    externalApiIdentifier: getEntityFieldName('externalApiIdentifier'),
    isExternalCustomer: getEntityFieldName('isExternalCustomer'),

    // custom field
    billingLocation: 'billingLocation',
};
