import React from 'react';
import {Modal} from 'react-bootstrap';

import {DriverOffer} from 'core/entities/Quote/types';
import Button from 'components/ui/Buttons/Button';

import styles from './styles.module.scss';

interface OwnProps {
    data: {
        offerNumber: string;
        offerStatus: DriverOffer['status'];
    };
    onClose(): void;
    handlers: {onSubmit(): void};
}

const getStatusTitle = (offerStatus: DriverOffer['status']): string => {
    if (offerStatus === 'APPROVED') {
        return 'Approve';
    }
    if (offerStatus === 'NOT_APPROVED') {
        return 'Note Approve';
    }
    return '';
};

const OfferCancelStatusModal: React.FC<OwnProps> = (props) => {
    const {onClose, data, handlers} = props;
    const {offerNumber, offerStatus} = data;
    const {onSubmit} = handlers;
    const statusTitle = getStatusTitle(offerStatus);
    return (
        <Modal show={true} onHide={onClose} className="info-modal" backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <h4>Cancel {statusTitle} Offer ?</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.body}>
                <i className="fa fa-exclamation feedback-icon-error" />
                <div className={styles.wrapper}>
                    Do you cancel the <strong>{statusTitle}</strong> offer <strong> #{offerNumber}</strong>?
                </div>
            </Modal.Body>
            <Modal.Footer className={styles.footer}>
                <Button onClick={onClose} className="mr10" colorTheme="grey" buttonSize="small" defaultFocus={true}>
                    Close
                </Button>
                <Button onClick={onSubmit} className="mr10" colorTheme="red" buttonSize="small" defaultFocus={true}>
                    Cancel {statusTitle}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OfferCancelStatusModal;
