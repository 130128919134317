import React, {PureComponent} from 'react';
import {Modal} from 'react-bootstrap';

import Form from 'components/common/Truck/modals/DispatcherNoteModal/Form';

class DispatcherNoteModal extends PureComponent {
    constructor(props) {
        super(props);

        const {truck} = props.data;

        this.initValues = {
            text: truck.dispatcher_note,
        };
    }

    formSubmitHandler = (values) => {
        const {text} = values;
        const {
            data: {truck},
            handlers: {onSubmit},
            onClose,
        } = this.props;
        onSubmit(truck.id, text);
        onClose();
    };

    render() {
        const {
            data: {truck},
            onClose,
        } = this.props;

        return (
            <Modal
                show={true}
                onHide={onClose}
                dialogClassName="custom-modal standard-form-modal"
                backdrop="static"
                size="sm"
            >
                <Modal.Header>
                    <div className="modal-header__top">
                        <Modal.Title>{`Dispatcher's Note of the Truck#${truck.number}`}</Modal.Title>

                        <button onClick={onClose} type="button" className="close">
                            <i className="fa fa-times" aria-hidden="true" />
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Form
                            form="choose-owner-form"
                            onSubmit={this.formSubmitHandler}
                            onClose={onClose}
                            initialValues={this.initValues}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default DispatcherNoteModal;
