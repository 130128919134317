import * as appActions from 'store/actions';

import * as requests from 'core/gateways/TrucksNearbyApiGateway/requests';
import {DispatcherNoteApi} from 'core/entities/Truck/types/Note';
import {getResponsiblePerson} from 'core/entities/Truck';
import Truck from 'core/entities/Truck/types';

import {modalNames} from 'pages/TrucksNearby/components/modals/modalMap';
import * as actionTypes from 'pages/TrucksNearby/redux/actionTypes';
import * as selectors from 'pages/TrucksNearby/redux/selectors';

import * as modalActions from 'components/ui/ModalProvider/actions';

import getUniqSeparatedValues from 'utils/getUniqSeparatedValues';

export const openSendQuoteModal = () => (dispatch, getState) => {
    const state = getState();

    const trucks = selectors.getTrucks(state);

    const phoneListData = trucks.map((truck) => {
        const responsiblePerson = getResponsiblePerson(truck);

        const isResponsiblePersonWithCoordinator = responsiblePerson.owner_with_coordinator;
        const responsiblePersonFullName =
            (isResponsiblePersonWithCoordinator && `${responsiblePerson.coordinator_full_name}`) ||
            `${responsiblePerson.full_name}`;

        return {
            label: `${responsiblePersonFullName} | #${truck.number}`,
            value: `${
                isResponsiblePersonWithCoordinator
                    ? responsiblePerson.coordinator_mobile_phone
                    : responsiblePerson.mobile_phone
            }#${truck.number}`,
        };
    });

    return dispatch(modalActions.openModal({modalName: modalNames.sendQuote, data: {phoneListData}}));
};

export const sendQuoteToUsers = (params: {formData}) => async (dispatch) => {
    const {formData} = params;
    const {phoneListWithTruckNumbers, message} = formData;

    try {
        dispatch(appActions.showLoader());

        // We store phone number with truck number, owner may have several truck and with uniq phone select doesn't
        // correctly render labels.
        const phoneList = phoneListWithTruckNumbers.replace(new RegExp(`(#\\w+)`, 'g'), '');

        const uniqPhoneList = getUniqSeparatedValues(phoneList);

        const phoneListWithoutSpaces = uniqPhoneList.replace(new RegExp(` `, 'g'), '');

        const requestPayload = {phone_list: phoneListWithoutSpaces, text: message};

        const postQuoteResponse = await requests.postQuoteSms({requestPayload});

        dispatch(appActions.hideLoader());

        dispatch(modalActions.closeAll());

        dispatch(appActions.showAlert(postQuoteResponse.data, 'success'));
    } catch (error: any) {
        dispatch(appActions.hideLoader());

        dispatch(appActions.showAlert(error.message, 'danger'));
    }
};

export const receiveDispatcherNote = (params: {truck: Truck; note?: DispatcherNoteApi}) => (dispatch, getState) => {
    dispatch({
        name: selectors.getTrucksOwningType(getState()),
        type: actionTypes.DISPATCHER_NOTE_UPDATE,
        payload: params,
    });
};

export const setSSEData = (payload) => ({type: actionTypes.SSE_DATA_RECEIVED, payload} as const);
