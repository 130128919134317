import React from 'react';
import {Field, reduxForm} from 'redux-form';

import {normalizeNumberLetterHyphenInput} from 'utils';

import ChatSearchInput from './components/SearchInput';
import {DRIVER_SEARCH_FORM} from '../../constants';

const DriverSearch = (props) => {
    const {handleSubmit} = props;

    return (
        <form onSubmit={handleSubmit}>
            <Field
                placeholder="truck#"
                name="truckNumber"
                maxLength={8}
                normalize={normalizeNumberLetterHyphenInput}
                component={ChatSearchInput}
                clearable={true}
                onSearch={handleSubmit}
            />
        </form>
    );
};

export default reduxForm({
    form: DRIVER_SEARCH_FORM,
})(DriverSearch);
