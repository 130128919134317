import React, {useEffect} from 'react';
import {ListGroup, ListGroupItem} from 'react-bootstrap';
import {useSelector} from 'react-redux';

import Button from 'components/ui/Buttons/Button';
import {getDuration} from 'widgets/Notifications/utils';

import * as selectors from '../../redux/selectors';
import QuoteLink from '../common/QuoteLink';
import NotificationMessage from '../common/NotificationMessage';
import useLoadBoardNotificationActions from '../../hooks/useLoadBoardNotificationActions';

import styles from './newNotification.module.scss';
import closeIcon from '../../images/close.svg';

const NewNotification: React.FC = () => {
    const {removeNewNotification} = useLoadBoardNotificationActions();
    const notification = useSelector(selectors.getNewNotification);

    useEffect(() => {
        const timerForCloseNotification = 3000;

        if (notification) {
            setTimeout(() => {
                removeNewNotification(notification);
            }, timerForCloseNotification);
        }
    }, [notification]);
    if (!notification) {
        return null;
    }
    const date = getDuration(notification.createdAt);

    return (
        <>
            <ListGroup className={styles.list_group}>
                <ListGroupItem className={styles.list_group_item}>
                    <div className={styles.wrapper}>
                        <h5 className={styles.title}>NEW offer!</h5>
                        <p className={styles.date}>{date}</p>
                        <button
                            type="button"
                            className={`${styles.close_button} close`}
                            onClick={() => removeNewNotification(notification)}
                        >
                            <img src={closeIcon} alt="close" />
                        </button>
                    </div>
                    <div className={styles.message}>
                        <NotificationMessage notification={notification} />
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                        <Button>
                            <QuoteLink quoteNumber={notification.quoteNumber} />
                        </Button>
                    </div>
                </ListGroupItem>
            </ListGroup>
        </>
    );
};

export default NewNotification;
