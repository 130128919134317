import fromPairs from 'lodash/fromPairs';
import intersection from 'lodash/intersection';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import toLower from 'lodash/toLower';

import {store} from 'store';

import * as truckStatuses from 'core/entities/Truck/constants/truckStatuses';

import {FullAllianceSearchFormValues} from 'pages/FullAlliance/types/formTypes';

import countries from 'utils/data/countries';
import {getTemperatureUnitByLabel} from 'utils/data/temperatureUnits';
import {transformDistance} from 'utils/distance';
import {convertToInches} from 'utils/lengthConverter';
import {convertToLbs} from 'utils/weightConverter';

const removeUnknownZipFromCityLine = (cityLine: string) => {
    return cityLine.replace(/unknown zip code,/i, '').replace(/\s+/g, ' ');
};

const checkIfSearchTrucksByHomeLocation = (params: {formData: Partial<FullAllianceSearchFormValues>}) => {
    const {formData} = params;

    return formData?.status?.includes(truckStatuses.TRUCK_STATUS_NOT_AVAILABLE);
};

const transformCertificatesDataToRequestBody = (params: {formData: Partial<FullAllianceSearchFormValues>}) => {
    const {formData} = params;

    return fromPairs(formData?.certificates?.split(',').map((certificate) => [certificate, true]));
};

const transformLocationDataToRequestBody = (params: {formData: Partial<FullAllianceSearchFormValues>}) => {
    const {formData} = params;

    const distance = transformDistance(formData?.radius);

    const pickupAtPlace = formData?.originPoint
        ? {radius: distance.toMiles, title: removeUnknownZipFromCityLine(formData?.originPoint)}
        : null;
    const deliveryAtPlace = formData?.destinationPoint
        ? {radius: 500, title: removeUnknownZipFromCityLine(formData?.destinationPoint)}
        : null;

    return {pickUpAtPlace: pickupAtPlace, deliveryAtPlace};
};

const transformDimensionsDataToRequestBody = (params: {formData: Partial<FullAllianceSearchFormValues>}) => {
    const {formData} = params;

    const dims = {
        valid_dims_length: formData?.dimLength,
        valid_dims_height: formData?.dimHeight,
        valid_dims_width: formData?.dimWidth,
    };

    return mapValues(dims, (value) => convertToInches(formData?.dimsUnit, Number(value)));
};

const transformPayloadDataToRequestBody = (params: {formData: Partial<FullAllianceSearchFormValues>}) => {
    const {formData} = params;

    return convertToLbs(formData?.weightsUnit, Number(formData?.payload));
};

const transformGrossWeightDataToRequestBody = (params: {formData: Partial<FullAllianceSearchFormValues>}) => {
    const {formData} = params;

    return convertToLbs(formData?.weightsUnit, Number(formData?.gross));
};

const transformEquipmentDataToRequestBody = (params: {formData: Partial<FullAllianceSearchFormValues>}) => {
    const {formData} = params;
    const {
        registry: {truckBasicEquipment, truckAdditionalEquipment},
    } = store.getState();

    const inputEquipment = formData?.equipment?.split(',');

    const basicEquipmentValues = truckBasicEquipment.map((elem) => elem.value);
    const additionalEquipmentValues = truckAdditionalEquipment.map((elem) => elem.value);

    const equipmentToRequest = intersection(inputEquipment, basicEquipmentValues).join(',');
    const additionalEquipmentToRequest = intersection(inputEquipment, additionalEquipmentValues).join(',');

    return {equipment: equipmentToRequest || null, additional_equipment: additionalEquipmentToRequest || null};
};

const transformCrossBorderDataToRequestBody = (params: {formData: Partial<FullAllianceSearchFormValues>}) => {
    const {formData} = params;

    const countryNames = {};

    formData?.crossBorder?.split(',').forEach((countryCode) => {
        if (countries[countryCode]) {
            countryNames[toLower(countries[countryCode].name).replace(/\s+/g, '_')] = true;
        }
    });

    return countryNames;
};

const transformTemperatureParamsToRequestBody = (formData: Partial<FullAllianceSearchFormValues>) => {
    return {
        temperatureDegreesUnit:
            formData?.temperatureRangeMin || formData?.temperatureRangeMax
                ? getTemperatureUnitByLabel(formData?.temperatureDegreesUnit)
                : null,
        temperatureRangeMin: Number(formData?.temperatureRangeMin) || null,
        temperatureRangeMax: Number(formData?.temperatureRangeMax) || null,
    };
};

export const transformSearchDataToRequestBody = (params: {formData: Partial<FullAllianceSearchFormValues>}) => {
    const {formData} = params;

    const isSearchTrucksByHomeLocation = checkIfSearchTrucksByHomeLocation({formData});

    const temperatureRangeParams = transformTemperatureParamsToRequestBody(formData);
    const certificates = transformCertificatesDataToRequestBody({formData});
    const grossWeight = transformGrossWeightDataToRequestBody({formData});
    const crossBorder = transformCrossBorderDataToRequestBody({formData});
    const dimensions = transformDimensionsDataToRequestBody({formData});
    const equipment = transformEquipmentDataToRequestBody({formData});
    const location = transformLocationDataToRequestBody({formData});
    const payload = transformPayloadDataToRequestBody({formData});

    const searchParams = {
        reserved_by: formData?.reservedBy ? Number(formData?.reservedBy.value) : null,
        carrierId: formData?.company ? Number(formData?.company.value) : null,
        year: formData?.yearFrom ? Number(formData?.yearFrom.value) : null,
        driver: formData?.driver ? Number(formData?.driver.value) : null,
        owner: formData?.owner ? Number(formData?.owner.value) : null,
        is_search_by_home_location: isSearchTrucksByHomeLocation,
        status_date_from: formData?.statusDateFrom || null,
        preferred_loads: formData?.preferredLoads || null,
        is_reefer: Boolean(formData?.isReefer) || null,
        status_date_to: formData?.statusDateTo || null,
        trailer_type: formData?.trailerType || null,
        citizenship: formData?.citizenship || null,
        company_signs: formData?.withCompanySigns,
        team: Boolean(formData?.isTeam) || null,
        door_type: formData?.doorType || null,
        is_reserved: formData?.isReserved,
        status: formData?.status || null,
        type: formData?.type || null,
        gross_weight: grossWeight,
        payload,

        ...temperatureRangeParams,
        ...certificates,
        ...crossBorder,
        ...dimensions,
        ...equipment,
        ...location,
    };

    return omitBy(searchParams, (value) => isNull(value) || isUndefined(value));
};
