import isPlainObject from 'lodash/isPlainObject';
import {trucksEvents} from '../../events';
import * as truckActions from './actions';

export const trucksChannelMessageHandler = (store) => (uri, payload) => {
    const messageData = payload.msg;
    if (!messageData.event || !messageData.data) {
        return;
    }
    const {event, data} = messageData;

    switch (event) {
        case trucksEvents.TRUCK_UPDATED:
            store.dispatch(truckActions.trucksUpdateReceived(data));
            break;
        case trucksEvents.STATUS_UPDATED:
            store.dispatch(truckActions.trucksUpdateReceived(data));
            break;
        case trucksEvents.COORDINATES_UPDATED:
            store.dispatch(truckActions.trucksCoordinatesReceived(data));
            break;
        case trucksEvents.RESERVATION_UPDATED: {
            if (isPlainObject(data)) {
                store.dispatch(truckActions.trucksReserveChanged(data));
            } else {
                store.dispatch(truckActions.trucksReserveCanceled(data));
            }
            break;
        }
        default:
            break;
    }
};
