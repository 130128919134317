import React, {useRef, useState} from 'react';
import {SketchPicker} from 'react-color';
import {useClickAway} from 'react-use';

import type {Color} from '../../types';

import styles from './styles.module.scss';

interface OwnProps {
    label: string;
    color: string;
    onSelectColor(color: Color);
}

const ColorItem = (props: OwnProps): JSX.Element => {
    const {label, color, onSelectColor} = props;
    const ref = useRef(null);
    const [showColorPicker, setShowColorPicker] = useState(false);

    useClickAway(ref, () => {
        setShowColorPicker(false);
    });

    return (
        <div ref={ref} className={styles.item} onClick={() => setShowColorPicker(!showColorPicker)}>
            <span style={{backgroundColor: color}} />
            <span className={styles.label}>{label}</span>
            {showColorPicker && (
                <div className={styles.color_picker}>
                    <SketchPicker color={color} onChange={onSelectColor} />
                </div>
            )}
        </div>
    );
};

export default ColorItem;
