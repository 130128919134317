import React from 'react';

import Customer from 'core/entities/Customer/types';

import ArchivingWarnWrapper from 'components/ui/modals/ArchivingWarnWrapper';

type OwnProps = {
    data: {
        customer: Customer;
    };
    handlers: {
        onConfirm: () => void;
        onCancel: () => void;
    };

    onClose: () => void;
};

const ArchivingWarn = (props: OwnProps): JSX.Element => {
    const {
        data: {customer},
        handlers: {onCancel, onConfirm},
        onClose,
    } = props;

    return (
        <ArchivingWarnWrapper title={customer.name} onClose={onClose} onCancel={onCancel} onConfirm={onConfirm}>
            <p>
                <strong>DO YOU WANT TO ARCHIVE THE CUSTOMER?</strong>
            </p>
            <p>
                {' '}
                The archived customer`s profile <strong>{customer.name}</strong> will disable any actions with
                it.&nbsp;If needed, you will be able to restore the profile.
            </p>
        </ArchivingWarnWrapper>
    );
};

export default ArchivingWarn;
