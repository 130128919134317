import {TIME_ZONES} from 'utils/data/timeZones';
import {getCurrentDate, setSpecifiedUnits} from 'utils/dateTime';

export const getNowWithNewYorkTimeZone = () =>
    getCurrentDate({timeZone: TIME_ZONES['America/New_York'].name}).originalDateTime;

export const getDateStart = (dateTime: string): string =>
    setSpecifiedUnits(dateTime, {hour: 0, minute: 0, second: 0}).dateTimeWithSecInServerFormat;

export const getDateEnd = (dateTime: string): string =>
    setSpecifiedUnits(dateTime, {hour: 23, minute: 59, second: 59}).dateTimeWithSecInServerFormat;
