import React from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';
import {Col, Row} from 'react-bootstrap';

import {SENDER_TYPE_UPDATE} from 'core/entities/Sender/constants';

import TextInput from 'components/ui/Form/TextInput';
import TextArea from 'components/ui/Form/TextArea';
import Button from 'components/ui/Buttons/Button';

import SendersSelectByType from 'components/common/Sender/SendersSelectByType';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import {SendEmailData, SendEmailFormValues} from '../../types';

const FORM_NAME = 'send-email-modal';

const getName = getTypeFieldNameFactory<SendEmailFormValues>();

const Form: React.FC<SendEmailData & InjectedFormProps<{}, SendEmailData>> = (props) => {
    const {handleSubmit, reset, nameTo, emailTo} = props;

    const typedSubject = useGetReduxFormValue(FORM_NAME, getName('subject'));
    const typedText = useGetReduxFormValue(FORM_NAME, getName('text'));

    return (
        <form onSubmit={handleSubmit}>
            <div className="modal-body__email-from">
                <Row>
                    <Col md={6}>
                        <Field
                            name={getName('sender')}
                            component={SendersSelectByType}
                            placeholder="type one or more emails"
                            label="from"
                            senderType={SENDER_TYPE_UPDATE}
                            isClearable={false}
                        />
                    </Col>
                </Row>
            </div>
            <div className="modal-body__email-to">
                EMAIL TO: {nameTo} ({emailTo})
            </div>
            <div className="modal-body__fields">
                <Field
                    name={getName('subject')}
                    component={TextInput}
                    placeholder="type email subject"
                    label="subject"
                />
                <Field
                    name={getName('text')}
                    rows="4"
                    component={TextArea}
                    placeholder="type your message"
                    label="message"
                />
            </div>
            <div className="modal-body__bottom">
                <Button onClick={reset} colorTheme="grey" className="pull-left">
                    CLEAR ALL FIELDS
                </Button>

                <Button disabled={!typedSubject || !typedText} type="submit" colorTheme="blue">
                    Send
                </Button>
            </div>
        </form>
    );
};

export default reduxForm<{}, SendEmailData>({form: FORM_NAME, enableReinitialize: true})(Form);
