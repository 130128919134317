import {InferActions} from 'store';

import {ComputedPermissions} from 'core/entities/Permissions';

import * as types from './actionTypes';

export const setPermissions = (payload: ComputedPermissions) => ({type: types.SET_PERMISSIONS, payload} as const);

const actionCreators = {
    setPermissions,
};

export type PermissionsActionsTypes = InferActions<typeof actionCreators>;

export default actionCreators;
