import React from 'react';
import classNames from 'classnames';

import {isMemberOwner} from 'core/entities/Truck/modules/truckTeamInfo';
import {getOwnerName} from 'core/entities/Owner/modules/ownerName';
import {TruckMember} from 'core/entities/Truck/types/Team';
import Truck from 'core/entities/Truck/types';

import TruckOwnerBadge from 'components/common/Truck/TruckOwnerBadge';
import LanguageBadge from 'components/ui/Badges/LanguageBadge';
import {OwnerLink} from 'components/ui/Links';

import {phoneNumberWithBraces} from 'utils';

type OwnProps = {
    truckMember: TruckMember;
    truck: Truck;
};

const TruckOwnerBlock: React.FC<OwnProps> = (props) => {
    const {truck, truckMember} = props;

    const {isResponsible, entity: owner} = truckMember;

    const isOwner = isMemberOwner(owner);

    if (!isOwner) {
        return null;
    }

    const isMainClassName = classNames({'is-main': isResponsible});

    return (
        <>
            {isResponsible ? <div className="badge">m</div> : null}

            <div>
                <TruckOwnerBadge truck={truck} />
                &nbsp;
                <OwnerLink owner={owner} content={getOwnerName(owner)} className="second-link" />
            </div>

            <div>
                <span className={isMainClassName}>{phoneNumberWithBraces(owner.mobile_phone)}</span>
                &nbsp;
                <LanguageBadge language={owner.language} tooltipPosition="right" />
            </div>
        </>
    );
};

export default TruckOwnerBlock;
