import last from 'lodash/last';
import isPlainObject from 'lodash/isPlainObject';
import omit from 'lodash/omit';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import {v4 as uuidv4} from 'uuid';

import {ACCEPTED_IMAGE_TYPES} from 'components/ui/Files/FileUpload/constants';

import {File as StoredFile, FileNew as StoredFileNew} from 'types/File';

import {isStoredFile, isNewStoredFile} from 'utils/files';
import getThumbPath from 'utils/files/getThumbPath';
import getFilePath from 'utils/files/getFilePath';

import {NormalizedFile, NormalizedFileWithoutExtraData, UploadedFile} from '../types';

const PDF_FILE = 'pdf';

export const isFileObject = (value: any): value is File => value instanceof File;

export const isUploadedFile = (value: any): value is UploadedFile => 'file' in value && isFileObject(value.file);

export const ifFileHasPdfExtension = (file: NormalizedFile | NormalizedFileWithoutExtraData): boolean =>
    file.extension === PDF_FILE;

export const isImage = (file: NormalizedFile | NormalizedFileWithoutExtraData): boolean =>
    ACCEPTED_IMAGE_TYPES.includes(file.extension.toLowerCase());

export const isPDF = (file: NormalizedFile | NormalizedFileWithoutExtraData): boolean =>
    isImage(file) && file.extension.toLowerCase() === 'application/pdf';

export const parseFileExtraData = (storedFile: StoredFile): any => {
    const {extra_data}: {extra_data?: string | object | null} = storedFile;
    if (!extra_data) {
        return {};
    }
    if (isPlainObject(extra_data)) {
        return extra_data;
    }
    try {
        return extra_data ? JSON.parse(extra_data as string) : {};
    } catch (error) {
        console.error(error);
    }
    return {};
};

const getStoredFileExtension = (file: StoredFile | StoredFileNew): string => {
    let extensionFromName;
    if (isNewStoredFile(file)) {
        extensionFromName = last(file?.displayName.split('.')) || '';
    }
    if (isStoredFile(file)) {
        extensionFromName = last(file?.real_name.split('.')) || '';
    }
    return extensionFromName || file.type;
};

export const getUploadedFileExtension = (file: File): any => {
    if (!isString(file.name)) {
        return '';
    }
    const splitFileName = file.name.split('.');
    return last(splitFileName);
};

export const getUploadedFileUrl = (file: File): string => URL.createObjectURL(file);

export const composeBadgeNewFile = (storedFileNew: StoredFileNew) => {
    const {badge, documentNumber, documentPage} = storedFileNew;

    if (isEmpty(badge)) {
        return '';
    }

    const documentNumbers = documentNumber ? ` #${documentNumber}` : '';
    const documentPages = documentPage ? `.${documentPage}` : '';

    return `${badge}${documentNumbers}${documentPages}`;
};

type Options = {
    isNeedComposeBadge?: boolean;

    remove?: () => void;
};

export const normalizeUploadedFile = (
    uploadedFile: UploadedFile,
    options: Options = {},
): NormalizedFile & {documentNumber: number; documentPage: number; tmpID: string} => {
    const {remove} = options;

    const extraData: any = omit(uploadedFile, 'file');
    const {badge, documentPage, documentNumber} = extraData;

    return {
        extension: getUploadedFileExtension(uploadedFile.file),
        url: getUploadedFileUrl(uploadedFile.file),
        name: uploadedFile.file.name,
        type: uploadedFile.file.type,
        size: uploadedFile.file.size,
        handleRemove: remove,
        tmpID: uuidv4(),
        documentNumber,
        documentPage,
        isBlob: true,
        extraData,
        badge,
    };
};

export const normalizeStoredFile = (storedFile: StoredFile, options: Options = {}): NormalizedFile => {
    const {remove} = options;

    const fileExtension = getStoredFileExtension(storedFile);
    const pathToFileThumb = getThumbPath(storedFile);
    const pathToFile = getFilePath(storedFile);

    const extraData = parseFileExtraData(storedFile);
    const {badge} = extraData;

    return {
        size: storedFile.file_size,
        name: storedFile.real_name,
        thumbUrl: pathToFileThumb,
        extension: fileExtension,
        type: storedFile.type,
        handleRemove: remove,
        id: storedFile.id,
        url: pathToFile,
        isBlob: false,
        extraData,
        badge,
    };
};

export const normalizeNewStoredFile = (
    storedFileNew: StoredFileNew,
    options: Options = {},
): NormalizedFileWithoutExtraData => {
    const {remove, isNeedComposeBadge = true} = options;

    const badge = isNeedComposeBadge ? composeBadgeNewFile(storedFileNew) : storedFileNew.badge;

    const fileExtension = getStoredFileExtension(storedFileNew);
    const pathToFileThumb = getThumbPath(storedFileNew);
    const pathToFile = getFilePath(storedFileNew);

    return {
        documentNumber: storedFileNew.documentNumber,
        documentPage: storedFileNew.documentPage,
        name: storedFileNew.displayName,
        size: storedFileNew.fileSize,
        thumbUrl: pathToFileThumb,
        extension: fileExtension,
        handleRemove: remove,
        id: storedFileNew.id,
        url: pathToFile,
        isBlob: false,
        badge,
    };
};

export const normalizeFile = (
    file: StoredFileNew | StoredFile | UploadedFile,
    options?: Options,
): NormalizedFile | NormalizedFileWithoutExtraData => {
    if (isUploadedFile(file)) {
        return normalizeUploadedFile(file, options);
    }

    if (isNewStoredFile(file)) {
        return normalizeNewStoredFile(file, options);
    }

    return normalizeStoredFile(file, options);
};
