export * from './merge';
export * from './simplify';

export const getTypeFieldNameFactory = <T>() => (name: keyof T): string => String(name);
export const getTypeSubFieldNameFactory = <P, C extends keyof P>() => (subName: keyof P[C]) => String(subName);
export const getTypeFieldChildNameFactory = <P, C extends keyof P>() => (parents: keyof P, child: keyof P[C]) =>
    `${String(parents)}.${String(child)}`;
export const getDeepFormFieldName = <P extends string, C, SubC extends keyof C>() => (
    parent: string,
    child: keyof C,
    subChild: keyof C[SubC],
) => `${String(parent)}.${String(child)}.${String(subChild)}`;
export const getEmbedFormFieldName = <P extends string, C>() => (parent: string, child: keyof C) =>
    `${String(parent)}.${String(child)}`;
export const mockTypeObject = <T>(instance: Partial<T>): T => instance as any;
