import React, {useState} from 'react';
import Button from 'components/ui/Buttons/Button';
import SuccessModal from './components/SuccessModal';

const ClearButton: React.FC<{reset: () => void; dirty: boolean}> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const {reset, dirty} = props;
    const handleClose = (): void => {
        setOpen(false);
    };
    const handleClear = (): void => {
        reset();
        handleClose();
    };
    const openModal = (): void => {
        setOpen(true);
    };
    return (
        <>
            <Button
                disabled={!dirty}
                onClick={openModal}
                className="mr10"
                colorTheme="grey"
                buttonSize="small"
                defaultFocus={true}
            >
                CLEAR ALL FIELDS
            </Button>
            {open && <SuccessModal onClear={handleClear} onClose={handleClose} />}
        </>
    );
};

export default ClearButton;
