import {AppState} from 'store';

import {getPagePermissions} from 'store/reducers/userPermissions/selectors';

import {FactoringCompany} from 'core/entities/FactoringCompany/types';

import {CardState} from 'pages/FactoringCompanies/redux/reducers/card';
import {ListState} from 'pages/FactoringCompanies/redux/reducers/list';

import permissionsFields from 'utils/data/permissions';
import * as pages from 'utils/data/pages';

export const getCurrenFactoringCompany = (state: AppState): CardState['current'] =>
    state.factoringCompanies.card.current;
export const getFactoringCompanyNotes = (state: AppState): FactoringCompany['notes'] | undefined =>
    state.factoringCompanies.card.current?.notes || undefined;

export const getFactoringCompaniesSearchParams = (state: AppState): ListState['searchParams'] =>
    state.factoringCompanies.list.searchParams;
export const getFactoringCompaniesDeletedFlag = (state: AppState): ListState['isDeleted'] =>
    state.factoringCompanies.list.isDeleted;
export const getFactoringCompaniesPagination = (state: AppState): ListState['pagination'] =>
    state.factoringCompanies.list.pagination;
export const getFactoringCompanies = (state: AppState): ListState['items'] => state.factoringCompanies.list.items;

// PERMISSIONS
const getFactoringPagePermissions = (state: AppState) => getPagePermissions(state, pages.FACTORING_COMPANIES);

export const getPermissionCreate = (state: AppState): boolean => {
    const pagePermission = getFactoringPagePermissions(state);
    return pagePermission[permissionsFields.create] !== false;
};
export const getPermissionView = (state: AppState): boolean => {
    const pagePermission = getFactoringPagePermissions(state);
    return pagePermission[permissionsFields.view] !== false;
};
export const getPermissionUpdate = (state: AppState): boolean => {
    const pagePermission = getFactoringPagePermissions(state);
    return pagePermission[permissionsFields.update] !== false;
};
export const getPermissionDelete = (state: AppState): boolean => {
    const pagePermission = getFactoringPagePermissions(state);
    return pagePermission[permissionsFields.delete] !== false;
};
export const getPermissionList = (state: AppState): boolean => {
    const pagePermission = getFactoringPagePermissions(state);
    return pagePermission[permissionsFields.list] !== false;
};
