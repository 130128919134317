import {WEB_SOCKET_DRIVERS_TRUCKS_COORDINATES_RECEIVED, WEB_SOCKET_DRIVERS_TRUCKS_UPDATE_RECEIVED} from './actionTypes';

export function driversTrucksUpdateReceived(message) {
    return {
        type: WEB_SOCKET_DRIVERS_TRUCKS_UPDATE_RECEIVED,
        payload: message,
    };
}

export function driversTrucksCoordinatesReceived(message) {
    return {
        type: WEB_SOCKET_DRIVERS_TRUCKS_COORDINATES_RECEIVED,
        payload: message,
    };
}
