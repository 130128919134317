import React from 'react';

import currencyData, {CurrencyValue} from 'utils/data/currency';

import styles from './styles.module.scss';

type OwnProps = {
    rate: number | undefined;
    currency: CurrencyValue | undefined;
};

const LoadRate: React.FC<OwnProps> = (props) => {
    const {rate, currency} = props;
    if (!rate || !currency) {
        return null;
    }
    const currencySettings = currencyData[currency];
    if (!currencySettings) {
        return null;
    }
    return (
        <div>
            <strong className={styles.currency}>{currencySettings.mark}</strong> <strong>{rate}</strong>
        </div>
    );
};

export default LoadRate;
