import {useEffect} from 'react';
import isNumber from 'lodash/isNumber';

import {Quote} from 'core/entities/Quote/types';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import {convertMmToMiles} from 'pages/LoadBoard/utils/distance';

import getFormFieldNames from '../utils/getFormFieldNames';
import useFormActions from './useFormAction';

const calculateRPM = (rate, distance): number | undefined => {
    if (!rate || !distance) {
        return;
    }
    const numbersAfterPoint = 2;
    return Number((rate / distance).toFixed(numbersAfterPoint));
};

export default function (fromName: string, quote: Quote): void {
    const {getPaymentRateOfferFieldName} = getFormFieldNames();
    const {setPaymentRpm} = useFormActions();
    const rate = useGetReduxFormValue(fromName, getPaymentRateOfferFieldName());
    const loadDistance = quote?.route?.distance || 0;

    useEffect(() => {
        const distance = convertMmToMiles(loadDistance);
        const rpm = calculateRPM(rate, distance);

        if (isNumber(rpm)) {
            setPaymentRpm(rpm);
            return;
        }

        setPaymentRpm(undefined);
    }, [rate, loadDistance]);
}
