import {ThunkAction} from 'redux-thunk';
import {push} from 'connected-react-router';

import * as appActions from 'store/actions';
import {AppState} from 'store';

import {getTravelOrderNumber} from 'core/entities/TravelOrder/modules/common/getTravelOrderNumber';
import * as toRequests from 'core/gateways/TravelOrderApiGateway/requests/indexNew';

import * as cancelTravelOrderMappers from 'pages/TravelOrders/redux/mappers/cancelTravelOrder';
import {getCurrentTravelOrder} from 'pages/TravelOrders/redux/selectors';
import * as formTypes from 'pages/TravelOrders/types/formTypes';

type ThunkActionTypes = ThunkAction<void, AppState, unknown, any>;

const handleCancelTravelOrder = (
    requestBody: Partial<{
        cityLine: string | null;
        dateTime: string | null;
        latitude: number | null;
        longitude: number | null;
        timeZone: string | null;
    }>,
): ThunkActionTypes => async (dispatch, getState) => {
    const travelOrder = getCurrentTravelOrder(getState());
    const toNumber = getTravelOrderNumber(travelOrder);

    try {
        dispatch(appActions.showLoader());

        await toRequests.cancelTravelOrderRequest({toNumber, requestBody});

        dispatch(push('/trip-monitor'));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const cancelPlannedTravelOrder = (): ThunkActionTypes => (dispatch) => {
    dispatch(handleCancelTravelOrder({}));
};

export const cancelNotPlannedTOWithPlannedTO = (
    cancelData: formTypes.CancelNotPlannedTOWithPlannedTOFormValues,
): ThunkActionTypes => (dispatch) => {
    const requestBody = cancelTravelOrderMappers.transformCancelPlannedTO(cancelData);

    dispatch(handleCancelTravelOrder(requestBody));
};

export const cancelNotPlannedTOWithoutPlannedTO = (
    cancelData: formTypes.CancelNotPlannedTOWithoutPlannedTOFormValues,
): ThunkActionTypes => (dispatch) => {
    const requestBody = cancelTravelOrderMappers.transformCancelNotPlannedTO(cancelData);

    dispatch(handleCancelTravelOrder(requestBody));
};
