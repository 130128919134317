import React from 'react';
import {Field, FieldArray, reduxForm, InjectedFormProps, formValueSelector} from 'redux-form';
import {Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';

import {AppState} from 'store';

import TextInput from 'components/ui/Form/TextInput';
import TextArea from 'components/ui/Form/TextArea';
import Button from 'components/ui/Buttons/Button';

import FileList from '../FileList';
import validate from './validation';

import {FilesType} from '../../types';
import {attachmentSizeError, attachmentLength} from '../../utils';

import styles from './styles.module.scss';

export interface OwnProps extends FilesType {
    onClose: () => void;
}

export const SupportForm: React.FC<OwnProps & InjectedFormProps<{}, OwnProps>> = (props) => {
    const {handleSubmit, onClose, files} = props;

    return (
        <form onSubmit={handleSubmit} className="support-modal__form">
            <div className="support-modal__form-body">
                <div className="support-modal__email">
                    <Row>
                        <Col md={7}>
                            <Field name="email" component={TextInput} placeholder="type email" label="your email" />
                        </Col>
                    </Row>
                </div>
                <div className="support-modal__subject">
                    <Field name="subject" component={TextInput} placeholder="type subject" label="subject:" />
                </div>
                <div className="support-modal__message">
                    <Field
                        name="message"
                        rows="4"
                        component={TextArea}
                        placeholder="enter your message"
                        label="message:"
                    />
                </div>

                <FieldArray
                    name="files"
                    component={FileList}
                    titleButton="Upload File"
                    disabled={attachmentLength(files) || attachmentSizeError(files)}
                />

                <div className={styles.error_notice}>
                    {attachmentSizeError(files) && (
                        <div className={styles.error_notice__text}>
                            can not send, the file size is too large, the max is 20MB
                        </div>
                    )}
                </div>
            </div>

            <div className="support-modal__footer">
                <Button onClick={onClose} colorTheme="grey" className="pull-left">
                    Cancel
                </Button>

                <Button disabled={attachmentSizeError(files)} type="submit" colorTheme="blue">
                    Send
                </Button>
            </div>
        </form>
    );
};

const selector = formValueSelector('support-modal');

export default connect((state: AppState) => ({
    initialValues: {
        email: state.userData.email,
    },
    files: selector(state, 'files'),
}))(
    reduxForm<{}, OwnProps>({form: 'support-modal', validate})(SupportForm),
);
