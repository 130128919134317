import {getTruckTeam} from 'core/entities/Truck/modules/truckTeam';
import {DriverOffer} from 'core/entities/Quote/types';
import Driver from 'core/entities/Driver/types';
import {Dispatcher} from 'core/entities/Dispatcher/types';
import {fetchDriver} from 'core/gateways/DriverApiGateway/requests';

import {CurrencyValue} from 'utils/data/currency';

import {DriverOfferFormValues} from 'pages/LoadBoard/types';

type OfferPostData = Omit<DriverOfferFormValues, 'truck'> & {
    driverID: number | undefined;
    truckID: number | undefined;
    appID: string;
    dispatcherID: number;
    payment?: {
        rate: number;
        rpm: number;
        currency: CurrencyValue;
    };
};

const getDriverPayment = (formQuote: DriverOfferFormValues): DriverOffer['payment'] => {
    const {payment} = formQuote;
    return {
        rate: Number(payment.rate),
        rpm: Number(payment.rpm),
        currency: payment.currency,
    };
};

const fetchDriverAppID = async (driver: Driver | undefined): Promise<string> => {
    if (!driver) {
        return '';
    }
    const driverWithFullData = await fetchDriver(driver.id)
        .then((response) => response.data as Driver)
        .catch((error) => {
            console.error('Error on fetch driver app id: ', error);
        });
    if (!driverWithFullData) {
        return '';
    }
    return driverWithFullData.device_id || '';
};

export default async function (
    formQuote: DriverOfferFormValues,
    currentDispatcher: Dispatcher,
): Promise<OfferPostData> {
    const {quoteID, truck, emptyDistance, note} = formQuote;
    const {firstDriver} = getTruckTeam(truck);
    const driverAppID = await fetchDriverAppID(firstDriver);
    const payment = getDriverPayment(formQuote);
    return {
        quoteID,
        driverID: firstDriver?.id,
        truckID: truck?.id,
        dispatcherID: currentDispatcher.id,
        emptyDistance,
        payment,
        appID: driverAppID,
        note,
    };
}
