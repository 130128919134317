import groupBy from 'lodash/groupBy';
import {isChangeLocationAction} from 'routing/utils/index';

import * as types from '../actionTypes';
import locationChangeCommonReducer from './locationChangeCommonReducer';

const defaultState = {};

export default function settlementReducer(state = defaultState, action) {
    if (isChangeLocationAction(action)) {
        return locationChangeCommonReducer(state, defaultState, action);
    }

    switch (action.type) {
        case types.SETTLEMENT_RECEIVED: {
            const {invoice, payPeriods, travelOrderFiles, travelOrderNotes, loadFiles, loadNotes} = action.payload;
            return {
                ...state,
                invoice,
                allPayPeriods: payPeriods,
                travelOrderFiles: groupBy(travelOrderFiles, 'attach_type'),
                travelOrderNotes,
                loadFiles: groupBy(loadFiles, 'attach_type'),
                loadNotes,
            };
        }

        case types.SETTLEMENT_TRAVEL_ORDER_UPDATED_FILES: {
            const {files, type} = action.payload;
            return {
                ...state,
                travelOrderFiles: {
                    ...state.travelOrderFiles,
                    [type]: files,
                },
            };
        }

        default:
            return state;
    }
}
