import React, {useMemo, useCallback, ReactNodeArray} from 'react';
import {Dispatch} from 'redux';
import {connect, useDispatch} from 'react-redux';
import {change, formValueSelector, WrappedFieldProps, WrappedFieldMetaProps} from 'redux-form';
import {FormGroup, FormLabel} from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import {AppState} from 'store';
import imageGalleryActions from 'store/reducers/gallery/actionCreators';

import StoredFile from 'types/File';

import Button from 'components/ui/Buttons/Button';
import {normalizeFile} from 'components/ui/Files/FileUpload/utils';
import FilePreview from 'components/ui/Files/FileUpload/components/FilePreview';
import ButtonFileInput from 'components/ui/Files/FileUpload/components/ButtonFileInput';
import styles from 'components/ui/Files/FileUpload/components/GalleryFileInput/gallery.module.scss';

import {NormalizedFile} from '../../types';

interface PublicProps {
    meta: WrappedFieldMetaProps;
    deletedFilesPath: string;
}

interface StateProps {
    deletedFilesIds: number[];
}

interface DispatchProps {
    changeDeletedFilesField(value: number[]): void;
}

interface OwnProps extends StateProps, DispatchProps {
    files?: StoredFile[];
    badge?: string;
    label?: string;
}

const SHOWN_NUMBER_OF_FILES = 7;

const GalleryFileInput = (props: WrappedFieldProps & OwnProps): JSX.Element => {
    const dispatch = useDispatch();

    const {
        files = [],
        input: {name, value: newFiles = [], onChange},
        deletedFilesIds = [],
        changeDeletedFilesField,
        badge,
        label,
    } = props;

    const filterDeletedFiles = (item: StoredFile): boolean => !deletedFilesIds.includes(item.id);
    const normalizedExistingFiles = useMemo(
        function (): NormalizedFile[] {
            return files
                .filter(filterDeletedFiles)
                .map((file: StoredFile): NormalizedFile => normalizeFile(file) as NormalizedFile);
        },
        [files, deletedFilesIds],
    );
    const normalizedNewFiles = useMemo(
        function (): NormalizedFile[] {
            return newFiles.map(normalizeFile);
        },
        [newFiles],
    );
    const mergedFiles = useMemo(
        function (): NormalizedFile[] {
            return normalizedExistingFiles.concat(normalizedNewFiles);
        },
        [normalizedExistingFiles, normalizedNewFiles],
    );
    const handleOpenGallery = (index = 0): void => {
        dispatch(imageGalleryActions.openGallery({files: mergedFiles, currentItemIndex: index}));
    };

    const handleUpload = useCallback(
        function (event: React.FormEvent<HTMLInputElement>): void {
            const {files: addedFiles} = event.currentTarget;
            if (!addedFiles) {
                return;
            }
            const formattedFiles = Array.from(addedFiles).map(function (file: File): {file: File; badge?: string} {
                return {file, badge};
            });
            onChange(newFiles.concat(formattedFiles));
        },
        [newFiles],
    );
    const handleRemove = useCallback(
        function (file: NormalizedFile, removedFileIndex: number): void {
            if (file.isBlob) {
                const updatedNewFiles = newFiles.filter(
                    (value: {file: File; badge?: string}, index: number): boolean =>
                        index !== removedFileIndex - normalizedExistingFiles.length,
                );
                onChange(updatedNewFiles);
                return;
            }
            if (file.id) {
                changeDeletedFilesField(deletedFilesIds.concat(file.id));
            }
        },
        [newFiles, normalizedExistingFiles],
    );
    function renderFilePreviews(): ReactNodeArray {
        return mergedFiles
            .map((file, index) => (
                <FilePreview
                    key={index}
                    file={file}
                    viewInGallery={() => handleOpenGallery(index)}
                    handleRemove={() => handleRemove(file, index)}
                    grid
                />
            ))
            .slice(-SHOWN_NUMBER_OF_FILES);
    }
    const filesList = useMemo(renderFilePreviews, [mergedFiles]);
    return (
        <FormGroup>
            {label && <FormLabel>{label}</FormLabel>}
            <div className={styles.item}>
                <div className="file-items">{filesList}</div>
                <div className="btn-items">
                    {!isEmpty(mergedFiles) && (
                        <Button
                            colorTheme="white"
                            className="button-file-input"
                            buttonIcon="expand"
                            onClick={handleOpenGallery}
                        >
                            view all
                        </Button>
                    )}
                    <ButtonFileInput
                        inputName={name}
                        fileTypes="image/*"
                        colorTheme="white"
                        buttonIcon="upload"
                        onChange={handleUpload}
                        multiple
                    >
                        UPLOAD
                    </ButtonFileInput>
                </div>
            </div>
        </FormGroup>
    );
};

export default connect(
    (state: AppState, props: PublicProps): StateProps => ({
        deletedFilesIds: formValueSelector(props.meta.form)(state, props.deletedFilesPath),
    }),
    (dispatch: Dispatch, props: PublicProps): DispatchProps => ({
        changeDeletedFilesField: (value: number[]) => dispatch(change(props.meta.form, props.deletedFilesPath, value)),
    }),
)(GalleryFileInput);
