import restapi from 'services/restapi/index';

const apiRoute = '/map/routeMatrix';

/**
 * @param {Object} params
 */

// TODO change by "getDistanceCalculation"
export function getDistanceFromOriginsToDestinations(params) {
    return restapi.post(apiRoute, params);
}
