import {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {SettlementCarrier} from 'core/entities/Settlement/types';
import {fetchBatchSettlementsCarrier} from 'core/entities/Settlement/requests/settlementCarrierRequests.js';
import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import {getBatchSettlements, getSearchParams} from 'pages/Settlements/selectors';
import {batchSettlementsReceived} from 'pages/Settlements/actions/batchActions';
import {SettlementCarrierSearchParams} from 'pages/Settlements/types/SettlementSearchParams';

import {isAllFetched} from './utils';

interface FetchResult {
    settlements: SettlementCarrier[];
    isFetching: boolean;
    isAllFetched: boolean;
    fetchNextPage();
}

interface FetchParams {
    batchID: number;
    searchParams: SettlementCarrierSearchParams;
    pagination: {page: number; perPage: number};
}

const PER_PAGE = 10;

const fetchSettlementsCarrier = (params: FetchParams): Promise<{settlements: SettlementCarrier[]; pagination: any}> => {
    const {batchID, searchParams, pagination} = params;
    return fetchBatchSettlementsCarrier(batchID, pagination, searchParams)
        .then((response) => {
            return {settlements: response.data, pagination: parsePaginationHeaders(response.headers)};
        })
        .catch((error) => {
            console.error('Error on fetch batch: ', error);
            return {settlements: [], pagination: parsePaginationHeaders(error.headers)};
        });
};

export default ({batchID}: {batchID: number}): FetchResult => {
    const [isFetching, setIsFetching] = useState(true);
    const ref = useRef({isAllFetched: false, isSearched: false, currentPage: 1});
    const settlementsCarrierSearchParams = useSelector(getSearchParams) as SettlementCarrierSearchParams;
    const settlements = useSelector((state) => getBatchSettlements(state, batchID)) as SettlementCarrier[];
    const dispatch = useDispatch();
    const fetchData = (paginationPage: number): void => {
        setIsFetching(true);

        fetchSettlementsCarrier({
            batchID,
            searchParams: settlementsCarrierSearchParams,
            pagination: {page: paginationPage, perPage: PER_PAGE},
        })
            .then((response) => {
                ref.current.isAllFetched = isAllFetched(response.pagination, response.settlements, PER_PAGE);
                dispatch(batchSettlementsReceived(batchID, response.settlements));
            })
            .finally(() => {
                setIsFetching(false);
            });
    };
    const fetchNextPage = (): void => {
        ref.current.currentPage += 1;
        fetchData(ref.current.currentPage);
    };
    useEffect(() => {
        fetchData(ref.current.currentPage);
    }, []);

    return {
        settlements,
        isFetching,
        isAllFetched: ref.current.isAllFetched,
        fetchNextPage,
    };
};
