import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {WrappedFieldProps} from 'redux-form';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';

import LENGTH_UNITS from 'utils/data/lengthUnits';
import {convertMeasurementsToTheInches, convertFromInches} from 'utils/lengthConverter';

import getValidationState from '../getValidationState';
import OverlayTriggerCustom from '../OverlayTriggerCustom';

import {updateFirstInput, updateSecondInput} from './utils';

import styles from './styles.module.scss';

interface OwnProps {
    label: string | JSX.Element;
    units: string;
    normalize: (str: string) => string;
    disabled?: boolean;
}

const LengthMeasureInput: React.FC<WrappedFieldProps & OwnProps> = (props) => {
    const {label, units, disabled, meta, input} = props;
    const [value, setValue] = useState([0, 0]);

    useEffect(() => {
        setValue(convertFromInches(units, input.value || 0));
    }, [units, input.value]);

    const firstInputHandler = (e) => {
        const target = e.target.value || '';
        if (updateFirstInput(target)) {
            const newValue = [target, value[1]].map((item) => (!item ? 0 : Number(item)));
            input.onChange(convertMeasurementsToTheInches(units, newValue));
        }
    };

    const secondInputHandler = (e) => {
        const target = e.target.value || '';
        if (updateSecondInput(target, units)) {
            const newValue = [value[0], target].map((item) => (!item ? 0 : Number(item)));
            input.onChange(convertMeasurementsToTheInches(units, newValue));
        }
    };

    const valid = getValidationState(meta);

    const inputsClasses =
        units === LENGTH_UNITS.FOOT || units === LENGTH_UNITS.METER
            ? classNames(styles.inputs)
            : classNames(styles.inputs, styles.single);
    const inputClasses = valid ? classNames(styles.input) : classNames(styles.input, styles.input_has_error);

    const placeholder =
        (units === LENGTH_UNITS.FOOT && LENGTH_UNITS.INCH) ||
        (units === LENGTH_UNITS.METER && LENGTH_UNITS.CENTIMETER) ||
        units;

    const errorIcon = !valid && (
        <Form.Control.Feedback type="invalid" className="error">
            <i className="fa fa-exclamation-circle" />
        </Form.Control.Feedback>
    );
    const handleBlur = () => {
        if (input.value === '' || input.value === null || input.value === undefined) {
            return;
        }
        const currentInputValue = Number(input.value);
        if (isNumber(currentInputValue)) {
            input.onBlur(currentInputValue);
        }
    };
    const inputOne = units !== LENGTH_UNITS.INCH && units !== LENGTH_UNITS.CENTIMETER && (
        <div className={inputClasses}>
            <Form.Control
                value={value[0] || ''}
                onFocus={input.onFocus}
                onChange={firstInputHandler}
                onBlur={handleBlur}
                placeholder={units}
                disabled={disabled}
            />
            {errorIcon}
        </div>
    );

    const inputTwo = (
        <div className={inputClasses}>
            <Form.Control
                value={value[1] || ''}
                onFocus={input.onFocus}
                onChange={secondInputHandler}
                onBlur={handleBlur}
                placeholder={placeholder}
                disabled={disabled}
            />
            {errorIcon}
        </div>
    );

    return (
        <div className={styles.wrap}>
            <Form.Label>{label}</Form.Label>
            <OverlayTriggerCustom inputMeta={meta}>
                <div className={inputsClasses}>
                    {inputOne}
                    {inputTwo}
                </div>
            </OverlayTriggerCustom>
            <div>{errorIcon}</div>
        </div>
    );
};

export default LengthMeasureInput;
