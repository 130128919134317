import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import sortBy from 'lodash/sortBy';

import * as actions from 'store/actions';
import {getIsDispatchersFetching} from 'store/reducers/commonData/selectors';
import {getCurrentUserID} from 'store/reducers/userData/selectors';

import Select from 'components/ui/Form/Select';

import OptimizationWrapper from './OptimizationWrapper';
import {getDispatchersList, getDispatchersGroupedByRole} from './selectors';

const DispatchersSelect = (props) => {
    const {
        allDispatchers,
        placeholder = 'choose dispatcher',
        fetchDispatchers,
        isDispatchersFetching,
        isMinRequest = true,
        isGroupedByRole,
        currentDispatcherID,
        withoutCurrent,
        liftCurrentToTop,
        maxLength,
    } = props;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(isDispatchersFetching);
    }, [isDispatchersFetching]);

    useEffect(() => {
        fetchDispatchers({isMinRequest});
    }, []);

    const setOptionDisabled = () => maxLength <= props.input.value.length;

    if (isGroupedByRole) {
        // SelectWithOptimization  is unable to correctly display grouped options
        return <Select {...props} data={allDispatchers || []} placeholder={placeholder} isLoading={isLoading} />;
    }

    if (withoutCurrent) {
        const dispatchersWithoutCurrent = allDispatchers.filter((option) => option.value !== currentDispatcherID);
        return (
            <OptimizationWrapper
                {...props}
                data={dispatchersWithoutCurrent || []}
                placeholder={placeholder}
                isLoading={isLoading}
            />
        );
    }

    const withLiftedCurrentDispatcher = sortBy(allDispatchers, (dispatcher) => {
        if (dispatcher?.value?.id) {
            return dispatcher?.value?.id === currentDispatcherID ? 0 : 1;
        }
        return dispatcher?.value === currentDispatcherID ? 0 : 1;
    });

    const dispatchers = liftCurrentToTop ? withLiftedCurrentDispatcher : allDispatchers || [];

    return (
        <OptimizationWrapper
            {...props}
            data={dispatchers}
            placeholder={placeholder}
            isLoading={isLoading}
            isOptionDisabled={setOptionDisabled}
        />
    );
};

// for tests
export {DispatchersSelect};

export default connect(
    (state, ownProps) => {
        const {valueAsObject, skipHidden, isGroupedByRole, includeRoles} = ownProps;
        return {
            allDispatchers: isGroupedByRole
                ? getDispatchersGroupedByRole(state, {valueAsObject, skipHidden})
                : getDispatchersList(state, {valueAsObject, skipHidden, includeRoles}),
            isDispatchersFetching: getIsDispatchersFetching(state),
            currentDispatcherID: getCurrentUserID(state),
        };
    },
    (dispatch) => ({
        fetchDispatchers: (params) => dispatch(actions.fetchAllDispatchers(params)),
    }),
)(DispatchersSelect);
