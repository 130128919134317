export const LOADS_RECEIVED = 'LOADS_COMPONENT_ITEMS_RECEIVED';
export const LOADS_NOTES_RECEIVED = 'LOADS_LIST_COMPONENT_ALL_NOTES_RECEIVED';
export const RECEIVE_NOTE = 'LOADS_LIST_RECEIVE_NEW_NOTE';
export const RECEIVE_LOAD = 'LOADS_LIST_RECEIVE_NEW_LOAD';
export const TRAVEL_ORDER_CREATED = 'LOAD_COMPONENT_TRAVEL_ORDER_CREATED';
export const LIST_DATA_RECEIVED = 'LOADS_LIST_COMPONENT_DATA_RECEIVED';
export const LIST_DATA_CLEARED = 'LOADS_LIST_COMPONENT_DATA_CLEARED';
export const SET_ADVANCED_SEARCH_PARAMS = 'LOADS_COMPONENT_SET_ADVANCED_SEARCH_PARAMS';
export const SET_STATUS_SEARCH_PARAMS = 'LOADS_LIST_COMPONENT_SET_STATUS_SEARCH_PARAMS';
export const SET_SIMPLE_SEARCH_PARAMS = 'LOADS_LIST_COMPONENT_SET_SIMPLE_SEARCH_PARAMS';
export const UPDATE_PICK_UP_DELIVERY_DISTANCES = 'LOADS_LIST_COMPONENT_UPDATE_PICK_UP_DELIVERY_DISTANCES';
export const CLEAR_STATE = 'LOADS_COMPONENT_CLEAR_STATE';
