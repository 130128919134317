import React, {useCallback} from 'react';
import isObject from 'lodash/isObject';
import {WrappedFieldInputProps, WrappedFieldMetaProps} from 'redux-form';

import Owner from 'core/entities/Owner/types';
import {searchOwnersByTitle, fetchOwner} from 'core/gateways/OwnerApiGateway/requests';

import AsyncPaginatedSelect from 'components/ui/Form/AsyncSelect';

import {getOwnersList, transformOwnersLabel} from './utils';

interface OwnProps {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    valueAsObject: boolean;
    label?: string;
    placeholder?: string;
    isDisabled: boolean;
    isClearable?: boolean;
    labelWithPhoneNumber?: boolean;
    withoutOwnerDriver?: boolean;
    setSelectedOwner?: (selectedOwner: Owner | null) => void;
}

const isOwnerObject = (owner: any): owner is Owner => {
    return isObject(owner);
};

const OwnersSelect = (props: OwnProps): JSX.Element | null => {
    const {
        input,
        input: {value: initialInputValue, onChange},
        meta,
        label,
        valueAsObject,
        placeholder = 'choose owner',
        isDisabled,
        isClearable = true,
        labelWithPhoneNumber,
        withoutOwnerDriver,
        setSelectedOwner,
    } = props;

    const ownersList = (value) =>
        labelWithPhoneNumber
            ? transformOwnersLabel(getOwnersList(value, valueAsObject))
            : getOwnersList(value, valueAsObject, withoutOwnerDriver);

    const loadOptions = {
        getInitialOption: async () => {
            if (!initialInputValue) {
                return undefined;
            }
            if (isOwnerObject(initialInputValue)) {
                const [initialOwner] = ownersList([initialInputValue]);
                return initialOwner;
            }
            const fetchedOwner = await fetchOwner(initialInputValue).then((res: any) => res.data);
            const [initialOwner] = ownersList([fetchedOwner]);
            if (setSelectedOwner) {
                setSelectedOwner(initialOwner?.value ?? null);
            }
            return initialOwner;
        },
        getNextOptions: async ({searchValue, page}): Promise<any> => {
            const searchedOwners = await searchOwnersByTitle({
                title: searchValue,
                page,
                'per-page': 50,
                is_owner: withoutOwnerDriver || undefined,
            });
            return searchedOwners.data;
        },
        formatNextOptions: (nextOptions): any => {
            return ownersList(nextOptions);
        },
    };

    const handleSelectItem = useCallback((option) => {
        if (onChange) {
            const res = valueAsObject ? option?.value : option?.value?.id;
            onChange(res);
        }
        if (setSelectedOwner) {
            setSelectedOwner(option?.value ?? null);
        }
    }, []);

    return (
        <AsyncPaginatedSelect
            label={label}
            formInput={input}
            formMeta={meta}
            loadOptions={loadOptions}
            placeholder={placeholder}
            onSelectOption={handleSelectItem}
            isDisabled={isDisabled}
            isClearable={isClearable}
        />
    );
};

export default OwnersSelect;
