import React from 'react';

import styles from './styles.module.scss';

type OwnProps = {
    currentPage: number;
    perPageCount: number;
    allPagesCount: number;
    allItemsCount: number;
    onChangePage(pageNumber);
};

const InvoicesPagination = (props: OwnProps): JSX.Element => {
    const {currentPage, perPageCount, allItemsCount, allPagesCount, onChangePage} = props;
    const viewedItemsCount = currentPage * perPageCount;
    const handleChangeToPrevPage = () => {
        const prevPage = currentPage - 1;
        if (prevPage > 0) {
            onChangePage(prevPage);
        }
    };
    const handleChangeToNextPage = () => {
        const nextPage = currentPage + 1;
        if (nextPage <= allPagesCount) {
            onChangePage(nextPage);
        }
    };
    return (
        <div className={styles.container}>
            <div className={styles.container__counters}>
                {currentPage} - {viewedItemsCount} of {allItemsCount}
            </div>
            <div className={styles.container__arrows}>
                <span onClick={handleChangeToPrevPage}>
                    <i className="fa fa-angle-left" />
                </span>
                <span onClick={handleChangeToNextPage}>
                    <i className="fa fa-angle-right" />
                </span>
            </div>
        </div>
    );
};

export default InvoicesPagination;
