import isNumber from 'lodash/isNumber';
import {DriverOfferFormValues} from 'pages/LoadBoard/types';

export default function (values: DriverOfferFormValues): any {
    const errors = {
        payment: {},
    } as Record<keyof DriverOfferFormValues, any>;
    if (!values.payment?.rate) {
        errors.payment.rate = 'Please enter Rate';
    }
    if (!isNumber(values.emptyDistance)) {
        errors.emptyDistance = 'Please enter Miles Away';
    }
    if (!values.truck) {
        errors.truck = 'Please enter Truck';
    }
    return errors;
}
