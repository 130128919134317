import React from 'react';
import {Modal} from 'react-bootstrap';
import Button from 'components/ui/Buttons/Button';

type OwnProps = {
    data: {
        offerNumber: number;
    };
    onClose(): void;
};
const SuccessfullyChangedDriverOfferModal: React.FC<OwnProps> = (props) => {
    const {data, onClose} = props;
    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Successfully Changed</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row align-items-center">
                    <div className="col-1">
                        <i className="fa fa-check feedback-icon-success" />
                    </div>
                    <div className="col-11">
                        <p>
                            The Offer <strong>#{data.offerNumber}</strong> has been successfully changed.
                        </p>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <div />
                <div className="d-flex">
                    <Button
                        onClick={onClose}
                        className="mr10"
                        colorTheme="green"
                        buttonSize="small"
                        defaultFocus={true}
                    >
                        OK
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
export default SuccessfullyChangedDriverOfferModal;
