import {createDate, getCurrentDateSettings} from 'utils/dateTime';

export const isInvalidDateTime = (dateTime: string): boolean => dateTime === 'Invalid DateTime';

export const getDateAndTimeFromFullDate = (dateTime: string): {date?: string; time?: string} => {
    const {currentTimeZone} = getCurrentDateSettings();
    const {originalDate, originalTime} = createDate(dateTime, {fromTimeZone: currentTimeZone}) || {};

    const date = isInvalidDateTime(originalDate) ? undefined : originalDate;
    const time = isInvalidDateTime(originalTime) ? undefined : originalTime;

    return {date, time};
};

export const getUpdatedFieldValue = ({time, date}: {time?: string; date?: string}): string | undefined => {
    if (!time && !date) {
        return;
    }
    if (!time) {
        return date;
    }
    if (!date) {
        return time;
    }
    return `${date} ${time}`;
};
