import omit from 'lodash/omit';

import {BrokerUpdateRequestBody} from 'core/entities/LocationEvents/types';

import {BrokerUpdateSendFormValues} from 'components/common/LocationEvents/modals/BrokerUpdateSendModal/types';

export const transformToArrayEmails = (emails?: string): string[] => emails?.split(',') || [];

export const transformToStringEmails = (emails?: string[]): string => emails?.join(',') || '';

export const transformBrokerUpdateDataToRequestBody = (
    brokerUpdate: BrokerUpdateSendFormValues,
): BrokerUpdateRequestBody => {
    const brokerUpdateRequestData = omit(brokerUpdate, 'travelOrderNumber');

    return {
        ...brokerUpdateRequestData,
        emailsTo: transformToArrayEmails(brokerUpdate.emailsTo),
    };
};
