import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';

import * as appActions from 'store/actions';

import {fetchCustomerApiConnections} from 'core/gateways/CustomerApiGateway/requests';

const useApiConnection = () => {
    const [apiConnections, setApiConnections] = useState([]);
    const dispatch = useDispatch();

    const getApiConnections = async () => {
        try {
            dispatch(appActions.showLoader());

            const {data} = await fetchCustomerApiConnections();

            setApiConnections(data);
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(appActions.hideLoader());
        }
    };

    useEffect(() => {
        getApiConnections();
    }, []);

    return {apiConnections};
};

export default useApiConnection;
