import head from 'lodash/head';

import Load from 'core/entities/Load/types';
import TravelOrder from 'core/entities/TravelOrder/types';
import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';

export const getCurrentTravelOrder = (load?: Load): TravelOrder | undefined => head(load?.travelOrders);

export const getTravelOrderNumber = (load?: Load): number | undefined =>
    head(load?.travelOrders?.map((travelOrder) => travelOrder.number));

export const getMatchingTOStopAndLoadStop = (
    loadStopID: string,
    travelOrder: TravelOrder | undefined,
): TravelOrderStop | undefined => travelOrder?.toStops.find((stop) => stop.loadStop.id === loadStopID);
