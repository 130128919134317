import React from 'react';
import {Field} from 'redux-form';
import DropDownCheckBoxMulti from 'components/ui/Form/DropDownCheckBoxMulti';
import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import countries from 'utils/data/countries';

const crossBorder = [
    {label: countries.CA.name, value: countries.CA.name},
    {label: countries.MX.name, value: countries.MX.name},
];
const CrossBorder: React.FC = (): JSX.Element => {
    const {getTruckDriverCrossBorderFieldName} = getFormFieldNames();
    return (
        <Field
            btnTitle="Cross Border"
            name={getTruckDriverCrossBorderFieldName()}
            component={DropDownCheckBoxMulti}
            items={crossBorder}
        />
    );
};
export default CrossBorder;
