import {LOCATION_CHANGE} from 'connected-react-router';

import {USER_LOGOUT} from 'store/actionTypes';
import * as types from 'store/middlewares/mainAPISocketsIO/actionTypes';
import * as messageHandlers from 'store/middlewares/mainAPISocketsIO/entities/channelMessageHandlers';
import {pagesChannelsMap} from 'store/middlewares/mainAPISocketsIO/pagesChannelsMap';
import websocketChannels from 'store/middlewares/mainAPISocketsIO/websocketChannels';
import {getIsUserLoggedIn} from 'store/reducers/auth/selectors';

import * as pages from 'utils/data/pages';

import * as actions from './actions';

interface Socket {
    close(...params): any;
    on(...params): any;
    emit(...params): any;
    hasListeners(...params): any;
    off(...params): any;
    _callbacks: any;
    id: string;
    connected: boolean;
    disconnected: boolean;
}

const getPageFromUrl = (url: string): string => {
    if (!url) {
        return '';
    }
    const [, page, subPage] = url.split('/');
    if (page === pages.SETTLEMENTS) {
        return `${page}/${subPage}`;
    }
    return page;
};

const mainAPISocketsIO = (store) => (next) => {
    const {dispatch} = store;
    let socket: Socket | null = null;
    let currentChannelPage = '';

    const subscribeOnChannel = (channelName, messageHandler) => {
        if (
            !socket ||
            !socket.connected ||
            !channelName ||
            !messageHandler ||
            (socket && socket.hasListeners(channelName))
        ) {
            return;
        }
        try {
            socket.on(channelName, (newMessage) => {
                if (!newMessage) {
                    console.warn('incorrect new message from websocket!');
                    return;
                }
                messageHandler(store)(channelName, newMessage);
            });
        } catch (error) {
            console.warn('Websocket channel subscribe error: ', error);
        }
    };

    const unsubscribeFromChannel = (channelName) => {
        if (!channelName || !socket || !socket.connected || (socket && !socket.hasListeners(channelName))) {
            return;
        }
        try {
            socket.off(channelName);
        } catch (error) {
            console.warn('Websocket channel unsubscribe error: ', error);
        }
    };

    const subscribeToMultipleChannels = (channelsData) => {
        if (!socket || !socket.connected) {
            return;
        }
        channelsData.forEach((data) => {
            const {channel, handler} = data;
            if (channel && handler) {
                subscribeOnChannel(channel, handler);
            }
        });
    };

    const unsubscribeFromMultipleChannels = (channelsData) => {
        if (!socket || !socket.connected) {
            return;
        }
        channelsData.forEach((data) => {
            const {channel, handler} = data;
            if (channel && handler) {
                unsubscribeFromChannel(channel);
            }
        });
    };

    const switchChannelByChangePage = ({prevPage, newPage}: {prevPage: string; newPage: string}) => {
        const oldChannelsData = pagesChannelsMap[prevPage] || [];
        const newChannelsData = pagesChannelsMap[newPage] || [];
        unsubscribeFromMultipleChannels(oldChannelsData);
        subscribeToMultipleChannels(newChannelsData);
    };

    const subscribeToChannelsDependingOnLocation = (action) => {
        if (action.type === LOCATION_CHANGE) {
            const {
                location: {pathname},
            } = action.payload;
            const newPage = getPageFromUrl(pathname);
            if (currentChannelPage !== newPage) {
                switchChannelByChangePage({prevPage: currentChannelPage, newPage});
                currentChannelPage = newPage;
            }
        }
    };

    const initSocketHandlers = () => {
        if (!socket || !socket.connected) {
            return;
        }

        actions.connected({socket});

        subscribeOnChannel(websocketChannels.notifications, messageHandlers.notificationsChannelMessageHandler);
        subscribeOnChannel(websocketChannels.messages, messageHandlers.messagesChannelMessageHandler);

        const currentPage = getPageFromUrl(window.location.pathname);
        const channelsData = pagesChannelsMap[currentPage] || [];
        subscribeToMultipleChannels(channelsData);
    };

    const initWsConnectionAndSetSocket = () => {
        dispatch(actions.connect())
            .then((socketClient: Socket) => {
                socket = socketClient;
                initSocketHandlers();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const clearCachedSocket = () => {
        socket = null;
    };

    return (action) => {
        const state = store.getState();
        const isUserLoggedIn = getIsUserLoggedIn(state);

        if (!isUserLoggedIn) {
            return next(action);
        }

        if (action.type === USER_LOGOUT && socket) {
            socket.close();
            return next(action);
        }

        if (action.type === types.WEB_SOCKET_DISCONNECTED) {
            clearCachedSocket();
            return next(action);
        }

        if (action.type === types.WEB_SOCKET_INIT || action.type === types.WEB_SOCKET_RECONNECT) {
            initWsConnectionAndSetSocket();
        }

        if (socket) {
            subscribeToChannelsDependingOnLocation(action);
        }

        return next(action);
    };
};

export default mainAPISocketsIO;
