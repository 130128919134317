import Pagination from 'types/Pagination';

import {Agent} from 'core/entities/Agent';

import * as actionTypes from 'pages/Customers/redux/actionTypes/agents';

const actionCreators = {
    clearCustomerAgents: () => ({type: actionTypes.AGENTS_STATE_CLEARED} as const),
    setAgents: (payload: {agents: Agent[]; pagination: Pagination}) =>
        ({type: actionTypes.AGENTS_RECEIVED, payload} as const),
    switchShowArchived: (isArchived: boolean) =>
        ({type: actionTypes.AGENTS_SHOW_ARCHIVED_SWITCHED, payload: isArchived} as const),
    setPaginationParams: (pagination: Partial<Pagination>) =>
        ({type: actionTypes.AGENTS_PAGINATION_PARAMS_RECEIVED, payload: pagination} as const),
};

export default actionCreators;
