import React from 'react';

import Driver from 'core/entities/Driver/types';

import GeneralBadge from 'components/ui/Badges/GeneralBadge';

type OwnProps = {
    driver?: Driver;
    withBorderRadius?: boolean;
};

const DriverCrossBorderBadges: React.FC<OwnProps> = (props) => {
    const {driver, withBorderRadius = false} = props;

    const isCrossBorderCanada = Boolean(driver?.canada);
    const isCrossBorderMexico = Boolean(driver?.mexico);
    const isCrossBorderGreatBritain = Boolean(driver?.great_britain);
    const isCrossBorderSwitzerland = Boolean(driver?.switzerland);

    return (
        <>
            {isCrossBorderCanada ? (
                <GeneralBadge
                    text="CA"
                    tooltip="allowed to cross Canada borders"
                    tooltipPosition="right"
                    withBorderRadius={withBorderRadius}
                />
            ) : null}

            {isCrossBorderMexico ? (
                <GeneralBadge
                    text="MX"
                    tooltip="allowed to cross Mexico borders"
                    tooltipPosition="right"
                    withBorderRadius={withBorderRadius}
                />
            ) : null}

            {isCrossBorderGreatBritain ? (
                <GeneralBadge
                    text="GB"
                    tooltip="allowed to cross Great Britain borders"
                    tooltipPosition="right"
                    withBorderRadius={withBorderRadius}
                />
            ) : null}

            {isCrossBorderSwitzerland ? (
                <GeneralBadge
                    text="CH"
                    tooltip="allowed to cross Switzerland borders"
                    tooltipPosition="right"
                    withBorderRadius={withBorderRadius}
                />
            ) : null}
        </>
    );
};

export default DriverCrossBorderBadges;
