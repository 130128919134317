import {FileNew, TOFile} from 'types/File';

import {ATTACH_TYPES} from 'core/entities/TravelOrder/constants/attachTypes';
import TravelOrder from 'core/entities/TravelOrder/types';

import getText from 'utils/getText';

import {getTravelOrderFiles, transferTOFileToNewFileInterface} from '../index';

export const getTravelOrderSignedFiles = (travelOrder?: TravelOrder | null): TOFile[] => {
    const travelOrderFiles = getTravelOrderFiles(travelOrder);

    const signedFiles = (travelOrderFiles || []).filter(
        (attachment) =>
            attachment.attachType === ATTACH_TYPES.TRAVEL_ORDER_FILE ||
            attachment.attachType === ATTACH_TYPES.RC_SIGNED,
    );

    return signedFiles;
};

export const transferTravelOrderSignedAttachments = (travelOrder?: TravelOrder | null): Partial<FileNew>[] => {
    const travelOrderSignedFiles = getTravelOrderSignedFiles(travelOrder);

    return travelOrderSignedFiles.map((file, fileIndex) => transferTOFileToNewFileInterface({file, fileIndex}));
};

export const getGroupedTravelOrderSignedAttachments = (params: {
    travelOrder?: TravelOrder | null;
}): {[key: string]: Partial<FileNew>[]} => {
    const {travelOrder} = params;

    const transferredTravelOrderAttachments = transferTravelOrderSignedAttachments(travelOrder);

    return {[getText('rateConfirmation')]: transferredTravelOrderAttachments};
};
