export const GENERAL = {
    section: 'general',
    updateTitle: 'General',
    createTitle: 'Step 1. General',
};
export const CONTACT = {
    section: 'contact',
    updateTitle: 'Contact',
    createTitle: 'Step 2. Contact',
};
export const FILES = {
    section: 'files',
    updateTitle: 'Files',
};

export const FIELDS = {
    general: {
        // first_name: 'first_name',
        is_owner: 'is_owner',
        full_name: 'full_name',
        // last_name: 'last_name',
        birthdate: 'birthdate',
        canada: 'canada',
        mexico: 'mexico',
        language: 'language',
        avatar: 'avatar',
        hired_by: 'hired_by',
        hired_date: 'hired_date',
        ssn: 'ssn',
        ein: 'ein',

        form_1099: 'form_1099',
        llc_name: 'llc_name',
        llc_ein: 'llc_ein',
        note: 'note',
        password: 'password',
        confirm_password: 'confirm_password',

        /* New Fields */
        carrier: 'carrier',
        status: 'status',
        citizenship: 'citizenship',

        passportDate: 'passportDate',
        payroll: 'payroll',
        documents: 'documents',
        company_name: 'company_name',
        license_type: 'license_type',
        drivingSince: 'drivingSince',
        expiration: 'expiration',
        is_hazmat: 'is_hazmat',
        is_tsa: 'is_tsa',
        is_twic: 'is_twic',
        is_tanker_endorsement: 'is_tanker_endorsement',
        /** ***** */
    },
    contact: {
        address: 'address',
        city_line: 'city_line',
        home_phone: 'home_phone',
        cell_phone: 'cell_phone',
        fax: 'fax',
        email: 'email',
        emergency_title: 'emergency_title',
        emergency_name: 'emergency_name',
        emergency_phone: 'emergency_phone',
        emergency_phone2: 'emergency_phone',
        home_lat: 'home_lat',
        home_lng: 'home_lng',

        /* New Fields */
        address1: 'address1',
        address2: 'address2',
        phone_code: 'phone_code',
        extra_cell_phone: 'extra_cell_phone',
        is_mobile: 'is_mobile',
        /** ***** */
    },
    license: {
        med_card_exp: 'med_card_exp',
        license_number: 'license_number',
        state: 'state',
        license_exp: 'license_exp',
    },
};
