import isObject from 'lodash/isObject';

import {
    WEB_SOCKET_SETTLEMENTS_CARRIERS_SETTLEMENT_UPDATED,
    WEB_SOCKET_SETTLEMENTS_CARRIERS_BATCH_CREATED,
    WEB_SOCKET_ADD_SETTLEMENTS_TO_CARRIERS_BATCH,
    WEB_SOCKET_RESET_SETTLEMENTS_FROM_CARRIERS_BATCH,
    WEB_SOCKET_CLOSE_CARRIERS_BATCH,
    WEB_SOCKET_REOPEN_CARRIERS_BATCH,
    WEB_SOCKET_SEND_EMAIL_FOR_CARRIERS_BATCH,
} from 'store/middlewares/mainAPISocketsIO/entities/settlementsCarriers/actionTypes';

import {addItemToTable, updateItemInTable} from './utils';

const getOpenInfoModalCommandAfterBatchCreate = (state, modalData) => {
    return {...state.command, name: 'showInfoBatchCreate', data: {modalData}};
};

const getOpenInfoModalCommandAfterAddSettlementsToBatch = (state, modalData) => {
    return {...state.command, name: 'showInfoAddSettlementToBatch', data: {modalData}};
};

const getOpenInfoModalCommandAfterResetSettlementsFromBatch = (state, modalData) => {
    return {...state.command, name: 'showInfoResetSettlementFromBatch', data: {modalData}};
};

const getOpenInfoModalCommandAfterCloseBatch = (state, modalData) => {
    return {...state.command, name: 'showInfoCloseBatch', data: {modalData}};
};

const getOpenInfoModalCommandAfterReOpenBatch = (state, modalData) => {
    return {...state.command, name: 'showInfoReOpenBatch', data: {modalData}};
};

const getOpenInfoModalCommandAfterSendEmailForBatch = (state, modalData) => {
    return {...state.command, name: 'showInfoSendEmailForBatch', data: {modalData}};
};

export default function settlementsCarriersWebsocketReducer(state, action) {
    switch (action.type) {
        case WEB_SOCKET_SETTLEMENTS_CARRIERS_SETTLEMENT_UPDATED: {
            const updatedSettlement = action.payload;

            if (!updatedSettlement || !isObject(updatedSettlement)) {
                return state;
            }

            const isUpdatedSettlementInCurrentState = Boolean(state.settlements.byId[updatedSettlement.id]);

            if (isUpdatedSettlementInCurrentState) {
                return {
                    ...state,
                    settlements: updateItemInTable(state.settlements, updatedSettlement),
                };
            }
            return {
                ...state,
                settlements: addItemToTable(state.settlements, updatedSettlement),
            };
        }

        case WEB_SOCKET_SETTLEMENTS_CARRIERS_BATCH_CREATED: {
            const modalData = action.payload;
            return {
                ...state,
                command: getOpenInfoModalCommandAfterBatchCreate(state, modalData),
            };
        }

        case WEB_SOCKET_ADD_SETTLEMENTS_TO_CARRIERS_BATCH: {
            const modalData = action.payload;
            return {
                ...state,
                command: getOpenInfoModalCommandAfterAddSettlementsToBatch(state, modalData),
            };
        }
        case WEB_SOCKET_RESET_SETTLEMENTS_FROM_CARRIERS_BATCH: {
            const modalData = action.payload;
            return {
                ...state,
                command: getOpenInfoModalCommandAfterResetSettlementsFromBatch(state, modalData),
            };
        }
        case WEB_SOCKET_CLOSE_CARRIERS_BATCH: {
            const modalData = action.payload;
            return {
                ...state,
                command: getOpenInfoModalCommandAfterCloseBatch(state, modalData),
            };
        }
        case WEB_SOCKET_REOPEN_CARRIERS_BATCH: {
            const modalData = action.payload;
            return {
                ...state,
                command: getOpenInfoModalCommandAfterReOpenBatch(state, modalData),
            };
        }
        case WEB_SOCKET_SEND_EMAIL_FOR_CARRIERS_BATCH: {
            const modalData = action.payload;
            return {
                ...state,
                command: getOpenInfoModalCommandAfterSendEmailForBatch(state, modalData),
            };
        }

        default:
            return state;
    }
}
