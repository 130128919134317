import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import toNumber from 'lodash/toNumber';

import {TripMonitorState} from 'pages/TravelOrders/redux/reducers/list';
import {TripMonitorSearchFormValues} from 'pages/TravelOrders/types/formTypes';

export const transformBookedByDataToRequestBody = (params: {filters: Partial<TripMonitorSearchFormValues>}) => {
    const {filters} = params;
    const bookedBy = filters?.bookedBy?.map((dispatcher) => toNumber(dispatcher.value));

    return !isEmpty(bookedBy) ? bookedBy : null;
};

export const transformDispatchersAssignToLoadDataToRequestBody = (params: {
    filters: Partial<TripMonitorSearchFormValues>;
}) => {
    const {filters} = params;
    const assignedTo = filters?.dispatchersAssignToLoad?.map((dispatcher) => toNumber(dispatcher.value));

    return !isEmpty(assignedTo) ? assignedTo : null;
};

type StateOption = {label: string; value: any};
const transformStateDataToRequestBody = (stateData?: StateOption[]) => {
    if (!isArray(stateData)) {
        return null;
    }

    return stateData.map(({value}) => value).join(',') || null;
};

export const transformSearchDataToRequestBody = (params: {filters: Partial<TripMonitorSearchFormValues>}) => {
    const {filters} = params;

    const dispatchersAssignToLoadIDs = transformDispatchersAssignToLoadDataToRequestBody({filters});
    const bookedByDispatchersIDs = transformBookedByDataToRequestBody({filters});

    const searchByLoad = {
        bookedCustomerId: filters.bookedWith?.id ? toNumber(filters?.bookedWith?.id) : null,
        loadNumber: filters?.loadNumber ? toNumber(filters?.loadNumber) : null,
        dispatchersAssignToLoad: dispatchersAssignToLoadIDs || null,
        bookedByDispatcherId: bookedByDispatchersIDs || null,
        notAssigned: filters?.notAssigned || null,
        refNumbers: filters?.refNumber || null,
        loadType: !isEmpty(filters?.loadType) ? filters.loadType : null,
    };

    const omittedSearchByLoad = omitBy(searchByLoad, (value) => isNull(value) || isUndefined(value));
    const originState = transformStateDataToRequestBody(filters?.originState);
    const destinationState = transformStateDataToRequestBody(filters?.destinationState);

    const searchParams = {
        travelOrderNumber: filters?.travelOrderNumber ? toNumber(filters?.travelOrderNumber) : null,
        carrierId: filters?.carrier?.value ? toNumber(filters?.carrier?.value) : null,
        crossBorderMexico: filters?.crossBorderMexico || null,
        withStoppedTrucks: filters?.withStoppedTrucks || null,
        crossBorderCanada: filters?.crossBorderCanada || null,
        withPlannedLoads: filters?.withPlannedLoads || null,
        destinationCity: filters?.destinationCity || null,
        destinationZip: filters?.destinationZip || null,
        progressStep: filters?.progressStep || null,
        truckNumber: filters?.truckNumber || null,
        phoneNumber: filters?.phoneNumber || null,
        originCity: filters?.originCity || null,
        originZip: filters?.originZip || null,
        autoEventStatus: filters?.autoEventStatus || null,
        searchByLoad: isEmpty(omittedSearchByLoad) ? null : omittedSearchByLoad,
        destinationState,
        originState,
    };

    return omitBy(searchParams, (value) => isNull(value) || isUndefined(value));
};

export const handleReducerBySetTripMonitorSearchParams = (params: {
    state: TripMonitorState;
    searchParams: Partial<TripMonitorSearchFormValues>;
}) => {
    const {state, searchParams} = params;

    const omittedSearchParams = omitBy(searchParams, (value) => isNull(value) || isUndefined(value)) || {};

    return {
        ...state,
        searchParams: omittedSearchParams,
    };
};
