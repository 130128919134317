import React from 'react';

import Load from 'core/entities/Load/types';

import TooltipBootstrap from 'components/ui/TooltipBootstrap';

import styles from './styles.module.scss';

type OwnProps = {
    load: Load;
};

const LoadTempControlBadge: React.FC<OwnProps> = (props) => {
    const {load} = props;

    if (!load.truckWithTempControl) {
        return null;
    }

    return (
        <TooltipBootstrap tooltipContent="reefer load">
            <div className={styles.badge}>
                <i className="fa fa-snowflake-o" aria-hidden="true" />
            </div>
        </TooltipBootstrap>
    );
};

export default LoadTempControlBadge;
