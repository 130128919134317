import React from 'react';

import {IsTruckReservedContext} from 'hocs/withTruckReservingTimer/IsTruckReservedContext';
import withTruckReservingTimer from 'hocs/withTruckReservingTimer';

import {checkTruckInsuranceExpiration} from 'core/entities/Truck/modules/truckInsurance/checkTruckInsuranceExpiration';
import {checkTruckDriversLicensesExpiration, getTruckDrivers} from 'core/entities/Truck/modules/truckDrivers';
import * as statuses from 'core/entities/Truck/constants/truckStatuses';
import Truck from 'core/entities/Truck/types';

import {checkLoadExpirationDateIsEarlier} from 'pages/Loads/modals/DocumentsExpirationModal/utils';
import useAttachState from 'pages/Loads/components/common/modals/AttachModal/hooks/useAttachState';
import useModalActions from 'pages/Loads/hooks/useModalActions';

import InfoBadge from 'components/ui/Badges/InfoBadge';
import Button from 'components/ui/Buttons/Button';
import LengthView from 'components/ui/LengthView';
import {TruckLink} from 'components/ui/Links';

import TruckItemReservingInfo from './components/TruckItemReservingInfo';
import TruckItemTeamSlider from './components/TruckItemTeamSlider';

import styles from './styles.module.scss';

type OwnProps = {
    truck: Truck;
};

const TruckItem: React.FC<OwnProps> = (props) => {
    const {truck} = props;

    const {number, type, status} = truck;

    const {createTravelOrder, showDocumentsExpirationModal} = useModalActions();
    const {loadToAttach} = useAttachState();

    if (!loadToAttach) {
        return null;
    }

    const checkIsShowExpirationModal = (): boolean => {
        const isTruckInsuranceWillNotExpire = checkLoadExpirationDateIsEarlier(
            loadToAttach.stops,
            truck.insurance_expiration,
        );
        const truckInsuranceExpiration = checkTruckInsuranceExpiration(truck?.insurance_expiration);
        const {firstDriver, secondDriver} = checkTruckDriversLicensesExpiration(truck);
        const drivers = getTruckDrivers(truck);

        const hasExpiredOrEmptyDocuments =
            truckInsuranceExpiration.severity ||
            firstDriver.severity ||
            (drivers?.secondDriver && secondDriver.severity);
        const isFirstDriverLicenseWillNotExpire = checkLoadExpirationDateIsEarlier(
            loadToAttach.stops,
            drivers?.firstDriver?.expiration,
        );
        const isSecondDriverLicenseWillNotExpire =
            !drivers?.secondDriver ||
            checkLoadExpirationDateIsEarlier(loadToAttach.stops, drivers?.secondDriver?.expiration);
        const isSomeDocsWillExpireBeforeLoad =
            !isTruckInsuranceWillNotExpire || !isFirstDriverLicenseWillNotExpire || !isSecondDriverLicenseWillNotExpire;

        return Boolean(hasExpiredOrEmptyDocuments) || isSomeDocsWillExpireBeforeLoad;
    };

    const handleAttachLoadToTruck = () => {
        if (checkIsShowExpirationModal()) {
            showDocumentsExpirationModal(loadToAttach, truck);
            return;
        }

        createTravelOrder({load: loadToAttach, truck});
    };

    const statusClassName = {
        [statuses.TRUCK_STATUS_WILL_BE_AVAILABLE_AT]: 'blue',
        [statuses.TRUCK_STATUS_NOT_AVAILABLE]: 'gray',
        [statuses.TRUCK_STATUS_ON_ROUTE]: 'yellow',
        [statuses.TRUCK_STATUS_AVAILABLE]: 'green',
    };

    return (
        <div className={styles.wrap}>
            <div className={styles.title}>
                <TruckLink truck={truck} content={`Truck #${number}`} className="second-link" />
            </div>

            <div>{type}</div>

            <div className="d-flex justify-content-center text-lowercase font-bold">
                <LengthView
                    length={truck.valid_dims_length}
                    width={truck.valid_dims_width}
                    height={truck.valid_dims_height}
                    units={truck.dims_units}
                />
            </div>

            <IsTruckReservedContext.Consumer>
                {(isReserved) => (isReserved ? <TruckItemReservingInfo truck={truck} /> : null)}
            </IsTruckReservedContext.Consumer>

            <div className="mb5" />

            <div className={styles.badge}>
                <InfoBadge text={status} colorBadge={statusClassName[status]} isLargeSize={true} />
            </div>

            <div className="mb15" />

            <TruckItemTeamSlider truck={truck} />

            <div className="mb15" />

            <Button className={styles.button} onClick={handleAttachLoadToTruck}>
                Attach truck
            </Button>
        </div>
    );
};

export default withTruckReservingTimer(React.memo(TruckItem));
