import {WEB_SOCKET_TRUCKS_UPDATE_RECEIVED} from 'store/middlewares/mainAPISocketsIO/entities/trucks/actionTypes';
import * as types from './actionTypes';

const defaultState = {
    truckUpdatedByWebSocket: null,
    truckId: null,
};

export default function truckReserveModalReducer(state = defaultState, action) {
    switch (action.type) {
        case types.SET_TRUCK_ID: {
            const {id} = action.payload;
            return {
                ...state,
                truckId: id,
            };
        }

        case WEB_SOCKET_TRUCKS_UPDATE_RECEIVED: {
            const updatedTruck = action.payload;

            if (updatedTruck.id !== state.truckId) {
                return state;
            }

            return {
                ...state,
                truckUpdatedByWebSocket: updatedTruck,
            };
        }

        case types.CLEAR_STATE:
            return defaultState;

        default:
            return state;
    }
}
