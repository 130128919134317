import React from 'react';
import {Field} from 'redux-form';
import {normalizeMultipleSelect} from 'utils';
import Select from 'components/ui/Form/Select';
import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';
import citizenshipData from 'utils/data/citizenship';

export const citizenship = [
    {label: citizenshipData.citizen, value: citizenshipData.citizen},
    {label: citizenshipData.resident, value: citizenshipData.resident},
    {label: citizenshipData.NL.toUpperCase(), value: citizenshipData.NL},
    {label: citizenshipData.other, value: citizenshipData.other},
];

const Citizenship: React.FC = (): JSX.Element => {
    const {getTruckDriverCitizenshipFieldName} = getFormFieldNames();
    return (
        <Field
            label="Citizenship"
            name={getTruckDriverCitizenshipFieldName()}
            type="text"
            data={citizenship}
            component={Select}
            placeholder="choose or type the citizenship"
            normalize={normalizeMultipleSelect}
            closeMenuOnSelect={false}
            isMulti
            isCreatable
        />
    );
};
export default Citizenship;
