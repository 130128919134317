export const SET_PARTNERS_TRUCKS_DUPLICATES_MAP = 'TRUCKS_NEARBY/SET_PARTNERS_TRUCKS_DUPLICATES_MAP' as const;
export const CHANGE_TRUCKS_OWNING_TYPE = 'TRUCKS_NEARBY/CHANGE_TRUCKS_OWNING_TYPE' as const;
export const PAGINATION_CHANGED = 'TRUCKS_NEARBY/PAGINATION_CHANGED' as const;
export const SEARCH_FORM_RESET = 'TRUCKS_NEARBY/SEARCH_FORM_RESET' as const;
export const TRUCKS_SEARCH_TOGGLE = 'TRUCKS_NEARBY/SEARCH_TOGGLE' as const;
export const TRUCKS_NOT_FOUND = 'TRUCKS_NEARBY/TRUCKS_NOT_FOUND' as const;
export const PER_PAGE_CHANGE = 'TRUCKS_NEARBY/PER_PAGE_CHANGE' as const;

export const TOGGLE_MAP_SIZE = 'TRUCKS_NEARBY/TOGGLE_MAP_SIZE' as const;
export const COLLAPSE_MAP = 'TRUCKS_NEARBY/COLLAPSE_MAP' as const;
export const SHOW_ON_MAP = 'TRUCKS_NEARBY/SHOW_ON_MAP' as const;
export const EXPAND_MAP = 'TRUCKS_NEARBY/EXPAND_MAP' as const;

export const TRUCKS_TABLE_TOGGLE_ALL_ROWS = 'TRUCKS_NEARBY/TRUCKS_TABLE_TOGGLE_ALL_ROWS' as const;
export const TRUCKS_TABLE_TOGGLE_ROW = 'TRUCKS_NEARBY/TRUCKS_TABLE_TOGGLE_ROW' as const;

export const TRUCKS_TABLE_CLOSE_ALL_NOTES = 'TRUCKS_NEARBY/TRUCKS_TABLE_CLOSE_ALL_NOTES' as const;
export const TRUCKS_TABLE_OPEN_ALL_NOTES = 'TRUCKS_NEARBY/TRUCKS_TABLE_OPEN_ALL_NOTES' as const;
export const TRUCKS_TABLE_TOGGLE_NOTE = 'TRUCKS_NEARBY/TRUCKS_TABLE_TOGGLE_NOTE' as const;

export const TOGGLE_DISTANCE_AWAY_SORT = 'TRUCKS_NEARBY/TOGGLE_DISTANCE_AWAY_SORT' as const;

export const REMOVE_OPENED_INFOWINDOW = 'TRUCKS_NEARBY/REMOVE_OPENED_INFOWINDOW_BY_TRUCKID' as const;
export const REMOVE_ALL_OPENED_INFOWINDOWS = 'TRUCKS_NEARBY/REMOVE_ALL_OPENED_INFOWINDOWS' as const;
export const SET_TO_OPEN_INFOWINDOW = 'TRUCKS_NEARBY/SET_TO_OPEN_INFOWINDOW' as const;

export const DISPATCHER_NOTE_UPDATE = 'TRUCKS_NEARBY_DISPATCHER_NOTE_UPDATE' as const;

export const TRUCKS_NEARBY_SEARCH_RESULTS_CLEARED = 'TRUCKS_NEARBY/SEARCH_RESULTS_CLEARED' as const;
export const TRUCKS_NEARBY_STATE_CLEARED = 'TRUCKS_NEARBY/STATE_CLEARED' as const;

export const SET_TRUCKS_NEARBY_ORIGIN_DESTINATION_POINTS = 'TRUCKS_NEARBY/SET_TRUCKS_NEARBY_ORIGIN_DESTINATION_POINTS' as const;
export const SET_TRUCKS_NEARBY_SEARCH_PARAMS = 'TRUCKS_NEARBY/SET_TRUCKS_NEARBY_SEARCH_PARAMS' as const;

// new ↓
export const EXTERNAL_SEARCH_PARAMS_RECEIVED = 'TNB/EXTERNAL_SEARCH_PARAMS_RECEIVED' as const;
export const EXTERNAL_SEARCH_PARAMS_CLEARED = 'TNB/EXTERNAL_SEARCH_PARAMS_CLEARED' as const;
export const LOCATION_POINTS_RECEIVED = 'TNB/LOCATION_POINTS_RECEIVED' as const;
export const SEARCH_PARAMS_RECEIVED = 'TNB/SEARCH_PARAMS_RECEIVED' as const;
export const LIST_SORT_BY_RECEIVED = 'TNB/LIST_SORT_BY_RECEIVED' as const;
export const EXPANDED_IDS_RECEIVED = 'TNB/EXPANDED_IDS_RECEIVED' as const;
export const PAGINATION_RECEIVED = 'TNB/PAGINATION_RECEIVED' as const;
export const LIST_STATE_CLEARED = 'TNB/LIST_STATE_CLEARED' as const;
export const LIST_TAB_RECEIVED = 'TNB/LIST_TAB_RECEIVED' as const;
export const TRUCKS_RECEIVED = 'TNB/TRUCKS_RECEIVED' as const;
export const SSE_DATA_RECEIVED = 'SSE/TNB/DATA_RECEIVED' as const;
