import last from 'lodash/last';
import sortBy from 'lodash/sortBy';

import {PROGRESS_STEP_STATUSES as TRAVEL_ORDER_STATUSES} from 'core/entities/TravelOrder/constants/travel-order';

export function getTruckLastTravelOrder(truck) {
    const travelOrder = last(sortBy(truck.travel_orders, ['id']));
    if (!travelOrder) {
        return null;
    }
    return travelOrder;
}

export function getCurrentTravelOrder(truck) {
    if (!truck.travel_orders || !truck.travel_orders.length) {
        return undefined;
    }

    return truck.travel_orders.find(
        (travelOrder) =>
            travelOrder.status !== TRAVEL_ORDER_STATUSES.finished &&
            travelOrder.status !== TRAVEL_ORDER_STATUSES.dryRunConfirmed &&
            travelOrder.is_planned === false,
    );
}

export function getTruckLastPlannedTravelOrder(truck) {
    if (!truck.travel_orders || truck.travel_orders.length === 0) {
        return undefined;
    }

    const currentTravelOrder = getCurrentTravelOrder(truck);
    const lastTravelOrder = getTruckLastTravelOrder(truck);

    // for case when truck has several travel orders and on route to last of them these travel orders will be equal
    if (currentTravelOrder && lastTravelOrder && currentTravelOrder.id === lastTravelOrder.id) {
        return currentTravelOrder;
    }

    if (lastTravelOrder && lastTravelOrder.is_planned) {
        return lastTravelOrder;
    }

    return undefined;
}
