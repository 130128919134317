import {Pages} from 'types/Pages';

export const SETTLEMENTS_CARRIERS: Pages = 'settlements/carriers';
export const FACTORING_COMPANIES: Pages = 'factoring-companies';
export const SETTLEMENTS_OWNERS: Pages = 'settlements/owners';
export const TRUCKS_NEAR_BY: Pages = 'trucks-nearby';
export const FULL_ALLIANCE: Pages = 'full-alliance';
export const RELEASE_NOTES: Pages = 'release-notes';
export const TRIP_MONITOR: Pages = 'trip-monitor';
export const SETTLEMENTS: Pages = 'settlements';
export const DISPATCHERS: Pages = 'dispatchers';
export const FACILITIES: Pages = 'facilities';
export const MY_COMPANY: Pages = 'my-company';
export const CUSTOMERS: Pages = 'customers';
export const NOT_FOUND: Pages = 'not-found';
export const LOAD_BOARD: Pages = 'auction';
export const CARRIERS: Pages = 'carriers';
export const INVOICES: Pages = 'invoices';
export const LOGS: Pages = 'changes-log';
export const BROKERS: Pages = 'brokers';
export const DRIVERS: Pages = 'drivers';
export const REPORTS: Pages = 'reports';
export const TRUCKS_MAP: Pages = 'map';
export const TRUCKS: Pages = 'trucks';
export const OWNERS: Pages = 'owners';
export const LOADS: Pages = 'loads';
export const LOGIN: Pages = 'login';
export const MAP: Pages = 'map';

export const DEFAULT_PAGE: Pages = LOADS;
