import isEmpty from 'lodash/isEmpty';

import {GENERAL_TRUCK_TYPES} from 'core/entities/Truck/constants/truckTypes';
import {GENERAL_TRAILER_TYPES} from 'core/entities/Truck/constants/trailerTypes';

import Load from 'core/entities/Load/types';

const reeferTypes = [
    GENERAL_TRUCK_TYPES.BOX_TRUCK_REEFER,
    GENERAL_TRUCK_TYPES.STRAIGHT_TRUCK_REEFER,
    GENERAL_TRUCK_TYPES.REEFER_VAN,
    GENERAL_TRUCK_TYPES.ISOTHERM_TRUCK,
    GENERAL_TRAILER_TYPES.REEFER_TRAILER,
    GENERAL_TRAILER_TYPES.ISOTHERM,
];

export const isLoadHasTempControl = (load: Load): boolean => {
    const {trailerTypes, truckTypes} = load;

    const allTypes = truckTypes.concat(trailerTypes || []);

    const reeferOptions = reeferTypes.filter((type) => allTypes.includes(type));
    return !isEmpty(reeferOptions);
};
