import React from 'react';
import {NavLink} from 'react-router-dom';
import classNames from 'classnames';

type OwnProps = {
    pathTo: string;
    currentPath: string;
    icon: string;
    label: string;
};

const SidebarSingleItem: React.FC<OwnProps> = ({pathTo, currentPath, icon, label}) => (
    <li className="sublist-item">
        <NavLink
            to={pathTo}
            className={classNames('side-bar-menu-item', {'active-page': pathTo === currentPath})}
            activeClassName="active"
        >
            <i className={icon} />
            <span className="side-bar-menu-label">{label}</span>
        </NavLink>
    </li>
);

export default SidebarSingleItem;
