import type {ActionsTypes} from './actionCreators';
import * as types from './actionTypes';

interface AppState {
    mapApiLoaded: boolean;
    showLoader: boolean;
    isDataLoading: boolean;

    isCarriersFetching: boolean;
    isDispatchersFetching: boolean;
    isSendersFetching: boolean;

    countScheduledLoaders: number;
    // TODO: change to entity after creating types
    commonData: any;
}

const defaultState: AppState = {
    mapApiLoaded: false,
    showLoader: false,
    isDataLoading: false,

    isCarriersFetching: false,
    isDispatchersFetching: false,
    isSendersFetching: false,

    countScheduledLoaders: 0,
    commonData: {
        dispatchers: [],
        facilities: undefined,
        carriers: undefined,
        customers: [],
        owners: undefined,
    },
};

export default function app(state = defaultState, action: ActionsTypes): AppState {
    switch (action.type) {
        case types.MAP_API_LOADED:
            return {
                ...state,
                mapApiLoaded: true,
            };

        case types.GET_COMMON_DATA:
            return {
                ...state,
                commonData: {
                    ...state.commonData,
                    [action.payload.entityName]: action.payload.data,
                },
            };

        case types.COMMON_DATA_ENTITY_CREATED: {
            const createdEntityName = action.payload.entityName;
            const createdEntity = action.payload.data;
            const currentEntityData = state.commonData[createdEntityName];
            if (!currentEntityData) {
                return state;
            }

            return {
                ...state,
                commonData: {
                    ...state.commonData,
                    [action.payload.entityName]: [...currentEntityData, createdEntity],
                },
            };
        }

        case types.START_FETCHING_CARRIERS_COMMON_DATA:
            return {
                ...state,
                isCarriersFetching: true,
            };

        case types.FINISH_FETCHING_CARRIERS_COMMON_DATA:
            return {
                ...state,
                isCarriersFetching: false,
            };

        case types.START_FETCHING_DISPATCHERS_COMMON_DATA:
            return {
                ...state,
                isDispatchersFetching: true,
            };

        case types.FINISH_FETCHING_DISPATCHERS_COMMON_DATA:
            return {
                ...state,
                isDispatchersFetching: false,
            };

        case types.SHOW_LOADER:
            return {
                ...state,
                showLoader: true,
                countScheduledLoaders: state.countScheduledLoaders + 1,
            };

        case types.HIDE_LOADER: {
            const countRemainingLoaders = state.countScheduledLoaders - 1;
            if (countRemainingLoaders > 0) {
                return {
                    ...state,
                    countScheduledLoaders: countRemainingLoaders,
                };
            }
            return {
                ...state,
                showLoader: false,
                countScheduledLoaders: 0,
            };
        }

        case types.START_LOADING_DATA:
            return {
                ...state,
                isDataLoading: true,
            };

        case types.END_LOADING_DATA:
            return {
                ...state,
                isDataLoading: false,
            };

        default:
            return state;
    }
}
