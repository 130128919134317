import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import first from 'lodash/first';

import {FileNew as StoredFile} from 'types/File';

import Load from '../../types';

const sort = (files: StoredFile[]): StoredFile[] => {
    return sortBy(files, ['documentNumber', 'documentPage']);
};

const groupByDocumentNumber = (files: StoredFile[]): Record<any, StoredFile[]> => {
    return groupBy(files, 'documentNumber');
};

export const formatRCFiles = (files: StoredFile[]): StoredFile[][] => {
    const sortedFiles = sort(files);
    const groupedFiles = groupByDocumentNumber(sortedFiles);
    return Object.values(groupedFiles);
};

export const getLoadRCFile = (load?: Load): StoredFile[][] => {
    const loadFiles = groupBy(load?.files, 'attachType');
    return formatRCFiles(loadFiles?.rc || []);
};

export const getLoadLastRCFile = (load: Load): StoredFile | undefined => {
    const loadFiles = getLoadRCFile(load);
    return first(last(loadFiles));
};
