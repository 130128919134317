import {FIELDS} from 'core/entities/Customer/constants/fields';
import {getCityLineFromObject} from 'utils/getCityLine';

/**
 *
 * @param {Object} customer
 * @param {string} customer.billing_city
 * @param {string} customer.billing_state
 * @param {string} customer.billing_zip
 * @param {string} customer.billing_country
 * @returns {string}
 */
export const composeCustomerBillingCityLine = (customer): string =>
    getCityLineFromObject({
        city: customer[FIELDS.billing_city],
        state: customer[FIELDS.billing_state],
        zip: customer[FIELDS.billing_zip],
        country: customer[FIELDS.billing_country],
    });
