import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import omit from 'lodash/omit';

import Pagination from 'types/Pagination';
import {InferActions} from 'store';
import Carrier from 'core/entities/Carrier/types';

import * as types from '../actionTypes';
import * as actionCreators from '../actionCreators';

export interface ListState {
    currentCarrier: Carrier | null;
    allCarriers: Carrier[];
    pagination: Pagination;
    searchParams: any;
    items: any;
}

export const defaultState: ListState = {
    currentCarrier: null,
    allCarriers: [],
    items: {
        byId: {},
        ids: [],
    },
    pagination: {
        currentPage: 1,
        pagesCount: 0,
        perPage: 50,
        totalItemsCount: 0,
    },
    searchParams: {},
};

const makePagination = ({items, perPage, currentPage}) => ({
    currentPage,
    pagesCount: Math.ceil(items.length / perPage),
    perPage,
    totalItemsCount: items.length,
});

const splitToHashAndIds = (items) => ({
    byId: keyBy(items, 'id'),
    ids: items.map((item) => item.id),
});

type ListActionCreators = 'receiveCarriers' | 'archiveCarrier' | 'setPagination' | 'setSearchParams' | 'clearState';
type ActionsTypes = InferActions<Pick<typeof actionCreators, ListActionCreators>>;

export default function carriersReducer(state = defaultState, action: ActionsTypes): ListState {
    switch (action.type) {
        case types.RECEIVED_CARRIERS: {
            const {
                pagination: {perPage, currentPage},
            } = state;
            const {current, carriers} = action.payload;

            const pagination = makePagination({items: carriers, perPage, currentPage});
            const itemsOnPage = carriers.slice(0, perPage);

            return {
                ...state,
                currentCarrier: current,
                allCarriers: carriers,
                pagination,
                items: splitToHashAndIds(itemsOnPage),
            };
        }

        case types.CARRIER_ARCHIVED: {
            const {carrier} = action.payload;
            return {...state, items: {...state.items, byId: omit(state.items.byId, [carrier.id])}};
        }

        case types.SET_PAGINATION: {
            const {allCarriers} = state;
            const {
                pagination: {currentPage, perPage},
            } = action.payload;

            if (perPage === 0) {
                const pagination = {
                    currentPage: 1,
                    pagesCount: 1,
                    perPage: 0,
                };

                return {
                    ...state,
                    items: splitToHashAndIds(allCarriers),
                    // @ts-ignore
                    pagination,
                };
            }

            const to = currentPage * perPage;
            const from = to - perPage;
            const itemsOnPage = state.allCarriers.slice(from, to);

            const pagination = makePagination({items: allCarriers, perPage, currentPage});

            return {
                ...state,
                items: splitToHashAndIds(itemsOnPage),
                pagination,
            };
        }

        case types.SET_SEARCH_PARAMS: {
            const {searchParams} = action.payload;
            return {
                ...state,
                searchParams,
            };
        }

        case types.CLEAR_STATE: {
            return defaultState;
        }

        // no default
    }

    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
