import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';

import {getTravelOrderStopByID, getTravelOrderStops} from 'core/entities/TravelOrder/modules/travelOrderStops';
import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';
import TravelOrder from 'core/entities/TravelOrder/types';

const getTravelOrderStopBOLs = (stop?: TravelOrderStop): TravelOrderStop['bols'] => stop?.bols || [];

export const getAllBOLsFromTheTOStops = (travelOrder?: TravelOrder | null): TravelOrderStop['bols'] => {
    const toStops = getTravelOrderStops(travelOrder);

    const bols = toStops.map((stop) => stop?.bols || []);

    return flatten(bols);
};

export const getAllNotSignedBOLsFromTheTOStops = (travelOrder?: TravelOrder | null): TravelOrderStop['bols'] => {
    const bols = getAllBOLsFromTheTOStops(travelOrder);

    const notSignedBOLs = bols.filter((bol) => isEmpty(bol.pods));

    return notSignedBOLs;
};

export const getTravelOrderStopBOLsByID = (params: StopInfoParams): TravelOrderStop['bols'] =>
    getTravelOrderStopBOLs(getTravelOrderStopByID(params));
