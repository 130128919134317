import React from 'react';
import {Field} from 'redux-form';
import DropDownRadioInput from 'components/ui/Form/DropDownRadioInput';
import {signsItems} from 'core/entities/Truck/constants/selectInputOptions';
import getFormFieldNames from 'pages/LoadBoard/components/modals/CreateQuoteModal/components/Form/utils/getFormFieldNames';

const Signs: React.FC = () => {
    const {getTruckSignsFieldName} = getFormFieldNames();

    return <Field name={getTruckSignsFieldName()} component={DropDownRadioInput} btnTitle="Signs" items={signsItems} />;
};
export default Signs;
