import TravelOrder from 'core/entities/TravelOrder/types';

import restApi from 'services/restapi';

export const fetchTravelOrderList = ({requestBody}) => {
    return restApi.post('/v2/travel-order/trip-monitor', requestBody);
};

export const setTravelOrderPayRequest = ({toNumber, loadNumber, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/load/${loadNumber}/set-pay`, requestBody);
};

export const setTravelOrderDryRunRequest = ({travelOrderNumber, requestBody}) => {
    return restApi.put(`/travel-order/${travelOrderNumber}/dryrun`, requestBody);
};

export const editTravelOrderPayRequest = ({toNumber, loadNumber, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/load/${loadNumber}/update-pay`, requestBody);
};

export const generateTravelOrderRateConfirmationRequest = ({toNumber}) => {
    return restApi.post(`/travel-order/${toNumber}/generate-rc`);
};

export const fetchTravelOrder = (toNumber: string | number) => {
    return restApi.get<TravelOrder>(`/travel-order/${toNumber}`);
};

export const fetchNextPlannedTOByTruckIdAndTravelOrderId = ({
    toNumber,
    truckId,
}: {
    toNumber: string | number;
    truckId: string | number;
}) => {
    return restApi.get(`/trucks/${truckId}/travel-order/${toNumber}/next-travel-order-by-number`);
};

export const checkInTravelOrderRequest = ({toNumber, stopNumber, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/stop/${stopNumber}/check-in`, requestBody);
};

export const addTravelOrderBOLRequest = ({toNumber, stopNumber, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/stop/${stopNumber}/add-bol`, requestBody);
};

export const addTravelOrderBOLFilesRequest = ({toNumber, stopID, requestBody}) => {
    return restApi.post(`/travel-order/${toNumber}/stop/${stopID}/bol/files`, requestBody, {
        headers: {'Content-Type': 'multipart/form-data'},
    });
};

export const addTravelOrderPODFilesRequest = ({toNumber, stopNumber, podID, requestBody}) => {
    return restApi.post(`/travel-order/${toNumber}/stop/${stopNumber}/pod/${podID}/files`, requestBody, {
        headers: {'Content-Type': 'multipart/form-data'},
    });
};

export const addTravelOrderPODRequest = ({toNumber, stopNumber, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/stop/${stopNumber}/sign-pod`, requestBody);
};

export const addAllTravelOrderPODsRequest = ({toNumber, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/sign-empty-bols-before-finish`, requestBody);
};

export const checkOutTravelOrderRequest = ({toNumber, stopNumber, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/stop/${stopNumber}/check-out`, requestBody);
};

export const finishTravelOrderRequest = ({toNumber, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/finish`, requestBody);
};

export const editTravelOrderDeadheadRequest = ({toNumber, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/edit-deadhead`, requestBody);
};

export const editTravelOrderCheckInRequest = ({toNumber, stopID, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/stop/${stopID}/edit-check-in`, requestBody);
};

export const editTravelOrderCheckOutRequest = ({toNumber, stopID, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/stop/${stopID}/edit-check-out`, requestBody);
};

export const editTravelOrderBOLRequest = ({toNumber, stopID, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/stop/${stopID}/edit-bol`, requestBody);
};

export const editTravelOrderPODsRequest = ({toNumber, stopID, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/stop/${stopID}/edit-pod`, requestBody);
};

export const editTravelOrderDryRunRequest = ({travelOrderNumber, requestBody}) => {
    return restApi.put(`/travel-order/${travelOrderNumber}/dryrun/edit`, requestBody);
};

export const cancelAddBOLRequest = ({toNumber, stopID}) => {
    return restApi.put(`/travel-order/${toNumber}/stop/${stopID}/cancel-bol`);
};

export const cancelCheckInRequest = ({toNumber, stopID}) => {
    return restApi.put(`/travel-order/${toNumber}/stop/${stopID}/cancel-check-in`);
};

export const cancelCheckOutRequest = ({toNumber, stopID}) => {
    return restApi.put(`/travel-order/${toNumber}/stop/${stopID}/cancel-check-out`);
};

export const cancelSignPODRequest = ({toNumber, stopID}) => {
    return restApi.put(`/travel-order/${toNumber}/stop/${stopID}/cancel-pod`);
};

export const cancelFinishTravelOrderRequest = ({toNumber}) => {
    return restApi.put(`/travel-order/${toNumber}/cancel-finish-confirm`);
};

export const cancelTravelOrderRequest = ({toNumber, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/cancel`, requestBody);
};

export const cancelTravelOrderDryRunRequest = ({travelOrderNumber}) => {
    return restApi.put(`/travel-order/${travelOrderNumber}/dryrun/cancel`);
};

export const fetchLastLocationOfThePreviousTravelOrder = ({toNumber, truckNumber}) => {
    return restApi.get(`/trucks/${truckNumber}/travel-order/${toNumber}/last-location-previous-travel-order`);
};

export const confirmTravelOrderDryRunRequest = ({travelOrderNumber, requestBody}) => {
    return restApi.put(`/travel-order/${travelOrderNumber}/dryrun/confirm`, requestBody);
};

export const addTravelOrderNoteRequest = ({travelOrderNumber, requestBody}) => {
    return restApi.put(`/travel-order/${travelOrderNumber}/note`, requestBody);
};

export const getLatestTravelOrderEmailSubjectRequest = (travelOrderNumber) => {
    return restApi.get(`/v2/travel-order/${travelOrderNumber}/email/subject`);
};

export const emailAttachmentsRequest = ({travelOrderNumber, requestBody}) => {
    return restApi.post(`/v2/travel-order/${travelOrderNumber}/email`, requestBody);
};

export const addTravelOrderAttachmentsMetaRequest = ({toNumber, requestBody}) => {
    return restApi.post(`/travel-order/${toNumber}/files/meta`, requestBody);
};

export const addTravelOrderOtherAttachmentsMetaRequest = ({toNumber, requestBody}) => {
    return restApi.post(`/travel-order/${toNumber}/files/other/meta`, requestBody);
};

export const addTravelOrderAttachmentsRequest = ({toNumber, requestBody}) => {
    return restApi.post(`/travel-order/${toNumber}/files`, requestBody, {
        headers: {'Content-Type': 'multipart/form-data'},
    });
};

export const addTravelOrderOtherAttachmentsRequest = ({toNumber, requestBody}) => {
    return restApi.post(`/travel-order/${toNumber}/files/other`, requestBody, {
        headers: {'Content-Type': 'multipart/form-data'},
    });
};

export const setTravelOrderEventTimerRequest = ({toNumber, requestBody}) => {
    return restApi.post(`/v2/travel-order/${toNumber}/timers`, requestBody);
};

export const setToggleThirdApiRequestSendingStatus = ({toNumber, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/toggle-third-api-sending-status`, requestBody);
};

// dispatch ↓

export const dispatchTravelOrderRequest = ({toNumber, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/dispatch`, requestBody);
};

export const confirmDispatchTravelOrderRequest = ({toNumber, requestBody}) => {
    return restApi.put(`/travel-order/${toNumber}/dispatch/confirm`, requestBody);
};

// redispatch ↓

export const fetchRedispatchTravelOrder = (toNumber: string) => {
    return restApi.get(`/v2/travel-order/${toNumber}/redispatch`);
};

export const reDispatchTravelOrderRequest = ({toNumber, loadNumber, requestBody}) => {
    return restApi.put(`/v2/travel-order/${toNumber}/load/${loadNumber}/redispatch`, requestBody);
};

export const directReDispatchTravelOrderRequest = ({toNumber, loadNumber, requestBody}) => {
    return restApi.put(`/v2/travel-order/${toNumber}/load/${loadNumber}/redispatch-rc`, requestBody);
};

export const confirmReDispatchTravelOrderRequest = ({toNumber, requestBody}) => {
    return restApi.put(`/v2/travel-order/${toNumber}/redispatch/confirm`, requestBody);
};

export const fetchTravelOrderDispatchMessage = (params) => {
    return restApi.post(`/v2/travel-order/${params.toNumber}/redispatch/message`, params.requestBody);
};
