import React from 'react';

import {TravelOrderLink} from 'components/ui/Links';

import styles from './styles.module.scss';

const VehicleInfo: React.FC<{travelOrderNumber: number}> = ({travelOrderNumber}) => {
    return (
        <div className={styles.wrap}>
            <div className={styles.icon}>
                <i className="fa fa-truck" />
            </div>
            <div className={styles.info}>
                <div className={styles.title}>Truck Info</div>
                <div className={styles.description}>
                    Adding a check call to{' '}
                    <TravelOrderLink
                        travelOrder={{number: travelOrderNumber}}
                        content={`TO#${travelOrderNumber}`}
                        className="second-link"
                    />
                </div>
            </div>
        </div>
    );
};

export default VehicleInfo;
