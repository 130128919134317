import sum from 'lodash/sum';

import Load from 'core/entities/Load/types';

const getRouteDistance = (load?: Load): number[] | undefined => load?.route?.distance;

const isNumber = (value: any): value is number => {
    return Number.isInteger(value);
};

export const getTotalDistance = (load?: Load): number => {
    const distance = getRouteDistance(load);
    if (!distance) {
        return 0;
    }
    if (isNumber(distance)) {
        return distance;
    }
    return sum(distance);
};

export const getDistanceSegments = (load?: Load): number[] => {
    const distance = getRouteDistance(load);
    if (!distance) {
        return [];
    }
    if (isNumber(distance)) {
        return [distance];
    }
    return distance;
};
