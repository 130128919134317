import React from 'react';
import classNames from 'classnames';

import {lengthView} from 'utils/lengthConverter';

import styles from './styles.module.scss';

type OwnProps = {
    height?: number | string;
    length?: number | string;
    width?: number | string;
    units?: string | null;
    skipLength?: boolean;
    skipHeight?: boolean;
    skipWidth?: boolean;
    className?: string;
};

const LengthView: React.FC<OwnProps> = (props) => {
    const {length, width, height, units, skipLength, skipWidth, skipHeight, className} = props;

    const dims: string[] = [];

    if (!skipLength) {
        dims.push(lengthView(units, length));
    }

    if (!skipWidth) {
        dims.push(lengthView(units, width));
    }

    if (!skipHeight) {
        dims.push(lengthView(units, height));
    }

    return (
        <div className={styles.wrap}>
            <div className={className}>{dims.join(' x ')}</div>
        </div>
    );
};

export default LengthView;
