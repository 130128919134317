import React from 'react';
import {Route, RouteProps} from 'react-router-dom';

import {PermissionsValues} from 'core/entities/Permissions';

import AnonymousRoute from '../AnonymousRoute';
import PrivateRoute from '../PrivateRoute';

type Private = {
    type: 'private';
    permission: PermissionsValues;
};

type Public = {
    type: 'public';
};

type Anonymous = {
    type: 'anonymous';
};

type OwnProps = Private | Public | Anonymous;

const AppRoute: React.FC<RouteProps & OwnProps> = (props) => {
    const {type, ...restProps} = props;

    if (type === 'private') {
        return <PrivateRoute {...restProps} permission={props.permission} />;
    }

    if (type === 'anonymous') {
        return <AnonymousRoute {...restProps} />;
    }

    return <Route {...restProps} />;
};

export default AppRoute;
