import React from 'react';

import {createDate} from 'utils/dateTime';
import certificates from 'utils/data/certificates';

import GeneralBadge from '../GeneralBadge';

const CertificatesBadge = ({driver, ...rest}) => {
    const formatDate = (date) => date && createDate(date, {timeZoneFrom: 'utc'}).date;
    if (
        !driver.adr_certificate &&
        !driver.mi_log_certificate &&
        !driver.loi_macron_certificate &&
        !driver.tanker_endorsement &&
        !driver.twic_certificate &&
        !driver.tsa_certificate &&
        !driver.hazmat_certificate
    ) {
        return null;
    }

    const tooltip = (
        <>
            {driver.adr_certificate && (
                <div>
                    {certificates.adr.title} {formatDate(driver.adr_expire)}
                </div>
            )}
            {driver.mi_log_certificate && (
                <div>
                    {certificates.mi_log.title} {formatDate(driver.mi_log_issue)}
                    {driver.mi_log_expire && ` - ${formatDate(driver.mi_log_expire)}`}
                </div>
            )}
            {driver.loi_macron_certificate && (
                <div>
                    {certificates.loi_macron.title} {formatDate(driver.loi_macron_issue)}
                    {driver.loi_macron_expire && ` - ${formatDate(driver.loi_macron_expire)}`}
                </div>
            )}
            {driver.hazmat_certificate && (
                <div>
                    {certificates.hazmat.title} {formatDate(driver.hazmat_since)}
                    {driver.hazmat_exp && ` - ${formatDate(driver.hazmat_exp)}`}
                </div>
            )}
            {driver.tsa_certificate && (
                <div>
                    {certificates.tsa.title} {formatDate(driver.tsa_since)}
                    {driver.tsa_exp && ` - ${formatDate(driver.tsa_exp)}`}
                </div>
            )}
            {driver.twic_certificate && (
                <div>
                    {certificates.twic.title} {formatDate(driver.twic_since)}
                    {driver.twic_exp && ` - ${formatDate(driver.twic_exp)}`}
                </div>
            )}
            {driver.tanker_endorsement && <div>{certificates.tanker_endorsement.title}</div>}
        </>
    );

    return <GeneralBadge text={<i className="fa fa-bookmark" />} tooltip={tooltip} {...rest} tooltipPosition="right" />;
};

export default CertificatesBadge;
