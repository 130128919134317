import {EMPTY_DATE, EMPTY_TIME} from 'utils/validation/errorMessages';

export const validateTime = (value) => {
    if (!value) {
        return EMPTY_TIME;
    }
};

export const validateDate = (value) => {
    if (!value) {
        return EMPTY_DATE;
    }
};
