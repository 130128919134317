import React from 'react';
import Button from 'components/ui/Buttons/Button';

import styles from './styles.module.scss';

interface OwnProps {
    onToggle(): void;
    open: boolean;
    trucksLength: number;
}

const TableLabel: React.FC<OwnProps> = (props) => {
    const {onToggle, trucksLength, open} = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.expand}>
                <span className={styles.label}>
                    SEND QUOTE TO <strong>{trucksLength}</strong> DRIVERS:
                </span>
                <Button
                    onClick={onToggle}
                    buttonIcon={open ? 'angle-up' : 'angle-down'}
                    buttonSize="icon"
                    className="btn-link"
                />
            </div>

            <div>
                <span className={styles.info}>
                    <i className="fa fa-info-circle" />
                </span>{' '}
                Quote will only be sent to truck when driver is <strong>main contact</strong> person
            </div>
        </div>
    );
};

export default TableLabel;
