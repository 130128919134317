import React, {useMemo} from 'react';
import classNames from 'classnames';

import {PayRecord, SettlementOwner} from 'core/entities/Settlement/types';

import useFetchPayRecordSettlements from 'pages/Settlements/hooks/useFetchPayRecordSettlements';
import DataLoading from 'pages/Settlements/components/common/DataLoading';
import PayRecordTotalInfo from 'pages/Settlements/components/common/PayRecordTotalInfo';

import {extendPayRecordPayments} from '../utils';
import SettlementsList from './SettlementsList';

interface Props {
    newSettlement: SettlementOwner;
    existsPayRecord: PayRecord;
    isExpanded: boolean;
}

const AddToExistsPayRecord = ({isExpanded, newSettlement, existsPayRecord}: Props): JSX.Element => {
    const payRecordEmail = existsPayRecord.email;
    const {settlements, isFetching, isAllFetched, fetchNextPage} = useFetchPayRecordSettlements({
        payRecordID: existsPayRecord.id,
        useSearchParams: false,
    });
    const allSettlements = useMemo(() => {
        return newSettlement ? [newSettlement, ...settlements] : settlements;
    }, [newSettlement, settlements]);
    const containerClassName = classNames('main__settlements_list', {open: isExpanded});
    // we should add currency from new settlement to exists pay record for success calculate total payments
    const extendedPayRecord = extendPayRecordPayments(existsPayRecord, {
        amount: newSettlement.total_pay,
        currency: newSettlement.currency,
    });
    return (
        <div className="main">
            <div className={containerClassName}>
                <SettlementsList settlements={allSettlements} newSettlementID={newSettlement.id} />

                <br />

                <DataLoading
                    isLoading={isFetching}
                    isAllLoaded={isAllFetched}
                    loadMoreTitle="load more settlements"
                    onLoadMore={fetchNextPage}
                />
            </div>

            <PayRecordTotalInfo payRecord={extendedPayRecord} isExpanded={isExpanded}>
                {payRecordEmail}
            </PayRecordTotalInfo>
        </div>
    );
};

export default AddToExistsPayRecord;
