import React, {useMemo} from 'react';

import {SettlementOwner} from 'core/entities/Settlement/types';

import {getDistanceSettings} from 'utils/distance';

import SettlementRow from './SettlementRow';

interface Props {
    settlements: SettlementOwner[];
    newSettlementID?: number;
}

const SettlementsList = ({settlements, newSettlementID}: Props): JSX.Element => {
    const rows = useMemo(() => {
        return settlements.map((s) => <SettlementRow key={s.id} settlement={s} isNew={s.id === newSettlementID} />);
    }, [settlements]);

    const distanceSettings = getDistanceSettings();

    return (
        <table className="standard-table-style settlements-group-table">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Truck and Driver</th>
                    <th>Origin - Destination</th>
                    <th>{distanceSettings.longUnit}</th>
                    <th>Check OUT info</th>
                    <th>Payment</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
    );
};

export default SettlementsList;
