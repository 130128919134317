import React from 'react';
import DistanceValue from 'pages/LoadBoard/components/common/DistanceValue';

const Radius: React.FC<{value: number}> = ({value}) => {
    return (
        <div className="w-100">
            <h6 className="font-weight-light">
                Radius search:{' '}
                <span className="font-weight-bold">
                    <DistanceValue distance={value} withUnit />
                </span>
            </h6>
        </div>
    );
};

export default Radius;
