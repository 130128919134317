import React from 'react';
import {Modal} from 'react-bootstrap';

import {Company} from 'core/entities/Settlement/types';

import Form from './Form';

interface OwnProps {
    data: {companies: Company[]};
    handlers: {onSubmitBatch()};
    onClose();
}

const SetupPayPeriodModal = ({data: {companies}, handlers: {onSubmitBatch}, onClose}: OwnProps): JSX.Element => {
    return (
        <Modal show={true} onHide={onClose} dialogClassName="standard-form-modal" size="sm" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Setup Batch</Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form companies={companies} onSubmit={onSubmitBatch} onClose={onClose} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default React.memo(SetupPayPeriodModal);
