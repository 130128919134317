import React, {useCallback} from 'react';
import {FormGroup, FormLabel} from 'react-bootstrap';
import AsyncPaginate from 'react-select-async-paginate';
import {WrappedFieldInputProps, WrappedFieldMetaProps} from 'redux-form';

import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';
import MenuList from 'components/ui/ReactWindowWrapper';

import {handleKeyDown} from 'utils/form/handleKeyDown';

import getValidationState from '../getValidationState';

import {useLoadOptionsSettings} from './hooks';

import styles from '../Select/styles.module.scss';

const DEBOUNCE_TIMEOUT = 250;

interface OwnProps {
    formInput: WrappedFieldInputProps;
    formMeta: WrappedFieldMetaProps;
    placeholder?: string;
    label?: string | JSX.Element;
    optionHeight?: number;
    isDisabled?: boolean;
    isClearable?: boolean;
    isDisplayLabelOnlyName?: boolean;
    inputId?: any;
    loadOptions: {
        getInitialOption?: () => Promise<any>;
        getNextOptions(params: any): Promise<any>;
        formatNextOptions(params: any);
    };
    onSelectOption(params: any);
}

const AsyncSelect = (props: OwnProps): JSX.Element => {
    const {
        formInput,
        formMeta,
        placeholder = '',
        label = '',
        optionHeight = 0,
        isDisabled = false,
        isClearable = true,
        isDisplayLabelOnlyName = false,
        loadOptions,
        onSelectOption,
        inputId,
    } = props;

    const {loadSettings, currentOption, setCurrentOption} = useLoadOptionsSettings({
        formInput: {value: formInput.value},
        getInitialOption: loadOptions.getInitialOption,
        getNextOptions: loadOptions.getNextOptions,
        formatNextOptions: loadOptions.formatNextOptions,
        isDisplayLabelOnlyName,
    });

    const handleSelectOption = useCallback((option) => {
        const optionToSelect = isDisplayLabelOnlyName ? {...option, label: option?.value?.name} : option;

        if (onSelectOption) {
            setCurrentOption(optionToSelect);
            onSelectOption(optionToSelect);
        }
    }, []);

    const selectStyles = {
        option: (optionStyles, optionProps) => ({
            ...optionStyles,
            ...(optionProps?.isDisabled ? {pointerEvents: 'none'} : {}),
        }),
    };

    const validState = getValidationState(formMeta);
    const validStateCls = !validState ? styles.error : '';
    const MenuListForSelect = (menuListProps): JSX.Element => <MenuList itemHeight={optionHeight} {...menuListProps} />;

    const SelectComponent = (): JSX.Element => (
        <AsyncPaginate
            value={currentOption}
            debounceTimeout={DEBOUNCE_TIMEOUT}
            loadOptions={loadSettings}
            additional={{
                page: 1,
            }}
            onChange={handleSelectOption}
            onKeyDown={handleKeyDown}
            components={{
                MenuList: MenuListForSelect,
            }}
            inputId={inputId}
            ignoreAccents={false} // https://github.com/JedWatson/react-select/issues/3128
            placeholder={placeholder}
            className={`select ${styles.select_component} ${validStateCls}`}
            cacheUniq={true}
            isDisabled={isDisabled}
            isClearable={isClearable}
            styles={selectStyles}
        />
    );

    return (
        <FormGroup
            controlId="formControlsSelect"
            className={`${!validState ? 'has-error' : ''} ${isDisabled ? 'has-disabled' : ''}`}
        >
            {label && <FormLabel>{label}</FormLabel>}
            <OverlayTriggerCustom inputMeta={formMeta} placement="bottom">
                <SelectComponent />
            </OverlayTriggerCustom>
        </FormGroup>
    );
};

export default AsyncSelect;
