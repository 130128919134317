import React from 'react';

import Batch from 'core/entities/Invoice/types/Batch';
import {composeCustomerBillingCityLine} from 'core/entities/Customer/composeCustomerCityLine';

interface OwnProps {
    batch: Batch;
}

const BatchCustomerAddress = (props: OwnProps): JSX.Element => {
    const {batch} = props;
    const isBatchCustomerBillingInfo = batch?.customer?.billing_address_line;
    return (
        <div>
            {isBatchCustomerBillingInfo ? (
                <>
                    <span>{batch?.customer?.billing_address_line}</span>
                    <br />
                    {batch?.customer?.billing_additional_address_line && (
                        <span>
                            {batch?.customer?.billing_additional_address_line}
                            <br />
                        </span>
                    )}
                    <span>{composeCustomerBillingCityLine(batch?.customer)}</span>
                </>
            ) : (
                '-'
            )}
        </div>
    );
};

export default BatchCustomerAddress;
