import {SendDataType, FileType} from '../types';

const emailText = (email: string, subject: string, message: string, name: string) => {
    return `
    ${subject}    
    —
    
    Получен фидбек от пользователя ${name}
    Связаться с пользователем по указанному e-mail ${email}
    
    —
    ${message}
    —
    `;
};

export const transformSupportMessageData = (data: SendDataType & {name: string}) => {
    const {email, subject, message, files, name} = data;

    const formData = new FormData();

    formData.append('subject', subject.trim());
    formData.append('body', emailText(email.trim(), subject.trim(), message.trim(), name));

    if (files?.length) {
        files.forEach((file) => {
            formData.append('attachments[]', file.file);
        });
    }

    return formData;
};

const maxFileSize = 2e7;
const attachmentFilesLength = 9;

export const attachmentSizeError = (files: FileType[] | undefined) =>
    files?.length ? Boolean(files.filter((item) => item.file.size > maxFileSize).length) : false;

export const attachmentLength = (files: FileType[] | undefined) =>
    files?.length ? files.length > attachmentFilesLength : false;
