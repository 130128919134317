import {InferActions} from 'store';

import {Facility} from 'core/entities/Facility/types';

import * as types from '../actionTypes';
import * as actionCreators from '../actionCreators';

interface CurrentFacility {
    current: Facility;
}

type CurrentReducerActions = Pick<
    typeof actionCreators,
    'receiveFacility' | 'archiveFacility' | 'receiveGeneralNote' | 'clearState'
>;
type ActionsTypes = InferActions<CurrentReducerActions>;

export const defaultState: CurrentFacility = {
    current: {
        id: null,
        name: '',
        latitude: null,
        longitude: null,
        timeZone: '',
        phone: '',
        fax: '',
        contact: '',
        email: '',
        note: '',
        working_days: '',
        is_active: false,
        is_deleted: false,
        address_line: '',
        additional_address_line: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        working_hours_from: '',
        working_hours_to: '',

        files: [],
        generalNotes: [],
    },
};

export default function currentReducer(state = defaultState, action: ActionsTypes): CurrentFacility {
    switch (action.type) {
        case types.RECEIVED_FACILITY: {
            return {
                ...state,
                current: action.payload,
            };
        }

        case types.FACILITY_ARCHIVED: {
            const {facility: archivedFacility} = action.payload;
            if (!state.current) {
                return state;
            }
            return {
                ...state,
                current: archivedFacility,
            };
        }

        case types.FACILITY_GENERAL_NOTE_RECEIVED: {
            return {
                ...state,
                current: {
                    ...state.current,
                    generalNotes: [action.payload, ...state.current.generalNotes],
                },
            };
        }

        case types.FACILITY_CLEAR_STATE:
            return defaultState;

        // no default
    }

    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
