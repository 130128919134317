import isUndefined from 'lodash/isUndefined';
import toNumber from 'lodash/toNumber';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';

import {AddTruckLocationFormValues} from 'components/ui/modals/AddTruckLocationModal/types';

import * as truckStatuses from 'core/entities/Truck/constants/truckStatuses';
import Truck from 'core/entities/Truck/types';

import {MAX_COUNT_TRUCKS_IN_ONE_PAGE} from 'pages/Loads/constants/loadConstants';
import {TrucksSearchFormValues} from 'pages/Loads/types/formTypes';
import {AttachState} from 'pages/Loads/redux/reducers/attach';

import {convertDate} from 'utils/dateTime';

import Pagination from 'types/Pagination';

export const handleReducerBySetTrucksPagination = (params: {state: AttachState; pagination: Partial<Pagination>}) => {
    const {state, pagination} = params;

    return {
        ...state,
        pagination: {...state.pagination, trucks: {...state.pagination.trucks, ...pagination}},
    };
};

export const handleReducerByReceivedTrucks = (params: {
    state: AttachState;
    pagination?: Pagination;
    trucks: AttachState['trucks'];
    isAvailableMoreTrucks?: boolean;
    searchParams: AttachState['searchParams']['trucks'];
}) => {
    const {state, trucks, searchParams, isAvailableMoreTrucks, pagination} = params;

    return {
        ...state,
        trucks,
        isAvailableMoreTrucks: Boolean(isAvailableMoreTrucks),
        searchParams: {...state.searchParams, trucks: searchParams},
        pagination: {...state.pagination, trucks: {...state.pagination.trucks, ...pagination}},
    };
};

export const handleReducerByReceivedMoreTrucks = (params: {
    state: AttachState;
    isAvailableMoreTrucks: boolean;
    additionalTrucks: AttachState['trucks'];
}) => {
    const {state, additionalTrucks, isAvailableMoreTrucks} = params;

    return {
        ...state,
        isAvailableMoreTrucks,
        trucks: [...state.trucks, ...additionalTrucks],
    };
};

export const transformTrucksSearchDataToRequestBody = (params: {filters: Partial<TrucksSearchFormValues>}) => {
    const {filters} = params;

    const truckStatusesForAttachToLoad = [
        truckStatuses.TRUCK_STATUS_WILL_BE_AVAILABLE_AT,
        truckStatuses.TRUCK_STATUS_NOT_AVAILABLE,
        truckStatuses.TRUCK_STATUS_AVAILABLE,
        truckStatuses.TRUCK_STATUS_ON_ROUTE,
    ].join(',');

    const searchPayload = {
        'per-page': MAX_COUNT_TRUCKS_IN_ONE_PAGE,
        status: truckStatusesForAttachToLoad,
        number: filters?.truckNumber || null,
        driver: filters?.drivers || null,
        owner: filters?.owners || null,
        has_driver: true,
        page: 1,
    };

    const omittedSearchPayload = omitBy(searchPayload, (value) => isNull(value) || isUndefined(value));

    return omittedSearchPayload;
};

export const transformAddTruckLocationFormDataToRequestBody = (params: {
    truck: Truck;
    formData: AddTruckLocationFormValues;
}) => {
    const {formData, truck} = params;

    const dateTime = `${formData?.date} ${formData?.time}`;
    const timeZone = formData?.timeZone || '';

    const convertedStatusDateInUTC = convertDate(dateTime, {timeZoneFrom: timeZone, timeZoneTo: 'utc'});

    const payload = {
        availabilityDate: convertedStatusDateInUTC.defaultDateTime,
        lastCityLine: {
            country: formData?.location?.country || null,
            state: formData?.location?.state || null,
            city: formData?.location?.city || null,
            zip: formData?.location?.zip || null,
        },
        lastCoordinates: {
            longitude: formData?.location?.longitude ? toNumber(formData?.location?.longitude) : null,
            latitude: formData?.location?.latitude ? toNumber(formData?.location?.latitude) : null,
        },
        preferredLoads: truck?.preferred_loads || null,
        timeZone: formData?.timeZone || null,
        status: truck?.status || null,
    };

    return omitBy(payload, (value) => isNull(value) || isUndefined(value));
};
