import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';

import {PayPeriodResponsibleUser} from '../../../types';

export const getUsersPayments = (users: PayPeriodResponsibleUser[]): {amount: number; currency: string}[] => {
    const allPayments = users.flatMap((u) => u.settlements_total_pays);
    const paymentsByCurrency = groupBy(allPayments, 'currency');
    return Object.entries(paymentsByCurrency).map(([currency, values]) => {
        return {
            currency,
            amount: sumBy(values, (item) => item.amount),
        };
    });
};
