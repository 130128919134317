import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {InjectedFormProps, Field, reduxForm, formValueSelector} from 'redux-form';

import Truck from 'core/entities/Truck/types';
import {getTypeFieldNameFactory} from 'utils/typeScript';

import TextArea from 'components/ui/Form/TextArea';
import Button from 'components/ui/Buttons/Button';

import trimAndReduceSpaces from 'utils/trimAndReduceSpaces';
import {AddNote, FormTab} from '../../types';
import styles from '../../trucksNotes.module.scss';

const ADD_TRUCK_NOTES_FORM_NAME = 'add-notes-modal-form';
const getName = getTypeFieldNameFactory<AddNote>();

interface OwnProps {
    truck: Truck;
    noteField: string | undefined;
    formNoteType: FormTab;
    permissionAddNote: boolean;
}

const NotesForm = (props: InjectedFormProps<{}, OwnProps> & OwnProps): JSX.Element => {
    const {truck, handleSubmit, permissionAddNote, reset, noteField, formNoteType} = props;

    useEffect(() => {
        reset();
    }, [formNoteType]);

    const submit = (note): void => {
        handleSubmit(note);
        reset();
    };

    return (
        <form onSubmit={submit}>
            <div className={styles.add_note}>
                <Field
                    disabled={!permissionAddNote || truck.is_deleted}
                    name={getName('note')}
                    maxLength="750"
                    component={TextArea}
                    rows="2"
                    placeholder="type a note here"
                    normalize={trimAndReduceSpaces}
                />
            </div>
            <div className={styles.bottom}>
                <div />
                <div className={styles.bottom__actions}>
                    <Button
                        disabled={!permissionAddNote || !noteField}
                        type="submit"
                        colorTheme="blue"
                        className="custom-design ml-1"
                    >
                        Add a Note
                    </Button>
                </div>
            </div>
        </form>
    );
};

const selector = formValueSelector(ADD_TRUCK_NOTES_FORM_NAME);

const Form = reduxForm<{}, OwnProps>({
    form: ADD_TRUCK_NOTES_FORM_NAME,
})(NotesForm);

export default connect((state) => {
    const noteField = selector(state, getName('note'));
    return {
        noteField,
    };
})(Form);
