import React from 'react';

import {PayPeriod} from 'core/entities/Settlement/types';
import {isPayPeriodOpened, getPayPeriodDateEnd} from 'core/entities/Settlement/settlementPayPeriod';

type OwnProps = {
    payPeriod: PayPeriod;
};

const PayPeriodTitle: React.FC<OwnProps> = ({payPeriod}) => {
    const periodDateEnd = getPayPeriodDateEnd(payPeriod);
    return isPayPeriodOpened(payPeriod) ? (
        <div>
            Close Pay Period #{payPeriod.number} - {periodDateEnd}
        </div>
    ) : (
        <div>
            <strong>Pay Period #{payPeriod.number}</strong> - {periodDateEnd} (closed)
        </div>
    );
};

export default PayPeriodTitle;
