import React from 'react';
import {reduxForm, Field, InjectedFormProps} from 'redux-form';

import Button from 'components/ui/Buttons/Button';
import TextInput from 'components/ui/Form/TextInput';
import PasswordInput from 'components/ui/Form/PasswordInput';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import {ChangePasswordModalFormValues} from 'pages/Dispatchers/types';

import validate from './validation';

export const getFormFieldName = getTypeFieldNameFactory<ChangePasswordModalFormValues>();

type OwnProps = {
    isOwnProfile: boolean;
    onClose: () => void;
};

const Form: React.FC<OwnProps & InjectedFormProps<{}, OwnProps>> = (props) => {
    const {handleSubmit, onClose, isOwnProfile} = props;

    return (
        <form onSubmit={handleSubmit} className="tm-confirm tm-check-in">
            <div className="modal-body__main">
                <div className="modal-body__main_item ">
                    <Field
                        name={getFormFieldName('email')}
                        type="text"
                        component={TextInput}
                        disabled={true}
                        label="Login"
                    />

                    <div>
                        {isOwnProfile ? (
                            <Field
                                name={getFormFieldName('current_password')}
                                type="password"
                                showValidation={true}
                                errorTooltipPlacement="top"
                                placeholder="type your current password"
                                component={PasswordInput}
                                label="Current Password"
                            />
                        ) : null}

                        <Field
                            name={getFormFieldName('password')}
                            type="password"
                            showValidation={true}
                            errorTooltipPlacement="top"
                            placeholder="type password with letters and numbers"
                            component={PasswordInput}
                            label="New Password"
                            maxLength={16}
                        />

                        <Field
                            name={getFormFieldName('confirm_password')}
                            type="password"
                            errorTooltipPlacement="top"
                            showValidation={true}
                            placeholder="confirm password"
                            component={PasswordInput}
                            label="Confirm Password"
                            maxLength={16}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <Button type="submit" colorTheme="blue">
                        Change
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default reduxForm<{}, OwnProps>({
    validate,
})(Form);
