export const RECEIVED_FACILITY = 'FACILITY_PAGE_RECEIVED_CURRENT_FACILITY';
export const FACILITY_ARCHIVED = 'FACILITY_PAGE_FACILITY_ARCHIVED';
export const FACILITY_RESTORED = 'FACILITY_PAGE_FACILITY_RESTORED';
export const FACILITY_GENERAL_NOTE_RECEIVED = 'FACILITY_PAGE_GENERAL_NOTE_RECEIVED';
export const FACILITY_CLEAR_STATE = 'FACILITY_PAGE_CLEAR_STATE';
export const RECEIVED_FACILITIES = 'SHIPPERS_PAGE_RECEIVED_FACILITIES';
export const SET_CURRENT_PAGE = 'SHIPPERS_PAGE_SET_CURRENT_PAGE';
export const SET_PER_PAGE = 'SHIPPERS_PAGE_SET_PER_PAGE';
export const SET_SEARCH_PARAMS = 'SHIPPERS_PAGE_SET_SEARCH_PARAMS';
export const LIST_TAB_RECEIVED = 'FACILITIES/LIST_TAB_RECEIVED';
