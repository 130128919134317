import React from 'react';

import getText from 'utils/getText';

import styles from './carrierBadge.module.scss';

const CarrierBadge = ({carrier}) => {
    const globalCarrierName = getText('globalCompanyName');
    return carrier.is_global ? <div className={styles.item}>{globalCarrierName} carrier</div> : null;
};

export default CarrierBadge;
