import * as errorMessages from 'utils/validation/errorMessages';
import * as regexps from 'utils/validation/regexps';

const maxSubjectLength = 70;
const maxMessageLength = 1000;

type ValidationType = {
    email?: string;
    subject?: string;
    message?: string;
};

export default (value: ValidationType): ValidationType => {
    const errors: ValidationType = {};

    if (!value.email?.trim()) {
        errors.email = errorMessages.IF_EMPTY_EMAIL;
    } else if (value.email.trim() && !regexps.EMAIL.test(value.email.trim())) {
        errors.email = errorMessages.INVALID_EMAIL_ADDRESS;
    }

    if (!value.subject?.trim()) {
        errors.subject = errorMessages.IF_EMPTY_SUBJECT;
    } else if (value.subject.trim().length > maxSubjectLength) {
        errors.subject = 'Value should be 70 characters or less.';
    }

    if (!value.message?.trim()) {
        errors.message = errorMessages.IF_EMPTY_MESSAGE;
    } else if (value.message.trim().length > maxMessageLength) {
        errors.message = 'Value should be 1000 characters or less.';
    }

    return errors;
};
