import trim from 'lodash/trim';

import * as loadConstants from 'core/entities/Load/constants';
import {LoadStop} from 'core/entities/Load/types';

import {createDate} from 'utils/dateTime';

type GetDateTimeParams = {toTimeZone: string | null; dateTime: string | null};
type GetDateTimeReturn = {timeZoneCode: string; date: string; time: string};

const getDateTime = (params: GetDateTimeParams): GetDateTimeReturn => {
    const {dateTime, toTimeZone} = params;

    if (!dateTime) {
        return {timeZoneCode: '', date: '', time: ''};
    }

    const {isValid, date, time, timeZoneCodeTo} = createDate(dateTime, {
        toTimeZone: toTimeZone || undefined,
        fromTimeZone: 'utc',
    });

    return {
        timeZoneCode: isValid ? timeZoneCodeTo : '',
        date: isValid ? date : '',
        time: isValid ? time : '',
    };
};

const getStopDateTime = (params: {loadStop?: LoadStop | null; inTheInitialTimeZone?: boolean}) => {
    const {loadStop, inTheInitialTimeZone = false} = params;

    const {dateEnd, dateStart, timeZone} = loadStop || {};

    const toTimeZone = inTheInitialTimeZone ? timeZone : null;

    const startDateTime = getDateTime({dateTime: dateStart || null, toTimeZone: toTimeZone || null});
    const endDateTime = getDateTime({dateTime: dateEnd || null, toTimeZone: toTimeZone || null});

    return {
        timeZoneCode: startDateTime.timeZoneCode || endDateTime.timeZoneCode,

        dateStart: startDateTime.date,
        timeStart: startDateTime.time,

        dateEnd: endDateTime.date,
        timeEnd: endDateTime.time,
    };
};

type TimeFrameOption = {value: string; isBold?: boolean; isTop?: boolean};

type GetTimeFrameOptionsReturn = {
    bottomOptions: TimeFrameOption[];
    allOptions: TimeFrameOption[];
    topOptions: TimeFrameOption[];
};
type GetTimeFrameOptionsParams = {
    inTheInitialTimeZone?: boolean;
    loadStop?: LoadStop | null;
    withTimeZone?: boolean;
};

export const getTimeFrameOptions = (params: GetTimeFrameOptionsParams): GetTimeFrameOptionsReturn => {
    const {loadStop, withTimeZone, inTheInitialTimeZone = false} = params;

    if (!loadStop) {
        return {allOptions: [], bottomOptions: [], topOptions: []};
    }

    const {timeFrame} = loadStop || {};

    const stopDateTime = getStopDateTime({loadStop, inTheInitialTimeZone});

    const timeZoneCode = withTimeZone ? stopDateTime.timeZoneCode : '';

    let options: TimeFrameOption[] = [];

    const optionFCFS = [
        {value: stopDateTime.dateStart, isBold: true, isTop: true},
        {value: stopDateTime.timeStart, isTop: true},
        {value: '—', isTop: true},
        {value: stopDateTime.dateEnd, isBold: true},
        {value: stopDateTime.timeEnd},
        {value: timeZoneCode},
    ];

    const optionsDIRECT = [
        {value: stopDateTime.dateEnd, isBold: true, isTop: true},
        {value: 'direct by'},
        {value: stopDateTime.timeEnd},
        {value: timeZoneCode},
    ];

    const optionsAPPT = [
        {value: stopDateTime.dateEnd, isBold: true, isTop: true},
        {value: 'appt at'},
        {value: stopDateTime.timeEnd},
        {value: timeZoneCode},
    ];

    const optionsASAP = [
        {value: stopDateTime.dateEnd, isBold: true, isTop: true},
        {value: 'asap by'},
        {value: stopDateTime.timeEnd},
        {value: timeZoneCode},
    ];

    if (timeFrame === loadConstants.TIME_FRAME_FCFS) {
        options = optionFCFS;
    }

    if (timeFrame === loadConstants.TIME_FRAME_DIRECT) {
        options = optionsDIRECT;
    }

    if (timeFrame === loadConstants.TIME_FRAME_APPT) {
        options = optionsAPPT;
    }

    if (timeFrame === loadConstants.TIME_FRAME_ASAP) {
        options = optionsASAP;
    }

    const filteredOptions = options.filter((item) => Boolean(trim(item?.value)));

    const bottomOptions = filteredOptions.filter((option) => !option.isTop);
    const topOptions = filteredOptions.filter((option) => option.isTop);

    return {allOptions: [...topOptions, ...bottomOptions], bottomOptions, topOptions};
};
