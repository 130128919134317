export const GENERAL = {
    section: 'general',
    updateTitle: 'General',
    createTitle: 'Step 1. General',
};
export const CONTACT = {
    section: 'contacts',
    updateTitle: 'Contacts',
    createTitle: 'Step 2. Contacts',
};
export const FILES = {
    section: 'files',
    updateTitle: 'Files',
};
