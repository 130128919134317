import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import StoredFile from 'types/File';
import {NormalizedFile} from 'components/ui/Files/FileUpload/types';
import getText from 'utils/getText';

export function omitRemoved(files: StoredFile[], removedFileIds: number[]): StoredFile[] {
    return files.filter((file: StoredFile): boolean => !removedFileIds.includes(file.id));
}

export function composeBadge(primaryIndex: number, secondaryIndex: number): string {
    const rateConfirmation = getText('rateConfirmation').toLowerCase();
    return `load ${rateConfirmation} #${primaryIndex}.${secondaryIndex}`;
}

export function groupByPrimaryIndex(files: NormalizedFile[]): NormalizedFile[][] {
    const groupedInObject = groupBy(files, 'extraData.primary_index');
    return Object.values(groupedInObject);
}

export function sortFiles(files: NormalizedFile[]): NormalizedFile[] {
    return sortBy(files, ['extraData.primary_index', 'extraData.secondary_index']);
}
