import {transformMetaDataToRequest} from '../changeLoadFiles/utils';
import {changeLoadFiles as changeLoadFilesUseCase} from '../index';
import type {LoadApiGateway, UpdateLoadParams, UpdateLoadResult} from '../types';
import {loadDataCanBeUpdated} from '../utils';

import transformLoadDataToRequest from './transformLoadDataToRequest';

const updateLoad = async function (
    data: UpdateLoadParams,
    deps: {
        loadApiGateway: LoadApiGateway;
    },
): Promise<UpdateLoadResult> {
    const {originalLoad, changedLoad, deletedFiles, addedFiles, deletedLoadStopsIds} = data;
    const {loadApiGateway} = deps;

    const isNeedToUpdateLoadData = loadDataCanBeUpdated(changedLoad);

    const isNeedToUpdateOnlyLoadFiles = !isNeedToUpdateLoadData && (addedFiles || deletedFiles);

    const currentTravelOrders = originalLoad.travelOrders;
    let updatedLoad;
    let hasAccessToView = true;

    const addLoadFilesMeta = transformMetaDataToRequest(addedFiles);

    if (isNeedToUpdateLoadData) {
        const dataForUpdate = transformLoadDataToRequest({
            load: changedLoad,
            currentTravelOrders,
            addLoadFilesMeta,
            deletedLoadFilesIds: deletedFiles,
            deletedLoadStopsIds,
        });

        const {loadNumber, files, hasAccess} = await loadApiGateway.updateLoadData(dataForUpdate);

        hasAccessToView = hasAccess;

        await changeLoadFilesUseCase(changedLoad.number, {deletedFiles, addedFiles, fileIds: files});

        if (hasAccess) {
            updatedLoad = await loadApiGateway.getLoad(loadNumber);
        }
    }

    if (isNeedToUpdateOnlyLoadFiles) {
        const dataForRequest = {
            files: addLoadFilesMeta.files,
            deleteFileIds: deletedFiles,
        };

        const {loadNumber, files} = await loadApiGateway.updateLoadFilesMeta({
            loadNumber: changedLoad.number,
            dataForRequest,
        });

        await changeLoadFilesUseCase(changedLoad.number, {deletedFiles, addedFiles, fileIds: files});

        updatedLoad = await loadApiGateway.getLoad(loadNumber);
    }

    return {
        loadNumber: originalLoad.number,
        hasAccessToView,
        updatedLoad,
    };
};

export default updateLoad;
