import AddVirtualStopModal from './AddVirtualStopModal';
import ApproveInvoiceModal from './ApproveInvoiceModal';
import ResetInvoiceModal from './ResetInvoiceModal';
import GenerateInvoicePDFModal from './GenerateInvoicePDFModal';
import SetupBatchModal from './SetupBatchModal';
import PostBatchModal from './PostBatchModal';
import GenerationFailedModal from './GenerationFailedModal';
import CreateInvoiceCustomerModal from './CreateInvoiceCustomerModal';
import ErrorChangeStatusModal from './ErrorChangeStatusModal';
import ErrorResetInvoiceModal from './ErrorResetInvoiceModal';
import InvoicesAddedToBatchInfo from './InvoicesAddedToBatchInfo';
import BatchInvoicesModal from './BatchInvoicesModal';
import ResetBatchInvoicesModal from './ResetBatchInvoicesModal';
import BatchSuccessCreatedModal from './BatchSuccessCreatedModal';

export const billingModalNames = {
    addVirtualStop: 'ADD_VIRTUAL_STOP_MODAL',
    approveInvoice: 'BILLING_APPROVE_INVOICE_MODAL',
    resetInvoice: 'RESET_INVOICE_MODAL',
    generatePDF: 'GENERATE_INVOICE_PDF_MODAL',
    setupBatch: 'SETUP_BATCH_INVOICES_MODAL',
    postBatch: 'POST_BATCH_INVOICES_MODAL',
    generationFailed: 'GENERATION_FAILED_MODAL',
    createCustomer: 'CREATE_CUSTOMER_MODAL',
    errorChangeStatus: 'ERROR_CHANGE_STATUS_INVOICE_MODAL',
    errorResetInvoice: 'ERROR_RESET_INVOICE_MODAL',
    invoicesAddedToBatchInfo: 'INVOICES_ADDED_TO_BATCH_INFO',
    batchInvoicesModal: 'BATCH_INVOICES_MODAL',
    resetBatchInvoicesModal: 'RESET_BATCH_INVOICES_MODAL',
    batchSuccessCreatedModal: 'SUCCESS_CREATED_BATCH_INVOICES_MODAL',
};

const modalMap = {
    [billingModalNames.addVirtualStop]: AddVirtualStopModal,
    [billingModalNames.approveInvoice]: ApproveInvoiceModal,
    [billingModalNames.resetInvoice]: ResetInvoiceModal,
    [billingModalNames.generatePDF]: GenerateInvoicePDFModal,
    [billingModalNames.setupBatch]: SetupBatchModal,
    [billingModalNames.postBatch]: PostBatchModal,
    [billingModalNames.generationFailed]: GenerationFailedModal,
    [billingModalNames.createCustomer]: CreateInvoiceCustomerModal,
    [billingModalNames.errorChangeStatus]: ErrorChangeStatusModal,
    [billingModalNames.errorResetInvoice]: ErrorResetInvoiceModal,
    [billingModalNames.invoicesAddedToBatchInfo]: InvoicesAddedToBatchInfo,
    [billingModalNames.batchInvoicesModal]: BatchInvoicesModal,
    [billingModalNames.resetBatchInvoicesModal]: ResetBatchInvoicesModal,
    [billingModalNames.batchSuccessCreatedModal]: BatchSuccessCreatedModal,
};

export default modalMap;
