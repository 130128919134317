import toNumber from 'lodash/toNumber';
import {getDivider} from 'utils/getDivider';

export const generateTimeMask = (timeFormat: string) => (timeValue) => {
    const divider = getDivider(timeFormat);
    const [firstSymbol] = timeValue;

    return firstSymbol && toNumber(firstSymbol) > 1
        ? [/[2]/, /[0-3]/, divider, /[0-5]/, /[0-9]/]
        : [/[0-1]/, /[0-9]/, divider, /[0-5]/, /[0-9]/];
};
