import type {GetLoadParams, GetLoadResult, LoadApiGateway} from '../types';

type Deps = {
    loadApiGateway: LoadApiGateway;
};

const getLoad = async function (data: GetLoadParams, deps: Deps): Promise<GetLoadResult> {
    const {loadNumber} = data;
    const {loadApiGateway} = deps;
    const load = await loadApiGateway.getLoad(loadNumber);

    return {
        load,
    };
};

export default getLoad;
