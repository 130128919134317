import React, {useMemo} from 'react';
import Tooltip from 'components/ui/Tooltip';
import RefLink from 'components/common/Load/RefNumberLink';

const LoadRefNumbers = ({load, isDisabled = false}) => {
    const nextRefNumbersAfterFirst = useMemo(() => {
        if (isDisabled) {
            return null;
        }
        const nextRefNumbers = (load.ref_number && load.ref_number.split(',').slice(1)) || [];
        return nextRefNumbers.map((refNumber) => (
            <>
                <br />
                <Tooltip
                    key={refNumber}
                    className="icon-tooltip"
                    tooltipContent={
                        <>
                            <span>#{refNumber}</span>
                        </>
                    }
                >
                    <div className="ellips-text">#{refNumber}</div>
                </Tooltip>
            </>
        ));
    }, [load.ref_number, isDisabled]);

    return (
        <>
            <RefLink load={load} isDisabled={isDisabled} />
            {nextRefNumbersAfterFirst}
        </>
    );
};

export default LoadRefNumbers;
