export const LOCATION_EVENTS_TYPES = {
    TRUCK_ATTACHED: 'vehicle_attached',
    FIRST_CHECK_CALL: 'first_check_call',
    CHECK_CALL: 'check_call',
    CHECK_CALL_IN_FUTURE: 'check_call_future',
    PICK_UP_CHECK_IN: 'pick_up_check_in',
    PICK_UP_CHECK_OUT: 'pickup_check_out',
    LOADED: 'loaded',
    ON_THE_WAY_TO_DELIVERY: 'on_the_way_to_delivery',
    ON_THE_WAY_TO_PICK_UP: 'on_the_way_to_pick_up',
    DELIVERY_CHECK_IN: 'delivery_check_in',
    DELIVERY_CHECK_OUT: 'delivery_check_out',
    UNLOADED: 'unloaded',
    LAST_UNLOAD: 'last_unload',
    LAST_CHECK_OUT: 'last_check_out',
    LOAD_COMPLETED: 'load_completed',
    FINISH_TO: 'travel_order_finished',
    PLANNED_TO: 'travel_order_planned',
};
export const locationEventsMap = {
    [LOCATION_EVENTS_TYPES.TRUCK_ATTACHED]: 'vehicle attached',
    [LOCATION_EVENTS_TYPES.FIRST_CHECK_CALL]: 'first check call',
    [LOCATION_EVENTS_TYPES.CHECK_CALL]: 'check call',
    [LOCATION_EVENTS_TYPES.CHECK_CALL_IN_FUTURE]: 'check call ( future )',
    [LOCATION_EVENTS_TYPES.PICK_UP_CHECK_IN]: 'pick up check in',
    [LOCATION_EVENTS_TYPES.PICK_UP_CHECK_OUT]: 'pickup check out',
    [LOCATION_EVENTS_TYPES.LOADED]: 'loaded',
    [LOCATION_EVENTS_TYPES.ON_THE_WAY_TO_DELIVERY]: 'on the way to delivery',
    [LOCATION_EVENTS_TYPES.ON_THE_WAY_TO_PICK_UP]: 'on the way to pick up',
    [LOCATION_EVENTS_TYPES.DELIVERY_CHECK_IN]: 'delivery check in',
    [LOCATION_EVENTS_TYPES.DELIVERY_CHECK_OUT]: 'delivery check out',
    [LOCATION_EVENTS_TYPES.UNLOADED]: 'unloaded',
    [LOCATION_EVENTS_TYPES.LAST_UNLOAD]: 'last unload',
    [LOCATION_EVENTS_TYPES.LAST_CHECK_OUT]: 'last check out',
    [LOCATION_EVENTS_TYPES.LOAD_COMPLETED]: 'load completed',
    [LOCATION_EVENTS_TYPES.FINISH_TO]: 'travel order finished',
    [LOCATION_EVENTS_TYPES.PLANNED_TO]: 'travel order planned',
} as const;

type LocationEventsTypesKeys = keyof typeof LOCATION_EVENTS_TYPES;

export type LocationEventsTypes = typeof LOCATION_EVENTS_TYPES[LocationEventsTypesKeys];
