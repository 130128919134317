import {AxiosResponse} from 'axios';

import {Dispatcher} from 'core/entities/Dispatcher/types';

import {UserRoleOptions} from 'utils/data/roles';

import restapi from 'services/restapi';
import {ENTITY_NAME_DISPATCHERS} from 'utils/data/entityNames';

const apiUrl = `/${ENTITY_NAME_DISPATCHERS}`;

export type FetchDispatcherRolesOptionsApiResponse = {
    userUpdateRoleDropDown: UserRoleOptions;
    userFilterRoleDropDown: UserRoleOptions;
};

export const fetchDispatcherRolesOptions = (): Promise<AxiosResponse<FetchDispatcherRolesOptionsApiResponse>> => {
    return restapi.options(apiUrl);
};

export const searchDispatchers = (params): Promise<AxiosResponse> => {
    return restapi.post(`${apiUrl}/search`, params);
};

export const fetchDispatchersList = (params): Promise<AxiosResponse> => {
    return restapi.get(apiUrl, {params});
};

export const fetchDispatcher = (customerID: number): Promise<AxiosResponse> => {
    return restapi.get(`${apiUrl}/${customerID}`);
};

export const updateDispatcher = (dispatcher: Dispatcher): Promise<AxiosResponse> => {
    return restapi.put(`${apiUrl}/${dispatcher.id}`, dispatcher);
};

export const createDispatcher = (dispatcher: Dispatcher): Promise<AxiosResponse> => {
    return restapi.post(apiUrl, dispatcher);
};

export const archiveDispatcher = (dispatcher: Dispatcher): Promise<Dispatcher> => {
    return restapi.put(`${apiUrl}/${dispatcher.id}/archive`).then(() => ({...dispatcher, is_deleted: true}));
};

export const restoreDispatcher = (dispatcher: Dispatcher): Promise<Dispatcher> => {
    return restapi.put(`${apiUrl}/${dispatcher.id}/restore`).then(() => ({...dispatcher, is_deleted: false}));
};

export const changeDispatcherPassword = (dispatcherID: number, newPassword: string): Promise<AxiosResponse> => {
    return restapi.put(`${apiUrl}/${dispatcherID}/password`, {new_password: newPassword});
};

type JsonString = string;
export const updateDispatcherPreferences = (preferences: JsonString): Promise<AxiosResponse> => {
    // we dont need update by some id or other user data cause api using auth header for user
    return restapi.put(`${apiUrl}/preferences`, {preferences});
};

type ChangeAccountPasswordPayload = {
    password: string;
    confirm_password: string;
    current_password: string;
};

export const changeAccountPasswordRequest = (payload: ChangeAccountPasswordPayload): Promise<AxiosResponse> => {
    const body = {
        newPassword: payload.password,
        confirmPassword: payload.confirm_password,
        currentPassword: payload.current_password,
    };

    return restapi.put(`/account/change-password`, body);
};
