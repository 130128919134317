import React from 'react';

import {Facility} from 'core/entities/Facility/types';

import ArchivingWarnWrapper from 'components/ui/modals/ArchivingWarnWrapper';
import {CustomerLink} from 'components/ui/Links';

type OwnProps = {
    data: {facility: Facility};
    handlers: {onCancel(); onConfirm()};
    onClose();
};

const ArchivingWarn = (props: OwnProps): JSX.Element => {
    const {
        data: {facility},
        handlers: {onCancel, onConfirm},
        onClose,
    } = props;
    return (
        <ArchivingWarnWrapper title={facility.name} onClose={onClose} onCancel={onCancel} onConfirm={onConfirm}>
            <p>
                <strong>DO YOU WANT TO ARCHIVE THE FACILITY?</strong>
            </p>
            <p>
                {' '}
                The archived facility profile <strong>{facility.name}</strong> will disable any actions with it.&nbsp;If
                needed, you still will be able to restore the profile.
            </p>
        </ArchivingWarnWrapper>
    );
};

export default ArchivingWarn;
