import Load from '../../types';

export const LOAD_STATUS_AVAILABLE = 'Available';
export const LOAD_STATUS_IN_PROGRESS = 'In Progress';
export const LOAD_STATUS_PLANNED = 'Planned';
export const LOAD_STATUS_COMPLETED = 'Completed';
export const LOAD_STATUS_TONU = 'TONU';
export const LOAD_STATUS_CANCELED = 'Canceled';
export const LOAD_STATUS_WAITING_FOR_TONU = 'Waiting for TONU';
export const LOAD_STATUS_NO_TONU = 'No TONU';

export const LOAD_STATUSES_ALL = [
    LOAD_STATUS_AVAILABLE,
    LOAD_STATUS_IN_PROGRESS,
    LOAD_STATUS_PLANNED,

    LOAD_STATUS_COMPLETED,
    LOAD_STATUS_TONU,
    LOAD_STATUS_CANCELED,
    LOAD_STATUS_WAITING_FOR_TONU,
    LOAD_STATUS_NO_TONU,
];

export const statusNameMap = {
    [LOAD_STATUS_AVAILABLE]: 'Available',
    [LOAD_STATUS_IN_PROGRESS]: 'In Progress',
    [LOAD_STATUS_PLANNED]: 'Planned',
    [LOAD_STATUS_COMPLETED]: 'Completed',
    [LOAD_STATUS_TONU]: 'TONU',
    [LOAD_STATUS_CANCELED]: 'Canceled',
    [LOAD_STATUS_WAITING_FOR_TONU]: 'TONU Processing',
    [LOAD_STATUS_NO_TONU]: 'No TONU',
};

export const isLoadAvailable = (load: Load): boolean => load?.status === LOAD_STATUS_AVAILABLE;
export const isLoadTONUStatus = (load: Load): boolean => load?.status === LOAD_STATUS_TONU;
export const isLoadWaitingForTONUStatus = (load: Load): boolean => load?.status === LOAD_STATUS_WAITING_FOR_TONU;
export const isLoadCompletedStatus = (load: Load): boolean => load?.status === LOAD_STATUS_COMPLETED;
export const isLoadInProgressStatus = (load: Load): boolean => load?.status === LOAD_STATUS_IN_PROGRESS;
export const isLoadInPlannedStatus = (load: Load): boolean => load?.status === LOAD_STATUS_PLANNED;
export const isLoadNoTONUStatus = (load: Load | undefined): boolean => load?.status === LOAD_STATUS_NO_TONU;
