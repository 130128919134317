import React from 'react';
import {Modal} from 'react-bootstrap';

import Button from 'components/ui/Buttons/Button/index';

import Batch from 'core/entities/Invoice/types/Batch';
import Invoice from 'core/entities/Invoice/types';

type OwnProps = {
    data: {batch: Batch; invoices: Invoice[]};
    handlers: {onSubmit()};
    onClose();
};

const ResetInvoiceModal = (props: OwnProps) => {
    const {
        data: {batch, invoices},
        handlers: {onSubmit},
        onClose,
    } = props;
    return (
        <Modal show={true} dialogClassName="info-modal" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Reset Status</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-error" />
                    </div>
                    <div className="col-11">
                        <p>
                            You are resetting <strong>{invoices.length}</strong> invoice(s) in the batch #
                            <strong>{batch.number}.</strong>
                        </p>
                        <p>
                            These invoices will change their status back to <strong>Rated.</strong>
                        </p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} buttonSize="small" colorTheme="grey">
                    Cancel
                </Button>
                <Button onClick={onSubmit} buttonSize="small" colorTheme="red">
                    Reset
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default React.memo(ResetInvoiceModal);
