import {Log} from 'core/entities/Log/types';

import {EntityListFromAPI, EntityList, LogItem} from '../types';
import entitySettings from '../data/entitySettings';

type EntityNamesMap = Record<EntityListFromAPI, EntityList>;

const entityNamesMap: EntityNamesMap = Object.entries(entitySettings).reduce((result, settingData) => {
    const [entityName, entityData] = settingData;
    result[entityData.apiAlias] = entityName as EntityList;
    return result;
}, {} as EntityNamesMap);

export const mapLogsItems = (rawLogs: Log[]): LogItem[] => {
    return rawLogs.map(
        (log): LogItem => {
            return {...log, entityType: entityNamesMap[log.entity_name]};
        },
    );
};
