class ModalHandlers {
    constructor() {
        this.modalHandlers = {};
    }

    setById({id, functions}) {
        this.modalHandlers[id] = functions;
    }

    getById(id) {
        return this.modalHandlers[id];
    }

    resetById(id) {
        delete this.modalHandlers[id];
    }

    resetAll() {
        this.modalHandlers = {};
    }
}

export const modalHandlers = new ModalHandlers();
