import React from 'react';
import {useSelector} from 'react-redux';

import Truck from 'core/entities/Truck/types';
import {DispatcherNoteApi} from 'core/entities/Truck/types/Note';

import {getQuoteByID} from 'pages/LoadBoard/redux/selectors';
import {isQuoteArchived, isQuoteExpired} from 'core/entities/Quote/modules/quoteProgress';
import Notes from './components/Notes';
import useGetQuoteNotes from './hooks/useGetQuoteNotes';
import styles from './styles.module.scss';

interface OwnProps {
    data: {quoteID: string};
    handlers: {addGeneralNote(params: {text: string}); deleteGeneralNote(params: {id: string})};
    onClose(): void;
}

const QuoteNotesModal: React.FC<OwnProps> = (props) => {
    const {data, onClose, handlers} = props;
    const quoteID = data?.quoteID;
    const quote = useSelector((state) => getQuoteByID(state as any, quoteID));
    const notes = useGetQuoteNotes(quoteID);
    if (!quote) {
        return null;
    }
    const showNotesAction = isQuoteExpired(quote) || isQuoteArchived(quote);
    return (
        <div className={styles.wrap}>
            <div className={styles.header}>
                <p className={styles.title}>
                    <strong>Quote #{quote.number}</strong> Notes
                </p>
                <div className={styles.close} onClick={onClose}>
                    <i className="fa fa-times" />
                </div>
            </div>
            <Notes
                showNotesAction={showNotesAction}
                notes={notes}
                onAddGeneralNote={handlers.addGeneralNote}
                onDeleteGeneralNote={handlers.deleteGeneralNote}
            />
        </div>
    );
};

export default QuoteNotesModal;
