import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

const BadgeLocationForBroker: React.FC<{isRealLocation: boolean}> = ({isRealLocation}) => {
    return (
        <div className={classNames(styles.badge, isRealLocation ? styles.green : styles.red)}>
            {isRealLocation ? 'Real Location' : 'LFB'}
        </div>
    );
};

export default BadgeLocationForBroker;
