import React from 'react';
import {Modal} from 'react-bootstrap';

import FIELDS from './fields';
import Form from './Form';

const PostSettlementCarrierModal = (props) => {
    const {
        onClose,
        data: {settlement, openedBatch, closedBatches},
        handlers: {onSubmit},
    } = props;
    const formInitialValues = {
        [FIELDS.batch]: openedBatch || null,
        [FIELDS.settlement]: settlement,
        [FIELDS.settlementTotal]: null,
    };
    return (
        <Modal show={true} dialogClassName="standard-form-modal" size="sm" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>
                        Post <strong>Settlement #{settlement.settlement_number}</strong>
                    </Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form
                    openedBatch={openedBatch}
                    closedBatches={closedBatches}
                    initialValues={formInitialValues}
                    onSubmit={onSubmit}
                    onClose={onClose}
                />
            </Modal.Body>
        </Modal>
    );
};

export default React.memo(PostSettlementCarrierModal);
