import {AxiosResponse} from 'axios';

import restapi from 'services/restapi';
import {
    AddCheckCallRequestBody,
    LocationEvent,
    GetBrokerUpdateDataFromRequest,
    GetCheckCallEventDataFromRequest,
    BrokerUpdateRequestBody,
    GetConfirmEventDataFromRequest,
    GetConfirmEventDataToRequest,
    AddCheckCallResponse,
    AddBrokerUpdateResponse,
} from 'core/entities/LocationEvents/types';

const apiLoadUrl = `/load`;
const apiTravelOrderUrl = `/travel-order`;

export const addCheckCallEventRequest = (
    travelOrderNumber: number,
    postData: AddCheckCallRequestBody,
): Promise<AxiosResponse<AddCheckCallResponse[]>> => {
    return restapi.post(`${apiTravelOrderUrl}/${travelOrderNumber}/location-events/check-call`, postData);
};

export const fetchAllLocationEventsForLoad = (loadNumber: number): Promise<AxiosResponse<LocationEvent[]>> => {
    return restapi.get(`${apiLoadUrl}/${loadNumber}/location-events`);
};

export const fetchAllLocationEventsForTravelOrder = (
    travelOrderNumber: number,
): Promise<AxiosResponse<LocationEvent[]>> => {
    return restapi.get(`${apiTravelOrderUrl}/${travelOrderNumber}/location-events`);
};

export const getBrokerUpdateRequest = (
    travelOrderNumber: number,
    eventID: string,
): Promise<AxiosResponse<GetBrokerUpdateDataFromRequest>> => {
    return restapi.get(`${apiTravelOrderUrl}/${travelOrderNumber}/location-events/${eventID}/broker-update`);
};

export const sendBrokerUpdateRequest = (
    eventID: string,
    travelOrderNumber: number,
    sendBrokerUpdateData: BrokerUpdateRequestBody,
): Promise<AxiosResponse<AddBrokerUpdateResponse>> => {
    return restapi.post(
        `${apiTravelOrderUrl}/${travelOrderNumber}/location-events/${eventID}/broker-update`,
        sendBrokerUpdateData,
    );
};

export const banLocationEventRequest = (eventID: string, travelOrderNumber: number): Promise<AxiosResponse> => {
    return restapi.put(`${apiTravelOrderUrl}/${travelOrderNumber}/location-events/${eventID}/ban`);
};

export const unBanLocationEventRequest = (eventID: string, travelOrderNumber: number): Promise<AxiosResponse> => {
    return restapi.put(`${apiTravelOrderUrl}/${travelOrderNumber}/location-events/${eventID}/unban`);
};

export const updateLocationEventNoteRequest = (
    eventID: string,
    travelOrderNumber: number,
    note: string,
): Promise<AxiosResponse> => {
    return restapi.put(`${apiTravelOrderUrl}/${travelOrderNumber}/location-events/${eventID}/note`, {note});
};

export const getCheckCallEventDataRequest = (
    travelOrderNumber: number,
): Promise<AxiosResponse<GetCheckCallEventDataFromRequest>> => {
    return restapi.get(`${apiTravelOrderUrl}/${travelOrderNumber}/location-events/modal-data/step-1`);
};

export const getConfirmEventDataRequest = ({
    travelOrderNumber,
    postData,
}: GetConfirmEventDataToRequest): Promise<AxiosResponse<GetConfirmEventDataFromRequest>> => {
    return restapi.post(`${apiTravelOrderUrl}/${travelOrderNumber}/location-events/modal-data/step-2`, postData);
};
