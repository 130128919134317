import React from 'react';

import styles from './spinner.module.scss';

const Spinner = (): JSX.Element | null => {
    return (
        <div className={styles.spinner}>
            <div className={styles.spinner__icon}>
                <i className="fa fa-spinner fa-pulse" />
            </div>
        </div>
    );
};

export default Spinner;
