import isEmpty from 'lodash/isEmpty';

import {FORM_NAME} from 'pages/LoadBoard/components/modals/CreateQuoteModal';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import getFormFieldNames from '../utils/getFormFieldNames';

const useCheckEmptyReceivers = (): boolean => {
    const {getReceiversListFiledName} = getFormFieldNames();
    const receivers = useGetReduxFormValue(FORM_NAME, getReceiversListFiledName());

    return isEmpty(receivers);
};

export default useCheckEmptyReceivers;
